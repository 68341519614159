import styled from '@emotion/styled';
import React from 'react';
import { useUpdateDocumentTitle } from '../../libs/hooks';
import CampaignList, { CampaignListProps } from '../../components/organisms/Campaign/CampaignList/CampaignList';
import TutorialSearchJob from '../../components/organisms/Tutorial/SearchJob';
import { ViewportBreakpoint } from '../../libs/theme';
import SetPriceNotice from './SetPriceNotice';

const Template = (props: CampaignListProps) => {
  useUpdateDocumentTitle({ title: 'pageTitle.Search Job', pageTitle: 'Search Job' });

  return (
    <Container>
      <ResponsiveWrapper>
        <TutorialSearchJob />
        <SetPriceNotice />
        <CampaignList {...props} />
      </ResponsiveWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ResponsiveWrapper = styled.div`
  width: 100%;

  @media (min-width: ${ViewportBreakpoint.DESKTOP}px) {
    width: 702px;
  }
`;

export default Template;
