import React from 'react';
import { AnalyticsTemplateProps } from '@src/components/organisms/AnalyticsShared/types';
import InteractionOverview from './Overview';
import InteractionPosts from './Posts';

const IgInteraction = ({ selectedPeriod, selectedSocialMedia }: AnalyticsTemplateProps) => (
  <>
    <InteractionOverview period={selectedPeriod} id={selectedSocialMedia.id} />
    <InteractionPosts period={selectedPeriod} id={selectedSocialMedia.id} />
  </>
);

export default IgInteraction;
