import * as React from 'react';
import EngagementPostList from '../../components/organisms/EngagementPost/EngagementPostList';
import { useUpdateDocumentTitle } from '../../libs/hooks';

export interface TemplateProps {
  engagementId: number;
}

const Template = (props: TemplateProps) => {
  const { engagementId } = props;
  const title = 'pageTitle.Posts';
  const pageTitle = 'Post List';
  const href = `/job/engagement/${engagementId}`;
  useUpdateDocumentTitle({ title, pageTitle, href });

  return <EngagementPostList engagementId={engagementId} />;
};

export default Template;
