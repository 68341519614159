import styled from '@emotion/styled';
import * as React from 'react';

interface CarouselArrow {
  content: React.ReactElement;
}

const CarouselArrow = (props: CarouselArrow) => {
  const { content, ...rest } = props;

  return <CarouselNav {...rest}>{content}</CarouselNav>;
};

const CarouselNav = styled.div`
  background-color: #fff;
  color: #6e7c89;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  &::before {
    display: none;
  }

  &:hover {
    background-color: #fff;
    color: #6e7c89;
  }
`;

export default CarouselArrow;
