import styled from '@emotion/styled';
import * as React from 'react';
import notFoundGuy from '../../assets/icon/notFoundGuy.svg';
import { useUpdateDocumentTitle } from '../../libs/hooks';
import { PalleteButton } from '../../components/atoms/Button';
import { mainWhite } from '../../libs/pallete';

const Template = ({ showLink = true }) => {
  const title = 'pageTitle.Not Found';
  useUpdateDocumentTitle({ title });

  return (
    <Wrapper>
      <Image src={notFoundGuy} alt="a man can't found and confused" />
      <NotFoundText>
        404
        <br />
        <span>Not found</span>
      </NotFoundText>
      {showLink && (
        <PalleteButton text="Back to top" palleteColor={mainWhite} height="40px" width="max-content" href="/" />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  background-color: #f6f8fa;
  padding-top: 30%;
`;
const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 10px;
`;
const NotFoundText = styled.p`
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 7px;
  line-height: 20px;
  margin-bottom: 32px;

  span {
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: normal;
  }
`;

export default Template;
