import { useField, useFormikContext } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import addressCard from '@src/assets/icon/chat/addressCard.svg';
import { Button } from '@src/components/atoms/Button';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import { Icon } from '@src/components/atoms/Icon';
import Textarea from '@src/components/atoms/Textarea';
import SingleSelectField from '@src/components/molecules/SingleSelectField';
import TextForm from '@src/components/molecules/TextForm';
import YearMonthDayPicker from '@src/components/molecules/YearMonthDayPicker';
import useProvinceOptions from '@src/components/organisms/FanDetails/FanDetailsForm/useProvinceOptions';
import { getOptions } from '@src/libs/form';
import { ViewportType } from '@src/libs/theme';
import { usePageLayout, useQueryHelper, useTranslateOptions } from '@src/libs/hooks';
import useFanCountries from '@src/pages/Fans/useFanCountries';
import { FanGender } from '@src/__generated__/globalTypes';

export interface FanInformation {
  address1: string;
  address2: string;
  birthday: string;
  city: string;
  contactNumber: string;
  countryId: string;
  email: string;
  firstName: string;
  gender: FanGender;
  lastName: string;
  postalCode: string;
  province: string;
  tags: string[];
}

const FanDetail = () => {
  const [tag, setTag] = useState<string>('');
  const { params, t } = useQueryHelper();
  const { activeChat, fanId } = params;
  const { fanCountries } = useFanCountries();
  const { isMobileView } = usePageLayout();

  const { isSubmitting, handleSubmit, setFieldValue } = useFormikContext<FanInformation>();
  const [address1Field] = useField('address1');
  const [address2Field] = useField('address2');
  const [birthdayField] = useField('birthday');
  const [cityField] = useField('city');
  const [contactNumberField] = useField('contactNumber');
  const [countryIdField] = useField('countryId');
  const [emailField, emailMeta] = useField('email');
  const [firstNameField, firstNameMeta] = useField('firstName');
  const [genderField] = useField('gender');
  const [lastNameField, lastNameMeta] = useField('lastName');
  const [postalCodeField] = useField('postalCode');
  const [provinceField] = useField('province');
  const [tagsField] = useField('tags');

  const fanCountryOptions = useMemo(() => getOptions(fanCountries), [fanCountries.length]);
  const countryOptions = useTranslateOptions(fanCountryOptions);
  const genderOptions = useTranslateOptions([
    { value: FanGender.FEMALE, label: 'Female' },
    { value: FanGender.MALE, label: 'Male' },
    { value: FanGender.OTHER, label: 'Other' },
  ]);

  const { provinceOptions, refetch } = useProvinceOptions({ countryId: countryIdField.value });

  useEffect(() => {
    refetch({
      input: {
        countryId: countryIdField.value,
      },
    });
  }, [countryIdField.value]);

  const onClickAddTag = () => {
    if (tag) {
      const tags = [...tagsField.value];
      tags.push(tag);
      setFieldValue(tagsField.name, tags);
      setTag('');
    }
  };

  const onClickRemoveTag = (index: number) => {
    const tags = [...tagsField.value];
    tags.splice(index, 1);
    setFieldValue(tagsField.name, tags);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div css={styles.container}>
        {!isMobileView && (
          <div css={styles.navigatorContainer}>
            <Link to={`/chat/${activeChat}/${fanId}`}>
              <Icon color="#6e7c89" icon="chevron_left" />
            </Link>
            <div>
              <img alt="icon" height="32" src={addressCard} width="32" />
            </div>
            <div>{t('MenuTitle.Fan Detail')}</div>
          </div>
        )}

        <ContentContainer isMobileView={isMobileView}>
          <InputContainer>
            <div>{t('TextForm.Name')}:</div>
            <div css={styles.nameFieldsContainer}>
              <div>
                <TextForm
                  placeholder="Ryuji"
                  value={firstNameField.value}
                  onChange={e => setFieldValue(firstNameField.name, e.target.value)}
                />
                {firstNameMeta.error && <ErrorMessage message={t(firstNameMeta.error)} />}
              </div>
              <div>
                <TextForm
                  placeholder="Takemoto"
                  value={lastNameField.value}
                  onChange={e => setFieldValue(lastNameField.name, e.target.value)}
                />
                {lastNameMeta.error && <ErrorMessage message={t(lastNameMeta.error)} />}
              </div>
            </div>
          </InputContainer>

          <InputContainer>
            <div>{t('TextForm.Email')}:</div>
            <div>
              <TextForm
                placeholder="anychat@anymindgroup.com"
                value={emailField.value}
                onChange={e => setFieldValue(emailField.name, e.target.value)}
              />
              {emailMeta.error && <ErrorMessage message={t(emailMeta.error)} />}
            </div>
          </InputContainer>

          <InputContainer>
            <div>{t('TextForm.Phone Number')}:</div>
            <TextForm
              placeholder="anychat@anymindgroup.com"
              value={contactNumberField.value}
              onChange={e => setFieldValue(contactNumberField.name, e.target.value)}
            />
          </InputContainer>

          <InputContainer>
            <div>{t('Birth day')}:</div>
            <div>
              <YearMonthDayPicker
                css={styles.datepicker}
                format="yyyy-MM-dd"
                maxDate={new Date()}
                value={birthdayField.value || ''}
                onChange={value => setFieldValue(birthdayField.name, value)}
              />
            </div>
          </InputContainer>

          <InputContainer>
            <div>{t('Selector.Gender')}:</div>
            <StyledSingleSelectField
              name={genderField.name}
              options={genderOptions}
              placeholder="Select Gender"
              value={genderField.value}
              setFieldValue={setFieldValue}
            />
          </InputContainer>

          <InputContainer>
            <div>{t('Selector.Country')}:</div>
            <StyledSingleSelectField
              name={countryIdField.name}
              options={countryOptions}
              placeholder="Select Country"
              value={countryIdField.value}
              setFieldValue={setFieldValue}
            />
          </InputContainer>

          <InputContainer>
            <div>{t('Selector.Province')}:</div>
            <StyledSingleSelectField
              name={provinceField.name}
              options={provinceOptions}
              placeholder="Select Province"
              value={provinceField.value}
              setFieldValue={setFieldValue}
            />
          </InputContainer>

          <InputContainer>
            <div>{t('TextForm.City')}:</div>
            <TextForm
              placeholder={t('TextForm.City')}
              value={cityField.value}
              onChange={e => setFieldValue(cityField.name, e.target.value)}
            />
          </InputContainer>

          <InputContainer>
            <div>{t('TextForm.Postal Code')}:</div>
            <TextForm
              placeholder="〒106-6131"
              value={postalCodeField.value}
              onChange={e => setFieldValue(postalCodeField.name, e.target.value)}
            />
          </InputContainer>

          <InputContainer>
            <FlexStartLabel>
              <div>{t('Sentence.Address 1')}:</div>
            </FlexStartLabel>
            <div>
              <StyledTextarea
                placeholder="Roppongi Roppongihiruzumoritawa(31-, Minato Ku"
                value={address1Field.value}
                onChange={e => setFieldValue(address1Field.name, e.target.value)}
              />
            </div>
          </InputContainer>

          <InputContainer>
            <FlexStartLabel>
              <div>{t('Sentence.Address 2')}:</div>
            </FlexStartLabel>
            <div>
              <StyledTextarea
                placeholder="Roppongi Roppongihiruzumoritawa(31-, Minato Ku"
                value={address2Field.value}
                onChange={e => setFieldValue(address2Field.name, e.target.value)}
              />
            </div>
          </InputContainer>

          <InputContainer>
            <FlexStartLabel>{t('Label.Tags')}:</FlexStartLabel>
            <div css={styles.tagsFieldContainer}>
              <div>
                <TextForm placeholder="VIP,etc." value={tag} onChange={e => setTag(e.target.value)} />
                <Button
                  bgcolor="#27313b"
                  disabled={!tag}
                  hoverbgcolor="#27313b"
                  title="Add Tag"
                  onClick={onClickAddTag}
                />
              </div>
              <div>
                {tagsField.value.map((item: string, index: number) => (
                  <div css={styles.tag} key={item}>
                    <div>{item}</div>
                    <Icon color="#27313b" icon="close" onClick={() => onClickRemoveTag(index)} />
                  </div>
                ))}
              </div>
            </div>
          </InputContainer>

          <div css={styles.actionContainer}>
            <Button
              bgcolor="#3892e5"
              css={styles.saveButton}
              disabled={isSubmitting}
              hoverbgcolor="#3892e5"
              title="Save"
              type="submit"
            />
          </div>
        </ContentContainer>
      </div>
    </form>
  );
};

const ContentContainer = styled.div<{ isMobileView: boolean }>`
  display: grid;
  height: ${({ isMobileView }) => (isMobileView ? '90vh' : '65vh')};
  overflow-y: auto;
`;

const FlexStartLabel = styled.div`
  align-items: flex-start;
  display: grid;
  height: 100%;

  & > div {
    margin-top: 8px;
  }
`;

const InputContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;

  & > div:nth-of-type(1) {
    color: #27313b;
    font-size: 14px;
    width: 20%;
  }

  & > div:nth-of-type(2) {
    display: flex;
    flex-wrap: wrap;
    width: 80%;

    & > div {
      width: fill-available;
    }

    & > div:nth-of-type(2) {
      margin-left: 8px;
    }
  }
`;

const StyledSingleSelectField = styled(SingleSelectField)`
  & > label {
    font-size: 14px;
  }

  /* stylelint-disable no-descending-specificity */
  & > div > div {
    border-radius: 2px;
    min-height: 32px;

    & > div > div {
      align-items: center;
      display: flex;
    }
  }

  & input {
    border-radius: 2px;
    min-height: 32px;
  }
`;

const StyledTextarea = styled(Textarea)`
  height: 56px;
`;

const styles = {
  actionContainer: css`
    display: flex;
    justify-content: flex-end;
  `,
  container: css`
    display: grid;
  `,
  datepicker: css`
    & > div {
      & > div {
        border-radius: 2px;
        min-height: 32px;
      }

      & > div > div > input {
        min-height: 32px;
      }
    }

    & > div:nth-of-type(2) {
      margin: 0 8px;
    }
  `,
  nameFieldsContainer: css`
    & > div {
      width: 48% !important;
    }
  `,
  navigatorContainer: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;

    & > a {
      align-items: center;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 2px 5px #dee5ec, 0 1px 4px rgba(222, 229, 236, 0.5);
      display: grid;
      height: 32px;
      justify-content: center;
      margin-right: 8px;
      width: 32px;

      & > i {
        margin: 0;
      }
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: 16px;
      font-weight: 600;
      margin-left: 8px;
    }
  `,
  saveButton: css`
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
    height: 32px;
    width: 77px;

    @media (max-width: ${ViewportType.TABLET}px) {
      height: 40px;
      width: 100%;
    }
  `,
  tag: css`
    align-items: center;
    background-color: #eef3f7;
    border-radius: 3px;
    display: flex;
    flex-wrap: wrap;
    height: 24px;
    margin-bottom: 4px;
    margin-right: 8px;
    padding: 0 8px;
    width: fit-content;

    & > div {
      color: #27313b;
      font-size: 12px;
      margin-right: 8px;
    }
  `,
  tagsFieldContainer: css`
    display: flex;
    flex-wrap: wrap;

    & > div:nth-of-type(1) {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 12px;

      & > div {
        display: flex;
        flex: 1;

        & > div {
          width: 100%;
        }
      }

      & > button {
        border-radius: 3px;
        font-size: 12px;
        font-weight: 600;
        height: 32px;
        margin-left: 8px;
        width: 82px;
      }
    }

    & > div:nth-of-type(2) {
      display: flex;
      flex-wrap: wrap;
    }
  `,
};

export default FanDetail;
