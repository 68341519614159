import { Formik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { formState, submitFormState, useSetRecoilState } from '@src/recoilAtoms';
import { QuestionType } from '@src/__generated__/globalTypes';
import { QuestionErrorMessages } from './DynamicInput';
import Form, { FormInformation, Refs } from './Form';
import ADD_FORM from './mutations/AddForm.graphql';
import { AddForm, AddFormVariables } from './mutations/__generated__/AddForm';
import UPDATE_FORM from './mutations/UpdateForm.graphql';
import { UpdateForm, UpdateFormVariables } from './mutations/__generated__/UpdateForm';

interface IndexProps extends FormInformation {
  isFormAnswerSubmitted: boolean;
  refs: Refs;
}

const Index = (props: IndexProps) => {
  const {
    description,
    hash,
    id,
    isFormAnswerSubmitted,
    questions,
    refs,
    status,
    title,
    thankDescription,
    thankTitle,
  } = props;
  // to reinitialize formik value after submit the form
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  // form is able to draft edit/preview without saving, using recoil to save the draft information
  const setFormState = useSetRecoilState(formState);
  const setSubmitFormState = useSetRecoilState(submitFormState);
  const { enqueueSnackbar, history, t } = useQueryHelper();

  const validationSchema = yup.object().shape({
    description: yup.string().required('requiredDescriptionMessage'),
    questions: yup.array().of(
      yup.object().shape({
        options: yup.array().when('questionType', {
          is: type => [QuestionType.CHECKBOX, QuestionType.DROPDOWN].includes(type),
          then: yup.array().of(
            yup.object().shape({
              optionTitle: yup.string().required(QuestionErrorMessages.OPTION_TITLE),
            })
          ),
        }),
        questionType: yup.string().required(QuestionErrorMessages.TYPE),
        title: yup.string().when('questionType', {
          is: type => [QuestionType.CHECKBOX, QuestionType.DROPDOWN, QuestionType.SHORT_ANSWER].includes(type),
          then: yup.string().required(QuestionErrorMessages.TITLE),
        }),
      })
    ),
    title: yup.string().required('requiredTitleMessage'),
    thankDescription: yup.string().required('requiredThankDescriptionMessage'),
    thankTitle: yup.string().required('requiredThankTitleMessage'),
  });

  const [addForm] = useMutation<AddForm, AddFormVariables>(ADD_FORM, {
    onCompleted: () => {
      enqueueSnackbar(t('succeededInCreating'), { variant: 'success' });
      history.push('/forms');
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [updateForm] = useMutation<UpdateForm, UpdateFormVariables>(UPDATE_FORM, {
    refetchQueries: ['GetForm'],
    onCompleted: () => {
      setIsSubmitted(true);
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const onSubmit = (values: FormInformation) => {
    if (values.id) {
      updateForm({
        variables: {
          input: {
            description: values.description,
            id: values.id,
            questions: values.questions.map(question => ({
              id: question.id,
              image: question.image,
              isRequired: question.isRequired,
              options: question.options.map(option => {
                const { id: optionId, optionTitle, order } = option;

                return {
                  id: optionId,
                  optionTitle,
                  order,
                };
              }),
              order: question.order,
              questionType: question.questionType,
              title: question.title,
            })),
            thankDescription: values.thankDescription,
            thankTitle: values.thankTitle,
            title: values.title,
          },
        },
      });
    } else {
      addForm({
        variables: {
          input: {
            description: values.description,
            hash: values.hash as string,
            questions: values.questions.map(question => ({
              image: question.image,
              isRequired: question.isRequired,
              options: question.options,
              order: question.order,
              questionType: question.questionType,
              title: question.title,
            })),
            thankDescription: values.thankDescription,
            thankTitle: values.thankTitle,
            title: values.title,
          },
        },
      });
    }
    // form draft information is save using recoil, to reset draft information after the form is submit
    setFormState(null);
    setSubmitFormState(null);
  };

  return (
    <Formik
      enableReinitialize={isSubmitted}
      initialValues={{
        description,
        questions,
        status,
        title,
        thankDescription,
        thankTitle,
        ...(hash && { hash }),
        ...(id && { id }),
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form isFormAnswerSubmitted={isFormAnswerSubmitted} refs={refs} />
    </Formik>
  );
};

export default Index;
