import React, { useState } from 'react';
import styled from '@emotion/styled';
import css from '@emotion/css';
import { Redirect } from 'react-router';
import { useMutation } from '@apollo/client';
import mailOpenedBlue from '@src/assets/icon/mailOpenedBlue.svg';
import { usePageLayout, useQueryHelper, useUpdateDocumentTitle } from '@src/libs/hooks';
import { SignUpAndSignInBreakpoint } from '@src/libs/theme';
import TopPageHeader from '@src/components/organisms/TopPage/Header';
import { PalleteButton } from '@src/components/atoms/Button';
import { mainWhite } from '@src/libs/pallete';
import TopPageFooter from '@src/components/organisms/TopPage/Footer';
import { checkSignedIn, getAppRootRoute } from '@src/libs/auth';
import { ListIndicator } from '@src/components/molecules/Indicator';
import * as RESEND_EMAIL from './ResendInfluencerSignUpEmail.graphql';
import {
  ResendInfluencerSignUpEmailVariables,
  ResendInfluencerSignUpEmail,
} from './__generated__/ResendInfluencerSignUpEmail';

const SignUpSuccess = () => {
  const [loading, setLoading] = useState(false);
  const { t, enqueueSnackbar } = useQueryHelper();
  const isSignedIn = checkSignedIn();
  const rootPath = getAppRootRoute();
  const { isMobileViewPublicRoutes } = usePageLayout();

  const [resendEmail] = useMutation<ResendInfluencerSignUpEmail, ResendInfluencerSignUpEmailVariables>(RESEND_EMAIL);
  useUpdateDocumentTitle({ title: 'pageTitle.Profile', pageTitle: 'Profile' });

  if (isSignedIn) {
    return <Redirect to={rootPath} />;
  }

  const email = localStorage.getItem('confirmationEmail');

  const handleResendEmail = async () => {
    setLoading(true);
    try {
      await resendEmail({ variables: { input: { email: email as string } } });
      enqueueSnackbar('Confirmation email has been resended', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to resend email, please try again', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Container>
        <HeaderWrapper>
          <Header href="/" />
        </HeaderWrapper>
        <ContentWrapper>
          {loading ? (
            <ListIndicator border={false} />
          ) : (
            <>
              <MailIcon src={mailOpenedBlue} alt="opened mail" />
              <MainTitle>{t('We have sent a confirmation email')}</MainTitle>
              {!!email && <Email>{email}</Email>}
              <ConfirmationDescription>
                {t('SignUp.EmailConfirmation1')}
                <br />
                {t('SignUp.EmailConfirmation2')}
              </ConfirmationDescription>
              <ResendEmail onClick={handleResendEmail}>{t('Label.Resend confirmation email')}</ResendEmail>
              <PalleteButtonStyled
                text="Back"
                palleteColor={mainWhite}
                href="/signin"
                width="max-content"
                height="40px"
                css={buttonMinWidth}
              />
            </>
          )}
        </ContentWrapper>
        {!isMobileViewPublicRoutes && <TopPageFooter css={footer} />}
      </Container>
    </Wrapper>
  );
};

const footer = css`
  margin-top: initial;
  width: 100%;
`;
const buttonMinWidth = css`
  min-width: 144px;
  text-transform: capitalize;
`;
const Header = styled(TopPageHeader)`
  padding-top: 16px;
`;

const HeaderWrapper = styled.div`
  width: 100%;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    display: flex;
    justify-content: center;

    & > header {
      width: 90%;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    background-color: #fff;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 856px;
  align-items: center;
`;
const ContentWrapper = styled.section`
  box-shadow: 0 1px 4px 0 #dee5ec, 0 2px 5px 0 #dee5ec;
  width: 100%;
  padding: 48px;
  background-color: #fff;
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    border-radius: 0;
    box-shadow: none;
    padding: 32px 48px 32px;
    height: auto;
  }
`;
const MailIcon = styled.img`
  width: 48px;
`;
const MainTitle = styled.h1`
  font-size: 20px;
  line-height: 24px;
  color: #27313b;
  margin-bottom: 24px;
  text-align: center;
`;
const Email = styled.span`
  font-size: 13px;
  line-height: 18px;
  color: #27313b;
  margin-bottom: 24px;
`;
const ConfirmationDescription = styled.p`
  font-size: 13px;
  color: #27313b;
  line-height: 18px;
  text-align: center;
  width: 100%;
`;

const PalleteButtonStyled = styled(PalleteButton)`
  border-radius: 3px;
  filter: brightness(1);
  height: 32px;
  line-height: 32px;
  transition: 0.2s;

  &:hover {
    border: 1px solid #dee5ec;
    filter: brightness(0.9);
  }

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    border-radius: 5px;
    height: 40px;
    line-height: 40px;

    &:hover {
      filter: brightness(1);
    }
  }
`;

const ResendEmail = styled.button`
  margin: 24px 0 24px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: #3892e5;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export default SignUpSuccess;
