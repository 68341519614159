import styled from '@emotion/styled';
import * as React from 'react';
import { Link } from 'react-router-dom';
import css from '@emotion/css';
import { useTranslation } from 'react-i18next';
import {
  CampaignPostStatusForInfluencer,
  CampaignType,
  CampaignStatusForInfluencer,
  MarketplaceCampaignType,
  MarketplaceCampaignAppliedStatus,
} from '../../../../__generated__/globalTypes';
import { getCampaignBadge, getCampaignThumbnailText } from '../../../../libs/campaign';
import CampaignBudget from '../CampaignBudget/CampaignBudget';
import { EngagementPostButtons, MarketplacePostButtons } from '../PostButtonLinks';
import CampaignCardPeriod from './CampaignCardPeriod';
import CampaignCardPrice, { CampaignCardPriceProps } from './CampaignCardPrice';

export interface CampaignCardProps extends CampaignCardPriceProps {
  className?: string;
  url: string;
  thumbNail: string;
  title: string;
  start: string;
  end: string;
  remainingAmount: number | null;
  totalAmount: number | null;
  icon: string | null;
  showCampaignBadge?: boolean; // Only true for FinishedJob and YourJob
  campaignId: number | null;
  hasReport?: boolean;
  hasDraftPosts?: boolean;
  hasWarningPosts?: boolean;
  campaignStatus: CampaignStatusForInfluencer;
  marketplaceCampaignType?: MarketplaceCampaignType;
  isApplying?: boolean | null;
  showingInFinishedCampaign: boolean;
  appliedStatus?: MarketplaceCampaignAppliedStatus | null;
  postStatus?: CampaignPostStatusForInfluencer | null;
}

const CampaignCard = (props: CampaignCardProps) => {
  const { t } = useTranslation();
  const {
    className,
    url,
    thumbNail,
    title,
    start,
    end,
    icon,
    prices,
    currency,
    remainingAmount,
    totalAmount,
    campaignType,
    showCampaignBadge,
    campaignId,
    hasDraftPosts,
    hasReport,
    hasWarningPosts,
    campaignStatus,
    marketplaceCampaignType,
    isApplying,
    appliedStatus,
    postStatus,
  } = props;

  const isEngagementCard = campaignType === CampaignType.ENGAGEMENT;
  const isFinishedCampaign = campaignStatus === CampaignStatusForInfluencer.FINISHED;

  const thumbnailText = getCampaignThumbnailText({
    status: campaignStatus,
    hasReport,
    isApplying: !!isApplying,
    appliedStatus,
    postStatus,
  });

  const campaignBadge = getCampaignBadge(campaignType, !!isApplying);

  return (
    <Container className={className}>
      <Link to={url}>
        <Thumbnail source={thumbNail} isFinished={isFinishedCampaign}>
          <TitleWrapper>
            {marketplaceCampaignType === MarketplaceCampaignType.SELECTION && (
              <CampaignStatus>{t('Label.Selection Campaign')}</CampaignStatus>
            )}
            <Title>{title}</Title>
          </TitleWrapper>
          {/* Status on top left corner */}
          {showCampaignBadge && (
            <BadgeWrapper
              css={css`
                background-color: ${campaignBadge.bgColor};
              `}
            >
              {t(`Label.${campaignBadge.text}`)}
            </BadgeWrapper>
          )}
          {thumbnailText?.mainText && (
            <StatusBadge>
              {t(`Label.${thumbnailText.mainText}`)}
              {thumbnailText.subText && <p>{t(`Annotation.${thumbnailText.subText}`)}</p>}
            </StatusBadge>
          )}
        </Thumbnail>

        <Wrapper>
          {!isEngagementCard && (
            <>
              <Icon src={icon || undefined} />
              <CampaignCardPrice campaignType={campaignType} prices={prices} currency={t(currency)} />
            </>
          )}

          <CampaignCardPeriod start={start} end={end} />
        </Wrapper>

        {remainingAmount !== null && totalAmount !== null && totalAmount > 0 && (
          <Footer>
            <CampaignBudget showLabel={true} remainingAmount={remainingAmount} totalAmount={totalAmount} />
          </Footer>
        )}
      </Link>
      {isEngagementCard ? (
        <EngagementPostButtons
          campaignId={campaignId}
          campaignStatus={campaignStatus}
          isJoined={true}
          hasDraftPosts={hasDraftPosts}
          hasReport={hasReport}
          hasWarningPosts={hasWarningPosts}
          css={[engagementPostButtonsCss]}
        />
      ) : (
        <MarketplacePostButtons campaignId={campaignId} isJoined={true} hasReport={hasReport} />
      )}
    </Container>
  );
};

export const BadgeWrapper = styled.span`
  left: -48px;
  top: -48px;
  position: absolute;
  width: 96px;
  height: 96px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 6px;
  box-sizing: border-box;
  transform: rotate(-45deg);
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  pointer-events: none;
`;

const CampaignStatus = styled.span`
  background-color: #f6b756;
  color: #fff;
  border-radius: 3px;
  padding: 4px;
  align-self: flex-start;
  font-size: 12px;
  font-weight: bold;
`;

const TitleWrapper = styled.div`
  position: absolute;
  width: calc(100% - 32px);
  bottom: 16px;
  left: 16px;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  max-width: 343px;
  margin: 0 auto 16px;
  background-color: #fff;
  box-shadow: 0 1px 7px 0 #dee5ec, 0 3px 6px 1px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
  break-inside: avoid;

  &:hover h2 {
    color: #179cd7;
    text-decoration: underline;
  }
`;

const Wrapper = styled.div`
  padding: 11px 16px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  min-height: 22px;
`;

const Title = styled.h2`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
  margin-top: 8px;
`;

const Icon = styled.img`
  width: 22px;
  margin-right: 8px;
`;

const Thumbnail = styled.div<{
  source: string;
  isFinished?: boolean;
}>`
  overflow: hidden;
  position: relative;
  height: 220px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  z-index: 1;

  /* hack for 'filter: grayscale(100%)' not affect whole Thumbnail, but only the image */
  &::after {
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    height: 220px;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    background-image: ${props =>
      !!props.source
        ? `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)), url("${props.source}")`
        : 'none'};
    ${({ isFinished }) => isFinished && 'filter: grayscale(100%);'}
  }
`;

const Footer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  border-top: 1px solid #eef3f7;
  padding: 0 16px;
`;

export const StatusBadge = styled.div`
  position: absolute;
  min-width: 120px;
  min-height: 40px;
  max-width: 200px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #000;
  background-clip: padding-box;
  border: 4px solid rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  word-break: break-word;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 8px 0;

  p {
    font-size: 12px;
    text-transform: none;
  }
`;

const engagementPostButtonsCss = css`
  border-top: 1px solid #dee5ec;
  padding: 8px 16px;
`;

export default CampaignCard;
