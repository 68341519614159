import React from 'react';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { AuthSocialAccountType } from '../../../__generated__/globalTypes';
import { ConnectType } from '../../../libs/auth';
import { useConnect } from '../Profile/ProfileConnectForm/useConnect';
import { ChooseFanpageForm } from './ChooseFanpageForm';
import { ChooseInstagramAccount } from './ChooseInstagramAccount';
import BaseSignUpConnectForm from './SignUpConnectForm';
import { SocialAccounts } from './types';

export type SignUpConnectFormProps = SocialAccounts;

const SignUpConnectForm = (props: SignUpConnectFormProps) => {
  const { facebook, instagramAccounts, twitterAccounts, youtubeAccounts, tiktokAccounts } = props;

  const { onClick, loading } = useConnect(FE_REDIRECT_MAPPING.SIGNUP_CONNECT);

  const handleConnectFacebook = async () => {
    // Set ConnectType for Choosing FB page and IG account.
    localStorage.setItem('connectType', ConnectType.CONNECT_FB_PAGE);

    await onClick(AuthSocialAccountType.FACEBOOK)();

    return;
  };

  const handleConnectInstagram = async () => {
    // Set ConnectType for Choosing IG account.
    localStorage.setItem('connectType', ConnectType.CONNECT_IG_ACCOUNT);

    await onClick(AuthSocialAccountType.FACEBOOK)();

    return;
  };

  const handleConnectTwitter = async () => {
    // Set RedirectType for Twitter OAuth
    localStorage.setItem('redirectType', FE_REDIRECT_MAPPING.SIGNUP_CONNECT);

    await onClick(AuthSocialAccountType.TWITTER)();

    return;
  };

  const handleConnectTiktok = async () => {
    await onClick(AuthSocialAccountType.TIKTOK)();

    return;
  };

  const handleConnectYoutube = async () => {
    await onClick(AuthSocialAccountType.YOUTUBE)();

    return;
  };

  return (
    <BaseSignUpConnectForm
      facebook={facebook}
      instagramAccounts={instagramAccounts}
      twitterAccounts={twitterAccounts}
      youtubeAccounts={youtubeAccounts}
      tiktokAccounts={tiktokAccounts}
      handleConnectFacebook={handleConnectFacebook}
      handleConnectInstagram={handleConnectInstagram}
      handleConnectTwitter={handleConnectTwitter}
      handleConnectYoutube={handleConnectYoutube}
      handleConnectTiktok={handleConnectTiktok}
      loading={loading}
    />
  );
};

export default SignUpConnectForm;
export { ChooseFanpageForm, ChooseInstagramAccount };
