import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import SearchForm from '@src/components/molecules/SearchForm';
import SingleSelectField from '@src/components/molecules/SingleSelectField';
import { ViewportType } from '@src/libs/theme';
import { FormStatus } from '@src/__generated__/globalTypes';
import { FilterItems } from './useFilter';

interface FormListFilterProps {
  values: FilterItems;
  onChange: (values: FilterItems) => void;
}

const FormListFilter = ({ values, onChange }: FormListFilterProps) => {
  const [filter, setFilter] = useState<FilterItems>(values);
  const { t } = useTranslation();
  const statusOptions = [
    { label: t(FormStatus.DRAFT), value: FormStatus.DRAFT },
    { label: t(FormStatus.PRIVATE), value: FormStatus.PRIVATE },
    { label: t(FormStatus.PUBLIC), value: FormStatus.PUBLIC },
  ];

  useEffect(() => {
    setFilter(values);
  }, [values.keyword, values.status]);

  function onChangeSelect(value: FormStatus) {
    const items = { ...filter, status: value };
    setFilter(items);
    onChange(items);
  }

  return (
    <div css={styles.container}>
      <div>
        <SearchForm
          css={styles.searchForm}
          placeholder={t(`TextForm.Search by Form's Name`)}
          value={filter.keyword}
          onBlur={() => onChange(filter)}
          onChange={e => setFilter({ ...filter, keyword: e.target.value })}
          onEnterKeyPress={() => onChange(filter)}
        />
      </div>
      <div>
        <StyledSingleSelectField
          name=""
          options={statusOptions}
          placeholder="Status"
          value={filter.status}
          setFieldValue={(_attr, value) => onChangeSelect(value as FormStatus)}
        />
      </div>
    </div>
  );
};

const StyledSingleSelectField = styled(SingleSelectField)`
  & > label {
    font-size: 14px;
  }

  & > div > div {
    border-radius: 2px;
    min-height: 30px;

    & > div > div {
      align-items: center;
      display: flex;
    }
  }

  & input {
    border-radius: 2px;
    min-height: 30px;
  }
`;

const styles = {
  container: css`
    background-color: #fff;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    display: flex;
    flex-wrap: wrap;
    padding: 16px;

    & > div:nth-of-type(1) {
      width: 80%;

      @media (max-width: ${ViewportType.SMALL}px) {
        width: 100%;
        margin-bottom: 8px;
      }
    }

    & > div:nth-of-type(2) {
      width: 20%;

      & > div {
        margin-left: 8px;
        width: fill-available;
      }

      @media (max-width: ${ViewportType.SMALL}px) {
        width: 100%;

        & > div {
          margin-left: 0;
        }
      }
    }
  `,
  searchForm: css`
    & input {
      height: 32px;
    }
  `,
};

export default FormListFilter;
