import React, { useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { useAuthData } from '../../../libs/hooks';
import { defaultInfluencerCover, defaultInfluencerAvatar } from '../../../libs/image';
import { ProfileCoverSkeleton } from '../../atoms/PageSkeletons';
import * as PROFILE_COVER from './ProfileCover.graphql';
import { ProfileCover } from './__generated__/ProfileCover';

interface ProfileCoverDataProps {
  cover: string | null;
  avatar: string | null;
  name: string;
}
const defaultProfileCoverData: ProfileCoverDataProps = {
  cover: null,
  avatar: null,
  name: '',
};

export const ProfileCoverHeader = () => {
  const { userId } = useAuthData();
  const [{ cover, avatar, name }, setCoverAvatar] = useState<ProfileCoverDataProps>(defaultProfileCoverData);
  const { loading } = useQuery<ProfileCover>(PROFILE_COVER, {
    variables: { pk: userId },
    skip: !userId,
    fetchPolicy: 'cache-and-network',
    onCompleted: useCallback((data: ProfileCover) => {
      const profileData = data.influencerProfileV2 || defaultProfileCoverData;
      setCoverAvatar({ ...profileData });
    }, []),
  });

  if (loading) {
    return <ProfileCoverSkeleton />;
  }

  return (
    <Wrapper>
      <CoverImage src={defaultInfluencerCover(cover)} alt="Cover image" />
      <ProfileName>
        <AvatarWithName>
          <Avatar src={defaultInfluencerAvatar(avatar)} alt="Influencer avatar" />
          <span>{name}</span>
        </AvatarWithName>
      </ProfileName>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  border-bottom: 1px solid #dee5ec;
`;
const CoverImage = styled.img`
  width: 100%;
  max-height: 200px;
  object-fit: contain;
  background-color: #000;
`;
const ProfileName = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  height: 72px;
  background-color: #fff;
  box-sizing: border-box;
`;
const AvatarWithName = styled.div`
  position: absolute;
  top: -20px;
  left: 16px;
  display: flex;
  align-items: center;

  span {
    margin-left: 16px;
    margin-top: 16px;
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: normal;
    color: #27313b;
  }
`;
const Avatar = styled.img`
  box-sizing: border-box;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 1px solid #f7f7f7;
`;
