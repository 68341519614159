import { format } from 'date-fns';
import React from 'react';
import { css } from '@emotion/react';
import Grid from '@src/components/atoms/Grid';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import EmailForm, { dateFormat } from '@src/components/organisms/Emails/Form';
import { usePageLayout, usePersonalInformation } from '@src/libs/hooks';

const Compose = () => {
  const { userPersonalInfo } = usePersonalInformation();
  const { isMobileView } = usePageLayout();

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <div css={styles.navigatorContainer}>
          <BackNavigator title="Compose" to="/emails" />
        </div>
      )}

      <div css={styles.infoContainer}>
        <Grid md={8} sm={10} xs={12}>
          <EmailForm
            backUrl="/emails"
            fans={[]}
            isDraft={false}
            message=""
            scheduleDate={format(new Date(), dateFormat)}
            sender={userPersonalInfo?.email || ''}
            sendNow={true}
            subject=""
            tags={[]}
          />
        </Grid>
      </div>
    </div>
  );
};

const styles = {
  container: css`
    padding: 16px 24px;
  `,
  infoContainer: css`
    display: flex;
    justify-content: center;
  `,
  navigatorContainer: css`
    margin-bottom: 16px;
  `,
};

export default Compose;
