import React, { CSSProperties, useState } from 'react';
import Popup from 'reactjs-popup';
import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Avatar from '@src/components/atoms/Avatar';
import { Button } from '@src/components/atoms/Button';
import Grid from '@src/components/atoms/Grid';
import { Icon } from '@src/components/atoms/Icon';
import { RadioIcon } from '@src/components/atoms/RadioButtonElement';
import { getAuthSocialAccountType } from '@src/libs/campaign';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { formatDate, formatIntNumber, formatNumberWithCommas } from '@src/libs/format';
import { useRedirectUrl } from '@src/libs/hooks/useRedirectUrl';
import { defaultInfluencerAvatar } from '@src/libs/image';
import { useRedirectWithinIframe, useQueryHelper } from '@src/libs/hooks';
import { switchImage, switchText } from '@src/libs/SocialMedia';
import { ViewportBreakpoint, ViewportType } from '@src/libs/theme';
import { CampaignSocialMediaType, SocialAccountType } from '@src/__generated__/globalTypes';
import { useMediaQuery } from 'react-responsive';
import CAMPAIGN_JOINABLE_ACCS from './queries/CampaignJoinableAccounts.graphql';
import {
  CampaignJoinableAccounts,
  CampaignJoinableAccountsVariables,
} from './queries/__generated__/CampaignJoinableAccounts';

enum DialogStatus {
  CLOSE = 'CLOSE',
  CHOOSE_SOCIAL_MEDIA = 'CHOOSE_SOCIAL_MEDIA',
  TERMS_CONDITION = 'TERMS_CONDITION',
  REQUIRED_SIGN_IN = 'REQUIRED_SIGN_IN',
}

interface JoinButtonProps {
  campaignDetails: {
    currency: string;
    endDate: any;
    id: number;
    maximumRevenuePerInfluencer: number | null;
    minimumPaymentAmount: number;
    socialMedias: CampaignSocialMediaType[];
    startDate: any;
  };
  height?: number;
  isSelection?: boolean;
}

const JoinButton = ({ campaignDetails, height = 32, isSelection }: JoinButtonProps) => {
  const {
    currency,
    endDate,
    id: campaignId,
    maximumRevenuePerInfluencer,
    minimumPaymentAmount,
    socialMedias,
    startDate,
  } = campaignDetails;
  const [dialog, setDialog] = useState<DialogStatus>(DialogStatus.CLOSE);
  const [selectedAccount, setSelectedAccount] = useState<{
    id: number;
    selectedSocialAccountId: number;
    type: SocialAccountType;
  } | null>(null);
  const { enqueueSnackbar, history, t } = useQueryHelper();
  const { getRedirectUrl } = useRedirectUrl(FE_REDIRECT_MAPPING.JOIN_CAMPAIGN);
  const { startRedirectProcess } = useRedirectWithinIframe();
  const isDesktop = useMediaQuery({ minWidth: 1000 });
  const isTablet = useMediaQuery({ minWidth: ViewportBreakpoint.MOBILE, maxWidth: ViewportBreakpoint.MEDIUM });
  const isVerticalView = useMediaQuery({ maxWidth: ViewportBreakpoint.MEDIUM });

  const { data, loading } = useQuery<CampaignJoinableAccounts, CampaignJoinableAccountsVariables>(
    CAMPAIGN_JOINABLE_ACCS,
    {
      variables: {
        campaignId,
      },
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  const joinableAccounts = (() => {
    const socialMedia = socialMedias[0];

    let accounts: {
      avatar: string | null;
      disabled: boolean;
      id: number;
      isBusinessAccount?: boolean;
      followersCount: number;
      selectedSocialAccountId: number;
      type: SocialAccountType;
      username: string;
    }[] = [];

    if (data?.marketplaceJoinableAccounts) {
      const {
        facebookAccount,
        facebookPages,
        instagramAccounts,
        tiktokAccounts,
        twitterAccounts,
        youtubeAccounts,
      } = data.marketplaceJoinableAccounts;

      if (
        [
          CampaignSocialMediaType.FACEBOOK,
          CampaignSocialMediaType.INSTAGRAM,
          CampaignSocialMediaType.INSTAGRAM_STORY,
        ].includes(socialMedia) &&
        facebookAccount
      ) {
        if (socialMedia === CampaignSocialMediaType.FACEBOOK) {
          accounts = accounts.concat([
            { ...facebookAccount, selectedSocialAccountId: facebookAccount.id, type: SocialAccountType.FACEBOOK },
          ]);
          if (facebookPages.length > 0) {
            accounts = accounts.concat(
              facebookPages.map(fbPage => ({
                ...fbPage,
                selectedSocialAccountId: facebookAccount.id,
                type: SocialAccountType.FACEBOOK_PAGE,
              }))
            );
          }
        }
        if (
          [CampaignSocialMediaType.INSTAGRAM, CampaignSocialMediaType.INSTAGRAM_STORY].includes(socialMedia) &&
          instagramAccounts.length > 0
        ) {
          accounts = instagramAccounts.map(igAccount => ({
            ...igAccount,
            selectedSocialAccountId: facebookAccount.id,
            type: SocialAccountType.INSTAGRAM,
          }));
        }
      } else if (socialMedia === CampaignSocialMediaType.TIKTOK && tiktokAccounts.length > 0) {
        accounts = tiktokAccounts.map(tiktokAccount => ({
          ...tiktokAccount,
          selectedSocialAccountId: tiktokAccount.id,
          type: SocialAccountType.TIKTOK,
        }));
      } else if (socialMedia === CampaignSocialMediaType.TWITTER && twitterAccounts.length > 0) {
        accounts = twitterAccounts.map(twAccount => ({
          ...twAccount,
          selectedSocialAccountId: twAccount.id,
          type: SocialAccountType.TWITTER,
        }));
      } else if (socialMedia === CampaignSocialMediaType.YOUTUBE && youtubeAccounts.length > 0) {
        accounts = youtubeAccounts.map(ytAccount => ({
          ...ytAccount,
          selectedSocialAccountId: ytAccount.id,
          type: SocialAccountType.YOUTUBE,
        }));
      }
    }

    return accounts;
  })();

  const onClickConnect = async () => {
    if (selectedAccount) {
      localStorage.setItem('joinCampaignId', String(campaignId));
      localStorage.setItem('joinAccountSocialMedia', String(selectedAccount.type));
      localStorage.setItem('joinAccountId', String(selectedAccount.id));
      if (
        [SocialAccountType.FACEBOOK, SocialAccountType.FACEBOOK_PAGE, SocialAccountType.INSTAGRAM].includes(
          selectedAccount.type
        )
      ) {
        localStorage.setItem('fbAccountId', String(selectedAccount.selectedSocialAccountId));
      }

      const authSocialAccount = getAuthSocialAccountType(socialMedias[0]);
      if (!authSocialAccount) {
        return;
      }

      const redirectUrl = await getRedirectUrl(authSocialAccount);
      if (!redirectUrl) {
        return;
      }

      if (authSocialAccount === SocialAccountType.TWITTER) {
        // Set RedirectType for Twitter OAuth
        localStorage.setItem('redirectType', FE_REDIRECT_MAPPING.JOIN_CAMPAIGN);
      }
      startRedirectProcess({ redirectUrl, redirectType: FE_REDIRECT_MAPPING.JOIN_CAMPAIGN });
    }

    return;
  };

  const onClickJoinNow = () => {
    if (joinableAccounts.length === 1) {
      const { id, selectedSocialAccountId, type } = joinableAccounts[0];
      setSelectedAccount({ id, selectedSocialAccountId, type });
      setDialog(DialogStatus.TERMS_CONDITION);
    } else {
      setDialog(DialogStatus.CHOOSE_SOCIAL_MEDIA);
    }
  };

  const onCloseDialog = () => {
    setSelectedAccount(null);
    setDialog(DialogStatus.CLOSE);
  };

  const popupStyles: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    width: isDesktop ? '900px' : isTablet ? '450px' : '90%',
    height: isVerticalView ? '700px' : '500px',
    maxHeight: '90vh',
    padding: '0',
    border: 'none',
    borderRadius: isVerticalView ? '15px' : '5px',
  };

  return (
    <div>
      <Popup modal lockScroll contentStyle={popupStyles} open={dialog !== DialogStatus.CLOSE} onClose={onCloseDialog}>
        <ModalWrapper>
          <div css={styles.closeBtn} onClick={onCloseDialog}>
            <Icon icon="close" />
          </div>

          {dialog === DialogStatus.CHOOSE_SOCIAL_MEDIA ? (
            joinableAccounts.length > 0 ? (
              <>
                <DialogContent>
                  <DialogTitle>{t('Choose an account to join')}</DialogTitle>
                  <DialogDescription>{t('ChooseJoiningAccountExplained')}</DialogDescription>
                  <div css={styles.socialMediasContainer}>
                    {joinableAccounts.map(account => {
                      const { avatar, disabled, id, followersCount, selectedSocialAccountId, type, username } = account;

                      return (
                        <Grid key={id} md={6} xs={12}>
                          <div
                            css={styles.socialMediaContainer}
                            onClick={() =>
                              !disabled ? setSelectedAccount({ id, selectedSocialAccountId, type }) : null
                            }
                          >
                            <RadioIcon isChecked={id === selectedAccount?.id} />
                            <img alt="socialMedia" height="50" src={switchImage(socialMedias[0], true)} width="50" />
                            <Avatar src={defaultInfluencerAvatar(avatar)} />
                            <div>
                              <div>{username}</div>
                              <div>{`${formatNumberWithCommas(followersCount, 0)} ${t('Followers')}`}</div>
                            </div>
                            {disabled && (
                              <div css={styles.socialMediaOverlay}>{t(`It's not match the condition Account`)}</div>
                            )}
                          </div>
                        </Grid>
                      );
                    })}
                  </div>
                </DialogContent>
                <DialogAction>
                  <DialogButton title="Close" onClick={onCloseDialog} />
                  <DialogButton
                    disabled={!selectedAccount}
                    isPrimary
                    title="Next"
                    onClick={() => setDialog(DialogStatus.TERMS_CONDITION)}
                  />
                </DialogAction>
              </>
            ) : (
              <>
                <DialogContent>
                  <DialogTitle>{t('Choose an account to join')}</DialogTitle>
                  <DialogDescription>{t('ChooseJoiningAccountExplained')}</DialogDescription>
                  <div css={styles.connectSocialAcc}>
                    {t(`Please connect ${switchText(socialMedias[0])} account before joining this campaign`)}
                  </div>
                </DialogContent>
                <DialogAction>
                  <DialogButton title="Close" onClick={() => history.push('/settings/social_connect')} />
                </DialogAction>
              </>
            )
          ) : dialog === DialogStatus.TERMS_CONDITION ? (
            <>
              <DialogContent>
                <DialogTitle>{t('TermsConditionsPayment')}</DialogTitle>
                <DialogDescription>
                  {t('TheCampaignPeriodIs', { start: formatDate(startDate), end: formatDate(endDate) })}
                </DialogDescription>
                {maximumRevenuePerInfluencer && (
                  <DialogDescription>
                    {t('RevenueLimitGuideLine', { price: formatIntNumber(maximumRevenuePerInfluencer), currency })}
                  </DialogDescription>
                )}
                <DialogDescription>
                  {t('RevenueQuestionMask', { price: formatIntNumber(minimumPaymentAmount), currency })}
                </DialogDescription>
                <DialogDescription>{t('Terms1')}</DialogDescription>
                <DialogDescription>{t('Terms2')}</DialogDescription>
                <DialogDescription>{t('Terms3')}</DialogDescription>
                <DialogDescription>{t('Terms4')}</DialogDescription>
              </DialogContent>
              <DialogAction>
                <DialogButton title="Close" onClick={onCloseDialog} />
                <DialogButton isPrimary title="Next" onClick={() => setDialog(DialogStatus.REQUIRED_SIGN_IN)} />
              </DialogAction>
            </>
          ) : dialog === DialogStatus.REQUIRED_SIGN_IN ? (
            <>
              <DialogContent>
                <DialogTitle>{t('Required sign-in with social platform again')}</DialogTitle>
                <DialogDescription>{t('Dialog.OauthJoinCampaign')}</DialogDescription>
              </DialogContent>
              <DialogAction>
                <DialogButton title="Close" onClick={onCloseDialog} />
                <DialogButton isPrimary title="OK" onClick={onClickConnect} />
              </DialogAction>
            </>
          ) : null}
        </ModalWrapper>
      </Popup>

      <StyledButton
        bgcolor="#3892e5"
        disabled={loading}
        hoverbgcolor="#55a3ec"
        height={height}
        title={isSelection ? 'Apply' : 'Join Now'}
        onClick={onClickJoinNow}
      />
    </div>
  );
};

const DialogAction = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;

  & > button:nth-of-type(2) {
    margin-left: 8px;
  }

  @media (max-width: ${ViewportType.SMALL}px) {
    & > button {
      height: 40px;
      width: fill-available;
    }
  }
`;

const DialogButton = styled(Button)<{ isPrimary?: boolean }>`
  color: ${({ isPrimary }) => (isPrimary ? '#fff' : '#6e7c89')};
  background-color: ${({ isPrimary }) => (isPrimary ? '#3892e5' : '#fff')};
  border: 1px solid ${({ isPrimary }) => (isPrimary ? '#fff' : '#dee5ec')};
  border-radius: 5px;
  filter: brightness(1);
  font-weight: 600;
  height: 32px;
  padding: 0 16px;
  width: 78px;

  &:hover {
    background-color: ${({ isPrimary }) => (isPrimary ? '#3892e5' : '#fff')};
    filter: brightness(0.9);
  }
`;

const DialogContent = styled.div`
  border-bottom: 1px solid #dee5ec;
  padding: 16px;
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  font-size: 12px;
`;

const DialogDescription = styled.div`
  color: #27313b;
  font-size: 13px;
  margin-bottom: 16px;
`;

const DialogTitle = styled.div`
  color: #27313b;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const StyledButton = styled(Button)<{ height: number }>`
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  height: ${({ height }) => height}px;
  padding: 0 24px;
  width: 100%;
`;

const styles = {
  closeBtn: css`
    align-items: center;
    background-color: #000;
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: -16px;
    top: -16px;
    width: 40px;
    z-index: 10;

    & > i {
      margin: 0;
    }
  `,
  connectSocialAcc: css`
    color: #27313b;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  `,
  socialMediaContainer: css`
    align-items: center;
    border: 1px solid #dee5ec;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    margin: 4px;
    padding: 8px;
    position: relative;

    & > img:nth-of-type(1) {
      margin-left: 8px;
    }

    & > img:nth-of-type(2) {
      border: 3px solid #fff;
      margin-left: -10px;
    }

    & > div:nth-of-type(2) {
      display: grid;
      margin-left: 8px;
      width: 53%;

      @media (max-width: ${ViewportType.SMALL}px) {
        width: 50%;
      }

      & > div:nth-of-type(1) {
        color: #27313b;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }

      & > div:nth-of-type(2) {
        color: #6e7c89;
        font-size: 12px;
      }
    }
  `,
  socialMediaOverlay: css`
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    height: 100%;
    justify-content: center;
    margin-left: -8px !important;
    position: absolute;
    top: 0;
    width: 100%;
  `,
  socialMediasContainer: css`
    display: flex;
    flex-wrap: wrap;
  `,
};

export default JoinButton;
