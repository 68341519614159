import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import { ListIndicator } from '@src/components/molecules/Indicator';
import Notice from '@src/components/molecules/Notice';
import { UNEXPECTED_ERROR } from '@src/libs/error';
import { useQueryHelper } from '@src/libs/hooks';
import { SocialMediaAccountType } from '../types';
import { useAnalyticsReconnectInstagram } from '../../Profile/ProfileConnectForm/useAnalyticsReconnectInstagram';
import {
  InfluencerAnalyticsInstagramAccessTokenWarning,
  InfluencerAnalyticsInstagramAccessTokenWarningVariables,
} from './__generated__/InfluencerAnalyticsInstagramAccessTokenWarning';
import * as INFLUENCER_ANALYTICS_ACCESS_TOKEN_WARNING from './InfluencerAnalyticsInstagramAccessTokenWarning.graphql';

export interface NeedAnalyticsAccountReconnectProps {
  selectedSocialMedia: SocialMediaAccountType;
}

const NeedAnalyticsAccountReconnect = (props: NeedAnalyticsAccountReconnectProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { selectedSocialMedia } = props;
  const { startAnalyticsReconnectInstagram, isLoading } = useAnalyticsReconnectInstagram({
    accountId: selectedSocialMedia.id,
  });

  const { data, loading, error } = useQuery<
    InfluencerAnalyticsInstagramAccessTokenWarning,
    InfluencerAnalyticsInstagramAccessTokenWarningVariables
  >(INFLUENCER_ANALYTICS_ACCESS_TOKEN_WARNING, {
    variables: {
      socialAccountId: selectedSocialMedia.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (loading || isLoading || error) {
    if (error) {
      const errorMessage = error.message || UNEXPECTED_ERROR;
      enqueueSnackbar(t(errorMessage), { variant: 'error' });
    }

    return <ListIndicator height={'80px'} css={{ marginBottom: '16px' }} />;
  }

  return data?.influencerAnalyticsInstagramAccessTokenWarning?.needReconnect ? (
    <Notification
      color="#ffb63d"
      title="Please reconnect"
      content={
        <>
          {t('Your Instagram account is disconnected')}&nbsp;
          <StyledP
            onClick={() =>
              startAnalyticsReconnectInstagram({
                reconnectStatus: data.influencerAnalyticsInstagramAccessTokenWarning,
              })
            }
          >
            {t('Please reconnect from here')}
          </StyledP>
        </>
      }
      icon="warning"
    />
  ) : null;
};

const Notification = styled(Notice)`
  margin: 0 0 16px 0;
`;

const StyledP = styled.p`
  display: inline;
  color: #179cd7;
  text-decoration: underline;
  transition: color 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    color: #1686b9;
  }
`;

export default NeedAnalyticsAccountReconnect;
