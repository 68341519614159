import { useQuery } from '@apollo/client';
import React from 'react';
import { useQueryHelper } from '@src/libs/hooks';
import { Period } from '@src/components/molecules/DayPicker/types';
import { ListIndicator } from '@src/components/molecules/Indicator';
import AudienceCardTemplate from '@src/components/organisms/AnalyticsShared/AudienceCardTemplate';
import * as INFLUENCER_FB_AUDIENCE_ANALYTICS from './InfluencerFacebookPageAnalyticsAudience.graphql';
import {
  InfluencerFacebookPageAnalyticsAudience,
  InfluencerFacebookPageAnalyticsAudienceVariables,
} from './__generated__/InfluencerFacebookPageAnalyticsAudience';

interface AudienceChartProps {
  period: Period;
  socialMediaAccountId: number;
  influencerAccountId?: number;
}
const AudienceChart = (props: AudienceChartProps) => {
  const { period, socialMediaAccountId, influencerAccountId } = props;
  const { t, enqueueSnackbar } = useQueryHelper();

  const { data, loading, error } = useQuery<
    InfluencerFacebookPageAnalyticsAudience,
    InfluencerFacebookPageAnalyticsAudienceVariables
  >(INFLUENCER_FB_AUDIENCE_ANALYTICS, {
    ...(!influencerAccountId
      ? { skip: true }
      : {
          variables: {
            socialAccountId: socialMediaAccountId,
            influencerId: influencerAccountId,
            startDate: period.startDate,
            endDate: period.endDate,
          },
        }),
  });

  if (loading) {
    return <ListIndicator />;
  }

  const stats = data?.influencerFacebookPageAnalyticsAudience;

  if (error) {
    enqueueSnackbar(t(error?.message || 'Can not get Facebook fans data for current account'), {
      variant: 'error',
    });

    return null;
  }

  return <AudienceCardTemplate {...stats} />;
};

export default AudienceChart;
