import React from 'react';
import AnalyticsContentWrapper, {
  ContentType,
  sectionStyles,
} from '@src/components/organisms/AnalyticsShared/AnalyticsContentWrapper';
import { Period } from '@src/components/molecules/DayPicker/types';
import { useQueryHelper } from '@src/libs/hooks';
import SentimentAnalysis from '@src/components/organisms/ProfileWidgets/SentimentAnalysis';
import { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import { PostAnalystics } from '../types';
import AverageEngagement from './AverageEngagement';
import FanActivity from './FanActivity';
import FeedPosts from './FeedPosts';

interface AnalyticsPostsProps extends PostAnalystics {
  socialMediaAccountId: number;
  period: Period;
  influencerAccountId?: number;
  avatarUrl?: string;
  accountName?: string;
}
const AnalyticsPosts = (props: AnalyticsPostsProps) => {
  const { t, search } = useQueryHelper();
  const {
    averageEngagement,
    sentimentAnalysis,
    fanActivity,
    posts,
    socialMediaAccountId,
    influencerAccountId,
    accountName,
    avatarUrl,
  } = props;

  return (
    <AnalyticsContentWrapper contentType={ContentType.Posts}>
      <div css={sectionStyles.responsiveWrapper}>
        {averageEngagement && <AverageEngagement {...averageEngagement} />}
        <SentimentAnalysis
          commentAnalytics={sentimentAnalysis}
          css={[sectionStyles.middleSection, sectionStyles.verticalContaner]}
        />
        {!!fanActivity && <FanActivity fanActivity={fanActivity} css={sectionStyles.verticalContaner} />}
      </div>
      <FeedPosts
        header={<SectionHeader>{t('Title.Feed Posts')}</SectionHeader>}
        socialMediaAccountId={socialMediaAccountId}
        posts={posts}
        moreLink={`/analytics/facebook_feed_post${search}`}
        influencerAccountId={influencerAccountId}
        accountName={accountName}
        avatarUrl={avatarUrl}
      />
    </AnalyticsContentWrapper>
  );
};

export default AnalyticsPosts;
