import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { getSocialMediaLabel } from '../../../libs/utils';
import { CampaignSocialMediaType } from '../../../__generated__/globalTypes';
import { ViewportBreakpoint } from '../../../libs/theme';
import { formatIntNumber } from '../../../libs/format';

interface EngagementStats {
  postUrl?: string | null;
  comments?: number;
  reach?: number;
  impressions?: number;
  exits?: number;
  tapsForward?: number;
  tapsBack?: number;
  /*   postDate?: string; */
}

const EngagementStats = (props: EngagementStats) => {
  const {
    postUrl,
    /*     postDate, */
    tapsForward = 0,
    tapsBack = 0,
    reach = 0,
    impressions = 0,
    exits = 0,
    comments = 0,
  } = props;
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Header>
        <Title>{t('Engagement')}</Title>
        {postUrl && (
          <Link href={postUrl} target="_blank" rel="noreferrer">
            {t(`View on ${getSocialMediaLabel(CampaignSocialMediaType.INSTAGRAM)}`)}
          </Link>
        )}
      </Header>
      <Stats>
        {/* <Stat>
          <Unit>{t('Post Date')}</Unit>
          <Value>{formatDate(postDate || '')}</Value>
        </Stat> */}
        <Stat>
          <Unit>{t('Comments')}</Unit>
          <Value>{formatIntNumber(comments || 0)}</Value>
        </Stat>
        <Stat>
          <Unit>{t('Reach')}</Unit>
          <Value>{formatIntNumber(reach || 0)}</Value>
        </Stat>
        <Stat>
          <Unit>{t('Imps')}</Unit>
          <Value>{formatIntNumber(impressions || 0)}</Value>
        </Stat>
        <Stat>
          <Unit>{t('Exits')}</Unit>
          <Value>{formatIntNumber(exits || 0)}</Value>
        </Stat>
        <Stat>
          <Unit>{t('Taps Forward')}</Unit>
          <Value>{formatIntNumber(tapsForward || 0)}</Value>
        </Stat>
        <Stat>
          <Unit>{t('Taps Back')}</Unit>
          <Value>{formatIntNumber(tapsBack || 0)}</Value>
        </Stat>
      </Stats>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Header = styled.div`
  padding: 16px 0;
  display: flex;
  justify-content: start;
  align-items: center;
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

const Link = styled.a`
  margin-left: 16px;
  color: #3892e5;
  font-size: 11px;
  font-weight: normal;

  &:hover {
    text-decoration: underline;
  }
`;

const Stats = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25%);
`;

const Stat = styled.div`
  padding: 0 8px;

  @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
    flex: 1;
  }
`;
const Value = styled.p`
  font-size: 13px;
  color: #27313b;
`;
const Unit = styled.p`
  font-size: 10px;
  line-height: 1.3;
  letter-spacing: normal;
  color: #6e7c89;
`;

export default EngagementStats;
