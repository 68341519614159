import { useState } from 'react';
import { useQueryHelper } from '@src/libs/hooks';
import { setUrlParams } from '@src/libs/url';
import { EmailEventStatus } from '@src/__generated__/globalTypes';

export interface FilterItems {
  keyword: string;
  status: EmailEventStatus;
}

const useFilter = () => {
  const { history, pathname, search } = useQueryHelper();
  const searchParams = new URLSearchParams(search);
  const initialFilter = {
    keyword: searchParams.get('keyword') || '',
    status: (searchParams.get('status') || '') as EmailEventStatus,
  };

  const [filter, setInitialFilter] = useState<FilterItems>(initialFilter);

  const setFilter = (values: FilterItems) => {
    setInitialFilter(values);
    setUrlParams(history, pathname, search, values);
  };

  return {
    filter,
    setFilter,
  };
};

export default useFilter;
