import React from 'react';
import { css } from '@emotion/core';
import { SliderComponents, StyledComponents, SliderCss } from '@src/components/molecules/SliderTable';
import { HeaderColumn, TextColumn } from '@src/components/atoms/List';
import { SortActionType } from '@src/libs/filter';
import { formatIntNumber } from '@src/libs/format';
import { ORDER, InstagramInteractionUserSortField } from '@src/__generated__/globalTypes';
import styled from '@emotion/styled';
import { EmptyTable } from '@src/components/molecules/Table';
import { getUserAnalysisColumns } from './helpers';
import { InfluencerInstagramInteractionPosts_influencerAllInstagramInteractionUsers_usernames } from './__generated__/InfluencerInstagramInteractionPosts';

interface UserAnalysisType extends SortActionType<InstagramInteractionUserSortField> {
  userAnalytics?: InfluencerInstagramInteractionPosts_influencerAllInstagramInteractionUsers_usernames[] | null;
  hasSort?: boolean;
  showSimplifiedTable?: boolean;
}
const UserAnalysisTable = (props: UserAnalysisType) => {
  const {
    userAnalytics,
    sort: { field, order },
    setSort,
    hasSort,
    showSimplifiedTable,
  } = props;

  if (userAnalytics?.length === 0) {
    return <EmptyTable colSpan={14} css={{ border: 'none' }} />;
  }

  return (
    <>
      <SliderComponents.Container>
        <SliderComponents.SliderSection>
          <SliderComponents.SliderTable>
            <thead>
              <SliderComponents.HeaderRow css={{ backgroundColor: '#f6f8fa' }}>
                <SliderComponents.StickyHeaderTdWrapper>
                  <StyledUsernameHeader title="User Name" showSimplifiedTable={showSimplifiedTable} isTable={false} />
                </SliderComponents.StickyHeaderTdWrapper>
                {/* Slider */}
                {getUserAnalysisColumns().map(({ title, sort }) => (
                  <HeaderColumn
                    key={title}
                    title={title}
                    {...(hasSort && {
                      activeAsc: field === sort && order === ORDER.ASC,
                      activeDesc: field === sort && order === ORDER.DESC,
                      sortAsc: () => setSort({ field: sort, order: ORDER.ASC }),
                      sortDesc: () => setSort({ field: sort, order: ORDER.DESC }),
                    })}
                    css={css`
                      text-transform: capitalize;
                    `}
                  />
                ))}
              </SliderComponents.HeaderRow>
            </thead>

            <tbody>
              {userAnalytics?.map((user, index) => {
                const {
                  username,
                  avgMentionedComments,
                  avgMentionedLikes,
                  avgTaggedComments,
                  avgTaggedLikes,
                  avgTotalComments,
                  avgTotalLikes,
                  mentionedPosts,
                  taggedPosts,
                  totalPostsCount,
                } = user;

                return (
                  <StyledComponents.StyledRowNew css={[SliderCss.paddingSides, styles.rowStyles]} key={index}>
                    <SliderComponents.StickyBodyRowWrapper>
                      <Anchor target="_blank" rel="noreferrer" href={`https://www.instagram.com/${username}`}>
                        {username}
                      </Anchor>
                    </SliderComponents.StickyBodyRowWrapper>
                    <StyledTextColumn data={formatIntNumber(totalPostsCount)} css={{ padding: '0 32px' }} />
                    <StyledTextColumn data={formatIntNumber(taggedPosts)} />
                    <StyledTextColumn data={formatIntNumber(mentionedPosts)} />
                    <StyledTextColumn data={formatIntNumber(avgTotalLikes)} />
                    <StyledTextColumn data={formatIntNumber(avgTaggedLikes)} />
                    <StyledTextColumn data={formatIntNumber(avgMentionedLikes)} />
                    <StyledTextColumn data={formatIntNumber(avgTotalComments)} />
                    <StyledTextColumn data={formatIntNumber(avgTaggedComments)} />
                    <StyledTextColumn data={formatIntNumber(avgMentionedComments)} />
                  </StyledComponents.StyledRowNew>
                );
              })}
            </tbody>
          </SliderComponents.SliderTable>
        </SliderComponents.SliderSection>
      </SliderComponents.Container>
    </>
  );
};

const styles = {
  rowStyles: css`
    height: 40px;
    border-bottom: 1px solid #dee5ec;
  `,
};

export const Anchor = styled.a`
  color: #3892e5;
  font-weight: normal;

  &:hover {
    text-decoration: underline;
  }
`;
const StyledTextColumn = styled(TextColumn)`
  padding-left: 16px !important;
`;
const StyledUsernameHeader = styled(HeaderColumn)<{ showSimplifiedTable?: boolean }>`
  width: ${({ showSimplifiedTable }) => (showSimplifiedTable ? '105px' : '230px')};
  padding: 0;

  & > div {
    justify-content: flex-start;
  }
`;

export default UserAnalysisTable;
