import React, { useState } from 'react';
import { Redirect } from 'react-router';
import styled from '@emotion/styled';
import { Period } from '@src/components/molecules/DayPicker/types';
import { getThirtyDaysAgo, getToday } from '@src/libs/date';
import { useAuthData, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { ListIndicator } from '@src/components/molecules/Indicator';
import MobileDaysRangeSelector from '@src/components/molecules/MobileDaysRangeSelector';
import { ViewportType } from '@src/libs/theme';
import { getLocationFromSearch } from '@src/libs/paging';
import { InfluencerType, SocialAccountType } from '@src/__generated__/globalTypes';
import { TabWrapper, NavTab } from '../AnalyticsYoutube';
import DefaultProfile from './DefaultProfile';
import AnalyticsProfile from './AnalyticsProfile';
import NeedAnalyticsAccountReconnect from './NeedAnalyticsAccountReconnect';
import { SocialMediaAccountType } from './types';
import TopMenuBar from './TopMenuBar';

interface ProfileDetailProps {
  availableSocialMediaAccounts: SocialMediaAccountType[];
}

const ProfileDetail = (props: ProfileDetailProps) => {
  const { availableSocialMediaAccounts } = props;

  const { userId, creatorType } = useAuthData();
  const { search, history, t, pathname } = useQueryHelper();
  // when we change view and dateRange selector from Top Bar dissapear
  const { isMobileView } = usePageLayout();

  if (!userId) {
    return <Redirect to="/home" />;
  }

  const searchParams = new URLSearchParams(search);

  const [id, sm, stda, enda] = [
    searchParams.get('id') as string,
    searchParams.get('sm') as string,
    searchParams.get('stda') as string,
    searchParams.get('enda') as string,
  ];

  React.useEffect(() => {
    const initialSocialMedia =
      (id &&
        sm &&
        availableSocialMediaAccounts.find(social => id === social.id.toString() && sm === social.socialAccountType)) ||
      availableSocialMediaAccounts[0];
    setSelectedSocialMedia(initialSocialMedia);
  }, [search]);

  const [selectedSocialMedia, setSelectedSocialMedia] = useState<SocialMediaAccountType>();
  const [selectedPeriod, setPeriod] = useState<Period>({
    startDate: stda || getThirtyDaysAgo(),
    endDate: enda || getToday(),
  });

  const handlePeriodChange = (period: Period) => {
    setPeriod(period);
    const urlParams = getLocationFromSearch(pathname, search, {
      stda: period.startDate || '',
      enda: period.endDate || '',
    });
    history.replace({ search: urlParams.search });
  };

  const onChangeSocialMedia = (social: SocialMediaAccountType) => {
    setSelectedSocialMedia(social);
    const queryParams = new URLSearchParams(search);
    queryParams.set('id', social.id.toString());
    queryParams.set('sm', social.socialAccountType.toString());

    history.push(`${pathname}?${queryParams}`);
  };

  const isTwitter = selectedSocialMedia?.socialAccountType === SocialAccountType.TWITTER;
  // we don't have Analytics UI for Twitter just to be sure still show profile_v2 if analyticsEnabled is "true" for some reasons;
  const enabledAnalytics = selectedSocialMedia?.analyticsEnabled && !isTwitter;
  const isYouTubeAccount = selectedSocialMedia?.socialAccountType === SocialAccountType.YOUTUBE;
  const isInstagramAccount = selectedSocialMedia?.socialAccountType === SocialAccountType.INSTAGRAM;
  const showAnalyticsWarning = enabledAnalytics && isInstagramAccount;

  if (!selectedSocialMedia) {
    return <ListIndicator />;
  }

  return (
    <>
      <TopMenuBar
        availableSocialMediaAccounts={availableSocialMediaAccounts}
        selectedSocialMedia={selectedSocialMedia}
        onChangeSocialMedia={v => onChangeSocialMedia(v)}
        withPeriod={!!enabledAnalytics}
        onPeriodChange={handlePeriodChange}
        initialPeriod={selectedPeriod}
      />
      {enabledAnalytics ? (
        <>
          {isYouTubeAccount ? (
            <TabWrapper>
              <NavTab activeClassName="selected" exact to={`/analytics${search}`}>
                {t('Dashboard')}
              </NavTab>
              {creatorType === InfluencerType.PRO ? (
                <NavTab activeClassName="selected" exact to={`/analytics:compare${search}`}>
                  {t('Compare')}
                </NavTab>
              ) : null}
            </TabWrapper>
          ) : null}
          {isInstagramAccount ? (
            <TabWrapper>
              <NavTab activeClassName="selected" exact to={`/analytics${search}`}>
                {t('Dashboard')}
              </NavTab>
              <NavTab activeClassName="selected" exact to={`/analytics:ig_interaction${search}`}>
                {t('Interaction')}
              </NavTab>
            </TabWrapper>
          ) : null}
          <div css={{ margin: '24px' }}>
            {isMobileView ? (
              <MobileDaysRangeSelector
                initialPeriod={selectedPeriod}
                onRangeChange={handlePeriodChange}
                css={{ marginBottom: '16px' }}
              />
            ) : null}
            {showAnalyticsWarning && (
              <WarningContainer>
                <NeedAnalyticsAccountReconnect selectedSocialMedia={selectedSocialMedia} />
              </WarningContainer>
            )}
            <AnalyticsProfile
              selectedPeriod={selectedPeriod}
              userId={userId}
              selectedSocialMedia={selectedSocialMedia}
            />
          </div>
        </>
      ) : (
        <div css={{ margin: '24px' }}>
          {showAnalyticsWarning && (
            <WarningContainer>
              <NeedAnalyticsAccountReconnect selectedSocialMedia={selectedSocialMedia} />
            </WarningContainer>
          )}
          <DefaultProfile userId={userId} selectedSocialMedia={selectedSocialMedia} />
        </div>
      )}
    </>
  );
};

const WarningContainer = styled.div`
  display: flex;

  & > div {
    flex-basis: 70%;

    @media (max-width: ${ViewportType.TABLET}px) {
      flex-basis: 100%;
    }
  }
`;

export default ProfileDetail;
