import React from 'react';
import { css } from '@emotion/react';
import { ApolloError } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useMediaQuery } from 'react-responsive';
import { Formik, FormikHelpers } from 'formik';
import { FanSchema } from '@src/libs/validation';
import { useQueryHelper, useUpdateDocumentTitle } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import AddFanForm from './AddFanForm';
import { FormValues } from './types';
import * as ADD_FAN from './AddFan.graphql';
import { AddFan, AddFanVariables } from './__generated__/AddFan';

const AddFanComponent = () => {
  useUpdateDocumentTitle({ title: 'pageTitle.Add Fan' });
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });
  const { enqueueSnackbar, t, history } = useQueryHelper();
  const [addFanMutation] = useMutation<AddFan, AddFanVariables>(ADD_FAN);

  const handleSubmit = async (payload: FormValues, { setSubmitting, setFieldError }: FormikHelpers<FormValues>) => {
    setSubmitting(true);

    await addFanMutation({
      variables: {
        input: payload,
      },
    })
      .then(result => {
        if (result?.data?.addFan?.ok) {
          enqueueSnackbar(t('succeededInCreating'), { variant: 'success' });
          history.push('/fans');
        } else {
          const failureMessage = 'failedToCreate';
          enqueueSnackbar(t(failureMessage), { variant: 'error' });
        }
      })
      .catch((e: ApolloError) => {
        const { message } = e.graphQLErrors[0];
        if (message === 'fan already exists') {
          setFieldError('email', 'Fan already exists');
        }
        enqueueSnackbar(t(message || 'failedToCreate'), { variant: 'error' });
      });

    setSubmitting(false);
  };

  return (
    <div css={styles.container}>
      {isDesktopView && <BackNavigator to="/fans" title="Add Fan" />}

      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          contactNumber: '',
        }}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={FanSchema}
      >
        <AddFanForm />
      </Formik>
    </div>
  );
};

const styles = {
  container: css`
    margin: 16px 24px;
  `,
};

export default AddFanComponent;
