import React, { useMemo } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import anyCreatorLogo from '@src/assets/icon/anyCreatorBlack.svg';
import picIcon from '@src/assets/icon/chat/pic.svg';
import hamburgerIcon from '@src/assets/icon/chat/hamburger.svg';
import { Icon } from '@src/components/atoms/Icon';
import TutorialTool, { TutorialTypes } from '@src/components/organisms/TutorialTool';
import { useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import { dynamicTitleState, selectedChatState, useRecoilState } from '@src/recoilAtoms';
import TextCutter from '@src/components/atoms/TextCutter';

interface MobileToolbarProps {
  backPath?: string;
  toolbarTitle?: string;
  onClickMenu?: () => void;
}

const MobileToolbar = ({ backPath, toolbarTitle, onClickMenu }: MobileToolbarProps) => {
  const { history, params, pathname, t, search, path } = useQueryHelper();
  const redirectPath = backPath ? generatePath(backPath, params as any) + search : '';
  const isActiveChatPath = path === '/chat/:activeChat?';
  const isDynamicTitle = toolbarTitle === '_dynamic'; // if need to pass another _dynamic title - we can make recoil atom for this
  const [{ pic, fanId }, setSelectedChartState] = useRecoilState(selectedChatState);
  const [{ title, interpolation }] = useRecoilState(dynamicTitleState);

  const tutorialType = useMemo(() => {
    switch (pathname) {
      case '/casting/your_job':
        return TutorialTypes.CASTING;
      case '/marketplace/search_job':
        return TutorialTypes.MARKETPLACE_SEARCH_JOB;
      case '/payment':
        return TutorialTypes.PAYMENT;

      default:
        return '';
    }
  }, [pathname]);

  return (
    <>
      <div css={[styles.toolbar, isActiveChatPath ? styles.toolbarFixed : '']}>
        {backPath ? (
          <IconContainer onClick={() => history.push(redirectPath)}>
            <Icon color="#27313b" icon="chevron_left" />
          </IconContainer>
        ) : (
          <IconContainer onClick={onClickMenu}>
            <Icon color="#27313b" icon="menu" />
          </IconContainer>
        )}
        <div css={styles.logoContainer}>
          {toolbarTitle ? (
            <TextCutter
              css={styles.title}
              text={!isDynamicTitle ? t(`MenuTitle.${toolbarTitle}`) : t(title, { ...interpolation })}
              lines={1}
            />
          ) : (
            <Link to="/home">
              <img alt="logo" height="32" src={anyCreatorLogo} />
            </Link>
          )}
        </div>

        {tutorialType && (
          <div css={styles.hintContainer}>
            <TutorialTool type={tutorialType} />
          </div>
        )}
        {isActiveChatPath ? (
          <div css={styles.chatMenu}>
            <img
              src={picIcon}
              alt="PIC"
              css={[styles.chatMenuItem, !pic ? styles.greyscaleIcon : '']}
              onClick={() =>
                setSelectedChartState(prevState => ({
                  ...prevState,
                  isChartStateMenuOpen: !prevState.isChartStateMenuOpen,
                }))
              }
            />
            <Link to={fanId ? `${pathname}/${fanId}` : '#'}>
              <img src={hamburgerIcon} alt="customer details" css={styles.chatMenuItem} />
            </Link>
          </div>
        ) : null}
      </div>
    </>
  );
};

const IconContainer = styled.div`
  display: flex;
  flex-basis: 10%;
  justify-content: center;

  & i {
    font-size: 25px;
  }

  @media (max-width: ${ViewportType.SMALL}px) {
    flex-basis: 15%;
  }
`;

const styles = {
  hintContainer: css`
    display: flex;
    flex-basis: 10%;
    justify-content: center;

    @media (max-width: ${ViewportType.SMALL}px) {
      flex-basis: 15%;
    }
  `,
  logoContainer: css`
    display: flex;
    flex-basis: 80%;
    justify-content: center;

    @media (max-width: ${ViewportType.SMALL}px) {
      flex-basis: 70%;
    }
  `,
  title: css`
    color: #27313b;
    font-size: 18px;
    font-weight: 600;
  `,
  toolbar: css`
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #e5ebf1;
    box-shadow: 0 1px 2px 0 #dee5ec;
    display: flex;
    height: 56px;
    width: 100%;
  `,
  toolbarFixed: css`
    position: absolute;
    top: 0;
    z-index: 100;
  `,
  chatMenu: css`
    display: flex;
    column-gap: 8px;
    padding: 0 16px;
  `,
  chatMenuItem: css`
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  `,
  greyscaleIcon: css`
    filter: grayscale(1);
  `,
};

export default MobileToolbar;
