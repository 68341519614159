import React from 'react';
import { useQuery } from '@apollo/client';
import { Period } from '@src/components/molecules/DayPicker/types';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { useQueryHelper } from '@src/libs/hooks';
import { AvatarAndNameType } from '../OverView/types';
import {
  InfluencerYoutubeComparePosts,
  InfluencerYoutubeComparePostsVariables,
} from './__generated__/InfluencerYoutubeComparePosts';
import Template from './template';
import * as YT_COMPARE_POSTS from './InfluencerYoutubeComparePosts.graphql';

interface AnalyticsComparePostsType {
  accountsAvatarAndName: AvatarAndNameType[];
  visibleAccountsIds: number[];
  isMainAccountVisible: boolean;
  period: Period;
  id: number;
}
const AnalyticsComparePosts = ({
  period,
  id,
  accountsAvatarAndName,
  visibleAccountsIds,
  isMainAccountVisible,
}: AnalyticsComparePostsType) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data, loading } = useQuery<InfluencerYoutubeComparePosts, InfluencerYoutubeComparePostsVariables>(
    YT_COMPARE_POSTS,
    {
      variables: { socialAccountId: id, ...period },
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
    }
  );

  if (loading) {
    return <ListIndicator css={{ margin: 0 }} />;
  }
  const comparedPostsData = data?.influencerYoutubeComparePosts;

  if (!comparedPostsData) {
    return null;
  }

  const combinedAccounts = [
    ...(isMainAccountVisible ? [comparedPostsData.mainAccount] : []),
    ...comparedPostsData.compareAccounts.filter(acc => visibleAccountsIds.includes(acc.accountId)),
  ];
  const accountsWithAvatar = accountsAvatarAndName.map((acc, i) => ({ ...acc, ...combinedAccounts[i] }));

  return <Template accountsWithAvatar={accountsWithAvatar} id={id} />;
};

export default AnalyticsComparePosts;
