import styled from '@emotion/styled';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import css from '@emotion/css';
import { useHistory, useParams } from 'react-router';
import TopPageFooter from '@src/components/organisms/TopPage/Footer';
import TopPageHeader from '@src/components/organisms/TopPage/Header';
import { useUpdateDocumentTitle } from '@src/libs/hooks';
import { SignUpAndSignInBreakpoint } from '@src/libs/theme';
import PasswordForm from '@src/components/organisms/PasswordForm';

const Template = () => {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const isTabletView = useMediaQuery({ query: `(max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px)` });

  useUpdateDocumentTitle({ title: 'pageTitle.Login', pageTitle: 'Login' });

  if (!token) {
    history.push('/');

    return null;
  }

  return (
    <Wrapper>
      <Container>
        <Header href="/" />
        <ContentWrapper>
          <PasswordForm isPasswordReset token={token} />
        </ContentWrapper>
        {!isTabletView && <TopPageFooter css={styles.footer} />}
      </Container>
    </Wrapper>
  );
};

const Header = styled(TopPageHeader)`
  padding-top: 16px;
`;
const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    background-color: #fff;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 856px;
  align-items: center;
`;
const ContentWrapper = styled.section`
  box-shadow: 0 1px 4px 0 #dee5ec, 0 2px 5px 0 #dee5ec;
  width: 100%;
  padding: 48px;
  background-color: #fff;
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    border-radius: 0;
    box-shadow: none;
    padding: 32px 48px 32px;
  }
`;

const styles = {
  footer: css`
    margin-top: 0;
    width: 100%;
  `,
};

export default Template;
