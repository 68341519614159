import i18n from 'i18next';
import React from 'react';
import styled from '@emotion/styled';
import signInBgCover from '@src/assets/img/signInBgCover.png';
import signInCoverDescription from '@src/assets/img/signInCoverDescription.png';
import signInCoverDescriptionJP from '@src/assets/img/signInCoverDescriptionJP.png';
import signUpBgCover from '@src/assets/img/signUpBgCover.png';
import signUpCoverCount from '@src/assets/img/signUpCoverCount.png';
import signUpCoverDescription from '@src/assets/img/signUpCoverDescription.png';
import signUpCoverDescriptionJP from '@src/assets/img/signUpCoverDescriptionJP.png';

interface BackgroundCoverProps {
  isSignIn?: boolean;
}

const BackgroundCover = ({ isSignIn }: BackgroundCoverProps) => {
  const isJPLanguage = i18n.language === 'ja';

  return isSignIn ? (
    <Container>
      <ImageContainer marginBottom={8}>
        <img alt="description" height="38" src={isJPLanguage ? signInCoverDescriptionJP : signInCoverDescription} />
      </ImageContainer>
      <ImageContainer>
        <img alt="cover" height="198" src={signInBgCover} />
      </ImageContainer>
    </Container>
  ) : (
    <Container>
      <ImageContainer marginBottom={8}>
        <img
          alt="description"
          height={isJPLanguage ? 40 : 57}
          src={isJPLanguage ? signUpCoverDescriptionJP : signUpCoverDescription}
          width={isJPLanguage ? 250 : 311}
        />
      </ImageContainer>
      <ImageContainer marginBottom={-32}>
        <img alt="count" height="58" src={signUpCoverCount} />
      </ImageContainer>
      <ImageContainer>
        <img alt="cover" height="207" src={signUpBgCover} />
      </ImageContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 240px;
`;

const ImageContainer = styled.div<{ marginBottom?: number }>`
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  ${({ marginBottom }) => (marginBottom ? `margin-bottom: ${marginBottom}px` : '')};
`;

export default BackgroundCover;
