import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import offlineIcon from '@src/assets/icon/offline.svg';

const defaultWrappetText = 'The system is collecting data, please wait until the data is ready';

interface EnrichedChildren {
  type: {
    displayName: string;
  };
}
interface BlurredBlockProps {
  children: React.ReactNode;
  isAvailableRate?: boolean;
  wrapperText?: string;
}

const BlurredBlock = ({ children, isAvailableRate, wrapperText = defaultWrappetText }: BlurredBlockProps) => {
  const { t } = useTranslation();

  return (
    <>
      <FocusedBlock>
        <FocusedContainer>
          <img src={offlineIcon} />
          <OfflineText>{t(wrapperText)}</OfflineText>
        </FocusedContainer>
      </FocusedBlock>

      {React.Children.map(children, child => {
        let blurRadius = '5px';
        const elementChild = child as EnrichedChildren;

        if (elementChild?.type?.displayName === 'LegendBar' && isAvailableRate) {
          blurRadius = '0';
        }

        return <OfflineBlock blurRadius={blurRadius}>{child}</OfflineBlock>;
      })}
    </>
  );
};

const OfflineText = styled.p`
  font-size: 13px;
  color: #27313b;
  margin-top: 8px;
`;
const FocusedBlock = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
`;

const FocusedContainer = styled.div`
  z-index: 5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const OfflineBlock = styled.div<{ blurRadius: string }>`
  filter: ${({ blurRadius }) => `blur(${blurRadius})`};
`;
export default BlurredBlock;
