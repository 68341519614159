import styled from '@emotion/styled';
import React from 'react';
import PostDetail from '@src/components/organisms/AnalyticsShared/PostDetail';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { VideoDetail } from './types';

export interface TemplateProps {
  video: VideoDetail | null;
  accountType?: SocialAccountType;
  avatarUrl?: string | null;
  accountName?: string;
  loading?: boolean;
}

const Template = (props: TemplateProps) => {
  const { video, avatarUrl, accountName } = props;
  if (!video) {
    return null;
  }
  const videoUrl = `https://www.youtube.com/watch?v=${video.videoId}`;
  const accountType = SocialAccountType.YOUTUBE;

  const { videoCaption, videoComments, videoTags, videoTitle, postDate, subscribers, ...rest } = video;

  return (
    <PostDetail
      avatarUrl={avatarUrl || null}
      accountType={accountType}
      accountName={accountName || ''}
      followers={subscribers}
      content={videoCaption}
      commentList={videoComments || []}
      mediaUrls={null}
      videoUrl={videoUrl}
      videoTags={videoTags}
      videoTitle={videoTitle}
      videoPostDate={postDate}
      engagement={{
        ...rest,
      }}
    />
  );
};

export const ReactPlayerWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 225px;
  margin-bottom: 8px;
`;

export default Template;
