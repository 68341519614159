import React from 'react';
import TextForm from '@src/components/molecules/TextForm';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { useField, useFormikContext } from 'formik';
import { SubmitButton } from '@src/components/atoms/Button';
import * as StyledComponents from '../StyledComponents';
import { FormValues } from './types';

const AddressInformationForm = () => {
  const { t } = useTranslation();

  const { isSubmitting, handleSubmit } = useFormikContext<FormValues>();

  const [fullNameField, fullNameMeta] = useField<FormValues['fullName']>('fullName');
  const [postalCodeField, postalCodeMeta] = useField<FormValues['postalCode']>('postalCode');
  const [provinceField, provinceMeta] = useField<FormValues['province']>('province');
  const [cityField, cityMeta] = useField<FormValues['city']>('city');
  const [addressField, addressMeta] = useField<FormValues['address']>('address');
  const [additionalAddressField, additionalAddressMeta] = useField<FormValues['address2']>('address2');

  return (
    <form onSubmit={handleSubmit}>
      <StyledComponents.ResponsiveWrapper>
        <StyledComponents.FormContainer>
          <StyledComponents.Wrapper>
            <TextForm
              title="Full Name (Please enter your real name)"
              isRequired={true}
              error={!!fullNameMeta.error}
              placeholder={t('Full Name')}
              {...fullNameField}
            />
            {fullNameMeta.error && <ErrorMessage message={t(fullNameMeta.error)} />}
          </StyledComponents.Wrapper>
          <StyledComponents.Wrapper>
            <TextForm
              title="Postal code"
              isRequired={true}
              error={!!postalCodeMeta.error}
              placeholder={t('Postal code')}
              {...postalCodeField}
            />
            {postalCodeMeta.error && <ErrorMessage message={t(postalCodeMeta.error)} />}
          </StyledComponents.Wrapper>
          <StyledComponents.Wrapper>
            <TextForm
              title="Province"
              isRequired={true}
              error={!!provinceMeta.error}
              placeholder={t('Province')}
              {...provinceField}
            />
            {provinceMeta.error && <ErrorMessage message={t(provinceMeta.error)} />}
          </StyledComponents.Wrapper>
          <StyledComponents.Wrapper>
            <TextForm title="City" isRequired={true} error={!!cityMeta.error} placeholder={t('City')} {...cityField} />
            {cityMeta.error && <ErrorMessage message={t(cityMeta.error)} />}
          </StyledComponents.Wrapper>
          <StyledComponents.Wrapper>
            <TextForm
              title="Address"
              isRequired={true}
              error={!!addressMeta.error}
              placeholder={t('Address')}
              {...addressField}
            />
            {addressMeta.error && <ErrorMessage message={t(addressMeta.error)} />}
          </StyledComponents.Wrapper>
          <StyledComponents.Wrapper>
            <TextForm
              title="Apartment, suite, etc"
              isRequired={true}
              error={!!additionalAddressMeta.error}
              placeholder={t('Apartment, suite, etc')}
              {...additionalAddressField}
            />
            {additionalAddressMeta.error && <ErrorMessage message={t(additionalAddressMeta.error)} />}
          </StyledComponents.Wrapper>
          <StyledComponents.SubmitWrapper>
            <SubmitButton value="Save" isSubmitting={isSubmitting} />
          </StyledComponents.SubmitWrapper>
        </StyledComponents.FormContainer>
      </StyledComponents.ResponsiveWrapper>
    </form>
  );
};

export default AddressInformationForm;
