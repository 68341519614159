import * as React from 'react';
import Thanks from '../../components/organisms/Help/Thanks';
import { useUpdateDocumentTitle } from '../../libs/hooks';
import NavigationHeader from '../../components/organisms/Header/NavigationHeader';

const Template = () => {
  const title = 'pageTitle.Thanks';
  const pageTitle = 'Thanks';
  const href = '/contact';
  useUpdateDocumentTitle({ title, pageTitle, href });

  return (
    <>
      <NavigationHeader />
      <Thanks />
    </>
  );
};

export default Template;
