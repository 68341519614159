import React from 'react';
import styled from '@emotion/styled';
// import { DefaultCss, DefaultVariables } from '@src/styles';
import { defaultInfluencerAvatar } from '@src/libs/image';
import { SliderComponents, SliderCss, StyledComponents } from '@src/components/molecules/SliderTable';
import { HeaderColumn } from '@src/components/atoms/List';
import { TableGrowthSection } from './GrowthSectionDesktop';
import { EngagementAccountDataType } from './types';

const ComparableAccountsTableDesktop = ({ engagement }: { engagement: EngagementAccountDataType[] }) => (
  <SliderComponents.Wrapper css={{ margin: 0, padding: 0, border: 0 }}>
    <SliderComponents.Container>
      <SliderComponents.SliderSection>
        <SliderComponents.SliderTable>
          <thead>
            <SliderComponents.HeaderRow css={{ backgroundColor: '#f6f8fa' }}>
              <SliderComponents.StickyHeaderRowWrapper css={{ padding: 0 }}>
                <StyledHeader title="Account" isTable={false} />
              </SliderComponents.StickyHeaderRowWrapper>
              <StyledHeader title="Subscribers (Total)" />
              <StyledHeader title="Posts (Total)" help="comparePostsTotal" />
              <StyledHeader title="Avg Views" />
              <StyledHeader title="Maximum Views" />
              <StyledHeader title="Avg Likes" />
              <StyledHeader title="Maximum Likes" />
              <StyledHeader title="Avg Comments" />
            </SliderComponents.HeaderRow>
          </thead>

          <tbody>
            {engagement.map((item, index) => {
              const {
                avatar,
                name,
                subscribers,
                posts,
                avgLikes,
                maxLikes,
                avgComments,
                avgViews,
                maxViews,
                color,
              } = item;

              return (
                <StyledComponents.StyledRowNew css={[SliderCss.paddingSides]} key={index}>
                  <SliderComponents.StickyBodyRowWrapper>
                    <SliderComponents.ThumbnailCell
                      src={defaultInfluencerAvatar(avatar)}
                      title={name}
                      role="div"
                      imageWidth="42px"
                      css={{ padding: 0 }}
                    >
                      <ColorBox color={color as string} />
                    </SliderComponents.ThumbnailCell>
                  </SliderComponents.StickyBodyRowWrapper>
                  <TableGrowthSection {...subscribers} />
                  <TableGrowthSection {...posts} />
                  <TableGrowthSection {...avgViews} />
                  <TableGrowthSection {...maxViews} />
                  <TableGrowthSection {...avgLikes} />
                  <TableGrowthSection {...maxLikes} />
                  <TableGrowthSection {...avgComments} />
                </StyledComponents.StyledRowNew>
              );
            })}
          </tbody>
        </SliderComponents.SliderTable>
      </SliderComponents.SliderSection>
    </SliderComponents.Container>
  </SliderComponents.Wrapper>
);

const StyledHeader = styled(HeaderColumn)`
  & > div {
    justify-content: flex-start;
  }
`;
const ColorBox = styled.div<{ color: string }>`
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background-color: ${({ color }) => color};
`;

export default ComparableAccountsTableDesktop;
