import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogModal } from '../../../molecules/DialogModal';
import { PalleteButton } from '../../../atoms/Button';
import { PalleteColorType } from '../../../../libs/pallete';
import useTutorialData from '../../../../libs/tutorial/useTutorialData';
import { TutorialName } from '../../../../__generated__/globalTypes';

interface TutorialStepButton {
  text: string;
  palleteColor: PalleteColorType;
  borderColor?: string;
}

export interface TutorialSteps {
  titleIcon: string;
  title: string;
  img: string;
  heading: string;
  text: string;
  backButton: TutorialStepButton;
  nextButton: TutorialStepButton;
}

interface TutorialProps {
  name: TutorialName;
  steps: TutorialSteps[];
}

const BaseTutorial = (props: TutorialProps) => {
  const { name, steps } = props;
  const { checkShouldDisplayTutorial, setReadTutorial } = useTutorialData();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(checkShouldDisplayTutorial(name));
  const handleClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!isModalOpen) {
      setReadTutorial(name);
    }
  }, [isModalOpen]);

  const [active, setActive] = useState<number>(0);

  const { t } = useTranslation();

  const handleNext = () => setActive(active + 1);
  const handlePrev = () => setActive(active - 1);

  // Scroll to Top
  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView();
    }
  }, [active]);

  return !steps.length ? null : (
    <DialogModal
      isOpen={isModalOpen}
      closeModal={handleClose}
      BackButton={
        <PalleteButton
          text={steps[active].backButton.text}
          palleteColor={steps[active].backButton.palleteColor}
          onClick={active === 0 ? handleClose : handlePrev}
        />
      }
      NextButton={
        <PalleteButton
          text={steps[active].nextButton.text}
          palleteColor={steps[active].nextButton.palleteColor}
          onClick={active === steps.length - 1 ? handleClose : handleNext}
        />
      }
      isActionsBorderTop={false}
      stepsIndicator={{
        length: steps.length,
        active,
      }}
      contentRef={contentRef}
    >
      <ModalContentWrapper>
        <ModalTitle>
          <ModalIcon src={steps[active].titleIcon} /> {t(`Tutorial.Title.${steps[active].title}`)}
        </ModalTitle>
        <ModalImg src={steps[active].img} />
        <ModalHeading>{t(`Tutorial.Heading.${steps[active].heading}`)}</ModalHeading>
        <ModalText>{t(`Tutorial.Text.${steps[active].text}`)}</ModalText>
      </ModalContentWrapper>
    </DialogModal>
  );
};

const ModalContentWrapper = styled.div`
  color: #27313b;
  padding: 24px 8px 0;
`;

const ModalIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  object-fit: contain;
`;

const ModalTitle = styled.h1`
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ff2b52;
  display: flex;
`;

const ModalImg = styled.img`
  object-fit: contain;
  width: 100%;
  margin: 10px 0;
`;

const ModalHeading = styled.h3`
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #27313b;
`;

const ModalText = styled.div`
  min-height: 104px;
  margin-top: 8px;
  font-size: 13px;
  line-height: 1.38;
  letter-spacing: normal;
  color: #27313b;
`;

export default BaseTutorial;
