import React, { useState, useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import { css } from '@emotion/core';
import SearchForm from '@src/components/molecules/SearchForm';
import sortIcon from '@src/assets/icon/sort.svg';
import importIcon from '@src/assets/icon/import.svg';
import exportIcon from '@src/assets/icon/export.svg';
import filterIcon from '@src/assets/icon/filter.svg';
import filterActiveIcon from '@src/assets/icon/filterActive.svg';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useQueryHelper, useGlobalLayoutProps } from '@src/libs/hooks';
import { Button, SubmitButton } from '@src/components/atoms/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import { CheckBox } from '@src/components/atoms/CheckBox';
import TextForm from '@src/components/molecules/TextForm';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import accordionIcon from '@src/assets/icon/accordionIcon.svg';
import Drawer from '@material-ui/core/Drawer';
import { PalleteButton } from '@src/components/atoms/Button';
import { makeStyles } from '@material-ui/styles';
import { FilterByDateRange } from '@src/__generated__/globalTypes';
import MultiTagsSearchSelector from '@src/components/molecules/MultiTagsSearchSelector';
import { useApolloClient } from '@apollo/client';
import { ViewportType } from '@src/libs/theme';
import useFanList from '../useFanList';
import { FanListFilterType } from '../types';
import DateOptionGroup from './DateOptionGroup';
import { FormValues } from './types';
import useStateHandler from './useStateHandler';
import ImportFansModal from './ImportFansModal';
import * as GET_CREATOR_TAGS_BY_ID from './GetCreatorTagsById.graphql';
import { GetCreatorTagsById, GetCreatorTagsByIdVariables } from './__generated__/GetCreatorTagsById';
import { getTagsIdFromURLString, getFanFilterCount } from './helper';

const useStyles = makeStyles({
  root: {
    padding: '0 16px 0',
  },
  rounded: {
    borderRadius: '0 !important',
    boxShadow: 'none',
    borderBottom: '1px solid #EEF3F7',
  },
  expanded: {
    paddingLeft: 16,
    margin: '0 !important',
  },
});

interface FanListFilterProps {
  filter: FanListFilterType;
  onSort: () => void;
  selectedFansId: Set<number>;
}

const dateGroupOptions = [
  { label: 'Last 7 days', value: FilterByDateRange.LAST_7_DAYS },
  { label: 'Last 30 days', value: FilterByDateRange.LAST_30_DAYS },
  { label: 'Last 90 days', value: FilterByDateRange.LAST_90_DAYS },
  { label: 'Last 12 months', value: FilterByDateRange.LAST_12_MONTHS },
];

const FanListFilter = (props: FanListFilterProps) => {
  const { t } = useQueryHelper();
  const client = useApolloClient();
  const { updateGlobalLayout } = useGlobalLayoutProps();
  const { selectedFansId, filter, onSort } = props;
  const [openDrawer, setOpenDrawer] = useState(false);
  const classes = useStyles();
  const { isSubmitting, handleSubmit, setFieldValue, handleBlur, handleChange } = useFormikContext<FormValues>();
  const { exportFanCSV, uploadSelectedFile } = useFanList();
  const [keywordField] = useField<FormValues['keyword']>('keyword');
  const [createdDateField] = useField<FormValues['createdDate']>('createdDate');
  const [lastOrderDateField] = useField<FormValues['lastOrderDate']>('lastOrderDate');
  const [checkedFields] = useField<FormValues['checkedFields']>('checkedFields');
  const [tagsField] = useField<FormValues['tags']>('tags');
  // Amount Spent
  const [minAmountSpentField] = useField<FormValues['minAmountSpent']>('minAmountSpent');
  const [maxAmountSpentField] = useField<FormValues['maxAmountSpent']>('maxAmountSpent');
  const [exactAmountSpentField] = useField<FormValues['exactAmountSpent']>('exactAmountSpent');
  // Number of Order
  const [minNumberOfOrdersField] = useField<FormValues['minNumberOfOrders']>('minNumberOfOrders');
  const [maxNumberOfOrdersField] = useField<FormValues['maxNumberOfOrders']>('maxNumberOfOrders');
  const [exactNumberOfOrdersField] = useField<FormValues['exactNumberOfOrders']>('exactNumberOfOrders');

  const setSelectedTags = async (tagIds: number[]) => {
    const { data } = await client.query<GetCreatorTagsById, GetCreatorTagsByIdVariables>({
      query: GET_CREATOR_TAGS_BY_ID,
      variables: { input: { tagIds } },
    });

    setFieldValue(
      'tags',
      data.getCreatorTagsById?.tags.map(item => ({
        label: item.tag,
        value: item.id.toString(),
      })) || []
    );
  };

  useEffect(() => {
    const appliedTagIds = getTagsIdFromURLString(filter.tags);
    if (appliedTagIds.length) {
      setSelectedTags(appliedTagIds);
    } else {
      setFieldValue('tags', []);
    }
  }, [filter.tags]);

  const {
    donwloadMenu,
    openDownloadMenu,
    closeDownloadMenu,
    showImportModal,
    closeImportModal,
    onClickImport,
  } = useStateHandler();

  const handleBlurAndSubmit = (e: any) => {
    handleBlur(e);
    handleSubmit(e);
  };

  const handleClickImport = async (file: File) => {
    await uploadSelectedFile(file);
    closeImportModal();
  };

  const onClickExport = () => {
    exportFanCSV(selectedFansId);
    closeDownloadMenu();
  };

  const handleClickOk = () => {
    handleSetDrawerState(false);
    handleSubmit();
  };

  const handleSetDrawerState = (isOpened: boolean) => {
    setOpenDrawer(isOpened);
    updateGlobalLayout({ isMenuOpen: isOpened });
  };

  const handleClickClearCreatedDate = () => {
    setFieldValue('createdDate', '');
  };

  const handleClickClearLastOrderDate = () => {
    setFieldValue('lastOrderDate', '');
  };

  const handleClearAllAmount = () => {
    setFieldValue('minAmountSpent', '');
    setFieldValue('maxAmountSpent', '');
    setFieldValue('exactAmountSpent', '');
    setFieldValue('checkedFields', {
      ...checkedFields.value,
      ...{ minAmountSpent: false, maxAmountSpent: false, exactAmountSpent: false },
    });
  };

  const handleClearAllOrders = () => {
    setFieldValue('minNumberOfOrders', '');
    setFieldValue('maxNumberOfOrders', '');
    setFieldValue('exactNumberOfOrders', '');
    setFieldValue('checkedFields', {
      ...checkedFields.value,
      ...{ minNumberOfOrders: false, maxNumberOfOrders: false, exactNumberOfOrders: false },
    });
  };

  const hangleTagsChange = setFieldValue.bind(null, 'tags');

  const filterCount = getFanFilterCount(filter);

  return (
    <>
      <ImportFansModal open={showImportModal} onClose={closeImportModal} onImportFile={handleClickImport} />
      <form onSubmit={handleSubmit}>
        <div css={styles.btnWrapper}>
          <SearchForm
            placeholder={t('TextForm.Search by Fan’s Name or Email Address')}
            value={keywordField.value}
            name="keyword"
            onBlur={handleBlurAndSubmit}
            onChange={handleChange}
            onEnterKeyPress={handleSubmit}
            css={styles.input}
          />
          <Button
            title="More filters"
            bgcolor="#fff"
            color="#6E7C89"
            hoverbgcolor="#F6F8FA"
            onClick={() => handleSetDrawerState(true)}
            css={[styles.button, filterCount ? styles.filterIcon : undefined]}
            icon={<img src={filterCount ? filterActiveIcon : filterIcon} css={styles.marginRight8} />}
            suffixIcon={
              filterCount ? (
                <span css={styles.filterCount}>
                  <i>{filterCount}</i>
                </span>
              ) : undefined
            }
          />
          <Button
            title="Sort"
            icon={<img src={sortIcon} css={styles.marginRight8} />}
            bgcolor="#fff"
            color="#6E7C89"
            hoverbgcolor="#F6F8FA"
            onClick={onSort}
            css={styles.button}
          />
          <Button
            bgcolor="#fff"
            color="#6e7c89"
            hoverbgcolor="#F6F8FA"
            excludeTitleTranslation={true}
            title="..."
            css={styles.downloadBtn}
            onClick={openDownloadMenu}
          />
          <Menu anchorEl={donwloadMenu.anchorEl} open={Boolean(donwloadMenu.anchorEl)} onClose={closeDownloadMenu}>
            <MenuItem onClick={onClickImport}>
              <img src={importIcon} css={styles.marginRight8} />
              {t('Import CSV')}
            </MenuItem>
            <MenuItem onClick={onClickExport}>
              <img src={exportIcon} css={styles.marginRight8} />
              {t('Export CSV')}
            </MenuItem>
          </Menu>
        </div>

        <Drawer anchor="right" open={openDrawer} onClose={() => handleSetDrawerState(false)}>
          <div css={styles.container}>
            <div>
              <div css={styles.heading}>
                <p css={styles.headingText}>{t('More Filters')}</p>
                <img
                  css={{ transform: 'rotate(-90deg)', cursor: 'pointer' }}
                  src={accordionIcon}
                  onClick={() => handleSetDrawerState(false)}
                />
              </div>
              {/* Create Date */}
              <ExpansionPanel classes={classes} css={styles.expansion} defaultExpanded={!!createdDateField.value}>
                <ExpansionPanelSummary expandIcon={<img src={accordionIcon} />} css={styles.summary}>
                  <h2 css={styles.title}>{t('Created Date')}</h2>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails css={styles.panelDetails}>
                  <DateOptionGroup
                    items={dateGroupOptions}
                    value={createdDateField.value}
                    onClear={handleClickClearCreatedDate}
                    onChange={val => setFieldValue('createdDate', val)}
                  />
                </ExpansionPanelDetails>
              </ExpansionPanel>
              {/* Last Order Date */}
              <ExpansionPanel classes={classes} css={styles.expansion} defaultExpanded={!!lastOrderDateField.value}>
                <ExpansionPanelSummary expandIcon={<img src={accordionIcon} />} css={styles.summary}>
                  <h2 css={styles.title}>{t('Last Order Date')}</h2>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails css={styles.panelDetails}>
                  <DateOptionGroup
                    items={dateGroupOptions}
                    value={lastOrderDateField.value}
                    onClear={handleClickClearLastOrderDate}
                    onChange={val => setFieldValue('lastOrderDate', val)}
                  />
                </ExpansionPanelDetails>
              </ExpansionPanel>
              {/* Amount Spent */}
              <ExpansionPanel
                classes={classes}
                css={styles.expansion}
                defaultExpanded={
                  checkedFields.value.minAmountSpent ||
                  checkedFields.value.maxAmountSpent ||
                  checkedFields.value.exactAmountSpent
                }
              >
                <ExpansionPanelSummary expandIcon={<img src={accordionIcon} />} css={styles.summary}>
                  <h2 css={styles.title}>{t('Amount Spent')}</h2>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails css={styles.panelDetails}>
                  <div css={{ display: 'flex', flexDirection: 'column' }}>
                    {/* Min */}
                    <CheckBox
                      css={styles.checkbox}
                      label={t('Amount.More than this number')}
                      name={minAmountSpentField.name}
                      value={checkedFields.value.minAmountSpent}
                      handleClick={() => {
                        setFieldValue('checkedFields', {
                          ...checkedFields.value,
                          minAmountSpent: !checkedFields.value.minAmountSpent,
                        });
                      }}
                    />
                    {checkedFields.value.minAmountSpent && (
                      <TextForm css={styles.textForm} placeholder="¥ 0" {...minAmountSpentField} />
                    )}
                    {/* Max */}
                    <CheckBox
                      label={t('Amount.Less than this number')}
                      css={styles.checkbox}
                      name={maxAmountSpentField.name}
                      value={checkedFields.value.maxAmountSpent}
                      handleClick={() => {
                        setFieldValue('checkedFields', {
                          ...checkedFields.value,
                          maxAmountSpent: !checkedFields.value.maxAmountSpent,
                        });
                      }}
                    />
                    {checkedFields.value.maxAmountSpent && (
                      <TextForm css={styles.textForm} placeholder="¥ 0" {...maxAmountSpentField} />
                    )}
                    {/* Exact */}
                    <CheckBox
                      label={t('Amount.This exact amount')}
                      css={styles.checkbox}
                      name={exactAmountSpentField.name}
                      value={checkedFields.value.exactAmountSpent}
                      handleClick={() => {
                        setFieldValue('checkedFields', {
                          ...checkedFields.value,
                          exactAmountSpent: !checkedFields.value.exactAmountSpent,
                        });
                      }}
                    />
                    {checkedFields.value.exactAmountSpent && (
                      <TextForm css={styles.textForm} placeholder="¥ 0" {...exactAmountSpentField} />
                    )}
                    <p css={styles.clearBtn} onClick={handleClearAllAmount}>
                      {t('Clear')}
                    </p>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              {/* Number of Order */}
              <ExpansionPanel
                classes={classes}
                css={styles.expansion}
                defaultExpanded={
                  checkedFields.value.minNumberOfOrders ||
                  checkedFields.value.maxNumberOfOrders ||
                  checkedFields.value.exactNumberOfOrders
                }
              >
                <ExpansionPanelSummary expandIcon={<img src={accordionIcon} />} css={styles.summary}>
                  <h2 css={styles.title}>{t('Number of Order')}</h2>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails css={styles.panelDetails}>
                  <div css={{ display: 'flex', flexDirection: 'column' }}>
                    {/* Min */}
                    <CheckBox
                      css={styles.checkbox}
                      label={t('OrderCount.More than this number')}
                      name={minNumberOfOrdersField.name}
                      value={checkedFields.value.minNumberOfOrders}
                      handleClick={() => {
                        setFieldValue('checkedFields', {
                          ...checkedFields.value,
                          minNumberOfOrders: !checkedFields.value.minNumberOfOrders,
                        });
                      }}
                    />
                    {checkedFields.value.minNumberOfOrders && (
                      <TextForm css={styles.textForm} placeholder="0" {...minNumberOfOrdersField} />
                    )}
                    {/* Max */}
                    <CheckBox
                      label={t('OrderCount.Less than this number')}
                      css={styles.checkbox}
                      name={maxNumberOfOrdersField.name}
                      value={checkedFields.value.maxNumberOfOrders}
                      handleClick={() => {
                        setFieldValue('checkedFields', {
                          ...checkedFields.value,
                          maxNumberOfOrders: !checkedFields.value.maxNumberOfOrders,
                        });
                      }}
                    />
                    {checkedFields.value.maxNumberOfOrders && (
                      <TextForm css={styles.textForm} placeholder="0" {...maxNumberOfOrdersField} />
                    )}
                    {/* Exact */}
                    <CheckBox
                      label={t('OrderCount.This exact amount')}
                      css={styles.checkbox}
                      name={exactNumberOfOrdersField.name}
                      value={checkedFields.value.exactNumberOfOrders}
                      handleClick={() => {
                        setFieldValue('checkedFields', {
                          ...checkedFields.value,
                          exactNumberOfOrders: !checkedFields.value.exactNumberOfOrders,
                        });
                      }}
                    />
                    {checkedFields.value.exactNumberOfOrders && (
                      <TextForm css={styles.textForm} placeholder="0" {...exactNumberOfOrdersField} />
                    )}
                    <p css={styles.clearBtn} onClick={handleClearAllOrders}>
                      {t('Clear')}
                    </p>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              {/* Tags */}
              <ExpansionPanel classes={classes} css={styles.expansion} defaultExpanded={!!tagsField.value.length}>
                <ExpansionPanelSummary expandIcon={<img src={accordionIcon} />} css={styles.summary}>
                  <h2 css={styles.title}>{t('Tags')}</h2>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails css={styles.panelDetails}>
                  <div css={styles.tagSelector}>
                    <MultiTagsSearchSelector
                      placeholder="VIP,etc"
                      isCreatable={false}
                      // @ts-ignore type is TagsValue[]
                      value={tagsField.value}
                      setFieldValue={hangleTagsChange}
                    />
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>

            <div css={styles.footer}>
              <PalleteButton
                text="Cancel"
                width="90px"
                height="32px"
                borderRadius="3px"
                onClick={() => handleSetDrawerState(false)}
                css={styles.marginRight8}
                skipUppercase={true}
              />
              <SubmitButton disabled={isSubmitting} value="OK" css={styles.submitBtn} onClick={handleClickOk} />
            </div>
          </div>
        </Drawer>
      </form>
    </>
  );
};

const styles = {
  title: css`
    font-weight: 600;
    font-size: 14px;
    color: #27313b;
  `,
  panelDetails: css`
    padding: 0 !important;
  `,
  wrapper: css`
    display: flex;
    flex-direction: column;
  `,

  form: css`
    height: 100%;
  `,
  submitBtn: css`
    width: fit-content;
    line-height: 32px;
    border-radius: 3px;
    padding: 0 24px;
  `,
  summary: css`
    height: 48px !important;
    min-height: 48px !important;
    padding: 0 !important;
  `,
  heading: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #eef3f7;
  `,
  headingText: css`
    font-weight: 600;
    font-size: 18px;
    color: #27313b;
  `,
  input: css`
    flex: 1;
    margin-right: 8px;

    @media (max-width: ${ViewportType.TABLET}px) {
      flex-basis: 100%;
      margin-bottom: 8px;
      margin-right: 0;
    }

    i {
      background-color: transparent;
      height: 30px;
    }

    input {
      border-radius: 0;
      line-height: 30px;
    }
  `,
  label: css`
    font-weight: 600;
    font-size: 14px;
    color: #27313b;
  `,
  container: css`
    width: 336px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  `,
  btnWrapper: css`
    padding: 16px;
    margin: 16px 25px 0;
    background-color: #fff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px rgb(222 229 236 / 50%);
    display: flex;
    flex-wrap: wrap;
  `,
  button: css`
    width: fit-content;
    padding: 0 8px;
    border: 1px solid #dee5ec;
    border-radius: 3px;
    line-height: 30px;
    margin-right: 8px;
    font-weight: 600;

    & > p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @media (max-width: ${ViewportType.TABLET}px) {
      display: flex;
      flex: 1;
    }
  `,
  downloadBtn: css`
    width: 32px;
    border: 1px solid #dee5ec;
    border-radius: 3px;
    line-height: 30px;
    font-weight: 600;
  `,
  footer: css`
    background-color: #f6f8fa;
    padding: 24px 24px 24px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `,
  expansion: css`
    &::before {
      height: 0;
    }

    &:last-of-type {
      border-bottom: none;
    }
  `,
  textForm: css`
    width: 240px;
    margin: 0 0 16px 26px;

    /* stylelint-disable */
    & input {
      border-radius: 3px;
      height: 32px;
    }

    label {
      padding-left: 0;
    }
    /* stylelint-enable */
  `,
  checkbox: css`
    padding: 0 0 4px 0;

    label {
      margin-right: 0;
      align-items: flex-start;
    }
  `,
  clearBtn: css`
    margin: 8px 0;
    color: #3892e5;
    font-size: 14px;
    cursor: pointer;
  `,
  marginRight8: css`
    margin-right: 8px;
  `,
  filterCount: css`
    font-size: 12px;
    font-weight: 600;
    color: #3892e5;
    background-color: #fff;
    margin-left: 8px;
    border-radius: 50%;
    padding: 6px;
    line-height: 12px;
  `,
  filterIcon: css`
    border-color: #3892e5;
    background-color: #ecf3fb;

    p {
      color: #3892e5;
    }
  `,
  tagSelector: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 40px;
  `,
};

export default FanListFilter;
