import { useFilter } from '@src/components/organisms/AnalyticsShared/BasePostListFilter/useFilter';
import { getVideosListSortFiledType } from '@src/components/organisms/AnalyticsYoutube/Dashboard/PostCard/FeedVideos/helpers';
import { getThirtyDaysAgo, getToday } from '@src/libs/date';
import { useSort } from '@src/libs/filter';
import { useUpdateDocumentTitle } from '@src/libs/hooks';
import { usePaging } from '@src/libs/paging';
import { YoutubeAnalyticsPostsSortOrder } from '@src/__generated__/globalTypes';
import React from 'react';
import { useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';
import Template from './template';

const YoutubeVideoList = () => {
  const { search } = useLocation();

  useUpdateDocumentTitle({
    title: 'pageTitle.Video List',
    pageTitle: 'pageTitle.Video List',
    href: `/analytics${search}`,
  });

  const searchParams = new URLSearchParams(search);
  const socialMediaAccountId = searchParams.get('socialMediaAccountId');

  if (!socialMediaAccountId) {
    return <Redirect to="/404" />;
  }

  const currentPage = usePaging();
  const { filter, setFilter } = useFilter({ startDate: getThirtyDaysAgo(), endDate: getToday() });
  const sortData = useSort<YoutubeAnalyticsPostsSortOrder>(getVideosListSortFiledType);

  return (
    <Template
      currentPage={currentPage}
      filter={filter}
      setFilter={setFilter}
      socialMediaAccountId={socialMediaAccountId}
      {...sortData}
    />
  );
};

export default YoutubeVideoList;
