import React from 'react';
import { css } from '@emotion/core';
import { useSort } from '@src/libs/filter';
import { useContainerQuery } from '@src/libs/hooks';
import { InstagramInteractionUserSortField } from '@src/__generated__/globalTypes';
import { ViewportBreakpoint } from '@src/libs/theme';
import { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import { MoreText } from '@src/components/organisms/AnalyticsShared/PostDetail/SliderStyledComponents';
import { useTranslation } from 'react-i18next';
import UserAnalysisTable from './UserAnalysisTable';
import { getUserAnalysisSortFiledType } from './helpers';
import { InfluencerInstagramInteractionPosts_influencerAllInstagramInteractionUsers } from './__generated__/InfluencerInstagramInteractionPosts';

interface TagRankingProps {
  userAnalytics: InfluencerInstagramInteractionPosts_influencerAllInstagramInteractionUsers['usernames'];
  moreLink: string;
}
const UserAnalysisList = ({ userAnalytics, moreLink }: TagRankingProps) => {
  const { t } = useTranslation();
  const { containerRef, isSmaller: showSimplifiedTable } = useContainerQuery({
    hasWidthSmallerThan: ViewportBreakpoint.MOBILE,
  });
  const { sort, setSort } = useSort<InstagramInteractionUserSortField>(getUserAnalysisSortFiledType);

  return (
    <div ref={containerRef} css={styles.container}>
      <SectionHeader css={styles.header}>{t('User Analysis')}</SectionHeader>
      <div css={!showSimplifiedTable ? styles.tagWrapper : styles.tagWrapperMobile}>
        <UserAnalysisTable
          userAnalytics={userAnalytics || []}
          sort={sort}
          setSort={setSort}
          showSimplifiedTable={showSimplifiedTable}
        />
      </div>
      <MoreText to={moreLink} css={styles.more} />
    </div>
  );
};

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    border-top: 1px solid #dee5ec;
    border-bottom: 1px solid #dee5ec;
  `,
  header: css`
    padding: 16px 16px 0 16px;
  `,
  tagWrapper: css`
    padding: 16px;
  `,
  tagWrapperMobile: css`
    padding: 16px 0;
  `,
  loading: css`
    margin: 0;
  `,
  more: css`
    margin-top: 0;
  `,
};

export default UserAnalysisList;
