import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { formatIntNumber } from '@src/libs/format';
import { defaultInfluencerAvatar } from '@src/libs/image';
import { switchImage, switchCountUnit } from '@src/libs/SocialMedia';
import { SocialAccountType } from '@src/__generated__/globalTypes';

export interface InfluencerInfoProps {
  avatar: string | null;
  name: string | null;
  followersCount: number | null;
  postDate?: string;
  hideFollowersCount?: boolean;
  accountType?: SocialAccountType;
}

const InfluencerInfo = (props: InfluencerInfoProps) => {
  const { avatar, name, followersCount, postDate, hideFollowersCount = false } = props;
  const accountType = props.accountType || SocialAccountType.INSTAGRAM;
  const imgSrc = switchImage(accountType, true);
  const countUnit = switchCountUnit(accountType);
  const { t } = useTranslation();

  return (
    <Container>
      <LogoWrapper>
        <Image src={imgSrc} />
      </LogoWrapper>
      <Thumbnail src={defaultInfluencerAvatar(avatar)} />
      <Wrapper>
        <Name>{name}</Name>
        {!hideFollowersCount && (
          <Follower>
            {formatIntNumber(followersCount)} {t(countUnit)}
          </Follower>
        )}
      </Wrapper>
      <PostDate>{postDate}</PostDate>
    </Container>
  );
};

const Name = styled.h6`
  overflow: hidden;
  max-height: 16px;
  font-weight: 500;
  line-height: 16px;
  word-break: break-all;
  font-size: 13px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
`;

const LogoWrapper = styled.div`
  border-radius: 50%;
  z-index: 1;
  background-color: #fff;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  height: 18px;
  width: 18px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 400px;
`;

const Thumbnail = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: contain;
  left: -8px;
  position: relative;
`;

const Wrapper = styled.div`
  flex: 1;
  margin-left: 8px;
`;

const Follower = styled.p`
  margin-top: 4px;
  font-size: 11px;
  color: #6e7c89;
`;

const PostDate = styled.span`
  font-size: 13px;
  font-weight: 600;
`;

export default InfluencerInfo;
