import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface ButtonProps extends StyledButtonProps {
  className?: string;
  icon?: string | React.ReactElement;
  suffixIcon?: React.ReactElement;
  title?: string;
  excludeTitleTranslation?: boolean;
  type?: 'button' | 'reset' | 'submit';
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button = (props: ButtonProps) => {
  const { t } = useTranslation();
  const {
    className,
    icon,
    suffixIcon,
    title,
    onClick,
    disabled,
    color,
    bgcolor,
    hoverbgcolor,
    hoverBorder,
    excludeTitleTranslation = false,
    type = 'button',
  } = props;
  const translatedTitle = title && !excludeTitleTranslation ? t(`Button.${title}`) : title;

  return (
    <StyledButton
      className={className}
      onClick={onClick}
      disabled={disabled}
      color={color}
      bgcolor={bgcolor}
      hoverbgcolor={hoverbgcolor}
      hoverBorder={hoverBorder}
      type={type}
    >
      {React.isValidElement(icon) ? icon : icon ? <Icon className="material-icons">{icon}</Icon> : null}
      <p>{translatedTitle}</p>
      {React.isValidElement(suffixIcon) ? suffixIcon : null}
    </StyledButton>
  );
};

interface StyledButtonProps {
  disabled?: boolean;
  color?: string;
  bgcolor?: string;
  hoverbgcolor?: string;
  hoverBorder?: string;
}

const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${props => props?.bgcolor || '#00ac56'};
  border-radius: 2px;
  color: ${props => props?.color || '#fff'};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  text-align: center;
  letter-spacing: 0.02em;
  line-height: 40px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: ${props => props?.hoverbgcolor || '#00984c'};
    ${props => props.hoverBorder && `border-color: ${props.hoverBorder}`}

    @media (max-width: 450px) {
      background-color: ${props => props?.bgcolor || '#00ac56'};
    }
  }
`;

const Icon = styled.i`
  margin-right: 4px;
  font-size: 16px;
`;

export default Button;
