import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AppRoute, PrivateRoute, PublicRoute } from './components/organisms/Route';
import AuthSetUp from './components/organisms/AuthSetUp';
import * as pages from './pages';
import ScrollToTop from './ScrollToTop';
import { CampaignPageType } from './libs/campaign';
import { useGlobalLayoutProps, usePageLayout } from './libs/hooks';
import UuumRoutes from './UuumRoutes';

const Routes = () => {
  const {
    globalLayout: { isUuum },
  } = useGlobalLayoutProps();
  const { isMobileView } = usePageLayout();
  const fansBackPach = isMobileView ? '/fans/:id' : '/fans';

  if (isUuum) {
    return <UuumRoutes />;
  }

  return (
    <Router>
      <AuthSetUp>
        <ScrollToTop>
          <Switch>
            <PublicRoute path="/" exact={true} component={pages.SignIn} />
            <PublicRoute path="/signup" exact={true} component={pages.SignUp} />
            <PublicRoute path="/signup/email" exact={true} component={pages.SignUpEmail} />
            <PublicRoute path="/signup/instagram" exact={true} component={pages.SignUpInstagram} />
            <PublicRoute
              path="/signup/instagram_unable_connect"
              exact={true}
              component={pages.SignUpInstagramUnableConnect}
            />
            <PublicRoute path="/signup/success" exact={true} component={pages.SignUpSuccess} />
            <PublicRoute path="/signin" exact={true} component={pages.SignIn} />
            <PublicRoute
              path="/signin/instagram_unable_connect"
              exact={true}
              component={pages.SignInInstagramUnableConnect}
            />
            <PublicRoute path="/signin/failed_connect" exact={true} component={pages.SignInFailedConnect} />
            <PublicRoute path="/signin/email" exact={true} component={pages.SignInEmail} />
            <Route path="/password_forgot" component={pages.PasswordForgot} />
            <Route path="/password_register/:token" component={pages.PasswordRegister} />
            <Route path="/password_reset/:token" component={pages.PasswordReset} />
            <PublicRoute path="/signup/profile" exact={true} component={pages.SignUpProfile} />
            <Route path="/redirect/signup-enabled/:provider" component={pages.RedirectSignUpEnabled} />
            <Route path="/redirect/reconnect_ig" exact={true} component={pages.RedirectIgReconnect} />
            <Route path="/reconnect" exact={true} component={pages.IgReconnect} />
            <PrivateRoute path="/signup/connect" exact={true} component={pages.SignUpConnect} />
            <Route path="/redirect/twitter" component={pages.RedirectTwitter} />
            <Route path="/redirect/signup/:provider" component={pages.RedirectSignUp} />
            <Route path="/redirect/signin/:provider" component={pages.RedirectSignIn} />
            <Route path="/redirect/signup-connect/:provider" component={pages.RedirectSignUpConnect} />
            <Route path="/redirect/connect/:provider" component={pages.RedirectConnect} />
            <Route path="/redirect/join_campaign/:provider" component={pages.RedirectJoinCampaign} />
            <Route path="/redirect/analytics-reconnect/:provider" component={pages.RedirectAnalyticsReconnect} />
            <Route path="/redirect/shopify_connect" component={pages.RedirectShopifyConnect} />
            {/* Home */}
            <AppRoute path="/home" exact component={pages.Home} />
            {/* Social Media: Analytics, Marketplace, Casting */}
            <AppRoute path="/analytics:active_tab?" exact={true} component={pages.Analytics} />
            <AppRoute
              path="/analytics/instagram_feed_post"
              exact={true}
              component={pages.InstagramStoryOrPostFeed}
              backPath="/analytics"
              toolbarTitle="Post List"
            />
            <AppRoute
              path="/analytics/instagram_story_feed"
              exact={true}
              component={pages.InstagramStoryOrPostFeed}
              backPath="/analytics"
              toolbarTitle="Post List"
            />
            <AppRoute
              path="/analytics/facebook_feed_post"
              exact={true}
              component={pages.FacebookPostFeed}
              backPath="/analytics"
              toolbarTitle="Post List"
            />
            <AppRoute
              path="/analytics/feed_videos"
              exact={true}
              component={pages.YoutubeVideoList}
              backPath="/analytics"
              toolbarTitle="Video List"
            />
            <AppRoute
              path="/analytics/tag_ranking_list"
              exact={true}
              component={pages.TagRanking}
              backPath="/analytics"
              toolbarTitle="Tag Ranking"
            />
            <AppRoute
              path="/analytics:compare/youtube_compare_feed_videos"
              exact={true}
              component={pages.YoutubeCompareFeedVideos}
              backPath="/analytics"
              toolbarTitle="Video List"
            />
            <AppRoute
              path="/analytics:ig_interaction/user_analysis"
              exact={true}
              component={pages.UserAnalysisList}
              backPath="/analytics:ig_interaction"
              toolbarTitle="_dynamic"
            />
            <AppRoute
              path="/analytics:ig_interaction/tagged_posts"
              exact={true}
              component={pages.IgTaggedOrMentionedPostsList}
              backPath="/analytics:ig_interaction"
              toolbarTitle="_dynamic"
            />
            <AppRoute
              path="/analytics:ig_interaction/mentioned_posts"
              exact={true}
              component={pages.IgTaggedOrMentionedPostsList}
              backPath="/analytics:ig_interaction"
              toolbarTitle="_dynamic"
            />
            {/* Marketplace */}
            <AppRoute
              path="/marketplace/search_job"
              exact
              component={pages.MarketplaceSearchJob}
              toolbarTitle="Search Job"
            />
            <AppRoute
              path="/marketplace/your_job"
              exact
              component={pages.MarketplaceYourJobListings}
              toolbarTitle="Your Job"
            />
            <AppRoute
              path={['/marketplace/search_job/:id', '/marketplace/your_job/:id']}
              exact
              component={pages.MarketplaceYourJobDetails}
              backPath="/marketplace/search_job"
              toolbarTitle="Campaigns Detail"
            />
            <AppRoute
              path="/marketplace/your_job/:id/report"
              exact
              component={pages.MarketplaceYourJobReports}
              backPath="/marketplace/your_job/:id"
              toolbarTitle="Campaign's Report"
            />
            {/* Casting */}
            <AppRoute path="/casting" exact component={pages.Casting} />
            <AppRoute path="/casting/your_job" exact component={pages.CastingYourJobListings} toolbarTitle="Your Job" />
            <AppRoute
              path="/casting/your_job/:id"
              exact
              component={pages.CastingYourJobDetails}
              backPath="/casting/your_job"
              toolbarTitle="Campaigns Detail"
            />
            <AppRoute
              path="/casting/your_job/:id/post"
              exact
              component={pages.CastingYourJobPosts}
              backPath="/casting/your_job/:id"
              toolbarTitle="Post List"
            />
            <AppRoute
              path="/casting/your_job/:id/post/add"
              exact
              component={pages.CastingYourJobAddPost}
              backPath="/casting/your_job/:id/post"
              toolbarTitle="Upload Draft Post"
            />
            <AppRoute
              path="/casting/your_job/:id/post/:postId"
              exact
              component={pages.CastingYourJobEditPost}
              backPath="/casting/your_job/:id/post"
              toolbarTitle="Edit Post"
            />
            <AppRoute
              path="/casting/your_job/:id/report"
              exact
              component={pages.CastingYourJobReports}
              backPath="/casting/your_job/:id"
              toolbarTitle="Campaign's Report"
            />
            {/* Fan management: Fans, Emails, Chat, Forms */}
            <AppRoute path="/fans" toolbarTitle="Fans" exact component={pages.Fans} />
            <AppRoute path="/fans/add" toolbarTitle="Add Fan" backPath="/fans" exact component={pages.AddFan} />
            <AppRoute
              path="/fans/:id"
              toolbarTitle="Fan's Details"
              backPath="/fans"
              exact
              component={pages.FanDetails}
            />
            <AppRoute
              path="/fans/:id/details"
              toolbarTitle="Fan's Details"
              backPath={fansBackPach}
              exact
              component={pages.FanDetails}
            />
            <AppRoute
              path="/fans/:id/activity"
              toolbarTitle="Activity"
              backPath={fansBackPach}
              exact
              component={pages.FanDetails}
            />
            <AppRoute
              path="/fans/:id/orders"
              toolbarTitle="Orders"
              backPath={fansBackPach}
              exact
              component={pages.FanDetails}
            />
            <AppRoute
              path="/fans/:id/forms"
              toolbarTitle="Forms"
              backPath={fansBackPach}
              exact
              component={pages.FanDetails}
            />
            <AppRoute
              path="/fans/:id/forms/:submissionId"
              toolbarTitle="Forms"
              backPath="/fans/:id/forms"
              exact
              component={pages.FanDetails}
            />
            {/* Emails */}
            <AppRoute path="/emails" exact component={pages.EmailListing} toolbarTitle="Emails" />
            <AppRoute
              path="/emails/compose"
              exact
              component={pages.EmailCompose}
              backPath="/emails"
              toolbarTitle="Compose"
            />
            <AppRoute
              path="/emails/:id/compose"
              exact
              component={pages.EmailEdit}
              backPath="/emails"
              toolbarTitle="Compose"
            />
            <AppRoute
              path={['/emails/:id/overview', '/emails/:id/recipient']}
              exact
              component={pages.EmailReports}
              backPath="/emails"
              toolbarTitle="Email Report"
            />
            {/* Chat */}
            <AppRoute path="/chat" exact component={pages.Chat} toolbarTitle="Chat" skipBottomPadding />
            <AppRoute
              path="/chat/:activeChat?"
              exact
              component={pages.Chat}
              toolbarTitle="_dynamic"
              backPath="/chat"
              skipBottomPadding
            />
            <AppRoute
              path="/chat/:activeChat?/:fanId"
              exact
              component={pages.Chat}
              backPath="/chat/:activeChat?"
              skipBottomPadding
              toolbarTitle="Details"
            />
            <AppRoute
              path="/chat/:activeChat?/:fanId/fan_detail"
              exact
              component={pages.Chat}
              backPath="/chat/:activeChat?/:fanId"
              skipBottomPadding
              toolbarTitle="Fan Detail"
            />
            <AppRoute
              path="/chat/:activeChat?/:fanId/activity"
              exact
              component={pages.Chat}
              backPath="/chat/:activeChat?/:fanId"
              skipBottomPadding
              toolbarTitle="Activity"
            />
            <AppRoute
              path="/chat/:activeChat?/:fanId/orders"
              exact
              component={pages.Chat}
              backPath="/chat/:activeChat?/:fanId"
              skipBottomPadding
              toolbarTitle="Orders"
            />
            {/* Forms */}
            <AppRoute path="/forms" exact component={pages.FormListings} toolbarTitle="Form" />
            <AppRoute path="/forms/add" exact component={pages.AddForm} backPath="/forms" toolbarTitle="Add Form" />
            <AppRoute
              path="/forms/add/live_preview"
              exact
              component={pages.AddPreviewForm}
              backPath="/forms/add"
              toolbarTitle="Live Preview"
            />
            <AppRoute
              path={[
                '/forms/:id/entry',
                '/forms/:id/report',
                '/forms/:id/report/respondent',
                '/forms/:id/report/individual',
                '/forms/:id/report/individual/:page',
              ]}
              exact
              component={pages.EditForm}
              backPath="/forms"
              toolbarTitle="Edit Form"
            />
            <AppRoute
              path="/forms/:id/live_preview"
              exact
              component={pages.EditPreviewForm}
              backPath="/forms/:id/entry"
              toolbarTitle="Live Preview"
            />
            <Route path="/form/:hash" component={pages.SubmitForm} />
            {/* Notification */}
            <AppRoute path="/notification" exact component={pages.Notification} toolbarTitle="Notification" />
            {/* Settings */}
            <AppRoute path="/settings" exact component={pages.SettingsMenu} toolbarTitle="Settings" />
            <AppRoute
              path="/settings/account"
              exact
              component={pages.SettingsAccount}
              backPath="/settings"
              toolbarTitle="Account"
            />
            <AppRoute
              path="/settings/address"
              exact
              component={pages.SettingsAddress}
              backPath="/settings"
              toolbarTitle="Address"
            />
            <AppRoute
              path="/settings/social_connect"
              exact
              component={pages.SettingsSocialConnect}
              backPath="/settings"
              toolbarTitle="Social Connect"
            />
            <AppRoute
              path="/settings/social_connect/instagram_unable_connect"
              exact
              component={pages.SetttingsInstagramUnableConnect}
              backPath="/settings/social_connect"
              toolbarTitle="Unable to connect"
            />
            <AppRoute
              path="/settings/payment_information"
              exact
              component={pages.SettingsPaymentInformation}
              backPath="/settings"
              toolbarTitle="Payment Information"
            />
            <AppRoute
              path="/settings/users"
              exact
              component={pages.SettingsUsers}
              backPath="/settings"
              toolbarTitle="Users"
            />
            <AppRoute
              path="/settings/users/:userId"
              exact
              component={pages.SettingsUserDetail}
              backPath="/settings/users"
              toolbarTitle="User Detail"
            />
            <AppRoute
              path="/settings/sns_budget"
              exact
              component={pages.SettingsSnsBudget}
              backPath="/settings"
              toolbarTitle="SNS budget"
            />
            <AppRoute
              path="/settings/channel_connect"
              exact
              component={pages.SettingsChannelConnect}
              backPath="/settings"
              toolbarTitle="Channel Connect"
            />
            {/* Payment */}
            <AppRoute path="/payment" exact={true} component={pages.Payment} toolbarTitle="ToolbarPayment" />
            <AppRoute
              path="/payment/history"
              exact={true}
              component={pages.PaymentHistory}
              backPath="/payment"
              toolbarTitle="Payment History"
            />
            {/* Your Job */}
            <AppRoute path="/job" exact component={pages.YourJob} />
            <AppRoute path="/job/finished" exact={true} component={pages.FinishedJob} />
            <AppRoute path="/job/engagement/:id" exact={true} component={pages.EngagementCampaignDetail} />
            <AppRoute path="/job/engagement/:id/post" exact={true} component={pages.EngagementPost} />
            <AppRoute path="/job/engagement/:id/post/add" exact={true} component={pages.AddEngagementPost} />
            <AppRoute path="/job/engagement/:id/post/:postId" component={pages.EditEngagementPost} />
            <AppRoute path="/job/engagement/:id/report" exact={true} component={pages.EngagementPostReport} />
            <AppRoute
              path="/job/marketplace/:id"
              exact={true}
              component={pages.MarketplaceCampaignDetail}
              page={CampaignPageType.JOB}
            />
            <AppRoute path="/job/marketplace/:id/report" exact={true} component={pages.MarketplacePostReport} />
            {/* Search Job */}
            <AppRoute path="/search" exact={true} component={pages.Search} />
            <AppRoute
              path="/search/marketplace/:id"
              exact={true}
              component={pages.MarketplaceCampaignDetail}
              page={CampaignPageType.SEARCH}
            />
            {/* Settings */}
            <AppRoute path="/profile" exact={true} component={pages.Profile} />
            <AppRoute path="/profile/instagram_feed_post" exact={true} component={pages.InstagramStoryOrPostFeed} />
            <AppRoute path="/profile/instagram_story_feed" exact={true} component={pages.InstagramStoryOrPostFeed} />
            <AppRoute path="/profile/feed_videos" exact={true} component={pages.YoutubeVideoList} />
            <AppRoute path="/profile/tag_ranking_list" exact={true} component={pages.TagRanking} />
            <AppRoute path="/profile/settings" exact={true} component={pages.ProfileSettings} />
            <AppRoute path="/profile/settings/edit" exact={true} component={pages.EditProfile} />
            <AppRoute path="/profile/settings/connect" exact={true} component={pages.ProfileConnect} />
            <AppRoute path="/profile/settings/payment" exact={true} component={pages.PaymentInformation} />
            <AppRoute path="/profile/settings/address" exact={true} component={pages.AddressInformation} />
            <AppRoute path="/profile:active_tab?" exact={true} component={pages.Profile} />
            <AppRoute
              path="/profile:compare/youtube_compare_feed_videos"
              exact={true}
              component={pages.YoutubeCompareFeedVideos}
            />
            {/* Help & Contact */}
            <AppRoute path="/contact" exact={true} component={pages.Contact} />
            <AppRoute path="/contact/thanks" exact={true} component={pages.Thanks} />
            {/* Terms & Privacy */}
            <Route path="/terms" component={pages.Terms} />
            <Route path="/privacy" component={pages.Privacy} />
            {/* Not Found inside the App */}
            <AppRoute component={pages.NotFound} />
          </Switch>
        </ScrollToTop>
      </AuthSetUp>
    </Router>
  );
};

export default Routes;
