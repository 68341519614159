import styled from '@emotion/styled';
import { ViewportBreakpoint } from '@src/libs/theme';
import MoreButton from '@src/components/atoms/MoreButton';
import Image from '@src/components/atoms/Image';

export const VideoContainerWrapper = styled.div`
  width: 160px !important;
  height: 160px;
  position: relative;
  margin-right: 16px;

  &:focus {
    outline: none;
  }
`;

export const VideoWrapper = styled.div`
  width: 100%;
  height: 60%;
  position: relative;
`;

export const VideoThumbnail = styled(Image)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: cover;
`;

export const PostImage = styled.img`
  margin-right: 16px;
  object-fit: contain;
  cursor: pointer;
`;

export const VideoTitle = styled.div`
  font-size: 11px;
  font-weight: 600;
  line-height: 1.36;
  letter-spacing: 0.28px;
  color: #27313b;
  margin: 4px 0 8px;
  overflow: hidden;
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* stylelint-enable */
`;

export const EngagementInfo = styled.div`
  font-size: 11px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  cursor: pointer;
  width: 100%;
`;
export const InfoWrapper = styled.div`
  padding: 0;
`;
export const Label = styled.p`
  color: #6e7c89;
  margin-bottom: 3px;
`;
export const Count = styled.p``;
export const MoreText = styled(MoreButton)`
  font-size: 12px;
  font-weight: 600;
  color: #6e7c89;
  text-align: right;
  margin: 20px 20px 20px auto;
`;
export const Arrow = styled.span`
  color: #ff2b52;
  font-size: 14px;
`;
// Inline styles generated from `react-slick` in here
export const PostImageWrapper = styled.div`
  position: relative;

  &:focus {
    outline: none;
  }
`;
export const PostsTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 27px 0 19px 0;
  font-size: 18px;
  padding-right: 16px;
`;
export const CheckboxText = styled.p`
  font-size: 12px;
  color: #27313b;
  padding: 0 4px;
`;
export const PostsPreviewWrapper = styled.div`
  padding: 8px 0 8px 16px;
  border-top: 1px solid #eef2f5;
`;
export const TotalPostsPreview = styled.span`
  font-size: 12px;
  font-weight: 600;
`;

export const ChartWrapper = styled.div`
  flex: 1;

  &:last-child {
    border-right-style: none;
  }
`;
export const ChartContainer = styled.div`
  display: flex;
  height: 385px;
`;
export const ChartContainerBorderBottom = styled.div`
  display: flex;
  border-bottom: 1px solid #e6ecf0;
  flex-direction: column;

  @media (min-width: ${ViewportBreakpoint.LARGE}px) {
    flex-direction: row;
  }
`;
export const VideoDuration = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 3px;
  padding: 0 3px;
  width: max-content;
  height: 12px;
  background-color: #000;
  color: #fff;
  font-weight: 600;
  font-size: 10px;
`;

export const Delimeter = styled.div`
  width: 1px;
  background-color: #dee5ec;
`;
