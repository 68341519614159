import React from 'react';
import { useQuery } from '@apollo/client';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { useAuthData } from '@src/libs/hooks';
import AccountsInterestChart from './template';
import * as INFLUENCER_INTERESTS from './InfluencerInterests.graphql';
import { InfluencerInterests, InfluencerInterestsVariables } from './__generated__/InfluencerInterests';

interface AccountsInterestProps {
  socialAccountId: number;
  socialMedia: SocialAccountType;
  className?: string;
}
const AccountsInterest = ({ socialAccountId, socialMedia, className }: AccountsInterestProps) => {
  const { userId } = useAuthData();
  const { data } = useQuery<InfluencerInterests, InfluencerInterestsVariables>(INFLUENCER_INTERESTS, {
    variables: {
      socialAccountId,
      socialMedia,
      influencerId: userId as number,
    },
    skip: !userId,
  });

  return <AccountsInterestChart interestRate={data?.influencerInterests || []} className={className} />;
};

export default AccountsInterest;
