import styled from '@emotion/styled';
import React, { useEffect, useRef } from 'react';
import { SerializedStyles } from '@emotion/react';
import { Option } from '@src/libs/form';
import InputBox from './InputBox';
import { useClickOutside } from './useClickOutside';
import { useMenuOpen } from './useMenuOpen';
import { useSearch } from './useSearch';

type ValueType = Option | Option[] | null;
interface ItemsType<V = ValueType> {
  selectedValue: V;
  setSelectedValue: (value: V) => void;
  removeValue: (item: Option) => void;
}

interface RenderSelectedValueProps<V = ValueType> {
  selectedValue: V;
}

type SearchType = ReturnType<typeof useSearch>;
export type MenuType = ReturnType<typeof useMenuOpen>;
interface RenderMenuProps<V = ValueType> {
  items: ItemsType<V>;
  search: SearchType;
  menu: MenuType;
}
export interface BaseSelectorProps<V = ValueType> {
  autoSelectMatched?: boolean;
  className?: string;
  name: string;
  options: Option[];
  error?: boolean;
  disabled?: boolean;
  disabledInputBox?: boolean;
  isRoundInput?: boolean;
  hideInput?: boolean;
  placeholder?: string;
  items: ItemsType<V>;
  onClose?: () => void;
  onClickInputBox?: (menu: MenuType) => void;
  MenuControlIcon?: React.ComponentType<{ className?: string }>;
  renderSelectedValue: (props: RenderSelectedValueProps<V>) => React.ReactNode;
  renderMenu: (props: RenderMenuProps<V>) => React.ReactNode;
  dropdownCss?: SerializedStyles;
}

const BaseSelector = <V extends Option | Option[] | null>(props: BaseSelectorProps<V>) => {
  const {
    autoSelectMatched,
    items,
    error,
    disabled,
    options,
    disabledInputBox,
    placeholder,
    onClose,
    onClickInputBox,
    MenuControlIcon,
    renderSelectedValue,
    renderMenu,
    className,
    hideInput,
    isRoundInput = false,
    dropdownCss,
  } = props;

  // Search items via InputText
  const search = useSearch(options);

  // Menu StateHandler
  const menu = useMenuOpen();

  // Close menu if click outside of selector
  const { ref: selectorRef, clickedOutside } = useClickOutside();
  useEffect(() => {
    if (clickedOutside) {
      menu.setIsOpen(false);
    }
  }, [clickedOutside]);

  // Ref for input area
  const inputRef = useRef<HTMLInputElement>(null);

  const isFirstRun = useRef(true);
  useEffect(() => {
    // Skip first run
    if (isFirstRun.current) {
      isFirstRun.current = false;

      return;
    }
    if (!menu.isOpen) {
      search.setSearchText('');
    }
    if (!menu.isOpen && onClose) {
      onClose();
    }
  }, [menu.isOpen]);

  const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    search.setSearchText(e.target.value);
  };

  const handleDeleteByKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !search.searchText) {
      if (items.selectedValue instanceof Array) {
        items.removeValue(items.selectedValue[items.selectedValue.length - 1]);
      } else {
        items.removeValue(items.selectedValue as Option);
      }
    }

    if (autoSelectMatched && e.key === 'Enter' && search.searchText) {
      e.preventDefault();
      const matched = options.find(option => option.label === search.searchText);
      if (matched) {
        items.setSelectedValue(matched as V);
        menu.setIsOpen(false);

        if (inputRef.current) {
          inputRef.current.blur();
        }
      } else {
        search.setSearchText('');
      }
    }
  };

  const handleClickInput = (e: React.MouseEvent<HTMLInputElement>) => {
    e.persist();
    e.stopPropagation();
    menu.setIsOpen(true);
  };

  const handleClickInputBox = () => {
    if (disabledInputBox) {
      return;
    }
    if (!!onClickInputBox) {
      onClickInputBox(menu);
    } else {
      menu.toggle();
    }
    if (!menu.isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <Wrapper ref={selectorRef} css={dropdownCss}>
      <InputBox
        className={className}
        isMenuOpen={menu.isOpen}
        isError={error}
        disabled={disabled}
        hideInput={hideInput}
        isRoundInput={isRoundInput}
        searchText={search.searchText}
        inputRef={inputRef}
        placeholder={placeholder}
        handleChange={handleSearchTextChange}
        handleKeyDown={handleDeleteByKey}
        handleClickInput={handleClickInput}
        handleClickInputBox={handleClickInputBox}
        MenuControlIcon={MenuControlIcon}
        renderSelectedValue={() => renderSelectedValue({ selectedValue: items.selectedValue })}
      />
      {menu.isOpen && <>{renderMenu({ items, search, menu })}</>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

export default BaseSelector;
