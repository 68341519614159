import { SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from '@src/libs/form';
import { MenuControlIcon } from '@src/components/atoms/MenuControlIcon/MenuControlIcon';
import BaseSelector from '../BaseSelector';
import Apply from './Apply';
import MenuItems from './MenuItems';
import SelectAll from './SelectAll';
import { getInitialSelectedItems } from './utils';

type ValueType = Option[];
export interface MultiSelectorProps {
  className?: string;
  value?: string[];
  menuCss?: SerializedStyles;
  name: string;
  options: Option[];
  error?: boolean;
  disabled?: boolean;
  placeholder?: string;
  hasSelectAll?: boolean;
  isRoundInput?: boolean;
  onChange?: (items: string[]) => void;
  onClose?: () => void;
  initialValues?: string[];
}

const MultiSelector = (props: MultiSelectorProps) => {
  const {
    name,
    className,
    menuCss,
    value = [],
    options,
    error = false,
    disabled,
    hasSelectAll = true,
    placeholder,
    onChange,
    onClose,
    isRoundInput = true,
    initialValues,
  } = props;
  const { t } = useTranslation();

  // Items
  const selectedValue = getInitialSelectedItems(value, options);
  const setSelectedValue = (items: Option[]) => {
    if (onChange) {
      onChange(items.map(item => item.value));
    }
  };
  const removeValue = (item: Option) => {
    const isInitalValue = initialValues?.includes(item?.value);
    if (isInitalValue) {
      return;
    }
    setSelectedValue(selectedValue.filter(i => i !== item));
  };

  return (
    <BaseSelector<ValueType>
      name={name}
      className={className}
      items={{ selectedValue, setSelectedValue, removeValue }}
      error={error}
      options={options}
      disabled={disabled}
      disabledInputBox={false}
      placeholder={!selectedValue?.length ? placeholder : ''}
      onClose={onClose}
      MenuControlIcon={MenuControlIcon}
      isRoundInput={isRoundInput}
      renderSelectedValue={({ selectedValue: values }) =>
        values.length > 2 ? (
          <SelectedCount>{t(`Selected`, { count: values.length })}</SelectedCount>
        ) : (
          values.length > 0 && <SelectedValues>{values.map(item => item.label).join(', ')} </SelectedValues>
        )
      }
      renderMenu={({ items, search, menu }) => {
        const isSelectedAll = search.filteredValue.every(v => items.selectedValue.includes(v));
        const isSelectedNoting = items.selectedValue.length === 0;

        const handleSelectAll = (_: React.MouseEvent<HTMLDivElement>) => {
          if (disabled) {
            return;
          }
          // can unselect all values exept initialValues
          const allUnselectedValues = initialValues
            ? search.filteredValue.filter(val => initialValues?.includes(val.value))
            : [];
          items.setSelectedValue(isSelectedAll ? allUnselectedValues : search.filteredValue);
        };

        const handleClickItem = (item: Option) => (_: React.MouseEvent<HTMLDivElement>) => {
          if (disabled) {
            return;
          }
          const isSelected = items.selectedValue.includes(item);

          // Remove Item
          if (isSelected) {
            items.removeValue(item);

            return;
          }
          // Add Item
          items.setSelectedValue([...items.selectedValue, item]);
        };

        return (
          <>
            {hasSelectAll && (
              <SelectAll
                disabled={disabled}
                isSelectedAll={isSelectedAll}
                isSelectedNoting={isSelectedNoting}
                handleClick={handleSelectAll}
              />
            )}
            <MenuAndApplyWrapper isRoundInput={isRoundInput} isError={error}>
              <MenuWrapper css={menuCss}>
                <MenuItems
                  disabled={disabled}
                  options={search.filteredValue}
                  handleClick={handleClickItem}
                  checkIsSelected={(option: Option) =>
                    items.selectedValue.map(item => item.value).includes(option.value)
                  }
                />
              </MenuWrapper>
              <Apply handleClick={() => menu.setIsOpen(false)} />
            </MenuAndApplyWrapper>
          </>
        );
      }}
    />
  );
};

const SelectedCount = styled.span`
  margin-left: 10px;
`;

const SelectedValues = styled.div`
  height: 16px;
  overflow: hidden;
  word-break: break-all;
  margin: 2px 0 2px 8px;
  padding: 2px;
`;

const MenuWrapper = styled.div`
  max-height: 210px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  z-index: 10;
  background-color: #fff;
  width: fill-available;
  transition: opacity 0.1s ease;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
`;

const MenuAndApplyWrapper = styled.div<{ isRoundInput: boolean; isError: boolean }>`
  border: ${({ isError }) => `1px solid ${isError ? 'tomato' : '#179cd7'}`};
  border-top: none;
  border-radius: ${({ isRoundInput }) => (isRoundInput ? '0 0 20px 20px' : '3px')};
  padding-bottom: 10px;
`;

export default MultiSelector;
