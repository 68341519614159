import { AccountNameType, getSocialAccountTypeByAccountName } from '@src/libs/SocialMedia';
import { SocialAccountType, CampaignSocialMediaType } from '@src/__generated__/globalTypes';
import { MarketplaceJoinableAccounts_marketplaceJoinableAccounts } from './__generated__/MarketplaceJoinableAccounts';
import { JoinableCombinedAccount } from './types';

export const getFormattedAccounts = (
  accountsData: MarketplaceJoinableAccounts_marketplaceJoinableAccounts,
  campaignType: CampaignSocialMediaType
) => {
  const formattedAccountsData = (Object.keys(accountsData) as AccountNameType[]).reduce<JoinableCombinedAccount[]>(
    (total, accountName: AccountNameType) => {
      if (accountName === '__typename') {
        return total;
      }
      if (accountName === 'facebookAccount') {
        const facebookSocialAccount = accountsData[accountName];

        if (facebookSocialAccount === null || campaignType !== CampaignSocialMediaType.FACEBOOK) {
          return total;
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { __typename, ...facebookAccountData } = facebookSocialAccount;

        return [...total, { ...facebookAccountData, socialMedia: SocialAccountType.FACEBOOK }];
      }

      return [
        ...total,
        // @ts-ignore -> join Union issue https://github.com/microsoft/TypeScript/pull/31023
        ...accountsData[accountName].map(({ username, avatar, id, followersCount, disabled, isBusiness }) => ({
          username,
          avatar,
          id,
          followersCount,
          disabled,
          socialMedia: getSocialAccountTypeByAccountName(accountName),
          isBusiness,
        })),
      ];
    },
    []
  );

  return formattedAccountsData;
};
