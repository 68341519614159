import React from 'react';
import styled from '@emotion/styled';
import AddressInformationForm from '../../../components/organisms/Profile/AddressInformationForm';
import NavigationHeader from '../../../components/organisms/Header/NavigationHeader';

const Template = () => (
  <>
    <NavigationHeader />
    <Wrapper>
      <AddressInformationForm />
    </Wrapper>
  </>
);

export default Template;

const Wrapper = styled.section`
  width: 100%;
  margin-top: 48px;
`;
