import React from 'react';
import iconSearchJob from '../../../../assets/icon/tutorialSearchJobBinocular.svg';
import imgStep1 from '../../../../assets/img/tutorial/searchJob/step1.jpg';
import imgStep2 from '../../../../assets/img/tutorial/searchJob/step2.jpg';
import { mainRed, mainWhite } from '../../../../libs/pallete';
import BaseTutorial, { TutorialSteps } from '../BaseTutorial';
import { TutorialName } from '../../../../__generated__/globalTypes';

const TutorialSearchJob = () => {
  const steps: TutorialSteps[] = [
    {
      titleIcon: iconSearchJob,
      title: 'SearchJobStepOne',
      img: imgStep1,
      heading: 'SearchJobStepOne',
      text: 'SearchJobStepOne',
      backButton: { text: 'Skip', palleteColor: mainWhite, borderColor: '#dee5ca' },
      nextButton: { text: 'Next', palleteColor: mainRed },
    },
    {
      titleIcon: iconSearchJob,
      title: 'SearchJobStepTwo',
      img: imgStep2,
      heading: 'SearchJobStepTwo',
      text: 'SearchJobStepTwo',
      backButton: { text: 'Prev', palleteColor: mainWhite, borderColor: '#dee5ca' },
      nextButton: { text: 'Start', palleteColor: mainRed },
    },
  ];

  return <BaseTutorial name={TutorialName.SEARCH_JOB} steps={steps} />;
};

export default TutorialSearchJob;
