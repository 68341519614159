import React from 'react';
import { SocialMediaAccountType } from './types';
import Template from './template';

interface ProfileDetailProps {
  availableSocialMediaAccounts: SocialMediaAccountType[];
}

const AccountDetails = (props: ProfileDetailProps) => <Template {...props} />;

export default AccountDetails;
