import React from 'react';
import { Redirect } from 'react-router';
import { useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import PostDetail from './PostDetail';
import * as INFLUENCER_YT_ANALYTICS_POST_DETAILS from './InfluencerYoutubeAnalyticsPostDetail.graphql';
import {
  InfluencerYoutubeAnalyticsPost,
  InfluencerYoutubeAnalyticsPostVariables,
} from './__generated__/InfluencerYoutubeAnalyticsPost';

interface TemplateProps {
  postId: number;
  socialAccountId: number;
  avatarUrl?: string;
  accountName?: string;
}
const Template = (props: TemplateProps) => {
  const { postId, socialAccountId, accountName, avatarUrl } = props;
  const { t, history, enqueueSnackbar, pathname } = useQueryHelper();

  const { data, loading, error } = useQuery<InfluencerYoutubeAnalyticsPost, InfluencerYoutubeAnalyticsPostVariables>(
    INFLUENCER_YT_ANALYTICS_POST_DETAILS,
    {
      variables: { youtubeAnalyticsPostId: postId, socialAccountId },
      onError: () => {
        history.push(pathname);
        enqueueSnackbar(t('Post was not found'), { variant: 'error' });
      },
    }
  );

  if (error) {
    return <Redirect to="/404" />;
  }

  return (
    <PostDetail
      {...props}
      video={data?.analyticsPost || null}
      loading={loading}
      avatarUrl={avatarUrl}
      accountName={accountName}
    />
  );
};

export default Template;
