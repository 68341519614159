import { useFilter } from '@src/components/organisms/AnalyticsShared/BasePostListFilter/useFilter';
import { getThirtyDaysAgo, getToday } from '@src/libs/date';
import { usePaging } from '@src/libs/paging';
import React from 'react';
import { useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';
import { useAuthData } from '@src/libs/hooks';
import Template from './template';

const IgPostFeed = () => {
  const { userId } = useAuthData();

  if (!userId) {
    return <Redirect to="/home" />;
  }
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [id, stda, enda] = [
    searchParams.get('id') as string,
    searchParams.get('stda') as string,
    searchParams.get('enda') as string,
  ];

  const currentPage = usePaging();
  const { filter, setFilter } = useFilter({
    startDate: stda || getThirtyDaysAgo(),
    endDate: enda || getToday(),
  });

  return (
    <Template accountId={Number(id)} currentPage={currentPage} filter={filter} setFilter={setFilter} userId={userId} />
  );
};

export default IgPostFeed;
