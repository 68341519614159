import styled from '@emotion/styled';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import SearchForm from '@src/components/molecules/SearchForm';
import { DayPickerRange } from '@src/components/molecules/DayPicker';
import { useMediaQuery } from 'react-responsive';
import { ViewportBreakpoint } from '@src/libs/theme';
import { FormValues } from './types';

const PostFeedFilters = () => {
  const { t } = useTranslation();
  const { handleSubmit, values, handleBlur, handleChange, setFieldValue } = useFormikContext<FormValues>();
  const hasCalendarUi = useMediaQuery({ minWidth: ViewportBreakpoint.MEDIUM });

  const handleBlurAndSubmit = (e: any) => {
    handleBlur(e);
    handleSubmit(e);
  };
  const handleChangeAndSubmitPeriod = (e: any) => {
    setFieldValue('period', e);
    handleSubmit(e);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Wrapper>
        <SearchForm
          placeholder={t('SearchForm.Search by Keywords')}
          value={values.keyword}
          name="keyword"
          onBlur={handleBlurAndSubmit}
          onChange={handleChange}
          onEnterKeyPress={handleSubmit}
          css={{ flex: 1 }}
        />
        {hasCalendarUi ? (
          <DayPickerRange
            period={values.period}
            handleChangePeriod={handleChangeAndSubmitPeriod}
            disabledRange={{ after: new Date() }}
            // css={styles.dayPicker}
          />
        ) : null}
      </Wrapper>
    </form>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 16px;
  gap: 16px;
`;

export default PostFeedFilters;
