import React, { useContext } from 'react';
import { Redirect } from 'react-router';
import { ListIndicator } from '../../components/molecules/Indicator';
import { AdminStore } from '../../Context';
import { useQueryHelper } from '../../libs/hooks';
import { SocialAccountType } from '../../__generated__/globalTypes';
import Empty from '../../components/atoms/Empty';
import { SocialMediaAccountType } from '../../components/organisms/ProfileDetail/types';
import Template from './template';
import { useInfluencerProfileQuery } from './useInfluencerProfileQuery';

const Profile = () => {
  const {
    state: { userId },
  } = useContext(AdminStore);
  const { t, enqueueSnackbar } = useQueryHelper();
  if (!userId) {
    return <Redirect to="/" />;
  }

  const { loading, error, profileInfo: influencerProfileV2 } = useInfluencerProfileQuery({
    influencerId: userId,
  });

  if (loading) {
    return <ListIndicator />;
  }

  if (error === 'NOT_FOUND' || !influencerProfileV2) {
    if (error) {
      enqueueSnackbar(t(error), { variant: 'error' });
    }

    return <Empty />;
  }

  const categories = influencerProfileV2.categories.map(category => category.description);

  const availableSocialMediaAccounts: SocialMediaAccountType[] = [
    ...(influencerProfileV2.youtubeAccounts || []).map(social => ({
      id: social.id,
      name: social.name || 'YouTube',
      url: social.url,
      socialAccountType: SocialAccountType.YOUTUBE,
      analyticsEnabled: social.analyticsEnabled,
    })),
    ...(influencerProfileV2.instagramAccounts || []).map(social => ({
      id: social.id,
      name: social.name || 'Instagram',
      url: social.url,
      socialAccountType: SocialAccountType.INSTAGRAM,
      analyticsEnabled: social.analyticsEnabled,
    })),
    ...(influencerProfileV2.twitterAccounts || []).map(social => ({
      id: social.id,
      name: social.name || 'Twitter',
      url: social.url,
      socialAccountType: SocialAccountType.TWITTER,
    })),
    ...(influencerProfileV2.facebookAccount
      ? [
          {
            id: influencerProfileV2.facebookAccount.id,
            name: influencerProfileV2.facebookAccount.name || 'Facebook',
            url: influencerProfileV2.facebookAccount.url,
            socialAccountType: SocialAccountType.FACEBOOK,
          },
        ]
      : []),
    ...(influencerProfileV2.facebookPages || []).map(social => ({
      id: social.id,
      name: social.name || 'Facebook Page',
      url: social.url,
      socialAccountType: SocialAccountType.FACEBOOK_PAGE,
    })),
    ...(influencerProfileV2.tiktokAccounts || []).map(social => ({
      id: social.id,
      name: social.name || 'TikTok',
      url: social.url,
      socialAccountType: SocialAccountType.TIKTOK,
    })),
  ];

  const getSocialAccountFollowers = (social: { followersCount: number | null }[] | null) =>
    social?.length ? social.reduce((a, c) => (c?.followersCount !== null ? c?.followersCount + a : a), 0) : null;

  const instagramFollowers = getSocialAccountFollowers(influencerProfileV2.instagramAccounts);
  const twitterFollowers = getSocialAccountFollowers(influencerProfileV2.twitterAccounts);
  const tiktokFollowers = getSocialAccountFollowers(influencerProfileV2.tiktokAccounts);
  const youtubeFollowers = getSocialAccountFollowers(influencerProfileV2.youtubeAccounts);
  const facebookAndFacebookPagesAccounts = [
    ...(influencerProfileV2?.facebookPages || [{ followersCount: null }]),
    influencerProfileV2?.facebookAccount || { followersCount: null },
  ];
  const facebookFollowers = getSocialAccountFollowers(
    facebookAndFacebookPagesAccounts.filter(social => social?.followersCount !== null)
  );

  const influencerProfile = {
    ...influencerProfileV2,
    country: influencerProfileV2.country ? influencerProfileV2.country.name : null,
    region: influencerProfileV2.region ? influencerProfileV2.region.name : null,
    categories: categories || null,
    followersCount: influencerProfileV2.followersCount,
    facebookFollowers,
    instagramFollowers,
    twitterFollowers,
    tiktokFollowers,
    youtubeFollowers,
    tags: influencerProfileV2.tags,
    avgEngagementRate: influencerProfileV2.engagement.engagementRate,
    avgEngagementNumber: influencerProfileV2.engagement.averageEngagement,
  };

  return <Template influencerProfile={influencerProfile} availableSocialMediaAccounts={availableSocialMediaAccounts} />;
};

export default Profile;
