import React from 'react';
import { useTranslation } from 'react-i18next';
import { bigIntFormatter, intlNumberFormat } from '@src/libs/format';
import { getGrowthArrow, getGrowthPrefix } from '@src/components/organisms/AnalyticsShared/utils';
import * as Styled from '@src/components/organisms/AnalyticsShared/AverageEngagementStyled';
import * as StyledOverview from '@src/components/organisms/AnalyticsShared/Overview/StyledComponents';
import DataOpacityWrapper, { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import { ViewportBreakpoint } from '@src/libs/theme';
import { useMediaQuery } from 'react-responsive';
import { FacebookPageAnalyticsStats } from '../../types';
import { getAverageEngagementIconAndTitle } from './helpers';

const AverageEngagement = (props: FacebookPageAnalyticsStats) => {
  const { t } = useTranslation();
  const isDirectionColumn = useMediaQuery({ maxWidth: ViewportBreakpoint.MOBILE });
  const {
    averagePostsPerWeek,
    averageReactions,
    averageComments,
    averageImpressions,
    averageShares,
    averageViews,
  } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { __typename, ...averageEngagements } = props;

  const dataNotAvailable =
    !averagePostsPerWeek &&
    !averageReactions &&
    !averageShares &&
    !averageViews &&
    !averageComments &&
    !averageImpressions;
  const engagementValues = (!dataNotAvailable ? Object.keys(averageEngagements) : []) as Array<
    keyof typeof averageEngagements
  >;
  const { engagementStyles } = Styled;

  return (
    <DataOpacityWrapper
      header={<SectionHeader>{t('Average Engagement')}</SectionHeader>}
      dataNotAvailable={dataNotAvailable}
      css={engagementStyles.container}
    >
      <Styled.MainWrapper css={engagementStyles.gridContainer}>
        {engagementValues.map(engKey => {
          const avgGrowth = props[engKey] ? props[engKey].growth : null;
          const avgTotal = props[engKey] ? props[engKey].value : null;
          const total = bigIntFormatter(avgTotal, 0);
          const growth = bigIntFormatter(avgGrowth, 0);
          const { title, icon } = getAverageEngagementIconAndTitle(engKey);
          const hoverTotal = intlNumberFormat(avgTotal);
          const hoverGrowth = intlNumberFormat(avgGrowth);

          if (isDirectionColumn) {
            return (
              <StyledOverview.EngagementGridCell key={engKey}>
                <StyledOverview.PartWithIcon>
                  <StyledOverview.EngagementTitle text={t(title)} lines={1} />
                  <StyledOverview.EngagementTileIcon src={icon} css={engagementStyles.icon} />
                </StyledOverview.PartWithIcon>

                <StyledOverview.EngagementNumbers>
                  <StyledOverview.TotalNumber title={hoverTotal}>{total}</StyledOverview.TotalNumber>
                  {!!avgGrowth && (
                    <StyledOverview.Growth isPositive={Number(avgGrowth) > 0} title={hoverGrowth}>{`${getGrowthPrefix(
                      avgGrowth
                    )}${growth}  ${getGrowthArrow(avgGrowth)}`}</StyledOverview.Growth>
                  )}
                </StyledOverview.EngagementNumbers>
              </StyledOverview.EngagementGridCell>
            );
          }

          return (
            <Styled.EngagementDataRow key={engKey}>
              <Styled.EngagementHalf isDirectionColumn={isDirectionColumn}>
                <img src={icon} css={{ width: 32, height: 32 }} />
                <Styled.Label>{t(title)}</Styled.Label>
              </Styled.EngagementHalf>
              <Styled.EngagementHalf>
                <Styled.GrowthWrapper>
                  <Styled.Value title={hoverTotal}>{total}</Styled.Value>
                  {!!avgGrowth && (
                    <Styled.Identifier isPositive={Number(avgGrowth) > 0} title={hoverGrowth}>{`${getGrowthPrefix(
                      avgGrowth
                    )}${growth}  ${getGrowthArrow(avgGrowth)}`}</Styled.Identifier>
                  )}
                </Styled.GrowthWrapper>
              </Styled.EngagementHalf>
            </Styled.EngagementDataRow>
          );
        })}
      </Styled.MainWrapper>
    </DataOpacityWrapper>
  );
};

export default AverageEngagement;
