import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { getThirtyDaysAgo, getToday } from '@src/libs/date';
import { usePageLayout, useUpdateDocumentTitle } from '@src/libs/hooks';
import INFLUENCER_COMPARE_ACCOUNTS_CARDS from '@src/components/organisms/AnalyticsYoutube/Compare/InfluencerYoutubeAnalyticsCompareAccountCards.graphql';
import { useQueryHelper } from '@src/libs/hooks';
import {
  InfluencerYoutubeAnalyticsCompareAccountCards,
  InfluencerYoutubeAnalyticsCompareAccountCardsVariables,
} from '@src/components/organisms/AnalyticsYoutube/Compare/__generated__/InfluencerYoutubeAnalyticsCompareAccountCards';
import styled from '@emotion/styled';
import BasePostFilter from '@src/components/organisms/AnalyticsShared/BasePostListFilter';
import { useFilter } from '@src/components/organisms/AnalyticsShared/BasePostListFilter/useFilter';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import TableVideoList from './TableVideoList';
import { TabsAccounts } from './TabsAccounts';
import { CompareAccountType } from './types';
import DesktopAccountsTabs from './DesktopAccountsTabs';

interface Props {
  socialAccountId: string;
}
const InfluencerYoutubeCompareFeedVideos = ({ socialAccountId }: Props) => {
  const { enqueueSnackbar, t, search } = useQueryHelper();
  const { filter, setFilter } = useFilter({ startDate: getThirtyDaysAgo(), endDate: getToday() });
  const { setDocumentAndAppTitles } = useUpdateDocumentTitle({
    title: 'documentTitle.Post List',
    pageTitle: 'Post List',
    href: `/analytics:compare${search}`,
  });

  const [allCompareAccounts, setAllCompareAccounts] = useState<CompareAccountType[]>([]);

  useQuery<InfluencerYoutubeAnalyticsCompareAccountCards, InfluencerYoutubeAnalyticsCompareAccountCardsVariables>(
    INFLUENCER_COMPARE_ACCOUNTS_CARDS,
    {
      skip: !socialAccountId,
      variables: {
        socialAccountId: Number(socialAccountId),
      },
      onCompleted: ({ influencerYoutubeAnalyticsCompareAccountCards }) => {
        let mainAccount: CompareAccountType[] = [];
        let compareAccounts: CompareAccountType[] = [];
        if (influencerYoutubeAnalyticsCompareAccountCards.mainAccount) {
          const {
            accountId,
            avatarUrl,
            channelName,
            elementId,
            profileUrl,
          } = influencerYoutubeAnalyticsCompareAccountCards?.mainAccount;
          mainAccount = [
            {
              accountId,
              avatarUrl,
              channelName,
              elementId,
              isMain: true,
              isSelected: true,
              profileUrl,
            },
          ];

          setDocumentAndAppTitles({
            title: 'PostListWithAccountName',
            interpolation: {
              title: channelName,
            },
            pageTitle: 'PostListWithAccountName',
            href: `/analytics:compare${search}`,
          });
        }

        if (influencerYoutubeAnalyticsCompareAccountCards.compareAccounts) {
          compareAccounts = influencerYoutubeAnalyticsCompareAccountCards.compareAccounts.map(account => {
            const { accountId, avatarUrl, channelName, elementId, profileUrl } = account;

            return {
              accountId,
              avatarUrl,
              channelName,
              elementId,
              isMain: false,
              isSelected: false,
              profileUrl,
            };
          });
        }

        setAllCompareAccounts([...mainAccount, ...compareAccounts]);
      },
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  const selectedAccount = allCompareAccounts.find(account => account.isSelected);
  const { isMobileView } = usePageLayout();

  return (
    <>
      <Container>
        {isMobileView ? (
          <TabsAccounts
            allCompareAccounts={allCompareAccounts}
            setAllCompareAccounts={setAllCompareAccounts}
            isMobile={isMobileView}
          />
        ) : (
          <>
            <div css={{ margin: '24px 24px 0' }}>
              <BackNavigator title="Video List" to={`/analytics:compare${search}`} />
            </div>
            <DesktopAccountsTabs
              allCompareAccounts={allCompareAccounts}
              setAllCompareAccounts={setAllCompareAccounts}
            />
          </>
        )}

        <div css={{ margin: isMobileView ? '16px 16px 0;' : '0 24px 0;' }}>
          <BasePostFilter
            filter={filter}
            setFilter={setFilter}
            isMobile={isMobileView}
            css={{
              border: 'none',
              boxShadow: '0px 0px 1px #dee5ec, 0px 1px 2px #dee5ec',
            }}
          />
        </div>
        {selectedAccount ? <TableVideoList account={selectedAccount} filter={filter} isMobile={isMobileView} /> : null}
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export default InfluencerYoutubeCompareFeedVideos;
