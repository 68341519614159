import { Formik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  CreateEngagementPostInputForInfluencerV2,
  EngagementCampaignPostStatus,
  UpdateEngagementPostInputForInfluencerV2,
} from '@src/__generated__/globalTypes';
import CREATE_CAMPAIGN_POST from './mutations/CreateCampaignPost.graphql';
import UPDATE_CAMPAIGN_POST from './mutations/UpdateCampaignPost.graphql';
import { CreateCampaignPost, CreateCampaignPostVariables } from './mutations/__generated__/CreateCampaignPost';
import { UpdateCampaignPost, UpdateCampaignPostVariables } from './mutations/__generated__/UpdateCampaignPost';
import PostForm, { PostDetails } from './PostForm';
import CAMPAIGN_INFLUENCER_SOCIAL_ACCS from './queries/CampaignInfluencerSocialAccounts.graphql';
import CAMPAIGN_POST_DETAILS from './queries/CampaignPostDetails.graphql';
import {
  CampaignInfluencerSocialAccounts,
  CampaignInfluencerSocialAccountsVariables,
} from './queries/__generated__/CampaignInfluencerSocialAccounts';
import { CampaignPostDetails, CampaignPostDetailsVariables } from './queries/__generated__/CampaignPostDetails';

interface IndexProps {
  engagementId: number;
  postId?: number;
}

const Index = ({ engagementId, postId }: IndexProps) => {
  const [postDetails, setPostDetails] = useState<PostDetails>({
    campaignId: engagementId,
    content: '',
    materialsUrl: [],
    planedPostDate: '',
    socialAccountId: null,
    socialAccountMedia: null,
    socialMedia: null,
  });
  const [status, setStatus] = useState<EngagementCampaignPostStatus>(EngagementCampaignPostStatus.REVIEWING);
  const { enqueueSnackbar, history, t } = useQueryHelper();
  const validationSchema = yup.object().shape({
    content: yup.string().required('requiredEngagementPostContent'),
    planedPostDate: yup.string().required('requiredEngagementPlannedPostDate'),
    socialAccountId: yup.number().nullable().required('requiredSocialAccountId'),
    socialMedia: yup.string().nullable().required('requiredIgSocialMedia'),
  });

  useQuery<CampaignPostDetails, CampaignPostDetailsVariables>(CAMPAIGN_POST_DETAILS, {
    skip: !postId,
    ...(postId && {
      variables: {
        pk: postId,
      },
    }),
    onCompleted: ({ engagementPostForInfluencerV2 }) => {
      if (engagementPostForInfluencerV2) {
        const {
          content,
          id,
          materials,
          planedPostDate,
          postStatus,
          socialAccountId,
          socialAccountMedia,
          socialMedia,
        } = engagementPostForInfluencerV2;
        const postInfo = {
          campaignId: engagementId,
          content,
          id,
          materialsUrl: materials?.map(material => material.url) || [],
          planedPostDate,
          socialAccountId,
          socialAccountMedia,
          socialMedia,
        };
        setPostDetails(postInfo);
        setStatus(postStatus);
      }
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const { data } = useQuery<CampaignInfluencerSocialAccounts, CampaignInfluencerSocialAccountsVariables>(
    CAMPAIGN_INFLUENCER_SOCIAL_ACCS,
    {
      variables: {
        pk: engagementId,
      },
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  const [createCampaignPost] = useMutation<CreateCampaignPost, CreateCampaignPostVariables>(CREATE_CAMPAIGN_POST, {
    refetchQueries: ['EngagementCampaignPosts'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInCreating'), { variant: 'success' });
      history.push(`/casting/your_job/${engagementId}/post`);
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [updateCampaignPost] = useMutation<UpdateCampaignPost, UpdateCampaignPostVariables>(UPDATE_CAMPAIGN_POST, {
    onCompleted: () => {
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  function onSubmit(values: PostDetails) {
    if (values.id) {
      updateCampaignPost({
        variables: {
          input: values as UpdateEngagementPostInputForInfluencerV2,
        },
      });
    } else {
      createCampaignPost({
        variables: {
          input: values as CreateEngagementPostInputForInfluencerV2,
        },
      });
    }
  }

  const socialAccounts = data?.engagementPostSocialAccountsForInfluencer || [];

  return (
    <Formik
      enableReinitialize
      initialValues={postDetails}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <PostForm isEdit={!!postId} socialAccounts={socialAccounts} status={status} />
    </Formik>
  );
};

export default Index;
