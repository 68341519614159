import React from 'react';
import { css } from '@emotion/core';
import { SliderComponents, StyledComponents, SliderCss } from '@src/components/molecules/SliderTable';
import { HeaderColumn, AmountColumn } from '@src/components/atoms/List';
import { SortActionType } from '@src/libs/filter';
import { formatIntNumber, formatPercent, bigIntFormatter } from '@src/libs/format';
import { YoutubeAnalyticsTagRankingSortOrder, ORDER } from '@src/__generated__/globalTypes';
import { useQueryHelper } from '@src/libs/hooks';
import { InfluencerYoutubeAnalyticsTagsTopRanking_tagsTopRanking_tags } from './__generated__/InfluencerYoutubeAnalyticsTagsTopRanking';
import { getYtTagRankingColumns } from './helpers';
import * as TagStyled from './StyledComponents';

interface UserAnalysisType extends SortActionType<YoutubeAnalyticsTagRankingSortOrder> {
  tags?: InfluencerYoutubeAnalyticsTagsTopRanking_tagsTopRanking_tags[];
  firstIndex: number;
  isDesktopView: boolean;
}
const TagRankingTable = (props: UserAnalysisType) => {
  const {
    tags,
    sort: { field, order },
    setSort,
    firstIndex,
    isDesktopView,
  } = props;
  const { t } = useQueryHelper();

  if (!isDesktopView) {
    return (
      <>
        {tags?.map((tag, i) => (
          <TagStyled.TagBox key={i}>
            <TagStyled.TagHeading>
              <TagStyled.TagIndex css={i < 3 ? { background: '#3892E5' } : ''}>{i + 1}</TagStyled.TagIndex>
              <TagStyled.TagText>{tag.tag}</TagStyled.TagText>
            </TagStyled.TagHeading>
            <TagStyled.EngagmentsGrid>
              {getYtTagRankingColumns().map(el => (
                <div key={el.title}>
                  <TagStyled.Label text={t(`HeaderColumn.${el.title}`)} lines={1} />
                  <TagStyled.Value>{bigIntFormatter(tag[el.tagkey])}</TagStyled.Value>
                </div>
              ))}
            </TagStyled.EngagmentsGrid>
          </TagStyled.TagBox>
        ))}
      </>
    );
  }

  return (
    <>
      <SliderComponents.Container>
        <SliderComponents.SliderSection>
          <SliderComponents.SliderTable>
            <thead>
              <SliderComponents.HeaderRow css={{ backgroundColor: '#f6f8fa' }}>
                <SliderComponents.StickyHeaderTdWrapper>
                  <TagStyled.StyledHeader
                    title="Tag"
                    css={css`
                      width: 230px;
                      padding: 0;
                    `}
                    isTable={false}
                  />
                </SliderComponents.StickyHeaderTdWrapper>
                {/* Slider */}
                {getYtTagRankingColumns().map(({ title, orderField }) => (
                  <HeaderColumn
                    key={title}
                    title={title}
                    {...(orderField && {
                      activeAsc: field === orderField && order === ORDER.ASC,
                      activeDesc: field === orderField && order === ORDER.DESC,
                      sortAsc: () => setSort({ field: orderField, order: ORDER.ASC }),
                      sortDesc: () => setSort({ field: orderField, order: ORDER.DESC }),
                    })}
                    css={css`
                      text-transform: capitalize;
                    `}
                  />
                ))}
              </SliderComponents.HeaderRow>
            </thead>

            <tbody>
              {tags?.map((tagData, index) => {
                const {
                  tag,
                  posts,
                  views,
                  averageLike,
                  averageDislike,
                  averageComment,
                  averageEngagement,
                  averageShare,
                  engagementRate,
                } = tagData;

                return (
                  <StyledComponents.StyledRowNew css={[SliderCss.paddingSides, styles.rowStyles]} key={index}>
                    <SliderComponents.StickyBodyRowWrapper>
                      <TagStyled.TagWrapped>
                        <TagStyled.TagNumberBlock isTopThree={firstIndex + index <= 3}>
                          {firstIndex + index}
                        </TagStyled.TagNumberBlock>
                        <p title={tag}>{tag}</p>
                      </TagStyled.TagWrapped>
                    </SliderComponents.StickyBodyRowWrapper>
                    <StyledComponents.NumberColumn data={formatIntNumber(posts)} />
                    <StyledComponents.NumberColumn data={formatIntNumber(views)} />
                    <StyledComponents.NumberColumn data={formatIntNumber(averageLike)} />
                    <StyledComponents.NumberColumn data={formatIntNumber(averageDislike)} />
                    <StyledComponents.NumberColumn data={formatIntNumber(averageComment)} />
                    <StyledComponents.NumberColumn data={formatIntNumber(averageEngagement)} />
                    <StyledComponents.NumberColumn data={formatIntNumber(averageShare)} />
                    <AmountColumn data={formatPercent(engagementRate, false)} currency="%" />
                  </StyledComponents.StyledRowNew>
                );
              })}
            </tbody>
          </SliderComponents.SliderTable>
        </SliderComponents.SliderSection>
      </SliderComponents.Container>
    </>
  );
};

const styles = {
  rowStyles: css`
    height: 40px;
    border-bottom: 1px solid #dee5ec;
  `,
};

export default TagRankingTable;
