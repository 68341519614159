import * as React from 'react';
import PostForm from '../../components/organisms/EngagementPost/EngagementPostForm';
import { useUpdateDocumentTitle } from '../../libs/hooks';
import NavigationHeader from '../../components/organisms/Header/NavigationHeader';

export interface TemplateProps {
  id: number;
  postId: number;
}

const Template = ({ id, postId }: TemplateProps) => {
  const title = 'pageTitle.Edit Post';
  const pageTitle = 'Edit Post';
  const href = `/job/engagement/${id}/post`;
  useUpdateDocumentTitle({ title, pageTitle, href });

  return (
    <>
      <NavigationHeader />
      <PostForm id={id} postId={postId} />
    </>
  );
};

export default Template;
