import * as React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useMutation } from '@apollo/client';
import { ApolloError } from '@apollo/client';
import { PasswordResetSchema } from '@src/libs/validation';
import { useQueryHelper } from '@src/libs/hooks';
import { UNEXPECTED_ERROR } from '@src/libs/error';
import PasswordForm from './PasswordForm';
import { FormValues } from './types';
import { ResetPassword, ResetPasswordVariables } from './__generated__/ResetPassword';
import * as RESET_PASSWORD from './ResetPassword.graphql';

interface PasswordFormComponentProps {
  isPasswordReset?: boolean;
  token: string;
}

const PasswordFormComponent = (props: PasswordFormComponentProps) => {
  const { isPasswordReset, token } = props;
  const { t, enqueueSnackbar, history } = useQueryHelper();

  const [resetPassword] = useMutation<ResetPassword, ResetPasswordVariables>(RESET_PASSWORD);

  const handleSubmit = async (payload: FormValues, { setSubmitting, setStatus }: FormikHelpers<FormValues>) => {
    const { password } = payload;
    const variables = {
      input: {
        token,
        password,
      },
    };
    setSubmitting(true);
    await resetPassword({ variables })
      .then(result => {
        if (!!result?.data?.resetPassword) {
          enqueueSnackbar(t('succeededInUpdatingPassword'), { variant: 'success' });
          history.push('/');
        } else {
          setStatus(t(UNEXPECTED_ERROR));
        }
      })
      .catch((error: ApolloError) => {
        enqueueSnackbar(t('failedToUpdatePassword'), { variant: 'error' });
        const errorMessage = error.message || UNEXPECTED_ERROR;
        console.error(errorMessage);
        enqueueSnackbar(t(errorMessage), { variant: 'error' });
      });
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ password: '', passwordConfirm: '' }}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={PasswordResetSchema}
    >
      <PasswordForm isPasswordReset={isPasswordReset} />
    </Formik>
  );
};

export default PasswordFormComponent;
