import styled from '@emotion/styled';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import * as React from 'react';

const styles = createStyles({
  tooltip: {
    fontSize: '12px',
    letterSpacing: '0.02em',
  },
});

interface LabelProps extends WithStyles<typeof styles> {
  className?: string;
  title: string;
  isRequired?: boolean;
  help?: string;
  iconMargin?: string;
}

const Label = (props: LabelProps) => {
  const { className, title, isRequired, help, classes, iconMargin } = props;

  return (
    <Wrapper className={className}>
      {title}
      {isRequired && <RequiredIcon>&nbsp;*</RequiredIcon>}
      {help && (
        <Tooltip title={help} placement="left" classes={{ tooltip: classes.tooltip }} enterTouchDelay={100}>
          <HelpIcon className="material-icons" iconMargin={iconMargin}>
            help_outline
          </HelpIcon>
        </Tooltip>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.label`
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding: 0 0 2px 4px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #27313b;
`;

const RequiredIcon = styled.span`
  color: tomato;
`;

const HelpIcon = styled.i<{ iconMargin?: string }>`
  margin-left: ${({ iconMargin }) => iconMargin || 'auto'};
  color: #d5d5d5;
  font-size: 16px;
  cursor: pointer;
`;

export default withStyles(styles)(Label);
