import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import Template from '@src/components/organisms/AnalyticsInstagram/Dashboard/InstagramAnalyticsFeedPostDetail/template';
import {
  InfluencerInstagramAnalyticsPostDetails,
  InfluencerInstagramAnalyticsPostDetailsVariables,
} from './__generated__/InfluencerInstagramAnalyticsPostDetails';
import * as INFLUENCER_INSTAGRAM_ANALYTICS_POST_DETAILS from './InfluencerInstagramAnalyticsPostDetails.graphql';

interface AnayticsFeedPostDetailProps {
  postId?: number;
  accountId?: number;
  closeModal: () => void;
}

const AnayticsFeedPostDetail = (props: AnayticsFeedPostDetailProps) => {
  const { accountId, postId, closeModal } = props;
  const { t, enqueueSnackbar, search } = useQueryHelper();

  const selectPostId = postId || Number(new URLSearchParams(search).get('popup'));

  const { data, loading } = useQuery<
    InfluencerInstagramAnalyticsPostDetails,
    InfluencerInstagramAnalyticsPostDetailsVariables
  >(INFLUENCER_INSTAGRAM_ANALYTICS_POST_DETAILS, {
    variables: { postId: selectPostId, socialAccountId: Number(accountId) },
    onError: () => {
      closeModal();
      enqueueSnackbar(t('Post was not found'), { variant: 'error' });
    },
  });

  if (loading) {
    return <CircularProgress size={64} thickness={2.4} color="inherit" />;
  }

  return (
    <Template data={data?.analyticsPostDetails} closeModal={closeModal} accountType={SocialAccountType.INSTAGRAM} />
  );
};

export default AnayticsFeedPostDetail;
