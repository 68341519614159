import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import thanks from '../../../../assets/icon/contactThanks.svg';
import { ViewportBreakpoint } from '../../../../libs/theme';
import { PalleteButton } from '../../../atoms/Button';
import { mainWhite } from '../../../../libs/pallete';

const Thanks = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Icon src={thanks} alt="letter icon" />
      <Title>{t('We received your enquiry')}</Title>
      <Paragraph>{t('Help.Thank you for your enquiry')}</Paragraph>
      <PalleteButton text="Back to campaign" href="/job" palleteColor={mainWhite} />
    </Container>
  );
};

const Paragraph = styled.p`
  margin: 16px 42px 27px;
  font-size: 13px;
  line-height: 1.38;
  color: #27313b;
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  height: max-content;
  margin-top: 48px;
  padding: 48px 16px 16px;
  background-color: #fff;

  @media (min-width: ${ViewportBreakpoint.MEDIUM}px) {
    padding: 48px 64px 24px;

    ${Paragraph} {
      text-align: center;
    }
  }
`;

const Icon = styled.img`
  width: 67px;
  height: 61px;
  margin: 0 auto;
`;

const Title = styled.h6`
  margin-top: 24px;
  text-align: center;
  font-size: 16px;
  line-height: 1.38;
  color: #27313b;
`;

export default Thanks;
