import React from 'react';
import { Global, css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { formatPercent, formatIntNumber } from '@src/libs/format';
import { localizedWeekdays, hourIntervals } from '@src/libs/date';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import HighChart, { Highcharts } from '@src/components/molecules/HighChart';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import DataOpacityWrapper, { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { PostsHabits } from './types';

/* initializing heatmap module */
HighchartsHeatmap(Highcharts);

const minColor = '#eef3f7';
const maxColor = '#ed183e';

interface PostsHabitsProps {
  postsHabit: PostsHabits[];
  className?: string;
  selectedSocialMedia?: SocialAccountType;
}
const PostingHabits = ({ postsHabit, selectedSocialMedia, className }: PostsHabitsProps) => {
  const { t, i18n } = useTranslation();

  const chartData = !postsHabit?.every(data => data.engagementRate === 0)
    ? postsHabit.map(item => ({
        x: item.hour,
        y: item.weekday,
        value: parseFloat(formatPercent(item.engagementRate, false)),
        likes: formatIntNumber(item.averageLikes),
        comments: formatIntNumber(item.averageComments),
        time: `${localizedWeekdays(i18n.language as AppLanguage)[item.weekday]} ${hourIntervals[item.hour]}`,
      }))
    : [];

  const options: Highcharts.Options = {
    chart: {
      type: 'heatmap',
      marginTop: 0,
      inverted: true,
      height: 285,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: hourIntervals.map(item => item.replace(/\s/g, '').toUpperCase()),
      min: 0,
      gridLineColor: 'transparent',
      lineColor: '#FFFFFF',
      opposite: true,
      offset: 35,
      labels: {
        x: 0,
        align: 'right',
        style: {
          fontSize: '11px',
        },
      },
    },
    yAxis: {
      categories: localizedWeekdays(i18n.language as AppLanguage).map(item => item.slice(0, 3)),
      title: {
        text: '',
      },
      min: 0,
      gridLineColor: 'transparent',
      lineColor: '#FFFFFF',
      lineWidth: 2,
      labels: {
        autoRotation: false,
        style: {
          fontSize: '10px',
        },
      },
    },
    colorAxis: {
      min: 0,
      minColor,
      maxColor,
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        type: 'heatmap',
        pointPadding: 2,
        borderColor: 'transparent',
        name: 'Posting Habits',
        data: chartData,
        dataLabels: {
          enabled: false,
        },
      },
    ],
    tooltip: {
      padding: 0,
      useHTML: true,
      borderColor: '#27313b',
      headerFormat: '',
      borderWidth: 0,
      borderRadius: 3,
      pointFormat: `
      <div style="border-radius:3px;box-shadow: 0 3px 10px 0 rgba(39, 49, 59, 0.59);background-color:#27313b;padding:16px">

      <h1 style="font-size:13px;color: #ffffff;margin-bottom:8px">{point.time}</h1>
 
      <div style="display:flex">
          <div style="margin-right:16px;border-left:1px solid #39444f;padding-left:4px">
          <p style="color:#c5d0da;font-size:11px;margin-bottom:2px">${t('General.Likes')}</p>
          <p style="color:#ffffff;font-size:13px">{point.likes}</p>
          </div>
          <div style="margin-right:16px;border-left:1px solid #39444f;padding-left:4px">
          <p style="color:#c5d0da;font-size:11px;margin-bottom:2px">${
            selectedSocialMedia && selectedSocialMedia === SocialAccountType.TWITTER
              ? t('Label.Replies')
              : t('Label.Comments')
          }</p>
            <p style="color:#ffffff;font-size:13px">{point.comments}</p></div>
          <div style="border-left:1px solid #39444f;padding-left:4px">
          <p style="color:#c5d0da;font-size:11px;margin-bottom:2px">${t('General.Engagement Rate')}</p>
            <p style="color:#ffffff;font-size:13px">{point.value}%</p>
          </div>
        </div>`,
      footerFormat: '</div>',
    },
  };

  return (
    <DataOpacityWrapper
      header={<SectionHeader>{t('Posting Habits')}</SectionHeader>}
      dataNotAvailable={!postsHabit || postsHabit.every(data => data.engagementRate === 0)}
      className={className}
    >
      {/* Setting tooltip arrow color */}
      <Global
        styles={css`
          .highcharts-tooltip path {
            fill: #27313b;
          }
        `}
      />
      <HighChart options={options} />
    </DataOpacityWrapper>
  );
};

export default PostingHabits;
