import styled from '@emotion/styled';

export const ReasonReportWrapper = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  align-items: flex-start;
  background-color: #fff;
  border: 1px solid #dee5ec;
  border-left: 4px solid #ffb63d;
  border-radius: 3px;
  box-shadow: 0 1px 5px 0 #27313b33;
  box-sizing: border-box;
  padding: 16px;

  /* for mobile Safari */
  flex: none;
`;

export const WarningIconColumn = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 12px;
`;

export const WarningIcon = styled.img``;

export const ReasonHeader = styled.h1`
  font-size: 13px;
  font-weight: 600;
  color: #27313b;
`;

export const ReasonBody = styled.div``;

export const ReasonText = styled.p`
  margin-top: 8px;
  font-size: 13px;
  font-weight: normal;
  color: #6e7c89;
`;
