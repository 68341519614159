import React from 'react';
import { Redirect } from 'react-router';
import { useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import PostDetail from './PostDetail';
import * as INFLUENCER_YT_COMPARE_POST_BY_ID from './InfluencerYoutubeComparePostById.graphql';
import {
  InfluencerYoutubeComparePostById,
  InfluencerYoutubeComparePostByIdVariables,
} from './__generated__/InfluencerYoutubeComparePostById';

interface TemplateProps {
  postId: number;
  socialAccountId: number;
  compareAccountId: number;
  avatarUrl?: string;
  accountName?: string;
}
const CompareTemplate = (props: TemplateProps) => {
  const { postId, socialAccountId, compareAccountId } = props;
  const { t, history, enqueueSnackbar, pathname } = useQueryHelper();

  const { data, loading, error } = useQuery<
    InfluencerYoutubeComparePostById,
    InfluencerYoutubeComparePostByIdVariables
  >(INFLUENCER_YT_COMPARE_POST_BY_ID, {
    variables: { postId, socialAccountId, compareAccountId },
    onError: () => {
      history.push(pathname);
      enqueueSnackbar(t('Post was not found'), { variant: 'error' });
    },
  });

  if (error) {
    return <Redirect to="/404" />;
  }

  return <PostDetail {...props} video={data?.influencerYoutubeComparePostById || null} loading={loading} />;
};

export default CompareTemplate;
