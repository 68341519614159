import { Highcharts } from '@src/components/molecules/HighChart';

const COLORS = ['#CE2E22', '#AC655D', '#DA9384', '#F1C5E0', '#CC70D7', '#852D40', '#391D84', '#EA90C4'];

export const getChartOptions = (data: Highcharts.PointOptionsObject[]): Highcharts.Options => ({
  chart: {
    spacing: [0, 0, 0, 0],
    height: '250px',
    // min|max fontSize doesn't work for single word - it takes all available space, to fix it we can set margins to make it smaller
    margin: data.length === 1 ? 80 : 0,
  },
  title: {
    text: '',
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    enabled: false,
  },
  series: [
    {
      data,
      name: 'Occurrences',
      colors: COLORS,
      type: 'wordcloud',
      minFontSize: 5,
      maxFontSize: 15,
    },
  ],
});
