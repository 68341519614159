import { SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled';
import * as React from 'react';

export interface FieldType {
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: any) => void;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  name?: string;
}

export interface TextSearchProps extends FieldType {
  className?: string;
  placeholder: string;
  value?: string;
  inputStyle?: SerializedStyles;
  autoFocus?: boolean;
  disabled?: boolean;
}

const TextSearch = (props: TextSearchProps) => {
  const { className, placeholder, value, name, inputStyle, onBlur, onChange, onKeyPress, autoFocus, disabled } = props;

  return (
    <Wrapper className={className} css={inputStyle}>
      <Icon className="material-icons">search</Icon>
      <Input
        type="text"
        placeholder={placeholder}
        value={value}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onKeyPress={onKeyPress}
        autoFocus={autoFocus}
        disabled={disabled}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  border: 1px solid #dee5ec;
  box-sizing: border-box;
  box-shadow: inset 0 1px 2px rgba(39, 49, 59, 0.2);
  border-radius: 3px;
  width: 100%;
  padding-left: 38px;
  background-color: #fff;
  font-size: 13px;
  line-height: 30px;
  letter-spacing: 0.02em;

  &:focus {
    border-color: lightslategrey;
  }
`;

const Icon = styled.i`
  position: absolute;
  left: 10px;
  top: 5px;
  color: #c5d0da;
`;

export default TextSearch;
