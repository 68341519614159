import styled from '@emotion/styled';
import { useMediaQuery } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { format, isDate, isValid, parse } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import classNames from 'react-day-picker/lib/src/classNames';
import 'react-day-picker/lib/style.css';
import { Modifier } from 'react-day-picker/types/common';
import { useTranslation } from 'react-i18next';
import { localeUtils } from '@src/libs/date';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { DEFAULT_FNS_DATE_FORMAT } from '../../../libs/constant';
import { CustomInput, Navbar } from './Components';
import { DAYPICKER_INPUT_FORMAT, getDefaultDate, placeholderSingle } from './helpers';

interface DatePickerProps extends WithStyles {
  value: string;
  handleChangeDay: (day: string) => void;
  error?: boolean;
  disabled?: boolean;
  rangeTo?: Date;
  disabledRange?: Modifier | Modifier[];
}

const DayPickerHandInput = ({
  classes,
  value,
  disabled,
  error,
  handleChangeDay,
  rangeTo,
  disabledRange,
}: DatePickerProps) => {
  const { i18n } = useTranslation();
  // State
  const defaultInputState = {
    from: getDefaultDate(value),
  };
  const [state, setState] = useState<{
    from: Date | null;
  }>(defaultInputState);

  const inputRef = useRef(null);
  const isMobile = useMediaQuery('(max-width:450px)');

  useEffect(() => {
    setState(defaultInputState);
  }, [value]);

  // Handlers
  const handleDayClick = (day: Date) => {
    setState({
      from: day,
    });
    handleChangeDay(format(day, DEFAULT_FNS_DATE_FORMAT));
  };

  const handleChange = (day: Date) => {
    if (!day || !isValid(day)) {
      return;
    }
    handleDayClick(new Date(day));
  };

  const parseDate = (str: string, parseFormat: string) => {
    if (str.length !== parseFormat.length) {
      return undefined;
    }
    const parsed = parse(str, parseFormat, new Date());

    if (isDate(parsed) && isValid(parsed)) {
      return parsed;
    }

    return undefined;
  };
  const formatDate = (date: Date, pickerFormat: string) => format(date, pickerFormat);

  return (
    <WidgetBlock>
      <DayPickerInput
        formatDate={formatDate}
        format="MMM dd, yyyy"
        placeholder={placeholderSingle}
        parseDate={parseDate}
        value={
          state.from ? localizedDateFormatter(state.from, DAYPICKER_INPUT_FORMAT, i18n.language as AppLanguage) : ''
        }
        onDayChange={handleChange}
        inputProps={{ error: !!error, disabled: !!disabled }} // to pass props in CustomInput
        component={CustomInput}
        ref={inputRef}
        onBlur={() => null} // prevent blur effect after change month
        dayPickerProps={{
          numberOfMonths: isMobile ? 1 : 2,
          localeUtils,
          locale: i18n.language,
          selectedDays: state.from || undefined,
          ...(rangeTo && { disabledDays: { after: rangeTo } }),
          // eslint-disable-next-line react/display-name
          navbarElement: navbarProps => <Navbar {...navbarProps} isMobile={isMobile} />,
          classNames: { ...classNames, ...classes },
          captionElement: () => null,
          initialMonth: state.from || new Date(),
          disabledDays: disabledRange,
        }}
        classNames={{
          overlayWrapper: 'DayPickerInput-OverlayWrapper',
          overlay: 'DayPickerInput-Overlay',
          container: classes.inputContainer,
        }}
      />
    </WidgetBlock>
  );
};

const styles = createStyles({
  tooltip: {
    fontSize: '12px',
    letterSpacing: '0.02em',
  },
  button: {
    color: 'rgb(23, 156, 215)',
  },
  actions: {
    alignItems: 'baseline',
  },
  months: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  today: {
    color: 'tomato',
  },
  selected: {
    backgroundColor: '#4A90E2',
    color: '#fff',
    borderRadius: '0 !important',
  },
  day: {
    display: 'table-cell',
    padding: '0.5em',
    borderRadius: 0,
    verticalAlign: 'middle',
    textAlign: 'center',
    cursor: 'pointer',

    '&.outside': {
      backgroundColor: '#fff !important',
    },

    '&:not($selected)': {
      '&:hover ': {
        backgroundColor: '#e1e2e3',
      },
    },
  },
  disabled: {
    opacity: 0.3,
    backgroundColor: '#fff !important',
    color: '#000',
    cursor: 'unset',
    outline: 'none',

    '&:hover': {
      backgroundColor: '#fff !important',
      color: '#000',
    },
  },
  container: {
    input: {
      width: '200px',
      height: '38px',
      border: '1px solid #dee5ec',
      borderRadius: '20px',
    },
    left: '20px !important',
  },
  wrapper: {
    outline: 'none',
  },
  inputContainer: {
    width: '100%',
  },
});

const WidgetBlock = styled.div`
  flex-direction: column;

  & .DayPickerInput-Overlay {
    z-index: 3;
  }
`;

export default withStyles(styles)(DayPickerHandInput);
