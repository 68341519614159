import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CampaignSocialMediaType } from '../../../../__generated__/globalTypes';
import Label from '../Label/Label';
import { switchImage } from '../../../../libs/SocialMedia';

export interface CampaignPriceProps {
  currency: string;
  socialMedias: CampaignSocialMediaType[];
  remainingAmount?: number | null;
  totalAmount?: number | null;
  revenuePerPost?: number | null;
  revenuePerLike?: number | null;
  revenuePerView?: number | null;
  revenuePerShare?: number | null;
  revenuePerComment?: number | null;
  revenuePerFollower?: number | null;
  revenuePerClick?: number | null;
  revenuePerAction?: number | null;
}

const CampaignPrice = (props: CampaignPriceProps) => {
  const {
    currency,
    socialMedias,
    revenuePerPost,
    revenuePerLike,
    revenuePerView,
    revenuePerShare,
    revenuePerComment,
    revenuePerFollower,
    revenuePerClick,
    revenuePerAction,
  } = props;
  const { t } = useTranslation();

  const hasFbReactions = socialMedias[0] === CampaignSocialMediaType.FACEBOOK;

  return (
    <Wrapper>
      <Label title={'Unit Price'} />

      <PriceContainer>
        <Icon src={switchImage(socialMedias[0])}></Icon>
        <PriceWrapper>
          {!!revenuePerPost && (
            <MarketplaceCampaignPrice>
              {revenuePerPost}
              <Unit>
                {t(currency)}&nbsp;/&nbsp;
                {t('RevenuePer.Post')}
              </Unit>
            </MarketplaceCampaignPrice>
          )}
          {!!revenuePerLike && (
            <MarketplaceCampaignPrice>
              {revenuePerLike}
              <Unit>
                {t(currency)}&nbsp;/&nbsp;{t(`RevenuePer.${hasFbReactions ? 'Reaction' : 'Like'}`)}
              </Unit>
            </MarketplaceCampaignPrice>
          )}
          {!!revenuePerView && (
            <MarketplaceCampaignPrice>
              {revenuePerView}
              <Unit>
                {t(currency)}&nbsp;/&nbsp;{t('RevenuePer.View')}
              </Unit>
            </MarketplaceCampaignPrice>
          )}
          {!!revenuePerShare && (
            <MarketplaceCampaignPrice>
              {revenuePerShare}
              <Unit>
                {t(currency)}&nbsp;/&nbsp;{t('RevenuePer.Share')}
              </Unit>
            </MarketplaceCampaignPrice>
          )}
          {!!revenuePerComment && (
            <MarketplaceCampaignPrice>
              {revenuePerComment}
              <Unit>
                {t(currency)}&nbsp;/&nbsp;{t('RevenuePer.Comment')}
              </Unit>
            </MarketplaceCampaignPrice>
          )}
          {!!revenuePerFollower && (
            <MarketplaceCampaignPrice>
              {revenuePerFollower}
              <Unit>
                {t(currency)}&nbsp;/&nbsp;{t('RevenuePer.Follower')}
              </Unit>
            </MarketplaceCampaignPrice>
          )}
          {!!revenuePerClick && (
            <MarketplaceCampaignPrice>
              {revenuePerClick}
              <Unit>
                {t(currency)}&nbsp;/&nbsp;{t('RevenuePer.Click')}
              </Unit>
            </MarketplaceCampaignPrice>
          )}
          {!!revenuePerAction && (
            <MarketplaceCampaignPrice>
              {revenuePerAction}
              <Unit>
                {t(currency)}&nbsp;/&nbsp;{t('RevenuePer.Action')}
              </Unit>
            </MarketplaceCampaignPrice>
          )}
        </PriceWrapper>
      </PriceContainer>
    </Wrapper>
  );
};

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const EngagementCampaignPrice = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #27313b;
  margin-top: 14px;
`;

const MarketplaceCampaignPrice = styled(EngagementCampaignPrice)`
  font-size: 14px;
  font-weight: 600;
`;

const Unit = styled.span`
  font-size: 11px;
  font-weight: normal;
  color: #6e7c89;
  margin-left: 4px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
`;

const Icon = styled.img`
  width: 32px;
  margin-right: 8px;
`;

export default CampaignPrice;
