import React from 'react';
import { formatIntNumber, formatPercent } from '@src/libs/format';
import { switchImage } from '@src/libs/SocialMedia';
import { useQueryHelper } from '@src/libs/hooks';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { TwitterEngagementInfoProps } from '../types';
import * as Styled from '../StyledEngagement';

const EngagementInfo = ({
  engagement,
  impression,
  like,
  negativeRate,
  positiveRate,
  postUrl,
  quoteTweet,
  reply,
  retweet,
  view,
}: TwitterEngagementInfoProps) => {
  const { t } = useQueryHelper();

  return (
    <Styled.MainContainer>
      <Styled.Title>
        {t('General.Engagement')}
        <Styled.Anchor href={postUrl as string} target="_blank" rel="noopener noreferrer">
          {`View on ${SocialAccountType.TWITTER}`}
        </Styled.Anchor>
      </Styled.Title>

      <Styled.EngagementContainer>
        <Styled.Engagement>
          <Styled.Icon alt="socialMediaIcon" src={switchImage(SocialAccountType.TWITTER, true)} />
          <Styled.CountWrapper>
            <Styled.Wrapper>
              <Styled.Value>{formatIntNumber(like)}</Styled.Value>
              <Styled.Unit text={t('General.Like')} lines={1} />
            </Styled.Wrapper>
            <Styled.Wrapper>
              <Styled.Value>{formatIntNumber(view)}</Styled.Value>
              <Styled.Unit text={t('General.Views')} lines={1} />
            </Styled.Wrapper>
            <Styled.Wrapper>
              <Styled.Value>{formatIntNumber(reply)}</Styled.Value>
              <Styled.Unit text={t('General.Reply')} lines={1} />
            </Styled.Wrapper>
            <Styled.Wrapper>
              <Styled.Value>{formatIntNumber(retweet)}</Styled.Value>
              <Styled.Unit text={t('General.Retweet')} lines={1} />
            </Styled.Wrapper>
            <Styled.Wrapper>
              <Styled.Value>{formatIntNumber(quoteTweet)}</Styled.Value>
              <Styled.Unit text={t('General.Quote Tweet')} lines={1} />
            </Styled.Wrapper>
            <Styled.Wrapper>
              <Styled.Value>{formatIntNumber(impression)}</Styled.Value>
              <Styled.Unit text={t('General.Impression')} lines={1} />
            </Styled.Wrapper>
            <Styled.Wrapper>
              <Styled.Value>{formatIntNumber(engagement)}</Styled.Value>
              <Styled.Unit text={t('General.Engagement')} lines={1} />
            </Styled.Wrapper>
            <Styled.Wrapper>
              <Styled.Value>{formatPercent(positiveRate, true, 2)}</Styled.Value>
              <Styled.Unit text={t('General.positive rate')} lines={1} />
            </Styled.Wrapper>
            <Styled.Wrapper>
              <Styled.Value>{formatPercent(negativeRate, true, 2)}</Styled.Value>
              <Styled.Unit text={t('General.negative rate')} lines={1} />
            </Styled.Wrapper>
          </Styled.CountWrapper>
        </Styled.Engagement>
      </Styled.EngagementContainer>
    </Styled.MainContainer>
  );
};

export default EngagementInfo;
