import styled from '@emotion/styled';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import * as React from 'react';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../libs/format';

interface CampaignCardPeriodProps {
  start: string;
  end: string;
}

const CampaignCardPeriod = (props: CampaignCardPeriodProps) => {
  const { i18n } = useTranslation();
  const { start, end } = props;

  return (
    <Wrapper>
      <Data>
        {localizedDateFormatter(start, 'MMM dd', i18n.language as AppLanguage)}
        <br />
        <Year>{formatDate(start, 'yyyy')}</Year>
      </Data>
      <Hyphen />
      <Data>
        {localizedDateFormatter(end, 'MMM dd', i18n.language as AppLanguage)}
        <br />
        <Year>{formatDate(end, 'yyyy')}</Year>
      </Data>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: -10px;
  right: 16px;
`;

const Data = styled.p`
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #dee5ec;
  padding: 6px 6px 10px;
  color: #27313b;
  background-color: #fff;
`;

const Year = styled.span`
  text-align: center;
  font-size: 10px;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e7c89;
`;

const Hyphen = styled.span`
  width: 6px;
  height: 2px;
  margin: 0 4px;
  background-color: #27313b;
`;

export default CampaignCardPeriod;
