import styled from '@emotion/styled';
import * as React from 'react';
import { FORM } from '../../../libs/theme';

interface FieldType {
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: any) => void;
  value?: any;
  name?: string;
  defaultValue?: string;
  readOnly?: boolean;
}

export interface TextareaProps extends FormProps, FieldType {
  placeholder: string;
}

const Textarea = (props: TextareaProps) => {
  const { placeholder, error, disabled, readOnly, ...field } = props;

  return (
    <Form
      placeholder={!!disabled ? '' : placeholder}
      error={error}
      disabled={disabled}
      {...field}
      readOnly={readOnly}
    />
  );
};

interface FormProps {
  error?: boolean;
  disabled?: boolean;
}

const Form = styled.textarea<FormProps>`
  box-sizing: border-box;
  width: 100%;
  height: 140px;
  padding: 12px;
  background-color: ${props => (props.disabled ? FORM.DISABLED : '#fff')};
  border: 1px solid #dee5ec;
  border-color: ${props => (props.error ? FORM.ERROR : FORM.BORDER)};
  border-radius: 3px;
  ${props => !props.error && `&:focus {border-color: lightslategrey}`};
`;

export default Textarea;
