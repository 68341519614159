import styled from '@emotion/styled';
import * as React from 'react';
import CampaignDetail, { CampaignDetailProps } from '../../components/organisms/Campaign/CampaignDetail';
import { useUpdateDocumentTitle } from '../../libs/hooks';
import NavigationHeader from '../../components/organisms/Header/NavigationHeader';
import { CampaignStatusForInfluencer } from '../../__generated__/globalTypes';
import BottomOverflowSection from '../../components/atoms/BottomOverflowSection';
import { EngagementPostButtons } from '../../components/organisms/Campaign/PostButtonLinks';
import { ViewportBreakpoint } from '../../libs/theme';

const Template = (props: CampaignDetailProps) => {
  const headerTitle = 'pageTitle.Campaign Detail';
  const pageTitle = 'pageTitle.Campaign Detail';
  const href = props.campaignStatus === CampaignStatusForInfluencer.FINISHED ? `/job/finished` : `/job`;

  useUpdateDocumentTitle({ title: headerTitle, pageTitle, href });

  const {
    title,
    serviceInformation,
    materials,
    campaignType,
    startDate,
    endDate,
    campaignStatus,
    currency,
    requirements,
    socialMedias,
    thumbNail,
    productUrl,
    isJoined,
    campaignId,
    hasReport,
    hasDraftPosts,
    hasWarningPosts,
    materialUrl,
    trackingUrl,
  } = props;

  return (
    <Wrapper>
      <NavigationHeader>
        <EngagementPostButtons
          campaignId={campaignId}
          campaignStatus={campaignStatus}
          isJoined={isJoined}
          hasDraftPosts={hasDraftPosts}
          hasReport={hasReport}
          hasWarningPosts={hasWarningPosts}
        />
      </NavigationHeader>

      <CampaignDetailWrapper>
        <CampaignDetail
          thumbNail={thumbNail}
          title={title}
          serviceInformation={serviceInformation}
          materials={materials}
          campaignType={campaignType}
          startDate={startDate}
          endDate={endDate}
          campaignStatus={campaignStatus}
          currency={currency}
          requirements={requirements}
          socialMedias={socialMedias}
          productUrl={productUrl}
          isJoined={isJoined}
          campaignId={campaignId}
          materialUrl={materialUrl}
          trackingUrl={trackingUrl}
          isApplied={false} // Only needed for selection marketplace
          hasReport={hasReport}
        />
      </CampaignDetailWrapper>

      <BottomOverflowSection>
        <EngagementPostButtons
          campaignId={campaignId}
          campaignStatus={campaignStatus}
          isJoined={isJoined}
          hasDraftPosts={hasDraftPosts}
          hasReport={hasReport}
          hasWarningPosts={hasWarningPosts}
        />
      </BottomOverflowSection>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const CampaignDetailWrapper = styled.div`
  @media (min-width: ${ViewportBreakpoint.MEDIUM}px) {
    margin-top: 48px;
  }
`;

export default Template;
