import * as React from 'react';
import IgReconnectForm from '../../components/organisms/IgReconnect';
import TopPageContainer from '../../components/organisms/TopPage/TopPageContainer';

const Template = () => (
  <TopPageContainer showGoBackArrow>
    <IgReconnectForm />
  </TopPageContainer>
);

export default Template;
