import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import homeWelcomeBackground from '@src/assets/img/signInUpFormBackground.png';
import welcomeImage from '@src/assets/img/welcomeToAnyCreator.svg';
import { usePageLayout } from '@src/libs/hooks';

const Welcome = () => {
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();

  return isMobileView ? (
    <div css={styles.mobileWelcomeContainer}>
      <div>
        <div>
          <div>{t('Title.Welcome to AnyCreator')}</div>
          <div>
            <img alt="welcomeImg" src={welcomeImage} />
          </div>
        </div>
      </div>
      <div>
        <div>{t('Annotation.Welcome to AnyCreator')}</div>
      </div>
      {/* to display a space gradient background */}
      <div />
    </div>
  ) : (
    <div css={styles.welcomeContainer}>
      <div>
        <div>
          <div>{t('Title.Welcome to AnyCreator')}</div>
          <div>{t('Annotation.Welcome to AnyCreator')}</div>
        </div>
      </div>
      <div>
        <img alt="welcomeImg" src={welcomeImage} />
      </div>
    </div>
  );
};

const styles = {
  mobileWelcomeContainer: css`
    background-color: #eef3f7;
    margin-left: -24px;
    margin-top: -24px;
    width: calc(100% + 48px);

    & > div:nth-of-type(1) {
      padding: 12px 16px;

      & > div:nth-of-type(1) {
        align-items: center;
        display: flex;
        flex-wrap: wrap;

        & > div:nth-of-type(1) {
          color: #27313b;
          display: flex;
          flex-basis: 50%;
          font-size: 20px;
          font-weight: 700;
        }

        & > div:nth-of-type(2) {
          display: flex;
          flex-basis: 50%;

          & > img {
            object-fit: fill;
            width: 100%;
          }
        }
      }
    }

    /* stylelint-disable no-descending-specificity */
    & > div:nth-of-type(2) {
      background-image: url(${homeWelcomeBackground});
      margin-top: -24px;
      padding: 24px 24px 0 24px;
      position: relative;

      & > div {
        color: #6e7c89;
        font-size: 12px;
        font-weight: 600;
      }
    }

    & > div:nth-of-type(3) {
      background-image: linear-gradient(#fff, #f7f8fa);
      height: 24px;
    }
  `,
  welcomeContainer: css`
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px #dee5ec, 0 1px 4px rgba(222, 229, 236, 0.5);
    display: flex;
    flex-wrap: wrap;
    height: 174px;
    margin-bottom: 24px;
    width: 100%;

    & > div:nth-of-type(1) {
      align-items: center;
      display: flex;
      flex-basis: 50%;

      & > div {
        margin: 0 -24px 0 24px;

        & > div:nth-of-type(1) {
          color: #27313b;
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 12px;
        }

        & > div:nth-of-type(2) {
          color: #6e7c89;
          font-size: 12px;
          font-weight: 600;
        }
      }
    }

    & > div:nth-of-type(2) {
      display: flex;
      flex-basis: 50%;

      & > img {
        height: 174px;
        width: 100%;
      }
    }
  `,
};

export default Welcome;
