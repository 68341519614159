import { useState } from 'react';
import { useQueryHelper } from '@src/libs/hooks';
import {
  generateYAxisBasedOnDisplayedCharts,
  getBarDataBasedOnPointer,
  getEngagementDataWithHistory,
} from '@src/components/organisms/AnalyticsShared/Overview/helpers';
import { getGrowthArrow, getGrowthPrefix } from '@src/components/organisms/AnalyticsShared/utils';
import userpicIcon from '@src/assets/icon/userpic.svg';
import pictureIcon from '@src/assets/icon/picture.svg';
import viewsIcon from '@src/assets/icon/views.svg';
import pagesLikesIcon from '@src/assets/icon/pagesLikes.svg';
import chatIcon from '@src/assets/icon/chat_green.svg';
import networkIcon from '@src/assets/icon/network.svg';
import targetIcon from '@src/assets/icon/target.svg';
import impressionsIcon from '@src/assets/icon/impressions.svg';
import engagementIcon from '@src/assets/icon/engagement_analytics.svg';
import engagementRateIcon from '@src/assets/icon/engagementRate.svg';
import reactionIcon from '@src/assets/icon/thumbs-up.svg';
import pageViewsIcon from '@src/assets/icon/pageViews.svg';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import { OverviewStats } from './types';
import { allChartsOrdered, ChartColorTypes, colors, getSeriesAndCategories } from './helpers';

export const getOptions = (
  displayedCharts: ChartColorTypes[],
  overviewStats: OverviewStats,
  fetchDailyPosts: (date: string) => void,
  chosenSelectableCharts: ChartColorTypes[],
  locale?: string
): {
  options: Highcharts.Options;
  engagement: Array<{ icon: string; title: string; total: number; growth: number; rate: number }>;
} => {
  const { t, pathname } = useQueryHelper();
  const [chartState, setChartState] = useState<Highcharts.Chart | undefined>(undefined);
  const {
    follower,
    post,
    view,
    reaction,
    comment,
    share,
    impression,
    engagement,
    engagementRate,
    pageCta,
    pageImpression,
    pageLikes,
  } = overviewStats;
  const { history: followerHistory, engagement: followerEngagement } = getEngagementDataWithHistory(follower);
  const { history: postsHistory, engagement: postsEngagement } = getEngagementDataWithHistory(post);
  const { history: viewsHistory, engagement: viewsEngagement } = getEngagementDataWithHistory(view);
  const { history: reactionHistory, engagement: reactionEngagement } = getEngagementDataWithHistory(reaction);
  const { history: shareHistory, engagement: shareEngagement } = getEngagementDataWithHistory(share);
  const { history: commentsHistory, engagement: commentsEngagement } = getEngagementDataWithHistory(comment);
  const { history: impressionHistory, engagement: impressionEngagement } = getEngagementDataWithHistory(impression);
  const { history: engagementHistory, engagement: engagementEngagement } = getEngagementDataWithHistory(engagement);
  const { history: pageCtaHistory, engagement: pageCtaEngagement } = getEngagementDataWithHistory(pageCta);
  const { history: pageImpressionHistory, engagement: pageImpressionEngagement } = getEngagementDataWithHistory(
    pageImpression
  );
  const { history: pageLikesHistory, engagement: pageLikesEngagement } = getEngagementDataWithHistory(pageLikes);
  const { history: engagementRateHistory, engagement: engagementRateEngagement } = getEngagementDataWithHistory(
    engagementRate
  );

  // we are showing X-Axis dates based on displayed charts checkboxes
  const { series, categories, tooltipData } = getSeriesAndCategories({
    historyData: {
      followerHistory,
      postsHistory,
      viewsHistory,
      reactionHistory,
      shareHistory,
      commentsHistory,
      impressionHistory,
      pageCtaHistory,
      engagementHistory,
      engagementRateHistory,
      pageImpressionHistory,
      pageLikesHistory,
    },
    displayedCharts,
  });
  const yAxis = generateYAxisBasedOnDisplayedCharts<ChartColorTypes>(allChartsOrdered, false);
  const isTooltipOutside = pathname.includes('/engagement') ? false : true;

  return {
    engagement: [
      {
        icon: userpicIcon,
        title: 'Follower (Total)',
        ...followerEngagement,
      },
      { icon: pictureIcon, title: 'Post', ...postsEngagement },
      { icon: viewsIcon, title: 'Views', ...viewsEngagement },
      { icon: reactionIcon, title: 'Reaction', ...reactionEngagement },
      { icon: chatIcon, title: 'Comment', ...commentsEngagement },
      { icon: networkIcon, title: 'Share', ...shareEngagement },
      ...(chosenSelectableCharts.includes('Impression')
        ? [{ icon: impressionsIcon, title: 'Impression', ...impressionEngagement }]
        : []),
      ...(chosenSelectableCharts.includes('Engagement')
        ? [{ icon: engagementIcon, title: 'Engagement', ...engagementEngagement }]
        : []),
      ...(chosenSelectableCharts.includes('Engagement Rate')
        ? [{ icon: engagementRateIcon, title: 'Engagement Rate', ...engagementRateEngagement }]
        : []),
      ...(chosenSelectableCharts.includes('PageCta')
        ? [{ icon: targetIcon, title: 'Page CTA', ...pageCtaEngagement }]
        : []),
      ...(chosenSelectableCharts.includes('Page Impression')
        ? [{ icon: pageViewsIcon, title: 'Page Impression', ...pageImpressionEngagement }]
        : []),
      ...(chosenSelectableCharts.includes('Page Likes')
        ? [{ icon: pagesLikesIcon, title: 'Page Likes', ...pageLikesEngagement }]
        : []),
    ],
    options: {
      chart: {
        type: 'column',
        events: {
          // eslint-disable-next-line object-shorthand
          click: function (e) {
            const selectedPoint = getBarDataBasedOnPointer(this, e, [colors.Post]);
            // if Y-axis value is other than 0, means that there are some posts on this day
            if (selectedPoint?.length && selectedPoint[0].y) {
              fetchDailyPosts(`${selectedPoint[0].category}`);
            }
          },
          // eslint-disable-next-line object-shorthand
          load: function () {
            setChartState(this);
          },
        },
      },
      title: {
        text: '',
      },
      xAxis: {
        categories,
        crosshair: true,
        type: 'category',
        labels: {
          formatter: function formatter() {
            return localizedDateFormatter(this.value, 'MMM do', locale as AppLanguage);
          },
        },
      },
      yAxis,
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          cursor: 'pointer',

          point: {
            events: {
              // eslint-disable-next-line object-shorthand
              click: function () {
                if (colors.Post === this.color) {
                  fetchDailyPosts(`${this.category}`);
                }
              },
              // eslint-disable-next-line object-shorthand
              mouseOver: function () {
                if (tooltipData[this.category]?.Post.value !== '-') {
                  /* eslint-disable */
                  // @ts-ignore
                  chartState?.chartBackground?.htmlCss({ cursor: 'pointer' });
                }
              },
              mouseOut: () => {
                // @ts-ignore
                chartState?.chartBackground?.htmlCss({ cursor: 'auto' });
              },
            },
          },
        },
      },
      tooltip: {
        useHTML: true,
        borderColor: '#27313b',
        borderRadius: 3,
        outside: isTooltipOutside,
        shadow: false,
        formatter: function formatter(): string {
          const followersIncrement = tooltipData[this.key].Follower.increment;
          const incrementArrow = getGrowthArrow(followersIncrement);
          const prefix = getGrowthPrefix(followersIncrement);

          const incrementSection = followersIncrement
            ? `<span style="color: ${
                followersIncrement > 0 ? '#40b87c' : '#ff2b52'
              };">${prefix} ${followersIncrement} ${incrementArrow}</span>`
            : '';

          return `
          <div style="display:flex;flex-direction:column;border-radius:3px;border: 2px solid #27313b;margin:-2px;background-color:#27313b;font-size:12px;padding:13px 16px;">
            <h3 style="margin-bottom:8px; font-size: 13px; color: #fff;">${this.key}</h3>
            <div style="display:flex; flex-direction: column;">

              <div style="display: flex;">
                <div style="display: flex; flex: 1; width: max-content; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Follower')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key].Follower.value}
                      ${incrementSection}
                    </p>
                  </div>
                </div>
  
                <div style="display: flex;  flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Post')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key].Post.value}
                    </p>
                  </div>
                </div>
  
                <div style="display: flex;  flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('View')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key].View.value}
                    </p>
                  </div>
                </div>

              </div>

              <div style="display: flex; margin-top: 12px;">

                <div style="display: flex; flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Reaction')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key].Reaction.value}
                    </p>
                  </div>
                </div>

                <div style="display: flex;  flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Comment')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key].Comment.value}
                    </p>
                  </div>
                </div>

                <div style="display: flex;  flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Share')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key].Share.value}
                    </p>
                  </div>
                </div>
              </div>

              <div style="display: flex; margin-top: 12px;">

                <div style="display: flex;  flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Impression')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key].Impression.value}
                    </p>
                  </div>
                </div>

                <div style="display: flex;  flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Engagement')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key].Engagement.value}
                    </p>
                  </div>
                </div>

                <div style="display: flex;  flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Engagement Rate')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key]['Engagement Rate'].value}
                    </p>
                  </div>
                </div>
              </div>

              <div style="display: flex; margin-top: 12px;">

                <div style="display: flex;  flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('PageCta')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key].PageCta.value}
                    </p>
                  </div>
                </div>

                <div style="display: flex; flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Like')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key]['Page Likes'].value}
                    </p>
                  </div>
                </div>

                <div style="display: flex;  flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Page Impression')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key]['Page Impression'].value}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>`;
        },
        pointFormat: '',
      },
      series,
    },
  };
};
