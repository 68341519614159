import * as React from 'react';
import { useQuery } from '@apollo/client';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { useQueryHelper } from '@src/libs/hooks';
import { BasePostFilterType } from '@src/pages/InstagramStoryOrFeedPosts/types';
import Template from './template';
import {
  InfluencerAllInstagramStoryPosts,
  InfluencerAllInstagramStoryPostsVariables,
} from './__generated__/InfluencerAllInstagramStoryPosts';
import * as INFLUENCER_ALL_INSTAGRAM_STORY_POSTS from './InfluencerAllInstagramStoryPosts.graphql';
import { storyPostVariables } from './utils';

interface PostFeedProps {
  accountId: number;
  currentPage: number;
  filter: BasePostFilterType;
  setFilter: (filter: BasePostFilterType) => void;
}

const StoryFeed = (props: PostFeedProps) => {
  const { t, history, enqueueSnackbar, search } = useQueryHelper();

  const { data, loading, error } = useQuery<
    InfluencerAllInstagramStoryPosts,
    InfluencerAllInstagramStoryPostsVariables
  >(INFLUENCER_ALL_INSTAGRAM_STORY_POSTS, { variables: storyPostVariables(props) });

  if (error) {
    history.push(`/profile${search}`);
    enqueueSnackbar(t(error?.message || ''), { variant: 'error' });
  }

  if (loading || !data) {
    return <ListIndicator />;
  }

  return (
    <Template total={data?.getTotalNumber?.totalNumber || 0} posts={data?.allInstagramStoryPosts || []} {...props} />
  );
};

export default StoryFeed;
