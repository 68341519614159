import React from 'react';
import { css } from '@emotion/react';
import { convertDurationToHhMmSs } from '@src/libs/date';
import { formatIntNumber, formatPercent } from '@src/libs/format';
import { defaultEmptyImage } from '@src/libs/image';
import { ORDER, YoutubeAnalyticsComparePostsSortOrder } from '@src/__generated__/globalTypes';
import { SliderComponents, StyledComponents } from '@src/components/molecules/SliderTable';
import { HeaderColumn, DateColumn, AmountColumn } from '@src/components/atoms/List';
import { getYtCompareFeedVideosListColumns } from './utils';

export interface SortType<T extends string> {
  field: T | null;
  order: ORDER | null;
}

interface TableContentProps {
  posts: {
    comments: any;
    dislikes: any;
    id: number;
    likes: any;
    negativeRate: number;
    positiveRate: number;
    postDate: any;
    videoDuration: number;
    videoThumbnail: string | null;
    videoTitle: string;
    views: any;
  }[];
  sort: SortType<YoutubeAnalyticsComparePostsSortOrder>;
  onClick?: (postId: number) => void;
  onSort: (sort: SortType<YoutubeAnalyticsComparePostsSortOrder>) => void;
}

const TableContent = ({ posts, sort: { field, order }, onClick, onSort }: TableContentProps) => (
  <SliderComponents.Wrapper css={styles.tableWrapper}>
    <SliderComponents.Container>
      <SliderComponents.SliderSection>
        <SliderComponents.SliderTable>
          <thead>
            <SliderComponents.HeaderRow css={{ backgroundColor: '#f6f8fa' }}>
              <SliderComponents.StickyHeaderTdWrapper>
                <HeaderColumn css={styles.headerColumn} isTable={false} title="Videos" />
              </SliderComponents.StickyHeaderTdWrapper>
              {getYtCompareFeedVideosListColumns().map(({ title, orderField }) => (
                <HeaderColumn
                  key={title}
                  title={title}
                  {...(orderField && {
                    activeAsc: field === orderField && order === ORDER.ASC,
                    activeDesc: field === orderField && order === ORDER.DESC,
                    sortAsc: () => onSort({ field: orderField, order: ORDER.ASC }),
                    sortDesc: () => onSort({ field: orderField, order: ORDER.DESC }),
                  })}
                  css={styles.sortHeaderColumn}
                />
              ))}
            </SliderComponents.HeaderRow>
          </thead>

          <tbody>
            {posts.map(video => {
              const {
                comments,
                dislikes,
                id,
                likes,
                negativeRate,
                positiveRate,
                postDate,
                videoDuration,
                videoThumbnail,
                videoTitle,
                views,
              } = video;

              return (
                <StyledComponents.StyledRowNew css={styles.tableRow} key={id}>
                  <SliderComponents.StickyBodyRowWrapper>
                    <SliderComponents.ThumbnailCell
                      imageWidth="85px"
                      role="div"
                      src={defaultEmptyImage(videoThumbnail, true)}
                      title={videoTitle}
                      handleClick={() => onClick?.(id)}
                    />
                  </SliderComponents.StickyBodyRowWrapper>
                  <DateColumn css={styles.dateColumn} date={postDate} ignoreClientUTC={true} />
                  <StyledComponents.NumberColumn data={formatIntNumber(views)} />
                  <StyledComponents.NumberColumn data={formatIntNumber(likes)} />
                  <StyledComponents.NumberColumn data={formatIntNumber(dislikes)} />
                  <StyledComponents.NumberColumn data={formatIntNumber(comments)} />
                  <AmountColumn data={formatPercent(positiveRate, false)} currency="%" />
                  <AmountColumn data={formatPercent(negativeRate, false)} currency="%" />
                  <StyledComponents.NumberColumn data={convertDurationToHhMmSs(videoDuration)} />
                </StyledComponents.StyledRowNew>
              );
            })}
          </tbody>
        </SliderComponents.SliderTable>
      </SliderComponents.SliderSection>
    </SliderComponents.Container>
  </SliderComponents.Wrapper>
);

const styles = {
  dateColumn: css`
    padding-left: 16px;
  `,
  headerColumn: css`
    padding: 0;
    width: 230px;

    & > div {
      justify-content: flex-start;
    }
  `,
  tableRow: css`
    border-bottom: 1px solid #dee5ec;
    height: 40px;

    td:first-of-type {
      padding-left: 16px;
    }

    td:last-of-type {
      padding-right: 16px;
    }
  `,
  tableWrapper: css`
    margin: 0 24px 0;
    padding: 0;
    border: none;
    box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px rgb(222 229 236 / 50%);
  `,
  sortHeaderColumn: css`
    text-transform: capitalize;
  `,
};

export default TableContent;
