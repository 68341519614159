import { LIMIT } from '@src/libs/constant';
import { getOffset } from '@src/libs/paging';
import { BasePostFilterType } from '@src/pages/InstagramStoryOrFeedPosts/types';
import { SortActionType } from '@src/libs/filter';
import { InstagramStoryPostSortField } from '@src/__generated__/globalTypes';

export interface PostFeedProps {
  accountId: number;
  currentPage: number;
  filter: BasePostFilterType;
  setFilter: (filter: BasePostFilterType) => void;
}
export const storyPostVariables = (props: PostFeedProps, { sort }: SortActionType<InstagramStoryPostSortField>) => {
  const {
    currentPage,
    filter: { keyword, period },
    accountId,
  } = props;

  return {
    socialAccountId: accountId,
    offset: getOffset(currentPage),
    limit: LIMIT,
    keyword,
    orderBy: sort,
    startDate: period.startDate,
    endDate: period.endDate,
  };
};
