import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { Trans } from 'react-i18next';
import FileDrop from '@src/components/molecules/FileDrop';
import { useQueryHelper } from '@src/libs/hooks';
import Dialog from '@src/components/molecules/Dialog';
import useFanList from '../useFanList';

interface ImportFansModalProps {
  open: boolean;
  onClose: () => void;
  onImportFile: (file: File) => void;
}

const ImportFansModal = (props: ImportFansModalProps) => {
  const childRef = useRef<{
    requestUpload: () => Promise<void>;
    newFile: File;
  }>(null);
  const { open, onClose, onImportFile } = props;
  const { t } = useQueryHelper();
  const { downloadSampleFanCSV } = useFanList();

  const onClickImportFans = () => {
    if (childRef.current?.newFile) {
      onImportFile(childRef.current.newFile);
    }
  };

  return (
    <Dialog visible={open} onClose={onClose} onExec={onClickImportFans} execText="Import Customers">
      <Heading>{t('Import customers by CSV')}</Heading>
      <Trans i18nKey="Fan upload annotation">
        text0
        <DownloadTemplate onClick={downloadSampleFanCSV}>{{ text: t('sample CSV') }}</DownloadTemplate>
      </Trans>
      <FileDrop ref={childRef} accept=".csv, text/csv" multiple={false} notes={[]} />
    </Dialog>
  );
};

const DownloadTemplate = styled.span`
  cursor: pointer;
  color: #0093ff;
  text-decoration: underline;
`;
const Heading = styled.h2`
  font-weight: 600;
  font-size: 18px;
  color: #27313b;
  margin-bottom: 16px;
`;

export default ImportFansModal;
