import { useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import GET_CREATOR_STAFFS from './queries/GetCreatorStaffs.graphql';
import { GetCreatorStaffs } from './queries/__generated__/GetCreatorStaffs';

const useGetCreatorStaffs = () => {
  const { enqueueSnackbar, t } = useQueryHelper();

  const { data } = useQuery<GetCreatorStaffs>(GET_CREATOR_STAFFS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  return { data };
};

export default useGetCreatorStaffs;
