import useGlobalLayoutProps from './useGlobalLayoutProps';

export default () => {
  const {
    globalLayout: { isUuum },
  } = useGlobalLayoutProps();

  return {
    isUuumView: isUuum,
  };
};
