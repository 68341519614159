import styled from '@emotion/styled';
import React from 'react';
import { TagCloud } from 'react-tagcloud';

const mainColor = 'rgba(92, 170, 225, 0.85)';
const secondColor = 'rgba(92, 170, 225, 0.6)';
const thirdColor = 'rgba(255, 43, 82, 0.5)';

const customRenderer = (tag: { value: string; count: number }, size: number) => {
  const colorCalc = size === 3 ? mainColor : size === 2 ? secondColor : thirdColor;

  return (
    <StyledTag key={tag.value} colorCalc={colorCalc} size={size}>
      #{tag.value}
    </StyledTag>
  );
};

interface HashtagCloudType {
  onHashtagClick: (value: string) => void;
  data: string[];
}

const HashtagCloud = ({ onHashtagClick, data }: HashtagCloudType) => (
  <StyledTagcloud
    minSize={1}
    maxSize={3}
    tags={data}
    onClick={(tag: { value: string; count: number }) => {
      onHashtagClick(`#${tag.value}`);
    }}
    renderer={customRenderer}
  />
);

export default HashtagCloud;

const StyledTag = styled.span<{ colorCalc: string; size: number }>`
  display: inline-block;
  cursor: pointer;
  font-size: ${({ size }) => size}em;
  margin: 3px;
  padding: 0 3px;
  color: ${({ colorCalc }) => colorCalc};

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 450px) {
    font-size: ${({ size }) => size * 0.8}em;
  }
`;

const StyledTagcloud = styled(TagCloud)`
  text-align: center;
`;
