import styled from '@emotion/styled';

export const OverviewWrapper = styled.section`
  display: flex;
  flex-direction: column;
  border-radius: 0 0 3px 3px;
  background-color: #fff;
  color: #27313b;
  border: 1px solid #dee5ec;
  border-top: 0;
`;
export const EngagementGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, auto));

  /* remove the edge borders */
  overflow: hidden;
  background-color: white;
  border: 1px solid #dee5ec;
  border-radius: 3px 3px 0 0;
`;
export const EngagementGridCell = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 16px;

  /* to create inner borders only inside grid container */
  border-right: inherit;
  margin-right: -1px;
  border-bottom: inherit;
  margin-bottom: -1px;
`;
export const EngagementTileIcon = styled.img`
  width: 48px;
  height: 48px;

  /* we have to position icon & ignoring padding */
  margin: -16px;
`;
export const EngagementTitle = styled.h3`
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.28px;
  padding-bottom: 4px;
`;
export const TotalNumber = styled.span`
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.3px;
  padding-bottom: 4px;
`;
export const Growth = styled.div<{ isPositive?: boolean }>`
  width: max-content;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${({ isPositive }) => (isPositive === undefined ? 'inherit' : isPositive ? '#40b87c' : '#ff2b52')};
  white-space: pre-wrap;
`;
export const PartWithIcon = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const LeftPart = styled.div`
  display: flex;
  flex-flow: column wrap;
`;
export const Chart = styled.div``;
export const LegendBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px 12px;
`;
export const LegendTileColored = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  margin-right: 8px;
  border-radius: 3px;
  border: solid 1px #dee5ec;
  background-color: #f6f8fa;
  cursor: pointer;
  margin-bottom: 8px;

  span {
    width: 8px;
    height: 8px;
    border-radius: 2px;
  }
`;
export const Text = styled.p`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #27313b;
  padding: 0 4px;
`;
export const LegendTile = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  margin-right: 8px;
  cursor: pointer;
`;
