import React from 'react';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useQueryHelper } from '@src/libs/hooks';

const Footer = () => {
  const { t } = useQueryHelper();

  return (
    <div css={styles.container}>
      <div css={styles.copyrightContainer}>
        <span css={styles.copyright}>Copyright © AnyMind Group. All right reserved.</span>
      </div>

      <div css={styles.termsAndConditionContainer}>
        <StyledLink target="_blank" to="/privacy">
          {t('Privacy Policy')}
        </StyledLink>
        <StyledLink border target="_blank" to="/terms">
          {t('Terms of Service')}
        </StyledLink>
        <StyledLink target="_blank" to="/contact">
          {t('Contact')}
        </StyledLink>
      </div>
    </div>
  );
};

const StyledLink = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{ border?: boolean }>`
  ${({ border }) => (border ? 'border-left: 1px solid #dee5ec; border-right: 1px solid #dee5ec;' : '')}
  color: #6e7c89;
  font-size: 12px;
  padding: 0 16px;
`;

const styles = {
  container: css`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  `,
  copyright: css`
    color: #6e7c89;
    font-size: 12px;
  `,
  copyrightContainer: css`
    display: flex;
    flex-basis: 50%;
  `,
  termsAndConditionContainer: css`
    display: flex;
    flex-basis: 50%;
    flex-wrap: wrap;
    justify-content: flex-end;
  `,
};

export default Footer;
