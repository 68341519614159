import styled from '@emotion/styled';
import React from 'react';

interface NoticeProps {
  className?: string;
  title: string;
  color?: string;
  href?: string;
  content?: string | React.ReactNode;
  icon: string;
}

const Notice = (props: NoticeProps) => {
  const { className, color, title, href, content, icon } = props;

  return (
    <Wrapper color={color} className={className}>
      <SortIcon color={color} className="material-icons">
        {icon}
      </SortIcon>
      <div>
        <Title>{title}</Title>
        <NoticeMessage>
          {content}
          {!!href && (
            <NoticeAnchor href={href} target="_blank">
              {href}
            </NoticeAnchor>
          )}
        </NoticeMessage>
      </div>
    </Wrapper>
  );
};

const SortIcon = styled.i<{ color?: string }>`
  align-self: center;
  margin-right: 12px;
  color: ${props => props.color || '#3892e5'};
`;

const Wrapper = styled.div<{ color?: string }>`
  display: flex;
  margin: 24px;
  margin-bottom: 0;
  padding: 14px 16px;
  background-color: #fff;
  border-radius: 3px;
  font-size: 13px;
  box-shadow: 0 1px 2px 0 rgb(222, 229, 236), 0 0 0 1px rgba(222, 229, 236, 0.5);
  border-left: 3px solid ${props => props.color || '#3892e5'};
`;

const Title = styled.p`
  font-weight: 600;
  margin-bottom: 4px;
`;

const NoticeMessage = styled.div`
  display: inline-block;
  align-items: baseline;
  color: #6e7c89;
  font-weight: 500;
`;

const NoticeAnchor = styled.a`
  margin-left: 4px;
  color: #3892e5;
  font-weight: 500;
  text-decoration: underline;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

export default Notice;
