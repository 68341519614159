import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useQueryHelper } from '@src/libs/hooks';
import { useQuery } from '@apollo/client';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { Period } from '@src/components/molecules/DayPicker/types';
import YtComparePosts from './YtComparePosts';
import CompareOverViewSection from './OverView';
import {
  InfluencerYoutubeAnalyticsCompareAccountCards,
  InfluencerYoutubeAnalyticsCompareAccountCardsVariables,
} from './__generated__/InfluencerYoutubeAnalyticsCompareAccountCards';
import * as COMPARABLE_YT_ACCOUNTS_LIST from './InfluencerYoutubeAnalyticsCompareAccountCards.graphql';
import AccountsCards from './AccountsCards';
import { ComparableYtAccountType } from './types';
import ComparableTagRanking from './TagRanking';

interface AnalyticsCompareType {
  period: Period;
  id: number;
}
const YoutubeAnalyticsCompare = (props: AnalyticsCompareType) => {
  const { id, period } = props;
  const { t, enqueueSnackbar } = useQueryHelper();

  const { loading } = useQuery<
    InfluencerYoutubeAnalyticsCompareAccountCards,
    InfluencerYoutubeAnalyticsCompareAccountCardsVariables
  >(COMPARABLE_YT_ACCOUNTS_LIST, {
    variables: { socialAccountId: id },
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ influencerYoutubeAnalyticsCompareAccountCards }) => {
      if (influencerYoutubeAnalyticsCompareAccountCards?.mainAccount) {
        setComparableAccounts([
          {
            ...influencerYoutubeAnalyticsCompareAccountCards.mainAccount,
            isSelected: true,
            uniqId: `${influencerYoutubeAnalyticsCompareAccountCards.mainAccount.accountId}_main`,
            isDeletable: false,
            blocked: false,
            elementId: undefined,
          },
          ...(influencerYoutubeAnalyticsCompareAccountCards.compareAccounts?.map(acc => ({
            ...acc,
            isSelected: true,
            isDeletable: true,
            blocked: false,
            uniqId: `${acc.accountId}_${acc.elementId}`,
          })) || []),
        ]);
      }
    },
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  const [comparableAccounts, setComparableAccounts] = useState<ComparableYtAccountType[]>([]);

  if (loading) {
    return <StyledLoadingIndicator />;
  }

  const accountsAvatarAndName = comparableAccounts.map(acc => ({
    ...acc,
    name: acc.channelName,
    avatar: acc.avatarUrl,
    id: acc.accountId,
  }));

  const visibleAccountsAvatarAndName = accountsAvatarAndName.filter(acc => !!acc.isSelected && !acc.blocked);
  const visibleComparableAccountsIds = visibleAccountsAvatarAndName
    .filter(acc => acc.elementId !== undefined && !acc.blocked)
    .map(acc => acc.id);
  const isMainAccountVisible = !!visibleAccountsAvatarAndName.find(acc => acc.elementId === undefined)?.isSelected;

  return (
    <>
      <AccountsCards
        id={id}
        comparableAccounts={comparableAccounts}
        setComparableAccounts={setComparableAccounts}
        maxCards={5}
      />
      <CompareOverViewSection
        period={period}
        id={id}
        accountsAvatarAndName={visibleAccountsAvatarAndName}
        visibleAccountsIds={visibleComparableAccountsIds}
        isMainAccountVisible={isMainAccountVisible}
      />
      <ComparableTagRanking
        period={period}
        id={id}
        accountsAvatarAndName={visibleAccountsAvatarAndName}
        visibleAccountsIds={visibleComparableAccountsIds}
        isMainAccountVisible={isMainAccountVisible}
      />
      <YtComparePosts
        period={period}
        id={id}
        accountsAvatarAndName={visibleAccountsAvatarAndName}
        visibleAccountsIds={visibleComparableAccountsIds}
        isMainAccountVisible={isMainAccountVisible}
      />
    </>
  );
};

const StyledLoadingIndicator = styled(ListIndicator)`
  margin: 0;
`;

export default YoutubeAnalyticsCompare;
