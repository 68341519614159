import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SocialAccountType } from '../../../../../__generated__/globalTypes';
import chartIcon from '../../../../../assets/icon/chart.svg';
import { formatIntNumber, formatDate } from '../../../../../libs/format';
import { switchFollowersGrowthLineColor, switchText } from '../../../../../libs/SocialMedia';
import { ViewportType } from '../../../../../libs/theme';
import HighChart, { Highcharts } from '../../../../molecules/HighChart';
import ProfileDetailsContentWrapper from '../ProfileDetailsContentWrapper/ProfileDetailsContentWrapper';

const dateFormatInXAxis = 'd MMM';

const chartHeight = 190;

export interface FollowersGrowthData {
  time: any;
  followersCount: number | null;
}

interface FollowersGrowthProps {
  className?: string;
  socialAccountType: SocialAccountType | string;
  followersGrowth: FollowersGrowthData[];
}

const FollowersGrowth = (props: FollowersGrowthProps) => {
  const { className, followersGrowth, socialAccountType } = props;

  const { t } = useTranslation();

  // Detect Facebook pages
  const selectedSocialAccount = Object.keys(SocialAccountType).includes(socialAccountType)
    ? socialAccountType
    : SocialAccountType.FACEBOOK_PAGE;

  const color = switchFollowersGrowthLineColor(selectedSocialAccount);

  const data = followersGrowth.map(item => item.followersCount);

  const categories = followersGrowth.map(item => formatDate(item.time, dateFormatInXAxis));

  const options: Highcharts.Options = {
    chart: {
      height: chartHeight,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories,
    },
    yAxis: {
      title: {
        text: '',
      },
      allowDecimals: false,
    },
    legend: {
      enabled: false,
    },
    // @ts-ignore
    series: [
      {
        type: 'line',
        name: switchText(selectedSocialAccount as SocialAccountType),
        data,
        color,
        marker: {
          symbol: 'circle',
        },
      },
    ],
    tooltip: {
      padding: 0,
      formatter: function formatter(): string {
        return `
          <div style="border-radius:3px;border: 2px solid #27313b;margin:-2px;background-color:#27313b;font-size:12px;padding:13px 16px;display:flex;align-items:center">
            <span style="display:inline-block;margin-right:8px;background-color:${this.point.color};height:12px;width:12px"></span>
            <span style="margin-right:16px">${this.key}
            </span><span>${this.point.y}</span>
          </div>`;
      },
      pointFormat: '',
      borderColor: '#27313b',
      borderWidth: 0,
      backgroundColor: '#27313b',
      style: {
        color: '#ffffff',
      },
      useHTML: true,
      headerFormat: '',
    },
  };

  const header = (
    <Header>
      <TitleWrapper>
        <Img src={chartIcon} />
        <HeadingText>{t('HeaderColumn.Followers Growth')}</HeadingText>
      </TitleWrapper>
      <CountWrapper>
        <FollowersCount>{data.length ? formatIntNumber(data[data.length - 1]) : null}</FollowersCount>
        <Label>{t('Label.Total Followers')}</Label>
      </CountWrapper>
    </Header>
  );

  return (
    <ProfileDetailsContentWrapper header={header} className={className} dataNotAvailable={followersGrowth.length === 0}>
      <HighChart options={options} />
    </ProfileDetailsContentWrapper>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${ViewportType.MEDIUM}px) {
    margin-bottom: 20px;
  }
`;

const HeadingText = styled.span`
  font-size: 13px;
  font-weight: 600;
  color: #27313b;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  @media (max-width: ${ViewportType.MEDIUM}px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

const Img = styled.img`
  width: 24px;
  margin-right: 10px;
`;

const CountWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.h6`
  color: #6e7c89;
  font-size: 12px;
`;

const FollowersCount = styled.h3`
  font-size: 20px;
  margin-bottom: 4px;
  color: #27313b;
`;

export default FollowersGrowth;
