import React, { Dispatch, SetStateAction } from 'react';
import Popover from '@material-ui/core/Popover';
import { css } from '@emotion/react';
import { CheckBox } from '@src/components/atoms/CheckBox';
import { useTranslation } from 'react-i18next';
import { PalleteButton } from '@src/components/atoms/Button';
import { mainBlue } from '@src/libs/pallete';

interface AddEngagementTilesModalProps {
  anchorEl: null | HTMLElement;
  setAnchorEl: (el: null | HTMLElement) => void;
  setSelectedCheckboxes: Dispatch<SetStateAction<string[]>>;
  savedEngagementTiles: string[];
  selectableCharts: string[];
  allSelectedCheckboxes: string[];
  handleEngagementSave: () => void;
}
export const AddEngagementTilesModal = ({
  anchorEl,
  setAnchorEl,
  savedEngagementTiles,
  setSelectedCheckboxes,
  selectableCharts,
  allSelectedCheckboxes,
  handleEngagementSave,
}: AddEngagementTilesModalProps) => {
  const { t } = useTranslation();

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={() => {
        setAnchorEl(null);
      }}
      // reset to prev saved state
      onExited={() => setSelectedCheckboxes(savedEngagementTiles)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      css={styles.popover}
    >
      <div>
        <div css={styles.checkboxes}>
          {selectableCharts.map(chart => {
            const isChecked = allSelectedCheckboxes.includes(chart);

            return (
              <CheckBox
                key={chart}
                value={isChecked}
                name={chart}
                label={t(chart)}
                handleClick={() => {
                  if (isChecked) {
                    setSelectedCheckboxes(allSelectedCheckboxes.filter(checkboxVal => checkboxVal !== chart));
                  } else {
                    setSelectedCheckboxes([...allSelectedCheckboxes, chart]);
                  }
                }}
                css={css`
                  &:not(:last-of-type) {
                    padding-bottom: 16px;
                  }
                `}
              />
            );
          })}
        </div>
        <div css={styles.submitSection}>
          <PalleteButton
            text="Save"
            onClick={handleEngagementSave}
            palleteColor={mainBlue}
            borderRadius="3px"
            width="auto"
            height="24px"
            fontSize="12px"
            css={styles.submitBtn}
          />
        </div>
      </div>
    </Popover>
  );
};

const styles = {
  popover: css`
    z-index: 100;
    margin-top: 4px !important;

    & .MuiPaper-elevation8 {
      box-shadow: 0 1px 2px 0 #27313b19;
      width: 248px;
      background-color: #fff;
      border-radius: 3px;
      border: solid 1px #dee5ec;
    }
  `,
  checkboxes: css`
    padding: 8px;
  `,
  submitSection: css`
    padding: 8px;
    border-top: 1px solid #dee5ec;
  `,
  submitBtn: css`
    margin-left: auto;
    min-width: 66px;
  `,
};
