import { format } from 'date-fns';
import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { AuthSocialAccountType, Genders } from '@src/__generated__/globalTypes';
import { DEFAULT_FNS_DATE_FORMAT } from '@src/libs/constant';
import { SignUpProfileSchema } from '@src/libs/validation';
import { useInfluencerCategories, useAllAvailableCountries } from '@src/libs/hooks';
import { switchSocialMediaName } from '@src/libs/SocialMedia';
import SignUpEmailForm from '@src/components/organisms/SignUpEmailForm/SignUpEmailForm';
import { FormValues, ProfileInput } from './types';

export interface SignUpProfileFormProps {
  socialMedia: AuthSocialAccountType;
  name: string;
  email: string;
  uuid: string;
}

const SignUpProfileFormComponent = (props: SignUpProfileFormProps) => {
  const history = useHistory();
  const { influencerCategories } = useInfluencerCategories();
  const { allTranslatedCountries } = useAllAvailableCountries();

  const handleSubmit = async (payload: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    setSubmitting(true);
    const { name, categories, gender, country, regionId, email, phoneNumber, birthday } = payload;
    const { socialMedia, uuid } = props;

    const input: ProfileInput = {
      name,
      email,
      countryId: country,
      regionId: Number(regionId),
      categoryIds: categories.map(category => Number(category)),
      gender: gender || Genders.UNKNOWN,
      dateOfBirth: format(new Date(birthday), DEFAULT_FNS_DATE_FORMAT),
      phoneNumber: phoneNumber || '',
      uuid,
    };
    localStorage.setItem('profile', JSON.stringify(input));

    const sm = switchSocialMediaName(socialMedia);
    history.push(`/redirect/signup/${sm}`);
  };

  const profileData = localStorage.getItem('profile');
  const profile: ProfileInput = profileData ? JSON.parse(profileData) : {};

  return (
    <Formik
      initialValues={{
        name: props.name || profile.name || '',
        categories: profile?.categoryIds?.map(id => (id ? String(id) : '')) || [],
        gender: profile.gender || '',
        country: profile.countryId || '',
        regionId: profile.regionId ? String(profile.regionId) : '',
        email: props.email || profile.email || '',
        phoneNumber: profile.phoneNumber,
        birthday: profile.dateOfBirth || '',
        agreed: false,
      }}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
      validationSchema={SignUpProfileSchema}
    >
      <SignUpEmailForm allCountries={allTranslatedCountries} allCategories={influencerCategories} />
    </Formik>
  );
};

export default SignUpProfileFormComponent;
