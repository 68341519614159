import React, { useState, useRef, useEffect, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import searchIcon from '@src/assets/icon/search.svg';
import closeIcon from '@src/assets/icon/close.svg';
import filterDescIcon from '@src/assets/icon/filter-desc.svg';
import arrowDownIcon from '@src/assets/icon/arrowDown.svg';
import SearchForm from '@src/components/molecules/SearchForm';
import Popup from 'reactjs-popup';
import { css } from '@emotion/react';
import TextCutter from '@src/components/atoms/TextCutter';
import { CRMChatTypeFilter } from '@src/__generated__/globalTypes';
import useDimensions from '@src/libs/hooks/useDimensions';
import { ChatFilterFormValues, LineChatCountsType } from '../types';

const checkmarkCode = '\u2713';

export interface ChatListFilterProps extends LineChatCountsType {
  onKeywordSearch: (keyword: string) => void;
  filter: ChatFilterFormValues;
  setFilter: React.Dispatch<React.SetStateAction<ChatFilterFormValues>>;
  disabled?: boolean;
}

const ChatListFilter = ({
  totalNum = 0,
  assignedToMeNum = 0,
  setFilter,
  onKeywordSearch,
  filter: { type, keyword },
  disabled,
}: ChatListFilterProps) => {
  const [showSearch, setShowSearch] = useState(false);
  const { t } = useTranslation();
  const filterRef = useRef<HTMLDivElement | null>(null);
  const { width } = useDimensions(filterRef);

  useEffect(() => {
    if (width) {
      setPopupStyles(prevState => ({ ...prevState, width: `${width}px` }));
    }
  }, [width]);

  const [popupStyles, setPopupStyles] = useState<CSSProperties>({
    marginTop: '4px',
    padding: 0,
    border: '1px solid #DEE5EC',
    borderRadius: '3px',
    boxShadow: '0px 1px 2px rgba(39, 49, 59, 0.1)',
    boxSizing: 'border-box',
    width: 'max-content',
  });

  const options = [
    { value: CRMChatTypeFilter.ASSIGNED_TO_ME, label: 'Assigned to me', count: assignedToMeNum },
    { value: CRMChatTypeFilter.ACTIVATED, label: 'All', count: totalNum },
  ];

  const selectedOption = options.find(opt => opt.value === type);

  return (
    <div css={styles.wrapper} className={disabled ? 'disabled' : ''}>
      <div css={{ flex: 1 }} ref={filterRef}>
        {showSearch ? (
          <SearchForm
            placeholder={t('search')}
            autoFocus
            value={keyword}
            onChange={e => {
              const inputText = e.target.value;
              setFilter(prevState => ({ ...prevState, keyword: inputText }));
              onKeywordSearch(inputText);
            }}
          />
        ) : (
          <Popup
            trigger={isOpened => (
              <div css={styles.popupTrigger} className={disabled ? 'disabled' : ''}>
                <img src={filterDescIcon} css={styles.filterDecs} />
                {selectedOption ? (
                  <TextCutter
                    lines={1}
                    text={`${t(selectedOption.label)} (${selectedOption.count})`}
                    css={styles.filterText}
                  />
                ) : (
                  '-'
                )}
                <img src={arrowDownIcon} css={[styles.arrowDown, isOpened ? { transform: 'rotate(180deg)' } : '']} />
              </div>
            )}
            position="bottom left"
            on="click"
            closeOnDocumentClick
            contentStyle={popupStyles}
            arrow={false}
          >
            {close => (
              <div>
                {options.map(option => {
                  const isSelected = option.value === type;

                  return (
                    <div
                      key={option.value}
                      css={[styles.popupItem, isSelected ? { backgroundColor: '#f6f8fa' } : '']}
                      onClick={() => {
                        setFilter(prevState => ({ ...prevState, type: option.value }));
                        close();
                      }}
                    >
                      <div css={styles.checkmark}>{isSelected ? checkmarkCode : null}</div>
                      <div css={[styles.optionText]}>
                        {/* {option.status ? <span css={styles.optionStatus}>{t(option.status)}</span> : null} */}
                        <span
                          css={[
                            styles.optionLabel,
                            isSelected
                              ? css`
                                  color: #3892e5;
                                  font-weight: 600;
                                `
                              : '',
                          ]}
                        >
                          {`${t(option.label)} (${option.count})`}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </Popup>
        )}
      </div>
      <div css={styles.iconBox} className={disabled ? 'disabled' : ''}>
        {showSearch ? (
          <img
            src={closeIcon}
            alt="close"
            onClick={() => {
              setShowSearch(false);
              setFilter(prevState => ({ ...prevState, keyword: '' }));
              onKeywordSearch('');
            }}
            css={styles.close}
          />
        ) : (
          <img src={searchIcon} alt="search" onClick={() => setShowSearch(true)} />
        )}
      </div>
    </div>
  );
};

const styles = {
  wrapper: css`
    display: flex;
    padding: 8px;
    column-gap: 8px;
    background-color: #fff;

    &.disabled {
      opacity: 0.3;
      background-color: #c5d0da;
    }
  `,
  popupTrigger: css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    border: 1px solid #dee5ec;
    box-sizing: border-box;
    border-radius: 3px;
    background-color: #fff;

    &.disabled {
      pointer-events: none;
      background-color: inherit;
    }
  `,
  filterDecs: css`
    margin: 0 8px 0 4px;
  `,
  filterText: css`
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #27313b;
  `,
  checkmark: css`
    color: #3892e5;
    width: 24px;
    text-align: center;
  `,
  optionText: css`
    display: flex;
    flex-direction: column;
  `,
  optionLabel: css`
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  `,
  optionStatus: css`
    font-size: 10px;
    color: #6e7c89;
  `,
  popupItem: css`
    display: flex;
    align-items: center;
    height: 48px;
    padding-right: 16px;
    cursor: pointer;

    &:hover {
      background-color: #dee5ec;
    }
  `,
  iconBox: css`
    align-items: center;
    display: flex;
    width: 32px;
    height: 32px;
    background: #fff;
    border: 1px solid #dee5ec;
    box-sizing: border-box;
    border-radius: 3px;

    &.disabled {
      pointer-events: none;
      background-color: inherit;
    }

    img {
      margin: auto;
    }

    &:hover {
      border-color: lightgrey;
      cursor: pointer;
    }
  `,
  arrowDown: css`
    margin-left: auto;
    margin-right: 4px;
    transition: all 0.2s ease-in-out;
  `,
  close: css`
    filter: brightness(0.5);
    width: 16px;
    height: 16px;
  `,
};

export default ChatListFilter;
