import { ApolloError } from '@apollo/client';
import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import { JPPaymentInformationSchema, PaymentInformationSchema } from '@src/libs/validation';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { useAuthData, useQueryHelper, useUpdateDocumentTitle } from '@src/libs/hooks';
import PaymentInformationForm from './PaymentInformationForm';
import { FormValues } from './types';
import { usePaymentInformation } from './utils';
import PaymentInformationFormJP from './PaymentInformationFormJP';

const PaymentInformationFormComponent = () => {
  const title = 'pageTitle.Payment Information';
  const pageTitle = 'pageTitle.Payment Information';
  const href = '/profile/settings';
  useUpdateDocumentTitle({ title, pageTitle, href });

  const { t, enqueueSnackbar } = useQueryHelper();
  // Query
  const { userId } = useAuthData();
  const {
    updatePaymentInformation,
    loading,
    allBanks,
    allCities,
    information,
    isMenaInfluencer,
    hasBranchId,
    country,
  } = usePaymentInformation(userId);

  const handleSubmit = async (payload: FormValues, { setSubmitting, setFieldError }: FormikHelpers<FormValues>) => {
    setSubmitting(true);
    const {
      accountName,
      email,
      bankId,
      branchId,
      accountNumber,
      address,
      cityId,
      postalCode,
      iban,
      swiftCode,
      branchName,
      subject,
      fullName,
    } = payload;
    // Update Account
    const variables = {
      input: {
        accountName,
        email,
        bankId: Number(bankId),
        ...(branchId && { branchId: Number(branchId) }),
        accountNumber,
        // we need to remove lineBreaks from address
        address: address.replace(/^\s+|\s+$/g, ''),
        cityId: cityId ? Number(cityId) : null,
        postalCode,
        iban,
        swiftCode,
        branchName,
        subject,
        fullName,
      },
    };
    await updatePaymentInformation({ variables })
      .then(result => {
        if (result?.data?.updatePaymentInformation?.ok) {
          enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
        } else {
          const failureMessage = 'failedToUpdate';
          enqueueSnackbar(t(failureMessage), { variant: 'error' });
        }
      })
      .catch((e: ApolloError) => {
        console.error(e);
        const { message } = e.graphQLErrors[0];
        if (message === 'Name should be english.') {
          setFieldError('accountName', 'Name should be in English');
        }
        if (message === 'Email is not valid') {
          setFieldError('email', 'Email is not valid');
        }
        if (message === 'Account name should contain only full or half-width Katakana characters') {
          setFieldError('accountName', 'Account name should contain only full or half-width Katakana characters');
        }
        if (message === 'maximum letters should be less than 88 letters.') {
          setFieldError('address', 'Address should be less than 88 letters');
        }
        if (message === 'Address should be english') {
          setFieldError('address', 'Address should be in English');
        }
        if (message === 'Account number is not valid') {
          setFieldError('accountNumber', 'Account number is not valid');
        }
        if (message === 'Swift code should be less than 12 letters') {
          setFieldError('swiftCode', 'Swift code should be less than 12 letters');
        }
        enqueueSnackbar(t(message || 'failedToUpdate'), { variant: 'error' });
      });

    setSubmitting(false);

    return;
  };

  if (loading) {
    return <ListIndicator />;
  }

  const isJPInfluencer = country?.id === 'JP';

  return (
    <Formik
      initialValues={{
        accountName: information.accountName,
        email: information.email,
        bankId: information.bankId,
        branchId: information.branchId,
        accountNumber: information.accountNumber,
        address: information.address,
        cityId: information.cityId,
        postalCode: information.postalCode,
        iban: information.iban,
        swiftCode: information.swiftCode,
        branchName: information.branchName,
        isMenaInfluencer,
        hasBranchId,
        subject: information.subject,
        fullName: information.fullName,
      }}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={isJPInfluencer ? JPPaymentInformationSchema : PaymentInformationSchema}
    >
      {isJPInfluencer ? (
        <PaymentInformationFormJP allBanks={allBanks} />
      ) : (
        <PaymentInformationForm isMenaInfluencer={isMenaInfluencer} allBanks={allBanks} allCities={allCities} />
      )}
    </Formik>
  );
};

export default PaymentInformationFormComponent;
