import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CRM_URL } from '@src/libs/requests';
import { useQueryHelper } from '@src/libs/hooks';
import { downloadFile } from '@src/libs/requests/downloadFile';
import REMOVE_ALL_FORM_RESPONSES from './RemoveAllFormResponses.graphql';
import { RemoveAllFormResponses, RemoveAllFormResponsesVariables } from './__generated__/RemoveAllFormResponses';

export default () => {
  const { t, params, enqueueSnackbar } = useQueryHelper();
  const { id } = params as { id: string };

  const [downloadMenu, setDownloadMenu] = useState<null | HTMLElement>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const [deleteAllFormResponses] = useMutation<RemoveAllFormResponses, RemoveAllFormResponsesVariables>(
    REMOVE_ALL_FORM_RESPONSES,
    {
      refetchQueries: ['GetFormRespondentsList'],
      onCompleted: () => {
        enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
      },
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
    }
  );

  const openDownloadMenu = (e: React.MouseEvent<HTMLElement>) => {
    setDownloadMenu(e.currentTarget);
  };

  const closeDownloadMenu = () => {
    setDownloadMenu(null);
  };

  const showDeleteConfirmation = () => {
    setDeleteConfirmation(true);
  };

  const hideDeleteConfirmation = () => {
    setDeleteConfirmation(false);
  };

  const handleClickDownloadResponses = async () => {
    const url = new URL(`${CRM_URL}forms/responses/${id}`);
    await downloadFile({ url, filenamePrefix: 'responses', extension: '.csv' });

    closeDownloadMenu();
  };

  const handleClickDeleteAllResponses = () => {
    closeDownloadMenu();

    showDeleteConfirmation();
  };

  const handleDeleteConfirm = async () => {
    await deleteAllFormResponses({
      variables: {
        input: {
          formId: Number(id),
        },
      },
    });

    hideDeleteConfirmation();
  };

  return {
    downloadMenu,
    deleteConfirmation,
    openDownloadMenu,
    closeDownloadMenu,
    showDeleteConfirmation,
    hideDeleteConfirmation,
    handleDeleteConfirm,
    handleClickDeleteAllResponses,
    handleClickDownloadResponses,
  };
};
