import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icon } from '@src/components/atoms/Icon';
import Grid from '@src/components/atoms/Grid';
import { useQueryHelper } from '@src/libs/hooks';
import { checkIsActivePathname, menuItems, MenuItemProps } from './helpers';
import Menu from './Menu';

interface MobileMenuProps {
  open?: boolean;
  onClose?: () => void;
}

const MobileMenu = ({ open, onClose }: MobileMenuProps) => {
  const { pathname, t } = useQueryHelper();
  const isSelectedMenu = menuItems
    .filter(menu => menu.submenus)
    .find(menu =>
      menu.submenus?.some(submenu => {
        // submenu may contain multiple pathname
        // to check has visible submenu
        if (typeof submenu.to === 'string') {
          return checkIsActivePathname(submenu.to, true);
        } else {
          return submenu.to.some(path => checkIsActivePathname(path, true));
        }
      })
    );

  const selectedMenuItems =
    isSelectedMenu && (menuItems.find(menu => menu.title === isSelectedMenu.title) as MenuItemProps);
  const isAnalytics = pathname.includes('analytics');

  return (
    <div css={styles.container}>
      <Overlay open={open}>
        <div css={styles.closeBtn} onClick={onClose}>
          <Icon color="#6e7C89" icon="close" />
        </div>

        <Menu />
      </Overlay>

      {selectedMenuItems && (
        <div css={styles.submenu}>
          <Grid container xs={12}>
            {selectedMenuItems.submenus?.map((submenu, index) => {
              const { activeIcon, activeSubmenuIcon, icon, to } = submenu;
              const isActive =
                typeof to === 'string' ? checkIsActivePathname(to) : to.some(path => checkIsActivePathname(path));
              // submenu may contain multiple valid pathname
              const redirect = typeof to === 'string' ? to : to[0];

              return (
                <SubmenuContainer key={index} to={redirect}>
                  <div>
                    <img
                      alt="submenu"
                      height="24"
                      src={isActive ? (activeSubmenuIcon ? activeSubmenuIcon : activeIcon) : icon}
                      width="24"
                    />
                  </div>
                  <SubmenuTitle isActive={isActive} isAnalytics={isAnalytics}>
                    {t(submenu.title)}
                  </SubmenuTitle>
                </SubmenuContainer>
              );
            })}
          </Grid>
        </div>
      )}
    </div>
  );
};

const Overlay = styled.div<{ open?: boolean }>`
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: ${({ open }) => (open ? 'block' : 'none')};
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
`;

const SubmenuContainer = styled(Link)`
  display: grid;
  flex: auto;

  & > div {
    display: flex;
    justify-content: center;
  }
`;

const SubmenuTitle = styled.div<{ isActive: boolean; isAnalytics: boolean }>`
  color: ${({ isActive, isAnalytics }) => (isActive ? (isAnalytics ? '#27313b' : '#3892e5') : '#97a4af')};
  display: flex;
  font-weight: 600;
  justify-content: center;
`;

const styles = {
  closeBtn: css`
    background-color: #fff;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 40px;

    & i {
      font-size: 25px;
      margin-top: 8px;
    }
  `,
  container: css`
    position: relative;
  `,
  submenu: css`
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #e5ebf1;
    bottom: 0;
    box-shadow: 0 1px 2px 0 #dee5ec;
    display: flex;
    height: 56px;
    justify-content: center;
    position: fixed;
    width: 100%;
    z-index: 5;
  `,
};

export default MobileMenu;
