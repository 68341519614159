import { ApolloError } from '@apollo/client';
import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import InitialLoading from '@src/components/molecules/InitialLoading';
import { getMessagesFromApolloError, getMessagesFromFetchResult, UNEXPECTED_ERROR } from '@src/libs/error';
import { useQueryHelper } from '@src/libs/hooks';
import SHOPIFY_VALIDATE_AUTH from './mutations/ShopifyValidateAuth.graphql';
import { ShopifyValidateAuth, ShopifyValidateAuthVariables } from './mutations/__generated__/ShopifyValidateAuth';

const ShopifyConnect = () => {
  const { t, enqueueSnackbar, history, search } = useQueryHelper();
  const response = search.substr(1);

  const [shopifyValidateAuth] = useMutation<ShopifyValidateAuth, ShopifyValidateAuthVariables>(SHOPIFY_VALIDATE_AUTH);

  if (!response) {
    enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
    history.push('/settings/channel_connect');

    return null;
  }

  async function validateAuth() {
    const shopName = localStorage.getItem('shopifyStoreName') || '';

    const variables = {
      input: {
        response,
        shopName,
      },
    };
    const { ok, errors } = await shopifyValidateAuth({ variables })
      .then(result => {
        if (result?.data?.shopifyValidateAuth?.ok) {
          return { ok: true, errors: [] };
        } else {
          return { ok: false, errors: getMessagesFromFetchResult(result) };
        }
      })
      .catch((e: ApolloError) => ({ ok: false, errors: getMessagesFromApolloError(e) }));

    if (ok) {
      enqueueSnackbar(t('succeededInConnecting'), { variant: 'success' });
    } else {
      errors.forEach(error => {
        console.error(error);
        enqueueSnackbar(t(error), { variant: 'error' });
      });
    }

    history.push('/settings/channel_connect');
  }

  useEffect(() => {
    validateAuth();
  }, []);

  return <InitialLoading />;
};

export default ShopifyConnect;
