import React from 'react';
import { css } from '@emotion/react';
import { defaultInfluencerAvatar } from '@src/libs/image';
import TextCutter from '@src/components/atoms/TextCutter';
import { useQueryHelper } from '@src/libs/hooks';
import { CompareAccountType } from './types';

interface TabsAccountsProps {
  allCompareAccounts: CompareAccountType[];
  setAllCompareAccounts: React.Dispatch<React.SetStateAction<CompareAccountType[]>>;
  isMobile: boolean;
}
export const TabsAccounts = ({ allCompareAccounts, setAllCompareAccounts, isMobile }: TabsAccountsProps) => {
  const { search, history } = useQueryHelper();
  const searchParams = new URLSearchParams(search);

  const handleClick = (account: CompareAccountType) => {
    if (searchParams.has('p')) {
      searchParams.delete('p');
      history.replace({
        search: searchParams.toString(),
      });
    }

    setAllCompareAccounts(prevState =>
      prevState.map(acc => ({ ...acc, isSelected: acc.accountId === account.accountId }))
    );
  };

  return (
    <div
      css={css`
        margin: ${isMobile ? '8px 16px' : '24px 24px 0 24px'};
      `}
    >
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
        `}
      >
        {allCompareAccounts.map(el => (
          <div
            key={el.channelName}
            onClick={() => (!el.isSelected ? handleClick(el) : null)}
            css={css`
              display: flex;
              align-items: center;
              height: 40px;
              border-bottom: 1px solid #dee5ec;
              border-right: 1px solid #dee5ec;
              background-color: ${el.isSelected ? '#3892E5' : '#fff'};
              color: ${el.isSelected ? '#fff' : '#6e7c89'};
              cursor: pointer;

              &:hover {
                opacity: 0.8;
              }

              &:nth-of-type(-n + 3) {
                border-top: 1px solid #dee5ec;
              }

              &:nth-of-type(4) {
                border-left: 1px solid #dee5ec;
                border-bottom-left-radius: 3px;
              }

              &:first-of-type {
                border-top-left-radius: 3px;
                border-left: 1px solid #dee5ec;
              }

              &:last-of-type {
                border-bottom-right-radius: 3px;
              }

              &:nth-of-type(3) {
                border-top-right-radius: 3px;
              }
            `}
          >
            <img
              src={defaultInfluencerAvatar(el.avatarUrl)}
              alt="channel avatar"
              width="24"
              height="24"
              css={css`
                border-radius: 50%;
                margin: 0 8px;
              `}
            />
            <TextCutter text={el.channelName} lines={2} />
          </div>
        ))}
      </div>
    </div>
  );
};
