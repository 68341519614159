import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface EngagementProps {
  value: string;
  unit: string;
}

const Engagement = (props: EngagementProps) => {
  const { t } = useTranslation();
  const { value, unit } = props;
  const translatedUnit = unit ? t(`Label.${unit}`) : '';

  return (
    <div>
      <Value>{value}</Value>
      <Unit>{translatedUnit}</Unit>
    </div>
  );
};

const Value = styled.h6`
  font-weight: 600;
  font-size: 12px;
  color: #27313b;
`;

const Unit = styled.p`
  margin-top: 1px;
  color: #6e7c89;
  font-size: 11px;
`;

export default Engagement;
