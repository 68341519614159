import { ApolloError } from '@apollo/client';
import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useQueryHelper } from '../../../libs/hooks';
import InitialLoading from '../../../components/molecules/InitialLoading';
import { getCallbackUrl, removeToken, setToken } from '../../../libs/auth';
import { getMessagesFromApolloError, getMessagesFromFetchResult } from '../../../libs/error';
import { useProviderResponse } from '../hooks';
import { AuthSocialAccountType } from '../../../__generated__/globalTypes';
import { useAuthSetup } from '../../../components/organisms/AuthSetUp/useAuthSetup';
import {
  SocialAuthReConnectInstagram,
  SocialAuthReConnectInstagramVariables,
} from './__generated__/SocialAuthReConnectInstagram';
import * as SOCIAL_AUTH_RECONNECT from './SocialAuthReConnectInstagram.graphql';

const IgReconnectRedirect = () => {
  const [socialAuthReconnect] = useMutation<SocialAuthReConnectInstagram, SocialAuthReConnectInstagramVariables>(
    SOCIAL_AUTH_RECONNECT
  );
  const { setUp } = useAuthSetup();

  const { t, enqueueSnackbar, history } = useQueryHelper();

  const { response } = useProviderResponse('/reconnect');

  const executeReconnect = async () => {
    const variables: SocialAuthReConnectInstagramVariables = {
      input: {
        response,
        callbackUrl: getCallbackUrl(AuthSocialAccountType.FACEBOOK, FE_REDIRECT_MAPPING.RECONNECT_IG),
      },
    };

    const { token, errors } = await socialAuthReconnect({ variables })
      .then(result => {
        if (result?.data?.socialAuthReConnectInstagram) {
          const payload = result.data.socialAuthReConnectInstagram;

          return {
            token: payload.token,
            errors: [],
          };
        } else {
          return {
            token: null,
            errors: getMessagesFromFetchResult(result),
          };
        }
      })
      .catch((e: ApolloError) => ({
        token: null,
        errors: getMessagesFromApolloError(e),
      }));

    // SocialAuthentication Failed
    if (!token || errors.length > 0) {
      errors.forEach(error => {
        console.error(error);
        enqueueSnackbar(t(error), { variant: 'error' });

        // User needs to sign up instead of sign in.
        if (error === "account doesn't exist") {
          if (window.opener) {
            window.opener.postMessage({ redirectPath: '/signup', errorMsg: `account doesn't exist` }, '*');
            window.close();

            return;
          }
          history.push('/signup');

          return;
        }
      });
      if (window.opener) {
        window.opener.postMessage({ redirectPath: '/' }, '*');
        window.close();

        return;
      }
      history.push('/');

      return;
    }

    // Sign In with token
    setToken(token);

    try {
      await setUp(token);
      if (window.opener) {
        window.opener.postMessage({ redirectPath: '/settings/social_connect' }, '*');
        window.close();

        return;
      }
      history.push('/settings/social_connect');
    } catch (e) {
      removeToken();
      console.error(e);
      enqueueSnackbar(t(e.message), { variant: 'error' });
      if (window.opener) {
        window.opener.postMessage({ redirectPath: '/', errorMsg: e.message }, '*');
        window.close();

        return;
      }
      history.push('/');
    }

    return;
  };

  useEffect(() => {
    executeReconnect();
  }, []);

  return <InitialLoading />;
};

export default IgReconnectRedirect;
