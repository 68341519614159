import TimePicker, { TimePickerProps } from 'rc-time-picker';
import React from 'react';
import { css } from '@emotion/react';
import clockLight from '@src/assets/icon/clockLight.svg';
import { Icon } from '@src/components/atoms/Icon';
import 'rc-time-picker/assets/index.css';

export const timeDefaultFormat = 'hh:mm A';

interface RCTimePickerProps extends TimePickerProps {
  format?: string;
  showSecond?: boolean;
}

const RCTimePicker = ({ format = timeDefaultFormat, showSecond = false, ...restProps }: RCTimePickerProps) => (
  <div css={styles.timePickerContainer}>
    <img alt="icon" height="24" src={clockLight} width="24" />
    <TimePicker format={format} showSecond={showSecond} {...restProps} />
    <div css={styles.iconContainer}>
      <Icon color="#27313b" icon="expand_more" />
    </div>
  </div>
);

const styles = {
  iconContainer: css`
    position: absolute;
    right: 8px;
    top: 8px;

    & > i {
      margin: 0;
    }
  `,
  timePickerContainer: css`
    position: relative;

    & > img {
      left: 8px;
      position: absolute;
      top: 4px;
      z-index: 1;
    }

    & .rc-time-picker {
      & > input {
        border-color: #dee5ec;
        border-radius: 3px;
        color: #27313b;
        font-size: 13px;
        height: 32px;
        padding-left: 40px;
        width: 150px;
      }

      & > a {
        display: none;
      }
    }
  `,
};

export default RCTimePicker;
