import { FormValues } from './types';

export const hasAllRequiredValues = (values: FormValues) =>
  !!values.name &&
  !!values.email &&
  !!values.categories.length &&
  !!values.gender &&
  !!values.birthday &&
  !!values.country &&
  !!values.regionId;
