import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { useAuthData, useQueryHelper } from '@src/libs/hooks';
import { InfluencerType } from '@src/__generated__/globalTypes';

const Tips = () => {
  const { creatorType } = useAuthData();
  const { i18n, t } = useQueryHelper();
  const isJPLanguage = i18n.language === 'ja';
  const isProInfluencer = creatorType === InfluencerType.PRO;

  // temporary using static information until feature is implement
  const questions = [
    {
      title: 'What can I do with AnyCreator',
      to: isProInfluencer
        ? isJPLanguage
          ? 'https://anymind-group.gitbook.io/anycreator-help/hajimeni/untitled'
          : 'https://anymind-group.gitbook.io/anycreator-help-page-english/getting-started/anyxs-function'
        : isJPLanguage
        ? 'https://anymind-group.gitbook.io/anycreatorhelp/hajimeni/untitled'
        : 'https://anymind-group.gitbook.io/anycreator-help-page/getting-started/anyxs-function',
    },
    {
      title: 'I fail to connect Facebook to Instagram',
      to: isProInfluencer
        ? isJPLanguage
          ? 'https://anymind-group.gitbook.io/anycreator-help/yokuaru/instagramtofacebooknogadekinai'
          : 'https://anymind-group.gitbook.io/anycreator-help-page-english/faq/fail-to-connect-instagram-to-facebook'
        : isJPLanguage
        ? 'https://anymind-group.gitbook.io/anycreatorhelp/yokuaru/instagramtofacebooknogadekinai'
        : 'https://anymind-group.gitbook.io/anycreator-help-page/faq/fail-to-connect-instagram-to-facebook',
    },
    {
      title: 'How to grant the permissions',
      to: isProInfluencer
        ? isJPLanguage
          ? 'https://anymind-group.gitbook.io/anycreator-help/yokuaru/akusesunogawakaranai'
          : 'https://anymind-group.gitbook.io/anycreator-help-page-english/faq/how-to-grant-permissions'
        : isJPLanguage
        ? 'https://anymind-group.gitbook.io/anycreatorhelp/yokuaru/akusesunogawakaranai'
        : 'https://anymind-group.gitbook.io/anycreator-help-page/faq/how-to-grant-permissions',
    },
  ];

  return (
    <div css={styles.tipsContainer}>
      <div css={styles.header}>
        <div>{t('Tips')}</div>
        <div>
          <Link
            css={styles.helpCenterAnchor}
            target="_blank"
            to={{
              pathname: isProInfluencer
                ? isJPLanguage
                  ? 'https://anymind-group.gitbook.io/anycreator-help/'
                  : 'https://anymind-group.gitbook.io/anycreator-help-page-english/'
                : isJPLanguage
                ? 'https://anymind-group.gitbook.io/anycreatorhelp/'
                : 'https://anymind-group.gitbook.io/anycreator-help-page/',
            }}
          >
            {t('Help Center')}
          </Link>
        </div>
      </div>

      <div style={{ padding: '16px 16px 1px 16px' }}>
        {questions.map((question, qIndex) => {
          const { title, to } = question;

          return (
            <div css={styles.information} key={qIndex}>
              <Link target="_blank" to={{ pathname: to }}>
                {t(`Annotation.${title}`)}
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const styles = {
  header: css`
    align-items: center;
    border-bottom: 1px solid #dee5ec;
    display: flex;
    padding: 16px;

    & > div:nth-of-type(1) {
      color: #27313b;
      display: grid;
      flex-basis: 50%;
      font-size: 16px;
      font-weight: 600;
    }

    & > div:nth-of-type(2) {
      display: grid;
      flex-basis: 50%;
      justify-content: flex-end;

      & > a {
        color: #3892e5;
        font-size: 14px;
      }
    }
  `,
  helpCenterAnchor: css`
    &:hover {
      text-decoration: underline;
    }
  `,
  information: css`
    margin-bottom: 24px;

    /* stylelint-disable no-descending-specificity */
    & > a {
      color: #27313b;
      font-size: 14px;
      font-weight: 600;

      &:hover {
        color: #3892e5;
        text-decoration: underline;
      }
    }
  `,
  tipsContainer: css`
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px #dee5ec, 0 1px 4px rgba(222, 229, 236, 0.5);
    width: 100%;
  `,
};

export default Tips;
