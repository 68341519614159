import { css } from '@emotion/core';
import { NavLink } from 'react-router-dom';
import { Button } from '@src/components/atoms/Button';
import { usePaging } from '@src/libs/paging';
import { useQuery } from '@apollo/client';
import Dialog from '@src/components/molecules/Dialog';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import React from 'react';
import Menu from '@material-ui/core/Menu';
import importIcon from '@src/assets/icon/import.svg';
import trashIcon from '@src/assets/icon/trashIcon.svg';
import MenuItem from '@material-ui/core/MenuItem';
import FormRespondent from './FormRespondent';
import FormSummary from './FormSummary';
import FormIndividual from './FormIndividual';
import GET_FORM_RESPONDENTS_COUNT from './GetFormRespondentsCount.graphql';
import { GetFormRespondentsCount, GetFormRespondentsCountVariables } from './__generated__/GetFormRespondentsCount';
import useStateHandler from './useStateHandler';

const FormReport = () => {
  const currentPage = usePaging();
  const { t, params, path, enqueueSnackbar } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const {
    downloadMenu,
    deleteConfirmation,
    openDownloadMenu,
    closeDownloadMenu,
    hideDeleteConfirmation,
    handleDeleteConfirm,
    handleClickDeleteAllResponses,
    handleClickDownloadResponses,
  } = useStateHandler();

  const { id } = params as { id: string };

  const { data } = useQuery<GetFormRespondentsCount, GetFormRespondentsCountVariables>(GET_FORM_RESPONDENTS_COUNT, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        id: Number(id),
      },
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const totalResponse = data?.getFormRespondentsCount?.total || 0;

  const renderContent = () => {
    switch (path) {
      case '/forms/:id/report/respondent':
        return <FormRespondent currentPage={currentPage} total={totalResponse} />;
      case '/forms/:id/report/individual/:page':
      case '/forms/:id/report/individual':
        return <FormIndividual totalResponse={totalResponse} />;
      default:
        return <FormSummary />;
    }
  };

  return (
    <>
      <div css={styles.container}>
        <div css={styles.headingRow}>
          <p css={styles.responseCount}>{t('response', { count: totalResponse })}</p>
          <Button
            bgcolor="#fff"
            color="#6e7c89"
            hoverbgcolor="#F6F8FA"
            icon="more_vert"
            css={styles.button}
            onClick={openDownloadMenu}
          />
        </div>
        <div css={styles.navWrapper}>
          <NavLink css={styles.navlink} activeClassName="selected" to={`/forms/${id}/report`} exact={true}>
            {t('Summary')}
          </NavLink>
          {!isMobileView ? (
            <>
              <NavLink
                css={styles.navlink}
                activeClassName="selected"
                to={`/forms/${id}/report/respondent`}
                exact={true}
              >
                {t('Respondent')}
              </NavLink>
              <NavLink css={styles.navlink} activeClassName="selected" to={`/forms/${id}/report/individual`}>
                {t('Individual')}
              </NavLink>
            </>
          ) : null}
        </div>
        {renderContent()}
      </div>
      <Menu anchorEl={downloadMenu} open={Boolean(downloadMenu)} onClose={closeDownloadMenu}>
        <MenuItem onClick={handleClickDownloadResponses}>
          <img src={importIcon} css={styles.icon} />
          {t('Download responses (.csv)')}
        </MenuItem>
        <MenuItem onClick={handleClickDeleteAllResponses}>
          <img src={trashIcon} css={styles.icon} />
          {t('Delete all responses')}
        </MenuItem>
      </Menu>
      <Dialog
        visible={deleteConfirmation}
        onClose={hideDeleteConfirmation}
        onExec={handleDeleteConfirm}
        execText="Delete"
      >
        <p css={styles.heading}>{t('Are you sure you want to permanently delete this?')}</p>
        <p css={styles.bodyText}>
          {t('You might lost response data forever. Before continuing, export your responses')}
        </p>
        <p css={styles.warning}>{t('Do you want to delete this question and lost responses?')}</p>
      </Dialog>
    </>
  );
};

const styles = {
  container: css`
    background-color: #fff;
    padding: 24px;
  `,
  headingRow: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  `,
  button: css`
    width: 32px;
    height: 32px;
    border: 1px solid #dee5ec;
    border-radius: 3px;
    line-height: 30px;
    margin-left: 8px;
    font-weight: 600;

    i {
      margin-right: 0;
    }
  `,
  responseCount: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #27313b;
  `,
  navWrapper: css`
    display: flex;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 0 #dee5ec;
    margin-bottom: 24px;
  `,
  navlink: css`
    min-width: 70px;
    color: #27313b;
    padding: 0 8px 14px;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    margin-right: 16px;

    &.selected {
      border-bottom: 4px solid #3892e5;
      font-weight: 600;
      font-size: 14px;
    }
  `,
  icon: css`
    margin-right: 8px;
    width: 22px;
  `,
  heading: css`
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 18px;
    color: #27313b;
  `,
  bodyText: css`
    font-size: 14px;
    color: #27313b;
    margin-bottom: 16px;
  `,
  warning: css`
    font-size: 14px;
    color: #ff5f5f;
  `,
};

export default FormReport;
