import styled from '@emotion/styled';
import { css } from '@emotion/core';
import React from 'react';
import { DayPickerRange } from '@src/components/molecules/DayPicker';
import { Period } from '@src/components/molecules/DayPicker/types';
import { SingleSelector } from '@src/components/molecules/Selector';
import { switchImage } from '@src/libs/SocialMedia';
import { ViewportType, ViewportBreakpoint } from '@src/libs/theme';
import { defaultInfluencerAvatar } from '@src/libs/image';
import { useMediaQuery } from 'react-responsive';
import { SocialMediaAccountType } from '../types';

export interface StatsOverviewProps {
  availableSocialMediaAccounts: SocialMediaAccountType[];
  selectedSocialMedia: SocialMediaAccountType;
  onChangeSocialMedia: (social: SocialMediaAccountType) => void;
  withPeriod?: boolean;
  initialPeriod: Period;
  onPeriodChange: (value: Period) => void;
}

const TopMenuBar = (props: StatsOverviewProps) => {
  const hasCalendarUi = useMediaQuery({ minWidth: ViewportBreakpoint.MEDIUM });

  const {
    availableSocialMediaAccounts,
    selectedSocialMedia,
    onChangeSocialMedia,
    onPeriodChange,
    withPeriod,
    initialPeriod,
  } = props;
  const socialAccountsDropdown = availableSocialMediaAccounts.map(social => ({
    label: social.name,
    value: social.id.toString(),
    icon: (
      <div css={{ display: 'flex' }}>
        <img src={defaultInfluencerAvatar(social.avatar || null)} css={styles.avatar} alt="" className="avatarImg" />
        <SocialIcon src={switchImage(social.socialAccountType, true)} alt="" className="avatarSmIcon" />
      </div>
    ),
  }));

  const handleOnChangeSocialMedia = (id: string) =>
    onChangeSocialMedia(
      availableSocialMediaAccounts.find(social => social.id.toString() === id) || selectedSocialMedia
    );

  return (
    <>
      <Wrapper>
        <SingleSelectorWrapper
          menuCss={styles.menuCss(hasCalendarUi)}
          value={selectedSocialMedia.id.toString()}
          name="accounts selector"
          options={socialAccountsDropdown}
          onChange={handleOnChangeSocialMedia}
          hideInput={true}
          hideDeselectOption={true}
          isRoundInput={false}
          hasCalendarUi={hasCalendarUi}
          disabled={socialAccountsDropdown.length <= 1}
        />
        {withPeriod && hasCalendarUi ? (
          <DayPickerRange
            period={initialPeriod}
            handleChangePeriod={onPeriodChange}
            disabledRange={{ after: new Date() }}
            css={styles.dayPicker}
          />
        ) : null}
      </Wrapper>
    </>
  );
};

const styles = {
  dayPicker: css`
    width: 275px;
    margin-right: 24px;
  `,
  menuCss: (hasCalendarUi: boolean) => css`
    width: ${!hasCalendarUi ? '100%' : '420px'};
    border-radius: 0;
    border: 1px solid #dee5ec;
    border-left: 0;
    border-right: 0;

    .avatarSmIcon {
      display: none;
    }
  `,
  avatar: css`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 10px;
  `,
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  background: #fff;
  box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
`;

const SingleSelectorWrapper = styled(SingleSelector)<{ disabled: boolean; hasCalendarUi: boolean }>`
  background-color: #fff;
  height: 64px;
  width: ${props => (!props.hasCalendarUi ? '100%' : '420px')};
  border-radius: 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #dee5ec;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
  ${({ disabled }) => (disabled ? 'cursor: default;' : '')}

  & .selected_values {
    height: 48px;
    padding-left: 24px;
    font-weight: 600;
    font-size: 18px;

    .avatarImg {
      width: 48px;
      height: 48px;
    }
  }

  @media (max-width: ${ViewportType.SMALL}px) {
    border-right: none;
  }
`;

const SocialIcon = styled.img`
  width: 24px;
  display: inline-block;
  margin-right: 8px;
`;

export default TopMenuBar;
