import { useQuery } from '@apollo/client';
import React from 'react';
import { css } from '@emotion/react';
import { CircularProgress } from '@material-ui/core';
import { useQueryHelper } from '@src/libs/hooks';
import AnalyticsContentWrapper, {
  ContentType,
} from '@src/components/organisms/AnalyticsShared/AnalyticsContentWrapper';
import { Period } from '@src/components/molecules/DayPicker/types';
import Overview from './InteractionOverview';
import * as IG_INTERACTION_OVERVIEW from './InstagramInteractionOverview.graphql';
import {
  InfluencerInstagramInteractionOverview,
  InfluencerInstagramInteractionOverviewVariables,
} from './__generated__/InfluencerInstagramInteractionOverview';

interface InteractionOverviewProps {
  period: Period;
  id: number;
}
const InteractionOverview = ({ period, id }: InteractionOverviewProps) => {
  const { history, enqueueSnackbar, t, search } = useQueryHelper();
  const { data, loading } = useQuery<
    InfluencerInstagramInteractionOverview,
    InfluencerInstagramInteractionOverviewVariables
  >(IG_INTERACTION_OVERVIEW, {
    variables: { socialAccountId: id, startDate: period.startDate, endDate: period.endDate },
    fetchPolicy: 'network-only',
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  if (loading) {
    return (
      <div css={styles.loaderWrapper}>
        <CircularProgress size={64} thickness={2.4} css={styles.progress} />
      </div>
    );
  } else if (!data?.influencerInstagramInteractionOverview) {
    history.push(`/analytics${search}`);

    return null;
  }

  return (
    <AnalyticsContentWrapper contentType={ContentType.Overview}>
      <Overview influencerInstagramInteractionOverview={data.influencerInstagramInteractionOverview} />
    </AnalyticsContentWrapper>
  );
};

const styles = {
  loaderWrapper: css`
    background-color: #fff;
    text-align: center;
    height: 554px;
  `,
  progress: css`
    margin: 240px auto;
    color: #dee5ec;
  `,
};

export default InteractionOverview;
