import { InstagramInteractionUserSortField } from '@src/__generated__/globalTypes';
import { SliderCss } from '@src/components/molecules/SliderTable';
import { css, SerializedStyles } from '@emotion/react';
import { InfluencerInstagramInteractionPosts_influencerAllInstagramInteractionUsers_usernames } from './__generated__/InfluencerInstagramInteractionPosts';

export enum UserAnalysisSortFiled {
  POST = 'POST',
  LIKE = 'LIKE',
  COMMENT = 'COMMENT',
  TAGGED_POST = 'TAGGED_POST',
  MENTIONED_POST = 'MENTIONED_POST',
  TAGGED_LIKE = 'TAGGED_LIKE',
  MENTIONED_LIKE = 'MENTIONED_LIKE',
  TAGGED_COMMENT = 'TAGGED_COMMENT',
  MENTIONED_COMMENT = 'MENTIONED_COMMENT',
}

export const getUserAnalysisSortFiledType = (field?: string | null) => {
  if (!field) {
    return null;
  }

  switch (field) {
    case 'POST':
      return InstagramInteractionUserSortField.POST;
    case 'LIKE':
      return InstagramInteractionUserSortField.LIKE;
    case 'COMMENT':
      return InstagramInteractionUserSortField.COMMENT;
    case 'TAGGED_POST':
      return InstagramInteractionUserSortField.TAGGED_POST;
    case 'MENTIONED_POST':
      return InstagramInteractionUserSortField.MENTIONED_POST;
    case 'TAGGED_LIKE':
      return InstagramInteractionUserSortField.TAGGED_LIKE;
    case 'MENTIONED_LIKE':
      return InstagramInteractionUserSortField.MENTIONED_LIKE;
    case 'TAGGED_COMMENT':
      return InstagramInteractionUserSortField.TAGGED_COMMENT;
    case 'MENTIONED_COMMENT':
      return InstagramInteractionUserSortField.MENTIONED_COMMENT;

    default:
      return null;
  }
};

interface UserColumnsType {
  id: number;
  title: string;
  sort: InstagramInteractionUserSortField;
  customCss: SerializedStyles | SerializedStyles[];
  help: string;
  key: keyof InfluencerInstagramInteractionPosts_influencerAllInstagramInteractionUsers_usernames;
}
export const getUserAnalysisColumns = (): UserColumnsType[] => [
  {
    id: 1,
    title: 'Post',
    sort: InstagramInteractionUserSortField.POST,
    customCss: flexStart,
    help: 'interactionPost',
    key: 'totalPostsCount',
  },
  {
    id: 2,
    title: 'Tagged Post',
    sort: InstagramInteractionUserSortField.TAGGED_POST,
    customCss: SliderCss.twoLineHeader,
    help: 'interactionTaggedPost',
    key: 'taggedPosts',
  },
  {
    id: 3,
    title: 'Mentioned Post',
    sort: InstagramInteractionUserSortField.MENTIONED_POST,
    customCss: SliderCss.twoLineHeader,
    help: 'interactionMentionedPost',
    key: 'mentionedPosts',
  },
  {
    id: 4,
    title: 'Avg Likes',
    sort: InstagramInteractionUserSortField.LIKE,
    customCss: flexStart,
    help: 'interactionLike',
    key: 'avgTotalLikes',
  },
  {
    id: 5,
    title: 'Avg Tagged Likes',
    sort: InstagramInteractionUserSortField.TAGGED_LIKE,
    customCss: [
      SliderCss.twoLineHeader,
      css`
        min-width: 150px;
      `,
    ],
    help: 'interactionTaggedLike',
    key: 'avgTaggedLikes',
  },
  {
    id: 6,
    title: 'Avg Mentioned Likes',
    sort: InstagramInteractionUserSortField.MENTIONED_LIKE,
    customCss: [
      SliderCss.twoLineHeader,
      css`
        min-width: 135px;
      `,
    ],
    help: 'interactionMentionedLike',
    key: 'avgMentionedLikes',
  },
  {
    id: 7,
    title: 'Avg Comments',
    sort: InstagramInteractionUserSortField.COMMENT,
    customCss: flexStart,
    help: 'interactionComment',
    key: 'avgTotalComments',
  },
  {
    id: 8,
    title: 'Avg Tagged Comments',
    sort: InstagramInteractionUserSortField.TAGGED_COMMENT,
    customCss: [
      SliderCss.twoLineHeader,
      css`
        min-width: 120px;
      `,
    ],
    help: 'interactionTaggedComment',
    key: 'avgTaggedComments',
  },
  {
    id: 9,
    title: 'Avg Mentioned Comments',
    sort: InstagramInteractionUserSortField.MENTIONED_COMMENT,
    customCss: [
      SliderCss.twoLineHeader,
      css`
        min-width: 140px;
      `,
    ],
    help: 'interactionMentionedComment',
    key: 'avgMentionedComments',
  },
];

const flexStart = css`
  & > div {
    justify-content: flex-start;
  }
`;
