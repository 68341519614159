import React, { useState, SyntheticEvent } from 'react';
import * as StyledCards from '@src/components/organisms/AnalyticsShared/AccountCardsStyled';
import { defaultInfluencerAvatar } from '@src/libs/image';
import offlineIconWhite from '@src/assets/icon/offlineWhite.svg';
import { ComparableYtAccountType } from '../types';

interface DesktopTemplateProps {
  account: ComparableYtAccountType;
  handleClickDelete: (e: SyntheticEvent<HTMLButtonElement>, id: string) => void;
  toggleShowHide: (anchorId: number | null, elementId?: number | null) => void;
}
const DesktopTemplate = ({ account, toggleShowHide, handleClickDelete }: DesktopTemplateProps) => {
  const isHidden = !account.isSelected;
  const [hoverId, setHoverId] = useState<number>();
  const hasDeleteAccount = account.isDeletable;
  const showEye = !isHidden && hoverId === account.accountId;

  return (
    <StyledCards.Card
      clickable
      key={`${account.elementId}_${account.accountId}`}
      onMouseEnter={() => setHoverId(account.accountId)}
      onMouseLeave={() => setHoverId(undefined)}
      onClick={() => {
        toggleShowHide(account.accountId, account.elementId);
      }}
    >
      {hasDeleteAccount && (
        <StyledCards.VerticalDotsButton
          onClick={e => handleClickDelete(e, account.uniqId)}
          css={
            isHidden
              ? {
                  color: '#c5d0da',
                }
              : ''
          }
        >
          {'\u00D7'}
        </StyledCards.VerticalDotsButton>
      )}
      <StyledCards.AvatarWrapper>
        <StyledCards.Avatar
          src={defaultInfluencerAvatar(account.avatarUrl || null)}
          css={isHidden ? { opacity: 0.5 } : ''}
        />
        {(isHidden || showEye) && <StyledCards.OverflowImage src={offlineIconWhite} />}
      </StyledCards.AvatarWrapper>

      <StyledCards.TitleWithLink
        onMouseEnter={() => setHoverId(undefined)}
        onMouseLeave={() => setHoverId(account.accountId)}
        title={account.channelName}
        href={account.profileUrl}
        target="_blank"
        rel="noreferrer noopener"
      >
        {account.channelName}
      </StyledCards.TitleWithLink>
    </StyledCards.Card>
  );
};

export default DesktopTemplate;
