import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { ListIndicator } from '../../../molecules/Indicator';
import { AuthSocialAccountType } from '../../../../__generated__/globalTypes';
import { useQueryHelper } from '../../../../libs/hooks';
import { ConnectType } from '../../../../libs/auth';
import { UNEXPECTED_ERROR } from '../../../../libs/error';
import { PalleteButton } from '../../../atoms/Button';
import { linerGradientIG } from '../../../../libs/pallete';
import BaseProfileConnectForm from './ProfileConnectForm';
import { useConnect } from './useConnect';
import * as CHECK_CONNECTED_SOCIAL_ACCOUNTS from './CheckConnectedSocialAccountsForProfile.graphql';
import { CheckConnectedSocialAccountsForProfile } from './__generated__/CheckConnectedSocialAccountsForProfile';
import { defaultSocialAccounts, getAlreadyConnectedFanpagesIds } from './helpers';
import * as CONNECTABLE_FANPAGE_ACCOUNTS from './ConnectableFanpageAccounts.graphql';
import * as RATE_CARD from './GetInfluencerRateCardForInfluencer.graphql';
import { GetInfluencerRateCardForInfluencer } from './__generated__/GetInfluencerRateCardForInfluencer';
import { ConnectableFanPageAccountsProfile } from './__generated__/ConnectableFanPageAccountsProfile';
import { useConnectDialog, useFanpageDialog } from './useFanpageDialog';
import { useReconnectInstagram } from './useReconnectInstagram';
import { useLocationState } from './useLocationState';

const ProfileConnectForm = () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  /*
   Fetch connected accounts
   */
  const { data, error, loading: checkingAccount } = useQuery<CheckConnectedSocialAccountsForProfile>(
    CHECK_CONNECTED_SOCIAL_ACCOUNTS,
    {
      fetchPolicy: 'cache-and-network',
    }
  );
  const socialAccounts = data?.checkConnectedSocialAccountsV2 || defaultSocialAccounts;

  /*
   Fetch post price
   */
  const { data: priceData, error: priceError, loading: priceLoading } = useQuery<GetInfluencerRateCardForInfluencer>(
    RATE_CARD,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  const { data: connectableFanpages, loading: fanpageLoading } = useQuery<ConnectableFanPageAccountsProfile>(
    CONNECTABLE_FANPAGE_ACCOUNTS,
    {
      fetchPolicy: 'cache-and-network',
      // set already connected fanpages as default value
      onCompleted: (resultedFanpages: ConnectableFanPageAccountsProfile) => {
        setSelectedFanPageIds(getAlreadyConnectedFanpagesIds(resultedFanpages?.connectableFacebookPages?.pages));
      },
    }
  );

  if (error || priceError) {
    const errorObj = error || priceError;
    const errorMessage = errorObj?.message || UNEXPECTED_ERROR;
    console.error(errorObj);
    enqueueSnackbar(t(errorMessage), { variant: 'error' });
  }

  /*
   Authentication for connecting
   */
  const { onClick, loading: connecting } = useConnect(FE_REDIRECT_MAPPING.CONNECT);

  // Values which set before redirecting to SNS
  const { connectType, reconnectIgAccountId, resetLocationState } = useLocationState();

  /**
   * Connect Facebook account and pages
   */
  const fanPages = connectableFanpages?.connectableFacebookPages?.pages || [];
  const [selectedFanPageIds, setSelectedFanPageIds] = useState<string[]>([]);

  const handleConnectFBAccountAndFanpages = () => {
    try {
      // we need to reconnect FB in order to obtain latest fanPages
      localStorage.setItem('connectType', ConnectType.CONNECT_FB_PAGE);
      onClick(AuthSocialAccountType.FACEBOOK)();
    } catch (e) {
      console.error('error: ', e);
      enqueueSnackbar(t('failToObtainFanpages'), { variant: 'error' });
    }
  };

  /**
   * Connect Instagram Account
   */
  const handleConnectIGAccount = () => {
    localStorage.setItem('connectType', ConnectType.CONNECT_IG_ACCOUNT);
    onClick(AuthSocialAccountType.FACEBOOK)();
  };

  /**
   * Reconnect Instagram Account
   */
  const { startReconnectInstagram, handleReconnectInstagram, reconnecting } = useReconnectInstagram({
    onFinished: resetLocationState,
  });

  // When redirecting back from FB to this component for reconnect IG, this useEffect will start.
  useEffect(() => {
    if (connectType !== ConnectType.RECONNECT_IG_ACCOUNT) {
      // Do nothing.
      return;
    }

    handleReconnectInstagram(reconnectIgAccountId);
  }, [connectType, reconnectIgAccountId]);
  // Dialog for choosing Instagram Account
  const isIGConnectType = connectType === ConnectType.CONNECT_IG_ACCOUNT;
  const igAccountDialog = useConnectDialog(isIGConnectType);

  useEffect(() => {
    if (isIGConnectType) {
      igAccountDialog.open();
    }
  }, [isIGConnectType]);

  // Show Reconnect Button if the account is not a business account
  const instagramAccounts = socialAccounts.instagramAccounts.map(account => {
    const handleClickReconnect = () => startReconnectInstagram(account.id);

    const ReconnectButton = (
      <PalleteButton
        onClick={handleClickReconnect}
        text="Reconnect"
        palleteColor={linerGradientIG}
        height="31px"
        width="auto"
        fontSize="12px"
        isDisabled={reconnecting}
      />
    );

    return {
      ...account,
      rightElement: account.isBusiness ? null : ReconnectButton,
    };
  });

  // Dialog for choosing Facebook pages
  const connectStep = connectType === ConnectType.CONNECT_FB_PAGE ? 1 : 0;
  const fanPageDialog = useFanpageDialog(fanPages.length > 0 ? connectStep : 0);

  if (fanpageLoading || priceLoading || checkingAccount || connecting) {
    return <ListIndicator />;
  }

  return (
    <BaseProfileConnectForm
      facebook={socialAccounts.facebook}
      facebookFanpages={socialAccounts.facebookPages}
      instagramAccounts={instagramAccounts}
      twitterAccounts={socialAccounts.twitterAccounts}
      youtubeAccounts={socialAccounts.youtubeAccounts}
      tiktokAccounts={socialAccounts.tiktokAccounts}
      onClick={onClick}
      loading={checkingAccount || connecting}
      fanPages={fanPages}
      selectedFanPageIds={selectedFanPageIds}
      setSelectedFanPageIds={setSelectedFanPageIds}
      handleConnectFBAccountAndFanpages={handleConnectFBAccountAndFanpages}
      handleConnectIGAccount={handleConnectIGAccount}
      priceCards={priceData?.getInfluencerRateCardForInfluencer}
      fanPageDialog={fanPageDialog}
      igAccountDialog={igAccountDialog}
      connectType={connectType}
    />
  );
};

export default ProfileConnectForm;
