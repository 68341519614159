import React from 'react';
import { bigIntFormatter, intlNumberFormat } from '@src/libs/format';
import { useQueryHelper } from '@src/libs/hooks';
import * as Styled from '@src/components/organisms/AnalyticsShared/AverageEngagementStyled';
import DataOpacityWrapper, { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import { ViewportBreakpoint } from '@src/libs/theme';
import { useMediaQuery } from 'react-responsive';
import { getAverageEngagementIconAndTitle } from '@src/components/organisms/AnalyticsFacebookPage/AnalyticsPosts/AverageEngagement/helpers';
import * as StyledOverview from '@src/components/organisms/AnalyticsShared/Overview/StyledComponents';

interface AverageEngagementFacebookProps {
  averagePostsPerWeek: number | null;
  averageReactions: number | null;
  averageViews: number | null;
  averageComments: number | null;
  averageShares: number | null;
}
const AverageEngagementFacebook = (props: AverageEngagementFacebookProps) => {
  const { t } = useQueryHelper();
  const { averagePostsPerWeek, averageReactions, averageViews, averageComments, averageShares } = props;
  const isDirectionColumn = useMediaQuery({ maxWidth: ViewportBreakpoint.MOBILE });
  const { engagementStyles } = Styled;

  const dataNotAvailable =
    !averagePostsPerWeek && !averageReactions && !averageViews && !averageComments && !averageShares;

  const engagementValues = (!dataNotAvailable ? Object.keys(props) : []) as Array<keyof typeof props>;

  return (
    <DataOpacityWrapper
      header={<SectionHeader>{t('Average Engagement')}</SectionHeader>}
      dataNotAvailable={dataNotAvailable}
      css={engagementStyles.container}
    >
      <Styled.MainWrapper css={engagementStyles.gridContainer}>
        {engagementValues.map(engKey => {
          const avgTotal = props[engKey] || null;
          const total = bigIntFormatter(avgTotal, 0);
          const { title, icon } = getAverageEngagementIconAndTitle(engKey);
          const hoverTotal = intlNumberFormat(avgTotal);

          if (isDirectionColumn) {
            return (
              <StyledOverview.EngagementGridCell key={engKey}>
                <StyledOverview.PartWithIcon>
                  <StyledOverview.EngagementTitle text={t(title)} lines={1} />
                  <StyledOverview.EngagementTileIcon src={icon} css={engagementStyles.icon} />
                </StyledOverview.PartWithIcon>

                <StyledOverview.EngagementNumbers>
                  <StyledOverview.TotalNumber title={hoverTotal}>{total}</StyledOverview.TotalNumber>
                </StyledOverview.EngagementNumbers>
              </StyledOverview.EngagementGridCell>
            );
          }

          return (
            <Styled.EngagementDataRow key={engKey}>
              <Styled.EngagementHalf isDirectionColumn={isDirectionColumn}>
                <img src={icon} css={engagementStyles.icon} />
                <Styled.Label>{t(title)}</Styled.Label>
              </Styled.EngagementHalf>
              <Styled.EngagementHalf>
                <Styled.GrowthWrapper>
                  <Styled.Value title={hoverTotal}>{total}</Styled.Value>
                </Styled.GrowthWrapper>
              </Styled.EngagementHalf>
            </Styled.EngagementDataRow>
          );
        })}
      </Styled.MainWrapper>
    </DataOpacityWrapper>
  );
};

export default AverageEngagementFacebook;
