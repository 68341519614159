import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import * as RATE_CARD from '../../components/organisms/Profile/ProfileConnectForm/GetInfluencerRateCardForInfluencer.graphql';
import { GetInfluencerRateCardForInfluencer } from '../../components/organisms/Profile/ProfileConnectForm/__generated__/GetInfluencerRateCardForInfluencer';
import Notice from '../../components/molecules/Notice';
import { mainWhite } from '../../libs/pallete';
import { PalleteButton } from '../../components/atoms/Button';

const SetPriceNotice = () => {
  const { t } = useTranslation();
  const { data: priceData } = useQuery<GetInfluencerRateCardForInfluencer>(RATE_CARD, {
    fetchPolicy: 'cache-and-network',
  });

  // so if we can't set price, the root object is null
  const canSetPrice = priceData?.getInfluencerRateCardForInfluencer !== null;

  // if we can set price, everything can be null expect the root object.
  const hasNoPriceSet =
    !priceData?.getInfluencerRateCardForInfluencer?.facebook?.price ||
    !priceData?.getInfluencerRateCardForInfluencer?.youtube?.price ||
    !priceData?.getInfluencerRateCardForInfluencer?.instagram?.price ||
    !priceData?.getInfluencerRateCardForInfluencer?.instagramStory?.price ||
    !priceData?.getInfluencerRateCardForInfluencer?.tiktok?.price ||
    !priceData?.getInfluencerRateCardForInfluencer?.twitter?.price;

  return (
    <>
      {canSetPrice && hasNoPriceSet && (
        <Notice
          icon="info"
          title={t('DoYouWantMoreJobs')}
          content={
            <div>
              <Text>{t('PleaseSetAPrice')}</Text>
              <PalleteButton
                height={'32px'}
                text="GoToSetPrice"
                palleteColor={mainWhite}
                width="144px"
                href={'/settings/social_connect'}
              />
            </div>
          }
        />
      )}
    </>
  );
};

const Text = styled.p`
  margin-bottom: 4px;
`;
export default SetPriceNotice;
