import styled from '@emotion/styled';
import * as React from 'react';
import { css } from '@emotion/react';
import SignUpProfileForm, { SignUpProfileFormProps } from '@src/components/organisms/SignUpProfileForm';
import Footer from '@src/components/organisms/TopPage/Footer';
import TopPageHeader from '@src/components/organisms/TopPage/Header';
import { usePageLayout, useUpdateDocumentTitle } from '@src/libs/hooks';
import { SignUpAndSignInBreakpoint } from '@src/libs/theme';

export type TemplateProps = SignUpProfileFormProps;

const Template = (props: TemplateProps) => {
  const { isMobileViewPublicRoutes } = usePageLayout();

  const title = 'pageTitle.Profile';
  useUpdateDocumentTitle({ title });
  const { socialMedia, name, email, uuid } = props;

  return (
    <Container>
      <ContentWrapper>
        <div css={styles.header}>
          <TopPageHeader />
        </div>
        <Wrapper>
          <SignUpProfileForm socialMedia={socialMedia} name={name} email={email} uuid={uuid} />
        </Wrapper>
        {!isMobileViewPublicRoutes && <Footer css={styles.footer} />}
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  background-color: #f7f8fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    justify-content: flex-start;
  }
`;

const ContentWrapper = styled.div`
  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
  }
`;

const Wrapper = styled.section`
  box-shadow: 0 1px 4px 0 #dee5ec, 0 2px 5px 0 #dee5ec;
  display: flex;
  align-content: center;
  width: 856px;
  border-radius: 3px;
  background-color: #fff;
  padding: 32px 48px 32px;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    border-radius: 0;
    box-shadow: none;
    width: 100%;
    padding: 16px 24px;
  }
`;

const styles = {
  footer: css`
    width: 100%;
  `,
  header: css`
    @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
      background-color: #fff;
      display: flex;
      justify-content: center;

      & > header {
        width: 90%;
      }
    }
  `,
};

export default Template;
