import * as React from 'react';
import styled from '@emotion/styled';
import CarouselModal from '@src/components/molecules/CarouselModal/CarouselModal';
import DataOpacityWrapper, { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import PostCarousel from '@src/components/molecules/PostCarousel';
import { SocialMediaAccountType } from '@src/components/organisms/ProfileDetail/types';
import { useUuumInfluencerView, useQueryHelper } from '@src/libs/hooks';
import { usePostDetailModal } from '@src/components/organisms/AnalyticsShared/BasePostList/usePostDetailModal';
import * as StyledComponents from '@src/components/organisms/AnalyticsShared/PostDetail/SliderStyledComponents';
import { StoryPost } from '../types';
import AnayticsStoryPostDetail from './AnalyticsStoryPostDetail';

export interface StoryPostsProps {
  className?: string;
  posts: StoryPost[];
  selectedSocialMedia: SocialMediaAccountType;
}

const StoryPosts = (props: StoryPostsProps) => {
  const { className, posts, selectedSocialMedia } = props;
  const { t, search } = useQueryHelper();
  const { isUuumView } = useUuumInfluencerView();

  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev,
  } = usePostDetailModal(posts || []);

  const getParams = () => {
    const queryParams = new URLSearchParams(search);
    queryParams.set('id', String(selectedSocialMedia.id));
    queryParams.set('sm', selectedSocialMedia.socialAccountType);

    return queryParams.toString();
  };

  return (
    <>
      <DataOpacityWrapper
        className={className}
        header={<SectionHeader>{t('Title.Story Posts')}</SectionHeader>}
        dataNotAvailable={!posts || posts.length === 0}
        css={{ minHeight: '180px' }}
      >
        <PostCarousel posts={posts} onPostClick={(id: number) => handleClickPost(id)} type={'reach'} />
        {/* TODO: remove after full migration to AnyX */}
        <StyledComponents.MoreText
          to={
            isUuumView
              ? `/profile/instagram_story_feed/?${getParams()}`
              : `/analytics/instagram_story_feed/?${getParams()}`
          }
        />
      </DataOpacityWrapper>
      <CarouselModal
        open={!!openPostDetail}
        title=""
        cancel="OK"
        onClose={handleClosePostDetail}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        siblingPostId={siblingPostId}
      >
        <Container>
          <AnayticsStoryPostDetail closeModal={handleClosePostDetail} accountId={selectedSocialMedia.id} />
        </Container>
      </CarouselModal>
    </>
  );
};

const Container = styled.div`
  width: 100%;
`;

export default StoryPosts;
