import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SocialAccountType, CampaignSocialMediaType } from '@src/__generated__/globalTypes';
import { formatIntNumber, formatPercent } from '@src/libs/format';
import { switchImage } from '@src/libs/SocialMedia';
import { FacebookPageEngagementInfoProps } from '../types';
import * as Styled from '../StyledEngagement';

const InstagramEngagementInfo = (props: FacebookPageEngagementInfoProps) => {
  const { t } = useTranslation();
  const { postUrl, views, reactions, comments, shares, adImpressions, positiveRate, negativeRate } = props;

  return (
    <Styled.MainContainer>
      <Styled.Title>
        {t('Engagement')}
        <Styled.Anchor href={postUrl as string} target="_blank" rel="noreferrer">
          {`View on ${CampaignSocialMediaType.FACEBOOK}`}
        </Styled.Anchor>
      </Styled.Title>
      <Styled.EngagementContainer>
        <Styled.Engagement>
          <Styled.Icon src={switchImage(SocialAccountType.FACEBOOK, true)} alt="" />
          <Styled.CountWrapper>
            <Styled.Wrapper>
              <Styled.Value>{formatIntNumber(reactions)}</Styled.Value>
              <Styled.Unit text={t('Reactions')} lines={1} />
            </Styled.Wrapper>
            <Styled.Wrapper>
              <Styled.Value>{formatIntNumber(comments)}</Styled.Value>
              <Styled.Unit text={t('Comments')} lines={1} />
            </Styled.Wrapper>
            <Styled.Wrapper>
              <Styled.Value>{formatIntNumber(views)}</Styled.Value>
              <Styled.Unit text={t('View')} lines={1} />
            </Styled.Wrapper>
            <Styled.Wrapper>
              <Styled.Value>{formatIntNumber(shares)}</Styled.Value>
              <Styled.Unit text={t('Shares')} lines={1} />
            </Styled.Wrapper>
            <Styled.Wrapper>
              <Styled.Value>{formatIntNumber(adImpressions)}</Styled.Value>
              <Styled.Unit text={t('Imps')} lines={1} />
            </Styled.Wrapper>
            <Styled.Wrapper>
              <Styled.Value>{formatPercent(positiveRate, true, 2)}</Styled.Value>
              <Styled.Unit text={t('Positive Rate')} lines={1} />
            </Styled.Wrapper>
            <Styled.Wrapper>
              <Styled.Value>{formatPercent(negativeRate, true, 2)}</Styled.Value>
              <Styled.Unit text={t('Negative Rate')} lines={1} />
            </Styled.Wrapper>
          </Styled.CountWrapper>
        </Styled.Engagement>
      </Styled.EngagementContainer>
    </Styled.MainContainer>
  );
};

export default InstagramEngagementInfo;
