import React from 'react';
import { useQueryHelper } from '@src/libs/hooks';

import Template from './template';
import CompareTemplate from './compareTemplate';

interface AnayticsFeedPostDetailProps {
  postId?: number | null;
  accountId: number;
  avatarUrl?: string;
  accountName?: string;
  compareAccountId?: number;
  isMain?: boolean;
}

const AnalyticsPostDetail = (props: AnayticsFeedPostDetailProps) => {
  const { accountId, postId, accountName, avatarUrl, isMain = true, compareAccountId } = props;
  const { search } = useQueryHelper();

  const selectPostId = postId || Number(new URLSearchParams(search).get('popup'));

  return isMain ? (
    <Template
      postId={selectPostId}
      socialAccountId={Number(accountId)}
      avatarUrl={avatarUrl}
      accountName={accountName}
    />
  ) : compareAccountId ? (
    <CompareTemplate
      postId={selectPostId}
      socialAccountId={Number(accountId)}
      avatarUrl={avatarUrl}
      accountName={accountName}
      compareAccountId={compareAccountId}
    />
  ) : null;
};

export default AnalyticsPostDetail;
