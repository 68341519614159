import { useQuery } from '@apollo/client';
import * as INFLUENCER_PROFILE from './InfluencerProfile.graphql';
import { InfluencerProfile, InfluencerProfileVariables } from './__generated__/InfluencerProfile';

interface InfluencerProfileQuery {
  influencerId: number;
}
export const useInfluencerProfileQuery = ({ influencerId }: InfluencerProfileQuery) => {
  const { data, error, loading } = useQuery<InfluencerProfile, InfluencerProfileVariables>(INFLUENCER_PROFILE, {
    variables: { pk: influencerId },
  });

  return {
    loading,
    error: error?.message || '',
    profileInfo: data?.influencerProfileV2 || null,
  };
};
