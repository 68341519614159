import React from 'react';
import { useQueryHelper, useUpdateDocumentTitle } from '@src/libs/hooks';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { Formik } from 'formik';
import { AddressInformationSchema } from '@src/libs/validation';
import { FAILED_TO_UPDATE, UNEXPECTED_ERROR } from '@src/libs/error';
import { useAddressInformation } from './useAddressInformation';
import AddressInformationForm from './AddressInformationForm';
import { FormValues } from './types';

const AddressInformationFormComponent = () => {
  const title = 'pageTitle.Edit Address';
  const pageTitle = 'pageTitle.Edit Address';
  const href = '/profile/settings';
  useUpdateDocumentTitle({ title, pageTitle, href });

  const { t, enqueueSnackbar } = useQueryHelper();
  const { addressInformation, loading, updateAddress } = useAddressInformation();

  if (loading) {
    return <ListIndicator />;
  }

  const handleSubmit = async (values: FormValues) => {
    try {
      const result = await updateAddress({ variables: { input: values } });

      if (result.data?.updateAddress?.ok) {
        enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
      } else {
        enqueueSnackbar(t(FAILED_TO_UPDATE), { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <Formik<FormValues>
      initialValues={addressInformation}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={AddressInformationSchema}
    >
      <AddressInformationForm />
    </Formik>
  );
};

export default AddressInformationFormComponent;
