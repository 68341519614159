import { getToken } from '../auth';

export const getAuthHeaders = () => {
  const token = getToken();

  const authHeaders = new Headers();
  authHeaders.append('Authorization', `${token}`);

  return authHeaders;
};
