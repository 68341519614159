import React from 'react';
import { useMutation } from '@apollo/client';
import { Formik, FormikHelpers } from 'formik';
import { ResetPasswordSchema } from '../../../libs/validation';
import { useQueryHelper } from '../../../libs/hooks';
import { UNEXPECTED_ERROR } from '../../../libs/error';
import {
  ResendInfluencerForgotPasswordEmailVariables,
  ResendInfluencerForgotPasswordEmail,
} from './__generated__/ResendInfluencerForgotPasswordEmail';
import * as RESEND_INFLUENCER_SIGN_UP_EMAIL from './ResendInfluencerSignUpEmail.graphql';
import ResetPasswordForm from './ResetPasswordForm';
import { FormValues, SubmissionStatus } from './types';

const ResetPasswordFormComponent = () => {
  const { t, enqueueSnackbar } = useQueryHelper();

  const [doMutation] = useMutation<ResendInfluencerForgotPasswordEmail, ResendInfluencerForgotPasswordEmailVariables>(
    RESEND_INFLUENCER_SIGN_UP_EMAIL
  );

  const handleSubmit = async (payload: FormValues, { setSubmitting, setStatus }: FormikHelpers<FormValues>) => {
    setSubmitting(true);
    try {
      await doMutation({ variables: { input: { email: payload.email } } });
      setStatus(SubmissionStatus.OK);
      enqueueSnackbar(t('succeededInRequest'), { variant: 'success' });
    } catch (error) {
      setStatus(t(UNEXPECTED_ERROR));
      enqueueSnackbar('Failed to resend email, please try again', { variant: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={ResetPasswordSchema}
    >
      <ResetPasswordForm />
    </Formik>
  );
};

export default ResetPasswordFormComponent;
