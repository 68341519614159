import commentIcon from '@src/assets/icon/comment.svg';
import likesIcon from '@src/assets/icon/thumbs-up.svg';
import dislikeIcon from '@src/assets/icon/thumbs-down.svg';
import viewsIcon from '@src/assets/icon/views.svg';
import postWeekIcon from '@src/assets/icon/youtubeVideo.svg';
import { YoutubeAnalyticsStats } from '@src/components/organisms/AnalyticsYoutube/types';

export const getYtAverageEngagementIconAndTitle = (engKey: keyof YoutubeAnalyticsStats) => {
  switch (engKey) {
    case 'averageComments':
      return {
        title: 'Avg Comments',
        icon: commentIcon,
      };
    case 'averageDislikes':
      return {
        title: 'Avg Dislike',
        icon: dislikeIcon,
      };
    case 'averagePostsPerWeek':
      return {
        title: 'Avg posts/week',
        icon: postWeekIcon,
      };
    case 'averageLikes':
      return {
        title: 'Avg Likes',
        icon: likesIcon,
      };
    case 'averageViews':
      return {
        title: 'Avg Views',
        icon: viewsIcon,
      };

    default:
      return {
        title: '',
        icon: '',
      };
  }
};
