import React, { MouseEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import anyCreatorBlack from '@src/assets/icon/anyCreatorBlack.svg';
import connectSmAccount from '@src/assets/icon/menu/connectSmAccount.svg';
import { Icon } from '@src/components/atoms/Icon';
import { useAuthData, useQueryHelper } from '@src/libs/hooks';
import useInfluencerProfile from '@src/pages/Analytics/useInfluencerProfile';
import { InfluencerType, UserRoles } from '@src/__generated__/globalTypes';
import { ViewportType } from '@src/libs/theme';
import {
  checkIsActivePathname,
  menuItems,
  menuWidth,
  BadgeType,
  DynamicToType,
  HiddenType,
  SmDisconnectStatusType,
  Submenus,
} from './helpers';
import useMenuQuery from './useMenuQuery';

interface MenuProps {
  isDesktopView?: boolean;
}

const Menu = ({ isDesktopView }: MenuProps) => {
  const [expandedMenus, setExpandedMenus] = useState<string[]>([]);
  const { creatorType, role } = useAuthData();
  const { history, i18n, pathname, t } = useQueryHelper();
  const { hasFBAccount, hasIGAccount, hasTWAccount, hasYTAccount, hasTKAccount } = useInfluencerProfile();
  const { notificationCount } = useMenuQuery();
  const isJPLanguage = i18n.language === 'ja';
  const isProInfluencer = creatorType === InfluencerType.PRO;

  const badgesCount = {
    [BadgeType.NOTIFICATION]: notificationCount,
  };

  const menusTo = {
    [DynamicToType.HELP]: isProInfluencer
      ? isJPLanguage
        ? 'https://anymind-group.gitbook.io/anycreator-help/'
        : 'https://anymind-group.gitbook.io/anycreator-help-page-english/'
      : isJPLanguage
      ? 'https://anymind-group.gitbook.io/anycreatorhelp/'
      : 'https://anymind-group.gitbook.io/anycreator-help-page/',
  } as Record<DynamicToType, string>;

  const isHidden = {
    [HiddenType.CRM]: creatorType === InfluencerType.NORMAL,
  } as Record<HiddenType, boolean>;

  const smDisconnectedAccounts = {
    [SmDisconnectStatusType.FACEBOOK]: !hasFBAccount,
    [SmDisconnectStatusType.INSTAGRAM]: !hasIGAccount,
    [SmDisconnectStatusType.TWITTER]: !hasTWAccount,
    [SmDisconnectStatusType.YOUTUBE]: !hasYTAccount,
    [SmDisconnectStatusType.TIKTOK]: !hasTKAccount,
  } as Record<SmDisconnectStatusType, boolean>;

  useEffect(() => {
    const activeMenu = menuItems
      .filter(menu => menu.submenus)
      .find(menu => {
        const { submenus } = menu;

        return (
          submenus &&
          submenus.some(submenu => {
            const { to } = submenu;

            return typeof to === 'string' ? checkIsActivePathname(to) : to.some(path => checkIsActivePathname(path));
          })
        );
      });

    if (activeMenu) {
      setExpandedMenus([activeMenu.title || '']);
    }
  }, []);

  function onClickMenuEvent(e: MouseEvent<HTMLAnchorElement>, func: () => void) {
    e.preventDefault();
    e.stopPropagation();
    func();
  }

  function onClickOpenSubmenus(
    e: MouseEvent<HTMLAnchorElement>,
    title: string,
    submenus: Submenus[],
    isActive: boolean
  ) {
    e.preventDefault();
    e.stopPropagation();

    const menus = [...expandedMenus];
    const index = menus.findIndex(menuTitle => menuTitle === title);
    if (index >= 0) {
      menus.splice(index, 1);
    } else {
      const submenuTo = submenus[0].to;
      const redirect = typeof submenuTo === 'string' ? submenuTo : submenuTo[0];
      if (!isActive) {
        history.push(redirect);
      }
      menus.push(title);
    }
    setExpandedMenus(menus);
  }

  return (
    <div css={styles.menu}>
      {isDesktopView && (
        <Link css={styles.menuLogoContainer} to="/home">
          <img alt="anyCreatorLogo" height="32" src={anyCreatorBlack} width="150" />
        </Link>
      )}
      {menuItems
        .filter(menu => {
          const { hiddenType } = menu;

          return !(hiddenType && isHidden[hiddenType]);
        })
        .map((menu, index) => {
          const {
            activeIcon,
            badge,
            dynamicTo,
            groupTitle,
            icon,
            isDivider,
            isNewTab,
            rolesToAccess,
            submenus,
            title,
            to,
            onClick,
          } = menu;
          const badgeCount = badge ? badgesCount[badge] : 0;
          const isAccessible = rolesToAccess ? rolesToAccess.includes(role as UserRoles) : true;
          const redirect = to || (dynamicTo && menusTo[dynamicTo]);
          const isActive = !!redirect && `/${pathname.split('/')[1]}`.includes(redirect.split('?')[0]);
          const isMenuExpanded = !!title && expandedMenus.includes(title);

          const menuTo = submenus ? submenus[0].to : redirect;
          const menuRedirect = typeof menuTo === 'string' ? menuTo : menuTo ? menuTo[0] : '';

          return groupTitle ? (
            <div css={styles.menuGroupTitle} key={index}>
              {t(`Title.${groupTitle}`)}
            </div>
          ) : isAccessible ? (
            <div key={index}>
              <MenuItem
                isActive={isActive}
                target={isNewTab ? '_blank' : ''}
                to={isNewTab ? { pathname: menuRedirect } : menuRedirect}
                onClick={e =>
                  submenus
                    ? onClickOpenSubmenus(e, title || '', submenus, isActive)
                    : onClick
                    ? onClickMenuEvent(e, onClick)
                    : null
                }
              >
                <img alt="menuIcon" height="24" src={isActive ? activeIcon : icon} width="24" />
                <span>{t(`MenuTitle.${title}`)}</span>
                {!!badgeCount && (
                  <div>
                    <div css={styles.badge}>{badgeCount}</div>
                  </div>
                )}
                {submenus && <Icon color="#6e7C89" icon={isMenuExpanded ? 'expand_less' : 'expand_more'} />}
              </MenuItem>
              {submenus && (
                <SubMenus isVisible={isMenuExpanded}>
                  {submenus.map((submenu, subIndex) => {
                    const hasConnectIcon = submenu.disconnectStatusType
                      ? smDisconnectedAccounts[submenu.disconnectStatusType]
                      : null;
                    const {
                      activeIcon: submenuActiveIcon,
                      icon: submenuIcon,
                      title: submenuTitle,
                      to: submenuTo,
                    } = submenu;
                    const isSubmenuActive =
                      typeof submenuTo === 'string'
                        ? checkIsActivePathname(submenuTo)
                        : submenuTo.some(path => checkIsActivePathname(path));
                    // submenu may contain multiple valid pathname
                    const submenuRedirect = typeof submenuTo === 'string' ? submenuTo : submenuTo[0];

                    return (
                      <SubMenuItem isActive={isSubmenuActive} key={subIndex} to={submenuRedirect}>
                        <img
                          alt="menuIcon"
                          height="24"
                          src={isSubmenuActive ? submenuActiveIcon : submenuIcon}
                          width="24"
                        />
                        <span>{t(`MenuTitle.${submenuTitle}`)}</span>
                        {hasConnectIcon === true ? (
                          <img
                            src={connectSmAccount}
                            alt="connect"
                            width="16"
                            height="16"
                            css={{ marginLeft: 'auto' }}
                          />
                        ) : null}
                      </SubMenuItem>
                    );
                  })}
                </SubMenus>
              )}
              {isDivider && <div css={styles.menuDivider} />}
            </div>
          ) : null;
        })}
    </div>
  );
};

const MenuItem = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{ isActive: boolean }>`
  align-items: center;
  background-color: ${({ isActive }) => (isActive ? '#f6f8fa' : '#fff')};
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  filter: brightness(1);
  height: 40px;
  margin: 0 8px;
  padding: 0 12px;

  @media (max-width: ${ViewportType.TABLET}px) {
    margin: 4px 8px;
  }

  & > img {
    margin-right: 8px;
  }

  & > span {
    color: ${({ isActive }) => (isActive ? '#3892e5' : '#27313b')};
    font-size: 14px;
    font-weight: 600;
  }

  & > div,
  i {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }

  &:hover {
    filter: brightness(0.9);
  }
`;

const SubMenuItem = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{ isActive: boolean }>`
  align-items: center;
  background-color: ${({ isActive }) => (isActive ? '#f6f8fa' : '#fff')};
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  filter: brightness(1);
  height: 40px;
  margin: 0 8px;
  padding: 0 36px;

  @media (max-width: ${ViewportType.TABLET}px) {
    margin: 4px 8px;
  }

  & > img {
    margin-right: 8px;
  }

  & > span {
    color: ${({ isActive }) => (isActive ? '#3892e5' : '#27313b')};
    font-size: 14px;
  }

  &:hover {
    filter: brightness(0.9);
  }
`;

const SubMenus = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;

const styles = {
  badge: css`
    align-items: center;
    background-color: #ff5f5f;
    border-radius: 50%;
    color: #fff;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    height: 24px;
    justify-content: center;
    width: 24px;
  `,
  menu: css`
    background-color: #fff;
    border-right: 1px solid #e7edf3;
    height: 100%;
    overflow-y: auto;
    padding: 16px 0;
    position: fixed;
    width: ${menuWidth}px;
    z-index: 1;
  `,
  menuDivider: css`
    border-top: 1px solid #dee5ec;
    margin: 8px;
  `,
  menuGroupTitle: css`
    color: #6e7c89;
    font-size: 12px;
    font-weight: 600;
    padding: 12px 16px;
  `,
  menuLogoContainer: css`
    padding: 0 16px 12px 20px;
  `,
};

export default Menu;
