import { ApolloError } from '@apollo/client';
import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import InitialLoading from '../../../components/molecules/InitialLoading';
import { useQueryHelper } from '../../../libs/hooks';
import { getCallbackUrl, removeToken, setToken } from '../../../libs/auth';
import { getMessagesFromFetchResult, UNEXPECTED_ERROR } from '../../../libs/error';
import { useAuthProviderResponse } from '../hooks';
import { useAuthSetup } from '../../../components/organisms/AuthSetUp/useAuthSetup';
import { SocialAuthSignIn, SocialAuthSignInVariables } from './__generated__/SocialAuthSignIn';
import * as SOCIAL_AUTH_SIGN_IN from './SocialAuthSignIn.graphql';

const SignInRedirect = () => {
  const [socialAuthSignIn] = useMutation<SocialAuthSignIn, SocialAuthSignInVariables>(SOCIAL_AUTH_SIGN_IN);
  const { setUp } = useAuthSetup();

  const { t, enqueueSnackbar, history } = useQueryHelper();

  const { provider, response } = useAuthProviderResponse('/signin');

  const signInRedirectCall = async () => {
    if (!provider) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      history.push('/');

      return;
    }

    const variables = {
      input: {
        provider,
        response,
        callbackUrl: getCallbackUrl(provider, FE_REDIRECT_MAPPING.SIGNIN),
      },
    };

    const { token, refreshToken, errors } = await socialAuthSignIn({ variables })
      .then(result => {
        if (result && result.data && result.data.socialAuthSignIn) {
          const payload = result.data.socialAuthSignIn;

          return {
            token: payload.token,
            refreshToken: payload.refreshToken,
            errors: [],
          };
        } else {
          return {
            token: null,
            refreshToken: null,
            errors: getMessagesFromFetchResult(result),
          };
        }
      })
      .catch((e: ApolloError) => ({
        token: null,
        refreshToken: null,
        // @ts-ignore, see issue -> https://github.com/apollographql/@apollo/client/issues/1496
        errors: [e?.graphQLErrors[0].message],
      }));

    // SocialAuthentication Failed
    if (!token || errors.length > 0) {
      errors.forEach(error => {
        console.error(error);
        enqueueSnackbar(t(error), { variant: 'error' });

        // User needs to sign up instead of sign in.
        if (error === "account doesn't exist") {
          history.push('/signup');

          return;
        }
      });

      return;
    }

    // Sign In with token
    setToken(token, refreshToken);

    try {
      await setUp(token);
      const redirectUrlBeforeSignIn = localStorage.getItem('redirectUrlBeforeSignIn');
      if (window.opener) {
        window.opener.postMessage({ redirectPath: redirectUrlBeforeSignIn || '/profile' }, '*');
        window.close();

        return;
      }
      history.push(redirectUrlBeforeSignIn || '/profile');
      localStorage.removeItem('redirectUrlBeforeSignIn');
    } catch (e) {
      removeToken();
      console.error(e);
      enqueueSnackbar(t(e.message), { variant: 'error' });
      if (window.opener) {
        window.opener.postMessage({ redirectPath: '/', errorMsg: e.message }, '*');
        window.close();

        return;
      }
      history.push('/');
    }

    return;
  };

  useEffect(() => {
    signInRedirectCall();
  }, []);

  return <InitialLoading />;
};

export default SignInRedirect;
