import React, { ReactElement, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useMediaQuery } from '@material-ui/core';
import { useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import IntercomInitializer from '../Intercom';
import Footer from '../Footer';
import Menu from './Menu';
import { menuWidth } from './helpers';
import MobileMenu from './MobileMenu';
import MobileToolbar from './MobileToolbar';
import { NavLinkBar } from './NavLinkBar';

export interface LayoutProps {
  backPath?: string;
  toolbarTitle?: string;
  skipBottomPadding?: boolean;
}

interface MainProps extends LayoutProps {
  children: ReactElement | ReactElement[] | null;
}

const Layout = ({ children, backPath, toolbarTitle, skipBottomPadding }: MainProps) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const { pathname } = useQueryHelper();
  const isDesktopView = useMediaQuery(`(min-width:${ViewportType.TABLET}px)`);

  const menuRequiredWidth = (isDesktopView ? menuWidth : 0) + 2;

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [pathname]);

  useEffect(() => {
    if (isDesktopView) {
      setMobileMenuOpen(false);
    }
  }, [isDesktopView]);

  return (
    <div>
      {isDesktopView ? (
        <Menu isDesktopView />
      ) : (
        <>
          <MobileToolbar backPath={backPath} toolbarTitle={toolbarTitle} onClickMenu={() => setMobileMenuOpen(true)} />
          <MobileMenu open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)} />
        </>
      )}

      <PageContainer menuRequiredWidth={menuRequiredWidth} skipBottomPadding={skipBottomPadding && !isDesktopView}>
        <NavLinkBar />
        <div>
          {children}
          {isDesktopView && (
            <div css={styles.footerContainer}>
              <Footer />
            </div>
          )}
        </div>
        <IntercomInitializer />
      </PageContainer>
    </div>
  );
};

const PageContainer = styled.div<{ menuRequiredWidth: number; skipBottomPadding?: boolean }>`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  margin-bottom: 56px;
  margin-left: ${({ menuRequiredWidth }) => menuRequiredWidth}px;
  min-height: 80vh;
  overflow: hidden;
  padding-bottom: ${({ skipBottomPadding }) => (skipBottomPadding ? 0 : '10%')};
  position: relative;

  @media (max-width: ${ViewportType.SMALL}px) {
    margin-left: 0;
    width: 100%;
  }
`;

const styles = {
  footerContainer: css`
    bottom: 16px;
    display: flex;
    justify-content: center;
    height: 24px;
    position: absolute;
    width: 100%;

    & > div {
      width: 85%;
    }
  `,
};

export default Layout;
