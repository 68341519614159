import { format } from 'date-fns';
import React, { useState } from 'react';
import { StepStyleDTO } from 'react-form-stepper/dist/components/Step/StepTypes';
import Popup from 'reactjs-popup';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { useMutation, useQuery } from '@apollo/client';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from '@src/components/atoms/Button';
import Grid from '@src/components/atoms/Grid';
import { Icon } from '@src/components/atoms/Icon';
import VideoPreview from '@src/components/atoms/VideoPreview';
import StatusStepper from '@src/components/organisms/CastingMarketplace/YourJob/StatusStepper';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { LIMIT } from '@src/libs/constant';
import { useQueryHelper } from '@src/libs/hooks';
import { defaultEmptyImage, isVideoThumbnail } from '@src/libs/image';
import { getSocialMediaUrlByCampaignType } from '@src/libs/campaign';
import { switchImage } from '@src/libs/SocialMedia';
import { ViewportType } from '@src/libs/theme';
import CAMPAIGN_DETAILS from '@src/pages/Casting/YourJob/Details/queries/EngagementCampaignDetail.graphql';
import {
  EngagementCampaignDetail,
  EngagementCampaignDetailVariables,
} from '@src/pages/Casting/YourJob/Details/queries/__generated__/EngagementCampaignDetail';
import {
  AnyXEngagementCampaignStatus,
  CampaignStatusForInfluencer,
  CampaignType,
} from '@src/__generated__/globalTypes';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import DELETE_CAMPAIGN_POST from './mutations/DeleteCampaignPost.graphql';
import { DeleteCampaignPost, DeleteCampaignPostVariables } from './mutations/__generated__/DeleteCampaignPost';
import CAMPAIGN_POSTS from './queries/EngagementCampaignPosts.graphql';
import {
  EngagementCampaignPosts,
  EngagementCampaignPostsVariables,
} from './queries/__generated__/EngagementCampaignPosts';
import DialogContent from './DialogContent';
import ReasonWidget from './ReasonWidget';

const Posts = () => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState<number | null>(null);
  const [selectedPostId, setSelectedPostId] = useState<number | null>(null);
  const { enqueueSnackbar, params, t, i18n } = useQueryHelper();
  const { show } = useIntercom();
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });
  const engagementId = Number(params.id);

  const { data } = useQuery<EngagementCampaignPosts, EngagementCampaignPostsVariables>(CAMPAIGN_POSTS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      engagementId,
      limit: LIMIT,
      offset: 0,
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const { data: dataCampaignDetails } = useQuery<EngagementCampaignDetail, EngagementCampaignDetailVariables>(
    CAMPAIGN_DETAILS,
    {
      variables: {
        pk: engagementId,
      },
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  const [deletePost] = useMutation<DeleteCampaignPost, DeleteCampaignPostVariables>(DELETE_CAMPAIGN_POST, {
    refetchQueries: ['EngagementCampaignPosts'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
      setSelectedDeleteId(null);
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  function onClickDeletePost() {
    setIsDeleting(true);
    deletePost({
      variables: {
        id: Number(selectedDeleteId),
      },
    }).finally(() => setIsDeleting(false));
  }

  const campaignPosts = data?.allEngagementPostsForInfluencer || [];
  const isAddDraftPost = dataCampaignDetails?.engagementForInfluencer?.status !== CampaignStatusForInfluencer.FINISHED;

  return (
    <div css={styles.container}>
      <Popup
        contentStyle={{
          backgroundColor: 'transparent',
          border: 'none',
          maxWidth: 700,
          width: '90%',
        }}
        open={!!selectedPostId}
        onClose={() => setSelectedPostId(null)}
      >
        <div>
          {!!selectedPostId && <DialogContent postId={selectedPostId} onClose={() => setSelectedPostId(null)} />}
        </div>
      </Popup>

      <Popup
        contentStyle={{
          backgroundColor: 'transparent',
          border: 'none',
          maxWidth: 400,
          width: '90%',
        }}
        open={!!selectedDeleteId}
        onClose={() => setSelectedDeleteId(null)}
      >
        <div css={styles.dialog}>
          <div css={styles.dialogCloseBtn} onClick={() => setSelectedDeleteId(null)}>
            <Icon icon="close" />
          </div>
          <div css={styles.dialogContent}>
            <div css={styles.dialogTitle}>{t('Confirmation required')}</div>
            <div css={styles.dialogMessage}>{t('Dialog.Are you sure you want to delete the post?')}</div>
          </div>
          <div css={styles.dialogAction}>
            <DialogButton
              bgcolor="#fff"
              hoverbgcolor="#f6f8fa"
              title="Cancel"
              onClick={() => setSelectedDeleteId(null)}
            />
            <DialogButton
              bgcolor="#3892e5"
              color="#fff"
              disabled={isDeleting}
              hoverbgcolor="#4592ff"
              title="Delete"
              onClick={onClickDeletePost}
            />
          </div>
        </div>
      </Popup>

      {isDesktopView && (
        <div css={styles.navigatorContainer}>
          <div>
            <BackNavigator title="Post List" to={`/casting/your_job/${engagementId}`} />
          </div>
          {isAddDraftPost && (
            <div>
              <StyledLink primary to={`/casting/your_job/${engagementId}/post/add`}>
                {t('Button.Add Draft Post')}
              </StyledLink>
            </div>
          )}
        </div>
      )}

      {campaignPosts.map(post => {
        const {
          anyXStatus,
          content,
          id,
          inappropriateDetailedReason,
          inappropriateReason,
          planedPostDate,
          postedDate,
          socialMedia,
          thumbNail,
        } = post;

        const showReason = [
          AnyXEngagementCampaignStatus.WARNING_EFFECTIVE,
          AnyXEngagementCampaignStatus.WARNING_REVIEWING,
        ].includes(anyXStatus);

        return (
          <div css={styles.postsContainer} key={id}>
            <Grid lg={8} sm={10} xs={12}>
              <div css={styles.postContainer}>
                <div css={styles.infoSection}>
                  {isDesktopView && showReason && (
                    <ReasonContainer>
                      <ReasonWidget
                        content={inappropriateDetailedReason}
                        status={anyXStatus}
                        title={inappropriateReason}
                      />
                    </ReasonContainer>
                  )}

                  <div css={styles.headerContainer}>
                    <Grid css={styles.stepperContainer} md={6} xs={12}>
                      <HeaderTitle>{t('Post Status')}</HeaderTitle>
                      <StatusStepper
                        status={anyXStatus}
                        styleConfig={{ size: '10px' } as StepStyleDTO}
                        type={CampaignType.ENGAGEMENT}
                      />
                    </Grid>

                    {!isDesktopView && showReason && (
                      <ReasonContainer>
                        <ReasonWidget
                          content={inappropriateDetailedReason}
                          status={anyXStatus}
                          title={inappropriateReason}
                        />
                      </ReasonContainer>
                    )}

                    <Grid container md={6} xs={12}>
                      <HeaderInfo flexBasis="20%">
                        <HeaderTitle>{t('SNS')}</HeaderTitle>
                        <HeaderContent>
                          <img alt="socialMedia" height="24" src={switchImage(socialMedia, true)} width="24" />
                        </HeaderContent>
                      </HeaderInfo>
                      <HeaderInfo flexBasis="40%">
                        <HeaderTitle>{t('Planed Post Date')}</HeaderTitle>
                        <HeaderContent>
                          {localizedDateFormatter(
                            new Date(planedPostDate),
                            'MMMM dd, yyyy',
                            i18n.language as AppLanguage
                          )}
                        </HeaderContent>
                      </HeaderInfo>
                      <HeaderInfo flexBasis="40%">
                        <HeaderTitle>{t('Posted Date')}</HeaderTitle>
                        <HeaderContent>
                          {[
                            AnyXEngagementCampaignStatus.DRAFTING,
                            AnyXEngagementCampaignStatus.REVIEWING,
                            AnyXEngagementCampaignStatus.WARNING_REVIEWING,
                          ].includes(anyXStatus)
                            ? '- - -'
                            : postedDate
                            ? format(new Date(postedDate), 'MMMM dd, yyyy')
                            : '- - -'}
                        </HeaderContent>
                      </HeaderInfo>
                    </Grid>
                  </div>

                  <div css={styles.contentSection}>
                    <div>
                      <div>
                        {isVideoThumbnail(thumbNail) ? (
                          <VideoPreview height="86" url={thumbNail} width="152" />
                        ) : (
                          <img alt="postImg" height="86" src={defaultEmptyImage(thumbNail)} width="152" />
                        )}
                      </div>
                    </div>
                    <div onClick={() => setSelectedPostId(id)}>{content}</div>
                  </div>
                </div>

                {![AnyXEngagementCampaignStatus.DRAFTING].includes(anyXStatus) && (
                  <div css={styles.actionSection}>
                    {[AnyXEngagementCampaignStatus.REVIEWING, AnyXEngagementCampaignStatus.WARNING_REVIEWING].includes(
                      anyXStatus
                    ) ? (
                      <>
                        <StyledButton
                          bgcolor="#fff"
                          hoverbgcolor="#f6f8fa"
                          title="Delete"
                          onClick={() => setSelectedDeleteId(id)}
                        />
                        <StyledLink primary to={`/casting/your_job/${engagementId}/post/${id}`}>
                          {t('Button.Edit')}
                        </StyledLink>
                      </>
                    ) : anyXStatus === AnyXEngagementCampaignStatus.POSTING ? (
                      <StyledLink
                        primary
                        target="_blank"
                        to={{ pathname: getSocialMediaUrlByCampaignType(socialMedia) }}
                      >
                        {t('Button.Post Now')}
                      </StyledLink>
                    ) : anyXStatus === AnyXEngagementCampaignStatus.WARNING_EFFECTIVE ? (
                      <StyledButton bgcolor="#fff" hoverbgcolor="#f6f8fa" title="Contact" onClick={show} />
                    ) : (
                      <StyledLink to={`/casting/your_job/${engagementId}/report`}>{t('Button.View Report')}</StyledLink>
                    )}
                  </div>
                )}
              </div>
            </Grid>
          </div>
        );
      })}
    </div>
  );
};

const DialogButton = styled(Button)<{ color?: string }>`
  border: 1px solid #dee5ec;
  border-radius: 5px;
  color: ${({ color }) => (color ? color : '#6e7c89')};
  font-weight: 600;
  height: 32px;
  margin-right: 8px;
  width: 89px;
`;

const HeaderContent = styled.div`
  color: #27313b;
  font-size: 13px;
  margin-top: 8px;
`;

const HeaderInfo = styled.div<{ flexBasis: string }>`
  display: grid;
  flex-basis: ${({ flexBasis }) => flexBasis};
`;

const HeaderTitle = styled.div`
  color: #27313b;
  font-size: 13px;
  font-weight: 600;
`;

const ReasonContainer = styled.div`
  display: flex;
  flex-basis: 100%;
  margin-bottom: 16px;
`;

const StyledButton = styled(Button)`
  border: 1px solid #dee5ec;
  border-radius: 5px;
  color: #6e7c89;
  font-weight: 600;
  height: 32px;
  margin-right: 8px;
  width: 89px;

  @media (max-width: ${ViewportType.SMALL}px) {
    height: 40px;
    width: fill-available;
  }
`;

const StyledLink = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{
  primary?: boolean;
}>`
  align-items: center;
  background: ${({ primary }) => (primary ? '#3892e5' : '#fff')};
  ${({ primary }) => (primary ? '' : 'border: 1px solid #dee5ec;')}
  border-radius: 5px;
  color: ${({ primary }) => (primary ? '#fff' : '#6e7c89')};
  display: flex;
  font-weight: 600;
  height: 32px;
  justify-content: center;
  padding: 0 24px;

  @media (max-width: ${ViewportType.SMALL}px) {
    height: 40px;
    width: fill-available;
  }
`;

const styles = {
  actionSection: css`
    display: flex;
    justify-content: flex-end;
    padding: 24px;
  `,
  container: css`
    margin: 16px;
  `,
  contentSection: css`
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;

    & > div:nth-of-type(1) {
      height: 86px;
      max-width: 168px;
      width: 35%;

      & > div {
        background-color: #000;
        margin-right: 16px;

        & > img {
          object-fit: contain;
          width: 100%;
        }
      }

      @media (max-width: ${ViewportType.MEDIUM}px) {
        width: 50%;
      }
    }

    /* stylelint-disable no-descending-specificity, value-no-vendor-prefix, property-no-vendor-prefix */
    & > div:nth-of-type(2) {
      color: #3892e5;
      cursor: pointer;
      display: -webkit-box;
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 65%;
      word-break: break-all;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;

      &:hover {
        text-decoration: underline;
      }

      @media (max-width: ${ViewportType.MEDIUM}px) {
        width: 50%;
      }
    }
  `,
  dialog: css`
    background-color: #fff;
    border-radius: 5px;
  `,
  dialogAction: css`
    border-top: 1px solid #dee5ec;
    display: flex;
    padding: 16px;
    justify-content: flex-end;

    & > button:nth-of-type(1) {
      margin-right: 8px;
    }
  `,
  dialogCloseBtn: css`
    align-items: center;
    background-color: #000;
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: -12px;
    top: -16px;
    width: 40px;
  `,
  dialogContent: css`
    padding: 16px;
  `,
  dialogMessage: css`
    color: #27313b;
    margin: 8px 0;
  `,
  dialogTitle: css`
    color: #27313b;
    font-size: 18px;
    font-weight: 600;
  `,
  headerContainer: css`
    display: flex;
    flex-wrap: wrap;
  `,
  infoSection: css`
    border-bottom: 1px solid #eef3f7;
    padding: 24px;
  `,
  navigatorContainer: css`
    display: flex;
    flex: wrap;
    margin-bottom: 16px;

    & > div {
      display: flex;
      flex-basis: 50%;
    }

    & > div:nth-of-type(2) {
      justify-content: flex-end;
    }
  `,
  postContainer: css`
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
  `,
  postsContainer: css`
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  `,
  stepperContainer: css`
    @media (max-width: ${ViewportType.TABLET}px) {
      border-bottom: 1px solid #eef3f7;
      margin-bottom: 16px;
      padding-bottom: 8px;
    }
  `,
};

export default Posts;
