import styled from '@emotion/styled';
import * as React from 'react';
import css from '@emotion/css';
import { Redirect } from 'react-router';
import SignUpEmailForm from '@src/components/organisms/SignUpEmailForm';
import TopPageFooter from '@src/components/organisms/TopPage/Footer';
import TopPageHeader from '@src/components/organisms/TopPage/Header';
import { checkSignedIn, getAppRootRoute } from '@src/libs/auth';
import { SignUpAndSignInBreakpoint } from '@src/libs/theme';
import { usePageLayout } from '@src/libs/hooks';

const Template = () => {
  const isSignedIn = checkSignedIn();
  const rootPath = getAppRootRoute();
  const { isMobileViewPublicRoutes } = usePageLayout();
  const isWithinIframe = window.location !== window.parent.location;

  if (isSignedIn) {
    return <Redirect to={rootPath} />;
  }

  return (
    <Wrapper>
      <Container>
        {!isWithinIframe ? (
          <div css={styles.header}>
            <Header href="/signup" />
          </div>
        ) : null}
        <FormWrapper>
          <SignUpEmailForm isWithinIframe={isWithinIframe} />
        </FormWrapper>
        {!isMobileViewPublicRoutes && !isWithinIframe ? <TopPageFooter css={styles.footer} /> : null}
      </Container>
    </Wrapper>
  );
};

const Header = styled(TopPageHeader)`
  padding-top: 16px;
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 856px;
  width: 100%;
`;

const FormWrapper = styled.section`
  box-shadow: 0 1px 4px 0 #dee5ec, 0 2px 5px 0 #dee5ec;
  width: 100%;
  padding: 48px;
  background-color: #fff;
  border-radius: 3px;
  box-sizing: border-box;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    border-radius: 0;
    box-shadow: none;
    padding: 24px;
  }
`;

const styles = {
  footer: css`
    width: 100%;
  `,
  header: css`
    display: flex;
    justify-content: center;
    width: 100%;

    @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
      background-color: #fff;

      & header {
        width: 90%;
      }
    }
  `,
};

export default Template;
