import { FanGender, UpdateFanInput } from '@src/__generated__/globalTypes';
import { FormValues } from './FanDetailsForm';
import { GetFan_getFan } from './__generated__/GetFan';

export const getInitialFormValues = (data: GetFan_getFan) => ({
  firstName: data.firstName,
  lastName: data.lastName,
  email: data.email || '',
  contactNumber: data.contactNumber || '',
  birthday: data.birthday || '',
  gender: data.gender || '',
  countryId: data.countryId || '',
  province: data.province || '',
  city: data.city || '',
  postalCode: data.postalCode || '',
  address1: data.address1 || '',
  address2: data.address2 || '',
  tags: data.tags || [],
  tagEntry: '',
});

export const getUpdateFanInput = (id: number, payload: FormValues): UpdateFanInput => ({
  id: Number(id),
  firstName: payload.firstName,
  lastName: payload.lastName,
  tags: payload.tags,
  ...(!!payload.email && { email: payload.email }),
  ...(!!payload.contactNumber && { contactNumber: payload.contactNumber }),
  ...(!!payload.birthday && { birthday: payload.birthday }),
  ...(!!payload.gender && { gender: payload.gender as FanGender }),
  ...(!!payload.countryId && { countryId: payload.countryId }),
  ...(!!payload.province && { province: payload.province }),
  ...(!!payload.city && { city: payload.city }),
  ...(!!payload.postalCode && { postalCode: payload.postalCode }),
  ...(!!payload.address1 && { address1: payload.address1 }),
  ...(!!payload.address2 && { address2: payload.address2 }),
});
