import React from 'react';
import SponsoredPosts from '@src/components/organisms/ProfileWidgets/SponsoredPosts';
import { MentionedAccounts } from '@src/components/organisms/ProfileWidgets/MentionedAccounts';
import TagCloudPost from '@src/components/organisms/ProfileWidgets/TagCloudPost';
import { sectionStyles } from '@src/components/organisms/AnalyticsShared/AnalyticsContentWrapper';
import AudienceFollowers from '../AudienceFollowers';
import FollowersGrowthAndPosts from '../FollowersGrowthAndPosts';
import { ProfilePostsSection } from '../InstagramProfilePosts/ProfilePosts';
import { useHasEstimatedApi } from './useHasEstimatedApi';
import { ProfileV2TemplateProps } from './types';

const InstagramTemplate = (props: ProfileV2TemplateProps) => {
  const { selectedSocialMedia, influencerId } = props;
  const { id, socialAccountType } = selectedSocialMedia;
  const { hasNoEstimatedApiText } = useHasEstimatedApi({
    pk: influencerId,
    socialAccountId: id,
    socialMedia: socialAccountType,
  });

  return (
    <>
      <FollowersGrowthAndPosts
        socialMediaAccountId={id}
        influencerAccountId={influencerId}
        socialMedia={socialAccountType}
      />

      <AudienceFollowers socialMediaAccountId={id} influencerAccountId={influencerId} socialMedia={socialAccountType} />
      <ProfilePostsSection
        selectedSocialMedia={selectedSocialMedia}
        influencerAccountId={influencerId}
        hasNoEstimatedApiText={hasNoEstimatedApiText}
      />

      <SponsoredPosts
        influencerId={influencerId}
        socialAccountId={id}
        socialMedia={socialAccountType}
        css={sectionStyles.sectionContainer}
      />

      <MentionedAccounts influencerId={influencerId} socialAccountId={id} css={sectionStyles.sectionContainer} />

      <TagCloudPost
        influencerId={influencerId}
        socialAccountId={id}
        socialMedia={socialAccountType}
        css={sectionStyles.sectionContainer}
      />
    </>
  );
};

export default InstagramTemplate;
