import React from 'react';
import { SocialMediaAccountType } from '@src/components/organisms/ProfileDetail/types';
import FollowersGrowthAndPosts from '../FollowersGrowthAndPosts';
import { TkProfilePostsSection } from '../TikTokProfilePosts/ProfilePosts';
import { ProfileV2TemplateProps } from './types';

export interface TikTokTemplateProps {
  selectedSocialMedia: SocialMediaAccountType;
}

const TikTokTemplate = (props: ProfileV2TemplateProps) => {
  const { selectedSocialMedia, influencerId } = props;

  return (
    <>
      <FollowersGrowthAndPosts
        socialMediaAccountId={selectedSocialMedia.id}
        influencerAccountId={influencerId}
        socialMedia={selectedSocialMedia.socialAccountType}
      />

      <TkProfilePostsSection
        socialMediaAccountId={selectedSocialMedia.id}
        influencerAccountId={influencerId}
        socialMedia={selectedSocialMedia.socialAccountType}
      />
    </>
  );
};

export default TikTokTemplate;
