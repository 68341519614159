import { useHistory, useLocation } from 'react-router';
import { ConnectState } from '../../../../libs/auth';

export const useLocationState = () => {
  const history = useHistory();
  const { state } = useLocation<ConnectState | undefined>();
  const connectType = state?.connectType;
  const reconnectIgAccountId = state?.reconnectIgAccountId;

  // clear location state from Connect component
  const resetLocationState = () => {
    history.replace('/profile/settings/connect', {});
  };

  return {
    connectType,
    reconnectIgAccountId,
    resetLocationState,
  };
};
