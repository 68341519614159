import { useQuery } from '@apollo/client';
import { FBPageAndIGAccountInfoType } from './types';
import { ConnectableFacebookPagesAndInstagramAccountsForProfileConnect } from './__generated__/ConnectableFacebookPagesAndInstagramAccountsForProfileConnect';
import * as CONNECTABLE_PAGES_AND_INSTAGRAM_ACCOUNTS from './ConnectableFacebookPagesAndInstagramAccounts.graphql';

interface ModalForChooseInstagramReturnType {
  accounts: FBPageAndIGAccountInfoType[];
  loading: boolean;
  error?: string;
}

export const useIGAccounts = (): ModalForChooseInstagramReturnType => {
  const { data, loading, error: apolloError } = useQuery<ConnectableFacebookPagesAndInstagramAccountsForProfileConnect>(
    CONNECTABLE_PAGES_AND_INSTAGRAM_ACCOUNTS,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  if (!!apolloError) {
    return {
      accounts: [],
      loading,
      error: apolloError.message || 'UnexpectedError',
    };
  }

  const allFBPagesAndIGAccounts = data?.connectableFacebookPagesAndInstagramAccounts?.accounts || [];

  // Display only IG accounts which has not been connected yet.
  const accounts: FBPageAndIGAccountInfoType[] = allFBPagesAndIGAccounts.filter(
    (account): account is FBPageAndIGAccountInfoType => !!account.igAccount && !account.igAccount.isAlreadyConnected
  );

  return {
    accounts,
    loading,
    error: accounts.length === 0 ? 'NoInstagramAccountLinked' : undefined,
  };
};
