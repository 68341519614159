import { AdminStore } from '@src/Context';
import { getThirtyDaysAgo, getToday } from '@src/libs/date';
import { usePaging } from '@src/libs/paging';
import React, { useContext } from 'react';
import { useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';
import Template from './template';
import { useFilter } from './useFilter';

const StoryFeed = () => {
  const {
    state: { userId },
  } = useContext(AdminStore);

  if (!userId) {
    return <Redirect to="/" />;
  }

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [id, stda, enda] = [
    searchParams.get('id') as string,
    searchParams.get('stda') as string,
    searchParams.get('enda') as string,
  ];

  // Paging
  const currentPage = usePaging();
  // Filter
  const { filter, setFilter } = useFilter({
    startDate: stda || getThirtyDaysAgo(),
    endDate: enda || getToday(),
  });

  return <Template accountId={Number(id)} currentPage={currentPage} filter={filter} setFilter={setFilter} />;
};

export default StoryFeed;
