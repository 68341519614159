import styled from '@emotion/styled';
import Pager from '@src/components/molecules/Pager';
import { getPageInfo } from '@src/libs/paging';
import { BasePostFilterType } from '@src/pages/InstagramStoryOrFeedPosts/types';
import * as React from 'react';
import NavigationHeader from '../Header/NavigationHeader';
import { Post } from './model';
import PostFeedFilterForm from './PostFeedFilters';
import PostFeedList from './PostFeedList';

interface TemplateProps {
  posts: Post[];
  total: number;
  accountId: number;
  currentPage: number;
  filter: BasePostFilterType;
  setFilter: (filter: BasePostFilterType) => void;
}

const Template = (props: TemplateProps) => {
  const { posts, currentPage, filter, setFilter, accountId, total } = props;

  const pageInfo = getPageInfo(currentPage, total);

  return (
    <>
      <NavigationHeader />
      <Wrapper>
        <Card>
          <PostFeedFilterForm filter={filter} setFilter={setFilter} />
          <PostFeedList accountId={accountId} totalCount={pageInfo.totalCount} posts={posts} />
        </Card>
        <Pager
          currentPage={currentPage}
          totalPages={pageInfo.totalPages}
          totalCount={pageInfo.totalCount}
          first={pageInfo.firstIndex}
          last={pageInfo.lastIndex}
        />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 48px 16px 14px;
  padding: 16px 0;
  width: 100%;
`;

const Card = styled.div`
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5);
  background-color: #fff;
`;

export default Template;
