import React from 'react';
import styled from '@emotion/styled';
import { defaultInfluencerAvatar } from '@src/libs/image';
import { SliderComponents, StyledComponents } from '@src/components/molecules/SliderTable';
import { HeaderColumn } from '@src/components/atoms/List';
import css from '@emotion/css';
import BaseCss from '@src/components/molecules/SliderTable/BaseCss';
import TextCutter from '@src/components/atoms/TextCutter';
import { TableGrowthSection } from './GrowthSection';
import { EngagementAccountDataType } from './types';
import { compareColors } from './compareHelpers';

const ComparableAccountsTable = ({ engagement }: { engagement: EngagementAccountDataType[] }) => (
  <SliderComponents.Wrapper css={styles.tableWrapper}>
    <SliderComponents.Container css={styles.container}>
      <SliderComponents.SliderSection>
        <SliderComponents.SliderTable>
          <thead>
            <SliderComponents.HeaderRow css={{ backgroundColor: '#F6F8FA', height: '48px' }}>
              <SliderComponents.StickyHeaderRowWrapper>
                <StyledHeader
                  title="Account"
                  css={css`
                    padding: 0;
                    width: 64px;
                    font-weight: 400;
                    font-size: 12px;
                  `}
                  isTable={false}
                />
              </SliderComponents.StickyHeaderRowWrapper>
              <StyledHeader title="Subscribers (Total)" css={styles.headerCols} colSpan={1} />
              <StyledHeader title="Posts (Total)" css={styles.headerCols} />
              <StyledHeader title="Avg Views" css={styles.headerCols} />
              <StyledHeader title="Maximum Views" css={styles.headerCols} />
              <StyledHeader title="Avg Likes" css={styles.headerCols} />
              <StyledHeader title="Maximum Likes" css={styles.headerCols} />
              <StyledHeader title="Avg Comments" css={styles.headerCols} />
            </SliderComponents.HeaderRow>
          </thead>

          <tbody>
            {engagement.map((item, index) => {
              const { avatar, name, subscribers, posts, avgViews, maxViews, avgLikes, maxLikes, avgComments } = item;

              return (
                <StyledComponents.StyledRowNew
                  css={[
                    css`
                      padding: 0;
                      height: 82px;
                    `,
                  ]}
                  key={index}
                >
                  <SliderComponents.StickyBodyRowWrapper css={{ width: '64px' }}>
                    <AccountCell>
                      <Avatar src={defaultInfluencerAvatar(avatar)} />
                      <TextCutter text={name} lines={2} css={styles.accountName} />
                    </AccountCell>
                    <ColorBox color={compareColors[index]} />
                  </SliderComponents.StickyBodyRowWrapper>
                  <TableGrowthSection {...subscribers} />
                  <TableGrowthSection {...posts} />
                  <TableGrowthSection {...avgViews} />
                  <TableGrowthSection {...maxViews} />
                  <TableGrowthSection {...avgLikes} />
                  <TableGrowthSection {...maxLikes} />
                  <TableGrowthSection {...avgComments} />
                </StyledComponents.StyledRowNew>
              );
            })}
          </tbody>
        </SliderComponents.SliderTable>
      </SliderComponents.SliderSection>
    </SliderComponents.Container>
  </SliderComponents.Wrapper>
);

const StyledHeader = styled(HeaderColumn)`
  & > div {
    justify-content: flex-start;
    word-wrap: break-word;
  }
`;
const ColorBox = styled.div<{ color: string }>`
  position: absolute;
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;
const AccountCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-bottom: 8px;
`;
const styles = {
  tableWrapper: css`
    padding: 16px 0 16px 16px;
    margin: 0;
    border: 0;
  `,
  container: css`
    border: 1px solid #e6ecf0;
    border-right: none;
  `,
  headerCols: [
    BaseCss.twoLineHeader,
    css`
      padding: 0;
      padding-left: 8px;
      font-weight: 400;
      font-size: 12px;
      border-right: 1px solid #e6ecf0;
    `,
  ],
  accountName: css`
    text-align: center;
    font-size: 10px;
    font-weight: 400;
    line-height: 11px;
  `,
};

export default ComparableAccountsTable;
