import styled from '@emotion/styled';
import * as React from 'react';

interface FieldType {
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: any) => void;
  value?: any;
  name?: string;
}

interface TextDateProps extends FieldType {
  placeholder: string;
  disabled?: boolean;
}

const TextDate = (props: TextDateProps) => {
  const { placeholder, disabled, ...field } = props;

  return (
    <Wrapper>
      <Input type="text" placeholder={placeholder} disabled={!!disabled} {...field} />
      <Icon className="material-icons">calendar_today</Icon>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 0 12px;
  background-color: #fff;
  border: 1px solid #dee5ec;
  border-radius: 20px;
  font-size: 13px;
  line-height: 38px;
  letter-spacing: 0.02em;
  cursor: pointer;
`;

const Icon = styled.i`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  position: absolute;
  right: 1px;
  top: 1px;
  background-color: #fff;
  color: #d5d5d5;
  font-size: 18px;
  pointer-events: none;
`;

export default TextDate;
