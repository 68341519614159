import React from 'react';
import styled from '@emotion/styled';
import * as Styled from '@src/components/organisms/AnalyticsShared/Overview/StyledComponents';
import { getGrowthPrefix, getGrowthArrow } from '@src/components/organisms/AnalyticsShared/utils';
import { formatPercent, formatNumber, bigIntFormatter, intlNumberFormat } from '@src/libs/format';

interface GrowthItemType {
  total: number | null;
  growth: number | null;
  rate: number | null;
  hasDecimals?: boolean;
  hasHyphen?: boolean;
}
export const TableGrowthSection = (item: GrowthItemType) => {
  const isPositiveGrowth = item.growth === 0 ? undefined : Number(item.growth) > 0;
  const prefix = getGrowthPrefix(item.growth) || '';
  const arrow = getGrowthArrow(item.growth);
  // FIXME: remove after BE will support null for newly fetched hashtags
  const temporatyNullableTotal = item.hasHyphen ? item.total || null : item.total;
  const formattedTotal = !!item.hasDecimals
    ? formatNumber(temporatyNullableTotal, 2)
    : bigIntFormatter(temporatyNullableTotal);
  const formattedGrowth = !!item.hasDecimals ? formatNumber(item.growth, 2) : bigIntFormatter(item.growth);
  const hoverTotal = intlNumberFormat(item.total);
  const hoverGrowth = intlNumberFormat(item.growth);

  return (
    <TDWrapper>
      {/* <div css={{ width: '80px' }}> */}
      <div>
        <Styled.PartWithIcon>
          <Styled.EngagementNumbers>
            <Styled.TotalNumber title={hoverTotal}>{formattedTotal}</Styled.TotalNumber>
          </Styled.EngagementNumbers>
        </Styled.PartWithIcon>
        {formattedTotal !== '-' && (
          <Styled.Growth
            isPositive={isPositiveGrowth}
            title={hoverGrowth}
          >{`${prefix}${formattedGrowth}  ${arrow}\n (${formatPercent(item.rate)})`}</Styled.Growth>
        )}
      </div>
    </TDWrapper>
  );
};

const TDWrapper = styled.td`
  width: 64px;
  padding-left: 8px !important;
  border-right: 1px solid #e6ecf0;
`;
