import React from 'react';
import { useTranslation } from 'react-i18next';
import HighchartsTreemap from 'highcharts/modules/treemap';
import { renderToString } from 'react-dom/server';
import { css } from '@emotion/react';
import TextCutter from '@src/components/atoms/TextCutter';
import DataOpacityWrapper, { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import HighChart, { Highcharts } from '@src/components/molecules/HighChart';
import { formatPercent } from '@src/libs/format';

/* initializing treemap module */
HighchartsTreemap(Highcharts);

interface AreaBreakdownChartProps {
  interestRate: { value: number; name: string }[];
  className?: string;
  wrapperText?: string;
}
const AccountsInterestChart = ({ interestRate, className, wrapperText }: AreaBreakdownChartProps) => {
  const { t } = useTranslation();

  const chartOptions = (): Highcharts.Options => ({
    chart: {
      type: 'treemap',
      height: '290px',
      spacingTop: 0,
    },
    title: {
      text: '',
    },
    tooltip: {
      formatter: function formatter(): string {
        return renderToString(
          <div
            css={css`
              border-radius: 3px;
              display: flex;
              align-items: center;
              padding: 13px 16px;
            `}
          >
            {formatPercent(this.point.value)}
          </div>
        );
      },
      padding: 0,
      borderColor: 'green',
      borderWidth: 0,
      borderRadius: 3,
      backgroundColor: '#27313b',
      style: {
        color: '#ffffff',
      },
      useHTML: true,
    },
    plotOptions: {
      treemap: {
        layoutAlgorithm: 'squarified',
        animation: { duration: 500 },
        borderColor: '#fff',
        states: {
          hover: {
            borderColor: '#ED183E',
          },
        },
      },
    },
    series: [
      {
        data: interestRate.map((item, i) => ({
          ...item,
          color: `rgba(255, 128, 151, ${i ? 1 - i * 0.15 : 1}`,
          opacity: item.value,
        })),
        dataLabels: {
          useHTML: true,
          enabled: true,
          align: 'center',
          verticalAlign: 'middle',
          crop: false,
          overflow: 'allow',
          style: {
            fontSize: '11px',
            color: '#fff',
          },
          formatter: function formatter() {
            // @ts-ignore
            const labelWidth = this.point.shapeArgs.width ? `${this.point.shapeArgs.width - 8}px` : 'auto';

            return renderToString(
              <>
                <TextCutter
                  text={t(`Option.${this.point.name}`)}
                  lines={2}
                  css={css`
                    width: ${labelWidth};
                    overflow-wrap: break-word;
                    white-space: pre-line;
                    text-align: center;
                    word-break: break-word;
                  `}
                />
              </>
            );
          },
        },
        // @ts-ignore
        type: 'treemap',
      },
    ],
  });

  return (
    <DataOpacityWrapper
      header={<SectionHeader>{t(`Account's Interest`)}</SectionHeader>}
      dataNotAvailable={!interestRate.length}
      css={{ borderRight: '1px solid #E6ECF0' }}
      className={className}
      wrapperText={wrapperText}
    >
      <HighChart options={chartOptions()} />
    </DataOpacityWrapper>
  );
};

export default AccountsInterestChart;
