import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikContextType } from 'formik';
import TextForm from '@src/components/molecules/TextForm';
import ErrorMessage from '@src/components/atoms/ErrorMessage';

interface FormValues {
  lineKid: string;
}
const Step3 = ({ handleSubmit, values, handleChange, errors }: FormikContextType<FormValues>) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <TextForm
        name="lineKid"
        title="Kid"
        value={values.lineKid}
        isRequired
        onChange={handleChange}
        placeholder={t('TextForm.Kid')}
        error={!!errors.lineKid}
      />
      {errors.lineKid && <ErrorMessage message={t(errors.lineKid)} />}
    </form>
  );
};

export default Step3;
