import { YoutubeAnalyticsComparePostsSortOrder } from '@src/__generated__/globalTypes';

export const getYtCompareVideosListSortFiledType = (field?: string | null) => {
  if (!field) {
    return null;
  }

  return YoutubeAnalyticsComparePostsSortOrder[field as keyof typeof YoutubeAnalyticsComparePostsSortOrder];
};

export const getYtCompareFeedVideosListColumns = () => [
  {
    title: 'Post date',
    orderField: YoutubeAnalyticsComparePostsSortOrder.POST_DATE,
  },
  {
    title: 'views',
    orderField: YoutubeAnalyticsComparePostsSortOrder.VIEWS,
  },
  {
    title: 'Like',
    orderField: YoutubeAnalyticsComparePostsSortOrder.LIKE,
  },
  {
    title: 'Dislike',
    orderField: YoutubeAnalyticsComparePostsSortOrder.DISLIKE,
  },
  {
    title: 'Comment',
    orderField: YoutubeAnalyticsComparePostsSortOrder.COMMENT,
  },
  {
    title: 'Positive Rate',
    orderField: YoutubeAnalyticsComparePostsSortOrder.POSITIVE_RATE,
  },
  {
    title: 'Negative Rate',
    orderField: YoutubeAnalyticsComparePostsSortOrder.NEGATIVE_RATE,
  },
  {
    title: 'Play Time',
    orderField: YoutubeAnalyticsComparePostsSortOrder.DURATION,
  },
];
