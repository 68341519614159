import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import negativeIcon from '@src/assets/icon/negative.svg';
import neutralIcon from '@src/assets/icon/neutral.svg';
import positiveIcon from '@src/assets/icon/positive.svg';
import { formatPercent, renderIfMoreThan20Percent } from '@src/libs/format';
import { CommentAnalytics } from '@src/components/organisms/AnalyticsYoutube/types';
import DataOpacityWrapper, { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import { useContainerQuery } from '@src/libs/hooks';

interface SentimentAnalysisProps {
  commentAnalytics?: CommentAnalytics | null;
  className?: string;
}
const colors = {
  positive: '#ff8097',
  neutral: '#ffbe82',
  negative: '#707fc0',
};

const SentimentAnalysis = (props: SentimentAnalysisProps) => {
  const { t } = useTranslation();
  const { containerRef, isSmaller: showMetricsVertically } = useContainerQuery({
    hasWidthSmallerThan: 330,
  });

  const { commentAnalytics, className } = props;

  const hasSmallNumberOfComments = commentAnalytics === null;
  const { positiveRatio, neutralRatio, negativeRatio } = commentAnalytics || {};
  const dataNotAvailable = !hasSmallNumberOfComments && !positiveRatio && !neutralRatio && !negativeRatio;

  return (
    <DataOpacityWrapper
      header={<SectionHeader>{t('Influencer.SentimentAnalysis')}</SectionHeader>}
      dataNotAvailable={dataNotAvailable}
      css={styles.container}
      className={className}
    >
      <BadgeWrapper
        ref={containerRef}
        css={
          showMetricsVertically
            ? css`
                flex-direction: column;
              `
            : ''
        }
      >
        <Badge bgColor={colors.positive} css={showMetricsVertically && styles.marginBottom8}>
          <Img src={positiveIcon} />
          <BadgeLabel>{t('Influencer.Positive')}</BadgeLabel>
        </Badge>
        <Badge bgColor={colors.neutral} css={showMetricsVertically && styles.marginBottom8}>
          <Img src={neutralIcon} />
          <BadgeLabel>{t('Influencer.Neutral')}</BadgeLabel>
        </Badge>
        <Badge bgColor={colors.negative}>
          <Img src={negativeIcon} />
          <BadgeLabel>{t('Influencer.Negative')}</BadgeLabel>
        </Badge>
      </BadgeWrapper>
      <AnalysisAreaWrapper>
        {hasSmallNumberOfComments ? (
          <p>
            {t(
              'This section cannot be displayed because the number of comments in the specified period is less than 20'
            )}
          </p>
        ) : (
          <>
            {!!positiveRatio && (
              <AnalysisArea
                value={positiveRatio}
                bgColor={colors.positive}
                title={formatPercent(positiveRatio, true, 1)}
              >
                <Value>{renderIfMoreThan20Percent({ value: positiveRatio, digits: 1 })}</Value>
              </AnalysisArea>
            )}
            {!!neutralRatio && (
              <AnalysisArea value={neutralRatio} bgColor={colors.neutral} title={formatPercent(neutralRatio, true, 1)}>
                <Value>{renderIfMoreThan20Percent({ value: neutralRatio, digits: 1 })}</Value>
              </AnalysisArea>
            )}
            {!!negativeRatio && (
              <AnalysisArea
                value={negativeRatio}
                bgColor={colors.negative}
                title={formatPercent(negativeRatio, true, 1)}
              >
                <Value>{renderIfMoreThan20Percent({ value: negativeRatio, digits: 1 })}</Value>
              </AnalysisArea>
            )}
          </>
        )}
      </AnalysisAreaWrapper>
    </DataOpacityWrapper>
  );
};

const styles = {
  container: css`
    border-right: 1px solid #dee5ec;
  `,
  marginBottom8: css`
    margin-bottom: 8px;
  `,
};

const BadgeWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  padding: 0 16px;
`;
const Badge = styled.div<{ bgColor: string }>`
  height: 32px;
  border-radius: 24px;
  background-color: ${({ bgColor }) => bgColor};
  line-height: 32px;
  display: flex;
  flex: 1;
  padding-left: 4px;
  margin-right: 4px;

  &:last-child {
    margin-right: 0;
  }
`;
const BadgeLabel = styled.p`
  font-size: 13px;
  font-weight: 600;
  color: #fff;
`;
const Img = styled.img`
  width: 24px;
  margin-right: 8px;
`;
const AnalysisAreaWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100px;
  padding: 0 16px 16px;
`;
const AnalysisArea = styled.div<{ bgColor: string; value: number }>`
  background-color: ${({ bgColor }) => bgColor};
  width: ${({ value }) => `${formatPercent(value)}`};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &:hover {
    box-shadow: 0 3px 4px 0 rgba(39, 49, 59, 0.2);
    margin-bottom: 2px;
    margin-top: -2px;
  }
`;
const Value = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #fff;
`;

export default SentimentAnalysis;
