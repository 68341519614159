import binocularBlue from '@src/assets/icon/menu/binocularBlue.svg';
import binocularGray from '@src/assets/icon/menu/binocularGray.svg';
import clipboardLightBlue from '@src/assets/icon/menu/clipboardBlue.svg';
import clipboardCheckBlue from '@src/assets/icon/menu/clipboardCheckBlue.svg';
import clipboardCheckGray from '@src/assets/icon/menu/clipboardCheckGray.svg';
import clipboardGray from '@src/assets/icon/menu/clipboardGray.svg';
import contactBlue from '@src/assets/icon/menu/contactBlue.svg';
import contactGray from '@src/assets/icon/menu/contactGray.svg';
import fanBlue from '@src/assets/icon/menu/fanBlue.svg';
import fanGray from '@src/assets/icon/menu/fanGray.svg';
import giftBlue from '@src/assets/icon/menu/giftBlue.svg';
import giftGray from '@src/assets/icon/menu/giftGray.svg';
import homeGray from '@src/assets/icon/menu/homeGray.svg';
import homeBlue from '@src/assets/icon/menu/homeBlue.svg';
import mailGray from '@src/assets/icon/menu/mailGray.svg';
import mailBlue from '@src/assets/icon/menu/mailBlue.svg';
import notificationBlue from '@src/assets/icon/menu/notificationBlue.svg';
import notificationGray from '@src/assets/icon/menu/notificationGray.svg';
import questionMarkGray from '@src/assets/icon/menu/questionMarkGray.svg';
import signOutGray from '@src/assets/icon/menu/signoutGray.svg';
import thunderBlue from '@src/assets/icon/menu/thunderMoveBlue.svg';
import thunderGray from '@src/assets/icon/menu/thunderMoveGray.svg';
import walletGray from '@src/assets/icon/menu/walletGray.svg';
import walletBlue from '@src/assets/icon/menu/walletBlue.svg';
import chatGrey from '@src/assets/icon/menu/chatGrey.svg';
import chatBlue from '@src/assets/icon/menu/chatBlue.svg';
// Analytics submenu icons
import facebookBlue from '@src/assets/icon/menu/facebookBlue.svg';
import facebookColorful from '@src/assets/icon/menu/facebookColorful.svg';
import facebookGray from '@src/assets/icon/menu/facebookGray.svg';
import instagramBlue from '@src/assets/icon/menu/instagramBlue.svg';
import instagramColorful from '@src/assets/icon/menu/instagramColorful.svg';
import instagramGray from '@src/assets/icon/menu/instagramGray.svg';
import tiktokGray from '@src/assets/icon/menu/tiktokGray.svg';
import tiktokColorful from '@src/assets/icon/menu/tiktokColorful.svg';
import tiktokBlue from '@src/assets/icon/menu/tiktokBlue.svg';
import twitterBlue from '@src/assets/icon/menu/twitterBlue.svg';
import twitterColorful from '@src/assets/icon/menu/twitterColorful.svg';
import twitterGray from '@src/assets/icon/menu/twitterGray.svg';
import youtubeBlue from '@src/assets/icon/menu/youtubeBlue.svg';
import youtubeColorful from '@src/assets/icon/menu/youtubeColorful.svg';
import youtubeGray from '@src/assets/icon/menu/youtubeGray.svg';

import { removeToken } from '@src/libs/auth';
import { UserRoles } from '@src/__generated__/globalTypes';

export enum BadgeType {
  NOTIFICATION = 'NOTIFICATION',
}

export enum DynamicToType {
  HELP = 'HELP',
}

export enum HiddenType {
  CRM = 'CRM',
}

export enum SmDisconnectStatusType {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
  TIKTOK = 'TIKTOK',
}

export interface Submenus {
  activeIcon: string;
  icon: string;
  disconnectStatusType?: SmDisconnectStatusType;
  title: string;
  to: string | string[];
  activeSubmenuIcon?: string;
}

export interface MenuItemProps {
  activeIcon?: string;
  badge?: BadgeType;
  dynamicTo?: DynamicToType;
  icon?: string;
  groupTitle?: string;
  hiddenType?: HiddenType;
  isDivider?: boolean;
  isNewTab?: boolean;
  rolesToAccess?: UserRoles[];
  submenus?: Submenus[];
  title?: string;
  to?: string;
  onClick?: () => void;
}

function onClickSignOut() {
  removeToken();
  window.location.replace('/');
}

export const menuWidth = 240;

export const menuItems: MenuItemProps[] = [
  { activeIcon: homeBlue, icon: homeGray, title: 'Home', to: '/home' },
  { groupTitle: 'Social media' },
  {
    activeIcon: thunderBlue,
    icon: thunderGray,
    rolesToAccess: [UserRoles.INFLUENCER, UserRoles.CREATOR_STAFF],
    submenus: [
      {
        activeIcon: instagramBlue,
        icon: instagramGray,
        activeSubmenuIcon: instagramColorful,
        disconnectStatusType: SmDisconnectStatusType.INSTAGRAM,
        title: 'Instagram',
        to: '/analytics?sm=INSTAGRAM',
      },
      {
        activeIcon: facebookBlue,
        icon: facebookGray,
        activeSubmenuIcon: facebookColorful,
        disconnectStatusType: SmDisconnectStatusType.FACEBOOK,
        title: 'Facebook',
        to: ['/analytics?sm=FACEBOOK', '/analytics?sm=FACEBOOK_PAGE'],
      },
      {
        activeIcon: twitterBlue,
        icon: twitterGray,
        activeSubmenuIcon: twitterColorful,
        disconnectStatusType: SmDisconnectStatusType.TWITTER,
        title: 'Twitter',
        to: '/analytics?sm=TWITTER',
      },
      {
        activeIcon: youtubeBlue,
        icon: youtubeGray,
        activeSubmenuIcon: youtubeColorful,
        disconnectStatusType: SmDisconnectStatusType.YOUTUBE,
        title: 'Youtube',
        to: '/analytics?sm=YOUTUBE',
      },
      {
        activeIcon: tiktokBlue,
        icon: tiktokGray,
        activeSubmenuIcon: tiktokColorful,
        disconnectStatusType: SmDisconnectStatusType.TIKTOK,
        title: 'TikTok',
        to: '/analytics?sm=TIKTOK',
      },
    ],
    title: 'Analytics',
    to: '/analytics',
  },
  {
    activeIcon: giftBlue,
    icon: giftGray,
    rolesToAccess: [UserRoles.INFLUENCER],
    submenus: [
      { activeIcon: clipboardLightBlue, icon: clipboardGray, title: 'Your Job', to: '/marketplace/your_job' },
      { activeIcon: binocularBlue, icon: binocularGray, title: 'Search Job', to: '/marketplace/search_job' },
    ],
    title: 'Marketplace',
    to: '/marketplace',
  },
  {
    activeIcon: clipboardLightBlue,
    icon: clipboardGray,
    rolesToAccess: [UserRoles.INFLUENCER],
    title: 'Casting',
    to: '/casting',
  },
  { hiddenType: HiddenType.CRM, groupTitle: 'Fan management' },
  {
    activeIcon: fanBlue,
    hiddenType: HiddenType.CRM,
    icon: fanGray,
    title: 'Fans',
    to: '/fans',
  },
  {
    activeIcon: mailBlue,
    hiddenType: HiddenType.CRM,
    icon: mailGray,
    title: 'Emails',
    to: '/emails',
  },
  {
    activeIcon: chatBlue,
    hiddenType: HiddenType.CRM,
    icon: chatGrey,
    title: 'Chat',
    to: '/chat',
  },
  {
    activeIcon: clipboardCheckBlue,
    hiddenType: HiddenType.CRM,
    icon: clipboardCheckGray,
    title: 'Forms',
    to: '/forms',
  },
  { groupTitle: 'Account' },
  {
    activeIcon: notificationBlue,
    badge: BadgeType.NOTIFICATION,
    icon: notificationGray,
    title: 'Notification',
    to: '/notification',
  },
  {
    activeIcon: contactBlue,
    icon: contactGray,
    title: 'Settings',
    to: '/settings',
  },
  { activeIcon: walletBlue, icon: walletGray, title: 'Payment', to: '/payment' },
  {
    dynamicTo: DynamicToType.HELP,
    icon: questionMarkGray,
    isDivider: true,
    isNewTab: true,
    title: 'Help',
  },
  { icon: signOutGray, title: 'Sign Out', onClick: onClickSignOut },
];

export function checkIsActivePathname(path: string, checkIsInitialPage?: boolean) {
  // split url query string into array
  const params = window.location.search.split(/[?&]+/);

  // to split query string from path and form into array
  const pathParams = path.split('?')[1] ? path.split('?')[1].split(/[?&]+/) : [''];
  const pathWithoutParams = path.split('?')[0];
  // for YT compare URl will look like "analytics:compare?sm=YOUTUBE"
  const pathWithoutCompare = window.location.pathname.split(':')[0];

  return checkIsInitialPage
    ? pathWithoutParams === pathWithoutCompare
    : pathWithoutCompare.includes(pathWithoutParams) && pathParams.some(param => params.includes(param));
}
