import { QueryHookOptions } from '@apollo/client';
import { LIMIT } from '../../../../../../libs/constant';
import { getOffset, PageInfo } from '../../../../../../libs/paging';

interface GetTotalNumber {
  getTotalNumber: TotalNumber | null;
}

interface TotalNumber {
  totalNumber: number;
}

export const getPageInfo = <TData extends GetTotalNumber, TVariables>(
  currentPage: number,
  data?: QueryHookOptions<TData, TVariables> & Partial<TData>,
  limit: number | undefined = LIMIT
): PageInfo => {
  const totalCount = data?.getTotalNumber?.totalNumber || 0;
  const totalPages = totalCount > 0 ? Math.ceil(totalCount / limit) : 0;

  const firstIndex = getOffset(currentPage, limit) + 1;
  const lastIndex = Math.min(firstIndex + limit - 1, totalCount);

  return {
    firstIndex,
    lastIndex,
    currentPage,
    totalCount,
    totalPages,
  };
};
