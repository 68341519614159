import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useHistory } from 'react-router';
import { checkSignedIn } from '../../../../libs/auth';

const PublicRoute = ({ component: Component, ...rest }: RouteProps) => {
  const history = useHistory();
  const isSignedIn = checkSignedIn();
  const isWithinChildWindow = window.opener;

  useEffect(() => {
    if (isSignedIn && !isWithinChildWindow) {
      history.push({ pathname: '/profile' });
    }
  }, []);

  return <Route {...rest} render={props => (Component ? <Component {...props} /> : null)} />;
};

export default PublicRoute;
