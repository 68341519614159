import React, { useState, useEffect } from 'react';
import { Global, css } from '@emotion/react';
import { useApolloClient } from '@apollo/client';
import { bigIntFormatter, formatPercent, intlNumberFormat } from '@src/libs/format';
import HighChart from '@src/components/molecules/HighChart';
import { getGrowthArrow, getGrowthPrefix } from '@src/components/organisms/AnalyticsShared/utils';
import * as Styled from '@src/components/organisms/AnalyticsShared/Overview/StyledComponents';
import { EngagementDataType } from '@src/components/organisms/AnalyticsShared/Overview/helpers';
import { useQueryHelper } from '@src/libs/hooks';
import { usePostDetailModal } from '@src/components/organisms/AnalyticsShared/BasePostList/usePostDetailModal';
import CheckBoxIcon from '@src/components/atoms/Icon/CheckBoxIcon';
import CarouselModal from '@src/components/molecules/CarouselModal/CarouselModal';
import { MultiSelector } from '@src/components/molecules/Selector';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';
import { ViewportBreakpoint } from '@src/libs/theme';
import DataOpacityWrapper from '@src/components/molecules/DataCard/DataOpacityWrapper';
import FacebookPageAnalyticsFeedPostDetail from '../FacebookPageAnalyticsFeedPostDetail/template';
import {
  InfluencerFacebookPageAnalyticsPostsByDate,
  InfluencerFacebookPageAnalyticsPostsByDateVariables,
  InfluencerFacebookPageAnalyticsPostsByDate_influencerFacebookPageAnalyticsPostsByDate_posts,
} from './__generated__/InfluencerFacebookPageAnalyticsPostsByDate';
import * as INFLUENCER_FB_POSTS_IN_DATE from './InfluencerFacebookPageAnalyticsPostsByDate.graphql';
import { OverviewStats } from './types';
import { getOptions } from './getDashboardOverviewOptions';
import { colors, ChartColorTypes, allChartsOrdered } from './helpers';

interface AnalyticsOverviewProps {
  overviewStats: OverviewStats | null;
  allEngagements: ChartColorTypes[];
  socialMediaAccountId: number;
  influencerAccountId?: number;
}

const AnalyticsOverview = ({
  socialMediaAccountId,
  overviewStats,
  allEngagements,
  influencerAccountId,
}: AnalyticsOverviewProps) => {
  const { t, enqueueSnackbar, i18n } = useQueryHelper();
  const client = useApolloClient();
  const [dailyPostsData, setDailyPostsData] = useState<
    InfluencerFacebookPageAnalyticsPostsByDate_influencerFacebookPageAnalyticsPostsByDate_posts[]
  >([]);
  const [{ accountName, avatarUrl }, setAccountInfo] = useState({ accountName: '', avatarUrl: '' });
  const isSmall = useMediaQuery({ query: `(min-width: ${ViewportBreakpoint.MEDIUM}px)` });

  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev,
    setOpenPostDetail,
  } = usePostDetailModal(dailyPostsData, 'overviewPopup');

  const fetchInfluencerAnalyticsDailyPosts = async (date: string) => {
    try {
      const { data } = await client.query<
        InfluencerFacebookPageAnalyticsPostsByDate,
        InfluencerFacebookPageAnalyticsPostsByDateVariables
      >({
        query: INFLUENCER_FB_POSTS_IN_DATE,
        variables: { influencerId: Number(influencerAccountId), socialAccountId: socialMediaAccountId, date },
        fetchPolicy: 'network-only',
      });
      setAccountInfo({
        accountName: data.influencerProfileV2?.name || '',
        avatarUrl: data.influencerProfileV2?.avatar || '',
      });
      setDailyPostsData(data.influencerFacebookPageAnalyticsPostsByDate.posts);
      setOpenPostDetail(data.influencerFacebookPageAnalyticsPostsByDate.posts[0]?.id || null);
    } catch (error) {
      enqueueSnackbar(t('Can not get daily posts data, please try again later '), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (dailyPostsData.length && openPostDetail) {
      handleClickPost(openPostDetail);
    }
  }, [dailyPostsData.length, openPostDetail]);

  const [selectedCharts, setSelectedCharts] = useState<ChartColorTypes[]>(['Follower', 'Post']);
  const fetchDailyPosts = fetchInfluencerAnalyticsDailyPosts;
  const { options, engagement } = overviewStats
    ? getOptions(selectedCharts, overviewStats, fetchDailyPosts, allEngagements, i18n.language)
    : { options: [] as Highcharts.Options, engagement: [] as EngagementDataType[] };

  const toggleSelectedCharts = (isSelected: boolean, name: ChartColorTypes) => {
    if (isSelected) {
      setSelectedCharts(selectedCharts.filter(selectedItem => selectedItem !== name));
    } else {
      setSelectedCharts([...selectedCharts, name]);
    }
  };

  const noData = engagement.every(item => !item.total);
  const currentOverviewPostForModal = dailyPostsData.find(dailyPost => Number(dailyPost.id) === openPostDetail);
  const sortedEngagementCheckboxes = allEngagements.sort(
    (a, b) => allChartsOrdered.indexOf(a) - allChartsOrdered.indexOf(b)
  );

  return (
    <>
      <DataOpacityWrapper dataNotAvailable={noData}>
        <Styled.EngagementGrid>
          {engagement.map(item => {
            const isPercentage = ['Engagement Rate'].includes(item.title);
            const isPositiveGrowth = item.growth === 0 ? undefined : item.growth > 0;
            const prefix = getGrowthPrefix(item.growth) || '';
            const arrow = getGrowthArrow(item.growth);
            const total = isPercentage ? formatPercent(item.total) : bigIntFormatter(item.total, 0);
            const growth = isPercentage ? formatPercent(item.growth) : bigIntFormatter(item.growth, 0);
            const hoverTotal = `${intlNumberFormat(item.total)}${isPercentage ? '%' : ''}`;
            const hoverGrowth = intlNumberFormat(item.growth);

            return (
              <Styled.EngagementGridCell key={item.title}>
                <Styled.PartWithIcon>
                  <Styled.EngagementTitle text={t(item.title)} lines={1} />
                  <Styled.EngagementTileIcon src={item.icon} />
                </Styled.PartWithIcon>

                <Styled.EngagementNumbers>
                  <Styled.TotalNumber title={hoverTotal}>{total}</Styled.TotalNumber>
                  <Styled.Growth
                    isPositive={isPositiveGrowth}
                    title={hoverGrowth}
                  >{`${prefix}${growth}  ${arrow}  (${formatPercent(item.rate)})`}</Styled.Growth>
                </Styled.EngagementNumbers>
              </Styled.EngagementGridCell>
            );
          })}
        </Styled.EngagementGrid>
        <Styled.OverviewWrapper>
          <Styled.Chart>
            <Styled.LegendBar>
              {!isSmall ? (
                <MultiSelectorWrapper
                  menuCss={css`
                    margin-top: 5px;
                    box-shadow: 0 1px 2px 0 rgba(39, 49, 59, 0.1);
                    border-radius: 3px !important;
                    width: 100%;
                  `}
                  value={selectedCharts}
                  name={''}
                  isRoundInput={false}
                  options={sortedEngagementCheckboxes.map(v => ({
                    label: t(`Label.${v}`),
                    value: v,
                  }))}
                  onChange={e => setSelectedCharts(e as ChartColorTypes[])}
                  initialValues={['Follower']}
                />
              ) : (
                sortedEngagementCheckboxes.map(item => {
                  const isChecked = selectedCharts.includes(item);
                  const color = colors[item];

                  return (
                    <Styled.LegendTileColored key={item} onClick={() => toggleSelectedCharts(isChecked, item)}>
                      <CheckBoxIcon checked={isChecked} />
                      <Styled.Text>{t(item)}</Styled.Text>
                      <span style={{ background: color }} />
                    </Styled.LegendTileColored>
                  );
                })
              )}
            </Styled.LegendBar>
            <Global
              styles={css`
                .highcharts-tooltip path {
                  fill: #27313b;
                }

                .highcharts-root {
                  padding-left: 4px;
                }
              `}
            />
            <HighChart options={options} />
          </Styled.Chart>
        </Styled.OverviewWrapper>
      </DataOpacityWrapper>
      <CarouselModal
        open={!!openPostDetail}
        title=""
        cancel="OK"
        onClose={handleClosePostDetail}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        siblingPostId={siblingPostId}
      >
        <FacebookPageAnalyticsFeedPostDetail
          closeModal={handleClosePostDetail}
          data={currentOverviewPostForModal}
          accountName={accountName}
          avatarUrl={avatarUrl}
        />
      </CarouselModal>
    </>
  );
};

const MultiSelectorWrapper = styled(MultiSelector)`
  min-height: 30px;
  max-height: 30px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

export default AnalyticsOverview;
