import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import { EngagementPostReport, EngagementPostReportVariables } from './__generated__/EngagementPostReport';
import * as ENGAGEMENT_POST_REPORT from './EngagementPostReport.graphql';
import * as MARKETPLACE_POST_REPORT from './MarketplacePostReport.graphql';
import { MarketplacePostReport, MarketplacePostReportVariables } from './__generated__/MarketplacePostReport';

export const useFetchReportBasedOnCampagnType = (isEngagement: boolean) => {
  const { id } = useParams<{ id: string }>();

  const engagementReport = useQuery<EngagementPostReport, EngagementPostReportVariables>(ENGAGEMENT_POST_REPORT, {
    variables: { campaignId: Number(id) },
    fetchPolicy: 'cache-and-network',
    skip: !isEngagement,
  });

  const marketplaceReport = useQuery<MarketplacePostReport, MarketplacePostReportVariables>(MARKETPLACE_POST_REPORT, {
    variables: { campaignId: Number(id) },
    fetchPolicy: 'cache-and-network',
    skip: isEngagement,
  });

  return {
    loading: engagementReport.loading || marketplaceReport.loading,
    data: engagementReport.data?.engagementCampaignPostReportForInfluencer || {
      ...marketplaceReport.data?.marketplaceCampaignPostReportForInfluencer,
      postReport: [marketplaceReport.data?.marketplaceCampaignPostReportForInfluencer?.postReport],
    },
    error: engagementReport.error || marketplaceReport.error,
  };
};
