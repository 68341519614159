import styled from '@emotion/styled';
import React from 'react';
import css from '@emotion/css';
import { SocialButton, PalleteButton } from '../../../atoms/Button';
import { SocialAccountType } from '../../../../__generated__/globalTypes';
import SocialAccount from '../../../molecules/SocialAccount';
import { mainWhite } from '../../../../libs/pallete';
import { capitalizeText } from '../../../../libs/text';
import {
  getAccountAvatar,
  getAccountName,
  getAccountFollowers,
  addFanPageCss,
  getAddAccountButtonText,
} from './helpers';
import { SocialAccountWithElement } from './types';

interface SocialAccountProps {
  connectedAccounts: SocialAccountWithElement[] | null;
  pricesCards?: React.ReactNode | string;
  socialMedia: SocialAccountType;
  loading?: boolean;
  onClick: () => void;
  className?: string;
}
export const SocialAccountTile: React.FC<SocialAccountProps> = props => {
  const { connectedAccounts, pricesCards, socialMedia, loading = false, onClick, className } = props;
  const isFacebookAccount = socialMedia === SocialAccountType.FACEBOOK;

  return connectedAccounts !== null ? (
    <Container className={className}>
      <>
        {connectedAccounts.map(connectedAccount => (
          <EngagementWrapper key={connectedAccount.id}>
            <StyledSocialAccount
              socialMedia={socialMedia}
              src={getAccountAvatar(connectedAccount)}
              username={getAccountName(connectedAccount)}
              followers={getAccountFollowers(connectedAccount)}
              rightElement={connectedAccount.rightElement}
            />
          </EngagementWrapper>
        ))}
        {!isFacebookAccount && (
          <StyledPalleteButton
            text={getAddAccountButtonText(socialMedia)}
            palleteColor={mainWhite}
            onClick={onClick}
            height="31px"
            textClassName={css`
              &::before {
                content: '+';
                margin-right: 2px;
              }
            `}
          />
        )}
      </>
      {socialMedia === SocialAccountType.FACEBOOK && (
        <ChooseFPButtonWrapper>
          <PalleteButton
            onClick={onClick}
            text="Add Facebook Page"
            palleteColor={mainWhite}
            width="max-content"
            height="30px"
            fontSize="12px"
            textClassName={addFanPageCss}
          />
        </ChooseFPButtonWrapper>
      )}
      {pricesCards && <CardContainer>{pricesCards}</CardContainer>}
    </Container>
  ) : (
    <SocialConnectButton
      onClick={onClick}
      socialMedia={socialMedia}
      title={`Connect ${capitalizeText(socialMedia)}`}
      disabled={loading}
      hasIcon
    />
  );
};

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px #dee5ec80;
  margin-top: 16px;
`;

const EngagementWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const CardContainer = styled.div`
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
`;

const SocialConnectButton = styled(SocialButton)`
  height: 40px;
  border-radius: 20px;
`;

const StyledSocialAccount = styled(SocialAccount)`
  padding: 16px;
  height: auto;
  box-sizing: border-box;
  border-bottom: 1px solid #efefef;
`;
const ChooseFPButtonWrapper = styled.div`
  align-self: center;
  padding: 16px;
`;
const StyledPalleteButton = styled(PalleteButton)`
  min-width: 140px;
  max-width: max-content;
  margin: 16px auto;
`;
