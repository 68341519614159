import React from 'react';
import Template from './template';
import { DraftPostDetailTabs } from './types';

interface DraftPostDetailProps {
  postId: number;
  openedTab?: DraftPostDetailTabs;
  closeModal: () => void;
}
const DraftPostDetail = (props: DraftPostDetailProps) => <Template {...props} />;

export default DraftPostDetail;
