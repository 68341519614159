import { CircularProgress } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import React from 'react';
import { useQueryHelper } from '@src/libs/hooks';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import InfluencerListTemplate from '../InfluencerListTemplate';
import { EngagementPostDetail, EngagementPostDetailVariables } from './__generated__/EngagementPostDetail';
import * as ENGAGEMENT_POST_DETAIL from './EngagementPostDetail.graphql';

interface InfluencerPostDetailProps {
  closeModal: () => void;
  postIdUrlParam?: string;
}

const InfluencerEngagementPostDetail = ({ closeModal, postIdUrlParam = 'popup' }: InfluencerPostDetailProps) => {
  const { t, enqueueSnackbar, search } = useQueryHelper();

  const urlSearchParams = new URLSearchParams(search);
  const urlPostId = Number(urlSearchParams.get(postIdUrlParam));
  const selectedSm = urlSearchParams.get('sm');

  const { data, loading, error } = useQuery<EngagementPostDetail, EngagementPostDetailVariables>(
    ENGAGEMENT_POST_DETAIL,
    {
      variables: { postId: urlPostId, limit: 10 },
      onError: err => {
        closeModal();
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
    }
  );

  if (loading) {
    return <CircularProgress size={64} thickness={2.4} css={{ margin: 'auto', color: '#dee5ec' }} />;
  } else if (error || !data?.engagementPostDetail || !urlPostId) {
    return null;
  }

  return (
    <InfluencerListTemplate
      data={{
        influencerPostDetail: {
          ...data.engagementPostDetail,
          reactions: null,
          engagement: null,
          sentimentNegativeRatio: null,
          sentimentPositiveRatio: null,
          avatar: data.engagementPostDetail.engagementPostDetailInfluencer.avatar,
          postDate: null,
          followersCount: null,
          commentsContent: data.engagementPostComments,
        },
        influencerProfileV2: {
          id: Number(data.engagementPostDetail.engagementPostDetailInfluencer.id),
          name: data.engagementPostDetail.engagementPostDetailInfluencer.name || '',
        },
      }}
      closeModal={closeModal}
      selectedSm={selectedSm as SocialAccountType}
    />
  );
};

export default InfluencerEngagementPostDetail;
