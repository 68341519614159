import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import isPropValid from '@emotion/is-prop-valid';
import { SerializedStyles } from '@emotion/utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import css from '@emotion/css';
import { PalleteColorType, mainRed, mainWhite } from '@src/libs/pallete';

interface InnerButtonProps {
  palleteColor?: PalleteColorType;
  borderRadius?: string;
  width?: string;
  height?: string;
  isDisabled?: boolean;
  fontSize?: string;
  skipUppercase?: boolean;
}

interface ButtonProps extends InnerButtonProps {
  text?: string;
  onClick?: (event?: React.SyntheticEvent<any>) => void;
  borderRadius?: string;
  className?: string;
  href?: string;
  prefixIcon?: React.ReactNode;
  postfixIcon?: React.ReactNode;
  targetBlank?: boolean;
  callback?: () => void;
  textClassName?: SerializedStyles;
  loading?: boolean;
  loadingSize?: number;
}
/**
 * render 3 types of elements based on props
 * if `href` -> react-router Link
 * if `href + targetBlank` -> normal html <a>
 * if `onClick` -> normal html <button>
 */
const PalleteButton = (props: ButtonProps) => {
  const { t } = useTranslation();
  const {
    text,
    width,
    height,
    palleteColor = mainWhite,
    borderRadius,
    onClick,
    className,
    isDisabled,
    href,
    fontSize = '12px',
    prefixIcon,
    postfixIcon,
    targetBlank,
    callback,
    textClassName,
    loading,
    loadingSize = 18,
    skipUppercase = false,
  } = props;
  const translatedText = text ? t(`Button.${text}`) : '';
  const baseButtonProps = {
    palleteColor,
    width,
    height,
    borderRadius,
    isDisabled,
    fontSize,
    className,
    skipUppercase,
  };

  const renderButtonContent = () => (
    <ButtonTextWrapper>
      {loading ? (
        <CircularProgress
          size={loadingSize}
          thickness={2.5}
          css={css`
            color: ${palleteColor.textColor};
          `}
        />
      ) : (
        prefixIcon
      )}
      {translatedText || postfixIcon ? (
        <ButtonText css={textClassName} className="pallete_text">
          {translatedText}
          {postfixIcon}
        </ButtonText>
      ) : null}
    </ButtonTextWrapper>
  );

  const renderLinkButton = () =>
    targetBlank ? (
      <ExternalLinkButton {...baseButtonProps} href={href} target="_blank" onClick={callback} rel="noopener noreferrer">
        {renderButtonContent()}
      </ExternalLinkButton>
    ) : (
      <RouterLinkButton {...baseButtonProps} to={href as string} onClick={callback}>
        {renderButtonContent()}
      </RouterLinkButton>
    );

  const renderNormalButton = () => (
    <StyledButton {...baseButtonProps} onClick={onClick}>
      <ButtonTextWrapper>
        {loading ? (
          <CircularProgress
            size={loadingSize}
            thickness={2.5}
            css={css`
              color: ${palleteColor.textColor};
            `}
          />
        ) : (
          prefixIcon
        )}
        {translatedText || postfixIcon ? (
          <ButtonText css={textClassName} className="pallete_text">
            {translatedText}
            {postfixIcon}
          </ButtonText>
        ) : null}
      </ButtonTextWrapper>
    </StyledButton>
  );

  return (
    <>
      {href && renderLinkButton()}
      {onClick && renderNormalButton()}
    </>
  );
};

const StyledButton = styled.button<InnerButtonProps>`
  ${({ palleteColor, borderRadius, width, height, isDisabled, fontSize, skipUppercase }) => `
    cursor: pointer;
    background: ${isDisabled ? palleteColor?.disable : palleteColor?.normal || mainRed.normal};
    color: ${palleteColor?.textColor || '#fff'};
    border: 1px solid ${palleteColor?.borderColor || 'transparent'};
    border-radius: ${borderRadius || '3px'};
    width: ${width || '100%'};
    line-height: calc(${height || '40px'} - 2px);
    height: ${height || '40px'};
    ${isDisabled ? 'color: #fff; pointer-events: none' : ''};
    text-align: center;
    box-sizing: border-box;
    text-transform: ${skipUppercase ? 'none' : 'uppercase'} ;
    box-sizing: border-box;
    ${fontSize && `font-size: ${fontSize}`};

    &:hover {
      background: ${palleteColor?.hover};
      border-color: ${palleteColor?.hoverBorder || 'none'};
    }
  `};
`;

const RouterLinkButton = styled(StyledButton.withComponent(Link), { shouldForwardProp: prop => isPropValid(prop) })``;
const ExternalLinkButton = styled(StyledButton.withComponent('a'))``;

const ButtonTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  /* for positioning pseudo elements from textClassName */
  position: relative;
`;

const ButtonText = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  padding: 0 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.5px;
`;

export default PalleteButton;
