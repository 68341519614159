import likeIcon from '@src/assets/icon/thumbs-up.svg';
import postIcon from '@src/assets/icon/youtubeVideo.svg';
import engagementRateIcon from '@src/assets/icon/engagementRate.svg';
import replyIcon from '@src/assets/icon/chat_green.svg';
import retweetIcon from '@src/assets/icon/network.svg';

export interface AverageEngagementTwitterProps {
  averagePostsPerWeek: number | null;
  averageLikes: number | null;
  averageReplies: number | null;
  averageRetweets: number | null;
  averageSavedGrowth?: number | null;
  engagementRate?: number | null;
}

export const getAverageEngagementIconAndTitle = (engKey: keyof AverageEngagementTwitterProps) => {
  switch (engKey) {
    case 'averagePostsPerWeek':
      return {
        title: 'Avg posts/week',
        icon: postIcon,
      };
    case 'averageLikes':
      return {
        title: 'Avg Likes',
        icon: likeIcon,
      };
    case 'engagementRate':
      return {
        title: 'HeaderColumn.Avg Engagement Rate',
        icon: engagementRateIcon,
      };
    case 'averageReplies':
      return {
        title: 'Avg Reply',
        icon: replyIcon,
      };
    case 'averageRetweets':
      return {
        title: 'Avg Retweet',
        icon: retweetIcon,
      };

    default:
      return {
        title: '',
        icon: '',
      };
  }
};
