import { ApolloError } from '@apollo/client';
import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import InitialLoading from '@src/components/molecules/InitialLoading';
import { getCallbackUrl, setToken } from '@src/libs/auth';
import { getMessagesFromApolloError, getMessagesFromFetchResult, UNEXPECTED_ERROR } from '@src/libs/error';
import { useAuthData, useQueryHelper } from '@src/libs/hooks';
import * as VERIFY_TOKEN from '@src/libs/queries/VerifyToken.graphql';
import { VerifyToken, VerifyTokenVariables } from '@src/libs/queries/__generated__/VerifyToken';
import { AnyXSocialAuthSignUpInput } from '@src/__generated__/globalTypes';
import { useAuthProviderResponse } from '../hooks';
import { useSignup } from './useSignup';

const SignUpRedirect = () => {
  const [verifyToken] = useMutation<VerifyToken, VerifyTokenVariables>(VERIFY_TOKEN);
  const { requestSignUp } = useSignup();

  const { t, enqueueSnackbar, history } = useQueryHelper();

  const { provider, response } = useAuthProviderResponse();
  const { updateAuthData } = useAuthData();

  const signUpRedirectCall = async () => {
    if (!provider) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      if (window.opener) {
        window.opener.postMessage({ redirectPath: '/', errorMsg: UNEXPECTED_ERROR }, '*');
        window.close();

        return;
      }
      history.push('/');

      return;
    }

    // Check If contain any signup agencyHash
    const agencyHash = localStorage.getItem('agencyHash');
    const agencyType = localStorage.getItem('agencyType');
    localStorage.removeItem('agencyHash');
    localStorage.removeItem('agencyType');

    // Check Profile Data
    const profileData = localStorage.getItem('profile');

    if (!profileData) {
      const failureMessage = 'failedToCreate';
      enqueueSnackbar(t(failureMessage), { variant: 'error' });
      if (window.opener) {
        window.opener.postMessage({ redirectPath: '/signup/profile', errorMsg: failureMessage }, '*');
        window.close();

        return;
      }
      history.push('/signup/profile');

      return;
    }

    // Create Account
    const profile = JSON.parse(profileData) as AnyXSocialAuthSignUpInput;

    const { token, refreshToken, errors } = await requestSignUp({
      agencyType,
      agencyHash,
      payload: {
        ...profile,
        provider,
        response,
        callbackUrl: getCallbackUrl(provider, FE_REDIRECT_MAPPING.TALENT_SIGNUP),
      },
    });

    // Error Handling
    if (!token || errors.length > 0) {
      errors.forEach((error: string) => {
        console.error(error);
        enqueueSnackbar(t(error), { variant: 'error' });
      });
      if (window.opener) {
        window.close();

        return;
      }
      history.push({
        pathname: '/signup/profile',
        state: {
          socialMedia: provider,
          name: profile.name || '',
          email: profile.email || '',
        },
      });

      return;
    }

    // setToken
    setToken(token, refreshToken);

    // VerifyToken
    await verifyToken({ variables: { input: { token } } })
      .then(props => {
        if (props?.data?.verifyToken?.ok) {
          const { creatorType, role, userId } = props.data.verifyToken;
          updateAuthData({ creatorType, role, userId });

          Sentry.setUser({
            id: String(userId),
          });
          ReactGA.set({ userId });
          history.push(`/`);
        } else {
          getMessagesFromFetchResult(props).forEach(error => {
            console.error(error);
            enqueueSnackbar(t(error), { variant: 'error' });
            if (window.opener) {
              window.opener.postMessage({ redirectPath: `/`, errorMsg: error }, '*');
              window.close();

              return;
            }
          });
          if (window.opener) {
            window.opener.postMessage({ redirectPath: `/` }, '*');
            window.close();

            return;
          }
          history.push('/');
        }
      })
      .catch((e: ApolloError) => {
        getMessagesFromApolloError(e).forEach(error => {
          console.error(error);
          enqueueSnackbar(t(error), { variant: 'error' });
          if (window.opener) {
            window.opener.postMessage({ redirectPath: `/`, errorMsg: error }, '*');
            window.close();

            return;
          }
        });
        if (window.opener) {
          window.opener.postMessage({ redirectPath: `/` }, '*');
          window.close();

          return;
        }
        history.push('/');
      });

    localStorage.removeItem('profile');

    return;
  };

  useEffect(() => {
    signUpRedirectCall();
  }, []);

  return <InitialLoading />;
};

export default SignUpRedirect;
