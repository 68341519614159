import { SerializedStyles } from '@emotion/core';
import * as React from 'react';
import * as InputText from '../../atoms/InputText';
import { TextSearchProps } from '../../atoms/InputText/TextSearch';
import Label from '../../atoms/Label';

export interface SearchFormProps extends Omit<TextSearchProps, 'onKeyPress'> {
  className?: string;
  title?: string;
  isRequired?: boolean;
  help?: string;
  onEnterKeyPress?: () => void;
  inputStyle?: SerializedStyles;
}

const SearchForm = (props: SearchFormProps) => {
  const {
    className,
    title,
    isRequired,
    help,
    placeholder,
    inputStyle,
    value,
    name,
    onBlur,
    onChange,
    onEnterKeyPress,
    autoFocus,
    disabled,
  } = props;
  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!!onEnterKeyPress && e.charCode === 13) {
      onEnterKeyPress();
    }
  };

  return (
    <div className={className}>
      {!!title && <Label title={title} isRequired={isRequired} help={help} />}
      <InputText.TextSearch
        placeholder={placeholder}
        value={value}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onKeyPress={onKeyPress}
        inputStyle={inputStyle}
        autoFocus={autoFocus}
        disabled={disabled}
      />
    </div>
  );
};

export default SearchForm;
