import React from 'react';
import { useLocation } from 'react-router';
import { useUpdateDocumentTitle } from '@src/libs/hooks';
import FeedPostList from '@src/components/organisms/PostFeedList_backup';
import { BasePostFilterType } from './types';
interface TemplateProps {
  accountId: number;
  currentPage: number;
  filter: BasePostFilterType;
  hasTalentAgencyQuery?: boolean;
  hasPartnerQuery?: boolean;
  setFilter: (filter: BasePostFilterType) => void;
  goBackUrl?: string;
}

const Template = (props: TemplateProps) => {
  const { search } = useLocation();

  useUpdateDocumentTitle({
    title: 'documentTitle.Post List',
    pageTitle: 'Post List',
    href: `/profile${search}`,
  });

  return <FeedPostList {...props} />;
};

export default Template;
