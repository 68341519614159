import { SocialAccountType } from '@src/__generated__/globalTypes';
import { useState, useLayoutEffect } from 'react';
import { ViewportBreakpoint } from '@src/libs/theme';

export const useThumbnailDimensionsOnResize = (accountType: SocialAccountType) => {
  const [dimensions, setDimensions] = useState(['auto', 'auto']);

  function updateDimensions() {
    const isMobile = window.innerWidth <= ViewportBreakpoint.MOBILE;
    const [width, height] = isMobile ? ['295px', '295px'] : ['400px', '400px'];
    const [ytWidth, ytHeight] = isMobile ? ['100%', '225px'] : ['400px', '225px'];
    if (accountType === SocialAccountType.YOUTUBE) {
      setDimensions([ytWidth, ytHeight]);
    } else {
      setDimensions([width, height]);
    }
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', updateDimensions);
    updateDimensions();

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return dimensions;
};
