import React from 'react';
import SignInForm from '@src/components/organisms/SignInForm';
import TopPageContainer from '@src/components/organisms/TopPage/TopPageContainer';

const FailedConnect = () => (
  <TopPageContainer isSignIn showBgCover>
    <SignInForm failedConnect />
  </TopPageContainer>
);

export default FailedConnect;
