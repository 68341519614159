import { useField, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SubmitButton } from '@src/components/atoms/Button';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import Grid from '@src/components/atoms/Grid';
import TextForm from '@src/components/molecules/TextForm';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { ViewportType } from '@src/libs/theme';

export interface AddressInformation {
  address: string;
  address2: string;
  city: string;
  fullName: string;
  postalCode: string;
  province: string;
}

const Address = () => {
  const { t } = useTranslation();
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });
  const { isSubmitting, handleSubmit, setFieldValue } = useFormikContext<AddressInformation>();

  const [address, addressMeta] = useField('address');
  const [address2, address2Meta] = useField('address2');
  const [city, cityMeta] = useField('city');
  const [name, nameMeta] = useField('fullName');
  const [postalCode, postalCodeMeta] = useField('postalCode');
  const [province, provinceMeta] = useField('province');

  return (
    <div css={styles.container}>
      {isDesktopView && <BackNavigator to="/settings" title="Address" />}

      <div css={styles.description}>{t('Annotation.View and edit the address information to ship products')}</div>

      <form onSubmit={handleSubmit}>
        <div css={styles.formContainer}>
          <Grid md={8} sm={10} xs={12}>
            <div css={styles.formInputSection}>
              <InputContainer>
                <StyledTextForm
                  error={!!nameMeta.error}
                  isRequired
                  title="Settings Address Full Name"
                  placeholder="Full Name"
                  value={name.value || ''}
                  onChange={e => setFieldValue('fullName', e.target.value)}
                />
                {nameMeta.error && <ErrorMessage message={t(nameMeta.error)} />}
              </InputContainer>

              <InputContainer>
                <StyledTextForm
                  error={!!postalCodeMeta.error}
                  isRequired
                  title="Postal Code"
                  placeholder="Postal Code"
                  value={postalCode.value || ''}
                  onChange={e => setFieldValue('postalCode', e.target.value)}
                />
                {postalCodeMeta.error && <ErrorMessage message={t(postalCodeMeta.error)} />}
              </InputContainer>

              <InputContainer>
                <StyledTextForm
                  error={!!provinceMeta.error}
                  isRequired
                  title="Province"
                  placeholder="Province"
                  value={province.value || ''}
                  onChange={e => setFieldValue('province', e.target.value)}
                />
                {provinceMeta.error && <ErrorMessage message={t(provinceMeta.error)} />}
              </InputContainer>

              <InputContainer>
                <StyledTextForm
                  error={!!cityMeta.error}
                  isRequired
                  title="City"
                  placeholder="City"
                  value={city.value || ''}
                  onChange={e => setFieldValue('city', e.target.value)}
                />
                {cityMeta.error && <ErrorMessage message={t(cityMeta.error)} />}
              </InputContainer>

              <InputContainer>
                <StyledTextForm
                  error={!!addressMeta.error}
                  isRequired
                  title="Address"
                  placeholder="Address"
                  value={address.value || ''}
                  onChange={e => setFieldValue('address', e.target.value)}
                />
                {addressMeta.error && <ErrorMessage message={t(addressMeta.error)} />}
              </InputContainer>

              <InputContainer>
                <StyledTextForm
                  error={!!provinceMeta.error}
                  isRequired
                  title="Apartment, suite, etc"
                  placeholder="Apartment, Suite, etc"
                  value={address2.value || ''}
                  onChange={e => setFieldValue('address2', e.target.value)}
                />
                {address2Meta.error && <ErrorMessage message={t(address2Meta.error)} />}
              </InputContainer>
            </div>

            <div css={styles.formActionSection}>
              <SubmitButton disabled={isSubmitting} value="Save" />
            </div>
          </Grid>
        </div>
      </form>
    </div>
  );
};

const InputContainer = styled.div`
  margin-bottom: 24px;
`;

const StyledTextForm = styled(TextForm)`
  & > label {
    font-size: 14px;
  }

  & input {
    border-radius: 2px;
    height: 32px;
  }
`;

const styles = {
  container: css`
    margin: 16px 24px;

    @media (max-width: ${ViewportType.TABLET}px) {
      margin: 16px;
    }
  `,
  description: css`
    color: #27313b;
    font-size: 14px;
    margin-bottom: 24px;
    margin-left: 48px;

    @media (max-width: ${ViewportType.TABLET}px) {
      margin-left: 0;
    }
  `,
  formActionSection: css`
    background-color: #fff;
    border-top: 1px solid #eef3f7;
    display: flex;
    justify-content: flex-end;
    padding: 16px;

    /* stylelint-disable */
    & input {
      border-radius: 3px;
      width: 77px;

      @media (max-width: ${ViewportType.SMALL}px) {
        width: 100%;
      }
    }
    /* stylelint-enable */
  `,
  formContainer: css`
    display: flex;
    justify-content: center;
  `,
  formInputSection: css`
    background-color: #fff;
    padding: 16px;
  `,
};

export default Address;
