import React, { useState, useEffect } from 'react';
import { Global, css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import HighChart from '@src/components/molecules/HighChart';
import DataOpacityWrapper from '@src/components/molecules/DataCard/DataOpacityWrapper';
import * as Styled from '@src/components/organisms/AnalyticsShared/Overview/StyledComponents';
import styled from '@emotion/styled';
import { MultiSelector } from '@src/components/molecules/Selector';
import { ViewportBreakpoint } from '@src/libs/theme';
import { useContainerQuery } from '@src/libs/hooks';
import CheckBoxIcon from '@src/components/atoms/Icon/CheckBoxIcon';
import { useMediaQuery } from 'react-responsive';
import ComparableAccountsTable from './AccountsTable';
import { AccountInfo, AvatarAndNameType } from './types';
import {
  getOptions,
  allYtCompareCharts,
  YtCompareChartSeriesTypes,
  getCompareEngagementData,
  composeComparableAccountData,
  HIDDEN_KEY,
} from './compareHelpers';
import { ComparableAccountsSelectors } from './ComparableAccountsSelectors';
import { InfluencerYoutubeCompareOverview_influencerYoutubeCompareOverview } from './__generated__/InfluencerYoutubeCompareOverview';
import ComparableAccountsTableDesktop from './AccountsTableDesktop';

interface YtCompareOverViewType {
  compareOverviewData: InfluencerYoutubeCompareOverview_influencerYoutubeCompareOverview | null | undefined;
  accountsAvatarAndName: AvatarAndNameType[];
  visibleAccountsIds: number[];
  id: number;
  isMainAccountVisible: boolean;
}
const YtCompareOverView = ({
  compareOverviewData,
  accountsAvatarAndName,
  visibleAccountsIds,
  isMainAccountVisible,
}: YtCompareOverViewType) => {
  const { visibleAccountsData, allComparableAccountsData } = composeComparableAccountData(
    accountsAvatarAndName,
    isMainAccountVisible,
    visibleAccountsIds,
    compareOverviewData
  );
  const { isSmaller: isMobile, containerRef } = useContainerQuery({ hasWidthSmallerThan: ViewportBreakpoint.MOBILE });
  const { t, i18n } = useTranslation();
  const firstFiveAccountsForGraph = accountsAvatarAndName.slice(0, 5);
  const [uniqIdForGraph, setUniqIdForGraph] = useState<string[]>(firstFiveAccountsForGraph.map(acc => acc.uniqId));
  const isDesktop = useMediaQuery({ minWidth: ViewportBreakpoint.DESKTOP });

  // Recalculate selected dropdowns options on hide/show accounts
  useEffect(() => {
    setUniqIdForGraph(firstFiveAccountsForGraph.map(acc => acc.uniqId));
  }, [visibleAccountsData.length]);

  // Remove empty rows
  const selectedAccountsUniqIds = uniqIdForGraph.filter(uniqId => !uniqId.includes(HIDDEN_KEY));

  const [selectedCharts, setSelectedCharts] = useState<YtCompareChartSeriesTypes[]>(['Subscribers', 'Post']);
  const { options } = compareOverviewData
    ? getOptions(
        selectedCharts,
        uniqIdForGraph.map(uniqId =>
          !uniqId.includes(HIDDEN_KEY)
            ? ({
                ...accountsAvatarAndName.find(av => av.uniqId === uniqId),
                ...visibleAccountsData.find(acc => acc.uniqId === uniqId),
              } as AccountInfo)
            : null
        ),
        // since it is quite hard to fit compareModal into small viewport we don't need it unless we have mobile design
        () => null,
        i18n.language,
        isMobile
      )
    : { options: [] as Highcharts.Options };
  const engagement = getCompareEngagementData(visibleAccountsData, accountsAvatarAndName);

  const toggleSelectedCharts = (newSelectedCharts: YtCompareChartSeriesTypes[]) => {
    if (newSelectedCharts.includes('Subscribers') && newSelectedCharts.length === 3) {
      setSelectedCharts(['Subscribers', newSelectedCharts[2]]);

      return;
      // can select only one of 'Post' | 'View' | 'Like' | 'Comment' | 'Dislike'
    } else if (newSelectedCharts.length === 2 && !newSelectedCharts.includes('Subscribers')) {
      return;
    }

    setSelectedCharts(newSelectedCharts);
  };
  const toggleSelectedChartsDesktop = (
    isSelected: boolean,
    name: YtCompareChartSeriesTypes,
    isSubscriberItem: boolean
  ) => {
    if (isSelected) {
      setSelectedCharts(selectedCharts.filter(selectedItem => selectedItem !== name));
    } else if (isSubscriberItem) {
      setSelectedCharts([...selectedCharts, name]);
    } else {
      // can select only one of 'Post' | 'Like' | 'Commnet' | 'Engagement'
      setSelectedCharts(selectedCharts.includes('Subscribers') ? ['Subscribers', name] : [name]);
    }
  };

  const noVisibleData = !selectedAccountsUniqIds.length || !visibleAccountsData.length;
  const noData = !compareOverviewData || !allComparableAccountsData.length || noVisibleData;

  return (
    <>
      <Styled.Chart ref={containerRef}>
        {!noData && isDesktop ? (
          <div css={styles.compareMetricsWrapper}>
            {allYtCompareCharts.map(item => {
              const isChecked = selectedCharts.includes(item);
              const isSubscriberItem = item === 'Subscribers';

              return (
                <Styled.LegendTile
                  key={item}
                  onClick={() => toggleSelectedChartsDesktop(isChecked, item, isSubscriberItem)}
                  css={
                    isSubscriberItem
                      ? css`
                          padding-right: 10px;
                          border-right: 1px solid #dee5ef;
                        `
                      : []
                  }
                >
                  <CheckBoxIcon checked={isChecked} />
                  <Styled.Text>{t(item)}</Styled.Text>
                </Styled.LegendTile>
              );
            })}
          </div>
        ) : null}
        {/* accounts dropdowns */}
        <ComparableAccountsSelectors
          accountsAvatarAndName={accountsAvatarAndName}
          uniqIdForGraph={uniqIdForGraph}
          setUniqIdForGraph={setUniqIdForGraph}
        />

        {!isDesktop ? (
          <Styled.LegendBar>
            <SelectorWrapper>
              <MultiSelectorWrapper
                hasSelectAll={false}
                css={{ width: '300px' }}
                menuCss={styles.menuCss}
                value={selectedCharts}
                name={''}
                isRoundInput={false}
                options={allYtCompareCharts.map(v => ({
                  label: t(`Label.${v}`),
                  value: v,
                }))}
                onChange={e => toggleSelectedCharts(e as YtCompareChartSeriesTypes[])}
              />
            </SelectorWrapper>
          </Styled.LegendBar>
        ) : null}

        <DataOpacityWrapper dataNotAvailable={noData} css={styles.opacityWrapper}>
          <Global styles={styles.globalChart} />
          <HighChart options={options} />
        </DataOpacityWrapper>
      </Styled.Chart>
      {isDesktop ? (
        <ComparableAccountsTableDesktop engagement={engagement} />
      ) : (
        <ComparableAccountsTable engagement={engagement} />
      )}
    </>
  );
};

const styles = {
  menuCss: css`
    margin-top: 5px;
    box-shadow: 0 1px 2px 0 rgba(39, 49, 59, 0.1);
    border-radius: 3px !important;
  `,
  opacityWrapper: css`
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid #dee5ef;
    padding: 0;
  `,
  globalChart: css`
    .highcharts-tooltip path {
      fill: #27313b;
    }

    .highcharts-root {
      padding-left: 4px;
    }
  `,
  compareMetricsWrapper: css`
    display: flex;
    padding: 16px;
  `,
};

const SelectorWrapper = styled.div`
  padding: 8px;
`;
const MultiSelectorWrapper = styled(MultiSelector)`
  min-height: 30px;
  max-height: 30px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

export default YtCompareOverView;
