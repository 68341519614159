import { FacebookPagePosts } from '@src/components/organisms/AnalyticsFacebookPage/types';
import { FacebookPageAnalyticsPostsSortOrder } from '@src/__generated__/globalTypes';

export const getFacebookPageFeedSortFieldType = (field?: string | null) => {
  if (!field) {
    return null;
  }

  return FacebookPageAnalyticsPostsSortOrder[field as keyof typeof FacebookPageAnalyticsPostsSortOrder];
};

export const getFacebookPageFeedListColumns = () => [
  {
    title: 'Impression',
    orderField: FacebookPageAnalyticsPostsSortOrder.AD_IMPRESSIONS,
  },
  {
    title: 'views',
    orderField: FacebookPageAnalyticsPostsSortOrder.VIEWS,
  },
  {
    title: 'Engagement',
    orderField: FacebookPageAnalyticsPostsSortOrder.ENGAGEMENT,
  },
  {
    title: 'Reaction',
    orderField: FacebookPageAnalyticsPostsSortOrder.REACTIONS,
  },
  {
    title: 'Comment',
    orderField: FacebookPageAnalyticsPostsSortOrder.COMMENT,
  },
  {
    title: 'Positive Rate',
    orderField: FacebookPageAnalyticsPostsSortOrder.POSITIVE_RATE,
  },
  {
    title: 'Negative Rate',
    orderField: FacebookPageAnalyticsPostsSortOrder.NEGATIVE_RATE,
  },
  {
    title: 'Share',
    orderField: FacebookPageAnalyticsPostsSortOrder.SHARE,
  },
  {
    title: 'Post date',
    orderField: FacebookPageAnalyticsPostsSortOrder.POST_DATE,
  },
];

export const getPostMetricsByOrderField = (
  order: FacebookPageAnalyticsPostsSortOrder
): keyof FacebookPagePosts | null => {
  switch (order) {
    case FacebookPageAnalyticsPostsSortOrder.AD_IMPRESSIONS:
      return 'adImpressions';
    case FacebookPageAnalyticsPostsSortOrder.COMMENT:
      return 'comments';
    case FacebookPageAnalyticsPostsSortOrder.ENGAGEMENT:
      return 'engagement';
    case FacebookPageAnalyticsPostsSortOrder.NEGATIVE_RATE:
      return 'negativeRate';
    case FacebookPageAnalyticsPostsSortOrder.POSITIVE_RATE:
      return 'positiveRate';
    case FacebookPageAnalyticsPostsSortOrder.POST_DATE:
      return 'postDate';
    case FacebookPageAnalyticsPostsSortOrder.REACTIONS:
      return 'reactions';
    case FacebookPageAnalyticsPostsSortOrder.SHARE:
      return 'shares';
    case FacebookPageAnalyticsPostsSortOrder.VIEWS:
      return 'views';

    default:
      return null;
  }
};
