import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import styled from '@emotion/styled';
import { LocationDescriptor } from 'history';
import * as React from 'react';
import Logo from '@src/components/atoms/Logo';
import { useQueryHelper } from '@src/libs/hooks';
import { SignUpAndSignInBreakpoint } from '@src/libs/theme';
import { useUuumInfluencerView } from '@src/libs/hooks';

interface TopPageHeaderProps {
  className?: string;
  href?: LocationDescriptor;
}

const TopPageHeader = (props: TopPageHeaderProps) => {
  const { className, href } = props;
  const { pathname, t } = useQueryHelper();
  const { isUuumView } = useUuumInfluencerView();
  const isTabletView = useMediaQuery({ query: `(max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px)` });

  const path = pathname.split('/')[1];

  return (
    <Header
      className={className}
      style={!['', 'reconnect', 'signin', 'signup'].includes(path) && isTabletView ? { justifyContent: 'center' } : {}}
    >
      {!isUuumView ? (
        <>
          <AppLogo href={href} width="165px" />
          <SignInUpWrapper>
            {['', 'reconnect', 'signin'].includes(path) ? (
              <>
                <span>{t(`Don't have an account?`)}</span>
                <Link to="/signup">{t('Sign up')}</Link>
              </>
            ) : ['signup'].includes(path) ? (
              <>
                <span>{t('Already have an account?')}</span>
                <Link to="/signin">{t('Sign in')}</Link>
              </>
            ) : null}
          </SignInUpWrapper>
        </>
      ) : null}
    </Header>
  );
};

const Header = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 0;
  justify-content: space-between;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    height: 48px;
  }
`;

const AppLogo = styled(Logo)`
  margin: 0;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    width: 145px;
  }
`;

const SignInUpWrapper = styled.div`
  display: flex;

  & a {
    color: #3892e5;
    margin-left: 8px;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    & span {
      display: none;
    }
  }
`;

export default TopPageHeader;
