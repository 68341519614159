import styled from '@emotion/styled';
import * as React from 'react';

interface ErrorMessageProps {
  className?: string;
  message: string;
}

const ErrorMessage = (props: ErrorMessageProps) => {
  const { className, message } = props;

  return <Message className={className}>{message}</Message>;
};

const Message = styled.p`
  padding: 2px 0 0 2px;
  color: tomato;
  font-size: 11px;
`;

export default ErrorMessage;
