import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import HighChart, { Highcharts } from '@src/components/molecules/HighChart';
import { formatPercent } from '@src/libs/format';
import DataWrapper, { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import MoreButton from '@src/components/atoms/MoreButton';
import { DialogModal } from '@src/components/molecules/DialogModal';
import { mainWhite } from '@src/libs/pallete';
import { PalleteButton } from '@src/components/atoms/Button';
import { useTranslateCountry } from '@src/libs/hooks/useTranslateOptions';
import { ALL_COUNTRIES_WITH_CODE } from '@src/libs/countries';
import { css } from '@emotion/react';
import { SingleSelector } from '@src/components/molecules/Selector';
import { FollowerRegionRate, FollowerCountryRate } from './types';

export interface AreaBreakdownProps {
  followerRegionRate: FollowerRegionRate[];
  followerCountryRate: FollowerCountryRate[];
  className?: string;
  hasCountriesRates?: boolean;
}
type ChartType = 'regions' | 'countries';

const AreaBreakdown = ({
  followerRegionRate,
  followerCountryRate,
  hasCountriesRates,
  className,
}: AreaBreakdownProps) => {
  const { t } = useTranslation();
  const { tc } = useTranslateCountry();
  const [chartType, setChartType] = useState<ChartType>('regions');

  const { regionsRates, regions } = followerRegionRate?.reduce<{ regionsRates: number[]; regions: string[] }>(
    (acc, curr) => ({ regionsRates: [...acc.regionsRates, curr.rate], regions: [...acc.regions, t(curr.name)] }),
    { regionsRates: [], regions: [] }
  );
  const { countriesRates, countries } = followerCountryRate?.reduce<{ countriesRates: number[]; countries: string[] }>(
    (acc, curr) => ({ countriesRates: [...acc.countriesRates, curr.rate], countries: [...acc.countries, t(curr.id)] }),
    { countriesRates: [], countries: [] }
  );

  const [regionPopupOpen, setRegionPopupOpen] = useState<boolean>(false);

  useEffect(() => {
    if (hasCountriesRates && !followerRegionRate.length) {
      setChartType('countries');
    }
  }, []);

  const countriesNamesFromCode = countries.map(c =>
    tc(ALL_COUNTRIES_WITH_CODE.find(el => el.code === c)?.name || '')
  ) as string[];
  const categories = chartType === 'regions' ? regions : countriesNamesFromCode;
  const seriesData = chartType === 'regions' ? regionsRates : countriesRates;
  const dataNotAvailable = regionsRates.every(rate => rate === 0) && countriesRates.every(rate => rate === 0);

  const chartOptions = (showAll?: boolean): Highcharts.Options => ({
    chart: {
      type: 'bar',
      height: showAll ? null : 265,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: showAll ? categories : categories.slice(0, 7),
      title: {
        text: null,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: '',
      },
      labels: {
        overflow: 'justify',
        formatter() {
          return formatPercent(this.value, true, 0);
        },
      },
    },
    tooltip: {
      formatter() {
        return `
          <div style="border-radius:3px;display:flex;align-items:center;padding:13px 16px">
          <span style="display:inline-block;margin-right:8px;background-color:${
            this.point.color
          };height:12px;width:12px"></span>
            <span style="margin-right:16px">${t(this.x.toString())}</span>
            <span>${formatPercent(this.y)}</span>
          </div>`;
      },
      padding: 0,
      borderColor: 'green',
      borderWidth: 0,
      borderRadius: 3,
      backgroundColor: '#27313b',
      style: {
        color: '#ffffff',
      },
      useHTML: true,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: false,
        },
        showInLegend: false,
      },
    },

    credits: {
      enabled: false,
    },
    series: [
      {
        data: showAll ? seriesData : seriesData.slice(0, 7),
        color: '#9ea0e0',
        type: 'bar',
      },
    ],
    legend: {
      symbolHeight: 15,
      symbolWidth: 15,
      symbolRadius: 2,
      itemStyle: {
        fontSize: '13px',
        color: '#27313b',
        fontWeight: 'normal',
      },
    },
  });
  const options = [
    { value: 'regions', label: t('Selector.Region') },
    ...(hasCountriesRates ? [{ value: 'countries', label: t('Select.Country') }] : []),
  ];
  const HeaderWithDropdown = (
    <div css={styles.headerWrapper}>
      <SectionHeader>{t('Area Breakdown')}</SectionHeader>
      {hasCountriesRates && !dataNotAvailable ? (
        <SingleSelector
          name="Region|Country"
          value={chartType}
          isRoundInput={false}
          hideDeselectOption
          hideInput
          onChange={el => setChartType(el as ChartType)}
          options={options}
          dropdownCss={styles.dropdownCss}
          css={styles.selectorCss}
          menuCss={styles.menuCss}
        />
      ) : null}
    </div>
  );

  return (
    <DataWrapper header={HeaderWithDropdown} dataNotAvailable={dataNotAvailable} className={className}>
      <HighChart options={chartOptions()} />
      <SeeMore onClick={() => setRegionPopupOpen(true)} />
      <DialogModal
        BackButton={<PalleteButton text="Close" palleteColor={mainWhite} onClick={() => setRegionPopupOpen(false)} />}
        isOpen={regionPopupOpen}
        closeModal={() => setRegionPopupOpen(false)}
      >
        <div css={{ margin: 12 }}>{HeaderWithDropdown}</div>
        <HighChart options={chartOptions(true)} />
      </DialogModal>
    </DataWrapper>
  );
};

const styles = {
  headerWrapper: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  dropdownCss: css`
    width: auto;
  `,
  selectorCss: css`
    width: 130px;
    height: 30px;
    min-height: 30px;
    border: 1px solid #dee5ec;
    margin-left: 0;

    & .selected_values {
      top: 6px;
    }
  `,
  menuCss: css`
    width: 130px;
    border: none;
  `,
};

const SeeMore = styled(MoreButton)`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

export default AreaBreakdown;
