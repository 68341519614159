import profileIcon from '@src/assets/icon/profileMenu.svg';
import searchJobIcon from '@src/assets/icon/binocular.svg';
import paymentIcon from '@src/assets/icon/payment.svg';
import notificationIcon from '@src/assets/icon/notification.svg';
import yourJobIcon from '@src/assets/icon/yourJob.svg';
import helpIcon from '@src/assets/icon/help.svg';
import signOutIcon from '@src/assets/icon/signout.svg';
import warningIcon from '@src/assets/icon/warning.svg';
import closeIcon from '@src/assets/icon/close.svg';

export type IconName =
  | 'yourJob'
  | 'searchJob'
  | 'payment'
  | 'notification'
  | 'profile'
  | 'help'
  | 'signOut'
  | 'warning'
  | 'close'
  | undefined;

export const getIconByName = (name: IconName) => {
  switch (name) {
    case 'yourJob':
      return yourJobIcon;
    case 'searchJob':
      return searchJobIcon;
    case 'payment':
      return paymentIcon;
    case 'notification':
      return notificationIcon;
    case 'profile':
      return profileIcon;
    case 'help':
      return helpIcon;
    case 'signOut':
      return signOutIcon;
    case 'warning':
      return warningIcon;
    case 'close':
      return closeIcon;

    default:
      return undefined;
  }
};
