import React, { useEffect, useRef, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import eyeIcon from '@src/assets/icon/eye.svg';
import { Button } from '@src/components/atoms/Button';
import Grid from '@src/components/atoms/Grid';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { Tooltip } from '@src/components/atoms/Tooltip';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import Form from '@src/components/organisms/Forms/Form';
import ShareButton from '@src/components/organisms/Forms/ShareButton';
import StatusIndicator from '@src/components/organisms/Forms/StatusIndicator';
import FormReport from '@src/components/organisms/FormReport';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import { formState, submitFormState, useRecoilValue, useSetRecoilState } from '@src/recoilAtoms';
import { FormStatus } from '@src/__generated__/globalTypes';
import useGetForm from './useGetForm';

const EditForm = () => {
  const backButtonRef = useRef<HTMLButtonElement>(null);
  const previewButtonRef = useRef<HTMLButtonElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const { history, params, pathname, t } = useQueryHelper();
  // form is able to draft edit/preview without saving, using recoil to save the draft information
  const formInformation = useRecoilValue(formState);
  const setFormState = useSetRecoilState(formState);
  const setSubmitFormState = useSetRecoilState(submitFormState);
  const { isMobileView } = usePageLayout();
  const formId = params.id;
  const entryPath = `/forms/${formId}/entry`;
  const tabs = [
    { text: 'Entry', to: entryPath },
    { text: 'Report', to: `/forms/${formId}/report` },
  ];
  const isEntryTab = pathname === `/forms/${formId}/entry`;
  const {
    description,
    hash,
    id,
    isLoading,
    questions,
    respondentCount,
    status,
    thankDescription,
    thankTitle,
    title,
  } = useGetForm({
    formId,
  });
  // check if current form is in drafting; if yes, using draft information instead of DB information
  const isEditing = formInformation?.id === formId;

  useEffect(() => {
    // to reset the draft information if the information is not related to current selected form
    if (!isEditing) {
      setFormState(null);
      setSubmitFormState(null);
    }
  }, []);

  const onClickBack = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    if (backButtonRef.current) {
      backButtonRef.current.click();
    } else {
      history.push('/forms');
    }
  };

  const onClickPreview = (e: SyntheticEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (previewButtonRef.current) {
      previewButtonRef.current.click();
    } else {
      history.push(`/forms/${formId}/live_preview`);
    }
  };

  const onClickTab = (to: string) => {
    if (to !== entryPath) {
      // to reset the draft information if move to another tab except entry tab
      setFormState(null);
      setSubmitFormState(null);
    }
  };

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <div css={styles.navigatorContainer}>
          <div>
            <BackNavigator title="Edit Form" to="/forms" onClick={onClickBack} />
            {!!id && <StatusIndicator status={status as FormStatus} />}
          </div>
          <div>
            <Tooltip help="preview">
              <div css={styles.previewButton} onClick={onClickPreview}>
                <img alt="icon" height="16" src={eyeIcon} width="16" />
              </div>
            </Tooltip>
            <Button
              bgcolor="#3892e5"
              css={styles.saveBtn}
              disabled={!isEntryTab}
              hoverbgcolor="#3892e5"
              title="Save"
              onClick={() => submitButtonRef?.current?.click()}
            />
            <div css={styles.divider} />
            <ShareButton hash={hash} id={formId} status={status as FormStatus} />
          </div>
        </div>
      )}

      <div css={styles.infoContainer}>
        <Grid md={8} sm={10} xs={12}>
          <div css={styles.tabsContainer}>
            {tabs.map(tab => {
              const { text, to } = tab;
              const isActive = pathname.includes(to);

              return (
                <Tab isActive={isActive} key={to} to={to} onClick={() => onClickTab(to)}>
                  {t(text)}
                </Tab>
              );
            })}
          </div>

          {isEntryTab ? (
            isLoading ? (
              <ListIndicator />
            ) : (
              <>
                <NoAvailableFeatureContent isVisible={isMobileView}>
                  <div>
                    <div>{t('Annotation.This feature is only available on PCs')}</div>
                    <Link to="/forms">{t('Anchor.Back')}</Link>
                  </div>
                </NoAvailableFeatureContent>
                <ContentContainer isVisible={!isMobileView}>
                  <Form
                    description={isEditing ? formInformation?.description || '' : description}
                    refs={{
                      backButtonRef,
                      previewButtonRef,
                      submitButtonRef,
                    }}
                    id={isEditing ? formInformation?.id || '' : id}
                    isFormAnswerSubmitted={respondentCount > 0}
                    questions={isEditing ? formInformation?.questions || [] : questions}
                    status={isEditing ? formInformation?.status || FormStatus.DRAFT : status}
                    thankDescription={isEditing ? formInformation?.thankDescription || '' : thankDescription}
                    thankTitle={isEditing ? formInformation?.thankTitle || '' : thankTitle}
                    title={isEditing ? formInformation?.title || '' : title}
                  />
                </ContentContainer>
              </>
            )
          ) : (
            <FormReport />
          )}
        </Grid>
      </div>
    </div>
  );
};

const ContentContainer = styled.div<{ isVisible: boolean }>`
  background-color: #fff;
  padding: 24px;
  ${({ isVisible }) => (!isVisible ? 'display: none;' : '')}
`;

const NoAvailableFeatureContent = styled.div<{ isVisible: boolean }>`
  align-items: center;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  height: 88px;
  justify-content: center;
  text-align: center;

  & > div {
    & > div:nth-of-type(1) {
      color: #000;
      font-size: 14px;
      margin-bottom: 8px;
    }

    & > a {
      color: #3892e5;
      font-size: 14px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const Tab = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{ isActive: boolean }>`
  border-bottom: 4px solid ${({ isActive }) => (isActive ? '#3892e5' : '#fff')};
  color: #27313b;
  cursor: pointer;
  font-size: 14px;
  font-weight: ${({ isActive }) => (isActive ? '600' : '400')};
  margin: 0 16px;
  padding: 16px 0;
  width: fit-content;
`;

const styles = {
  container: css`
    margin: 16px 24px;

    @media (max-width: ${ViewportType.TABLET}px) {
      margin: 16px;
    }
  `,
  divider: css`
    border-left: 1px solid #dee5ec;
    height: 32px;
    margin: 0 8px;
  `,
  infoContainer: css`
    display: flex;
    justify-content: center;
    margin-top: 16px;
  `,
  navigatorContainer: css`
    display: flex;
    flex-wrap: wrap;

    /* stylelint-disable no-descending-specificity */
    & > div:nth-of-type(1) {
      align-items: center;
      display: flex;
      flex-basis: 50%;

      & > div:nth-of-type(2) {
        margin-left: 8px;
      }
    }

    & > div:nth-of-type(2) {
      display: flex;
      flex-basis: 50%;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  `,
  previewButton: css`
    align-items: center;
    background-color: #fff;
    border: 1px solid #dee5ec;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    height: 32px;
    justify-content: center;
    margin-right: 10px;
    width: 32px;
    box-sizing: border-box;
  `,
  saveBtn: css`
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
    height: 32px;
    width: 77px;
  `,
  shareBtn: css`
    border-radius: 3px;
    height: 32px;
    width: 96px;
  `,
  tabsContainer: css`
    background-color: #fff;
    border-bottom: 1px solid #edf2f5;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    display: flex;
    flex-wrap: wrap;
    padding: 0 8px;
  `,
};

export default EditForm;
