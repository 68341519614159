import { Formik } from 'formik';
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import yup, {
  BirthdaySchema,
  // BrandSchema,
  CategoriesSchema,
  CountrySchema,
  GenderSchema,
  NameSchema,
  RegionSchema,
} from '@src/libs/validation';
import { Genders } from '@src/__generated__/globalTypes';
import GET_ACCOUNT_INFORMATION from './queries/GetAccountInformation.graphql';
import UPDATE_ACCOUNT_INFORMATION from './mutations/UpdateAccountInformation.graphql';
import { GetAccountInformation } from './queries/__generated__/GetAccountInformation';
import { UpdateAccountInformationVariables } from './mutations/__generated__/UpdateAccountInformation';
import CreatorAccount, { AccountInformation } from './CreatorAccount';

const Index = () => {
  const { enqueueSnackbar, t } = useQueryHelper();

  const validationSchema = yup.object().shape({
    // brandName: BrandSchema,
    categoryIds: CategoriesSchema,
    countryId: CountrySchema,
    dateOfBirth: BirthdaySchema,
    gender: GenderSchema,
    name: NameSchema,
    regionId: RegionSchema,
  });

  const { data, loading } = useQuery<GetAccountInformation>(GET_ACCOUNT_INFORMATION, {
    fetchPolicy: 'no-cache',
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [updateAccountInformation] = useMutation<UpdateAccountInformationVariables>(UPDATE_ACCOUNT_INFORMATION, {
    onCompleted: () => {
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  function onSubmit(values: AccountInformation) {
    updateAccountInformation({
      variables: {
        // brandName exclude for the first release but is required for API
        input: { ...values, brandName: '' },
      },
    });
  }

  const accountInfo = data?.getAccountInformation;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        brandName: accountInfo?.brandName || '',
        categoryIds: accountInfo?.categoryIds || [],
        countryId: accountInfo?.countryId || '',
        dateOfBirth: accountInfo?.dateOfBirth || '',
        gender: accountInfo?.gender || Genders.MALE,
        introduce: accountInfo?.introduce || '',
        name: accountInfo?.name || '',
        regionId: accountInfo?.regionId || 0,
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <CreatorAccount isLoading={loading} />
    </Formik>
  );
};

export default Index;
