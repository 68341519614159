import styled from '@emotion/styled';
import map from '@highcharts/map-collection/custom/asia.geo.json';
import HighchartsMap from 'highcharts/modules/map';
import React from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import getCountryISO3 from 'country-iso-2-to-3';
import earthIcon from '../../../../../assets/icon/earth.svg';
import HighChart, { Highcharts, MapConstructor } from '../../../../molecules/HighChart';
import { formatNumber } from '../../../../../libs/format';
import ProfileDetailsContentWrapper from '../ProfileDetailsContentWrapper/ProfileDetailsContentWrapper';
import { COLORS } from '../colors';

const colors = [COLORS.skyBlue, COLORS.pink, COLORS.darkBlue, COLORS.darkYellow, COLORS.purple];
const getColor = (index: number) => colors[index] || COLORS.purple;

/* initializing map module */
HighchartsMap(Highcharts);

/** Chart Configuration */
const pointWidth = 11;
const mapHeight = 253;
const chartHeight = 127;
const maxCountriesForLineChart = 4;
const marginBottom = 40;
const maxNumberOfCharAsLabel = 13;

interface CountryRates {
  id: string;
  name: string;
  rate: number;
}
export interface CountrywiseFollowersMapProps {
  className?: string;
  countryRates: CountryRates[] | null;
}

const CountrywiseFollowersMap = (props: CountrywiseFollowersMapProps) => {
  const { countryRates, className } = props;
  const { t } = useTranslation();

  const mappedData =
    countryRates && !!countryRates.length
      ? countryRates.map((item, i) => ({
          y: Number(formatNumber(item.rate * 100)),
          color: getColor(i),
          name: item.name,
          country: item.name,
          z: Number(formatNumber(item.rate * 100)),
          code3: getCountryISO3(item.id),
          code: item.id,
          countyValue: Number(formatNumber(item.rate * 100)),
        }))
      : [];

  const topCountries = mappedData.sort((a, b) => (a.z > b.z ? -1 : 1)).slice(0, maxCountriesForLineChart);

  const options: Highcharts.Options = {
    chart: {
      height: chartHeight,
      marginBottom,
    },
    title: {
      text: '',
    },
    yAxis: {
      gridLineColor: 'transparent',
      lineColor: '#efefef',
      lineWidth: 1,
      title: {
        text: '',
      },
      labels: {
        formatter: function formatter(): string {
          return `${this.value}%`;
        },
      },
    },
    xAxis: {
      categories: topCountries.map(item =>
        item.name.length > maxNumberOfCharAsLabel ? `${item.name.slice(0, maxNumberOfCharAsLabel)}..` : item.name
      ),
      lineColor: 'transparent',
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        type: 'bar',
        data: topCountries,
      },
    ],
    tooltip: {
      padding: 0,
      formatter: function formatter(): string {
        return `
              <div style="border-radius:3px;border: 2px solid #27313b;margin:-2px;background-color:#27313b;font-size:12px;padding:13px 16px;display:flex;align-items:center">
                <span style="display:inline-block;margin-right:8px;background-color:${this.point.color};height:12px;width:12px"></span>
                <span style="margin-right:16px">${this.key}
                </span><span>${this.point.y}%</span>
              </div>`;
      },
      pointFormat: '',
      borderColor: '#27313b',
      borderWidth: 0,
      backgroundColor: '#27313b',
      style: {
        color: '#ffffff',
      },
      useHTML: true,
      headerFormat: '',
    },
    plotOptions: {
      bar: {
        pointWidth,
      },
    },
  };

  const mapOptions: Highcharts.Options = {
    chart: {
      map,
      height: mapHeight,
    },
    title: {
      text: '',
    },
    legend: {
      enabled: false,
    },
    // @ts-ignore
    series: [
      {
        name: 'Countries',
        color: '#E0E0E0',
        enableMouseTracking: false,
        type: 'map',
      },
      {
        type: 'mapbubble',
        name: 'Followers',
        joinBy: ['iso-a3', 'code3'],
        data: mappedData,
        tooltip: {
          headerFormat: '',
          valueSuffix: '%',
        },
      },
    ],
    tooltip: {
      padding: 0,
      formatter: function formatter(): string {
        const point: any = this.point;

        return `
          <div style="border-radius:3px;border: 2px solid #27313b;margin:-2px;background-color:#27313b;font-size:12px;padding:13px 16px;display:flex;align-items:center">
            <span style="display:inline-block;margin-right:8px;background-color:${point.color};height:12px;width:12px"></span>
            <span style="margin-right:16px">${this.key}
            </span><span>${point.countyValue}%</span>
          </div>`;
      },
      pointFormat: '',
      borderColor: '#27313b',
      borderWidth: 0,
      backgroundColor: '#27313b',
      style: {
        color: '#ffffff',
      },
      useHTML: true,
      headerFormat: '',
    },
  };

  const headerNode = (
    <Header>
      <Img src={earthIcon} /> <Title>{t('Title.Countries')}</Title>
    </Header>
  );

  return (
    <ProfileDetailsContentWrapper
      className={className}
      header={headerNode}
      dataNotAvailable={!countryRates || countryRates.length === 0}
    >
      <HighChartWrapper>
        <HighChart options={mapOptions} constructorType={MapConstructor.MAP_CHART} />
      </HighChartWrapper>
      <HighChart options={options} />
    </ProfileDetailsContentWrapper>
  );
};

const HighChartWrapper = styled.div`
  margin-top: -16px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 1;
`;

const Img = styled.img`
  width: 24px;
  margin-right: 8px;
`;

const Title = styled.h1`
  font-size: 13px;
  font-weight: 600;
  color: #27313b;
`;

export default CountrywiseFollowersMap;
