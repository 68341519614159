import { useField, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import Grid from '@src/components/atoms/Grid';
import Label from '@src/components/atoms/Label';
import { SubmitButton } from '@src/components/atoms/Button';
import { ListIndicator } from '@src/components/molecules/Indicator';
import SingleSelectField from '@src/components/molecules/SingleSelectField';
import Switch from '@src/components/molecules/Switch';
import TextForm from '@src/components/molecules/TextForm';
import { LANGUAGE_OPTIONS } from '@src/libs/i18n';
import { useAuthData } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import { DisplayLanguage, UserRoles } from '@src/__generated__/globalTypes';

export const LanguageMapping = {
  ch: DisplayLanguage.SIMPLIFIED_CHINESE,
  id: DisplayLanguage.BAHASA,
  en: DisplayLanguage.ENGLISH,
  ja: DisplayLanguage.JAPANESE,
  km: DisplayLanguage.CAMBODIA,
  my: DisplayLanguage.MYANMAR,
  th: DisplayLanguage.THAI,
  tw: DisplayLanguage.TRADITIONAL_CHINESE,
  vi: DisplayLanguage.VIETNAMESE,
} as Record<string, DisplayLanguage>;

export interface PersonalInformation {
  crmNotificationSetting: boolean;
  displayLanguage: DisplayLanguage;
  eggNotificationSetting: boolean | null;
  email: string;
  mkpNotificationSetting: boolean | null;
  name: string;
  password: string;
  passwordConfirm: string;
}

interface YourAccountProps {
  isLoading: boolean;
}

const YourAccount = ({ isLoading }: YourAccountProps) => {
  const { t } = useTranslation();
  const { role } = useAuthData();
  const { isSubmitting, handleSubmit, setFieldValue } = useFormikContext<PersonalInformation>();
  // disable CRM notification for the first release
  // const [crmNotificationSettingField] = useField<boolean>('crmNotificationSetting');
  const [displayLanguageField, displayLanguageMeta] = useField<DisplayLanguage>('displayLanguage');
  const [eggNotificationSettingField] = useField<boolean>('eggNotificationSetting');
  const [emailField, emailMeta] = useField<string>('email');
  const [mkpNotificationSettingField] = useField<boolean>('mkpNotificationSetting');
  const [nameField, nameMeta] = useField<string>('name');
  const [passwordField, passwordMeta] = useField<string>('password');
  const [passwordConfirmField, passwordConfirmMeta] = useField<string>('passwordConfirm');

  const languageOptions = LANGUAGE_OPTIONS.map(language => ({
    value: LanguageMapping[language.value],
    label: language.label,
  }));

  return (
    <form onSubmit={handleSubmit}>
      <div css={styles.container}>
        <Grid md={8} sm={10} xs={12}>
          {isLoading ? (
            <ListIndicator />
          ) : (
            <>
              <div css={styles.formInputSection}>
                <div css={styles.formTitle}>{t('Title.Your Account')}</div>
                <div css={styles.formDescription}>
                  {t('Annotation.Edit your information and set notifications to receive')}
                </div>

                <InputContainer>
                  <StyledTextForm
                    error={!!nameMeta.error}
                    isRequired
                    title="Name"
                    placeholder="Naoya Kurita"
                    value={nameField.value || ''}
                    onChange={e => setFieldValue('name', e.target.value)}
                  />
                  {nameMeta.error && <ErrorMessage message={t(nameMeta.error)} />}
                </InputContainer>

                <InputContainer>
                  <StyledTextForm
                    error={!!emailMeta.error}
                    isRequired
                    title="Email Address"
                    placeholder="take@anymindgroup.com"
                    value={emailField.value || ''}
                    onChange={e => setFieldValue('email', e.target.value)}
                  />
                  {emailMeta.error && <ErrorMessage message={t(emailMeta.error)} />}
                </InputContainer>

                <InputContainer>
                  <StyledTextForm
                    error={!!passwordMeta.error}
                    title="Password"
                    placeholder=""
                    type="password"
                    value={passwordField.value || ''}
                    onChange={e => setFieldValue('password', e.target.value)}
                  />
                  {passwordMeta.error && <ErrorMessage message={t(passwordMeta.error)} />}
                </InputContainer>

                <InputContainer>
                  <StyledTextForm
                    error={!!passwordConfirmMeta.error}
                    title="Confirm Password"
                    placeholder=""
                    type="password"
                    value={passwordConfirmField.value || ''}
                    onChange={e => setFieldValue('passwordConfirm', e.target.value)}
                  />
                  {passwordConfirmMeta.error && <ErrorMessage message={t(passwordConfirmMeta.error)} />}
                </InputContainer>

                <InputContainer>
                  <StyledSingleSelectField
                    name="displayLanguage"
                    options={languageOptions}
                    title="Display Language"
                    value={displayLanguageField.value || ''}
                    setFieldValue={setFieldValue}
                  />
                  {displayLanguageMeta.error && <ErrorMessage message={t(displayLanguageMeta.error)} />}
                </InputContainer>

                {role === UserRoles.INFLUENCER && (
                  <InputContainer>
                    <Label css={styles.inputLabel} title={t('Notification')} />
                    <SwitchContainer>
                      <Switch
                        isChecked={eggNotificationSettingField.value}
                        handleChange={() => setFieldValue('eggNotificationSetting', !eggNotificationSettingField.value)}
                      />
                      <SwitchLabel>{t(`MenuTitle.Casting`)}</SwitchLabel>
                    </SwitchContainer>
                    <SwitchContainer>
                      <Switch
                        isChecked={mkpNotificationSettingField.value}
                        handleChange={() => setFieldValue('mkpNotificationSetting', !mkpNotificationSettingField.value)}
                      />
                      <SwitchLabel>{t(`MenuTitle.Marketplace`)}</SwitchLabel>
                    </SwitchContainer>
                    {/* <SwitchContainer>
                  <Switch
                    isChecked={crmNotificationSettingField.value}
                    handleChange={() => setFieldValue('crmNotificationSetting', !crmNotificationSettingField.value)}
                  />
                  <SwitchLabel>{t(`MenuTitle.CRM`)}</SwitchLabel>
                </SwitchContainer> */}
                  </InputContainer>
                )}
              </div>

              <div css={styles.formActionSection}>
                <SubmitButton disabled={isSubmitting} value="Save" />
              </div>
            </>
          )}
        </Grid>
      </div>
    </form>
  );
};

const InputContainer = styled.div`
  margin-bottom: 24px;
`;

const StyledSingleSelectField = styled(SingleSelectField)`
  & > label {
    font-size: 14px;
  }

  & > div > div {
    border-radius: 2px;
    min-height: 32px;

    & > div > div {
      height: auto;
    }
  }

  & input {
    border-radius: 2px;
    min-height: 32px;
  }
`;

const StyledTextForm = styled(TextForm)`
  & > label {
    font-size: 14px;
  }

  & input {
    border-radius: 2px;
    height: 32px;
  }
`;

const SwitchContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 16px;
`;

const SwitchLabel = styled.div`
  color: #27313b;
  font-size: 14px;
  margin-left: 8px;
`;

const styles = {
  container: css`
    display: flex;
    justify-content: center;
  `,
  formActionSection: css`
    background-color: #fff;
    border-top: 1px solid #eef3f7;
    display: flex;
    justify-content: flex-end;
    padding: 16px;

    /* stylelint-disable */
    & input {
      border-radius: 3px;
      height: 32px;
      line-height: unset;
      width: 77px;

      @media (max-width: ${ViewportType.SMALL}px) {
        border-radius: 5px;
        height: 40px;
        width: 100%;
      }
    }
    /* stylelint-enable */
  `,
  formDescription: css`
    color: #27313b;
    font-size: 14px;
    margin-bottom: 24px;
  `,
  formInputSection: css`
    background-color: #fff;
    padding: 24px;
  `,
  formTitle: css`
    color: #27313b;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
  `,
  inputLabel: css`
    color: #27313b;
    font-size: 14px;
    margin-bottom: 16px;
  `,
};

export default YourAccount;
