import { useField, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SubmitButton } from '@src/components/atoms/Button';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import Grid from '@src/components/atoms/Grid';
import Label from '@src/components/atoms/Label';
import { ListIndicator } from '@src/components/molecules/Indicator';
import MultiSelectField from '@src/components/molecules/MultiSelectField';
import SentenceForm from '@src/components/molecules/SentenceForm';
import SingleSelectField from '@src/components/molecules/SingleSelectField';
import TextForm from '@src/components/molecules/TextForm';
import YearMonthDayPicker from '@src/components/molecules/YearMonthDayPicker';
import RegionSelector from '@src/components/organisms/RegionSelector';
import { COUNTRY_IDS, GENDERS } from '@src/libs/constant';
import { useInfluencerCategories, useTranslateOptions } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import { Genders } from '@src/__generated__/globalTypes';

export interface AccountInformation {
  brandName: string | null;
  categoryIds: number[];
  countryId: string;
  dateOfBirth: string | null;
  gender: Genders;
  introduce: string;
  name: string;
  regionId: number | null;
}

interface CreatorAccountProps {
  isLoading: boolean;
}

const CreatorAccount = ({ isLoading }: CreatorAccountProps) => {
  const { t } = useTranslation();
  const { influencerCategories } = useInfluencerCategories();
  const translatedCategories = useTranslateOptions(influencerCategories);
  const translatedCountries = useTranslateOptions(COUNTRY_IDS);
  const translatedGenders = useTranslateOptions(GENDERS);

  const { isSubmitting, handleSubmit, setFieldValue } = useFormikContext<AccountInformation>();
  // hided for the first release
  // const [brand, brandMeta] = useField('brandName');
  const [category, categoryMeta] = useField<number[]>('categoryIds');
  const [country, countryMeta] = useField('countryId');
  const [dateOfBirth, dateOfBirthMeta] = useField('dateOfBirth');
  const [gender, genderMeta] = useField('gender');
  const [introduce] = useField('introduce');
  const [name, nameMeta] = useField('name');
  const [region, regionMeta] = useField('regionId');

  return (
    <form onSubmit={handleSubmit}>
      <div css={styles.formContainer}>
        <Grid md={8} sm={10} xs={12}>
          {isLoading ? (
            <ListIndicator />
          ) : (
            <>
              <div css={styles.formInputSection}>
                <div css={styles.formTitle}>{t('Title.Creator Account')}</div>
                <div css={styles.formDescription}>
                  {t('Annotation.Edit your information and set notifications to receive')}
                </div>

                <InputContainer>
                  <StyledTextForm
                    error={!!nameMeta.error}
                    isRequired
                    title="Creator Name"
                    placeholder="Creator Name"
                    value={name.value || ''}
                    onChange={e => setFieldValue('name', e.target.value)}
                  />
                  {nameMeta.error && <ErrorMessage message={t(nameMeta.error)} />}
                </InputContainer>

                {/* <InputContainer>
                <StyledTextForm
                  error={!!brandMeta.error}
                  isRequired
                  title="Brand Name"
                  placeholder="Brand Name"
                  value={brand.value || ''}
                  onChange={e => setFieldValue('brandName', e.target.value)}
                />
                {brandMeta.error && <ErrorMessage message={t(brandMeta.error)} />}
              </InputContainer> */}

                <InputContainer>
                  <MultiSelectField
                    css={styles.dropdownField}
                    error={!!categoryMeta.error}
                    isRequired
                    name="category"
                    options={translatedCategories}
                    title="Categories"
                    value={category.value.map(id => id.toString()) || []}
                    setFieldValue={(_attr, value) =>
                      setFieldValue(
                        'categoryIds',
                        value.map(val => Number(val))
                      )
                    }
                  />
                  {categoryMeta.error && <ErrorMessage message={t(categoryMeta.error)} />}
                </InputContainer>

                <div css={styles.dobpickerContainer}>
                  <Label isRequired title={t('Selector.Date of Birth')} />
                  <YearMonthDayPicker
                    format="yyyy-MM-dd"
                    error={!!dateOfBirthMeta.error}
                    maxDate={new Date()}
                    value={dateOfBirth.value || ''}
                    onChange={value => setFieldValue('dateOfBirth', value)}
                  />
                  {dateOfBirthMeta.error && <ErrorMessage message={t(dateOfBirthMeta.error)} />}
                </div>

                <InputContainer>
                  <StyledSingleSelectField
                    disabled
                    error={!!countryMeta.error}
                    isRequired
                    name="country"
                    options={translatedCountries}
                    title="Country"
                    value={country.value || ''}
                    setFieldValue={(_attr, value) => setFieldValue('countryId', value)}
                  />
                  {countryMeta.error && <ErrorMessage message={t(countryMeta.error)} />}
                </InputContainer>

                <InputContainer>
                  <RegionSelector
                    countryId={country.value}
                    css={styles.dropdownField}
                    error={!!regionMeta.error}
                    isRequired
                    name="region"
                    title="Region"
                    value={region.value.toString() || ''}
                    setFieldValue={(_attr, value) => setFieldValue('regionId', value ? Number(value) : '')}
                  />
                  {regionMeta.error && <ErrorMessage message={t(regionMeta.error)} />}
                </InputContainer>

                <InputContainer>
                  <StyledSingleSelectField
                    disabled
                    error={!!genderMeta.error}
                    isRequired
                    name="gender"
                    options={translatedGenders}
                    title="Gender"
                    value={gender.value || ''}
                    setFieldValue={(attr, value) => setFieldValue(attr, value)}
                  />
                  {genderMeta.error && <ErrorMessage message={t(genderMeta.error)} />}
                </InputContainer>

                <InputContainer>
                  <StyledSentenceForm
                    placeholder="Introduction"
                    title="Introduction"
                    value={introduce.value || ''}
                    onChange={e => setFieldValue('introduce', e.target.value)}
                  />
                </InputContainer>
              </div>

              <div css={styles.formActionSection}>
                <SubmitButton disabled={isSubmitting} value="Save" />
              </div>
            </>
          )}
        </Grid>
      </div>
    </form>
  );
};

const InputContainer = styled.div`
  margin-bottom: 24px;
`;

const StyledSentenceForm = styled(SentenceForm)`
  & > label {
    font-size: 14px;
  }

  & > textarea {
    border-radius: 2px;
  }
`;

const StyledSingleSelectField = styled(SingleSelectField)`
  & > label {
    font-size: 14px;
  }

  & > div > div {
    border-radius: 2px;
    min-height: 32px;

    & > div > div {
      height: auto;
    }
  }

  & input {
    border-radius: 2px;
    min-height: 32px;
  }
`;

const StyledTextForm = styled(TextForm)`
  & > label {
    font-size: 14px;
  }

  & input {
    border-radius: 2px;
    height: 32px;
  }
`;

const styles = {
  container: css`
    margin: 32px 80px;

    @media (max-width: ${ViewportType.SMALL}px) {
      margin: 32px 16px;
    }
  `,
  dobpickerContainer: css`
    margin-bottom: 16px;
    width: 60%;

    @media (max-width: ${ViewportType.TABLET}px) {
      width: 100%;
    }

    & > label {
      font-size: 14px;
    }

    & > div > div:nth-of-type(2) {
      margin: 0 8px;
    }

    /* stylelint-disable */
    & > div > div > div {
      border-radius: 0;
      min-height: 32px;

      & > div > input {
        min-height: 32px;
      }
    }
    /* stylelint-enable */
  `,
  dropdownField: css`
    & > label {
      font-size: 14px;
    }

    /* stylelint-disable */
    & > div > div {
      border-radius: 2px;
      min-height: 32px;
    }

    & input {
      border-radius: 2px;
      min-height: 32px;
    }

    & button {
      border-radius: 5px;
    }
    /* stylelint-enable */
  `,
  formActionSection: css`
    background-color: #fff;
    border-top: 1px solid #eef3f7;
    display: flex;
    justify-content: flex-end;
    padding: 16px;

    /* stylelint-disable */
    & input {
      border-radius: 3px;
      height: 32px;
      line-height: unset;
      width: 77px;

      @media (max-width: ${ViewportType.SMALL}px) {
        border-radius: 5px;
        height: 40px;
        width: 100%;
      }
    }
    /* stylelint-enable */
  `,
  formContainer: css`
    display: flex;
    justify-content: center;
  `,
  formDescription: css`
    color: #27313b;
    font-size: 14px;
    margin-bottom: 24px;
  `,
  formInputSection: css`
    background-color: #fff;
    padding: 24px;
  `,
  formTitle: css`
    color: #27313b;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
  `,
  titleContainer: css`
    align-items: center;
    display: flex;

    & > a {
      background-color: #fff;
      box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
      display: flex;
      height: 32px;
      justify-content: center;
      width: 32px;

      & > i {
        padding-top: 2px;
      }
    }

    /* stylelint-disable */
    & > div {
      font-size: 18px;
      font-weight: 600;
      margin-left: 16px;
    }
    /* stylelint-enable */
  `,
};

export default CreatorAccount;
