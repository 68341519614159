import React from 'react';
import commentIcon from '@src/assets/icon/comment.svg';
import heartIcon from '@src/assets/icon/heart.svg';
import bookmarkIcon from '@src/assets/icon/bookmark.svg';
import pictureIcon from '@src/assets/icon/picture.svg';
import networkIcon from '@src/assets/icon/network.svg';
import estimatedReachIcon from '@src/assets/icon/estimatedReach.svg';
import { bigIntFormatter, intlNumberFormat, formatPercent } from '@src/libs/format';
import { useQueryHelper } from '@src/libs/hooks';
import * as Styled from '@src/components/organisms/AnalyticsShared/AverageEngagementStyled';
import DataOpacityWrapper, { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import { getGrowthPrefix, getGrowthArrow } from '@src/components/organisms/AnalyticsShared/utils';
import { ViewportBreakpoint } from '@src/libs/theme';
import * as StyledOverview from '@src/components/organisms/AnalyticsShared/Overview/StyledComponents';
import { useMediaQuery } from 'react-responsive';

interface AverageEngagementInstagramProps {
  averagePostsPerWeek: number | null;
  averagePostsGrowth?: number | null;
  averageLikes: number | null;
  averageLikesGrowth?: number | null;
  averageComments: number | null;
  averageCommentsGrowth?: number | null;
  averageSaved: number | null;
  averageSavedGrowth?: number | null;
  engagementRate?: number | null;
  engagementRateGrowth?: number | null;
  estimatedReach?: number | null;
}
const AverageEngagementInstagram = (props: AverageEngagementInstagramProps) => {
  const { t } = useQueryHelper();
  const {
    averagePostsPerWeek,
    averageLikes,
    averageSaved,
    averageComments,
    engagementRate,
    averagePostsGrowth,
    averageLikesGrowth,
    averageSavedGrowth,
    averageCommentsGrowth,
    engagementRateGrowth,
    estimatedReach,
  } = props;
  const isDirectionColumn = useMediaQuery({ maxWidth: ViewportBreakpoint.MOBILE });
  const { engagementStyles } = Styled;

  const dataNotAvailable = !averagePostsPerWeek && !averageLikes && !averageSaved && !engagementRate && !estimatedReach;
  const data = [
    { icon: pictureIcon, title: 'Avg posts/week', value: averagePostsPerWeek, growth: averagePostsGrowth },
    { icon: networkIcon, title: 'Avg ER', value: engagementRate, growth: engagementRateGrowth },
    { icon: heartIcon, title: 'Label.AvgLikes', value: averageLikes, growth: averageLikesGrowth },
    { icon: bookmarkIcon, title: 'Label.AvgSaves', value: averageSaved, growth: averageSavedGrowth },
    { icon: commentIcon, title: 'Label.AvgComments', value: averageComments, growth: averageCommentsGrowth },
    { icon: estimatedReachIcon, title: 'Est Reach', value: estimatedReach, growth: averageCommentsGrowth },
  ];

  return (
    <DataOpacityWrapper
      header={<SectionHeader>{t('Average Engagement')}</SectionHeader>}
      dataNotAvailable={dataNotAvailable}
      css={engagementStyles.container}
    >
      <Styled.MainWrapper css={engagementStyles.gridContainer}>
        {data.map(item => {
          const isPercentage = ['Avg ER'].includes(item.title);
          const avgGrowth = item.growth;
          const avgTotal = item.value;
          const total = isPercentage ? formatPercent(item.value) : bigIntFormatter(item.value, 0);
          const growth = isPercentage ? formatPercent(item.growth) : bigIntFormatter(item.growth, 0);
          const { title, icon } = item;
          const hoverTotal = intlNumberFormat(avgTotal);
          const hoverGrowth = intlNumberFormat(avgGrowth);

          if (isDirectionColumn) {
            return (
              <StyledOverview.EngagementGridCell key={title}>
                <StyledOverview.PartWithIcon>
                  <StyledOverview.EngagementTitle text={t(title)} lines={1} />
                  <StyledOverview.EngagementTileIcon src={icon} css={engagementStyles.icon} />
                </StyledOverview.PartWithIcon>

                <StyledOverview.EngagementNumbers>
                  <StyledOverview.TotalNumber title={hoverTotal}>{total}</StyledOverview.TotalNumber>
                  {!!avgGrowth && (
                    <StyledOverview.Growth isPositive={Number(avgGrowth) > 0} title={hoverGrowth}>{`${getGrowthPrefix(
                      avgGrowth
                    )}${growth}  ${getGrowthArrow(avgGrowth)}`}</StyledOverview.Growth>
                  )}
                </StyledOverview.EngagementNumbers>
              </StyledOverview.EngagementGridCell>
            );
          }

          return (
            <Styled.EngagementDataRow key={title}>
              <Styled.EngagementHalf isDirectionColumn={isDirectionColumn}>
                <img src={icon} css={{ width: 32, height: 32 }} />
                <Styled.Label>{t(title)}</Styled.Label>
              </Styled.EngagementHalf>
              <Styled.EngagementHalf>
                <Styled.GrowthWrapper>
                  <Styled.Value title={hoverTotal}>{total}</Styled.Value>
                  {!!avgGrowth && (
                    <Styled.Identifier isPositive={Number(avgGrowth) > 0} title={hoverGrowth}>{`${getGrowthPrefix(
                      avgGrowth
                    )}${growth}  ${getGrowthArrow(avgGrowth)}`}</Styled.Identifier>
                  )}
                </Styled.GrowthWrapper>
              </Styled.EngagementHalf>
            </Styled.EngagementDataRow>
          );
        })}
      </Styled.MainWrapper>
    </DataOpacityWrapper>
  );
};

export default AverageEngagementInstagram;
