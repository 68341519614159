import React from 'react';
import AnalyticsContentWrapper, {
  ContentType,
  sectionStyles,
} from '@src/components/organisms/AnalyticsShared/AnalyticsContentWrapper';
import { useQuery } from '@apollo/client';
import PostingHabits from '@src/components/organisms/ProfileWidgets/PostingHabits';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { useQueryHelper } from '@src/libs/hooks';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import YouTubeFeedPosts from './YouTubeFeedPosts';
import AverageEngagementYouTube from './AverageEngagementYouTube';
import {
  InfluencerYouTubeProfilePosts,
  InfluencerYouTubeProfilePostsVariables,
} from './__generated__/InfluencerYouTubeProfilePosts';
import * as INFLUENCER_YT_PROFILE_POSTS from './InfluencerYtProfilePosts.graphql';

interface ProfilePostsProps {
  socialMediaAccountId: number;
  influencerAccountId: number;
  socialMedia: SocialAccountType;
}
export const YtProfilePostsSection = ({
  socialMediaAccountId,
  influencerAccountId,
  socialMedia,
}: ProfilePostsProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data, loading } = useQuery<InfluencerYouTubeProfilePosts, InfluencerYouTubeProfilePostsVariables>(
    INFLUENCER_YT_PROFILE_POSTS,
    {
      variables: {
        pk: influencerAccountId,
        socialAccountId: socialMediaAccountId,
        socialMedia,
      },
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
    }
  );

  if (loading) {
    return <ListIndicator css={sectionStyles.loading} />;
  }

  const feedPosts = data?.influencerProfilePosts?.feedPosts || [];
  const postsHabit = data?.influencerProfilePosts?.postsHabit || [];
  const averageViews = data?.influencerProfilePosts?.averageViews || null;
  const averageComments = data?.influencerProfilePosts?.averageComments || null;
  const averageLikes = data?.influencerProfilePosts?.averageLikes || null;
  const averagePostsPerWeek = data?.influencerProfilePosts?.averagePostsPerWeek || null;

  return (
    <AnalyticsContentWrapper contentType={ContentType.Posts} hasHelp={false}>
      <div css={sectionStyles.responsiveWrapper}>
        <AverageEngagementYouTube
          averagePostsPerWeek={averagePostsPerWeek}
          averageLikes={averageLikes}
          averageViews={averageViews}
          averageComments={averageComments}
        />
        <PostingHabits postsHabit={postsHabit} css={[sectionStyles.middleSection, sectionStyles.verticalContaner]} />
      </div>
      <YouTubeFeedPosts
        influencerAccountId={influencerAccountId}
        posts={feedPosts}
        header={<SectionHeader>{t('Title.Videos')}</SectionHeader>}
      />
    </AnalyticsContentWrapper>
  );
};
