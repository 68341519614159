import { InstagramStoryPostSortField } from '@src/__generated__/globalTypes';
import { formatIntNumber, formatDate } from '@src/libs/format';
import { Post } from '../model';

export const getInstagramStoryPostSortFieldType = (field?: string | null) => {
  if (!field) {
    return null;
  }

  return InstagramStoryPostSortField[field as keyof typeof InstagramStoryPostSortField];
};

export const instagramStoryPostListColumns = [
  {
    key: 'postDate',
    title: 'Post Date',
    orderField: InstagramStoryPostSortField.POST_DATE,
    formatter: formatDate,
  },
  {
    key: 'comments',
    title: 'Comment',
    orderField: InstagramStoryPostSortField.COMMENT,
    formatter: formatIntNumber,
  },
  {
    key: 'reach',
    title: 'Reach',
    orderField: InstagramStoryPostSortField.REACH,
    formatter: formatIntNumber,
  },
  {
    key: 'impressions',
    title: 'Imps',
    orderField: InstagramStoryPostSortField.IMPRESSIONS,
    formatter: formatIntNumber,
  },
  {
    key: 'exits',
    title: 'Exits',
    orderField: InstagramStoryPostSortField.EXITS,
    formatter: formatIntNumber,
  },
  {
    key: 'tapsBack',
    title: 'Taps Back',
    orderField: InstagramStoryPostSortField.TAPS_BACK,
    formatter: formatIntNumber,
  },
  {
    key: 'tapsForward',
    title: 'Taps Forward',
    orderField: InstagramStoryPostSortField.TAPS_FORWARD,
    formatter: formatIntNumber,
  },
] as { title: string; orderField: InstagramStoryPostSortField; key: keyof Post; formatter: Function }[];
