import React from 'react';
import { useLocation } from 'react-router-dom';
import { AnalyticsTemplateProps } from '../AnalyticsShared/types';

import Interaction from './Interaction';
import Dashboard from './Dashboard';

const AnalyticsInstagram = (props: AnalyticsTemplateProps) => {
  const { pathname } = useLocation();

  const renderTabContent = () => {
    switch (pathname) {
      case '/analytics':
      case '/profile':
        return <Dashboard {...props} />;
      case '/analytics:ig_interaction':
      case '/profile:ig_interaction':
        return <Interaction {...props} />;

      default:
        return <Dashboard {...props} />;
    }
  };

  return renderTabContent();
};

export default AnalyticsInstagram;
