import * as React from 'react';
import {
  CampaignPostStatusForInfluencer,
  CampaignType,
  CampaignSocialMediaType,
  CampaignStatusForInfluencer,
  MarketplaceCampaignType,
  MarketplaceCampaignAppliedStatus,
} from '../../../../__generated__/globalTypes';
import Template from './template';

export interface CampaignDetailProps {
  thumbNail: string;
  title: string;
  serviceInformation: string;
  materials: string[];
  campaignType: CampaignType;
  startDate: string;
  endDate: string;
  campaignStatus: CampaignStatusForInfluencer;
  currency: string;
  requirements: string;
  socialMedias: CampaignSocialMediaType[];
  totalAmount?: number;
  remainingAmount?: number;
  hashtags?: string[];
  revenuePerPost?: number | null;
  revenuePerLike?: number | null;
  revenuePerView?: number | null;
  revenuePerShare?: number | null;
  revenuePerComment?: number | null;
  revenuePerFollower?: number | null;
  revenuePerClick?: number | null;
  revenuePerAction?: number | null;
  trackingUrl?: string | null;
  productUrl?: string | null;
  isJoined?: boolean;
  campaignId: number;
  hasReport?: boolean;
  hasDraftPosts?: boolean;
  hasWarningPosts?: boolean;
  maximumRevenuePerInfluencer?: number | null;
  materialUrl: string | null;
  selectionStartDate?: string;
  selectionEndDate?: string;
  marketplaceCampaignType?: MarketplaceCampaignType;
  isApplied?: boolean;
  appliedStatus?: MarketplaceCampaignAppliedStatus | null;
  postStatus?: CampaignPostStatusForInfluencer | null;
}

const CampaignDetail = (props: CampaignDetailProps) => {
  const {
    title,
    serviceInformation,
    materials,
    campaignType,
    startDate,
    endDate,
    campaignStatus,
    currency,
    requirements,
    socialMedias,
    thumbNail,
    totalAmount,
    remainingAmount,
    hashtags,
    revenuePerPost,
    revenuePerLike,
    revenuePerView,
    revenuePerShare,
    revenuePerComment,
    revenuePerFollower,
    revenuePerClick,
    revenuePerAction,
    trackingUrl,
    productUrl,
    isJoined,
    maximumRevenuePerInfluencer,
    materialUrl,
    selectionStartDate,
    selectionEndDate,
    marketplaceCampaignType,
    isApplied,
    appliedStatus,
    hasReport,
    postStatus,
  } = props;

  return (
    <Template
      thumbnail={thumbNail}
      title={title}
      information={serviceInformation}
      campaignType={campaignType}
      startDate={startDate}
      endDate={endDate}
      currency={currency}
      socialMedias={socialMedias}
      campaignStatus={campaignStatus}
      hashTags={hashtags || []}
      revenuePerPost={revenuePerPost}
      revenuePerLike={revenuePerLike}
      revenuePerView={revenuePerView}
      revenuePerShare={revenuePerShare}
      revenuePerComment={revenuePerComment}
      revenuePerFollower={revenuePerFollower}
      revenuePerClick={revenuePerClick}
      revenuePerAction={revenuePerAction}
      totalAmount={totalAmount}
      remainingAmount={remainingAmount}
      requirements={requirements}
      materials={materials}
      trackingUrl={trackingUrl}
      productUrl={productUrl}
      isJoined={!!isJoined}
      maximumRevenuePerInfluencer={maximumRevenuePerInfluencer}
      downloadMaterialsUrl={materialUrl}
      selectionStartDate={selectionStartDate}
      selectionEndDate={selectionEndDate}
      marketplaceCampaignType={marketplaceCampaignType}
      isApplied={isApplied}
      appliedStatus={appliedStatus}
      hasReport={!!hasReport}
      postStatus={postStatus}
    />
  );
};

export default CampaignDetail;
