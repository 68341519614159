import React from 'react';
import styled from '@emotion/styled';
import { formatPercent, formatNumber, bigIntFormatter, intlNumberFormat } from '@src/libs/format';
import { getGrowthPrefix, getGrowthArrow } from '@src/components/organisms/AnalyticsShared/utils';
import * as Styled from './StyledComponents';

interface GrowthItemType {
  total: number | null;
  growth: number | null;
  rate: number | null;
  hasDecimals?: boolean;
  hasHyphen?: boolean;
}
export const TableGrowthSection = (item: GrowthItemType) => {
  const isPositiveGrowth = item.growth === 0 ? undefined : Number(item.growth) > 0;
  const prefix = getGrowthPrefix(item.growth) || '';
  const arrow = getGrowthArrow(item.growth);
  // FIXME: remove after BE will support null for newly fetched hashtags
  const temporatyNullableTotal = item.hasHyphen ? item.total || null : item.total;
  const formattedTotal = !!item.hasDecimals
    ? formatNumber(temporatyNullableTotal, 2)
    : bigIntFormatter(temporatyNullableTotal, 0);
  const formattedGrowth = !!item.hasDecimals ? formatNumber(item.growth, 2) : bigIntFormatter(item.growth, 0);
  const hoverTotal = intlNumberFormat(item.total);
  const hoverGrowth = intlNumberFormat(item.growth);

  return (
    <TDWrapper>
      <Styled.PartWithIcon>
        <Styled.LeftPart>
          <Styled.TotalNumber title={hoverTotal}>{formattedTotal}</Styled.TotalNumber>
        </Styled.LeftPart>
      </Styled.PartWithIcon>
      {formattedTotal !== '-' && (
        <Styled.Growth
          isPositive={isPositiveGrowth}
          title={hoverGrowth}
        >{`${prefix}${formattedGrowth}  ${arrow}  (${formatPercent(item.rate)})`}</Styled.Growth>
      )}
    </TDWrapper>
  );
};

const TDWrapper = styled.td`
  padding-left: 16px !important;
`;
