import { GraphQLError } from 'graphql';
import {
  CreateEngagementPostInputForInfluencerV2,
  UpdateEngagementPostInputForInfluencerV2,
  SocialAccountType,
} from '../../../../__generated__/globalTypes';
import { CreateEngagementPostMutation, FormValues, UpdateEngagementPostMutation } from './types';

import { EngagementPostFormProps } from './types';

export const requestCreateUpdateEngagementPostForInfluencer = (
  props: EngagementPostFormProps,
  payload: FormValues,
  createEngagementPost: CreateEngagementPostMutation,
  updateEngagementPost: UpdateEngagementPostMutation
) => {
  const { id: campaignId, postId } = props;
  const { socialMedia, planedPostDate, content, materialsUrl, socialAccountId, socialAccountMedia } = payload;

  if (!campaignId || !socialMedia) {
    return { ok: false, error: null };
  }
  const createEngagementPostInput: CreateEngagementPostInputForInfluencerV2 = {
    campaignId: Number(campaignId),
    socialMedia,
    planedPostDate,
    content,
    materialsUrl,
    socialAccountId: Number(socialAccountId),
    socialAccountMedia: socialAccountMedia as SocialAccountType,
  };

  if (!!postId) {
    const updateEngagementPostInput: UpdateEngagementPostInputForInfluencerV2 = {
      ...createEngagementPostInput,
      id: postId,
    };

    return requestUpdateEngagementPostInput(updateEngagementPost, updateEngagementPostInput);
  } else {
    return requestCreateEngagementPostInput(createEngagementPost, createEngagementPostInput);
  }
};

const requestUpdateEngagementPostInput = async (
  mutation: UpdateEngagementPostMutation,
  input: UpdateEngagementPostInputForInfluencerV2
) => {
  const { ok, error } = await mutation({ variables: { input } })
    .then(result => {
      if (result && result.data && result.data.updateEngagementPostForInfluencerV2) {
        return { ok: result.data.updateEngagementPostForInfluencerV2.ok, error: null };
      }

      return { ok: false, error: null };
    })
    .catch((e: GraphQLError) => ({ ok: false, error: e.message }));

  return { ok, error };
};

const requestCreateEngagementPostInput = async (
  mutation: CreateEngagementPostMutation,
  input: CreateEngagementPostInputForInfluencerV2
) => {
  const { ok, error } = await mutation({ variables: { input } })
    .then(result => {
      if (result && result.data && result.data.createEngagementPostForInfluencerV2) {
        return { ok: result.data.createEngagementPostForInfluencerV2.ok, error: null };
      }

      return { ok: false, error: null };
    })
    .catch((e: GraphQLError) => ({ ok: false, error: e.message }));

  return { ok, error };
};
