import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { formatIntNumber } from '@src/libs/format';
import { usePostDetailModal } from '@src/components/organisms/AnalyticsShared/BasePostList/usePostDetailModal';
import * as StyledComponents from '@src/components/organisms/AnalyticsShared/PostDetail/SliderStyledComponents';
import ImageSlider from '@src/components/molecules/Slider';
import CarouselModal from '@src/components/molecules/CarouselModal/CarouselModal';
import FacebookPageAnalyticsFeedPostDetail from '@src/components/organisms/AnalyticsFacebookPage/FacebookPageAnalyticsFeedPostDetail';
import { FacebookPagePosts } from '@src/components/organisms/AnalyticsFacebookPage/types';
import DataOpacityWrapper from '@src/components/molecules/DataCard/DataOpacityWrapper';

interface LikeCommentPostsProps {
  posts?: FacebookPagePosts[] | null;
  header: ReactElement;
  moreLink: string;
  className?: string;
  socialMediaAccountId?: number;
  influencerAccountId?: number;
  accountName?: string;
  avatarUrl?: string;
}
const FeedPosts = (props: LikeCommentPostsProps) => {
  const { t } = useTranslation();
  const {
    posts,
    header,
    moreLink,
    className,
    socialMediaAccountId,
    influencerAccountId,
    accountName,
    avatarUrl,
  } = props;

  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev,
  } = usePostDetailModal(posts || []);

  return (
    <>
      <StyledOpacityWrapper header={header} dataNotAvailable={!posts?.length}>
        <MainWrapper className={className}>
          {!!posts?.length && (
            <>
              <ImageSlider data={posts}>
                {posts.map(post => (
                  <StyledComponents.PostImageWrapper key={post.id}>
                    <StyledComponents.Sizer>
                      <StyledComponents.PostImage src={post.image} onClick={() => handleClickPost(post.id)} />
                      <StyledComponents.EngagementInfo>
                        <StyledComponents.InfoWrapper>
                          <StyledComponents.Label>{t('Reaction')}</StyledComponents.Label>
                          <StyledComponents.Count>{formatIntNumber(post.reactions)}</StyledComponents.Count>
                        </StyledComponents.InfoWrapper>
                        <StyledComponents.InfoWrapper>
                          <StyledComponents.Label>{t('General.Comments')}</StyledComponents.Label>
                          <StyledComponents.Count>{formatIntNumber(post.comments)}</StyledComponents.Count>
                        </StyledComponents.InfoWrapper>
                      </StyledComponents.EngagementInfo>
                    </StyledComponents.Sizer>
                  </StyledComponents.PostImageWrapper>
                ))}
              </ImageSlider>

              <StyledComponents.MoreText to={moreLink} />
            </>
          )}
        </MainWrapper>
      </StyledOpacityWrapper>
      <CarouselModal
        open={!!openPostDetail}
        title=""
        cancel="OK"
        onClose={handleClosePostDetail}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        siblingPostId={siblingPostId}
      >
        <FacebookPageAnalyticsFeedPostDetail
          closeModal={handleClosePostDetail}
          socialMediaAccountId={socialMediaAccountId}
          influencerAccountId={influencerAccountId}
          accountName={accountName}
          avatarUrl={avatarUrl}
        />
      </CarouselModal>
    </>
  );
};

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  border-bottom: 1px solid #e6ecf0;
`;
const StyledOpacityWrapper = styled(DataOpacityWrapper)`
  min-height: 200px;
`;

export default FeedPosts;
