import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import audienceIcon from '@src/assets/icon/averageAge.svg';
import postsIcon from '@src/assets/icon/chat.svg';
import overviewIcon from '@src/assets/icon/clipboard.svg';
import { Tooltip } from '@src/components/atoms/Tooltip';
import { css } from '@emotion/react';
import { ViewportBreakpoint } from '@src/libs/theme';
import * as Styled from './StyledComponents';

export enum ContentType {
  Overview,
  Audience,
  Posts,
  TagRanking,
  FollowersGrowth,
}

interface AnalyticsContentWrapperProps {
  contentType: ContentType;
  className?: string;
  addButton?: ReactElement;
  hasHelp?: boolean;
  height?: string;
}
const switchContentTitleWithIcon = (contentType: ContentType) => {
  const { t } = useTranslation();

  switch (contentType) {
    case ContentType.Overview:
      return { title: t('Overview'), icon: overviewIcon };
    case ContentType.Audience:
      return { title: t('Audience (Followers)'), icon: audienceIcon };
    case ContentType.TagRanking:
      return { title: t('Tag Ranking'), icon: audienceIcon };
    case ContentType.FollowersGrowth:
      return { title: t('Follower Growth'), icon: overviewIcon };
    default:
      return { title: t('Posts'), icon: postsIcon };
  }
};

const AnalyticsContentWrapper: React.FC<AnalyticsContentWrapperProps> = props => {
  const { children, className, addButton, height, contentType } = props;

  const { title, icon } = switchContentTitleWithIcon(contentType);

  return (
    <Container className={className}>
      <Header css={{ height: height || '48px' }}>
        <Img src={icon} />
        <Title>{title}</Title>
        {addButton}
      </Header>
      <Content>{children}</Content>
    </Container>
  );
};

const Container = styled.div`
  background-color: #fff;
  border-radius: 3px;
  margin-bottom: 24px;
  box-sizing: border-box;
`;
const Header = styled.div`
  padding-left: 16px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
  border-bottom: none;
  border-radius: 3px 3px 0 0;
`;
const Img = styled.img`
  margin-right: 8px;
  width: 24px;
`;
const Title = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.22;
  color: #27313b;
`;
const Content = styled.div`
  box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
`;

export default AnalyticsContentWrapper;

interface TitleWithHelpType {
  help?: string;
  title: string;
  className?: string;
}
export const TitleWithHelp = ({ help, title, className }: TitleWithHelpType) => {
  const { t } = useTranslation();

  return (
    <Styled.Title className={className}>
      {t(title)}
      {help ? <Tooltip help={help} placement="top" iconMargin="6px" /> : null}
    </Styled.Title>
  );
};

export const sectionStyles = {
  responsiveWrapper: css`
    display: flex;
    flex: 1;
    flex-direction: column;
    border-bottom: 1px solid #dee5ec;

    @media (min-width: ${ViewportBreakpoint.DESKTOP}px) {
      flex-direction: row;
    }
  `,
  middleSection: css`
    min-height: 300px;
  `,
  verticalContaner: css`
    @media (max-width: ${ViewportBreakpoint.DESKTOP}px) {
      border-top: 1px solid #dee5ec;
    }
  `,
  storyPost: css`
    border-top: 1px solid #dee5ec;
  `,
  sectionContainer: css`
    margin-top: 24px;
    background: #fff;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    border-radius: 5px;
  `,
  loading: css`
    margin: 0;
    height: 600px;
  `,
};
