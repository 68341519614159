import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import React from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Clock from '@src/assets/icon/clockLight.svg';
import { useAuthData, useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import { notificationIcon, notificationRedirectUrl } from '@src/pages/Notification/helper';
import CLICK_NOTIFICATION from '@src/pages/Notification/mutations/ViewNotification.graphql';
import {
  ViewNotification,
  ViewNotificationVariables,
} from '@src/pages/Notification/mutations/__generated__/ViewNotification';
import GET_NOTIFICATIONS from '@src/pages/Notification/queries/Notifications.graphql';
import { Notifications, NotificationsVariables } from '@src/pages/Notification/queries/__generated__/Notifications';
import { NotificationId, UserRoles } from '@src/__generated__/globalTypes';

const Notification = () => {
  const { enqueueSnackbar, t } = useQueryHelper();
  const { role } = useAuthData();
  const isStaff = role === UserRoles.STAFF;
  const { data } = useQuery<Notifications, NotificationsVariables>(GET_NOTIFICATIONS, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {},
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [clickNotification] = useMutation<ViewNotification, ViewNotificationVariables>(CLICK_NOTIFICATION, {
    refetchQueries: ['Notifications'],
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const notifications =
    data?.getNotifications?.notifications
      .filter(({ notificationId }) =>
        isStaff
          ? [
              NotificationId.PAYMENT_COMPLETED,
              NotificationId.PAYMENT_FAILED,
              NotificationId.PAYMENT_REQUEST_DONE,
            ].includes(notificationId)
          : true
      )
      .slice(0, 3) || [];

  const onClickNotification = (id: string) => {
    clickNotification({
      variables: {
        input: { id },
      },
    });
  };

  return (
    <div css={styles.container}>
      <div css={styles.notificationHeader}>
        <div>
          <div>{t('pageTitle.Notification')}</div>
          <div>
            <Link to="/notification">{t('More')}</Link>
          </div>
        </div>
      </div>
      <div css={styles.notificationsContainer}>
        {notifications.map(notification => {
          const { content, created, id, isClicked, notificationId } = notification;
          const campaignId = content?.campaign_id || '';
          const campaignName = content?.campaign_name;
          const message = t(notificationId.toLowerCase(), {
            ...(campaignName ? { campaign_name: campaignName } : {}),
          });

          return (
            <NotificationContainer
              isClicked={isClicked}
              key={notification.id}
              to={notificationRedirectUrl(campaignId, notificationId)}
              onClick={() => (!isClicked ? onClickNotification(id) : null)}
            >
              <div>
                <div css={styles.notificationIcon}>
                  <img alt="campaignIcon" height="30" src={notificationIcon(notificationId)} width="30" />
                </div>
              </div>
              <div>
                <div css={styles.notificationMsg}>
                  <span>{message}</span>
                </div>
                <div css={styles.notificationTime}>
                  <img alt="clock" src={Clock} />
                  <span>{formatDistanceToNow(new Date(created), { addSuffix: true })}</span>
                </div>
              </div>
            </NotificationContainer>
          );
        })}
      </div>
    </div>
  );
};

const NotificationContainer = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{ isClicked?: boolean }>`
  background-color: ${({ isClicked }) => (isClicked ? '#fff' : '#f1f8ff')};
  border-bottom: 1px solid #dee5ec;
  display: flex;
  flex-wrap: wrap;
  font-weight: ${({ isClicked }) => (isClicked ? '400' : '600')};
  padding: 8px 16px;

  &:hover {
    background-color: ${({ isClicked }) => (isClicked ? '#edf5fa' : '#f6f8fa')};
  }

  & > div:nth-of-type(1) {
    align-items: center;
    display: flex;
    padding: 0 16px;
  }

  & > div:nth-of-type(2) {
    width: 84%;

    @media (max-width: ${ViewportType.SMALL}px) {
      width: 72%;
    }
  }
`;

const styles = {
  container: css`
    border-radius: 5px;
    box-shadow: 0 2px 5px #dee5ec, 0 1px 4px rgba(222, 229, 236, 0.5);
    margin-bottom: 16px;
    width: 100%;
  `,
  notificationHeader: css`
    background-color: #fff;
    border-bottom: 1px solid #dee5ec;
    border-radius: 5px 5px 0 0;

    /* stylelint-disable no-descending-specificity */
    & > div {
      display: flex;
      flex-wrap: wrap;
      padding: 16px;

      & > div:nth-of-type(1) {
        color: #27313b;
        display: flex;
        flex-basis: 50%;
        font-size: 16px;
        font-weight: 600;
      }

      & > div:nth-of-type(2) {
        display: flex;
        flex-basis: 50%;
        justify-content: flex-end;

        & > a {
          color: #3892e5;
          font-size: 14px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  `,
  notificationIcon: css`
    align-items: center;
    background-color: #fff;
    border-radius: 12px;
    display: flex;
    height: 45px;
    justify-content: center;
    width: 45px;
  `,
  notificationMsg: css`
    display: grid;
    width: 100%;

    & > span {
      color: #27313b;
    }
  `,
  notificationsContainer: css`
    display: grid;
    flex-basis: 100%;

    & > a:last-child {
      border-radius: 0 0 5px 5px;
    }
  `,
  notificationTime: css`
    align-items: center;
    color: #7b8a98;
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;

    & > img {
      margin-right: 8px;
    }
  `,
};

export default Notification;
