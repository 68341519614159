import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { HeaderColumn, AmountColumn, DateColumn } from '@src/components/atoms/List';
import CarouselModal from '@src/components/molecules/CarouselModal/CarouselModal';
import { SliderComponents, StyledComponents, SliderCss } from '@src/components/molecules/SliderTable';
import { usePostDetailModal } from '@src/components/organisms/AnalyticsInstagram/Dashboard/PostCard/BasePostList/usePostDetailModal';
import { convertDurationToHhMmSs } from '@src/libs/date';
import { SortActionType } from '@src/libs/filter';
import { formatIntNumber, formatPercent } from '@src/libs/format';
import { defaultEmptyImage } from '@src/libs/image';
import { YoutubeAnalyticsPostsSortOrder, ORDER } from '@src/__generated__/globalTypes';
import AnalyticsPostDetail from '../../AnalyticsPostDetail';
import { getYtFeedVideosListColumns } from './helpers';

interface UserAnalysisType extends SortActionType<YoutubeAnalyticsPostsSortOrder> {
  videos?: {
    id: number;
    postDate: any;
    views: any;
    likes: any;
    dislikes: any;
    averageViewDuration: any | null;
    averageViewPercentage: number | null;
    comments: any;
    shares: any;
    positiveRate: number;
    negativeRate: number;
    videoDuration: number;
    videoTitle: string;
    videoId: string;
    videoThumbnail: string | null;
  }[];
  firstIndex: number;
  socialMediaAccountId: number;
  accountName?: string;
  avatarUrl?: string;
}
const FeedVideosTable = (props: UserAnalysisType) => {
  const {
    videos,
    sort: { field, order },
    setSort,
    socialMediaAccountId,
    accountName,
    avatarUrl,
  } = props;
  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev,
  } = usePostDetailModal(videos || null);

  return (
    <>
      <SliderComponents.Container>
        <SliderComponents.SliderSection>
          <SliderComponents.SliderTable>
            <thead>
              <SliderComponents.HeaderRow css={headerRowBg}>
                <SliderComponents.StickyHeaderTdWrapper>
                  <StyledHeader
                    title="Videos"
                    css={css`
                      width: 230px;
                      padding: 0;
                    `}
                    isTable={false}
                  />
                </SliderComponents.StickyHeaderTdWrapper>
                {/* Slider */}
                {getYtFeedVideosListColumns().map(({ title, orderField }) => (
                  <HeaderColumn
                    key={title}
                    title={title}
                    {...(orderField && {
                      activeAsc: field === orderField && order === ORDER.ASC,
                      activeDesc: field === orderField && order === ORDER.DESC,
                      sortAsc: () => setSort({ field: orderField, order: ORDER.ASC }),
                      sortDesc: () => setSort({ field: orderField, order: ORDER.DESC }),
                    })}
                    css={css`
                      text-transform: capitalize;
                    `}
                  />
                ))}
              </SliderComponents.HeaderRow>
            </thead>

            <tbody>
              {videos?.map(video => {
                const {
                  postDate,
                  views,
                  likes,
                  dislikes,
                  averageViewDuration,
                  averageViewPercentage,
                  comments,
                  shares,
                  positiveRate,
                  negativeRate,
                  videoDuration,
                  videoThumbnail,
                  videoTitle,
                  videoId,
                  id,
                } = video;

                return (
                  <StyledComponents.StyledRowNew css={[SliderCss.paddingSides, height40, borderBottom]} key={videoId}>
                    <SliderComponents.StickyBodyRowWrapper>
                      <SliderComponents.ThumbnailCell
                        handleClick={() => {
                          handleClickPost(id);
                        }}
                        src={defaultEmptyImage(videoThumbnail, true)}
                        imageWidth="85px"
                        title={videoTitle}
                        role="div"
                        css={paddingThumbnail}
                      />
                    </SliderComponents.StickyBodyRowWrapper>
                    <StyledComponents.NumberColumn data={formatIntNumber(views)} />
                    <StyledComponents.NumberColumn data={convertDurationToHhMmSs(videoDuration)} />
                    <StyledComponents.NumberColumn data={convertDurationToHhMmSs(averageViewDuration)} />
                    <AmountColumn data={formatPercent(averageViewPercentage, false)} currency="%" />
                    <StyledComponents.NumberColumn data={formatIntNumber(likes)} />
                    <StyledComponents.NumberColumn data={formatIntNumber(dislikes)} />
                    <StyledComponents.NumberColumn data={formatIntNumber(comments)} />
                    <AmountColumn data={formatPercent(positiveRate, false)} currency="%" />
                    <AmountColumn data={formatPercent(negativeRate, false)} currency="%" />
                    <StyledComponents.NumberColumn data={formatIntNumber(shares)} />
                    <DateColumn date={postDate} css={paddingLeft16} ignoreClientUTC={true} />
                  </StyledComponents.StyledRowNew>
                );
              })}
            </tbody>
          </SliderComponents.SliderTable>
        </SliderComponents.SliderSection>
        <CarouselModal
          open={!!openPostDetail}
          title=""
          cancel="OK"
          onClose={handleClosePostDetail}
          onClickNext={handleClickNext}
          onClickPrev={handleClickPrev}
          siblingPostId={siblingPostId}
        >
          <Container>
            <AnalyticsPostDetail
              isMain
              postId={Number(openPostDetail)}
              accountId={socialMediaAccountId}
              avatarUrl={avatarUrl}
              accountName={accountName}
            />
          </Container>
        </CarouselModal>
      </SliderComponents.Container>
    </>
  );
};

const headerRowBg = css`
  background-color: #f6f8fa;
`;
const paddingThumbnail = css`
  padding: 0 8px 0 0;
`;
const height40 = css`
  height: 40;
`;
const paddingLeft16 = css`
  padding-left: 16px;
`;
const borderBottom = css`
  border-bottom: 1px solid #dee5ec;
`;
const StyledHeader = styled(HeaderColumn)`
  & > div {
    justify-content: flex-start;
  }
`;
const Container = styled.div`
  width: 100%;
  margin: auto;
  height: 100%;
`;

export default FeedVideosTable;
