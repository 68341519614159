import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import SearchForm from '@src/components/molecules/SearchForm';
import SingleSelectField from '@src/components/molecules/SingleSelectField';
import { EmailEventStatus } from '@src/__generated__/globalTypes';
import { FilterItems } from './useFilter';

interface RecipientListFilterProps {
  values: FilterItems;
  onChange: (values: FilterItems) => void;
}

const RecipientListFilter = ({ values, onChange }: RecipientListFilterProps) => {
  const [filter, setFilter] = useState<FilterItems>(values);
  const { t } = useTranslation();
  const statusOptions = [
    { label: 'Bounced', value: EmailEventStatus.BOUNCE },
    { label: 'Clicked', value: EmailEventStatus.CLICK },
    { label: 'Delivered', value: EmailEventStatus.DELIVERED },
    { label: 'Opened', value: EmailEventStatus.OPEN },
  ];

  useEffect(() => {
    setFilter(values);
  }, [values.keyword, values.status]);

  return (
    <div css={styles.container}>
      <div>
        <SearchForm
          css={styles.searchForm}
          placeholder={t('TextForm.Search by Fan’s Name or Email Address')}
          value={filter.keyword}
          onBlur={() => onChange(filter)}
          onChange={e => setFilter({ ...filter, keyword: e.target.value })}
          onEnterKeyPress={() => onChange(filter)}
        />
      </div>
      <div>
        <SingleSelectField
          css={styles.singleSelectField}
          name=""
          options={statusOptions}
          placeholder="Status"
          value={filter.status}
          setFieldValue={(_attr, value) => onChange({ ...filter, status: value as EmailEventStatus })}
        />
      </div>
    </div>
  );
};

const styles = {
  container: css`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;

    & > div:nth-of-type(1) {
      display: flex;
      flex: 1;

      & > div {
        margin-right: 8px;
        width: 100%;
      }
    }

    /* stylelint-disable no-descending-specificity */
    & > div:nth-of-type(2) {
      width: 160px;
    }
  `,
  searchForm: css`
    & input {
      height: 32px;
    }
  `,
  singleSelectField: css`
    & > label {
      font-size: 14px;
    }

    & > div > div {
      border-radius: 2px;
      min-height: 30px;

      & > div > div {
        align-items: center;
        display: flex;
      }
    }

    & input {
      border-radius: 2px;
      min-height: 30px;
    }
  `,
};

export default RecipientListFilter;
