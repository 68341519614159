import { atom } from 'recoil';
import { FormStatus, QuestionType } from '@src/__generated__/globalTypes';
import { TOptions } from 'i18next';
import { FormInformation } from './components/organisms/Forms/Form/Form';

export const formState = atom<FormInformation | null>({
  key: 'formState',
  default: null,
});

export const submitFormState = atom<{
  description: string;
  hash: string;
  id: string;
  questions: {
    id: string;
    image: string | null;
    isRequired: boolean;
    options: {
      id: string;
      optionTitle: string;
      order: number;
    }[];
    order: number;
    questionType: QuestionType;
    title: string;
  }[];
  status: FormStatus;
  thankDescription: string;
  thankTitle: string;
  title: string;
} | null>({
  key: 'submitFormState',
  default: null,
});

export interface SelectedChatStateType {
  pic: string | null;
  resolved: boolean;
  userName: string;
  isChartStateMenuOpen: boolean;
  fanId: number | null;
  hasNoChats: boolean | null;
}
export const selectedChatState = atom<SelectedChatStateType>({
  key: 'selectedChatState',
  default: { pic: null, resolved: false, userName: '', isChartStateMenuOpen: false, fanId: null, hasNoChats: null },
});

interface ChatMessageTemplatesStateType {
  name: string;
  templateId: string;
  text: string;
}
export const chatMessageTemplatesState = atom<ChatMessageTemplatesStateType[]>({
  key: 'chatMessageTemplatesState',
  default: [],
});

interface DynamicTitleStateType {
  title: string;
  interpolation?: TOptions;
}
export const dynamicTitleState = atom<DynamicTitleStateType>({
  key: 'dynamicTitleState',
  default: { title: '...', interpolation: undefined },
});

export * from 'recoil';
