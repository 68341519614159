import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SocialAccountType, CampaignSocialMediaType } from '@src/__generated__/globalTypes';
import { formatIntNumber, formatPercent } from '@src/libs/format';
import { switchImage } from '@src/libs/SocialMedia';
import * as Styled from '../StyledEngagement';
import { InstagramEngagementInfoProps } from '../types';

const InstagramEngagementInfo = (props: InstagramEngagementInfoProps) => {
  const { t } = useTranslation();
  const { postUrl, sentiment, analysis, storyData, taggedPost, hasViewColumn } = props;

  return (
    <Styled.MainContainer>
      <Styled.Title>
        {t('Engagement')}
        <Styled.Anchor href={postUrl as string} target="_blank" rel="noreferrer">
          {`View on ${CampaignSocialMediaType.INSTAGRAM}`}
        </Styled.Anchor>
      </Styled.Title>
      <Styled.EngagementContainer>
        <Styled.Engagement>
          <Styled.Icon src={switchImage(SocialAccountType.INSTAGRAM, true)} alt="" />
          <Styled.CountWrapper>
            {analysis && (
              <>
                <Styled.Wrapper>
                  <Styled.Value>{formatIntNumber(analysis.likes)}</Styled.Value>
                  <Styled.Unit text={t('Likes')} lines={1} />
                </Styled.Wrapper>
                <Styled.Wrapper>
                  <Styled.Value>{formatIntNumber(analysis.comments)}</Styled.Value>
                  <Styled.Unit text={t('Comments')} lines={1} />
                </Styled.Wrapper>

                {analysis.views !== undefined && (
                  <Styled.Wrapper>
                    <Styled.Value>{formatIntNumber(analysis.views)}</Styled.Value>
                    <Styled.Unit text={t('View')} lines={1} />
                  </Styled.Wrapper>
                )}
                {!hasViewColumn && (
                  <>
                    {analysis.saves !== undefined && (
                      <Styled.Wrapper>
                        <Styled.Value>{formatIntNumber(analysis.saves)}</Styled.Value>
                        <Styled.Unit text={t('Save')} lines={1} />
                      </Styled.Wrapper>
                    )}

                    {analysis.reach !== undefined && (
                      <Styled.Wrapper>
                        <Styled.Value>{formatIntNumber(analysis.reach)}</Styled.Value>
                        <Styled.Unit text={t('Reach')} lines={1} />
                      </Styled.Wrapper>
                    )}

                    {analysis.impressions !== undefined && (
                      <Styled.Wrapper>
                        <Styled.Value>{formatIntNumber(analysis.impressions)}</Styled.Value>
                        <Styled.Unit text={t('Imps')} lines={1} />
                      </Styled.Wrapper>
                    )}
                  </>
                )}
              </>
            )}

            {sentiment && (
              <>
                <Styled.Wrapper>
                  <Styled.Value>{formatPercent(sentiment.positiveRate, true, 2)}</Styled.Value>
                  <Styled.Unit text={t('Positive Rate')} lines={1} />
                </Styled.Wrapper>

                <Styled.Wrapper>
                  <Styled.Value>{formatPercent(sentiment.negativeRate, true, 2)}</Styled.Value>
                  <Styled.Unit text={t('Negative Rate')} lines={1} />
                </Styled.Wrapper>
              </>
            )}

            {storyData && (
              <>
                <Styled.Wrapper>
                  <Styled.Value>{formatIntNumber(storyData.comments)}</Styled.Value>
                  <Styled.Unit text={t('Comments')} lines={1} />
                </Styled.Wrapper>

                <Styled.Wrapper>
                  <Styled.Value>{formatIntNumber(storyData.reach)}</Styled.Value>
                  <Styled.Unit text={t('Reach')} lines={1} />
                </Styled.Wrapper>

                <Styled.Wrapper>
                  <Styled.Value>{formatIntNumber(storyData.impressions)}</Styled.Value>
                  <Styled.Unit text={t('Imps')} lines={1} />
                </Styled.Wrapper>

                <Styled.Wrapper>
                  <Styled.Value>{formatIntNumber(storyData.exits)}</Styled.Value>
                  <Styled.Unit text={t('Exits')} lines={1} />
                </Styled.Wrapper>

                <Styled.Wrapper>
                  <Styled.Value>{formatIntNumber(storyData.tapsForward)}</Styled.Value>
                  <Styled.Unit text={t('Tapforward')} lines={1} />
                </Styled.Wrapper>

                <Styled.Wrapper>
                  <Styled.Value>{formatIntNumber(storyData.tapsBack)}</Styled.Value>
                  <Styled.Unit text={t('Tap back')} lines={1} />
                </Styled.Wrapper>
              </>
            )}

            {taggedPost && (
              <>
                <Styled.Wrapper>
                  <Styled.Value>{formatIntNumber(taggedPost.likes)}</Styled.Value>
                  <Styled.Unit text={t('Likes')} lines={1} />
                </Styled.Wrapper>
                <Styled.Wrapper>
                  <Styled.Value>{formatIntNumber(taggedPost.comments)}</Styled.Value>
                  <Styled.Unit text={t('Comments')} lines={1} />
                </Styled.Wrapper>
              </>
            )}
          </Styled.CountWrapper>
        </Styled.Engagement>
      </Styled.EngagementContainer>
    </Styled.MainContainer>
  );
};

export default InstagramEngagementInfo;
