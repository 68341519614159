import * as React from 'react';
import { useQuery } from '@apollo/client';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { useQueryHelper } from '@src/libs/hooks';
import { useSort } from '@src/libs/filter';
import { getInstagramFeedPostSortFieldType } from '@src/components/organisms/PostFeedList/PostFeedList/helpers';
import { InstagramFeedPostSortField } from '@src/__generated__/globalTypes';
import Template from './template';
import {
  InfluencerAllInstagramFeedPosts,
  InfluencerAllInstagramFeedPostsVariables,
} from './__generated__/InfluencerAllInstagramFeedPosts';
import * as INFLUENCER_ALL_INSTAGRAM_FEED_POSTS from './InfluencerAllInstagramFeedPosts.graphql';
import { feedPostVariables, PostFeedProps } from './utils';

const PostFeed = (props: PostFeedProps) => {
  const { t, history, enqueueSnackbar, search } = useQueryHelper();
  const sortData = useSort<InstagramFeedPostSortField>(getInstagramFeedPostSortFieldType);

  const { data, loading, error } = useQuery<InfluencerAllInstagramFeedPosts, InfluencerAllInstagramFeedPostsVariables>(
    INFLUENCER_ALL_INSTAGRAM_FEED_POSTS,
    { variables: feedPostVariables(props, sortData) }
  );

  if (loading || !data) {
    return <ListIndicator />;
  }

  if (error) {
    history.push(`/analytics${search}`);
    enqueueSnackbar(t(error?.message || ''), { variant: 'error' });
  }

  return (
    <Template
      total={data?.getTotalNumber?.totalNumber || 0}
      posts={data?.allInstagramFeedPosts || []}
      accountName={data?.influencerProfileV2?.name}
      userId={props.userId}
      currentPage={props.currentPage}
      filter={props.filter}
      setFilter={props.setFilter}
      accountId={props.accountId}
      sortData={sortData}
    />
  );
};

export default PostFeed;
