import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import LanguageMenu, { LanguageMenuChildProps } from '@src/components/organisms/LanguageMenu';
import LanguageSwitcher from '@src/components/molecules/LanguageSwitcher';
import { SignUpAndSignInBreakpoint } from '@src/libs/theme';
import MenuLink from './MenuLink';

interface TopPageFooterProps {
  className?: string;
}

const TopPageFooter = ({ className }: TopPageFooterProps) => {
  const { t, i18n } = useTranslation();

  return (
    <Footer className={className}>
      <FooterRow>
        <Copyright>Copyright © AnyMind Group. All right reserved.</Copyright>
        <TermsWrapper>
          <TermAnchor>
            <MenuLinkStyled href="/privacy" title={t('Privacy Policy')} />
            <MenuLinkStyled href="/terms" title={t('Terms Of Service')} />
            <Anchor href={`https://any-creator.com/${i18n.language}/contact/`} target="_blank" rel="noopener">
              {t('Contact')}
            </Anchor>
          </TermAnchor>
          <LanguageMenu>
            {({ title, visible, handleButtonClick, handleSwitcherClick }: LanguageMenuChildProps) => (
              <>
                <LanguageButton onClick={handleButtonClick}>
                  <span>{title}</span>
                  <i className="material-icons">keyboard_arrow_down</i>
                </LanguageButton>
                <SwitcherWrapper>
                  <LanguageSwitcher visible={visible} onClick={handleSwitcherClick} />
                </SwitcherWrapper>
              </>
            )}
          </LanguageMenu>
        </TermsWrapper>
      </FooterRow>
    </Footer>
  );
};

const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: auto;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    padding-top: 16px;
  }
`;

const FooterRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 24px 0;
  width: 100%;
`;

const Copyright = styled.div`
  color: #6e7c89;
  display: flex;
  flex-basis: 40%;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    flex-basis: 100%;
    justify-content: center;
  }
`;

const Anchor = styled.a`
  display: flex;
  box-sizing: border-box;
  padding: 0 14px;
  color: #6e7c89;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;

  &:hover {
    text-decoration: underline;
  }
`;

const MenuLinkStyled = styled(MenuLink)`
  font-size: 12px;
`;

const TermsWrapper = styled.div`
  display: flex;
  flex-basis: 60%;
  flex-wrap: wrap;
  justify-content: flex-end;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    flex-basis: 100%;
    justify-content: center;
  }
`;

const TermAnchor = styled.div`
  display: flex;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    flex-basis: 100%;
    justify-content: center;
    margin: 16px 0;
  }
`;

const LanguageButton = styled.button`
  border-left: 1px solid #dee5ec;
  border-radius: 3px;
  color: #6e7c89;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  padding: 0 14px;

  & i {
    font-size: 16px;
    margin-left: 5px;
  }

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    align-items: center;
    background-color: #fff;
    border: 1px solid #dee5ec;
    color: #27313b;
    flex-basis: 100%;
    height: 32px;
    justify-content: center;
    width: auto;
  }
`;

const SwitcherWrapper = styled.div`
  & ul {
    bottom: 28px;
  }
`;

export default TopPageFooter;
