import React from 'react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { useQueryHelper, useYourJobPageCount } from '../../../libs/hooks';
import { ProfileCoverHeader } from './ProfileCoverHeader';

export const NavLinkBar = () => {
  const { t, pathname, path } = useQueryHelper();

  const {
    yourJobPageCount: { progress, finished },
  } = useYourJobPageCount();

  const isYourJobPage = pathname === '/job' || pathname === '/job/finished';
  const isProfileSettingsPage = pathname === '/profile/settings';
  const isProfilePage = path === '/profile:active_tab?';

  return (
    <Wrapper hasBoxShadow={!isProfileSettingsPage}>
      {isYourJobPage && (
        <TabsWrapper>
          <StyledNavLink to="/job" exact activeClassName="selected">
            <Count>{progress}</Count>
            <TabTitle>{t('Progress')}</TabTitle>
          </StyledNavLink>
          <StyledNavLink to="/job/finished" exact activeClassName="selected">
            <Count>{finished}</Count>
            <TabTitle>{t('Finished')}</TabTitle>
          </StyledNavLink>
        </TabsWrapper>
      )}
      {(isProfilePage || isProfileSettingsPage) && (
        <>
          <ProfileCoverHeader />
          <TabsWrapper>
            <StyledNavLink to="/profile" exact activeClassName="selected" isActive={() => isProfilePage} height="48px">
              <ProfileTabsTitle>{t('Profile & Insight')}</ProfileTabsTitle>
            </StyledNavLink>
            <StyledNavLink to="/profile/settings" exact activeClassName="selected" height="48px">
              <ProfileTabsTitle>{t('Settings')}</ProfileTabsTitle>
            </StyledNavLink>
          </TabsWrapper>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ hasBoxShadow: boolean }>`
  ${({ hasBoxShadow }) => hasBoxShadow && 'box-shadow: 0 2px 0 0 #dee5ec, 0 5px 6px 1px #0000000c; z-index: 1;'}
`;

const TabsWrapper = styled.div`
  display: flex;
  background-color: #fff;
`;
const ProfileTabsTitle = styled.h3<{ paddingLeft?: string }>`
  font-size: 14px;
  letter-spacing: normal;
  color: #27313b;
  padding-left: 16px;
`;
const StyledNavLink = styled(NavLink)<{
  height?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  height: ${({ height }) => height || '60px'};
  color: #6e7c89;

  &.selected {
    border-bottom: 2px solid #ff2b52;
    color: #27313b;

    span {
      opacity: 1;
    }

    ${ProfileTabsTitle} {
      color: #ff2b52;
    }
  }

  &:first-of-type {
    border-right: 1px solid #dee5ec;
  }
`;
const Count = styled.span`
  font-size: 18px;
  opacity: 0.4;
  text-align: center;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #27313b;
`;
const TabTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
`;
