import { css } from '@emotion/core';
import React from 'react';
import styled from '@emotion/styled';
import {
  YoutubeAnalyticsTagRankingSortInput,
  YoutubeAnalyticsTagRankingSortOrder,
} from '@src/__generated__/globalTypes';
import { Period } from '@src/components/molecules/DayPicker/types';
import { ListIndicator } from '@src/components/molecules/Indicator';
import PageCount from '@src/components/molecules/PageCount';
import { SliderComponents } from '@src/components/molecules/SliderTable';
import TagRankingTable from '@src/components/organisms/AnalyticsYoutube/Dashboard/TagRankingList/TagRankingTable';
import { LIMIT } from '@src/libs/constant';
import { SortType } from '@src/libs/filter';
import BasePostFilter from '@src/components/organisms/AnalyticsShared/BasePostListFilter';
import { getOffset, getPageInfo } from '@src/libs/paging';
import { useQuery } from '@apollo/client';
import { BasePostFilterType } from '@src/components/organisms/AnalyticsShared/BasePostListFilter/types';
import { useMediaQuery } from 'react-responsive';
import { ViewportType } from '@src/libs/theme';
import Pager from '@src/components/molecules/Pager';
import PostFeedFilters from '@src/components/organisms/PostFeedList/PostFeedFilters';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { useQueryHelper } from '@src/libs/hooks';
import * as INFLUENCER_TAGS_TOP_RANKING_LIST from './InfluencerYoutubeAnalyticsTagsTopRankingList.graphql';
import {
  InfluencerYoutubeAnalyticsTagsTopRankingList,
  InfluencerYoutubeAnalyticsTagsTopRankingListVariables,
} from './__generated__/InfluencerYoutubeAnalyticsTagsTopRankingList';

interface TemplateProps {
  currentPage: number;
  filter: { keyword: string; period: Period };
  setFilter: (filter: BasePostFilterType) => void;
  sort: SortType<YoutubeAnalyticsTagRankingSortOrder>;
  setSort: (sort: SortType<YoutubeAnalyticsTagRankingSortOrder>) => void;
  socialMediaAccountId: string;
}

const Template = ({ filter, setFilter, sort, setSort, currentPage, socialMediaAccountId }: TemplateProps) => {
  const { data, loading } = useQuery<
    InfluencerYoutubeAnalyticsTagsTopRankingList,
    InfluencerYoutubeAnalyticsTagsTopRankingListVariables
  >(INFLUENCER_TAGS_TOP_RANKING_LIST, {
    variables: {
      socialAccountId: Number(socialMediaAccountId),
      keyword: filter.keyword,
      offset: getOffset(currentPage),
      limit: LIMIT,
      orderBy: sort as YoutubeAnalyticsTagRankingSortInput,
      ...filter.period,
    },
  });
  const isDesktopView = useMediaQuery({ query: `(min-width:${ViewportType.TABLET}px)` });
  const { search } = useQueryHelper();

  if (loading) {
    return (
      <ListIndicator
        css={css`
          margin: 0;
        `}
      />
    );
  }

  const { totalCount, totalPages, firstIndex, lastIndex } = getPageInfo(currentPage, data?.getTotalNumber.totalNumber);

  return (
    <>
      {isDesktopView ? (
        <Container>
          <div css={{ marginBottom: '24px' }}>
            <BackNavigator title="Video List" to={`/analytics${search}`} />
          </div>
          <BasePostFilter filter={filter} setFilter={setFilter} />
          <ButtonWrapper css={borderTop}>
            <PageCount total={totalCount} countLabel="Videos" />
          </ButtonWrapper>
          <SliderComponents.Wrapper css={[marginWrapper, padding0]}>
            <TagRankingTable
              tags={data?.tagsTopRanking.tags}
              sort={sort}
              setSort={setSort}
              firstIndex={firstIndex}
              isDesktopView
            />
          </SliderComponents.Wrapper>
          <SliderComponents.Pager
            currentPage={currentPage}
            totalPages={totalPages}
            totalCount={totalCount}
            first={firstIndex}
            last={lastIndex}
          />
        </Container>
      ) : (
        <Wrapper>
          <Card>
            <PostFeedFilters filter={filter} setFilter={setFilter} />
            <TagRankingTable
              tags={data?.tagsTopRanking.tags}
              sort={sort}
              setSort={setSort}
              firstIndex={firstIndex}
              isDesktopView={false}
            />
          </Card>
          <Pager
            currentPage={currentPage}
            totalPages={totalPages}
            totalCount={totalCount}
            first={firstIndex}
            last={lastIndex}
          />
        </Wrapper>
      )}
    </>
  );
};

const borderTop = css`
  border-top: 1px solid #dee5ec;
`;
const padding0 = css`
  padding: 0;
`;
const marginWrapper = css`
  margin: 0;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
`;

const Card = styled.div`
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5);
  background-color: #fff;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px 24px 0;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 35px;
  padding: 8px 15px;
  background-color: #fff;
  border: 1px solid #dee5ec;
  border-top: none;
  border-bottom: none;
`;

export default Template;
