import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import landingCover from '@src/assets/img/landingCover.jpg';
import analyticsTablet from '@src/assets/img/analyticsTablet.png';
import { SignUpAndSignInBreakpoint } from '@src/libs/theme';
import Footer from '../Footer';
import TopPageCover from '../Cover/index_backup';
import TopPageHeader from '../Header';

export interface TopPageContainerProps {
  children: ReactNode;
  showGoBackArrow?: boolean;
}

const TopPageContainer = (props: TopPageContainerProps) => {
  const { children, showGoBackArrow = false } = props;
  const { t } = useTranslation();
  const isWithinIframe = window.location !== window.parent.location;

  return (
    <Container>
      <ContentWrapper>
        {!isWithinIframe ? <TopPageHeader /> : null}
        <Wrapper>
          <FormWrapper>
            {showGoBackArrow && (
              <AnchorBack to="/">
                <Icon className="material-icons">arrow_back</Icon>
                <AnchorBackText>{t('Anchor.Back')}</AnchorBackText>
              </AnchorBack>
            )}
            {children}
            {!isWithinIframe ? <Footer /> : null}
          </FormWrapper>
          {isWithinIframe ? (
            <UuumCover>
              <TextBlock>
                <TextItem css={{ fontSize: '15px' }}>チャンネルアカウント管理</TextItem>
                <TextItem>視聴数や視聴者維持率などの重要指標の推移や、オーディエンス</TextItem>
                <TextItem>属性の変化、投稿ごとのインサイトなどをまとめて分析</TextItem>
              </TextBlock>
              <TextBlock css={{ marginTop: '40px' }}>
                <TextItem css={{ fontSize: '15px' }}>競合分析で強み・弱みを把握</TextItem>
                <TextItem>競合チャンネルの登録者推移やオーディエンス属性、投稿内容・</TextItem>
                <TextItem>インサイトなどを分析して、動画作成に応用</TextItem>
              </TextBlock>
              <img src={analyticsTablet} width="406" height="333" css={{ marginTop: '37px' }} />
            </UuumCover>
          ) : (
            <TopPageCover img={landingCover} />
          )}
        </Wrapper>
      </ContentWrapper>
    </Container>
  );
};

const UuumCover = styled.div`
  padding: 56px 0 56px 34px;
  border-radius: 0 7px 7px 0;
  background: linear-gradient(154.9deg, #0f83b0 15.5%, #99d8f0 97.03%);

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    display: none;
  }
`;
const TextBlock = styled.div`
  border-left: 3px solid #fff;
  padding-left: 9px;
`;
const TextItem = styled.p`
  font-family: Hiragino Kaku Gothic Pro, sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: #fff;
`;
const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  background-color: #27313b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    justify-content: flex-start;
  }
`;

const ContentWrapper = styled.div`
  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

const Wrapper = styled.section`
  display: flex;
  align-content: center;
  width: 856px;
  height: 675px;
  border-radius: 7px;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    border-radius: 0;
    width: 100%;
    flex: 1;
  }
`;

const FormWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 416px;
  padding: 8px 45px 24px;
  border-radius: 7px 0 0 7px;
  background-color: #fff;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    width: 100%;
    border-radius: 0;
    padding: 24px 24px 16px;
  }
`;

const AnchorBack = styled(Link)`
  display: flex;
  align-items: center;
`;

const Icon = styled.i`
  color: #ff4b61;
  font-size: 22px;
  margin-right: 8px;
`;

const AnchorBackText = styled.p`
  font-size: 16px;
  color: #27313b;
`;

export default TopPageContainer;
