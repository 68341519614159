import styled from '@emotion/styled';
import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { ApolloError } from '@apollo/client';
import {
  SocialAccountType,
  SocialMediaTypeForRateCard,
  UpdateInfluencerRateCardInput,
} from '../../../../__generated__/globalTypes';
import { useQueryHelper, useUpdateDocumentTitle } from '../../../../libs/hooks';
import { SelectedSocialAccount } from '../../SignUpConnectForm/types';
import { SocialButton } from '../../../atoms/Button';
import { ConnectType } from '../../../../libs/auth';
import { ViewportBreakpoint } from '../../../../libs/theme';
import { WarningWidget } from '../../../molecules/WarningWidget';
import ChooseFBPageAndIGAccountForm from '../ChooseFBPageAndIGAccountForm';
import PriceCard from '../../../molecules/PriceCard';
import { SocialAccounts } from './types';
import { SocialAccountTile } from './SocialAccountTile';
import { ConnectableFanPageAccountsProfile_connectableFacebookPages_pages } from './__generated__/ConnectableFanPageAccountsProfile';
import ModalForChooseFanpage from './ModalForChooseFanpage';
import { reconnectIgWarningHeader, reconnectIgWarningBody } from './helpers';
import { GetInfluencerRateCardForInfluencer_getInfluencerRateCardForInfluencer } from './__generated__/GetInfluencerRateCardForInfluencer';
import * as UPDATE_CARD_RATE from './UpdateInfluencerRateCard.graphql';
import { UpdateInfluencerRateCard, UpdateInfluencerRateCardVariables } from './__generated__/UpdateInfluencerRateCard';
export interface ProfileConnectProps extends SocialAccounts {
  className?: string;
  onClick: (socialMedia: SocialAccountType) => () => void;
  loading: boolean;
  fanPages: ConnectableFanPageAccountsProfile_connectableFacebookPages_pages[];
  priceCards?: GetInfluencerRateCardForInfluencer_getInfluencerRateCardForInfluencer | null;
  selectedFanPageIds: string[];
  setSelectedFanPageIds: (ids: string[]) => void;
  handleConnectFBAccountAndFanpages: () => void;
  handleConnectIGAccount: () => void;
  fanPageDialog: {
    isOpening: boolean;
    close: () => void;
    step: number;
    handleNext: () => void;
    handleBack: () => void;
  };
  igAccountDialog: {
    isOpening: boolean;
    close: () => void;
  };
  connectType?: ConnectType;
}

type priceKey = 'facebook' | 'instagram' | 'instagramStory' | 'twitter' | 'youtube' | 'tiktok';

const ProfileConnectForm = (props: ProfileConnectProps) => {
  const title = `pageTitle.Connect Social Accounts`;
  const pageTitle = 'pageTitle.Social Account Connect';
  const href = '/profile/settings';
  useUpdateDocumentTitle({ title, pageTitle, href });
  const { t, enqueueSnackbar } = useQueryHelper();
  const {
    className,
    facebook,
    priceCards,
    facebookFanpages,
    instagramAccounts,
    twitterAccounts,
    tiktokAccounts,
    youtubeAccounts,
    onClick,
    loading,
    fanPages,
    selectedFanPageIds,
    setSelectedFanPageIds,
    handleConnectFBAccountAndFanpages,
    handleConnectIGAccount,
    fanPageDialog,
    igAccountDialog,
    connectType,
  } = props;
  const isFBorIGConnected = !!facebook !== null || !!instagramAccounts.length;
  const hasReconnectIgBusinessWarning = instagramAccounts.some(igAccount => !igAccount.isBusiness);

  const [getPayload, setPayload] = useState<UpdateInfluencerRateCardInput | null>(null);
  const [prices, setPrices] = useState<
    GetInfluencerRateCardForInfluencer_getInfluencerRateCardForInfluencer | null | undefined
  >(priceCards);

  // https://adasiaholdings.atlassian.net/browse/ATTA-1283 -> we do not show rate card for TA/PA influencer
  const canSetPriceCard = priceCards !== null;

  const [updateCardRate] = useMutation<UpdateInfluencerRateCard, UpdateInfluencerRateCardVariables>(UPDATE_CARD_RATE, {
    onError: useCallback((error: ApolloError) => {
      enqueueSnackbar(t(error.message || 'UnexpectedError'), { variant: 'error' });
    }, []),
    onCompleted: useCallback(() => {
      const key: priceKey | undefined = getPayload?.socialAccountType
        .toLocaleLowerCase()
        .replace('instagram_story', 'instagramStory') as priceKey;

      setPrices(prevState =>
        !!prevState && typeof key === 'string'
          ? {
              ...prevState,
              [key]: {
                ...prevState[key],
                price: getPayload?.price || 0,
              },
            }
          : null
      );
    }, [getPayload]),
  });

  const handlePriceChange = (socialAccountType: SocialMediaTypeForRateCard, price: number) => {
    setPayload({ price, socialAccountType });
    updateCardRate({
      variables: { input: { price, socialAccountType } },
    });
  };

  return (
    <>
      <ResponsiveContainer>
        <SocialAccountsWrapper>
          {hasReconnectIgBusinessWarning && (
            <WarningWidget warningTitle={reconnectIgWarningHeader} warningReason={reconnectIgWarningBody} />
          )}
          {isFBorIGConnected ? (
            <>
              <SocialAccountTile
                socialMedia={SocialAccountType.FACEBOOK}
                connectedAccounts={facebook ? [facebook, ...facebookFanpages] : null}
                onClick={handleConnectFBAccountAndFanpages}
                pricesCards={
                  canSetPriceCard ? (
                    <PriceCard
                      price={Number(prices?.facebook?.price || 0)}
                      currency={prices?.currencyId || ''}
                      socialMedia={SocialMediaTypeForRateCard.FACEBOOK}
                      onChange={handlePriceChange}
                    />
                  ) : null
                }
                className={className}
              />
              <SocialAccountTile
                socialMedia={SocialAccountType.INSTAGRAM}
                connectedAccounts={instagramAccounts.length ? instagramAccounts : null}
                pricesCards={
                  canSetPriceCard ? (
                    <>
                      <PriceCard
                        price={Number(prices?.instagram?.price || 0)}
                        currency={prices?.currencyId || ''}
                        socialMedia={SocialMediaTypeForRateCard.INSTAGRAM}
                        onChange={handlePriceChange}
                      />
                      <PriceCardWithMargin
                        price={Number(prices?.instagramStory?.price || 0)}
                        currency={prices?.currencyId || ''}
                        socialMedia={SocialMediaTypeForRateCard.INSTAGRAM_STORY}
                        onChange={handlePriceChange}
                      />
                    </>
                  ) : null
                }
                onClick={handleConnectIGAccount}
              />
            </>
          ) : (
            <SocialConnectButton
              onClick={handleConnectFBAccountAndFanpages}
              socialMedia={SocialAccountType.FACEBOOK}
              title="Connect Facebook Instagram"
              disabled={loading}
              hasIcon={true}
              comboButton={true}
            />
          )}
          <SocialAccountTile
            socialMedia={SocialAccountType.TWITTER}
            connectedAccounts={twitterAccounts.length ? twitterAccounts : null}
            loading={loading}
            pricesCards={
              canSetPriceCard ? (
                <PriceCard
                  price={Number(prices?.twitter?.price || 0)}
                  currency={prices?.currencyId || ''}
                  socialMedia={SocialMediaTypeForRateCard.TWITTER}
                  onChange={handlePriceChange}
                />
              ) : null
            }
            onClick={onClick(SocialAccountType.TWITTER)}
          />
          <SocialAccountTile
            socialMedia={SocialAccountType.YOUTUBE}
            connectedAccounts={youtubeAccounts.length ? youtubeAccounts : null}
            loading={loading}
            pricesCards={
              canSetPriceCard ? (
                <PriceCard
                  price={Number(prices?.youtube?.price || 0)}
                  currency={prices?.currencyId || ''}
                  socialMedia={SocialMediaTypeForRateCard.YOUTUBE}
                  onChange={handlePriceChange}
                />
              ) : null
            }
            onClick={onClick(SocialAccountType.YOUTUBE)}
          />
          <SocialAccountTile
            socialMedia={SocialAccountType.TIKTOK}
            connectedAccounts={tiktokAccounts.length ? tiktokAccounts : null}
            loading={loading}
            pricesCards={
              canSetPriceCard ? (
                <PriceCard
                  price={Number(prices?.tiktok?.price || 0)}
                  currency={prices?.currencyId || ''}
                  socialMedia={SocialMediaTypeForRateCard.TIKTOK}
                  onChange={handlePriceChange}
                />
              ) : null
            }
            onClick={onClick(SocialAccountType.TIKTOK)}
          />
        </SocialAccountsWrapper>
      </ResponsiveContainer>
      <ModalForChooseFanpage
        facebook={facebook}
        fanPages={fanPages as SelectedSocialAccount[]}
        selectedFanPageIds={selectedFanPageIds}
        setSelectedFanPageIds={setSelectedFanPageIds}
        {...fanPageDialog}
      />
      {connectType === ConnectType.CONNECT_IG_ACCOUNT && (
        <ChooseFBPageAndIGAccountForm
          {...igAccountDialog}
          isProfileConnectPage
          refetchQueries={['CheckConnectedSocialAccountsForProfile']}
        />
      )}
    </>
  );
};

const ResponsiveContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const SocialAccountsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0 16px 16px;
  width: 100%;

  @media (min-width: ${ViewportBreakpoint.MEDIUM}px) {
    padding: 0 64px 16px;
  }

  @media (min-width: ${ViewportBreakpoint.DESKTOP}px) {
    max-width: 640px;
  }
`;

const PriceCardWithMargin = styled(PriceCard)`
  margin-top: 4px;
`;

const SocialConnectButton = styled(SocialButton)`
  height: 40px;
  border-radius: 20px;
  text-transform: uppercase;
`;

export default ProfileConnectForm;
