import React, { useState, SyntheticEvent } from 'react';
import { useQueryHelper } from '@src/libs/hooks';
import { useMutation } from '@apollo/client';
import { defaultInfluencerAvatar } from '@src/libs/image';
import { mainBlue } from '@src/libs/pallete';
import { PalleteButton } from '@src/components/atoms/Button';
import { DialogModal, DialogTitle, DialogContent } from '@src/components/molecules/DialogModal';
import { useMediaQuery } from 'react-responsive';
import { ViewportBreakpoint } from '@src/libs/theme';
import { ComparableYtAccountType } from '../types';
import {
  InfluencerAddYoutubeCompareAccountAnalytics,
  InfluencerAddYoutubeCompareAccountAnalyticsVariables,
} from './__generated__/InfluencerAddYoutubeCompareAccountAnalytics';
import {
  InfluencerDeleteYoutubeCompareAccountAnalytics,
  InfluencerDeleteYoutubeCompareAccountAnalyticsVariables,
} from './__generated__/InfluencerDeleteYoutubeCompareAccountAnalytics';
import * as ADD_COMPARABLE_ACCOUNT from './InfluencerAddYoutubeCompareAccountAnalytics.graphql';
import * as DELETE_COMPARABLE_ACCOUNT from './InfluencerDeleteYoutubeCompareAccountAnalytics.graphql';
import * as StyledCards from './AccountCardsStyled';
import MobileTemplate from './MobileTemplate';
import DesktopTemplate from './DesktopTemplate';

interface AccountsCardsType {
  id: number;
  comparableAccounts: ComparableYtAccountType[];
  setComparableAccounts: (ids: ComparableYtAccountType[]) => void;
  maxCards?: number;
}
const refetchQueries = [
  'InfluencerYoutubeAnalyticsCompareAccountCards',
  'InfluencerYoutubeCompareOverview',
  'InfluencerYoutubeCompareTagRanking',
  'InfluencerYoutubeComparePosts',
];
const AccountsCards = (props: AccountsCardsType) => {
  const { comparableAccounts, setComparableAccounts, id, maxCards } = props;
  const { t, enqueueSnackbar } = useQueryHelper();
  const isMobile = useMediaQuery({ maxWidth: ViewportBreakpoint.MOBILE });

  const [deletingAccount, setDeletingAccount] = useState<ComparableYtAccountType | undefined>(undefined);
  const [isOpenAddAccount, setOpenAddAccount] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');
  const [deleteComparableAccount, { loading: deleteLoading }] = useMutation<
    InfluencerDeleteYoutubeCompareAccountAnalytics,
    InfluencerDeleteYoutubeCompareAccountAnalyticsVariables
  >(DELETE_COMPARABLE_ACCOUNT, {
    refetchQueries,
  });
  const [addComparableAccount, { loading }] = useMutation<
    InfluencerAddYoutubeCompareAccountAnalytics,
    InfluencerAddYoutubeCompareAccountAnalyticsVariables
  >(ADD_COMPARABLE_ACCOUNT, {
    refetchQueries,
  });

  const toggleShowHide = (anchorId: number | null, elementId?: number | null) => {
    setComparableAccounts(
      comparableAccounts.map(item => {
        if (item.accountId === anchorId && item.elementId === elementId) {
          return { ...item, isSelected: !item.isSelected };
        }

        return item;
      })
    );
  };

  const handleDeleteComparableAccount = async () => {
    if (deletingAccount) {
      try {
        await deleteComparableAccount({
          variables: {
            input: {
              compareAccountId: deletingAccount.accountId,
              socialAccountId: id,
            },
          },
        });
        enqueueSnackbar(t('succeededInDeleting', { name: deletingAccount.channelName }), { variant: 'success' });
      } catch (error) {
        const message = error.message || 'failedToDelete';
        enqueueSnackbar(t(message), { variant: 'error' });
      } finally {
        setDeletingAccount(undefined);
      }
    }
  };
  const handleClickDelete = (e: SyntheticEvent<HTMLButtonElement>, uniqId: string) => {
    e.stopPropagation();
    setDeletingAccount(comparableAccounts.find(item => item.uniqId === uniqId));
  };
  const handleAddComparableAccount = async () => {
    try {
      await addComparableAccount({
        variables: {
          input: {
            url,
            socialAccountId: id,
          },
        },
      });

      setOpenAddAccount(false);
      enqueueSnackbar(t('succeededInCreating', { name: url }), { variant: 'success' });
    } catch (error) {
      const message = error.message || 'failedToCreate';
      enqueueSnackbar(t(message), { variant: 'error' });
    } finally {
      setUrl('');
    }
  };
  const handleCloseAddAccount = () => {
    setOpenAddAccount(false);
    setUrl('');
  };

  const maxComparableCards = maxCards || 5;

  return (
    <StyledCards.CardsWrapper>
      {comparableAccounts.map(account => {
        if (isMobile) {
          return (
            <MobileTemplate
              account={account}
              handleClickDelete={handleClickDelete}
              toggleShowHide={toggleShowHide}
              key={account.accountId}
            />
          );
        }

        return (
          <DesktopTemplate
            account={account}
            handleClickDelete={handleClickDelete}
            toggleShowHide={toggleShowHide}
            key={account.accountId}
          />
        );
      })}
      {comparableAccounts.length <= maxComparableCards && (
        <StyledCards.Card css={!isMobile ? { height: 110 } : ''}>
          <StyledCards.AddAccout onClick={() => setOpenAddAccount(true)}>+</StyledCards.AddAccout>
        </StyledCards.Card>
      )}
      <DialogModal
        isOpen={!!deletingAccount}
        BackButton={
          <PalleteButton text="Cancel" onClick={() => setDeletingAccount(undefined)} isDisabled={deleteLoading} />
        }
        NextButton={
          <PalleteButton
            text="Delete"
            palleteColor={mainBlue}
            onClick={handleDeleteComparableAccount}
            isDisabled={deleteLoading}
            loading={deleteLoading}
          />
        }
        closeModal={() => setDeletingAccount(undefined)}
      >
        <DialogTitle>{t('Dialog.Delete comparable Channel')}</DialogTitle>
        <DialogContent>
          <StyledCards.DialogContentWrapper>
            <p>{t('Dialog.DeleteComparableAccountWarning')}</p>
            <StyledCards.DialogAccountWrapper>
              <StyledCards.DialogAvatar src={defaultInfluencerAvatar(deletingAccount?.avatarUrl || null)} />
              <span>{deletingAccount?.channelName || ''}</span>
            </StyledCards.DialogAccountWrapper>
          </StyledCards.DialogContentWrapper>
        </DialogContent>
      </DialogModal>
      {/* add account */}
      <DialogModal
        isOpen={!!isOpenAddAccount}
        closeModal={handleCloseAddAccount}
        BackButton={<PalleteButton text="Cancel" onClick={handleCloseAddAccount} isDisabled={loading} />}
        NextButton={
          <PalleteButton
            text="Add"
            palleteColor={mainBlue}
            onClick={handleAddComparableAccount}
            isDisabled={loading || !url}
            loading={loading}
          />
        }
      >
        <DialogTitle>{t('Dialog.Add Channel To Compare')}</DialogTitle>
        <DialogContent>
          <form
            onSubmit={e => {
              e.preventDefault();
              handleAddComparableAccount();
            }}
          >
            <StyledCards.DialogContentWrapper>
              <p>{t('Dialog.Please enter YouTube channel URL that you wish to compare to')}</p>
              <StyledCards.DialogAccountWrapper>
                <StyledCards.StyledTextForm
                  title="YouTube"
                  name="youtubeChannelURL"
                  placeholder={t('YouTube channel URL')}
                  value={url}
                  onChange={e => setUrl(e.target.value)}
                  autoFocus
                />
              </StyledCards.DialogAccountWrapper>
            </StyledCards.DialogContentWrapper>
          </form>
        </DialogContent>
      </DialogModal>
    </StyledCards.CardsWrapper>
  );
};

export default AccountsCards;
