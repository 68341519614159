import * as React from 'react';
import HighChart, { Highcharts } from '@src/components/molecules/HighChart';
import DataOpacityWrapper, { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import { formatPercent } from '@src/libs/format';
import { useQueryHelper } from '@src/libs/hooks';
import { FollowerGenderRate } from './types';

const GenderBreakdown = (props: FollowerGenderRate) => {
  const { femaleRate, maleRate, otherRate, className } = props;
  const { t } = useQueryHelper();

  const options: Highcharts.Options = {
    chart: {
      height: 273,
      marginBottom: 35,
      plotShadow: false,
      type: 'pie',
    },
    legend: {
      enabled: true,
      symbolRadius: 2,
      itemStyle: {
        fontSize: '13px',
        lineHeight: '1.38',
      },
    },
    title: {
      text: undefined,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          distance: '-20',
          enabled: true,
          formatter() {
            return formatPercent(this.y || null);
          },
          style: {
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '1.13',
          },
          shadow: {
            offsetX: 0,
            offsetY: 2,
            opacity: 0.2,
            width: 2,
            color: '#27313b',
          },
          borderRadius: 5,
          backgroundColor: 'white',
          color: '#27313b',
        },
        showInLegend: true,
      },
    },
    series: [
      {
        type: 'pie',
        innerSize: '50%',
        colorByPoint: true,
        name: t('Gender'),
        data: [
          {
            name: t('Female') as string,
            y: femaleRate,
            color: '#ff8a94',
          },
          {
            name: t('Male') as string,
            y: maleRate,
            color: '#707fc0',
          },
          ...(otherRate && otherRate > 0
            ? [
                {
                  name: t('Other') as string,
                  y: otherRate,
                  color: '#ffca98',
                },
              ]
            : []),
        ],
      },
    ],
    tooltip: {
      padding: 0,
      formatter: function formatter(): string {
        return `
          <div style="display:flex;flex-direction:column;border-radius:3px;border: 2px solid #27313b;margin:-2px;background-color:#27313b;font-size:12px;padding:13px 16px;">
            <div style="margin-bottom:8px;">${t(`BasicInfo.${this.series.name}`)}</div>
            <div style="display:flex;align-items:center">
              <span style="display:inline-block;margin-right:8px;background-color:${
                this.point.color
              };height:12px;width:12px"></span>
              <span style="margin-right:16px">${t(this.key.toString())}:
              </span><span>${formatPercent(this.point.y || null)}</span>
            </div>
          </div>`;
      },
      pointFormat: '',
      borderColor: '#27313b',
      borderWidth: 0,
      backgroundColor: '#27313b',
      style: {
        color: '#ffffff',
      },
      useHTML: true,
      headerFormat: '',
    },
  };

  return (
    <DataOpacityWrapper
      header={<SectionHeader>{t('Title.Gender Breakdown')}</SectionHeader>}
      dataNotAvailable={!femaleRate && !maleRate && !otherRate}
      className={className}
    >
      <HighChart options={options} />
    </DataOpacityWrapper>
  );
};

export default GenderBreakdown;
