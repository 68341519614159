import { Formik } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import clock from '@src/assets/icon/chat/clock.svg';
import Empty from '@src/components/atoms/Empty';
import { Icon } from '@src/components/atoms/Icon';
import Dialog from '@src/components/molecules/Dialog';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { getActivityList } from '@src/components/organisms/FanActivity/helper';
import useGetFanActivities from '@src/components/organisms/FanActivity/ActivityTimeline/useGetFanActivities';
import useStateHandler from '@src/components/organisms/FanActivity/ActivityTimeline/useStateHandler';
import ActivityRow from '@src/components/organisms/FanActivity/ActivityTimeline/ActivityRow';
import useGetCreatorStaffs from '@src/pages/Settings/Users/useGetCreatorStaffs';
import { LIMIT } from '@src/libs/constant';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { FanActivityCommentSchema } from '@src/libs/validation';
import { Button } from '@src/components/atoms/Button';
import AddCommentInput from './AddCommentInput';

const Activity = () => {
  const { params, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const { activeChat, fanId } = params;
  const { data: dataCreatorStaffs } = useGetCreatorStaffs();
  const { data, loading, fetchMore } = useGetFanActivities({ fanId: Number(fanId) });

  const {
    activity,
    handleAddComment,
    handleClickMore,
    handleClickDelete,
    handleClickEdit,
    handleClose,
    handleDeleteConfirm,
    handleUpdateComment,
  } = useStateHandler(Number(fanId));

  const onClickViewMore = () => {
    fetchMore({
      variables: {
        input: { fanId: Number(fanId), offset: data?.getFanActivities?.activities.length || 0, limit: LIMIT },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return {
          ...prev,
          getFanActivities: {
            total: fetchMoreResult.getFanActivities?.total || 0,
            __typename: 'GetFanActivitiesPayload',
            activities: [
              ...(prev.getFanActivities?.activities || []),
              ...(fetchMoreResult.getFanActivities?.activities || []),
            ],
          },
        };
      },
    });
  };

  const activities = getActivityList(data?.getFanActivities?.activities || [], t);
  const creatorStaffs =
    dataCreatorStaffs?.getCreatorStaffs?.staffs.reduce<{ [key: string]: string }>(
      (prev, curr) => ({ ...prev, [curr.id]: curr.name }),
      {
        [dataCreatorStaffs?.getCreatorStaffs?.id]: dataCreatorStaffs?.getCreatorStaffs?.name,
      }
    ) || {};
  const total = data?.getFanActivities?.total || 0;

  return (
    <div css={styles.container}>
      {/* Menu */}
      <Menu anchorEl={activity.el} open={Boolean(activity.el)} onClose={handleClose}>
        <MenuItem onClick={handleClickEdit}>{t('Edit')}</MenuItem>
        <MenuItem onClick={handleClickDelete}>{t('Delete')}</MenuItem>
      </Menu>
      {/* Delete confirmation dialog */}
      <Dialog
        contentStyle={{ maxWidth: '400px' }}
        execText="Delete"
        visible={activity.showDeleteConfirmation}
        onClose={handleClose}
        onExec={handleDeleteConfirm}
      >
        <div css={styles.dialogContent}>
          <div>{t('Confirmation required')}</div>
          <div>{t('Are you sure you want to delete the activity?')}</div>
        </div>
      </Dialog>

      {!isMobileView && (
        <>
          <div css={styles.navigatorContainer}>
            <Link to={`/chat/${activeChat}/${fanId}`}>
              <Icon color="#6e7c89" icon="chevron_left" />
            </Link>
            <div>
              <img alt="icon" height="32" src={clock} width="32" />
            </div>
            <div>{t('MenuTitle.Activity')}</div>
          </div>
        </>
      )}

      <Formik
        initialValues={{ comment: '' }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={FanActivityCommentSchema}
        onSubmit={handleAddComment}
      >
        <AddCommentInput />
      </Formik>

      <ContentContainer isMobileView={isMobileView}>
        {loading ? (
          <ListIndicator />
        ) : activities.length > 0 ? (
          <div>
            {activities.map((item, index) => (
              <ActivityRow
                key={(item.activity || '') + index}
                created={item.created}
                activity={item.activity}
                activityType={item.activityType}
                commenter={item.commenterId ? creatorStaffs?.[item.commenterId] || '' : ''}
                onClickMore={e => handleClickMore(e, item.id)}
                showCommentInsideForm={activity.showCommentInsideForm && item.id === activity.id}
                onUpdate={handleUpdateComment}
              />
            ))}
          </div>
        ) : (
          <Empty />
        )}
        {activities.length < total && (
          <div css={styles.showMoreContainer}>
            <Button bgcolor="#fff" hoverbgcolor="#fff" title="More" onClick={onClickViewMore} />
          </div>
        )}
      </ContentContainer>
    </div>
  );
};

const ContentContainer = styled.div<{ isMobileView: boolean }>`
  display: grid;
  height: ${({ isMobileView }) => (isMobileView ? '80vh' : '55vh')};
  overflow-y: auto;
  padding-top: 18px;
  ${({ isMobileView }) => (isMobileView ? 'padding-bottom: 52px;' : '')}
`;

const styles = {
  container: css`
    display: grid;
    position: relative;
  `,
  dialogContent: css`
    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      margin-bottom: 24px;
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: 14px;
      margin-bottom: 16px;
    }
  `,
  navigatorContainer: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;

    & > a {
      align-items: center;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 2px 5px #dee5ec, 0 1px 4px rgba(222, 229, 236, 0.5);
      display: grid;
      height: 32px;
      justify-content: center;
      margin-right: 8px;
      width: 32px;

      & > i {
        margin: 0;
      }
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: 16px;
      font-weight: 600;
      margin-left: 8px;
    }
  `,
  showMoreContainer: css`
    background: linear-gradient(180deg, rgba(247, 248, 250, 0) 0%, #f7f8fa 49.4%);
    display: flex;
    margin-top: -56px;
    justify-content: center;
    z-index: 5;

    & > button {
      border: 1px solid #dee5ec;
      border-radius: 3px;
      color: #6e7c89;
      font-size: 12px;
      font-weight: 600;
      height: 32px;
      width: 79px;
    }
  `,
};

export default Activity;
