import { useTranslation } from 'react-i18next';

export const useHelpCenterUrl = () => {
  const { i18n } = useTranslation();
  const isJapaneseLang = i18n.language === 'ja';

  return {
    helpCenterUrl: isJapaneseLang
      ? 'https://anymind-group.gitbook.io/anycreator/'
      : 'https://anymind-group.gitbook.io/anycreator-eng-ver/',
    lineConnectHelp: isJapaneseLang
      ? 'https://anymind-group.gitbook.io/anyx/hajimeni/channel-idtochannel-secretno#2-noakauntowoshiwokurikku'
      : 'https://anymind-group.gitbook.io/anyx-help-page-english/faq/how-to-check-channel-id-and-channel-secret',
  };
};
