import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useQueryHelper } from '../../../libs/hooks';
import * as CONNECT_FB_AND_IG_ACCOUNTS from './ConnectFacebookAndInstagramAccounts.graphql';
import {
  ConnectFacebookPagesAndInstagramAccount,
  ConnectFacebookPagesAndInstagramAccountVariables,
} from './__generated__/ConnectFacebookPagesAndInstagramAccount';
import * as CONNECTABLE_INSTAGRAM_ACCOUNTS from './ConnectableInstagramAccounts.graphql';
import {
  ConnectableInstagramAccounts,
  ConnectableInstagramAccountsVariables,
} from './__generated__/ConnectableInstagramAccounts';
import { ConnectIgAccountProps } from './types';

export const useChooseConnectInstagramAccount = (props: ConnectIgAccountProps) => {
  const { selectedFanPageIds, refetchQueries, clickNext, clickPrev } = props;
  const { enqueueSnackbar, t } = useQueryHelper();

  const variables = { fbPageIds: selectedFanPageIds };

  const { data: igAccountsData, loading } = useQuery<
    ConnectableInstagramAccounts,
    ConnectableInstagramAccountsVariables
  >(CONNECTABLE_INSTAGRAM_ACCOUNTS, {
    variables,
  });

  const [connectFacebookAndInstagramAccount, { loading: connectingIG }] = useMutation<
    ConnectFacebookPagesAndInstagramAccount,
    ConnectFacebookPagesAndInstagramAccountVariables
  >(CONNECT_FB_AND_IG_ACCOUNTS, { refetchQueries });

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const handleClickNext = async () => {
    try {
      await connectFacebookAndInstagramAccount({
        variables: { input: { igUserIds: selectedIds, fbPageIds: selectedFanPageIds } },
      });
      enqueueSnackbar(t('connectFanpageSuccess'), { variant: 'success' });
    } catch (error) {
      clickPrev();
      enqueueSnackbar(t('failFanpageSelection'), { variant: 'error' });
    }

    clickNext();
  };

  const toggleSelectedIds = (accountId: string, isSelected: boolean) => {
    const updatedAccounts = isSelected ? selectedIds.filter(acc => acc !== accountId) : [...selectedIds, accountId];

    setSelectedIds(updatedAccounts);
  };

  return {
    handleClickNext,
    selectedIds,
    toggleSelectedIds,
    igAccountsData,
    loading,
    connectingIG,
  };
};
