import { useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import GET_UNREAD_NOTI_COUNT from './queries/GetUnreadNotificationCount.graphql';
import { GetUnreadNotificationCount } from './queries/__generated__/GetUnreadNotificationCount';

const useMenuQuery = () => {
  const { enqueueSnackbar, t } = useQueryHelper();

  const { data } = useQuery<GetUnreadNotificationCount>(GET_UNREAD_NOTI_COUNT, {
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  return {
    notificationCount: data?.countUnreadNotifications?.count || 0,
  };
};

export default useMenuQuery;
