import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useIntercom } from 'react-use-intercom';
import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import chooseBusinessAccount from '@src/assets/img/chooseBusinessAccount.png';
import chooseBusinessAccountJP from '@src/assets/img/chooseBusinessAccountJP.png';
import clickNextCompletion from '@src/assets/img/clickNextCompletion.png';
import clickNextCompletionJP from '@src/assets/img/clickNextCompletionJP.png';
import editProfile from '@src/assets/img/editProfile.png';
import editProfileJP from '@src/assets/img/editProfileJP.png';
import mobileAccount from '@src/assets/img/mobileAccount.png';
import mobileAccountJP from '@src/assets/img/mobileAccountJP.png';
import mobileChooseBusinessAccount from '@src/assets/img/mobileChooseBusinessAccount.png';
import mobileChooseBusinessAccountJP from '@src/assets/img/mobileChooseBusinessAccountJP.png';
import mobileSettings from '@src/assets/img/mobileSettings.png';
import mobileSettingsJP from '@src/assets/img/mobileSettingsJP.png';
import mobileSelectFBPages from '@src/assets/img/mobileSelectFBPages.png';
import mobileSelectFBPagesJP from '@src/assets/img/mobileSelectFBPagesJP.png';
import mobileSwitchProfessionalAccount from '@src/assets/img/mobileSwitchProfessionalAccount.png';
import mobileSwitchProfessionalAccountJP from '@src/assets/img/mobileSwitchProfessionalAccountJP.png';
import switchProfessionalAccount from '@src/assets/img/switchProfessionalAccount.png';
import switchProfessionalAccountJP from '@src/assets/img/switchProfessionalAccountJP.png';
import { Button } from '@src/components/atoms/Button';
import Grid from '@src/components/atoms/Grid';
import { Icon } from '@src/components/atoms/Icon';
import INFLUENCER_DETAIL_FOR_INTERCOM from '@src/components/organisms/Intercom/InfluencerDetailForIntercom.graphql';
import {
  InfluencerDetailForIntercom,
  InfluencerDetailForIntercomVariables,
} from '@src/components/organisms/Intercom/__generated__/InfluencerDetailForIntercom';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import TopPageFooter from '@src/components/organisms/TopPage/Footer';
import { ConnectType } from '@src/libs/auth';
import { ENABLED_INTERCOM_COUNTRIES, FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useAuthData, useQueryHelper, useRedirectWithinIframe } from '@src/libs/hooks';
import { useRedirectUrl } from '@src/libs/hooks/useRedirectUrl';
import { switchImage } from '@src/libs/SocialMedia';
import { ViewportType } from '@src/libs/theme';
import { SocialAccountType } from '@src/__generated__/globalTypes';

const InstagramUnableConnect = () => {
  const [currStep, setCurrStep] = useState<number>(0);
  const { enqueueSnackbar, i18n, t } = useQueryHelper();
  // FIXME: useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` }); should be isMobileView, consider user usePageLayout
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });
  const isMobileView = useMediaQuery({ query: `(max-width: ${ViewportType.SMALL}px)` });
  const { getRedirectUrl } = useRedirectUrl(FE_REDIRECT_MAPPING.CONNECT);
  const { startRedirectProcess } = useRedirectWithinIframe();
  const { show } = useIntercom();
  const { userId } = useAuthData();
  const isJPLanguage = i18n.language === 'ja';

  const registerAccountSteps = isMobileView
    ? [
        {
          image: isJPLanguage ? mobileSettingsJP : mobileSettings,
          title: 'Tutorial.Text.MobileRegisterYourAccountOne',
        },
        { image: isJPLanguage ? mobileAccountJP : mobileAccount, title: 'Tutorial.Text.MobileRegisterYourAccountTwo' },
        {
          image: isJPLanguage ? mobileSwitchProfessionalAccountJP : mobileSwitchProfessionalAccount,
          title: 'Tutorial.Text.MobileRegisterYourAccountThree',
        },
        {
          image: isJPLanguage ? mobileChooseBusinessAccountJP : mobileChooseBusinessAccount,
          title: 'Tutorial.Text.MobileRegisterYourAccountFour',
        },
        {
          image: isJPLanguage ? mobileSelectFBPagesJP : mobileSelectFBPages,
          title: 'Tutorial.Text.MobileRegisterYourAccountFive',
        },
      ]
    : [
        { image: isJPLanguage ? editProfileJP : editProfile, title: 'Tutorial.Text.RegisterYourAccountOne' },
        {
          image: isJPLanguage ? switchProfessionalAccountJP : switchProfessionalAccount,
          title: 'Tutorial.Text.RegisterYourAccountTwo',
        },
        {
          image: isJPLanguage ? chooseBusinessAccountJP : chooseBusinessAccount,
          title: 'Tutorial.Text.RegisterYourAccountThree',
        },
        {
          image: isJPLanguage ? clickNextCompletionJP : clickNextCompletion,
          title: 'Tutorial.Text.RegisterYourAccountFour',
        },
      ];

  const { data } = useQuery<InfluencerDetailForIntercom, InfluencerDetailForIntercomVariables>(
    INFLUENCER_DETAIL_FOR_INTERCOM,
    {
      variables: { pk: userId as number },
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  useEffect(() => {
    setCurrStep(0);
  }, [isMobileView]);

  async function onClickTryAgain() {
    localStorage.setItem('connectType', ConnectType.CONNECT_IG_ACCOUNT);
    try {
      const redirectUrl = await getRedirectUrl(SocialAccountType.FACEBOOK);

      if (redirectUrl) {
        startRedirectProcess({ redirectUrl, redirectType: FE_REDIRECT_MAPPING.CONNECT });
      }
    } catch (error) {
      enqueueSnackbar(t('UnexpectedError'), { variant: 'error' });
    }
  }

  const isEnabledIntercom = data?.influencerDetail
    ? ENABLED_INTERCOM_COUNTRIES.includes(data.influencerDetail.country.id)
    : false;

  function onClickContact() {
    if (isEnabledIntercom) {
      show();
    } else {
      window.open(isJPLanguage ? 'https://any-creator.com/ja/contact/' : 'https://any-creator.com/contact/', '_blank');
    }
  }

  return (
    <div css={styles.container}>
      {isDesktopView && (
        <div css={styles.navigatorContainer}>
          <BackNavigator title="Unable to connect" to="/settings/social_connect" />
        </div>
      )}

      <div css={styles.infoContainer}>
        <Grid md={8} sm={10} xs={12}>
          <div css={styles.contentContainer}>
            <div css={styles.content}>
              <div css={styles.contentHeader}>
                <img alt="igIcon" height="18" src={switchImage(SocialAccountType.INSTAGRAM, true)} width="18" />
                <span>{t(`Instagram couldn't be found`)}</span>
              </div>

              <div css={styles.contentDescription}>{t(`Annotation.Instagram couldn't be found`)}</div>

              <div css={styles.contentStepDescription}>{t('Tutorial.Heading.RegisterYourAccount')}</div>

              {registerAccountSteps.map((step, index) => (
                <div css={styles.contentStepContainer} key={index}>
                  <div>{index + 1}</div>
                  <div>{t(step.title)}</div>
                </div>
              ))}

              <div css={styles.contentImg}>
                <div>
                  {currStep !== 0 && (
                    <ImgPreviewButton css={styles.contentImgLeftBtn} onClick={() => setCurrStep(step => step - 1)}>
                      <Icon color="#27313b" icon="chevron_left" />
                    </ImgPreviewButton>
                  )}
                  <img alt="stepImg" src={registerAccountSteps[currStep]?.image} />
                  {currStep < registerAccountSteps.length - 1 && (
                    <ImgPreviewButton css={styles.contentImgRightBtn} onClick={() => setCurrStep(step => step + 1)}>
                      <Icon color="#27313b" icon="chevron_right" />
                    </ImgPreviewButton>
                  )}
                </div>
              </div>

              <div css={styles.contentCurrStepDescription}>{`${currStep + 1}. ${t(
                registerAccountSteps[currStep]?.title
              )}`}</div>
            </div>

            <div css={styles.actionContainer}>
              <StyledButton
                bgcolor="#fff"
                color="#6e7c89"
                hoverbgcolor="#fff"
                title="Contact"
                onClick={onClickContact}
              />
              <StyledButton
                bgcolor="#3892e5"
                color="#fff"
                hoverbgcolor="#3892e5"
                title="Try it Again"
                onClick={onClickTryAgain}
              />
            </div>
          </div>
        </Grid>
      </div>

      {isMobileView && <TopPageFooter />}
    </div>
  );
};

const ImgPreviewButton = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  top: 80px;
  width: 40px;

  @media (max-width: ${ViewportType.SMALL}px) {
    top: 140px;
  }
`;

const StyledButton = styled(Button)`
  border: 1px solid #dee5ec;
  border-radius: 3px;
  filter: brightness(1);
  font-size: 12px;
  font-weight: 600;
  height: 32px;
  padding: 0 16px;
  transition: 0.2s;
  width: fit-content;

  &:hover {
    filter: brightness(0.9);
  }
`;

const styles = {
  actionContainer: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 16px 24px;

    & > button:nth-of-type(1) {
      margin: 0 8px 8px 0;
    }

    @media (max-width: ${ViewportType.SMALL}px) {
      padding-top: 0;

      & > button {
        border-radius: 5px;
        height: 40px;
        width: 100%;
      }
    }
  `,
  container: css`
    margin: 16px 24px;

    @media (max-width: ${ViewportType.SMALL}px) {
      margin: 16px;
    }
  `,
  content: css`
    border-bottom: 1px solid #eef3f7;
    padding: 24px;

    @media (max-width: ${ViewportType.SMALL}px) {
      border-bottom: none;
    }
  `,
  contentContainer: css`
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
  `,
  contentCurrStepDescription: css`
    color: #27313b;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  `,
  contentDescription: css`
    color: #27313b;
    font-size: 14px;
    margin-bottom: 24px;
  `,
  contentHeader: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;

    & > span {
      color: #27313b;
      font-size: 18px;
      font-weight: 600;
      margin-left: 16px;
    }
  `,
  contentImg: css`
    display: flex;
    justify-content: center;
    margin: 24px 0;

    & > div {
      position: relative;

      & > img {
        height: 195px;
        max-width: 348px;
        width: 100%;

        @media (max-width: ${ViewportType.SMALL}px) {
          height: 308px;
          max-width: 231px;
        }
      }
    }
  `,
  contentImgLeftBtn: css`
    left: -20px;
  `,
  contentImgRightBtn: css`
    right: -20px;
  `,
  contentStepContainer: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;

    & > div:nth-of-type(1) {
      align-items: center;
      background-color: #000;
      border-radius: 50%;
      color: #fff;
      display: flex;
      font-size: 13px;
      font-weight: 600;
      justify-content: center;
      height: 24px;
      width: 24px;
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      display: flex;
      flex-basis: 88%;
      font-size: 14px;
      font-weight: 600;
      margin-left: 8px;
    }
  `,
  contentStepDescription: css`
    color: #27313b;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
  `,
  infoContainer: css`
    display: flex;
    justify-content: center;
  `,
  navigatorContainer: css`
    margin-bottom: 24px;
  `,
};

export default InstagramUnableConnect;
