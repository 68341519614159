import { useState } from 'react';

interface DownloadMenu {
  downloading: boolean;
  anchorEl: null | HTMLElement;
}

export default () => {
  const [donwloadMenu, setDownloadMenu] = useState<DownloadMenu>({ downloading: false, anchorEl: null });
  const [showImportModal, setShowImportModal] = useState(false);

  const setDownloadAnchorEl = (anchorEl: null | HTMLElement) => {
    setDownloadMenu(prevState => ({
      ...prevState,
      anchorEl,
    }));
  };

  const openDownloadMenu = (e: React.MouseEvent<HTMLElement>) => {
    setDownloadAnchorEl(e.currentTarget);
  };

  const closeDownloadMenu = () => {
    setDownloadAnchorEl(null);
  };

  const openImportModal = () => {
    setShowImportModal(true);
  };

  const closeImportModal = () => {
    setShowImportModal(false);
  };

  const onClickImport = () => {
    openImportModal();
    closeDownloadMenu();
  };

  return {
    donwloadMenu,
    openDownloadMenu,
    closeDownloadMenu,
    showImportModal,
    closeImportModal,
    onClickImport,
  };
};
