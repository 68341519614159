import styled from '@emotion/styled';
import React from 'react';
import { useUpdateDocumentTitle, useQueryHelper } from '@src/libs/hooks';
import AnalyticsAccounts from '@src/components/organisms/AnalyticsAccounts';
import { SocialMediaAccountType } from '@src/components/organisms/AnalyticsAccounts/types';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { SocialButton } from '@src/components/atoms/Button';
import { switchText, switchImage } from '@src/libs/SocialMedia';
import { ViewportType } from '@src/libs/theme';
import { getEmptyImageBasedOnSm } from './helper';

export interface TemplateProps {
  availableSocialMediaAccounts: SocialMediaAccountType[];
  selectedSmTab: SocialAccountType | null;
}

const Template = (props: TemplateProps) => {
  const { availableSocialMediaAccounts, selectedSmTab } = props;
  useUpdateDocumentTitle({ title: 'MenuTitle.Analytics', pageTitle: 'MenuTitle.Analytics' });
  const { t } = useQueryHelper();

  const hasConnectedAccount = availableSocialMediaAccounts.length > 0;
  const smTitle = switchText(selectedSmTab);

  return (
    <>
      {!hasConnectedAccount && selectedSmTab && (
        <>
          <TopBarWrapper>
            <img src={switchImage(selectedSmTab, true)} width="24px" height="24px" alt={`${selectedSmTab} icon`} />
            <p>{t(`${smTitle} Analytics`)}</p>
          </TopBarWrapper>
          <EmptyConnectedAccounts>
            <img src={getEmptyImageBasedOnSm(selectedSmTab)} width="187px" height="180px" />
            <StyledTextWrapper>
              <p>{t('Please connect your')}</p>
              <p>{t(`${smTitle} account to see your account's insight`)}</p>
            </StyledTextWrapper>
            <StyledSocialButton
              light
              socialMedia={selectedSmTab}
              title={`Sign in with ${smTitle}`}
              href="/settings/social_connect"
            />
          </EmptyConnectedAccounts>
        </>
      )}

      {hasConnectedAccount && <AnalyticsAccounts availableSocialMediaAccounts={availableSocialMediaAccounts} />}
    </>
  );
};

const TopBarWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  background: #fff;
  box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;

  img {
    margin-left: 24px;
    margin-right: 8px;
  }

  p {
    font-weight: 600;
    font-size: 18px;
  }
`;
const EmptyConnectedAccounts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 440px;
  margin: 24px;
  background: #fff;
  box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
  border-radius: 3px;
`;
const StyledSocialButton = styled(SocialButton)`
  max-width: 280px;
  height: 32px;

  @media (max-width: ${ViewportType.TABLET}px) {
    height: 40px;
  }

  img {
    width: 20px;
    height: 20px;
  }
`;
const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
  font-weight: 600;
  font-size: 16px;
`;

export default Template;
