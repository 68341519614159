import likeIcon from '@src/assets/icon/thumbs-up.svg';
import postIcon from '@src/assets/icon/youtubeVideo.svg';
import viewsIcon from '@src/assets/icon/views.svg';
import commentIcon from '@src/assets/icon/comment.svg';

export interface AverageEngagementTwitterProps {
  averagePostsPerWeek: number | null;
  averageLikes: number | null;
  averageViews: number | null;
  averageComments: number | null;
}

export const getAverageEngagementIconAndTitle = (engKey: keyof AverageEngagementTwitterProps) => {
  switch (engKey) {
    case 'averageComments':
      return {
        title: 'Avg Comments',
        icon: commentIcon,
      };
    case 'averageLikes':
      return {
        title: 'Avg Likes',
        icon: likeIcon,
      };
    case 'averagePostsPerWeek':
      return {
        title: 'Avg posts/week',
        icon: postIcon,
      };
    case 'averageViews':
      return {
        title: 'Avg Views',
        icon: viewsIcon,
      };

    default:
      return {
        title: '',
        icon: '',
      };
  }
};
