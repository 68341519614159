import isAfter from 'date-fns/isAfter';
import * as jwtDecode from 'jwt-decode';
import { AnyXAuthSocialAccountType, SocialAccountType, AuthSocialAccountType } from '@src/__generated__/globalTypes';
import { FE_REDIRECT_MAPPING } from '../constant';
import { switchSocialMediaName } from '../SocialMedia';
import { Token } from './types';

export const setToken = (token: string, refreshToken?: string | null) => {
  if (window.localStorage) {
    localStorage.setItem('accessToken', token);

    if (refreshToken) {
      localStorage.setItem('refreshToken', refreshToken);
    }
  }
};

export const getToken = () => localStorage.getItem('accessToken');

export const getRefreshToken = () => localStorage.getItem('refreshToken');

export const removeToken = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
};

export const checkSignedIn = () => {
  const token = getToken();
  if (!token) {
    return false;
  }

  let decoded: Token;

  try {
    decoded = jwtDecode<Token>(token);
  } catch (e) {
    removeToken();

    return false;
  }

  const expire = decoded.exp * 1000;
  const isNotExpired = isAfter(expire, Date.now());

  const isSignedIn = isNotExpired;

  if (!isSignedIn) {
    removeToken();
  }

  return isSignedIn;
};

export const getRedirectTypePath = (redirectType: FE_REDIRECT_MAPPING): string => {
  switch (redirectType) {
    case FE_REDIRECT_MAPPING.SIGNIN:
    case FE_REDIRECT_MAPPING.TALENT_SIGNIN:
      return 'signin';
    case FE_REDIRECT_MAPPING.RECONNECT_IG:
      return 'reconnect_ig';
    case FE_REDIRECT_MAPPING.TALENT_SIGNUP:
    case FE_REDIRECT_MAPPING.SIGNUP:
      return 'signup';
    case FE_REDIRECT_MAPPING.SIGNUP_ENABLED:
      return 'signup-enabled';
    case FE_REDIRECT_MAPPING.CONNECT:
      return 'connect';
    case FE_REDIRECT_MAPPING.SIGNUP_CONNECT:
      return 'signup-connect';
    case FE_REDIRECT_MAPPING.JOIN_CAMPAIGN:
      return 'join_campaign';
    case FE_REDIRECT_MAPPING.ANALYTICS_IG_RECONNECT:
      return 'analytics-reconnect';
    default:
      return '';
  }
};

export const getProvider = (socialMedia: AnyXAuthSocialAccountType | SocialAccountType | AuthSocialAccountType) =>
  (socialMedia === SocialAccountType.INSTAGRAM ? AuthSocialAccountType.FACEBOOK : socialMedia) as AuthSocialAccountType;

export const getRedirectPath = (
  socialMedia: SocialAccountType | AuthSocialAccountType,
  redirectType: FE_REDIRECT_MAPPING
): string => {
  const redirectPath = `redirect/${getRedirectTypePath(redirectType)}`;
  if (redirectType === FE_REDIRECT_MAPPING.RECONNECT_IG) {
    return redirectPath;
  }

  const socialMediaName = switchSocialMediaName(socialMedia);

  return `${redirectPath}/${socialMediaName}`;
};

export const getCallbackUrl = (
  socialMedia: AnyXAuthSocialAccountType | AuthSocialAccountType | SocialAccountType,
  redirectType: FE_REDIRECT_MAPPING
): string => {
  const origin = location.origin;
  if (socialMedia === AuthSocialAccountType.TWITTER) {
    return `${origin}/redirect/twitter`;
  }

  const provider = getProvider(socialMedia);

  return `${origin}/${getRedirectPath(provider, redirectType)}`;
};

/*
 Connect
 */
export enum ConnectType {
  CONNECT_FB_PAGE = 'CONNECT_FB_PAGE',
  CONNECT_IG_ACCOUNT = 'CONNECT_IG_ACCOUNT',
  CONNECT_SHOPIFY = 'CONNECT_SHOPIFY',
  RECONNECT_IG_ACCOUNT = 'RECONNECT_IG_ACCOUNT',
}

const toConnectType = (key: string | null, initial = null): ConnectType | null => {
  switch (key) {
    case 'CONNECT_FB_PAGE':
      return ConnectType.CONNECT_FB_PAGE;
    case 'CONNECT_IG_ACCOUNT':
      return ConnectType.CONNECT_IG_ACCOUNT;
    case 'RECONNECT_IG_ACCOUNT':
      return ConnectType.RECONNECT_IG_ACCOUNT;
    default:
      return initial;
  }
};

export interface ConnectState {
  connectType: ConnectType;
  reconnectIgAccountId: string;
}

export const getConnectState = async (): Promise<ConnectState | undefined> => {
  const connectTypeString = (await localStorage.getItem('connectType')) || '';
  const connectType = toConnectType(connectTypeString);
  localStorage.removeItem('connectType');

  const reconnectIgAccountId = (await localStorage.getItem('reconnectIgAccountId')) || '';
  localStorage.removeItem('reconnectIgAccountId');

  return connectType ? { connectType, reconnectIgAccountId } : undefined;
};

export const getAppRootRoute = () => '/job';
