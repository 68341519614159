import React from 'react';
import { useQuery } from '@apollo/client';
import ListIndicator from '../../../molecules/Indicator/ListIndicator';
import { formatPercent } from '../../../../libs/format';
import { InfluencerProfileDetail, InfluencerProfileDetailVariables } from '../__generated__/InfluencerProfileDetail';
import * as INFLUENCER_PROFILE_DETAIL from '../InfluencerProfileDetail.graphql';
import { SocialMediaAccountType } from '../types';
import Template, { TemplateProps } from './template';

interface ProfileDetailProps {
  selectedSocialMedia: SocialMediaAccountType;
  userId: number;
  setLastUpdate: (date: string) => void;
}

const ProfileDetail = ({ selectedSocialMedia, userId, setLastUpdate }: ProfileDetailProps) => {
  const variables = {
    pk: Number(userId),
    socialMedia: selectedSocialMedia.socialAccountType,
    socialAccountId: selectedSocialMedia.id,
  };

  const { data, loading } = useQuery<InfluencerProfileDetail, InfluencerProfileDetailVariables>(
    INFLUENCER_PROFILE_DETAIL,
    {
      variables,
      fetchPolicy: 'cache-and-network',
      skip: !selectedSocialMedia,
    }
  );

  if (loading || !data?.influencerProfileDetailV2) {
    return <ListIndicator />;
  }

  const {
    followers,
    postsStats,
    postsHabit,
    popularPosts,
    sentimentAnalysis,
    lastUpdatedDatetime,
  } = data.influencerProfileDetailV2;
  setLastUpdate(lastUpdatedDatetime);

  const { positiveRatio = 0, negativeRatio = 0, neutralRatio = 0 } = sentimentAnalysis || {};
  const { femaleRate, maleRate, ageGenderRates, followersGrowth } = followers;

  const averageAgeAndGender = {
    femaleRate,
    maleRate,
    ageGroups: (ageGenderRates?.ageGroup || []).map(item => `${item.start}-${item.end}`),
    maleAges: (ageGenderRates?.maleRate || []).map(item => parseFloat(formatPercent(item, false))),
    femaleAges: (ageGenderRates?.femaleRate || []).map(item => parseFloat(formatPercent(item, false))),
  };

  const postingHabits = {
    averageEngagement: postsStats.averageEngagement,
    engagementRate: postsStats.engagementRate,
    averageLikes: postsStats.averageLikes,
    averageComments: postsStats.averageComments,
    averageShares: postsStats.averageShares,
    averageViews: postsStats.averageViews,
    postingHabitsData: postsHabit,
    socialAccountType: variables.socialMedia,
  };

  const profileDetailsData: TemplateProps = {
    positiveRatio,
    negativeRatio,
    neutralRatio,
    countryRates: followers.countryRates,
    averageAgeAndGender,
    postingHabits,
    posts: popularPosts,
    followersGrowth: followersGrowth || [],
    selectedSocialMedia,
  };

  return <Template {...profileDetailsData} />;
};

export default ProfileDetail;
