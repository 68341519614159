import { useField, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { StepStyleDTO } from 'react-form-stepper/dist/components/Step/StepTypes';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from '@src/components/atoms/Button';
import { CheckBox } from '@src/components/atoms/CheckBox';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import { Icon } from '@src/components/atoms/Icon';
import Label from '@src/components/atoms/Label';
import Stepper from '@src/components/atoms/Stepper';
import SingleSelectField from '@src/components/molecules/SingleSelectField';
import TextForm from '@src/components/molecules/TextForm';
import YearMonthDayPicker from '@src/components/molecules/YearMonthDayPicker';
import RegionSelector from '@src/components/organisms/RegionSelector';
import { GENDERS } from '@src/libs/constant';
import { useQueryHelper, useTranslateOptions } from '@src/libs/hooks';
import { SignUpAndSignInBreakpoint } from '@src/libs/theme';
import CHECK_EMAIL from './AnyXCheckEmail.graphql';
import { AnyXCheckEmail, AnyXCheckEmailVariables } from './__generated__/AnyXCheckEmail';
import { hasAllRequiredValues } from './helpers';
import { FormValues, Option } from './types';

interface SignUpEmailFormProps {
  allCategories: Option[];
  allCountries: Option[];
}

const SignUpEmailForm = ({ allCategories, allCountries }: SignUpEmailFormProps) => {
  const [currStep, setCurrStep] = useState<number>(0);
  const [isEmailChecking, setIsEmailChecking] = useState<boolean>(false);
  const { enqueueSnackbar, t } = useQueryHelper();
  const translatedCategories = useTranslateOptions(allCategories);
  const translatedCountries = useTranslateOptions(allCountries);
  const translatedGenders = useTranslateOptions(GENDERS);
  const { handleSubmit, isSubmitting, setErrors, setFieldValue, validateForm, values } = useFormikContext<FormValues>();
  const [agreedField] = useField<FormValues['agreed']>('agreed');
  const [birthdayField, birthdayMeta] = useField<FormValues['birthday']>('birthday');
  const [categoriesField, categoriesMeta] = useField<FormValues['categories']>('categories');
  const [countryField, countryMeta] = useField<FormValues['country']>('country');
  const [emailField, emailMeta] = useField<FormValues['email']>('email');
  const [genderField, genderMeta] = useField<FormValues['gender']>('gender');
  const [nameField, nameMeta] = useField<FormValues['name']>('name');
  const [regionField, regionMeta] = useField<FormValues['regionId']>('regionId');
  const disableCategories = categoriesField.value.length > 2;
  const disableSubmit = !hasAllRequiredValues(values) || !agreedField.value;

  const [checkEmail] = useMutation<AnyXCheckEmail, AnyXCheckEmailVariables>(CHECK_EMAIL, {
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  function onClickCategory(id: string) {
    const categories = [...categoriesField.value];
    const selectedCategoryIndex = categories.findIndex(categoryId => categoryId === id);
    if (selectedCategoryIndex > -1) {
      categories.splice(selectedCategoryIndex, 1);
    } else {
      categories.push(id);
    }
    setFieldValue('categories', categories);
  }

  function onClickValidateFirstStep() {
    validateForm().then(error => {
      if (error.categories) {
        handleSubmit();
      } else {
        setErrors({});
        setCurrStep(prevStep => prevStep + 1);
      }
    });
  }

  function onClickValidateSecondStep() {
    validateForm().then(error => {
      if (error.name || error.email) {
        handleSubmit();
      } else {
        setIsEmailChecking(true);
        checkEmail({
          variables: {
            input: {
              email: emailField.value,
            },
          },
        })
          .then(data => {
            if (data?.data?.anyXCheckEmail?.ok) {
              setErrors({});
              setCurrStep(prevStep => prevStep + 1);
            }
          })
          .finally(() => setIsEmailChecking(false));
      }
    });
  }

  return (
    <div css={styles.container}>
      <div css={styles.header}>
        <div>
          {currStep > 0 && (
            <Icon color="#3892e5" icon="arrow_back" onClick={() => setCurrStep(prevStep => prevStep - 1)} />
          )}
          <div>{t('Your Profile')}</div>
        </div>
      </div>

      <div css={styles.stepperContainer}>
        <Stepper
          activeStep={currStep}
          steps={[{ label: t('Step1') }, { label: t('Step2') }, { label: t('Step3') }]}
          styleConfig={{ size: '10px' } as StepStyleDTO}
        />
      </div>

      {currStep === 0 ? (
        <>
          <Description>{t('Annotation.Please select three or fewer categories')}</Description>
          <div css={styles.categoriesContainer}>
            {translatedCategories.map(category => {
              const { label, value } = category;
              const active = categoriesField.value.includes(value);

              return (
                <div key={value} onClick={() => (disableCategories && !active ? null : onClickCategory(value))}>
                  <Category active={active} disabled={disableCategories}>
                    {label}
                  </Category>
                </div>
              );
            })}
          </div>
          {!!categoriesMeta.error && (
            <Grid>
              <ErrorMessage message={t(categoriesMeta.error)} />
            </Grid>
          )}
          <Grid>
            <StyledButton
              bgcolor="#3892e5"
              color="#fff"
              disabled={!categoriesField.value.length}
              hoverbgcolor="#3892e5"
              title="Next"
              onClick={onClickValidateFirstStep}
            />
          </Grid>
        </>
      ) : currStep === 1 ? (
        <>
          <Description>{t('Annotation.We will never share your information with other companies')}</Description>
          <Description>
            {t('Annotation.The information you enter will be used for better sponsorship matching')}
          </Description>
          <InfoWrapper marginTop>
            <InputWrapper>
              <TextFormStyled
                error={!!nameMeta.error}
                isRequired={true}
                placeholder="Name"
                title="Name"
                {...nameField}
              />
              {nameMeta.error && <ErrorMessage message={t(nameMeta.error)} />}
            </InputWrapper>

            <InputWrapper>
              <TextFormStyled
                error={!!emailMeta.error}
                isRequired={true}
                placeholder="your@email.com"
                title="Email address"
                {...emailField}
              />
              {emailMeta.error && <ErrorMessage message={t(emailMeta.error)} />}
            </InputWrapper>
          </InfoWrapper>
          <Grid>
            <StyledButton
              bgcolor="#3892e5"
              color="#fff"
              disabled={isEmailChecking}
              hoverbgcolor="#3892e5"
              title="Next"
              onClick={onClickValidateSecondStep}
            />
          </Grid>
        </>
      ) : (
        <>
          <InfoWrapper>
            <InputWrapper>
              <Label title={t('Date Of Birth')} isRequired={true} />
              <div css={styles.yearMonthDayPickerContainer}>
                <YearMonthDayPicker
                  error={!!birthdayMeta.error}
                  format="yyyy-MM-dd"
                  maxDate={new Date()}
                  value={birthdayField.value || ''}
                  onChange={value => setFieldValue('birthday', value)}
                />
              </div>
              {birthdayMeta.error && <ErrorMessage message={t(birthdayMeta.error)} />}
            </InputWrapper>

            <InputWrapper>
              <SingleSelectFieldStyled
                name={countryField.name}
                value={countryField.value}
                error={!!countryMeta.error}
                title="Country"
                options={translatedCountries}
                setFieldValue={setFieldValue}
                isRequired={true}
              />
              {countryMeta.error && <ErrorMessage message={t(countryMeta.error)} />}
            </InputWrapper>

            <InputWrapper>
              <RegionSelector
                countryId={countryField.value}
                css={styles.regionSelector}
                error={!!regionMeta.error}
                isRequired={true}
                name={regionField.name}
                title="Region"
                value={regionField.value}
                setFieldValue={setFieldValue}
              />
              {regionMeta.error && <ErrorMessage message={t(regionMeta.error)} />}
            </InputWrapper>

            <InputWrapper>
              <SingleSelectFieldStyled
                error={!!genderMeta.error}
                isRequired={true}
                name={genderField.name}
                options={translatedGenders}
                title="Gender"
                value={genderField.value}
                setFieldValue={setFieldValue}
              />
              {genderMeta.error && <ErrorMessage message={t(genderMeta.error)} />}
            </InputWrapper>
          </InfoWrapper>

          <div css={styles.termContainer}>
            <CheckBox
              css={styles.checkbox}
              label=""
              name={agreedField.name}
              value={agreedField.value}
              handleClick={() => setFieldValue('agreed', !agreedField.value)}
            />
            <span>{t('I agree to the')}</span>
            <Anchor to="/terms" target="_blank" rel="noopener">
              &nbsp;{t('Link.Terms and Conditions')}
            </Anchor>
            <SimpleText>&amp;</SimpleText>
            <Anchor to="/privacy" target="_blank" rel="noopener">
              &nbsp;{t('Privacy Policy')}
            </Anchor>
          </div>

          <Grid>
            <StyledButton
              bgcolor="#3892e5"
              color="#fff"
              disabled={disableSubmit || isSubmitting}
              hoverbgcolor="#3892e5"
              title="Next"
              onClick={() => handleSubmit()}
            />
          </Grid>
        </>
      )}
    </div>
  );
};

const Anchor = styled(Link)`
  align-self: center;
  color: #3892e5;
  font-size: 13px;

  &:hover {
    text-decoration: underline;
  }
`;

const Category = styled.div<{ active: boolean; disabled: boolean }>`
  align-items: center;
  background-color: ${({ active, disabled }) => (active ? '#eaf5ff' : disabled ? '#dee5ec' : '#fff')};
  border: 1px solid ${({ active, disabled }) => (active ? '#3992e5' : disabled ? '#dee5ec' : '#dee5ec')};
  border-radius: 3px;
  color: ${({ active, disabled }) => (!active && disabled ? '#fff' : '#000')};
  cursor: ${({ active, disabled }) => (!active && disabled ? 'default' : 'pointer')};
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 56px;
  justify-content: center;
  margin: 5px;
  padding: 0 8px;
  text-align: center;
  width: 100%;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    height: 48px;
  }
`;

const Description = styled.div`
  color: #27313b;
  display: flex;
  flex-basis: 100%;
  font-size: 14px;
  justify-content: center;
`;

const Grid = styled.div`
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  margin-bottom: 16px;
`;

const InfoWrapper = styled.div<{ marginTop?: boolean }>`
  ${({ marginTop }) => (marginTop ? 'margin-top: 24px;' : '')}
  max-width: 343px;
  width: 90%;
`;

const InputWrapper = styled.div`
  flex: 1;
  margin-bottom: 24px;
`;

const SingleSelectFieldStyled = styled(SingleSelectField)`
  & > div > div {
    border-radius: 0;
    min-height: 32px;

    @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
      min-height: 40px;
    }
  }

  & input {
    min-height: 32px;

    @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
      min-height: 40px;
    }
  }
`;

const StyledButton = styled(Button)`
  border-radius: 3px;
  filter: brightness(1);
  height: 32px;
  transition: 0.2s;
  width: 348px;

  &:hover {
    filter: brightness(0.9);
  }

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    height: 40px;

    &:hover {
      filter: brightness(1);
    }
  }
`;

const TextFormStyled = styled(TextForm)`
  & label {
    font-size: 14px;
  }

  & input {
    border-radius: 3px;
    height: 32px;
    width: 100%;

    @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
      height: 40px;
    }
  }
`;

const SimpleText = styled.div`
  align-self: center;
  color: #3892e5;
  font-size: 13px;
  margin: 0 2px 0 4px;
`;

const styles = {
  ampersand: css`
    color: #3892e5;
    font-size: 13px;
  `,
  categoriesContainer: css`
    display: flex;
    flex-wrap: wrap;
    max-width: 504px;
    margin: 24px 0;

    /* stylelint-disable no-descending-specificity */
    & > div {
      display: flex;
      flex-basis: 33%;

      @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
        flex-basis: 50%;
      }
    }
  `,
  checkbox: css`
    padding: 0;

    label {
      margin-right: 0;
    }
  `,
  container: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  `,
  header: css`
    display: flex;
    flex-basis: 100%;
    justify-content: center;
    margin-bottom: 16px;

    & > div {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      max-width: 343px;
      position: relative;
      width: 100%;

      & > i {
        left: 0;
        position: absolute;
      }

      & > div {
        color: #27313b;
        display: flex;
        flex-grow: 1;
        font-size: 20px;
        justify-content: center;
      }
    }
  `,
  regionSelector: css`
    & > div > div {
      border-radius: 0;
      min-height: 32px;

      @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
        min-height: 40px;
      }
    }

    & input {
      min-height: 32px;

      @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
        min-height: 40px;
      }
    }
  `,
  stepperContainer: css`
    display: flex;
    flex-basis: 100%;
    justify-content: center;
    margin-bottom: 24px;

    & > div {
      max-width: 310px;
    }
  `,
  termContainer: css`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 16px;
    width: 100%;
  `,
  yearMonthDayPickerContainer: css`
    & > div {
      & > div:nth-of-type(1) {
        margin-right: 4px;
      }

      & > div:nth-of-type(2) {
        margin: 0 4px;
      }

      & > div:nth-of-type(3) {
        margin-left: 4px;
      }

      & > div {
        & > div {
          border-radius: 3px;
          min-height: 32px;

          @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
            min-height: 40px;
          }
        }

        & > div > div > input {
          min-height: 32px;

          @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
            min-height: 40px;
          }
        }
      }
    }
  `,
};

export default SignUpEmailForm;
