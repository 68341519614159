import React from 'react';
import styled from '@emotion/styled';
import ImageSlider from '@src/components/molecules/Slider';
import { css } from '@emotion/react';
import DataOpacityWrapper from '@src/components/molecules/DataCard/DataOpacityWrapper';
import { ComparePostType } from './types';
import { YoutubePreviewItem } from './PreviewItem';

interface CompareVideoPostSliderProps {
  item: ComparePostType;
  handleClickPost: (id: number) => void;
  className?: string;
}

export const CompareVideoPostSlider = ({ item, handleClickPost, className }: CompareVideoPostSliderProps) => (
  <Wrapper className={className}>
    <DataOpacityWrapper
      dataNotAvailable={!item.posts?.length}
      css={css`
        ${!item.posts?.length ? 'zoom: 0.5' : ''};
        border-top: unset;
      `}
    >
      {item.posts?.length ? (
        <ImageSlider data={item.posts}>
          {item.posts.map(video => (
            <YoutubePreviewItem
              key={video.id}
              id={video.id}
              thumbNail={video.thumbnail}
              title={video.title}
              videoDuration={video.duration}
              views={video.views}
              handleClickPost={handleClickPost}
              likes={video.likes}
              comments={video.comments}
            />
          ))}
        </ImageSlider>
      ) : null}
    </DataOpacityWrapper>
  </Wrapper>
);

const Wrapper = styled.div`
  min-height: 100px;
  display: flex;
  place-items: center;
  border-top: 1px solid #dee5ec;
  padding: 8px;
  padding-right: 0;
`;
