import React from 'react';
import lineIcon from '@src/assets/icon/line_chat.svg';
import { useQueryHelper } from '@src/libs/hooks';
import { Icon } from '@src/components/atoms/Icon';
import MenuButton from '@src/components/molecules/MenuButton';
import { DialogModal } from '@src/components/molecules/DialogModal';
import FormStepper from '@src/components/molecules/FormStepper';
import { css } from '@emotion/react';
import { useQuery, useMutation } from '@apollo/client';
import { PalleteButton } from '@src/components/atoms/Button';
import { mainBlue } from '@src/libs/pallete';
import { getOperationName } from '@apollo/client/utilities';
import * as GET_LINE_CHANNELS from '@src/libs/queries/GetLineChannels.graphql';
import { GetLineChannels } from '@src/libs/queries/__generated__/GetLineChannels';
import * as Styled from './Styled';
import { useConnectLineMachine, ConnectionStepsTypes } from './useConnectLineMachine';
import { useDeleteChannelWithModal } from './useDeleteChannelWithModal';
import * as DELETE_LINE_CHANNEL from './mutations/RemoveLineChannel.graphql';
import { RemoveLineChannel, RemoveLineChannelVariables } from './mutations/__generated__/RemoveLineChannel';

export const LineChannel = () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { styles } = Styled;

  const { data } = useQuery<GetLineChannels>(GET_LINE_CHANNELS);
  const lineAccount = data?.getLineChannels || null;
  const { machine, machineState, options, loading } = useConnectLineMachine();
  const [deleteLineChannel, { ...deleteOptions }] = useMutation<RemoveLineChannel, RemoveLineChannelVariables>(
    DELETE_LINE_CHANNEL,
    {
      notifyOnNetworkStatusChange: true, // to make loading status change on failed request
      refetchQueries: [getOperationName(GET_LINE_CHANNELS) || 'GetLineChannels'],
      onCompleted: () => {
        enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
        setIsOpened(false);
      },
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  const onClickDeleteLine = async () => {
    if (lineAccount) {
      await deleteLineChannel({
        variables: {
          input: {
            id: lineAccount.id,
          },
        },
      });
    }
  };

  const { DeleteModalWithState, setIsOpened } = useDeleteChannelWithModal({
    loading: deleteOptions.loading,
    handleClickDelete: onClickDeleteLine,
  });

  return (
    <>
      <div css={[styles.channelContent, customStyles.channelContent]}>
        <div>
          <img alt="Line icon" height="24" src={lineIcon} width="21" />
          <span>LINE</span>
        </div>
        {lineAccount ? (
          <div>
            <div css={styles.connectedIndicator}>
              <Icon icon="check" />
              {t('Button.Connected')}
            </div>
            <MenuButton options={[{ title: 'Delete', onClick: () => setIsOpened(true) }]} />
          </div>
        ) : (
          <div>
            <Styled.StyledButton
              bgcolor="#3892e5"
              hoverbgcolor="#3892e5"
              isPrimary
              title="Connect"
              onClick={() => machine.transition(machine.value, 'next')}
            />
          </div>
        )}
      </div>
      <DialogModal
        headerNode={
          <div css={styles.modalHeader}>
            <img src={lineIcon} height="32" width="32" alt="shopify_icon" />
            <h3 css={styles.modalTitle}>{t('LINE Connection')}</h3>
          </div>
        }
        isOpen={machineState !== ConnectionStepsTypes.IDLE}
        closeModal={() => machine.transition(machineState, 'prev')}
        footerNode={
          <div css={styles.modalFooter}>
            <PalleteButton
              text="Cancel"
              width="90px"
              height="32px"
              borderRadius="3px"
              onClick={() => machine.transition(machineState, 'prev')}
            />
            <PalleteButton
              width="76px"
              borderRadius="3px"
              height="32px"
              text={options.nextButtonText}
              palleteColor={mainBlue}
              isDisabled={loading}
              loading={loading}
              onClick={options.formSubmit}
              loadingSize={14}
            />
          </div>
        }
      >
        <div css={customStyles.formContent}>
          <div css={customStyles.stepperWrapper}>
            <FormStepper<ConnectionStepsTypes>
              steps={[
                { value: ConnectionStepsTypes.STEP1 },
                { value: ConnectionStepsTypes.STEP2 },
                { value: ConnectionStepsTypes.STEP3 },
              ]}
              currentStep={machineState}
              css={customStyles.stepper}
            />
          </div>
          {options.formNode}
        </div>
      </DialogModal>
      {DeleteModalWithState}
    </>
  );
};

const customStyles = {
  stepper: css`
    max-width: 310px;
  `,
  stepperWrapper: css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 0 38px;
  `,
  formContent: css`
    padding: 32px 16px;
  `,
  channelContent: css`
    border-top: 1px solid #eef3f7;
  `,
};
