import { CheckConnectedSocialAccountsForSignUp_checkConnectedSocialAccountsV2 } from './__generated__/CheckConnectedSocialAccountsForSignUp';

export const defaultSocialAccounts: CheckConnectedSocialAccountsForSignUp_checkConnectedSocialAccountsV2 = {
  __typename: 'SocialAuthCheckConnectedPayloadV2',
  facebook: null,
  instagramAccounts: [],
  twitterAccounts: [],
  youtubeAccounts: [],
  tiktokAccounts: [],
};
