import React from 'react';
import { Period } from '@src/components/molecules/DayPicker/types';
import AnalyticsContentWrapper, {
  ContentType,
  sectionStyles,
} from '@src/components/organisms/AnalyticsShared/AnalyticsContentWrapper';
import PostingHabits from '@src/components/organisms/ProfileWidgets/PostingHabits';
import SentimentAnalysis from '@src/components/organisms/ProfileWidgets/SentimentAnalysis';
import { PostAnalystics } from '../../types';
import AnalyticsTagRanking from '../TagRankingList';
import AverageEngagement from './AverageEngagement';
import FeedVideos from './FeedVideos';

interface AnalyticsPostsProps extends PostAnalystics {
  socialMediaAccountId: number;
  period: Period;
  className?: string;
}

const Template = (props: AnalyticsPostsProps) => {
  const {
    className,
    averageEngagement,
    sentimentAnalysis,
    postHabit,
    feedVideos,
    socialMediaAccountId,
    period,
  } = props;

  return (
    <AnalyticsContentWrapper contentType={ContentType.Posts} className={className}>
      <div css={sectionStyles.responsiveWrapper}>
        {averageEngagement && <AverageEngagement {...averageEngagement} />}

        <SentimentAnalysis
          commentAnalytics={sentimentAnalysis}
          css={[sectionStyles.middleSection, sectionStyles.verticalContaner]}
        />

        {!!postHabit && <PostingHabits postsHabit={postHabit} css={sectionStyles.verticalContaner} />}
      </div>

      <FeedVideos videos={feedVideos} socialMediaAccountId={socialMediaAccountId} />
      <AnalyticsTagRanking period={period} socialMediaAccountId={socialMediaAccountId} />
    </AnalyticsContentWrapper>
  );
};

export default Template;
