import { useMutation, useQuery } from '@apollo/client';
import { ApolloError } from '@apollo/client';
import { useCallback } from 'react';
import { useQueryHelper } from '@src/libs/hooks';
import { getBankOptions, getOptions } from '@src/libs/form';
import { InfluencerPaymentInformation } from './__generated__/InfluencerPaymentInformation';
import { UpdatePaymentInformation, UpdatePaymentInformationVariables } from './__generated__/UpdatePaymentInformation';
import * as INFLUENCER_PAYMENT_INFORMATION from './InfluencerPaymentInformation.graphql';
import { BankOption, Option } from './types';
import * as UPDATE_PAYMENT_INFORMATION from './UpdatePaymentInformation.graphql';

export const usePaymentInformation = (userId: number | null) => {
  // Query
  const { enqueueSnackbar, t } = useQueryHelper();
  const { data, loading } = useQuery<InfluencerPaymentInformation>(INFLUENCER_PAYMENT_INFORMATION, {
    variables: {
      pk: userId,
    },
    // apollo can't normalize cache bcs influencerPaymentInformation doesn't have `id`
    fetchPolicy: 'no-cache',
    onError: useCallback((error: ApolloError) => {
      enqueueSnackbar(t(error.message || 'UnexpectedError'), { variant: 'error' });
    }, []),
  });

  const allBanks: BankOption[] = data ? getBankOptions(data.allBanks) : [];
  const allCities: Option[] = data ? getOptions(data.allCities) : [];
  const hasBranchId = allBanks.some(({ hasBranches }) => hasBranches);
  const country = data?.influencerProfileV2?.country;
  const isMenaInfluencer = !!country?.isMena;
  const information = data?.paymentInformation
    ? {
        accountName: data.paymentInformation.accountName || '',
        email: data.paymentInformation.paymentEmail || '',
        bankId: data.paymentInformation.bankId ? String(data.paymentInformation.bankId) : '',
        branchId: data.paymentInformation.branchId ? String(data.paymentInformation.branchId) : '',
        accountNumber: data.paymentInformation.accountNumber || '',
        address: data.paymentInformation.address || '',
        cityId: data.paymentInformation.cityId ? String(data.paymentInformation.cityId) : '',
        postalCode: data.paymentInformation.postalCode || '',
        swiftCode: data.paymentInformation.swiftCode || '',
        iban: data.paymentInformation.iban || '',
        branchName: data.paymentInformation.branchName || '',
        subject: data.paymentInformation.subject || '',
        fullName: data.paymentInformation.fullName || '',
      }
    : {
        accountName: '',
        email: '',
        bankId: '',
        branchId: '',
        accountNumber: '',
        address: '',
        cityId: '',
        postalCode: '',
        swiftCode: '',
        iban: '',
        branchName: '',
        subject: '',
        fullName: '',
      };
  // Mutation
  const [updatePaymentInformation] = useMutation<UpdatePaymentInformation, UpdatePaymentInformationVariables>(
    UPDATE_PAYMENT_INFORMATION,
    { refetchQueries: ['PaymentStatus'] }
  );

  return {
    updatePaymentInformation,
    loading,
    allBanks,
    allCities,
    country,
    isMenaInfluencer,
    hasBranchId,
    information,
  };
};
