import React from 'react';
import css from '@emotion/css';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { formatIntNumber } from '@src/libs/format';
import { defaultEmptyImage, isVideoThumbnail } from '@src/libs/image';
import VideoPreview from '@src/components/atoms/VideoPreview';
import ImageSlider from '../Slider';
import { CarouselPost } from './model';

interface PostCarouselProps {
  posts: CarouselPost[];
  className?: string;
  onPostClick?: (id: number) => void;
  type?: 'reach' | 'likes';
}
const PostCarousel = (props: PostCarouselProps) => {
  const { t } = useTranslation();
  const { posts, className, onPostClick, type = 'likes' } = props;

  return (
    <>
      <MainWrapper className={className}>
        {!!posts?.length && (
          <>
            <ImageSlider data={posts}>
              {posts.map(post => (
                <PostImageWrapper key={post.id}>
                  {isVideoThumbnail(post.thumbNail) ? (
                    <PreviewWrapper onClick={() => onPostClick?.(post.id)}>
                      <VideoPreview
                        css={css`
                          margin-right: 16px;
                        `}
                        url={post.thumbNail || null}
                        width="160"
                        height="160"
                      />
                    </PreviewWrapper>
                  ) : (
                    <PostImage src={defaultEmptyImage(post.thumbNail)} onClick={() => onPostClick?.(post.id)} />
                  )}
                  <EngagementInfo>
                    <InfoWrapper>
                      <Label>{t(type === 'reach' ? 'Reach' : 'Like')}</Label>
                      <Count>{formatIntNumber(post[type])}</Count>
                    </InfoWrapper>
                    <InfoWrapper>
                      <Label>{t('Comments')}</Label>
                      <Count>{formatIntNumber(post.comments)}</Count>
                    </InfoWrapper>
                  </EngagementInfo>
                </PostImageWrapper>
              ))}
            </ImageSlider>
          </>
        )}
      </MainWrapper>
    </>
  );
};

const MainWrapper = styled.div`
  padding-left: 16px;
`;

const PreviewWrapper = styled.div`
  cursor: pointer;
`;

export const PostImage = styled.img`
  width: 160px;
  height: 160px;
  margin-right: 16px;
  object-fit: contain;
  cursor: pointer;
`;
export const EngagementInfo = styled.div`
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  position: absolute;
  width: calc(100% - 16px);
  bottom: 0;
  left: 0;
  cursor: pointer;
`;
export const InfoWrapper = styled.div`
  padding: 0 8px 8px;
`;
export const Label = styled.p`
  margin-bottom: 3px;
`;
export const Count = styled.p``;
// Inline styles generated from `react-slick` in here
export const PostImageWrapper = styled.div`
  position: relative;

  &:focus {
    outline: none;
  }
`;

export default PostCarousel;
