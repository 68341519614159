import styled from '@emotion/styled';
import Icon from '@material-ui/core/Icon';
import React, { ReactElement, CSSProperties } from 'react';
import Popup from 'reactjs-popup';
import { css } from '@emotion/react';
import Portal from '@src/components/atoms/Portal';
import CloseIcon from '@src/assets/icon/close.svg';
import { useMediaQuery } from 'react-responsive';
import { ViewportBreakpoint } from '@src/libs/theme';
import { PalleteButton } from '@src/components/atoms/Button';

export interface ModalProps {
  open: boolean;
  title: string;
  cancel?: string;
  execute?: string;
  disabled?: boolean;
  handleExecute?: () => void;
  onClose: () => void;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  siblingPostId?: { prevId: number | null; nextId: number | null };
  children: ReactElement<any> | null;
  bgColor?: string;
  arrowsTop?: string;
  closeOnDocumentClick?: boolean;
  overridePopurStyles?: CSSProperties;
}

const CarouselModal = (props: ModalProps) => {
  const {
    open,
    onClose,
    children,
    onClickNext,
    onClickPrev,
    siblingPostId,
    closeOnDocumentClick = true,
    overridePopurStyles,
  } = props;
  const isDesktop = useMediaQuery({ minWidth: 1000 });
  const isTablet = useMediaQuery({ minWidth: ViewportBreakpoint.MOBILE, maxWidth: ViewportBreakpoint.MEDIUM });
  const isVerticalView = useMediaQuery({ maxWidth: ViewportBreakpoint.MEDIUM });

  const popupStyles: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    width: isDesktop ? '900px' : isTablet ? '450px' : '90%',
    height: isVerticalView ? '700px' : '500px',
    maxHeight: '90vh',
    padding: '0',
    border: 'none',
    borderRadius: isVerticalView ? '15px' : '5px',
  };

  return (
    <Portal portalId="portal-root">
      <Popup
        modal
        lockScroll
        closeOnDocumentClick={closeOnDocumentClick}
        open={open}
        onClose={onClose}
        contentStyle={{ ...popupStyles, ...overridePopurStyles }}
      >
        {close => (
          <>
            <ModalWrapper>
              <Close onClick={close}>
                <img src={CloseIcon} alt="close_icon" width={24} height={24} css={{ margin: 'auto' }} />
              </Close>
              {!!siblingPostId?.prevId && (
                <IconWrapper onClick={onClickPrev}>
                  <Icon css={styles.leftArrow} fontSize="large">
                    keyboard_arrow_left
                  </Icon>
                </IconWrapper>
              )}
              <ContentWrapper>{children}</ContentWrapper>
              {!!siblingPostId?.nextId && (
                <IconWrapper onClick={onClickNext}>
                  <Icon css={styles.rightArrow} fontSize="large">
                    keyboard_arrow_right
                  </Icon>
                </IconWrapper>
              )}
              {isVerticalView ? (
                <div css={styles.closeWrapper}>
                  <PalleteButton text="Close" onClick={close} borderRadius="5px" />
                </div>
              ) : null}
            </ModalWrapper>
          </>
        )}
      </Popup>
    </Portal>
  );
};

const styles = {
  rightArrow: css`
    position: absolute;
    right: -30px;
    top: 45%;
    color: #fff;
  `,
  leftArrow: css`
    position: absolute;
    left: -30px;
    top: 45%;
    color: #fff;
  `,
  closeWrapper: css`
    padding: 16px;
    border-top: 1px solid #dee5ec;
  `,
};

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  font-size: 12px;
`;
const ContentWrapper = styled.div`
  overflow-y: auto;
  flex: 1;
  display: flex;
`;
const IconWrapper = styled.button`
  cursor: pointer;
`;

const Close = styled.span`
  position: absolute;
  right: -10px;
  top: -10px;
  display: flex;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: #fff;
  border-radius: 50%;
  background-color: #2d2d2d;
  cursor: pointer;
  z-index: 100;

  &:hover {
    background-color: #6e7c89;
  }
`;

export default CarouselModal;
