import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from '@src/components/atoms/Button';
import * as List from '@src/components/atoms/List';
import Dialog from '@src/components/molecules/Dialog';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { SliderComponents, StyledComponents } from '@src/components/molecules/SliderTable';
import StatusIndicator from '@src/components/organisms/Emails/StatusIndicator';
import { LIMIT } from '@src/libs/constant';
import { formatPercent } from '@src/libs/format';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { getOffset, getPageInfo, usePaging } from '@src/libs/paging';
import { mainLightRed } from '@src/libs/pallete';
import { ViewportType } from '@src/libs/theme';
import { EmailTemplateStatus } from '@src/__generated__/globalTypes';
import EmptyList from './EmptyList';
import REMOVE_EMAILS from './mutations/RemoveEmails.graphql';
import { RemoveEmails, RemoveEmailsVariables } from './mutations/__generated__/RemoveEmails';
import GET_EMAIL_LIST from './queries/GetEmailList.graphql';
import { GetEmailList, GetEmailListVariables } from './queries/__generated__/GetEmailList';
import { FilterItems } from './useFilter';

interface EmailListProps {
  filter: FilterItems;
}

const EmailList = ({ filter }: EmailListProps) => {
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { enqueueSnackbar, t } = useQueryHelper();
  const currentPage = usePaging();
  const { isMobileView } = usePageLayout();
  const checkedIdsLength = checkedIds.length;

  useEffect(() => {
    if (isMobileView) {
      setCheckedIds([]);
      setDialogOpen(false);
    }
  }, [isMobileView]);

  const { data, loading } = useQuery<GetEmailList, GetEmailListVariables>(GET_EMAIL_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      keyword: filter.keyword,
      limit: LIMIT,
      offset: getOffset(currentPage),
      orderBy: { field: filter.orderByField, order: filter.orderBySequence },
      ...(filter.status && { status: filter.status }),
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [removeEmails] = useMutation<RemoveEmails, RemoveEmailsVariables>(REMOVE_EMAILS, {
    refetchQueries: ['GetEmailList'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
      setDialogOpen(false);
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const onClickAllChecked = (status: boolean) => {
    const items = [...checkedIds];
    emailList.forEach(email => {
      const index = items.findIndex(checkedId => checkedId === email.id);
      if (status && index >= 0) {
        items.splice(index, 1);
      } else if (!status && index < 0) {
        items.push(email.id);
      }
    });
    setCheckedIds(items);
  };

  const onClickCheck = (id: string) => {
    const items = [...checkedIds];
    const index = items.findIndex(item => item === id);
    if (index > -1) {
      items.splice(index, 1);
    } else {
      items.push(id);
    }
    setCheckedIds(items);
  };

  const onDeleteEmails = () => {
    removeEmails({
      variables: {
        input: {
          emailIds: checkedIds.map(emailId => Number(emailId)),
        },
      },
    });
  };

  const emailList = data?.getEmailList?.emails || [];
  const getCheckedItemStatus = () => {
    let isAllCheckedStatus = checkedIds.length > 0;
    let isItemSelectedStatus = false;

    emailList.every(email => {
      if (!checkedIds.includes(email.id)) {
        isAllCheckedStatus = false;
      } else {
        isItemSelectedStatus = true;
      }

      if (!isAllCheckedStatus && isItemSelectedStatus) {
        return false;
      }

      return true;
    });

    return {
      isAllChecked: isAllCheckedStatus,
      isItemSelected: isItemSelectedStatus,
    };
  };
  const { isAllChecked, isItemSelected } = getCheckedItemStatus();
  const isEmptyEmails = !emailList.length;
  const totalCount = data?.getEmailListCount?.total || 0;
  const { firstIndex, lastIndex, totalPages } = getPageInfo(currentPage, totalCount, LIMIT);

  if (loading) {
    return <ListIndicator />;
  }

  return (
    <>
      <Dialog
        execColor={mainLightRed}
        execText="Delete"
        visible={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onExec={onDeleteEmails}
      >
        <div css={styles.dialogContent}>
          <div>{t('Title.Delete Email List')}</div>
          <div>{t('Annotation.Delete Email List 1')}</div>
          <div>{t('Annotation.Delete Email List 2')}</div>
        </div>
      </Dialog>

      <SliderComponents.Wrapper css={styles.container}>
        <SliderComponents.Container>
          <SliderComponents.SliderSection>
            <SliderComponents.SliderTable>
              <Thead isItemSelected={checkedIdsLength > 0}>
                <SliderComponents.HeaderRow>
                  {!isMobileView && (
                    <List.HeaderCheckboxColumn
                      checked={isAllChecked || isItemSelected}
                      css={{ padding: '0 8px 0 16px' }}
                      indeterminate={!isAllChecked && isItemSelected}
                      handleClick={e => onClickAllChecked((e.target as HTMLInputElement).checked)}
                    />
                  )}
                  {isItemSelected ? (
                    <th colSpan={6} css={{ height: 48 }}>
                      <div css={styles.actionContainer}>
                        <div>{t('Selected', { count: checkedIdsLength })}</div>
                        {!isAllChecked && <div onClick={() => onClickAllChecked(false)}>{t('Select all items')}</div>}
                        <div>
                          <Button
                            bgcolor="#ff5f5f"
                            hoverbgcolor="#f5f5f"
                            title="Delete"
                            onClick={() => setDialogOpen(true)}
                          />
                        </div>
                      </div>
                    </th>
                  ) : (
                    <>
                      <List.HeaderColumn title="Subject" css={{ paddingLeft: '8px' }} />
                      <List.HeaderColumn title="Status" />
                      {!isMobileView && (
                        <>
                          <List.HeaderColumn title="Scheduled Date" />
                          <List.HeaderColumn title="Sent Date" />
                          <List.HeaderColumn title="Open Rate" />
                          <List.HeaderColumn title="Click Rate" css={{ paddingRight: '8px' }} />
                        </>
                      )}
                    </>
                  )}
                </SliderComponents.HeaderRow>
              </Thead>

              <tbody>
                {emailList.map(email => {
                  const { clickRate, id, openRate, scheduleDate, sentAt, status, subject } = email;
                  const isChecked = checkedIds.includes(id);

                  return (
                    <StyledComponents.StyledRowNew css={isChecked ? styles.activeTableRow : {}} key={id}>
                      {!isMobileView && (
                        <List.HeaderCheckboxColumn
                          checked={isChecked}
                          css={styles.columnCheckbox}
                          handleClick={() => onClickCheck(id)}
                        />
                      )}
                      <List.TextLinkColumn
                        css={styles.linkColumn}
                        data={subject}
                        href={
                          [EmailTemplateStatus.DRAFT, EmailTemplateStatus.SCHEDULED].includes(status)
                            ? `/emails/${id}/compose`
                            : `/emails/${id}/overview`
                        }
                      />
                      <List.TextColumn data={<StatusIndicator status={status} />} />
                      {!isMobileView && (
                        <>
                          {/* Note: API is returning timestamp based on the logged in user's country, we don't need to convert to local timezone */}
                          <List.TextColumn
                            data={scheduleDate ? format(new Date(scheduleDate), 'MMM dd, yyyy hh:mm a') : '-'}
                          />
                          <List.TextColumn data={sentAt ? format(new Date(sentAt), 'MMM dd, yyyy hh:mm a') : '-'} />
                          <List.TextColumn data={formatPercent(openRate)} />
                          <List.TextColumn data={formatPercent(clickRate)} />
                        </>
                      )}
                    </StyledComponents.StyledRowNew>
                  );
                })}
              </tbody>
            </SliderComponents.SliderTable>
          </SliderComponents.SliderSection>
        </SliderComponents.Container>

        {isEmptyEmails && <EmptyList />}
      </SliderComponents.Wrapper>

      <SliderComponents.Pager
        currentPage={currentPage}
        first={firstIndex}
        last={lastIndex}
        totalCount={totalCount}
        totalPages={totalPages}
      />
    </>
  );
};

const Thead = styled.thead<{ isItemSelected: boolean }>`
  background-color: ${({ isItemSelected }) => (isItemSelected ? '#27313b' : '#f6f8fa')};
  border-bottom: 1px solid #dfe7ec;
  border-top: 1px solid #dfe7ec;
`;

const styles = {
  actionContainer: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    & > div:nth-of-type(1) {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      margin-right: 16px;
    }

    & > div:nth-of-type(2) {
      color: #fff;
      cursor: pointer;
      font-size: 14px;
      text-decoration: underline;
    }

    & > div:last-child {
      display: flex;
      flex: 1;
      justify-content: flex-end;

      & > button {
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        height: 32px;
        margin-right: 16px;
        width: 70px;
      }
    }
  `,
  activeTableRow: css`
    background-color: #fffde7;

    & > td {
      background-color: #fffde7;
    }
  `,
  columnCheckbox: css`
    border-top: 1px solid #efefef;
    padding: 0 0 0 16px;
  `,
  container: css`
    border: none;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    margin: 0;
    padding: 0;
  `,
  dialogContent: css`
    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 24px;
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: 14px;
      margin-bottom: 16px;
    }

    & > div:nth-of-type(3) {
      color: #ff5f5f;
      font-size: 14px;
    }
  `,
  linkColumn: css`
    width: 300px;

    @media (max-width: ${ViewportType.TABLET}px) {
      width: 80%;
    }

    & > div > a {
      color: #3892e5;
      font-size: 14px;
      font-weight: 600;
    }
  `,
};

export default EmailList;
