import * as React from 'react';
import CheckBox from './CheckBox';

export interface CheckBoxForSelectAllProps {
  className?: string;
  name: string;
  label: string;
  disabled?: boolean;
  isCheckedAll: boolean;
  isCheckedNothing: boolean;
  interpolation?: Record<string, string>;
  onChange?: (e?: any) => void;
  handleClick?: (e: React.SyntheticEvent<HTMLDivElement>) => void;
}

const CheckBoxForSelectAll = (props: CheckBoxForSelectAllProps) => {
  const {
    className,
    name,
    disabled,
    onChange,
    handleClick,
    label,
    isCheckedAll,
    isCheckedNothing,
    interpolation,
  } = props;
  const checkedIcon = isCheckedAll ? 'check_box' : 'indeterminate_check_box';

  return (
    <CheckBox
      className={className}
      name={name}
      value={!isCheckedNothing}
      label={label}
      disabled={disabled}
      onChange={onChange}
      checkedIcon={checkedIcon}
      interpolation={interpolation}
      handleClick={handleClick}
    />
  );
};

export default CheckBoxForSelectAll;
