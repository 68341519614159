import React, { useState, useCallback } from 'react';
import { css } from '@emotion/react';
import { usePaging } from '@src/libs/paging';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { PalleteButton } from '@src/components/atoms/Button';
import { mainBlue } from '@src/libs/pallete';
import { Order } from '@src/__generated__/globalTypes';
import FansList from './FansList';
import FanListFilter from './FanListFilter';
import { useFilter } from './useFilter';
import useFanSelection from './useFanSelection';
import useFanList from './useFanList';
import SelectionNotice from './SelectionNotice';

const Fans = () => {
  const [sortOrder, setSortOrder] = useState(Order.DESC);
  const { isMobileView } = usePageLayout();
  const { filter, setFilter } = useFilter();
  const currentPage = usePaging();
  const { removeFansMutation } = useFanList();

  const {
    selectedFansId,
    selectedTags,
    isAllSelectedFromCurrentPage,
    totalFanCount,
    setTotalFanCount,
    toggleFanSelection,
    setFansIdFromCurrentPage,
    handleCheckAll,
    resetSelection,
  } = useFanSelection();

  const { t, enqueueSnackbar } = useQueryHelper();

  const handleSort = useCallback(() => {
    setSortOrder(sortOrder === Order.ASC ? Order.DESC : Order.ASC);
  }, [sortOrder]);

  const handleDelete = async () => {
    try {
      await removeFansMutation({
        variables: {
          input: {
            fanIds: Array.from(selectedFansId),
          },
        },
      });
      enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
      resetSelection();
    } catch (err) {
      const message = err?.message || 'failedToDelete';
      enqueueSnackbar(t(message), { variant: 'error' });
    }
  };

  return (
    <>
      <div css={[styles.header, isMobileView && styles.mobileHeader]}>
        {!isMobileView && <h4 css={styles.title}>{t('pageTitle.Fans')}</h4>}
        <PalleteButton
          href="/fans/add"
          text="Add Contact"
          palleteColor={mainBlue}
          borderRadius="3px"
          height="32px"
          width="fit-content"
        />
      </div>
      <FanListFilter filter={filter} setFilter={setFilter} onSort={handleSort} selectedFansId={selectedFansId} />
      {selectedFansId.size > 0 && (
        <SelectionNotice
          selectedFansId={selectedFansId}
          allSelectedTags={selectedTags}
          totalFanCount={totalFanCount}
          onConfirm={handleDelete}
          onSelectAll={handleCheckAll}
        />
      )}
      <FansList
        filter={filter}
        currentPage={currentPage}
        sortOrder={sortOrder}
        selectedFansId={selectedFansId}
        onChangeFanSelection={toggleFanSelection}
        setFansIdFromCurrentPage={setFansIdFromCurrentPage}
        setTotalFanCount={setTotalFanCount}
        isAllSelectedFromCurrentPage={isAllSelectedFromCurrentPage}
        onCheckAll={handleCheckAll}
      />
    </>
  );
};

const styles = {
  header: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 24px 0;
  `,
  mobileHeader: css`
    justify-content: flex-end;
  `,
  title: css`
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #27313b;
  `,
};

export default Fans;
