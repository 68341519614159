import styled from '@emotion/styled';
import { TitleWithHelp } from '@src/components/organisms/AnalyticsShared/AnalyticsContentWrapper';
import { ViewportBreakpoint } from '@src/libs/theme';
import { css } from '@emotion/react';

export const MainTitle = styled(TitleWithHelp)`
  margin: 22px 16px 16px;
`;
export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-top: 1px solid #dee5ec;
`;
export const EngagementDataContainer = styled.div`
  display: flex;
  height: 50%;
`;
export const EngagementDataBlock = styled.div`
  flex: 1;
  border-top: 1px solid #e6ecf0;
  border-right: 1px solid #e6ecf0;
  display: flex;
  flex-direction: column;
  padding-left: 16px;

  &:last-child,
  &:nth-of-type(3n) {
    border-right-style: none;
  }
`;
export const Label = styled.p`
  font-size: 11px;
  font-weight: 600;
  color: #27313b;
`;
export const LabelWithMargin = styled(Label)`
  margin-bottom: 4px;
`;
export const Value = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: #27313b;
  margin-bottom: 4px;
`;
export const Identifier = styled.p<{ isPositive: boolean }>`
  font-size: 13px;
  font-weight: 600;
  color: ${({ isPositive }) => (isPositive ? '#40b87c' : '#ff2b52')};
`;
export const Img = styled.img`
  align-self: flex-end;
`;
export const EngagementDataRow = styled.div`
  display: flex;
  height: 75px;
  outline: 1px solid #dee5ec;

  @media (min-width: ${ViewportBreakpoint.DESKTOP}px) {
    height: 100%;
  }
`;
export const EngagementHalf = styled.div<{ isDirectionColumn?: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  padding-left: 8px;
  overflow: hidden;
  ${({ isDirectionColumn }) =>
    isDirectionColumn &&
    `
  flex-direction: column;
  justify-content: center;

    p {
      text-align: center;
    }
`}
`;
export const GrowthWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const engagementStyles = {
  container: css`
    border-right: 1px solid #dee5ec;
  `,
  icon: css`
    width: 32px;
    height: 32px;
  `,
  gridContainer: css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1px;
    overflow: hidden;
    min-height: 200px;

    @media (min-width: ${ViewportBreakpoint.DESKTOP}px) {
      grid-template-columns: 1fr;
      height: 100%;
    }
  `,
};
