import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Popup from 'reactjs-popup';
import { defaultEmptyImage } from '../../../../libs/image';
import { ViewportBreakpoint } from '../../../../libs/theme';
import { switchImage } from '../../../../libs/SocialMedia';
import { formatDate } from '../../../../libs/format';
import { DialogModal, DialogTitle, DialogContent, WarningIcon } from '../../../molecules/DialogModal';
import { PalleteButton } from '../../../atoms/Button';
import { mainWhite, mainRed } from '../../../../libs/pallete';
import { getIconByName } from '../../../../libs/getIconByName';
import { NotFound } from '../../../../pages';
import DraftPostDetail from '../../DraftPostDetail';
import { WarningWidget } from '../../../molecules/WarningWidget';
import { EngagementCampaignPostStatus } from '../../../../__generated__/globalTypes';
import ActionButtonBlock from './ActionButtonBlock';
import StatusProgress from './StatusProgress';
import { Post } from './types';

export interface EngagementPostListProps {
  engagementId: number;
  posts: Post[];
  dialog: boolean;
  deleteEngagementPost: () => void;
  closeDialog: () => void;
  isDeleting: boolean;
  hasNotFoundError: boolean;
}

const EngagementPostList = (props: EngagementPostListProps) => {
  const { t } = useTranslation();
  const { engagementId, posts, dialog, deleteEngagementPost, closeDialog, isDeleting, hasNotFoundError } = props;
  const [previewPost, setPreviewPost] = useState<{ isOpened: boolean; postId?: number }>({
    isOpened: false,
  });

  const openPostPreview = (postId: number) => {
    setPreviewPost({
      isOpened: true,
      postId,
    });
  };

  const closePostPreview = () => {
    setPreviewPost({
      isOpened: false,
    });
  };

  if (hasNotFoundError) {
    return <NotFound />;
  }

  return (
    <>
      <Wrapper>
        {posts.length > 0 ? (
          posts.map(post => (
            <PostTile key={post.id}>
              <MainTileContent>
                <PostDateSection>
                  <SocialMediaIcon src={switchImage(post.socialMedia)} alt="social media icon" />
                  <DateWrapper>
                    <Title>{t('Planed Post Date')}</Title>
                    <Date>{formatDate(post.planedPostDate, 'MMM dd, yyyy')}</Date>
                  </DateWrapper>
                  <DateWrapper>
                    <Title>{t('Posted Date')}</Title>
                    <Date>{post.postedDate ? formatDate(post.postedDate, 'MMM dd, yyyy') : '- - -'}</Date>
                  </DateWrapper>
                </PostDateSection>
                {/* Post Status */}
                <PostStatus>
                  <Title>{t('Post Status')}</Title>
                  <StatusProgress status={post.status} />
                </PostStatus>
                {/* Thumbnail & Caption */}
                <PreviewWrapper>
                  {/* Reason for Report */}
                  <ReportWrapper>
                    {(post.status === EngagementCampaignPostStatus.WARNING ||
                      post.status === EngagementCampaignPostStatus.REJECT) && (
                      <WarningWidget
                        warningTitle={post.inappropriateReason || 'Invalid content'}
                        warningReason={post.inappropriateDetailedReason || ''}
                      />
                    )}
                  </ReportWrapper>
                  <PostWrapper>
                    <PostThumbnail src={defaultEmptyImage(post.thumbNail)} alt="post thumbnail" />
                    <Caption onClick={() => openPostPreview(post.id)}>{post.content}</Caption>
                  </PostWrapper>
                </PreviewWrapper>
              </MainTileContent>

              <ActionButtonBlock post={post} engagementId={engagementId} />
            </PostTile>
          ))
        ) : (
          // TODO: We don't have empty post page design
          <NoPosts>{t('No Posts')}</NoPosts>
        )}
      </Wrapper>
      <Popup
        contentStyle={{
          backgroundColor: 'transparent',
          border: 'none',
          width: '90%',
          height: '90%',
          maxWidth: '500px',
          maxHeight: '660px',
          padding: '16px',
        }}
        open={previewPost.isOpened}
        onClose={closePostPreview}
      >
        {!!previewPost.postId ? (
          <DraftPostDetail closeModal={closePostPreview} postId={previewPost.postId} />
        ) : (
          <WrapperNotFound>
            <Close onClick={closePostPreview} />
            <NotFound showLink={false} />
          </WrapperNotFound>
        )}
      </Popup>
      <DialogModal
        isOpen={dialog}
        BackButton={<PalleteButton text="Cancel" palleteColor={mainWhite} onClick={closeDialog} />}
        NextButton={
          <PalleteButton text="OK" palleteColor={mainRed} onClick={deleteEngagementPost} isDisabled={isDeleting} />
        }
        closeModal={closeDialog}
      >
        <DialogTitle>
          <WarningIcon src={getIconByName('warning')} alt="warning icon" />
          {t('Confirmation required')}
        </DialogTitle>
        <DialogContent>{t('Dialog.Are you sure you want to delete the post?')}</DialogContent>
      </DialogModal>
    </>
  );
};

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 48px;
  padding: 16px;
  height: fit-content;

  @media (min-width: ${ViewportBreakpoint.MEDIUM}px) {
    flex-direction: row;
    flex-wrap: wrap;

    & > div {
      flex: 0 calc(50% - 8px);
    }
  }
`;

const PostTile = styled.div`
  flex: 1;
  height: fit-content;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px #dee5ec80;
  background-color: #fff;
  margin-bottom: 16px;

  @media (min-width: ${ViewportBreakpoint.MEDIUM}px) {
    flex: 0.5;

    &:nth-of-type(even) {
      margin-left: 16px;
    }
  }
`;
const MainTileContent = styled.div`
  padding: 16px;
`;
const PostDateSection = styled.div`
  display: flex;
  margin-bottom: 16px;
`;
const SocialMediaIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;
const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:first-of-type) {
    margin-left: 12px;
  }
`;
const Title = styled.h3`
  font-size: 11px;
  color: #6e7c89;
`;
const Date = styled.span`
  font-size: 12px;
  color: #27313b;
  margin-top: 9px;
`;
const PostStatus = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px dashed #dee5ec;
  padding-top: 16px;
`;

const ReportWrapper = styled.div`
  margin-bottom: 16px;
`;

const PostWrapper = styled.div`
  display: flex;
`;

const WrapperNotFound = styled.div`
  height: 100%;
  width: 100%;
  background: #f6f8fa;
`;

const PostThumbnail = styled.img`
  width: 108px;
  height: 80px;
  margin-right: 12px;
  background-color: #000;
  object-fit: contain;
`;
const Caption = styled.p`
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  flex: 1;
  line-height: 1.38;
  overflow: hidden;
  word-break: break-all;
  font-size: 13px;
  height: max-content;
  margin: 8px 0;
  /* stylelint-enable */
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    text-decoration: underline;
    color: #3892e5;

    @media (max-width: 450px) {
      opacity: 1;
    }
  }
`;
const Close = styled.a`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  width: 36px;
  height: 36px;
  font-size: 25px;
  line-height: 34px;
  font-weight: 100;
  background: #fff;
  border-radius: 50%;
  background-color: ${mainRed.normal};
  cursor: pointer;

  &:hover {
    background-color: ${mainRed.hover};
  }

  &::before {
    content: '\\00d7';
    color: #fff;
    font-weight: bold;
  }
`;

const NoPosts = styled.div`
  margin: 40px auto 0;
  text-align: center;
`;

export default EngagementPostList;
