import * as React from 'react';
import styled from '@emotion/styled';
import CarouselModal from '@src/components/molecules/CarouselModal/CarouselModal';
import DataOpacityWrapper, { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import PostCarousel from '@src/components/molecules/PostCarousel';
import { useUuumInfluencerView, useQueryHelper } from '@src/libs/hooks';
import { usePostDetailModal } from '@src/components/organisms/AnalyticsShared/BasePostList/usePostDetailModal';
import * as StyledComponents from '@src/components/organisms/AnalyticsShared/PostDetail/SliderStyledComponents';
import { SocialMediaAccountType } from '@src/components/organisms/ProfileDetail/types';
import { FeedPost } from '../types';
import AnayticsFeedPostDetail from './AnalyticsFeedPostDetail';

export interface FeedPostsProps {
  className?: string;
  posts: FeedPost[];
  selectedSocialMedia: SocialMediaAccountType;
  hasMoreLink?: boolean;
}

const FeedPosts = (props: FeedPostsProps) => {
  const { className, posts, selectedSocialMedia, hasMoreLink = true } = props;
  const { t, search } = useQueryHelper();
  const { isUuumView } = useUuumInfluencerView();
  const { id: socialAccountId } = selectedSocialMedia;

  const getParams = () => {
    const queryParams = new URLSearchParams(search);
    queryParams.set('id', String(socialAccountId));

    return queryParams.toString();
  };

  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev,
  } = usePostDetailModal(posts || []);

  return (
    <>
      <DataOpacityWrapper
        className={className}
        header={<SectionHeader>{t('Title.Feed Posts')}</SectionHeader>}
        dataNotAvailable={!posts || posts.length === 0}
        css={{ minHeight: '180px' }}
      >
        <PostCarousel posts={posts} onPostClick={(id: number) => handleClickPost(id)} />
        {hasMoreLink ? (
          <StyledComponents.MoreText
            to={
              //  TODO: remove after full migration to AnyX
              isUuumView
                ? `/profile/instagram_feed_post?${getParams()}`
                : `/analytics/instagram_feed_post?${getParams()}`
            }
          />
        ) : null}
      </DataOpacityWrapper>
      <CarouselModal
        open={!!openPostDetail}
        title=""
        cancel="OK"
        onClose={handleClosePostDetail}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        siblingPostId={siblingPostId}
      >
        <Container>
          <AnayticsFeedPostDetail accountId={socialAccountId} closeModal={handleClosePostDetail} />
        </Container>
      </CarouselModal>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  margin: auto;
  height: 100%;
`;

export default FeedPosts;
