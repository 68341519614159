import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { SerializedStyles } from '@emotion/core';
import { ApolloError } from '@apollo/client';
import {
  EngagementDraftPostHistoryForInfluencer,
  EngagementDraftPostHistoryForInfluencerVariables,
} from '../__generated__/EngagementDraftPostHistoryForInfluencer';
import * as ENGAGEMENT_DRAFT_POST_HISTORY_FOR_INFLUENCER from '../EngagementDraftPostHistoryForInfluencer.graphql';
import { formatDate } from '../../../../libs/format';
import { useQueryHelper } from '../../../../libs/hooks';
import { getEngagementPostStatusHistoryMessage } from '../../../../libs/status/message';
import { getEngagementPostStatusTextColor } from '../../../../libs/status/color';
import { EngagementCampaignPostStatus } from '../../../../__generated__/globalTypes';

interface DraftPostHistoryTabProps {
  postId: number;
}

const DraftPostHistory = ({ postId }: DraftPostHistoryTabProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();

  const { data } = useQuery<EngagementDraftPostHistoryForInfluencer, EngagementDraftPostHistoryForInfluencerVariables>(
    ENGAGEMENT_DRAFT_POST_HISTORY_FOR_INFLUENCER,
    {
      variables: { pk: postId },
      onError: useCallback((error: ApolloError) => {
        enqueueSnackbar(t(error.message || 'UnexpectedError'), { variant: 'error' });
      }, []),
    }
  );

  const stories = data?.engagementDraftPostHistoryForInfluencer?.historyRecords || [];

  return (
    <>
      <Header>{t('History')}</Header>
      <Content>
        {stories.length === 0 ? (
          <NoHistory>{t('No History')}</NoHistory>
        ) : (
          stories.map(history => {
            const showAsColoredTitle =
              history.postStatus === EngagementCampaignPostStatus.READY_TO_POST || !history.reason;

            return (
              <HistoryWrapper key={history.id}>
                <HistoryDate>{formatDate(history.createdDate, 'MMM dd, yyyy')}</HistoryDate>
                <HistoryContentWrapper>
                  {!showAsColoredTitle && history.reason && (
                    <>
                      <HistoryTitle>{history.reason}</HistoryTitle>
                      <HistoryContent>{history.detailedReason}</HistoryContent>
                    </>
                  )}
                  {showAsColoredTitle && (
                    <HistoryTitle textColor={getEngagementPostStatusTextColor(history.postStatus)}>
                      {t(getEngagementPostStatusHistoryMessage(history.postStatus))}
                    </HistoryTitle>
                  )}
                </HistoryContentWrapper>
              </HistoryWrapper>
            );
          })
        )}
      </Content>
    </>
  );
};

export default DraftPostHistory;

const Header = styled.div`
  display: inline-flex;
  margin-bottom: 8px;
  border-bottom: solid 1px #dee5ec;
  width: 100%;
  padding: 16px 0;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.88;
  letter-spacing: normal;
  color: #27313b;
`;

const HistoryDate = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #27313b;
  width: fit-content;
`;

const HistoryContent = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #6e7c89;
`;

interface HistoryTitleProps {
  textColor?: SerializedStyles;
}

const HistoryTitle = styled.div<HistoryTitleProps>`
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  margin-bottom: 4px;
  ${props => (props.textColor ? props.textColor : 'color: #27313b')};
`;

const Content = styled.div`
  height: 100%;
  overflow-y: auto;

  > div {
    border-bottom: solid 1px #dee5ec;
  }

  > div:last-child {
    border-bottom: none;
  }
`;

const HistoryWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  padding: 8px 0;
`;

const NoHistory = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const HistoryContentWrapper = styled.div`
  margin-left: 6px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;
