import React, { useState } from 'react';
import { css } from '@emotion/react';
import { useSort } from '@src/libs/filter';
import { getOffset, getPageInfo } from '@src/libs/paging';
import { LIMIT } from '@src/libs/paging/constant';
import { useQueryHelper } from '@src/libs/hooks';
import {
  YoutubeAnalyticsComparePostsSort,
  YoutubeAnalyticsComparePostsSortOrder,
  YoutubeAnalyticsPostsSortInput,
} from '@src/__generated__/globalTypes';
import { useQuery } from '@apollo/client';
import { usePostDetailModal } from '@src/components/organisms/AnalyticsInstagram/Dashboard/PostCard/BasePostList/usePostDetailModal';
import { ListIndicator } from '@src/components/molecules/Indicator';
import CarouselModal from '@src/components/molecules/CarouselModal/CarouselModal';
import AnalyticsPostDetail from '@src/components/organisms/AnalyticsYoutube/Dashboard/AnalyticsPostDetail';
import PageCount from '@src/components/molecules/PageCount';
import { SliderComponents } from '@src/components/molecules/SliderTable';
import styled from '@emotion/styled';
import { BasePostFilterType } from '@src/components/organisms/AnalyticsShared/BasePostListFilter/types';
import { CompareAccountType } from '../types';
import {
  InfluencerYoutubeComparePostsInfo,
  InfluencerYoutubeComparePostsInfoVariables,
  InfluencerYoutubeComparePostsInfo_influencerYoutubeComparePostList_posts,
} from '../queries/__generated__/InfluencerYoutubeComparePostsInfo';
import {
  InfluencerYoutubeAnalyticsPostsInfo,
  InfluencerYoutubeAnalyticsPostsInfoVariables,
  InfluencerYoutubeAnalyticsPostsInfo_influencerYoutubeAnalyticsPosts_posts,
} from '../queries/__generated__/InfluencerYoutubeAnalyticsPostsInfo';
import COMPARE_ACCOUNT_YOUTUBE_POSTS from '../queries/InfluencerYoutubeComparePostsInfo.graphql';
import INFLUENCER_YOUTUBE_POSTS from '../queries/InfluencerYoutubeAnalyticsPostsInfo.graphql';
import { getYtCompareVideosListSortFiledType } from './utils';
import TableContent from './TableContent';
import TableContentMobile from './TableContentMobile';

interface TableVideoListProps {
  account: CompareAccountType;
  filter: BasePostFilterType;
  isMobile: boolean;
}

type Posts =
  | InfluencerYoutubeAnalyticsPostsInfo_influencerYoutubeAnalyticsPosts_posts[]
  | InfluencerYoutubeComparePostsInfo_influencerYoutubeComparePostList_posts[];

const TableVideoList = ({ account, filter, isMobile }: TableVideoListProps) => {
  const { accountId, avatarUrl, channelName, isMain } = account;
  const [posts, setPosts] = useState<Posts>([]);
  const { sort, setSort } = useSort<YoutubeAnalyticsComparePostsSortOrder>(getYtCompareVideosListSortFiledType);
  const { enqueueSnackbar, search, t } = useQueryHelper();
  const query = new URLSearchParams(search);
  const socialAccountId = query.get('id');
  const currentPage = Number(query.get('p')) || 1;

  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev,
  } = usePostDetailModal(posts || []);

  const { data: dataMainAccount, loading: loadingMainAccount } = useQuery<
    InfluencerYoutubeAnalyticsPostsInfo,
    InfluencerYoutubeAnalyticsPostsInfoVariables
  >(INFLUENCER_YOUTUBE_POSTS, {
    skip: !accountId || !isMain,
    variables: {
      socialAccountId: accountId,
      keyword: filter.keyword,
      offset: getOffset(currentPage),
      limit: LIMIT,
      orderBy: sort as YoutubeAnalyticsPostsSortInput,
      ...filter.period,
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    onCompleted: ({ influencerYoutubeAnalyticsPosts }) => {
      setPosts(influencerYoutubeAnalyticsPosts.posts || []);
    },
  });

  const { data: dataCompareAccount, loading: loadingCompareAccount } = useQuery<
    InfluencerYoutubeComparePostsInfo,
    InfluencerYoutubeComparePostsInfoVariables
  >(COMPARE_ACCOUNT_YOUTUBE_POSTS, {
    skip: !accountId || isMain,
    variables: {
      socialAccountId: Number(socialAccountId),
      compareAccountId: accountId,
      keyword: filter.keyword,
      offset: getOffset(currentPage),
      limit: LIMIT,
      orderBy: sort as YoutubeAnalyticsComparePostsSort,
      ...filter.period,
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    onCompleted: ({ influencerYoutubeComparePostList }) => {
      setPosts(influencerYoutubeComparePostList.posts || []);
    },
  });

  const totalVideos =
    dataMainAccount?.influencerYoutubeAnalyticsPostsCount.totalNumber ||
    dataCompareAccount?.influencerYoutubeComparePostsCount.totalNumber ||
    0;

  const { totalCount, totalPages, firstIndex, lastIndex } = getPageInfo(currentPage, totalVideos);

  function onCloseModal() {
    handleClosePostDetail();
  }

  if (loadingMainAccount || loadingCompareAccount) {
    return <ListIndicator css={styles.listIndicator} />;
  }

  return (
    <>
      <CarouselModal
        cancel="OK"
        open={!!openPostDetail}
        siblingPostId={siblingPostId}
        title=""
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        onClose={onCloseModal}
      >
        <AnalyticsPostDetail
          accountId={Number(socialAccountId)}
          compareAccountId={accountId}
          isMain={isMain}
          postId={openPostDetail}
          avatarUrl={avatarUrl}
          accountName={channelName}
        />
      </CarouselModal>
      <ButtonWrapper css={isMobile ? { minHeight: 'auto', margin: '0 16px' } : ''}>
        <PageCount total={totalCount} countLabel="Videos" />
      </ButtonWrapper>

      {isMobile ? (
        <TableContentMobile posts={posts} onClick={postId => handleClickPost(postId)} />
      ) : (
        <TableContent posts={posts} sort={sort} onClick={postId => handleClickPost(postId)} onSort={setSort} />
      )}

      <SliderComponents.Pager
        currentPage={currentPage}
        first={firstIndex}
        last={lastIndex}
        totalCount={totalCount}
        totalPages={totalPages}
        css={isMobile ? '' : { margin: '0 24px' }}
      />
    </>
  );
};

const styles = {
  buttonWrapper: css`
    border-top: 1px solid #dee5ec;
  `,
  listIndicator: css`
    margin: 0;
  `,
};

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 35px;
  margin: 0 24px 0;
  padding: 8px 15px;
  background-color: #fff;
  border-top: 1px solid #dee5ec;
  box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 0 2px #dee5ec;
`;

export default TableVideoList;
