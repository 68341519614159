import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { SingleSelector } from '@src/components/molecules/Selector';
import hideGray from '@src/assets/icon/hide.svg';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useMediaQuery } from 'react-responsive';
import { ViewportBreakpoint } from '@src/libs/theme';
import { AvatarAndNameType } from './types';
import { compareColors, HIDDEN_KEY } from './compareHelpers';

interface ComparableAccountsSelectorsProps {
  accountsAvatarAndName: AvatarAndNameType[];
  uniqIdForGraph: string[];
  setUniqIdForGraph: React.Dispatch<React.SetStateAction<string[]>>;
}
export const ComparableAccountsSelectors = (props: ComparableAccountsSelectorsProps) => {
  const { accountsAvatarAndName, uniqIdForGraph, setUniqIdForGraph } = props;
  const isDesktop = useMediaQuery({ minWidth: ViewportBreakpoint.DESKTOP });
  // in fact there are 5 comparable + 1 main account
  const remainigAccounts = accountsAvatarAndName.slice(5, 6);
  const { t } = useTranslation();

  useEffect(() => {
    // recalculate remainig dropdowns options on hide/show accounts
    setDropdownAccounts(remainigAccounts);
  }, [accountsAvatarAndName.length]);

  const [dropdownAccounts, setDropdownAccounts] = useState<AvatarAndNameType[]>(remainigAccounts);

  const handleChange = (currUniqId: string, newUniqId: string) => {
    // No need to do all sorting if we select the same thing.
    if (currUniqId === newUniqId) {
      return;
    }
    setUniqIdForGraph(prevState => prevState.map(selection => (selection === currUniqId ? newUniqId : selection)));

    setDropdownAccounts(prevState => {
      // remove the new newly selected accoutn from dropdown if there is any
      const accounts = prevState.filter(acc => acc.uniqId !== newUniqId);

      // add back the previously selected account to the dropdown if there is any
      const previous = accountsAvatarAndName.find(acc => acc.uniqId === currUniqId);
      if (previous) {
        accounts.push(previous);
      }

      return accounts;
    });
  };

  return (
    <div css={isDesktop ? styles.desktopWrapper : styles.chartWrapper}>
      {/* 5 is a max */}
      {uniqIdForGraph.map((uniqId, idx) => (
        <SingleSelector
          key={`compareAccount_${uniqId}`}
          name={`compareAccount_${idx}`}
          value={uniqId}
          options={buildOptions(
            accountsAvatarAndName.find(acc => acc.uniqId === uniqId),
            dropdownAccounts,
            idx,
            t
          )}
          onChange={newId => handleChange(uniqId, newId)}
          hideInput
          hideDeselectOption
          isRoundInput={false}
          menuCss={css`
            border: none;
          `}
          css={isDesktop ? styles.desktopSelectorCss : styles.selectorCss}
          dropdownCss={
            isDesktop
              ? css`
                  max-width: calc((100vw - 250px) / 5);
                `
              : undefined
          }
        />
      ))}
    </div>
  );
};

const buildOptions = (
  account: AvatarAndNameType | undefined,
  allAccounts: AvatarAndNameType[],
  idx: number,
  t: TFunction
) => [
  ...(account ? prepareDropdownOptions([account], idx) : []),
  ...prepareDropdownOptions(allAccounts, idx),
  hiddenOption(idx, t),
];

const hiddenOption = (idx: number, t: TFunction) => ({
  label: t('Hidden'),
  title: '',
  icon: (
    <img
      css={[
        styles.avatar,
        css`
          background-color: black;
        `,
      ]}
      src={hideGray}
    />
  ),
  value: `${HIDDEN_KEY}${idx}`,
});

const prepareDropdownOptions = (accounts: AvatarAndNameType[], idx: number) =>
  accounts.map(acc => ({
    ...acc,
    value: acc.uniqId,
    label: acc.name,
    icon: <img src={acc.avatar} alt="account's avatar" css={styles.avatar} />,
    prefixIcon: (
      <div
        css={css`
          width: 14px;
          height: 14px;
          background-color: ${compareColors[idx]};
          margin-right: 16px;
          flex-shrink: 0;
        `}
      />
    ),
  }));

const styles = {
  desktopWrapper: css`
    display: flex;
    margin: 0 16px 16px 16px;
  `,
  desktopSelectorCss: css`
    height: 40px;
    max-width: calc((100vw - 250px) / 5);

    & > div {
      height: 100%;

      > div {
        height: 100%;
        align-items: center;
      }
    }
  `,
  selectorCss: css`
    height: 40px;
    border-radius: 0;
    border: none;

    /* stylelint-disable */
    & > div {
      height: 100%;

      > div {
        height: 100%;
        align-items: center;
      }
    }
  `,
  chartWrapper: css`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 50%);

    & > div {
      border-bottom: 1px solid #e6ecf0;
      width: auto;

      &:nth-of-type(odd) {
        border-right: 1px solid #e6ecf0;
      }
    }
  `,
  avatar: css`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 4px;
  `,
};
