import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { convertDurationToHhMmSs } from '@src/libs/date';
import { SocialAccountType, CampaignSocialMediaType } from '@src/__generated__/globalTypes';
import { formatIntNumber, formatPercent } from '@src/libs/format';
import { switchImage } from '@src/libs/SocialMedia';
import { YoutubeEngagementInfoProps } from '../types';
import * as Styled from '../StyledEngagement';

const YoutubeEngagementInfo = (props: YoutubeEngagementInfoProps) => {
  const { t } = useTranslation();
  const {
    averageViewDuration,
    averageViewPercentage,
    views,
    videoId,
    likes,
    dislikes,
    comments,
    positiveRate,
    negativeRate,
  } = props;
  const postUrl = `https://youtu.be/${videoId}`;

  return (
    <Styled.MainContainer>
      <Styled.Title>
        {t('Engagement')}
        <Styled.Anchor href={postUrl as string} target="_blank" rel="noreferrer">
          {`View on ${CampaignSocialMediaType.YOUTUBE}`}
        </Styled.Anchor>
      </Styled.Title>
      <Styled.EngagementContainer>
        <Styled.Engagement>
          <Styled.Icon src={switchImage(SocialAccountType.YOUTUBE, true)} alt="" />
          <Styled.CountWrapper css={{ gridTemplateColumns: 'repeat(auto-fill, minmax(100px, auto))' }}>
            <Styled.Wrapper>
              <Styled.Value>{formatIntNumber(views)}</Styled.Value>
              <Styled.Unit text={t('Views')} lines={1} />
            </Styled.Wrapper>
            {averageViewDuration !== undefined && (
              <Styled.Wrapper>
                <Styled.Value>{convertDurationToHhMmSs(averageViewDuration)}</Styled.Value>
                <Styled.Unit text={t('Average View Duration')} lines={2} />
              </Styled.Wrapper>
            )}
            {averageViewPercentage !== undefined && (
              <Styled.Wrapper>
                <Styled.Value>{`${formatPercent(averageViewPercentage)}`}</Styled.Value>
                <Styled.Unit text={t('Average View Duration Rate')} lines={2} />
              </Styled.Wrapper>
            )}
            <Styled.Wrapper>
              <Styled.Value>{formatIntNumber(likes)}</Styled.Value>
              <Styled.Unit text={t('Likes')} lines={1} />
            </Styled.Wrapper>
            <Styled.Wrapper>
              <Styled.Value>{formatIntNumber(dislikes)}</Styled.Value>
              <Styled.Unit text={t('Dislikes')} lines={1} />
            </Styled.Wrapper>
            <Styled.Wrapper>
              <Styled.Value>{formatIntNumber(comments)}</Styled.Value>
              <Styled.Unit text={t('Comments')} lines={1} />
            </Styled.Wrapper>
            <Styled.Wrapper>
              <Styled.Value>{formatPercent(positiveRate, true, 2)}</Styled.Value>
              <Styled.Unit text={t('Positive Rate')} lines={1} />
            </Styled.Wrapper>

            <Styled.Wrapper>
              <Styled.Value>{formatPercent(negativeRate, true, 2)}</Styled.Value>
              <Styled.Unit text={t('Negative Rate')} lines={1} />
            </Styled.Wrapper>
          </Styled.CountWrapper>
        </Styled.Engagement>
      </Styled.EngagementContainer>
    </Styled.MainContainer>
  );
};

export default YoutubeEngagementInfo;
