import React from 'react';
import iconYourJob from '../../../../assets/icon/bag.svg';
import imgStep1 from '../../../../assets/img/tutorial/yourJob/step1.jpg';
import imgStep2 from '../../../../assets/img/tutorial/yourJob/step2.jpg';
import imgStep3 from '../../../../assets/img/tutorial/yourJob/step3.jpg';
import { mainRed, mainWhite } from '../../../../libs/pallete';
import BaseTutorial, { TutorialSteps } from '../BaseTutorial';
import { TutorialName } from '../../../../__generated__/globalTypes';

const TutorialYourJob = () => {
  const steps: TutorialSteps[] = [
    {
      titleIcon: iconYourJob,
      title: 'YourJobStepOne',
      img: imgStep1,
      heading: 'YourJobStepOne',
      text: 'YourJobStepOne',
      backButton: { text: 'Skip', palleteColor: mainWhite, borderColor: '#dee5ca' },
      nextButton: { text: 'Next', palleteColor: mainRed },
    },
    {
      titleIcon: iconYourJob,
      title: 'YourJobStepTwo',
      img: imgStep2,
      heading: 'YourJobStepTwo',
      text: 'YourJobStepTwo',
      backButton: { text: 'Prev', palleteColor: mainWhite, borderColor: '#dee5ca' },
      nextButton: { text: 'Next', palleteColor: mainRed },
    },
    {
      titleIcon: iconYourJob,
      title: 'YourJobStepThree',
      img: imgStep3,
      heading: 'YourJobStepThree',
      text: 'YourJobStepThree',
      backButton: { text: 'Prev', palleteColor: mainWhite, borderColor: '#dee5ca' },
      nextButton: { text: 'Start', palleteColor: mainRed },
    },
  ];

  return <BaseTutorial name={TutorialName.YOUR_JOB} steps={steps} />;
};

export default TutorialYourJob;
