import { getParamsFromSearch } from '../paging/utils';
import { getOrderType } from '../constant';
import { SortType } from './index';

interface SortParams<T extends string> {
  f: T | ''; // field
  o: string; // order
}

export const getInitialSort = <T extends string>(search: string, getInitialField: (field?: T | '') => T | null) => {
  const params = getParamsFromSearch<SortParams<T>>(search);

  const field = params.f;
  const order = params.o;

  const initialSort: SortType<T> = {
    field: getInitialField(field),
    order: getOrderType(order),
  };

  return initialSort;
};

export const getSortParams = <T extends string>(params: SortType<T>): SortParams<T> => ({
  f: params.field || '',
  o: params.order || '',
});
