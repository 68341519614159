import styled from '@emotion/styled';
import * as React from 'react';
import { format, isValid, parseISO } from 'date-fns';
import { enUS, ja } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../libs/format';
import { AppLanguage } from '../../../libs/i18n/languageDetector/utils';

export const getDateLocal = (locale?: AppLanguage) => {
  switch (locale) {
    case 'ja':
      return ja;
    case 'en':
    default:
      return enUS;
  }
};

export const localizedDateFormatter = (
  date: Date | number | string,
  dateFormat = 'MMM do',
  local: AppLanguage = 'en'
): string => {
  const dateIsIso = typeof date === 'string' ? isValid(parseISO(date)) : false;
  if (!date || (!dateIsIso && !isValid(date))) {
    return '-';
  }

  // https://stackoverflow.com/questions/65699256/datefns-format-is-not-completely-formatted
  // We want Dec 23, 2020 in english (not full date), but 2020年2月15日 in japanese (full date)
  if (dateFormat === 'PPP' && local !== 'ja') {
    dateFormat = 'MMM dd, yyyy';
  }

  return format(new Date(date), dateFormat, { locale: getDateLocal(local) });
};

interface DateColumnProps {
  date: string | null;
  className?: string;
  isTable?: boolean;
  ignoreClientUTC?: boolean;
}

const DateColumn = (props: DateColumnProps) => {
  const { date, className, isTable = true, ignoreClientUTC } = props;
  const { i18n } = useTranslation();
  const Wrapper = isTable ? TableWrapper : DivWrapper;
  // https://stackoverflow.com/questions/66914706/date-fns-format-function-shows-next-date-instead-of-the-expected-one
  // `ignoreClientUTC` props uses the same UTC from incoming ISO date
  const parsedDate = ignoreClientUTC ? date?.replace('Z', '') || null : date;

  return (
    <Wrapper className={className}>
      <Data>
        {parsedDate ? (
          <>
            {localizedDateFormatter(parsedDate, 'MMM do', i18n.language as AppLanguage)}
            <br />
            <Year>{formatDate(parsedDate, 'yyyy')}</Year>
          </>
        ) : (
          '-'
        )}
      </Data>
    </Wrapper>
  );
};

const TableWrapper = styled.td`
  height: 56px;
  padding: 0 8px;
`;
const DivWrapper = styled.div``;

const Data = styled.p`
  font-size: 13px;
  text-align: left;
  white-space: nowrap;
`;

const Year = styled.span`
  color: #8694a2;
  font-size: 11px;
  text-align: center;
`;

export default DateColumn;
