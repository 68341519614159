import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatIntNumber, bigIntFormatter } from '@src/libs/format';
import HighChart, { Highcharts } from '@src/components/molecules/HighChart';
import AnalyticsContentWrapper, {
  ContentType,
} from '@src/components/organisms/AnalyticsShared/AnalyticsContentWrapper';
import userpicIcon from '@src/assets/icon/userpic.svg';
import pictureIcon from '@src/assets/icon/picture.svg';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import DataOpacityWrapper from '@src/components/molecules/DataCard/DataOpacityWrapper';
import { ViewportBreakpoint } from '@src/libs/theme';
import {
  InfluencerProfileFollowerGrowth_influencerProfileFollowerGrowth_followersGrowth as FollowersGrowthData,
  InfluencerProfileFollowerGrowth_influencerProfileFollowerGrowth_postsGrowth as PostGrowthData,
} from './__generated__/InfluencerProfileFollowerGrowth';
import PostByDateWithModal from './PostByDateWithModal';

const chartHeight = 190;
const followerColor = '#FF2B52';
const postColor = '#FFD7C1';

interface FollowersGrowthAndPostsProps {
  influencerAccountId: number;
  followersGrowth: FollowersGrowthData[];
  className?: string;
  postsGrowth?: PostGrowthData[];
  totalFollowers?: number | null;
  totalPosts?: number | null;
}
const FollowersGrowthAndPostsChart = (props: FollowersGrowthAndPostsProps) => {
  const { className, followersGrowth, postsGrowth, totalFollowers, totalPosts, influencerAccountId } = props;
  const { t, i18n } = useTranslation();

  const { followersCategories, followersSeries } = followersGrowth.reduce<{
    followersSeries: (number | null)[];
    followersCategories: string[];
  }>(
    (acc, curr) => ({
      followersSeries: [...acc.followersSeries, curr.followersCount],
      followersCategories: [...acc.followersCategories, curr.time],
    }),
    {
      followersSeries: [],
      followersCategories: [],
    }
  );
  const [selectedPostIds, setSelectedPostIds] = useState<{ id: number }[]>([]);
  const clearPostIds = () => setSelectedPostIds([]);
  const postSeries = postsGrowth?.map(el => el.postsCount);

  const options: Highcharts.Options = {
    chart: {
      height: chartHeight,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: followersCategories,
      labels: {
        formatter: function formatter() {
          return localizedDateFormatter(this.value, 'MMM do', i18n.language as AppLanguage);
        },
      },
    },
    yAxis: [
      {
        title: {
          text: '',
        },
        labels: {
          formatter: function formatter() {
            return bigIntFormatter(this.value);
          },
        },
        allowDecimals: false,
      },
      {
        title: {
          text: '',
        },
        labels: {
          enabled: false,
        },
        allowDecimals: false,
      },
    ],
    legend: {
      enabled: false,
    },
    series: [
      {
        type: 'line',
        data: followersSeries,
        color: followerColor,
        marker: {
          symbol: 'circle',
        },
        zIndex: 1,
      },
      {
        name: 'Post',
        type: 'column',
        data: postSeries,
        color: postColor,
        yAxis: 1,
        cursor: 'pointer',
        point: {
          events: {
            click() {
              // find postIds and convert them to Array<{id: number}> to feed into usePostDetailModal
              setSelectedPostIds(postsGrowth?.find(el => el.date === this.category)?.postIds.map(id => ({ id })) || []);
            },
          },
        },
      },
    ],
    tooltip: {
      padding: 0,
      formatter: function formatter(): string {
        return `
          <div style="border-radius:3px;border: 2px solid #27313b;margin:-2px;background-color:#27313b;font-size:12px;padding:13px 16px;display:flex;align-items:center">
            <span style="display:inline-block;margin-right:8px;background-color:${
              this.point.color
            };height:12px;width:12px"></span>
            <span style="margin-right:16px">${this.key}
            </span><span>${formatIntNumber(this.point.y)}</span>
          </div>`;
      },
      pointFormat: '',
      borderColor: '#27313b',
      borderWidth: 0,
      backgroundColor: '#27313b',
      style: {
        color: '#fff',
      },
      useHTML: true,
      headerFormat: '',
    },
  };

  const dataNotAvailable = !(followersGrowth.length || postsGrowth?.length);

  return (
    <>
      <AnalyticsContentWrapper contentType={ContentType.FollowersGrowth}>
        <Summary>
          <SummaryItem>
            <Image src={userpicIcon} width={40} height={40} />
            <Title>{t('Follower')}</Title>
            <Count>{formatIntNumber(totalFollowers)}</Count>
          </SummaryItem>
          <SummaryItem>
            <Image src={pictureIcon} width={40} height={40} />
            <Title>{t('Post')}</Title>
            <Count>{formatIntNumber(totalPosts)}</Count>
          </SummaryItem>
        </Summary>

        <DataOpacityWrapper className={className} dataNotAvailable={dataNotAvailable}>
          <Legend>
            <LegendItem>
              {t('Follower')}
              <Box css={{ backgroundColor: followerColor }} />
            </LegendItem>
            <LegendItem>
              {t('Post')}
              <Box css={{ backgroundColor: postColor }} />
            </LegendItem>
          </Legend>
          <HighChart options={options} />
        </DataOpacityWrapper>
      </AnalyticsContentWrapper>
      <PostByDateWithModal
        influencerAccountId={influencerAccountId}
        posts={selectedPostIds}
        clearPostIds={clearPostIds}
      />
    </>
  );
};

const Image = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
`;
const Legend = styled.div`
  display: flex;
  gap: 8px;
  margin: 16px;
`;
const LegendItem = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #27313b;
  background: #f6f8fa;
  border: 1px solid #dee5ec;
  box-sizing: border-box;
  border-radius: 3px;
`;
const Box = styled.div`
  width: 8px;
  height: 8px;
  margin-left: 4px;
  border-radius: 2px;
`;
const Summary = styled.div`
  display: flex;
  color: #27313b;
  border-bottom: 1px solid #dee5ec;
`;
const SummaryItem = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 163px;
  padding: 16px;
  box-sizing: border-box;
  border-right: 1px solid #dee5ec;

  @media (max-width: ${ViewportBreakpoint.MOBILE}px) {
    width: auto;
    flex: 1;

    &:last-of-type {
      border-right: none;
    }
  }
`;
const Title = styled.span`
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.28px;
`;
const Count = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
`;

export default FollowersGrowthAndPostsChart;
