import React from 'react';
import styled from '@emotion/styled';
import { mainWhite } from '../../../../libs/pallete';
import { PalleteButton } from '../../../atoms/Button';

interface MarketplacePostButtonsProps {
  campaignId?: number | null;
  isJoined?: boolean;
  hasReport?: boolean;
}
const MarketplacePostButtons = (props: MarketplacePostButtonsProps) => {
  const { campaignId, isJoined, hasReport } = props;
  const hasViewReportButton = isJoined && hasReport;

  return hasViewReportButton ? (
    <ButtonWrapper>
      <PalleteButton
        text="View report"
        palleteColor={mainWhite}
        height="32px"
        width="100%"
        href={`/job/marketplace/${campaignId}/report`}
      />
    </ButtonWrapper>
  ) : null;
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  padding: 0 16px 8px;
`;

export default MarketplacePostButtons;
