import React from 'react';
import styled from '@emotion/styled';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { SocialButton } from '@src/components/atoms/Button';
import * as Styled from '@src/components/atoms/SignInSignUpStyled';

export interface UuumSignUpFormFormProps {
  onClick: (socialMedia: SocialAccountType) => () => void;
  loading: boolean;
}

const UuumSignUpForm = (props: UuumSignUpFormFormProps) => {
  const { onClick, loading } = props;

  return (
    <>
      {/* no need translation here */}
      <Styled.Title>{'SNS連携'}</Styled.Title>
      <Styled.Annotation>
        {
          '自分のチャンネルや競合チャンネルを分析し効果的なチャンネル運用を行えます。(フォロワー推移や過去の投稿を一覧で見られるインサイト分析ツール、さらに競合分析も利用できます)'
        }
      </Styled.Annotation>

      <StyledSocialButton
        onClick={onClick(SocialAccountType.YOUTUBE)}
        socialMedia={SocialAccountType.YOUTUBE}
        title="Sign up with YouTube"
        disabled={loading}
      />
    </>
  );
};

const StyledSocialButton = styled(SocialButton)`
  margin-top: 40px;

  span {
    text-transform: initial;
  }
`;

export default UuumSignUpForm;
