import { LIMIT } from '@src/libs/constant';
import { getOffset } from '@src/libs/paging';

export const storyPostVariables = (props: any) => {
  const {
    currentPage,
    filter: { keyword, period },
    accountId,
  } = props;

  return {
    socialAccountId: accountId,
    offset: getOffset(currentPage),
    limit: LIMIT,
    keyword,
    orderBy: { field: null, order: null },
    startDate: period.startDate,
    endDate: period.endDate,
  };
};
