import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useQueryHelper, useRedirectWithinIframe } from '@src/libs/hooks';
import { useAnalyticsReconnectRedirectUrl } from '@src/libs/hooks/useAnalyticsReconnectRedirectUrl';
import { AuthSocialAccountType } from '@src/__generated__/globalTypes';
import { useState } from 'react';
import { ReconnectStatus } from '../types';

interface StartAnalyticsReconnectionProps {
  reconnectStatus?: ReconnectStatus;
}
interface ReconnectInstagramBags {
  startAnalyticsReconnectInstagram: (props: StartAnalyticsReconnectionProps) => void;
  isLoading: boolean;
}

export const useAnalyticsReconnectInstagram = ({ accountId }: { accountId: number }): ReconnectInstagramBags => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const { getRedirectUrl } = useAnalyticsReconnectRedirectUrl();
  const { enqueueSnackbar, t } = useQueryHelper();
  const { startRedirectProcess } = useRedirectWithinIframe();

  const startAnalyticsReconnectInstagram = async ({ reconnectStatus }: StartAnalyticsReconnectionProps) => {
    if (!reconnectStatus) {
      return;
    }
    setLoading(true);
    try {
      const redirectUrl = await getRedirectUrl({
        socialMedia: AuthSocialAccountType.FACEBOOK,
      });

      if (redirectUrl) {
        localStorage.setItem('reconnectStatus', JSON.stringify(reconnectStatus));
        localStorage.setItem('redirectBackRoute', `analytics?sm=INSTAGRAM&id=${accountId}`);
        // Redirect to SocialMedia
        startRedirectProcess({ redirectUrl, redirectType: FE_REDIRECT_MAPPING.ANALYTICS_IG_RECONNECT });
      }
    } catch (error) {
      console.error('error:', error);
      enqueueSnackbar(t('UnexpectedError'), { variant: 'error' });
      setLoading(false);
    }
  };

  return {
    startAnalyticsReconnectInstagram,
    isLoading,
  };
};
