import React from 'react';
import { getPageInfo, getOffset } from '@src/libs/paging';
import { SortActionType } from '@src/libs/filter';
import { InstagramInteractionUserSortField } from '@src/__generated__/globalTypes';
import { useUpdateDocumentTitle, useQueryHelper, useAuthData } from '@src/libs/hooks';
import { Redirect } from 'react-router-dom';
import { usePaging } from '@src/libs/paging';
import { useFilter } from '@src/components/organisms/AnalyticsShared/BasePostListFilter/useFilter';
import { getThirtyDaysAgo, getToday } from '@src/libs/date';
import { useSort } from '@src/libs/filter';
import { getUserAnalysisSortFiledType } from '@src/components/organisms/AnalyticsInstagram/Interaction/Posts/helpers';
import { useQuery } from '@apollo/client';
import { LIMIT } from '@src/libs/constant';
import { dynamicTitleState, useSetRecoilState } from '@src/recoilAtoms';
import { BasePostFilterType } from '@src/components/organisms/AnalyticsShared/BasePostListFilter/types';
import UserAnalysisList from './UserAnalysisList';
import {
  InfluencerAllInstagramInteractionUsers,
  InfluencerAllInstagramInteractionUsersVariables,
} from './__generated__/InfluencerAllInstagramInteractionUsers';
import * as ALL_IG_INTERACTION_USERS from './InfluencerAllInstagramInteractionUsers.graphql';

export interface UserAnalysisListProps extends SortActionType<InstagramInteractionUserSortField> {
  currentPage: number;
  filter: BasePostFilterType;
  id: number;
}

const UserAnalysisListComponent = () => {
  const { search, history, enqueueSnackbar, t } = useQueryHelper();
  useUpdateDocumentTitle({
    title: 'User Analysis',
    pageTitle: 'User Analysis',
    href: `/analytics:ig_interaction${search}`,
  });
  const searchParams = new URLSearchParams(search);
  const socialMediaAccountId = searchParams.get('socialMediaAccountId');
  const { sort, setSort } = useSort<InstagramInteractionUserSortField>(getUserAnalysisSortFiledType);
  const { filter, setFilter } = useFilter({ startDate: getThirtyDaysAgo(), endDate: getToday() });
  const currentPage = usePaging();
  const { userId } = useAuthData();
  const setDynamicTitleState = useSetRecoilState(dynamicTitleState);

  if (!socialMediaAccountId) {
    return <Redirect to={`/analytics:ig_interaction${search}`} />;
  }

  const { data, loading, error } = useQuery<
    InfluencerAllInstagramInteractionUsers,
    InfluencerAllInstagramInteractionUsersVariables
  >(ALL_IG_INTERACTION_USERS, {
    variables: {
      pk: Number(userId),
      socialAccountId: Number(socialMediaAccountId),
      offset: getOffset(currentPage),
      limit: LIMIT,
      keyword: filter.keyword,
      orderBy: sort,
      ...filter.period,
    },
    skip: !socialMediaAccountId,
    onCompleted: ({ influencerProfileV2 }) => {
      setDynamicTitleState({
        title: 'pageTitle.UserAnalysisListWithAccountName',
        interpolation: influencerProfileV2?.name ? { title: influencerProfileV2.name } : undefined,
      });
    },
  });

  if (error) {
    history.push(`/analytics:ig_interaction${search}`);
    enqueueSnackbar(t(error.message), { variant: 'error' });

    return null;
  }

  const pageInfo = getPageInfo(currentPage, data?.influencerAllInstagramInteractionUsers.totalNumber);

  return (
    <UserAnalysisList
      sort={sort}
      setSort={setSort}
      filter={filter}
      setFilter={setFilter}
      pageInfo={pageInfo}
      userData={data?.influencerAllInstagramInteractionUsers.usernames}
      loading={loading}
      accountName={data?.influencerProfileV2?.name}
    />
  );
};

export default UserAnalysisListComponent;
