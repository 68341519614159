import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import trash from '@src/assets/icon/trashIcon.svg';
import { Button } from '@src/components/atoms/Button';
import { Icon } from '@src/components/atoms/Icon';
import * as List from '@src/components/atoms/List';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { SliderComponents, StyledComponents } from '@src/components/molecules/SliderTable';
import StatusIndicator from '@src/components/organisms/Forms/StatusIndicator';
import { LIMIT } from '@src/libs/constant';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { getOffset, usePaging } from '@src/libs/paging';
import { ViewportType } from '@src/libs/theme';
import { FormListSortableField, Order } from '@src/__generated__/globalTypes';
import EmptyList from './EmptyList';
import REMOVE_FORMS from './mutations/RemoveForms.graphql';
import { RemoveForms, RemoveFormsVariables } from './mutations/__generated__/RemoveForms';
import GET_FORMS_LIST from './queries/GetFormsList.graphql';
import { GetFormsList, GetFormsListVariables } from './queries/__generated__/GetFormsList';
import { FilterItems } from './useFilter';

interface FormListProps {
  filter: FilterItems;
}

const FormList = ({ filter }: FormListProps) => {
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { enqueueSnackbar, t } = useQueryHelper();
  const currentPage = usePaging();
  const { isMobileView } = usePageLayout();

  const { data, loading } = useQuery<GetFormsList, GetFormsListVariables>(GET_FORMS_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      keyword: filter.keyword,
      limit: LIMIT,
      offset: getOffset(currentPage),
      orderBy: { field: FormListSortableField.UPDATED, order: Order.DESC },
      ...(filter.status && { status: filter.status }),
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [removeForms] = useMutation<RemoveForms, RemoveFormsVariables>(REMOVE_FORMS, {
    refetchQueries: ['GetFormsList'],
    onCompleted: () => {
      setCheckedIds([]);
      setDialogOpen(false);
      enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  useEffect(() => {
    if (isMobileView) {
      setCheckedIds([]);
      setDialogOpen(false);
    }
  }, [isMobileView]);

  function onClickAllChecked(status: boolean) {
    const items = [...checkedIds];
    formsList.forEach(form => {
      const index = items.findIndex(checkedId => checkedId === form.id);
      if (status && index >= 0) {
        items.splice(index, 1);
      } else if (!status && index < 0) {
        items.push(form.id);
      }
    });
    setCheckedIds(items);
  }

  function onClickCheck(id: number) {
    const items = [...checkedIds];
    const index = items.findIndex(checkedId => checkedId === id);
    if (index >= 0) {
      items.splice(index, 1);
    } else {
      items.push(id);
    }
    setCheckedIds(items);
  }

  function onDelete() {
    removeForms({
      variables: {
        input: {
          formIds: checkedIds,
        },
      },
    });
  }

  const checkedIdsLength = checkedIds.length;
  const formsList = data?.getFormsList?.forms || [];
  const isEmptyForms = !formsList.length;
  const pageFirstIndex = LIMIT * (currentPage - 1) + 1;
  const pageLastIndex = pageFirstIndex + formsList.length - 1;
  const totalCount = data?.getFormsCount?.total || 0;
  const totalPages = totalCount < LIMIT ? 1 : Math.ceil(totalCount / LIMIT);

  const getCheckedItemStatus = () => {
    let isAllCheckedStatus = checkedIds.length > 0;
    let isItemSelectedStatus = false;

    formsList.every(email => {
      if (!checkedIds.includes(email.id)) {
        isAllCheckedStatus = false;
      } else {
        isItemSelectedStatus = true;
      }

      if (!isAllCheckedStatus && isItemSelectedStatus) {
        return false;
      }

      return true;
    });

    return {
      isAllChecked: isAllCheckedStatus,
      isItemSelected: isItemSelectedStatus,
    };
  };
  const { isAllChecked, isItemSelected } = getCheckedItemStatus();

  if (loading) {
    return <ListIndicator />;
  }

  return (
    <>
      <Popup contentStyle={styles.dialog} open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <>
          <div css={styles.dialogCloseBtn}>
            <Icon color="#c5d0da" icon="close" onClick={() => setDialogOpen(false)} />
          </div>

          <div css={styles.dialogContent}>
            <div>{t('Are you sure you want to permanently delete this?')}</div>
            <div>{t('Annotation.You might lost response data forever. Before continuing, export your responses')}</div>
            <div>{t('Annotation.Do you want to delete this question and lost responses')}</div>
          </div>

          <div css={styles.dialogAction}>
            <DialogButton
              bgcolor="#fff"
              color="#6e7c89"
              hoverbgcolor="#fff"
              title="Cancel"
              onClick={() => setDialogOpen(false)}
            />
            <DialogButton bgcolor="#3892e5" color="#fff" hoverbgcolor="#3892e5" title="Delete" onClick={onDelete} />
          </div>
        </>
      </Popup>
      {checkedIdsLength > 0 && (
        <div css={styles.deleteActionContainer}>
          <div>{`${checkedIdsLength} ${t('Selected')}`}</div>
          <div>
            <div onClick={() => setDialogOpen(true)}>
              <img alt="delete" height="16" src={trash} width="16" />
            </div>
          </div>
        </div>
      )}
      <SliderComponents.Wrapper css={styles.container}>
        <SliderComponents.Container>
          <SliderComponents.SliderSection>
            <SliderComponents.SliderTable>
              <thead css={styles.tableHeader}>
                <SliderComponents.HeaderRow>
                  {!isMobileView && (
                    <List.HeaderCheckboxColumn
                      checked={isAllChecked || isItemSelected}
                      css={{ padding: '0 0 0 16px' }}
                      indeterminate={!isAllChecked && isItemSelected}
                      handleClick={e => onClickAllChecked((e.target as HTMLInputElement).checked)}
                    />
                  )}
                  <List.HeaderColumn title="Name" css={{ width: isMobileView ? '80%' : '50%', paddingLeft: '8px' }} />
                  <List.HeaderColumn title="Status" />
                  {!isMobileView && (
                    <>
                      <List.HeaderColumn title="Number of Submissions" />
                      <List.HeaderColumn title="Last Update" />
                    </>
                  )}
                </SliderComponents.HeaderRow>
              </thead>
              {!isEmptyForms && (
                <tbody>
                  {formsList.map(form => {
                    const { id, lastUpdated, status, submissionCount, title } = form;
                    const isChecked = checkedIds.includes(id);

                    return (
                      <StyledComponents.StyledRowNew css={isChecked ? styles.activeTableRow : {}} key={id}>
                        {!isMobileView && (
                          <List.HeaderCheckboxColumn
                            checked={isChecked}
                            css={styles.columnCheckbox}
                            handleClick={() => onClickCheck(id)}
                          />
                        )}
                        <List.TextLinkColumn css={styles.linkColumn} data={title} href={`/forms/${id}/entry`} />
                        <List.TextColumn data={<StatusIndicator status={status} />} />
                        {!isMobileView && (
                          <>
                            <List.TextColumn data={`${submissionCount.toString()} ${t('Submissions')}`} />
                            <List.TextColumn data={format(new Date(lastUpdated + 'Z'), 'dd/MM/yyyy hh:mm')} />
                          </>
                        )}
                      </StyledComponents.StyledRowNew>
                    );
                  })}
                </tbody>
              )}
            </SliderComponents.SliderTable>
          </SliderComponents.SliderSection>
        </SliderComponents.Container>
      </SliderComponents.Wrapper>
      {isEmptyForms && <EmptyList />}
      <SliderComponents.Pager
        currentPage={currentPage}
        totalPages={totalPages}
        totalCount={totalCount}
        first={pageFirstIndex}
        last={pageLastIndex}
      />
    </>
  );
};

const DialogButton = styled(Button)<{ isPrimary?: boolean }>`
  border: 1px solid ${({ isPrimary }) => (isPrimary ? '#3892e5' : '#dee5ec')};
  border-radius: 5px;
  height: 32px;
  padding: 0 16px;
  width: fit-content;

  @media (max-width: ${ViewportType.MEDIUM}px) {
    border-radius: 3px;
    height: 40px;
    width: fill-available;
  }
`;

const styles = {
  activeTableRow: css`
    background-color: #fffde7;

    & > td {
      background-color: #fffde7;
    }
  `,
  columnCheckbox: css`
    border-top: 1px solid #efefef;
    padding: 0 0 0 16px;
  `,
  container: css`
    border: none;
    margin: 0;
    padding: 0;
  `,
  deleteActionContainer: css`
    align-items: center;
    background-color: #27313b;
    display: flex;
    flex-wrap: wrap;
    height: 40px;
    padding: 8px 16px;

    & > div:nth-of-type(1) {
      display: flex;
      color: #fff;
      flex-basis: 50%;
      font-size: 14px;
    }

    & > div:nth-of-type(2) {
      display: flex;
      flex-basis: 50%;
      justify-content: flex-end;

      & > div {
        align-items: center;
        background-color: #fff;
        border-radius: 2px;
        cursor: pointer;
        display: flex;
        height: 32px;
        justify-content: center;
        width: 32px;
      }
    }
  `,
  dialog: {
    borderRadius: '5px',
    boxShadow: '0 0 12px rgba(0, 0, 0, 0.12), 0 12px 12px rgba(0, 0, 0, 0.24)',
    maxWidth: 648,
    padding: 0,
    width: '90%',
  },
  dialogAction: css`
    border-top: 1px solid #dee5ec;
    display: flex;
    justify-content: flex-end;
    padding: 16px;

    & > button:nth-of-type(2) {
      margin-left: 16px;
    }
  `,
  dialogCloseBtn: css`
    align-items: center;
    background-color: #000;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: -10px;
    top: -15px;
    width: 40px;

    & > i {
      margin: 0;
    }
  `,
  dialogContent: css`
    padding: 24px;

    /* stylelint-disable no-descending-specificity */
    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 24px;
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: 14px;
      margin-bottom: 16px;
    }

    & > div:nth-of-type(3) {
      color: #ff5f5f;
      font-size: 14px;
    }
  `,
  linkColumn: css`
    & a {
      color: #3892e5;
      font-size: 14px;
      font-weight: 600;
    }
  `,
  tableHeader: css`
    background-color: #f6f8fa;
    border-bottom: 1px solid #dfe7ec;
    border-top: 1px solid #dfe7ec;
  `,
};

export default FormList;
