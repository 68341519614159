import { Formik } from 'formik';
import React from 'react';
import { useMutation } from '@apollo/client';
import { useQueryHelper, usePersonalInformation } from '@src/libs/hooks';
import yup, {
  InfluencerEmailSchema,
  LanguageSchema,
  NameSchema,
  PasswordConfirmSchema,
  PasswordSchema,
} from '@src/libs/validation';
import UPDATE_PERSONAL_INFORMATION from './mutations/UpdatePersonalUserDetail.graphql';
import {
  updatePersonalUserDetail,
  updatePersonalUserDetailVariables,
} from './mutations/__generated__/updatePersonalUserDetail';
import YourAccount, { LanguageMapping, PersonalInformation } from './YourAccount';

const Index = () => {
  const { enqueueSnackbar, i18n, t } = useQueryHelper();
  const { loading, userPersonalInfo } = usePersonalInformation();

  const validationSchema = yup.object().shape(
    {
      displayLanguage: LanguageSchema,
      email: InfluencerEmailSchema,
      name: NameSchema,
      password: yup.string().when('password', {
        is: password => password && password.length > 0,
        then: PasswordSchema,
      }),
      passwordConfirm: yup.string().when('password', {
        is: password => password && password.length > 0,
        then: PasswordConfirmSchema,
      }),
    },
    [['password', 'password']]
  );

  const [updatePersonalInformation] = useMutation<updatePersonalUserDetail, updatePersonalUserDetailVariables>(
    UPDATE_PERSONAL_INFORMATION,
    {
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  function onSubmit(values: PersonalInformation) {
    updatePersonalInformation({
      variables: {
        input: {
          crmNotificationSetting: values.crmNotificationSetting,
          displayLanguage: values.displayLanguage,
          eggNotificationSetting: values.eggNotificationSetting,
          email: values.email,
          mkpNotificationSetting: values.mkpNotificationSetting,
          name: values.name,
          password: values.password,
        },
      },
    }).then(results => {
      if (results?.data?.updatePersonalUserDetail?.ok) {
        const language = Object.keys(LanguageMapping).find(key => LanguageMapping[key] === values.displayLanguage);

        if (language !== i18n.language) {
          i18n.changeLanguage(language);
        }
        enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
      }
    });
  }

  const personalInformation = {
    crmNotificationSetting: userPersonalInfo?.crmNotificationSetting || false,
    displayLanguage: userPersonalInfo?.displayLanguage || LanguageMapping[i18n.language],
    eggNotificationSetting: userPersonalInfo?.eggNotificationSetting || false,
    email: userPersonalInfo?.email || '',
    mkpNotificationSetting: userPersonalInfo?.mkpNotificationSetting || false,
    name: userPersonalInfo?.name || '',
    password: '',
    passwordConfirm: '',
  };

  return (
    <Formik
      enableReinitialize
      initialValues={personalInformation}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <YourAccount isLoading={loading} />
    </Formik>
  );
};

export default Index;
