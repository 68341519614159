import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { EngagementCampaignPostStatus } from '../../../../__generated__/globalTypes';
import { getEngagementPostStatusMessage } from '../../../../libs/status';

interface StatusProgressType {
  status: EngagementCampaignPostStatus;
}

const reviewingSection = [EngagementCampaignPostStatus.REVIEWING, EngagementCampaignPostStatus.REJECT];
const readyToPostSection = [EngagementCampaignPostStatus.READY_TO_POST];
const effectiveSection = [
  EngagementCampaignPostStatus.EFFECTIVE,
  EngagementCampaignPostStatus.APPROVED,
  EngagementCampaignPostStatus.WARNING,
  EngagementCampaignPostStatus.WARNING_SOLVED,
];

const tillreadyToPostSection = [...readyToPostSection, ...effectiveSection];

const StatusProgress = ({ status }: StatusProgressType) => {
  const { t } = useTranslation();
  const getIsInSection = (sectionStatuses: EngagementCampaignPostStatus[]) => sectionStatuses.includes(status);

  return (
    <ProgressWrapper>
      {/* Reviewing */}
      <Sector>
        <ProgressSectionWrapper>
          <AutoFlexLine />
          <CircleProgress isComplete />
          <ProgressLine isComplete={getIsInSection(tillreadyToPostSection)} />
        </ProgressSectionWrapper>
        <Status isActive={getIsInSection(reviewingSection)}>
          {t(getEngagementPostStatusMessage(EngagementCampaignPostStatus.REVIEWING))}
        </Status>
      </Sector>
      {/* Ready to Post */}
      <Sector>
        <ProgressSectionWrapper>
          <ProgressLine isComplete={getIsInSection(tillreadyToPostSection)} />
          <CircleProgress isComplete={getIsInSection(tillreadyToPostSection)} />
          <ProgressLine isComplete={getIsInSection(effectiveSection)} />
        </ProgressSectionWrapper>
        <Status isActive={getIsInSection(readyToPostSection)}>
          {t(getEngagementPostStatusMessage(EngagementCampaignPostStatus.READY_TO_POST))}
        </Status>
      </Sector>
      {/* Effective */}
      <Sector>
        <ProgressSectionWrapper>
          <ProgressLine isComplete={getIsInSection(effectiveSection)} />
          <CircleProgress isComplete={getIsInSection(effectiveSection)} />
          <AutoFlexLine />
        </ProgressSectionWrapper>
        <Status isActive={getIsInSection(effectiveSection)}>
          {t(getEngagementPostStatusMessage(EngagementCampaignPostStatus.EFFECTIVE))}
        </Status>
      </Sector>
    </ProgressWrapper>
  );
};

const Sector = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const ProgressWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  /* negative margin allow to stretch container more accurate, needs improvement */
  margin: 0 -16px;
`;
const ProgressSectionWrapper = styled.div`
  display: flex;
  margin: 12px 0 7px;
`;
const ProgressLine = styled.hr<{ isComplete: boolean }>`
  background-color: ${({ isComplete }) => (isComplete ? '#ff2b52' : '#c5d0da')};
  height: 2px;
  margin: auto;
  flex: auto;
`;
const AutoFlexLine = styled.hr`
  flex: auto;
`;
const CircleProgress = styled.div<{ isComplete: boolean }>`
  border: 2px solid ${({ isComplete }) => (isComplete ? '#ff2b52' : '#c5d0da')};
  border-radius: 100%;
  position: relative;
  width: 16px;
  height: 16px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${({ isComplete }) => (isComplete ? '#ff2b52' : '#c5d0da')};
    border-radius: 100%;
  }
`;
const Status = styled.span<{ isActive: boolean }>`
  font-size: 11px;
  text-align: center;
  color: ${({ isActive }) => (isActive ? '#ff2b52' : '#6e7c89')};
  text-transform: capitalize;
`;

export default StatusProgress;
