import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ViewportType } from '@src/libs/theme';
import { useMediaQuery } from 'react-responsive';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { useQueryHelper } from '@src/libs/hooks';
import BasePostFilter from '@src/components/organisms/AnalyticsShared/BasePostListFilter';
import PageCount from '@src/components/molecules/PageCount';
import Pager from '@src/components/molecules/Pager';
import UserAnalysisTable, {
  Anchor,
} from '@src/components/organisms/AnalyticsInstagram/Interaction/Posts/UserAnalysisTable';
import { SortActionType } from '@src/libs/filter';
import { InstagramInteractionUserSortField } from '@src/__generated__/globalTypes';
import { PageInfo } from '@src/libs/paging';
import { EmptyTable } from '@src/components/molecules/Table';
import { SliderComponents } from '@src/components/molecules/SliderTable';
import { BasePostFilterType } from '@src/components/organisms/AnalyticsShared/BasePostListFilter/types';
import PostFeedFilters from '@src/components/organisms/PostFeedList/PostFeedFilters';
import * as Styled from '@src/components/organisms/AnalyticsYoutube/Dashboard/TagRankingList/StyledComponents';
import { getUserAnalysisColumns } from '@src/components/organisms/AnalyticsInstagram/Interaction/Posts/helpers';
import { bigIntFormatter } from '@src/libs/format';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { InfluencerAllInstagramInteractionUsers } from './__generated__/InfluencerAllInstagramInteractionUsers';

export type UserAnalysisSortAction = SortActionType<InstagramInteractionUserSortField>;
export interface UserAnalysisListProps extends UserAnalysisSortAction {
  pageInfo: PageInfo;
  userData?: InfluencerAllInstagramInteractionUsers['influencerAllInstagramInteractionUsers']['usernames'];
  filter: BasePostFilterType;
  setFilter: (filter: BasePostFilterType) => void;
  loading: boolean;
  accountName?: string;
}

const UserAnalysisList = (props: UserAnalysisListProps) => {
  const {
    sort,
    setSort,
    pageInfo: { firstIndex, lastIndex, totalCount, currentPage, totalPages },
    userData,
    filter,
    setFilter,
    loading,
    accountName,
  } = props;
  const isDesktopView = useMediaQuery({ query: `(min-width:${ViewportType.TABLET}px)` });
  const { search, t } = useQueryHelper();

  return (
    <>
      {isDesktopView ? (
        <Container>
          <div css={{ marginBottom: '24px' }}>
            <BackNavigator
              title="UserAnalysisListWithAccountName"
              interpolation={accountName ? { title: accountName } : undefined}
              to={`/analytics:ig_interaction${search}`}
            />
          </div>
          <BasePostFilter filter={filter} setFilter={setFilter} />
          {userData?.length !== 0 && !loading ? (
            <ButtonWrapper css={styles.borderTop}>
              <PageCount total={totalCount} countLabel="Users" />
            </ButtonWrapper>
          ) : null}
          {loading ? (
            <ListIndicator css={{ padding: '100px' }} />
          ) : (
            <SliderComponents.Wrapper css={styles.wrapper}>
              <UserAnalysisTable userAnalytics={userData} sort={sort} setSort={setSort} hasSort />
            </SliderComponents.Wrapper>
          )}

          <SliderComponents.Pager
            currentPage={currentPage}
            totalPages={totalPages}
            totalCount={totalCount}
            first={firstIndex}
            last={lastIndex}
          />
        </Container>
      ) : (
        <Wrapper>
          <Card>
            <PostFeedFilters filter={filter} setFilter={setFilter} />
            {userData?.length === 0 ? <EmptyTable colSpan={14} css={{ border: 'none' }} /> : null}
            {loading ? (
              <ListIndicator />
            ) : (
              userData?.map((user, i) => (
                <Styled.TagBox key={i}>
                  <Styled.TagHeading>
                    <Anchor target="_blank" rel="noreferrer" href={`https://www.instagram.com/${user.username}`}>
                      {user.username}
                    </Anchor>
                  </Styled.TagHeading>
                  <Styled.EngagmentsGrid>
                    {getUserAnalysisColumns().map(el => (
                      <div key={el.title}>
                        <Styled.Label text={t(`HeaderColumn.${el.title}`)} lines={1} />
                        <Styled.Value>{bigIntFormatter(user[el.key] as number)}</Styled.Value>
                      </div>
                    ))}
                  </Styled.EngagmentsGrid>
                </Styled.TagBox>
              ))
            )}
          </Card>
          <Pager
            currentPage={currentPage}
            totalPages={totalPages}
            totalCount={totalCount}
            first={firstIndex}
            last={lastIndex}
          />
        </Wrapper>
      )}
    </>
  );
};

const styles = {
  borderTop: css`
    border-top: 1px solid #dee5ec;
  `,
  wrapper: css`
    padding: 0;
    margin: 0;
  `,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
`;

const Card = styled.div`
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5);
  background-color: #fff;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px 24px 0;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 35px;
  padding: 8px 15px;
  background-color: #fff;
  border: 1px solid #dee5ec;
  border-top: none;
  border-bottom: none;
`;

export default UserAnalysisList;
