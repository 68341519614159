import { useField, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Button } from '@src/components/atoms/Button';
import Grid from '@src/components/atoms/Grid';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import SnsBudgetInput from '@src/components/organisms/Settings/SnsBudget/SnsBudgetInput';
import { ViewportType } from '@src/libs/theme';
import { SocialMediaTypeForRateCard } from '@src/__generated__/globalTypes';
import { usePageLayout } from '@src/libs/hooks';

export interface Information {
  facebookPrice: string;
  instagramPrice: string;
  instagramStoryPrice: string;
  tiktokPrice: string;
  twitterPrice: string;
  youtubePrice: string;
}

interface SnsBudgetProps {
  currency: string;
  hasFBAccount: boolean;
  hasIGAccount: boolean;
  hasTTAccount: boolean;
  hasTWAccount: boolean;
  hasYTAccount: boolean;
}

const SnsBudget = ({
  currency,
  hasFBAccount,
  hasIGAccount,
  hasTTAccount,
  hasTWAccount,
  hasYTAccount,
}: SnsBudgetProps) => {
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();
  const { isSubmitting, handleSubmit, setFieldValue } = useFormikContext<Information>();

  const [facebookPriceField, facebookPriceMeta] = useField('facebookPrice');
  const [instagramPriceField, instagramPriceMeta] = useField('instagramPrice');
  const [instagramStoryPriceField, instagramStoryPriceMeta] = useField('instagramStoryPrice');
  const [tiktokPriceField, tiktokPriceMeta] = useField('tiktokPrice');
  const [twitterPriceField, twitterPriceMeta] = useField('twitterPrice');
  const [youtubePriceField, youtubePriceMeta] = useField('youtubePrice');

  return (
    <div css={styles.container}>
      {!isMobileView && <BackNavigator title="SNS budget" to="/settings" />}

      <div css={styles.description}>
        {t('Annotation.Connect with your social account to use Analytics data and get sponsorship campaign')}
      </div>

      <form onSubmit={handleSubmit}>
        <div css={styles.formContainer}>
          <Grid md={8} sm={10} xs={12}>
            <div css={styles.formInputSection}>
              <SnsBudgetInput
                currency={currency}
                disabled={!hasFBAccount}
                error={facebookPriceMeta.error}
                type={SocialMediaTypeForRateCard.FACEBOOK}
                value={facebookPriceField.value}
                onChange={value => setFieldValue('facebookPrice', value)}
              />
              <SnsBudgetInput
                currency={currency}
                disabled={!hasIGAccount}
                error={instagramPriceMeta.error}
                type={SocialMediaTypeForRateCard.INSTAGRAM}
                value={instagramPriceField.value}
                onChange={value => setFieldValue('instagramPrice', value)}
              />
              <SnsBudgetInput
                currency={currency}
                disabled={!hasIGAccount}
                error={instagramStoryPriceMeta.error}
                type={SocialMediaTypeForRateCard.INSTAGRAM_STORY}
                value={instagramStoryPriceField.value}
                onChange={value => setFieldValue('instagramStoryPrice', value)}
              />
              <SnsBudgetInput
                currency={currency}
                disabled={!hasTWAccount}
                error={twitterPriceMeta.error}
                type={SocialMediaTypeForRateCard.TWITTER}
                value={twitterPriceField.value}
                onChange={value => setFieldValue('twitterPrice', value)}
              />
              <SnsBudgetInput
                currency={currency}
                disabled={!hasYTAccount}
                error={youtubePriceMeta.error}
                type={SocialMediaTypeForRateCard.YOUTUBE}
                value={youtubePriceField.value}
                onChange={value => setFieldValue('youtubePrice', value)}
              />
              <SnsBudgetInput
                currency={currency}
                disabled={!hasTTAccount}
                error={tiktokPriceMeta.error}
                type={SocialMediaTypeForRateCard.TIKTOK}
                value={tiktokPriceField.value}
                onChange={value => setFieldValue('tiktokPrice', value)}
              />
              <div css={styles.actionContainer}>
                <Button
                  bgcolor="#3892e5"
                  color="#fff"
                  css={styles.saveBtn}
                  disabled={isSubmitting}
                  hoverbgcolor="#3892e5"
                  title="Save"
                  type="submit"
                />
              </div>
            </div>
          </Grid>
        </div>
      </form>
    </div>
  );
};

const styles = {
  actionContainer: css`
    display: flex;
    justify-content: flex-end;
    padding: 16px;
  `,
  container: css`
    margin: 16px 24px;

    @media (max-width: ${ViewportType.TABLET}px) {
      margin: 16px;
    }
  `,
  description: css`
    color: #27313b;
    font-size: 14px;
    margin-bottom: 24px;
    margin-left: 48px;

    @media (max-width: ${ViewportType.TABLET}px) {
      margin-left: 0;
    }
  `,
  formContainer: css`
    display: flex;
    justify-content: center;
  `,
  formInputSection: css`
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
  `,
  saveBtn: css`
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
    height: 32px;
    width: 77px;

    @media (max-width: ${ViewportType.TABLET}px) {
      border-radius: 5px;
      height: 40px;
      width: 100%;
    }
  `,
};

export default SnsBudget;
