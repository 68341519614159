import React from 'react';
import { Period } from '@src/components/molecules/DayPicker/types';
import { SocialMediaAccountType } from '../types';
import Template from './template';

interface ProfileDetailProps {
  selectedSocialMedia: SocialMediaAccountType;
  selectedPeriod: Period;
  userId: number;
}

const ProfileDetail = (props: ProfileDetailProps) => <Template {...props} />;

export default ProfileDetail;
