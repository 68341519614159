import * as React from 'react';
import { render } from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';
import { config } from './libs/config';

// https://babeljs.io/docs/en/babel-plugin-syntax-dynamic-import
import 'core-js/modules/es6.array.iterator';
import 'core-js/modules/es6.promise';

// eslint-disable-next-line no-underscore-dangle,no-undef
const __DEV__ = process.env.NODE_ENV === 'development';
if (!__DEV__) {
  Sentry.init(config.sentry);
}

const root = document.getElementById('container');

if (__DEV__) {
  // eslint-disable-next-line no-undef
  const RedBox = require('redbox-react').default;
  try {
    render(<App />, root);
  } catch (e) {
    render(<RedBox error={e} />, root);
  }
} else {
  render(<App />, root);
}
