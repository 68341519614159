import React from 'react';
import { useQuery } from '@apollo/client';
import chatSquareIcon from '@src/assets/icon/chatSquare.svg';
import { formatIntNumber, formatPercent } from '@src/libs/format';
import { useQueryHelper } from '@src/libs/hooks';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import * as StyledHeading from '../StyledHeading';
import { SponsoredPosts as SponsoredPostsQuery, SponsoredPostsVariables } from './__generated__/SponsoredPosts';
import FETCH_SPONSORED_POSTS from './SponsoredPosts.graphql';
import AnalyticsSponsoredPostTemplate from './AnalyticsSponsoredPostsTemplate';

interface SponsoredPostsProps {
  socialAccountId: number;
  socialMedia: SocialAccountType;
  influencerId: number;
  className?: string;
}
const SponsoredPosts = ({ socialAccountId, socialMedia, influencerId, className }: SponsoredPostsProps) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  const { data } = useQuery<SponsoredPostsQuery, SponsoredPostsVariables>(FETCH_SPONSORED_POSTS, {
    variables: {
      influencerId,
      socialMedia,
      socialAccountId,
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const averageEngagement = data?.sponsoredPosts?.averageEngagement || 0;
  const averageEngagementRate = data?.sponsoredPosts?.averageEngagementRate || 0;
  const posts = data?.sponsoredPosts?.posts || [];

  const headerNode = (
    <StyledHeading.Header>
      <StyledHeading.TitleWrapper>
        <StyledHeading.Img src={chatSquareIcon} height={18} />
        <StyledHeading.HeadingText>{t('Sponsored Posts')}</StyledHeading.HeadingText>
      </StyledHeading.TitleWrapper>
      <StyledHeading.EngagementRateWrapper>
        <StyledHeading.CountWrapper css={{ marginRight: '16px' }}>
          <StyledHeading.Label>{t('HeaderColumn.Avg Engagement Rate')}</StyledHeading.Label>
          <StyledHeading.FollowersCount>{formatPercent(averageEngagementRate)}</StyledHeading.FollowersCount>
        </StyledHeading.CountWrapper>
        <StyledHeading.CountWrapper>
          <StyledHeading.Label>{t('HeaderColumn.Avg Engagement')}</StyledHeading.Label>
          <StyledHeading.FollowersCount>{formatIntNumber(averageEngagement)}</StyledHeading.FollowersCount>
        </StyledHeading.CountWrapper>
      </StyledHeading.EngagementRateWrapper>
    </StyledHeading.Header>
  );

  return (
    <AnalyticsSponsoredPostTemplate
      header={headerNode}
      sponsoredPosts={posts}
      influencerId={influencerId}
      className={className}
    />
  );
};

export default SponsoredPosts;
