import styled from '@emotion/styled';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import SearchForm from '@src/components/molecules/SearchForm';
import { FormValues } from './types';

const PostFeedFilters = () => {
  const { t } = useTranslation();
  const { handleSubmit, values, handleBlur, handleChange } = useFormikContext<FormValues>();

  const handleBlurAndSubmit = (e: any) => {
    handleBlur(e);
    handleSubmit(e);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Wrapper>
        <Keyword
          title="Keyword"
          placeholder={t('SearchForm.Search by Keywords')}
          value={values.keyword}
          name="keyword"
          onBlur={handleBlurAndSubmit}
          onChange={handleChange}
          onEnterKeyPress={handleSubmit}
        />
      </Wrapper>
    </form>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 16px;
`;

const Keyword = styled(SearchForm)`
  flex: 1;
`;

export default PostFeedFilters;
