import React from 'react';
import { Period } from '@src/components/molecules/DayPicker/types';
import AnalyticsContentWrapper, {
  ContentType,
  sectionStyles,
} from '@src/components/organisms/AnalyticsShared/AnalyticsContentWrapper';
import { SocialMediaAccountType } from '@src/components/organisms/ProfileDetail/types';
import PostingHabits from '@src/components/organisms/ProfileWidgets/PostingHabits';
import AccountsInterest from '@src/components/organisms/ProfileWidgets/AccountsInterest';
import SponsoredPosts from '@src/components/organisms/ProfileWidgets/SponsoredPosts';
import { MentionedAccounts } from '@src/components/organisms/ProfileWidgets/MentionedAccounts';
import TagCloudPost from '@src/components/organisms/ProfileWidgets/TagCloudPost';
import FeedPost from './FeedPost';
import PostBreakdown from './PostBreakDown/PostBreakdown';
import StoryPost from './StoryPost';
import { PostAnalystics } from './types';

interface AnalyticsPostsProps extends PostAnalystics {
  selectedSocialMedia: SocialMediaAccountType;
  selectedPeriod: Period;
  userId: number;
  className?: string;
}

const PostCard = (props: AnalyticsPostsProps) => {
  const { averageEngagement, postsHabit, feedPosts, storyPosts, className, selectedSocialMedia, userId } = props;

  return (
    <>
      <AnalyticsContentWrapper className={className} contentType={ContentType.Posts}>
        <div css={sectionStyles.responsiveWrapper}>
          {averageEngagement && <PostBreakdown {...averageEngagement} />}
          <AccountsInterest
            socialAccountId={selectedSocialMedia.id}
            socialMedia={selectedSocialMedia.socialAccountType}
            css={[sectionStyles.middleSection, sectionStyles.verticalContaner]}
          />
          {!!postsHabit && <PostingHabits postsHabit={postsHabit} css={sectionStyles.verticalContaner} />}
        </div>
        <FeedPost posts={feedPosts || []} selectedSocialMedia={selectedSocialMedia} />
        <StoryPost posts={storyPosts || []} selectedSocialMedia={selectedSocialMedia} css={sectionStyles.storyPost} />
      </AnalyticsContentWrapper>
      <SponsoredPosts
        socialAccountId={selectedSocialMedia.id}
        socialMedia={selectedSocialMedia.socialAccountType}
        css={sectionStyles.sectionContainer}
        influencerId={userId}
      />
      <MentionedAccounts
        socialAccountId={selectedSocialMedia.id}
        css={sectionStyles.sectionContainer}
        influencerId={userId}
      />
      <TagCloudPost
        socialAccountId={selectedSocialMedia.id}
        socialMedia={selectedSocialMedia.socialAccountType}
        influencerId={userId}
        css={sectionStyles.sectionContainer}
      />
    </>
  );
};

export default PostCard;
