import styled from '@emotion/styled';
import React from 'react';
import { CheckBox } from '../CheckBox';

interface CheckboxColumnProps {
  className?: string;
  value: boolean;
  label: string;
  onChange?: () => void;
  disabled?: boolean;
  name: string;
}

const CheckboxColumn = (props: CheckboxColumnProps) => {
  const { className, name, value, onChange, disabled = false, label } = props;

  return (
    <Wrapper className={className}>
      <CheckBox name={name} onChange={onChange} label={label} value={value} disabled={disabled} />
    </Wrapper>
  );
};

const Wrapper = styled.td`
  width: 22px;
`;

export default CheckboxColumn;
