import shownedEye from '@src/assets/icon/eye.svg';
import hiddenEye from '@src/assets/icon/hiddenEye.svg';

export const getCardIcon = (icon: 'shown' | 'hidden', isHovered: boolean) => {
  if (icon === 'shown') {
    return isHovered ? hiddenEye : shownedEye;
  }

  return isHovered ? shownedEye : hiddenEye;
};
