import { useState } from 'react';
import { AuthSocialAccountType, SocialAccountType } from '@src/__generated__/globalTypes';
import { getRedirectPath } from '../auth';
import { UNEXPECTED_ERROR } from '../error';
import { FE_REDIRECT_MAPPING } from '../constant';
import client from '../../apollo';
import useQueryHelper from './useQueryHelper';
import {
  SocialAuthRedirectUrlForInfluencer,
  SocialAuthRedirectUrlForInfluencerVariables,
} from './__generated__/SocialAuthRedirectUrlForInfluencer';
import * as SOCIAL_AUTH_REDIRECT_URL_FOR_INFLUENCER from './SocialAuthRedirectUrlForInfluencer.graphql';

interface AnalyticsAuthRedirectProps {
  socialMedia: AuthSocialAccountType;
}
export const useAnalyticsReconnectRedirectUrl = () => {
  const { enqueueSnackbar, t } = useQueryHelper();
  const [isLoading, setLoading] = useState<boolean>(false);

  const getRedirectUrl = async ({ socialMedia }: AnalyticsAuthRedirectProps): Promise<string | null> => {
    const redirectSocialMedia =
      socialMedia === AuthSocialAccountType.FACEBOOK ? SocialAccountType.INSTAGRAM : socialMedia;
    const input = {
      provider: socialMedia,
      // BECAUSE only HERE the callback of instagram is instagream and not facebook (while in the rest of the app,
      // the redirection for instagram is considered facebook)
      callbackUrl: `${location.origin}/${getRedirectPath(
        redirectSocialMedia,
        FE_REDIRECT_MAPPING.ANALYTICS_IG_RECONNECT
      )}`,
    };

    const { data, errors } = await client.query<
      SocialAuthRedirectUrlForInfluencer,
      SocialAuthRedirectUrlForInfluencerVariables
    >({
      query: SOCIAL_AUTH_REDIRECT_URL_FOR_INFLUENCER,
      variables: { input },
    });

    if (errors) {
      errors.forEach(err => {
        console.error(err);
        enqueueSnackbar(t(err.message), { variant: 'error' });
      });

      return null;
    }
    if (!data || !data.socialAuthRedirectUrlForInfluencer) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });

      return null;
    }

    return data.socialAuthRedirectUrlForInfluencer.redirectUri;
  };

  return {
    getRedirectUrl,
    loading: isLoading,
    setLoading,
  };
};
