interface CorporateSiteLanguages {
  [lang: string]: string;
}

export const getUrlOfHowToConnectIG = (lang: string) => {
  const languages: CorporateSiteLanguages = {
    // just now only en|ja articles
    ja: 'ja',
    // th: 'th',
    // id: 'id',
    // vi: 'vi',
    // tw: 'zh',
  };

  return `https://intercom.help/anycreator-pte-ltd/${
    languages[lang] || 'en'
  }/articles/5465653-how-to-register-instagram`;
};
