import { Formik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { UpdateUserDetailInput } from '@src/__generated__/globalTypes';
import UPDATE_USER_DETAIL from './mutations/UpdateUserDetail.graphql';
import { UpdateUserDetail, UpdateUserDetailVariables } from './mutations/__generated__/UpdateUserDetail';
import UserDetail from './UserDetail';
import GET_USER_DETAIL from './queries/GetUserDetail.graphql';
import { GetUserDetail, GetUserDetailVariables } from './queries/__generated__/GetUserDetail';

const Index = () => {
  const initialUserDetail = {
    userId: 0,
    name: '',
    email: '',
    crmNotificationSetting: false,
    mkpNotificationSetting: false,
    eggNotificationSetting: false,
  } as UpdateUserDetailInput;
  const [userDetail, setUserDetail] = useState<UpdateUserDetailInput>(initialUserDetail);
  const { enqueueSnackbar, params, t } = useQueryHelper();
  const { userId } = params;

  const validationSchema = yup.object().shape({
    name: yup.string().required('requiredNameMessage'),
    email: yup.string().email('invalidEmailMessage').required('requiredEmailMessage'),
  });

  const [updateUserDetail] = useMutation<UpdateUserDetail, UpdateUserDetailVariables>(UPDATE_USER_DETAIL, {
    onCompleted: () => {
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  useQuery<GetUserDetail, GetUserDetailVariables>(GET_USER_DETAIL, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        userId: Number(userId),
      },
    },
    onCompleted: ({ getUserDetail }) => {
      if (getUserDetail) {
        const { crmNotificationSetting, eggNotificationSetting, email, mkpNotificationSetting, name } = getUserDetail;
        setUserDetail({
          crmNotificationSetting,
          eggNotificationSetting,
          email: email || '',
          mkpNotificationSetting,
          name,
          userId: Number(userId),
        });
      }
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  function onSubmit(values: UpdateUserDetailInput) {
    updateUserDetail({
      variables: {
        input: values,
      },
    });
  }

  return (
    <Formik
      enableReinitialize
      initialValues={userDetail}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <UserDetail />
    </Formik>
  );
};

export default Index;
