import styled from '@emotion/styled';
import { useField, useFormikContext } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import YearMonthDayPicker from '@src/components/molecules/YearMonthDayPicker';
import { GENDERS } from '@src/libs/constant';
import { useTranslateOptions } from '@src/libs/hooks';
import { SubmitButton } from '@src/components/atoms/Button';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import Label from '@src/components/atoms/Label';
import MultiSelectField from '@src/components/molecules/MultiSelectField';
import SentenceForm from '@src/components/molecules/SentenceForm';
import SingleSelectField from '@src/components/molecules/SingleSelectField';
import TextForm from '@src/components/molecules/TextForm';
import RegionSelector from '@src/components/organisms/RegionSelector';
import SwitchToggle from '@src/components/molecules/Switch';
import { ViewportBreakpoint } from '@src/libs/theme';
import { FormValues, Option } from './types';

export interface ProfileFormProps {
  className?: string;
  allCountries: Option[];
  allCategories: Option[];
}

const ProfileForm = (props: ProfileFormProps) => {
  const { t } = useTranslation();
  const { className, allCategories, allCountries } = props;
  const formikProps = useFormikContext<FormValues>();
  const { handleSubmit, setFieldValue, isSubmitting } = formikProps;
  // Translate Options
  const translatedCategories = useTranslateOptions(allCategories);
  const translatedGenders = useTranslateOptions(GENDERS);

  // Fields TODO: refactor
  const [nameField, nameMeta] = useField('name');
  const [categoriesField, categoriesMeta] = useField<FormValues['categories']>('categories');
  const [genderField, genderMeta] = useField('gender');
  const [countryField, countryMeta] = useField('country');
  const [regionField, regionMeta] = useField('regionId');
  const [emailField, emailMeta] = useField('email');
  const [phoneNumberField, phoneNumberMeta] = useField('phoneNumber');
  const [birthdayField, birthdayMeta] = useField('birthday');
  const [introduceField, introduceMeta] = useField('introduce');
  const [notificationSettingField] = useField('notificationSetting');

  const handleChangeBirthday = (value: string) => {
    setFieldValue('birthday', value);
  };

  const handleNotificationChance = () => {
    setFieldValue('notificationSetting', !notificationSettingField.value);
  };

  return (
    <form className={className} onSubmit={handleSubmit}>
      <ResponsiveWrapper>
        <Container>
          <Wrapper>
            <TextForm title="Name" isRequired={true} error={!!nameMeta.error} placeholder="Jane Doe" {...nameField} />
            {nameMeta.error && <ErrorMessage message={t(nameMeta.error)} />}
          </Wrapper>

          <Wrapper>
            <TextForm
              title="Email address"
              placeholder="your@email.com"
              isRequired={true}
              error={!!emailMeta.error}
              {...emailField}
            />
            {emailMeta.error && <ErrorMessage message={t(emailMeta.error)} />}
          </Wrapper>

          <Wrapper>
            <TextForm
              title="Phone Number"
              placeholder="+66020485707"
              {...phoneNumberField}
              error={!!phoneNumberMeta.error}
            />
            {phoneNumberMeta.error && <ErrorMessage message={t(phoneNumberMeta.error)} />}
          </Wrapper>

          <Wrapper>
            <MultiSelectField
              name="categories"
              value={categoriesField.value}
              title="Categories"
              options={translatedCategories}
              isRequired={true}
              error={!!categoriesMeta.error}
              help={t('Selector.Please select at least two')}
              setFieldValue={setFieldValue}
              hasSelectAll={false}
            />
            {!!categoriesMeta.error && <ErrorMessage message={t(categoriesMeta.error)} />}
          </Wrapper>

          <Wrapper>
            <Label title={t('Date Of Birth')} />
            <YearMonthDayPicker
              format="yyyy-MM-dd"
              maxDate={new Date()}
              value={birthdayField.value || ''}
              onChange={value => handleChangeBirthday(value as string)}
            />
            {birthdayMeta.error && <ErrorMessage message={t(birthdayMeta.error)} />}
          </Wrapper>

          <Wrapper>
            <SingleSelectField
              name={countryField.name}
              value={countryField.value}
              error={!!countryMeta.error}
              title="Country"
              options={allCountries}
              disabled={true}
              setFieldValue={setFieldValue}
            />
          </Wrapper>

          <Wrapper>
            <RegionSelector
              name={regionField.name}
              value={regionField.value}
              title="Region"
              countryId={countryField.value}
              isRequired={true}
              error={!!regionMeta.error}
              setFieldValue={setFieldValue}
            />
            {regionMeta.error && <ErrorMessage message={t(regionMeta.error)} />}
          </Wrapper>

          <Wrapper>
            <SingleSelectField
              name={genderField.name}
              value={genderField.value}
              error={!!genderMeta.error}
              title="Gender"
              options={translatedGenders}
              disabled={true}
              setFieldValue={setFieldValue}
            />
            {genderMeta.error && <ErrorMessage message={t(genderMeta.error)} />}
          </Wrapper>
          <Wrapper />

          <Wrapper>
            <SentenceForm
              title="Introduce"
              placeholder="Placeholder"
              error={!!introduceMeta.error}
              {...introduceField}
            />
            {introduceMeta.error && <ErrorMessage message={t(introduceMeta.error)} />}
          </Wrapper>

          <ToggleWrapper>
            <CustomLabel
              title={t('Title.Notification Settings')}
              help={t('Notification settings help')}
              iconMargin="10px"
            />
            <SwitchToggle isChecked={!!notificationSettingField.value} handleChange={handleNotificationChance} />
          </ToggleWrapper>

          <SubmitWrapper>
            <SubmitButton value="Update Profile" isSubmitting={isSubmitting} />
          </SubmitWrapper>
        </Container>
      </ResponsiveWrapper>
    </form>
  );
};

const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: #fff;
`;

const Container = styled.section`
  padding: 24px 16px;
  background-color: #fff;
  border-radius: 2px;
  width: 100%;

  @media (min-width: ${ViewportBreakpoint.MEDIUM}px) {
    padding: 24px 64px 32px;
  }

  @media (min-width: ${ViewportBreakpoint.DESKTOP}px) {
    max-width: 640px;
  }
`;
const Wrapper = styled.div`
  flex: 1;
  margin-bottom: 16px;
`;
const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
`;
const SubmitWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #dee5ec;
`;

const CustomLabel = styled(Label)`
  margin-bottom: 16px;
`;

export default ProfileForm;
