import React from 'react';
import { useQueryHelper } from '@src/libs/hooks';
import { Period } from '@src/components/molecules/DayPicker/types';
import { useQuery } from '@apollo/client';
import { ListIndicator } from '@src/components/molecules/Indicator';
import AnalyticsContentWrapper, {
  ContentType,
} from '@src/components/organisms/AnalyticsShared/AnalyticsContentWrapper';
import YtCompareOverView from './YtCompareOverView';
import * as TY_COMPARE_OVERVIEW from './InfluencerYoutubeCompareOverview.graphql';
import {
  InfluencerYoutubeCompareOverview,
  InfluencerYoutubeCompareOverviewVariables,
} from './__generated__/InfluencerYoutubeCompareOverview';
import { AvatarAndNameType } from './types';

interface YtCompareOverViewSectionType {
  accountsAvatarAndName: AvatarAndNameType[];
  visibleAccountsIds: number[];
  isMainAccountVisible: boolean;
  id: number;
  period: Period;
}
const YtCompareOverViewSection = ({
  id,
  period,
  accountsAvatarAndName,
  visibleAccountsIds,
  isMainAccountVisible,
}: YtCompareOverViewSectionType) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data, loading } = useQuery<InfluencerYoutubeCompareOverview, InfluencerYoutubeCompareOverviewVariables>(
    TY_COMPARE_OVERVIEW,
    {
      variables: {
        socialAccountId: id,
        ...period,
      },
      fetchPolicy: 'cache-and-network',
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
    }
  );

  if (loading) {
    return <ListIndicator />;
  }

  const compareOverviewData = data?.influencerYoutubeCompareOverview;

  return (
    <AnalyticsContentWrapper contentType={ContentType.Overview}>
      <YtCompareOverView
        compareOverviewData={compareOverviewData}
        accountsAvatarAndName={accountsAvatarAndName}
        visibleAccountsIds={visibleAccountsIds}
        id={id}
        isMainAccountVisible={isMainAccountVisible}
      />
    </AnalyticsContentWrapper>
  );
};

export default YtCompareOverViewSection;
