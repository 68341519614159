import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Label from '../../atoms/Label';
import Textarea, { TextareaProps } from '../../atoms/Textarea';

interface SentenceFormProps extends TextareaProps {
  className?: string;
  title?: string;
  isRequired?: boolean;
  help?: string;
}

const SentenceForm = (props: SentenceFormProps) => {
  const { t } = useTranslation();
  const { className, title, isRequired, help, placeholder, error, ...field } = props;
  const translatedTitle = title ? t(`Sentence.${title}`) : '';
  const translatedPlaceholder = placeholder ? t(`Sentence.${placeholder}`) : '';

  return (
    <div className={className}>
      {title && <Label title={translatedTitle} isRequired={isRequired} help={help} />}
      <Textarea placeholder={translatedPlaceholder} error={error} {...field} />
    </div>
  );
};

export default SentenceForm;
