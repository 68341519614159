import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { useField, useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  EngagementCampaignPostStatus,
  SocialAccountType,
  CampaignSocialMediaType,
} from '../../../../__generated__/globalTypes';
import { Material } from '../../../../libs/requests';
import { SubmitButton } from '../../../atoms/Button';
import ErrorMessage from '../../../atoms/ErrorMessage';
import Label from '../../../atoms/Label';
import { DayPickerHandInput } from '../../../molecules/DayPicker';
import { PostFileField } from '../../../molecules/FileField';
import HashtagCloud from '../../../molecules/HashtagCloud';
import SentenceForm from '../../../molecules/SentenceForm';
import SingleSelectField from '../../../molecules/SingleSelectField';
import { ViewportBreakpoint } from '../../../../libs/theme';
import { switchImage } from '../../../../libs/SocialMedia';
import { SOCIAL_MEDIAS } from '../../../../libs/constant';
import { isEditablePost } from '../utils';
import * as GET_HASHTAG_CLOUD from './GetHashtagsCloud.graphql';
import { FormValues } from './types';
import {
  getDisabledFields,
  IMAGE_MIME,
  UPLOAD_IMAGE_TYPES,
  UPLOAD_VIDEO_TYPES,
  VIDEO_MIME,
  getDefaultSocialMediaName,
} from './utils';
import { EngagementInfluencerSocialMedia } from './__generated__/EngagementInfluencerSocialMedia';

// Types
export interface PostFormProps {
  postStatus?: EngagementCampaignPostStatus;
  initialMaterials: Material[];
  id: number;
  postId?: number;
  socialAccountsData?: EngagementInfluencerSocialMedia;
}

const PostForm = (props: PostFormProps) => {
  const client = useApolloClient();
  const { t } = useTranslation();
  const { postStatus, socialAccountsData } = props;
  const formikProps = useFormikContext<FormValues>();
  const { handleSubmit, isSubmitting, setFieldValue, values, errors } = formikProps;

  const [hashtagsData, setHashtagsData] = useState([]);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);

  const disabledFields = getDisabledFields(postStatus);
  const isSubmitDisabled = (!!postStatus && !isEditablePost(postStatus)) || isFileUploading;

  const [socialAccountIdField, socialAccountIdMeta] = useField('socialAccountId');
  const [socialAccountMediaField] = useField<FormValues['socialAccountMedia']>('socialAccountMedia');
  const [planedPostDateField, planedPostDateMeta] = useField('planedPostDate');
  const [captionField, campaignMeta] = useField('content');
  const [filesField, filesMeta] = useField<FormValues['materialsUrl']>('materialsUrl');
  const [igSocialMediaField, igSocialMediaMeta] = useField('igSocialMedia');

  const handleChangePlanedPostDate = setFieldValue.bind(null, 'planedPostDate');

  useEffect(() => {
    const socialAccountMedia =
      socialAccountsData?.engagementPostSocialAccountsForInfluencer?.find(
        account => account.id === Number(socialAccountIdField.value)
      )?.socialMedia || '';
    const socialMedia =
      socialAccountMedia === SocialAccountType.FACEBOOK_PAGE ? CampaignSocialMediaType.FACEBOOK : socialAccountMedia;

    setFieldValue('socialAccountMedia', socialAccountMedia);
    setFieldValue('socialMedia', socialMedia);
  }, [socialAccountIdField.value]);

  const handleTagClick = (value: string) => {
    setFieldValue('content', `${values.content} ${value}`);
    handleFetchTagsCloud();
  };

  const handleFetchTagsCloud = async () => {
    const variables = {
      // TODO: implement hashtags extraction, now we are sending all through the caption
      ...(!!captionField.value && { caption: captionField.value }),
      ...(!!socialAccountMediaField.value && { socialMedia: socialAccountMediaField.value }),
    };
    const { data: hashtagData } = await client.query({
      query: GET_HASHTAG_CLOUD,
      variables,
    });

    if (!!hashtagData.campaignHashtagCloud) {
      setHashtagsData(hashtagData.campaignHashtagCloud);
    }
  };

  const socialAccountsDropdown =
    socialAccountsData?.engagementPostSocialAccountsForInfluencer?.map(({ name, socialMedia, id }) => ({
      label: getDefaultSocialMediaName(name, socialMedia),
      value: String(id),
      icon: <SocialIcon src={switchImage(socialMedia)} alt="" />,
    })) || [];

  const isInstagramAccountChosen = SocialAccountType.INSTAGRAM === socialAccountMediaField.value;
  const igSocialMedias = SOCIAL_MEDIAS.filter(option =>
    [CampaignSocialMediaType.INSTAGRAM, CampaignSocialMediaType.INSTAGRAM_STORY].includes(option.value)
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Container>
        <Wrapper>
          <SingleSelectField
            name={socialAccountIdField.name}
            value={socialAccountIdField.value}
            title="Select Social Account"
            isRequired={true}
            error={!!socialAccountIdMeta.error}
            disabled={disabledFields.socialAccountId}
            help="Social Account"
            options={socialAccountsDropdown}
            setFieldValue={setFieldValue}
          />
          {socialAccountIdMeta.error && <ErrorMessage message={t(socialAccountIdMeta.error)} />}
        </Wrapper>
        {isInstagramAccountChosen && (
          <Wrapper>
            <SingleSelectField
              name={igSocialMediaField.name}
              value={igSocialMediaField.value}
              title="Post Type(for IG Story)"
              isRequired={true}
              error={!!igSocialMediaMeta.error}
              help="Post Type for IG Story"
              options={igSocialMedias}
              setFieldValue={setFieldValue}
              handleSubmit={() => {
                setFieldValue('socialMedia', igSocialMediaField.value);
              }}
              hideDeselectOption
            />
            {igSocialMediaMeta.error && <ErrorMessage message={t(igSocialMediaMeta.error)} />}
          </Wrapper>
        )}
        <Wrapper>
          <Label title={t('Selector.Plan Date of Post')} isRequired={true} help="Planed Post Date" />
          <DayPickerHandInput
            value={planedPostDateField.value}
            handleChangeDay={handleChangePlanedPostDate}
            disabled={disabledFields.planedPostDate}
            error={!!errors.planedPostDate}
            disabledRange={{ before: new Date() }}
          />
          {planedPostDateMeta.error && <ErrorMessage message={t(planedPostDateMeta.error)} />}
        </Wrapper>
        <Wrapper>
          <SentenceForm
            title="Caption"
            isRequired={true}
            error={!!errors.content}
            disabled={disabledFields.content}
            help="Caption"
            placeholder="Placeholder"
            {...captionField}
            onBlur={handleFetchTagsCloud}
          />
          {campaignMeta.error && <ErrorMessage message={t(campaignMeta.error)} />}
        </Wrapper>
        {/* Hashtag Cloud */}
        {!!hashtagsData.length && (
          <Wrapper>
            <Label
              title={t('EngagementPostForm.Recommended Hash Tags')}
              isRequired={false}
              help={t('EngagementPostForm.HelpText')}
              iconMargin="10px"
            />
            <HashtagCloud onHashtagClick={handleTagClick} data={hashtagsData} />
          </Wrapper>
        )}

        <Wrapper>
          <PostFileField
            customLabel={<Label title={t('Image/Video')} help={t('Files Help')} />}
            notes={[
              t('DragAndDrop.Info'),
              t('DragAndDrop.MaxSize', { maxSize: '10GB' }),
              t('DragAndDrop.FileType', {
                MIME: `${UPLOAD_IMAGE_TYPES}, ${UPLOAD_VIDEO_TYPES}`,
              }),
            ]}
            accept={`${IMAGE_MIME}, ${VIDEO_MIME}`}
            multiple={true}
            initialFileUrls={filesField.value.map(url => ({ url, preview: url })) || []}
            setFieldValue={setFieldValue}
            disabled={disabledFields.materialsUrl || isFileUploading}
            setIsFileUploading={setIsFileUploading}
            previewCss={previewCss}
            dropAreaCss={dropAreaCss}
            css={dragAndDropWrapperCss}
            error={!!filesMeta.error}
            {...filesField}
          />
          {filesMeta.error && <ErrorMessage message={String(filesMeta.error)} />}
        </Wrapper>
        <SubmitWrapper>
          <SubmitButton value="Upload Post" isSubmitting={isSubmitting} disabled={isSubmitDisabled} />
        </SubmitWrapper>
      </Container>
    </Form>
  );
};

const Form = styled.form`
  flex: 1;
  margin-top: 48px;
`;
const Container = styled.section`
  padding: 16px;
  background-color: #fff;

  @media (min-width: ${ViewportBreakpoint.MEDIUM}px) {
    padding: 16px 64px;
  }
`;

const Wrapper = styled.div`
  margin-bottom: 16px;
`;

const SubmitWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #dee5ec;
`;
const SocialIcon = styled.img`
  width: 16px;
  display: inline-block;
  margin-right: 8px;
`;

const previewCss = css`
  flex: 1;
  margin-top: 6px;

  & > div {
    width: 100%;

    @media (min-width: ${ViewportBreakpoint.MEDIUM}px) {
      width: 135px;
    }
  }
`;
const dropAreaCss = css`
  @media (min-width: ${ViewportBreakpoint.MEDIUM}px) {
    width: 343px;
    margin-right: 20px;
  }
`;
const dragAndDropWrapperCss = css`
  display: flex;

  @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
    flex-direction: column;
  }
`;

export default PostForm;
