import React, { Fragment } from 'react';
import styled from '@emotion/styled';

const checkmarkCode = '\u2713';

interface FormStepperProps<T> {
  steps: {
    value: T;
    label?: string;
  }[];
  currentStep: T;
  styleConfig?: {
    activeBgColor?: string;
    completedStepBgColor?: string;
    nextStepBgColor?: string;
    currentTextColor?: string;
    nextStepTextColor?: string;
  };
  className?: string;
}
const FormStepper = <T extends unknown>({ steps, currentStep, styleConfig, className }: FormStepperProps<T>) => {
  const {
    activeBgColor = '#6E7C89',
    completedStepBgColor = '#3892E5',
    nextStepBgColor = '#fff',
    currentTextColor = '#fff',
    nextStepTextColor = '#dee5ec',
  } = styleConfig ?? {};
  const currentStepIndex = steps.findIndex(el => el.value === currentStep);

  return (
    <StepperContainer className={className}>
      {steps.map((step, i) => {
        const isPrevStep = i < currentStepIndex;
        const isNextStep = i > currentStepIndex;
        const bgColor =
          step.value === currentStep ? activeBgColor : isPrevStep ? completedStepBgColor : nextStepBgColor;
        const textColor = isNextStep ? nextStepTextColor : currentTextColor;
        const borderWidth = isNextStep ? '2px' : '0';
        const labelIndex = isPrevStep ? checkmarkCode : i + 1;
        const delimeterColor = i < currentStepIndex ? completedStepBgColor : nextStepTextColor;

        return (
          <Fragment key={`${step.value}_${i}`}>
            {i > 0 ? <HorizontalLine key={`${i}_delimeter`} delimeterColor={delimeterColor} /> : null}
            <Step key={`${step.value}_${i}`} borderWidth={borderWidth} bgColor={bgColor} textColor={textColor}>
              <StepIndex className="step_index">{labelIndex}</StepIndex>
            </Step>
          </Fragment>
        );
      })}
    </StepperContainer>
  );
};

const StepperContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
const Step = styled.div<{ borderWidth: string; bgColor: string; textColor: string }>`
  display: flex;
  align-items: center;
  place-content: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: ${({ borderWidth }) => borderWidth} solid #dee5ec;
  box-sizing: border-box;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor};
`;
const HorizontalLine = styled.hr<{ delimeterColor: string }>`
  flex: auto;
  background-color: ${({ delimeterColor }) => delimeterColor};
  height: 2px;
`;
const StepIndex = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
`;

export default FormStepper;
