import React from 'react';
import { Period } from '@src/components/molecules/DayPicker/types';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { useQueryHelper } from '@src/libs/hooks';
import { useQuery } from '@apollo/client';
import { SocialMediaAccountType } from '@src/components/organisms/ProfileDetail/types';
import {
  InfluencerInstagramDashboardPostAnalytics,
  InfluencerInstagramDashboardPostAnalyticsVariables,
} from './__generated__/InfluencerInstagramDashboardPostAnalytics';
import * as INFLUENCER_INSTAGRAM_POST_ANALYTICS from './InfluencerInstagramDashboardPostAnalytics.graphql';
import Template from './template';

interface PostCardProps {
  selectedSocialMedia: SocialMediaAccountType;
  selectedPeriod: Period;
  userId: number;
  className?: string;
}

const PostCard = (props: PostCardProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { selectedPeriod, selectedSocialMedia } = props;

  const variables = {
    startDate: selectedPeriod.startDate,
    endDate: selectedPeriod.endDate,
    socialAccountId: selectedSocialMedia.id,
  };
  const { data, loading, error } = useQuery<
    InfluencerInstagramDashboardPostAnalytics,
    InfluencerInstagramDashboardPostAnalyticsVariables
  >(INFLUENCER_INSTAGRAM_POST_ANALYTICS, { variables });

  if (loading) {
    return <ListIndicator />;
  }

  if (error) {
    enqueueSnackbar(t('Can not get analytics Posts for current account'), { variant: 'error' });

    return null;
  }

  return <Template {...props} {...data?.dashboardPostAnalytics} />;
};

export default PostCard;
