import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import BlurredBlock from '../../../../molecules/BlurredBlock';

export interface ProfileDetailsContentWrapperProps {
  className?: string;
  children: ReactNode;
  title?: string;
  icon?: string;
  header?: ReactNode;
  dataNotAvailable?: boolean;
  isAvailableRate?: boolean;
}

const ProfileDetailsContentWrapper = (props: ProfileDetailsContentWrapperProps) => {
  const { className, children, header, title, icon, dataNotAvailable = false, isAvailableRate = false } = props;

  return (
    <Container className={className}>
      {header ? (
        header
      ) : (
        <Header>
          <Img src={icon} /> <Title>{title}</Title>
        </Header>
      )}
      {dataNotAvailable ? <BlurredBlock isAvailableRate={isAvailableRate}>{children}</BlurredBlock> : children}
    </Container>
  );
};
const Container = styled.section`
  position: relative;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5);
  padding: 16px;
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
`;
const Title = styled.h1`
  font-size: 13px;
  font-weight: 600;
  color: #27313b;
`;
const Img = styled.img`
  width: 24px;
  margin-right: 8px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export default ProfileDetailsContentWrapper;
