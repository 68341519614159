import React, { useCallback } from 'react';
import { css } from '@emotion/core';
import { Period } from '@src/components/molecules/DayPicker/types';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { useSort } from '@src/libs/filter';
import { formatIntNumber } from '@src/libs/format';
import { useQueryHelper, useContainerQuery, useUuumInfluencerView } from '@src/libs/hooks';
import { getOffset } from '@src/libs/paging';
import { LIMIT_10 } from '@src/libs/paging/constant';
import {
  YoutubeAnalyticsTagRankingSortOrder,
  YoutubeAnalyticsTagRankingSortInput,
} from '@src/__generated__/globalTypes';
import { useQuery } from '@apollo/client';
import { ViewportBreakpoint } from '@src/libs/theme';
import * as StyledComponents from '@src/components/organisms/AnalyticsShared/PostDetail/SliderStyledComponents';
import { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import TagRankingTable from './TagRankingTable';
import * as INFLUENCER_TAGS_TOP_RANKING from './InfluencerYoutubeAnalyticsTagsTopRanking.graphql';
import {
  InfluencerYoutubeAnalyticsTagsTopRanking,
  InfluencerYoutubeAnalyticsTagsTopRankingVariables,
} from './__generated__/InfluencerYoutubeAnalyticsTagsTopRanking';
import { getTopRankingSortFiledType } from './helpers';
import * as TagStyled from './StyledComponents';

interface TagRankingProps {
  period: Period;
  keyword?: string;
  socialMediaAccountId: number;
}
const TagRankingList = ({ socialMediaAccountId, period, keyword = '' }: TagRankingProps) => {
  const { t, search, enqueueSnackbar } = useQueryHelper();
  const { containerRef, isSmaller: showSimplifiedTable } = useContainerQuery({
    hasWidthSmallerThan: ViewportBreakpoint.MOBILE,
  });
  const { sort, setSort } = useSort<YoutubeAnalyticsTagRankingSortOrder>(getTopRankingSortFiledType);
  const { isUuumView } = useUuumInfluencerView();

  const getParams = () => {
    const queryParams = new URLSearchParams(search);

    if (socialMediaAccountId) {
      queryParams.set('socialMediaAccountId', String(socialMediaAccountId));
    }

    return queryParams.toString();
  };

  const { data, loading } = useQuery<
    InfluencerYoutubeAnalyticsTagsTopRanking,
    InfluencerYoutubeAnalyticsTagsTopRankingVariables
  >(INFLUENCER_TAGS_TOP_RANKING, {
    variables: {
      socialAccountId: Number(socialMediaAccountId),
      keyword,
      offset: getOffset(1),
      limit: LIMIT_10,
      orderBy: sort as YoutubeAnalyticsTagRankingSortInput,
      startDate: period.startDate,
      endDate: period.endDate,
    },
    onError: useCallback(err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }, []),
  });

  return (
    <div ref={containerRef} css={styles.container}>
      {loading ? (
        <ListIndicator css={styles.loading} />
      ) : (
        <>
          <SectionHeader css={styles.header}>{t('Tag Ranking')}</SectionHeader>
          <div css={styles.tagWrapper}>
            {showSimplifiedTable ? (
              <>
                {data?.tagsTopRanking.tags.map((item, index) => (
                  <TagStyled.TagRow key={item.tag + index}>
                    <TagStyled.TagWrapped>
                      <TagStyled.TagNumberBlock isTopThree={index + 1 <= 3}>{index + 1}</TagStyled.TagNumberBlock>
                      <p>{item.tag}</p>
                    </TagStyled.TagWrapped>
                    <TagStyled.PostCountWrapped>
                      {t('Post')} <span>{formatIntNumber(item.posts)}</span>
                    </TagStyled.PostCountWrapped>
                  </TagStyled.TagRow>
                ))}
              </>
            ) : (
              <TagRankingTable
                tags={data?.tagsTopRanking.tags}
                sort={sort}
                setSort={setSort}
                firstIndex={1}
                isDesktopView
              />
            )}
          </div>
          <StyledComponents.MoreText
            //  TODO: remove after full migration to AnyX
            to={isUuumView ? `/profile/tag_ranking_list?${getParams()}` : `/analytics/tag_ranking_list?${getParams()}`}
            css={styles.more}
          />
        </>
      )}
    </div>
  );
};

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    border-top: 1px solid #dee5ec;
  `,
  header: css`
    padding: 16px 16px 0 16px;
  `,
  tagWrapper: css`
    padding: 16px;
  `,
  loading: css`
    margin: 0;
  `,
  more: css`
    margin-top: 0;
  `,
};

export default TagRankingList;
