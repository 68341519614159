import { useQuery } from '@apollo/client';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { useQueryHelper } from '@src/libs/hooks';
import * as HAS_ESTIMATED_API from './InfluencerHasEstimateApi.graphql';
import { InfluencerHasEstimateApi, InfluencerHasEstimateApiVariables } from './__generated__/InfluencerHasEstimateApi';

export const hasNoEstimatedApiText = 'Cannot be measured because it has less than 10,000 followers';

interface HasEstimatedApiProps {
  pk: number;
  socialAccountId: number;
  socialMedia: SocialAccountType;
}
export const useHasEstimatedApi = ({ pk, socialAccountId, socialMedia }: HasEstimatedApiProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data } = useQuery<InfluencerHasEstimateApi, InfluencerHasEstimateApiVariables>(HAS_ESTIMATED_API, {
    variables: { pk, socialAccountId, socialMedia },
    skip: ![SocialAccountType.INSTAGRAM, SocialAccountType.YOUTUBE].includes(socialMedia),
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  return {
    hasAccountInterests: !!data?.influencerHasEstimateApi.accountInterest,
    hasAudienceDemographics: !!data?.influencerHasEstimateApi.audienceDemographics,
    hasNoEstimatedApiText,
  };
};
