import React, { useState } from 'react';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useQueryHelper, useRedirectWithinIframe } from '../../../libs/hooks';
import { AuthSocialAccountType } from '../../../__generated__/globalTypes';
import { useRedirectUrl } from '../../../libs/hooks/useRedirectUrl';
import BaseIgReconnectForm from './IgReconnect';

const SignInForm = () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const [loading, setLoading] = useState<boolean>(false);
  const { getRedirectUrl } = useRedirectUrl(FE_REDIRECT_MAPPING.RECONNECT_IG);
  const { startRedirectProcess } = useRedirectWithinIframe();

  const onClick = async () => {
    setLoading(true);

    try {
      const redirectUrl = await getRedirectUrl(AuthSocialAccountType.FACEBOOK);
      if (redirectUrl) {
        // Redirect to SocialMedia
        startRedirectProcess({ redirectUrl, redirectType: FE_REDIRECT_MAPPING.RECONNECT_IG });
      }
    } catch (error) {
      console.error('error:', error);
      enqueueSnackbar(t('UnexpectedError'), { variant: 'error' });
    } finally {
      setLoading(false);
    }

    return;
  };

  return <BaseIgReconnectForm onClick={onClick} loading={loading} />;
};

export default SignInForm;
