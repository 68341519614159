import React, { useMemo, useState } from 'react';
import HighChart, { Highcharts } from '@src/components/molecules/HighChart';
import HighchartsWordCloud from 'highcharts/modules/wordcloud';
import alignLeftIcon from '@src/assets/icon/alignLeft.svg';
import { useQuery } from '@apollo/client';
import { css, Global } from '@emotion/react';
import { useQueryHelper } from '@src/libs/hooks';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import DataOpacityWrapper from '@src/components/molecules/DataCard/DataOpacityWrapper';
import * as StyledHeading from '../StyledHeading';
import FETCH_WORD_CLOUD from './graphql/queries/TagCloudPost.graphql';
import {
  WordCloudList as WordCloudListQuery,
  WordCloudListVariables,
  WordCloudList_wordCloudList,
} from './graphql/queries/__generated__/WordCloudList';
import { getChartOptions } from './utils';

/* initializing worldcloud module */
HighchartsWordCloud(Highcharts);
interface TagCloudPostProps {
  isAnalytics?: boolean;
  socialAccountId: number;
  influencerId: number;
  socialMedia: SocialAccountType;
  className?: string;
}

const TagCloudPost = ({ socialAccountId, influencerId, socialMedia, className }: TagCloudPostProps) => {
  const [wordCloudListing, setWordCloudListing] = useState<Array<WordCloudList_wordCloudList>>([]);
  const { enqueueSnackbar, t } = useQueryHelper();

  useQuery<WordCloudListQuery, WordCloudListVariables>(FETCH_WORD_CLOUD, {
    skip: socialMedia !== SocialAccountType.INSTAGRAM,
    variables: {
      influencerId,
      socialAccountId,
      socialMedia,
    },
    onCompleted: ({ wordCloudList }) => {
      setWordCloudListing(wordCloudList || []);
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const wordCloudListingFormatted = useMemo(
    () =>
      wordCloudListing.map(({ hashtag, count }) => ({
        name: hashtag.length > 15 ? `${hashtag.substring(0, 15)}...` : hashtag,
        weight: count,
      })),
    [wordCloudListing]
  );
  const headerNode = (
    <StyledHeading.Header>
      <StyledHeading.TitleWrapper>
        <StyledHeading.Img src={alignLeftIcon} />
        <StyledHeading.HeadingText>{t('Tag Cloud (Post)')}</StyledHeading.HeadingText>
      </StyledHeading.TitleWrapper>
    </StyledHeading.Header>
  );

  return (
    <DataOpacityWrapper
      css={styles.container}
      dataNotAvailable={wordCloudListing.length <= 0}
      header={headerNode}
      className={className}
    >
      <Global styles={styles.globalComponent} />
      <HighChart options={getChartOptions(wordCloudListingFormatted)} />
    </DataOpacityWrapper>
  );
};

const styles = {
  container: css`
    min-height: 200px;
  `,
  // to solve an issue with Thai special characters
  globalComponent: css`
    .highcharts-root {
      letter-spacing: normal;
    }
  `,
};

export default TagCloudPost;
