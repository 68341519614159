import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CampaignSocialMediaType,
  EngagementCampaignPostStatus,
  MarketplaceCampaignPostStatus,
} from '../../../../__generated__/globalTypes';
import { formatIntNumber } from '../../../../libs/format';
import { switchImage } from '../../../../libs/SocialMedia';
import { defaultEmptyImage } from '../../../../libs/image';
import { getSocialMediaLabel, getEngagementSectionLabel } from '../../../../libs/utils';
import { EngagementPostStatusColumn, MarketplacePostStatusColumn } from '../../List';
import { ViewportBreakpoint } from '../../../../libs/theme';

export interface PostDetailProps {
  className?: string;
  id: number;
  postSocialMedia: CampaignSocialMediaType;
  postCaption: string;
  thumbNail: string | null;
  status: EngagementCampaignPostStatus | MarketplaceCampaignPostStatus;
  postUrl: string;
  like: number;
  comment: number;
  share: number;
  view: number;
  click: number;
  conversion: number;
  isEngagement?: boolean;
}

const ReportDetails = (props: PostDetailProps) => {
  const { t } = useTranslation();
  const {
    className,
    postUrl,
    thumbNail,
    postCaption,
    postSocialMedia,
    status,
    like,
    view,
    share,
    comment,
    conversion,
    click,
    isEngagement,
  } = props;
  const hasFbReactions = !isEngagement && postSocialMedia === CampaignSocialMediaType.FACEBOOK;

  return (
    <ReportWrapper>
      <ThumbnailWrapper>
        <PostThumbnail src={defaultEmptyImage(thumbNail)} alt="post thumbnail" />
        <CaptionWrapper>
          <Caption>{postCaption}</Caption>
          <Anchor href={postUrl} target="_blank" rel="noreferrer">
            {t(`View on ${getSocialMediaLabel(postSocialMedia)}`)}
          </Anchor>
        </CaptionWrapper>
      </ThumbnailWrapper>

      <PostStatsWrapper>
        <SocialMediaIcon src={switchImage(postSocialMedia)} alt="social media icon" />
        <EngagementStats className={className}>
          <RowWrapper>
            <Wrapper>
              <Unit>{t(`${hasFbReactions ? 'Reactions' : 'Likes'}`)}</Unit>
              <Value>{formatIntNumber(like)}</Value>
            </Wrapper>
            <Wrapper>
              <Unit>{t(getEngagementSectionLabel(postSocialMedia, 'comment'))}</Unit>
              <Value>{formatIntNumber(comment)}</Value>
            </Wrapper>
            {postSocialMedia !== CampaignSocialMediaType.YOUTUBE && (
              <Wrapper>
                <Unit>{t(getEngagementSectionLabel(postSocialMedia, 'share'))}</Unit>
                <Value>{formatIntNumber(share)}</Value>
              </Wrapper>
            )}
          </RowWrapper>

          <RowWrapper>
            <Wrapper>
              <Unit>{t('Click')}</Unit>
              <Value>{formatIntNumber(click)}</Value>
            </Wrapper>
            <Wrapper>
              <Unit>{t('Conversion')}</Unit>
              <Value>{formatIntNumber(conversion)}</Value>
            </Wrapper>
            <Wrapper>
              <Unit>{t('View')}</Unit>
              <Value>{formatIntNumber(view)}</Value>
            </Wrapper>
          </RowWrapper>
        </EngagementStats>
        <PostStatus>
          <h3>{t('Post Status')}</h3>
          {isEngagement ? (
            <EngagementPostStatusColumn status={status as EngagementCampaignPostStatus} isTableElement={false} />
          ) : (
            <MarketplacePostStatusColumn status={status as MarketplaceCampaignPostStatus} isTableElement={false} />
          )}
        </PostStatus>
      </PostStatsWrapper>
    </ReportWrapper>
  );
};

const PostStatsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 24px 0;
`;
const ReportWrapper = styled.div`
  border-top: 1px dashed #dee5ec;

  &:last-of-type {
    ${PostStatsWrapper} {
      margin-bottom: 0;
    }
  }
`;
const RowWrapper = styled.div`
  display: flex;
`;
const Wrapper = styled.div`
  padding: 0 8px;

  @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
    flex: 1;
  }
`;
const EngagementStats = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: 16px;

  @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
    flex-direction: column;
    flex: 1;

    & > ${RowWrapper} {
      flex: 1;

      &:not(:first-of-type) {
        margin-top: 16px;
      }
    }
  }
`;
const SocialMediaIcon = styled.img`
  width: 22px;
  margin-right: 8px;
`;
const Value = styled.p`
  font-size: 13px;
  color: #27313b;
`;
const Unit = styled.p`
  font-size: 10px;
  line-height: 1.3;
  letter-spacing: normal;
  color: #6e7c89;
`;
const Anchor = styled.a`
  margin-top: 8px;
  color: #3892e5;
  font-size: 11px;
  font-weight: normal;

  &:hover {
    text-decoration: underline;
  }
`;
const ThumbnailWrapper = styled.div`
  display: flex;
  border-top: 1px dashed #dee5ec;
  padding-top: 16px;
`;
const PostThumbnail = styled.img`
  width: 108px;
  height: 80px;
  margin-right: 12px;
  object-fit: contain;
  background-color: #000;
`;
const CaptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Caption = styled.p`
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* stylelint-enable */

  line-height: 1.38;
  overflow: hidden;
  word-break: break-all;
  font-size: 13px;
  height: max-content;
`;
const PostStatus = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: 3px;
    font-size: 10px;
    color: #6e7c89;
  }
`;

export default ReportDetails;
