import styled from '@emotion/styled';
import React from 'react';
import { useUpdateDocumentTitle } from '@src/libs/hooks';
import CampaignList, { CampaignListProps } from '@src/components/organisms/Campaign/CampaignList/CampaignList';
import TutorialYourJob from '@src/components/organisms/Tutorial/YourJob';
import { ViewportBreakpoint } from '@src/libs/theme';

const Template = (props: CampaignListProps) => {
  useUpdateDocumentTitle({ title: 'pageTitle.Your Job', pageTitle: 'Your Job' });

  return (
    <Container>
      <ResponsiveWrapper>
        <TutorialYourJob />
        <CampaignList {...props} />
      </ResponsiveWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const ResponsiveWrapper = styled.div`
  width: 100%;

  @media (min-width: ${ViewportBreakpoint.DESKTOP}px) {
    width: 702px;
  }
`;

export default Template;
