import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import noCampaigns from '../../../assets/img/monocular_girl.png';
import { PalleteButton } from '../../atoms/Button';
import { mainWhite } from '../../../libs/pallete';

const EmptyYourJobList = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ImageWrapper>
        <img src={noCampaigns} />
      </ImageWrapper>
      <Annotation>{t('Annotation.No Campaign')}</Annotation>
      <Annotation>{t('Annotation.Participate in ongoing')}</Annotation>
      <CopyButton palleteColor={mainWhite} height="40px" href="/search" text="Search Job" />
    </Wrapper>
  );
};

const CopyButton = styled(PalleteButton)`
  padding: 0 42px;
  width: auto;
  margin-top: 16px;
`;

const Annotation = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #6e7c89;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const ImageWrapper = styled.div`
  position: relative;
  margin-bottom: 16px;

  img {
    width: 105px;
    height: 120px;
  }
`;

export default EmptyYourJobList;
