import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const Empty = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Title>{t('General.Nothing has been found')}</Title>
      <Content>{t('General.Please try a different query')}</Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Title = styled.h6`
  color: #757575;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.02em;
`;

const Content = styled.p`
  margin-top: 8px;
  color: #757575;
  font-size: 14px;
`;

export default Empty;
