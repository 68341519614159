import React from 'react';
import { css } from '@emotion/react';
import { useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import AnalyticsContentWrapper, {
  ContentType,
} from '@src/components/organisms/AnalyticsShared/AnalyticsContentWrapper';
import { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { Period } from '@src/components/molecules/DayPicker/types';
import BasePosts from './BasePosts';
import UserAnalysisList from './UserAnalysis';
import * as IG_INTERACTION_POSTS from './InfluencerInstagramInteractionPosts.graphql';
import {
  InfluencerInstagramInteractionPosts,
  InfluencerInstagramInteractionPostsVariables,
} from './__generated__/InfluencerInstagramInteractionPosts';

interface InteractionPostsProps {
  period: Period;
  id: number;
}
const InteractionPosts = ({ period, id }: InteractionPostsProps) => {
  const { t, history, enqueueSnackbar, pathname, search } = useQueryHelper();
  const { data, loading } = useQuery<InfluencerInstagramInteractionPosts, InfluencerInstagramInteractionPostsVariables>(
    IG_INTERACTION_POSTS,
    {
      variables: {
        socialAccountId: id,
        startDate: period.startDate,
        endDate: period.endDate,
        offset: 0,
        limit: 5,
        orderBy: { field: null, order: null },
        keyword: '',
      },
      fetchPolicy: 'network-only',
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
    }
  );

  if (loading) {
    return <ListIndicator />;
  } else if (!data?.influencerInstagramInteractionPosts || !data.influencerAllInstagramInteractionUsers) {
    history.push('/analytics?sm=INSTAGRAM');

    return null;
  }

  const getParams = () => {
    const queryParams = new URLSearchParams(search);

    if (id) {
      queryParams.set('socialMediaAccountId', String(id));
    }

    return queryParams.toString();
  };

  const { taggedPosts, mentionedPosts } = data.influencerInstagramInteractionPosts;
  const searchWithId = getParams();

  return (
    <AnalyticsContentWrapper contentType={ContentType.Posts} css={styles.wrapper}>
      <UserAnalysisList
        userAnalytics={data.influencerAllInstagramInteractionUsers.usernames}
        moreLink={`${pathname}/user_analysis?${searchWithId}`}
      />
      <BasePosts
        socialMediaAccountId={id}
        posts={taggedPosts}
        header={<SectionHeader>{t('Tagged Posts')}</SectionHeader>}
        moreLink={`${pathname}/tagged_posts?${searchWithId}`}
      />
      <hr css={styles.delimeter} />
      <BasePosts
        socialMediaAccountId={id}
        posts={mentionedPosts}
        header={<SectionHeader>{t('Mentioned Posts')}</SectionHeader>}
        moreLink={`${pathname}/mentioned_posts?${searchWithId}`}
      />
    </AnalyticsContentWrapper>
  );
};

const styles = {
  wrapper: css`
    margin-top: 24px;
  `,
  delimeter: css`
    height: 1px;
    background-color: #dee5ec;
  `,
};

export default InteractionPosts;
