import {
  bigIntFormatter,
  formatDateCount,
  formatLineChartDateCount,
  formatIntNumber,
  formatNumber,
} from '@src/libs/format';

export const defaultSelectableCharts: ChartColorTypes[] = [
  'Subscribers',
  'Post',
  'Views',
  'Like',
  'Dislike',
  'Comments',
];

export const allSelectableCharts: ChartColorTypes[] = defaultSelectableCharts;

export type ChartColorTypes = 'Subscribers' | 'Post' | 'Views' | 'Like' | 'Dislike' | 'Comments';

export const colors: Record<ChartColorTypes, string> = {
  Subscribers: '#ff2b52',
  Post: '#ffd7c1',
  Views: '#ff96ae',
  Like: '#e9b8d7',
  Dislike: '#E9B8D7',
  Comments: '#a0c8df',
};

interface EngagementWithHistoryType {
  total: number | null;
  growth: number | null;
  percentage: number | null;
  history: Array<{ count: number | null; date: any | null }> | null;
}

export const getEngagementDataWithHistory = (eng: EngagementWithHistoryType) => ({
  engagement: {
    total: eng?.total || 0,
    growth: eng?.growth || 0,
    rate: eng?.percentage || 0,
  },
  history: eng?.history || [],
});

export const generateYAxisBasedOnDisplayedCharts = <T>(yAxisCharts: T[], hasYMin: boolean | undefined = true) =>
  yAxisCharts.map(
    (_, i): Highcharts.YAxisOptions => ({
      ...(hasYMin ? { min: 0 } : {}),
      title: {
        text: '',
      },
      labels: {
        enabled: i === 0,
        formatter: function formatter(): string {
          return bigIntFormatter(this.value, 0);
        },
      },
      allowDecimals: false,
    })
  );

export const getBarDataBasedOnPointer = (
  chart: Highcharts.Chart,
  e: Highcharts.PointerEventObject,
  postsColors: string[]
) => {
  let selectedPoint: Highcharts.Point[] | undefined;
  // @ts-ignore
  const x = Math.round(e.xAxis[0].value);
  chart.series.forEach(seria => {
    if (seria.visible) {
      seria.points.forEach(point => {
        if (point.x === x && postsColors.includes(`${point.color}`)) {
          selectedPoint = [...(selectedPoint || []), point];
        }
      });
    }
  });

  return selectedPoint;
};

export interface SeriesType {
  name: string;
  type: string;
  data: number[];
  color: string;
}
export interface TooltipDataType<T extends string> {
  [key: string]: {
    [key in T]: {
      value: string;
      increment: number;
    };
  };
}
export const getDailyDataForTooltip = (categories: string[], series: SeriesType[]): TooltipDataType<ChartColorTypes> =>
  categories.reduce(
    (acc, day, index) => ({
      ...acc,
      [day]: getSerieDayData<ChartColorTypes>(index, series),
    }),
    {}
  );

export const getSerieDayData = <T extends string>(index: number, series: SeriesType[]) =>
  series.reduce((acc, serie) => {
    const hasDecimals = serie.name === 'Engagement Rate';

    return {
      ...acc,
      [serie.name as T]: {
        // @ts-ignore not sure how to fix this
        value: hasDecimals ? formatNumber(serie.data[index] || null) : formatIntNumber(serie.data[index] || null),
        // currenlty we just show difference between prev and curr values as increment
        increment: serie.name === 'Subscribers' && index !== 0 ? serie.data[index] - serie.data[index - 1] : 0,
      },
    };
  }, {});

export interface SeriesHistoryType {
  count: number | null;
  date: any | null;
}

interface SeriesAndCategoriesType {
  historyData: Record<string, SeriesHistoryType[]>;
  displayedCharts: ChartColorTypes[];
}

export const getSeriesAndCategories = ({
  historyData: { subscribersHistory, postsHistory, viewsHistory, likesHistory, dislikesHistory, commentsHistory },
  displayedCharts,
}: SeriesAndCategoriesType) => {
  // We are creating data-sets based on Period which is represented by followersHistory, if no data for some day we will replace it with 0
  const { count: subscribersCount, date: subscribersCategories } = formatLineChartDateCount(subscribersHistory);
  const { count: postsCount } = formatDateCount(subscribersHistory, postsHistory);
  const { count: viewsCount } = formatDateCount(subscribersHistory, viewsHistory);
  const { count: likesCount } = formatDateCount(subscribersHistory, likesHistory);
  const { count: dislikesCount } = formatDateCount(subscribersHistory, dislikesHistory);
  const { count: commentsCount } = formatDateCount(subscribersHistory, commentsHistory);

  const dateCategories = subscribersCategories;

  const series = [
    {
      name: 'Subscribers',
      type: 'line',
      data: subscribersCount,
      color: colors.Subscribers,
      marker: {
        symbol: 'circle',
        lineWidth: 2,
        lineColor: undefined, // inherit from chart
        fillColor: '#fff',
      },
      visible: displayedCharts.includes('Subscribers'),
      zIndex: 1,
    },
    {
      name: 'Post',
      type: 'column',
      data: postsCount,
      color: colors.Post,
      yAxis: 1,
      visible: displayedCharts.includes('Post'),
    },
    {
      name: 'Views',
      type: 'column',
      data: viewsCount,
      color: colors.Views,
      yAxis: 2,
      visible: displayedCharts.includes('Views'),
    },
    {
      name: 'Like',
      type: 'column',
      data: likesCount,
      color: colors.Like,
      yAxis: 3,
      visible: displayedCharts.includes('Like'),
    },
    {
      name: 'Dislike',
      type: 'column',
      data: dislikesCount,
      color: colors.Dislike,
      yAxis: 4,
      visible: displayedCharts.includes('Dislike'),
    },
    {
      name: 'Comments',
      type: 'column',
      data: commentsCount,
      color: colors.Comments,
      yAxis: 5,
      visible: displayedCharts.includes('Comments'),
    },
  ];
  const tooltipData = getDailyDataForTooltip(dateCategories, series);

  return {
    series: series as Highcharts.SeriesOptionsType[],
    categories: dateCategories,
    tooltipData,
  };
};
