import trashBinIcon from '../assets/icon/trash.svg';
import editIcon from '../assets/icon/edit.svg';

export type CustomIconName = 'edit' | 'delete' | undefined;
export const getCustomIcon = (name: CustomIconName) => {
  switch (name) {
    case 'edit':
      return editIcon;
    case 'delete':
      return trashBinIcon;

    default:
      return undefined;
  }
};
