import styled from '@emotion/styled';
import TextCutter from '@src/components/atoms/TextCutter';
import { ViewportBreakpoint } from '@src/libs/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const Title = styled.h6`
  font-size: 12px;
  font-weight: 600;
  flex: 1;
  overflow: hidden;
`;

export const Caption = styled.p`
  padding: 8px;
  font-size: 13px;
  line-height: 18px;
  word-break: break-all;
  color: #27313b;
  white-space: pre-wrap;
`;

export const PostCaptionWrapper = styled.div`
  height: calc(100% - 17px);
  flex: 1 1 0;
  overflow-y: auto;
  border: 1px solid #dee5ec;
  min-height: 30px;
`;

export const TopHeading = styled.div`
  height: 40px;
  width: auto;
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  justify-content: space-between;
`;

export const CloseIcon = styled.img`
  cursor: pointer;
  opacity: 0.7;

  &:hover {
    color: #c5d0da;
    opacity: 1;
  }
`;

export const MainContent = styled.div`
  display: flex;
  height: 400px;

  @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
    flex-direction: column;
  }
`;

export const LeftContent = styled.div`
  width: 100%;
  margin-right: 24px;
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const CaptionSection = styled.div`
  margin-top: 16px;
  overflow: hidden;
  flex: 1;
`;
export const CommentsSection = styled.div`
  margin-top: 16px;
`;

export const CommentsWrapper = styled.div`
  display: block;
  width: auto;
`;

export const CaptionFrame = styled.div`
  height: 111px;
  border: 1px solid #dee5ec;
  overflow-y: auto;
`;

export const TextWrapper = styled.div`
  display: flex;
  padding: 8px;
`;
export const CommentText = styled.p`
  margin-left: 10px;
  font-size: 12px;
  color: #27313b;
  line-height: 17px;
  width: fit-content;
  word-break: break-all;
`;
export const CommentUsername = styled(TextCutter)`
  width: 80px;
  min-width: 80px;
  font-size: 13px;
  font-weight: 600;
  color: #27313b;
`;
export const ContainerWrapper = styled.div<{ hasPreview: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const ReactPlayerWrapper = styled.div`
  margin: auto;
`;
export const VideoPostTitle = styled.h3`
  margin-top: 8px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
`;
export const VideoTag = styled.span`
  color: #3892e5;
  padding-left: 16px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  cursor: pointer;
`;

export const PostDetailWrapper = styled.div`
  display: flex;
  max-width: 970px;
  min-height: 472px;
  margin: 0 16px;
  background-color: #fff;
  z-index: 10;
  box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.24), 0 0 12px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  color: #27313b;
`;
