import { useQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import { getOperationName } from '@apollo/client/utilities';
import { LIMIT } from '@src/libs/constant';
import { useQueryHelper } from '@src/libs/hooks';
import { CampaignStatusForInfluencer } from '@src/__generated__/globalTypes';
import { AllEngagementPosts, AllEngagementPostsVariables } from './__generated__/AllEngagementPosts';
import { DeleteEngagementPost, DeleteEngagementPostVariables } from './__generated__/DeleteEngagementPost';
import * as ALL_ENGAGEMENT_POSTS from './AllEngagementPosts.graphql';
import * as DELETE_ENGAGEMENT_POST from './DeleteEngagementPost.graphql';
import { State } from './types';

const defaultDialog = {
  dialog: false,
  deletingId: null,
  isDeleting: false,
};

export const useEngagementPostQueries = (engagementId: number) => {
  const { data, loading, error } = useQuery<AllEngagementPosts, AllEngagementPostsVariables>(ALL_ENGAGEMENT_POSTS, {
    variables: { engagementId, offset: 0, limit: LIMIT },
    fetchPolicy: 'cache-and-network',
  });
  const [deleteEngagementPostMutation] = useMutation<DeleteEngagementPost, DeleteEngagementPostVariables>(
    DELETE_ENGAGEMENT_POST,
    {
      refetchQueries: [getOperationName(ALL_ENGAGEMENT_POSTS) || 'AllEngagementPosts'],
    }
  );

  const [{ deletingId, dialog, isDeleting }, setDialogState] = useState<State>(defaultDialog);
  const { t, enqueueSnackbar } = useQueryHelper();

  const openDialog = (id: number) => {
    setDialogState(prevState => ({
      ...prevState,
      dialog: true,
      deletingId: id,
    }));
  };
  const closeDialog = () => {
    setDialogState(prevState => ({
      ...prevState,
      dialog: false,
    }));
  };
  const toggleDeleting = (deletingState: boolean) => {
    setDialogState(prevState => ({
      ...prevState,
      isDeleting: deletingState,
    }));
  };

  const posts =
    data?.allEngagementPostsForInfluencer?.map(post => ({
      ...post,
      handleClickDelete: () => {
        openDialog(post.id);
      },
    })) || [];

  const deleteEngagementPost = async () => {
    if (deletingId === null) {
      return;
    }

    toggleDeleting(true);
    const variables = { id: deletingId };
    await deleteEngagementPostMutation({ variables })
      .then(() => {
        const successMessage = t('succeededInDeleting');
        enqueueSnackbar(successMessage, { variant: 'success' });
      })
      .catch(() => {
        const failureMessage = t('failedToDelete');
        enqueueSnackbar(failureMessage, { variant: 'error' });
      });
    closeDialog();
    toggleDeleting(false);
  };

  const hasUploadDraftPost = data?.engagementForInfluencer?.status !== CampaignStatusForInfluencer.FINISHED;
  const hasNotFoundError = error?.graphQLErrors[0]?.message === 'NOT_FOUND';

  return {
    loading,
    posts,
    toggleDeleting,
    closeDialog,
    deleteEngagementPost,
    dialog,
    isDeleting,
    hasUploadDraftPost,
    hasNotFoundError,
  };
};
