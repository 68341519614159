import styled from '@emotion/styled';
import React from 'react';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { formatPercent, renderIfMoreThan20Percent } from '@src/libs/format';
import positiveIcon from '@src/assets/icon/positiveSentiment.svg';
import negativeIcon from '@src/assets/icon/negativeSentiment.svg';
import neutralIcon from '@src/assets/icon/neutralSentiment.svg';
import DataOpacityWrapper from '@src/components/molecules/DataCard/DataOpacityWrapper';

interface SentimentAnalysisColumnProps {
  className?: string;
  positive: number;
  negative: number;
  neutral: number;
  isTable?: boolean;
}

const SentimentAnalysisMobile = (props: SentimentAnalysisColumnProps) => {
  const { className, positive, negative, neutral, isTable = true } = props;

  const { t } = useTranslation();
  const noData = !(positive && negative && neutral);
  const Wrapper = isTable ? TableElement : DivElement;

  return (
    <Wrapper className={className}>
      <DataOpacityWrapper
        dataNotAvailable={noData}
        css={[
          noData
            ? css`
                zoom: 0.5;
              `
            : '',
          css`
            border-top: none;
          `,
        ]}
      >
        <TableData>
          <LabelContainer>
            <Label color="#ff8097">
              <Img src={positiveIcon} />
              {t('Positive')}
            </Label>
            <Label color="#ffbe82">
              <Img src={neutralIcon} />
              {t('Neutral')}
            </Label>
            <Label color="#707fc0">
              <Img src={negativeIcon} />
              {t('Negative')}
            </Label>
          </LabelContainer>
          <BreakdownWrapper>
            <Positive value={positive} title={formatPercent(positive, true, 1)}>
              {renderIfMoreThan20Percent({ value: positive, digits: 1 })}
            </Positive>
            <Neutral value={neutral} title={formatPercent(neutral, true, 1)}>
              {renderIfMoreThan20Percent({ value: neutral, digits: 1 })}
            </Neutral>
            <Negative value={negative} title={formatPercent(negative, true, 1)}>
              {renderIfMoreThan20Percent({ value: negative, digits: 1 })}
            </Negative>
          </BreakdownWrapper>
        </TableData>
      </DataOpacityWrapper>
    </Wrapper>
  );
};

const DivElement = styled.div`
  padding: 16px;

  &:not(:last-of-type) {
    border-right: 1px solid #dee5ec;
  }
`;
const TableElement = styled(DivElement.withComponent('td'))``;
const TableData = styled.div`
  display: inline-block;
  font-size: 13px;
  line-height: 16px;
  width: 100%;
`;
const BreakdownWrapper = styled.div`
  display: flex;
  color: #fff;
  height: 32px;
`;
const Analysis = styled.div<{ value: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;
  cursor: pointer;

  &:hover {
    box-shadow: 0 3px 4px 0 rgba(39, 49, 59, 0.2);
    margin-bottom: 2px;
    margin-top: -2px;
  }
`;
const Negative = styled(Analysis)<{ value: number }>`
  background-color: #707fc0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: ${props => `${props.value * 100}%`};
`;
const Positive = styled(Analysis)<{ value: number }>`
  background-color: #ff8097;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: ${props => `${props.value * 100}%`};
`;
const Neutral = styled(Analysis)<{ value: number }>`
  background-color: #ffbe82;
  width: ${props => `${props.value * 100}%`};
`;
const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;
const Img = styled.img`
  width: 16px;
  margin-right: 4px;
`;
const Label = styled.p<{ color: string }>`
  display: flex;
  font-size: 11px;
  font-weight: 600;
  color: ${props => `${props.color}`};
`;

export default SentimentAnalysisMobile;
