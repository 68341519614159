import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import clockOrange from '@src/assets/icon/clockOrange.svg';
import warningRed from '@src/assets/icon/warningRed.svg';
import { AnyXEngagementCampaignStatus } from '@src/__generated__/globalTypes';

interface ReasonWidgetProps {
  content: string | null;
  status: AnyXEngagementCampaignStatus;
  title: string | null;
}

const ReasonWidget = ({ content, status, title }: ReasonWidgetProps) => {
  const { t } = useTranslation();

  const color = () => {
    switch (status) {
      case AnyXEngagementCampaignStatus.WARNING_EFFECTIVE:
      case AnyXEngagementCampaignStatus.WARNING_REVIEWING:
        return '#ff5f5f';
      default:
        return '#ffb63d';
    }
  };

  const icon = () => {
    switch (status) {
      case AnyXEngagementCampaignStatus.WARNING_EFFECTIVE:
      case AnyXEngagementCampaignStatus.WARNING_REVIEWING:
        return warningRed;
      default:
        return clockOrange;
    }
  };

  const widgetContent = () => {
    switch (status) {
      case AnyXEngagementCampaignStatus.POSTING:
        return 'Checking the change post';
      case AnyXEngagementCampaignStatus.REVIEWING:
        return 'After posting, it will take around 20 minutes for the report to take';
      default:
        return content || '';
    }
  };

  const widgetTitle = () => {
    switch (status) {
      case AnyXEngagementCampaignStatus.POSTING:
        return 'Post reflection time';
      case AnyXEngagementCampaignStatus.REVIEWING:
        return 'Checking the post again';
      default:
        return title || 'Invalid contents';
    }
  };

  return (
    <Container color={color()}>
      <div>
        <img alt="icon" height="18" src={icon()} width="18" />
      </div>
      <div>
        <div>{t(widgetTitle())}</div>
        <div>{t(widgetContent())}</div>
      </div>
    </Container>
  );
};

const Container = styled.div<{ color: string }>`
  border-left: 3px solid ${({ color }) => color};
  border-radius: 3px;
  box-shadow: 0 1px 5px rgba(39, 49, 59, 0.2);
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  width: 100%;

  & > div:nth-of-type(2) {
    display: grid;
    flex-basis: 88%;
    margin-left: 8px;

    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    & > div:nth-of-type(2) {
      color: #6e7c89;
      font-size: 13px;
    }
  }
`;

export default ReasonWidget;
