import React from 'react';
import { css } from '@emotion/react';
import { convertDurationToHhMmSs } from '@src/libs/date';
import { formatIntNumber, formatPercent, formatDate } from '@src/libs/format';
import { defaultEmptyImage } from '@src/libs/image';
import { ORDER } from '@src/__generated__/globalTypes';
import { useQueryHelper } from '@src/libs/hooks';
import TextCutter from '@src/components/atoms/TextCutter';
import { EmptyVideos } from '../EmptyVideos';

export interface SortType<T extends string> {
  field: T | null;
  order: ORDER | null;
}

interface TableContentProps {
  posts: {
    comments: any;
    dislikes: any;
    id: number;
    likes: any;
    negativeRate: number;
    positiveRate: number;
    postDate: any;
    videoDuration: number;
    videoThumbnail: string | null;
    videoTitle: string;
    views: any;
  }[];
  onClick?: (postId: number) => void;
}

const TableContentMobile = ({ posts, onClick }: TableContentProps) => {
  const { t } = useQueryHelper();

  if (!posts.length) {
    return <EmptyVideos />;
  }

  return (
    <div css={styles.postsWrapper}>
      {posts.map((video, i) => {
        const {
          comments,
          dislikes,
          id,
          likes,
          negativeRate,
          positiveRate,
          postDate,
          videoDuration,
          videoThumbnail,
          videoTitle,
          views,
        } = video;

        return (
          <div key={`${id}_${i}}`} css={styles.postContainer}>
            <div onClick={() => onClick?.(id)} css={styles.postHeader}>
              <img src={defaultEmptyImage(videoThumbnail)} css={styles.postImage} />
              <TextCutter text={videoTitle} css={styles.postContent} lines={4} />
            </div>
            <div css={styles.postInfo}>
              <div css={styles.info} style={{ flex: 1.2 }}>
                <span css={styles.label}>{t('Post Date')}</span>
                <span css={styles.value}>{formatDate(postDate, 'MMM d, y', true)}</span>
              </div>
              <div css={styles.info}>
                <span css={styles.label}>{t('View')}</span>
                <span css={styles.value}>{formatIntNumber(views)}</span>
              </div>
              <div css={styles.info}>
                <span css={styles.label}>{t('Like')}</span>
                <span css={styles.value}>{formatIntNumber(likes)}</span>
              </div>
              <div css={styles.info}>
                <span css={styles.label}>{t('Dislike')}</span>
                <span css={styles.value}>{formatIntNumber(dislikes)}</span>
              </div>
              <div css={styles.info}>
                <span css={styles.label}>{t('Comments')}</span>
                <span css={styles.value}>{formatIntNumber(comments)}</span>
              </div>
              <div css={styles.info}>
                <span css={styles.label}>{t('Positive Rate')}</span>
                <span css={styles.value}>{formatPercent(positiveRate, true)}</span>
              </div>
              <div css={styles.info}>
                <span css={styles.label}>{t('Negative Rate')}</span>
                <span css={styles.value}>{formatPercent(negativeRate, true)}</span>
              </div>
              <div css={styles.info}>
                <span css={styles.label}>{t('Video Length')}</span>
                <span css={styles.value}>{convertDurationToHhMmSs(videoDuration)}</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const styles = {
  postsWrapper: css`
    display: flex;
    flex-direction: column;
    margin: 16px;
    margin-top: 0;
    box-sizing: border-box;
    border-top: solid 1px #e6ecf0;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px rgb(222 229 236 / 50%);
  `,
  postContainer: css`
    padding: 16px;
    flex: 1;
    border-bottom: 1px solid #dee5ec;
  `,
  postHeader: css`
    width: 100%;
    height: 80px;
    display: inline-flex;
    cursor: pointer;
  `,
  postImage: css`
    width: 138px;
    height: 78px;
  `,
  postContent: css`
    flex: 1;
    margin-left: 12px;
    font-size: 13px;
    color: #3892e5;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  `,
  postInfo: css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 16px;
  `,
  info: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 4px 0;
  `,
  value: css`
    font-size: 13px;
    line-height: 18px;
    color: #27313b;
  `,
  label: css`
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.28px;
    color: #6e7c89;
  `,
};

export default TableContentMobile;
