import React from 'react';
import PostingHabits from '@src/components/organisms/ProfileWidgets/PostingHabits';
import { InfluencerYoutubeComparePosts_influencerYoutubeComparePosts_compareAccounts_postHabit } from './__generated__/InfluencerYoutubeComparePosts';

const PostingHabitsDialogContent = ({
  postsHabit,
}: {
  postsHabit: InfluencerYoutubeComparePosts_influencerYoutubeComparePosts_compareAccounts_postHabit[];
}) => <PostingHabits postsHabit={postsHabit} />;

export default PostingHabitsDialogContent;
