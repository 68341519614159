import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../../../libs/format';
import ReportDetails from './ReportDetails';
import { ReportListDataType } from './types';

const ReportList = ({ report, isEngagement }: { report: ReportListDataType; isEngagement?: boolean }) => {
  const { t } = useTranslation();

  return (
    <>
      <CampaignTitle>{report?.title}</CampaignTitle>
      {!isEngagement && (
        <TotalRevenue>
          <h3>{t('Revenue')}</h3>
          <RevenueWrapper>
            <Value>{formatNumber(report?.revenue || null)}</Value>
            <Unit>{t(report?.currency)}</Unit>
          </RevenueWrapper>
        </TotalRevenue>
      )}
      <Wrapper>
        {report?.postReport.map(postReport => (
          <ReportDetails key={postReport.id} {...postReport} isEngagement={isEngagement} />
        ))}
      </Wrapper>
    </>
  );
};

const CampaignTitle = styled.h1`
  font-size: 18px;
  color: #27313b;
  margin: 16px 0;
`;
const Wrapper = styled.section`
  padding: 16px;
  border-radius: 7px;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px #dee5ec80;
  background-color: #fff;
`;
const TotalRevenue = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  font-size: 13px;
  color: #27313b;
  padding: 3px 0 13px;
`;
const RevenueWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;
const Value = styled.span`
  font-size: 14px;
  font-weight: 600;
`;
const Unit = styled.span`
  font-size: 11px;
  text-transform: capitalize;
  margin-left: 4px;
`;

export default ReportList;
