import { CampaignSocialMediaType } from '../../__generated__/globalTypes';
import { MarketplaceForInfluencer_influencerProfileV2 } from './__generated__/MarketplaceForInfluencer';

export const checkTheSocialMediaIsConnected = (
  socialMedia: CampaignSocialMediaType,
  influencerProfile: MarketplaceForInfluencer_influencerProfileV2
): boolean => {
  switch (socialMedia) {
    case CampaignSocialMediaType.FACEBOOK: {
      const isAccountConnected = !!influencerProfile.facebookAccount?.url;
      const isPageConnected =
        influencerProfile.facebookPages === null ? false : influencerProfile.facebookPages.length > 0;

      return isAccountConnected || isPageConnected;
    }
    case CampaignSocialMediaType.INSTAGRAM:
    case CampaignSocialMediaType.INSTAGRAM_STORY: {
      return !!influencerProfile.instagramAccounts?.length;
    }
    case CampaignSocialMediaType.TWITTER: {
      return !!influencerProfile.twitterAccounts?.length;
    }
    case CampaignSocialMediaType.YOUTUBE: {
      return !!influencerProfile.youtubeAccounts?.length;
    }
    case CampaignSocialMediaType.TIKTOK: {
      return !!influencerProfile.tiktokAccounts?.length;
    }
    default:
      return false;
  }
};
