import { useTranslation } from 'react-i18next';
import React from 'react';
import DataOpacityWrapper, { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import HighChart from '@src/components/molecules/HighChart';
import { formatPercent } from '@src/libs/format';

interface GenderChartProps {
  femaleRate?: number | null;
  maleRate?: number | null;
  className?: string;
}
const GenderChart = ({ femaleRate, maleRate, className }: GenderChartProps) => {
  const { t } = useTranslation();
  const options: Highcharts.Options = {
    chart: {
      type: 'pie',
      height: 300,
      spacingBottom: 0,
    },
    title: {
      text: '',
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    legend: {
      symbolHeight: 15,
      symbolWidth: 15,
      symbolRadius: 2,
      itemMarginBottom: 6,
      itemStyle: {
        fontSize: '13px',
        color: '#27313b',
        fontWeight: 'normal',
      },
    },
    series: [
      {
        innerSize: '50%',
        type: 'pie',
        data: [
          {
            name: t('Female') as string,
            y: femaleRate,
            color: '#ff8a94',
          },
          {
            name: t('Male') as string,
            y: maleRate,
            color: '#707fc0',
          },
        ],
      },
    ],
    tooltip: {
      formatter: function formatter(): string {
        return `
          <div style="border-radius:3px;display:flex;align-items:center;padding:13px 16px">
          <span style="display:inline-block;margin-right:8px;background-color:${
            this.point.color
          };height:12px;width:12px"></span>
            <span style="margin-right:16px">${this.point.name}</span>
            <span>${formatPercent(this.y)}</span>
          </div>`;
      },
      padding: 0,
      borderColor: 'green',
      borderWidth: 0,
      borderRadius: 3,
      backgroundColor: '#27313b',
      style: {
        color: '#ffffff',
      },
      useHTML: true,
    },
  };

  return (
    <DataOpacityWrapper
      // TODO:
      header={<SectionHeader>{t('BasicInfo.Gender')}</SectionHeader>}
      dataNotAvailable={!femaleRate && !maleRate}
      className={className}
    >
      <HighChart options={options} />
    </DataOpacityWrapper>
  );
};

export default GenderChart;
