import React, { SyntheticEvent } from 'react';
import { defaultInfluencerAvatar } from '@src/libs/image';
import { ComparableYtAccountType } from '../types';
import * as StyledCards from './AccountCardsStyled';
import { getCardIcon } from './utils';

interface MobileTemplateProps {
  account: ComparableYtAccountType;
  handleClickDelete: (e: SyntheticEvent<HTMLButtonElement>, id: string) => void;
  toggleShowHide: (anchorId: number | null, elementId?: number | null) => void;
}
const MobileTemplate = ({ account, handleClickDelete, toggleShowHide }: MobileTemplateProps) => {
  const isHidden = !account.isSelected;
  const eyeIcon = getCardIcon(isHidden ? 'hidden' : 'shown', false);

  return (
    <StyledCards.Card key={`${account.elementId}_${account.accountId}`}>
      {account.isDeletable && (
        <StyledCards.CardIcon
          onClick={e => handleClickDelete(e, account.uniqId)}
          css={
            isHidden
              ? {
                  color: '#c5d0da',
                }
              : ''
          }
        >
          {'\u00D7'}
        </StyledCards.CardIcon>
      )}
      <StyledCards.AvatarWrapper>
        <StyledCards.Avatar
          src={defaultInfluencerAvatar(account.avatarUrl || null)}
          css={isHidden ? { opacity: 0.5 } : ''}
        />
      </StyledCards.AvatarWrapper>

      <StyledCards.TitleWithLink
        title={account.channelName}
        href={account.profileUrl}
        target="_blank"
        rel="noreferrer noopener"
      >
        {account.channelName}
      </StyledCards.TitleWithLink>
      <StyledCards.EyeButton onClick={() => toggleShowHide(account.accountId, account.elementId)}>
        <img src={eyeIcon} width="24px" height="24px" />
      </StyledCards.EyeButton>
    </StyledCards.Card>
  );
};

export default MobileTemplate;
