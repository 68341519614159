import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useAuthData, usePageLayout } from '@src/libs/hooks';
import chat from '@src/assets/icon/home/chat.svg';
import clipboard from '@src/assets/icon/home/clipboard.svg';
import forms from '@src/assets/icon/home/forms.svg';
import gift from '@src/assets/icon/home/gift.svg';
import setting from '@src/assets/icon/home/setting.svg';
import shopify from '@src/assets/icon/home/shopify.svg';
import socialMedias from '@src/assets/icon/home/socialMedias.svg';
import thunder from '@src/assets/icon/home/thunder.svg';
import { Icon } from '@src/components/atoms/Icon';
import { ViewportType } from '@src/libs/theme';
import useInfluencerProfile from '@src/pages/Analytics/useInfluencerProfile';
import useEngagementCampaigns from '@src/pages/Casting/YourJob/Listings/useEngagementCampaigns';
import { InfluencerType, SocialAccountType, UserRoles } from '@src/__generated__/globalTypes';

interface Card {
  annotation: string;
  bgColor: string;
  icon: string;
  outlineColor?: string;
  title: string;
  titleColor: string;
  to: string;
}

const NavigationCards = () => {
  const { t } = useTranslation();
  const { creatorType, role } = useAuthData();
  const { hasFBAccount, hasIGAccount, hasTWAccount, hasYTAccount } = useInfluencerProfile();
  const { progressCampaignCount } = useEngagementCampaigns({ tab: 'in_progress' });
  const { isMobileView } = usePageLayout();
  const isCreatorAndStaff = [UserRoles.INFLUENCER, UserRoles.CREATOR_STAFF].includes(role as UserRoles);
  const isProInfluencer = creatorType === InfluencerType.PRO;
  const isCampaignsExist = !!progressCampaignCount;

  const analyticsRedirectType = () => {
    if (hasIGAccount) {
      return SocialAccountType.INSTAGRAM;
    } else if (hasFBAccount) {
      return SocialAccountType.FACEBOOK;
    } else if (hasTWAccount) {
      return SocialAccountType.TWITTER;
    } else if (hasYTAccount) {
      return SocialAccountType.YOUTUBE;
    }

    return SocialAccountType.TIKTOK;
  };

  const jobCard = {
    annotation: 'Join Campaigns',
    bgColor: '#f1f8ff',
    icon: clipboard,
    outlineColor: '#77b2ec',
    title: 'Job only for you',
    titleColor: '#3892e5',
    to: '/casting/your_job',
  } as Card;

  const cards = [
    ...(isCreatorAndStaff
      ? [
          {
            annotation: 'Analyze Accounts',
            bgColor: '#fff9ee',
            icon: thunder,
            title: 'Analytics',
            titleColor: '#ffb63d',
            to: `/analytics?sm=${analyticsRedirectType()}`,
          },
        ]
      : []),
    ...(isProInfluencer
      ? [
          {
            annotation: 'Chat with Fans',
            bgColor: '#f0feff',
            icon: chat,
            title: 'Chat',
            titleColor: '#00c4ce',
            to: '/chat',
          },
          {
            annotation: 'Form with Fans',
            bgColor: '#f8f7ff',
            icon: forms,
            title: 'Forms',
            titleColor: '#966acc',
            to: '/forms',
          },
        ]
      : [
          {
            annotation: 'Search Campaigns',
            bgColor: '#ecfdf4',
            icon: gift,
            title: 'Marketplace',
            titleColor: '#40b87c',
            to: '/marketplace/your_job',
          },
          {
            annotation: 'Link Social Media',
            bgColor: '#eef3f7',
            icon: setting,
            title: 'Setting',
            titleColor: '#6e7c89',
            to: '/settings/social_connect',
          },
        ]),
  ] as Card[];
  const allCards = isCampaignsExist ? [jobCard].concat(cards) : cards;

  const connectMedias = [
    { annotation: 'Connect with Social Media', icon: socialMedias, to: '/settings/social_connect' },
    { annotation: 'Connect with Shopify', icon: shopify, to: '/settings/channel_connect' },
  ];

  return (
    <div css={styles.container}>
      {isMobileView ? (
        <>
          {isCampaignsExist && (
            <JobCard
              bgColor={jobCard.bgColor}
              outlineColor={jobCard.outlineColor as string}
              titleColor={jobCard.titleColor}
              to={jobCard.to}
            >
              <div>
                <img alt="icon" height="32" src={jobCard.icon} width="32" />
              </div>
              <div>
                <div>{t(`Title.${jobCard.title}`)}</div>
                <div>{t(`Annotation.${jobCard.annotation}`)}</div>
              </div>
              <div>
                <Icon color={jobCard.titleColor} icon="chevron_right" />
              </div>
            </JobCard>
          )}
          <div css={styles.mobileCardsContainer}>
            {cards.map((card, index) => {
              const { annotation, bgColor, icon, title, titleColor, to } = card;

              return (
                <MobileCard bgColor={bgColor} key={index} titleColor={titleColor} to={to}>
                  <div>
                    <img alt="image" height="32" src={icon} width="32" />
                  </div>
                  <div>
                    <div>
                      <div>{t(`Title.${title}`)}</div>
                      <div>{t(`Annotation.${annotation}`)}</div>
                    </div>
                    <div>
                      <Icon color={titleColor} icon="chevron_right" />
                    </div>
                  </div>
                </MobileCard>
              );
            })}
          </div>
        </>
      ) : (
        <div css={styles.cardsContainer}>
          {allCards.map((card, index) => {
            const { annotation, bgColor, icon, outlineColor, title, titleColor, to } = card;

            return (
              <Card bgColor={bgColor} outlineColor={outlineColor} key={index} titleColor={titleColor} to={to}>
                <div>
                  <div>
                    <img alt="icon" height="48" src={icon} width="48" />
                  </div>
                  <div>
                    <div>
                      <div>{t(`Title.${title}`)}</div>
                      <div>
                        <div>{t(`Annotation.${annotation}`)}</div>
                        <div>
                          <Icon color={titleColor} icon="chevron_right" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            );
          })}
        </div>
      )}

      {isProInfluencer && (
        <div css={styles.connectMediasContainer}>
          {connectMedias.map((platform, index) => {
            const { annotation, icon, to } = platform;

            return (
              <ConnectMedia key={index} to={to}>
                <div>
                  <div>
                    <div>
                      <img alt="icon" height="48" src={icon} width="48" />
                    </div>
                    <div>{t(`Annotation.${annotation}`)}</div>
                    <div>
                      <Icon color="#6e7c89" icon="chevron_right" />
                    </div>
                  </div>
                </div>
              </ConnectMedia>
            );
          })}
        </div>
      )}
    </div>
  );
};

const Card = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{
  bgColor: string;
  outlineColor?: string;
  titleColor: string;
}>`
  display: grid;
  flex-basis: 25%;

  & > div {
    border-radius: 5px;
    box-shadow: 0 2px 5px #dee5ec, 0 1px 4px rgba(222, 229, 236, 0.5);
    height: 142px;
    ${({ outlineColor }) => (outlineColor ? `outline: 1px solid ${outlineColor}` : '')};

    & > div:nth-of-type(1) {
      align-items: center;
      background-color: ${({ bgColor }) => bgColor};
      display: flex;
      height: 80px;
      justify-content: center;
    }

    & > div:nth-of-type(2) {
      background-color: #fff;
      height: 62px;

      & > div {
        padding: 16px 12px;

        & > div:nth-of-type(1) {
          color: ${({ titleColor }) => titleColor};
          font-size: 12px;
          font-weight: 600;
        }

        & > div:nth-of-type(2) {
          align-items: center;
          display: flex;
          flex-wrap: wrap;

          & > div:nth-of-type(1) {
            color: #6e7c89;
            display: flex;
            flex: 1;
            font-size: 12px;
            font-weight: 600;
          }

          & > div:nth-of-type(2) {
            align-items: center;
            border-radius: 50%;
            box-shadow: 0 2px 5px #dee5ec, 0 1px 4px rgba(222, 229, 236, 0.5);
            display: flex;
            height: 16px;
            justify-content: center;
            width: 16px;

            & > i {
              font-size: 16px;
              font-weight: 600;
              margin: 0;
            }
          }
        }
      }
    }
  }
`;

const ConnectMedia = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })`
  display: flex;
  flex-basis: 50%;

  /* stylelint-disable no-descending-specificity */
  & > div {
    display: flex;
    flex: 1;

    & > div {
      align-items: center;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 2px 5px #dee5ec, 0 1px 4px rgba(222, 229, 236, 0.5);
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      height: 64px;
      padding: 8px;

      & > div:nth-of-type(1) {
        margin-right: 16px;
      }

      & > div:nth-of-type(2) {
        color: #6e7c89;
        display: flex;
        flex: 1;
        font-size: 14px;
        font-weight: 600;
      }

      & > div:nth-of-type(3) {
        align-items: center;
        border-radius: 50%;
        box-shadow: 0 2px 5px #dee5ec, 0 1px 4px rgba(222, 229, 236, 0.5);
        display: flex;
        height: 16px;
        justify-content: center;
        width: 16px;

        & > i {
          font-size: 16px;
          font-weight: 600;
          margin: 0;
        }

        @media (max-width: ${ViewportType.MEDIUM}px) {
          box-shadow: none;
        }
      }
    }
  }
`;

const MobileCard = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{
  bgColor: string;
  titleColor: string;
}>`
  background-color: #fff;
  border-bottom: 1px solid #eef3f7;
  display: flex;
  flex-wrap: wrap;

  & > div:nth-of-type(1) {
    background-color: ${({ bgColor }) => bgColor};
    padding: 16px;
  }

  & > div:nth-of-type(2) {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    padding: 16px;

    & > div:nth-of-type(1) {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      height: fit-content;

      & > div:nth-of-type(1) {
        color: ${({ titleColor }) => titleColor};
        display: flex;
        flex-basis: 100%;
        font-size: 14px;
        font-weight: 600;
      }

      & > div:nth-of-type(2) {
        color: #6e7c89;
        display: flex;
        flex-basis: 100%;
        font-size: 12px;
      }
    }
  }
`;

const JobCard = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{
  bgColor: string;
  outlineColor: string;
  titleColor: string;
}>`
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 5px;
  box-shadow: 0 2px 5px #dee5ec, 0 1px 4px rgba(222, 229, 236, 0.5);
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  outline: 1px solid ${({ outlineColor }) => outlineColor};
  padding: 16px;

  & > div:nth-of-type(1) {
    margin-right: 32px;
  }

  & > div:nth-of-type(2) {
    display: flex;
    flex: 1;
    flex-wrap: wrap;

    & > div:nth-of-type(1) {
      color: ${({ titleColor }) => titleColor};
      display: flex;
      flex-basis: 100%;
      font-size: 14px;
      font-weight: 600;
    }

    & > div:nth-of-type(2) {
      color: #6e7c89;
      display: flex;
      flex-basis: 100%;
      font-size: 12px;
    }
  }
`;

const styles = {
  cardsContainer: css`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    margin-bottom: 24px;

    & > a > div {
      margin: 0 4px;
    }

    & > a:nth-of-type(1) > div {
      margin: 0 4px 0 0;
    }

    & > a:nth-of-type(4) > div {
      margin: 0 0 0 4px;
    }
  `,
  connectMediasContainer: css`
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
    margin-bottom: 24px;

    & > a:nth-of-type(1) > div {
      margin-right: 8px;
    }

    & > a:nth-of-type(2) > div {
      margin-left: 8px;
    }
  `,
  container: css`
    width: 100%;
  `,
  mobileCardsContainer: css`
    border-radius: 5px;
    box-shadow: 0 2px 5px #dee5ec, 0 1px 4px rgba(222, 229, 236, 0.5);
    margin-bottom: 16px;

    & > a:nth-of-type(1) {
      border-radius: 5px 5px 0 0;

      & > div:nth-of-type(1) {
        border-radius: 5px 0 0 0;
      }
    }

    & > a:nth-of-type(3) {
      border-radius: 0 0 5px 5px;

      & > div:nth-of-type(1) {
        border-radius: 0 0 0 5px;
      }
    }
  `,
};

export default NavigationCards;
