import styled from '@emotion/styled';

export const NavigationButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const FanpageWrapper = styled.div<{ selected?: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: ${({ selected }) => (selected ? '14px' : '16px')};
  border-width: ${({ selected }) => (selected ? '3px' : '1px')};
  border-color: ${({ selected }) => (selected ? '#3892e5' : '#dee5ec')};
  border-style: solid;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
`;

export const FanpageSection = styled.div`
  width: 100%;
  margin-top: 24px;
  overflow: auto;

  ${FanpageWrapper} :not(:first-of-type) {
    margin-top: 16px;
  }
`;

export const Icon = styled.i<{ disabled: boolean; checked: boolean }>`
  margin-right: 16px;
  position: relative;
  z-index: 2;
  color: ${({ checked }) => (checked ? '#179cd7' : '#d5d5d5')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  font-size: 20px;
  line-height: 20px;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
`;

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex: 1;
`;

export const Title = styled.h1`
  font-size: 20px;
  color: #27313b;
`;

export const Annotation = styled.p`
  padding: 16px 0 16px;
  color: #27313b;
  text-align: center;
  line-height: 1.5;
  font-size: 12px;
`;

export const Thumbnail = styled.img`
  box-sizing: border-box;
  margin-right: 8px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;
