import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Clock from '@src/assets/icon/clockLight.svg';
import bgImg from '@src/assets/img/notificationBackground.png';
import { Button } from '@src/components/atoms/Button';
import Grid from '@src/components/atoms/Grid';
import { ListIndicator } from '@src/components/molecules/Indicator';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import { notificationIcon, notificationRedirectUrl } from './helper';
import MARK_NOTIFICATIONS_READ from './mutations/MarkAllNotificationsRead.graphql';
import CLICK_NOTIFICATION from './mutations/ViewNotification.graphql';
import { MarkAllNotificationsRead } from './mutations/__generated__/MarkAllNotificationsRead';
import { ViewNotification, ViewNotificationVariables } from './mutations/__generated__/ViewNotification';
import GET_NOTIFICATIONS from './queries/Notifications.graphql';
import { Notifications, NotificationsVariables } from './queries/__generated__/Notifications';

const Notification = () => {
  const { enqueueSnackbar, t } = useQueryHelper();
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });

  const { data, loading, fetchMore } = useQuery<Notifications, NotificationsVariables>(GET_NOTIFICATIONS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {},
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [markNotificationsRead] = useMutation<MarkAllNotificationsRead>(MARK_NOTIFICATIONS_READ, {
    refetchQueries: ['GetUnreadNotificationCount'],
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [clickNotification] = useMutation<ViewNotification, ViewNotificationVariables>(CLICK_NOTIFICATION, {
    refetchQueries: ['Notifications'],
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  useEffect(() => {
    markNotificationsRead();
  }, []);

  const notifications = data?.getNotifications?.notifications || [];
  const hasMore = data?.getNotifications?.hasMore || false;
  const checkpoint = data?.getNotifications?.checkpoint || 0;

  function onClickNotification(id: string) {
    clickNotification({
      variables: {
        input: { id },
      },
    });
  }

  function onClickViewMore() {
    fetchMore({
      variables: {
        input: { checkpoint },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return Object.assign({
          getNotifications: {
            ...fetchMoreResult.getNotifications,
            notifications: [
              ...(prev.getNotifications?.notifications || []),
              ...(fetchMoreResult.getNotifications?.notifications || []),
            ],
          },
        });
      },
    });
  }

  return (
    <div css={styles.container}>
      <div>
        <Grid xs={12}>
          {isDesktopView && (
            <div css={styles.title}>
              <BackNavigator title="Notification" />
            </div>
          )}

          {loading ? (
            <ListIndicator />
          ) : notifications.length > 0 ? (
            <>
              <div css={styles.notificationsContainer}>
                {notifications.map(notification => {
                  const { content, created, id, isClicked, notificationId } = notification;
                  const campaignId = content?.campaign_id || '';
                  const campaignName = content?.campaign_name;
                  const message = t(notificationId.toLowerCase(), {
                    ...(campaignName ? { campaign_name: campaignName } : {}),
                  });

                  return (
                    <NotificationContainer
                      isClicked={isClicked}
                      key={notification.id}
                      to={notificationRedirectUrl(campaignId, notificationId)}
                      onClick={() => (!isClicked ? onClickNotification(id) : null)}
                    >
                      <div>
                        <div css={styles.notificationIcon}>
                          <img alt="campaignIcon" height="30" src={notificationIcon(notificationId)} width="30" />
                        </div>
                      </div>
                      <div>
                        <div css={styles.notificationMsg}>
                          <span>{message}</span>
                        </div>
                        <div css={styles.notificationTime}>
                          <img alt="clock" src={Clock} />
                          <span>{formatDistanceToNow(new Date(created), { addSuffix: true })}</span>
                        </div>
                      </div>
                    </NotificationContainer>
                  );
                })}
              </div>

              {hasMore && (
                <div css={styles.actionContainer}>
                  <Grid sm={6} xs={12}>
                    <Button
                      bgcolor="#fff"
                      css={styles.viewMoreBtn}
                      disabled={loading}
                      hoverbgcolor="#f6f8fa"
                      title="View More"
                      onClick={onClickViewMore}
                    />
                  </Grid>
                </div>
              )}
            </>
          ) : (
            <div css={styles.emptyNotificationContainer}>
              <div>
                <div>
                  <img alt="bgImg" height="137" src={bgImg} width="128" />
                </div>
                <div>{t('Annotation.Empty Notification')}</div>
              </div>
            </div>
          )}
        </Grid>
      </div>
    </div>
  );
};

const NotificationContainer = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{ isClicked?: boolean }>`
  background-color: ${({ isClicked }) => (isClicked ? '#d8ecff' : '#fff')};
  border: 1px solid #eef3f7;
  border-bottom: ${({ isClicked }) => (isClicked ? '1px solid #fff' : '1px solid #eef3f7')};
  display: flex;
  flex-wrap: wrap;
  padding: 8px 16px;

  &:hover {
    background-color: ${({ isClicked }) => (isClicked ? '#edf5fa' : '#f6f8fa')};
  }

  & > div:nth-of-type(1) {
    align-items: center;
    display: flex;
    padding: 0 16px;
  }

  & > div:nth-of-type(2) {
    width: 84%;

    @media (max-width: ${ViewportType.SMALL}px) {
      width: 72%;
    }
  }
`;

const styles = {
  actionContainer: css`
    display: flex;
    justify-content: center;
    margin-top: 16px;
  `,
  container: css`
    padding: 16px;
  `,
  emptyNotificationContainer: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 70vh;

    /* stylelint-disable no-descending-specificity */
    & > div {
      display: flex;
      flex-wrap: wrap;

      & > div {
        display: flex;
        flex-basis: 100%;
        justify-content: center;
        margin-bottom: 8px;
      }

      & > div:nth-of-type(2) {
        color: #6e7c89;
        font-size: 14px;
        text-align: center;
        width: 110px;
      }
    }
  `,
  notificationIcon: css`
    align-items: center;
    background-color: #fff;
    border-radius: 12px;
    display: flex;
    height: 45px;
    justify-content: center;
    width: 45px;
  `,
  notificationMsg: css`
    display: grid;
    width: 100%;

    & > span {
      color: #27313b;
    }
  `,
  notificationTime: css`
    align-items: center;
    color: #7b8a98;
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;

    & > img {
      margin-right: 8px;
    }
  `,
  notificationsContainer: css`
    & > div:not(:first-of-type) {
      border-top: none;
    }

    & > div:nth-of-type(1) {
      border-radius: 10px 10px 0 0;
    }

    & > div:last-child {
      border-radius: 0 0 10px 10px;
    }
  `,
  title: css`
    margin-bottom: 16px;
  `,
  viewMoreBtn: css`
    border: 1px solid #dee5ec;
    border-radius: 5px;
    color: #6e7c89;
    height: 40px;
  `,
};

export default Notification;
