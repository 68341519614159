import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../atoms/Button';
import { SocialAccountWithAvatar } from '../../molecules/SocialAccount';
import PageLoading from '../../molecules/PageLoading';
import { SignUpAndSignInBreakpoint } from '../../../libs/theme';
import * as ConnectComponent from './BaseComponents';
import { ConnectableInstagramAccounts } from './__generated__/ConnectableInstagramAccounts';

interface IgAccountsProps {
  loading: boolean;
  connectingIG: boolean;
  igAccountsData?: ConnectableInstagramAccounts;
  clickNext: () => void;
  selectedIds: string[];
  toggleSelectedIds: (id: string, isSelected: boolean) => void;
  clickPrev: () => void;
  hasActionButtons: boolean;
}
export const ChooseInstagramAccount = (props: IgAccountsProps) => {
  const {
    clickPrev,
    loading,
    connectingIG,
    igAccountsData,
    clickNext,
    selectedIds,
    toggleSelectedIds,
    hasActionButtons,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    // redirect to connect page in case of no accounts
    if (!loading && !igAccountsData?.connectableInstagramAccounts?.igAccounts.length) {
      clickNext();
    }
  }, [igAccountsData, loading]);

  const selectedIgAccounts = igAccountsData?.connectableInstagramAccounts?.igAccounts || [];

  return (
    <ConnectComponent.Container>
      {loading || connectingIG ? (
        <PageLoading />
      ) : (
        <>
          <ConnectComponent.Title>{t('General.Choose an account')}</ConnectComponent.Title>
          <ConnectComponent.Annotation>{t('General.Select Instagram Account')}</ConnectComponent.Annotation>

          <ConnectComponent.FanpageSection>
            {selectedIgAccounts.map(({ id, name, followersCount, avatar }) => {
              const isChecked = selectedIds.includes(id);
              const icon = isChecked ? 'check_box' : 'check_box_outline_blank';

              return (
                <ConnectComponent.FanpageWrapper
                  key={id}
                  selected={isChecked}
                  onClick={() => {
                    toggleSelectedIds(id, isChecked);
                  }}
                >
                  <ConnectComponent.Icon
                    className="material-icons"
                    checked={isChecked}
                    disabled={false}
                    onClick={() => {
                      toggleSelectedIds(id, isChecked);
                    }}
                  >
                    {icon}
                  </ConnectComponent.Icon>

                  <ConnectComponent.Thumbnail src={avatar || ''} />
                  <SocialAccountWithAvatar followers={followersCount} username={name} />
                </ConnectComponent.FanpageWrapper>
              );
            })}
          </ConnectComponent.FanpageSection>
          {hasActionButtons && (
            <NavigationButtons>
              <PreviousButton title="Previous" onClick={clickPrev} icon="arrow_back" />

              <NextButton title="Next" onClick={clickNext} icon="arrow_forward" disabled={!selectedIds.length} />
            </NavigationButtons>
          )}
        </>
      )}
    </ConnectComponent.Container>
  );
};

const NavigationButtons = styled(ConnectComponent.NavigationButtons)`
  margin: 32px 0 28px;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    margin-bottom: 0;
  }
`;

const NextButton = styled(Button)`
  position: relative;
  flex-direction: row-reverse;
  background-color: #ff2b52;
  text-transform: uppercase;
  border-radius: 24px;
  line-height: 40px;
  margin-left: 8px;

  &:hover {
    background-color: #ff5575;
  }

  &:active {
    background-color: #cc2241;
  }

  i {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const PreviousButton = styled(NextButton)`
  color: #6e7c89;
  border: 1px solid #dee5ec;
  background-color: #fff;
  margin-right: 8px;
  margin-left: 0;

  &:hover {
    border-color: #c5d0da;
    background-color: transparent;
  }

  &:active {
    border-color: #c5d0da;
    background-color: #f6f8fa;
  }

  i {
    position: absolute;
    left: 16px;
    right: auto;
    top: 50%;
    transform: translateY(-50%);
  }
`;
