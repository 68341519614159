import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

interface EmptyVideosProps {
  className?: string;
}
export const EmptyVideos = ({ className }: EmptyVideosProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.container} className={className}>
      {t('No videos for selected account were found')}
    </div>
  );
};

const styles = {
  container: css`
    padding: 16px 24px;
    background-color: #fff;
    margin: 0 16px;
    box-shadow: 0 0 1px #dee5ec, 0 1px 2px #dee5ec;
    text-align: center;
  `,
};
