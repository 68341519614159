import styled from '@emotion/styled';
import TextCutter from '@src/components/atoms/TextCutter';
import { ViewportBreakpoint } from '@src/libs/theme';

export const BrandCardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(142px, 1fr));
  gap: 8px;
  padding: 0 16px;

  @media (min-width: ${ViewportBreakpoint.MOBILE}px) {
    grid-template-columns: repeat(auto-fill, minmax(126px, 1fr));
    gap: 6px;
  }
`;
export const BrandCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 181px;
  padding: 16px;
  background: #fff;
  border: 1px solid #eef3f7;
  box-sizing: border-box;
  border-radius: 5px;
`;
export const BrandImage = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;
`;
export const BrandName = styled(TextCutter)`
  width: 100%;
  margin-top: 16px;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #27313b;
`;
export const Mention = styled.span`
  margin-top: 16px;
  font-size: 13px;
  line-height: 100%;
  cursor: pointer;

  &:hover {
    color: #3892e5;
  }
`;
export const ER = styled(TextCutter)`
  font-size: 13px;
  line-height: 16px;
  margin-top: 8px;
`;
