import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import css from '@emotion/css';
import SocialAccount from '../../../molecules/SocialAccount';
import { SocialAccountType } from '../../../../__generated__/globalTypes';
import * as ConnectComponent from '../../SignUpConnectForm/BaseComponents';
import { Button, PalleteButton } from '../../../atoms/Button';
import { ListIndicator } from '../../../molecules/Indicator';
import { DialogModal, DialogTitle, DialogContent, WarningIcon } from '../../../molecules/DialogModal';
import { mainWhite, mainRed } from '../../../../libs/pallete';
import { getIconByName } from '../../../../libs/getIconByName';
import { FBPageAndIGAccountInfoType } from './types';

interface ChooseFBPageAndIGAccountFormProps {
  accounts: FBPageAndIGAccountInfoType[];
  loading: boolean;
  error?: string;
  selectedIGAccountIds: string[];
  toggleSelectedIGAccountIds: (id: string, isChecked: boolean) => void;
  close: () => void;
  handleConnect: () => void;
  isProfileConnectPage?: boolean;
  isOpening?: boolean;
}

const ChooseFBPageAndIGAccountForm = (props: ChooseFBPageAndIGAccountFormProps) => {
  const {
    accounts,
    loading,
    error,
    selectedIGAccountIds,
    toggleSelectedIGAccountIds,
    close,
    handleConnect,
    isProfileConnectPage,
    isOpening,
  } = props;
  const { t } = useTranslation();

  if (loading) {
    return <ListIndicator border={false} />;
  }

  const ConnectContainer = isProfileConnectPage ? DialogModal : ConnectComponent.Container;

  return (
    <>
      <DialogModal
        isOpen={!!error}
        closeModal={close}
        NextButton={<PalleteButton text="OK" palleteColor={mainWhite} onClick={close} />}
      >
        <DialogTitle>
          <WarningIcon src={getIconByName('warning')} alt="warning icon" />
          {t('Connect Instagram account warning')}
        </DialogTitle>
        <DialogContent>{!!error && <ErrorMessage>{t(error)}</ErrorMessage>}</DialogContent>
      </DialogModal>

      <ConnectContainer
        isOpen={!!isOpening && !error}
        closeModal={close}
        NextButton={
          <PalleteButton
            text="Connect"
            palleteColor={mainRed}
            onClick={handleConnect}
            isDisabled={!selectedIGAccountIds.length}
          />
        }
        css={dialogModalCss}
      >
        <ConnectComponent.Title>{t('General.Choose Instagram account')}</ConnectComponent.Title>
        <ConnectComponent.Annotation>{t('General.Select Instagram Account')}</ConnectComponent.Annotation>
        {accounts.map(({ page, igAccount }) => {
          const isChecked = selectedIGAccountIds.includes(igAccount.id);
          const icon = isChecked ? 'check_box' : 'check_box_outline_blank';

          return (
            <AccountWrapper key={igAccount.id}>
              <SocialAccount
                socialMedia={SocialAccountType.FACEBOOK}
                src={page.avatar}
                username={page.name}
                followers={page.followersCount}
              />
              <InstagramWrapper
                selected={isChecked}
                onClick={() => {
                  toggleSelectedIGAccountIds(igAccount.id, isChecked);
                }}
              >
                <ConnectComponent.Icon
                  className="material-icons"
                  checked={isChecked}
                  disabled={false}
                  onClick={() => {
                    toggleSelectedIGAccountIds(igAccount.id, isChecked);
                  }}
                >
                  {icon}
                </ConnectComponent.Icon>

                <SocialAccount
                  socialMedia={SocialAccountType.INSTAGRAM}
                  src={igAccount.avatar}
                  username={igAccount.name}
                  followers={igAccount.followersCount}
                />
              </InstagramWrapper>
            </AccountWrapper>
          );
        })}
        {!isProfileConnectPage && (
          <ConnectComponent.NavigationButtons>
            <ConnectButton title="Connect" onClick={handleConnect} disabled={!selectedIGAccountIds.length} />
          </ConnectComponent.NavigationButtons>
        )}
      </ConnectContainer>
    </>
  );
};

const AccountWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
`;

const ErrorMessage = styled.p`
  font-size: 18px;
`;

const InstagramWrapper = styled(ConnectComponent.FanpageWrapper)`
  margin-top: 8px;
`;

const ModalButton = styled(Button)`
  margin: 16px 0;
  position: relative;
  flex-direction: row-reverse;
  text-transform: uppercase;
  border-radius: 24px;
  line-height: 40px;

  i {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const ConnectButton = styled(ModalButton)`
  background-color: #ff2b52;

  &:hover {
    background-color: #ff5575;
  }

  &:active {
    background-color: #cc2241;
  }
`;

const dialogModalCss = css`
  padding: 16px;
`;

export default ChooseFBPageAndIGAccountForm;
