import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import SignUpConnectForm, {
  ChooseFanpageForm,
  ChooseInstagramAccount,
  SignUpConnectFormProps,
} from '../../components/organisms/SignUpConnectForm';
import { getInitialFanpageIds, setUrlParams } from '../../components/organisms/SignUpConnectForm/helpers';
import Header from '../../components/organisms/TopPage/Header';
import { useUpdateDocumentTitle } from '../../libs/hooks';
import { SignUpAndSignInBreakpoint } from '../../libs/theme';
import { ConnectType } from '../../libs/auth';
import ChooseFBPageAndIGAccountForm from '../../components/organisms/Profile/ChooseFBPageAndIGAccountForm';
import Footer from '../../components/organisms/Footer';
import { useChooseConnectInstagramAccount } from '../../components/organisms/SignUpConnectForm/useChooseConnectInstagramAccount';
import { ConnectableFacebookPages_connectableFacebookPages_pages } from './__generated__/ConnectableFacebookPages';

export interface TemplateProps extends SignUpConnectFormProps {
  connectType?: ConnectType;
  fanPages: ConnectableFacebookPages_connectableFacebookPages_pages[];
}

const Template = (props: TemplateProps) => {
  const title = 'pageTitle.Connect Social Accounts';
  useUpdateDocumentTitle({ title });
  const history = useHistory();

  const {
    facebook,
    instagramAccounts,
    twitterAccounts,
    youtubeAccounts,
    tiktokAccounts,
    connectType,
    fanPages,
  } = props;

  const initialStep =
    connectType === ConnectType.CONNECT_FB_PAGE ? '1' : connectType === ConnectType.CONNECT_IG_ACCOUNT ? 'IG' : null;
  const [currentStep, setCurrentStep] = useState<string | null>(initialStep);
  const [selectedFanPageIds, setSelectedFanPageIds] = useState<string[]>(getInitialFanpageIds(fanPages));

  const handleSetConnectStep = (connectStep: string) => {
    setUrlParams(history, connectStep);
  };

  const clearStep = () => {
    setCurrentStep(null);
    handleSetConnectStep('');
    history.push('/signup/connect');
  };

  const {
    loading,
    connectingIG,
    igAccountsData,
    handleClickNext,
    selectedIds,
    toggleSelectedIds,
  } = useChooseConnectInstagramAccount({
    selectedFanPageIds,
    clickNext: clearStep,
    clickPrev: () => setCurrentStep('1'),
    refetchQueries: ['CheckConnectedSocialAccountsForSignUp'],
  });

  return (
    <Container>
      <Contents>
        <Header />
        <Wrapper>
          {currentStep === '1' && (
            <ChooseFanpageForm
              fanPages={fanPages}
              facebook={facebook}
              selectedFanPageIds={selectedFanPageIds}
              setSelectedFanPageIds={setSelectedFanPageIds}
              clickNext={() => setCurrentStep('2')}
              loading={false}
            />
          )}
          {currentStep === '2' && (
            <ChooseInstagramAccount
              clickPrev={() => setCurrentStep('1')}
              loading={loading}
              connectingIG={connectingIG}
              igAccountsData={igAccountsData}
              clickNext={handleClickNext}
              selectedIds={selectedIds}
              toggleSelectedIds={toggleSelectedIds}
              hasActionButtons
            />
          )}
          {currentStep === 'IG' && (
            <ChooseFBPageAndIGAccountForm
              close={clearStep}
              refetchQueries={['CheckConnectedSocialAccountsForSignUp']}
            />
          )}
          {!currentStep && (
            <SignUpConnectForm
              facebook={facebook}
              instagramAccounts={instagramAccounts}
              twitterAccounts={twitterAccounts}
              youtubeAccounts={youtubeAccounts}
              tiktokAccounts={tiktokAccounts}
            />
          )}
          <Footer />
        </Wrapper>
      </Contents>
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  background-color: #27313b;
  display: flex;
  justify-content: center;
`;

const Contents = styled.div`
  width: 856px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    width: 100%;
    padding-top: 0;
  }
`;

const Wrapper = styled.section`
  background-color: #fff;
  padding: 32px 48px 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export default Template;
