import styled from '@emotion/styled';
import React from 'react';
import Label from '../../atoms/Label';

interface SwitchProps {
  isChecked: boolean;
  handleChange: () => void;
  title?: string;
  help?: string;
  disabled?: boolean;
  className?: string;
}

const SwitchToggle = (props: SwitchProps) => {
  const { isChecked, handleChange, title, help, disabled, className } = props;

  return (
    <div className={className}>
      {!!title && <Label title={title} help={help} iconMargin="10px" />}
      <SwitchWrapper onClick={handleChange} isChecked={isChecked} disabled={!!disabled}>
        <input type="checkbox" checked={isChecked} onChange={handleChange} />
        <SwitchLabel isChecked={isChecked}>{isChecked ? 'ON' : 'OFF'}</SwitchLabel>
      </SwitchWrapper>
    </div>
  );
};

const SwitchWrapper = styled.div<{ isChecked: boolean; disabled?: boolean }>`
  position: relative;
  display: inline-block;
  width: 58px;
  height: 24px;
  background-color: ${({ isChecked }) => (isChecked ? '#3892e5' : '#dee5ec')};
  border-radius: 24px;
  transition: all 0.1s;
  line-height: 26px;
  cursor: pointer;
  ${({ disabled }) => disabled && 'pointer-events: none;'}

  &::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: white;
    top: 13%;
    left: ${({ isChecked }) => (isChecked ? '37px' : '3px')};
    transition: all 0.3s;
  }
`;

const SwitchLabel = styled.div<{ isChecked: boolean }>`
  position: absolute;
  right: 8px;
  transform: ${({ isChecked }) => (isChecked ? 'translateX(-22px)' : 'none')};
  color: #fff;
  font-size: 12px;
  font-weight: 600;
`;

export default SwitchToggle;
