import styled from '@emotion/styled';
import * as React from 'react';

export interface HashTagProps {
  hashTag: string;
}

const HashTag = (props: HashTagProps) => {
  const { hashTag } = props;

  return <Paragraph>#{hashTag}</Paragraph>;
};

const Paragraph = styled.p`
  margin: 0 9px 9px 0;
  padding: 12px 20px;
  background-color: #d7e9fa;
  border-radius: 3px;
  color: #3892e5;
  font-size: 13px;
  letter-spacing: 0.025em;
`;

export default HashTag;
