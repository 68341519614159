import styled from '@emotion/styled';
import * as React from 'react';
import HashTag from '../../atoms/HashTag';

export interface HashTagsProps {
  hashTags?: string[];
  className?: string;
}

const HashTags: React.FC<HashTagsProps> = props => {
  const { hashTags, className } = props;

  return (
    <HashTagWrapper className={className}>
      {!!hashTags && hashTags.map(hashTag => <HashTag key={hashTag} hashTag={hashTag} />)}
    </HashTagWrapper>
  );
};

const HashTagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default HashTags;
