import { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Redirect } from 'react-router';
import React from 'react';
import { Genders } from '../../__generated__/globalTypes';
import { ListIndicator } from '../../components/molecules/Indicator';
import { AdminStore } from '../../Context';
import { InfluencerDetail, InfluencerDetailVariables } from './__generated__/InfluencerDetail';
import * as INFLUENCER_DETAIL from './InfluencerDetail.graphql';
import Template from './template';

const EditProfileComponent = () => {
  const {
    state: { userId },
  } = useContext(AdminStore);

  if (!userId) {
    return <Redirect to="/" />;
  }

  const { data, loading } = useQuery<InfluencerDetail, InfluencerDetailVariables>(INFLUENCER_DETAIL, {
    variables: { pk: userId },
    fetchPolicy: 'cache-and-network',
  });

  let baseResult = {
    userId,
    name: '',
    email: '',
    phoneNumber: '',
    categories: [] as string[],
    gender: Genders.FEMALE,
    country: '',
    regionId: '',
    birthday: '',
    introduce: '',
    notificationSetting: true,
  };

  if (loading) {
    return <ListIndicator />;
  }

  if (data && data.influencerDetail) {
    baseResult = {
      ...baseResult,
      name: data.influencerDetail.name,
      email: data.influencerDetail.email || '',
      phoneNumber: data.influencerDetail.phoneNumber || '',
      categories: data.influencerDetail.categories
        .map(category => (category ? String(category.id) : null))
        .filter((category): category is string => !!category),
      gender: data.influencerDetail.gender,
      country: data.influencerDetail.country.id,
      regionId: data.influencerDetail.region ? String(data.influencerDetail.region.id) : '',
      birthday: data.influencerDetail.dateOfBirth,
      introduce: data.influencerDetail.introduce || '',
      notificationSetting: data.influencerDetail.notificationSetting,
    };
  }

  return <Template {...baseResult} />;
};
export default EditProfileComponent;
