import { useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { getOptions } from '../../../../libs/form';
import { SubmitButton } from '../../../atoms/Button';
import ErrorMessage from '../../../atoms/ErrorMessage';
import SentenceForm from '../../../molecules/SentenceForm';
import SingleSelectField from '../../../molecules/SingleSelectField';
import TextForm from '../../../molecules/TextForm';
import * as StyledComponents from '../StyledComponents';
import * as GET_BANK_BRANCHES from './GetBankBranches.graphql';
import { FormValues, Option, Options } from './types';

export interface PaymentInformationFormProps extends Options {
  className?: string;
  isMenaInfluencer: boolean;
}

const PaymentInformationForm = (props: PaymentInformationFormProps) => {
  const [allBranches, setAllBranches] = useState<Option[]>([]);
  const client = useApolloClient();
  const { t } = useTranslation();
  const { className, allBanks, allCities, isMenaInfluencer } = props;
  const { isSubmitting, handleSubmit, values, setFieldValue } = useFormikContext<FormValues>();

  const getBankBranches = async (bankId: string) => {
    const { data } = await client.query({
      query: GET_BANK_BRANCHES,
      variables: { bankId: Number(bankId) },
    });

    setAllBranches(getOptions(data.allBankBranchesForInfluencer));
  };

  useEffect(() => {
    if (!!values.bankId && !isMenaInfluencer) {
      getBankBranches(values.bankId);
    }
  }, [values.bankId || values.branchId]);

  // Fields
  const [accountNameField, accountNameMeta] = useField('accountName');
  const [emailField, emailMeta] = useField('email');
  const [bankIdField, bankIdMeta] = useField('bankId');
  const [branchIdField, branchIdMeta] = useField('branchId');
  const [accountNumberField, accountNumberMeta] = useField('accountNumber');
  const [addressField, addressMeta] = useField('address');
  const [cityIdField, cityIdMeta] = useField('cityId');
  const [postalCodeField, postalCodeMeta] = useField('postalCode');
  const [ibanField, ibanMeta] = useField('iban');
  const [swiftCodeField, swiftCodeMeta] = useField('swiftCode');
  const [branchNameField, branchNameMeta] = useField('branchName');

  return (
    <>
      <form className={className} onSubmit={handleSubmit}>
        <StyledComponents.ResponsiveWrapper>
          <StyledComponents.FormContainer>
            <StyledComponents.Wrapper>
              <TextForm
                title="Account Holder Name"
                isRequired={true}
                error={!!accountNameMeta.error}
                help="Exactly as appears on your bank statement"
                placeholder="Jane Doe"
                {...accountNameField}
              />
              {accountNameMeta.error && <ErrorMessage message={t(accountNameMeta.error)} />}
            </StyledComponents.Wrapper>

            <StyledComponents.Wrapper>
              <TextForm
                title="Payment Email Address"
                isRequired={true}
                error={!!emailMeta.error}
                placeholder="your@email.com"
                {...emailField}
              />
              {emailMeta.error && <ErrorMessage message={t(emailMeta.error)} />}
            </StyledComponents.Wrapper>

            <StyledComponents.Wrapper>
              <SingleSelectField
                name={bankIdField.name}
                value={bankIdField.value}
                title="Bank Name"
                isRequired={true}
                error={!!bankIdMeta.error}
                options={allBanks}
                setFieldValue={setFieldValue}
              />
              {bankIdMeta.error && <ErrorMessage message={t(bankIdMeta.error)} />}
            </StyledComponents.Wrapper>

            {isMenaInfluencer ? (
              <StyledComponents.Wrapper>
                <TextForm
                  title="Branch Name"
                  isRequired={true}
                  error={!!branchNameMeta.error}
                  placeholder="Head Office"
                  {...branchNameField}
                />
                {branchNameMeta.error && <ErrorMessage message={t(branchNameMeta.error)} />}
              </StyledComponents.Wrapper>
            ) : (
              !!(allBranches.length || values.branchId) && (
                <StyledComponents.Wrapper>
                  <SingleSelectField
                    name={branchIdField.name}
                    value={branchIdField.value}
                    title="Branch Name"
                    isRequired={true}
                    error={!!branchIdMeta.error}
                    options={allBranches}
                    setFieldValue={setFieldValue}
                  />
                  {branchIdMeta.error && <ErrorMessage message={t(branchIdMeta.error)} />}
                </StyledComponents.Wrapper>
              )
            )}

            <StyledComponents.Wrapper>
              <TextForm
                title="Account Number"
                isRequired={true}
                error={!!accountNumberMeta.error}
                placeholder="12345678"
                {...accountNumberField}
              />
              {accountNumberMeta.error && <ErrorMessage message={t(accountNumberMeta.error)} />}
            </StyledComponents.Wrapper>

            {isMenaInfluencer ? (
              <>
                <StyledComponents.Wrapper>
                  <TextForm
                    title="Swift Code"
                    isRequired={true}
                    error={!!swiftCodeMeta.error}
                    placeholder="AAAA-BB-CC-123"
                    {...swiftCodeField}
                  />
                  {swiftCodeMeta.error && <ErrorMessage message={t(swiftCodeMeta.error)} />}
                </StyledComponents.Wrapper>

                <StyledComponents.Wrapper>
                  <TextForm
                    title="IBAN"
                    isRequired={true}
                    error={!!ibanMeta.error}
                    placeholder="XX7422001007941234567812321"
                    {...ibanField}
                  />
                  {ibanMeta.error && <ErrorMessage message={t(ibanMeta.error)} />}
                </StyledComponents.Wrapper>
              </>
            ) : (
              <>
                <StyledComponents.Wrapper>
                  <SentenceForm
                    title="Address"
                    isRequired={true}
                    error={!!addressMeta.error}
                    placeholder="Address Example"
                    {...addressField}
                  />
                  {addressMeta.error && <ErrorMessage message={t(addressMeta.error)} />}
                </StyledComponents.Wrapper>

                <StyledComponents.Wrapper>
                  <SingleSelectField
                    name={cityIdField.name}
                    value={cityIdField.value}
                    title="City"
                    isRequired={true}
                    error={!!cityIdMeta.error}
                    options={allCities || []}
                    setFieldValue={setFieldValue}
                  />
                  {cityIdMeta.error && <ErrorMessage message={t(cityIdMeta.error)} />}
                </StyledComponents.Wrapper>

                <StyledComponents.Wrapper>
                  <TextForm
                    title="Postal Code"
                    isRequired={true}
                    error={!!postalCodeMeta.error}
                    placeholder="068914"
                    {...postalCodeField}
                  />
                  {postalCodeMeta.error && <ErrorMessage message={t(postalCodeMeta.error)} />}
                </StyledComponents.Wrapper>
              </>
            )}

            <StyledComponents.SubmitWrapper>
              <SubmitButton value="Update Payment Information" isSubmitting={isSubmitting} />
            </StyledComponents.SubmitWrapper>
          </StyledComponents.FormContainer>
        </StyledComponents.ResponsiveWrapper>
      </form>
    </>
  );
};

export default PaymentInformationForm;
