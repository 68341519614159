import styled from '@emotion/styled';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { ViewportType } from '@src/libs/theme';
import { useUpdateDocumentTitle } from '@src/libs/hooks';
import { InstagramFeedPostSortField } from '@src/__generated__/globalTypes';
import { useSort } from '@src/libs/filter';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import PostFeedFilter from '@src/components/organisms/PostFeedList/PostFeedFilters';
import { PostFeedProps } from '@src/components/organisms/PostFeedList/utils';
import PostFeedList from '@src/components/organisms/AnalyticsInstagram/Interaction/InteractionFeedPostsList';
import { getInstagramFeedPostSortFieldType } from '@src/components/organisms/PostFeedList/PostFeedList/helpers';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState, dynamicTitleState } from '@src/recoilAtoms';
import Pager from '@src/components/molecules/Pager';
import { PageInfo } from '@src/libs/paging';

export interface TemplateProps extends PostFeedProps {
  accountName?: string;
}

const Template = (props: TemplateProps) => {
  const setDynamicTitleState = useSetRecoilState(dynamicTitleState);
  const { currentPage, filter, setFilter, accountId, accountName } = props;
  const isDesktopView = useMediaQuery({ minWidth: ViewportType.TABLET });
  const { pathname, search } = useLocation();
  const interpolation = accountName ? { title: accountName } : undefined;
  useUpdateDocumentTitle({ title: 'pageTitle.TaggedPostsListWithAccountName', interpolation });
  setDynamicTitleState({ title: 'pageTitle.TaggedPostsListWithAccountName', interpolation });
  const [pageInfo, setPageInfo] = React.useState<PageInfo>({
    totalPages: 0,
    totalCount: 0,
    firstIndex: 0,
    lastIndex: 0,
    currentPage,
  });

  const sortData = useSort<InstagramFeedPostSortField>(getInstagramFeedPostSortFieldType);
  const isTaggedPosts = pathname.includes('tagged_posts');

  return (
    <>
      <Wrapper>
        {isDesktopView && (
          <div css={{ marginBottom: '16px' }}>
            <BackNavigator
              to={`/analytics${search}`}
              title={isTaggedPosts ? 'TaggedPostsListWithAccountName' : 'MentionedPostsListWithAccountName'}
              interpolation={interpolation}
            />
          </div>
        )}
        <Card>
          <PostFeedFilter filter={filter} setFilter={setFilter} />
          <PostFeedList
            accountId={accountId}
            filter={filter}
            currentPage={currentPage}
            sortData={sortData}
            isTaggedPosts={isTaggedPosts}
            setPageInfo={setPageInfo}
          />
        </Card>
        <Pager
          currentPage={currentPage}
          totalPages={pageInfo.totalPages}
          totalCount={pageInfo.totalCount}
          first={pageInfo.firstIndex}
          last={pageInfo.lastIndex}
        />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
  width: calc(100% - 32px);
`;

const Card = styled.div`
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5);
  background-color: #fff;
`;

export default Template;
