import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { ViewportBreakpoint } from '../../../libs/theme';
import { useGlobalLayoutProps } from '../../../libs/hooks';

const BottomOverflowSection: React.FC = props => {
  const { updateGlobalLayout } = useGlobalLayoutProps();

  if (!props.children) {
    return null;
  }

  useEffect(() => {
    updateGlobalLayout({ hasBottomSection: true });

    return () => {
      updateGlobalLayout({ hasBottomSection: false });
    };
  }, []);

  return (
    <BottomOverflowButtons>
      <ButtonWrapper>{props.children}</ButtonWrapper>
    </BottomOverflowButtons>
  );
};

const BottomOverflowButtons = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -2px 11px 0 #0003;
  background-color: #fff;
  z-index: 5;

  @media (min-width: ${ViewportBreakpoint.MEDIUM}px) {
    display: none;
  }
`;
const ButtonWrapper = styled.div`
  width: 100%;
  margin: 8px 16px;
`;

export default BottomOverflowSection;
