import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { getThirtyDaysAgo, getToday } from '@src/libs/date';
import { useUpdateDocumentTitle } from '@src/libs/hooks';
import INFLUENCER_COMPARE_ACCOUNTS_CARDS from '@src/components/organisms/AnalyticsYoutube/Compare/InfluencerYoutubeAnalyticsCompareAccountCards.graphql';
import { useQueryHelper } from '@src/libs/hooks';
import {
  InfluencerYoutubeAnalyticsCompareAccountCards,
  InfluencerYoutubeAnalyticsCompareAccountCardsVariables,
} from '@src/components/organisms/AnalyticsYoutube/Compare/__generated__/InfluencerYoutubeAnalyticsCompareAccountCards';
import NavigationHeader from '@src/components/organisms/Header/NavigationHeader';
import { useMediaQuery } from '@material-ui/core';
import { ViewportBreakpoint } from '@src/libs/theme';
import styled from '@emotion/styled';
import BasePostFilter from '@src/components/organisms/AnalyticsShared/BasePostListFilter';
import { useFilter } from '@src/components/organisms/AnalyticsShared/BasePostListFilter/useFilter';
import TableVideoList from './TableVideoList';
import { TabsAccounts } from './TabsAccounts';
import { CompareAccountType } from './types';

interface Props {
  socialAccountId: string;
}
const InfluencerYoutubeCompareFeedVideos = ({ socialAccountId }: Props) => {
  const { enqueueSnackbar, t, search } = useQueryHelper();
  const { filter, setFilter } = useFilter({ startDate: getThirtyDaysAgo(), endDate: getToday() });
  const { setDocumentAndAppTitles } = useUpdateDocumentTitle({
    title: 'documentTitle.Post List',
    pageTitle: 'Post List',
    href: `/profile:compare${search}`,
  });

  const [allCompareAccounts, setAllCompareAccounts] = useState<CompareAccountType[]>([]);

  useQuery<InfluencerYoutubeAnalyticsCompareAccountCards, InfluencerYoutubeAnalyticsCompareAccountCardsVariables>(
    INFLUENCER_COMPARE_ACCOUNTS_CARDS,
    {
      skip: !socialAccountId,
      variables: {
        socialAccountId: Number(socialAccountId),
      },
      onCompleted: ({ influencerYoutubeAnalyticsCompareAccountCards }) => {
        let mainAccount: CompareAccountType[] = [];
        let compareAccounts: CompareAccountType[] = [];
        if (influencerYoutubeAnalyticsCompareAccountCards.mainAccount) {
          const {
            accountId,
            avatarUrl,
            channelName,
            elementId,
            profileUrl,
          } = influencerYoutubeAnalyticsCompareAccountCards?.mainAccount;
          mainAccount = [
            {
              accountId,
              avatarUrl,
              channelName,
              elementId,
              isMain: true,
              isSelected: true,
              profileUrl,
            },
          ];

          setDocumentAndAppTitles({
            title: 'PostListWithAccountName',
            interpolation: {
              title: channelName,
            },
            pageTitle: 'PostListWithAccountName',
            href: `/analytics:compare${search}`,
          });
        }

        if (influencerYoutubeAnalyticsCompareAccountCards.compareAccounts) {
          compareAccounts = influencerYoutubeAnalyticsCompareAccountCards.compareAccounts.map(account => {
            const { accountId, avatarUrl, channelName, elementId, profileUrl } = account;

            return {
              accountId,
              avatarUrl,
              channelName,
              elementId,
              isMain: false,
              isSelected: false,
              profileUrl,
            };
          });
        }

        setAllCompareAccounts([...mainAccount, ...compareAccounts]);
      },
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  const selectedAccount = allCompareAccounts.find(account => account.isSelected);
  const isMobile = useMediaQuery(`(max-width:${ViewportBreakpoint.MOBILE}px)`);

  return (
    <>
      <NavigationHeader />
      <Container>
        <TabsAccounts
          allCompareAccounts={allCompareAccounts}
          setAllCompareAccounts={setAllCompareAccounts}
          isMobile={isMobile}
        />
        <div css={{ margin: isMobile ? '16px 16px 0;' : '24px 24px 0;' }}>
          <BasePostFilter filter={filter} setFilter={setFilter} isMobile={isMobile} />
        </div>
        {selectedAccount ? <TableVideoList account={selectedAccount} filter={filter} isMobile={isMobile} /> : null}
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 56px;
`;

export default InfluencerYoutubeCompareFeedVideos;
