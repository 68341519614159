import styled from '@emotion/styled';
import { ViewportBreakpoint } from '@src/libs/theme';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const HeadingText = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #27313b;
`;
export const EngagementRateWrapper = styled.div`
  display: flex;

  @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const CountWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const Label = styled.h6`
  color: #6e7c89;
  font-size: 13px;
  margin-right: 8px;
`;
export const FollowersCount = styled.h3`
  font-weight: 600;
  font-size: 16px;
  color: #27313b;
`;
export const Img = styled.img`
  width: 24px;
  margin-right: 10px;
`;
