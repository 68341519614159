import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import flagWhite from '@src/assets/icon/flagWhite.svg';
import {
  AnyXEngagementCampaignStatus,
  AnyXMarketplaceCampaignStatus,
  CampaignType,
} from '@src/__generated__/globalTypes';

interface StatusIndicatorProps {
  campaignStatus?: AnyXEngagementCampaignStatus | AnyXMarketplaceCampaignStatus | null;
  campaignType: CampaignType;
}

const StatusIndicator = ({ campaignStatus, campaignType }: StatusIndicatorProps) => {
  const { t } = useTranslation();

  function statusIndicator(
    type: CampaignType,
    status?: AnyXEngagementCampaignStatus | AnyXMarketplaceCampaignStatus | null
  ) {
    if (type === CampaignType.ENGAGEMENT) {
      switch (status) {
        case AnyXEngagementCampaignStatus.DRAFTING:
          return { color: '#3892e5', message: 'Please upload a draft post', title: 'Drafting' };
        case AnyXEngagementCampaignStatus.EFFECTIVE:
          return { color: '#3892e5', message: 'Please check the report', title: 'Effective' };
        case AnyXEngagementCampaignStatus.INEFFECTIVE:
          return { color: '#ff5f5f', message: 'Your post was not approved', title: 'Ineffective' };
        case AnyXEngagementCampaignStatus.POSTING:
          return { color: '#3892e5', message: 'Please make a post', title: 'Posting' };
        case AnyXEngagementCampaignStatus.REVIEWING:
          return {
            color: '#3892e5',
            message: 'Please wait for the review',
            title: 'Reviewing',
          };
        default:
          return { color: '#ff5f5f', message: 'Please check the comment', title: 'Warning' };
      }
    } else {
      switch (status) {
        case AnyXMarketplaceCampaignStatus.APPROVED:
          return { color: '#3892e5', message: 'Your post has been approved', title: 'Approved' };
        case AnyXMarketplaceCampaignStatus.EFFECTIVE:
          return { color: '#3892e5', message: 'Please check the report', title: 'Effective' };
        case AnyXMarketplaceCampaignStatus.INEFFECTIVE:
          return { color: '#ff5f5f', message: 'Your post was not approved', title: 'Ineffective' };
        case AnyXMarketplaceCampaignStatus.POSTING:
          return { color: '#3892e5', message: 'Please make a post', title: 'Posting' };
        case AnyXMarketplaceCampaignStatus.REVIEWING:
          return {
            color: '#3892e5',
            message: 'Please wait for your post to be approved',
            title: 'Reviewing',
          };
        case AnyXMarketplaceCampaignStatus.WARNING_EFFECTIVE:
          return { color: '#ff5f5f', message: 'Please revise the post', title: 'Warning' };
        default:
          return { color: '#f8b74a', message: 'Please wait until the review is complete', title: 'Accessing' };
      }
    }
  }

  const { color, message, title } = statusIndicator(campaignType, campaignStatus);

  return (
    <Indicator color={color}>
      <div>
        <div>
          <img alt="statusIcon" height="12" src={flagWhite} width="12" />
          <span>{t(title)}</span>
        </div>
      </div>
      <div>
        <div>{message ? t(`Annotation.${message}`) : '---'}</div>
      </div>
    </Indicator>
  );
};

const Indicator = styled.div<{ color: string }>`
  display: flex;
  flex-wrap: wrap;
  height: 30px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 95%;

  & > div:nth-of-type(1) {
    background-color: ${({ color }) => color};
    border-radius: 5px 0 0 5px;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 31%;

    & > div {
      align-items: center;
      color: #fff;
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;
      font-weight: 600;
      height: 100%;

      & > img {
        margin-right: 5px;
      }
    }
  }

  /* stylelint-disable no-descending-specificity */
  & > div:nth-of-type(2) {
    background-color: #fff;
    border-radius: 0 5px 5px 0;
    height: 100%;
    width: 69%;

    & > div {
      align-items: center;
      color: ${({ color }) => color};
      display: flex;
      font-size: 12px;
      font-weight: 500;
      height: 100%;
      padding: 0 6px;
    }
  }
`;

export default StatusIndicator;
