import { ApolloError } from '@apollo/client';
import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import InitialLoading from '../../../components/molecules/InitialLoading';
import { getCallbackUrl, removeToken, setToken } from '../../../libs/auth';
import { getMessagesFromApolloError, getMessagesFromFetchResult, UNEXPECTED_ERROR } from '../../../libs/error';
import { useQueryHelper } from '../../../libs/hooks';
import { useAuthProviderResponse } from '../hooks';
import { useAuthSetup } from '../../../components/organisms/AuthSetUp/useAuthSetup';
import { ListIndicator } from '../../../components/molecules/Indicator';
import { CheckSignUpEnabled, CheckSignUpEnabledVariables } from './__generated__/CheckSignUpEnabled';
import * as CHECK_SIGN_UP_ENABLED from './CheckSignUpEnabled.graphql';

const SignUpEnabledComponent = () => {
  const [checkSignUpEnabled] = useMutation<CheckSignUpEnabled, CheckSignUpEnabledVariables>(CHECK_SIGN_UP_ENABLED, {
    onError: err => {
      if (window.opener) {
        window.opener.postMessage({ redirectPath: '/', errorMsg: err.message }, '*');
        window.close();
      }
    },
  });
  const { setUp } = useAuthSetup();

  const { t, enqueueSnackbar, history } = useQueryHelper();

  const { provider, response } = useAuthProviderResponse('/signup');

  const signUpEnabledCall = async () => {
    if (!provider) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      if (window.opener) {
        window.close();
      }
      history.push('/');

      return;
    }

    const variables = {
      input: {
        provider,
        response,
        callbackUrl: getCallbackUrl(provider, FE_REDIRECT_MAPPING.SIGNUP_ENABLED),
      },
    };

    const { name, email, token, refreshToken, errors } = await checkSignUpEnabled({ variables })
      .then(result => {
        if (result && result.data && result.data.checkSignUpEnabled) {
          const payload = result.data.checkSignUpEnabled;

          return {
            name: payload.name,
            email: payload.email,
            token: payload.token,
            refreshToken: payload.refreshToken,
            errors: [],
          };
        } else {
          return {
            name: null,
            email: null,
            token: null,
            refreshToken: null,
            errors: getMessagesFromFetchResult(result),
          };
        }
      })
      .catch((e: ApolloError) => ({
        name: null,
        email: null,
        token: null,
        refreshToken: null,
        errors: getMessagesFromApolloError(e),
      }));

    // User has already an account.
    if (errors.length === 0 && token) {
      // Sign In with token
      setToken(token, refreshToken);

      try {
        await setUp(token);

        if (window.opener) {
          // send redirect url to main app listener, from where we opened this child window
          window.opener.postMessage({ redirectPath: '/profile' }, '*');
          window.close();

          return;
        }
        history.push('/profile');
      } catch (e) {
        removeToken();
        console.error(e);
        enqueueSnackbar(t(e.message), { variant: 'error' });
        if (window.opener) {
          window.close();
          window.opener.postMessage({ redirectPath: '/', errorMsg: e.message }, '*');

          return;
        }
        history.push('/');
      }

      return;
    }

    // Redirect to SignUp form
    if (errors.length === 0) {
      if (window.opener) {
        window.opener.postMessage(
          {
            redirectPath: '/signup/profile',
            state: {
              socialMedia: provider,
              name: name || '',
              email: email || '',
            },
          },
          '*'
        );
        window.close();

        return;
      }

      history.push({
        pathname: '/signup/profile',
        state: {
          socialMedia: provider,
          name: name || '',
          email: email || '',
        },
      });

      return;
    }

    errors.forEach(error => {
      console.error(error);
      enqueueSnackbar(t(error), { variant: 'error' });

      if (error === 'this account is already signed up') {
        if (window.opener) {
          window.opener.postMessage({ redirectPath: '/', errorMsg: 'this account is already signed up' }, '*');
          window.close();

          return;
        }

        history.push('/');

        return;
      }
    });

    if (window.opener) {
      window.opener.postMessage({ redirectPath: '/signup' }, '*');
      window.close();

      return;
    }
    history.push('/signup');
  };

  useEffect(() => {
    signUpEnabledCall();
  }, []);

  return window.opener ? (
    <ListIndicator height="100vh" text="Authentication is in progress, please wait" />
  ) : (
    <InitialLoading />
  );
};

export default SignUpEnabledComponent;
