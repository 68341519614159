import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { ListIndicator } from '@src/components/molecules/Indicator';
import Empty from '@src/components/atoms/Empty';
import { useQueryHelper } from '@src/libs/hooks';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik } from 'formik';
import { FanActivityCommentSchema } from '@src/libs/validation';
import { LIMIT } from '@src/libs/constant';
import { Button } from '@src/components/atoms/Button';
import { css } from '@emotion/core';
import Dialog from '@src/components/molecules/Dialog';
import { GetCreatorStaffs } from '@src/pages/Settings/Users/queries/__generated__/GetCreatorStaffs';
import * as GET_CREATOR_STAFFS from '@src/pages/Settings/Users/queries/GetCreatorStaffs.graphql';
import { getActivityList } from '../helper';
import ActivityRow from './ActivityRow';
import CommentForm from './CommentForm';
import useGetFanActivities from './useGetFanActivities';
import useStateHandler from './useStateHandler';

const ActivityTimeline = () => {
  const { t, params } = useQueryHelper();
  const [creatorStaffs, setCreatorStaffs] = useState<{ [key: string]: string }>({});
  const { id } = params as { id: string };
  const { data, loading, fetchMore } = useGetFanActivities({ fanId: Number(id) });

  useQuery<GetCreatorStaffs>(GET_CREATOR_STAFFS, {
    onCompleted: ({ getCreatorStaffs }) => {
      const res =
        getCreatorStaffs?.staffs.reduce<{ [key: string]: string }>(
          (prev, curr) => ({ ...prev, [curr.id]: curr.name }),
          {
            [getCreatorStaffs.id]: getCreatorStaffs.name,
          }
        ) || {};

      setCreatorStaffs(res);
    },
  });

  const {
    activity,
    handleAddComment,
    handleUpdateComment,
    handleClickEdit,
    handleClickDelete,
    handleClickMore,
    handleClose,
    handleDeleteConfirm,
  } = useStateHandler(Number(id));

  const handleViewMore = async () => {
    await fetchMore({
      variables: { input: { fanId: Number(id), offset: data?.getFanActivities?.activities.length || 0, limit: LIMIT } },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return {
          ...prev,
          getFanActivities: {
            total: fetchMoreResult.getFanActivities?.total || 0,
            __typename: 'GetFanActivitiesPayload',
            activities: [
              ...(prev.getFanActivities?.activities || []),
              ...(fetchMoreResult.getFanActivities?.activities || []),
            ],
          },
        };
      },
    });
  };

  if (loading) {
    return <ListIndicator />;
  }

  if (!data?.getFanActivities?.activities.length) {
    return <Empty />;
  }

  const total = data.getFanActivities.total || 0;
  const activities = getActivityList(data.getFanActivities.activities, t);

  return (
    <Container>
      {/* Menu */}
      <Menu anchorEl={activity.el} open={Boolean(activity.el)} onClose={handleClose}>
        <MenuItem onClick={handleClickEdit}>{t('Edit')}</MenuItem>
        <MenuItem onClick={handleClickDelete}>{t('Delete')}</MenuItem>
      </Menu>

      {/* Delete confirmation dialog */}
      <Dialog
        visible={activity.showDeleteConfirmation}
        contentStyle={{ maxWidth: '400px' }}
        onClose={handleClose}
        onExec={handleDeleteConfirm}
        execText="Delete"
      >
        <Heading>{t('Confirmation required')}</Heading>
        <RegularText>{t('Are you sure you want to delete the activity?')}</RegularText>
      </Dialog>
      {/* Add new comment */}
      <Formik
        initialValues={{ comment: '' }}
        onSubmit={handleAddComment}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={FanActivityCommentSchema}
      >
        <CommentForm />
      </Formik>
      {/* List of activities */}
      <div>
        {activities.map((item, index) => (
          <ActivityRow
            key={(item.activity || '') + index}
            created={item.created}
            activity={item.activity}
            activityType={item.activityType}
            commenter={item.commenterId ? creatorStaffs?.[item.commenterId] || '' : ''}
            onClickMore={e => handleClickMore(e, item.id)}
            showCommentInsideForm={activity.showCommentInsideForm && item.id === activity.id}
            onUpdate={handleUpdateComment}
          />
        ))}
      </div>
      {data.getFanActivities.activities.length < total && (
        <ButtonWrapper>
          <Button
            bgcolor="#fff"
            color="#6e7c89"
            hoverbgcolor="#F6F8FA"
            excludeTitleTranslation={true}
            title="More"
            css={buttonStyle}
            onClick={handleViewMore}
          />
        </ButtonWrapper>
      )}
    </Container>
  );
};

const buttonStyle = css`
  width: fit-content;
  border: 1px solid #dee5ec;
  border-radius: 3px;
  line-height: 30px;
  font-weight: 600;
  padding: 0 24px;
  height: 32px;
`;
const Heading = styled.h1`
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #27313b;
`;
const RegularText = styled.p`
  font-size: 14px;
  color: #27313b;
  margin-bottom: 16px;
`;
const Container = styled.div`
  position: relative;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, rgba(246, 248, 250, 0) 0%, #fff 49.4%);
  height: 92px;
  bottom: -40px;
  position: absolute;
  width: 100%;
  align-items: center;
`;

export default ActivityTimeline;
