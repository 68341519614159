import { useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SubmitButton } from '@src/components/atoms/Button';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import Grid from '@src/components/atoms/Grid';
import Label from '@src/components/atoms/Label';
import { DayPickerHandInput } from '@src/components/molecules/DayPicker';
import { PostFileField } from '@src/components/molecules/FileField';
import SentenceForm from '@src/components/molecules/SentenceForm';
import SingleSelectField from '@src/components/molecules/SingleSelectField';
import { SOCIAL_MEDIAS } from '@src/libs/constant';
import { switchImage } from '@src/libs/SocialMedia';
import { ViewportType } from '@src/libs/theme';
import {
  CampaignSocialMediaType,
  EngagementCampaignPostStatus,
  SocialAccountType,
} from '@src/__generated__/globalTypes';
import { CampaignInfluencerSocialAccounts_engagementPostSocialAccountsForInfluencer } from './queries/__generated__/CampaignInfluencerSocialAccounts';

export interface PostDetails {
  campaignId: number;
  content: string;
  id?: number;
  materialsUrl: string[];
  planedPostDate: string;
  socialAccountId: number | null;
  socialAccountMedia: SocialAccountType | null;
  socialMedia: CampaignSocialMediaType | null;
}

interface PostFormProps {
  isEdit?: boolean;
  socialAccounts: CampaignInfluencerSocialAccounts_engagementPostSocialAccountsForInfluencer[];
  status: EngagementCampaignPostStatus;
}

const PostForm = ({ isEdit, socialAccounts, status }: PostFormProps) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [selectedSocialMedia, setSelectedSocialMedia] = useState<SocialAccountType | null>(null);
  const { t } = useTranslation();
  const { isSubmitting, handleSubmit, setFieldValue } = useFormikContext<PostDetails>();
  const [content, contentMeta] = useField<string>('content');
  const [materialsUrl] = useField<string[]>('materialsUrl');
  const [planedPostDate, planedPostDateMeta] = useField<string>('planedPostDate');
  const [socialAccountId, socialAccountIdMeta] = useField<number>('socialAccountId');
  const [socialMedia, socialMediaMeta] = useField<CampaignSocialMediaType>('socialMedia');
  const disabledFields = ![EngagementCampaignPostStatus.REJECT, EngagementCampaignPostStatus.REVIEWING].includes(
    status
  );

  const igSocialMedias = SOCIAL_MEDIAS.filter(sm =>
    [CampaignSocialMediaType.INSTAGRAM, CampaignSocialMediaType.INSTAGRAM_STORY].includes(sm.value)
  );
  const socialAccountsDropdown =
    socialAccounts.map(socialAccount => ({
      icon: <img alt="icon" css={styles.socialMediaIcon} src={switchImage(socialAccount.socialMedia)} />,
      label: socialAccount.name,
      value: socialAccount.id.toString(),
    })) || [];

  useEffect(() => {
    if (socialAccounts.length > 0 && socialAccountId.value) {
      const accSocialMedia =
        socialAccounts.find(socialAcc => socialAcc.id === socialAccountId.value)?.socialMedia || null;
      setSelectedSocialMedia(accSocialMedia);
      setFieldValue('socialAccountMedia', accSocialMedia);
      const sm =
        accSocialMedia === SocialAccountType.FACEBOOK_PAGE
          ? CampaignSocialMediaType.FACEBOOK
          : accSocialMedia === SocialAccountType.INSTAGRAM
          ? [CampaignSocialMediaType.INSTAGRAM, CampaignSocialMediaType.INSTAGRAM_STORY].includes(socialMedia.value)
            ? socialMedia.value
            : null
          : accSocialMedia;
      setFieldValue('socialMedia', sm);
    }
  }, [socialAccounts, socialAccountId.value]);

  return (
    <form onSubmit={handleSubmit}>
      <div css={styles.container}>
        <Grid lg={8} md={10} xs={12}>
          <div css={styles.infoContainer}>
            <InputContainer>
              <StyledSingleSelectField
                disabled={disabledFields}
                error={!!socialAccountIdMeta.error}
                isRequired
                name="social account"
                options={socialAccountsDropdown}
                title="Select Social Account"
                value={String(socialAccountId.value) || ''}
                setFieldValue={(_attr, value) => setFieldValue('socialAccountId', value ? Number(value) : null)}
              />
              {socialAccountIdMeta.error && <ErrorMessage message={t(socialAccountIdMeta.error)} />}
            </InputContainer>

            {selectedSocialMedia === SocialAccountType.INSTAGRAM && (
              <InputContainer>
                <StyledSingleSelectField
                  disabled={disabledFields}
                  error={!!socialMediaMeta.error}
                  isRequired
                  name="post type"
                  options={igSocialMedias}
                  title="Post Type (Instagram)"
                  value={socialMedia.value || ''}
                  setFieldValue={(_attr, value) => setFieldValue('socialMedia', value)}
                />
                {socialMediaMeta.error && <ErrorMessage message={t(socialMediaMeta.error)} />}
              </InputContainer>
            )}

            <InputContainer>
              <Label css={styles.label} title={t('Selector.Plan Date of Post')} isRequired={true} />
              <div css={styles.datepickerContainer}>
                <DayPickerHandInput
                  disabled={disabledFields}
                  disabledRange={{ before: new Date() }}
                  error={!!planedPostDateMeta.error}
                  value={planedPostDate.value}
                  handleChangeDay={value => setFieldValue('planedPostDate', value)}
                />
              </div>
              {planedPostDateMeta.error && <ErrorMessage message={t(planedPostDateMeta.error)} />}
            </InputContainer>

            <InputContainer>
              <SentenceForm
                css={styles.sentenceForm}
                disabled={disabledFields}
                error={!!contentMeta.error}
                isRequired={true}
                placeholder="Placeholder"
                title="Caption"
                value={content.value}
                onChange={e => setFieldValue('content', e.target.value)}
              />
              {contentMeta.error && <ErrorMessage message={t(contentMeta.error)} />}
            </InputContainer>

            <InputContainer>
              <PostFileField
                accept="image/gif, image/jpeg, image/png, video/avi, video/mp4, video/quicktime"
                customLabel={<Label title={t('Image/Video')} help={t('Files Help')} />}
                css={styles.fileUploadContainer}
                disabled={disabledFields || isUploading}
                dropAreaCss={styles.fileUploadDropAreaCss}
                initialFileUrls={materialsUrl.value.map(url => ({ url, preview: url })) || []}
                multiple
                name="materialsUrl"
                notes={[
                  t('DragAndDrop.Info'),
                  t('DragAndDrop.MaxSize', { maxSize: '10GB' }),
                  t('DragAndDrop.FileType', {
                    MIME: 'PNG, GIF, JPEG, AVI, MOV, MP4',
                  }),
                  t('DragAndDrop.ImageRatio'),
                ]}
                previewCss={styles.fileUploadPreviewCss}
                value={materialsUrl.value}
                setFieldValue={setFieldValue}
                setIsFileUploading={setIsUploading}
              />
            </InputContainer>

            <div css={styles.formActionSection}>
              <SubmitButton disabled={isSubmitting} value={isEdit ? 'Upload Post' : 'Upload Draft Post'} />
            </div>
          </div>
        </Grid>
      </div>
    </form>
  );
};

const InputContainer = styled.div`
  margin-bottom: 16px;
`;

const StyledSingleSelectField = styled(SingleSelectField)`
  & > label {
    font-size: 13px;
  }

  & > div > div {
    border-radius: 2px;
    min-height: 32px;

    & > div > div {
      height: inherit;
    }
  }

  & input {
    border-radius: 2px;
    min-height: 32px;
  }
`;

const styles = {
  container: css`
    display: flex;
    justify-content: center;
  `,
  datepickerContainer: css`
    /* stylelint-disable no-descending-specificity */
    & > div > div > div {
      border-radius: 2px;
      height: 32px;

      & > span {
        top: 7px;
      }

      & > input {
        height: 32px;
      }
    }
  `,
  fileUploadContainer: css`
    & label {
      font-size: 13px;
    }
  `,
  fileUploadDropAreaCss: css`
    & .drop-area-notes {
      justify-content: center;
    }
  `,
  fileUploadPreviewCss: css`
    position: relative;

    & > div {
      counter-increment: count;
      max-width: 292px;
      width: 45%;

      @media (max-width: ${ViewportType.SMALL}px) {
        width: 100%;
      }

      & > div::after {
        align-items: center;
        background-color: #fff;
        border-radius: 5px;
        color: #3892e5;
        content: 'Image ' counter(count);
        display: flex;
        font-size: 13px;
        font-weight: 600;
        height: 24px;
        left: 16px;
        justify-content: center;
        position: absolute;
        top: 16px;
        width: 62px;
      }
    }
  `,
  formActionSection: css`
    background-color: #fff;
    border-top: 1px solid #eef3f7;
    display: flex;
    justify-content: flex-end;
    padding: 16px;

    & input {
      border-radius: 3px;
      height: 32px;
      line-height: 0;
      text-transform: capitalize;
      width: 160px;

      @media (max-width: ${ViewportType.SMALL}px) {
        width: 100%;
      }
    }
  `,
  imagesContainer: css`
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;

    & img {
      background-color: #000;
      height: 219px;
      max-width: 292px;
      object-fit: contain;
      width: 100%;
    }
  `,
  infoContainer: css`
    background-color: #fff;
    padding: 16px;
  `,
  label: css`
    font-size: 13px;
  `,
  sentenceForm: css`
    & > label {
      font-size: 13px;
    }

    & > textarea {
      border-radius: 2px;

      &:focus {
        border-color: #e0e8ed;
      }
    }
  `,
  socialMediaIcon: css`
    height: 18px;
    margin-right: 8px;
    width: 18px;
  `,
};

export default PostForm;
