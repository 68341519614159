import { CircularProgress } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import React from 'react';
import { useQueryHelper } from '@src/libs/hooks';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import {
  InfluencerPostDetail as InfluencerPostDetailType,
  InfluencerPostDetailVariables,
} from './__generated__/InfluencerPostDetail';
import * as INFLUENCER_POST_DETAIL from './InfluencerPostDetail.graphql';
import InfluencerListTemplate from './InfluencerListTemplate';

interface InfluencerPostDetailProps {
  influencerId: number;
  closeModal: () => void;
  postIdUrlParam?: string;
}

const InfluencerPostDetail = ({ influencerId, closeModal, postIdUrlParam = 'popup' }: InfluencerPostDetailProps) => {
  const { t, enqueueSnackbar, search } = useQueryHelper();

  const urlSearchParams = new URLSearchParams(search);
  const urlPostId = Number(urlSearchParams.get(postIdUrlParam));
  const selectedSm = urlSearchParams.get('sm');

  const { data, loading, error } = useQuery<InfluencerPostDetailType, InfluencerPostDetailVariables>(
    INFLUENCER_POST_DETAIL,
    {
      variables: { postId: urlPostId, pk: influencerId },
      fetchPolicy: 'cache-and-network',
      skip: !urlPostId,
    }
  );

  if (loading) {
    return <CircularProgress size={64} thickness={2.4} css={{ margin: 'auto', color: '#dee5ec' }} />;
  }

  if (error) {
    closeModal();
    enqueueSnackbar(t(error?.message), { variant: 'error' });

    return null;
  } else if (!urlPostId) {
    closeModal();
  }

  return <InfluencerListTemplate data={data} closeModal={closeModal} selectedSm={selectedSm as SocialAccountType} />;
};

export default InfluencerPostDetail;
