import styled from '@emotion/styled';
import { LocationDescriptor } from 'history';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface LinkButtonProps extends AnchorProps {
  id?: string;
  className?: string;
  href: LocationDescriptor;
  icon?: string;
  title?: string;
}

const LinkButton = (props: LinkButtonProps) => {
  const { t } = useTranslation();
  const { id, className, href, icon, title, color, bgcolor, hoverbgcolor, disabled } = props;
  const translatedTitle = title ? t(`Button.${title}`) : '';

  return (
    <Anchor
      id={id}
      className={className}
      disabled={disabled}
      to={href}
      color={color}
      bgcolor={bgcolor}
      hoverbgcolor={hoverbgcolor}
      onClick={disabled ? (e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault() : undefined}
    >
      {icon ? <Icon className="material-icons">{icon}</Icon> : null}
      <p>{translatedTitle}</p>
    </Anchor>
  );
};

interface AnchorProps {
  color?: string;
  bgcolor?: string;
  hoverbgcolor?: string;
  disabled?: boolean;
}

const Anchor = styled(Link)<AnchorProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${props => (props.bgcolor ? props.bgcolor : '#00ac56')};
  border-radius: 2px;
  color: ${props => (props.color ? props.color : '#fff')};
  text-align: center;
  letter-spacing: 0.02em;
  line-height: 40px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: ${props => (props.hoverbgcolor ? props.hoverbgcolor : '#00984c')};

    @media (max-width: 450px) {
      background-color: ${props => (props.bgcolor ? props.bgcolor : '#00ac56')};
    }
  }
`;

const Icon = styled.i`
  margin-right: 4px;
  font-size: 16px;
`;

export default LinkButton;
