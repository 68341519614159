import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import TextForm from '@src/components/molecules/TextForm';
import { switchImage, switchText } from '@src/libs/SocialMedia';
import { SocialMediaTypeForRateCard } from '@src/__generated__/globalTypes';

interface SnsBudgetInputProps {
  currency: string;
  disabled: boolean;
  error?: string;
  type: SocialMediaTypeForRateCard;
  value: string;
  onChange: (value: string) => void;
}

const SnsBudgetInput = ({ currency, disabled, error, type, value, onChange }: SnsBudgetInputProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.inputContainer}>
      <div>
        <img alt="socialMediaIcon" height="24" src={switchImage(type, true)} width="24" />
        <span>{`${switchText(type)} / 1 ${t('post')}`}</span>
      </div>
      <div>
        <div>
          <div>
            <StyledTextForm
              css={styles.textForm}
              disabled={disabled}
              error={!!error}
              placeholder="0"
              type="number"
              value={value}
              onChange={e => onChange(e.target.value)}
            />
          </div>
          {error && (
            <div>
              <ErrorMessage message={t(error)} />
            </div>
          )}
        </div>
        <span>{currency}</span>
      </div>
    </div>
  );
};

const StyledTextForm = styled(TextForm)<{ disabled: boolean }>`
  ${({ disabled }) => (disabled ? 'pointer-events: none;' : '')}
`;

const styles = {
  inputContainer: css`
    border-bottom: 1px solid #eef3f7;
    display: flex;
    flex-wrap: wrap;
    padding: 16px;

    & > div:nth-of-type(1) {
      align-items: center;
      display: flex;
      flex-basis: 50%;

      & > span {
        color: #27313b;
        font-size: 14px;
        font-weight: 600;
        margin-left: 8px;
      }
    }

    & > div:nth-of-type(2) {
      align-items: center;
      display: flex;
      flex-basis: 50%;
      justify-content: flex-end;

      & > div > div {
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }

      & > span {
        color: #27313b;
        font-size: 14px;
        margin-left: 8px;
      }
    }
  `,
  textForm: css`
    & > label {
      font-size: 14px;
    }

    & input {
      border-radius: 2px;
      height: 32px;
      text-align: end;
      width: 108px;
    }
  `,
};

export default SnsBudgetInput;
