import React, { useState } from 'react';
import { Global, css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import DataOpacityWrapper from '@src/components/molecules/DataCard/DataOpacityWrapper';
import HighChart from '@src/components/molecules/HighChart';
import * as Styled from '@src/components/organisms/AnalyticsShared/Overview/StyledComponents';
import { formatPercent, bigIntFormatter, intlNumberFormat } from '@src/libs/format';
import { getGrowthPrefix, getGrowthArrow } from '@src/components/organisms/AnalyticsShared/utils';
import CheckBoxIcon from '@src/components/atoms/Icon/CheckBoxIcon';
import { EngagementDataType } from '@src/components/organisms/AnalyticsShared/Overview/helpers';
import { MultiSelector } from '@src/components/molecules/Selector';
import { useMediaQuery } from 'react-responsive';
import { ViewportBreakpoint } from '@src/libs/theme';
import { InfluencerInstagramInteractionOverview } from './__generated__/InfluencerInstagramInteractionOverview';
import {
  getOptions,
  defaultSelectedCharts,
  colors,
  allInteractionMetrics,
  InteractionChartColorTypes,
} from './interactionHelpers';

const InteractionOverview = ({ influencerInstagramInteractionOverview }: InfluencerInstagramInteractionOverview) => {
  const { t } = useTranslation();
  const isMedium = useMediaQuery({ minWidth: ViewportBreakpoint.MEDIUM });

  const [selectedCharts, setSelectedCharts] = useState<typeof defaultSelectedCharts>(defaultSelectedCharts);
  const { options, engagement } = influencerInstagramInteractionOverview
    ? getOptions(selectedCharts, influencerInstagramInteractionOverview)
    : { options: [] as Highcharts.Options, engagement: [] as EngagementDataType[] };

  const toggleSelectedCharts = (isSelected: boolean, name: typeof defaultSelectedCharts[0]) => {
    if (isSelected) {
      setSelectedCharts(selectedCharts.filter(selectedItem => selectedItem !== name));
    } else {
      setSelectedCharts([...selectedCharts, name]);
    }
  };

  const noData = engagement.every(item => !item.rate);

  return (
    <DataOpacityWrapper header="" dataNotAvailable={noData}>
      <Styled.EngagementGrid>
        {engagement.map(item => {
          const isPositiveGrowth = item.growth === 0 ? undefined : item.growth > 0;
          const prefix = getGrowthPrefix(item.growth) || '';
          const arrow = getGrowthArrow(item.growth);
          const total = bigIntFormatter(item.total, 0);
          const growth = bigIntFormatter(item.growth, 0);
          const hoverTotal = intlNumberFormat(item.total);
          const hoverGrowth = intlNumberFormat(item.growth);

          return (
            <Styled.EngagementGridCell key={item.title}>
              <Styled.PartWithIcon>
                <Styled.EngagementTitle text={t(item.title)} lines={1} />
                <Styled.EngagementTileIcon src={item.icon} />
              </Styled.PartWithIcon>

              <Styled.EngagementNumbers>
                <Styled.TotalNumber title={hoverTotal}>{total}</Styled.TotalNumber>
                <Styled.Growth
                  isPositive={isPositiveGrowth}
                  title={hoverGrowth}
                >{`${prefix}${growth}  ${arrow}  (${formatPercent(item.rate)})`}</Styled.Growth>
              </Styled.EngagementNumbers>
            </Styled.EngagementGridCell>
          );
        })}
      </Styled.EngagementGrid>
      <Styled.OverviewWrapper>
        <Styled.Chart>
          <Styled.LegendBar>
            {!isMedium ? (
              <MultiSelector
                value={selectedCharts}
                name={''}
                isRoundInput={false}
                options={allInteractionMetrics.map(v => ({
                  label: t(`Label.${v}`),
                  value: v,
                }))}
                onChange={e => setSelectedCharts(e as InteractionChartColorTypes[])}
                initialValues={['Follower']}
                menuCss={styles.menuCss}
                css={styles.selectorWrapper}
              />
            ) : (
              allInteractionMetrics.map(item => {
                const isChecked = selectedCharts.includes(item);
                const color = colors[item];

                return (
                  <Styled.LegendTileColored key={item} onClick={() => toggleSelectedCharts(isChecked, item)}>
                    <CheckBoxIcon checked={isChecked} />
                    <Styled.Text>{t(item)}</Styled.Text>
                    <span style={{ background: color }} />
                  </Styled.LegendTileColored>
                );
              })
            )}
          </Styled.LegendBar>
          <Global styles={styles.globalTooltip} />
          <HighChart options={options} />
        </Styled.Chart>
      </Styled.OverviewWrapper>
    </DataOpacityWrapper>
  );
};

const styles = {
  selectorWrapper: css`
    min-height: 30px;
    max-height: 30px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  `,
  menuCss: css`
    margin-top: 5px;
    box-shadow: 0 1px 2px 0 rgba(39, 49, 59, 0.1);
    border-radius: 3px !important;
    width: 100%;
  `,
  globalTooltip: css`
    .highcharts-tooltip path {
      fill: #27313b;
    }

    .highcharts-root {
      padding-left: 4px;
    }
  `,
};

export default InteractionOverview;
