import { useContext } from 'react';
import { AdminStore } from '../../Context';

export default () => {
  const {
    state: { yourJobPageCount },
    dispatch,
  } = useContext(AdminStore);

  const updateYourJobPageCount = (progress: number, finished: number) => {
    dispatch({ type: 'UPDATE_JOB_PAGE_COUNT', payload: { yourJobPageCount: { progress, finished } } });
  };

  return { yourJobPageCount, updateYourJobPageCount };
};
