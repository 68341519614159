import { CircularProgress } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import React from 'react';
import { useQueryHelper } from '@src/libs/hooks';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import InfluencerListTemplate from '../InfluencerListTemplate';
import * as MARKETPLACE_POST_DETAIL from './MarketplacePostDetail.graphql';
import { MarketplacePostDetail, MarketplacePostDetailVariables } from './__generated__/MarketplacePostDetail';

interface InfluencerPostDetailProps {
  closeModal: () => void;
  postIdUrlParam?: string;
}

const InfluencerMarketplacePostDetail = ({ closeModal, postIdUrlParam = 'popup' }: InfluencerPostDetailProps) => {
  const { t, enqueueSnackbar, search } = useQueryHelper();

  const urlSearchParams = new URLSearchParams(search);
  const urlPostId = Number(urlSearchParams.get(postIdUrlParam));
  const selectedSm = urlSearchParams.get('sm');

  const { data, loading, error } = useQuery<MarketplacePostDetail, MarketplacePostDetailVariables>(
    MARKETPLACE_POST_DETAIL,
    {
      variables: { postId: urlPostId, limit: 10 },
      onError: err => {
        closeModal();
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
    }
  );

  if (loading) {
    return <CircularProgress size={64} thickness={2.4} css={{ margin: 'auto', color: '#dee5ec' }} />;
  } else if (error || !data?.marketplacePost || !urlPostId) {
    closeModal();

    return null;
  }

  return (
    <InfluencerListTemplate
      data={{
        influencerPostDetail: {
          ...data.marketplacePost,
          reactions: null,
          engagement: null,
          sentimentNegativeRatio: null,
          sentimentPositiveRatio: null,
          avatar: data?.marketplacePost?.postDetailInfluencer.avatar || '',
          postDate: null,
          followersCount: null,
          thumbNail: data?.marketplacePost?.images?.[0] || null,
          commentsContent: data.marketplacePostComments,
        },
        influencerProfileV2: {
          id: Number(data?.marketplacePost?.postDetailInfluencer.id),
          name: data?.marketplacePost?.postDetailInfluencer.name || '',
        },
      }}
      closeModal={closeModal}
      selectedSm={selectedSm as SocialAccountType}
    />
  );
};

export default InfluencerMarketplacePostDetail;
