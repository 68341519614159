import { XYCoord } from 'dnd-core';
import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import Popup from 'reactjs-popup';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import copy from '@src/assets/icon/copy.svg';
import checkbox from '@src/assets/icon/form/checkbox.svg';
import dropdown from '@src/assets/icon/form/dropdown.svg';
import imageIcon from '@src/assets/icon/form/image.svg';
import mail from '@src/assets/icon/form/mail.svg';
import shortAnswer from '@src/assets/icon/form/shortAnswer.svg';
import user from '@src/assets/icon/form/user.svg';
import moreHorizontal from '@src/assets/icon/moreHorizontal.svg';
import trash from '@src/assets/icon/trashIcon.svg';
import { Button } from '@src/components/atoms/Button';
import { CheckBox } from '@src/components/atoms/CheckBox';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import { Icon } from '@src/components/atoms/Icon';
import { PostFileField } from '@src/components/molecules/FileField';
import TextForm from '@src/components/molecules/TextForm';
import SingleSelectField from '@src/components/molecules/SingleSelectField';
import Switch from '@src/components/molecules/Switch';
import { ViewportType } from '@src/libs/theme';
import { QuestionType } from '@src/__generated__/globalTypes';

enum DialogStatus {
  CLOSE = 'CLOSE',
  DELETE = 'DELETE',
  UPLOAD = 'UPLOAD',
}

enum ItemTypes {
  CARD = 'card',
}

export enum QuestionErrorMessages {
  OPTION_TITLE = 'requiredQuestionOptionTitleMessage',
  TITLE = 'requiredQuestionTitleMessage',
  TYPE = 'requiredQuestionTypeMessage',
}

interface DragItem {
  id: string;
  index: number;
  type: string;
}

export interface Questions {
  id?: any;
  genId: any;
  image: string | null;
  isRequired: boolean;
  options: {
    id?: string | null;
    order: number;
    optionTitle: string;
  }[];
  order: number;
  questionType: QuestionType;
  title: string;
}

interface DynamicInputProps {
  error: boolean;
  isEmailExist: boolean;
  isFormAnswerSubmitted: boolean;
  isNameExist: boolean;
  sequence: number;
  value: Questions;
  onChange: (value: Questions) => void;
  onCopy?: (value: Questions) => void;
  onDelete: () => void;
  onDrag: (dragIndex: number, hoverIndex: number) => void;
}

export const DynamicInput = ({
  error,
  isEmailExist,
  isFormAnswerSubmitted,
  isNameExist,
  sequence,
  value,
  onChange,
  onCopy,
  onDelete,
  onDrag,
}: DynamicInputProps) => {
  const { genId, image, isRequired, options, questionType, title } = value;
  const [dialogStatus, setDialogStatus] = useState<DialogStatus>(DialogStatus.CLOSE);
  const [uploadedImage, setUploadedImage] = useState<string | null>(image);
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = sequence;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      onDrag(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => ({ id: genId, index: sequence }),
    type: ItemTypes.CARD,
  });

  const dropdownOptions = [
    {
      icon: <SingleSelectIcon alt="icon" height="16" src={shortAnswer} width="16" />,
      label: t('Short answer'),
      value: QuestionType.SHORT_ANSWER,
    },
    {
      icon: <SingleSelectIcon alt="icon" height="12" src={checkbox} width="12" />,
      label: t('Checkboxes'),
      value: QuestionType.CHECKBOX,
    },
    {
      icon: <SingleSelectIcon alt="icon" height="16" src={dropdown} width="16" />,
      label: t('Dropdown'),
      value: QuestionType.DROPDOWN,
    },
  ];
  const [dropDownOptions, setDropDownOption] = useState(dropdownOptions);

  useEffect(() => {
    let items = dropdownOptions;
    if (!isNameExist) {
      items = items.concat({
        icon: <SingleSelectIcon alt="icon" height="16" src={user} width="16" />,
        label: t('Name'),
        value: QuestionType.NAME,
      });
    }

    if (!isEmailExist) {
      items = items.concat({
        icon: <SingleSelectIcon alt="icon" height="16" src={mail} width="16" />,
        label: t('Email Address'),
        value: QuestionType.EMAIL,
      });
    }
    setDropDownOption(items);
  }, [isEmailExist, isNameExist]);

  const onChangeOption = (index: number, val: string) => {
    const items = [...options];
    items[index].optionTitle = val;
    onChange({ ...value, options: items });
  };

  const onChangeSelect = (val: QuestionType) => {
    onChange({
      ...value,
      image: null,
      isRequired: false,
      options: [QuestionType.CHECKBOX, QuestionType.DROPDOWN].includes(val) ? [{ optionTitle: '', order: 1 }] : [],
      questionType: val,
      title: '',
    });
    setUploadedImage(null);
  };

  const onChangeUploadImage = (val: string) => {
    setDialogStatus(DialogStatus.CLOSE);
    onChange({ ...value, image: val });
  };

  const onClickAddOption = () => {
    const nextOrder = options.length + 1;
    const items = options.concat({ optionTitle: '', order: nextOrder });
    onChange({ ...value, options: items });
  };

  const onClickAddOther = () => {
    const nextOrder = options.length + 1;
    const items = options.concat({ optionTitle: 'Other', order: nextOrder });
    onChange({ ...value, options: items });
  };

  const onClickRemoveImage = () => {
    setUploadedImage(null);
    onChange({ ...value, image: '' });
  };

  const onClickRemoveOption = (index: number) => {
    // having readonly properties with es6 clone, so using lodash cloneDeep
    const items = cloneDeep(options);
    items.splice(index, 1).map((item, key) => {
      item.order = key + 1;

      return items;
    });
    onChange({ ...value, options: items });
  };

  drag(drop(ref));

  return (
    <>
      <Popup
        contentStyle={styles.dialog}
        open={dialogStatus !== DialogStatus.CLOSE}
        onClose={() => setDialogStatus(DialogStatus.CLOSE)}
      >
        <>
          <div css={styles.dialogCloseBtn}>
            <Icon color="#c5d0da" icon="close" onClick={() => setDialogStatus(DialogStatus.CLOSE)} />
          </div>

          {dialogStatus === DialogStatus.DELETE ? (
            <>
              <div css={styles.dialogContent}>
                <div>{t('Are you sure you want to permanently delete this?')}</div>
                <div>
                  {t('Annotation.You might lost response data forever. Before continuing, export your responses')}
                </div>
                <div>{t('Annotation.Do you want to delete this question and lost responses')}</div>
              </div>

              <div css={styles.dialogAction}>
                <DialogButton
                  bgcolor="#fff"
                  color="#6e7c89"
                  hoverbgcolor="#fff"
                  title="No, cancel"
                  onClick={() => setDialogStatus(DialogStatus.CLOSE)}
                />
                <DialogButton
                  bgcolor="#3892e5"
                  color="#fff"
                  hoverbgcolor="#3892e5"
                  title="Yes, delete it"
                  onClick={onDelete}
                />
              </div>
            </>
          ) : (
            <>
              <div css={styles.dialogDropArea}>
                <div>{t('Title.Image Upload')}</div>
                <div>{`※${t('Annotation.You can upload one image')}`}</div>
                <PostFileField
                  accept="image/gif, image/jpeg, image/png"
                  css={styles.fileUploadContainer}
                  dropAreaCss={styles.fileUploadDropAreaCss}
                  initialFileUrls={[]}
                  multiple={false}
                  name="materialsUrl"
                  notes={[
                    t('DragAndDrop.Info'),
                    t('DragAndDrop.MaxSize', { maxSize: '10GB' }),
                    t('DragAndDrop.FileType', {
                      MIME: 'PNG, GIF, JPEG, AVI, MOV, MP4',
                    }),
                    t('DragAndDrop.ImageRatio'),
                  ]}
                  value={[]}
                  setFieldValue={(_attr, val) => onChangeUploadImage(val[0])}
                  setPreviewValue={images => setUploadedImage(images[0])}
                />
              </div>
            </>
          )}
        </>
      </Popup>
      <InputContainer data-handler-id={handlerId} isDragging={isDragging} ref={ref}>
        <div css={styles.dragIconContainer}>
          <img alt="moreIcon" height="16" src={moreHorizontal} width="16" />
        </div>

        <div css={styles.contentContainer}>
          {questionType === QuestionType.NAME ? (
            <div css={styles.nameContentContainer}>
              <div>
                <StyledTextForm
                  disabled
                  isRequired={isRequired}
                  placeholder={t('TextForm.First Name')}
                  title="First Name"
                />
              </div>
              <div>
                <StyledTextForm
                  disabled
                  isRequired={isRequired}
                  placeholder={t('TextForm.Last Name')}
                  title="Last Name"
                />
              </div>
            </div>
          ) : questionType === QuestionType.EMAIL ? (
            <div>
              <StyledTextForm disabled isRequired={isRequired} placeholder="sample@example.com" title="Email Address" />
            </div>
          ) : (
            <div css={styles.multiContentContainer}>
              <div>
                <div>
                  <StyledTextForm
                    isRequired={isRequired}
                    placeholder={t('TextForm.Question Title')}
                    value={title}
                    onChange={e => onChange({ ...value, title: e.target.value })}
                  />
                  {error && !title && <ErrorMessage message={t(QuestionErrorMessages.TITLE)} />}
                </div>
                <div>
                  <div>
                    <StyledSingleSelectField
                      name=""
                      options={dropDownOptions}
                      value={questionType}
                      setFieldValue={(_attr, val) => onChangeSelect(val as QuestionType)}
                    />
                    {error && !questionType && <ErrorMessage message={t(QuestionErrorMessages.TYPE)} />}
                  </div>
                  <ActionIcon disabled={!!uploadedImage} onClick={() => setDialogStatus(DialogStatus.UPLOAD)}>
                    <img alt="image" height="16" src={imageIcon} width="16" />
                  </ActionIcon>
                </div>
              </div>

              <div>
                {uploadedImage && (
                  <div css={styles.uploadedImageContainer}>
                    <div>
                      <img alt="image" src={uploadedImage} />
                    </div>
                    <div>
                      <Icon color="#2d2d2d" icon="close" onClick={onClickRemoveImage} />
                    </div>
                  </div>
                )}
                {[QuestionType.CHECKBOX, QuestionType.DROPDOWN].includes(questionType) ? (
                  <>
                    {options.map((option, index) => (
                      <div css={styles.optionField} key={index}>
                        {questionType === QuestionType.CHECKBOX ? (
                          <CheckBox label="" value={false} />
                        ) : (
                          <NumberText>{`${index + 1}.`}</NumberText>
                        )}
                        <div>
                          <StyledTextForm
                            placeholder={`${t('option')} ${index + 1}`}
                            value={option.optionTitle}
                            onChange={e => onChangeOption(index, e.target.value)}
                          />
                          {error && !option.optionTitle && (
                            <ErrorMessage message={t(QuestionErrorMessages.OPTION_TITLE)} />
                          )}
                        </div>
                        <div>
                          {options.length > 1 && (
                            <Icon color="#2d2d2d" icon="close" onClick={() => onClickRemoveOption(index)} />
                          )}
                        </div>
                      </div>
                    ))}
                    <div css={styles.addOption}>
                      {questionType === QuestionType.CHECKBOX ? (
                        <CheckBox label="" value={false} />
                      ) : (
                        <NumberText>{`${options.length + 1}.`}</NumberText>
                      )}
                      <span onClick={onClickAddOption}>{t('Add option')}&nbsp;</span>
                      {questionType === QuestionType.CHECKBOX && (
                        <>
                          <span>{t('or')}&nbsp;</span>
                          <span onClick={onClickAddOther}>{t('add other')}</span>
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <div css={styles.shortAnsContentContainer}>
                    <StyledTextForm disabled placeholder={t('TextForm.Short answer text')} />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div css={styles.actionContainer}>
          {![QuestionType.EMAIL, QuestionType.NAME].includes(questionType) && (
            <ActionIcon onClick={() => onCopy?.(value)}>
              <img alt="copy" height="16" src={copy} width="16" />
            </ActionIcon>
          )}
          <ActionIcon onClick={() => (isFormAnswerSubmitted ? setDialogStatus(DialogStatus.DELETE) : onDelete())}>
            <img alt="delete" height="16" src={trash} width="16" />
          </ActionIcon>
          <div css={styles.divider} />
          <div css={styles.switchContainer}>
            <span>{t(`Required`)}</span>
            <Switch isChecked={isRequired} handleChange={() => onChange({ ...value, isRequired: !value.isRequired })} />
          </div>
        </div>
      </InputContainer>
    </>
  );
};

const ActionIcon = styled.div<{ disabled?: boolean }>`
  align-items: center;
  background-color: #fff;
  border: 1px solid #dee5ec;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  margin-right: 8px;
  width: 32px;
  ${({ disabled }) =>
    disabled ? 'background-color: #eef3f7; border: 1px solid #dee5ec; cursor: default; pointer-events: none;' : ''}
`;

const DialogButton = styled(Button)<{ isPrimary?: boolean }>`
  border: 1px solid ${({ isPrimary }) => (isPrimary ? '#3892e5' : '#dee5ec')};
  border-radius: 5px;
  height: 32px;
  padding: 0 16px;
  width: fit-content;

  @media (max-width: ${ViewportType.MEDIUM}px) {
    border-radius: 3px;
    height: 40px;
    width: fill-available;
  }
`;

const InputContainer = styled.div<{ isDragging: boolean }>`
  background-color: #f6f8fa;
  border: 1px solid #dee5ec;
  border-radius: 3px;
  cursor: move;
  margin-bottom: 24px;
  opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};
  padding: 0 24px;
`;

const NumberText = styled.div`
  color: #27313b;
  font-size: 14px;
  margin-right: 8px;
`;

const SingleSelectIcon = styled.img`
  margin-right: 8px;
`;

const StyledSingleSelectField = styled(SingleSelectField)`
  & > label {
    font-size: 14px;
  }

  & > div > div {
    border-radius: 2px;
    min-height: 32px;

    & > div > div {
      align-items: center;
      display: flex;
    }
  }

  & input {
    border-radius: 2px;
    min-height: 32px;
  }
`;

const StyledTextForm = styled(TextForm)`
  & > label {
    font-size: 14px;
    font-weight: 600;
  }

  & input {
    border-radius: 3px;
    height: 32px;
  }
`;

const styles = {
  actionContainer: css`
    display: flex;
    justify-content: flex-end;
    padding: 16px 0;
  `,
  addOption: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;

    /* stylelint-disable no-descending-specificity */
    & > div {
      margin-right: 8px;
      padding: 0;
      width: fit-content;

      & > label {
        margin-right: 0;
      }
    }

    & > span:nth-of-type(1) {
      color: #6e7c89;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      text-decoration: underline;
    }

    & > span:nth-of-type(2) {
      color: #6e7c89;
      font-size: 14px;
      font-weight: 600;
    }

    & > span:nth-of-type(3) {
      color: #3892e5;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
    }
  `,
  contentContainer: css`
    border-bottom: 1px solid #dee5ec;
    padding-bottom: 16px;
  `,
  dialog: {
    borderRadius: '5px',
    boxShadow: '0 0 12px rgba(0, 0, 0, 0.12), 0 12px 12px rgba(0, 0, 0, 0.24)',
    maxWidth: 648,
    padding: 0,
    width: '90%',
  },
  dialogAction: css`
    border-top: 1px solid #dee5ec;
    display: flex;
    justify-content: flex-end;
    padding: 16px;

    & > button:nth-of-type(2) {
      margin-left: 16px;
    }
  `,
  dialogCloseBtn: css`
    align-items: center;
    background-color: #000;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: -10px;
    top: -15px;
    width: 40px;

    & > i {
      margin: 0;
    }
  `,
  dialogContent: css`
    padding: 24px;

    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 24px;
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: 14px;
      margin-bottom: 24px;
    }

    & > div:nth-of-type(3) {
      color: #ff5f5f;
      font-size: 14px;
    }
  `,
  dialogDropArea: css`
    padding: 24px;

    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: 14px;
      margin-bottom: 16px;
    }
  `,
  divider: css`
    border-left: 1px solid #dee5ec;
    margin-right: 8px;
  `,
  dragIconContainer: css`
    display: flex;
    justify-content: center;
  `,
  fileUploadContainer: css`
    & label {
      font-size: 13px;
    }
  `,
  fileUploadDropAreaCss: css`
    & .drop-area-notes {
      justify-content: center;
    }
  `,
  nameContentContainer: css`
    display: flex;
    flex-basis: 100%;

    & > div:nth-of-type(1) {
      margin-right: 10px;
      width: fill-available;
    }

    & > div:nth-of-type(2) {
      margin-left: 10px;
      width: fill-available;
    }
  `,
  multiContentContainer: css`
    & > div:nth-of-type(1) {
      align-items: center;
      display: flex;
      flex-wrap: wrap;

      & > div:nth-of-type(1) {
        display: grid;
        flex-basis: 50%;

        & > div {
          margin-right: 4px;
          width: fill-available;
        }
      }

      & > div:nth-of-type(2) {
        display: flex;
        flex-basis: 50%;

        & > div:nth-of-type(1) {
          margin: 0 8px 0 4px;
          width: fill-available;
        }
      }
    }
  `,
  optionField: css`
    align-items: center;
    display: flex;
    margin-top: 24px;

    & > div:nth-of-type(1) {
      margin-right: 8px;
      padding: 0;
      width: fit-content;

      & > label {
        margin-right: 0;
      }
    }

    & > div:nth-of-type(2) {
      width: 350%;
    }

    & > div:nth-of-type(3) {
      display: flex;
      justify-content: flex-end;
      width: fill-available;
    }
  `,
  shortAnsContentContainer: css`
    margin-top: 16px;
    width: 40%;
  `,
  switchContainer: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    & > span {
      color: #27313b;
      font-size: 14px;
      margin-right: 4px;
    }
  `,
  uploadedImageContainer: css`
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;

    & > div:nth-of-type(1) {
      background-color: #000;
      width: 213px;

      & > img {
        height: 120px;
        object-fit: contain;
        width: 100%;
      }
    }

    & > div:nth-of-type(2) {
      margin-left: 8px;
    }
  `,
};

export default DynamicInput;
