import React from 'react';
import AnalyticsContentWrapper, {
  ContentType,
  sectionStyles,
} from '@src/components/organisms/AnalyticsShared/AnalyticsContentWrapper';
import { useQuery } from '@apollo/client';
import PostingHabits from '@src/components/organisms/ProfileWidgets/PostingHabits';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { useQueryHelper } from '@src/libs/hooks';
import { ListIndicator } from '@src/components/molecules/Indicator';
import SentimentAnalysis from '@src/components/organisms/ProfileWidgets/SentimentAnalysis';
import { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import {
  InfluencerTwitterProfilePosts,
  InfluencerTwitterProfilePostsVariables,
} from './__generated__/InfluencerTwitterProfilePosts';
import * as INFLUENCER_PROFILE_POSTS from './InfluencerProfilePosts.graphql';
import AverageEngagementTwitter from './AverageEngagementTwitter';
import TwitterFeedPosts from './TwitterFeedPosts';

interface ProfilePostsProps {
  socialMediaAccountId: number;
  influencerAccountId: number;
  socialMedia: SocialAccountType;
}
export const ProfilePostsSection = ({ socialMediaAccountId, influencerAccountId, socialMedia }: ProfilePostsProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data, loading } = useQuery<InfluencerTwitterProfilePosts, InfluencerTwitterProfilePostsVariables>(
    INFLUENCER_PROFILE_POSTS,
    {
      variables: {
        pk: influencerAccountId,
        socialAccountId: socialMediaAccountId,
        socialMedia,
      },
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
    }
  );

  if (loading) {
    return <ListIndicator css={sectionStyles.loading} />;
  }

  const feedPosts = data?.influencerProfilePosts?.feedPosts || [];
  const postsHabit = data?.influencerProfilePosts?.postsHabit || [];
  const averageReplies = data?.influencerProfilePosts?.averageReplies || null;
  const averageLikes = data?.influencerProfilePosts?.averageLikes || null;
  const averagePostsPerWeek = data?.influencerProfilePosts?.averagePostsPerWeek || null;
  const averageRetweets = data?.influencerProfilePosts?.averageRetweets || null;
  const engagementRate = data?.influencerProfilePosts?.engagementRate || null;
  const hasCommentAnalytics =
    data?.influencerProfilePosts?.sentimentNegativeRatio ||
    data?.influencerProfilePosts?.sentimentPositiveRatio ||
    data?.influencerProfilePosts?.sentimentNeutralRatio;

  return (
    <AnalyticsContentWrapper contentType={ContentType.Posts} hasHelp={false}>
      <div css={sectionStyles.responsiveWrapper}>
        <AverageEngagementTwitter
          averagePostsPerWeek={averagePostsPerWeek}
          engagementRate={engagementRate}
          averageLikes={averageLikes}
          averageRetweets={averageRetweets}
          averageReplies={averageReplies}
        />
        <SentimentAnalysis
          commentAnalytics={
            hasCommentAnalytics
              ? {
                  negativeRatio: data?.influencerProfilePosts?.sentimentNegativeRatio || null,
                  neutralRatio: data?.influencerProfilePosts?.sentimentNeutralRatio || null,
                  positiveRatio: data?.influencerProfilePosts?.sentimentPositiveRatio || null,
                }
              : null
          }
          css={[sectionStyles.middleSection, sectionStyles.verticalContaner]}
        />
        <PostingHabits postsHabit={postsHabit} css={sectionStyles.verticalContaner} />
      </div>
      <TwitterFeedPosts
        influencerAccountId={influencerAccountId}
        posts={feedPosts}
        header={<SectionHeader>{t('Title.Feed Posts')}</SectionHeader>}
      />
    </AnalyticsContentWrapper>
  );
};
