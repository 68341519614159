import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Label from '../../atoms/Label';
import Text, { TextProps } from '../../atoms/InputText/Text';

interface TextFormProps extends TextProps {
  className?: string;
  title?: string;
  isRequired?: boolean;
  help?: string;
  autoFocus?: boolean;
}

const TextForm = (props: TextFormProps) => {
  const { t } = useTranslation();
  const { className, title, isRequired, help, placeholder, error, autoFocus, ...field } = props;
  const translatedTitle = title ? t(`TextForm.${title}`) : '';

  return (
    <div className={className}>
      {title && <Label title={translatedTitle} isRequired={isRequired} help={help} />}
      <Text placeholder={placeholder} error={error} {...field} autoFocus={autoFocus} />
    </div>
  );
};

export default TextForm;
