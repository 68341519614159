import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { SortType } from '@src/libs/filter';
import { getOffset, getPageInfo } from '@src/libs/paging';
import { LIMIT } from '@src/libs/paging/constant';
import {
  FacebookPageAnalyticsPostsSortInput,
  FacebookPageAnalyticsPostsSortOrder,
} from '@src/__generated__/globalTypes';
import { useQueryHelper, useAuthData, useUpdateDocumentTitle } from '@src/libs/hooks';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { useMediaQuery } from 'react-responsive';
import { ViewportType } from '@src/libs/theme';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import styled from '@emotion/styled';
import Pager from '@src/components/molecules/Pager';
import PostFeedFilterForm from '@src/components/organisms/PostFeedList/PostFeedFilters';
import FeedPostsTable from '@src/components/organisms/AnalyticsFacebookPage/AnalyticsPosts/FeedPosts/FeedPostsTable';
import { Period } from '@src/components/molecules/DayPicker/types';
import { BasePostFilterType } from '@src/components/organisms/AnalyticsShared/BasePostListFilter/types';
import PageCount from '@src/components/molecules/PageCount';
import {
  InfluencerFacebookPageAnalyticsPosts,
  InfluencerFacebookPageAnalyticsPostsVariables,
} from './__generated__/InfluencerFacebookPageAnalyticsPosts';
import * as INFLUENCER_FB_PAGE_FEED_LIST from './InfluencerFacebookPageAnalyticsPosts.graphql';

interface TemplateProps {
  currentPage: number;
  filter: { keyword: string; period: Period };
  setFilter: (filter: BasePostFilterType) => void;
  sort: SortType<FacebookPageAnalyticsPostsSortOrder>;
  setSort: (sort: SortType<FacebookPageAnalyticsPostsSortOrder>) => void;
}

const Template = ({ filter, setFilter, sort, setSort, currentPage }: TemplateProps) => {
  const { t, enqueueSnackbar, search } = useQueryHelper();
  const query = new URLSearchParams(search);
  const { userId } = useAuthData();
  const socialMediaAccountId = query.get('id');
  const influencerId = Number(userId);
  const socialAccountId = Number(socialMediaAccountId);
  const [{ accountName, avatarUrl }, setAccountInfo] = useState({ accountName: '', avatarUrl: '' });
  const isDesktopView = useMediaQuery({ minWidth: ViewportType.TABLET });
  const { setDocumentAndAppTitles } = useUpdateDocumentTitle({
    title: 'pageTitle.Post List',
  });

  const { data, loading } = useQuery<
    InfluencerFacebookPageAnalyticsPosts,
    InfluencerFacebookPageAnalyticsPostsVariables
  >(INFLUENCER_FB_PAGE_FEED_LIST, {
    variables: {
      influencerId,
      socialAccountId,
      keyword: filter.keyword,
      offset: getOffset(currentPage),
      limit: LIMIT,
      orderBy: sort as FacebookPageAnalyticsPostsSortInput,
      ...filter.period,
    },
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    onCompleted: ({ influencerProfileV2 }) => {
      setAccountInfo({ accountName: influencerProfileV2?.name || '', avatarUrl: influencerProfileV2?.avatar || '' });
      setDocumentAndAppTitles({
        title: !!influencerProfileV2?.name ? 'pageTitle.PostListWithAccountName' : 'pageTitle.Post List',
        interpolation: {
          title: influencerProfileV2?.name || '',
        },
      });
    },
    fetchPolicy: 'cache-first',
  });

  if (loading) {
    return (
      <ListIndicator
        css={css`
          margin: 0;
        `}
      />
    );
  }

  const { totalCount, totalPages, firstIndex, lastIndex } = getPageInfo(currentPage, data?.getTotalNumber.totalNumber);

  return (
    <Wrapper>
      {isDesktopView && (
        <div css={{ marginBottom: '16px' }}>
          <BackNavigator
            to={`/analytics${search}`}
            title="PostListWithAccountName"
            interpolation={{ title: accountName }}
          />
        </div>
      )}
      <Card>
        <PostFeedFilterForm filter={filter} setFilter={setFilter} />
        <CountWrapper>
          <PageCount total={totalCount} countLabel="Posts" />
        </CountWrapper>
        <FeedPostsTable
          posts={data?.influencerFacebookPageAnalyticsPosts.posts || []}
          sort={sort}
          setSort={setSort}
          firstIndex={firstIndex}
          socialMediaAccountId={socialAccountId}
          isInfluencerPage
          influencerAccountId={influencerId}
          accountName={accountName}
          avatarUrl={avatarUrl}
        />
      </Card>
      <Pager
        currentPage={currentPage}
        totalPages={totalPages}
        totalCount={totalCount}
        first={firstIndex}
        last={lastIndex}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
  width: calc(100% - 32px);
`;

const Card = styled.div`
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5);
  background-color: #fff;
`;
const CountWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 35px;
  padding: 8px 15px;
  background-color: #fff;
  border-top: 1px solid #dee5ec;
  border-bottom: 1px solid #dee5ec;
`;

export default Template;
