import { Formik } from 'formik';
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { MENA_COUNTRIES } from '@src/libs/constant';
import { getBankOptions, getOptions } from '@src/libs/form';
import { useAuthData, useQueryHelper } from '@src/libs/hooks';
import { JPPaymentInformationSchema, PaymentInformationSchema } from '@src/libs/validation';
import UPDATE_PAYMENT_INFORMATION from './mutations/UpdateInfluencerPaymentInformation.graphql';
import {
  UpdateInfluencerPaymentInformation,
  UpdateInfluencerPaymentInformationVariables,
} from './mutations/__generated__/UpdateInfluencerPaymentInformation';
import PaymentInformation, { Information } from './PaymentInformation';
import INFLUENCER_INFORMATION from './queries/InfluencerInformation.graphql';
import { InfluencerInformation, InfluencerInformationVariables } from './queries/__generated__/InfluencerInformation';

const Index = () => {
  const { userId } = useAuthData();
  const { enqueueSnackbar, t } = useQueryHelper();

  const { data, loading } = useQuery<InfluencerInformation, InfluencerInformationVariables>(INFLUENCER_INFORMATION, {
    fetchPolicy: 'no-cache',
    variables: {
      pk: userId as number,
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [updatePaymentInformation] = useMutation<
    UpdateInfluencerPaymentInformation,
    UpdateInfluencerPaymentInformationVariables
  >(UPDATE_PAYMENT_INFORMATION, {
    onCompleted: () => {
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  function onSubmit(values: Information) {
    const {
      accountName,
      accountNumber,
      address,
      bankId,
      branchId,
      branchName,
      cityId,
      email,
      fullName,
      iban,
      postalCode,
      subject,
      swiftCode,
    } = values;

    updatePaymentInformation({
      variables: {
        input: {
          accountName,
          accountNumber,
          address,
          bankId: Number(bankId),
          branchId: branchId ? Number(branchId) : null,
          branchName,
          cityId: cityId ? Number(cityId) : null,
          email,
          fullName,
          iban,
          postalCode,
          subject,
          swiftCode,
        },
      },
    });
  }

  const allBanks = data?.allBanksForInfluencer ? getBankOptions(data.allBanksForInfluencer) : [];
  const allCities = data?.allCitiesForInfluencer ? getOptions(data.allCitiesForInfluencer) : [];
  const country = data?.influencerProfileV2?.country;
  const hasBranchId = allBanks.some(({ hasBranches }) => hasBranches);
  const isMenaInfluencer = country ? !!MENA_COUNTRIES.find(menaCountry => menaCountry.value === country.id) : false;
  const isJPInfluencer = country?.id === 'JP';
  const paymentInformation = data?.influencerPaymentInformation
    ? { ...data.influencerPaymentInformation, email: data.influencerPaymentInformation.paymentEmail }
    : {
        accountName: '',
        accountNumber: '',
        address: '',
        bankId: 0,
        branchId: 0,
        branchName: '',
        cityId: 0,
        email: '',
        fullName: '',
        iban: '',
        postalCode: '',
        subject: '',
        swiftCode: '',
      };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        accountName: paymentInformation.accountName || '',
        accountNumber: paymentInformation.accountNumber || '',
        address: paymentInformation.address || '',
        bankId: paymentInformation.bankId?.toString() || '',
        branchId: paymentInformation.branchId?.toString() || '',
        branchName: paymentInformation.branchName || '',
        cityId: paymentInformation.cityId?.toString() || '',
        email: paymentInformation.email || '',
        fullName: paymentInformation.fullName || '',
        hasBranchId,
        iban: paymentInformation.iban || '',
        isMenaInfluencer,
        postalCode: paymentInformation.postalCode || '',
        subject: paymentInformation.subject || '',
        swiftCode: paymentInformation.swiftCode || '',
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={isJPInfluencer ? JPPaymentInformationSchema : PaymentInformationSchema}
      onSubmit={onSubmit}
    >
      <PaymentInformation
        allBanks={allBanks}
        allCities={allCities}
        isLoading={loading}
        isMenaInfluencer={isMenaInfluencer}
        isJPInfluencer={isJPInfluencer}
      />
    </Formik>
  );
};

export default Index;
