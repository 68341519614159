import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icon } from '@src/components/atoms/Icon';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { useQueryHelper } from '@src/libs/hooks';
import PostDetails from './PostDetails';
import PostHistory from './PostHistory';
import CAMPAIGN_POST_DETAILS_HISTORY from './queries/CampaignPostDetailsHistory.graphql';
import {
  CampaignPostDetailsHistory,
  CampaignPostDetailsHistoryVariables,
} from './queries/__generated__/CampaignPostDetailsHistory';

interface DialogContentProps {
  postId: number;
  onClose: () => void;
}

const DialogContent = ({ postId, onClose }: DialogContentProps) => {
  const [currTab, setCurrTab] = useState<string>('DETAILS');
  const { enqueueSnackbar, t } = useQueryHelper();
  const tabs = [
    { title: 'Post Detail', value: 'DETAILS' },
    { title: 'History', value: 'HISTORY' },
  ];

  const { data } = useQuery<CampaignPostDetailsHistory, CampaignPostDetailsHistoryVariables>(
    CAMPAIGN_POST_DETAILS_HISTORY,
    {
      fetchPolicy: 'no-cache',
      variables: {
        pk: postId,
      },
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  const postDetails = data?.engagementPostDetailForInfluencer;
  const postHistory = data?.engagementDraftPostHistoryForInfluencer?.historyRecords || [];

  return (
    <div>
      <div css={styles.tabsContainer}>
        {tabs.map(tab => (
          <Tab active={tab.value === currTab} key={tab.value} onClick={() => setCurrTab(tab.value)}>
            {t(tab.title)}
          </Tab>
        ))}
      </div>

      <div css={styles.closeBtn} onClick={onClose}>
        <Icon icon="close" />
      </div>

      {postDetails && postHistory ? (
        currTab === 'DETAILS' ? (
          <PostDetails postDetails={postDetails} />
        ) : (
          <PostHistory postHistory={postHistory} />
        )
      ) : (
        <ListIndicator />
      )}
    </div>
  );
};

const Tab = styled.div<{ active: boolean }>`
  align-items: center;
  background-color: ${({ active }) => (active ? '#fff' : '#eef3f7')};
  color: ${({ active }) => (active ? '#27313b' : '#6e7c89')};
  cursor: pointer;
  display: flex;
  font-weight: 600;
  height: 32px;
  justify-content: center;
  padding: 0 16px;
`;

const styles = {
  closeBtn: css`
    align-items: center;
    background-color: #000;
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: -12px;
    top: 17px;
    width: 40px;
  `,
  tabsContainer: css`
    display: flex;
    flex-wrap: wrap;

    & > div:nth-of-type(1) {
      border-top-left-radius: 5px;
    }

    & > div:nth-of-type(2) {
      border-top-right-radius: 5px;
    }
  `,
};

export default DialogContent;
