import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useQueryHelper } from '@src/libs/hooks';
import { LayoutProps } from '@src/components/organisms/Layout';
import { CampaignPageType } from '@src/libs/campaign';
import { checkSignedIn } from '@src/libs/auth';
import IframeLayout from '@src/components/organisms/Layout/IframeLayout';

interface PageType {
  footerPadding?: number;
  page?: CampaignPageType;
}
interface IframeAppRouteProps extends RouteProps, LayoutProps, PageType {
  component: React.FC<any>;
}

const IframeAppRoute = ({ component: Component, footerPadding, page, children, ...rest }: IframeAppRouteProps) => {
  const { history } = useQueryHelper();
  const isSignedIn = checkSignedIn();

  if (!isSignedIn) {
    localStorage.setItem('redirectUrlBeforeSignIn', history.location.pathname);
    history.push({ pathname: '/', state: { from: history.location } });
  }

  return (
    <Route
      {...rest}
      render={props => (
        <>
          <IframeLayout footerPadding={footerPadding}>
            <Component {...props} page={page} />
          </IframeLayout>
          {children}
        </>
      )}
    />
  );
};

export default IframeAppRoute;
