import { CampaignType, CampaignSocialMediaType } from '../../../../__generated__/globalTypes';
import { useLanguageLinkSpecifier } from '../../Menu/helpers';
import { AllJobsProps } from './types';

export const getCampaignPrice = (campaign: AllJobsProps) => {
  const isEngagementCampaign = campaign.type === CampaignType.ENGAGEMENT;
  const hasFbReaction = !isEngagementCampaign && campaign.socialMedias[0] === CampaignSocialMediaType.FACEBOOK;

  const prices = isEngagementCampaign
    ? []
    : [
        {
          value: campaign.revenuePerPost,
          unit: 'Post',
        },
        {
          value: campaign.revenuePerLike,
          unit: hasFbReaction ? 'Reaction' : 'Like',
        },
        {
          value: campaign.revenuePerView,
          unit: 'View',
        },
        {
          value: campaign.revenuePerShare,
          unit: 'Share',
        },
        {
          value: campaign.revenuePerComment,
          unit: 'Comment',
        },
        {
          value: campaign.revenuePerFollower,
          unit: 'Follower',
        },
        {
          value: campaign.revenuePerClick,
          unit: 'Click',
        },
        {
          value: campaign.revenuePerAction,
          unit: 'Action',
        },
      ];

  return prices;
};

export const fbPermissionWarningHeader = 'Permission is required';
export const fbPermissionWarningBody =
  'Please grant permission before you join any job otherwise the system cannot find your post. You can change the setting when you sign-in';

export const getFbPermissionArticleLink = () => {
  const articlesLinks: Record<string, string> = {
    // currently we do have articles just for en|ja
    '': 'https://anymind-group.gitbook.io/anycreator-help-page/faq/how-to-grant-permissions',
    ja: 'https://anymind-group.gitbook.io/anycreatorhelp/yokuaru/akusesunogawakaranai',
    // id: '',
    // th: '',
    // ch: '',
    // km: '',
    // my: '',
    // zh: '',
    // vi: '',
  };
  const language = useLanguageLinkSpecifier();

  return articlesLinks[language];
};

export const getPostUrlArticleLink = () =>
  'https://intercom.help/anycreator-pte-ltd/en/articles/4841470-how-to-get-the-post-url';
