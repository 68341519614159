import { useEffect, useState } from 'react';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { SocialAccountType, AuthSocialAccountType } from '@src/__generated__/globalTypes';
import { useRedirectUrl } from '@src/libs/hooks/useRedirectUrl';
import { useQueryHelper, useRedirectWithinIframe } from '@src/libs/hooks';

export const useConnect = (redirectType: FE_REDIRECT_MAPPING) => {
  const { getRedirectUrl, loading: mutationLoading } = useRedirectUrl(redirectType);
  const [loading, setLoading] = useState<boolean>(false);
  const [redirectUrlFacebook, setRedirectUrlFacebook] = useState<string | null>(null);
  const { t, enqueueSnackbar } = useQueryHelper();
  const { startRedirectProcess } = useRedirectWithinIframe();

  // Fetch redirectUri for Facebook beforehand, because of Facebook policy.
  // https://adasiaholdings.atlassian.net/browse/CAS-1143
  const getRedirectUrlFacebook = async () => {
    const redirectUrl = await getRedirectUrl(AuthSocialAccountType.FACEBOOK);
    setRedirectUrlFacebook(redirectUrl);
  };
  useEffect(() => {
    getRedirectUrlFacebook();
  }, []);

  const onClick = (socialMedia: SocialAccountType | AuthSocialAccountType) => async () => {
    setLoading(true);

    // Set RedirectType for Twitter OAuth
    if (socialMedia === SocialAccountType.TWITTER) {
      localStorage.setItem('redirectType', redirectType);
    }

    try {
      const redirectUrl = [AuthSocialAccountType.FACEBOOK, SocialAccountType.INSTAGRAM].includes(socialMedia)
        ? redirectUrlFacebook
        : await getRedirectUrl(socialMedia);

      if (redirectUrl) {
        // Redirect to SocialMedia
        startRedirectProcess({ redirectUrl, redirectType: FE_REDIRECT_MAPPING.CONNECT });
      }
    } catch (error) {
      console.error('error: ', error);
      enqueueSnackbar(t('UnexpectedError'), { variant: 'error' });
    } finally {
      setLoading(false);
    }

    return;
  };

  return {
    onClick,
    loading: loading || mutationLoading,
  };
};
