import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { ViewportBreakpoint } from '../../../libs/theme';

interface ListProps {
  title: string;
  href: string;
  icon?: React.ReactNode | string;
  className?: string;
}

const LinkWithIcon = (props: ListProps) => {
  const { title, href, icon = 'chevron_right', className } = props;

  return (
    <ListItem className={className} to={href}>
      <Title>{title}</Title>
      <Icon className="material-icons">{icon}</Icon>
    </ListItem>
  );
};

const ListItem = styled(Link)`
  display: flex;
  justify-content: space-between;
  padding: 15px 10px 15px 16px;
  border-top: 1px solid #eef3f7;
  align-items: center;

  &:hover {
    color: #179cd7;
    text-decoration: underline;

    @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
      color: #3b4146;
      text-decoration: none;
    }

    i {
      opacity: 0.7;
    }
  }
`;

const Title = styled.span``;

const Icon = styled.i`
  color: #ff2b52;
  font-size: 18px;
  margin-left: 10px;

  &:hover {
    text-decoration: none !important;
  }
`;

export default LinkWithIcon;
