import styled from '@emotion/styled';
import React from 'react';
import ContentLoader from 'react-content-loader';

const ProfileCoverSkeleton = () => (
  <StaticBackgroundColor>
    <ContentLoader speed={2} width="100%" height={200} backgroundColor="#e1dde4" foregroundColor="#eeecef">
      <rect x="121" y="124" rx="3" ry="3" width="88" height="13" />
      <rect x="122" y="150" rx="3" ry="3" width="140" height="13" />
      <circle cx="69" cy="142" r="44" />
    </ContentLoader>
  </StaticBackgroundColor>
);

const StaticBackgroundColor = styled.div`
  background-color: #f7f8fa;
`;

export default ProfileCoverSkeleton;
