import React from 'react';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { SocialMediaAccountType } from '../types';
import {
  FacebookProfileV2,
  InstagramProfileV2,
  YoutubeProfileV2,
  TwitterProfileV2,
  TiktokProfileV2,
} from './profileV2Templates';

interface DefaultProfileProps {
  selectedSocialMedia: SocialMediaAccountType;
  userId: number;
}

const DefaultProfile = ({ selectedSocialMedia, userId }: DefaultProfileProps) => {
  switch (selectedSocialMedia.socialAccountType) {
    case SocialAccountType.INSTAGRAM:
      return <InstagramProfileV2 selectedSocialMedia={selectedSocialMedia} influencerId={userId} />;

    case SocialAccountType.FACEBOOK:
    case SocialAccountType.FACEBOOK_PAGE:
      return <FacebookProfileV2 selectedSocialMedia={selectedSocialMedia} influencerId={userId} />;

    case SocialAccountType.YOUTUBE:
      return <YoutubeProfileV2 selectedSocialMedia={selectedSocialMedia} influencerId={userId} />;

    case SocialAccountType.TWITTER:
      return <TwitterProfileV2 selectedSocialMedia={selectedSocialMedia} influencerId={userId} />;

    case SocialAccountType.TIKTOK:
      return <TiktokProfileV2 selectedSocialMedia={selectedSocialMedia} influencerId={userId} />;

    default:
      return null;
  }
};

export default DefaultProfile;
