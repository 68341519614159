import React, { useState } from 'react';
import styled from '@emotion/styled';
import { CircularProgress } from '@material-ui/core';
import { css } from '@emotion/core';
import { useQuery } from '@apollo/client';
import AnalyticsContentWrapper, {
  ContentType,
} from '@src/components/organisms/AnalyticsShared/AnalyticsContentWrapper';
import { Period } from '@src/components/molecules/DayPicker/types';
import { useQueryHelper } from '@src/libs/hooks';
import { SocialMediaAccountType } from '@src/components/organisms/ProfileDetail/types';
import { AddAccout } from '@src/components/organisms/AnalyticsShared/AccountCardsStyled';
import { AddEngagementTilesModal } from '@src/components/organisms/AnalyticsShared/Overview/AddEngagementTilesModal';
import Template from './template';
import { defaultSelectableCharts, ChartColorTypes, additionalSelectableCharts } from './helpers';
import {
  InfluencerInstagramDashboardOverview,
  InfluencerInstagramDashboardOverviewVariables,
} from './__generated__/InfluencerInstagramDashboardOverview';
import * as INFLUENCER_INSTAGRAM_DASHBOARD_OVERVIEW from './InfluencerInstagramDashboardOverview.graphql';

interface OverviewCardProps {
  selectedSocialMedia: SocialMediaAccountType;
  selectedPeriod: Period;
  userId: number;
  className?: string;
}

const OverviewCard = ({ selectedSocialMedia, selectedPeriod }: OverviewCardProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [savedEngagementTiles, setSavedEngagementTiles] = useState<ChartColorTypes[]>(defaultSelectableCharts);
  const [allSelectedCheckboxes, setSelectedCheckboxes] = useState<string[]>(savedEngagementTiles);

  const variables = {
    startDate: selectedPeriod.startDate,
    endDate: selectedPeriod.endDate,
    socialAccountId: selectedSocialMedia.id,
  };
  const { data, loading, error } = useQuery<
    InfluencerInstagramDashboardOverview,
    InfluencerInstagramDashboardOverviewVariables
  >(INFLUENCER_INSTAGRAM_DASHBOARD_OVERVIEW, { variables });

  if (error) {
    enqueueSnackbar(t(error.message), { variant: 'error' });

    return null;
  }

  const handleEngagementSave = () => {
    setAnchorEl(null);
    setSavedEngagementTiles(allSelectedCheckboxes as ChartColorTypes[]);
  };

  return (
    <>
      <AnalyticsContentWrapper
        contentType={ContentType.Overview}
        hasHelp={false}
        addButton={<StyledAddButton onClick={e => setAnchorEl(e.currentTarget)}>+</StyledAddButton>}
      >
        {loading ? (
          <LoaderWrapper>
            <CircularProgress
              size={64}
              thickness={2.4}
              css={css`
                margin: 240px auto;
                color: #e0e8ed;
              `}
            />
          </LoaderWrapper>
        ) : (
          <Template
            selectedSocialMedia={selectedSocialMedia}
            overviewStats={data?.dashboardOverview || null}
            allEngagements={savedEngagementTiles}
          />
        )}
      </AnalyticsContentWrapper>
      <AddEngagementTilesModal
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        savedEngagementTiles={savedEngagementTiles}
        setSelectedCheckboxes={setSelectedCheckboxes}
        selectableCharts={additionalSelectableCharts}
        allSelectedCheckboxes={allSelectedCheckboxes}
        handleEngagementSave={handleEngagementSave}
      />
    </>
  );
};

const LoaderWrapper = styled.div`
  background-color: #fff;
  text-align: center;
  height: 554px;
`;
const StyledAddButton = styled(AddAccout)`
  width: 32px;
  height: 32px;
  flex: unset;
  margin-left: auto;
  margin-right: 16px;
  font-size: 24px;
  border: solid 1px #c5d0da;
  border-radius: 3px;
  align-items: baseline;
  background-color: #f6f8fa;
`;
export default OverviewCard;
