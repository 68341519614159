import React from 'react';
import { AnalyticsTemplateProps } from '@src/components/organisms/AnalyticsShared/types';
import styled from '@emotion/styled';
import AudienceCard from './AudienceCard';
import PostCard from './PostCard';
import OverviewCard from './OverviewCard';

const IgDashboard = (props: AnalyticsTemplateProps) => (
  <>
    <OverviewWrapper {...props} />
    <AudienceWrapper {...props} />
    <PostWrapper {...props} />
  </>
);

export default IgDashboard;

const AudienceWrapper = styled(AudienceCard)`
  width: 100%;
  box-sizing: border-box;
  margin-top: 16px;
`;

const OverviewWrapper = styled(OverviewCard)`
  background-color: #fff;
  box-sizing: border-box;
  width: 100%;
  margin-top: 16px;
`;

const PostWrapper = styled(PostCard)`
  width: 100%;
  box-sizing: border-box;
  margin-top: 16px;
`;
