import styled from '@emotion/styled';
import { ViewportBreakpoint } from '@src/libs/theme';
import TextCutter from '@src/components/atoms/TextCutter';

export const OverviewWrapper = styled.section`
  display: flex;
  flex-direction: column;
  border-radius: 0 0 3px 3px;
  background-color: #fff;
  color: #27313b;
  border-top: 0;
`;
export const EngagementGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(165px, auto));

  @media (max-width: ${ViewportBreakpoint.MOBILE}px) {
    grid-template-columns: repeat(auto-fit, minmax(100px, auto));
  }

  overflow: hidden;
  background-color: white;
  border-top: 1px solid #dee5ec;
  border-bottom: 1px solid #dee5ec;
  gap: 1px;
`;
export const EngagementGridCell = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  /* trick to collapse borders */
  outline: 1px solid #dee5ec;
`;
export const EngagementTileIcon = styled.img`
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  background-color: #fff;
  margin: 2px;

  @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
    width: 32px;
    height: 32px;
  }
`;
export const EngagementTitle = styled(TextCutter)`
  font-weight: normal;
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.28px;
  padding: 16px;
  padding-bottom: 0;
  padding-right: 32px;
`;
export const TotalNumber = styled.span`
  font-weight: 600;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.3px;
  padding-bottom: 4px;
`;
export const Growth = styled.div<{ isPositive?: boolean }>`
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${({ isPositive }) => (isPositive === undefined ? 'inherit' : isPositive ? '#40b87c' : '#ff2b52')};
  white-space: pre-wrap;
`;
export const PartWithIcon = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const EngagementNumbers = styled.div`
  display: flex;
  flex-flow: column wrap;
  padding: 16px;
  padding-top: 4px;
`;

export const Chart = styled.div``;
export const LegendBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px 12px;
`;
export const LegendTileColored = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  margin-right: 8px;
  border-radius: 3px;
  border: solid 1px #dee5ec;
  background-color: #f6f8fa;
  cursor: pointer;
  margin-bottom: 8px;

  span {
    width: 8px;
    height: 8px;
    border-radius: 2px;
  }
`;
export const Text = styled.p`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #27313b;
  padding: 0 4px;
`;
export const LegendTile = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  margin-right: 8px;
  cursor: pointer;
`;
