import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import css from '@emotion/css';
import { ViewportBreakpoint } from '../../../../libs/theme';
import { CampaignType } from '../../../../__generated__/globalTypes';
import { switchImage } from '../../../../libs/SocialMedia';
import { getCampaignUrl, CampaignPageType } from '../../../../libs/campaign';
import CampaignCard, { CampaignCardProps } from '../CampaignCard';
import { useYourJobPageCount } from '../../../../libs/hooks';
import { EmptyYourJob, EmptySearchJob } from '../../../molecules/EmptyCampaignList';
import { defaultCampaignThumbnail } from '../../../../libs/image';
import { WarningWidget } from '../../../molecules/WarningWidget';
import {
  getCampaignPrice,
  fbPermissionWarningHeader,
  fbPermissionWarningBody,
  getFbPermissionArticleLink,
} from './helper';
import { AllJobsProps } from './types';
import * as CHECK_FB_TOKEN from './CheckFacebookToken.graphql';
import { CheckFacebookToken } from './__generated__/CheckFacebookToken';

export interface CampaignListProps {
  data?: AllJobsProps[] | null;
  showCampaignBadge?: boolean;
  page: CampaignPageType;
  totalCount?: {
    progressCampaignCount: number;
    finishedCampaignCount: number;
  } | null;
  showingInFinishedCampaign?: boolean;
}

const CampaignList = (props: CampaignListProps) => {
  const { data, showCampaignBadge, page, totalCount, showingInFinishedCampaign = false } = props;
  const { updateYourJobPageCount } = useYourJobPageCount();
  const { data: fbPermissionData } = useQuery<CheckFacebookToken>(CHECK_FB_TOKEN, { fetchPolicy: 'cache-and-network' });
  const hasFbGrantPermissionWarning =
    fbPermissionData?.socialAuthCheckFacebookToken?.needReconnect && page !== CampaignPageType.JOB_FINISHED;

  useEffect(() => {
    updateYourJobPageCount(totalCount?.progressCampaignCount || 0, totalCount?.finishedCampaignCount || 0);
  }, []);

  const warningWidgetProps = {
    buttonText: 'Check details',
    targetBlank: true,
    buttonLink: getFbPermissionArticleLink(),
  };

  if (!data?.length) {
    return (
      <EmptyListWrapper flexDirection={hasFbGrantPermissionWarning ? 'column' : 'row'}>
        {hasFbGrantPermissionWarning && (
          <WarningWrapper>
            <WarningWidget
              warningTitle={fbPermissionWarningHeader}
              warningReason={fbPermissionWarningBody}
              {...warningWidgetProps}
            />
          </WarningWrapper>
        )}
        {page === CampaignPageType.SEARCH && <EmptySearchJob />}
        {[CampaignPageType.JOB, CampaignPageType.JOB_FINISHED].includes(page) && <EmptyYourJob />}
      </EmptyListWrapper>
    );
  }

  const allCampaigns: CampaignCardProps[] = data.map(campaign => ({
    url: getCampaignUrl(page, campaign.campaignId, campaign.type),
    thumbNail: defaultCampaignThumbnail(campaign.thumbNail),
    title: campaign.title,
    icon: campaign.type === CampaignType.MARKETPLACE ? switchImage(campaign.socialMedias[0]) : null,
    campaignType: campaign.type,
    prices: getCampaignPrice(campaign)
      .filter((price): price is { value: number; unit: string } => !!price.value)
      .slice(0, 3),
    currency: campaign.currency,
    start: campaign.startDate,
    end: campaign.endDate,
    remainingAmount: campaign.remainingAmount,
    totalAmount: campaign.totalAmount,
    campaignId: campaign.campaignId,
    hasReport: campaign.hasReport,
    hasDraftPosts: campaign.hasDraftPosts,
    hasWarningPosts: campaign.hasWarningPosts,
    campaignStatus: campaign.status,
    marketplaceCampaignType: campaign.campaignType,
    isApplying: campaign.isApplying,
    showingInFinishedCampaign,
    appliedStatus: campaign.appliedStatus,
    postStatus: campaign.postStatus,
  }));

  const hasMoreThanOneCard = allCampaigns.length > 1;

  return (
    <>
      {hasFbGrantPermissionWarning && (
        <WarningWrapper
          css={css`
            padding-bottom: 0;
          `}
        >
          <WarningWidget
            warningTitle={fbPermissionWarningHeader}
            warningReason={fbPermissionWarningBody}
            {...warningWidgetProps}
          />
        </WarningWrapper>
      )}
      <Wrapper hasTwoColumns={hasMoreThanOneCard}>
        {allCampaigns.map(campaign => (
          <CampaignCard
            key={campaign.url}
            url={campaign.url}
            thumbNail={campaign.thumbNail}
            title={campaign.title}
            icon={campaign.icon}
            prices={campaign.prices}
            currency={campaign.currency}
            start={campaign.start}
            end={campaign.end}
            remainingAmount={campaign.remainingAmount}
            totalAmount={campaign.totalAmount}
            campaignType={campaign.campaignType}
            showCampaignBadge={showCampaignBadge}
            campaignId={campaign.campaignId}
            hasReport={campaign.hasReport}
            hasDraftPosts={campaign.hasDraftPosts}
            hasWarningPosts={campaign.hasWarningPosts}
            campaignStatus={campaign.campaignStatus}
            marketplaceCampaignType={campaign.marketplaceCampaignType}
            isApplying={campaign.isApplying}
            showingInFinishedCampaign={showingInFinishedCampaign}
            appliedStatus={campaign.appliedStatus}
            postStatus={campaign.postStatus}
          />
        ))}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.section<{ hasTwoColumns: boolean }>`
  text-align: center;
  columns: ${({ hasTwoColumns }) => (hasTwoColumns ? '2' : '1')};
  column-gap: 16px;
  padding: 24px 34px;

  @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
    columns: 1;
    padding: 16px;
  }

  @media (min-width: ${ViewportBreakpoint.DESKTOP}px) {
    padding: 24px 0;
  }
`;
const EmptyListWrapper = styled.div<{ flexDirection: string }>`
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: ${({ flexDirection }) => flexDirection};
`;
const WarningWrapper = styled.div`
  padding: 16px;
`;

export default CampaignList;
