import { FE_REDIRECT_MAPPING } from '../constant';
import useQueryHelper from './useQueryHelper';

interface CustomMessageEvent {
  data: {
    type?: string;
    redirectPath: string;
    errorMsg?: string;
    state?: any;
    needLocationReload?: boolean;
  };
}
interface RedirectWithinIframeProps {
  redirectUrl: string;
  redirectType: FE_REDIRECT_MAPPING;
}
const useRedirectWithinIframe = () => {
  const { history, enqueueSnackbar, t } = useQueryHelper();
  const isWithinIframe = window.location !== window.parent.location;

  const receiveMessage = (event: CustomMessageEvent) => {
    if (!!event.data?.type) {
      // ignore from intercom
      return;
    } else if (event.data?.redirectPath) {
      const needLocationReload = event.data.needLocationReload ?? true;
      if (event.data.errorMsg) {
        enqueueSnackbar(t(event.data.errorMsg), { variant: 'error' });
      }
      // need to pass connected account state or provider for signUp
      // TODO: consider redirects for iframe users
      if (['/signup/profile', '/profile/settings/connect'].includes(event.data.redirectPath)) {
        history.push(event.data.redirectPath, event.data.state);
        history.go(0); // to reload current route if it is same as "redirectPath"
      } else if (needLocationReload) {
        window.location.href = event.data.redirectPath;
      }
    }
  };

  const startRedirectProcess = ({ redirectUrl }: RedirectWithinIframeProps) => {
    if (isWithinIframe) {
      let oauthWindow = null as Window | null;
      let previousUrl = null;
      // @ts-ignore
      window.removeEventListener('message', receiveMessage);

      const windowFeatures =
        'menubar=yes,location=yes,resizable=yes,scrollbars=no,width=600,height=700,left=150,top=200,toolbar=0,status=0';

      if (oauthWindow?.closed || oauthWindow === null) {
        oauthWindow = window.open(redirectUrl, 'windowedOauth', windowFeatures);
      } else if (previousUrl !== redirectUrl) {
        oauthWindow = window.open(redirectUrl, 'windowedOauth', windowFeatures);
      } else {
        // try to focus if somehow it is not
        oauthWindow.focus();
      }
      // listen for message from OAuth child window useEfect in `PublicRoute` component
      window.addEventListener('message', event => receiveMessage(event), false);
      previousUrl = redirectUrl;

      return;
    } else {
      location.href = redirectUrl;
    }
  };

  return { startRedirectProcess, isWithinIframe };
};

export default useRedirectWithinIframe;
