import { useContext } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { AdminStore } from '../../Context';
import { TutorialName } from '../../__generated__/globalTypes';
import * as GET_READ_TUTORIALS from './GetReadTutorials.graphql';
import * as ADD_READ_TUTORIAL from './AddReadTutorial.graphql';
import { AddReadTutorial, AddReadTutorialVariables } from './__generated__/AddReadTutorial';
import { GetReadTutorials } from './__generated__/GetReadTutorials';

export default () => {
  const {
    state: { readTutorials },
    dispatch,
  } = useContext(AdminStore);
  const client = useApolloClient();
  const [addReadTutorial] = useMutation<AddReadTutorial, AddReadTutorialVariables>(ADD_READ_TUTORIAL);

  const setTutorials = (newReadTutorials: TutorialName[]) => {
    dispatch({ type: 'SET_READ_TUTORIALS', payload: { readTutorials: newReadTutorials } });
  };

  const setReadTutorial = async (tutorial: TutorialName) => {
    await addReadTutorial({ variables: { input: { tutorialName: tutorial } } });
    dispatch({ type: 'ADD_READ_TUTORIAL', payload: { tutorial } });
  };

  const fetchTutorials = async () => {
    const {
      data: { getReadTutorials },
    } = await client.query<GetReadTutorials>({
      query: GET_READ_TUTORIALS,
    });

    const tutorials = getReadTutorials?.readTutorials || [];
    setTutorials(tutorials);
  };

  // Check whether if the user has already read the tutorial.
  const checkShouldDisplayTutorial = (tutorial: TutorialName): boolean => !readTutorials.includes(tutorial);

  return { checkShouldDisplayTutorial, readTutorials, setTutorials, setReadTutorial, fetchTutorials };
};
