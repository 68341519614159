import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { FanDetailsSchema } from '@src/libs/validation';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { ListIndicator } from '@src/components/molecules/Indicator';
import Empty from '@src/components/atoms/Empty';
import FanDetailsForm, { FormValues } from './FanDetailsForm';
import useGetFan from './useGetFan';
import { getInitialFormValues, getUpdateFanInput } from './helper';

const FanDetails = () => {
  const { params, path } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const { id } = params as { id: string };

  const { data, error, loading, updateFan } = useGetFan({ fanId: Number(id) });

  const handleSubmit = async (payload: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    setSubmitting(true);

    const input = getUpdateFanInput(Number(id), payload);
    await updateFan({
      variables: {
        input,
      },
    });

    setSubmitting(false);
  };

  if (path === '/fans/:id' && isMobileView) {
    // Nothing to return. Only show fan navigation tabs from parent.
    return null;
  }

  if (loading) {
    return <ListIndicator />;
  }

  if (error?.message.includes("fan doesn't exist") || !data?.getFan) {
    return <Empty />;
  }

  return (
    <Formik
      initialValues={getInitialFormValues(data.getFan)}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={FanDetailsSchema}
    >
      <FanDetailsForm editable={data.getFan.editable} />
    </Formik>
  );
};

export default FanDetails;
