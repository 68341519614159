import React from 'react';
import { Redirect } from 'react-router';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import PostDetail from '@src/components/organisms/AnalyticsShared/PostDetail';
import { InfluencerInstagramInteractionMentionedPostDetails_influencerInstagramInteractionMentionedPostDetails } from './__generated__/InfluencerInstagramInteractionMentionedPostDetails';

interface TemplateProps {
  closeModal: () => void;
  data?: InfluencerInstagramInteractionMentionedPostDetails_influencerInstagramInteractionMentionedPostDetails | null;
  accountType?: SocialAccountType;
  accountName?: string;
  avatarUrl?: string;
}

const Template = (props: TemplateProps) => {
  const { data, closeModal } = props;
  const accountType = SocialAccountType.INSTAGRAM;

  if (!data) {
    closeModal();

    return <Redirect to="/404" />;
  }

  const { postUrl, avatarUrl, instagramAccountName, postImageUrl, comments, likes, commentList, content } = data;

  return (
    <PostDetail
      avatarUrl={avatarUrl || null}
      accountType={accountType}
      accountName={instagramAccountName}
      followers={null}
      content={content}
      commentList={commentList || []}
      mediaUrls={postImageUrl ? [postImageUrl] : null}
      engagement={{
        sentiment: null,
        storyData: null,
        analysis: {
          comments,
          likes,
        },
        postUrl,
      }}
    />
  );
};

export default Template;
