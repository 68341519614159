import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SocialAccountType } from '../../../__generated__/globalTypes';
import { Indicator } from '../../molecules/Indicator';
import { Button } from '../../atoms/Button';
import SocialAccount, { SocialAccountWithAvatar } from '../../molecules/SocialAccount';
import { SignUpAndSignInBreakpoint } from '../../../libs/theme';
import * as ConnectComponent from './BaseComponents';
import { SelectedSocialAccount, SocialAccountInfo } from './types';

interface FanpageProps {
  fanPages: SelectedSocialAccount[];
  facebook: SocialAccountInfo | null;
  clickNext?: () => void;
  loading: boolean;
  selectedFanPageIds: string[];
  setSelectedFanPageIds: (ids: string[]) => void;
}

export const ChooseFanpageForm = (props: FanpageProps) => {
  const { fanPages, facebook, selectedFanPageIds, setSelectedFanPageIds, clickNext, loading } = props;
  const { t } = useTranslation();

  if (loading) {
    return <Indicator />;
  }

  const handleCheckboxChange = (isSelected: boolean, id: string) =>
    isSelected
      ? setSelectedFanPageIds(selectedFanPageIds.filter(selectedId => selectedId !== id))
      : setSelectedFanPageIds([...selectedFanPageIds, id]);

  return (
    <ConnectComponent.Container>
      <ConnectComponent.Title>{t('General.Choose pages')}</ConnectComponent.Title>
      <ConnectComponent.Annotation>{t('General.Connect FB fan pages')}</ConnectComponent.Annotation>
      {!!facebook && (
        <StyledSocialAccount
          socialMedia={SocialAccountType.FACEBOOK}
          src={facebook.avatar}
          username={facebook.name}
          followers={facebook.followersCount}
        />
      )}
      <ConnectComponent.FanpageSection>
        {fanPages.map(({ id, avatar, followersCount, name, isAlreadyConnected }) => {
          const isSelected = selectedFanPageIds.includes(id) || isAlreadyConnected;
          const isDisabled = isAlreadyConnected;
          const icon = isSelected ? 'check_box' : 'check_box_outline_blank';

          return (
            <ConnectComponent.FanpageWrapper
              key={id}
              selected={isSelected}
              onClick={() => (isDisabled ? null : handleCheckboxChange(isSelected, id))}
              disabled={isDisabled}
            >
              <ConnectComponent.Icon
                className="material-icons"
                checked={isSelected}
                disabled={isDisabled}
                onClick={() => (isDisabled ? null : handleCheckboxChange(isSelected, id))}
              >
                {icon}
              </ConnectComponent.Icon>

              <ConnectComponent.Thumbnail src={avatar || ''} />
              <SocialAccountWithAvatarWrapper followers={followersCount} username={name} />
            </ConnectComponent.FanpageWrapper>
          );
        })}
      </ConnectComponent.FanpageSection>
      {!!clickNext && <NextButton title="Next" onClick={clickNext} icon="arrow_forward" />}
    </ConnectComponent.Container>
  );
};

const NextButton = styled(Button)`
  position: relative;
  flex-direction: row-reverse;
  background-color: #ff2b52;
  text-transform: uppercase;
  border-radius: 24px;
  line-height: 40px;
  margin: 16px 0 84px;

  &:hover {
    background-color: #ff5575;
  }

  &:active {
    background-color: #cc2241;
  }

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    margin-bottom: 0;
  }

  i {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const SocialAccountWithAvatarWrapper = styled(SocialAccountWithAvatar)`
  h3 {
    font-size: 12px;
    line-height: 1.17;
  }

  p {
    margin-top: 1px;
  }
`;

const StyledSocialAccount = styled(SocialAccount)`
  margin-top: 16px;
`;
