import { css } from '@emotion/react';
import { useQuery } from '@apollo/client';
import React from 'react';
import { Link } from 'react-router-dom';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { ListIndicator } from '@src/components/molecules/Indicator';
import Empty from '@src/components/atoms/Empty';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import * as GET_FAN_FORM_RESPONSE from './GetFanFormResponse.graphql';
import { GetFanFormResponse, GetFanFormResponseVariables } from './__generated__/GetFanFormResponse';
import { getQuestionWithAnswer } from './helper';

const FormsResponse = () => {
  const { t, enqueueSnackbar, params } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const { id, submissionId } = params as { id: string; submissionId: string };

  const { data, loading } = useQuery<GetFanFormResponse, GetFanFormResponseVariables>(GET_FAN_FORM_RESPONSE, {
    variables: {
      input: {
        fanId: id,
        submissionId,
      },
    },
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  if (loading) {
    return <ListIndicator />;
  }

  if (!data?.getFanFormResponse?.answers.length) {
    return <Empty />;
  }

  return (
    <div css={isMobileView ? styles.mobileContainer : styles.container}>
      {!isMobileView && (
        <div css={styles.wrapper}>
          <BackNavigator css={styles.navigator} to={`/fans/${id}/forms`} title="" skipTranslation={true} />
          <Link to={`/forms/${data.getFanFormResponse.formId}/entry`} css={styles.link}>
            {data.getFanFormResponse.formTitle}
          </Link>
        </div>
      )}
      {[...data.getFanFormResponse.answers]
        .sort((a, b) => a.order - b.order)
        .map((item, index) => {
          const { question, answer } = getQuestionWithAnswer(item, t);

          return (
            <div css={[styles.row, isMobileView && styles.mobileRow]} key={item.questionId}>
              <p css={styles.question}>{`${t('Question')} ${index + 1}: ${question}`}</p>
              <p css={styles.answer}>{answer}</p>
            </div>
          );
        })}
    </div>
  );
};

const styles = {
  wrapper: css`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  `,
  mobileContainer: css`
    padding: 0;
    background-color: #fff;
  `,
  container: css`
    padding: 24px;
    background-color: #fff;
  `,
  navigator: css`
    & > a {
      box-shadow: 0 2px 5px #dee5ec, 0 1px 4px rgb(222 229 236 / 50%);
    }
  `,
  row: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 24px 24px 18px;
    border: 1px solid #eef3f7;

    &:not(:last-of-type) {
      border-bottom-style: none;
    }
  `,
  mobileRow: css`
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
  `,
  question: css`
    font-size: 14px;
    color: #6e7c89;
  `,
  answer: css`
    font-size: 14px;
    color: #27313b;
  `,
  link: css`
    font-weight: 600;
    font-size: 16px;
    color: #3892e5;
  `,
};

export default FormsResponse;
