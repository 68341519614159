import React from 'react';
import { useField, useFormikContext } from 'formik';
import TextForm from '@src/components/molecules/TextForm';
import { SubmitButton } from '@src/components/atoms/Button';
import { css } from '@emotion/core';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

export interface FormValues {
  comment: string;
}

const CommentForm = () => {
  const { t } = useTranslation();
  const [commentField, commentMeta] = useField<FormValues['comment']>('comment');
  const { isSubmitting, handleSubmit } = useFormikContext<FormValues>();

  return (
    <form onSubmit={handleSubmit} css={formStyle}>
      <CommentWrapper>
        <TextForm css={textformStyle} error={!!commentMeta.error} placeholder={t('Comment')} {...commentField} />
        <SubmitButton disabled={isSubmitting} value="Post" css={submitBtnStyle} />
      </CommentWrapper>

      {commentMeta.error && <ErrorMessage message={t(commentMeta.error)} />}
    </form>
  );
};

const formStyle = css`
  display: flex;
  border: 1px solid #dee5ec;
  padding: 8px;
  border-radius: 5px;
  flex-direction: column;
  margin-bottom: 32px;
`;
const textformStyle = css`
  flex: 1;

  /* stylelint-disable */
  & input {
    border-radius: 3px;
    height: 32px;
  }
  /* stylelint-enable */
`;
const submitBtnStyle = css`
  width: fit-content;
  line-height: 32px;
  border-radius: 3px;
  padding: 0 16px;
  margin-left: 8px;
`;
const CommentWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export default CommentForm;
