import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import * as React from 'react';
import { Link } from 'react-router-dom';
import ArrowRight from '@src/assets/icon/blueArrowRight.svg';

interface MoreButtonProps {
  className?: string;
  to?: string;
  onClick?: () => void;
}

const MoreButton = ({ className, to, onClick }: MoreButtonProps) => {
  const { t } = useTranslation();

  return to ? (
    <MoreLink to={to} className={className}>
      {t('Button.More')}
      <Img src={ArrowRight} />
    </MoreLink>
  ) : (
    <MoreDiv onClick={onClick} className={className}>
      {t('Button.More')}
      <Img src={ArrowRight} />
    </MoreDiv>
  );
};

const MoreDiv = styled.div`
  display: inline-flex;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: #6e7c89;

  &:hover {
    opacity: 0.8;
  }
`;

const MoreLink = styled(Link)`
  display: inline-flex;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: #6e7c89;

  &:hover {
    opacity: 0.8;
  }
`;

const Img = styled.img`
  margin-left: 4px;
`;

export default MoreButton;
