export const convertLanguageCode = (code: string) => {
  switch (code) {
    case 'ja':
      return 'ja';
    case 'th':
      return 'th';
    case 'id':
      return 'id';
    case 'vi':
      return 'vi';
    case 'zh-TW':
    case 'zh-HK':
    case 'zh':
      return 'tw';
    case 'zh-CN':
      return 'ch';
    case 'km':
      return 'km';
    case 'my':
      return 'my';
    default:
      return 'en';
  }
};

export type AppLanguage = 'ja' | 'th' | 'id' | 'vi' | 'tw' | 'ch' | 'km' | 'my' | 'en';
