import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import editIcon from '@src/assets/icon/editOutlined.svg';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import SubmitForm from '@src/components/organisms/Forms/SubmitForm';
import ShareButton from '@src/components/organisms/Forms/ShareButton';
import StatusIndicator from '@src/components/organisms/Forms/StatusIndicator';
import { submitFormState, useRecoilValue } from '@src/recoilAtoms';
import { FormStatus } from '@src/__generated__/globalTypes';
import useGetForm from '../EditForm/useGetForm';

enum Mode {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
}

const EditPreviewForm = () => {
  const [mode, setMode] = useState<Mode>(Mode.DESKTOP);
  const { params, t } = useQueryHelper();
  // if form is previewing before save, the draft information is store into atoms
  // use the draft information instead of DB information
  const submitFormInformation = useRecoilValue(submitFormState);
  const { isMobileView } = usePageLayout();
  const isMobileMode = mode === Mode.MOBILE;
  const formId = params.id;
  const { description, hash, id, questions, status, thankDescription, thankTitle, title } = submitFormInformation
    ? submitFormInformation
    : useGetForm({ formId });
  const options = [{ value: Mode.DESKTOP }, { value: Mode.MOBILE }];

  return (
    <div>
      {!isMobileView && (
        <div css={styles.navigatorContainer}>
          <div>
            <BackNavigator title="Live Preview" to={`/forms/${formId}/entry`} />
            {!!id && <StatusIndicator status={status as FormStatus} />}
          </div>
          <div>
            <Link css={styles.editBtn} to={`/forms/${formId}/entry`}>
              <img alt="edit" height="16" src={editIcon} width="16" />
            </Link>
            <div css={styles.divider} />
            <ShareButton hash={hash} id={id} status={status as FormStatus} />
          </div>
        </div>
      )}

      <div css={styles.modeContainer}>
        {options.map(option => {
          const { value } = option;
          const isActive = mode === value;

          return (
            <Option isActive={isActive} key={value} onClick={() => setMode(value)}>
              {t(value)}
            </Option>
          );
        })}
      </div>

      <FormContainer isMobileMode={isMobileMode}>
        <div>
          <SubmitForm
            description={description}
            id={id}
            isMobileMode={isMobileMode}
            questions={questions}
            thankDescription={thankDescription}
            thankTitle={thankTitle}
            title={title}
          />
        </div>
      </FormContainer>
    </div>
  );
};

const FormContainer = styled.div<{ isMobileMode: boolean }>`
  display: flex;
  justify-content: center;

  & > div {
    width: ${({ isMobileMode }) => (isMobileMode ? '375px' : '100%')};
  }
`;

const Option = styled.div<{ isActive: boolean }>`
  align-items: center;
  background-color: ${({ isActive }) => (isActive ? '#27313b' : '#fff')};
  box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
  color: ${({ isActive }) => (isActive ? '#fff' : '#27313b')};
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 32px;
  justify-content: center;
  text-transform: capitalize;
  width: 81px;
`;

const styles = {
  divider: css`
    border-left: 1px solid #dee5ec;
    height: 32px;
    margin: 0 8px;
  `,
  editBtn: css`
    align-items: center;
    background-color: #fff;
    border: 1px solid #dee5ec;
    border-radius: 3px;
    display: flex;
    height: 32px;
    justify-content: center;
    width: 32px;
  `,
  modeContainer: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 16px 0 24px 0;

    & > div:nth-of-type(1) {
      border-radius: 5px 0 0 5px;
    }

    & > div:nth-of-type(2) {
      border-radius: 0 5px 5px 0;
    }
  `,
  navigatorContainer: css`
    display: flex;
    flex-wrap: wrap;
    padding: 16px 24px;

    & > div:nth-of-type(1) {
      align-items: center;
      display: flex;
      flex-basis: 50%;
      flex-wrap: wrap;

      & > div:nth-of-type(2) {
        margin-left: 8px;
      }
    }

    /* stylelint-disable no-descending-specificity */
    & > div:nth-of-type(2) {
      display: flex;
      flex-basis: 50%;
      justify-content: flex-end;
    }
  `,
};

export default EditPreviewForm;
