import { useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import * as StyledComponents from '../StyledComponents';
import { getOptions } from '../../../../libs/form';
import { SubmitButton } from '../../../atoms/Button';
import ErrorMessage from '../../../atoms/ErrorMessage';
import SingleSelectField from '../../../molecules/SingleSelectField';
import TextForm from '../../../molecules/TextForm';
import * as GET_BANK_BRANCHES from './GetBankBranches.graphql';
import { FormValues, Option, Options } from './types';

export interface PaymentInformationFormJPProps extends Options {
  className?: string;
}

const subjectOptions = [
  {
    value: '普通',
    label: '普通',
  },
  {
    value: '当座',
    label: '当座',
  },
  {
    value: '貯蓄',
    label: '貯蓄',
  },
];

const PaymentInformationFormJP = (props: PaymentInformationFormJPProps) => {
  const [allBranches, setAllBranches] = useState<Option[]>([]);
  const client = useApolloClient();
  const { t } = useTranslation();
  const { className, allBanks } = props;
  const { isSubmitting, handleSubmit, values, setFieldValue } = useFormikContext<FormValues>();

  const getBankBranches = async (bankId: string) => {
    const { data } = await client.query({
      query: GET_BANK_BRANCHES,
      variables: { bankId: Number(bankId) },
    });

    setAllBranches(getOptions(data.allBankBranchesForInfluencer));
  };

  useEffect(() => {
    if (!!values.bankId) {
      getBankBranches(values.bankId);
    }
  }, [values.bankId || values.branchId]);

  // Fields
  const [fullNameField, fullNameMeta] = useField('fullName');
  const [accountNameField, accountNameMeta] = useField('accountName');
  const [bankIdField, bankIdMeta] = useField('bankId');
  const [branchIdField, branchIdMeta] = useField('branchId');
  const [accountNumberField, accountNumberMeta] = useField('accountNumber');
  const [subjectField, subjectMeta] = useField('subject');

  return (
    <>
      <form className={className} onSubmit={handleSubmit}>
        <StyledComponents.ResponsiveWrapper>
          <StyledComponents.FormContainer>
            <StyledComponents.Wrapper>
              <TextForm
                title="Full Name"
                isRequired={true}
                error={!!fullNameMeta.error}
                placeholder={t('Full Name')}
                {...fullNameField}
              />
              {fullNameMeta.error && <ErrorMessage message={t(fullNameMeta.error)} />}
            </StyledComponents.Wrapper>

            <StyledComponents.Wrapper>
              <SingleSelectField
                name={bankIdField.name}
                value={bankIdField.value}
                title="Bank Name"
                isRequired={true}
                error={!!bankIdMeta.error}
                options={allBanks}
                setFieldValue={setFieldValue}
              />
              {bankIdMeta.error && <ErrorMessage message={t(bankIdMeta.error)} />}
            </StyledComponents.Wrapper>

            <StyledComponents.Wrapper>
              <SingleSelectField
                name={branchIdField.name}
                value={branchIdField.value}
                title="Branch Name"
                isRequired={true}
                error={!!branchIdMeta.error}
                options={allBranches}
                setFieldValue={setFieldValue}
              />
              {branchIdMeta.error && <ErrorMessage message={t(branchIdMeta.error)} />}
            </StyledComponents.Wrapper>

            <StyledComponents.Wrapper>
              <SingleSelectField
                name={subjectField.name}
                value={subjectField.value}
                title="Subject"
                isRequired={true}
                error={!!subjectMeta.error}
                options={subjectOptions}
                setFieldValue={setFieldValue}
              />
              {subjectMeta.error && <ErrorMessage message={t(subjectMeta.error)} />}
            </StyledComponents.Wrapper>

            <StyledComponents.Wrapper>
              <TextForm
                title="Account Number"
                isRequired={true}
                error={!!accountNumberMeta.error}
                placeholder="12345678"
                {...accountNumberField}
              />
              {accountNumberMeta.error && <ErrorMessage message={t(accountNumberMeta.error)} />}
            </StyledComponents.Wrapper>

            <StyledComponents.Wrapper>
              <TextForm
                title="Account Holder Name"
                isRequired={true}
                error={!!accountNameMeta.error}
                help="Exactly as appears on your bank statement"
                placeholder="Jane Doe"
                {...accountNameField}
              />
              {accountNameMeta.error && <ErrorMessage message={t(accountNameMeta.error)} />}
            </StyledComponents.Wrapper>

            <StyledComponents.SubmitWrapper>
              <SubmitButton value="Update Payment Information" isSubmitting={isSubmitting} />
            </StyledComponents.SubmitWrapper>
          </StyledComponents.FormContainer>
        </StyledComponents.ResponsiveWrapper>
      </form>
    </>
  );
};

export default PaymentInformationFormJP;
