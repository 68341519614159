import React from 'react';
import PostDetail from '@src/components/organisms/AnalyticsShared/PostDetail';
import { InstagramAnalyticsStoryDetails_analyticsStoryDetails } from './__generated__/InstagramAnalyticsStoryDetails';

interface TemplateProps {
  closeModal: () => void;
  data?: InstagramAnalyticsStoryDetails_analyticsStoryDetails | null;
}

const Template = (props: TemplateProps) => {
  const { data, closeModal } = props;

  if (!data) {
    closeModal();

    return null;
  }

  const {
    avatarUrl,
    instagramAccountName,
    followers,
    content,
    postImageUrl,
    postUrl,
    comments,
    reach,
    impressions,
    exits,
    tapsForward,
    tapsBack,
    storyVideoUrl,
  } = data;

  return (
    <PostDetail
      avatarUrl={avatarUrl}
      accountName={instagramAccountName}
      followers={followers}
      content={content}
      commentList={[]}
      mediaUrls={storyVideoUrl ? [storyVideoUrl] : postImageUrl ? [postImageUrl] : null}
      engagement={{
        postUrl,
        analysis: null,
        sentiment: null,
        storyData: { comments, reach, impressions, exits, tapsForward, tapsBack },
      }}
    />
  );
};

export default Template;
