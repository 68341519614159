import React, { useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Popover } from '@material-ui/core';
import { CheckBox } from '../../../atoms/CheckBox';
import { PalleteButton } from '../../../atoms/Button';
import { mainBlue } from '../../../../libs/pallete';
import { useQueryHelper } from '../../../../libs/hooks';
import { additionalSelectableCharts, ChartColorTypes } from './helpers';

export interface OverviewProps {
  currentSelection: ChartColorTypes[];
  anchorEl: HTMLElement;
  onSelectionChange: (selection: ChartColorTypes[]) => void;
  onClose: () => void;
}

const ChartSelector = (props: OverviewProps) => {
  const { t } = useQueryHelper();
  const { onSelectionChange, onClose, anchorEl, currentSelection } = props;
  const [allSelectedCheckboxes, setSelectedCheckboxes] = useState<ChartColorTypes[]>(currentSelection);

  const handleEngagementSave = () => {
    onSelectionChange(allSelectedCheckboxes);
    onClose();
  };

  return (
    <>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          onClose();
        }}
        // reset to prev saved state
        onExited={() => setSelectedCheckboxes(allSelectedCheckboxes)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        css={css`
          z-index: 100;
          margin-top: 4px !important;

          & .MuiPaper-elevation8 {
            box-shadow: 0 1px 2px 0 #27313b19;
            width: 248px;
            background-color: #fff;
            border-radius: 3px;
            border: solid 1px #dee5ec;
          }
        `}
      >
        <div>
          <Checkboxes>
            {additionalSelectableCharts.map(chart => {
              const isChecked = allSelectedCheckboxes.includes(chart);

              return (
                <CheckBox
                  key={chart}
                  value={isChecked}
                  name={chart}
                  label={t(chart)}
                  handleClick={() => {
                    if (isChecked) {
                      setSelectedCheckboxes(allSelectedCheckboxes.filter(checkboxVal => checkboxVal !== chart));
                    } else {
                      setSelectedCheckboxes([...allSelectedCheckboxes, chart]);
                    }
                  }}
                  css={css`
                    &:not(:last-of-type) {
                      padding-bottom: 16px;
                    }
                  `}
                />
              );
            })}
          </Checkboxes>
          <SubmitSection>
            <StyledSaveButton
              text="Save"
              onClick={handleEngagementSave}
              palleteColor={mainBlue}
              borderRadius="3px"
              width="auto"
              height="24px"
              fontSize="12px"
            />
          </SubmitSection>
        </div>
      </Popover>
    </>
  );
};

const Checkboxes = styled.div`
  padding: 8px;
`;
const SubmitSection = styled.div`
  padding: 8px;
  border-top: 1px solid #dee5ec;
`;
const StyledSaveButton = styled(PalleteButton)`
  margin-left: auto;
  min-width: 66px;
`;

export default ChartSelector;
