import React from 'react';
import { css } from '@emotion/core';
import { CircularProgress } from '@material-ui/core';
import styled from '@emotion/styled';
import { Period } from '@src/components/molecules/DayPicker/types';
import { useQueryHelper } from '@src/libs/hooks';
import { useQuery } from '@apollo/client';
import AnalyticsContentWrapper, {
  ContentType,
} from '@src/components/organisms/AnalyticsShared/AnalyticsContentWrapper';
import { SocialMediaAccountType } from '@src/components/organisms/ProfileDetail/types';
import Template from './template';
import {
  InfluencerYoutubeAnalyticsOverview,
  InfluencerYoutubeAnalyticsOverviewVariables,
} from './__generated__/InfluencerYoutubeAnalyticsOverview';
import * as INFLUENCER_YOUTUBE_DASHBOARD_OVERVIEW from './InfluencerYoutubeAnalyticsOverview.graphql';

interface OverviewCardProps {
  selectedSocialMedia: SocialMediaAccountType;
  selectedPeriod: Period;
  userId: number;
  className?: string;
}

const OverviewCard = ({ selectedSocialMedia, selectedPeriod, className }: OverviewCardProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();

  const { data, loading, error } = useQuery<
    InfluencerYoutubeAnalyticsOverview,
    InfluencerYoutubeAnalyticsOverviewVariables
  >(INFLUENCER_YOUTUBE_DASHBOARD_OVERVIEW, {
    variables: {
      startDate: selectedPeriod.startDate,
      endDate: selectedPeriod.endDate,
      socialAccountId: selectedSocialMedia.id,
    },
  });

  if (error) {
    enqueueSnackbar(t(error.message), { variant: 'error' });

    return null;
  }

  return (
    <AnalyticsContentWrapper contentType={ContentType.Overview} className={className}>
      {loading ? (
        <LoaderWrapper>
          <CircularProgress
            size={64}
            thickness={2.4}
            css={css`
              margin: 240px auto;
              color: #e0e8ed;
            `}
          />
        </LoaderWrapper>
      ) : (
        <Template selectedSocialMedia={selectedSocialMedia} overviewStats={data?.dashboardOverview || null} />
      )}
    </AnalyticsContentWrapper>
  );
};

const LoaderWrapper = styled.div`
  background-color: #fff;
  text-align: center;
  height: 554px;
`;

export default OverviewCard;
