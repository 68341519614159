import styled from '@emotion/styled';
import css from '@emotion/css';
import React from 'react';
import { ViewportBreakpoint } from '@src/libs/theme';
import AnalyticsContentWrapper, {
  ContentType,
} from '@src/components/organisms/AnalyticsShared/AnalyticsContentWrapper';
import AreaBreakdown from './AreaBreakdown';
import AgeBreakdown from './AgeBreakdown';
import GenderBreakdown from './GenderBreakdown';
import { AudienceData } from './types';

interface TemplateProps extends AudienceData {
  className?: string;
}

const AudienceCardTemplate = ({
  followerGenderRate,
  followerAgeRate,
  followerRegionRate,
  followerCountryRate,
  hasCountriesRates,
  className,
}: TemplateProps) => (
  <AnalyticsContentWrapper className={className} contentType={ContentType.Audience}>
    <OverviewWrapper>
      <GenderBreakdown {...followerGenderRate} css={[styles.cardStyle, styles.borderTop]} />
      <AgeBreakdown {...followerAgeRate} css={[styles.cardStyle, styles.borderTop]} />
      <AreaBreakdown
        followerRegionRate={followerRegionRate || []}
        followerCountryRate={followerCountryRate || []}
        css={[styles.areaBreakdown, styles.borderTop]}
        hasCountriesRates={hasCountriesRates}
      />
    </OverviewWrapper>
  </AnalyticsContentWrapper>
);

const OverviewWrapper = styled.section`
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  background-color: #fff;
  color: #27313b;

  @media (min-width: ${ViewportBreakpoint.DESKTOP}px) {
    flex-direction: row;
  }
`;
const styles = {
  cardStyle: css`
    @media (min-width: ${ViewportBreakpoint.DESKTOP}px) {
      border-right: 1px solid #dee5ec;
      padding-bottom: 16px;
    }
  `,
  areaBreakdown: css`
    padding-bottom: 16px;
  `,
  borderTop: css`
    border-top: 1px solid #dee5ec;
  `,
};

export default AudienceCardTemplate;
