import { ApolloError, useMutation } from '@apollo/client';
import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import { EnquirySchema } from '@src/libs/validation';
import { useQueryHelper } from '@src/libs/hooks';
import { SendContact, SendContactVariables } from './__generated__/SendContact';
import ContactForm from './ContactForm';
import * as SEND_CONTACT from './SendContact.graphql';
import { FormValues } from './types';

const ContactFormComponent = () => {
  const { t, enqueueSnackbar, history } = useQueryHelper();

  // Mutation
  const [sendContact] = useMutation<SendContact, SendContactVariables>(SEND_CONTACT);

  const handleSubmit = async (payload: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    setSubmitting(true);

    // Update Account
    const { enquiry } = payload;
    const variables = { input: { enquiry } };
    await sendContact({ variables })
      .then(result => {
        if (result && result.data && result.data.sendContact && result.data.sendContact.ok) {
          enqueueSnackbar(t('succeededInSending'), { variant: 'success' });
          history.push('/contact/thanks');
        } else {
          const failureMessage = 'failedToSend';
          enqueueSnackbar(t(failureMessage), { variant: 'error' });
        }

        return;
      })
      .catch((e: ApolloError) => {
        console.error(e);
        enqueueSnackbar(t('failedToSend'), { variant: 'error' });
      });
    setSubmitting(false);

    return;
  };

  return (
    <Formik
      initialValues={{
        enquiry: '',
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={EnquirySchema}
      onSubmit={handleSubmit}
    >
      <ContactForm />
    </Formik>
  );
};

export default ContactFormComponent;
