import React from 'react';
import styled from '@emotion/styled';
import { mainBlue, mainWhite } from '../../../../libs/pallete';
import { PalleteButton } from '../../../atoms/Button';
import { CampaignStatusForInfluencer } from '../../../../__generated__/globalTypes';
import warningIcon from '../../../../assets/icon/warning.svg';

interface EngagementPostButtonsProps {
  campaignId?: number | null;
  isJoined?: boolean;
  hasDraftPosts?: boolean;
  hasReport?: boolean;
  hasWarningPosts?: boolean;
  campaignStatus: CampaignStatusForInfluencer;
  className?: string;
}
const EngagementPostButtons = (props: EngagementPostButtonsProps) => {
  const { campaignId, isJoined, hasDraftPosts, hasReport, hasWarningPosts, campaignStatus, className } = props;

  const renderPostListButton = () => (
    <StyledPalleteButton
      text="Post list"
      palleteColor={mainWhite}
      href={`/job/engagement/${campaignId}/post`}
      postfixIcon={hasWarningPosts ? <WarningImage src={warningIcon} alt="warning" /> : undefined}
      {...buttonProps}
    />
  );

  const renderReportButton = () => (
    <StyledPalleteButton
      text="Post Report"
      palleteColor={mainWhite}
      href={`/job/engagement/${campaignId}/report`}
      {...buttonProps}
    />
  );

  const renderUploadPostButton = () => (
    <StyledPalleteButton
      text="Upload Draft Post"
      href={`/job/engagement/${campaignId}/post/add`}
      palleteColor={mainBlue}
      {...buttonProps}
    />
  );

  const renderButton = () => {
    if (hasReport) {
      return (
        <ButtonWrapper className={className}>
          {renderPostListButton()}
          {renderReportButton()}
        </ButtonWrapper>
      );
    }

    if (isJoined && hasDraftPosts) {
      return <ButtonWrapper className={className}>{renderPostListButton()}</ButtonWrapper>;
    }

    if (isJoined && !hasDraftPosts && campaignStatus !== CampaignStatusForInfluencer.FINISHED) {
      return <ButtonWrapper className={className}>{renderUploadPostButton()}</ButtonWrapper>;
    }

    return null;
  };

  return renderButton();
};

const ButtonWrapper = styled.div`
  display: flex;
`;
const StyledPalleteButton = styled(PalleteButton)`
  min-width: 152px;

  &:nth-of-type(even) {
    margin-left: 16px;
  }
`;
const buttonProps = {
  height: '32px',
  minWidth: '160px',
};
const WarningImage = styled.img`
  width: 15px;
  margin-left: 5px;
`;

export default EngagementPostButtons;
