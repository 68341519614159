import styled from '@emotion/styled';
import React from 'react';
import { ListIndicator } from '../../../molecules/Indicator';
import NavigationHeader from '../../Header/NavigationHeader';
import { ViewportBreakpoint } from '../../../../libs/theme';
import { useQueryHelper } from '../../../../libs/hooks';
import ReportList from './ReportList';
import { useFetchReportBasedOnCampagnType } from './useFetchReportBasedOnCampaignType';
import { ReportListDataType } from './types';

interface ReportListProps {
  isEngagement?: boolean;
}

const ReportListComponent = (props: ReportListProps) => {
  const { data, loading, error } = useFetchReportBasedOnCampagnType(!!props.isEngagement);
  const { enqueueSnackbar, t, history } = useQueryHelper();

  if (loading || !data) {
    return <ListIndicator />;
  }

  if (error) {
    const { message } = error.graphQLErrors[0];
    enqueueSnackbar(t(message), { variant: 'error' });
    history.push('/');

    return null;
  }

  return (
    <>
      <NavigationHeader />
      <SectionWrapper>
        <ReportList report={data as ReportListDataType} isEngagement={props.isEngagement} />
      </SectionWrapper>
    </>
  );
};

export default ReportListComponent;

const SectionWrapper = styled.div`
  margin-top: 48px;
  padding: 0 16px;
  flex: 1;

  @media (min-width: ${ViewportBreakpoint.MEDIUM}px) {
    padding: 0 64px;
  }
`;
