import React, { useRef, useState, useEffect, useCallback } from 'react';
import { css } from '@emotion/react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CRMChatTypeFilter } from '@src/__generated__/globalTypes';
import { GetLineChats_getLineChats } from '../__generated__/GetLineChats';
import { ChatFilterFormValues, LineChatCountsType } from '../types';
import ChatInfoCard from './ChatInfoCard';

interface ChatListProps extends LineChatCountsType {
  chats: GetLineChats_getLineChats['chats'];
  loading: boolean;
  fetchMore: () => void;
  filter: ChatFilterFormValues;
  hasMobileLayout?: boolean;
}
const ChatList = ({ filter, chats, loading, fetchMore, totalNum, assignedToMeNum, hasMobileLayout }: ChatListProps) => {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const isLastElementTriedToFetch = useRef(false);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    const chatsLength = chats.length || 0;

    if (filter.type === CRMChatTypeFilter.ASSIGNED_TO_ME) {
      setHasMore(assignedToMeNum > chatsLength);
    } else {
      setHasMore(totalNum > chatsLength);
    }
  }, [filter.type, assignedToMeNum, totalNum, chats.length]);

  const lastChatElementRef = useCallback(
    node => {
      if (loading) {
        return;
      }
      if (observerRef.current) {
        // eslint-disable-next-line no-unused-expressions
        observerRef.current?.disconnect();
        isLastElementTriedToFetch.current = false;
      }

      observerRef.current = new IntersectionObserver(
        entries => {
          if (entries[0].intersectionRatio === 1 && hasMore && !isLastElementTriedToFetch.current) {
            fetchMore();
            isLastElementTriedToFetch.current = true;
          }
        },
        {
          threshold: 1,
        }
      );

      if (node) {
        observerRef.current.observe(node);
      }
    },
    [loading, hasMore, fetchMore]
  );

  return (
    <ul css={styles.allChats}>
      {chats.map((chat, i) => (
        <li key={chat.chatId} ref={chats.length - 1 === i ? lastChatElementRef : undefined}>
          <ChatInfoCard
            id={chat.chatId}
            fanId={chat.fanId}
            avatar={chat.userAvatar}
            userName={chat.userName}
            lastMessage={chat.lastMessage}
            lastMessageAt={chat.lastMessageAt}
            pic={chat.pic?.name || ''}
            resolved={chat.resolved}
            checked={chat.checked}
            hasMobileLayout={hasMobileLayout}
          />
        </li>
      ))}
      {loading && (
        <div css={{ display: 'flex' }}>
          <CircularProgress size={24} thickness={2.4} css={styles.progress} />
        </div>
      )}
    </ul>
  );
};

const styles = {
  allChats: css`
    border-top: 1px solid #eef3f7;
    overflow-y: auto;
    background-color: #fff;
  `,
  progress: css`
    margin: 16px auto;
    color: #dee5ec;
  `,
};
export default ChatList;
