import styled from '@emotion/styled';
import { useHistory } from 'react-router';
import React from 'react';
import { DesktopSidebarWidth, ViewportBreakpoint } from '@src/libs/theme';
import { useAuthData, useUuumInfluencerView } from '@src/libs/hooks';

const NavigationHeader: React.FC<{ childrenWidth?: string }> = props => {
  const { href: backToParentUrl, title } = useAuthData();
  const history = useHistory();
  const { isUuumView } = useUuumInfluencerView();
  // For iframe we don't have sidebar
  const sidebarWidth = isUuumView ? 0 : DesktopSidebarWidth;

  const handleGoBack = () => {
    if (backToParentUrl) {
      history.replace(backToParentUrl);

      return;
    }

    history.goBack();
  };

  return (
    <NavigationHeaderWrapper css={isUuumView ? { width: '100%' } : ''} sidebarWidth={sidebarWidth}>
      <MenuWrapper>
        <RedirectBackButton onClick={handleGoBack}>
          <Icon className="material-icons">arrow_back</Icon>
        </RedirectBackButton>
        <PageTitle>{title}</PageTitle>
      </MenuWrapper>

      <ButtonWrapper width={props.childrenWidth}>{props?.children}</ButtonWrapper>
    </NavigationHeaderWrapper>
  );
};

const NavigationHeaderWrapper = styled.div<{ sidebarWidth: number }>`
  position: fixed;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${ViewportBreakpoint.MEDIUM}px;
  z-index: 5;
  background-color: #fff;
  border-bottom: 1px solid #dee5ec;
  box-sizing: border-box;

  @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
    position: absolute;
    width: 100%;
  }

  @media (min-width: ${ViewportBreakpoint.DESKTOP}px) {
    ${props => `width: calc(100% - ${props.sidebarWidth}px)`};
  }
`;

const MenuWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  height: 100%;
`;
const RedirectBackButton = styled.button`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #efefef;
  }
`;
const PageTitle = styled.h1`
  box-sizing: border-box;
  overflow: hidden;
  max-width: 540px;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #27313b;
`;
const Icon = styled.i`
  color: #ff4b61;
  font-size: 22px;
`;
const ButtonWrapper = styled.div<{ width?: string }>`
  display: flex;
  justify-content: flex-end;
  width: ${({ width }) => width || 'auto'};
  margin-right: 16px;

  @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
    display: none;
  }
`;

export default NavigationHeader;
