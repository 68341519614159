import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SocialAccountType } from '../../../__generated__/globalTypes';
import * as Buttons from '../../atoms/Button';
import { SocialButton } from '../../atoms/Button';
import SocialAccount from '../../molecules/SocialAccount';
import { SignUpAndSignInBreakpoint } from '../../../libs/theme';
import { SocialAccounts } from './types';

export interface SignUpConnectProps extends SocialAccounts {
  handleConnectFacebook: () => void;
  handleConnectInstagram: () => void;
  handleConnectTwitter: () => void;
  handleConnectYoutube: () => void;
  handleConnectTiktok: () => void;
  loading: boolean;
}

const SignUpConnectForm = (props: SignUpConnectProps) => {
  const { t } = useTranslation();
  const {
    facebook,
    instagramAccounts,
    twitterAccounts,
    youtubeAccounts,
    tiktokAccounts,
    handleConnectFacebook,
    handleConnectInstagram,
    handleConnectTwitter,
    handleConnectYoutube,
    handleConnectTiktok,
    loading,
  } = props;
  const translatedTitle = t('Title.Connect Social Network');
  const translatedAnnotation = t('Annotation.Please select more your networks if you want');

  return (
    <Container>
      <Title>{translatedTitle}</Title>
      <Annotation>{translatedAnnotation}</Annotation>
      <SnContainer>
        {facebook !== null ? (
          <StyledSocialAccount
            socialMedia={SocialAccountType.FACEBOOK}
            src={facebook.avatar}
            username={facebook.name}
            followers={facebook.followersCount}
          />
        ) : (
          <SocialButtonWrapper
            onClick={handleConnectFacebook}
            socialMedia={SocialAccountType.FACEBOOK}
            title="Connect Facebook"
            disabled={loading}
          />
        )}
        {/* TODO: refactor repetitive pattern */}
        {instagramAccounts.length ? (
          <GroupAccountsWrapper>
            {instagramAccounts.map(instagram => (
              <StyledSocialAccount
                key={instagram.name}
                socialMedia={SocialAccountType.INSTAGRAM}
                src={instagram.avatar}
                username={instagram.name}
                followers={instagram.followersCount}
              />
            ))}
          </GroupAccountsWrapper>
        ) : (
          <SocialButtonWrapper
            onClick={handleConnectInstagram}
            socialMedia={SocialAccountType.INSTAGRAM}
            title="Connect Instagram"
            disabled={loading}
          />
        )}
        {twitterAccounts.length ? (
          <GroupAccountsWrapper>
            {twitterAccounts.map(twitter => (
              <StyledSocialAccount
                key={twitter.name}
                socialMedia={SocialAccountType.TWITTER}
                src={twitter.avatar}
                username={twitter.name}
                followers={twitter.followersCount}
              />
            ))}
          </GroupAccountsWrapper>
        ) : (
          <SocialButtonWrapper
            onClick={handleConnectTwitter}
            socialMedia={SocialAccountType.TWITTER}
            title="Connect Twitter"
            disabled={loading}
          />
        )}
        {youtubeAccounts.length ? (
          <GroupAccountsWrapper>
            {youtubeAccounts.map(youtube => (
              <StyledSocialAccount
                key={youtube.name}
                socialMedia={SocialAccountType.YOUTUBE}
                src={youtube.avatar}
                username={youtube.name}
                followers={youtube.followersCount}
              />
            ))}
          </GroupAccountsWrapper>
        ) : (
          <SocialButtonWrapper
            onClick={handleConnectYoutube}
            socialMedia={SocialAccountType.YOUTUBE}
            title="Connect YouTube"
            disabled={loading}
          />
        )}
        {tiktokAccounts.length ? (
          <GroupAccountsWrapper>
            {tiktokAccounts.map(tiktok => (
              <StyledSocialAccount
                key={tiktok.name}
                socialMedia={SocialAccountType.TIKTOK}
                src={tiktok.avatar}
                username={tiktok.name}
                followers={tiktok.followersCount}
              />
            ))}
          </GroupAccountsWrapper>
        ) : (
          <SocialButtonWrapper
            onClick={handleConnectTiktok}
            socialMedia={SocialAccountType.TIKTOK}
            title="Connect TikTok"
            disabled={loading}
          />
        )}
      </SnContainer>
      <Link
        id="finishSignUp" // Required for CPA campaign of vietnam
        title="Skip"
        href="/profile"
        icon="arrow_forward"
      />
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;

const Title = styled.h1`
  font-size: 20px;
  color: #27313b;
`;

const Annotation = styled.p`
  padding: 16px 0 10px;
  color: #27313b;
  text-align: center;
  line-height: 1.5;
  font-size: 12px;
`;

const SocialButtonWrapper = styled(SocialButton)`
  height: 40px;
  border-radius: 20px;
`;

const Link = styled(Buttons.LinkButton)`
  position: relative;
  line-height: 40px;
  background-color: #fff;
  border-radius: 24px;
  border: 1px solid #dee5ec;
  color: #6e7c89;
  flex-direction: row-reverse;
  text-transform: uppercase;
  margin: 16px 0 86px;

  &:hover {
    border-color: #c5d0da;
    background-color: transparent;
  }

  &:active {
    border-color: #c5d0da;
    background-color: #f6f8fa;
  }

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    margin-bottom: 0;
  }

  i {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const StyledSocialAccount = styled(SocialAccount)`
  margin-top: 16px;
`;

const GroupAccountsWrapper = styled.div`
  width: 100%;
`;
const SnContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export default SignUpConnectForm;
