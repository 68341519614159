import styled from '@emotion/styled';
import Pager from '@src/components/molecules/Pager';
import { getPageInfo } from '@src/libs/paging';
import * as React from 'react';
import { ViewportType } from '@src/libs/theme';
import { useMediaQuery } from 'react-responsive';
import { useQueryHelper } from '@src/libs/hooks';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import PostFeedFilterForm from '@src/components/organisms/PostFeedList/PostFeedFilters';
import { SortActionType } from '@src/libs/filter';
import { InstagramStoryPostSortField } from '@src/__generated__/globalTypes';
import { Post } from './model';
import PostFeedList from './PostFeedList';
import { StoryFeedProps } from './index';

interface TemplateProps extends StoryFeedProps {
  posts: Post[];
  total: number;
  sortData: SortActionType<InstagramStoryPostSortField>;
}

const Template = (props: TemplateProps) => {
  const { posts, currentPage, filter, setFilter, accountId, total, sortData } = props;
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });
  const pageInfo = getPageInfo(currentPage, total);
  const { search } = useQueryHelper();

  return (
    <>
      <Wrapper>
        {isDesktopView && (
          <div css={{ marginBottom: '16px' }}>
            <BackNavigator title="Story List" to={`/analytics${search}`} />
          </div>
        )}
        <Card>
          <PostFeedFilterForm filter={filter} setFilter={setFilter} />
          <PostFeedList accountId={accountId} totalCount={pageInfo.totalCount} posts={posts} sortData={sortData} />
        </Card>
        <Pager
          currentPage={currentPage}
          totalPages={pageInfo.totalPages}
          totalCount={pageInfo.totalCount}
          first={pageInfo.firstIndex}
          last={pageInfo.lastIndex}
        />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
  width: calc(100% - 32px);
`;

const Card = styled.div`
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5);
  background-color: #fff;
`;

export default Template;
