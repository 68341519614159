import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';

interface OnlyAvailableOnWebIndicatorProps {
  to: string;
}

const OnlyAvailableOnWebIndicator = ({ to }: OnlyAvailableOnWebIndicatorProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.container}>
      <div>
        <span>{t('Annotation.This feature is only available on PCs')}</span>
        <Link to={to}>{t('Anchor.Back')}</Link>
      </div>
    </div>
  );
};

const styles = {
  container: css`
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    display: flex;
    height: 88px;
    justify-content: center;
    width: 100%;

    & > div {
      text-align: center;

      & > span {
        color: #000;
        font-size: 14px;
        margin-bottom: 8px;
      }

      & > a {
        color: #3892e5;
        font-size: 14px;
      }
    }
  `,
};

export default OnlyAvailableOnWebIndicator;
