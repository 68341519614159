import styled from '@emotion/styled';
import { css } from '@emotion/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ViewportBreakpoint } from '../../../../libs/theme';
import Carousel from '../../../molecules/Carousel';
import { CampaignPriceProps } from '../CampaignDetail/CampaignPrice';
import { switchImage, switchText } from '../../../../libs/SocialMedia';
import { useCopy } from '../../../../libs/hooks/useCopy';
import { CampaignType } from '../../../../__generated__/globalTypes';
import { PalleteButton } from '../../../atoms/Button';
import { mainWhite } from '../../../../libs/pallete';
import { formatIntNumber } from '../../../../libs/format';
import linkIcon from '../../../../assets/icon/link.svg';
import downloadIcon from '../../../../assets/icon/download.svg';
import RichEditor from '../../../molecules/RichEditor';

const carouselWrapperHeight = '230px';

export interface CampaignProductInfoProps extends CampaignPriceProps {
  hashTags: string[];
  campaignType: CampaignType;
  materials: string[];
  title: string;
  startDate: string;
  endDate: string;
  requirements: string;
  information: string;
  trackingUrl?: string | null;
  productUrl?: string | null;
  maximumRevenuePerInfluencer?: number | null;
  downloadMaterialsUrl: string | null;
  selectionStartDate?: string;
  selectionEndDate?: string;
}

const CampaignProductInfo = (props: CampaignProductInfoProps) => {
  const { t } = useTranslation();
  const { handleCopyHasHashtag, isCopying } = useCopy();

  const {
    information,
    hashTags,
    campaignType,
    materials,
    title,
    startDate,
    endDate,
    requirements,
    socialMedias,
    trackingUrl,
    productUrl,
    maximumRevenuePerInfluencer,
    currency,
    downloadMaterialsUrl,
  } = props;

  return (
    <>
      <Heading>{t('Label.Product information')}</Heading>
      <InfoContainer>
        <LabelWrapper>{t('Label.Product Name')}</LabelWrapper>
        <DataWrapper>{title}</DataWrapper>
        <LabelWrapper>{t('Label.Product / Service Information')}</LabelWrapper>
        <RichEditor
          showToolbar={false}
          height={'auto'}
          setOptions={{ mode: 'balloon', resizingBar: false }}
          disable={true}
          value={information}
        />
        {productUrl && (
          <MoreDetailWrapper>
            <PalleteButton
              palleteColor={mainWhite}
              text="More Details"
              href={productUrl}
              targetBlank
              height="32px"
              postfixIcon={<LinkIcon src={linkIcon} alt="link" />}
            />
          </MoreDetailWrapper>
        )}
      </InfoContainer>

      <Heading>{t('Label.Request details')}</Heading>
      <InfoContainer>
        <LabelWrapper>{t('Label.Posting period')}</LabelWrapper>
        <DataWrapper>
          {startDate} - {endDate}
        </DataWrapper>
        <LabelWrapper>{t('Label.Social Media')}</LabelWrapper>
        <DataWrapper css={mediaWrapperStyle}>
          {campaignType === CampaignType.ENGAGEMENT ? (
            socialMedias.map(item => (
              <MediaWrapper key={item}>
                <Icon src={switchImage(item)}></Icon>
                <span>{switchText(item)}</span>
              </MediaWrapper>
            ))
          ) : (
            <MediaWrapper>
              <Icon src={switchImage(socialMedias[0])}></Icon>
              <span>{switchText(socialMedias[0])}</span>
            </MediaWrapper>
          )}
        </DataWrapper>

        <LabelWrapper>{t('Label.Post Requirements')}</LabelWrapper>
        <RichEditor
          showToolbar={false}
          height={'auto'}
          setOptions={{ mode: 'balloon', resizingBar: false }}
          disable={true}
          value={requirements}
        />
        {trackingUrl && (
          <>
            <LabelWrapper>{t('Label.Tracking URL')}</LabelWrapper>
            <DataWrapper>
              {t('Annotation.Please add this URL to your post')}
              <TrackingUrlWrapper>{trackingUrl}</TrackingUrlWrapper>
            </DataWrapper>
          </>
        )}
        {hashTags.length > 0 && (
          <>
            <LabelWrapper>{t('Label.Hash Tags')}</LabelWrapper>
            <DataWrapper css={hashtagContainerStyle}>
              <div css={hashTagWrapperStyle}>
                {hashTags.map((hashTag, index) => (
                  <Hashtag key={hashTag + index}>
                    <span>#{hashTag}</span>
                  </Hashtag>
                ))}
              </div>

              <CopyButton
                palleteColor={mainWhite}
                height="32px"
                onClick={() => handleCopyHasHashtag(hashTags)}
                text="Copy"
                isDisabled={isCopying}
              />
            </DataWrapper>
          </>
        )}
        {!!maximumRevenuePerInfluencer && (
          <>
            <LabelWrapper>{t('Label.Maximum Revenue')}</LabelWrapper>
            <DataWrapper>{`${formatIntNumber(maximumRevenuePerInfluencer)} ${t(currency)}`}</DataWrapper>
          </>
        )}
        {downloadMaterialsUrl && (
          <>
            <LabelWrapper>{t('Label.Download materials for posting')}</LabelWrapper>
            <DataWrapper css={downloadWrapperCss}>
              <PalleteButton
                text="Download"
                palleteColor={mainWhite}
                href={downloadMaterialsUrl}
                prefixIcon={<img src={downloadIcon} alt="download" />}
                height="32px"
                targetBlank
              />
            </DataWrapper>
          </>
        )}
        {materials.length > 0 && (
          <>
            <LabelWrapper>{t('Label.Posting samples')}</LabelWrapper>
            <DataWrapper css={materialWrapperStyle}>
              <Material sources={materials} height={carouselWrapperHeight} />
            </DataWrapper>
          </>
        )}
      </InfoContainer>
    </>
  );
};

const CopyButton = styled(PalleteButton)`
  padding: 0 16px;
  width: auto;
`;

const TrackingUrlWrapper = styled.div`
  margin-top: 16px;
  background-color: #eef3f7;
  border-radius: 3px;
  padding: 8px;
  min-height: 80px;
  word-break: break-all;
`;

const hashtagContainerStyle = css`
  display: flex;
  justify-content: space-between;
`;

const Hashtag = styled.p`
  height: 32px;
  border-radius: 3px;
  background-color: #d7e9fa;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  span {
    color: #3892e5;
  }
`;

const hashTagWrapperStyle = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Icon = styled.img`
  width: 16px;
  margin-right: 8px;
`;

const DataWrapper = styled.div`
  padding: 8px 16px 16px;
  font-size: 13px;
  line-height: 1.38;
  letter-spacing: normal;
  color: #27313b;
`;

const MediaWrapper = styled.div`
  display: flex;
  margin-right: 24px;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

const Material = styled(Carousel)`
  width: 311px;

  @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
    width: 100%;
  }
`;

const mediaWrapperStyle = css`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
`;

const materialWrapperStyle = css`
  display: flex;
  justify-content: center;
  padding: 16px;
  height: ${carouselWrapperHeight};
`;

const InfoContainer = styled.div`
  border: 1px solid #eef3f7;
  margin-bottom: 24px;
`;

const LabelWrapper = styled.p`
  height: 32px;
  background-color: #eef3f7;
  padding-left: 6px;
  display: flex;
  align-items: center;
  border-left: 2px solid #ff2b52;
  font-weight: 600;
`;

const Heading = styled.h3`
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.13;
  letter-spacing: normal;
  color: #27313b;
`;

const MoreDetailWrapper = styled.div`
  padding: 4px 24px 16px;
`;

const LinkIcon = styled.img`
  padding-left: 8px;
`;

const downloadWrapperCss = css`
  padding: 16px 24px;
`;

export default CampaignProductInfo;
