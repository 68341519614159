import styled from '@emotion/styled';
import * as React from 'react';
import Slider from 'react-slick';
import { Icon } from '@src/components/atoms/Icon';
import Image from '@src/components/atoms/Image';
import CarouselArrow from './CarouselArrow';

interface CarouselProps {
  className?: string;
  sources: string[];
  height?: string;
  width?: string;
  afterChangeHandler?: (currentSlide: number) => void;
  currSlideIdx?: number;
}

const SETTINGS = {
  speed: 500,
  infinite: true,
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <CarouselArrow content={<Icon color="#74828e" icon="chevron_right" />} />,
  prevArrow: <CarouselArrow content={<Icon color="#74828e" icon="chevron_left" />} />,
};

const Carousel = (props: CarouselProps) => {
  const { className, sources, height, width, afterChangeHandler, currSlideIdx } = props;
  let sliderMethods: Slider | null;

  React.useEffect(() => {
    if (sliderMethods && currSlideIdx !== undefined) {
      sliderMethods.slickGoTo(currSlideIdx);
    }
  }, [currSlideIdx]);

  return (
    <StyledSlider
      className={className}
      height={height}
      {...SETTINGS}
      afterChange={afterChangeHandler}
      ref={slider => (sliderMethods = slider)}
    >
      {sources.map(source => {
        const extensions = /.jpeg|.jpg|.png|.gif|.zip|.pdf|.ppt|.pptx|.xls|.xlsx|.doc|.docx|.txt/;
        const isImage = extensions.test(source.toLowerCase());

        return (
          <Wrapper key={source}>
            {isImage ? (
              <StyledImage
                src={source}
                width={width || 'auto'}
                height={height || 'auto'}
                alt=""
                css={{ background: '#000' }}
              />
            ) : (
              <Video
                src={source}
                loop={true}
                muted={true}
                autoPlay={true}
                playsInline={true}
                controls={true}
                width={width ? `${width}px` : 'auto'}
                height={height ? `${height}px` : 'auto'}
              />
            )}
          </Wrapper>
        );
      })}
    </StyledSlider>
  );
};

const StyledSlider = styled(Slider)<{ height?: string }>`
  width: 100%;

  & div {
    outline: none !important;
  }

  & .slick-arrow {
    width: 24px;
    height: 48px;
    z-index: 100;
  }

  & .slick-arrow.slick-next {
    right: 0;
  }

  & .slick-arrow.slick-prev {
    left: 0;
  }

  .slick-dots {
    bottom: 0;
    display: block;
    background: white;
    width: fit-content;
    border-radius: 8px;
    height: 16px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & .slick-dots li {
    margin: 0;
    top: -4px;
  }

  & .slick-arrow::before {
    font-size: 24px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    opacity: 0.8;
  }

  & .slick-dots li button::before {
    color: #ff2b52;
    font-size: 10px;
  }

  & .slick-slide > div > div {
    ${({ height }) => !!height && `height: ${height}`};
  }
`;

const Wrapper = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: #000;
`;

const StyledImage = styled(Image)`
  margin: auto;
`;

const Video = styled.video`
  margin: auto;
`;

export default Carousel;
