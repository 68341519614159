import styled from '@emotion/styled';
import { Global, css } from '@emotion/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import clockIcon from '@src/assets/icon/clock.svg';
import { formatIntNumber, formatPercent } from '@src/libs/format';
import { switchImage } from '@src/libs/SocialMedia';
import { ViewportBreakpoint } from '@src/libs/theme';
import HighChart, { Highcharts } from '@src/components/molecules/HighChart';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import * as chartConfig from '@src/libs/date';
import ProfileDetailsContentWrapper from '../ProfileDetailsContentWrapper/ProfileDetailsContentWrapper';

interface PostHabit {
  weekday: number;
  hour: number;
  averageLikes: number;
  averageComments: number;
  averageShares: number;
  engagementRate: number;
}
export interface PostingHabitsProps {
  className?: string;
  averageEngagement: number | null;
  engagementRate: number | null;
  averageLikes: number | null;
  averageComments: number | null;
  averageShares: number | null;
  averageViews: number | null;
  postingHabitsData: PostHabit[] | null;
  socialAccountType: SocialAccountType;
}

const PostingHabits = (props: PostingHabitsProps) => {
  const { t } = useTranslation();

  const {
    className,
    postingHabitsData,
    averageEngagement,
    engagementRate,
    averageComments,
    averageLikes,
    averageShares,
    averageViews,
    socialAccountType,
  } = props;

  const chartData =
    postingHabitsData && !!postingHabitsData.length
      ? postingHabitsData.map(item => ({
          x: item.hour,
          y: item.weekday,
          value: parseFloat(formatPercent(item.engagementRate, false)),
          likes: formatIntNumber(item.averageLikes),
          comments: formatIntNumber(item.averageComments),
          time: `${chartConfig.weekDays[item.weekday]} ${chartConfig.hourIntervals[item.hour]}`,
        }))
      : [];

  const options: Highcharts.Options = {
    chart: {
      type: 'heatmap',
      marginTop: 0,
      plotBorderWidth: 1,
      marginRight: 0,
      inverted: true,
      height: 615,
      borderColor: '#FFFFFF',
      borderWidth: 2,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: chartConfig.hourIntervals.map(item => item.replace(/\s/g, '')),
      min: 0,
      gridLineColor: 'transparent',
      lineColor: '#FFFFFF',
    },
    yAxis: {
      categories: chartConfig.weekDays.map(item => item.slice(0, 3)),
      title: {
        text: '',
      },
      min: 0,
      gridLineColor: 'transparent',
      lineColor: '#FFFFFF',
      lineWidth: 2,
    },
    colorAxis: {
      min: 0,
      minColor: chartConfig.minColor,
      maxColor: chartConfig.maxColor,
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        type: 'heatmap',
        pointPadding: 4,
        borderColor: 'transparent',
        name: 'Posting Habits',
        data: chartData,
        dataLabels: {
          enabled: false,
        },
      },
    ],
    tooltip: {
      padding: 0,
      useHTML: true,
      borderColor: '#27313b',
      headerFormat: '',
      borderWidth: 0,
      borderRadius: 3,
      pointFormat: `
      <div style="border-radius:3px;box-shadow: 0 3px 10px 0 rgba(39, 49, 59, 0.59);background-color:#27313b;padding:16px">

      <h1 style="font-size:13px;color: #ffffff;margin-bottom:8px">{point.time}  </h1>
 
      <div style="display:flex">
          <div style="margin-right:16px">
            <p style="color:#ffffff;font-size:13px">{point.likes}</p>
            <p style="color:#6e7c89;font-size:11px">${t('Label.Likes')}</p>
          </div>
          <div style="margin-right:16px">
            <p style="color:#ffffff;font-size:13px">{point.comments}</p>
            <p style="color:#6e7c89;font-size:11px">${
              socialAccountType === SocialAccountType.TWITTER ? t('Label.Replies') : t('Label.Comments')
            }</p></div>
          <div>
            <p style="color:#ffffff;font-size:13px">{point.value}%</p>
            <p style="color:#6e7c89;font-size:11px">${t('Label.Engagement Rate')}</p>
          </div>
        </div>`,
      footerFormat: '</div>',
    },
  };

  return (
    <ProfileDetailsContentWrapper
      icon={clockIcon}
      className={className}
      title={t('HeaderColumn.Posting Habits')}
      dataNotAvailable={!postingHabitsData || postingHabitsData.every(data => data.engagementRate === 0)}
    >
      <SubHeading>
        <SocialIcon src={switchImage(socialAccountType)} />
        <StatsContainer>
          <DataWrapper>
            <Value>{formatPercent(engagementRate)}</Value>
            <Label>{t('HeaderColumn.Average Engagement Rate')}</Label>
          </DataWrapper>
          <DataWrapper>
            <Value>{formatIntNumber(averageEngagement)}</Value>
            <Label>{t('HeaderColumn.Average Engagement Number')}</Label>
          </DataWrapper>
        </StatsContainer>

        <SocialDataWrapper>
          <SocialData>
            <Count>{formatIntNumber(averageLikes)}</Count>
            <Text>{t('Label.Likes')}</Text>
          </SocialData>
          <SocialData>
            <Count>{formatIntNumber(averageComments)}</Count>
            <Text>{socialAccountType === SocialAccountType.TWITTER ? t('Label.Replies') : t('Label.Comments')}</Text>
          </SocialData>
          <SocialData>
            <Count>{formatIntNumber(averageShares)}</Count>
            <Text>{socialAccountType === SocialAccountType.TWITTER ? t('Label.Retweets') : t('Label.Shares')}</Text>
          </SocialData>
          {socialAccountType !== SocialAccountType.TWITTER && (
            <SocialData>
              <Count>{formatIntNumber(averageViews)}</Count>
              <Text>{t('Label.Views')}</Text>
            </SocialData>
          )}
        </SocialDataWrapper>
      </SubHeading>
      <Global
        styles={css`
          .highcharts-tooltip path {
            fill: #27313b;
          }
        `}
      />
      <HighChart options={options} />
    </ProfileDetailsContentWrapper>
  );
};

const SocialDataWrapper = styled.div`
  display: flex;
  padding-left: 20px;
  flex-wrap: wrap;
`;

const SocialData = styled.div`
  margin-right: 35px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const Count = styled.span`
  color: #27313b;
  font-size: 14px;
  margin-bottom: 4px;
`;

const Text = styled.span`
  color: #6e7c89;
  font-size: 11px;
`;

const SocialIcon = styled.img`
  width: 24px;
  margin-right: 16px;
  align-self: flex-start;
`;

const StatsContainer = styled.div`
  display: flex;
  border-right: 1px solid #eef3f7;

  @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
    flex-direction: column;
  }
`;
const SubHeading = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: flex-end;

  @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
    align-items: flex-start;
  }
`;
const DataWrapper = styled.div`
  margin-right: 22px;

  @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
    &:first-of-type {
      margin-bottom: 16px;
    }
  }
`;
const Label = styled.p`
  font-size: 12px;
  color: #6e7c89;
`;
const Value = styled.p`
  font-size: 20px;
  color: #27313b;
  margin-bottom: 4px;
`;

export default PostingHabits;
