import { SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import { Option } from '../../../../libs/form';
import BaseSelector, { MenuType } from '../BaseSelector';
import { MenuControlIcon } from '../../../atoms/MenuControlIcon/MenuControlIcon';
import MenuItems from './MenuItems';
import { getInitialSelectedItem } from './utils';

type ValueType = Option | null;
export interface SingleSelectorProps {
  autoSelectMatched?: boolean;
  className?: string;
  menuCss?: SerializedStyles;
  value?: string;
  name: string;
  options: Option[];
  error?: boolean;
  disabled?: boolean;
  placeholder?: string;
  hideInput?: boolean;
  hideDeselectOption?: boolean;
  onChange?: (value: string) => void;
  onClose?: () => void;
  isRoundInput?: boolean;
  customLabel?: string;
  dropdownCss?: SerializedStyles;
}

const SingleSelector = (props: SingleSelectorProps) => {
  const {
    autoSelectMatched,
    name,
    menuCss,
    className,
    hideDeselectOption,
    hideInput,
    value = '',
    options,
    error = false,
    disabled,
    placeholder,
    onChange,
    onClose,
    isRoundInput = true,
    customLabel = '',
    dropdownCss,
  } = props;

  // Items
  const selectedValue = getInitialSelectedItem(value, options) || null;
  const setSelectedValue = (item: Option | null) => {
    if (onChange) {
      onChange(item ? item.value : '');
    }
  };
  const removeValue = (_: Option) => {
    setSelectedValue(null);
  };

  const onClickInputBox = (menu: MenuType) => {
    if (disabled) {
      return;
    }
    menu.setIsOpen(true);
  };

  return (
    <BaseSelector<ValueType>
      autoSelectMatched={autoSelectMatched}
      className={className}
      name={name}
      items={{ selectedValue, setSelectedValue, removeValue }}
      error={error}
      options={options}
      disabled={disabled}
      disabledInputBox={false}
      hideInput={hideInput}
      isRoundInput={isRoundInput}
      placeholder={!!selectedValue ? '' : placeholder}
      onClose={onClose}
      onClickInputBox={onClickInputBox}
      MenuControlIcon={disabled ? undefined : MenuControlIcon}
      dropdownCss={dropdownCss}
      renderSelectedValue={({ selectedValue: option }) =>
        !!option ? (
          <SelectedValues className="selected_values">
            {option.prefixIcon}
            {option.icon}
            <SelectedValueText>{option.label}</SelectedValueText>
          </SelectedValues>
        ) : (
          <p css={{ marginLeft: '12px' }}>{customLabel}</p>
        )
      }
      renderMenu={({ items, search, menu }) => {
        const handleRemove = (_: React.MouseEvent<HTMLDivElement>) => {
          if (disabled) {
            return;
          }
          items.setSelectedValue(null);
          menu.setIsOpen(false);
        };

        const handleClickItem = (item: Option) => (_: React.MouseEvent<HTMLDivElement>) => {
          if (disabled) {
            return;
          }
          items.setSelectedValue(item);
          menu.setIsOpen(false);
        };

        return (
          <MenuWrapper isRoundInput={isRoundInput} isError={error} css={menuCss}>
            <MenuItems
              disabled={disabled}
              options={search.filteredValue}
              handleRemove={handleRemove}
              handleClick={handleClickItem}
              hideDeselectOption={hideDeselectOption}
            />
          </MenuWrapper>
        );
      }}
    />
  );
};

const SelectedValues = styled.div`
  display: flex;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  position: absolute;
  padding: 0 2em 0 0.5em;
  white-space: nowrap;
  width: 100%;
  box-sizing: border-box;
`;

const MenuWrapper = styled.div<{ isRoundInput: boolean; isError: boolean }>`
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: ${({ isRoundInput }) => (isRoundInput ? '0 0 20px 20px' : '3px')};
  border: ${({ isError }) => `1px solid ${isError ? 'tomato' : '#179cd7'}`};
  border-top: 0;
  padding-bottom: 10px;
  box-sizing: border-box;
  z-index: 10;
  position: absolute;
  background-color: #fff;
  width: fill-available;
  transition: opacity 0.1s ease;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
`;
const SelectedValueText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: auto 0;
`;

export default SingleSelector;
