import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useHistory } from 'react-router';
import { checkSignedIn } from '../../../../libs/auth';

const PublicRoute = ({ component: Component, ...rest }: RouteProps) => {
  const history = useHistory();
  const isSignedIn = checkSignedIn();

  if (isSignedIn) {
    history.push({ pathname: '/home', state: { from: history.location } });
  }

  return <Route {...rest} render={props => (Component ? <Component {...props} /> : null)} />;
};

export default PublicRoute;
