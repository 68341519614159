import styled from '@emotion/styled';
import * as React from 'react';

interface FieldType {
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: any) => void;
  value?: any;
  name?: string;
}

export interface TextProps extends FieldType, InputProps {
  className?: string;
  placeholder: string;
  type?: string;
  unit?: string;
  autoFocus?: boolean;
}

const Text = (props: TextProps) => {
  const { className, placeholder, unit, type, autoFocus, ...field } = props;
  const inputType = !!type ? type : 'text';

  return (
    <Wrapper className={className}>
      <Input type={inputType} placeholder={placeholder} autoFocus={autoFocus} {...field} />
      {unit && <Unit>{unit}</Unit>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

interface InputProps {
  error?: boolean;
  disabled?: boolean;
}

const Input = styled.input<InputProps>`
  box-sizing: border-box;
  width: 100%;
  padding: 0 12px;
  background-color: #fff;
  background-color: ${props => (props.disabled ? '#f2f2f2' : '#fff')};
  border: 1px solid #dee5ec;
  border-color: ${props => (!!props.error ? 'tomato' : '#e0e8ed')};
  border-radius: 3px;
  font-size: 13px;
  line-height: 32px;
  letter-spacing: 0.02em;
  ${props => !props.error && `&:focus {border-color: lightslategrey}`};
`;

const Unit = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 0 12px;
  position: absolute;
  right: 1px;
  top: 1px;
  background-color: #fff;
  color: #828282;
  font-size: 10px;
  letter-spacing: 0.03em;
  pointer-events: none;
`;

export default Text;
