import axios from 'axios';
import isEqual from 'lodash/isEqual';
import styled from '@emotion/styled';
import React, { useEffect, useState, ReactNode } from 'react';
import { SerializedStyles } from '@emotion/core';
import { useQueryHelper } from '../../../libs/hooks';
import DropArea from './DropArea';
import { DEFAULT_UPLOAD_LIMIT, VIDEO_UPLOAD_LIMIT } from './helpers';
import Preview from './Preview';
import { UploadedFile } from './types';

// Types
export interface DragAndDropProps {
  name: string;
  notes: string[];
  initialFileUrls: UploadedFile[];
  value: string[];
  multiple?: boolean;
  accept?: string;
  error?: boolean;
  disabled?: boolean;
  previewWidth?: string;
  customLabel?: ReactNode;
  isHidden?: boolean;
  className?: string;
  previewCss?: SerializedStyles;
  dropAreaCss?: SerializedStyles;
  generateSignedUrl: (filename: string) => Promise<{ fileName: string; signedUrl: string } | null>;
  setIsFileUploading?: (isUploading: boolean) => void;
  setPreviewValue?: (previewImage: string[]) => void;
  setFieldValue(field: string, value: string[]): void;
}

const DragAndDropComponent = (props: DragAndDropProps) => {
  const { enqueueSnackbar, t } = useQueryHelper();
  const {
    initialFileUrls,
    setFieldValue,
    value,
    setIsFileUploading,
    name,
    generateSignedUrl,
    multiple = false,
    customLabel,
    className,
    previewCss,
    dropAreaCss,
    setPreviewValue,
  } = props;

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>(initialFileUrls);
  const [uploadProgress, setUploadProgress] = useState<{ progressUrl: string | null; progress: number }>({
    progressUrl: null,
    progress: 0,
  });

  // rerender if the initialFileUrls is changed
  useEffect(() => {
    if (
      !isEqual(
        initialFileUrls.map(item => item.url),
        uploadedFiles.map(item => item.url)
      )
    ) {
      setUploadedFiles(initialFileUrls);
    }
  }, [initialFileUrls]);

  const uploadFileToGCS = async ({ signedUrl }: { fileName: string; signedUrl: string }, accepted: File) => {
    const videoUrlName = signedUrl.split('?')[0];
    const preview = URL.createObjectURL(accepted);

    let newUploadedFiles = [];

    if (multiple) {
      newUploadedFiles = [...uploadedFiles, { url: videoUrlName, preview }];
      setUploadedFiles(newUploadedFiles);
      setFieldValue(name, [...value, videoUrlName]);
      if (setPreviewValue) {
        setPreviewValue(newUploadedFiles.map(file => file.preview));
      }
    } else {
      newUploadedFiles = [{ url: videoUrlName, preview }];
      setUploadedFiles(newUploadedFiles);
      setFieldValue(name, [videoUrlName]);
      if (setPreviewValue) {
        setPreviewValue(newUploadedFiles.map(file => file.preview));
      }
    }

    if (setIsFileUploading) {
      setIsFileUploading(true);
    }

    try {
      await axios(signedUrl, {
        method: 'PUT',
        data: accepted,
        onUploadProgress: p => {
          setUploadProgress({ progress: (p.loaded / p.total) * 100, progressUrl: videoUrlName });
        },
      });

      setUploadProgress({ progress: 100, progressUrl: null });
    } catch (error) {
      enqueueSnackbar(t('fileUploadFail'), { variant: 'error' });
      setUploadedFiles(uploadedFiles);
    }

    if (setIsFileUploading) {
      setIsFileUploading(false);
    }
  };

  const onDrop = async (accepted: File[]) => {
    const acceptedFile = accepted && accepted.length ? accepted[0] : null;
    const isVideoFile = acceptedFile && ['video/avi', 'video/mp4', 'video/quicktime'].includes(acceptedFile.type);
    const sizeLimit = isVideoFile ? VIDEO_UPLOAD_LIMIT : DEFAULT_UPLOAD_LIMIT;

    if (acceptedFile && acceptedFile.size > sizeLimit) {
      enqueueSnackbar(t('General.UploadSizeError'), { variant: 'error' });

      return;
    }

    if (!acceptedFile) {
      return;
    }

    const generatedSignedUrl = await generateSignedUrl(acceptedFile.name);
    if (generatedSignedUrl) {
      uploadFileToGCS(generatedSignedUrl, acceptedFile);
    }

    return;
  };

  const deleteUploadedFile = (index: number) => () => {
    const newUploadedFiles = uploadedFiles.filter((_, i) => i !== index);
    const newMaterialsValue = value.filter(materialUrl => materialUrl !== uploadedFiles[index].url);

    setUploadedFiles(newUploadedFiles);
    setFieldValue(name, newMaterialsValue);
    if (setPreviewValue) {
      setPreviewValue(newUploadedFiles.map(file => file.preview));
    }

    return;
  };

  return (
    <Wrapper className={className}>
      {!props.isHidden && (
        <DropAreaWrapper css={dropAreaCss}>
          {!!customLabel && customLabel}
          <DropArea
            notes={props.notes}
            name={props.name}
            multiple={false}
            accept={props.accept}
            error={props.error}
            onDrop={onDrop}
            disabled={!!props.disabled}
          />
        </DropAreaWrapper>
      )}
      <Preview
        uploadedFiles={uploadedFiles}
        deleteUploadedFile={deleteUploadedFile}
        previewWidth={props.previewWidth}
        disabled={props.disabled}
        uploadProgress={uploadProgress}
        previewCss={previewCss}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
const DropAreaWrapper = styled.div``;

export default DragAndDropComponent;
