import React from 'react';
import { useHistory, useParams } from 'react-router';
import Template from './template';
export interface Params {
  id: string;
  postId: string;
}

const EditEngagementPost = () => {
  const history = useHistory();
  const params = useParams<Params>();
  const id = Number(params.id);
  const postId = Number(params.postId);

  if (!id) {
    history.push('/job');
  }
  if (!postId) {
    history.push(`/job/engagement/${id}/post`);
  }

  return <Template id={id} postId={postId} />;
};

export default EditEngagementPost;
