import { ToastContainer, toast } from 'react-toastify';
import * as React from 'react';
import { css } from '@emotion/react';
import closeIcon from '@src/assets/icon/close.svg';
import 'react-toastify/dist/ReactToastify.css';
import warningIcon from '@src/assets/icon/warningOrange.svg';
import errorToastify from '@src/assets/icon/errorToastify.svg';
import TextCutter from '@src/components/atoms/TextCutter';

const Notification = () => (
  <ToastContainer
    position="top-right"
    hideProgressBar
    closeOnClick
    draggable
    closeButton={() => <img src={closeIcon} css={{ filter: 'contrast(0.5)' }} />}
    css={styles.root}
    limit={3}
  />
);

export const customIcons = {
  [toast.TYPE.SUCCESS]: { icon: undefined, header: 'Success' },
  [toast.TYPE.ERROR]: { icon: <img src={errorToastify} alt="error icon" width={24} height={24} />, header: 'Error' },
  [toast.TYPE.INFO]: { icon: undefined, header: 'Information' },
  [toast.TYPE.WARNING]: {
    icon: <img src={warningIcon} alt="warning icon" width={24} height={24} />,
    header: 'Warning',
  },
  [toast.TYPE.DEFAULT]: { icon: undefined, header: '' },
};

interface ToastWrapperProps {
  header: string;
  message: string;
}
export const ToastWrapper = ({ header, message }: ToastWrapperProps) => (
  <div>
    {header ? <h3 css={styles.toastHeader}>{header}</h3> : null}
    <TextCutter lines={2} text={message} css={styles.toastContent} />
  </div>
);

const styles = {
  root: css`
    .Toastify__toast-body {
      padding: 0;
    }

    .Toastify__toast--error {
      border-left: 3px solid #ff5f5f;
    }

    .Toastify__toast--warning {
      border-left: 3px solid #ffb63d;
    }

    .Toastify__toast--success {
      border-left: 3px solid #40b87c;
    }

    .Toastify__toast--info {
      border-left: 3px solid #3892e5;
    }
  `,
  toastHeader: css`
    font-weight: 600;
    font-size: 14px;
    color: #27313b;
  `,
  toastContent: css`
    font-size: 12px;
    color: #27313b;
  `,
};

export default Notification;
