import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import Grid from '@src/components/atoms/Grid';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import SocialAccountConnector from '@src/components/organisms/Settings/SocialConnect/SocialAccountConnector';
import { ConnectType } from '@src/libs/auth';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useQueryHelper, useRedirectWithinIframe } from '@src/libs/hooks';
import { useRedirectUrl } from '@src/libs/hooks/useRedirectUrl';
import { ViewportType } from '@src/libs/theme';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import CONNECTED_SOCIAL_ACCS from './queries/AnyXCheckConnectedSocialAccounts.graphql';
import { AnyXCheckConnectedSocialAccounts } from './queries/__generated__/AnyXCheckConnectedSocialAccounts';

const SocialConnect = () => {
  const { enqueueSnackbar, t } = useQueryHelper();
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });
  const { getRedirectUrl } = useRedirectUrl(FE_REDIRECT_MAPPING.CONNECT);
  const { startRedirectProcess } = useRedirectWithinIframe();

  const { data } = useQuery<AnyXCheckConnectedSocialAccounts>(CONNECTED_SOCIAL_ACCS, {
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  async function onClickConnectSocialAccount(socialMedia: SocialAccountType) {
    let sm = socialMedia;
    switch (sm) {
      case SocialAccountType.INSTAGRAM:
        localStorage.setItem('connectType', ConnectType.CONNECT_IG_ACCOUNT);
        sm = SocialAccountType.FACEBOOK;
        break;
      case SocialAccountType.TWITTER:
        localStorage.setItem('redirectType', FE_REDIRECT_MAPPING.CONNECT);
        break;
    }

    try {
      const redirectUrl = await getRedirectUrl(sm);

      if (redirectUrl) {
        startRedirectProcess({ redirectUrl, redirectType: FE_REDIRECT_MAPPING.CONNECT });
      }
    } catch (error) {
      enqueueSnackbar(t('UnexpectedError'), { variant: 'error' });
    }
  }

  const connectedSocialAccounts = data?.anyXCheckConnectedSocialAccounts;
  const fbAccounts = [...(connectedSocialAccounts?.facebookPages || []), ...(connectedSocialAccounts?.facebooks || [])];
  const instagramAccounts = connectedSocialAccounts?.instagramAccounts || [];
  const twitterAccounts = connectedSocialAccounts?.twitterAccounts || [];
  const youtubeAccounts = connectedSocialAccounts?.youtubeAccounts || [];
  const tiktokAccounts = connectedSocialAccounts?.tiktokAccounts || [];

  return (
    <div css={styles.container}>
      {isDesktopView && <BackNavigator title="Social Connect" to="/settings" />}

      <div css={styles.description}>
        {t('Annotation.Connect with your social account to use Analytics data and get sponsorship campaign')}
      </div>

      {connectedSocialAccounts && (
        <div css={styles.socialMediasContainer}>
          <Grid md={8} sm={10} xs={12}>
            <SocialAccountConnector
              accounts={fbAccounts}
              socialMedia={SocialAccountType.FACEBOOK}
              onClickConnect={() => onClickConnectSocialAccount(SocialAccountType.FACEBOOK)}
            />
            <SocialAccountConnector
              accounts={instagramAccounts}
              socialMedia={SocialAccountType.INSTAGRAM}
              onClickConnect={() => onClickConnectSocialAccount(SocialAccountType.INSTAGRAM)}
            />
            <SocialAccountConnector
              accounts={twitterAccounts}
              socialMedia={SocialAccountType.TWITTER}
              onClickConnect={() => onClickConnectSocialAccount(SocialAccountType.TWITTER)}
            />
            <SocialAccountConnector
              accounts={youtubeAccounts}
              socialMedia={SocialAccountType.YOUTUBE}
              onClickConnect={() => onClickConnectSocialAccount(SocialAccountType.YOUTUBE)}
            />
            <SocialAccountConnector
              accounts={tiktokAccounts}
              socialMedia={SocialAccountType.TIKTOK}
              onClickConnect={() => onClickConnectSocialAccount(SocialAccountType.TIKTOK)}
            />
          </Grid>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: css`
    margin: 16px 24px;

    @media (max-width: ${ViewportType.TABLET}px) {
      margin: 16px;
    }
  `,
  description: css`
    color: #27313b;
    font-size: 14px;
    margin-bottom: 24px;
    margin-left: 48px;

    @media (max-width: ${ViewportType.TABLET}px) {
      margin-left: 0;
    }
  `,
  socialMediasContainer: css`
    display: flex;
    justify-content: center;
  `,
};

export default SocialConnect;
