import React from 'react';
import { useTranslation } from 'react-i18next';
import VideoPreview from '@src/components/atoms/VideoPreview';
import CarouselModal from '@src/components/molecules/CarouselModal/CarouselModal';
import { isVideoThumbnail } from '@src/libs/image';
import { useMediaQuery } from 'react-responsive';
import { ViewportBreakpoint } from '@src/libs/theme';
import { EmptyTable } from '@src/components/molecules/Table';
import { SliderComponents, StyledComponents, SliderCss } from '@src/components/molecules/SliderTable';
import { HeaderColumn, DateColumn } from '@src/components/atoms/List';
import { ORDER, InstagramFeedPostSortField } from '@src/__generated__/globalTypes';
import { SortActionType } from '@src/libs/filter';
import { formatIntNumber } from '@src/libs/format';
import PostColumn from '@src/components/organisms/AnalyticsShared/PostColumn';
import * as StoryStyled from '@src/pages/InstagramStoryOrFeedPosts/Styled';
import { usePostDetailModal } from '@src/components/organisms/AnalyticsShared/BasePostList/usePostDetailModal';
import InstargamTaggedPostDetail from '../TaggedPostDetail';
import InstargamMentionedPostDetail from '../MentionedPostDetail';
import { InteractionPostModel } from './types';
import { instagramInteractionPostListColumns } from './helpers';

interface PostFeedList {
  posts: InteractionPostModel[];
  accountId: number;
  totalCount: number;
  sortData: SortActionType<InstagramFeedPostSortField>;
  isTaggedPosts: boolean;
}
const TaggedPostList = ({ posts, accountId, totalCount, sortData: { sort, setSort }, isTaggedPosts }: PostFeedList) => {
  const { t } = useTranslation();
  const showColumnView = useMediaQuery({ maxWidth: ViewportBreakpoint.MEDIUM });
  const { styles } = StoryStyled;

  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev,
  } = usePostDetailModal(posts);

  if (!posts?.length) {
    return <EmptyTable colSpan={14} css={styles.emptyTable} />;
  }

  return (
    <>
      <StoryStyled.PostCount css={!showColumnView ? styles.postsCount : ''}>{`${totalCount} ${t(
        'Label.Posts found'
      )}`}</StoryStyled.PostCount>
      {showColumnView ? (
        <>
          {posts.map(post => (
            <StoryStyled.PostWrapper key={`${post.id}_${post.postDate}`}>
              <StoryStyled.PostHeader onClick={() => handleClickPost(post.id)}>
                {isVideoThumbnail(post.thumbNail) ? (
                  <VideoPreview url={post.thumbNail} width="92px" height="52px" css={{ backgroundColor: '#dee5ec' }} />
                ) : (
                  <StoryStyled.PostImage src={post.thumbNail} />
                )}
                <StoryStyled.PostContent text={post.content || '-'} lines={3} />
              </StoryStyled.PostHeader>
              <StoryStyled.PostInfo>
                {instagramInteractionPostListColumns.map(col => (
                  <StoryStyled.Info key={col.orderField}>
                    <StoryStyled.Unit>{t(col.title)}</StoryStyled.Unit>
                    <StoryStyled.Value>{col.formatter(post[col.key])}</StoryStyled.Value>
                  </StoryStyled.Info>
                ))}
              </StoryStyled.PostInfo>
            </StoryStyled.PostWrapper>
          ))}
        </>
      ) : (
        <SliderComponents.Container>
          <SliderComponents.SliderSection>
            <SliderComponents.SliderTable>
              <thead>
                <SliderComponents.HeaderRow css={styles.headerRow}>
                  <SliderComponents.StickyHeaderTdWrapper>
                    <HeaderColumn title="Posts" css={styles.postsHeader} isTable={false} />
                  </SliderComponents.StickyHeaderTdWrapper>
                  {instagramInteractionPostListColumns.map(({ title, orderField }) => (
                    <HeaderColumn
                      key={title}
                      title={title}
                      {...(orderField && {
                        activeAsc: sort.field === orderField && sort.order === ORDER.ASC,
                        activeDesc: sort.field === orderField && sort.order === ORDER.DESC,
                        sortAsc: () => setSort({ field: orderField, order: ORDER.ASC }),
                        sortDesc: () => setSort({ field: orderField, order: ORDER.DESC }),
                      })}
                      css={styles.sliderTableColsHeaders}
                    />
                  ))}
                </SliderComponents.HeaderRow>
              </thead>

              <tbody>
                {posts?.map(post => {
                  const { id, postDate, likes, comments, content, thumbNail } = post;

                  return (
                    <StyledComponents.StyledRowNew css={[SliderCss.paddingSides]} key={`${id}_${postDate}`}>
                      <SliderComponents.StickyBodyRowWrapper css={styles.postsColumn}>
                        <PostColumn
                          key={id}
                          id={id}
                          src={thumbNail}
                          title={content}
                          onClick={() => handleClickPost(id)}
                        />
                      </SliderComponents.StickyBodyRowWrapper>
                      <StyledComponents.NumberColumn data={formatIntNumber(likes)} />
                      <StyledComponents.NumberColumn data={formatIntNumber(comments)} />
                      <DateColumn date={postDate} css={styles.alignEnd} />
                    </StyledComponents.StyledRowNew>
                  );
                })}
              </tbody>
            </SliderComponents.SliderTable>
          </SliderComponents.SliderSection>
        </SliderComponents.Container>
      )}

      <CarouselModal
        open={!!openPostDetail}
        title=""
        cancel="OK"
        onClose={handleClosePostDetail}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        siblingPostId={siblingPostId}
      >
        {isTaggedPosts ? (
          <InstargamTaggedPostDetail socialMediaAccountId={accountId} closeModal={handleClosePostDetail} />
        ) : (
          <InstargamMentionedPostDetail socialMediaAccountId={accountId} closeModal={handleClosePostDetail} />
        )}
      </CarouselModal>
    </>
  );
};

export default TaggedPostList;
