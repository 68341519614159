import styled from '@emotion/styled';
import TextCutter from '@src/components/atoms/TextCutter';
import Image from '@src/components/atoms/Image';
import { css } from '@emotion/react';

export const styles = {
  emptyTable: css`
    margin: 0;
    border: 0;
  `,
  postsCount: css`
    padding: 16px;
    border-bottom: 1px solid #e6ecf0;
  `,
  headerRow: css`
    background-color: #f6f8fa;
    height: 48px;
  `,
  postsHeader: css`
    width: 230px;
    padding: 0;

    & > div {
      justify-content: flex-start;
    }
  `,
  sliderTableColsHeaders: css`
    text-transform: capitalize;
  `,
  postsColumn: css`
    max-width: 200px;
  `,
  alignEnd: css`
    & p {
      text-align: end;
    }
  `,
};

export const PostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;
  border-top: solid 1px #e6ecf0;
`;

export const PostHeader = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const PostImage = styled(Image)`
  width: 92px;
  height: 52px;
  background-color: #dee5ec;
`;

export const PostContent = styled(TextCutter)`
  flex: 1;
  margin-left: 12px;
  font-size: 13px;
  color: #3892e5;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`;

export const PostInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25%);
  margin-top: 16px;
`;

export const Info = styled.div`
  padding: 4px 0;
`;

export const Value = styled.p`
  font-size: 13px;
  color: #27313b;
`;
export const Unit = styled.p`
  font-size: 10px;
  line-height: 1.3;
  letter-spacing: normal;
  color: #6e7c89;
`;

export const PostCount = styled.div`
  padding: 8px 16px;
  border-top: solid 1px #e6ecf0;
`;
