import styled from '@emotion/styled';
import React, { ReactNode, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export interface MoreProps {
  className?: string;
  children: ReactNode;
  initialHeight: number;
}

const More = (props: MoreProps) => {
  const [showMoreButton, setShowMoreButton] = useState(true);
  const [scrollHeight, setScrollHeight] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const { children, initialHeight } = props;

  useEffect(() => {
    if (containerRef && containerRef.current) {
      setScrollHeight(containerRef.current.scrollHeight);
    }
  });

  return (
    <>
      <Container
        ref={containerRef}
        scrollHeight={scrollHeight}
        initialHeight={initialHeight}
        showMoreButton={showMoreButton}
      >
        {children}
        {showMoreButton && scrollHeight > initialHeight && (
          <ButtonWrapper>
            <MoreButton
              onClick={() => {
                setShowMoreButton(false);
              }}
            >
              {t('Button.More')}
            </MoreButton>
          </ButtonWrapper>
        )}
      </Container>
    </>
  );
};

const Container = styled.div<{ scrollHeight: number; initialHeight: number; showMoreButton: boolean }>`
  max-height: ${({ showMoreButton, initialHeight }) => (showMoreButton ? `${initialHeight}px` : 'auto')};
  overflow: ${({ scrollHeight, initialHeight }) => (scrollHeight > initialHeight ? `hidden` : 'visible')};
  position: relative;
  transition: height 2s;
`;

const ButtonWrapper = styled.div`
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 50%);
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  position: absolute;
  width: 100%;
`;

const MoreButton = styled.button`
  height: 24px;
  border-radius: 16px;
  border: 1px solid #dee5ec;
  background-color: #fff;
  padding: 0 44px;
  color: #6e7c89;
  font-size: 12px;
  font-weight: 600;
  text-transform: lowercase;
  cursor: pointer;
`;

export default More;
