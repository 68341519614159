import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { formatIntNumber } from '@src/libs/format';
import { usePostDetailModal } from '@src/components/organisms/AnalyticsShared/BasePostList/usePostDetailModal';
import * as StyledComponents from '@src/components/organisms/AnalyticsShared/PostDetail/SliderStyledComponents';
import ImageSlider from '@src/components/molecules/Slider';
import CarouselModal from '@src/components/molecules/CarouselModal/CarouselModal';
import DataOpacityWrapper from '@src/components/molecules/DataCard/DataOpacityWrapper';
import InstargamTaggedPostDetail from '../TaggedPostDetail';
import { BasePostsType } from './types';

interface LikeCommentPostsProps {
  posts?: BasePostsType[] | null;
  header: ReactElement;
  moreLink: string;
  className?: string;
  socialMediaAccountId?: number;
}
const BasePosts = (props: LikeCommentPostsProps) => {
  const { t } = useTranslation();
  const { posts, header, moreLink, className, socialMediaAccountId } = props;

  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev,
  } = usePostDetailModal(posts || []);

  return (
    <>
      <StyledOpacityWrapper header={header} dataNotAvailable={!posts?.length}>
        <MainWrapper className={className}>
          {!!posts?.length && (
            <>
              <ImageSlider data={posts}>
                {posts.map(post => (
                  <StyledComponents.PostImageWrapper key={post.id}>
                    <StyledComponents.Sizer>
                      <StyledComponents.PostImage src={post.thumbNail} onClick={() => handleClickPost(post.id)} />
                      <StyledComponents.EngagementInfo>
                        <StyledComponents.InfoWrapper>
                          <StyledComponents.Label>{t('Likes')}</StyledComponents.Label>
                          <StyledComponents.Count>{formatIntNumber(post.likes)}</StyledComponents.Count>
                        </StyledComponents.InfoWrapper>
                        <StyledComponents.InfoWrapper>
                          <StyledComponents.Label>{t('General.Comments')}</StyledComponents.Label>
                          <StyledComponents.Count>{formatIntNumber(post.comments)}</StyledComponents.Count>
                        </StyledComponents.InfoWrapper>
                      </StyledComponents.EngagementInfo>
                    </StyledComponents.Sizer>
                  </StyledComponents.PostImageWrapper>
                ))}
              </ImageSlider>

              <StyledComponents.MoreText to={moreLink} />
            </>
          )}
        </MainWrapper>
      </StyledOpacityWrapper>
      <CarouselModal
        open={!!openPostDetail}
        title=""
        cancel="OK"
        onClose={handleClosePostDetail}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        siblingPostId={siblingPostId}
      >
        <InstargamTaggedPostDetail closeModal={handleClosePostDetail} socialMediaAccountId={socialMediaAccountId} />
      </CarouselModal>
    </>
  );
};

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
`;
const StyledOpacityWrapper = styled(DataOpacityWrapper)`
  min-height: 200px;
`;

export default BasePosts;
