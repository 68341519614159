import React, { useState, useContext } from 'react';
import { Redirect, useLocation, useHistory } from 'react-router';
import { Period } from '@src/components/molecules/DayPicker/types';
import { AdminStore } from '@src/Context';
import { getThirtyDaysAgo, getToday } from '@src/libs/date';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import DefaultProfile from './DefaultProfile';
import AnalyticsProfile from './AnalyticsProfile';
import { SocialMediaAccountType } from './types';
import StatsOverview from './StatsOverview/StatsOverview';
import NeedAnalyticsAccountReconnect from './NeedAnalyticsAccountReconnect';

interface ProfileDetailProps {
  availableSocialMediaAccounts: SocialMediaAccountType[];
}

const ProfileDetail = (props: ProfileDetailProps) => {
  const { availableSocialMediaAccounts } = props;

  const {
    state: { userId },
  } = useContext(AdminStore);
  const { search } = useLocation();
  const history = useHistory();
  const [lastUpdate, setLastUpdate] = useState<string>('');
  if (!userId) {
    return <Redirect to="/" />;
  }

  const searchParams = new URLSearchParams(search);
  const [id, sm, stda, enda] = [
    searchParams.get('id') as string,
    searchParams.get('sm') as string,
    searchParams.get('stda') as string,
    searchParams.get('enda') as string,
  ];
  const initialSocialMedia =
    (id &&
      sm &&
      availableSocialMediaAccounts.find(social => social.id.toString() === id && sm === social.socialAccountType)) ||
    availableSocialMediaAccounts[0];

  const [selectedSocialMedia, setSelectedSocialMedia] = useState<SocialMediaAccountType>(initialSocialMedia);
  const [selectedPeriod, setPeriod] = useState<Period>({
    startDate: stda || getThirtyDaysAgo(),
    endDate: enda || getToday(),
  });

  const handlePeriodChange = (period: Period) => {
    setPeriod(period);
  };

  const onChangeSocialMedia = (social: SocialMediaAccountType) => {
    setSelectedSocialMedia(social);
    history.push(`/profile?id=${social.id.toString()}&sm=${social.socialAccountType}`);
  };

  const enabledAnalytics = selectedSocialMedia.analyticsEnabled;
  const showAnalyticsWarning =
    enabledAnalytics && selectedSocialMedia.socialAccountType === SocialAccountType.INSTAGRAM;

  return (
    <>
      {showAnalyticsWarning && <NeedAnalyticsAccountReconnect selectedSocialMedia={selectedSocialMedia} />}
      <StatsOverview
        availableSocialMediaAccounts={availableSocialMediaAccounts}
        selectedSocialMedia={selectedSocialMedia}
        onChangeSocialMedia={v => onChangeSocialMedia(v)}
        withPeriod={!!enabledAnalytics}
        onPeriodChange={handlePeriodChange}
        initialPeriod={selectedPeriod}
        lastUpdate={lastUpdate}
      />
      {enabledAnalytics ? (
        <AnalyticsProfile selectedPeriod={selectedPeriod} userId={userId} selectedSocialMedia={selectedSocialMedia} />
      ) : (
        <DefaultProfile setLastUpdate={setLastUpdate} userId={userId} selectedSocialMedia={selectedSocialMedia} />
      )}
    </>
  );
};

export default ProfileDetail;
