import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from '@src/components/organisms/AnalyticsShared/AverageEngagementStyled';
import { useMediaQuery } from 'react-responsive';
import * as StyledOverview from '@src/components/organisms/AnalyticsShared/Overview/StyledComponents';
import { intlNumberFormat, bigIntFormatter } from '@src/libs/format';
import DataOpacityWrapper, { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import pictureIcon from '@src/assets/icon/picture.svg';
import heartIcon from '@src/assets/icon/heart.svg';
import bookmarkIcon from '@src/assets/icon/bookmark.svg';
import chatIcon from '@src/assets/icon/chat_green.svg';
import { ViewportBreakpoint } from '@src/libs/theme';
import { getGrowthPrefix, getGrowthArrow } from '@src/components/organisms/AnalyticsShared/utils';
import { EngagementAnalytics } from '../types';

export const PostBreakdown = (props: EngagementAnalytics) => {
  const {
    averagePostsPerWeek,
    averageLikes,
    averageComments,
    averageSaved,
    averagePostsGrowth,
    averageLikesGrowth,
    averageCommentsGrowth,
    averageSavedGrowth,
    className,
  } = props;
  const { t } = useTranslation();
  const isDirectionColumn = useMediaQuery({ maxWidth: ViewportBreakpoint.MOBILE });

  const data = [
    { icon: pictureIcon, title: 'Label.AvgPosts', value: averagePostsPerWeek, growth: averagePostsGrowth },
    { icon: heartIcon, title: 'Label.AvgLikes', value: averageLikes, growth: averageLikesGrowth },
    { icon: chatIcon, title: 'Label.AvgComments', value: averageComments, growth: averageCommentsGrowth },
    { icon: bookmarkIcon, title: 'Label.AvgSaves', value: averageSaved, growth: averageSavedGrowth },
  ];

  const dataNotAvailable = !averagePostsPerWeek && !averageLikes && !averageComments && !averageSaved;
  const { engagementStyles } = Styled;

  return (
    <DataOpacityWrapper
      header={<SectionHeader>{t('Average Engagement')}</SectionHeader>}
      dataNotAvailable={dataNotAvailable}
      css={engagementStyles.container}
      className={className}
    >
      <Styled.MainWrapper css={engagementStyles.gridContainer}>
        {data.map(item => {
          const avgGrowth = item.growth;
          const avgTotal = item.value;
          const total = bigIntFormatter(avgTotal, 0);
          const growth = bigIntFormatter(avgGrowth, 0);
          const { title, icon } = item;
          const hoverTotal = intlNumberFormat(avgTotal);
          const hoverGrowth = intlNumberFormat(avgGrowth);

          if (isDirectionColumn) {
            return (
              <StyledOverview.EngagementGridCell key={title}>
                <StyledOverview.PartWithIcon>
                  <StyledOverview.EngagementTitle text={t(title)} lines={1} />
                  <StyledOverview.EngagementTileIcon src={icon} css={engagementStyles.icon} />
                </StyledOverview.PartWithIcon>

                <StyledOverview.EngagementNumbers>
                  <StyledOverview.TotalNumber title={hoverTotal}>{total}</StyledOverview.TotalNumber>
                  {!!avgGrowth && (
                    <StyledOverview.Growth isPositive={Number(avgGrowth) > 0} title={hoverGrowth}>{`${getGrowthPrefix(
                      avgGrowth
                    )}${growth}  ${getGrowthArrow(avgGrowth)}`}</StyledOverview.Growth>
                  )}
                </StyledOverview.EngagementNumbers>
              </StyledOverview.EngagementGridCell>
            );
          }

          return (
            <Styled.EngagementDataRow key={title}>
              <Styled.EngagementHalf isDirectionColumn={isDirectionColumn}>
                <img src={icon} css={{ width: 32, height: 32 }} />
                <Styled.Label>{t(title)}</Styled.Label>
              </Styled.EngagementHalf>
              <Styled.EngagementHalf>
                <Styled.GrowthWrapper>
                  <Styled.Value title={hoverTotal}>{total}</Styled.Value>
                  {!!avgGrowth && (
                    <Styled.Identifier isPositive={Number(avgGrowth) > 0} title={hoverGrowth}>{`${getGrowthPrefix(
                      avgGrowth
                    )}${growth}  ${getGrowthArrow(avgGrowth)}`}</Styled.Identifier>
                  )}
                </Styled.GrowthWrapper>
              </Styled.EngagementHalf>
            </Styled.EngagementDataRow>
          );
        })}
      </Styled.MainWrapper>
    </DataOpacityWrapper>
  );
};

export default PostBreakdown;
