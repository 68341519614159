import React, { useEffect, useState } from 'react';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { ConnectType } from '@src/libs/auth';
import { useQueryHelper, useRedirectWithinIframe } from '@src/libs/hooks';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { useRedirectUrl } from '@src/libs/hooks/useRedirectUrl';
import BaseSignInForm from './SignInForm';

interface SignInFormProps {
  failedConnect?: boolean;
}

const SignInForm = ({ failedConnect }: SignInFormProps) => {
  const { getRedirectUrl, loading: mutationLoading } = useRedirectUrl(FE_REDIRECT_MAPPING.SIGNIN);
  const [loading, setLoading] = useState<boolean>(false);
  const [redirectUrlFacebook, setRedirectUrlFacebook] = useState<string | null>(null);
  const { enqueueSnackbar, t } = useQueryHelper();
  const { startRedirectProcess } = useRedirectWithinIframe();

  // Fetch redirectUri for Facebook beforehand, because of Facebook policy.
  // https://adasiaholdings.atlassian.net/browse/CAS-1143
  const getRedirectUrlFacebook = async () => {
    const redirectUrl = await getRedirectUrl(SocialAccountType.FACEBOOK);
    setRedirectUrlFacebook(redirectUrl);
  };
  useEffect(() => {
    getRedirectUrlFacebook();
  }, []);

  const onClick = (socialMedia: SocialAccountType) => async () => {
    setLoading(true);

    // Set connectType for instagram provider
    if (socialMedia === SocialAccountType.INSTAGRAM) {
      localStorage.setItem('connectType', ConnectType.CONNECT_IG_ACCOUNT);
      socialMedia = SocialAccountType.FACEBOOK;
    }
    // Set RedirectType for Twitter OAuth
    if (socialMedia === SocialAccountType.TWITTER) {
      localStorage.setItem('redirectType', FE_REDIRECT_MAPPING.SIGNIN);
    }
    try {
      const redirectUrl =
        socialMedia === SocialAccountType.FACEBOOK ? redirectUrlFacebook : await getRedirectUrl(socialMedia);
      if (redirectUrl) {
        // Redirect to SocialMedia
        startRedirectProcess({ redirectUrl, redirectType: FE_REDIRECT_MAPPING.SIGNIN });
      }
    } catch (error) {
      console.error('error:', error);
      enqueueSnackbar(t('UnexpectedError'), { variant: 'error' });
    } finally {
      setLoading(false);
    }

    return;
  };

  return <BaseSignInForm failedConnect={failedConnect} onClick={onClick} loading={loading || mutationLoading} />;
};

export default SignInForm;
