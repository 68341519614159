import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import VideoPreview from '@src/components/atoms/VideoPreview';
import CarouselModal from '@src/components/molecules/CarouselModal/CarouselModal';
import { formatDate, formatIntNumber, formatPercent } from '@src/libs/format';
import { isVideoThumbnail, defaultEmptyImage } from '@src/libs/image';
import { usePostDetailModal } from '@src/components/organisms/AnalyticsInstagram/Dashboard/PostCard/BasePostList/usePostDetailModal';
import AnayticsFeedPostDetail from '@src/components/organisms/AnalyticsInstagram/Dashboard/PostCard/FeedPost/AnalyticsFeedPostDetail';
import { Post } from '../model';

interface PostFeedList {
  posts: Post[];
  accountId: number;
  totalCount: number;
}

const PostFeedList = (props: PostFeedList) => {
  const { t } = useTranslation();
  const { posts, accountId, totalCount } = props;

  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev,
  } = usePostDetailModal(posts);

  return (
    <>
      <PostCount>{`${totalCount} ${t('Posts Found')}`}</PostCount>
      {posts.map(post => (
        <PostWrapper key={post.id}>
          <PostHeader onClick={() => handleClickPost(post.id)}>
            {isVideoThumbnail(post.thumbNail) ? (
              <VideoPreview url={post.thumbNail} width="80" height="80" />
            ) : (
              <PostImage src={defaultEmptyImage(post.thumbNail)} />
            )}
            <PostContent>{post.content}</PostContent>
          </PostHeader>
          <PostInfo>
            <Info>
              <Unit>{t('Post Date')}</Unit>
              <Value>{formatDate(post.postDate)}</Value>
            </Info>
            <Info>
              <Unit>{t('Engagement')}</Unit>
              <Value>{formatIntNumber(post.engagement)}</Value>
            </Info>
            <Info>
              <Unit>{t('Engagement Rate')}</Unit>
              <Value>{formatPercent(post.engagementRate)}</Value>
            </Info>
            <Info>
              <Unit>{t('Like')}</Unit>
              <Value>{formatIntNumber(post.likes)}</Value>
            </Info>
            <Info>
              <Unit>{t('Comment')}</Unit>
              <Value>{formatIntNumber(post.comments)}</Value>
            </Info>
            <Info>
              <Unit>{t('Save')}</Unit>
              <Value>{formatIntNumber(post.saved)}</Value>
            </Info>
            <Info>
              <Unit>{t('Reach')}</Unit>
              <Value>{formatIntNumber(post.reach)}</Value>
            </Info>
            <Info>
              <Unit>{t('Imps')}</Unit>
              <Value>{formatIntNumber(post.impressions)}</Value>
            </Info>
            <Info>
              <Unit>{t('Positive Rate')}</Unit>
              <Value>{formatPercent(post.positiveRate)}</Value>
            </Info>
            <Info>
              <Unit>{t('Negative Rate')}</Unit>
              <Value>{formatPercent(post.negativeRate)}</Value>
            </Info>
          </PostInfo>
        </PostWrapper>
      ))}
      <CarouselModal
        open={!!openPostDetail}
        title=""
        cancel="OK"
        onClose={handleClosePostDetail}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        siblingPostId={siblingPostId}
      >
        <AnayticsFeedPostDetail
          postId={Number(openPostDetail)}
          accountId={accountId}
          closeModal={handleClosePostDetail}
        />
      </CarouselModal>
    </>
  );
};

const PostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;
  border-top: solid 1px #e6ecf0;
`;

const PostHeader = styled.div`
  width: 100%;
  height: 80px;
  display: inline-flex;
  cursor: pointer;
`;

const PostImage = styled.img`
  width: 80px;
  height: 100%;
`;

const PostContent = styled.div`
  flex: 1;
  margin-left: 12px;
  font-size: 13px;
  color: #3892e5;
  font-weight: 600;
  display: flex;
  word-break: break-all;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
  }
`;

const PostInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25%);
  margin-top: 16px;
`;

const Info = styled.div`
  padding: 4px 0;
`;

const Value = styled.p`
  font-size: 13px;
  color: #27313b;
`;
const Unit = styled.p`
  font-size: 10px;
  line-height: 1.3;
  letter-spacing: normal;
  color: #6e7c89;
`;

const PostCount = styled.div`
  padding: 8px 16px;
  border-top: solid 1px #e6ecf0;
`;

export default PostFeedList;
