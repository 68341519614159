import styled from '@emotion/styled';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { SerializedStyles } from '@emotion/core';
import emptyImage from '../../../assets/img/emptyImage.jpg';
import emptyVideo from '../../../assets/img/videoThumbnail.png';
import { getFileTypeFromUrl, getDecodedUrlFileName } from '../../../libs/requests';
import { UploadedFile } from './types';

export interface PreviewProps {
  previewWidth?: string;
  disabled?: boolean;
  uploadProgress: { progress: number; progressUrl: string | null };
  uploadedFiles: UploadedFile[];
  deleteUploadedFile: (index: number) => () => void;
  previewCss?: SerializedStyles;
}

const useStyles = makeStyles({
  colorPrimary: {
    backgroundColor: '#fff',
  },
  barColorPrimary: {
    backgroundColor: '#179cd7',
  },
});

const Preview = (props: PreviewProps) => {
  const { uploadedFiles, deleteUploadedFile, previewWidth, disabled, uploadProgress, previewCss } = props;

  const classes = useStyles();

  const { colorPrimary, barColorPrimary } = classes;

  return (
    <Container previewWidth={previewWidth} css={previewCss}>
      {uploadedFiles.map(({ url, preview }, index) => {
        const fileType = getFileTypeFromUrl(url);
        const isImage = ['jpeg', 'jpg', 'png', 'gif'].includes(fileType);
        // when we create preview from Blob fileType=[avi, mov, mp4], when we upload file fileType is normal MIME
        const isVideo = ['video/avi', 'avi', 'video/mp4', 'mp4', 'video/quicktime', 'mov'].includes(fileType);
        const isEmptyImage = !(isImage || isVideo);
        const imagePreview = preview && isImage ? preview : emptyImage;
        const hasProgressBar = url === uploadProgress.progressUrl && uploadProgress?.progress !== 100;

        return (
          <Wrapper previewWidth={previewWidth} key={index}>
            {isImage && (
              <ThumbnailWrapper>
                <Thumbnail src={imagePreview} />
              </ThumbnailWrapper>
            )}
            {isVideo && <StyledImage src={emptyVideo} />}
            {isEmptyImage && (
              <a download={url} href={url}>
                <StyledImage src={imagePreview} />
              </a>
            )}
            {hasProgressBar ? (
              <LinearProgress
                variant="determinate"
                value={uploadProgress.progress}
                color="primary"
                classes={{ colorPrimary, barColorPrimary }}
              />
            ) : (
              <TransparentBar />
            )}
            {!disabled && (
              <Icon className="material-icons" onClick={deleteUploadedFile(index)}>
                close
              </Icon>
            )}
            <Title title={getDecodedUrlFileName(preview)}>{getDecodedUrlFileName(preview)}</Title>
          </Wrapper>
        );
      })}
    </Container>
  );
};

const Container = styled.div<{ previewWidth?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;

  &::after {
    content: '';
    display: block;
    width: ${props => props.previewWidth || '31.5%'};
  }
`;

const Wrapper = styled.div<{ previewWidth?: string }>`
  overflow: hidden;
  width: ${props => props.previewWidth || '31.5%'};
  margin-top: 12px;
  position: relative;
`;

const ThumbnailWrapper = styled.div`
  position: relative;
  background-color: #27313b;

  &::before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
`;

const Title = styled.p`
  margin-top: 2px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Icon = styled.i`
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  font-size: 20px;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.7);
  cursor: pointer;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #ddd;
  }
`;

const StyledImage = styled.img`
  width: 100%;
`;
const TransparentBar = styled.div`
  height: 4px;
`;

export default Preview;
