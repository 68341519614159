import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SocialAccountType } from '../../../__generated__/globalTypes';
import { formatNumber } from '../../../libs/format';
import { switchSocialConnectIcon, switchSocialConnectColor, switchImage } from '../../../libs/SocialMedia';
import { defaultInfluencerAvatar } from '../../../libs/image';

export enum SocialIconType {
  CIRCULAR,
  DEFAULT,
}
interface SocialAccountProps extends IconWrapperProps {
  className?: string;
  src: string | null;
  iconType?: SocialIconType;
  username: string | null;
  followers: number | null;
  accountLink?: string | null;
  rightElement?: React.ReactNode;
}

const SocialAccount = (props: SocialAccountProps) => {
  const {
    className,
    socialMedia,
    src,
    username,
    followers,
    accountLink,
    rightElement,
    iconType = SocialIconType.CIRCULAR,
  } = props;
  const bgcolor = switchSocialConnectColor(socialMedia);

  return (
    <Container className={className} href={accountLink || undefined} rel="noopener" target="_blank">
      {iconType === SocialIconType.CIRCULAR ? (
        <IconWrapper socialMedia={socialMedia} bgcolor={bgcolor}>
          <Icon src={switchSocialConnectIcon(socialMedia)} />
        </IconWrapper>
      ) : (
        <Icon src={switchImage(socialMedia)} />
      )}
      <Thumbnail
        marginLeft={iconType === SocialIconType.CIRCULAR ? '-8px' : '8px'}
        src={defaultInfluencerAvatar(src)}
      />

      <TextWrapper>
        <SocialAccountWithAvatar username={username} followers={followers} />
      </TextWrapper>
      {rightElement}
    </Container>
  );
};

export const SocialAccountWithAvatar = ({
  username,
  followers,
  className,
}: {
  username: string | null;
  followers: number | null;
  className?: string;
}) => {
  const { t } = useTranslation();

  return (
    <InfoWrapper className={className}>
      <UserName>{username || ''}</UserName>
      <Follower>
        {formatNumber(followers)} {t('Followers')}
      </Follower>
    </InfoWrapper>
  );
};

const InfoWrapper = styled.div`
  flex: 1;
`;

const Container = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
`;

interface IconWrapperProps {
  socialMedia: SocialAccountType;
  bgcolor?: string;
}

const IconWrapper = styled.div<IconWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  background-color: ${props => props.bgcolor};
  background-image: ${props =>
    props.socialMedia === SocialAccountType.INSTAGRAM ? 'linear-gradient(72deg, #fccc63, #d92f7e)' : 'none'};
  border-radius: 50%;
`;

const Icon = styled.img`
  width: 22px;
`;

interface ThumbnailProps {
  marginLeft: string;
}
const Thumbnail = styled.img<ThumbnailProps>`
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  margin-left: ${props => props.marginLeft};
  border: 3px solid #fff;
  border-radius: 50%;
`;

const TextWrapper = styled.div`
  flex: 1;
  margin-left: 8px;
`;

const UserName = styled.h3`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #27313b;
`;

const Follower = styled.p`
  display: flex;
  align-items: center;
  margin-top: 5px;
  font-size: 12px;
  line-height: 1.17;
  letter-spacing: normal;
  color: #6e7c89;
`;

export default SocialAccount;
