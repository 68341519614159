import React from 'react';
import { useTranslation } from 'react-i18next';
import CarouselModal from '@src/components/molecules/CarouselModal/CarouselModal';
import { formatIntNumber } from '@src/libs/format';
import { isVideoThumbnail } from '@src/libs/image';
import { usePostDetailModal } from '@src/components/organisms/AnalyticsInstagram/Dashboard/PostCard/BasePostList/usePostDetailModal';
import AnalyticsStoryPostDetail from '@src/components/organisms/AnalyticsInstagram/Dashboard/PostCard/StoryPost/AnalyticsStoryPostDetail';
import VideoPreview from '@src/components/atoms/VideoPreview';
import { SortActionType } from '@src/libs/filter';
import { InstagramStoryPostSortField, ORDER } from '@src/__generated__/globalTypes';
import { useMediaQuery } from 'react-responsive';
import { ViewportBreakpoint } from '@src/libs/theme';
import { SliderComponents, StyledComponents, SliderCss } from '@src/components/molecules/SliderTable';
import { DateColumn, HeaderColumn } from '@src/components/atoms/List';
import PostColumn from '@src/components/organisms/AnalyticsShared/PostColumn';
import * as StoryStyled from '@src/pages/InstagramStoryOrFeedPosts/Styled';
import { Post } from '../model';
import { instagramStoryPostListColumns } from './helpers';

interface PostFeedList {
  posts: Post[];
  accountId: number;
  totalCount: number;
  sortData: SortActionType<InstagramStoryPostSortField>;
}

const PostFeedList = ({ posts, accountId, totalCount, sortData: { setSort, sort } }: PostFeedList) => {
  const { t } = useTranslation();
  const showColumnView = useMediaQuery({ maxWidth: ViewportBreakpoint.MEDIUM });
  const { styles } = StoryStyled;

  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev,
  } = usePostDetailModal(posts);

  return (
    <>
      <StoryStyled.PostCount css={!showColumnView ? styles.postsCount : ''}>{`${totalCount} ${t(
        'Label.Posts found'
      )}`}</StoryStyled.PostCount>
      {showColumnView ? (
        <>
          {posts.map(post => (
            <StoryStyled.PostWrapper key={`${post.id}_${post.postDate}`}>
              <StoryStyled.PostHeader onClick={() => handleClickPost(post.id)}>
                {isVideoThumbnail(post.thumbNail) ? (
                  <VideoPreview url={post.thumbNail} width="92px" height="52px" css={{ backgroundColor: '#dee5ec' }} />
                ) : (
                  <StoryStyled.PostImage src={post.thumbNail} />
                )}
                <StoryStyled.PostContent text={post.content || '-'} lines={3} />
              </StoryStyled.PostHeader>
              <StoryStyled.PostInfo>
                {instagramStoryPostListColumns.map(col => (
                  <StoryStyled.Info key={col.orderField}>
                    <StoryStyled.Unit>{t(col.title)}</StoryStyled.Unit>
                    <StoryStyled.Value>{col.formatter(post[col.key])}</StoryStyled.Value>
                  </StoryStyled.Info>
                ))}
              </StoryStyled.PostInfo>
            </StoryStyled.PostWrapper>
          ))}
        </>
      ) : (
        <SliderComponents.Container>
          <SliderComponents.SliderSection>
            <SliderComponents.SliderTable>
              <thead>
                <SliderComponents.HeaderRow css={styles.headerRow}>
                  <SliderComponents.StickyHeaderTdWrapper>
                    <HeaderColumn title="Posts" css={styles.postsHeader} isTable={false} />
                  </SliderComponents.StickyHeaderTdWrapper>
                  {instagramStoryPostListColumns.map(({ title, orderField }) => (
                    <HeaderColumn
                      key={title}
                      title={title}
                      {...(orderField && {
                        activeAsc: sort.field === orderField && sort.order === ORDER.ASC,
                        activeDesc: sort.field === orderField && sort.order === ORDER.DESC,
                        sortAsc: () => setSort({ field: orderField, order: ORDER.ASC }),
                        sortDesc: () => setSort({ field: orderField, order: ORDER.DESC }),
                      })}
                      css={styles.sliderTableColsHeaders}
                    />
                  ))}
                </SliderComponents.HeaderRow>
              </thead>

              <tbody>
                {posts?.map(post => {
                  const {
                    id,
                    postDate,
                    comments,
                    reach,
                    impressions,
                    exits,
                    tapsBack,
                    tapsForward,
                    thumbNail,
                    content,
                  } = post;

                  return (
                    <StyledComponents.StyledRowNew css={[SliderCss.paddingSides]} key={`${id}_${postDate}`}>
                      <SliderComponents.StickyBodyRowWrapper css={styles.postsColumn}>
                        <PostColumn
                          key={id}
                          id={id}
                          src={thumbNail}
                          title={content}
                          onClick={() => handleClickPost(id)}
                        />
                      </SliderComponents.StickyBodyRowWrapper>
                      <DateColumn date={postDate} />
                      <StyledComponents.NumberColumn data={formatIntNumber(comments)} />
                      <StyledComponents.NumberColumn data={formatIntNumber(reach)} />
                      <StyledComponents.NumberColumn data={formatIntNumber(impressions)} />
                      <StyledComponents.NumberColumn data={formatIntNumber(exits)} />
                      <StyledComponents.NumberColumn data={formatIntNumber(tapsBack)} />
                      <StyledComponents.NumberColumn data={formatIntNumber(tapsForward)} />
                    </StyledComponents.StyledRowNew>
                  );
                })}
              </tbody>
            </SliderComponents.SliderTable>
          </SliderComponents.SliderSection>
        </SliderComponents.Container>
      )}
      <CarouselModal
        open={!!openPostDetail}
        title=""
        cancel="OK"
        onClose={handleClosePostDetail}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        siblingPostId={siblingPostId}
      >
        <AnalyticsStoryPostDetail storyId={Number(openPostDetail)} accountId={accountId} />
      </CarouselModal>
    </>
  );
};

export default PostFeedList;
