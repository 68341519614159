import React from 'react';
import SponsoredPosts from '@src/components/organisms/ProfileWidgets/SponsoredPosts';
import { sectionStyles } from '@src/components/organisms/AnalyticsShared/AnalyticsContentWrapper';
import { ProfilePostsSection } from '../TwitterProfilePosts/ProfilePosts';
import FollowersGrowthAndPosts from '../FollowersGrowthAndPosts';
import { ProfileV2TemplateProps } from './types';

const TwitterTemplate = (props: ProfileV2TemplateProps) => {
  const { selectedSocialMedia, influencerId } = props;

  return (
    <>
      <FollowersGrowthAndPosts
        socialMediaAccountId={selectedSocialMedia.id}
        influencerAccountId={influencerId}
        socialMedia={selectedSocialMedia.socialAccountType}
      />

      <ProfilePostsSection
        socialMediaAccountId={selectedSocialMedia.id}
        influencerAccountId={influencerId}
        socialMedia={selectedSocialMedia.socialAccountType}
      />

      <SponsoredPosts
        socialAccountId={selectedSocialMedia.id}
        influencerId={influencerId}
        socialMedia={selectedSocialMedia.socialAccountType}
        css={sectionStyles.sectionContainer}
      />
    </>
  );
};

export default TwitterTemplate;
