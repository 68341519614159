import styled from '@emotion/styled';
import * as React from 'react';
import ProfileForm, { ProfileFormProps } from '../../components/organisms/Profile/ProfileForm';
import { useUpdateDocumentTitle } from '../../libs/hooks';
import NavigationHeader from '../../components/organisms/Header/NavigationHeader';

export type TemplateProps = ProfileFormProps;

const Template = (props: TemplateProps) => {
  const title = 'pageTitle.Edit Profile';
  const pageTitle = 'pageTitle.Edit Profile';
  const href = '/profile/settings';
  useUpdateDocumentTitle({ title, pageTitle, href });
  const { userId, ...rest } = props;

  return (
    <>
      <NavigationHeader />
      <Wrapper>
        <StyledProfileForm userId={userId} {...rest} />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
  margin-top: 48px;
`;

const StyledProfileForm = styled(ProfileForm)`
  flex: 1;
`;

export default Template;
