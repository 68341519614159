import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { BasePostFilterType } from '@src/pages/InstagramStoryOrFeedPosts/types';
import PostFeedFilters from './PostFeedFilters';
import { FormValues } from './types';

export interface PostFeedFilterProps {
  filter: BasePostFilterType;
  setFilter: (filter: BasePostFilterType) => void;
}
const PostFeedFilterForm = (props: PostFeedFilterProps) => {
  const {
    filter: { keyword, period },
    setFilter,
  } = props;

  const handleSubmit = (payload: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const { keyword: keywordPayload, period: periodPayload } = payload;

    setFilter({ keyword: keywordPayload, period: periodPayload });
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        period,
        keyword,
      }}
      onSubmit={handleSubmit}
    >
      <PostFeedFilters />
    </Formik>
  );
};

export default PostFeedFilterForm;
