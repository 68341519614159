import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getCampaignStatusColor, getCampaignStatusText } from '../../../../libs/campaign';
import Label from '../Label/Label';
import { CampaignStatusForInfluencer } from '../../../../__generated__/globalTypes';
import { ViewportBreakpoint } from '../../../../libs/theme';

interface TemplateProps {
  status: CampaignStatusForInfluencer;
}

const CampaignStatus = ({ status }: TemplateProps) => {
  const { t } = useTranslation();
  const bgColor = getCampaignStatusColor(status);

  const statusText = getCampaignStatusText(status);

  return (
    <Wrapper>
      <Label title="Campaign Status" />
      <Container>
        <StatusBlock bgColor={bgColor}>{t(statusText)}</StatusBlock>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-right: 16px;

  @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
    width: 35%;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const StatusBlock = styled.span<{ bgColor: string }>`
  background-color: ${props => props.bgColor};
  min-width: 80px;
  width: max-content;
  height: 24px;
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  line-height: 22px;
  font-size: 12px;
  font-weight: 600;
`;

export default CampaignStatus;
