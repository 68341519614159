import React from 'react';
import { RouteComponentProps } from 'react-router';
import NavigationHeader from '../../components/organisms/Header/NavigationHeader';
import Template from './template';

// Extract Engagement's ID
export interface Params {
  id: string;
}
const AddEngagementPost = (props: RouteComponentProps<Params>) => {
  // Extract Engagement's ID from URL parameters
  const {
    match: {
      params: { id },
    },
    history,
  } = props;

  if (!id) {
    history.push('/engagement');
  }

  return (
    <>
      <NavigationHeader />
      <Template id={Number(id)} />
    </>
  );
};

export default AddEngagementPost;
