import * as React from 'react';
import PostForm from '../../components/organisms/EngagementPost/EngagementPostForm';
import { useUpdateDocumentTitle } from '../../libs/hooks';

export interface TemplateProps {
  id: number;
}

const Template = ({ id }: TemplateProps) => {
  const title = `pageTitle.Upload Draft Post`;
  const pageTitle = 'Upload Draft Post';
  const href = `/job/engagement/${id}/post`;
  useUpdateDocumentTitle({ title, pageTitle, href });

  return <PostForm id={id} />;
};

export default Template;
