import { useMediaQuery } from 'react-responsive';
import { SignUpAndSignInBreakpoint, ViewportType } from '../theme';

export default () => {
  const isMobileView = useMediaQuery({ maxWidth: ViewportType.TABLET });
  const isMobileViewPublicRoutes = useMediaQuery({ maxWidth: SignUpAndSignInBreakpoint.MEDIUM });

  return {
    isMobileView,
    isMobileViewPublicRoutes,
  };
};
