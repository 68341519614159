import { YoutubeAnalyticsTagRankingSortOrder } from '@src/__generated__/globalTypes';
import { InfluencerYoutubeAnalyticsTagsTopRanking_tagsTopRanking_tags } from './__generated__/InfluencerYoutubeAnalyticsTagsTopRanking';

export const getYtTagRankingColumns = (): {
  title: string;
  orderField: YoutubeAnalyticsTagRankingSortOrder;
  tagkey: keyof InfluencerYoutubeAnalyticsTagsTopRanking_tagsTopRanking_tags;
}[] => [
  {
    title: 'Post',
    orderField: YoutubeAnalyticsTagRankingSortOrder.POST,
    tagkey: 'posts',
  },
  {
    title: 'views',
    orderField: YoutubeAnalyticsTagRankingSortOrder.VIEWS,
    tagkey: 'views',
  },
  {
    title: 'Ave like',
    orderField: YoutubeAnalyticsTagRankingSortOrder.AVERAGE_LIKE,
    tagkey: 'averageLike',
  },
  {
    title: 'Ave dislike',
    orderField: YoutubeAnalyticsTagRankingSortOrder.AVERAGE_DISLIKE,
    tagkey: 'averageDislike',
  },
  {
    title: 'Ave Comment',
    orderField: YoutubeAnalyticsTagRankingSortOrder.AVERAGE_COMMENT,
    tagkey: 'averageComment',
  },
  {
    title: 'Ave Eng',
    orderField: YoutubeAnalyticsTagRankingSortOrder.AVERAGE_ENGAGEMENT,
    tagkey: 'averageEngagement',
  },
  {
    title: 'Ave share',
    orderField: YoutubeAnalyticsTagRankingSortOrder.AVERAGE_SHARE,
    tagkey: 'averageShare',
  },
  {
    title: 'Eng rate',
    orderField: YoutubeAnalyticsTagRankingSortOrder.ENGAGEMENT_RATE,
    tagkey: 'engagementRate',
  },
];

export const getTopRankingSortFiledType = (field?: string | null) => {
  if (!field) {
    return null;
  }

  return YoutubeAnalyticsTagRankingSortOrder[field as keyof typeof YoutubeAnalyticsTagRankingSortOrder];
};
