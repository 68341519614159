import * as React from 'react';
import { SeriesOptionsType } from 'highcharts';
import HighChart, { Highcharts } from '@src/components/molecules/HighChart';
import DataOpacityWrapper, { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import { formatPercent } from '@src/libs/format';
import { useQueryHelper } from '@src/libs/hooks';
import { FollowerAgeRate } from './types';

const AgeBreakdown = (props: FollowerAgeRate) => {
  const { ageGroup, maleRate, femaleRate, otherRate, className } = props;
  const { t } = useQueryHelper();
  const isOtherRateAvailable = !!(otherRate && otherRate.find(rate => rate > 0));

  const options: Highcharts.Options = {
    chart: {
      height: 273,
      marginBottom: 70,
    },
    title: {
      text: '',
    },
    legend: {
      enabled: true,
      symbolRadius: 2,
      itemStyle: {
        fontSize: '13px',
        lineHeight: '1.38',
      },
    },
    xAxis: {
      categories: ageGroup?.map(item => `${item.start}-${item.end}`) || [],
      plotLines: [
        {
          color: '#dee5ec',
          width: 1,
          value: 6.5,
        },
      ],
    },
    plotOptions: {
      series: {
        stacking: 'normal',
      },
    },
    // @ts-ignore
    yAxis: {
      title: '',
      labels: {
        formatter: function formatter(): string {
          return formatPercent(this.value, true, 0);
        },
      },
    },
    series: [
      {
        type: 'bar',
        name: t('Male'),
        color: '#707fc0',
        data: maleRate || [],
      },
      {
        type: 'bar',
        name: t('Female'),
        color: '#ff8097',
        data: femaleRate || [],
      },
      ...(isOtherRateAvailable
        ? [
            {
              type: 'bar',
              name: t('Other'),
              color: '#ffca98',
              data: otherRate || [],
            } as SeriesOptionsType,
          ]
        : []),
    ],
    tooltip: {
      padding: 0,
      formatter: function formatter(): string {
        return `
          <div style="display:flex;flex-direction:column;border-radius:3px;border: 2px solid #27313b;margin:-2px;background-color:#27313b;font-size:12px;padding:13px 16px;">
            <div style="margin-bottom:8px;">${this.x}</div>
            <div style="display:flex;align-items:center">
              <span style="display:inline-block;margin-right:8px;background-color:${
                this.point.color
              };height:12px;width:12px"></span>
              <span style="margin-right:16px">${this.series.name}:
              </span><span>${formatPercent(this.point.y || null)}</span>
            </div>
          </div>`;
      },
      pointFormat: '',
      borderColor: '#27313b',
      borderWidth: 0,
      backgroundColor: '#27313b',
      style: {
        color: '#ffffff',
      },
      useHTML: true,
      headerFormat: '',
    },
  };

  return (
    <DataOpacityWrapper
      header={<SectionHeader>{t('Title.Age Breakdown')}</SectionHeader>}
      dataNotAvailable={!femaleRate?.length && !maleRate?.length && !otherRate?.length}
      isAvailableRate={Number(maleRate) > 0 || Number(femaleRate) > 0}
      className={className}
    >
      <HighChart options={options} />
    </DataOpacityWrapper>
  );
};

export default AgeBreakdown;
