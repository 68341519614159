import React from 'react';
import { SocialAccountType } from '../../../../../__generated__/globalTypes';
import { formatIntNumber } from '../../../../../libs/format';
import Engagement from './Engagement';

interface SocialEngagementsProps {
  likes: number;
  comments: number;
  shares: number | null;
  views: number | null;
  socialMedia?: SocialAccountType;
}

const SocialEngagements = (props: SocialEngagementsProps) => {
  const { likes, comments, shares, views, socialMedia } = props;

  return (
    <>
      <Engagement value={formatIntNumber(likes)} unit="Likes" />
      <Engagement
        value={formatIntNumber(comments)}
        unit={socialMedia === SocialAccountType.TWITTER ? 'Replies' : 'Comments'}
      />
      {shares !== null && (
        <Engagement
          value={formatIntNumber(shares)}
          unit={socialMedia === SocialAccountType.TWITTER ? 'Retweets' : 'Shares'}
        />
      )}
      {views !== null && <Engagement value={formatIntNumber(views)} unit="Views" />}
    </>
  );
};

export default SocialEngagements;
