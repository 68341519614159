import React from 'react';
import { useQuery } from '@apollo/client';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { useQueryHelper } from '@src/libs/hooks';
import { ListIndicator } from '@src/components/molecules/Indicator';
import {
  InfluencerProfileAudience,
  InfluencerProfileAudienceVariables,
} from './__generated__/InfluencerProfileAudience';
import * as INFLUENCER_PROFILE_AUDIENCE from './InfluencerProfileAudience.graphql';
import AudienceFollowersTemplate from './template';

interface AudienceFollowersProps {
  socialMediaAccountId: number;
  influencerAccountId: number;
  socialMedia: SocialAccountType;
}
const AudienceFollowers = ({ influencerAccountId, socialMediaAccountId, socialMedia }: AudienceFollowersProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data, loading } = useQuery<InfluencerProfileAudience, InfluencerProfileAudienceVariables>(
    INFLUENCER_PROFILE_AUDIENCE,
    {
      variables: {
        pk: influencerAccountId,
        socialAccountId: socialMediaAccountId,
        socialMedia,
      },
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
    }
  );

  if (loading) {
    return <ListIndicator css={{ margin: 0, height: '400px' }} />;
  }

  return (
    <AudienceFollowersTemplate
      followerAgeRate={data?.influencerProfileAudience?.ageGenderRates}
      followerGenderRate={{
        femaleRate: data?.influencerProfileAudience?.femaleRate,
        maleRate: data?.influencerProfileAudience?.maleRate,
      }}
    />
  );
};

export default AudienceFollowers;
