import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useField, useFormikContext } from 'formik';
import TextForm from '@src/components/molecules/TextForm';
import { Button, SubmitButton } from '@src/components/atoms/Button';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import { css } from '@emotion/core';
import YearMonthDayPicker from '@src/components/molecules/YearMonthDayPicker';
import useFanCountries from '@src/pages/Fans/useFanCountries';
import SingleSelectField from '@src/components/molecules/SingleSelectField';
import SentenceForm from '@src/components/molecules/SentenceForm';
import { getOptions } from '@src/libs/form';
import { FanGender } from '@src/__generated__/globalTypes';
import { usePageLayout } from '@src/libs/hooks';
import useProvinceOptions from './useProvinceOptions';

export interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  birthday: string;
  gender: string;
  countryId: string;
  province: string;
  city: string;
  postalCode: string;
  address1: string;
  address2: string;
  tags: string[];
  tagEntry: string;
}

interface FanDetaisFormProps {
  editable: boolean;
}

const FanDetailsForm = ({ editable }: FanDetaisFormProps) => {
  const { t } = useTranslation();
  const { fanCountries } = useFanCountries();
  const { isMobileView } = usePageLayout();

  const [firstNameField, firstNameMeta] = useField<FormValues['firstName']>('firstName');
  const [lastNameField, lastNameMeta] = useField<FormValues['lastName']>('lastName');
  const [emailField, emailMeta] = useField<FormValues['email']>('email');
  const [contactNumberField, contactNumberMeta] = useField<FormValues['contactNumber']>('contactNumber');
  const [birthdayField, birthdayMeta] = useField<FormValues['birthday']>('birthday');
  const [genderField, genderMeta] = useField<FormValues['gender']>('gender');
  const [countryIdField, countryIdMeta] = useField<FormValues['countryId']>('countryId');
  const [provinceField, provinceMeta] = useField<FormValues['province']>('province');
  const [cityField, cityMeta] = useField<FormValues['city']>('city');
  const [postalCodeField, postalCodeMeta] = useField<FormValues['postalCode']>('postalCode');
  const [address1Field, address1Meta] = useField<FormValues['address1']>('address1');
  const [address2Field, address2Meta] = useField<FormValues['address2']>('address2');
  const [tagsField] = useField<FormValues['tags']>('tags');
  const [tagEntryField, tagEntryMeta] = useField<FormValues['tagEntry']>('tagEntry');

  const { isSubmitting, handleSubmit, setFieldValue } = useFormikContext<FormValues>();

  const { provinceOptions, refetch } = useProvinceOptions({ countryId: countryIdField.value });

  const countryOptions = useMemo(() => getOptions(fanCountries), [fanCountries.length]);

  const genderOptions = [
    { value: FanGender.FEMALE, label: 'Female' },
    { value: FanGender.MALE, label: 'Male' },
    { value: FanGender.OTHER, label: 'Other' },
  ];

  useEffect(() => {
    refetch({
      input: {
        countryId: countryIdField.value,
      },
    });
  }, [countryIdField.value]);

  const handleChangeCountry = (value: string) => {
    setFieldValue('countryId', value);
    // Reset province after changing country
    if (countryIdField.value !== value) {
      setFieldValue('province', '');
    }
  };

  const handleClickAddTag = () => {
    setFieldValue('tags', [...tagsField.value, ...tagEntryField.value.split(',').filter(item => !!item)]);

    setFieldValue('tagEntry', '');
  };

  const handleClickDelete = (tag: string) => {
    if (editable) {
      setFieldValue(
        'tags',
        tagsField.value.filter(item => item !== tag)
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div css={styles.container}>
        <FieldRow>
          <Label>{t('Name')}:</Label>
          <Col css={styles.marginRight16}>
            <TextForm
              css={styles.textForm}
              isRequired={true}
              error={!!firstNameMeta.error}
              placeholder=""
              disabled={!editable}
              {...firstNameField}
            />
            {firstNameMeta.error && <ErrorMessage message={t(firstNameMeta.error)} />}
          </Col>
          <Col>
            <TextForm
              css={styles.textForm}
              isRequired={true}
              error={!!lastNameMeta.error}
              placeholder=""
              disabled={!editable}
              {...lastNameField}
            />
            {lastNameMeta.error && <ErrorMessage message={t(lastNameMeta.error)} />}
          </Col>
        </FieldRow>
        <FieldRow>
          <Label>{t('Email')}:</Label>
          <Col>
            <TextForm
              css={styles.textForm}
              error={!!emailMeta.error}
              placeholder="anychat@anymindgroup.com"
              disabled={!editable}
              {...emailField}
            />
            {emailMeta.error && <ErrorMessage message={t(emailMeta.error)} />}
          </Col>
        </FieldRow>
        <FieldRow>
          <Label>{t('Phone Number')}:</Label>
          <TextForm
            css={styles.textForm}
            error={!!contactNumberMeta.error}
            placeholder="0123456789"
            disabled={!editable}
            {...contactNumberField}
          />
        </FieldRow>
        <FieldRow>
          <Label>{t('Birth day')}:</Label>
          <YearMonthDayPicker
            format="yyyy-MM-dd"
            error={!!birthdayMeta.error}
            maxDate={new Date()}
            value={birthdayField.value || ''}
            onChange={value => setFieldValue('birthday', value)}
            css={styles.pickerStyle}
          />
        </FieldRow>
        <FieldRow>
          <Label>{t('Selector.Gender')}:</Label>
          <SingleSelectField
            placeholder="Select Gender"
            error={!!genderMeta.error}
            name="gender"
            options={genderOptions}
            value={genderField.value || ''}
            setFieldValue={(_attr, value) => setFieldValue('gender', value)}
            css={styles.selector}
          />
        </FieldRow>
        <FieldRow>
          <Label>{t('Selector.Country')}:</Label>
          <SingleSelectField
            placeholder="Select Country"
            error={!!countryIdMeta.error}
            name="country"
            options={countryOptions}
            value={countryIdField.value || ''}
            setFieldValue={(_, value) => handleChangeCountry(value)}
            css={styles.selector}
            disabled={!editable}
          />
        </FieldRow>
        <FieldRow>
          <Label>{t('Selector.Province')}:</Label>
          {/* Show TextFrom from for Shopify tagged fan */}
          {editable ? (
            <SingleSelectField
              placeholder="Select Province"
              error={!!provinceMeta.error}
              name="province"
              options={provinceOptions}
              value={provinceField.value || ''}
              setFieldValue={(_attr, value) => setFieldValue('province', value)}
              css={styles.selector}
              disabled={!editable}
            />
          ) : (
            <TextForm css={styles.textForm} placeholder="" disabled={true} {...provinceField} />
          )}
        </FieldRow>
        <FieldRow>
          <Label>{t('City')}:</Label>
          <TextForm
            css={styles.textForm}
            error={!!cityMeta.error}
            placeholder={t('City')}
            disabled={!editable}
            {...cityField}
          />
        </FieldRow>
        <FieldRow>
          <Label>{t('Postal Code')}:</Label>
          <TextForm
            css={styles.textForm}
            error={!!postalCodeMeta.error}
            placeholder="〒106-6131"
            disabled={!editable}
            {...postalCodeField}
          />
        </FieldRow>
        <FieldRow>
          <Label>{t('Address 1')}:</Label>
          <SentenceForm
            css={styles.sentenceForm}
            error={!!address1Meta.error}
            placeholder="Address 1"
            disabled={!editable}
            {...address1Field}
          />
        </FieldRow>
        <FieldRow>
          <Label>{t('Address 2')}:</Label>
          <SentenceForm
            css={styles.sentenceForm}
            error={!!address2Meta.error}
            placeholder="Address 2"
            disabled={!editable}
            {...address2Field}
          />
        </FieldRow>
        <FieldRow>
          <Label>{t('Tags')}:</Label>
          <div css={styles.tagField}>
            <div css={styles.tagTextBoxWrapper}>
              <Col css={styles.marginRight8}>
                <TextForm
                  css={styles.textForm}
                  error={!!tagEntryMeta.error}
                  placeholder={t('Tags')}
                  disabled={!editable}
                  {...tagEntryField}
                />
              </Col>
              <Button
                title={t('Add Tag')}
                css={styles.tagBtn}
                hoverbgcolor="#27313b"
                disabled={!tagEntryField.value}
                onClick={handleClickAddTag}
              />
            </div>
            <div css={styles.tagsContainer}>
              {tagsField.value
                .filter(item => item)
                .map((tag, index) => (
                  <p key={index + tag} css={styles.tag}>
                    {tag}
                    <i
                      className="material-icons"
                      css={[styles.icon, !editable && { cursor: 'default' }]}
                      onClick={() => handleClickDelete(tag)}
                    >
                      close
                    </i>
                  </p>
                ))}
            </div>
          </div>
        </FieldRow>
        {isMobileView && (
          <div css={[styles.submitBtnWrapper, styles.mobileSubmitWrapper]}>
            <SubmitButton disabled={isSubmitting} value="Save" css={styles.submitBtn} />
          </div>
        )}
      </div>
      {!isMobileView && (
        <div css={styles.submitBtnWrapper}>
          <SubmitButton disabled={isSubmitting} value="Save" css={styles.submitBtn} />
        </div>
      )}
    </form>
  );
};

const styles = {
  container: css`
    max-width: 600px;
    padding: 24px;
    background-color: #fff;
  `,
  mobileSubmitWrapper: css`
    padding-right: 0;

    input {
      width: 100%;
    }
  `,
  pickerStyle: css`
    width: 50%;

    & > div:nth-of-type(2) {
      margin: 0 8px;
    }

    /* stylelint-disable */
    & > div > div {
      border-radius: 0;
      min-height: 32px;

      & > div > input {
        min-height: 32px;
      }
    }
    /* stylelint-enable */
  `,
  tagBtn: css`
    width: fit-content;
    padding: 0 32px;
    line-height: 32px;
    border-radius: 3px;
    background-color: #27313b;
  `,
  sentenceForm: css`
    flex: 1;

    textarea {
      border-radius: 3px;
      height: 56px;
      padding: 8px 12px;
    }
  `,
  submitBtn: css`
    width: fit-content;
    line-height: 32px;
    border-radius: 3px;
    padding: 0 24px;
  `,
  submitBtnWrapper: css`
    height: 64px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #eef3f7;
    padding-right: 24px;
  `,
  selector: css`
    width: 100%;

    /* stylelint-disable */
    & > div > div {
      min-height: 32px;
      border-radius: 3px;

      input {
        min-height: 32px;
      }
    }
    /* stylelint-enable */
  `,
  textForm: css`
    flex: 1;

    /* stylelint-disable */
    & input {
      border-radius: 3px;
      height: 32px;
    }
    /* stylelint-enable */
  `,
  icon: css`
    margin-left: 8px;
    font-size: 16px;
    cursor: pointer;
  `,
  marginRight16: css`
    margin-right: 16px;
  `,
  marginRight8: css`
    margin-right: 8px;
  `,
  tag: css`
    display: inline-flex;
    align-items: center;
    background-color: #eef3f7;
    border-radius: 3px;
    height: 24px;
    padding: 0 8px;
    color: #27313b;
    margin: 0 8px 8px 0;
  `,
  tagTextBoxWrapper: css`
    display: flex;
    flex: 1;
  `,
  tagField: css`
    display: flex;
    flex: 1;
    flex-direction: column;
  `,
  tagsContainer: css`
    margin-top: 12px;
  `,
};
const FieldRow = styled.div`
  display: flex;
  margin-bottom: 16px;
  flex: 1;
`;
const Label = styled.p`
  font-size: 14px;
  color: #27313b;
  line-height: 32px;
  min-width: 116px;
`;
const Col = styled.div`
  flex: 1;
`;

export default FanDetailsForm;
