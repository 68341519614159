import { QuestionType } from '@src/__generated__/globalTypes';
import React from 'react';
import { css } from '@emotion/core';
import TextForm from '@src/components/molecules/TextForm';
import CheckBoxIcon from '@src/components/atoms/Icon/CheckBoxIcon';
import SingleSelector from '@src/components/molecules/Selector/SingleSelector';
import { useTranslation } from 'react-i18next';
import {
  GetIndividualFormResponse_getForm,
  GetIndividualFormResponse_getFormResponseByPage_answers,
} from './__generated__/GetIndividualFormResponse';

interface FormPageResponseProps {
  formData: GetIndividualFormResponse_getForm | null;
  answers: GetIndividualFormResponse_getFormResponseByPage_answers[];
}

const FormPageResponse = (props: FormPageResponseProps) => {
  const { t } = useTranslation();
  const { formData, answers } = props;

  const renderAnswer = (answer: GetIndividualFormResponse_getFormResponseByPage_answers) => {
    switch (answer.questionType) {
      case QuestionType.NAME: {
        const [firstName, lastName] = answer.answerText?.split(' ') || ' ';

        return (
          <div css={[styles.row, { display: 'flex' }]} key={answer.questionId}>
            <div css={{ flex: 1, marginRight: 16 }}>
              <p css={styles.questionTitle}>{t('First Name')}</p>
              <TextForm css={styles.textForm} disabled={true} placeholder="" value={firstName} />
            </div>
            <div css={{ flex: 1 }}>
              <p css={styles.questionTitle}>{t('Last Name')}</p>
              <TextForm css={styles.textForm} disabled={true} placeholder="" value={lastName} />
            </div>
          </div>
        );
      }
      case QuestionType.EMAIL:
        return (
          <div css={styles.row} key={answer.questionId}>
            <p css={styles.questionTitle}>{t('Email Address')}</p>
            <TextForm css={styles.textForm} disabled={true} placeholder="" value={answer.answerText} />
          </div>
        );
      case QuestionType.SHORT_ANSWER:
        return (
          <div css={styles.row} key={answer.questionId}>
            <p css={styles.questionTitle}>{answer.questionTitle}</p>
            <TextForm css={styles.textForm} disabled={true} placeholder="" value={answer.answerText} />
          </div>
        );
      case QuestionType.CHECKBOX: {
        const allAnswersHash: { [key: string]: number } = answer.answerOptionIds.reduce(
          (prev, curr) => ({ ...prev, [curr]: 1 }),
          {}
        );

        return (
          <div css={styles.row} key={answer.questionId}>
            <p css={styles.questionTitle}>{answer.questionTitle}</p>
            {formData?.questions
              .find(question => question.id === answer.questionId)
              ?.options.map(option => (
                <div key={option.id} css={styles.checkboxRow}>
                  <CheckBoxIcon checked={!!allAnswersHash[option.id]} />
                  <span>{option.optionTitle}</span>
                </div>
              ))}
          </div>
        );
      }
      case QuestionType.DROPDOWN: {
        const dropdownOptions = formData?.questions
          .find(question => question.id === answer.questionId)
          ?.options.map(option => ({ value: option.id, label: option.optionTitle }));

        return (
          <div css={styles.row} key={answer.questionId}>
            <p css={styles.questionTitle}>{answer.questionTitle}</p>
            <SingleSelector
              placeholder={answer.questionTitle}
              name={answer.questionTitle}
              options={dropdownOptions || []}
              value={answer.answerOptionIds?.[0] ? String(answer.answerOptionIds?.[0]) : ''}
              dropdownCss={styles.dropdown}
              disabled={true}
            />
          </div>
        );
      }
      default:
        return null;
    }
  };

  return <>{answers.map(item => renderAnswer(item))}</>;
};

const styles = {
  row: css`
    padding: 24px 16px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #dee5ec;
    }
  `,
  questionTitle: css`
    font-weight: 600;
    font-size: 14px;
    color: #27313b;
    margin-bottom: 16px;
  `,
  textForm: css`
    flex: 1;

    /* stylelint-disable */
    & input {
      border-radius: 3px;
      height: 32px;
      border: none;
    }
    /* stylelint-enable */
  `,
  checkboxRow: css`
    display: flex;
    align-items: center;

    span {
      margin-left: 8px;
    }

    i {
      cursor: default;
    }

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  `,
  dropdown: css`
    width: 180px;

    & > div {
      border-radius: 3px;
      min-height: 32px;
    }

    input {
      min-height: 32px;
    }
  `,
};

export default FormPageResponse;
