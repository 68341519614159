import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ListIndicator } from '@src/components/molecules/Indicator';
import CampaignCards from '@src/components/organisms/CastingMarketplace/YourJob/CampaignCards';
import EmptyJob from '@src/components/organisms/CastingMarketplace/YourJob/EmptyJob';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import MARKETPLACE_CAMPAIGNS from './queries/MarketplaceCampaigns.graphql';
import { MarketplaceCampaigns, MarketplaceCampaignsVariables } from './queries/__generated__/MarketplaceCampaigns';

const YourJob = () => {
  const { enqueueSnackbar, history, t } = useQueryHelper();
  const [tab, setTab] = useState<string>(location.hash.slice(1) || 'in_progress');
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });

  const { data, loading, refetch } = useQuery<MarketplaceCampaigns, MarketplaceCampaignsVariables>(
    MARKETPLACE_CAMPAIGNS,
    {
      variables: {
        isFinished: tab === 'finished',
      },
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [tab]);

  function onClickTab(value: string) {
    setTab(value);
    history.push({ hash: value });
  }

  const tabs = [
    {
      count: data?.allMarketplaceCampaignsForYourJobCounts?.progressCampaignCount || 0,
      title: 'in progress',
      value: 'in_progress',
    },
    {
      count: data?.allMarketplaceCampaignsForYourJobCounts?.finishedCampaignCount || 0,
      title: 'finished',
      value: 'finished',
    },
  ];

  const campaigns = data?.allMarketplaceCampaignsForYourJob || [];

  return (
    <div css={styles.container}>
      <div>
        {isDesktopView && <BackNavigator title="Your Job" />}

        <div css={styles.tabsContainer}>
          {tabs.map((item, index) => {
            const isActive = item.value === tab;

            return (
              <Tab isActive={isActive} key={index} onClick={() => onClickTab(item.value)}>
                <span>{item.count}</span>
                <span>{t(item.title)}</span>
              </Tab>
            );
          })}
        </div>

        {loading ? <ListIndicator /> : campaigns.length > 0 ? <CampaignCards campaigns={campaigns} /> : <EmptyJob />}
      </div>
    </div>
  );
};

const Tab = styled.div<{ isActive?: boolean }>`
  background-color: ${({ isActive }) => (isActive ? '#27313b' : '#fff')};
  color: ${({ isActive }) => (isActive ? '#fff' : '#27313b')};
  cursor: pointer;
  display: grid;
  flex-basis: 50%;
  height: 40px;
  padding: 8px 0;
  text-align: center;
  text-transform: uppercase;

  & > span:nth-of-type(1) {
    font-size: 18px;
    font-weight: 600;
  }

  & > span:nth-of-type(2) {
    font-size: 12px;
    font-weight: 600;
  }
`;

const styles = {
  container: css`
    display: flex;
    justify-content: center;
    margin: 16px;

    & > div {
      max-width: 1080px;
      width: 100%;
    }
  `,
  tabsContainer: css`
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0;
  `,
};

export default YourJob;
