import React from 'react';
import { useQuery } from '@apollo/client';
import { Period } from '@src/components/molecules/DayPicker/types';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { useQueryHelper } from '@src/libs/hooks';
import { LIMIT_10 } from '@src/libs/paging/constant';
import { SocialMediaAccountType } from '../../../ProfileDetail/types';
import * as INFLUENCER_YT_POSTS_STATISTICS from './InfluencerYoutubeAnalyticsPostsStatistics.graphql';
import {
  InfluencerYoutubeAnalyticsPostsStatistics,
  InfluencerYoutubeAnalyticsPostsStatisticsVariables,
} from './__generated__/InfluencerYoutubeAnalyticsPostsStatistics';
import Template from './template';

interface PostCardProps {
  selectedSocialMedia: SocialMediaAccountType;
  selectedPeriod: Period;
  userId: number;
  className?: string;
}

const PostCard = (props: PostCardProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { selectedPeriod, selectedSocialMedia } = props;

  const { data, loading, error } = useQuery<
    InfluencerYoutubeAnalyticsPostsStatistics,
    InfluencerYoutubeAnalyticsPostsStatisticsVariables
  >(INFLUENCER_YT_POSTS_STATISTICS, {
    variables: {
      socialAccountId: selectedSocialMedia.id,
      startDate: selectedPeriod.startDate,
      endDate: selectedPeriod.endDate,
      offset: 0,
      limit: LIMIT_10,
      keyword: '',
      orderBy: {
        field: null,
        order: null,
      },
    },
  });

  if (loading) {
    return <ListIndicator />;
  }

  if (error) {
    enqueueSnackbar(t(error.message), { variant: 'error' });

    return null;
  }

  const stats = data?.analyticsPostsStatistics || {};
  const feedVideos = data?.analyticsPosts.feedVideos;

  return (
    <Template
      period={selectedPeriod}
      socialMediaAccountId={selectedSocialMedia.id}
      {...stats}
      feedVideos={feedVideos || null}
    />
  );
};

export default PostCard;
