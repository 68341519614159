import React from 'react';
import DataOpacityWrapper, { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import { useQueryHelper, useContainerQuery } from '@src/libs/hooks';
import { YoutubeAnalyticsStats } from '@src/components/organisms/AnalyticsYoutube/types';
import { bigIntFormatter, intlNumberFormat } from '@src/libs/format';
import * as Styled from '@src/components/organisms/AnalyticsShared/AverageEngagementStyled';
import * as StyledOverview from '@src/components/organisms/AnalyticsShared/Overview/StyledComponents';
import { getGrowthPrefix, getGrowthArrow } from '@src/components/organisms/AnalyticsShared/utils';
import { getYtAverageEngagementIconAndTitle } from './helpers';

const AverageEngagement = (props: YoutubeAnalyticsStats & { className?: string }) => {
  const { t } = useQueryHelper();
  const { averagePostsPerWeek, averageLikes, averageComments, averageDislikes, averageViews } = props;

  const { isSmaller: isDirectionColumn } = useContainerQuery({
    hasWidthSmallerThan: 250,
  });

  const dataNotAvailable =
    !averagePostsPerWeek && !averageLikes && !averageDislikes && !averageViews && !averageComments;
  const { className, ...engagements } = props;
  const engagementValues = (!dataNotAvailable ? Object.keys(engagements) : []).filter(
    item => item !== '__typename'
  ) as Array<keyof YoutubeAnalyticsStats>;

  const { engagementStyles } = Styled;

  return (
    <DataOpacityWrapper
      header={<SectionHeader>{t('Average Engagement')}</SectionHeader>}
      dataNotAvailable={dataNotAvailable}
      css={engagementStyles.container}
      className={className}
    >
      <Styled.MainWrapper css={engagementStyles.gridContainer}>
        {engagementValues.map(engKey => {
          const item = props[engKey];
          const avgGrowth = item?.growth;
          const avgTotal = item?.value;
          const total = bigIntFormatter(item?.value, 0);
          const growth = bigIntFormatter(item?.growth, 0);
          const { title, icon } = getYtAverageEngagementIconAndTitle(engKey);
          const hoverTotal = intlNumberFormat(avgTotal);
          const hoverGrowth = intlNumberFormat(avgGrowth);

          if (isDirectionColumn) {
            return (
              <StyledOverview.EngagementGridCell key={title}>
                <StyledOverview.PartWithIcon>
                  <StyledOverview.EngagementTitle text={t(title)} lines={1} />
                  <StyledOverview.EngagementTileIcon src={icon} css={engagementStyles.icon} />
                </StyledOverview.PartWithIcon>

                <StyledOverview.EngagementNumbers>
                  <StyledOverview.TotalNumber title={hoverTotal}>{total}</StyledOverview.TotalNumber>
                  {!!avgGrowth && (
                    <StyledOverview.Growth isPositive={Number(avgGrowth) > 0} title={hoverGrowth}>{`${getGrowthPrefix(
                      avgGrowth
                    )}${growth}  ${getGrowthArrow(avgGrowth)}`}</StyledOverview.Growth>
                  )}
                </StyledOverview.EngagementNumbers>
              </StyledOverview.EngagementGridCell>
            );
          }

          return (
            <Styled.EngagementDataRow key={title}>
              <Styled.EngagementHalf isDirectionColumn={isDirectionColumn}>
                <img src={icon} css={{ width: 32, height: 32 }} />
                <Styled.Label>{t(title)}</Styled.Label>
              </Styled.EngagementHalf>
              <Styled.EngagementHalf>
                <Styled.GrowthWrapper>
                  <Styled.Value title={hoverTotal}>{total}</Styled.Value>
                  {!!avgGrowth && (
                    <Styled.Identifier isPositive={Number(avgGrowth) > 0} title={hoverGrowth}>{`${getGrowthPrefix(
                      avgGrowth
                    )}${growth}  ${getGrowthArrow(avgGrowth)}`}</Styled.Identifier>
                  )}
                </Styled.GrowthWrapper>
              </Styled.EngagementHalf>
            </Styled.EngagementDataRow>
          );
        })}
      </Styled.MainWrapper>
    </DataOpacityWrapper>
  );
};

export default AverageEngagement;
