import React from 'react';
import { FbProfilePostsSection } from '../FacebookProfilePosts/ProfilePosts';
import FollowersGrowthAndPosts from '../FollowersGrowthAndPosts';
import { ProfileV2TemplateProps } from './types';

const FacebookPageTemplate = (props: ProfileV2TemplateProps) => {
  const { selectedSocialMedia, influencerId } = props;

  return (
    <>
      <FollowersGrowthAndPosts
        socialMediaAccountId={selectedSocialMedia.id}
        influencerAccountId={influencerId}
        socialMedia={selectedSocialMedia.socialAccountType}
      />
      <FbProfilePostsSection
        socialMediaAccountId={selectedSocialMedia.id}
        influencerAccountId={influencerId}
        socialMedia={selectedSocialMedia.socialAccountType}
      />
    </>
  );
};

export default FacebookPageTemplate;
