import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MarketplaceCampaignPostStatus } from '../../../__generated__/globalTypes';
import { getMarketplacePostStatusColor, getMarketplacePostStatusMessage } from '../../../libs/status';
import { StatusColumn } from '../../atoms/List';

interface StatusColumnProps {
  className?: string;
  status: MarketplaceCampaignPostStatus;
  isTableElement?: boolean;
}

const MarketplacePostStatusColumn = (props: StatusColumnProps) => {
  const { className, status, isTableElement } = props;
  const color = getMarketplacePostStatusColor(status);
  const message = getMarketplacePostStatusMessage(status);
  const { t } = useTranslation();

  return (
    <StatusColumn className={className} statusColor={color} isTableElement={isTableElement}>
      {t(message)}
    </StatusColumn>
  );
};

export default MarketplacePostStatusColumn;
