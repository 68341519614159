import React, { useCallback } from 'react';
import { Redirect } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import { useQueryHelper } from '@src/libs/hooks';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { useQuery } from '@apollo/client';
import Template from './template';
import {
  InfluencerInstagramAnalyticsStoryDetails,
  InfluencerInstagramAnalyticsStoryDetailsVariables,
} from './__generated__/InfluencerInstagramAnalyticsStoryDetails';
import * as INFLUENCER_INSTAGRAM_ANALYTICS_STORY_DETAILS from './InfluencerInstagramAnalyticsStoryDetails.graphql';

interface AnayticsStoryPostDetailProps {
  accountId: number;
  storyId?: number;
  closeModal?: () => void;
}

const AnayticsStoryPostDetail = (props: AnayticsStoryPostDetailProps) => {
  const { accountId } = props;
  const { t, history, enqueueSnackbar, search, pathname } = useQueryHelper();

  const storyId = props.storyId || Number(new URLSearchParams(search).get('popup'));

  const { data, loading, error } = useQuery<
    InfluencerInstagramAnalyticsStoryDetails,
    InfluencerInstagramAnalyticsStoryDetailsVariables
  >(INFLUENCER_INSTAGRAM_ANALYTICS_STORY_DETAILS, {
    variables: { storyId, socialAccountId: Number(accountId) },
    onError: useCallback(() => {
      history.push(pathname);
      enqueueSnackbar(t('Story was not found'), { variant: 'error' });
    }, []),
  });

  if (loading) {
    return <CircularProgress size={64} thickness={2.4} color="inherit" />;
  }

  if (error) {
    return <Redirect to="/404" />;
  }

  return (
    <Template
      post={
        data?.analyticsStoryDetails
          ? {
              ...data?.analyticsStoryDetails,
              accountName: data?.analyticsStoryDetails?.instagramAccountName,
            }
          : null
      }
      accountType={SocialAccountType.INSTAGRAM}
    />
  );
};

export default AnayticsStoryPostDetail;
