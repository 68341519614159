import commentIcon from '@src/assets/icon/comment.svg';
import reactionIcon from '@src/assets/icon/thumbs-up.svg';
import impressionsIcon from '@src/assets/icon/impressions.svg';
import viewsIcon from '@src/assets/icon/views.svg';
import postWeekIcon from '@src/assets/icon/picture.svg';
import sharesIcon from '@src/assets/icon/network.svg';
import { FacebookPageAnalyticsStats } from '../../types';

export const getAverageEngagementIconAndTitle = (engKey: keyof FacebookPageAnalyticsStats) => {
  switch (engKey) {
    case 'averageComments':
      return {
        title: 'Avg Comments',
        icon: commentIcon,
      };
    case 'averageImpressions':
      return {
        title: 'Avg Impressions',
        icon: impressionsIcon,
      };
    case 'averagePostsPerWeek':
      return {
        title: 'Avg posts/week',
        icon: postWeekIcon,
      };
    case 'averageReactions':
      return {
        title: 'Avg Reaction',
        icon: reactionIcon,
      };
    case 'averageShares':
      return {
        title: 'Avg Shares',
        icon: sharesIcon,
      };
    case 'averageViews':
      return {
        title: 'Avg Views',
        icon: viewsIcon,
      };

    default:
      return {
        title: '',
        icon: '',
      };
  }
};
