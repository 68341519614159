import styled from '@emotion/styled';
import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useCopy } from '../../../../libs/hooks/useCopy';
import { mainWhite } from '../../../../libs/pallete';
import { PalleteButton } from '../../../atoms/Button';
import Textarea from '../../../atoms/Textarea';
import Carousel from '../../../molecules/Carousel';
import {
  EngagementPostDetailForInfluencer,
  EngagementPostDetailForInfluencerVariables,
} from '../__generated__/EngagementPostDetailForInfluencer';
import * as ENGAGEMENT_POST_DETAIL_FOR_INFLUENCER from '../EngagementPostDetailForInfluencer.graphql';
import emptyCampaignImage from '../../../../assets/img/emptyImage.jpg';

interface DraftPostDetailTabProps {
  postId: number;
}

const DraftPostDetailTab = ({ postId }: DraftPostDetailTabProps) => {
  const { handleCopyHasText, isCopying } = useCopy();
  const { t } = useTranslation();

  const { data } = useQuery<EngagementPostDetailForInfluencer, EngagementPostDetailForInfluencerVariables>(
    ENGAGEMENT_POST_DETAIL_FOR_INFLUENCER,
    { variables: { pk: postId } }
  );

  const images = data?.engagementPostDetailForInfluencer?.images || [emptyCampaignImage];

  return (
    <>
      <Content>
        <Carousel sources={images.length > 0 ? images : [emptyCampaignImage]}></Carousel>
        <PostCaptionWrapper>
          <Caption>
            <CaptionLabel>{t('Post caption')}</CaptionLabel>
            <PalleteButton
              text="Copy"
              palleteColor={mainWhite}
              isDisabled={isCopying}
              height={'24px'}
              width={'fit-content'}
              onClick={() => handleCopyHasText(data?.engagementPostDetailForInfluencer?.caption || '')}
            />
          </Caption>
          <Textarea defaultValue={data?.engagementPostDetailForInfluencer?.caption || ''} placeholder={''} />
        </PostCaptionWrapper>
      </Content>
    </>
  );
};

export default DraftPostDetailTab;

const Content = styled.div``;

const PostCaptionWrapper = styled.div`
  textarea {
    border-radius: 0;
  }
`;

const Caption = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 8px 0;

  button {
    margin-left: auto;
  }
`;

const CaptionLabel = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #27313b;
`;
