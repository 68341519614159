import { useField, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import send from '@src/assets/icon/chat/send.svg';
import { Button } from '@src/components/atoms/Button';
import TextForm from '@src/components/molecules/TextForm';
import { usePageLayout } from '@src/libs/hooks';

interface FormValues {
  comment: string;
}

const AddCommentInput = () => {
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();
  const { isSubmitting, handleSubmit, setFieldValue } = useFormikContext<FormValues>();
  const [commentField] = useField<FormValues['comment']>('comment');

  return (
    <form onSubmit={handleSubmit}>
      <Container isMobileView={isMobileView}>
        <TextForm
          placeholder={t('Comment')}
          value={commentField.value}
          onChange={e => setFieldValue('comment', e.target.value)}
        />
        <Button
          bgcolor="#3892e5"
          disabled={!commentField.value || isSubmitting}
          hoverbgcolor="#3892e5"
          icon={isMobileView ? <img alt="sendIcon" height="24" src={send} width="24" /> : <div />}
          title={!isMobileView ? 'Post' : ''}
          type="submit"
        />
      </Container>
    </form>
  );
};

const Container = styled.div<{ isMobileView: boolean }>`
  background-color: #fff;
  border: 1px solid #dee5ec;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  padding: 8px;
  z-index: 10;
  ${({ isMobileView }) =>
    isMobileView ? 'bottom: -8px; left: -16px; position: absolute; width: calc(100% + 16px);' : ''}

  & > div {
    display: flex;
    flex: 1;

    & > div {
      width: 100%;
    }
  }

  & > button {
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
    height: 32px;
    margin-left: 8px;
    width: ${({ isMobileView }) => (isMobileView ? '56' : '59')}px;
  }
`;

export default AddCommentInput;
