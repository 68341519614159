import React from 'react';
import SunEditor, { SunEditorReactProps } from 'suneditor-react';
import Lang from 'suneditor-react/types/Lang';
import 'suneditor/src/assets/css/suneditor.css';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { FORM } from '@src/libs/theme';
import Label from '../../atoms/Label';

interface RichEditorProps extends Omit<SunEditorReactProps, 'setContents'> {
  className?: string;
  title?: string;
  isRequired?: boolean;
  error?: boolean;
  help?: string;
  note?: string;
  value?: string;
}

const RichEditor = (props: RichEditorProps) => {
  const { i18n, t } = useTranslation();
  const { title, isRequired, help, value, error, setOptions } = props;
  const buttonList = [
    ['undo', 'redo'],
    ['bold', 'underline', 'italic', 'strike'],
    ['fontColor', 'hiliteColor'],
    ['removeFormat'],
    ['indent', 'outdent'],
    ['list'],
    ['link'],
    ['image'],
  ];

  return (
    <StyledContent>
      {title && <Label title={t(title)} isRequired={isRequired} help={help} />}
      <EditorContainer error={error}>
        <SunEditor
          lang={i18n.language as Lang}
          setContents={value}
          setOptions={{ buttonList, ...setOptions }}
          {...props}
        />
      </EditorContainer>
    </StyledContent>
  );
};

// For some reason, all our native css is reseted in our general css.. so need to add back those default styles
const StyledContent = styled.div`
  h1 {
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h3 {
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h4 {
    display: block;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h5 {
    display: block;
    font-size: 0.83em;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h6 {
    display: block;
    font-size: 0.67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  a,
  button,
  i,
  img,
  input,
  label,
  select,
  textarea {
    display: inline-block;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }

  ul ul,
  ol ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: 15px;
  }

  ol ol,
  ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
    margin-left: 15px;
  }

  li {
    list-style: inherit;
    display: list-item;
  }

  pre {
    background-color: #e0e0e0;
    border-radius: 10px;
    color: rgba(0, 0, 0, 0.9);
    font-family: Consolas, monospace;
    margin: 0;
  }

  blockquote {
    border-left: 5px solid #eee;
    font-family: 'Hoefler Text', 'Georgia', serif;
    color: #666;
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }

  a {
    color: #004cff;
    text-decoration: none;
  }

  a:hover {
    cursor: pointer;
    color: #0093ff;
    text-decoration: underline;
  }

  .sun-editor {
    color: #27313b;
    font-family: 'Inter', sans-serif;
    border: inherit;
  }

  .se-dialog.sun-editor-common {
    z-index: 2;
  }

  .se-wrapper {
    position: unset !important;
    z-index: unset;
  }

  .se-wrapper-inner.se-wrapper-wysiwyg.sun-editor-editable {
    word-break: break-all;
    padding: 16px 16px 0 16px;
  }

  .se-dialog-btn-radio {
    appearance: auto;
  }
`;

const EditorContainer = styled.div<{ error?: boolean }>`
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  border-color: ${props => (props.error ? FORM.ERROR : FORM.BORDER)};
  border-radius: 2px;
  font-size: 13px;
`;

export default RichEditor;
