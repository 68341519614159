import React from 'react';
import MaterialTooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/styles/makeStyles';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  tooltip: {
    fontSize: '12px',
    maxWidth: 'none',
    backgroundColor: '#27313b',
    marginBottom: '8px',
  },
  arrow: {
    color: '#27313b',
  },
});

interface TooltipTypes {
  help: React.ReactNode;
  iconMargin?: string;
  placement?: TooltipProps['placement'];
  className?: string;
  helpInterpol?: { [key: string]: number };
  children?: JSX.Element;
}

export const Tooltip = (props: TooltipTypes) => {
  const { t } = useTranslation();
  // @ts-ignore
  const classes = useStyles();
  const { help, iconMargin, placement = 'left', className, helpInterpol } = props;
  const isHelpString = typeof help === 'string';

  return (
    <MaterialTooltip
      // @ts-ignore
      title={isHelpString ? t(`Tooltip.${help}`, { ...helpInterpol }) || '' : help}
      placement={placement}
      classes={classes}
      className={className}
      // @ts-ignore
      arrow
    >
      {props.children ? (
        props.children
      ) : (
        <HelpIcon className="material-icons" iconMargin={iconMargin}>
          help
        </HelpIcon>
      )}
    </MaterialTooltip>
  );
};

const HelpIcon = styled.i<{ iconMargin?: string }>`
  margin-left: ${({ iconMargin }) => iconMargin || 'auto'};
  padding-right: 1px;
  color: #d5d5d5;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: #27313b;
  }
`;
