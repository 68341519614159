import styled from '@emotion/styled';
import { css } from '@emotion/core';
import React, { useState } from 'react';
import targetBlank from '@src/assets/icon/link.svg';
import { SocialMediaColumn } from '@src/components/atoms/List';
import { DayPickerRange } from '@src/components/molecules/DayPicker';
import { Period } from '@src/components/molecules/DayPicker/types';
import { SingleSelector } from '@src/components/molecules/Selector';
import { getThirtyDaysAgo, getToday } from '@src/libs/date';
import { getLocationFromSearch } from '@src/libs/paging';
import { switchImage, switchSocialBackgroundColor } from '@src/libs/SocialMedia';
import { ViewportType } from '@src/libs/theme';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import { useQueryHelper } from '@src/libs/hooks';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import TextCutter from '@src/components/atoms/TextCutter';
import { SocialMediaAccountType } from '../types';

export interface StatsOverviewProps {
  availableSocialMediaAccounts: SocialMediaAccountType[];
  selectedSocialMedia: SocialMediaAccountType;
  onChangeSocialMedia: (social: SocialMediaAccountType) => void;
  withPeriod?: boolean;
  initialPeriod?: Period;
  lastUpdate?: string;
  onPeriodChange?: (value: Period) => void;
}

const StatsOverview = (props: StatsOverviewProps) => {
  const { history, pathname, search, t, i18n } = useQueryHelper();

  const {
    availableSocialMediaAccounts,
    selectedSocialMedia,
    onChangeSocialMedia,
    onPeriodChange,
    withPeriod,
    initialPeriod,
    lastUpdate,
  } = props;
  const [period, setPeriod] = useState<Period>(initialPeriod || { startDate: getThirtyDaysAgo(), endDate: getToday() });
  const socialAccountsDropdown = availableSocialMediaAccounts.map(social => ({
    label: social.name,
    value: social.id.toString(),
    icon: <SocialIcon src={switchImage(social.socialAccountType)} alt="" />,
  }));

  const handleOnChangeSocialMedia = (id: string) =>
    onChangeSocialMedia(
      availableSocialMediaAccounts.find(social => social.id.toString() === id) || selectedSocialMedia
    );

  const handleOnChangePeriod = (selectedPeriod: Period) => {
    setPeriod(selectedPeriod);
    if (onPeriodChange) {
      onPeriodChange(selectedPeriod);
      const urlParams = getLocationFromSearch(pathname, search, {
        stda: selectedPeriod.startDate || '',
        enda: selectedPeriod.endDate || '',
      });
      history.replace({ search: urlParams.search });
    }
  };

  return (
    <>
      <Stats background={switchSocialBackgroundColor(selectedSocialMedia.socialAccountType)}>
        <Header>
          <StatsTitle>{t('HeaderColumn.Stats Overview')}</StatsTitle>
          {lastUpdate && (
            <UpdateAt>
              {t('Last Update')} : {localizedDateFormatter(lastUpdate, 'PPP', i18n.language as AppLanguage)}
            </UpdateAt>
          )}
        </Header>

        <ActionWrapper>
          <SingleSelectorWrapper
            menuCss={css`
              margin-top: 5px;
              box-shadow: 0 1px 2px 0 rgba(39, 49, 59, 0.1);
              border-radius: 3px;
            `}
            value={selectedSocialMedia.id.toString()}
            name={t('HeaderColumn.Stats Overview')}
            options={socialAccountsDropdown}
            onChange={handleOnChangeSocialMedia}
            hideInput={true}
            hideDeselectOption={true}
            isRoundInput={false}
          />
        </ActionWrapper>
      </Stats>
      <SubStats>
        {withPeriod ? (
          <>
            <SocialLinkWrapper href={selectedSocialMedia.url || undefined} target="_blank" rel="noopener noreferrer">
              <LinkWrapper>
                <Image
                  src={switchImage(selectedSocialMedia.socialAccountType)}
                  alt={`selectedSocialMedia.socialAccountType`}
                />
                <Image src={targetBlank} alt={`Go To Profile`} />
              </LinkWrapper>
            </SocialLinkWrapper>
            <DayPickerRange
              period={period}
              initialPeriod={initialPeriod}
              handleChangePeriod={handleOnChangePeriod}
              disabledRange={{ after: new Date() }}
            />
          </>
        ) : (
          <SocialLinkWrapper href={selectedSocialMedia.url || undefined} target="_blank" rel="noopener noreferrer">
            <StyledSocialMediaColumn socialMedia={selectedSocialMedia.socialAccountType} isTable={false} />
            <AccountUrl>
              {`${t('Account URL')}: `}
              <AccountUrlSpan text={selectedSocialMedia.url} lines={1} />
            </AccountUrl>
          </SocialLinkWrapper>
        )}
      </SubStats>
    </>
  );
};

const LinkWrapper = styled.div`
  display: inline-flex;
  padding: 0 12px;
`;

const Image = styled.img`
  width: 17px;
  margin: 0 auto;
`;

const SubStats = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 16px;
  border: 1px solid #dee5ec;
  border-top: none;
  border-radius: 0 0 3px 3px;
  box-sizing: border-box;
  background-color: #fff;
  padding: 0 8px;
`;

const Stats = styled.div<{ background: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  background: ${props => (props.background ? props.background : 'transparent')};
  height: 48px;
  color: #fff;
  padding: 0 8px 0 16px;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5);
  border-radius: 3px 3px 0 0;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatsTitle = styled.h2`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
`;

const UpdateAt = styled.div`
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #27313b;
  font-size: 13px;
  cursor: pointer;
`;

const SingleSelectorWrapper = styled(SingleSelector)`
  min-height: 30px;
  max-height: 30px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  width: 214px;
  box-sizing: border-box;

  @media (max-width: ${ViewportType.SMALL}px) {
    width: 145px;
  }
`;

const SocialIcon = styled.img`
  width: 16px;
  display: inline-block;
  margin-right: 8px;
`;

const SocialLinkWrapper = styled.a`
  display: flex;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledSocialMediaColumn = styled(SocialMediaColumn)`
  align-self: center;
  padding-left: 8px;
`;

const AccountUrl = styled.p`
  align-self: center;
  line-height: 17px;
  color: #27313b;
`;

const AccountUrlSpan = styled(TextCutter)`
  text-decoration: underline;

  &:hover {
    color: #6ab0ff;
  }
`;

export default StatsOverview;
