import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { Icon } from '@src/components/atoms/Icon';

interface BackNavigatorProps {
  className?: string;
  title: string;
  to?: string;
  interpolation?: Record<string, string>;
  skipTranslation?: boolean;
  onClick?: (e: SyntheticEvent<HTMLAnchorElement>) => void;
}

const BackNavigator = (props: BackNavigatorProps) => {
  const { t } = useTranslation();
  const { title, to, interpolation, skipTranslation, className, onClick } = props;

  return (
    <div className={className} css={styles.container}>
      {to && (
        <Link to={to} onClick={onClick}>
          <Icon color="#979797" icon="chevron_left" />
        </Link>
      )}
      <div>{skipTranslation ? title : t(`pageTitle.${title}`, { ...interpolation })}</div>
    </div>
  );
};

const styles = {
  container: css`
    align-items: center;
    display: flex;

    & > a {
      background-color: #fff;
      box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
      display: flex;
      height: 32px;
      justify-content: center;
      margin-right: 16px;
      width: 32px;

      & > i {
        padding-top: 2px;
      }
    }

    & > div {
      font-size: 18px;
      font-weight: 600;
    }
  `,
};

export default BackNavigator;
