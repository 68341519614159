import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import * as React from 'react';

const styles = createStyles({
  progress: {
    color: '#e0e8ed',
  },
});

const Indicator = (props: WithStyles<typeof styles>) => {
  const { classes } = props;

  return <CircularProgress className={classes.progress} size={64} thickness={2.4} />;
};

export default withStyles(styles)(Indicator);
