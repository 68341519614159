import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { useMutation, useQuery } from '@apollo/client';
import shopifyIcon from '@src/assets/icon/shopify.svg';
import warningRed from '@src/assets/icon/warningRed.svg';
import shopifyImg from '@src/assets/img/shopify.svg';
import { Icon } from '@src/components/atoms/Icon';
import MenuButton from '@src/components/molecules/MenuButton';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useQueryHelper, useRedirectWithinIframe } from '@src/libs/hooks';
import REMOVE_SHOPIFY_ACCOUNT from './mutations/RemoveShopifyAccount.graphql';
import { RemoveShopifyAccount, RemoveShopifyAccountVariables } from './mutations/__generated__/RemoveShopifyAccount';
import GET_SHOPIFY_ACCOUNT from './queries/GetShopifyAccount.graphql';
import { GetShopifyAccount } from './queries/__generated__/GetShopifyAccount';
import { useRedirectUrl } from './useRedirectUrl';
import * as Styled from './Styled';

enum DialogStatus {
  CLOSE = 'CLOSE',
  CONNECT_SHOPIFY = 'CONNECT_SHOPIFY',
  DELETE_SHOPIFY = 'DELETE_SHOPIFY',
}

export const ShopifyChannel = () => {
  const [dialogStatus, setDialogStatus] = useState<DialogStatus>(DialogStatus.CLOSE);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [storeName, setStoreName] = useState<string>('');
  const { enqueueSnackbar, t } = useQueryHelper();
  const { startRedirectProcess } = useRedirectWithinIframe();
  const { isLoading, getShopifyRedirectUrl } = useRedirectUrl();

  const { styles } = Styled;

  const { data } = useQuery<GetShopifyAccount>(GET_SHOPIFY_ACCOUNT, {
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [removeShopifyAccount] = useMutation<RemoveShopifyAccount, RemoveShopifyAccountVariables>(
    REMOVE_SHOPIFY_ACCOUNT,
    {
      refetchQueries: ['GetShopifyAccount'],
      onCompleted: () => {
        enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
        setDialogStatus(DialogStatus.CLOSE);
      },
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  async function onClickConnectShopify() {
    const redirectUrl = await getShopifyRedirectUrl(storeName);
    if (redirectUrl) {
      localStorage.setItem('shopifyStoreName', storeName);
      startRedirectProcess({ redirectUrl, redirectType: FE_REDIRECT_MAPPING.SHOPIFY_CONNECT });
    }
  }

  function onClickDeleteShopify() {
    if (shopifyAccount) {
      setIsSubmitting(true);
      removeShopifyAccount({
        variables: {
          input: {
            id: shopifyAccount?.id,
          },
        },
      }).finally(() => setIsSubmitting(false));
    }
  }

  const shopifyAccount = data?.getShopifyAccount || null;

  return (
    <>
      <Popup
        contentStyle={{
          backgroundColor: '#fff',
          borderRadius: 3,
          maxWidth: 648,
          padding: 0,
          width: '90%',
        }}
        open={dialogStatus !== DialogStatus.CLOSE}
        onClose={() => setDialogStatus(DialogStatus.CLOSE)}
      >
        <div>
          <div css={styles.dialogCloseBtn} onClick={() => setDialogStatus(DialogStatus.CLOSE)}>
            <Icon color="#c5d0da" icon="close" />
          </div>

          {dialogStatus === DialogStatus.CONNECT_SHOPIFY ? (
            <>
              <Styled.DialogContent>
                <Styled.DialogHeader>{t('Title.Channel Connection')}</Styled.DialogHeader>
                <Styled.DialogSubHeader>{t('Annotation.Select Sales Channel')}</Styled.DialogSubHeader>
                <Styled.DialogImageContainer>
                  <img alt="shopify" height="200" src={shopifyImg} width="228" />
                </Styled.DialogImageContainer>
                <div>
                  <Styled.StyledTextForm
                    isRequired
                    placeholder="Store name"
                    title="Shopify Store Name"
                    value={storeName}
                    onChange={e => setStoreName(e.target.value)}
                  />
                </div>
              </Styled.DialogContent>

              <Styled.DialogAction>
                <Styled.StyledButton
                  bgcolor="#fff"
                  hoverbgcolor="#fff"
                  isFillAvailable
                  title="Cancel"
                  onClick={() => setDialogStatus(DialogStatus.CLOSE)}
                />
                <Styled.StyledButton
                  bgcolor="#3892e5"
                  disabled={!storeName || isLoading || isSubmitting}
                  hoverbgcolor="#3892e5"
                  isFillAvailable
                  isPrimary
                  title="Save"
                  onClick={onClickConnectShopify}
                />
              </Styled.DialogAction>
            </>
          ) : dialogStatus === DialogStatus.DELETE_SHOPIFY ? (
            <>
              <Styled.DialogContent>
                <Styled.DialogHeader>
                  <img alt="warning" height="24" src={warningRed} width="24" />
                  {t('Title.Delete Channel')}
                </Styled.DialogHeader>
                <Styled.DialogSubHeader>
                  {t('Annotation.Are you sure you want to delete Channel', { name: 'Shopify' })}
                </Styled.DialogSubHeader>
              </Styled.DialogContent>
              <Styled.DialogAction>
                <Styled.StyledButton
                  bgcolor="#fff"
                  hoverbgcolor="#fff"
                  isFillAvailable
                  title="Cancel"
                  onClick={() => setDialogStatus(DialogStatus.CLOSE)}
                />
                <Styled.StyledButton
                  bgcolor="#ff5f5f"
                  disabled={isSubmitting}
                  hoverbgcolor="#ff5f5f"
                  isError
                  isFillAvailable
                  title="Delete"
                  onClick={onClickDeleteShopify}
                />
              </Styled.DialogAction>
            </>
          ) : null}
        </div>
      </Popup>

      <div css={styles.channelContent}>
        <div>
          <img alt="shopify" height="24" src={shopifyIcon} width="21" />
          <span>Shopify</span>
        </div>
        {shopifyAccount ? (
          <div>
            <div css={styles.connectedIndicator}>
              <Icon icon="check" />
              {t('Button.Connected')}
            </div>
            <MenuButton options={[{ title: 'Delete', onClick: () => setDialogStatus(DialogStatus.DELETE_SHOPIFY) }]} />
          </div>
        ) : (
          <div>
            <Styled.StyledButton
              bgcolor="#3892e5"
              hoverbgcolor="#3892e5"
              isPrimary
              title="Connect"
              onClick={() => setDialogStatus(DialogStatus.CONNECT_SHOPIFY)}
            />
          </div>
        )}
      </div>
    </>
  );
};
