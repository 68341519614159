import React from 'react';
import { useQuery } from '@apollo/client';
import { useQueryHelper } from '../../libs/hooks';
import { CampaignPageType } from '../../libs/campaign';
import PageLoading from '../../components/molecules/PageLoading';
import Template from './template';
import * as ALL_SEARCH_JOBS from './AllSearchJobs.graphql';
import { AllSearchJobs } from './__generated__/AllSearchJobs';

const SearchPage = () => {
  const { t, enqueueSnackbar } = useQueryHelper();

  const { data, loading } = useQuery<AllSearchJobs>(ALL_SEARCH_JOBS, {
    fetchPolicy: 'cache-and-network',
    onError: error => {
      enqueueSnackbar(t(error.message || 'UnexpectedError'), { variant: 'error' });
    },
  });

  if (loading) {
    return <PageLoading isFullWidthTransparent />;
  }

  return <Template data={data?.allCampaignsForSearchJob} page={CampaignPageType.SEARCH} />;
};

export default SearchPage;
