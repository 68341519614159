import React, { forwardRef } from 'react';
import { Step, Stepper } from 'react-form-stepper';
import { ConnectorStyleProps } from 'react-form-stepper/dist/components/Connector/ConnectorTypes';
import { StepStyleDTO } from 'react-form-stepper/dist/components/Step/StepTypes';
import { StepperProps } from 'react-form-stepper/dist/components/Stepper/StepperTypes';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import stepperConnector from '@src/assets/img/stepperConnector.png';

interface ReactFormStepperProps extends StepperProps {
  activeColor?: string;
}

// ref are incompatible passing to styled component and no solutions to solve
const ReactFormStepper = forwardRef((props: ReactFormStepperProps, ref: any) => {
  const { activeColor = '#3892e5', connectorStyleConfig, styleConfig, ...restProps } = props;

  const connectorStyle = {
    style: 'none',
    ...connectorStyleConfig,
  } as ConnectorStyleProps;
  const style = {
    activeBgColor: activeColor,
    circleFontSize: '0px',
    completedBgColor: '#c5d0da',
    inactiveBgColor: '#c5d0da',
    fontWeight: '600',
    size: '15px',
    ...styleConfig,
  } as StepStyleDTO;

  return (
    <div css={styles.container}>
      <StyledStepper
        activeColor={activeColor}
        connectorStyleConfig={connectorStyle}
        ref={ref}
        styleConfig={style}
        {...restProps}
      />
    </div>
  );
});

const StyledStepper = styled(Stepper, { shouldForwardProp: prop => prop !== 'activeColor' })<ReactFormStepperProps>`
  & .RFS-StepContainer:nth-of-type(${({ activeStep }) => (activeStep || 0) + 1}) {
    & .RFS-Label {
      color: ${({ activeColor }) => activeColor};
    }
  }
`;

const styles = {
  container: css`
    width: 100%;

    & .RFS-StepperContainer {
      padding: 8px 0;
    }

    & .RFS-ConnectorContainer {
      background-image: url('${stepperConnector}');
      background-repeat: repeat-y;
      height: 2px;
      margin-left: 2px;
    }

    /* stylelint-disable no-descending-specificity */
    & .RFS-Label {
      color: #c5d0da;
      margin-top: 5px;
    }
    /* stylelint-enable no-descending-specificity */
  `,
};

export { Step };
export default ReactFormStepper;
