import styled from '@emotion/styled';
import TextCutter from '@src/components/atoms/TextCutter';
import { HeaderColumn } from '@src/components/atoms/List';

export const TagBox = styled.div`
  padding: 16px;
  border-top: 1px solid #dee5ec;
`;
export const TagHeading = styled.div`
  display: flex;
  align-items: center;
`;
export const TagIndex = styled.div`
  width: 24px;
  height: 24px;
  background: #c5d0da;
  text-align: center;
  color: #fff;
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;
`;
export const TagText = styled.p`
  font-size: 13px;
  margin-left: 8px;
`;
export const EngagmentsGrid = styled.div`
  display: grid;
  grid-template-columns: 1.4fr 1fr 1fr 1fr;
  grid-row-gap: 16px;
  margin-top: 16px;
`;
export const Label = styled(TextCutter)`
  font-size: 11px;
  color: #6e7c89;
`;
export const Value = styled.p`
  font-size: 11px;
  color: #27313b;
`;
export const StyledHeader = styled(HeaderColumn)`
  & > div {
    justify-content: flex-start;
  }
`;
export const TagWrapped = styled.div`
  display: flex;
  align-items: center;

  p {
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
export const TagNumberBlock = styled.div<{ isTopThree: boolean }>`
  display: flex;
  align-items: center;
  place-content: center;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background-color: ${({ isTopThree }) => (isTopThree ? '#3892E5' : '#c5d0da')};
  color: #fff;
  font-size: 16px;
  font-weight: 600;
`;
export const TagRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;
export const PostCountWrapped = styled.div`
  display: flex;
  align-items: center;
  color: #6e7c89;
  font-size: 11px;

  span {
    color: #27313b;
    margin-left: 8px;
    font-size: 13px;
  }
`;
