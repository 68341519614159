import React from 'react';
import styled from '@emotion/styled';
import { useIntercom } from 'react-use-intercom';
import { PalleteButton, SocialButton } from '../../../atoms/Button';
import { getCustomIcon } from '../../../../libs/getCustomIcon';
import { mainWhite } from '../../../../libs/pallete';
import { getEditPostPath, convertSocialMedia, getPostReportPath, isEditablePost } from '../utils';
import { EngagementCampaignPostStatus } from '../../../../__generated__/globalTypes';
import { getSocialMediaUrlByCampaignType } from '../../../../libs/campaign';
import { Post } from './types';

interface ActionButtonProps {
  post: Post;
  engagementId: number;
}
const ActionButtonBlock = (props: ActionButtonProps) => {
  const {
    post: { id, handleClickDelete, status, socialMedia },
    engagementId,
  } = props;
  const { show } = useIntercom();

  const renderEditDelete = () => {
    const editPostPath = getEditPostPath(engagementId, id);

    return (
      <>
        <StyledPalleteButton
          prefixIcon={<img src={getCustomIcon('edit')} alt="edit" />}
          text="Edit"
          href={editPostPath}
          palleteColor={{ ...mainWhite, borderColor: 'none' }}
          borderRadius="0"
          hasBorderRight
        />
        <StyledPalleteButton
          prefixIcon={<img src={getCustomIcon('delete')} alt="delete" />}
          text="Delete"
          palleteColor={{ ...mainWhite, borderColor: 'none' }}
          borderRadius="0"
          onClick={handleClickDelete}
        />
      </>
    );
  };

  const renderUploadPost = () => (
    <SocialButtonWrapper
      onClick={undefined}
      socialMedia={convertSocialMedia(socialMedia)}
      title="Upload Post"
      hasArrow
      href={getSocialMediaUrlByCampaignType(socialMedia)}
    />
  );

  const renderViewReportPost = () => (
    <ViewReportButton
      text="View report"
      palleteColor={mainWhite}
      height="32px"
      href={getPostReportPath(engagementId)}
    />
  );

  const renderContact = () => <ViewReportButton text="Contact" palleteColor={mainWhite} height="32px" onClick={show} />;

  return (
    <ButtonBlock>
      {isEditablePost(status) && renderEditDelete()}
      {status === EngagementCampaignPostStatus.READY_TO_POST && renderUploadPost()}
      {status === EngagementCampaignPostStatus.WARNING && renderContact()}
      {[EngagementCampaignPostStatus.EFFECTIVE, EngagementCampaignPostStatus.APPROVED].includes(status) &&
        renderViewReportPost()}
    </ButtonBlock>
  );
};

const ButtonBlock = styled.div`
  display: flex;
  border-top: 1px solid #dee5ec;
`;
const StyledPalleteButton = styled(PalleteButton)<{ hasBorderRight?: boolean }>`
  ${({ hasBorderRight }) =>
    hasBorderRight
      ? 'border-right: 1px solid #dee5ec; border-bottom-left-radius: 5px'
      : 'border-bottom-right-radius: 5px'};

  &:hover {
    opacity: 0.7;
  }
`;
const ViewReportButton = styled(PalleteButton)`
  margin: 12px 16px;
`;
const SocialButtonWrapper = styled(SocialButton)`
  --arrowLeft: 20px;
  --arrowWidth: 16px;

  height: 32px;
  line-height: 32px;
  border-radius: 20px;
  justify-content: center;
  position: relative;
  margin: 12px 16px;

  img {
    position: absolute;
    left: var(--arrowLeft);
    padding-left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: var(--arrowWidth);
  }

  span {
    text-transform: uppercase;
    font-weight: 500;
    padding-left: calc(var(--arrowLeft) + var(--arrowWidth));
  }
`;

export default ActionButtonBlock;
