import {
  CampaignSocialMediaType,
  EngagementCampaignPostStatus,
  SocialAccountType,
} from '../../../../__generated__/globalTypes';
import { ALL_SOCIAL_MEDIAS } from '../../../../libs/constant';
import { FormValues } from './types';
import { EngagementPostForInfluencer_engagementPostForInfluencerV2 } from './__generated__/EngagementPostForInfluencer';

const disabledFields = ['socialMedia', 'planedPostDate', 'content', 'materials'];

type DisabledFields = { [x in keyof FormValues]: boolean };

const arrayToObject = (arr: string[], val: boolean): DisabledFields => {
  const arrOfObj = arr.map(item => ({ [item]: val }));
  const result: DisabledFields = Object.assign({}, ...arrOfObj);

  return result;
};

export const getDisabledFields = (status?: EngagementCampaignPostStatus): DisabledFields => {
  const allEnabled = {
    planedPostDate: false,
    content: false,
    materials: false,
    materialsUrl: false,
    socialAccountId: false,
    socialAccountName: false,
    socialAccountMedia: false,
    socialMedia: false,
    igSocialMedia: false,
  };

  switch (status) {
    case undefined:
    case EngagementCampaignPostStatus.REVIEWING:
    case EngagementCampaignPostStatus.REJECT:
      return allEnabled;
    default:
      return {
        ...allEnabled,
        ...arrayToObject(disabledFields, true),
      };
  }
};

export const getSocialMedias = (socialMedias: CampaignSocialMediaType[]) =>
  ALL_SOCIAL_MEDIAS.filter(media => socialMedias.includes(media.value));

export const UPLOAD_VIDEO_TYPES = 'AVI, MP4, MOV';
export const UPLOAD_IMAGE_TYPES = 'PNG, GIF, JPEG';

export const IMAGE_MIME = 'image/jpeg, image/png, image/gif';
export const VIDEO_MIME = 'video/avi, video/mp4, video/quicktime';

export const getDefaultSocialMediaName = (name: string, accountType: SocialAccountType) => {
  if (name) {
    return name;
  }

  switch (accountType) {
    case SocialAccountType.FACEBOOK:
      return 'Facebook';
    case SocialAccountType.FACEBOOK_PAGE:
      return 'Facebook Page';
    case SocialAccountType.INSTAGRAM:
      return 'Instagram';
    case SocialAccountType.YOUTUBE:
      return 'YouTube';
    case SocialAccountType.TWITTER:
      return 'Twitter';

    default:
      return '';
  }
};

export const getIgDefaultSocialMedia = (
  engagementPost: EngagementPostForInfluencer_engagementPostForInfluencerV2 | null
) => (engagementPost?.socialAccountMedia === SocialAccountType.INSTAGRAM ? engagementPost.socialMedia : '');
