import styled from '@emotion/styled';
import * as React from 'react';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import { useTranslation } from 'react-i18next';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { formatDate } from '../../../../libs/format';
import Label from '../Label/Label';

interface CampaignPeriodProps {
  start: string;
  end: string;
  title: string;
  className?: string;
}

const CampaignPeriod = (props: CampaignPeriodProps) => {
  const { i18n } = useTranslation();
  const { start, end, title, className } = props;

  return (
    <Wrapper className={className}>
      <Label title={title} />
      <Container>
        <Data>
          {localizedDateFormatter(start, 'MMM dd', i18n.language as AppLanguage)}
          <br />
          <Year>{formatDate(start, 'yyyy')}</Year>
        </Data>
        <Hyphen />
        <Data>
          {localizedDateFormatter(end, 'MMM dd', i18n.language as AppLanguage)}
          <br />
          <Year>{formatDate(end, 'yyyy')}</Year>
        </Data>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Data = styled.p`
  font-size: 12px;
  font-weight: 600;
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #dee5ec;
  color: #27313b;
  background-color: #fff;
  height: 48px;
  width: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: column;
`;

const Year = styled.span`
  text-align: center;
  font-size: 10px;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6e7c89;
  margin-top: 4px;
`;

const Hyphen = styled.span`
  width: 6px;
  height: 2px;
  margin: 0 4px;
  background-color: #27313b;
`;

export default CampaignPeriod;
