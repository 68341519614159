import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import InstagramInstructure from '@src/components/organisms/SignUpInstagram/InstagramInstruction';
import Footer from '@src/components/organisms/TopPage/Footer';
import Header from '@src/components/organisms/TopPage/Header';
import { useRedirectUrl } from '@src/libs/hooks/useRedirectUrl';
import { ConnectType } from '@src/libs/auth';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { usePageLayout, useQueryHelper, useRedirectWithinIframe } from '@src/libs/hooks';
import { SignUpAndSignInBreakpoint } from '@src/libs/theme';
import { SocialAccountType } from '@src/__generated__/globalTypes';

const SignUpInstagram = () => {
  const [redirectUrlFacebook, setRedirectUrlFacebook] = useState<string | null>(null);
  const { getRedirectUrl, loading } = useRedirectUrl(FE_REDIRECT_MAPPING.SIGNUP_ENABLED);
  const { startRedirectProcess } = useRedirectWithinIframe();
  const { enqueueSnackbar, t } = useQueryHelper();
  const { isMobileViewPublicRoutes } = usePageLayout();

  // Fetch redirectUri for Facebook beforehand, because of Facebook policy.
  // https://adasiaholdings.atlassian.net/browse/CAS-1143
  const getRedirectUrlFacebook = async () => {
    const redirectUrl = await getRedirectUrl(SocialAccountType.FACEBOOK);
    setRedirectUrlFacebook(redirectUrl);
  };

  useEffect(() => {
    getRedirectUrlFacebook();
  }, []);

  const onClickInstagramSignUp = () => {
    try {
      if (redirectUrlFacebook) {
        // Set connectType for instagram provider
        localStorage.setItem('connectType', ConnectType.CONNECT_IG_ACCOUNT);
        // Redirect to SocialMedia
        startRedirectProcess({ redirectUrl: redirectUrlFacebook, redirectType: FE_REDIRECT_MAPPING.SIGNUP_ENABLED });
      }
    } catch (error) {
      console.error('error: ', error);
      enqueueSnackbar(t('UnexpectedError'), { variant: 'error' });
    }

    return;
  };

  return (
    <div css={styles.container}>
      <div css={styles.content}>
        {!isMobileViewPublicRoutes && <Header href="/" />}
        <div css={styles.instructionContainer}>
          <InstagramInstructure backUrl="/signup" disabled={loading} onClick={onClickInstagramSignUp} />
        </div>
        {!isMobileViewPublicRoutes && <Footer />}
      </div>
    </div>
  );
};

const styles = {
  container: css`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;

    @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
      align-items: flex-start;
    }
  `,
  content: css`
    max-width: 856px;
    width: 100%;
  `,
  instructionContainer: css`
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px #dee5ec, 0 1px 4px rgba(222, 229, 236, 0.5);
    min-height: 520px;
    width: 100%;

    @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
      box-shadow: none;
    }
  `,
};

export default SignUpInstagram;
