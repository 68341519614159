import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import warningRed from '@src/assets/icon/warningRed.svg';

interface ReasonWidgetProps {
  content: string;
  title: string;
}

const ReasonWidget = ({ content, title }: ReasonWidgetProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.container}>
      <div>
        <img alt="icon" height="18" src={warningRed} width="18" />
      </div>
      <div>
        <div>{t(title)}</div>
        {/* the content is enter by user so it is dynamic and no need for translation */}
        <div>{content}</div>
      </div>
    </div>
  );
};

const styles = {
  container: css`
    border-left: 3px solid #ff5f5f;
    border-radius: 3px;
    box-shadow: 0 1px 5px rgba(39, 49, 59, 0.2);
    display: flex;
    flex-wrap: wrap;
    padding: 16px;
    width: 100%;

    & > div:nth-of-type(2) {
      display: grid;
      flex-basis: 88%;
      margin-left: 8px;

      & > div:nth-of-type(1) {
        color: #27313b;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      & > div:nth-of-type(2) {
        color: #6e7c89;
        font-size: 13px;
      }
    }
  `,
};

export default ReasonWidget;
