import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { useQuery } from '@apollo/client';
import { ListIndicator } from '../../components/molecules/Indicator';
import { CampaignDetailProps } from '../../components/organisms/Campaign/CampaignDetail';
import Empty from '../../components/atoms/Empty';
import { CampaignPageType } from '../../libs/campaign';
import { useAuthData } from '../../libs/hooks';
import { MarketplaceForInfluencer, MarketplaceForInfluencerVariables } from './__generated__/MarketplaceForInfluencer';
import * as MARKETPLACE_FOR_INFLUENCER from './MarketplaceForInfluencer.graphql';
import Template from './template';

interface MarketplaceCampaignDetailProps extends RouteComponentProps<{ id: string }> {
  page: CampaignPageType;
}
const MarketplaceCampaignDetail = (props: MarketplaceCampaignDetailProps) => {
  const {
    match: {
      params: { id },
    },
    page,
  } = props;
  const { userId } = useAuthData();

  const { data, loading } = useQuery<MarketplaceForInfluencer, MarketplaceForInfluencerVariables>(
    MARKETPLACE_FOR_INFLUENCER,
    {
      variables: { pk: Number(id), userId: Number(userId) },
      fetchPolicy: 'cache-and-network',
    }
  );

  if (loading) {
    return <ListIndicator />;
  }

  if (!data || !data.marketplaceForInfluencer) {
    return <Empty />;
  }

  const {
    title,
    serviceInformation,
    thumbNail,
    type,
    startDate,
    endDate,
    status,
    currency,
    requirement,
    socialMedias,
    totalAmount,
    remainingAmount,
    hashtags,
    revenuePerPost,
    revenuePerLike,
    revenuePerView,
    revenuePerShare,
    revenuePerComment,
    revenuePerFollower,
    revenuePerClick,
    revenuePerAction,
    trackingUrl,
    clickUrl,
    isJoined,
    maximumRevenuePerInfluencer,
    minimumPaymentAmount,
    postStatus,
    hasReport,
    materialUrl,
    selectionStartDate,
    selectionEndDate,
    campaignType,
    isApplied,
    appliedStatus,
  } = data.marketplaceForInfluencer;

  const materials = !data.marketplaceForInfluencer.materials
    ? []
    : data.marketplaceForInfluencer.materials
        .map(material => (!!material ? material.url : null))
        .filter((material): material is string => !!material);

  const campaignDetail: CampaignDetailProps = {
    title,
    serviceInformation,
    thumbNail,
    campaignType: type,
    startDate,
    endDate,
    campaignStatus: status,
    currency,
    requirements: requirement,
    socialMedias,
    materials,
    totalAmount,
    remainingAmount,
    hashtags,
    revenuePerPost,
    revenuePerLike,
    revenuePerView,
    revenuePerShare,
    revenuePerComment,
    revenuePerFollower,
    revenuePerClick,
    revenuePerAction,
    trackingUrl,
    productUrl: clickUrl,
    isJoined,
    campaignId: Number(id),
    maximumRevenuePerInfluencer,
    materialUrl,
    selectionStartDate,
    selectionEndDate,
    marketplaceCampaignType: campaignType,
    isApplied,
    appliedStatus,
    postStatus,
  };

  const joinButtonProps = {
    maximumRevenuePerInfluencer,
    minimumPaymentAmount,
    postStatus,
    influencerProfile: data.influencerProfileV2,
    hasReport,
  };

  return <Template {...campaignDetail} joinButtonProps={joinButtonProps} page={page} />;
};

export default MarketplaceCampaignDetail;
