import { useTranslation } from 'react-i18next';

export const useLanguageLinkSpecifier = (): string => {
  const { i18n } = useTranslation();
  const languages: { [l: string]: string } = {
    ja: 'ja',
    th: 'th',
    ch: 'ch',
    id: 'id',
    km: 'km',
    my: 'my',
    tw: 'tw',
    vi: 'vi',
  };

  return languages[i18n.language] || '';
};
