import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Avatar from '@src/components/atoms/Avatar';
import { Button, SocialButton } from '@src/components/atoms/Button';
import { formatNumberWithCommas } from '@src/libs/format';
import { defaultInfluencerAvatar } from '@src/libs/image';
import { switchImage, switchText } from '@src/libs/SocialMedia';
import { SocialAccountType } from '@src/__generated__/globalTypes';

interface SocialAccountConnectorProps {
  accounts: {
    avatar: string | null;
    followersCount: number | null;
    id: string;
    isBusiness?: boolean | null;
    name: string;
    url: string | null;
  }[];
  socialMedia: SocialAccountType;
  onClickConnect: () => void;
  onClickReconnect?: () => void;
}

const SocialAccountConnector = ({
  accounts,
  socialMedia,
  onClickConnect,
  onClickReconnect,
}: SocialAccountConnectorProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.container}>
      {accounts.length > 0 ? (
        <>
          {accounts.map(account => {
            const { avatar, id, isBusiness, followersCount, name } = account;

            return (
              <div css={styles.connectedSocialAccountContent} key={`${id}_${account.name}`}>
                <div>
                  <img alt="socialMedia" height="24" src={switchImage(socialMedia, true)} width="24" />
                  <Avatar size={24} src={defaultInfluencerAvatar(avatar)} />
                </div>
                <div>
                  <div>{name}</div>
                  <div>{`${formatNumberWithCommas(followersCount, 0)} ${t('Followers')}`}</div>
                </div>
                {socialMedia === SocialAccountType.INSTAGRAM && !isBusiness && (
                  <div>
                    <Button
                      bgcolor="#fff"
                      css={styles.reconnectBtn}
                      hoverbgcolor="#fff"
                      title="Reconnect"
                      onClick={onClickReconnect}
                    />
                  </div>
                )}
              </div>
            );
          })}
          <div css={styles.addAccountContainer}>
            <StyledSocialButton light socialMedia={socialMedia} title="Add Account" onClick={onClickConnect} />
          </div>
        </>
      ) : (
        <div css={styles.socialAccountContent}>
          <div>
            <img alt="socialMedia" height="24" src={switchImage(socialMedia, true)} width="24" />
            <span>{switchText(socialMedia)}</span>
          </div>
          <div>
            <StyledSocialButton isConnect light socialMedia={socialMedia} title="Connect" onClick={onClickConnect} />
          </div>
        </div>
      )}
    </div>
  );
};

const StyledSocialButton = styled(SocialButton)<{ isConnect?: boolean }>`
  border-radius: 5px;
  height: 32px;
  justify-content: flex-end;
  margin-top: 0;
  padding-right: 16px;
  width: ${({ isConnect }) => (isConnect ? '102px' : '128px')};

  & > img {
    height: 16px;
    width: 16px;
  }

  & > span {
    font-size: 12px;
    font-weight: 600;
  }
`;

const styles = {
  addAccountContainer: css`
    padding: 16px;
  `,
  connectedSocialAccountContent: css`
    align-items: center;
    border-bottom: 1px solid #eef3f7;
    display: flex;
    flex-wrap: wrap;
    padding: 16px;

    & > div:nth-of-type(1) {
      display: flex;
      flex-wrap: wrap;
      margin-right: 16px;

      & > img:nth-of-type(2) {
        margin-left: -4px;
      }
    }

    & > div:nth-of-type(2) {
      & > div:nth-of-type(1) {
        color: #27313b;
        font-size: 14px;
        font-weight: 600;
      }

      & > div:nth-of-type(2) {
        color: #6e7c89;
        font-size: 12px;
      }
    }

    /* stylelint-disable no-descending-specificity */
    & > div:nth-of-type(3) {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
    }
  `,
  container: css`
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    margin-bottom: 16px;
  `,
  reconnectBtn: css`
    background-image: linear-gradient(116.92deg, #f9bb67 22.14%, #da337e 90.57%);
    border-radius: 5px;
    height: 32px;
    padding: 0 16px;
    width: fit-content;
  `,
  socialAccountContent: css`
    display: flex;
    flex-wrap: wrap;
    padding: 16px;

    & > div:nth-of-type(1) {
      align-items: center;
      display: flex;
      flex-wrap: wrap;

      & > span {
        color: #27313b;
        font-size: 14px;
        font-weight: 600;
        margin-left: 16px;
      }
    }

    & > div:nth-of-type(2) {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
    }
  `,
};

export default SocialAccountConnector;
