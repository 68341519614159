import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatPercent } from '@src/libs/format';
import DataOpacityWrapper, { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import HighChart from '@src/components/molecules/HighChart';
import { InfluencerProfileAudience_influencerProfileAudience_ageGenderRates_ageGroup } from './__generated__/InfluencerProfileAudience';

interface AgeChartProps {
  ageGroup?: InfluencerProfileAudience_influencerProfileAudience_ageGenderRates_ageGroup[] | null;
  maleRate?: number[] | null;
  femaleRate?: number[] | null;
  className?: string;
}
const AgeChart = ({ ageGroup, maleRate, femaleRate, className }: AgeChartProps) => {
  const { t } = useTranslation();

  const options: Highcharts.Options = {
    chart: {
      type: 'bar',
      height: 300,
      spacingBottom: 0,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: ageGroup?.map(item => `${item.start}-${item.end}`) || [],
    },
    yAxis: {
      min: 0,
      title: {
        text: '',
      },
      labels: {
        formatter: function formatter() {
          return formatPercent(this.value, true, 0);
        },
      },
    },
    legend: {
      reversed: true,
      symbolHeight: 15,
      symbolWidth: 15,
      symbolRadius: 2,
      itemMarginBottom: 6,
      itemStyle: {
        fontSize: '13px',
        color: '#27313b',
        fontWeight: 'normal',
      },
    },
    plotOptions: {
      series: {
        stacking: 'normal',
      },
    },
    series: [
      {
        name: t('Male'),
        data: maleRate || [],
        color: '#707fc0',
        type: 'bar',
      },
      {
        name: t('Female'),
        data: femaleRate || [],
        color: '#ff8a94',
        type: 'bar',
      },
    ],
    tooltip: {
      formatter: function formatter(): string {
        return `
          <div style="border-radius:3px;display:flex;flex-direction:column;padding:13px 16px">
            <p style="margin-bottom:8px;">${this.x}</p>  
            <div style="display:flex;align-items:center">
              <span style="display:inline-block;margin-right:8px;background-color:${
                this.point.color
              };height:12px;width:12px"></span>    
              <span style="margin-right:16px">${this.series.name}:</span>
              <span>${formatPercent(this.point.y)}</span>
            </div>
          </div>`;
      },
      padding: 0,
      borderColor: 'green',
      borderWidth: 0,
      borderRadius: 3,
      backgroundColor: '#27313b',
      style: {
        color: '#ffffff',
      },
      useHTML: true,
    },
  };

  return (
    <DataOpacityWrapper
      header={<SectionHeader>{t('BasicInfo.Age')}</SectionHeader>}
      dataNotAvailable={!femaleRate?.length && !maleRate?.length}
      className={className}
    >
      <HighChart options={options} />
    </DataOpacityWrapper>
  );
};

export default AgeChart;
