import * as React from 'react';
import SignUpForm from '@src/components/organisms/SignUpForm';
import TopPageContainer from '@src/components/organisms/TopPage/TopPageContainer/index_backup';

const Template = () => (
  <TopPageContainer>
    <SignUpForm />
  </TopPageContainer>
);

export default Template;
