import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useQueryHelper, useRedirectWithinIframe } from '../../../libs/hooks';
import { SocialAccountType } from '../../../__generated__/globalTypes';
import { useRedirectUrl } from '../../../libs/hooks/useRedirectUrl';
import BaseSignUpForm from './SignUpForm';
import UuumSignUpForm from './UuumSignUpForm';

const SignUpForm = () => {
  const { getRedirectUrl, loading: mutationLoading } = useRedirectUrl(FE_REDIRECT_MAPPING.SIGNUP_ENABLED);
  const [loading, setLoading] = useState<boolean>(false);
  const [redirectUrlFacebook, setRedirectUrlFacebook] = useState<string | null>(null);
  const { enqueueSnackbar, t } = useQueryHelper();
  const { startRedirectProcess, isWithinIframe } = useRedirectWithinIframe();

  // Get Hash for signup with specific talent Agency, or just reset potential previous hash.
  const { search } = useLocation();
  const agencyHash = new URLSearchParams(search).get('hash');
  const agencyType = new URLSearchParams(search).get('agencyType');
  if (!!agencyHash && !!agencyType) {
    localStorage.setItem('agencyHash', String(agencyHash));
    localStorage.setItem('agencyType', String(agencyType));
  } else {
    localStorage.removeItem('agencyHash');
    localStorage.removeItem('agencyType');
  }

  // Fetch redirectUri for Facebook beforehand, because of Facebook policy.
  // https://adasiaholdings.atlassian.net/browse/CAS-1143
  const getRedirectUrlFacebook = async () => {
    const redirectUrl = await getRedirectUrl(SocialAccountType.FACEBOOK);
    setRedirectUrlFacebook(redirectUrl);
  };
  useEffect(() => {
    getRedirectUrlFacebook();
  }, []);

  const onClick = (socialMedia: SocialAccountType) => async () => {
    setLoading(true);

    // Set RedirectType for Twitter OAuth
    if (socialMedia === SocialAccountType.TWITTER) {
      localStorage.setItem('redirectType', FE_REDIRECT_MAPPING.SIGNUP_ENABLED);
    }

    try {
      const redirectUrl =
        socialMedia === SocialAccountType.FACEBOOK ? redirectUrlFacebook : await getRedirectUrl(socialMedia);

      if (redirectUrl) {
        // Redirect to SocialMedia
        startRedirectProcess({ redirectUrl, redirectType: FE_REDIRECT_MAPPING.SIGNUP_ENABLED });
      }
    } catch (error) {
      console.error('error: ', error);
      enqueueSnackbar(t('UnexpectedError'), { variant: 'error' });
    } finally {
      setLoading(false);
    }

    return;
  };

  return isWithinIframe ? (
    <UuumSignUpForm onClick={onClick} loading={loading || mutationLoading} />
  ) : (
    <BaseSignUpForm onClick={onClick} loading={loading || mutationLoading} />
  );
};

export default SignUpForm;
