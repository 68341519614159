import React from 'react';
import { useQuery } from '@apollo/client';
import * as ALL_CAMPAIGNS_FOR_YOUR_JOB from '../../libs/queries/AllCampaignsForYourJob.graphql';
import {
  AllCampaignsForYourJob,
  AllCampaignsForYourJobVariables,
} from '../../libs/queries/__generated__/AllCampaignsForYourJob';
import { ListIndicator } from '../../components/molecules/Indicator';
import { CampaignPageType } from '../../libs/campaign';
import Template from './template';

const YourJob = () => {
  const variables = {
    isFinished: false,
  };

  const { data, loading } = useQuery<AllCampaignsForYourJob, AllCampaignsForYourJobVariables>(
    ALL_CAMPAIGNS_FOR_YOUR_JOB,
    {
      variables,
      fetchPolicy: 'cache-and-network',
    }
  );

  if (loading) {
    return <ListIndicator />;
  }

  return (
    <Template
      data={data?.allCampaignsForYourJob}
      showCampaignBadge
      page={CampaignPageType.JOB}
      totalCount={data?.allCampaignsForYourJobCounts}
    />
  );
};

export default YourJob;
