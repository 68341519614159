import styled from '@emotion/styled';
import { ViewportBreakpoint } from '@src/libs/theme';
import TextCutter from '@src/components/atoms/TextCutter';

export const MainContainer = styled.div`
  margin-bottom: 16px;

  @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
    margin-top: 16px;
  }
`;

export const EngagementContainer = styled.div``;

export const Engagement = styled.div`
  display: flex;
  align-items: flex-start;

  div:not(:last-of-type) {
    border-right: 1px solid #dee5ec;
  }
`;

export const Icon = styled.img`
  width: 22px;
  margin-right: 12px;
`;

export const Wrapper = styled.div`
  padding: 0 8px;
`;

export const Value = styled.h6`
  font-weight: 600;
  font-size: 13px;
  color: #27313b;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Unit = styled(TextCutter)`
  margin-top: 1px;
  color: #6e7c89;
  font-size: 10px;
`;

export const CountWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
  row-gap: 12px;
`;

export const Anchor = styled.a`
  margin-left: 17px;
  color: #179cd7;
  font-size: 12px;
  font-weight: normal;

  &:hover {
    text-decoration: underline;
  }
`;
export const Title = styled.h6`
  display: flex;
  margin-bottom: 8px;
  color: #27313b;
  font-size: 12px;
  font-weight: 600;
`;
