import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { SliderComponents, SliderCss, StyledComponents } from '@src/components/molecules/SliderTable';
import { AmountColumn, DateColumn, HeaderColumn } from '@src/components/atoms/List';
import { ORDER, FacebookPageAnalyticsPostsSortOrder } from '@src/__generated__/globalTypes';
import { SortActionType } from '@src/libs/filter';
import { formatIntNumber, formatPercent } from '@src/libs/format';
import { usePostDetailModal } from '@src/components/organisms/AnalyticsShared/BasePostList/usePostDetailModal';
import { EmptyTable } from '@src/components/molecules/Table';
import CarouselModal from '@src/components/molecules/CarouselModal/CarouselModal';
import PostColumn from '@src/components/organisms/AnalyticsShared/PostColumn';
import FacebookPageAnalyticsFeedPostDetail from '@src/components/organisms/AnalyticsFacebookPage/FacebookPageAnalyticsFeedPostDetail';
import { useMediaQuery } from 'react-responsive';
import { ViewportBreakpoint } from '@src/libs/theme';
import Image from '@src/components/atoms/Image';
import TextCutter from '@src/components/atoms/TextCutter';
import { useQueryHelper } from '@src/libs/hooks';
import { FacebookPagePosts } from '@src/components/organisms/AnalyticsFacebookPage/types';
import { getFacebookPageFeedListColumns, getPostMetricsByOrderField } from './helpers';

interface UserAnalysisType extends SortActionType<FacebookPageAnalyticsPostsSortOrder> {
  posts?: FacebookPagePosts[];
  firstIndex: number;
  socialMediaAccountId: number;
  isInfluencerPage?: boolean;
  influencerAccountId?: number;
  accountName?: string;
  avatarUrl?: string;
}
const FeedPostsTable = (props: UserAnalysisType) => {
  const {
    posts,
    sort: { field, order },
    setSort,
    socialMediaAccountId,
    influencerAccountId,
    accountName,
    avatarUrl,
  } = props;
  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev,
  } = usePostDetailModal(posts || null);
  const showColumnView = useMediaQuery({ maxWidth: ViewportBreakpoint.MEDIUM });
  const { t } = useQueryHelper();

  if (!posts?.length) {
    return <EmptyTable colSpan={14} css={styles.emptyTable} />;
  }

  const allEngagementMetrics = getFacebookPageFeedListColumns();

  if (showColumnView) {
    return (
      <>
        {posts.map((post, i) => (
          <PostWrapper key={post.id} css={i === 0 ? { borderTop: 'none' } : ''}>
            <PostHeader onClick={() => handleClickPost(post.id)}>
              <Image src={post.image} width="92px" height="52px" />
              <PostContent text={post.content} lines={3} />
            </PostHeader>
            <PostInfo>
              {allEngagementMetrics.map(metric => {
                const metricKey = getPostMetricsByOrderField(metric.orderField);
                const isPercetageValue = metricKey?.includes('Rate');
                if (metricKey === null) {
                  return null;
                }

                return (
                  <Info key={metric.orderField}>
                    <Unit text={t(metric.title)} lines={1} />
                    <Value>
                      {isPercetageValue ? formatPercent(post[metricKey]) : formatIntNumber(post[metricKey])}
                    </Value>
                  </Info>
                );
              })}
            </PostInfo>
          </PostWrapper>
        ))}
      </>
    );
  }

  return (
    <SliderComponents.Container>
      <SliderComponents.SliderSection>
        <SliderComponents.SliderTable>
          <thead>
            <SliderComponents.HeaderRow css={styles.headerRow}>
              <SliderComponents.StickyHeaderTdWrapper>
                <HeaderColumn title="Posts" css={styles.postsHeader} isTable={false} />
              </SliderComponents.StickyHeaderTdWrapper>
              {/* Slider */}
              {allEngagementMetrics.map(({ title, orderField }) => (
                <HeaderColumn
                  key={title}
                  title={title}
                  {...(orderField && {
                    activeAsc: field === orderField && order === ORDER.ASC,
                    activeDesc: field === orderField && order === ORDER.DESC,
                    sortAsc: () => setSort({ field: orderField, order: ORDER.ASC }),
                    sortDesc: () => setSort({ field: orderField, order: ORDER.DESC }),
                  })}
                  css={styles.sliderTableColsHeaders}
                />
              ))}
            </SliderComponents.HeaderRow>
          </thead>

          <tbody>
            {posts?.map(post => {
              const {
                id,
                postDate,
                views,
                reactions,
                comments,
                shares,
                engagement,
                adImpressions,
                positiveRate,
                negativeRate,
                content,
                image,
              } = post;

              return (
                <StyledComponents.StyledRowNew css={[SliderCss.paddingSides]} key={id}>
                  <SliderComponents.StickyBodyRowWrapper css={styles.postsColumn}>
                    <PostColumn key={id} id={id} src={image} title={content} onClick={() => handleClickPost(id)} />
                  </SliderComponents.StickyBodyRowWrapper>
                  <StyledComponents.NumberColumn data={formatIntNumber(adImpressions)} />
                  <StyledComponents.NumberColumn data={formatIntNumber(views)} />
                  <StyledComponents.NumberColumn data={formatIntNumber(engagement)} />
                  <StyledComponents.NumberColumn data={formatIntNumber(reactions)} />
                  <StyledComponents.NumberColumn data={formatIntNumber(comments)} />
                  <AmountColumn data={formatPercent(positiveRate, false)} currency="%" />
                  <AmountColumn data={formatPercent(negativeRate, false)} currency="%" />
                  <StyledComponents.NumberColumn data={formatIntNumber(shares)} />
                  <DateColumn date={postDate} />
                </StyledComponents.StyledRowNew>
              );
            })}
          </tbody>
        </SliderComponents.SliderTable>
      </SliderComponents.SliderSection>
      <CarouselModal
        open={!!openPostDetail}
        title=""
        cancel="OK"
        onClose={handleClosePostDetail}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        siblingPostId={siblingPostId}
      >
        <FacebookPageAnalyticsFeedPostDetail
          socialMediaAccountId={socialMediaAccountId}
          closeModal={handleClosePostDetail}
          influencerAccountId={influencerAccountId}
          accountName={accountName}
          avatarUrl={avatarUrl}
        />
      </CarouselModal>
    </SliderComponents.Container>
  );
};

const styles = {
  emptyTable: css`
    margin: 0;
    border: 0;
  `,
  headerRow: css`
    background-color: #f6f8fa;
    height: 48px;
  `,
  postsHeader: css`
    width: 230px;
    padding: 0;

    & > div {
      justify-content: flex-start;
    }
  `,
  sliderTableColsHeaders: css`
    text-transform: capitalize;
  `,
  postsColumn: css`
    max-width: 200px;
  `,
};

const PostInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25%);
  margin-top: 16px;
`;

const Info = styled.div`
  padding: 4px 0;
`;

const Value = styled.p`
  font-size: 13px;
  color: #27313b;
`;
const Unit = styled(TextCutter)`
  font-size: 11px;
  line-height: 1.3;
  letter-spacing: normal;
  color: #6e7c89;
`;
const PostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;
  border-top: solid 1px #dee5ec;
`;

const PostHeader = styled.div`
  width: 100%;
  display: inline-flex;
  cursor: pointer;
`;

const PostContent = styled(TextCutter)`
  flex: 1;
  margin-left: 12px;
  font-size: 14px;
  line-height: 18px;
  color: #3892e5;

  &:hover {
    text-decoration: underline;
  }
`;
export default FeedPostsTable;
