import React from 'react';
import TextForm from '@src/components/molecules/TextForm';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import questionIcon from '@src/assets/icon/questionMark.svg';
import { FormikContextType } from 'formik';
import { useHelpCenterUrl } from '@src/libs/help';

interface FormValues {
  lineChannelId: string;
  lineChannelSecret: string;
}
const Step1 = ({ handleSubmit, values, handleChange, errors }: FormikContextType<FormValues>) => {
  const { t } = useTranslation();
  const { lineConnectHelp } = useHelpCenterUrl();

  return (
    <form onSubmit={handleSubmit}>
      <TextForm
        name="lineChannelId"
        title="LINE Channel ID"
        value={values.lineChannelId}
        isRequired
        onChange={handleChange}
        placeholder={t('TextForm.LINE Channel ID')}
        error={!!errors.lineChannelId}
      />
      {errors.lineChannelSecret && <ErrorMessage message={t(errors.lineChannelSecret)} />}
      <TextForm
        name="lineChannelSecret"
        title="LINE Channel secret"
        value={values.lineChannelSecret}
        isRequired
        onChange={handleChange}
        placeholder={t('TextForm.LINE Channel secret')}
        error={!!errors.lineChannelSecret}
        css={styles.channelSecret}
      />
      {errors.lineChannelSecret && <ErrorMessage message={t(errors.lineChannelSecret)} />}
      <div css={styles.descriptionWrapper}>
        <p css={styles.description}>
          {t('Please attach “Line Official Account” & “LINE Developers” in order to input LINE Channel ID/Secret')}
        </p>
        <div css={styles.helpLinkWrapper}>
          <img src={questionIcon} width={16} height={16} alt="question mark" />
          <a href={lineConnectHelp} rel="noopener noreferrer" target="_blank" css={styles.helpLink}>
            {t('How to check LINE Channel ID & LINE Channel secret?')}
          </a>
        </div>
      </div>
    </form>
  );
};
export default Step1;

const styles = {
  channelSecret: css`
    margin-top: 24px;
  `,
  descriptionWrapper: css`
    margin: 32px 0 24px;
  `,
  description: css`
    font-size: 12px;
    line-height: 140%;
    color: #27313b;
  `,
  helpLinkWrapper: css`
    display: flex;
    align-items: center;
    margin-top: 8px;

    img {
      margin-right: 8px;
    }
  `,
  helpLink: css`
    color: #3892e5;

    &:hover {
      text-decoration: underline;
    }
  `,
};
