import React, { useEffect } from 'react';
import { secondsToMilliseconds } from 'date-fns';
import { css } from '@emotion/react';
import TextCutter from '@src/components/atoms/TextCutter';
import { NavLink } from 'react-router-dom';
import { dynamicTitleState, selectedChatState, useSetRecoilState } from '@src/recoilAtoms';
import { useQueryHelper } from '@src/libs/hooks';
import ChatAvatar from '../ChatAvatar';
import useFormatChatTime from '../hooks/useFormatChatTime';

export const enum ChatType {
  Line = 'LINE',
}
export interface ChatInfoCardProps {
  id: string;
  fanId: number | null;
  userName: string;
  avatar?: string | null;
  type?: ChatType;
  lastMessage: string;
  lastMessageAt: number;
  pic: string | null;
  checked: boolean;
  resolved: boolean;
  hasMobileLayout?: boolean;
}

function ChatInfoCard({
  id,
  fanId,
  userName,
  avatar,
  type = ChatType.Line, // currently type is always LINE
  lastMessage,
  lastMessageAt,
  pic,
  checked,
  resolved,
  hasMobileLayout,
}: ChatInfoCardProps) {
  const { t, params } = useQueryHelper();
  const setSelectedChatState = useSetRecoilState(selectedChatState);
  const setDynamicTitleState = useSetRecoilState(dynamicTitleState);
  const { formattedChatTime } = useFormatChatTime({
    time: lastMessageAt,
    interval: secondsToMilliseconds(30),
  });

  useEffect(() => {
    if (id === params.activeChat) {
      setSelectedChatState(prevState => ({ ...prevState, pic, resolved, userName, fanId }));
      setDynamicTitleState({ title: userName });
    }
  }, [params.activeChat, pic, resolved, userName]);

  return (
    <NavLink to={fanId && !hasMobileLayout ? `/chat/${id}/${fanId}` : `/chat/${id}`} css={styles.container}>
      <div css={styles.mainContent}>
        <div css={styles.avatarRow}>
          <ChatAvatar avatar={avatar} type={type} />
          <div css={styles.textSection}>
            <div css={styles.justifiedRow}>
              <TextCutter text={userName} lines={1} css={[{ fontsize: '16px' }, !checked ? styles.unreadText : '']} />
              <span css={{ fontsize: '12px' }}>{formattedChatTime}</span>
            </div>
            <div css={styles.justifiedRow}>
              <TextCutter lines={1} text={lastMessage} css={[!checked ? styles.unreadText : '', { width: '100%' }]} />
              {!checked && <div css={styles.unreadBadge} />}
            </div>
          </div>
        </div>

        <div css={styles.statusBar}>
          <p css={[styles.status, resolved ? { background: '#dee5ec' } : '']}>{t(resolved ? 'Resolved' : 'Ongoing')}</p>
          {pic && <TextCutter lines={1} text={`${t('PIC')}: ${pic}`} css={styles.pic} />}
        </div>
      </div>
    </NavLink>
  );
}

const styles = {
  container: css`
    padding: 12px 10px;
    border-bottom: 1px solid #eef3f7;
    cursor: pointer;

    &:hover {
      background-color: rgba(56, 146, 229, 0.2);
    }

    &.active {
      background-color: rgba(56, 146, 229, 0.1);
    }
  `,
  mainContent: css`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  `,
  avatarRow: css`
    display: flex;
    align-items: end;
  `,
  statusBar: css`
    display: flex;
    justify-content: space-between;
    column-gap: 8px;
    align-items: center;
    white-space: nowrap;
  `,
  status: css`
    background: #ffb63d;
    border-radius: 40px;
    font-weight: 600;
    font-size: 11px;
    padding: 2px 6px;
    color: #fff;
    min-width: 60px;
    text-align: center;
  `,
  textSection: css`
    display: flex;
    overflow: hidden;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    margin-left: 12px;
    color: #27313b;
  `,
  justifiedRow: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  unreadBadge: css`
    background: #3892e5;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    flex-shrink: 0;
  `,
  pic: css`
    color: #3892e5;
    font-size: 12px;
  `,
  unreadText: css`
    font-weight: bold;
  `,
};

export default ChatInfoCard;
