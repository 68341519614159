import React from 'react';
import { Period } from '@src/components/molecules/DayPicker/types';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { useQueryHelper } from '@src/libs/hooks';
import { useQuery } from '@apollo/client';
import { SocialMediaAccountType } from '@src/components/organisms/ProfileDetail/types';
import AudienceCardTemplate from '@src/components/organisms/AnalyticsShared/AudienceCardTemplate';
import * as INFLUENCER_IG_FOLLOWERS_ANALYTICS from './InfluencerInstagramDashboardFollowerAnalytics.graphql';
import {
  InfluencerInstagramDashboardFollowerAnalytics,
  InfluencerInstagramDashboardFollowerAnalyticsVariables,
} from './__generated__/InfluencerInstagramDashboardFollowerAnalytics';

interface AudienceCardProps {
  selectedSocialMedia: SocialMediaAccountType;
  selectedPeriod: Period;
  userId: number;
  className?: string;
}

const AudienceCard = ({ selectedPeriod, selectedSocialMedia }: AudienceCardProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();

  const variables = {
    startDate: selectedPeriod.startDate,
    endDate: selectedPeriod.endDate,
    socialAccountId: selectedSocialMedia.id,
  };
  const { data, loading, error } = useQuery<
    InfluencerInstagramDashboardFollowerAnalytics,
    InfluencerInstagramDashboardFollowerAnalyticsVariables
  >(INFLUENCER_IG_FOLLOWERS_ANALYTICS, { variables });

  if (loading) {
    return <ListIndicator />;
  }

  if (error) {
    enqueueSnackbar(t('Can not get Instagram followers data for current account'), { variant: 'error' });

    return null;
  }

  return <AudienceCardTemplate {...data?.dashboardFollowerAnalytics} hasCountriesRates />;
};

export default AudienceCard;
