import * as React from 'react';
import SignInForm from '@src/components/organisms/SignInForm';
import TopPageContainer from '@src/components/organisms/TopPage/TopPageContainer';

const Template = () => (
  <TopPageContainer isSignIn showBgCover>
    <SignInForm />
  </TopPageContainer>
);

export default Template;
