import React from 'react';
import { Redirect } from 'react-router';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import PostDetail from '@src/components/organisms/AnalyticsShared/PostDetail';
import { InfluencerFacebookPageAnalyticsPost_influencerFacebookPageAnalyticsPost } from './__generated__/InfluencerFacebookPageAnalyticsPost';

interface TemplateProps {
  closeModal: () => void;
  data?: InfluencerFacebookPageAnalyticsPost_influencerFacebookPageAnalyticsPost | null;
  accountType?: SocialAccountType;
  accountName?: string;
  avatarUrl?: string;
}

const Template = (props: TemplateProps) => {
  const { data, closeModal, accountName, avatarUrl } = props;
  const accountType = props.accountType || SocialAccountType.FACEBOOK_PAGE;
  const pageName = accountName || '';
  const pageAvatar = avatarUrl || '';

  if (!data) {
    closeModal();

    return <Redirect to="/404" />;
  }

  const {
    postDate,
    postUrl,
    subscribers,
    views,
    reactions,
    comments,
    shares,
    adImpressions,
    positiveRate,
    negativeRate,
    content,
    image,
    postComments,
  } = data;

  const postCommentsRef = postComments.map(c => ({
    id: Number(c.commentId),
    username: c.posterName,
    content: c.content,
  }));

  return (
    <PostDetail
      avatarUrl={pageAvatar}
      accountType={accountType}
      accountName={pageName}
      followers={subscribers || 0}
      content={content}
      commentList={postCommentsRef || []}
      mediaUrls={image ? [image] : null}
      engagement={{
        views,
        postDate,
        postUrl,
        reactions,
        comments,
        shares,
        adImpressions,
        positiveRate,
        negativeRate,
      }}
    />
  );
};

export default Template;
