import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import css from '@emotion/css';
import styled from '@emotion/styled';
import TopPageHeader from '@src/components/organisms/TopPage/Header';
import ResetPasswordForm from '@src/components/organisms/ResetPasswordForm';
import { SignUpAndSignInBreakpoint } from '@src/libs/theme';
import TopPageFooter from '@src/components/organisms/TopPage/Footer';

const Template = () => {
  const isTabletView = useMediaQuery({ query: `(max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px)` });

  return (
    <Wrapper>
      <Container>
        <Header href="/" />
        <ContentWrapper>
          <ResetPasswordForm />
        </ContentWrapper>
        {!isTabletView && <TopPageFooter css={styles.footer} />}
      </Container>
    </Wrapper>
  );
};

const Header = styled(TopPageHeader)`
  padding-top: 16px;
`;
const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    background-color: #fff;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 856px;
  align-items: center;
`;
const ContentWrapper = styled.section`
  box-shadow: 0 1px 4px 0 #dee5ec, 0 2px 5px 0 #dee5ec;
  width: 100%;
  padding: 48px;
  background-color: #fff;
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 367px;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    border-radius: 0;
    box-shadow: none;
    padding: 32px 48px 32px;
    flex: 1;
  }
`;

const styles = {
  footer: css`
    margin-top: initial;
    width: 100%;
  `,
};

export default Template;
