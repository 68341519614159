import {
  analyticsChartNumberFormatter,
  formatDateCount,
  formatLineChartDateCount,
  SeriesHistoryType,
  formatIntNumber,
  formatNumber,
} from '@src/libs/format';

export const defaultSelectableCharts: ChartColorTypes[] = ['Follower', 'Post', 'Like', 'Reach', 'Comment', 'Save'];
export const additionalSelectableCharts: ChartColorTypes[] = [
  // 'Follow', -> we don't need for now
  'Impression',
  'Profile views',
  'Website Click',
];
export const allSelectableCharts: ChartColorTypes[] = [...defaultSelectableCharts, ...additionalSelectableCharts];

export type ChartColorTypes =
  | 'Follower'
  | 'Post'
  | 'Like'
  | 'Reach'
  | 'Comment'
  | 'Save'
  | 'Follow'
  | 'Impression'
  | 'Profile views'
  | 'Website Click';
export const colors: Record<ChartColorTypes, string> = {
  Follower: '#ff2b52',
  Post: '#ffd7c1',
  Reach: '#ff96ae',
  Like: '#e9b8d7',
  Comment: '#aec0e4',
  Save: '#a0c8df',
  Follow: '#9dd9f4',
  Impression: '#ffb6b7',
  'Profile views': '#c484eb',
  'Website Click': '#ffaae1',
};

export interface SeriesType {
  name: string;
  type: string;
  data: number[];
  color: string;
}
export interface TooltipDataType<T extends string> {
  [key: string]: {
    [key in T]: {
      value: string;
      increment: number;
    };
  };
}
export const getSerieDayData = <T extends string>(index: number, series: SeriesType[]) =>
  series.reduce((acc, serie) => {
    const hasDecimals = serie.name === 'Engagement Rate';

    return {
      ...acc,
      [serie.name as T]: {
        // @ts-ignore not sure how to fix this
        value: hasDecimals ? formatNumber(serie.data[index] || null) : formatIntNumber(serie.data[index] || null),
        // currenlty we just show difference between prev and curr values as increment
        increment: serie.name === 'Follower' && index !== 0 ? serie.data[index] - serie.data[index - 1] : 0,
      },
    };
  }, {});

export const getDailyDataForTooltip = (categories: string[], series: SeriesType[]): TooltipDataType<ChartColorTypes> =>
  categories.reduce(
    (acc, day, index) => ({
      ...acc,
      [day]: getSerieDayData<ChartColorTypes>(index, series),
    }),
    {}
  );

interface SeriesAndCategoriesType {
  historyData: Record<string, SeriesHistoryType[]>;
  displayedCharts: ChartColorTypes[];
  fetchDailyPosts: (date: string) => void;
}
export const getSeriesAndCategories = ({
  historyData: {
    followersHistory,
    postsHistory,
    reachHistory,
    likesHistory,
    commentsHistory,
    savedHistory,
    impressionsHistory,
    profileViewsHistory,
    websiteClicksHistory,
  },
  displayedCharts,
  fetchDailyPosts,
}: SeriesAndCategoriesType) => {
  // We are creating data-sets based on Period which is represented by followersHistory, if no data for some day we will replace it with 0
  const { count: followersCount, date: followersCategories } = formatLineChartDateCount(followersHistory);
  const { count: postsCount } = formatDateCount(followersHistory, postsHistory);
  const { count: reachCount } = formatDateCount(followersHistory, reachHistory);
  const { count: likesCount } = formatDateCount(followersHistory, likesHistory);
  const { count: commentsCount } = formatDateCount(followersHistory, commentsHistory);
  const { count: savedCount } = formatDateCount(followersHistory, savedHistory);
  const { count: impressionsCount } = formatDateCount(followersHistory, impressionsHistory);
  const { count: profileViewsCount } = formatDateCount(followersHistory, profileViewsHistory);
  const { count: websiteClicksCount } = formatDateCount(followersHistory, websiteClicksHistory);

  const dateCategories = followersCategories;

  const series: Highcharts.SeriesOptionsType[] = [
    {
      name: 'Follower',
      type: 'line',
      data: followersCount,
      color: colors.Follower,
      marker: {
        symbol: 'circle',
        lineWidth: 2,
        lineColor: undefined, // inherit from chart
        fillColor: '#fff',
      },
      visible: displayedCharts.includes('Follower'),
      zIndex: 1,
    },
    {
      name: 'Post',
      type: 'column',
      data: postsCount,
      color: colors.Post,
      yAxis: 1,
      visible: displayedCharts.includes('Post'),
      cursor: 'pointer',
      point: {
        events: {
          click() {
            if (this.category) {
              fetchDailyPosts(this.category);
            }
          },
        },
      },
    },
    {
      name: 'Like',
      type: 'column',
      data: likesCount,
      color: colors.Like,
      yAxis: 2,
      visible: displayedCharts.includes('Like'),
    },
    {
      name: 'Reach',
      type: 'column',
      data: reachCount,
      color: colors.Reach,
      yAxis: 3,
      visible: displayedCharts.includes('Reach'),
    },
    {
      name: 'Comment',
      type: 'column',
      data: commentsCount,
      color: colors.Comment,
      yAxis: 4,
      visible: displayedCharts.includes('Comment'),
    },
    {
      name: 'Save',
      type: 'column',
      data: savedCount,
      color: colors.Save,
      yAxis: 5,
      visible: displayedCharts.includes('Save'),
    },
    {
      name: 'Impression',
      type: 'column',
      data: impressionsCount,
      color: colors.Impression,
      yAxis: 6,
      visible: displayedCharts.includes('Impression'),
    },
    {
      name: 'Profile views',
      type: 'column',
      data: profileViewsCount,
      color: colors['Profile views'],
      yAxis: 7,
      visible: displayedCharts.includes('Profile views'),
    },
    {
      name: 'Website Click',
      type: 'column',
      data: websiteClicksCount,
      color: colors['Website Click'],
      yAxis: 8,
      visible: displayedCharts.includes('Website Click'),
    },
  ];
  const tooltipData = getDailyDataForTooltip(dateCategories, series as SeriesType[]);

  return {
    series,
    categories: dateCategories,
    tooltipData,
  };
};

interface EngagementWithHistoryType {
  total: number | null;
  growth: number | null;
  percentage: number | null;
  history: Array<{ count: number | null; date: any | null }> | null;
}
export const getEngagementDataWithHistory = (eng: EngagementWithHistoryType) => ({
  engagement: {
    total: eng?.total || 0,
    growth: eng?.growth || 0,
    rate: eng?.percentage || 0,
  },
  history: eng?.history || [],
});

export const generateYAxisBasedOnDisplayedCharts = <T>(yAxisCharts: T[], hasYMin: boolean | undefined = true) =>
  yAxisCharts.map(
    (_, i): Highcharts.YAxisOptions => ({
      ...(hasYMin ? { min: 0 } : {}),
      title: {
        text: '',
      },
      labels: {
        enabled: i === 0,
        formatter: function formatter(): string {
          return analyticsChartNumberFormatter(this.value);
        },
      },
      gridLineWidth: 1,
      allowDecimals: false,
    })
  );
