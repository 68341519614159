import React from 'react';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router';
import { useQueryHelper } from '../../libs/hooks';
import InitialLoading from '../../components/molecules/InitialLoading';
import { getUrlSearchParams } from '../../libs/url';
import { ConnectType } from '../../libs/auth';
import { CheckConnectedSocialAccountsForSignUp } from './__generated__/CheckConnectedSocialAccountsForSignUp';
import * as CHECK_CONNECTED_SOCIAL_ACCOUNTS from './CheckConnectedSocialAccountsForSignUp.graphql';
import * as CONNECTABLE_FANPAGE_ACCOUNTS from './ConnectableFanpageAccounts.graphql';
import Template from './template';
import { ConnectableFacebookPages } from './__generated__/ConnectableFacebookPages';
import { defaultSocialAccounts } from './helpers';

const SignUpConnect = () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { search } = useLocation();

  const { data: connectedAccounts, loading: isConnectedAccountLoading, error } = useQuery<
    CheckConnectedSocialAccountsForSignUp
  >(CHECK_CONNECTED_SOCIAL_ACCOUNTS);
  const { data: connectableFanpages, loading: isFanpageLoading } = useQuery<ConnectableFacebookPages>(
    CONNECTABLE_FANPAGE_ACCOUNTS
  );

  if (isConnectedAccountLoading || isFanpageLoading) {
    return <InitialLoading />;
  }

  if (error) {
    console.error(error);
    enqueueSnackbar(t(error.message), { variant: 'error' });
  }

  const fanPages = connectableFanpages?.connectableFacebookPages?.pages || [];
  const socialMediaData = connectedAccounts?.checkConnectedSocialAccountsV2 || defaultSocialAccounts;

  // if fanpages are empty we should skip steps
  const connectTypeParam = getUrlSearchParams(search, 'type');
  const connectTypeEnum =
    connectTypeParam === 'fb'
      ? ConnectType.CONNECT_FB_PAGE
      : connectTypeParam === 'ig'
      ? ConnectType.CONNECT_IG_ACCOUNT
      : undefined;
  const connectType = search && fanPages.length ? connectTypeEnum : undefined;

  return <Template {...socialMediaData} connectType={connectType} fanPages={fanPages} />;
};

export default SignUpConnect;
