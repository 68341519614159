import React, { useState, useEffect } from 'react';
import { css } from '@emotion/core';
import { LinkButton } from '@src/components/atoms/Button';
import { useQueryHelper } from '@src/libs/hooks';
import SearchForm from '@src/components/molecules/SearchForm';

interface FormIndividualNavigationProps {
  total: number;
}

export interface FormValues {
  page: string;
}

const FormIndividualNavigation = ({ total }: FormIndividualNavigationProps) => {
  const { params, t, history } = useQueryHelper();
  const { id, page = '1' } = params as { id: string; page: string };
  const [pageInput, setPageInput] = useState(page);

  const handleChange = (e: React.ChangeEvent<any>) => {
    setPageInput(e.currentTarget.value);
  };

  const handlePressEnter = () => {
    history.push(`/forms/${id}/report/individual/${pageInput}`);
  };

  useEffect(() => {
    setPageInput(page);
  }, [page]);

  return (
    <div css={styles.navigationWrapper}>
      <LinkButton
        css={[styles.navButton, { marginRight: 8 }]}
        bgcolor="#fff"
        color="#6E7C89"
        hoverbgcolor="#F6F8FA"
        href={`/forms/${id}/report/individual/${+page - 1}`}
        icon="navigate_before"
        disabled={Number(page) === 1}
      />
      <SearchForm
        css={styles.search}
        placeholder=""
        value={pageInput}
        onChange={handleChange}
        onEnterKeyPress={handlePressEnter}
      />
      <span css={styles.count}>
        {t('of')} {total}
      </span>
      <LinkButton
        css={styles.navButton}
        bgcolor="#fff"
        color="#6E7C89"
        hoverbgcolor="#F6F8FA"
        href={`/forms/${id}/report/individual/${+page + 1}`}
        icon="navigate_next"
        disabled={Number(page) === total}
      />
    </div>
  );
};

const styles = {
  navButton: css`
    border: 1px solid #dee5ec;
    border-radius: 3px;
    line-height: 30px;
    font-weight: 600;
    width: 30px;
    height: 30px;

    i {
      font-size: 24px;
    }
  `,
  navigationWrapper: css`
    display: flex;
    align-items: center;
  `,
  count: css`
    color: #6e7c89;
    font-weight: 600;
    font-size: 12px;
    margin: 0 16px 0 8px;
  `,
  textForm: css`
    flex: 1;

    /* stylelint-disable */
    & input {
      border-radius: 3px;
      height: 32px;
      border: none;
    }
    /* stylelint-enable */
  `,
  search: css`
    i {
      display: none;
    }

    input {
      width: 32px;
      height: 32px;
      padding: 0 8px;
      border-radius: 3px;
      box-shadow: none;
      padding-left: 8px;
      color: #6e7c89;
      font-weight: 600;
      font-size: 12px;
    }
  `,
};

export default FormIndividualNavigation;
