import likeIcon from '@src/assets/icon/thumbs-up.svg';
import postIcon from '@src/assets/icon/youtubeVideo.svg';
import viewsIcon from '@src/assets/icon/views.svg';
import commentIcon from '@src/assets/icon/comment.svg';
import networkIcon from '@src/assets/icon/network.svg';
import engagementRateIcon from '@src/assets/icon/engagementRate.svg';

export interface AverageEngagementTikTokProps {
  averagePostsPerWeek: number | null;
  averageLikes: number | null;
  averageViews: number | null;
  averageComments: number | null;
  averageShares: number | null;
  engagementRate: number | null;
}

export const getAverageEngagementIconAndTitle = (engKey: keyof AverageEngagementTikTokProps) => {
  switch (engKey) {
    case 'averagePostsPerWeek':
      return {
        title: 'Avg posts/week',
        icon: postIcon,
      };
    case 'averageLikes':
      return {
        title: 'Avg Likes',
        icon: likeIcon,
      };
    case 'engagementRate':
      return {
        title: 'HeaderColumn.Avg Engagement Rate',
        icon: engagementRateIcon,
      };
    case 'averageViews':
      return {
        title: 'Avg Views',
        icon: viewsIcon,
      };
    case 'averageComments':
      return {
        title: 'Avg Comments',
        icon: commentIcon,
      };
    case 'averageShares':
      return {
        title: 'Avg Shares',
        icon: networkIcon,
      };

    default:
      return {
        title: '',
        icon: '',
      };
  }
};
