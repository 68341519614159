import styled from '@emotion/styled';
import * as React from 'react';
import { COLORS } from '../../../libs/theme';

interface BaseProps {
  color?: string;
  withText?: boolean;
}
const I = styled.i<BaseProps>`
  margin: ${props => (!!props.withText ? '0 10px 0 0' : '4px 0 0 0')};
  color: ${props => (!!props.color ? props.color : '#fff')};
  font-size: 20px;
  opacity: 0.9;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

interface IconProps extends BaseProps {
  icon: string;
  onClick?: () => void;
}

export const Icon = (props: IconProps) => {
  const { icon, color, withText, onClick } = props;

  return (
    <I className="material-icons" color={color} withText={withText} onClick={onClick}>
      {icon}
    </I>
  );
};

type CustomIconProps = Omit<IconProps, 'icon'>;

export const SuccessIcon = (props: CustomIconProps) => (
  <Icon icon="check" color={COLORS.green.base} withText={true} {...props} />
);
export const ErrorIcon = (props: CustomIconProps) => (
  <Icon icon="error_outline" color={COLORS.red.base} withText={true} {...props} />
);
export const InfoIcon = (props: CustomIconProps) => (
  <Icon icon="info" color={COLORS.blue.base} withText={true} {...props} />
);
