import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import BlurredBlock from '../BlurredBlock';

export interface DataWrapperProps {
  className?: string;
  children: ReactNode;
  header?: string | React.ReactNode;
  dataNotAvailable?: boolean;
  isAvailableRate?: boolean;
  wrapperText?: string;
}

const DataOpacityWrapper = (props: DataWrapperProps) => {
  const { children, header, dataNotAvailable = false, isAvailableRate = false, className, wrapperText } = props;
  const { t } = useTranslation();

  return (
    <RelativeContainer className={className}>
      {header && <Title>{typeof header === 'string' ? t(header) : header}</Title>}
      {dataNotAvailable ? (
        <BlurredBlock isAvailableRate={isAvailableRate} wrapperText={wrapperText}>
          {children}
        </BlurredBlock>
      ) : (
        children
      )}
    </RelativeContainer>
  );
};

const RelativeContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  padding: 16px;
`;

export default DataOpacityWrapper;

export const SectionHeader = styled.h3`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #27313b;
`;
