import { format, parse } from 'date-fns';
import { useField, useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from '@src/components/atoms/Button';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import Label from '@src/components/atoms/Label';
import { RadioIcon } from '@src/components/atoms/RadioButtonElement';
import TimePicker, { timeDefaultFormat } from '@src/components/atoms/TimePicker';
import { DayPickerHandInput } from '@src/components/molecules/DayPicker';
import Dialog from '@src/components/molecules/Dialog';
import RichEditor from '@src/components/molecules/RichEditor';
import TextForm from '@src/components/molecules/TextForm';
import { getDateTimeInPickerFormat } from '@src/libs/date';
import RecipientSelect, { RecipientSelectOption } from '../RecipientSelect';

export const dateFormat = 'dd/MM/yyyy HH:mm';

export interface FormInformation {
  fans: number[];
  id?: number;
  isDraft: boolean;
  message: string;
  scheduleDate: any;
  sender: string;
  sendNow: boolean;
  subject: string;
  tags: number[];
}

const Form = () => {
  const [date, setDate] = useState<string>('');
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [time, setTime] = useState<string>('');
  const { t } = useTranslation();
  const { isSubmitting, handleSubmit, setFieldValue, validateForm } = useFormikContext<FormInformation>();
  const [fansField, fansMeta] = useField<number[]>('fans');
  const [isDraftField] = useField('isDraft');
  const [messageField, messageMeta] = useField('message');
  const [scheduleDateField, scheduleDateMeta] = useField('scheduleDate');
  const [senderField, senderMeta] = useField('sender');
  const [sendNowField] = useField('sendNow');
  const [subjectField, subjectMeta] = useField('subject');
  const [tagsField, tagsMeta] = useField<number[]>('tags');
  const isScheduleForLater = !sendNowField.value;

  useEffect(() => {
    const selectedDate = parse(scheduleDateField.value, dateFormat, new Date());
    const { date: dateString, time: timeString } = getDateTimeInPickerFormat(selectedDate);

    setDate(dateString);
    setTime(timeString);
  }, [scheduleDateField.value]);

  const onChangeDateTime = (dateString: string, timeString: string) => {
    const parseDate = format(new Date(Date.parse(`${dateString} ${timeString}`)), dateFormat);
    setFieldValue(scheduleDateField.name, parseDate);
  };

  const onClickSubmit = () => {
    if (isScheduleForLater) {
      setFieldValue(isDraftField.name, false);
      handleSubmit();
    } else {
      validateForm().then(error => {
        if (isEmpty(error)) {
          setDialogOpen(true);
        } else {
          handleSubmit();
        }
      });
    }
  };

  const onClickDialogSendNow = () => {
    setFieldValue(isDraftField.name, false);
    handleSubmit();
  };

  const onClickSaveDraft = () => {
    setFieldValue(isDraftField.name, true);
    handleSubmit();
  };

  return (
    <form>
      <Dialog
        execText="Send Now"
        visible={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onExec={onClickDialogSendNow}
      >
        <div css={styles.dialogContent}>
          <div>{t('Title.Email Send Now')}</div>
          <div>{`※ ${t('Annotation.Dialog Send Now 1')}`}</div>
          <div>{`※ ${t('Annotation.Dialog Send Now 2')}`}</div>
        </div>
      </Dialog>

      <div css={styles.container}>
        <div css={styles.infoContainer}>
          <InputContainer>
            <StyledTextForm
              error={!!subjectMeta.error}
              placeholder={t('TextForm.Enter Subject')}
              title="Subject"
              value={subjectField.value}
              onChange={e => setFieldValue(subjectField.name, e.target.value)}
            />
            {subjectMeta.error && <ErrorMessage message={t(subjectMeta.error)} />}
          </InputContainer>

          <InputContainer>
            <RecipientSelect
              error={!!(fansMeta.error || tagsMeta.error)}
              fanIds={fansField.value}
              tagIds={tagsField.value}
              title="To"
              onChange={value => {
                setFieldValue(
                  fansField.name,
                  (value as RecipientSelectOption[])?.filter(option => option.isFan).map(option => option.id)
                );
                setFieldValue(
                  tagsField.name,
                  (value as RecipientSelectOption[])?.filter(option => !option.isFan).map(option => option.id)
                );
              }}
            />
            {(fansMeta.error || tagsMeta.error) && <ErrorMessage message={t(fansMeta.error || tagsMeta.error || '')} />}
          </InputContainer>

          <InputContainer>
            <StyledTextForm
              error={!!senderMeta.error}
              placeholder="sample@anymindgroup.com"
              title="From"
              value={senderField.value}
              onChange={e => setFieldValue(senderField.name, e.target.value)}
            />
            {senderMeta.error && <ErrorMessage message={t(senderMeta.error)} />}
          </InputContainer>

          <InputContainer>
            <div css={styles.richEditorContainer}>
              <RichEditor
                title="Message"
                value={messageField.value}
                onChange={value => setFieldValue(messageField.name, value)}
              />
            </div>
            {messageMeta.error && <ErrorMessage message={t(messageMeta.error)} />}
          </InputContainer>

          <div css={styles.deliveryScheduleContainer}>
            <Label css={styles.label} title={t('Delivery Schedule')} />
            <RadioGroup>
              <RadioIcon isChecked={isScheduleForLater} onClick={() => setFieldValue(sendNowField.name, false)} />
              <span>{t('Schedule for later')}</span>
            </RadioGroup>
            <div css={styles.datetimeContainer}>
              <div css={styles.datePickerContainer}>
                <DayPickerHandInput
                  disabledRange={{ before: new Date() }}
                  handleChangeDay={value => onChangeDateTime(value, time)}
                  value={date}
                />
              </div>
              <TimePicker
                use12Hours
                value={moment(time, timeDefaultFormat)}
                onChange={value => onChangeDateTime(date, value.format(timeDefaultFormat))}
              />
            </div>
            {scheduleDateMeta.error && <ErrorMessage message={t(scheduleDateMeta.error)} />}
            <RadioGroup>
              <RadioIcon isChecked={!isScheduleForLater} onClick={() => setFieldValue(sendNowField.name, true)} />
              <span>{t('Sent Now')}</span>
            </RadioGroup>
            <Annotation>{`※ ${t('Annotation.Sent Now 1')}`}</Annotation>
            <Annotation>{`※ ${t('Annotation.Sent Now 2')}`}</Annotation>
          </div>
        </div>

        <div css={styles.actionContainer}>
          <ActionButton
            bgcolor="#fff"
            disabled={isSubmitting}
            hoverbgcolor="#fff"
            title="Save Draft"
            onClick={onClickSaveDraft}
          />
          <ActionButton
            bgcolor="#3892e5"
            disabled={isSubmitting}
            hoverbgcolor="#3892e5"
            isPrimary
            title={isScheduleForLater ? 'Schedule' : 'Send Now'}
            onClick={onClickSubmit}
          />
        </div>
      </div>
    </form>
  );
};

const ActionButton = styled(Button)<{ isPrimary?: boolean }>`
  border-radius: 3px;
  font-size: 12px;
  font-weight: 600;
  height: 32px;
  width: 109px;
  ${({ isPrimary }) => (!isPrimary ? 'border: 1px solid #dee5ec; color: #6e7c89;' : '')}
`;

const Annotation = styled.p`
  color: #27313b;
  font-size: 12px;
  font-weight: 500;
`;

const InputContainer = styled.div`
  margin-bottom: 24px;
  width: 100%;
`;

const RadioGroup = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  margin-top: 16px;

  & > span {
    color: #27313b;
    font-size: 14px;
    margin-left: 8px;
  }
`;

const StyledTextForm = styled(TextForm)`
  & > label {
    color: #27313b;
    font-size: 14px;
    font-weight: 600;
  }

  & input {
    border-radius: 3px;
    height: 32px;
  }
`;

const styles = {
  actionContainer: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 16px 24px;

    & > button:nth-of-type(1) {
      margin-right: 8px;
    }
  `,
  container: css`
    background-color: #fff;
  `,
  datePickerContainer: css`
    margin-right: 8px;

    /* stylelint-disable no-descending-specificity */
    & > div > div > div {
      border-color: #dee5ec;
      border-radius: 3px;
      height: 30px;
      width: 150px;

      & > span {
        color: #6e7c89;
        font-size: 24px;
        top: 3px;
      }

      & > input {
        height: 32px;
      }
    }
  `,
  datetimeContainer: css`
    display: flex;
    flex-wrap: wrap;
  `,
  deliveryScheduleContainer: css`
    margin-bottom: 8px;
  `,
  dialogContent: css`
    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: 18px;
      margin-bottom: 24px;
    }

    & > div:not(:nth-of-type(1)) {
      color: #ff5f5f;
      font-size: 14px;
      font-weight: 500;
    }
  `,
  label: css`
    color: #27313b;
    font-size: 14px;
    font-weight: 600;
  `,
  infoContainer: css`
    border-bottom: 1px solid #dee5ec;
    padding: 24px;
  `,
  richEditorContainer: css`
    background-color: #fff;

    & > div > label {
      color: #27313b;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 4px;
    }

    & > div > div > div > div > div:nth-of-type(3) {
      max-width: 50vw;
      width: 100%;
    }

    & .sun-editor-common {
      z-index: 0;
    }

    & .sun-editor-editable {
      height: auto !important;
      min-height: 370px !important;
    }
  `,
};

export default Form;
