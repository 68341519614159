import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { useQueryHelper } from '@src/libs/hooks';
import Template from './template';
import {
  InfluencerFacebookPageAnalyticsPost,
  InfluencerFacebookPageAnalyticsPostVariables,
} from './__generated__/InfluencerFacebookPageAnalyticsPost';
import * as INFLUENCER_FB_ANALYTICS_POST_DETAILS from './InfluencerFacebookPageAnalyticsPost.graphql';

interface AnayticsFeedPostDetailProps {
  closeModal: () => void;
  hasViewColumn?: boolean;
  socialMediaAccountId?: number;
  influencerAccountId?: number;
  accountName?: string;
  avatarUrl?: string;
}

const FacebookPageAnalyticsFeedPostDetail = (props: AnayticsFeedPostDetailProps) => {
  const { closeModal, socialMediaAccountId, influencerAccountId, accountName, avatarUrl } = props;
  const { search, t, enqueueSnackbar } = useQueryHelper();

  const postId = Number(new URLSearchParams(search).get('popup'));

  const { data, loading } = useQuery<InfluencerFacebookPageAnalyticsPost, InfluencerFacebookPageAnalyticsPostVariables>(
    INFLUENCER_FB_ANALYTICS_POST_DETAILS,
    {
      ...(!influencerAccountId
        ? { skip: true }
        : {
            variables: {
              influencerId: influencerAccountId,
              socialAccountId: Number(socialMediaAccountId),
              postId,
            },
          }),
      onError: err => {
        closeModal();
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
      fetchPolicy: 'cache-first',
    }
  );

  if (loading) {
    return <CircularProgress size={64} thickness={2.4} css={{ margin: 'auto', color: '#dee5ec' }} />;
  }

  return (
    <Template
      closeModal={closeModal}
      data={data?.influencerFacebookPageAnalyticsPost}
      accountType={SocialAccountType.FACEBOOK_PAGE}
      accountName={accountName}
      avatarUrl={avatarUrl}
    />
  );
};

export default FacebookPageAnalyticsFeedPostDetail;
