import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { mainBlue } from '../../../libs/pallete';
import { switchImage } from '../../../libs/SocialMedia';
import { capitalizeText } from '../../../libs/text';
import { SocialMediaTypeForRateCard } from '../../../__generated__/globalTypes';
import { PalleteButton } from '../../atoms/Button';
import ErrorMessage from '../../atoms/ErrorMessage';
import { DialogTitle } from '../DialogModal';
import { FormValues } from './type';

interface PriceEditorFormProps {
  price: number;
  currency: string;
  socialMedia: SocialMediaTypeForRateCard;
}

const PriceEditorForm = (props: PriceEditorFormProps) => {
  const { socialMedia, currency, price } = props;
  const { submitForm, setFieldValue, isSubmitting } = useFormikContext<FormValues>();
  const { t } = useTranslation();
  const currentSocialMedia =
    socialMedia === SocialMediaTypeForRateCard.TIKTOK
      ? 'TikTok'
      : capitalizeText(socialMedia.replace('INSTAGRAM_STORY', 'INSTAGRAM STORY'));

  // Fields
  const [priceField, priceMeta] = useField('price');

  return (
    <ModalContentWrapper>
      <DialogTitle>
        <Trans i18nKey={!price ? 'AddPriceAccount' : 'EditPriceAccount'}>
          Edit Price
          <span
            css={css`
              margin-left: 4px;
            `}
          >
            {{ account: currentSocialMedia }}
          </span>
        </Trans>
      </DialogTitle>
      <ModalText>{t(`Please fill in the price you want to ${!price ? 'set' : 'edit'}`)}</ModalText>
      <ModalInput>
        <InputLabel>
          <SocialMediaIcon src={switchImage(socialMedia)} alt="social media icon" />
          <Trans i18nKey="AccountPrice">
            text0
            <span>{{ account: currentSocialMedia }}</span>
          </Trans>
        </InputLabel>
        <InputContainer>
          <ButtonIncDec onClick={() => setFieldValue('price', Number(priceField.value) + 1)}>+</ButtonIncDec>
          <Input type="number" {...priceField} />
          <Currency>{currency}</Currency>
          <ButtonIncDec onClick={() => setFieldValue('price', Number(priceField.value) - 1)}>-</ButtonIncDec>
        </InputContainer>
        {priceMeta.error && <ErrorMessage message={t(priceMeta.error)} />}
      </ModalInput>
      <PalleteButtonMargin onClick={submitForm} isDisabled={isSubmitting} text="Save" palleteColor={mainBlue} />
    </ModalContentWrapper>
  );
};

const InputLabel = styled.div`
  display: inline-flex;
  margin-bottom: 6px;
`;

const SocialMediaIcon = styled.img`
  width: 22px;
  margin-right: 8px;
`;

const ModalContentWrapper = styled.div`
  padding: 0 8px;
`;

const Currency = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #6e7c89;
  margin-left: 4px;
`;

const ButtonIncDec = styled.div`
  user-select: none;
  color: #ff2b52;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  cursor: pointer;
`;

const ModalText = styled.p`
  margin: 10px 0 24px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #27313b;

  span {
    color: #ff2b52;
  }
`;

const ModalInput = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #27313b;
  margin-bottom: 16px;
`;

const InputContainer = styled.div`
  display: inline-flex;
  background: #fff;
  height: 44px;
  border: 1px solid #dee5ec;
  box-sizing: border-box;
  border-radius: 5px;
`;

const Input = styled('input')`
  /* stylelint-disable */
  flex: 1;
  text-align: right;
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const PalleteButtonMargin = styled(PalleteButton)`
  margin-bottom: 8px;
`;

export default PriceEditorForm;
