import styled from '@emotion/styled';
import React from 'react';
import { useUpdateDocumentTitle } from '../../libs/hooks';
import { ViewportType } from '../../libs/theme';
import ProfileDetail from '../../components/organisms/ProfileDetail';
import { SocialMediaAccountType } from '../../components/organisms/ProfileDetail/types';
import { WarningWidget } from '../../components/molecules/WarningWidget';
import ProfileIntro, { ProfileIntroProps } from '../../components/organisms/ProfileDetail/ProfileIntro/ProfileIntro';

export interface TemplateProps {
  influencerProfile: ProfileIntroProps;
  availableSocialMediaAccounts: SocialMediaAccountType[];
}

const Template = (props: TemplateProps) => {
  const { influencerProfile, availableSocialMediaAccounts } = props;

  useUpdateDocumentTitle({ title: 'pageTitle.Profile Detail', pageTitle: 'Profile Detail' });

  const {
    id,
    age,
    gender,
    country,
    region,
    categories,
    introduce,
    facebookFollowers,
    instagramFollowers,
    twitterFollowers,
    tiktokFollowers,
    youtubeFollowers,
    followersCount,
    status,
    avgEngagementRate,
    avgEngagementNumber,
    tags,
  } = influencerProfile;
  const hasConnectedAccount = availableSocialMediaAccounts.length > 0;

  return (
    <Container>
      {!hasConnectedAccount && (
        <StyledWarningWidget
          warningTitle="None of your social media accounts have been connected"
          warningReason="You need to register your social media to participate in a job"
          buttonText="Connect"
          buttonLink="/profile/settings/connect"
        />
      )}
      <ProfileIntroWrapper
        id={id}
        age={age}
        gender={gender}
        country={country}
        region={region}
        categories={categories}
        introduce={introduce}
        status={status}
        tags={tags}
        followersCount={followersCount}
        facebookFollowers={facebookFollowers}
        instagramFollowers={instagramFollowers}
        twitterFollowers={twitterFollowers}
        tiktokFollowers={tiktokFollowers}
        youtubeFollowers={youtubeFollowers}
        avgEngagementNumber={avgEngagementNumber}
        avgEngagementRate={avgEngagementRate}
      />

      <RightPanel>
        {hasConnectedAccount && <ProfileDetail availableSocialMediaAccounts={availableSocialMediaAccounts} />}
      </RightPanel>
    </Container>
  );
};

const Container = styled.div`
  padding: 16px;
  width: calc(100% - 32px);
`;

const ProfileIntroWrapper = styled(ProfileIntro)`
  width: 240px;
  background-color: #fff;
  float: left;
  margin-right: 24px;

  @media (max-width: ${ViewportType.MEDIUM}px) {
    float: unset;
    width: 100%;
    margin-bottom: 16px;
  }
`;

const RightPanel = styled.section`
  width: auto;
  float: none;
  overflow: hidden;
`;

const StyledWarningWidget = styled(WarningWidget)`
  margin-bottom: 24px;
`;

export default Template;
