import styled from '@emotion/styled';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/styles/makeStyles';
import * as React from 'react';
import css from '@emotion/css';

const useStyles = makeStyles({
  progress: {
    color: '#e0e8ed',
    margin: '0 auto',
  },
});

interface PageLoadingProps {
  className?: string;
  isFullWidthTransparent?: boolean;
}
const PageLoading = (props: PageLoadingProps) => {
  const { className, isFullWidthTransparent } = props;
  const classes = useStyles();

  return (
    <Wrapper className={className} css={isFullWidthTransparent ? transparentCenterisedCss : ''}>
      <CircularProgress className={classes.progress} size={64} thickness={2.4} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  margin: 50%;
  background-color: #fff;
`;

const transparentCenterisedCss = css`
  width: 100%;
  align-items: center;
  margin: 0;
  background-color: unset;
`;

export default PageLoading;
