import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useQueryHelper } from '../../../../libs/hooks';
import BaseChooseFBPageAndIGAccountForm from './ChooseFBPageAndIGAccountForm';
import { useIGAccounts } from './useIGAccounts';
import * as CONNECT_IG_ACCOUNTS from './ConnectInstagramAccounts.graphql';
import { ConnectInstagramAccounts, ConnectInstagramAccountsVariables } from './__generated__/ConnectInstagramAccounts';

export interface ChooseFBPageAndIGAccountFormProps {
  close: () => void;
  refetchQueries?: Array<
    | 'CheckConnectedSocialAccountsForProfile'
    | 'CheckConnectedSocialAccountsForSignUp'
    | 'ConnectableFanPageAccountsProfile'
  >;
  isProfileConnectPage?: boolean;
  isOpening?: boolean;
}

const ChooseFBPageAndIGAccountForm = (props: ChooseFBPageAndIGAccountFormProps) => {
  const { close, refetchQueries = [], isProfileConnectPage, isOpening } = props;
  const { t, enqueueSnackbar } = useQueryHelper();
  const { accounts, loading: queryLoading, error } = useIGAccounts();

  const [selectedIGAccountIds, setSelectedIGAccountIds] = useState<string[]>([]);
  const [connectInstagramAccount, { loading: mutationLoading }] = useMutation<
    ConnectInstagramAccounts,
    ConnectInstagramAccountsVariables
  >(CONNECT_IG_ACCOUNTS, { refetchQueries });

  const handleConnect = async () => {
    const selectedFBPageId = selectedIGAccountIds
      .map(selectedAccount => accounts.find(account => account.igAccount.id === selectedAccount)?.page.id)
      .filter(fanpageId => !!fanpageId) as string[];

    if (!selectedFBPageId) {
      return;
    }

    try {
      await connectInstagramAccount({
        variables: { input: { igUserIds: selectedIGAccountIds, fbPageIds: selectedFBPageId } },
      });
      enqueueSnackbar(t('succeededInConnecting'), { variant: 'success' });
    } catch (e) {
      console.error(e);
      enqueueSnackbar(t('failedToConnect'), { variant: 'error' });
    }

    close();
  };

  const toggleSelectedIGAccountIds = (accountId: string, isSelected: boolean) => {
    const updatedAccounts = isSelected
      ? selectedIGAccountIds.filter(acc => acc !== accountId)
      : [...selectedIGAccountIds, accountId];

    setSelectedIGAccountIds(updatedAccounts);
  };

  return (
    <BaseChooseFBPageAndIGAccountForm
      accounts={accounts}
      loading={queryLoading || mutationLoading}
      error={error}
      selectedIGAccountIds={selectedIGAccountIds}
      toggleSelectedIGAccountIds={toggleSelectedIGAccountIds}
      close={close}
      handleConnect={handleConnect}
      isProfileConnectPage={isProfileConnectPage}
      isOpening={isOpening}
    />
  );
};

export default ChooseFBPageAndIGAccountForm;
