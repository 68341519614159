import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import likeIcon from '@src/assets/icon/like.svg';
import negativeIcon from '@src/assets/icon/negative.svg';
import neutralIcon from '@src/assets/icon/neutral.svg';
import positiveIcon from '@src/assets/icon/positive.svg';
import { formatPercent } from '@src/libs/format';
import { css } from '@emotion/react';
import ProfileDetailsContentWrapper from '../ProfileDetailsContentWrapper/ProfileDetailsContentWrapper';

export interface SentimentAnalysisProps {
  className?: string;
  positiveRatio: number | null;
  negativeRatio: number | null;
  neutralRatio: number | null;
}

export const SentimentAnalysis = (props: SentimentAnalysisProps) => {
  const { positiveRatio, negativeRatio, neutralRatio, className } = props;
  const { t } = useTranslation();

  const headerNode = (
    <Header>
      <Img src={likeIcon} />
      <HeadingText>{t('HeaderColumn.Sentiment Analysis')}</HeadingText>
    </Header>
  );

  return (
    <ProfileDetailsContentWrapper
      className={className}
      header={headerNode}
      css={css`
        display: flex;
        flex-direction: column;
      `}
      dataNotAvailable={!positiveRatio && !negativeRatio && !neutralRatio}
    >
      <BadgeContainer>
        <BadgeWrapper>
          <SentimentBadge bgColor="#ff8097">
            <Img src={positiveIcon} />
            <Label>{t('Label.Positive')}</Label>
          </SentimentBadge>
          <Score>{formatPercent(positiveRatio || 0)}</Score>
        </BadgeWrapper>
        <BadgeWrapper>
          <SentimentBadge bgColor="#ffbe82">
            <Img src={neutralIcon} />
            <Label>{t('Label.Neutral')}</Label>
          </SentimentBadge>
          <Score>{formatPercent(neutralRatio || 0)}</Score>
        </BadgeWrapper>
        <BadgeWrapper>
          <SentimentBadge bgColor="#707fc0">
            <Img src={negativeIcon} />
            <Label>{t('Label.Negative')}</Label>
          </SentimentBadge>
          <Score>{formatPercent(negativeRatio || 0)}</Score>
        </BadgeWrapper>
      </BadgeContainer>
    </ProfileDetailsContentWrapper>
  );
};

const HeadingText = styled.span`
  font-size: 13px;
  font-weight: 600;
  color: #27313b;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
`;

const BadgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
`;

const BadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
  width: 200px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const SentimentBadge = styled.div<{ bgColor: string }>`
  height: 48px;
  min-width: 96px;
  background-color: ${({ bgColor }) => bgColor};
  display: flex;
  align-items: center;
  border-radius: 24px;
  padding: 0 12px;
`;

const Score = styled.div`
  color: #27313b;
  font-size: 20px;
`;

const Img = styled.img`
  width: 24px;
  margin-right: 8px;
`;

const Label = styled.span`
  font-size: 13px;
  font-weight: 600;
  color: #fff;
`;

export default SentimentAnalysis;
