import { useContext, useState } from 'react';
import { AdminStore } from '../../../../Context';

export const useDialog = (initial = false) => {
  const [isOpening, setIsOpening] = useState(initial);
  const { dispatch } = useContext(AdminStore);

  // Global App state
  const setGlobalDialogState = (dialog: boolean) => dispatch({ type: 'SET_DIALOG', payload: { isDialogOpen: dialog } });

  const open = () => {
    setIsOpening(true);
    setGlobalDialogState(true);
  };
  const close = () => {
    setIsOpening(false);
    setGlobalDialogState(false);
  };

  return {
    isOpening,
    setIsOpening,
    open,
    close,
  };
};

export const useDialogStep = (initial = 0) => {
  const [activeStep, setActiveStep] = useState(initial);
  const handleNext = () => setActiveStep(prev => prev + 1);
  const handleBack = () => setActiveStep(prev => prev - 1);
  const resetStep = () => setActiveStep(0);

  return {
    activeStep,
    setActiveStep,
    handleNext,
    handleBack,
    resetStep,
  };
};
