import styled from '@emotion/styled';
import React, { useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { css } from '@emotion/core';
import CampaignDetail, { CampaignDetailProps } from '../../components/organisms/Campaign/CampaignDetail';
import { useUpdateDocumentTitle } from '../../libs/hooks';
import { ViewportBreakpoint } from '../../libs/theme';
import NavigationHeader from '../../components/organisms/Header/NavigationHeader';
import {
  CampaignPageType,
  switchCampaignStatus,
  getSocialMediaUrlByCampaignType,
  getAuthSocialAccountType,
} from '../../libs/campaign';
import {
  CampaignStatusForInfluencer,
  CampaignPostStatusForInfluencer,
  MarketplaceCampaignType,
} from '../../__generated__/globalTypes';
import JoinButton from '../../components/organisms/Campaign/JoinButton';
import { SocialButton, PalleteButton } from '../../components/atoms/Button';
import BottomOverflowSection from '../../components/atoms/BottomOverflowSection';
import { mainWhite } from '../../libs/pallete';
import { checkTheSocialMediaIsConnected } from './utils';
import { MarketplaceForInfluencer_influencerProfileV2 } from './__generated__/MarketplaceForInfluencer';
import { CopyHashtagModal } from './CopyHashtagModal';

interface JoinButtonProps {
  maximumRevenuePerInfluencer: number | null;
  minimumPaymentAmount: number;
  postStatus: CampaignPostStatusForInfluencer | null;
  influencerProfile: MarketplaceForInfluencer_influencerProfileV2 | null;
  hasReport?: boolean;
}
interface TemplateProps extends CampaignDetailProps {
  page: CampaignPageType;
  joinButtonProps: JoinButtonProps;
}
const Template = (props: TemplateProps) => {
  const headerTitle = 'pageTitle.Campaign Detail';
  const pageTitle = 'pageTitle.Campaign Detail';
  const href =
    props.page === CampaignPageType.SEARCH
      ? `/${props.page}`
      : props.campaignStatus === CampaignStatusForInfluencer.FINISHED
      ? `/${props.page}/finished`
      : `/${props.page}`;

  useUpdateDocumentTitle({ title: headerTitle, pageTitle, href });
  const isMobile = useMediaQuery(`(max-width:${ViewportBreakpoint.MEDIUM}px)`);

  const [isCopyHashtagOpen, setCopyHashtagOpen] = useState<boolean>(false);
  const closeCopyHashtag = () => {
    setCopyHashtagOpen(false);
  };

  const {
    title,
    serviceInformation,
    materials,
    campaignType,
    startDate,
    endDate,
    campaignStatus,
    currency,
    requirements,
    socialMedias,
    thumbNail,
    totalAmount,
    remainingAmount,
    hashtags,
    revenuePerPost,
    revenuePerLike,
    revenuePerView,
    revenuePerShare,
    revenuePerComment,
    revenuePerFollower,
    revenuePerClick,
    revenuePerAction,
    trackingUrl,
    productUrl,
    isJoined,
    campaignId,
    materialUrl,
    selectionStartDate,
    selectionEndDate,
    marketplaceCampaignType,
    isApplied,
    appliedStatus,
  } = props;
  const {
    maximumRevenuePerInfluencer,
    minimumPaymentAmount,
    postStatus,
    influencerProfile,
    hasReport,
  } = props.joinButtonProps;

  const hasJoinOrApplyButton =
    !isJoined && // For normal marketplace
    !isApplied && // For selection marketplace
    [CampaignStatusForInfluencer.ONGOING, CampaignStatusForInfluencer.UPCOMING].includes(campaignStatus);
  const status = switchCampaignStatus(campaignStatus, !!isJoined, postStatus);
  const regionId = influencerProfile?.region?.id || null;
  const clickUrlLink = getSocialMediaUrlByCampaignType(socialMedias[0]);
  const socialMedia = socialMedias[0];
  const hasConnectedAccount = checkTheSocialMediaIsConnected(
    socialMedia,
    influencerProfile as MarketplaceForInfluencer_influencerProfileV2
  );

  const joinButtonProps = {
    campaignInfo: {
      id: campaignId,
      currency,
      requirement: requirements,
      hashTags: hashtags,
      start: startDate,
      end: endDate,
      maximumRevenuePerInfluencer,
      minimumPaymentAmount,
      clickUrl: clickUrlLink,
      socialMedia: socialMedias[0],
    },
    status,
    regionId,
    hasConnectedAccount,
  };
  // postStatus could be possibly null for unsuccessful post, so we have to check campaignStatus as well
  const hasUploadPostButton = isJoined && postStatus === null && CampaignStatusForInfluencer.ONGOING === campaignStatus;
  const hasViewReportButton = isJoined && hasReport;
  const isDisabledUploadPost =
    hasUploadPostButton &&
    [CampaignStatusForInfluencer.UPCOMING, CampaignStatusForInfluencer.FINISHED].includes(campaignStatus);

  const renderActionButtons = () => {
    if (!(hasJoinOrApplyButton || hasUploadPostButton || hasViewReportButton)) {
      return null;
    }

    return (
      <>
        <Flexed>
          {hasJoinOrApplyButton && (
            <div css={[margin4, flex1]}>
              <JoinButton
                buttonText={marketplaceCampaignType === MarketplaceCampaignType.SELECTION ? 'Apply' : undefined}
                {...joinButtonProps}
                isMobile={isMobile}
                css={margin4}
              />
            </div>
          )}
          {hasUploadPostButton && (
            <StyledSocialButton
              css={[UploadButton, flex1]}
              iconSize="16px"
              onClick={() => (isDisabledUploadPost ? undefined : setCopyHashtagOpen(true))}
              socialMedia={getAuthSocialAccountType(socialMedia)}
              title="Upload Post"
              hasArrow
              isDisabled={isDisabledUploadPost}
            />
          )}
          {hasViewReportButton && (
            <PalleteButton
              css={[margin4, flex1]}
              text="View report"
              palleteColor={mainWhite}
              height="32px"
              width={isMobile ? '100%' : 'max-content'}
              href={`/job/marketplace/${campaignId}/report`}
            />
          )}
        </Flexed>
      </>
    );
  };

  return (
    <Wrapper>
      <NavigationHeader childrenWidth={isMobile ? '100%' : 'max-content'}>{renderActionButtons()}</NavigationHeader>
      <CampaignDetailWrapper>
        <CampaignDetail
          thumbNail={thumbNail}
          title={title}
          serviceInformation={serviceInformation}
          materials={materials}
          campaignType={campaignType}
          startDate={startDate}
          endDate={endDate}
          campaignStatus={campaignStatus}
          currency={currency}
          requirements={requirements}
          socialMedias={socialMedias}
          totalAmount={totalAmount}
          remainingAmount={remainingAmount}
          revenuePerPost={revenuePerPost}
          revenuePerLike={revenuePerLike}
          revenuePerView={revenuePerView}
          revenuePerShare={revenuePerShare}
          revenuePerComment={revenuePerComment}
          revenuePerFollower={revenuePerFollower}
          revenuePerClick={revenuePerClick}
          revenuePerAction={revenuePerAction}
          trackingUrl={trackingUrl}
          productUrl={productUrl}
          hashtags={hashtags}
          isJoined={isJoined}
          maximumRevenuePerInfluencer={maximumRevenuePerInfluencer}
          campaignId={campaignId}
          materialUrl={materialUrl}
          selectionStartDate={selectionStartDate}
          selectionEndDate={selectionEndDate}
          marketplaceCampaignType={marketplaceCampaignType}
          isApplied={isApplied}
          appliedStatus={appliedStatus}
          hasReport={hasReport}
          postStatus={postStatus}
        />
      </CampaignDetailWrapper>
      <BottomOverflowSection>{renderActionButtons()}</BottomOverflowSection>
      {/* Modals */}
      <CopyHashtagModal
        isOpen={isCopyHashtagOpen}
        closeModal={closeCopyHashtag}
        socialMedia={socialMedia}
        hashtags={hashtags}
        campaignId={campaignId}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const CampaignDetailWrapper = styled.div`
  margin-top: 48px;
`;

const Flexed = styled.div`
  display: inline-flex;
  width: 100%;
`;

const margin4 = css`
  margin: 4px;
`;

const UploadButton = css`
  min-width: 175px;
  margin: 4px;
`;

const flex1 = css`
  flex: 1;
`;

const StyledSocialButton = styled(SocialButton)<{ isDisabled?: boolean }>`
  line-height: 32px;
  border-radius: 20px;
  justify-content: center;
  position: relative;
  margin-top: 0;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  ${({ isDisabled }) => isDisabled && 'background-color: #dee5ec; cursor: unset;'}

  img {
    padding-left: 0;
    margin-right: 4px;
  }

  span {
    margin-right: 8px;
  }
`;

export default Template;
