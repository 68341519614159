import React from 'react';
import { NotFound } from '@src/pages';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { AnalyticsTemplateProps } from '../../AnalyticsShared/types';
import AnalyticsInstagram from '../../AnalyticsInstagram';
import AnalyticsYoutube from '../../AnalyticsYoutube';
import AnalyticsFacebookPage from '../../AnalyticsFacebookPage';

const Template = (props: AnalyticsTemplateProps) => {
  const { selectedSocialMedia } = props;

  if (selectedSocialMedia.socialAccountType === SocialAccountType.INSTAGRAM) {
    return <AnalyticsInstagram {...props} />;
  } else if (selectedSocialMedia.socialAccountType === SocialAccountType.YOUTUBE) {
    return <AnalyticsYoutube {...props} />;
  } else if (selectedSocialMedia.socialAccountType === SocialAccountType.FACEBOOK_PAGE) {
    return <AnalyticsFacebookPage {...props} />;
  } else {
    return <NotFound />;
  }
};

export default Template;
