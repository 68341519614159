import { YoutubeAnalyticsPostsSortOrder } from '../../../../../../__generated__/globalTypes';

export const getVideosListSortFiledType = (field?: string | null) => {
  if (!field) {
    return null;
  }

  return YoutubeAnalyticsPostsSortOrder[field as keyof typeof YoutubeAnalyticsPostsSortOrder];
};

export const getYtFeedVideosListColumns = () => [
  {
    title: 'Views',
    orderField: YoutubeAnalyticsPostsSortOrder.VIEWS,
  },
  {
    title: 'Play Time',
    orderField: YoutubeAnalyticsPostsSortOrder.DURATION,
  },
  {
    title: 'Average View Duration',
    orderField: YoutubeAnalyticsPostsSortOrder.AVERAGE_VIEW_DURATION,
  },
  {
    title: 'Average View Duration Rate',
    orderField: YoutubeAnalyticsPostsSortOrder.AVERAGE_VIEW_PERCENTAGE,
  },
  {
    title: 'Like',
    orderField: YoutubeAnalyticsPostsSortOrder.LIKE,
  },
  {
    title: 'Dislike',
    orderField: YoutubeAnalyticsPostsSortOrder.DISLIKE,
  },
  {
    title: 'Comment',
    orderField: YoutubeAnalyticsPostsSortOrder.COMMENT,
  },
  {
    title: 'Positive Rate',
    orderField: YoutubeAnalyticsPostsSortOrder.POSITIVE_RATE,
  },
  {
    title: 'Negative Rate',
    orderField: YoutubeAnalyticsPostsSortOrder.NEGATIVE_RATE,
  },
  {
    title: 'Share',
    orderField: YoutubeAnalyticsPostsSortOrder.SHARE,
  },
  {
    title: 'Post date',
    orderField: YoutubeAnalyticsPostsSortOrder.POST_DATE,
  },
];
