import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import mailBlack from '@src/assets/icon/mailBlack.svg';
import { Button, SocialButton } from '@src/components/atoms/Button';
import { Icon } from '@src/components/atoms/Icon';
import { useQueryHelper } from '@src/libs/hooks';
import { switchImageWhite } from '@src/libs/SocialMedia';
import { SignUpAndSignInBreakpoint } from '@src/libs/theme';
import { SocialAccountType } from '@src/__generated__/globalTypes';

export interface SignInFormProps {
  loading: boolean;
  onClick: (socialMedia: SocialAccountType) => () => void;
}

const SignInForm = ({ onClick, loading }: SignInFormProps) => {
  const [dialog, setDialog] = useState<boolean>(false);
  const { history, t } = useQueryHelper();

  return (
    <div css={styles.container}>
      <Popup
        contentStyle={{
          animation: 'fadeIn linear 0.3s',
          borderRadius: 5,
          maxWidth: 336,
          width: '90%',
        }}
        open={dialog}
        onClose={() => setDialog(false)}
      >
        <div css={styles.dialog}>
          <div css={styles.dialogCloseBtn} onClick={() => setDialog(false)}>
            <Icon icon="close" />
          </div>
          <div css={styles.dialogTitle}>{t('Title.Sign Up')}</div>
          <div>
            <StyledSocialButton
              light
              socialMedia={SocialAccountType.FACEBOOK}
              title="Sign up with Facebook"
              onClick={onClick(SocialAccountType.FACEBOOK)}
            />
            <StyledSocialButton
              light
              socialMedia={SocialAccountType.TWITTER}
              title="Sign up with Twitter"
              onClick={onClick(SocialAccountType.TWITTER)}
            />
            <StyledSocialButton
              css={styles.tiktokBtn}
              light
              socialMedia={SocialAccountType.TIKTOK}
              title="Sign up with TikTok"
              onClick={onClick(SocialAccountType.TIKTOK)}
            />
            <StyledButton
              bgcolor="#fff"
              color="#27313b"
              disabled={loading}
              hoverbgcolor="#fff"
              icon={<img alt="icon" height="24" src={mailBlack} width="24" />}
              title="Sign up with E-Mail"
              onClick={() => history.push('/signup/email')}
            />

            <Hint>※{t('We never post')}</Hint>
            <Hint>※{t('We never share your data with others')}</Hint>
          </div>
        </div>
      </Popup>
      <div>
        <div css={styles.title}>{t('Title.Sign Up')}</div>
        <div css={styles.subTitle}>{t('Annotation.Sign In')}</div>

        <SocialButton
          css={styles.instagramBtn}
          disabled={loading}
          socialMedia={SocialAccountType.INSTAGRAM}
          title="Sign up with Instagram"
          onClick={() => history.push('/signup/instagram')}
        />

        <StyledButton
          bgcolor="#e45c5c"
          color="#fff"
          disabled={loading}
          hoverbgcolor="#e45c5c"
          icon={<img alt="icon" height="24" src={switchImageWhite(SocialAccountType.YOUTUBE)} width="24" />}
          noBorder
          title="Sign up with YouTube"
          onClick={onClick(SocialAccountType.YOUTUBE)}
        />

        <StyledButton
          bgcolor="#fff"
          color="#6e7C89"
          css={styles.signUpOtherPlatformBtn}
          hoverbgcolor="#fff"
          title="Sign-up with other platforms"
          onClick={() => setDialog(true)}
        />

        <Hint>※{t('We will not post on your behalf without your permission')}</Hint>
        <Hint>※{t('Data will not be used for any purpose other than the sponsorships')}</Hint>
      </div>
    </div>
  );
};

const Hint = styled.div`
  color: #6e7c89;
  font-size: 12px;
`;

const StyledButton = styled(Button)<{ noBorder?: boolean }>`
  border-radius: 3px;
  filter: brightness(1);
  height: 32px;
  margin-bottom: 16px;
  position: relative;
  transition: 0.2s;
  ${({ noBorder }) => (noBorder ? '' : 'border: 1px solid #dee5ec;')}

  & > img {
    left: 18px;
    position: absolute;
  }

  & > p {
    font-size: 12px;
    font-weight: 600;
  }

  &:hover {
    filter: brightness(0.9);
  }

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    border-radius: 5px;
    height: 40px;

    &:hover {
      filter: brightness(1);
    }
  }
`;

const StyledSocialButton = styled(SocialButton)`
  border-radius: 3px;
  height: 32px;
  line-height: 40px;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    border-radius: 5px;
    height: 40px;
  }
`;

const styles = {
  container: css`
    align-items: center;
    display: flex;
    padding: 0 24px;
  `,
  dialog: css`
    padding: 32px 16px;
  `,
  dialogCloseBtn: css`
    align-items: center;
    background-color: #000;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: -10px;
    top: -15px;
    width: 40px;

    & > i {
      margin: 0;
    }
  `,
  dialogTitle: css`
    color: #27313b;
    display: flex;
    font-size: 20px;
    font-weight: 600;
    justify-content: center;
    margin-bottom: 8px;
  `,
  instagramBtn: css`
    background-image: linear-gradient(89.37deg, #ebb752 0.07%, #ff6641 31.78%, #e83262 65.06%, #ce3396 99.89%);
    border-radius: 3px;
    height: 32px;
    margin-bottom: 16px;

    @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
      border-radius: 5px;
      height: 40px;
    }
  `,
  subTitle: css`
    color: #27313b;
    font-size: 14px;
    margin-bottom: 16px;
    text-align: center;
  `,
  signUpOtherPlatformBtn: css`
    margin-bottom: 24px;
  `,
  tiktokBtn: css`
    margin-bottom: 16px;
  `,
  title: css`
    color: #27313b;
    display: flex;
    font-size: 20px;
    font-weight: 600;
    justify-content: center;
    margin-bottom: 24px;
  `,
};

export default SignInForm;
