import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import OnlyAvailableOnWebIndicator from '@src/components/molecules/OnlyAvailableOnWebIndicator';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import Form, { dateFormat, FormInformation } from './Form';
import ADD_EMAIL_TEMPLATE from './mutations/AddEmailTemplate.graphql';
import { AddEmailTemplate, AddEmailTemplateVariables } from './mutations/__generated__/AddEmailTemplate';
import UPDATE_EMAIL_TEMPLATE from './mutations/UpdateEmailTemplate.graphql';
import { UpdateEmailTemplate, UpdateEmailTemplateVariables } from './mutations/__generated__/UpdateEmailTemplate';

interface IndexProps extends FormInformation {
  backUrl?: string;
}

const Index = ({ backUrl, fans, id, isDraft, message, scheduleDate, sender, sendNow, subject, tags }: IndexProps) => {
  const { enqueueSnackbar, history, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const validationSchema = yup.object().shape(
    {
      fans: yup.array().when('tags', {
        is: items => items && items.length <= 0,
        then: yup.array().required('requiredRecipient'),
      }),
      message: yup.string().required('requiredMessage'),
      scheduleDate: yup.string().when('sendNow', {
        is: false,
        then: yup.string().required('requiredScheduleDateMessage'),
      }),
      sender: yup.string().email().required('requiredEmailMessage'),
      subject: yup.string().required('requiredSubjectMessage'),
      tags: yup.array().when('fans', {
        is: items => items && items.length <= 0,
        then: yup.array().required('requiredRecipient'),
      }),
    },
    [['fans', 'tags']]
  );

  const [addEmail] = useMutation<AddEmailTemplate, AddEmailTemplateVariables>(ADD_EMAIL_TEMPLATE, {
    onCompleted: () => {
      enqueueSnackbar(t('succeededInCreating'), { variant: 'success' });
      history.push('/emails');
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [updateEmail] = useMutation<UpdateEmailTemplate, UpdateEmailTemplateVariables>(UPDATE_EMAIL_TEMPLATE, {
    refetchQueries: ['GetEmailTemplate'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
      history.push('/emails');
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const onSubmit = async (values: FormInformation) => {
    if (!values.id) {
      await addEmail({
        variables: { input: { ...values, scheduleDate: values.sendNow ? null : values.scheduleDate } },
      });
    } else {
      await updateEmail({
        variables: { input: { ...values, id: values.id, scheduleDate: values.sendNow ? null : values.scheduleDate } },
      });
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        fans,
        id,
        isDraft,
        message,
        scheduleDate,
        sender,
        sendNow,
        subject,
        tags,
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {isMobileView ? <OnlyAvailableOnWebIndicator to={backUrl || ''} /> : <Form />}
    </Formik>
  );
};

export default Index;
export { dateFormat };
