import React from 'react';
import { useTranslation } from 'react-i18next';
import css from '@emotion/css';
import { getIconByName } from '../../../libs/getIconByName';
import { mainWhite } from '../../../libs/pallete';
import { PalleteButton } from '../../atoms/Button';
import * as StyledComponents from './StyledComponents';

interface WarningWidgetProps {
  warningTitle: string;
  warningReason: string;
  buttonText?: string;
  className?: string;
  buttonLink?: string;
  targetBlank?: boolean;
  showWarningIcon?: boolean;
}
export const WarningWidget = (props: WarningWidgetProps) => {
  const { t } = useTranslation();
  const {
    warningTitle,
    warningReason,
    buttonText,
    buttonLink = '/settings/payment_information',
    className,
    targetBlank = false,
    showWarningIcon = true,
  } = props;

  return (
    <StyledComponents.ReasonReportWrapper className={className}>
      {/* Icon column */}
      {showWarningIcon && (
        <StyledComponents.WarningIconColumn>
          <StyledComponents.WarningIcon src={getIconByName('warning')} />
        </StyledComponents.WarningIconColumn>
      )}
      {/* Reason column */}
      <StyledComponents.ReasonBody>
        <StyledComponents.ReasonHeader>{t(warningTitle)}</StyledComponents.ReasonHeader>
        <StyledComponents.ReasonText css={marginBottom8}>{t(warningReason)}</StyledComponents.ReasonText>
        {!!buttonText && (
          <PalleteButton
            text={buttonText}
            palleteColor={mainWhite}
            href={buttonLink}
            width="max-content"
            height="32px"
            targetBlank={targetBlank}
          />
        )}
      </StyledComponents.ReasonBody>
    </StyledComponents.ReasonReportWrapper>
  );
};

const marginBottom8 = css`
  margin-bottom: 8px;
`;
