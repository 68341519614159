import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { SliderComponents, StyledComponents } from '@src/components/molecules/SliderTable';
import { useQuery } from '@apollo/client';
import { Period } from '@src/components/molecules/DayPicker/types';
import TextCutter from '@src/components/atoms/TextCutter';
import { useQueryHelper } from '@src/libs/hooks';
import { defaultInfluencerAvatar } from '@src/libs/image';
import { ListIndicator } from '@src/components/molecules/Indicator';
import AnalyticsContentWrapper, {
  ContentType,
} from '@src/components/organisms/AnalyticsShared/AnalyticsContentWrapper';
import { useMediaQuery } from 'react-responsive';
import { ViewportType } from '@src/libs/theme';
import { ThumbnailColumn } from '@src/components/atoms/List';
import { TagRankingSlider } from '../TagRanking/TagRankingSlider';
import { AvatarAndNameType } from '../OverView/types';
import {
  InfluencerYoutubeCompareTagRanking,
  InfluencerYoutubeCompareTagRankingVariables,
} from './__generated__/InfluencerYoutubeCompareTagRanking';
import * as YT_COMPARE_TAG_RANKING from './InfluencerYoutubeCompareTagRanking.graphql';
import { AccountAndNameWithTagRankingType } from './types';

interface ComparableTagRankingProps {
  accountsAvatarAndName: AvatarAndNameType[];
  visibleAccountsIds: number[];
  isMainAccountVisible: boolean;
  period: Period;
  id: number;
}
const ComparableTagRanking = ({
  accountsAvatarAndName,
  id,
  period,
  isMainAccountVisible,
  visibleAccountsIds,
}: ComparableTagRankingProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const isTablet = useMediaQuery({ minWidth: ViewportType.TABLET });
  const { data, loading } = useQuery<InfluencerYoutubeCompareTagRanking, InfluencerYoutubeCompareTagRankingVariables>(
    YT_COMPARE_TAG_RANKING,
    {
      variables: { socialAccountId: id, ...period },
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
    }
  );
  if (loading) {
    return (
      <ListIndicator
        css={css`
          margin: 0;
        `}
      />
    );
  }
  const comparedTagsData = data?.influencerYoutubeCompareTagRanking;

  if (!comparedTagsData) {
    return null;
  }

  const visibleCompareAccounts = comparedTagsData.compareAccounts.filter(acc =>
    visibleAccountsIds.includes(acc.accountId)
  );
  // order of visibleCompareAccounts and accounts from tagRanking are different
  const accountsWithAvatar = accountsAvatarAndName.reduce<AccountAndNameWithTagRankingType[]>((acc, curr, i) => {
    if (i === 0 && isMainAccountVisible) {
      return [...acc, { ...curr, ...comparedTagsData.mainAccount }];
    } else {
      const matchedAccount = visibleCompareAccounts.find(el => el.accountId === curr.id);
      if (matchedAccount) {
        return [...acc, { ...curr, ...matchedAccount }];
      } else {
        return acc;
      }
    }
  }, []);

  return (
    <AnalyticsContentWrapper contentType={ContentType.TagRanking}>
      <SliderComponents.Wrapper css={styles.wrapper}>
        <SliderComponents.Container>
          <SliderComponents.SliderSection>
            <SliderComponents.SliderTable css={{ width: '100%' }}>
              <thead>
                <SliderComponents.HeaderRow css={{ backgroundColor: '#F6F8FA' }}>
                  <StyledComponents.StyledHeaderColumn
                    title="Account"
                    css={[styles.firstHeaderCol, isTablet ? { width: 180 } : '']}
                  />
                  <HeaderImitation>
                    <HeaderTitle css={{ flex: 1 }}></HeaderTitle>
                  </HeaderImitation>
                </SliderComponents.HeaderRow>
              </thead>

              <tbody>
                {accountsWithAvatar.map((item, index) => (
                  <StyledComponents.StyledRowNew css={styles.row} key={index}>
                    {isTablet ? (
                      <ThumbnailCell
                        imageWidth="42px"
                        src={defaultInfluencerAvatar(item.avatar)}
                        title={item.name}
                        titleWidth="100%"
                      />
                    ) : (
                      <td css={styles.accountCol}>
                        <AccountCell>
                          <Avatar src={defaultInfluencerAvatar(item.avatar)} />
                          <TextCutter text={item.name} lines={2} css={styles.accountName} />
                        </AccountCell>
                      </td>
                    )}

                    <SectionWrapper>
                      <TagRankingSlider tags={item.tagsRanking} />
                    </SectionWrapper>
                  </StyledComponents.StyledRowNew>
                ))}
              </tbody>
            </SliderComponents.SliderTable>
          </SliderComponents.SliderSection>
        </SliderComponents.Container>
      </SliderComponents.Wrapper>
    </AnalyticsContentWrapper>
  );
};

const styles = {
  wrapper: css`
    padding: 0;
    margin: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
  `,
  firstHeaderCol: css`
    width: 80px;
    justify-content: flex-start;

    & > div {
      justify-content: flex-start;
      border-top: none;
    }
  `,
  row: css`
    padding: 0;
    height: 82px;
  `,
  accountCol: css`
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: unset;
    filter: drop-shadow(2px 0 0 #efefef);
  `,
  accountName: css`
    text-align: center;
    font-size: 10px;
    font-weight: 400;
    line-height: 11px;
  `,
};
const HeaderImitation = styled.th`
  line-height: 46px;
  display: flex;
`;
const HeaderTitle = styled.h3`
  display: flex;
  align-items: center;
  color: #27313b;
  font-size: 11px;
  font-weight: 600;
  line-height: 48px;
  padding-left: 16px;
`;
const AccountCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-bottom: 4px;
`;
const SectionWrapper = styled.td`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 !important;
  border-top: none !important;
`;
const ThumbnailCell = styled(ThumbnailColumn)<{ titleWidth?: string }>`
  border-right: 1px solid #dee5ec;

  & div > div:first-of-type {
    border-radius: 20px;
    height: 40px;
    width: 40px;
  }

  p {
    max-height: 33px;
    min-width: unset;
    overflow: hidden;
    text-overflow: ellipsis;
    width: ${({ titleWidth }) => titleWidth};
  }
`;
export default ComparableTagRanking;
