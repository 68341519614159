import * as React from 'react';
import SignUpForm from '@src/components/organisms/SignUpForm';
import TopPageContainer from '@src/components/organisms/TopPage/TopPageContainer';
import TopPageContainerUuum from '@src/components/organisms/TopPage/TopPageContainer/index_backup';
import { useUuumInfluencerView } from '@src/libs/hooks';

const Template = () => {
  const { isUuumView } = useUuumInfluencerView();

  return isUuumView ? (
    <TopPageContainerUuum>
      <SignUpForm />
    </TopPageContainerUuum>
  ) : (
    <TopPageContainer showBgCover>
      <SignUpForm />
    </TopPageContainer>
  );
};

export default Template;
