import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import userpicIcon from '@src/assets/icon/userpic_stats.svg';
import youtubeVideoIcon from '@src/assets/icon/youtubeVideo.svg';
import thunderIcon from '@src/assets/icon/thunder.svg';
import thumbsUpIcon from '@src/assets/icon/thumbs-up.svg';
import thumbsDownIcon from '@src/assets/icon/thumbs-down.svg';
import commentsIcon from '@src/assets/icon/comment.svg';
import { getGrowthArrow, getGrowthPrefix } from '@src/components/organisms/ProfileDetail/AnalyticsProfile/helpers';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import {
  allSelectableCharts,
  ChartColorTypes,
  getSeriesAndCategories,
  generateYAxisBasedOnDisplayedCharts,
  getEngagementDataWithHistory,
  getBarDataBasedOnPointer,
  colors,
} from './helper';
import { OverviewStats } from './types';

export const getOptions = (
  displayedCharts: ChartColorTypes[],
  dashboardOverview: OverviewStats,
  fetchDailyPosts: (date: string) => void
): {
  options: Highcharts.Options;
  engagement: Array<{ icon: string; title: string; total: number; growth: number; rate: number }>;
} => {
  const { t, i18n } = useTranslation();
  const [chartState, setChartState] = useState<Highcharts.Chart | undefined>(undefined);
  const { subscriber, posts, views, likes, dislikes, comments } = dashboardOverview;
  const { history: subscribersHistory, engagement: subscribersEngagement } = getEngagementDataWithHistory(subscriber);
  const { history: postsHistory, engagement: postsEngagement } = getEngagementDataWithHistory(posts);
  const { history: viewsHistory, engagement: viewsEngagement } = getEngagementDataWithHistory(views);
  const { history: likesHistory, engagement: likesEngagement } = getEngagementDataWithHistory(likes);
  const { history: dislikesHistory, engagement: dislikesEngagement } = getEngagementDataWithHistory(dislikes);
  const { history: commentsHistory, engagement: commentsEngagement } = getEngagementDataWithHistory(comments);

  // we are showing X-Axis dates based on displayed charts checkboxes
  const { series, categories, tooltipData } = getSeriesAndCategories({
    historyData: {
      subscribersHistory,
      postsHistory,
      viewsHistory,
      likesHistory,
      dislikesHistory,
      commentsHistory,
    },
    displayedCharts,
  });
  const yAxis = generateYAxisBasedOnDisplayedCharts<ChartColorTypes>(allSelectableCharts, false);

  return {
    engagement: [
      {
        icon: userpicIcon,
        title: 'Subscriber (Total)',
        ...subscribersEngagement,
      },
      { icon: youtubeVideoIcon, title: 'Post', ...postsEngagement },
      { icon: thunderIcon, title: 'Views (Total)', ...viewsEngagement },
      { icon: thumbsUpIcon, title: 'Like', ...likesEngagement },
      { icon: thumbsDownIcon, title: 'Dislike', ...dislikesEngagement },
      { icon: commentsIcon, title: 'Comments', ...commentsEngagement },
    ],
    options: {
      chart: {
        type: 'column',
        height: 250,
        events: {
          // eslint-disable-next-line object-shorthand
          click: function (e) {
            const selectedPoint = getBarDataBasedOnPointer(this, e, [colors.Post]);
            // if Y-axis value is other than 0, means that there are some posts on this day
            if (selectedPoint?.length && selectedPoint[0].y) {
              fetchDailyPosts(`${selectedPoint[0].category}`);
            }
          },
          // eslint-disable-next-line object-shorthand
          load: function () {
            setChartState(this);
          },
        },
      },
      title: {
        text: '',
      },
      xAxis: {
        categories,
        gridLineWidth: 1,
        crosshair: true,
        type: 'category',
        labels: {
          formatter: function formatter() {
            return localizedDateFormatter(this.value, 'MMM do', i18n.language as AppLanguage);
          },
        },
      },
      yAxis,
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click() {
                if (colors.Post === this.color) {
                  fetchDailyPosts(`${this.category}`);
                }
              },
              // eslint-disable-next-line object-shorthand
              mouseOver: function () {
                if (tooltipData[this.category]?.Post.value !== '-') {
                  /* eslint-disable */
                  // @ts-ignore
                  chartState?.chartBackground?.htmlCss({ cursor: 'pointer' });
                }
              },
              mouseOut: () => {
                // @ts-ignore
                chartState?.chartBackground?.htmlCss({ cursor: 'auto' });
              },
            },
          },
        },
      },
      tooltip: {
        useHTML: true,
        borderColor: '#27313b',
        borderRadius: 3,
        outside: true,
        shadow: false,
        formatter: function formatter(): string {
          const followersIncrement = tooltipData[this.key].Subscribers.increment;
          const incrementArrow = getGrowthArrow(followersIncrement);
          const prefix = getGrowthPrefix(followersIncrement);

          const incrementSection = followersIncrement
            ? `<span style="color: ${
                followersIncrement > 0 ? '#40b87c' : '#ff2b52'
              };">${prefix} ${followersIncrement} ${incrementArrow}</span>`
            : '';

          return `
          <div style="display:flex;flex-direction:column;border-radius:3px;border: 2px solid #27313b;margin:-2px;background-color:#27313b;font-size:12px;padding:13px 16px;">
            <h3 style="margin-bottom:8px; font-size: 13px; color: #fff;">${this.key}</h3>
            <div style="display:flex; flex-direction: column;">

              <div style="display: flex;">
                <div style="display: flex; flex: 1; width: max-content; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Subscribers')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key].Subscribers.value}
                      ${incrementSection}
                    </p>
                  </div>
                </div>
  
                <div style="display: flex;  flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Post')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key].Post.value}
                    </p>
                  </div>
                </div>
  
                <div style="display: flex;  flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('View')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key].Views.value}
                    </p>
                  </div>
                </div>
              </div>

              <div style="display: flex; margin-top: 12px;">
                <div style="display: flex; flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Like')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key].Like.value}
                    </p>
                  </div>
                </div>

                <div style="display: flex; flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Dislike')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key].Dislike.value}
                    </p>
                  </div>
                </div>

                <div style="display: flex;  flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Comment')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key].Comments.value}
                    </p>
                  </div>
                </div>           
              </div>
            </div>
          </div>`;
        },
        pointFormat: '',
      },
      series,
    },
  };
};
