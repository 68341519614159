import React, { useEffect } from 'react';
import { usePostDetailModal } from '@src/components/organisms/AnalyticsShared/BasePostList/usePostDetailModal';
import CarouselModal from '@src/components/molecules/CarouselModal/CarouselModal';
import InfluencerPostDetail from '../InfluencerPostDetail';

interface LikeCommentPostsProps {
  posts: { id: number }[];
  className?: string;
  influencerAccountId: number;
  clearPostIds: () => void;
}
const PostByDateWithModal = (props: LikeCommentPostsProps) => {
  const { posts, influencerAccountId, clearPostIds } = props;

  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickNext,
    handleClickPrev,
    handleClickPost,
  } = usePostDetailModal(posts || []);

  useEffect(() => {
    if (posts.length) {
      handleClickPost(posts[0].id);
    }
  }, [posts.length]);

  const closePostDetailModal = () => {
    handleClosePostDetail();
    clearPostIds();
  };

  return (
    <>
      <CarouselModal
        open={!!openPostDetail}
        title=""
        cancel="OK"
        onClose={closePostDetailModal}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        siblingPostId={siblingPostId}
      >
        <InfluencerPostDetail influencerId={influencerAccountId} closeModal={closePostDetailModal} />
      </CarouselModal>
    </>
  );
};

export default PostByDateWithModal;
