import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { formatIntNumber } from '../../../../libs/format';

export interface AmountGraphProps {
  className?: string;
  remainingAmount: number;
  totalAmount: number;
  showLabel?: boolean;
}
const CampaignBudget = (props: AmountGraphProps) => {
  const { className, remainingAmount, totalAmount, showLabel } = props;

  const isRemaining = remainingAmount > 0 && totalAmount > 0;
  const amountWidth = isRemaining ? (100 * (totalAmount - remainingAmount)) / totalAmount : 100;

  const { t } = useTranslation();

  return (
    <Container className={className}>
      {showLabel && <Label>{t('Label.Budget')}</Label>}
      <GraphWrapper>
        <Graph width={amountWidth} />
      </GraphWrapper>
      <Indicator isOverBudget={!isRemaining}>{formatIntNumber(amountWidth)}&nbsp;%</Indicator>
    </Container>
  );
};

const Label = styled.span`
  font-size: 11px;
  font-weight: 600;
  color: #27313b;
  margin-right: 16px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const Indicator = styled.span<{ isOverBudget: boolean }>`
  ${({ isOverBudget }) => `
  width: 40px;
  height: 16px;
  border-radius: 12px;
  background-color: ${isOverBudget ? '#ff2b52' : '#c5d0da'};
  font-size: 11px;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 12px;

  &::before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3px 6px 3px 0;
    border-color: transparent ${isOverBudget ? '#ff2b52' : '#c5d0da'} transparent transparent;
    content: '';
    position: absolute;
    left: -5px;
  }
  `}
`;

const GraphWrapper = styled.div`
  flex: 1;
  position: relative;
  box-shadow: inset 0 1px 2px 0 rgba(39, 49, 59, 0.15);
  background-color: #dee5ec;
  border-radius: 4px;
  height: 8px;
`;

const Graph = styled.span<{ width: number }>`
  width: ${({ width }) => `${width}%`};
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to right, #ffa756, #ff2b52);
  border-radius: 4px;
  height: 100%;
  max-width: 100%;
`;

export default CampaignBudget;
