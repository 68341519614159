import React from 'react';
import { useQueryHelper } from '@src/libs/hooks';
import YoutubeCompareFeedVideos from './YoutubeCompareFeedVideos';

const InfluencerCompareFeedVideos = () => {
  const { enqueueSnackbar, history, search, t } = useQueryHelper();
  const query = new URLSearchParams(search);
  const socialAccountId = query.get('id');

  if (!socialAccountId) {
    history.push(`/profile:compare${search}`);
    enqueueSnackbar(t('Cannot find comparable accounts information'), { variant: 'error' });

    return null;
  }

  return <YoutubeCompareFeedVideos socialAccountId={socialAccountId} />;
};

export default InfluencerCompareFeedVideos;
