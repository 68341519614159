import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Locize from 'i18next-locize-backend';
import { languageDetector } from './languageDetector';

declare const LOCIZE_API_KEY: string;
declare const LOCIZE_PROJECT_ID: string;

const locizeOptions = {
  apiKey: LOCIZE_API_KEY,
  projectId: LOCIZE_PROJECT_ID,
  allowedAddOrUpdateHosts: ['localhost'],
};

i18next
  .use(Locize)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    initImmediate: false,
    fallbackLng: 'en',
    // we don't save missing keys, they have to be added by hand
    saveMissing: false,
    defaultNS: 'influencer',
    ns: ['influencer'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: locizeOptions,
    react: { wait: true, useSuspense: false },
  });

export const LANGUAGE_OPTIONS = [
  { value: 'en', label: 'English' },
  { value: 'ja', label: '日本語' },
  { value: 'th', label: 'ไทย' },
  { value: 'ch', label: '简体中文' },
  { value: 'id', label: 'Bahasa' },
  { value: 'km', label: 'ភាសាខ្មែរ' },
  { value: 'my', label: 'မြန်မာဘာသာ' },
  { value: 'tw', label: '繁體中文' },
  { value: 'vi', label: 'Tiếng Việt' },
];

export default i18next;
