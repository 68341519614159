import React from 'react';
import { bigIntFormatter } from '@src/libs/format';
import { defaultEmptyImage } from '@src/libs/image';
import { useQueryHelper } from '@src/libs/hooks';
import { convertDurationToHhMmSs } from '@src/libs/date';
import * as StyledComponents from '../../StyledComponents';

interface YoutubePreviewItemProps {
  id: number;
  thumbNail: string | null;
  title: string;
  videoDuration: number;
  views: number;
  likes: number;
  comments: number;
  handleClickPost: (id: number) => void;
}
export const YoutubePreviewItem = ({
  id,
  thumbNail,
  title,
  videoDuration,
  views,
  handleClickPost,
  likes,
  comments,
}: YoutubePreviewItemProps) => {
  const { t } = useQueryHelper();

  return (
    <StyledComponents.VideoContainerWrapper>
      <StyledComponents.VideoWrapper>
        <StyledComponents.VideoThumbnail
          // eslint-disable-next-line no-undef
          src={defaultEmptyImage(thumbNail)}
          onClick={() => handleClickPost(id)}
        />
        <StyledComponents.VideoDuration>{convertDurationToHhMmSs(videoDuration)}</StyledComponents.VideoDuration>
      </StyledComponents.VideoWrapper>
      <StyledComponents.VideoTitle title={title}>{title}</StyledComponents.VideoTitle>
      <StyledComponents.EngagementInfo>
        <StyledComponents.InfoWrapper>
          <StyledComponents.Label>{t('View')}</StyledComponents.Label>
          <StyledComponents.Count title={`${views}`}>
            {bigIntFormatter(views, 0, { k: true, m: true })}
          </StyledComponents.Count>
        </StyledComponents.InfoWrapper>
        <StyledComponents.InfoWrapper>
          <StyledComponents.Label>{t('Like')}</StyledComponents.Label>
          <StyledComponents.Count title={`${likes}`}>
            {bigIntFormatter(likes, 0, { k: true, m: true })}
          </StyledComponents.Count>
        </StyledComponents.InfoWrapper>
        <StyledComponents.InfoWrapper>
          <StyledComponents.Label>{t('General.Comments')}</StyledComponents.Label>
          <StyledComponents.Count title={`${comments}`}>
            {bigIntFormatter(comments, 0, { k: true, m: true })}
          </StyledComponents.Count>
        </StyledComponents.InfoWrapper>
      </StyledComponents.EngagementInfo>
    </StyledComponents.VideoContainerWrapper>
  );
};
