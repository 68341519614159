import React from 'react';
import AnalyticsContentWrapper, {
  ContentType,
  sectionStyles,
} from '@src/components/organisms/AnalyticsShared/AnalyticsContentWrapper';
import { useQuery } from '@apollo/client';
import AccountsInterestChart from '@src/components/organisms/ProfileWidgets/AccountsInterest/template';
import PostingHabits from '@src/components/organisms/ProfileWidgets/PostingHabits';
import { useQueryHelper } from '@src/libs/hooks';
import { ListIndicator } from '@src/components/molecules/Indicator';
import FeedPosts from '@src/components/organisms/AnalyticsInstagram/Dashboard/PostCard/FeedPost';
import { SocialMediaAccountType } from '@src/components/organisms/ProfileDetail/types';
import { InfluencerProfilePosts, InfluencerProfilePostsVariables } from './__generated__/InfluencerProfilePosts';
import * as INFLUENCER_PROFILE_POSTS from './InfluencerProfilePosts.graphql';
import AverageEngagementInstagram from './AverageEngagementInstagram';

interface ProfilePostsProps {
  influencerAccountId: number;
  selectedSocialMedia: SocialMediaAccountType;
  hasNoEstimatedApiText: string;
}
export const ProfilePostsSection = ({
  selectedSocialMedia,
  influencerAccountId,
  hasNoEstimatedApiText,
}: ProfilePostsProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { socialAccountType, id: socialMediaAccountId } = selectedSocialMedia;
  const { data, loading } = useQuery<InfluencerProfilePosts, InfluencerProfilePostsVariables>(
    INFLUENCER_PROFILE_POSTS,
    {
      variables: {
        pk: influencerAccountId,
        socialAccountId: socialMediaAccountId,
        socialMedia: socialAccountType,
      },
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
    }
  );

  if (loading) {
    return <ListIndicator css={sectionStyles.loading} />;
  }

  const interestRate = data?.influencerProfilePosts?.accountInterests || [];
  const feedPosts = data?.influencerProfilePosts?.feedPosts || [];
  const postsHabit = data?.influencerProfilePosts?.postsHabit || [];
  const averageComments = data?.influencerProfilePosts?.averageComments || null;
  const averageLikes = data?.influencerProfilePosts?.averageLikes || null;
  const averagePostsPerWeek = data?.influencerProfilePosts?.averagePostsPerWeek || null;
  const averageSaved = data?.influencerProfilePosts?.averageSaved || null;
  const engagementRate = data?.influencerProfilePosts?.engagementRate || null;
  const estimatedReach = data?.influencerProfilePosts?.estimateReach || null;

  return (
    <AnalyticsContentWrapper contentType={ContentType.Posts} hasHelp={false}>
      <div css={sectionStyles.responsiveWrapper}>
        <AverageEngagementInstagram
          averagePostsPerWeek={averagePostsPerWeek}
          averageLikes={averageLikes}
          averageSaved={averageSaved}
          averageComments={averageComments}
          engagementRate={engagementRate}
          estimatedReach={estimatedReach}
        />

        <AccountsInterestChart
          interestRate={interestRate}
          wrapperText={hasNoEstimatedApiText}
          css={[sectionStyles.middleSection, sectionStyles.verticalContaner]}
        />
        <PostingHabits postsHabit={postsHabit} css={sectionStyles.verticalContaner} />
      </div>
      <FeedPosts posts={feedPosts} selectedSocialMedia={selectedSocialMedia} hasMoreLink={false} />
    </AnalyticsContentWrapper>
  );
};
