import { useFilter } from '@src/components/organisms/AnalyticsShared/BasePostListFilter/useFilter';
import { getThirtyDaysAgo, getToday } from '@src/libs/date';
import { usePaging } from '@src/libs/paging';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAuthData, useQueryHelper } from '@src/libs/hooks';
import { useQuery } from '@apollo/client';
import Template from './template';
import * as GET_ACCOUNT_USERNAME from './GetAccountUsername.graphql';
import { InfluencerProfileV2, InfluencerProfileV2Variables } from './__generated__/InfluencerProfileV2';

const IgTaggedOrMentionedPostsFeed = () => {
  const { userId } = useAuthData();
  const { search, t, enqueueSnackbar } = useQueryHelper();
  if (!userId) {
    return <Redirect to="/home" />;
  }

  const { data } = useQuery<InfluencerProfileV2, InfluencerProfileV2Variables>(GET_ACCOUNT_USERNAME, {
    variables: { pk: userId },
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  const searchParams = new URLSearchParams(search);
  const [id, stda, enda] = [
    searchParams.get('socialMediaAccountId') as string,
    searchParams.get('stda') as string,
    searchParams.get('enda') as string,
  ];

  const currentPage = usePaging();
  const { filter, setFilter } = useFilter({
    startDate: stda || getThirtyDaysAgo(),
    endDate: enda || getToday(),
  });

  return (
    <Template
      accountId={Number(id)}
      currentPage={currentPage}
      filter={filter}
      setFilter={setFilter}
      userId={userId}
      accountName={data?.influencerProfileV2?.name}
    />
  );
};

export default IgTaggedOrMentionedPostsFeed;
