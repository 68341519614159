import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { FanListFilterType } from '../types';
import BasePostListFilter from './FanListFilter';
import { FormValues } from './types';
import { getInitialFormValues, getFilterData } from './helper';

interface FanListFilterComponentProps {
  filter: FanListFilterType;
  selectedFansId: Set<number>;
  setFilter: (filter: FanListFilterType) => void;
  onSort: () => void;
}

const FanListFilterComponent = ({ filter, selectedFansId, setFilter, onSort }: FanListFilterComponentProps) => {
  const handleSubmit = (payload: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    setFilter(getFilterData(payload));
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={getInitialFormValues(filter)}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <BasePostListFilter filter={filter} onSort={onSort} selectedFansId={selectedFansId} />
    </Formik>
  );
};

export default FanListFilterComponent;
