import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { useField, useFormikContext } from 'formik';
import React, { useCallback, useState, Ref } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import thanksPage from '@src/assets/img/formThanksPage.png';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import { Icon } from '@src/components/atoms/Icon';
import Textarea from '@src/components/atoms/Textarea';
import Dialog from '@src/components/molecules/Dialog';
import TextForm from '@src/components/molecules/TextForm';
import { formState, submitFormState, useSetRecoilState, useRecoilValue } from '@src/recoilAtoms';
import { FormStatus, QuestionType } from '@src/__generated__/globalTypes';
import DynamicInput, { Questions } from './DynamicInput';

export interface FormInformation {
  description: string;
  hash?: string;
  id?: any;
  questions: Questions[];
  status: FormStatus;
  title: string;
  thankDescription: string;
  thankTitle: string;
}

export interface Refs {
  backButtonRef: Ref<HTMLButtonElement>;
  previewButtonRef: Ref<HTMLButtonElement>;
  submitButtonRef: Ref<HTMLButtonElement>;
}

interface FormProps {
  isFormAnswerSubmitted: boolean;
  refs: Refs;
}

const Form = ({ isFormAnswerSubmitted, refs }: FormProps) => {
  const history = useHistory();
  const [isLeaveDialogOpen, setIsLeaveDialogOpen] = useState<boolean>(false);
  // form is able to draft edit/preview without saving, using recoil to save the draft information
  const formInformation = useRecoilValue(formState);
  const setFormState = useSetRecoilState(formState);
  const setSubmitFormState = useSetRecoilState(submitFormState);
  const { initialValues, values, handleSubmit, setFieldValue, validateForm } = useFormikContext<FormInformation>();
  const [descriptionField, descriptionMeta] = useField('description');
  const [idField] = useField('id');
  const [questionsField, questionsMeta] = useField<Questions[]>('questions');
  const [thankDescriptionField, thankDescriptionMeta] = useField('thankDescription');
  const [thankTitleField, thankTitleMeta] = useField('thankTitle');
  const [titleField, titleMeta] = useField('title');
  const { t } = useTranslation();

  const onDragInput = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      let items = [...questionsField.value];
      const dragItem = items[dragIndex];
      items.splice(dragIndex, 1);
      items.splice(hoverIndex, 0, dragItem);
      items = items.map((item, key) => {
        item.order = key + 1;

        return item;
      });

      setFieldValue('questions', items);
    },
    [questionsField.value]
  );

  const onChangeInput = (index: number, value: Questions) => {
    const items = [...questionsField.value];
    items[index] = value;
    setFieldValue('questions', items);
  };

  const onClickAddInput = () => {
    const nextOrder = questionsField.value.length + 1;
    const item = {
      genId: uuidv4(),
      image: null,
      isRequired: false,
      options: [],
      order: nextOrder,
      questionType: QuestionType.SHORT_ANSWER,
      title: '',
    };
    setFieldValue('questions', questionsField.value.concat(item));
  };

  const onClickLeave = () => {
    if (formInformation || !isEqual(initialValues, values)) {
      setIsLeaveDialogOpen(true);
    } else {
      history.push('/forms');
    }
  };

  const onClickPreview = () => {
    validateForm().then(error => {
      if (isEmpty(error)) {
        if (!initialValues.id || !isEqual(initialValues, values)) {
          const { description, questions, status, thankDescription, thankTitle, title } = values;
          const previewItems = {
            description,
            hash: '',
            id: '',
            questions: questions.map(question => {
              const { image, isRequired, options, order: questionOrder, questionType, title: questionTitle } = question;

              return {
                id: uuidv4(),
                image,
                isRequired,
                options: options.map(option => {
                  const { optionTitle, order: optionOrder } = option;

                  return {
                    id: uuidv4(),
                    optionTitle,
                    order: optionOrder,
                  };
                }),
                order: questionOrder,
                questionType,
                title: questionTitle,
              };
            }),
            status,
            thankDescription,
            thankTitle,
            title,
          };

          // check if the initial values is same with current values, if the form is previewing before save
          // save the information into atoms for draft edit/preview
          setFormState(values);
          setSubmitFormState(previewItems);
        }
        const formId = idField.value;
        history.push(formId ? `/forms/${formId}/live_preview` : '/forms/add/live_preview');
      } else {
        handleSubmit();
      }
    });
  };

  const onCopyInput = (value: Questions) => {
    let items = [...questionsField.value];
    items = items.concat({ ...value, genId: uuidv4(), order: questionsField.value.length + 1 });
    setFieldValue('questions', items);
  };

  const onDeleteInput = (index: number) => {
    // having readonly properties with es6 clone, so using lodash cloneDeep
    let items = cloneDeep(questionsField.value);
    items.splice(index, 1);
    items = items.map((item, key) => {
      item.order = key + 1;

      return item;
    });
    setFieldValue('questions', items);
  };

  const isEmailExist = !!questionsField.value.find(question => question.questionType === QuestionType.EMAIL);
  const isNameExist = !!questionsField.value.find(question => question.questionType === QuestionType.NAME);

  return (
    <form onSubmit={handleSubmit}>
      <HiddenButton ref={refs.backButtonRef} type="button" onClick={onClickLeave} />
      <HiddenButton ref={refs.previewButtonRef} type="button" onClick={onClickPreview} />
      <HiddenButton ref={refs.submitButtonRef} type="submit" />
      <Dialog
        execText="Leave"
        visible={isLeaveDialogOpen}
        onClose={() => setIsLeaveDialogOpen(false)}
        onExec={() => history.push('/forms')}
      >
        <div css={styles.dialogContent}>
          <span>{t('Dialog.Leave Form')}</span>
          <span>{t('Annotation.Changes you made may not be saved')}</span>
        </div>
      </Dialog>
      <div css={styles.container}>
        <div css={styles.formContainer}>
          <InputContainer marginBottom={16}>
            <StyledTextForm
              css={styles.textFormTitle}
              placeholder={t('TextForm.Form Title')}
              value={titleField.value}
              onChange={e => setFieldValue('title', e.target.value)}
            />
            {titleMeta.error && <ErrorMessage message={t(titleMeta.error)} />}
          </InputContainer>

          <InputContainer marginBottom={24}>
            <StyledTextForm
              placeholder={t('TextForm.Description')}
              value={descriptionField.value}
              onChange={e => setFieldValue('description', e.target.value)}
            />
            {descriptionMeta.error && <ErrorMessage message={t(descriptionMeta.error)} />}
          </InputContainer>

          <DndProvider backend={HTML5Backend}>
            <div>
              {questionsField.value.map((question, index) => {
                const { genId, questionType } = question;

                return (
                  <DynamicInput
                    error={!!questionsMeta.error}
                    isEmailExist={questionType !== QuestionType.EMAIL && isEmailExist}
                    isFormAnswerSubmitted={isFormAnswerSubmitted}
                    isNameExist={questionType !== QuestionType.NAME && isNameExist}
                    key={genId}
                    sequence={index}
                    value={{ ...question }}
                    onChange={value => onChangeInput(index, value)}
                    onCopy={value => onCopyInput(value)}
                    onDelete={() => onDeleteInput(index)}
                    onDrag={onDragInput}
                  />
                );
              })}
            </div>
          </DndProvider>

          <div css={styles.addOptionContainer}>
            <div onClick={onClickAddInput}>
              <Icon color="#6E7C89" icon="add" />
            </div>
          </div>

          <div css={styles.nextPageDivider}>
            <h2>
              <span>{t('Next Page')}</span>
            </h2>
          </div>

          <div css={styles.thanksPageContainer}>
            <div>{t('Thanks Page')}</div>
            <div>
              <img alt="bgImg" height="120" src={thanksPage} width="180" />
            </div>
            <div>
              <InputContainer marginBottom={16}>
                <StyledTextForm
                  css={styles.thanksPageTitle}
                  placeholder={t('Your response has been recorded')}
                  value={thankTitleField.value}
                  onChange={e => setFieldValue('thankTitle', e.target.value)}
                />
                {thankTitleMeta.error && <ErrorMessage message={t(thankTitleMeta.error)} />}
              </InputContainer>

              <InputContainer marginBottom={0}>
                <StyledTextArea
                  css={styles.textFormTitle}
                  placeholder={t(
                    'Thank you very much for your response. As soon as we confirm the content of your inquiry, our staff will contact you regarding our future actions'
                  )}
                  value={thankDescriptionField.value}
                  onChange={e => setFieldValue('thankDescription', e.target.value)}
                />
                {thankDescriptionMeta.error && <ErrorMessage message={t(thankDescriptionMeta.error)} />}
              </InputContainer>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

const InputContainer = styled.div<{ marginBottom: number }>`
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
`;

const HiddenButton = styled.button`
  display: none;
`;

const StyledTextArea = styled(Textarea)`
  border-radius: 3px;
`;

const StyledTextForm = styled(TextForm)`
  & > label {
    font-size: 14px;
  }

  & input {
    border-radius: 3px;
    height: 32px;
  }
`;

const styles = {
  actionContainer: css`
    position: absolute;
    right: 24px;
    top: 16px;
  `,
  addOptionContainer: css`
    display: flex;
    justify-content: center;
    margin-bottom: 48px;

    & > div {
      align-items: center;
      border: 1px solid #dee5ec;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      height: 32px;
      justify-content: center;
      width: 56px;

      & > i {
        margin: 0;
      }
    }
  `,
  container: css`
    display: flex;
    justify-content: center;
  `,
  dialogContent: css`
    display: grid;

    & > span:nth-of-type(1) {
      color: #27313b;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 24px;
    }

    & > span:nth-of-type(2) {
      color: #ff5f5f;
      font-size: 14px;
      font-weight: 400;
    }
  `,
  formContainer: css`
    background-color: #fff;
    width: 100%;
  `,
  nextPageDivider: css`
    margin-bottom: 32px;

    & > h2 {
      border-bottom: 1px solid #dee5ec;
      line-height: 0.1em;
      text-align: center;
      width: 100%;
    }

    /* stylelint-disable no-descending-specificity */
    & > h2 span {
      background-color: #fff;
      color: #c5d0da;
      font-size: 14px;
      font-weight: 600;
      padding: 0 10px;
    }
  `,
  textFormTitle: css`
    & input {
      color: #27313b;
      font-size: 16px;
      font-weight: 600;
    }
  `,
  thanksPageContainer: css`
    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 24px;
    }

    & > div:nth-of-type(2) {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
    }

    & > div:nth-of-type(3) {
      background-color: #f6f8fa;
      padding: 24px;
    }
  `,
  thanksPageTitle: css`
    & input {
      color: #27313b;
      font-size: 16px;
    }
  `,
};

export default Form;
