import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import InitialLoading from '@src/components/molecules/InitialLoading';
import { getCallbackUrl } from '@src/libs/auth';
import { useQueryHelper } from '@src/libs/hooks';
import { SocialAccountType, AuthSocialAccountType } from '@src/__generated__/globalTypes';
import { UNEXPECTED_ERROR } from '@src/libs/error';
import { getSocialAccountType } from '../utils/utils';
import { JoinMarketplace, JoinMarketplaceVariables } from './__generated__/JoinMarketplace';
import * as JOIN_MARKETPLACE from './JoinMarketplace.graphql';
import * as SOCIAL_AUTH_JOIN_CAMPAIGN from './SocialAuthJoinCampaign.graphql';
import {
  SocialAuthForJoinCampaign,
  SocialAuthForJoinCampaignVariables,
} from './__generated__/SocialAuthForJoinCampaign';

interface UrlParams {
  provider: string;
}
const JoinCampaignRedirectComponent = () => {
  const [joinMarketplace] = useMutation<JoinMarketplace, JoinMarketplaceVariables>(JOIN_MARKETPLACE, {
    refetchQueries: ['Marketplace'],
  });
  const [socialAuthJoinCampaign] = useMutation<SocialAuthForJoinCampaign, SocialAuthForJoinCampaignVariables>(
    SOCIAL_AUTH_JOIN_CAMPAIGN,
    {
      refetchQueries: ['Marketplace'],
    }
  );

  const { t, enqueueSnackbar, history, search } = useQueryHelper();
  const { provider: providerStr } = useParams<UrlParams>();
  const campaignId = localStorage.getItem('joinCampaignId');
  const socialAccountId = localStorage.getItem('joinAccountId');
  const socialMedia = localStorage.getItem('joinAccountSocialMedia') as SocialAccountType;
  const fbAccountId = localStorage.getItem('fbAccountId');
  const response = search.substr(1);
  const failToJoinRedirectUrl = campaignId ? `/marketplace/search_job/${campaignId}` : '/marketplace/search_job';

  useEffect(() => {
    connectAndJoinCampaign();

    return () => {
      localStorage.removeItem('joinCampaignId');
      localStorage.removeItem('joinAccountId');
      localStorage.removeItem('joinAccountSocialMedia');
      localStorage.removeItem('fbAccountId');
    };
  }, []);

  const connectAndJoinCampaign = async () => {
    const provider = getSocialAccountType(providerStr) as AuthSocialAccountType | null;
    if (!provider) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      history.push(failToJoinRedirectUrl);

      return;
    }
    // in order to understand is account which user choose for join campaign is same as one he is using for login ot our platform
    // BE will throw an error if they are not match and we need to stop join campaign flow too
    const selectedSocialAccountId = [
      SocialAccountType.FACEBOOK,
      SocialAccountType.FACEBOOK_PAGE,
      SocialAccountType.INSTAGRAM,
    ].includes(socialMedia)
      ? fbAccountId
      : socialAccountId;

    const signInVariables: SocialAuthForJoinCampaignVariables = {
      input: {
        provider,
        response,
        callbackUrl: getCallbackUrl(provider, FE_REDIRECT_MAPPING.JOIN_CAMPAIGN),
        selectedSocialAccountId: selectedSocialAccountId || null,
      },
    };

    try {
      const { data } = await socialAuthJoinCampaign({ variables: signInVariables });
      if (data?.socialAuthForJoinCampaign?.ok) {
        enqueueSnackbar('Success to connect.', { variant: 'success' });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t(error), { variant: 'error' });

      // redirect back to campaignPage
      history.push(failToJoinRedirectUrl);

      return;
    }

    const joinVariables = {
      input: {
        campaignId: Number(campaignId),
        socialAccountId: Number(socialAccountId),
        socialMedia,
      },
    };

    try {
      await joinMarketplace({ variables: joinVariables });
      history.push(`/marketplace/your_job/${campaignId}`);
    } catch (e) {
      console.error(e.message);
      if (e.message === "GraphQL error: you don't meet the campaign's condition") {
        enqueueSnackbar(t('NotMeetCampaignCondition'), { variant: 'error' });
      } else {
        enqueueSnackbar(t(`${e.message || 'failedToUpdate'}`), { variant: 'error' });
      }
      history.push(failToJoinRedirectUrl);
    }
  };

  return <InitialLoading />;
};

export default JoinCampaignRedirectComponent;
