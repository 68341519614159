import styled from '@emotion/styled';
import TextForm from '@src/components/molecules/TextForm';

export const CardsWrapper = styled.section`
  display: flex;
  grid-gap: 10px;
  padding-bottom: 24px;
  overflow-x: auto;

  /* this scrollbar looks ugly */
  &::-webkit-scrollbar {
    display: none;
  }
`;

interface IsClickable {
  clickable?: boolean;
}

export const Card = styled.div<IsClickable>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 104px;
  height: 152px;
  cursor: ${({ clickable }) => (!!clickable ? `pointer` : 'inherit')};
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px #dee5ec80;
  background-color: #fff;
`;
export const Avatar = styled.img`
  width: 56px;
  height: 56px;
  border: solid 1px #f6f6f6;
  border-radius: 50%;
  object-fit: contain;
  margin-top: 16px;
  box-sizing: border-box;
`;

export const AccountName = styled.p`
  margin-top: 8px;
  font-size: 13px;
  text-align: center;
  color: #27313b;
  width: 91px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const CardIcon = styled.button`
  position: absolute;
  width: 26px;
  text-align: right;
  z-index: 2;
  top: 0;
  right: 3px;
  font-size: 22px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
export const AddAccout = styled.span`
  display: flex;
  place-content: center;
  align-items: center;
  width: 104px;
  height: 152px;
  font-size: 30px;
  font-weight: 300;
  color: #808d98;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    box-shadow: 1px 1px 2px 1px #dee5ef;
  }
`;
export const DialogContentWrapper = styled.div`
  p {
    padding: 16px 0;
    font-size: 13px;
  }
`;
export const DialogAccountWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    padding-left: 8px;
  }
`;
export const DialogAvatar = styled(Avatar)`
  width: 40px;
  height: 40px;
  margin-top: 0;
`;
export const StyledTextForm = styled(TextForm)`
  width: 100%;
`;
export const OverflowImage = styled.img`
  position: absolute;
  top: 6px;
  left: -10px;
  zoom: 1.2;
`;
export const AvatarWrapper = styled.div`
  position: relative;
`;
export const TitleWithLink = styled(AccountName.withComponent('a'))`
  &:hover {
    color: #3892e5;
  }
`;
export const EyeButton = styled.button`
  margin-top: auto;
  width: 76px;
  height: 32px;
  margin-bottom: 8px;
  background: #fff;
  border: 1px solid #dee5ec;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;

  img {
    margin: auto;
  }

  &:hover {
    border-color: #c5d0da;
  }
`;
