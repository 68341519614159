import styled from '@emotion/styled';
import Pager from '@src/components/molecules/Pager';
import { getPageInfo } from '@src/libs/paging';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { ViewportType } from '@src/libs/theme';
import { useQueryHelper, useUpdateDocumentTitle } from '@src/libs/hooks';
import { InstagramFeedPostSortField } from '@src/__generated__/globalTypes';
import { SortActionType } from '@src/libs/filter';
import BackNavigator from '../Layout/BackNavigator';
import { Post } from './model';
import PostFeedFilterForm from './PostFeedFilters';
import PostFeedList from './PostFeedList';
import { PostFeedProps } from './utils';

export interface TemplateProps extends PostFeedProps {
  posts: Post[];
  total: number;
  accountName?: string;
  sortData: SortActionType<InstagramFeedPostSortField>;
}

const Template = (props: TemplateProps) => {
  const { posts, currentPage, filter, setFilter, accountId, total, accountName, sortData } = props;
  const isDesktopView = useMediaQuery({ minWidth: ViewportType.TABLET });
  const { search } = useQueryHelper();
  const interpolation = accountName ? { title: accountName } : undefined;
  useUpdateDocumentTitle({ title: 'pageTitle.PostListWithAccountName', interpolation });

  const pageInfo = getPageInfo(currentPage, total);

  return (
    <>
      <Wrapper>
        {isDesktopView && (
          <div css={{ marginBottom: '16px' }}>
            <BackNavigator to={`/analytics${search}`} title="PostListWithAccountName" interpolation={interpolation} />
          </div>
        )}
        <Card>
          <PostFeedFilterForm filter={filter} setFilter={setFilter} />
          <PostFeedList accountId={accountId} totalCount={pageInfo.totalCount} posts={posts} sortData={sortData} />
        </Card>
        <Pager
          currentPage={currentPage}
          totalPages={pageInfo.totalPages}
          totalCount={pageInfo.totalCount}
          first={pageInfo.firstIndex}
          last={pageInfo.lastIndex}
        />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
  width: calc(100% - 32px);
`;

const Card = styled.div`
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5);
  background-color: #fff;
`;

export default Template;
