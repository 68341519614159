import styled from '@emotion/styled';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHelpCenterUrl } from '@src/libs/help';
import { SubmitButton, PalleteButton } from '@src/components/atoms/Button';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import SentenceForm from '@src/components/molecules/SentenceForm';
import { mainWhite, mainBlue } from '@src/libs/pallete';
import { ViewportBreakpoint } from '@src/libs/theme';

const ContactForm = () => {
  const { t } = useTranslation();
  const { handleSubmit, isSubmitting } = useFormikContext();
  const { helpCenterUrl } = useHelpCenterUrl();
  // Fields
  const [enquiryField, enquiryMeta] = useField('enquiry');

  return (
    <ResponsiveWrapper>
      <Container onSubmit={handleSubmit}>
        <FormWrapper>
          <AttentionMessage>
            <Paragraph>{t('Help.ContactGotAQuestion')}</Paragraph>
            <AttentionLink targetBlank={true} text="FAQ" href={helpCenterUrl} palleteColor={mainWhite} width="80px" />
          </AttentionMessage>
          <SentenceForm
            title="Enquiry"
            isRequired={true}
            error={!!enquiryMeta.error}
            placeholder="Describe your question"
            {...enquiryField}
          />
          {enquiryMeta.error && <ErrorMessage message={t(enquiryMeta.error)} />}
          <Submit value="Send" bgcolor={mainBlue.normal} hoverbgcolor={mainBlue.hover} isSubmitting={isSubmitting} />
        </FormWrapper>
      </Container>
    </ResponsiveWrapper>
  );
};

const FormWrapper = styled.div`
  max-width: 640px;
`;

const ResponsiveWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  background-color: #fff;
`;

const Container = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: max-content;
  margin-top: 48px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 1px 7px 0 #dee5ec, 0 3px 6px 1px #0000000c;
  align-items: center;

  @media (min-width: ${ViewportBreakpoint.MEDIUM}px) {
    padding: 24px 64px;
  }
`;

const Submit = styled(SubmitButton)`
  margin-top: 24px;
  line-height: 40px;
`;
const AttentionMessage = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
  align-items: center;
`;
const Paragraph = styled.p`
  display: flex;
  flex: 1;
  font-size: 13px;
  line-height: 1.38;
  color: #27313b;
`;
const AttentionLink = styled(PalleteButton)`
  margin-left: 10px;
`;
export default ContactForm;
