import { FormikHandlers } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Omit } from '../../../libs/types';
import Label from '../../atoms/Label';
import { SingleSelector, SingleSelectorProps } from '../Selector';
import { NativeSelect } from '../Selector/NativeSelect';

export interface Option {
  label: string;
  value: string;
}

export interface SingleSelectFieldProps extends Omit<SingleSelectorProps, 'onChange' | 'onClose'> {
  className?: string;
  title?: string;
  isRequired?: boolean;
  help?: string;
  handleSubmit?: FormikHandlers['handleSubmit'];
  setFieldValue: (field: any, value: string) => void; // field: string is not assignable to keyof Values & string  https://github.com/jaredpalmer/formik/issues/1388
  hasNativeSelect?: boolean;
  hideDeselectOption?: boolean;
}

const SingleSelectField = (props: SingleSelectFieldProps) => {
  const { t } = useTranslation();
  const {
    className,
    name,
    title,
    isRequired,
    placeholder,
    value,
    options,
    help,
    error,
    handleSubmit,
    setFieldValue,
    hasNativeSelect,
    hideDeselectOption,
  } = props;
  const disabled = props.disabled || options.length === 0;

  const handleClose = async () => {
    if (handleSubmit) {
      await Promise.resolve();
      handleSubmit();
    }
  };
  const handleSetValues = (item: string) => {
    setFieldValue(name, item);
  };

  const placeholderText = disabled ? '' : !!placeholder ? t(`Selector.${placeholder}`) : t('Selector.Please Select');

  return (
    <div className={className}>
      {title && <Label title={t(`Selector.${title}`)} isRequired={isRequired} help={help} />}
      {!!hasNativeSelect ? (
        <NativeSelect
          options={options}
          placeholder={disabled ? '' : 'Please Select'}
          onChange={setFieldValue}
          fieldName={name}
          fieldValue={value || ''}
        />
      ) : (
        <SingleSelector
          options={options}
          name={name}
          disabled={disabled}
          placeholder={placeholderText}
          value={value}
          error={error}
          onChange={handleSetValues}
          onClose={handleClose}
          hideDeselectOption={hideDeselectOption}
        />
      )}
    </div>
  );
};

export default SingleSelectField;
