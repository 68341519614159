import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { bigIntFormatter } from '@src/libs/format';
import * as StyledComponents from '@src/components/organisms/AnalyticsYoutube/StyledComponents';
import TextCutter from '@src/components/atoms/TextCutter';
import { usePostDetailModal } from '@src/components/organisms/AnalyticsShared/BasePostList/usePostDetailModal';
import ImageSlider from '@src/components/molecules/Slider';
import CarouselModal from '@src/components/molecules/CarouselModal/CarouselModal';
import DataOpacityWrapper from '@src/components/molecules/DataCard/DataOpacityWrapper';
import { InfluencerTwitterProfilePosts_influencerProfilePosts_feedPosts } from '../__generated__/InfluencerTwitterProfilePosts';
import InfluencerPostDetail from '../../InfluencerPostDetail';

interface LikeCommentPostsProps {
  posts?: InfluencerTwitterProfilePosts_influencerProfilePosts_feedPosts[] | null;
  header: ReactElement;
  className?: string;
  moreLink?: string;
  influencerAccountId: number;
}
const TwitterFeedPosts = (props: LikeCommentPostsProps) => {
  const { t } = useTranslation();
  const { posts, header, className, influencerAccountId } = props;

  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev,
  } = usePostDetailModal(posts || []);

  const dataNotAvailable = !posts?.length;

  return (
    <>
      <DataOpacityWrapper header={header} dataNotAvailable={dataNotAvailable}>
        <MainWrapper className={className} dataNotAvailable={dataNotAvailable}>
          {!!posts?.length && (
            <>
              <ImageSlider data={posts}>
                {posts.map(post => (
                  <StyledComponents.VideoContainerWrapper key={post.id} css={{ height: '135px' }}>
                    <StyledComponents.VideoWrapper onClick={() => handleClickPost(post.id)} css={{ cursor: 'pointer' }}>
                      {!post.thumbNail && post.caption ? (
                        <TextCutter text={post.caption} lines={5} />
                      ) : (
                        <StyledComponents.VideoThumbnail src={post.thumbNail} />
                      )}
                    </StyledComponents.VideoWrapper>
                    <StyledComponents.EngagementInfo>
                      <StyledComponents.InfoWrapper>
                        <StyledComponents.Label>{t('Like')}</StyledComponents.Label>
                        <StyledComponents.Count title={`${post.likes}`}>
                          {bigIntFormatter(post.likes)}
                        </StyledComponents.Count>
                      </StyledComponents.InfoWrapper>
                      <StyledComponents.InfoWrapper>
                        <StyledComponents.Label>{t('Reply')}</StyledComponents.Label>
                        <StyledComponents.Count title={`${post.comments}`}>
                          {bigIntFormatter(post.comments)}
                        </StyledComponents.Count>
                      </StyledComponents.InfoWrapper>
                      <StyledComponents.InfoWrapper>
                        <StyledComponents.Label>{t('Retweet')}</StyledComponents.Label>
                        <StyledComponents.Count title={`${post.shares}`}>
                          {bigIntFormatter(post.shares)}
                        </StyledComponents.Count>
                      </StyledComponents.InfoWrapper>
                    </StyledComponents.EngagementInfo>
                  </StyledComponents.VideoContainerWrapper>
                ))}
              </ImageSlider>
            </>
          )}
        </MainWrapper>
      </DataOpacityWrapper>
      <CarouselModal
        open={!!openPostDetail}
        title=""
        cancel="OK"
        onClose={handleClosePostDetail}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        siblingPostId={siblingPostId}
      >
        <InfluencerPostDetail influencerId={influencerAccountId} closeModal={handleClosePostDetail} />
      </CarouselModal>
    </>
  );
};

const MainWrapper = styled.div<{ dataNotAvailable?: boolean }>`
  padding-left: 16px;
  padding-bottom: 16px;
  ${({ dataNotAvailable }) => (dataNotAvailable ? 'min-height: 300px;' : '')}
`;

export default TwitterFeedPosts;
