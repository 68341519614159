import React from 'react';
import { useLocation, useHistory } from 'react-router';
import Template, { TemplateProps } from './template';

type LocationState = TemplateProps;

const SignUpProfile = () => {
  const history = useHistory();
  const { state } = useLocation<LocationState>();
  const { email, name, socialMedia, uuid } = state;

  if (!state) {
    history.push('/');

    return null;
  }

  return <Template socialMedia={socialMedia} name={name} email={email} uuid={uuid} />;
};

export default SignUpProfile;
