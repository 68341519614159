import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CampaignSocialMediaType, SocialAccountType } from '@src/__generated__/globalTypes';
import { bigIntFormatter, formatIntNumber, formatPercent } from '@src/libs/format';
import { switchImage, switchText } from '@src/libs/SocialMedia';
import * as Styled from '@src/components/organisms/AnalyticsShared/PostDetail/StyledEngagement';

export interface PostDetailEngagementProps {
  className?: string;
  socialMedia: SocialAccountType | CampaignSocialMediaType;
  postUrl: string | null;
  likes: number | null;
  views: number | null;
  comments: number | null;
  shares: number | null;
  sentimentPositiveRatio: number | null;
  sentimentNegativeRatio: number | null;
  reactions: number | null;
}

const PostDetailEngagement = (props: PostDetailEngagementProps) => {
  const { t } = useTranslation();
  const {
    socialMedia,
    postUrl,
    likes,
    views,
    comments,
    shares,
    sentimentPositiveRatio,
    sentimentNegativeRatio,
    reactions,
  } = props;

  const hasFbReactions = [SocialAccountType.FACEBOOK_PAGE].includes(socialMedia as SocialAccountType);
  const hasReactionsFromApi = [SocialAccountType.FACEBOOK_PAGE].includes(socialMedia as SocialAccountType);

  return (
    <Styled.MainContainer>
      <Styled.Title>
        {t('Engagement')}
        <Styled.Anchor href={postUrl as string} target="_blank" rel="noreferrer">
          {`View on ${switchText(socialMedia)}`}
        </Styled.Anchor>
      </Styled.Title>
      <Styled.EngagementContainer>
        <Styled.Engagement>
          <Styled.Icon src={switchImage(socialMedia, true)} alt="" />
          <Styled.CountWrapper>
            <Styled.Wrapper>
              <Styled.Value title={formatIntNumber(likes)}>
                {bigIntFormatter(hasReactionsFromApi ? reactions : likes, 0)}
              </Styled.Value>
              <Styled.Unit text={t(`${hasFbReactions ? 'Reactions' : 'Likes'}`)} lines={1} />
            </Styled.Wrapper>
            {socialMedia !== SocialAccountType.TWITTER ? (
              <Styled.Wrapper>
                <Styled.Value title={formatIntNumber(views)}>{bigIntFormatter(views, 0)}</Styled.Value>
                <Styled.Unit text={t('Views')} lines={1} />
              </Styled.Wrapper>
            ) : null}

            {socialMedia !== SocialAccountType.TWITTER && (
              <Styled.Wrapper>
                <Styled.Value title={formatIntNumber(comments)}>{bigIntFormatter(comments, 0)}</Styled.Value>
                <Styled.Unit text={t('Comments')} lines={1} />
              </Styled.Wrapper>
            )}
            {[SocialAccountType.FACEBOOK_PAGE, SocialAccountType.FACEBOOK, SocialAccountType.TIKTOK].includes(
              socialMedia as SocialAccountType
            ) && (
              <Styled.Wrapper>
                <Styled.Value title={formatIntNumber(shares)}>{bigIntFormatter(shares, 0)}</Styled.Value>
                <Styled.Unit text={t('Shares')} lines={1} />
              </Styled.Wrapper>
            )}

            {socialMedia === SocialAccountType.TWITTER && (
              <>
                <Styled.Wrapper>
                  <Styled.Value title={formatIntNumber(shares)}>{bigIntFormatter(shares, 0)}</Styled.Value>
                  <Styled.Unit text={t('Retweets')} lines={1} />
                </Styled.Wrapper>
                <Styled.Wrapper>
                  <Styled.Value title={formatIntNumber(comments)}>{bigIntFormatter(comments, 0)}</Styled.Value>
                  <Styled.Unit text={t('Replies')} lines={1} />
                </Styled.Wrapper>
              </>
            )}
            {[SocialAccountType.FACEBOOK, SocialAccountType.FACEBOOK_PAGE].includes(
              socialMedia as SocialAccountType
            ) && (
              <>
                <Styled.Wrapper>
                  <Styled.Value title={formatIntNumber(shares)}>{bigIntFormatter(shares, 0)}</Styled.Value>
                  <Styled.Unit text={t('Engagement')} lines={1} />
                </Styled.Wrapper>
                <Styled.Wrapper>
                  <Styled.Value title={formatPercent(sentimentPositiveRatio)}>
                    {formatPercent(sentimentPositiveRatio)}
                  </Styled.Value>
                  <Styled.Unit text={t('Positive Rate')} lines={1} />
                </Styled.Wrapper>
                <Styled.Wrapper>
                  <Styled.Value title={formatPercent(sentimentNegativeRatio)}>
                    {formatPercent(sentimentNegativeRatio)}
                  </Styled.Value>
                  <Styled.Unit text={t('Negative Rate')} lines={1} />
                </Styled.Wrapper>
              </>
            )}
          </Styled.CountWrapper>
        </Styled.Engagement>
      </Styled.EngagementContainer>
    </Styled.MainContainer>
  );
};

export default PostDetailEngagement;
