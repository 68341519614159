import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';
import { useField, useFormikContext } from 'formik';
import TextForm from '@src/components/molecules/TextForm';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { SubmitButton } from '@src/components/atoms/Button';
import { ViewportBreakpoint } from '@src/libs/theme';
import { FormValues } from './types';

const AddFanForm = () => {
  const { t } = useTranslation();
  const { isSubmitting, handleSubmit } = useFormikContext<FormValues>();
  const showNameInRow = useMediaQuery({ query: `(min-width: ${ViewportBreakpoint.MEDIUM}px)` });

  const [firstNameField, firstNameMeta] = useField<FormValues['firstName']>('firstName');
  const [lastNameField, lastNameMeta] = useField<FormValues['lastName']>('lastName');
  const [emailField, emailMeta] = useField<FormValues['email']>('email');
  const [contactNumberField, contactNumberMeta] = useField<FormValues['contactNumber']>('contactNumber');

  return (
    <form onSubmit={handleSubmit}>
      <ResponsiveWrapper>
        <FixedWidthContainer>
          <FormElementsWrapper>
            {showNameInRow ? (
              <InputWrapper css={flexRow}>
                <div css={flex1}>
                  <StyledTextForm
                    title="First Name"
                    isRequired={true}
                    error={!!firstNameMeta.error}
                    placeholder={t('First Name')}
                    css={marginRight16}
                    {...firstNameField}
                  />
                  {firstNameMeta.error && <ErrorMessage message={t(firstNameMeta.error)} />}
                </div>

                <div css={flex1}>
                  <StyledTextForm
                    title="Last Name"
                    isRequired={true}
                    error={!!lastNameMeta.error}
                    placeholder={t('Last Name')}
                    {...lastNameField}
                  />
                  {lastNameMeta.error && <ErrorMessage message={t(lastNameMeta.error)} />}
                </div>
              </InputWrapper>
            ) : (
              <>
                <InputWrapper>
                  <StyledTextForm
                    title="First Name"
                    isRequired={true}
                    error={!!firstNameMeta.error}
                    placeholder="First Name"
                    {...firstNameField}
                  />
                  {firstNameMeta.error && <ErrorMessage message={t(firstNameMeta.error)} />}
                </InputWrapper>
                <InputWrapper>
                  <StyledTextForm
                    title="Last Name"
                    isRequired={true}
                    error={!!lastNameMeta.error}
                    placeholder="Last Name"
                    {...lastNameField}
                  />
                  {lastNameMeta.error && <ErrorMessage message={t(lastNameMeta.error)} />}
                </InputWrapper>
              </>
            )}
            <InputWrapper>
              <StyledTextForm
                title="Email"
                isRequired={true}
                error={!!emailMeta.error}
                placeholder={t('Your Email Address')}
                {...emailField}
              />
              {emailMeta.error && <ErrorMessage message={t(emailMeta.error)} />}
            </InputWrapper>
            <InputWrapper>
              <StyledTextForm
                title="Phone Number"
                error={!!contactNumberMeta.error}
                placeholder={t('Phone Number')}
                {...contactNumberField}
              />
              {contactNumberMeta.error && <ErrorMessage message={t(contactNumberMeta.error)} />}
            </InputWrapper>
          </FormElementsWrapper>
          <ButtonWrapper>
            <StyledSubmitButton disabled={isSubmitting} value="Save" />
          </ButtonWrapper>
        </FixedWidthContainer>
      </ResponsiveWrapper>
    </form>
  );
};

const flex1 = css`
  flex: 1;
`;
const marginRight16 = css`
  margin-right: 16px;
`;
const flexRow = css`
  display: flex;
  flex-direction: row;
`;
const ResponsiveWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;
const FixedWidthContainer = styled.div`
  width: 100%;
  background-color: #fff;

  @media (min-width: ${ViewportBreakpoint.MEDIUM}px) {
    max-width: 640px;
  }
`;
const InputWrapper = styled.div`
  margin-bottom: 24px;
`;
const StyledTextForm = styled(TextForm)`
  & > label {
    font-size: 14px;
  }

  & input {
    border-radius: 2px;
    height: 32px;
  }
`;
const FormElementsWrapper = styled.div`
  padding: 24px 24px 0;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #eef3f7;
  padding: 16px 24px;
`;
const StyledSubmitButton = styled(SubmitButton)`
  border-radius: 3px;
  height: 32px;
  line-height: 32px;
  width: 78px;
`;

export default AddFanForm;
