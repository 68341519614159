import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import imgPaymentSrc from '../../../../assets/icon/notificationType/payment.svg';
import imgSearchJobSrc from '../../../../assets/icon/notificationType/searchJob.svg';
import imgYourJobSrc from '../../../../assets/icon/notificationType/yourJob.svg';
import { NotificationId } from '../../../../__generated__/globalTypes';

export const switchImage = (type: NotificationId): string => {
  switch (type) {
    case NotificationId.MARKETPLACE_CAMPAIGN_FINISHED:
    case NotificationId.MARKETPLACE_CAMPAIGN_JOINED:
    case NotificationId.MARKETPLACE_CAMPAIGN_REACH_BUDGET:
    case NotificationId.MARKETPLACE_CAMPAIGN_UPDATE:
    case NotificationId.ENGAGEMENT_DRAFT_POST_APPROVED:
    case NotificationId.ENGAGEMENT_DRAFT_POST_REJECTED:
    case NotificationId.ENGAGEMENT_FETCHED:
    case NotificationId.ENGAGEMENT_FINISH:
    case NotificationId.MP_CAMPAIGNS_APPLICANT_INFLS_APPROVED:
      return imgYourJobSrc;

    case NotificationId.MARKETPLACE_CAMPAIGN_STARTED:
    case NotificationId.MARKETPLACE_CAMPAIGN_UPCOMING:
      return imgSearchJobSrc;

    case NotificationId.PAYMENT_COMPLETED:
    case NotificationId.PAYMENT_FAILED:
    case NotificationId.PAYMENT_REQUEST_DONE:
      return imgPaymentSrc;

    default:
      return '';
  }
};

export const getFormatDistanceToNow = (date: string, isLocal: undefined | boolean = false): string =>
  formatDistanceToNow(new Date(isLocal ? date + 'Z' : date), { addSuffix: true });

interface NotificationRedirectUrlProps {
  type: NotificationId;
  campaignId: number;
}
export const getNotificationRedirectUrl = ({ type, campaignId }: NotificationRedirectUrlProps) => {
  switch (type) {
    case NotificationId.MARKETPLACE_CAMPAIGN_FINISHED:
      return `/job/marketplace/${campaignId}`;
    case NotificationId.MARKETPLACE_CAMPAIGN_JOINED:
    case NotificationId.MARKETPLACE_CAMPAIGN_REACH_BUDGET:
    case NotificationId.MARKETPLACE_CAMPAIGN_UPDATE:
    case NotificationId.MP_CAMPAIGNS_APPLICANT_INFLS_APPROVED:
      return `/job/marketplace/${campaignId}`;
    case NotificationId.ENGAGEMENT_DRAFT_POST_APPROVED:
    case NotificationId.ENGAGEMENT_FETCHED:
    case NotificationId.ENGAGEMENT_DRAFT_POST_REJECTED:
      return `/job/engagement/${campaignId}/post`;
    case NotificationId.ENGAGEMENT_FINISH:
      return `/job/engagement/${campaignId}`;

    case NotificationId.MARKETPLACE_CAMPAIGN_STARTED:
    case NotificationId.MARKETPLACE_CAMPAIGN_UPCOMING:
      return `/search/marketplace/${campaignId}`;

    case NotificationId.PAYMENT_FAILED:
      return '/profile/settings/payment';

    case NotificationId.PAYMENT_COMPLETED:
    case NotificationId.PAYMENT_REQUEST_DONE:
      return '/payment';

    default:
      return '';
  }
};
