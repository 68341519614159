import { Highcharts } from '@src/components/molecules/HighChart';
import {
  SeriesHistoryType,
  formatLineChartDateCount,
  formatDateCount,
  getSerieDayData,
  SeriesType,
  TooltipDataType,
} from '@src/components/organisms/AnalyticsShared/Overview/helpers';

export const defaultSelectableCharts: ChartColorTypes[] = ['Follower', 'Post', 'Reaction', 'View', 'Comment', 'Share'];

export const additionalSelectableCharts: ChartColorTypes[] = [
  'Impression',
  'Engagement',
  'Engagement Rate',
  'PageCta',
  'Page Impression',
  'Page Likes',
];

export const allChartsOrdered: ChartColorTypes[] = [
  'Follower',
  'Post',
  'PageCta',
  'Page Impression',
  'Page Likes',
  'Impression',
  'View',
  'Engagement',
  'Engagement Rate',
  'Reaction',
  'Comment',
  'Share',
];

export type ChartColorTypes =
  | 'Follower'
  | 'Post'
  | 'View'
  | 'Reaction'
  | 'Comment'
  | 'Share'
  | 'PageCta'
  | 'Impression'
  | 'Engagement'
  | 'Engagement Rate'
  | 'Page Impression'
  | 'Page Likes';

export const colors: Record<ChartColorTypes, string> = {
  Follower: '#ff2b52',
  Post: '#ffd7c1',
  View: '#ff96ae',
  Reaction: '#e9b8d7',
  Comment: '#aec0e4',
  Share: '#a0c8df',
  Impression: '#9dd9f4',
  Engagement: '#ffb6b7',
  'Engagement Rate': '#a0dfd5',
  PageCta: '#a5a8e0',
  'Page Impression': '#c484eb',
  'Page Likes': '#ffaae1',
};

export const getDailyDataForTooltip = (categories: string[], series: SeriesType[]): TooltipDataType<ChartColorTypes> =>
  categories.reduce(
    (acc, day, index) => ({
      ...acc,
      [day]: getSerieDayData<ChartColorTypes>(index, series),
    }),
    {}
  );

interface SeriesAndCategoriesType {
  historyData: Record<string, SeriesHistoryType[]>;
  displayedCharts: ChartColorTypes[];
}

export const getSeriesAndCategories = ({
  historyData: {
    followerHistory,
    postsHistory,
    viewsHistory,
    reactionHistory,
    shareHistory,
    commentsHistory,
    impressionHistory,
    pageCtaHistory,
    engagementHistory,
    engagementRateHistory,
    pageImpressionHistory,
    pageLikesHistory,
  },
  displayedCharts,
}: SeriesAndCategoriesType) => {
  // We are creating data-sets based on Period which is represented by followersHistory, if no data for some day we will replace it with 0
  const { count: followersCount, date: followersCategories } = formatLineChartDateCount(followerHistory);
  const { count: postsCount } = formatDateCount(followerHistory, postsHistory);
  const { count: viewsCount } = formatDateCount(followerHistory, viewsHistory);
  const { count: reactionCount } = formatDateCount(followerHistory, reactionHistory);
  const { count: shareCount } = formatDateCount(followerHistory, shareHistory);
  const { count: pageCtaCount } = formatDateCount(followerHistory, pageCtaHistory);
  const { count: impressionCount } = formatDateCount(followerHistory, impressionHistory);
  const { count: engagementCount } = formatDateCount(followerHistory, engagementHistory);
  const { count: engagementRateCount } = formatDateCount(followerHistory, engagementRateHistory);
  const { count: pageImpressionCount } = formatDateCount(followerHistory, pageImpressionHistory);
  const { count: pageLikesCount } = formatDateCount(followerHistory, pageLikesHistory);
  const { count: commentsCount } = formatDateCount(followerHistory, commentsHistory);

  const dateCategories = followersCategories;

  const series = [
    {
      name: 'Follower',
      type: 'line',
      data: followersCount,
      color: colors.Follower,
      marker: {
        symbol: 'circle',
        lineWidth: 2,
        lineColor: undefined, // inherit from chart
        fillColor: '#fff',
      },
      visible: displayedCharts.includes('Follower'),
      zIndex: 1,
    },
    {
      name: 'Post',
      type: 'column',
      data: postsCount,
      color: colors.Post,
      yAxis: 1,
      visible: displayedCharts.includes('Post'),
    },
    {
      name: 'View',
      type: 'column',
      data: viewsCount,
      color: colors.View,
      yAxis: 2,
      visible: displayedCharts.includes('View'),
    },
    {
      name: 'Reaction',
      type: 'column',
      data: reactionCount,
      color: colors.Reaction,
      yAxis: 3,
      visible: displayedCharts.includes('Reaction'),
    },
    {
      name: 'Comment',
      type: 'column',
      data: commentsCount,
      color: colors.Comment,
      yAxis: 4,
      visible: displayedCharts.includes('Comment'),
    },
    {
      name: 'Share',
      type: 'column',
      data: shareCount,
      color: colors.Share,
      yAxis: 5,
      visible: displayedCharts.includes('Share'),
    },
    {
      name: 'Impression',
      type: 'column',
      data: impressionCount,
      color: colors.Impression,
      yAxis: 6,
      visible: displayedCharts.includes('Impression'),
    },
    {
      name: 'Engagement',
      type: 'column',
      data: engagementCount,
      color: colors.Engagement,
      yAxis: 7,
      visible: displayedCharts.includes('Engagement'),
    },
    {
      name: 'Engagement Rate',
      type: 'column',
      data: engagementRateCount,
      color: colors['Engagement Rate'],
      yAxis: 8,
      visible: displayedCharts.includes('Engagement Rate'),
    },
    {
      name: 'PageCta',
      type: 'column',
      data: pageCtaCount,
      color: colors.Engagement,
      yAxis: 9,
      visible: displayedCharts.includes('PageCta'),
    },
    {
      name: 'Page Impression',
      type: 'column',
      data: pageImpressionCount,
      color: colors['Page Impression'],
      yAxis: 10,
      visible: displayedCharts.includes('Page Impression'),
    },
    {
      name: 'Page Likes',
      type: 'column',
      data: pageLikesCount,
      color: colors['Page Likes'],
      yAxis: 11,
      visible: displayedCharts.includes('Page Likes'),
    },
  ];
  const tooltipData = getDailyDataForTooltip(dateCategories, series);

  return {
    series: series as Highcharts.SeriesOptionsType[],
    categories: dateCategories,
    tooltipData,
  };
};
