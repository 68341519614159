import React from 'react';
import { format } from 'date-fns';
import { Formik, FormikHelpers } from 'formik';
import { useMutation } from '@apollo/client';
import { SignUpFormSchema } from '@src/libs/validation';
import { DEFAULT_FNS_DATE_FORMAT } from '@src/libs/constant';
import { useQueryHelper, useAllAvailableCountries } from '@src/libs/hooks';
import { Genders } from '@src/__generated__/globalTypes';
import { useInfluencerCategories } from '@src/libs/hooks';
import BaseSignUpForm from './SignUpEmailForm';
import { FormValues } from './types';
import Influencer_SignUp from './InfluencerSignUp.graphql';
import { InfluencerSignUp, InfluencerSignUpVariables } from './__generated__/InfluencerSignUp';

const SignUpEmailForm = ({ isWithinIframe }: { isWithinIframe: boolean }) => {
  const { history, enqueueSnackbar, t } = useQueryHelper();
  const { influencerCategories } = useInfluencerCategories();
  const { allTranslatedCountries } = useAllAvailableCountries();
  const [createInfluencer] = useMutation<InfluencerSignUp, InfluencerSignUpVariables>(Influencer_SignUp);

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    localStorage.setItem('confirmationEmail', values.email);

    try {
      await createInfluencer({
        variables: {
          input: {
            name: values.name,
            email: values.email,
            phoneNumber: values.phoneNumber,
            gender: values.gender as Genders,
            countryId: values.country,
            regionId: Number(values.regionId),
            dateOfBirth: !!values.birthday ? format(new Date(values.birthday), DEFAULT_FNS_DATE_FORMAT) : '',
            categoryIds: values.categories.map(category => Number(category)),
          },
        },
      });

      enqueueSnackbar(t('Your account was created successfully'), {
        variant: 'success',
      });
      history.push('/signup/success');
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        phoneNumber: '',
        categories: [],
        country: '',
        regionId: '',
        birthday: '',
        introduce: '',
        // for UUUMs users don't need to agreed on our terms
        agreed: isWithinIframe,
      }}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={SignUpFormSchema}
    >
      <BaseSignUpForm allCategories={influencerCategories} allCountries={allTranslatedCountries} />
    </Formik>
  );
};

export default SignUpEmailForm;
