import React, { useState, useEffect } from 'react';
import { formatIntNumber, formatPercent } from '@src/libs/format';
import { useQueryHelper } from '@src/libs/hooks';
import buildingIcon from '@src/assets/icon/building.svg';
import { css } from '@emotion/react';
import { PalleteButton } from '@src/components/atoms/Button';
import { useQuery } from '@apollo/client';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { usePostDetailModal } from '@src/components/organisms/AnalyticsShared/BasePostList/usePostDetailModal';
import DataOpacityWrapper from '@src/components/molecules/DataCard/DataOpacityWrapper';
import CarouselModal from '@src/components/molecules/CarouselModal/CarouselModal';
import InfluencerPostDetail from '@src/components/organisms/AnalyticsAccounts/DefaultProfile/InfluencerPostDetail';
import { ViewportBreakpoint } from '@src/libs/theme';
import * as StyledHeading from '../StyledHeading';
import * as Styled from './StyledComponents';
import * as BRAND_ACCOUNTS from './BrandAccounts.graphql';
import { BrandAccounts, BrandAccountsVariables } from './__generated__/BrandAccounts';

interface MentionedAccountsProps {
  className?: string;
  socialAccountId: number;
  influencerId: number;
  wrapperText?: string;
}
export const MentionedAccounts = ({
  wrapperText = `No brand mentioned posts to show`,
  socialAccountId,
  className,
  influencerId,
}: MentionedAccountsProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();

  const [showAllBrands, setShowAllBrands] = useState(false);
  const [{ brandPostIds, postIdOpened }, setBrandPostInfo] = useState<{
    brandPostIds: number[];
    postIdOpened: number | null;
  }>({ brandPostIds: [], postIdOpened: null });
  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev,
  } = usePostDetailModal(
    brandPostIds.map(el => ({ id: el })),
    'brandPostId'
  );
  const { data } = useQuery<BrandAccounts, BrandAccountsVariables>(BRAND_ACCOUNTS, {
    variables: {
      influencerId,
      socialMedia: SocialAccountType.INSTAGRAM,
      socialAccountId,
    },
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });
  const avgEngagement = data?.brandAccounts?.averageEngagement;
  const avgEngagementRate = data?.brandAccounts?.averageEngagementRate;
  const collaborativeBrands = data?.brandAccounts?.brandAccounts || [];
  const itemsToDisplay = showAllBrands ? collaborativeBrands : collaborativeBrands.slice(0, 8);

  const onClickMentions = (postIds: number[]) => {
    setBrandPostInfo({ brandPostIds: postIds, postIdOpened: postIds[0] });
  };
  const handleCloseModal = () => {
    handleClosePostDetail();
    setBrandPostInfo({ brandPostIds: [], postIdOpened: null });
  };

  useEffect(() => {
    if (brandPostIds.length && postIdOpened) {
      handleClickPost(postIdOpened);
    }
  }, [brandPostIds?.length, postIdOpened]);

  const headerNode = (
    <StyledHeading.Header>
      <StyledHeading.TitleWrapper>
        <StyledHeading.Img src={buildingIcon} />
        <StyledHeading.HeadingText>{t('Mentioned Accounts')}</StyledHeading.HeadingText>
      </StyledHeading.TitleWrapper>
      <StyledHeading.EngagementRateWrapper>
        <StyledHeading.CountWrapper css={{ marginRight: '16px' }}>
          <StyledHeading.Label>{t('HeaderColumn.Avg Engagement Rate')}</StyledHeading.Label>
          <StyledHeading.FollowersCount>{formatPercent(avgEngagementRate)}</StyledHeading.FollowersCount>
        </StyledHeading.CountWrapper>
        <StyledHeading.CountWrapper>
          <StyledHeading.Label>{t('HeaderColumn.Avg Engagement')}</StyledHeading.Label>
          <StyledHeading.FollowersCount>{formatIntNumber(avgEngagement)}</StyledHeading.FollowersCount>
        </StyledHeading.CountWrapper>
      </StyledHeading.EngagementRateWrapper>
    </StyledHeading.Header>
  );

  return itemsToDisplay.length > 0 ? (
    <div css={{ borderBottom: '1px solid #E6ECF0' }} className={className}>
      <DataOpacityWrapper
        header={headerNode}
        dataNotAvailable={!itemsToDisplay.length}
        wrapperText={wrapperText}
        css={styles.opacityWrapper}
      >
        <div css={styles.cardsWrapper}>
          {itemsToDisplay.map(({ avatar, name, mentionPostsCount, averageEngagementRate, postIds }, i) => (
            <Styled.BrandCard key={i} css={styles.card}>
              <Styled.BrandImage src={avatar} alt="brand image" />
              <Styled.BrandName text={name} lines={1} />
              <Styled.Mention
                onClick={() => {
                  onClickMentions(postIds);
                }}
              >
                {t('mention', { count: mentionPostsCount })}
              </Styled.Mention>
              <Styled.ER text={`${t(`HeaderColumn.ER`)}: ${formatPercent(averageEngagementRate)}`} lines={1} />
            </Styled.BrandCard>
          ))}
        </div>
        {collaborativeBrands.length > 8 && (
          <PalleteButton
            text={showAllBrands ? 'Less' : 'More'}
            width="216px"
            height="32px"
            borderRadius="3px"
            onClick={() => setShowAllBrands(!showAllBrands)}
            css={styles.moreLess}
          />
        )}
      </DataOpacityWrapper>
      <CarouselModal
        open={!!openPostDetail}
        title=""
        cancel="OK"
        onClose={handleCloseModal}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        siblingPostId={siblingPostId}
      >
        <InfluencerPostDetail influencerId={influencerId} closeModal={handleCloseModal} postIdUrlParam="brandPostId" />
      </CarouselModal>
    </div>
  ) : null;
};

const styles = {
  moreLess: css`
    margin: 8px auto;
    text-transform: capitalize;
  `,
  cardsWrapper: css`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    padding: 16px;

    @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
      grid-template-columns: repeat(2, 1fr);
    }
  `,
  opacityWrapper: css`
    padding-bottom: 8px;
    margin-top: 24px;
    min-height: 200px;
  `,
  card: css`
    height: 186px;
  `,
};
