import styled from '@emotion/styled';
import { ViewportBreakpoint } from '@src/libs/theme';

export const ResponsiveWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: #fff;
  flex: 1;
`;
export const FormContainer = styled.section`
  padding: 24px 24px 32px;
  background-color: #fff;
  width: 100%;

  @media (min-width: ${ViewportBreakpoint.MEDIUM}px) {
    padding: 40px 64px;
  }

  @media (min-width: ${ViewportBreakpoint.DESKTOP}px) {
    max-width: 640px;
  }
`;
export const Wrapper = styled.div`
  flex: 1;
  margin-bottom: 16px;
`;
export const SubmitWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #dee5ec;
`;
