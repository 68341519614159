import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mainRed, mainWhite } from '../../../libs/pallete';
import { PalleteButton } from '../../atoms/Button';
import DraftPostDetailTab from './DraftPostDetailTab';
import DraftPostHistoryTab from './DraftPostHistoryTab';
import { DraftPostDetailTabs } from './types';

export interface TemplateProps {
  postId: number;
  openedTab?: DraftPostDetailTabs;
  closeModal: () => void;
}

const Template = (props: TemplateProps) => {
  const { closeModal, openedTab = DraftPostDetailTabs.DETAILS, postId } = props;
  const [selectedTab, setSelectedTab] = useState(DraftPostDetailTabs.DETAILS);
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedTab(openedTab);
  }, [props.openedTab]);

  const onChangeTab = (value: DraftPostDetailTabs) => {
    setSelectedTab(value);
  };

  return (
    <Sizer>
      <Close onClick={closeModal} />
      <Header>
        <TabButton
          active={selectedTab === DraftPostDetailTabs.DETAILS}
          onClick={() => onChangeTab(DraftPostDetailTabs.DETAILS)}
        >
          <p>{t('Details')}</p>
        </TabButton>
        <TabButton
          active={selectedTab === DraftPostDetailTabs.HISTORY}
          onClick={() => onChangeTab(DraftPostDetailTabs.HISTORY)}
        >
          <p>{t('History')}</p>
        </TabButton>
      </Header>
      <White>
        <Content>
          {selectedTab === DraftPostDetailTabs.DETAILS && <DraftPostDetailTab postId={postId} />}
          {selectedTab === DraftPostDetailTabs.HISTORY && <DraftPostHistoryTab postId={postId} />}
        </Content>
        <Footer>
          <PalleteButton
            text="Close"
            palleteColor={mainWhite}
            width={'fit-content'}
            height={'32px'}
            onClick={closeModal}
          />
        </Footer>
      </White>
    </Sizer>
  );
};

const Sizer = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  background: transparent;
`;

const Content = styled.div`
  flex: 1;
  background: #fff;
  position: relative;
  height: 100%;
  padding: 16px;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;

  button {
    padding: 0 16px;
  }
`;

const Header = styled.div`
  display: inline-flex;
  background: transparent;

  button:first-of-type {
    border-top-left-radius: 3px;
  }

  button:last-of-type {
    border-top-right-radius: 3px;
  }
`;

interface TabButtonProps {
  active: boolean;
}

const TabButton = styled.button<TabButtonProps>`
  cursor: pointer;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #27313b;
  background: ${props => (props.active ? '#fff' : '#eef3f7')};

  p {
    padding: 0 16px;
  }
`;

const White = styled.div`
  background: #fff;
  box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.24), 0 0 12px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const Close = styled.a`
  position: absolute;
  right: 0;
  top: 35px;
  z-index: 2;
  display: flex;
  justify-content: center;
  width: 36px;
  height: 36px;
  font-size: 25px;
  line-height: 34px;
  font-weight: 100;
  background: #fff;
  border-radius: 50%;
  background-color: ${mainRed.normal};
  cursor: pointer;

  &:hover {
    background-color: ${mainRed.hover};
  }

  &::before {
    content: '\\00d7';
    color: #fff;
    font-weight: bold;
  }
`;

export default Template;
