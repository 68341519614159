import * as React from 'react';
import { useQuery } from '@apollo/client';
import { RouteComponentProps } from 'react-router';
import { ListIndicator } from '../../components/molecules/Indicator';
import { CampaignDetailProps } from '../../components/organisms/Campaign/CampaignDetail';
import Empty from '../../components/atoms/Empty';
import * as ENGAGEMENT_FOR_INFLUENCER from './EngagementForInfluencer.graphql';
import { EngagementForInfluencer, EngagementForInfluencerVariables } from './__generated__/EngagementForInfluencer';
import Template from './template';

const EngagementCampaignDetail = (props: RouteComponentProps<{ id: string }>) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const { data, loading } = useQuery<EngagementForInfluencer, EngagementForInfluencerVariables>(
    ENGAGEMENT_FOR_INFLUENCER,
    {
      variables: { pk: Number(id) },
      fetchPolicy: 'cache-and-network',
    }
  );

  if (loading) {
    return <ListIndicator border={false} />;
  }

  if (!data || !data.engagementForInfluencer) {
    return <Empty />;
  }

  const {
    title,
    serviceInformation,
    thumbNail,
    type,
    startDate,
    endDate,
    status,
    currency,
    requirements,
    socialMedias,
    serviceUrl,
    isJoined,
    id: campaignId,
    hasReport,
    hasDraftPosts,
    hasWarningPosts,
    materialUrl,
    trackingUrl,
  } = data.engagementForInfluencer;

  const materials = !data.engagementForInfluencer.materials
    ? []
    : data.engagementForInfluencer.materials
        .map(material => (!!material ? material.url : null))
        .filter((material): material is string => !!material);

  const campaignDetail: CampaignDetailProps = {
    title,
    serviceInformation,
    thumbNail,
    campaignType: type,
    startDate,
    endDate,
    campaignStatus: status,
    currency,
    requirements: requirements || '',
    socialMedias,
    materials,
    productUrl: serviceUrl,
    isJoined,
    campaignId,
    hasReport,
    hasDraftPosts,
    hasWarningPosts,
    materialUrl,
    trackingUrl,
  };

  return <Template {...campaignDetail} />;
};

export default EngagementCampaignDetail;
