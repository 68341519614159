import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { EngagementCampaignPostStatus } from '../../../__generated__/globalTypes';
import { getEngagementPostStatusColor, getEngagementPostStatusMessage } from '../../../libs/status';
import { StatusColumn } from '../../atoms/List';

interface StatusColumnProps {
  className?: string;
  status: EngagementCampaignPostStatus;
  isTableElement?: boolean;
}

const EngagementPostStatusColumn = (props: StatusColumnProps) => {
  const { className, status, isTableElement } = props;
  const color = getEngagementPostStatusColor(status);
  const message = getEngagementPostStatusMessage(status);
  const { t } = useTranslation();

  return (
    <StatusColumn className={className} statusColor={color} isTableElement={isTableElement}>
      {t(message)}
    </StatusColumn>
  );
};

export default EngagementPostStatusColumn;
