import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { defaultInfluencerAvatar } from '@src/libs/image';
import { SliderComponents, StyledComponents } from '@src/components/molecules/SliderTable';
import { ThumbnailColumn, TextColumn } from '@src/components/atoms/List';
import { useQueryHelper } from '@src/libs/hooks';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import SentimentAnalysisMobile from '@src/components/organisms/AnalyticsShared/SentimentAnalysisMobile';
import { usePostDetailModal } from '@src/components/organisms/AnalyticsShared/BasePostList/usePostDetailModal';
import PostingHabitsColumn from '@src/components/molecules/PostingHabitsColumn';
import { ViewportBreakpoint, ViewportType } from '@src/libs/theme';
import { DialogModal } from '@src/components/molecules/DialogModal';
import { PalleteButton } from '@src/components/atoms/Button';
import CarouselModal from '@src/components/molecules/CarouselModal/CarouselModal';
import { MoreText } from '@src/components/organisms/AnalyticsShared/PostDetail/SliderStyledComponents';
import AnalyticsContentWrapper, {
  ContentType,
} from '@src/components/organisms/AnalyticsShared/AnalyticsContentWrapper';
import { useMediaQuery } from 'react-responsive';
import AnalyticsPostDetail from '../../Dashboard/AnalyticsPostDetail';
import { CompareVideoPostSlider } from './CompareVideoPostSlider';
import { ComparePostType } from './types';
import { generateTimeslots } from './helpers';
import PostingHabitsDialogContent from './PostingHabitsDialogContent';
import CustomCss from './CustomCss';

interface TemplateProps {
  id: number;
  accountsWithAvatar: ComparePostType[];
}
const Template = ({ accountsWithAvatar, id }: TemplateProps) => {
  const [dialog, setdialog] = useState<{
    showHashtagsDialog: boolean;
    showPostingHabitsDialog: boolean;
    data: ComparePostType | null;
  }>({ showHashtagsDialog: false, showPostingHabitsDialog: false, data: null });
  const isMobile = useMediaQuery({ maxWidth: ViewportType.SMALL });
  const isTablet = useMediaQuery({ minWidth: ViewportType.TABLET });

  const [clickedThumbnailInfo, setClickedThumbnailInfo] = useState<{
    accountId: number;
    postId: number;
    isMainAccount?: boolean;
  } | null>(null);
  const { t, pathname, search, i18n } = useQueryHelper();
  const clickedAccount = accountsWithAvatar.find(acc => acc.id === clickedThumbnailInfo?.accountId);
  const allVideosPostsOfClickedAccount = clickedAccount?.posts || [];
  const { avatar, name: channelName } = clickedAccount || { avatar: '', name: '' };
  const queryParams = new URLSearchParams(search);
  queryParams.append('id', String(id));

  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev,
  } = usePostDetailModal(allVideosPostsOfClickedAccount);

  const handleCloseDialog = () => {
    setdialog({ showHashtagsDialog: false, showPostingHabitsDialog: false, data: null });
  };

  const handleClickPostingHabitDetail = (item: ComparePostType) => {
    setdialog({ ...dialog, showPostingHabitsDialog: true, data: item });
  };

  const handlePostThumbnailClick = (accountId: number, postId: number, isMainAccount?: boolean) => {
    setClickedThumbnailInfo({ accountId, postId, isMainAccount });
  };

  const handleCloseModal = () => {
    handleClosePostDetail();
    setClickedThumbnailInfo(null);
  };

  useEffect(() => {
    if (clickedThumbnailInfo) {
      handleClickPost(clickedThumbnailInfo.postId);
    }
  }, [clickedThumbnailInfo?.accountId, clickedThumbnailInfo?.postId]);

  return (
    <>
      <AnalyticsContentWrapper contentType={ContentType.Posts}>
        <SliderComponents.Wrapper css={styles.tableWrapper}>
          <SliderComponents.Container>
            <SliderComponents.SliderSection>
              <SliderComponents.SliderTable css={{ width: 100 }}>
                <thead>
                  <SliderComponents.HeaderRow css={styles.headerRow}>
                    <StyledComponents.StyledHeaderColumn
                      title="Account"
                      css={[styles.account, isTablet ? { width: 180 } : '']}
                    />
                    {/* we are imitating header row bcs we have to place posts-slider in full width */}
                    <HeaderImitation>
                      <HeaderTitle css={{ flex: 1 }}>{t('HeaderColumn.Sentiment Analysis')}</HeaderTitle>
                      {!isMobile ? (
                        <HeaderTitle css={{ flex: 1.2 }}>{t('HeaderColumn.Posting Habits')}</HeaderTitle>
                      ) : null}
                    </HeaderImitation>
                  </SliderComponents.HeaderRow>
                </thead>

                <tbody>
                  {accountsWithAvatar.map((item, index) => {
                    const timeslots = generateTimeslots(item.postHabit, i18n.language as AppLanguage);
                    const isMainAccount = item.uniqId.includes('main');

                    return (
                      <StyledComponents.StyledRowNew css={[]} key={index}>
                        <ThumbnailColumn
                          title={item.name}
                          src={defaultInfluencerAvatar(item.avatar)}
                          css={isTablet ? styles.accountThumbnailTablet : styles.accountThumbnail}
                        />
                        <SectionWrapper>
                          {/* top part */}
                          <TopPostSection>
                            <SentimentAnalysisMobile
                              positive={item.sentimentAnalysis?.positiveRate || 0}
                              neutral={item.sentimentAnalysis?.neutralRate || 0}
                              negative={item.sentimentAnalysis?.negativeRate || 0}
                              css={CustomCss.sentimentAnalysisColumn}
                              isTable={false}
                            />
                            {isMobile ? <HeaderTitle>{t('HeaderColumn.Posting Habits')}</HeaderTitle> : null}
                            <PostingHabitsColumn
                              timeslots={timeslots}
                              onClickAdd={() => handleClickPostingHabitDetail(item)}
                              css={CustomCss.postHabitsColumn}
                              isTable={false}
                            />
                          </TopPostSection>
                          <CompareVideoPostSlider
                            item={item}
                            handleClickPost={postId => handlePostThumbnailClick(item.id, postId, isMainAccount)}
                          />
                        </SectionWrapper>
                      </StyledComponents.StyledRowNew>
                    );
                  })}
                  <StyledComponents.StyledRowNew css={[CustomCss.rightAlignedCol]}>
                    <TextColumn data="" />
                    <TextColumn
                      data={<MoreText to={`${pathname}/youtube_compare_feed_videos?${queryParams.toString()}`} />}
                    />
                  </StyledComponents.StyledRowNew>
                </tbody>
              </SliderComponents.SliderTable>
            </SliderComponents.SliderSection>
          </SliderComponents.Container>
        </SliderComponents.Wrapper>
      </AnalyticsContentWrapper>
      {/* posting habits modal */}
      <DialogModal
        isOpen={!!dialog.showHashtagsDialog || !!dialog.showPostingHabitsDialog}
        NextButton={<PalleteButton text="Close" onClick={handleCloseDialog} borderRadius="5px" width="100%" />}
        closeModal={handleCloseDialog}
      >
        <DialogContentWrapper>
          <DialogContentHeader>
            <Avatar src={dialog?.data?.avatar} />
            <h3>{`${dialog?.data?.name || ''} (${t('Posting Habits')})`}</h3>
          </DialogContentHeader>
          {dialog.showPostingHabitsDialog && dialog.data?.postHabit && (
            <PostingHabitsDialogContent postsHabit={dialog.data.postHabit} />
          )}
        </DialogContentWrapper>
      </DialogModal>
      {/* post details dialog */}
      <CarouselModal
        cancel="OK"
        open={!!openPostDetail}
        siblingPostId={siblingPostId}
        title=""
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        onClose={handleCloseModal}
      >
        <AnalyticsPostDetail
          accountId={Number(id)}
          compareAccountId={clickedThumbnailInfo?.accountId || 0}
          isMain={!!clickedThumbnailInfo?.isMainAccount}
          postId={openPostDetail}
          avatarUrl={avatar}
          accountName={channelName}
        />
      </CarouselModal>
    </>
  );
};

const styles = {
  tableWrapper: css`
    padding: 0;
    margin: 0;
    border: 0;
  `,
  account: css`
    width: 74px;
    overflow: hidden;
    text-overflow: ellipsis;
    border-right: 1px solid #dee5ec;
    vertical-align: inherit;

    & > div {
      line-height: 40px;
      height: 40px;
      border-top: 1px solid #dee5ec;
      box-sizing: border-box;
    }
  `,
  headerRow: css`
    background-color: #f6f8fa;
    line-height: 40px;
    height: 40px;
  `,
  container: css`
    border: 1px solid #e6ecf0;
    border-right: none;
  `,
  headerCols: [
    css`
      padding: 0;
      padding-left: 8px;
      font-weight: 400;
      font-size: 12px;
      border-right: 1px solid #e6ecf0;
    `,
  ],
  accountName: css`
    text-align: center;
    font-size: 10px;
    font-weight: 400;
    line-height: 11px;
  `,
  accountThumbnail: css`
    border-right: 1px solid #dee5ec;

    & > div {
      flex-direction: column;

      & > div:first-of-type {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        margin-right: 0;
      }
    }

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: unset;
      text-align: center;

      @media (min-width: ${ViewportType.TABLET}px) {
        width: 100%;
      }
    }
  `,
  accountThumbnailTablet: css`
    border-right: 1px solid #dee5ec;

    & div > div:first-of-type {
      border-radius: 20px;
      height: 40px;
      width: 40px;
    }

    p {
      max-height: 33px;
      min-width: unset;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  `,
};

const HeaderImitation = styled.th`
  display: flex;
`;
const HeaderTitle = styled.h3`
  display: flex;
  align-items: center;
  color: #27313b;
  font-size: 11px;
  font-weight: 600;
  height: 40px;
  padding-left: 16px;
  border-top: 1px solid #dee5ec;
`;
const DialogContentWrapper = styled.div`
  margin-top: 24px;
`;
const DialogContentHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 24px;
`;
const Avatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
`;
const TopPostSection = styled.div`
  display: flex;
  min-height: 90px;

  @media (max-width: ${ViewportBreakpoint.DESKTOP}px) {
    flex-direction: column;

    & > div:first-of-type {
      border-right: 1px solid #dee5ec;
    }
  }
`;
const SectionWrapper = styled.td`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 !important;
  border-top: none !important;
`;

export default Template;
