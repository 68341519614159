import styled from '@emotion/styled';
import * as React from 'react';
import css from '@emotion/css';
import { useLocation } from 'react-router';
import { ViewportBreakpoint } from '../../../../libs/theme';
import {
  CampaignPostStatusForInfluencer,
  CampaignType,
  CampaignStatusForInfluencer,
  MarketplaceCampaignType,
  MarketplaceCampaignAppliedStatus,
} from '../../../../__generated__/globalTypes';
import CampaignBudget from '../CampaignBudget/CampaignBudget';
import Label from '../Label/Label';
import { getCampaignBadge, getCampaignThumbnailText } from '../../../../libs/campaign';
import { BadgeWrapper, StatusBadge } from '../CampaignCard';
import RichEditor from '../../../molecules/RichEditor';
import { useQueryHelper } from '../../../../libs/hooks';
import { WarningWidget } from '../../../molecules/WarningWidget';
import CampaignProductInfo, { CampaignProductInfoProps } from './CampaignProductInfo';
import CampaignStatus from './CampaignStatus';
import CampaignPeriod from './CampaignPeriod';
import CampaignPrice from './CampaignPrice';

interface TemplateProps extends CampaignProductInfoProps {
  thumbnail: string;
  materials: string[];
  campaignStatus: CampaignStatusForInfluencer;
  isJoined: boolean;
  marketplaceCampaignType?: MarketplaceCampaignType;
  isApplied?: boolean;
  appliedStatus?: MarketplaceCampaignAppliedStatus | null;
  hasReport: boolean;
  postStatus?: CampaignPostStatusForInfluencer | null;
}

const Template = (props: TemplateProps) => {
  const { t } = useQueryHelper();
  const { pathname } = useLocation();
  const isSearchPage = pathname.includes('/search');

  const {
    thumbnail,
    title: campaignTitle,
    information,
    campaignType,
    startDate,
    endDate,
    materials,
    currency,
    socialMedias,
    campaignStatus,
    revenuePerPost,
    revenuePerLike,
    revenuePerView,
    revenuePerShare,
    revenuePerComment,
    revenuePerFollower,
    revenuePerClick,
    revenuePerAction,
    totalAmount,
    remainingAmount,
    requirements,
    hashTags,
    trackingUrl,
    productUrl,
    maximumRevenuePerInfluencer,
    downloadMaterialsUrl,
    selectionStartDate,
    selectionEndDate,
    marketplaceCampaignType,
    appliedStatus,
    hasReport,
    postStatus,
  } = props;

  const thumbnailText = getCampaignThumbnailText({
    status: campaignStatus,
    hasReport,
    appliedStatus,
    selectionEndDate,
    postStatus,
  });

  const applicantApproved = appliedStatus === MarketplaceCampaignAppliedStatus.APPROVED;

  const widgetInfo = React.useMemo(() => {
    if (appliedStatus === MarketplaceCampaignAppliedStatus.REJECTED) {
      return {
        title: t('Heading.Application Rejected'),
        reason: t(
          'Annotation.We regret to announce that your application was not approved. We look forward to seeing you again soon!'
        ),
      };
    } else if (postStatus === CampaignPostStatusForInfluencer.INEFFECTIVE) {
      return {
        title: t('Heading.Your post is rejected'),
        reason: t(`Annotation.The post content didn't meet the campaign condition`),
      };
    } else {
      return !applicantApproved
        ? {
            title: t('Heading.This is a Selection campaign'),
            reason: t('Annotation.This campaign is only open to those who have applied and been accepted'),
          }
        : null;
    }
  }, []);

  const isApplying =
    campaignType === CampaignType.ENGAGEMENT ||
    marketplaceCampaignType === MarketplaceCampaignType.NORMAL ||
    applicantApproved
      ? false
      : true;

  const campaignBadge = getCampaignBadge(campaignType, isApplying);
  const showWarningWidget =
    (marketplaceCampaignType === MarketplaceCampaignType.SELECTION ||
      postStatus === CampaignPostStatusForInfluencer.INEFFECTIVE) &&
    widgetInfo;

  return (
    <Wrapper>
      <ThumbnailWrapper>
        <Thumbnail src={thumbnail}></Thumbnail>
        {thumbnailText?.mainText && (
          <StatusBadge
            css={css`
              text-align: center;
            `}
          >
            {t(`Label.${thumbnailText.mainText}`)}
            {thumbnailText?.subText && <p>{t(`Annotation.${thumbnailText.subText}`)}</p>}
          </StatusBadge>
        )}

        {!isSearchPage && (
          <BadgeWrapper
            css={css`
              background-color: ${campaignBadge.bgColor};
            `}
          >
            {t(`Label.${campaignBadge.text}`)}
          </BadgeWrapper>
        )}
      </ThumbnailWrapper>

      <ContentWrapper>
        <ResponsiveContainer>
          {showWarningWidget && (
            <WarningWidget
              warningTitle={widgetInfo!.title}
              warningReason={widgetInfo!.reason}
              showWarningIcon={false}
              css={css`
                margin-bottom: 16px;
              `}
            />
          )}
          <CampaignTitle>{campaignTitle}</CampaignTitle>
          <RichEditor
            showToolbar={false}
            height={'auto'}
            setOptions={{ mode: 'balloon', resizingBar: false }}
            disable={true}
            value={information}
          />
          <Separator />

          <CampaignStats>
            <MobileWrapper>
              {campaignType !== CampaignType.ENGAGEMENT && (
                <CampaignPrice
                  socialMedias={socialMedias}
                  revenuePerPost={revenuePerPost}
                  revenuePerLike={revenuePerLike}
                  revenuePerView={revenuePerView}
                  revenuePerShare={revenuePerShare}
                  revenuePerComment={revenuePerComment}
                  revenuePerFollower={revenuePerFollower}
                  revenuePerClick={revenuePerClick}
                  revenuePerAction={revenuePerAction}
                  currency={currency}
                />
              )}
              <PeriodWrapper>
                {selectionStartDate && selectionEndDate && (
                  <CampaignPeriod
                    css={css`
                      margin-bottom: 16px;
                    `}
                    title={t('Application Period')}
                    start={selectionStartDate}
                    end={selectionEndDate}
                  />
                )}
                <CampaignPeriod title={t('Campaign Period')} start={startDate} end={endDate} />
              </PeriodWrapper>
            </MobileWrapper>
            <MobileWrapper>
              {campaignType === CampaignType.MARKETPLACE && remainingAmount !== null && Number(totalAmount) > 0 && (
                <CampaignBudgetWrapper>
                  <Label title="Budget" />
                  <CampaignBudget remainingAmount={remainingAmount || 0} totalAmount={totalAmount || 0} />
                </CampaignBudgetWrapper>
              )}
              <CampaignStatus status={campaignStatus} />
            </MobileWrapper>
          </CampaignStats>
          <Separator />

          <CampaignProductInfo
            productUrl={productUrl}
            trackingUrl={trackingUrl}
            information={information}
            campaignType={campaignType}
            hashTags={hashTags}
            materials={materials}
            currency={currency}
            title={campaignTitle}
            startDate={startDate}
            endDate={endDate}
            requirements={requirements}
            socialMedias={socialMedias}
            maximumRevenuePerInfluencer={maximumRevenuePerInfluencer}
            downloadMaterialsUrl={downloadMaterialsUrl}
          />
        </ResponsiveContainer>
      </ContentWrapper>
    </Wrapper>
  );
};

const ResponsiveContainer = styled.div`
  @media (min-width: ${ViewportBreakpoint.DESKTOP}px) {
    max-width: 640px;
  }
`;

const Wrapper = styled.div`
  overflow: hidden;
`;

const ThumbnailWrapper = styled.div`
  position: relative;
`;

const PeriodWrapper = styled.div`
  margin-right: 16px;

  @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
    width: 35%;
  }
`;

const CampaignBudgetWrapper = styled.div`
  width: 164px;
  margin-right: 16px;
`;

const Thumbnail = styled.img`
  width: 100%;
  object-fit: contain;
  background-color: #000;
  max-height: 400px;
  min-height: 200px;
`;

const ContentWrapper = styled.div`
  padding: 24px 64px 40px;
  background-color: #fff;

  @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
    padding: 16px;
  }

  @media (min-width: ${ViewportBreakpoint.DESKTOP}px) {
    display: flex;
    justify-content: center;
  }
`;

const MobileWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
    justify-content: space-between;
    margin-bottom: 16px;
  }
`;

const CampaignStats = styled.div`
  display: flex;

  @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
    flex-direction: column;
  }
`;

const CampaignTitle = styled.h3`
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #27313b;
  margin-bottom: 16px;
`;

const Separator = styled.div`
  height: 1px;
  background-color: #eef3f7;
  margin: 16px 0;
`;

export default Template;
