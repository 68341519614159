import { useField, useFormikContext } from 'formik';
import * as React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { SubmitButton } from '@src/components/atoms/Button';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import TextForm from '@src/components/molecules/TextForm';
import { SignUpAndSignInBreakpoint } from '@src/libs/theme';
import { FormValues } from './types';

const SignInEmailForm = () => {
  const { t } = useQueryHelper();
  const { isMobileViewPublicRoutes } = usePageLayout();

  const formikProps = useFormikContext<FormValues>();
  const { handleSubmit, isSubmitting, status } = formikProps;
  const [emailField, emailMeta] = useField('email');
  const [passwordField, passwordMeta] = useField('password');

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <AnchorBack>
          {!isMobileViewPublicRoutes && (
            <AnchorLink to="/">
              <Icon className="material-icons">arrow_back</Icon>
            </AnchorLink>
          )}
          <Title>{t('Heading.Sign in with E-Mail')}</Title>
        </AnchorBack>
        <InfoText marginBottom={24}>{t('Annotation.Email address and password registered')}</InfoText>

        <InfoWrapper>
          <InputWrapper>
            <TextFormStyled title="Email" placeholder="your@email.com" error={!!emailMeta.error} {...emailField} />
            {emailMeta.error && <ErrorMessage message={t(emailMeta.error)} />}
          </InputWrapper>

          <InputWrapper>
            <TextFormStyled
              type="password"
              title="Password"
              placeholder="Your Password"
              error={!!passwordMeta.error}
              {...passwordField}
            />
            {passwordMeta.error && <ErrorMessage message={t(passwordMeta.error)} />}
            {!emailMeta.error && !passwordMeta.error && status && <ErrorMessage message={status} />}
          </InputWrapper>

          <ForgetPasswordWrapper>
            <ForgotPassword to="/password_forgot">{`${t('Label.Forgot Password')}?`}</ForgotPassword>
          </ForgetPasswordWrapper>

          <SubmitButtonStyled value="Sign in" isSubmitting={isSubmitting} bgcolor="#3892e5" hoverbgcolor="#3892e5" />
        </InfoWrapper>
      </Container>
    </form>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 24px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  margin-bottom: 24px;
`;

const Title = styled.div<{ marginBottom?: number }>`
  display: flex;
  flex: 1;
  font-size: 20px;
  font-weight: 600;
  justify-content: center;
  color: #27313b;
  ${({ marginBottom }) => (marginBottom ? `margin-bottom: ${marginBottom}px;` : '')}
`;

const AnchorLink = styled(Link)`
  display: flex;
  left: 0;
  position: absolute;

  & > i {
    &:hover {
      opacity: 0.7;
    }
  }
`;

const Icon = styled.i`
  color: #3892e5;
  font-size: 22px;
`;

const AnchorBack = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
  position: relative;
`;

const InfoText = styled.p<{ marginBottom?: number }>`
  font-size: 14px;
  line-height: 18px;
  color: #27313b;
  text-align: center;
  ${({ marginBottom }) => (marginBottom ? `margin-bottom: ${marginBottom}px;` : '')}
`;

const ForgotPassword = styled(Link)`
  color: #3892e5;
  font-size: 12px;
  line-height: 1.4em;

  &:hover {
    text-decoration: underline;
  }
`;

const ForgetPasswordWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
  width: 100%;
`;

const TextFormStyled = styled(TextForm)`
  width: 100%;

  & label {
    font-size: 14px;
  }

  & input {
    border-radius: 3px;
    height: 32px;

    @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
      height: 40px;
    }
  }
`;

const SubmitButtonStyled = styled(SubmitButton)`
  border-radius: 3px;
  height: 32px;
  line-height: inherit;
  margin-bottom: 24px;
  text-transform: capitalize;
  filter: brightness(1);
  transition: 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    border-radius: 5px;
    height: 40px;

    &:hover {
      filter: brightness(1);
    }
  }
`;

export default SignInEmailForm;
