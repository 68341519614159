import React from 'react';
import styled from '@emotion/styled';
import AnalyticsContentWrapper, {
  ContentType,
} from '@src/components/organisms/AnalyticsShared/AnalyticsContentWrapper';
import { ViewportBreakpoint } from '@src/libs/theme';
import { css } from '@emotion/react';
import GenderChart from './GenderChart';
import { InfluencerProfileAudience_influencerProfileAudience_ageGenderRates } from './__generated__/InfluencerProfileAudience';
import AgeChart from './AgeChart';

interface AudienceChartProps {
  followerGenderRate?: {
    femaleRate?: number | null;
    maleRate?: number | null;
  };
  followerAgeRate?: InfluencerProfileAudience_influencerProfileAudience_ageGenderRates;
}
const AudienceFollowersTemplate = (props: AudienceChartProps) => {
  const { followerAgeRate, followerGenderRate } = props;

  return (
    <AnalyticsContentWrapper contentType={ContentType.Audience} hasHelp={false}>
      <ChartContainer>
        <GenderChart {...followerGenderRate} css={styles.chartContainer} />
        <AgeChart {...followerAgeRate} css={styles.chartContainer} />
      </ChartContainer>
    </AnalyticsContentWrapper>
  );
};

const styles = {
  chartContainer: css`
    width: 100%;

    &:last-child {
      border-left: 1px solid #dee5ec;

      @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
        border-left-style: none;
        border-top: 1px solid #dee5ec;
      }
    }
  `,
};
export const ChartContainer = styled.div`
  display: flex;
  flex: 1;

  @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
    flex-direction: column;
  }
`;
export const ChartWrapper = styled.div`
  width: 100%;
  border-right: 1px solid #dee5ec;

  &:last-child {
    border-right-style: none;

    @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
      border-top: 1px solid #dee5ec;
    }
  }
`;

export default AudienceFollowersTemplate;
