import styled from '@emotion/styled';
import * as React from 'react';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { PalleteButton } from '@src/components/atoms/Button';
import { SignUpAndSignInBreakpoint } from '@src/libs/theme';
import { mainWhite, linerGradientIG } from '@src/libs/pallete';
import { convertBreakLines } from '@src/libs/format';
import { getUrlOfHowToConnectIG } from '@src/libs/getUrlOfHowToConnectIG';

export interface IgReconnectFormProps {
  onClick: () => void;
  loading: boolean;
}

const IgReconnectForm = (props: IgReconnectFormProps) => {
  const { t, i18n } = useTranslation();
  const { onClick, loading } = props;

  return (
    <>
      <Title>{t('Annotation.About changing your Instagram business account')}</Title>
      <AnnotationRed>{t('Annotation.This change is for those who have registered with Instagram')}</AnnotationRed>
      <Annotation css={styles.annotation}>
        {t('Annotation.Business account conversion of Instagram account is required')}
      </Annotation>
      <Annotation>
        {convertBreakLines(
          t(
            'Annotation.Simply sign-up with Facebook in order to connect with your Instagram account. If you already have an account with Instagram, please follow the following steps, as we will be streamlining signing-up/singing-in through your Instagram account very soon'
          )
        )}
      </Annotation>

      <ButtonIgReconnect
        palleteColor={linerGradientIG}
        height="32px"
        text="Reconnect"
        onClick={onClick}
        isDisabled={loading}
      />

      <ButtonMoreInformation
        palleteColor={mainWhite}
        height="32px"
        href={getUrlOfHowToConnectIG(i18n.language)}
        targetBlank={true}
        text="More information"
        isDisabled={loading}
      />
    </>
  );
};

const Title = styled.h1`
  font-size: 18px;
  color: #27313b;
  font-weight: bold;
  margin: 32px 0 8px;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    margin: 24px 0 16px;
  }
`;

const Annotation = styled.p`
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #27313b;
`;

const AnnotationRed = styled(Annotation)`
  margin-bottom: 16px;
  color: #ff5f5f;
  font-size: 12px;
`;

const ButtonMoreInformation = styled(PalleteButton)`
  border-radius: 5px;
  padding: 0 42px;
  width: auto;
  margin-top: 16px;
  text-transform: capitalize;
`;

const ButtonIgReconnect = styled(PalleteButton)`
  border-radius: 5px;
  padding: 0 42px;
  width: auto;
  margin-top: 16px;
  text-transform: capitalize;
`;

const styles = {
  annotation: css`
    margin-bottom: 16px;
  `,
};

export default IgReconnectForm;
