import React from 'react';
import { css } from '@emotion/react';
import InstagramInstructure from '@src/components/organisms/SignUpInstagram/InstagramInstruction';
import Footer from '@src/components/organisms/TopPage/Footer';
import Header from '@src/components/organisms/TopPage/Header';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { SignUpAndSignInBreakpoint } from '@src/libs/theme';

const SignUpInstagramUnableConnect = () => {
  const { history } = useQueryHelper();
  const { isMobileViewPublicRoutes } = usePageLayout();

  return (
    <div css={styles.container}>
      <div css={styles.content}>
        {!isMobileViewPublicRoutes && <Header href="/" />}
        <div css={styles.instructionContainer}>
          <InstagramInstructure backUrl="/signup" failedConnect onClick={() => history.push('/signup')} />
        </div>
        {!isMobileViewPublicRoutes && <Footer />}
      </div>
    </div>
  );
};

const styles = {
  container: css`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;

    @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
      align-items: flex-start;
    }
  `,
  content: css`
    max-width: 856px;
    width: 100%;
  `,
  instructionContainer: css`
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px #dee5ec, 0 1px 4px rgba(222, 229, 236, 0.5);
    min-height: 520px;
    width: 100%;

    @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
      box-shadow: none;
    }
  `,
};

export default SignUpInstagramUnableConnect;
