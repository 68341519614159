import styled from '@emotion/styled';
import { useField, useFormikContext } from 'formik';
import * as React from 'react';
import css from '@emotion/css';
import { useTranslation } from 'react-i18next';
import { LinkButton, SubmitButton } from '@src/components/atoms/Button';
import mailOpenIcon from '@src/assets/icon/mailOpenedBlue.svg';
import TextForm from '@src/components/molecules/TextForm';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import { SignUpAndSignInBreakpoint } from '@src/libs/theme';
import { FormValues, SubmissionStatus } from './types';

const ResetPasswordForm = () => {
  const { t } = useTranslation();
  const { handleSubmit, isSubmitting, status } = useFormikContext<FormValues>();
  const [emailField, emailMeta] = useField('email');
  const isSuccess = status === SubmissionStatus.OK;

  return (
    <form onSubmit={handleSubmit} css={isSuccess ? styles.formSuccessResetPassword : styles.formResetPassword}>
      <Container>
        <Heading>{t('Heading.Reset the password')}</Heading>
        {isSuccess ? (
          <>
            <MailImage src={mailOpenIcon} />

            <InfoText>{t('checkInbox')}</InfoText>

            <ResetPasswordText>{t('resetFollowInstruction')}</ResetPasswordText>
          </>
        ) : (
          <>
            <SubHeading>{t('SubHeading.Please reset the password from the email sent')}</SubHeading>
            <InputWrapper>
              <TextFormStyled
                title="Email"
                placeholder="your@gmail.com"
                isRequired
                error={!!emailMeta.error}
                {...emailField}
              />
              {emailMeta.error && <ErrorMessage message={t(emailMeta.error)} />}
            </InputWrapper>
            <ButtonWrapper>
              <BackButton title="Back" href="/" color="#6e7c89" bgcolor="#fff" hoverbgcolor="#fff" />
              <SubmitButtonStyled value="Send" isSubmitting={isSubmitting} bgcolor="#3892e5" hoverbgcolor="#3892e5" />
            </ButtonWrapper>
          </>
        )}
      </Container>
    </form>
  );
};

const Heading = styled.p`
  font-size: 20px;
  line-height: 24px;
  color: #27313b;
  margin-bottom: 16px;
  text-align: center;
`;

const SubHeading = styled.p`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #27313b;
  margin-bottom: 32px;
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;

const InputWrapper = styled.div`
  flex: 1;
  margin-bottom: 24px;
`;

const MailImage = styled.img`
  margin: 42px auto 0;
  width: 36px;
`;

const InfoText = styled.p`
  font-size: 14px;
  color: #27313b;
  line-height: 1.38;
  margin-top: 28px;
  text-align: center;
`;

const ResetPasswordText = styled(InfoText)`
  margin-top: 13px;
`;

const BackButton = styled(LinkButton)`
  border-radius: 3px;
  border: 1px solid #dee5ec;
  margin-right: 16px;
  font-weight: 600;
  font-size: 12px;
  flex: 1;
  filter: brightness(1);
  height: 30px;
  transition: 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  &:active {
    background-color: #f6f8fa;
  }

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    border-radius: 5px;
    height: 38px;

    &:hover {
      filter: brightness(1);
    }
  }
`;

const TextFormStyled = styled(TextForm)`
  & label {
    font-size: 14px;
  }

  & input {
    border-radius: 3px;
    height: 32px;

    @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
      height: 40px;
    }
  }
`;

const SubmitButtonStyled = styled(SubmitButton)`
  border: 1px solid #3892e5;
  border-radius: 3px;
  flex: 1;
  height: 32px;
  line-height: 0;
  filter: brightness(1);
  text-transform: capitalize;
  transition: 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    border-radius: 5px;
    height: 40px;

    &:hover {
      filter: brightness(1);
    }
  }
`;

const styles = {
  formResetPassword: css`
    max-width: 348px;
    width: 100%;
  `,
  formSuccessResetPassword: css`
    width: 100%;
  `,
};

export default ResetPasswordForm;
