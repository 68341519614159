import { NotificationId } from '@src/__generated__/globalTypes';
import ClipboardIcon from '@src/assets/icon/clipboardLightBlue.svg';
import WalletIcon from '@src/assets/icon/walletBlack.svg';

export const notificationIcon = (type: NotificationId) => {
  switch (type) {
    case NotificationId.ENGAGEMENT_DRAFT_POST_APPROVED:
    case NotificationId.ENGAGEMENT_DRAFT_POST_REJECTED:
    case NotificationId.ENGAGEMENT_FETCHED:
    case NotificationId.ENGAGEMENT_FINISH:
    case NotificationId.MARKETPLACE_CAMPAIGN_FINISHED:
    case NotificationId.MARKETPLACE_CAMPAIGN_JOINED:
    case NotificationId.MARKETPLACE_CAMPAIGN_REACH_BUDGET:
    case NotificationId.MARKETPLACE_CAMPAIGN_STARTED:
    case NotificationId.MARKETPLACE_CAMPAIGN_UPCOMING:
    case NotificationId.MARKETPLACE_CAMPAIGN_UPDATE:
    case NotificationId.MP_CAMPAIGNS_APPLICANT_INFLS_APPROVED:
      return ClipboardIcon;
    case NotificationId.PAYMENT_COMPLETED:
    case NotificationId.PAYMENT_FAILED:
    case NotificationId.PAYMENT_REQUEST_DONE:
      return WalletIcon;
    default:
      return '';
  }
};

export const notificationRedirectUrl = (campaignId: number, type: NotificationId) => {
  switch (type) {
    case NotificationId.ENGAGEMENT_FINISH:
      return `/casting/your_job/${campaignId}`;
    case NotificationId.ENGAGEMENT_DRAFT_POST_APPROVED:
    case NotificationId.ENGAGEMENT_DRAFT_POST_REJECTED:
    case NotificationId.ENGAGEMENT_FETCHED:
      return `/casting/your_job/${campaignId}/post`;
    case NotificationId.MARKETPLACE_CAMPAIGN_FINISHED:
    case NotificationId.MARKETPLACE_CAMPAIGN_JOINED:
    case NotificationId.MARKETPLACE_CAMPAIGN_REACH_BUDGET:
    case NotificationId.MARKETPLACE_CAMPAIGN_UPDATE:
    case NotificationId.MP_CAMPAIGNS_APPLICANT_INFLS_APPROVED:
      return `/marketplace/your_job/${campaignId}`;
    case NotificationId.MARKETPLACE_CAMPAIGN_STARTED:
    case NotificationId.MARKETPLACE_CAMPAIGN_UPCOMING:
      return `/marketplace/search_job/${campaignId}`;
    case NotificationId.PAYMENT_COMPLETED:
    case NotificationId.PAYMENT_FAILED:
    case NotificationId.PAYMENT_REQUEST_DONE:
      return '/payment';
    default:
      return '';
  }
};
