import React from 'react';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import PostDetail from '@src/components/organisms/AnalyticsShared/PostDetail';
import { InfluencerInstagramInteractionTaggedPostDetails_influencerInstagramInteractionTaggedPostDetails } from './__generated__/InfluencerInstagramInteractionTaggedPostDetails';

interface TemplateProps {
  closeModal: () => void;
  data?: InfluencerInstagramInteractionTaggedPostDetails_influencerInstagramInteractionTaggedPostDetails | null;
  accountType?: SocialAccountType;
  accountName?: string;
  avatarUrl?: string;
}

const Template = (props: TemplateProps) => {
  const { data, closeModal, accountType } = props;

  if (!data) {
    closeModal();

    return null;
  }

  const { postUrl, avatarUrl, instagramAccountName, postImageUrl, comments, likes, commentList, content } = data;

  return (
    <PostDetail
      avatarUrl={avatarUrl || null}
      accountType={accountType}
      accountName={instagramAccountName}
      followers={null}
      content={content}
      commentList={commentList || []}
      mediaUrls={postImageUrl ? [postImageUrl] : null}
      engagement={{
        sentiment: null,
        storyData: null,
        analysis: {
          comments,
          likes,
        },
        postUrl,
      }}
    />
  );
};

export default Template;
