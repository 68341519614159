import { Trans } from 'react-i18next';
import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import warningOrange from '@src/assets/icon/warningOrange.svg';
import searchJobBackground from '@src/assets/img/searchJobBackground.png';
import { Button } from '@src/components/atoms/Button';
import Grid from '@src/components/atoms/Grid';
import { Icon } from '@src/components/atoms/Icon';
import { ListIndicator } from '@src/components/molecules/Indicator';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { LIMIT } from '@src/libs/constant';
import { formatNumberWithCommas } from '@src/libs/format';
import { useAuthData, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import { PaymentRequestStatusForInfluencer, UserRoles } from '@src/__generated__/globalTypes';
import CREATE_PAYMENT_REQUEST from './mutations/CreatePaymentReq.graphql';
import { CreatePaymentReq } from './mutations/__generated__/CreatePaymentReq';
import PAYMENT_INFORMATION from './queries/PaymentInformation.graphql';
import { PaymentInformation, PaymentInformationVariables } from './queries/__generated__/PaymentInformation';

const Payment = () => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { role } = useAuthData();
  const { enqueueSnackbar, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();

  const { data, loading } = useQuery<PaymentInformation, PaymentInformationVariables>(PAYMENT_INFORMATION, {
    variables: { offset: 0, limit: LIMIT },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [createPaymentRequest] = useMutation<CreatePaymentReq>(CREATE_PAYMENT_REQUEST, {
    refetchQueries: ['PaymentInformation'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInCreating'), { variant: 'success' });
      setIsDialogOpen(false);
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const grossRevenueCurrency = data?.paymentRequest?.currency || '';
  const grossRevenue = data?.paymentRequest?.grossRevenue || 0;
  const hasIncorrectBankAcc = !!data?.influencerPaymentRequests.paymentRequests.find(
    payment => payment.status === PaymentRequestStatusForInfluencer.FAILED
  );
  const hasPaymentInformation = !!data?.influencerPaymentInformation?.accountNumber;
  const minimumPaymentAmount = data?.paymentRequest?.minimumPaymentAmount || 0;
  const payableCampaigns = data?.payableCampaign || [];
  const isLessThanPayableAmount = grossRevenue < minimumPaymentAmount;
  const isPayableCampaigns = payableCampaigns.length > 0;
  const amount = grossRevenueCurrency
    ? `${formatNumberWithCommas(minimumPaymentAmount)} ${t(grossRevenueCurrency)}`
    : formatNumberWithCommas(minimumPaymentAmount);

  function onClickRequestPayment() {
    setIsSubmitting(true);
    createPaymentRequest().finally(() => setIsSubmitting(false));
  }

  return (
    <>
      <Popup
        contentStyle={{
          animation: 'fadeIn linear 0.3s',
          borderRadius: 9,
          maxWidth: 343,
          width: '90%',
        }}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        <div>
          <div css={styles.dialogCloseBtn} onClick={() => setIsDialogOpen(false)}>
            <Icon icon="close" />
          </div>

          <div css={styles.dialogContent}>
            {isLessThanPayableAmount ? (
              <>
                <div>{t('The payable amount has not been reached')}</div>
                <div>
                  <Trans i18nKey="In order to get your money">
                    In order to get your money, the requestable revenue has to be at least
                    <span css={styles.warningFont}>{{ amount }}</span>.
                  </Trans>
                </div>
              </>
            ) : (
              <>
                <div>{t('Request Payment')}</div>
                <div>{t('Are you sure you would like to request a payment')}</div>
              </>
            )}
          </div>

          <div css={styles.dialogAction}>
            <StyledButton
              bgcolor="#fff"
              color="#6e7c89"
              hoverbgcolor="#fff"
              title="Close"
              onClick={() => setIsDialogOpen(false)}
            />
            {!isLessThanPayableAmount && (
              <StyledButton
                bgcolor="#3892e5"
                disabled={isSubmitting}
                isPrimary
                hoverbgcolor="#3892e5"
                title="Confirm"
                onClick={onClickRequestPayment}
              />
            )}
          </div>
        </div>
      </Popup>

      <div css={styles.container}>
        <div>
          {!isMobileView && (
            <div css={{ marginBottom: 24 }}>
              <BackNavigator title="Payment" />
            </div>
          )}

          <div css={styles.infoContainer}>
            <Grid lg={8} sm={10} xs={12}>
              {hasIncorrectBankAcc || !hasPaymentInformation ? (
                <div css={styles.warningIndicator}>
                  <img alt="warningIcon" height="24" src={warningOrange} width="24" />
                  <div>
                    <span>
                      {t(hasIncorrectBankAcc ? 'Incorrect bank account' : 'Bank account has not been registered')}
                    </span>
                    <div>
                      <span>
                        {t(
                          hasIncorrectBankAcc
                            ? `We couldn't transfer the money because your registered bank account is incorrect! Click the button below to correct it`
                            : 'You need to register your bank account to receive your reward'
                        )}
                        <Link to="/settings/payment_information">{`>${t('settings')}`}</Link>
                      </span>
                    </div>
                  </div>
                </div>
              ) : null}

              <div css={styles.pendingRevenueContainer}>
                <div>
                  <div>
                    <div>{t('Payment.Pending Revenue')}</div>
                    <div>
                      <span>{formatNumberWithCommas(grossRevenue, 0)}&nbsp;</span>
                      <span>{grossRevenueCurrency}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <StyledButton
                      bgcolor="#3892e5"
                      disabled={!isPayableCampaigns || hasIncorrectBankAcc || !hasPaymentInformation}
                      hoverbgcolor="#3892e5"
                      isPrimary
                      title="Request Payment"
                      onClick={() => setIsDialogOpen(true)}
                    />
                    <StyledLink to="/payment/history">{t('Button.Payment History')}</StyledLink>
                  </div>
                </div>
              </div>

              {loading ? (
                <ListIndicator />
              ) : isPayableCampaigns ? (
                <>
                  <div css={styles.payableCampaignsHeader}>
                    <div>{t('Payable finished campaigns')}</div>
                    <div>
                      <span>{payableCampaigns.length}</span>
                      <span>{t('HeaderColumn.Campaign')}</span>
                    </div>
                  </div>
                  <div css={styles.payableCampaignsContainer}>
                    {payableCampaigns.map(campaign => {
                      const { currency, id, revenue, thumbNail, title } = campaign;

                      return (
                        <div css={styles.payableCampaignContainer} key={id}>
                          <div>
                            <div>
                              <img alt="campaignImg" height="72" src={thumbNail} width="112" />
                            </div>
                            <div>{title}</div>
                          </div>
                          <div>
                            <div>{t('Revenue')}</div>
                            <div>
                              <span>{formatNumberWithCommas(revenue, 0)}&nbsp;</span>
                              <span>{currency}</span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <div css={styles.emptyJobContainer}>
                  <div>
                    <div>
                      <img alt="emptyJob" height="124" src={searchJobBackground} width="120" />
                    </div>
                    <span>{t('Annotation.There are no completed projects at this time')}</span>
                    <span>{t(`Annotation.Join the job and get rewarded`)}</span>
                    {role !== UserRoles.CREATOR_STAFF && (
                      <div>
                        <StyledLink css={styles.searchJobBtn} to="/marketplace/search_job">
                          {t('Button.Search Job')}
                        </StyledLink>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

const StyledButton = styled(Button)<{ isPrimary?: boolean }>`
  border: 1px solid ${({ isPrimary }) => (isPrimary ? '#3892e5' : '#dee5ec')};
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  height: 32px;
  margin-bottom: 16px;

  @media (max-width: ${ViewportType.TABLET}px) {
    height: 40px;
  }
`;

const StyledLink = styled(Link)`
  align-items: center;
  border: 1px solid #dee5ec;
  border-radius: 5px;
  color: #6e7c89;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 32px;
  justify-content: center;
  width: fill-available;

  @media (max-width: ${ViewportType.TABLET}px) {
    height: 40px;
  }
`;

const styles = {
  container: css`
    display: flex;
    justify-content: center;
    margin: 32px 80px;

    @media (max-width: ${ViewportType.TABLET}px) {
      margin: 16px 16px 32px 16px;
    }

    & > div {
      max-width: 1080px;
      width: 100%;
    }
  `,
  dialogAction: css`
    display: flex;
    justify-content: center;
    margin-top: 16px;
    padding: 0 16px;

    & > button:nth-of-type(2) {
      margin-left: 8px;
    }
  `,
  dialogCloseBtn: css`
    align-items: center;
    background-color: #000;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: -10px;
    top: -15px;
    width: 40px;

    & > i {
      margin: 0;
    }
  `,
  dialogContent: css`
    border-bottom: 1px solid #dee5ec;
    display: grid;
    padding: 24px 16px;

    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: 14px;
    }
  `,
  emptyJobContainer: css`
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    display: flex;
    height: 342px;
    justify-content: center;
    width: 100%;

    /* stylelint-disable no-descending-specificity */
    & > div {
      display: grid;
      text-align: center;
      max-width: 300px;

      & > div {
        display: flex;
        justify-content: center;
      }

      & > span:nth-of-type(1) {
        color: #27313b;
        font-size: 14px;
        margin-top: 16px;
      }

      & > span:nth-of-type(2) {
        color: #6e7c89;
        font-size: 12px;
        margin-top: 16px;
      }
    }
  `,
  infoContainer: css`
    display: flex;
    justify-content: center;
  `,
  payableCampaignContainer: css`
    padding: 24px;

    & > div:nth-of-type(1) {
      align-items: center;
      border-bottom: 1px dashed #dee5ec;
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 16px;

      & > div:nth-of-type(1) {
        background-color: #000;
        height: 72px;
        margin-right: 16px;
        width: 112px;

        & > img {
          object-fit: contain;
        }
      }

      & > div:nth-of-type(2) {
        color: #27313b;
        display: flex;
        flex: 1;
        font-size: 14px;
      }
    }

    & > div:nth-of-type(2) {
      display: flex;
      flex-wrap: wrap;
      padding-top: 16px;

      & > div:nth-of-type(1) {
        color: #27313b;
        display: flex;
        flex-basis: 50%;
        font-size: 14px;
      }

      & > div:nth-of-type(2) {
        display: flex;
        flex-basis: 50%;
        justify-content: flex-end;

        & > span:nth-of-type(1) {
          color: #27313b;
          font-size: 14px;
          font-weight: 600;
        }

        & > span:nth-of-type(2) {
          color: #27313b;
          font-size: 14px;
        }
      }
    }
  `,
  payableCampaignsContainer: css`
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;

    & > div:not(:last-child) {
      border-bottom: 1px solid #eef3f7;
    }
  `,
  payableCampaignsHeader: css`
    align-items: center;
    display: flex;
    margin-bottom: 10px;

    & > div:nth-of-type(1) {
      color: #27313b;
      display: flex;
      flex-basis: 50%;
      font-size: 14px;
    }

    & > div:nth-of-type(2) {
      align-items: center;
      display: flex;
      flex-basis: 50%;
      justify-content: flex-end;

      & > span:nth-of-type(1) {
        color: #27313b;
        font-size: 14px;
        font-weight: 600;
      }

      & > span:nth-of-type(2) {
        color: #6e7c89;
        font-size: 11px;
        margin-left: 4px;
      }
    }
  `,
  pendingRevenueContainer: css`
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    display: flex;
    flex-wrap: wrap;
    height: 128px;
    margin: 0 0 24px 0;

    & > div:nth-of-type(1) {
      align-items: center;
      border-right: 1px solid #dee5ec;
      display: flex;
      flex-basis: 49.5%;

      & > div {
        padding: 0 24px;

        & > div:nth-of-type(1) {
          color: #27313b;
          font-size: 12px;
        }

        & > div:nth-of-type(2) {
          & > span:nth-of-type(1) {
            color: #27313b;
            font-size: 18px;
            font-weight: 600;
          }

          & > span:nth-of-type(2) {
            color: #27313b;
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
    }

    & > div:nth-of-type(2) {
      align-items: center;
      display: flex;
      flex-basis: 50%;

      & > div {
        padding: 0 24px;
        width: 100%;
      }
    }
  `,
  searchJobBtn: css`
    height: 40px;
    margin-top: 24px;
    width: 156px;
  `,
  warningFont: css`
    color: #ff5f5f;
  `,
  warningIndicator: css`
    background-color: #fff;
    border-left: 4px solid #ffb63d;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    padding: 12px;

    & > img {
      margin-right: 12px;
    }

    & > div {
      display: grid;
      flex: 1;

      & > span {
        color: #27313b;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      & > div {
        color: #6e7c89;
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;

        & > span > a {
          color: #6e7c89;
          display: initial;
          font-size: 14px;
          margin-left: 8px;
          text-decoration: underline;
        }
      }
    }
  `,
};

export default Payment;
