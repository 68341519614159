import React from 'react';
import { css } from '@emotion/core';
import { Formik } from 'formik';
import styled from '@emotion/styled';
import { Button } from '@src/components/atoms/Button';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import { useTranslation } from 'react-i18next';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import clipboardGray from '@src/assets/icon/menu/clipboardGray.svg';
import { FanActivityType } from '@src/__generated__/globalTypes';
import { FanActivityCommentSchema } from '@src/libs/validation';
import CommentForm, { FormValues } from './CommentForm';

const ACTIVITY_TIME_FORMAT = 'MM-dd-yyyy h:mm aaa';

interface ActivityRowProps {
  activity: string | null;
  created: string;
  activityType: FanActivityType;
  commenter: string;
  showCommentInsideForm: boolean;
  onClickMore?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onUpdate?: (comment: string) => void;
}

const ActivityRow = ({
  activity,
  created,
  activityType,
  commenter,
  showCommentInsideForm,
  onClickMore,
  onUpdate,
}: ActivityRowProps) => {
  const { i18n, t } = useTranslation();

  const handleSubmit = (payload: FormValues) => {
    if (onUpdate) {
      onUpdate(payload.comment);
    }
  };

  const activityTime = localizedDateFormatter(
    new Date(created + 'Z'),
    ACTIVITY_TIME_FORMAT,
    i18n.language as AppLanguage
  );

  if (activityType === FanActivityType.COMMENT_CREATE) {
    if (showCommentInsideForm) {
      return (
        <Formik
          initialValues={{ comment: activity || '' }}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={FanActivityCommentSchema}
        >
          <CommentForm />
        </Formik>
      );
    } else {
      return (
        <>
          <Row isComment={true}>
            <CommentTime>{activityTime}</CommentTime>
            <CommentIcon src={clipboardGray} />
            <DetailsWrapper>
              <PrimaryText css={marginBottom4}>{activity}</PrimaryText>
              <SecondaryText css={marginBottom0}>
                {t('Note by', { ...(!!commenter ? { name: commenter } : {}) })}
              </SecondaryText>
            </DetailsWrapper>
            <MoreButton
              bgcolor="#fff"
              color="#6e7c89"
              hoverbgcolor="#F6F8FA"
              excludeTitleTranslation={true}
              title="..."
              onClick={onClickMore}
            />
          </Row>
        </>
      );
    }
  }

  return (
    <Row>
      <CircleWrapper>
        <Circle />
      </CircleWrapper>
      <DetailsWrapper>
        <SecondaryText>{activityTime}</SecondaryText>
        <PrimaryText>{activity}</PrimaryText>
      </DetailsWrapper>
    </Row>
  );
};

const marginBottom0 = css`
  margin-bottom: 0;
`;
const marginBottom4 = css`
  margin-bottom: 4px;
`;
const CircleWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 9px;

  &::before {
    content: '';
    width: 2px;
    height: 50%;
    position: absolute;
    background-color: #dee5ec;
    left: calc(50% - 1px);
    top: -2px;
  }

  &::after {
    content: '';
    width: 2px;
    height: 50%;
    position: absolute;
    background-color: #dee5ec;
    left: calc(50% - 1px);
    bottom: -2px;
  }
`;
const Circle = styled.div`
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 4px solid #dee5ec;
  border-radius: 8px;
  box-sizing: border-box;
  z-index: 1;
`;
const MoreButton = styled(Button)`
  width: 32px;
  line-height: 30px;
  font-weight: bold;
  margin-right: 8px;
`;
const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 8px;
`;
const CommentIcon = styled.img`
  width: 24px;
  height: 24px;
  align-self: center;
  padding-left: 12px;
`;
const Row = styled.div<{ isComment?: boolean }>`
  border-radius: 5px;
  margin-bottom: 24px;
  position: relative;
  display: flex;
  align-items: stretch;
  border: 1px solid ${props => (props.isComment ? '#dee5ec' : 'transparent')};
  ${({ isComment }) => (isComment ? 'background-color: #fff;' : '')}

  &:not(:last-of-type) {
    &::after {
      content: '';
      background-color: #dee5ec;
      width: 2px;
      height: 24px;
      position: absolute;
      left: 16px;
      bottom: -25px;
    }
  }

  &:last-of-type {
    ${CircleWrapper} {
      &::after {
        background-color: transparent;
      }
    }
  }

  &:first-of-type {
    ${CircleWrapper} {
      &::before {
        background-color: transparent;
      }
    }
  }
`;
const PrimaryText = styled.p`
  font-size: 14px;
  color: #27313b;
  line-height: 14px;
`;
const SecondaryText = styled.p`
  font-size: 12px;
  color: #6e7c89;
  line-height: 12px;
  margin-bottom: 4px;
`;
const CommentTime = styled(SecondaryText)`
  position: absolute;
  top: -18px;
  left: 30px;
`;

export default ActivityRow;
