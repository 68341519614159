import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { ListIndicator } from '@src/components/molecules/Indicator';
import CampaignDetails from '@src/components/organisms/CastingMarketplace/YourJob/CampaignDetails';
import JoinButton from '@src/components/organisms/CastingMarketplace/YourJob/CampaignDetails/JoinButton';
import PostButton from '@src/components/organisms/CastingMarketplace/YourJob/CampaignDetails/PostButton';
import { useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import {
  AnyXMarketplaceCampaignStatus,
  CampaignStatusForInfluencer,
  MarketplaceCampaignAppliedStatus,
  MarketplaceCampaignType,
} from '@src/__generated__/globalTypes';
import CAMPAIGN_DETAILS from './queries/MarketplaceCampaignDetails.graphql';
import {
  MarketplaceCampaignDetails,
  MarketplaceCampaignDetailsVariables,
} from './queries/__generated__/MarketplaceCampaignDetails';

const Details = () => {
  const { enqueueSnackbar, history, params, pathname, t } = useQueryHelper();
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });
  const campaignId = Number(params.id);
  const backUrl = pathname.split('/');
  backUrl.pop();

  const { data, loading } = useQuery<MarketplaceCampaignDetails, MarketplaceCampaignDetailsVariables>(
    CAMPAIGN_DETAILS,
    {
      variables: {
        pk: campaignId,
      },
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  const campaignDetails = data?.marketplaceForInfluencer;

  if (loading) {
    return <ListIndicator />;
  } else if (!campaignDetails) {
    history.push(backUrl.join('/'));

    return null;
  }

  const {
    anyXStatus,
    appliedStatus,
    campaignType,
    isApplied,
    isJoined,
    remainingAmount,
    selectionEndDate,
    status,
  } = campaignDetails;
  const hasJoinButton =
    !isApplied && // For selection marketplace
    !isJoined && // For normal marketplace
    [CampaignStatusForInfluencer.ONGOING, CampaignStatusForInfluencer.UPCOMING].includes(status);
  const hasPostButton = anyXStatus === AnyXMarketplaceCampaignStatus.POSTING;
  const hasRemainingAmount = remainingAmount > 0;
  const hasReportButton = [
    AnyXMarketplaceCampaignStatus.APPROVED,
    AnyXMarketplaceCampaignStatus.EFFECTIVE,
    AnyXMarketplaceCampaignStatus.INEFFECTIVE,
    AnyXMarketplaceCampaignStatus.REVIEWING,
    AnyXMarketplaceCampaignStatus.WARNING_EFFECTIVE,
  ].includes(anyXStatus as AnyXMarketplaceCampaignStatus);
  const isApplying = appliedStatus === MarketplaceCampaignAppliedStatus.APPLYING;
  const isCampaignFinish =
    anyXStatus === AnyXMarketplaceCampaignStatus.INEFFECTIVE ||
    appliedStatus === MarketplaceCampaignAppliedStatus.REJECTED ||
    status === CampaignStatusForInfluencer.FINISHED;
  const isRejected =
    appliedStatus === MarketplaceCampaignAppliedStatus.REJECTED ||
    anyXStatus === AnyXMarketplaceCampaignStatus.INEFFECTIVE;
  const isSelection = campaignType === MarketplaceCampaignType.SELECTION;
  const isSelectionFinish = isSelection
    ? new Date(new Date().setHours(0, 0, 0, 0)) > new Date(selectionEndDate)
    : false;

  return (
    <>
      <div css={styles.container}>
        {isDesktopView && (
          <div css={styles.backNavigatorContainer}>
            <div>
              <BackNavigator
                title="Campaigns Detail"
                to={`${backUrl.join('/')}${
                  !hasJoinButton && (isRejected || status === CampaignStatusForInfluencer.FINISHED) ? '#finished' : ''
                }`}
              />
            </div>
            {!isApplying &&
              !isRejected &&
              (!isSelectionFinish && hasJoinButton ? (
                <div>
                  <JoinButton campaignDetails={campaignDetails} isSelection={isSelection} />
                </div>
              ) : !isSelectionFinish && !isCampaignFinish && hasPostButton && hasRemainingAmount ? (
                <div>
                  <PostButton campaignDetails={campaignDetails} />
                </div>
              ) : hasReportButton ? (
                <div>
                  <Link css={styles.viewReportBtn} to={`${pathname}/report`}>
                    {t('Button.View Report')}
                  </Link>
                </div>
              ) : null)}
          </div>
        )}

        {campaignDetails && <CampaignDetails campaignDetails={campaignDetails} />}
      </div>

      {!isDesktopView && !isApplying && !isRejected && (hasJoinButton || hasPostButton || hasReportButton) && (
        <div css={styles.actionContainer}>
          {hasJoinButton ? (
            <JoinButton campaignDetails={campaignDetails} height={40} />
          ) : hasPostButton ? (
            <PostButton campaignDetails={campaignDetails} height={40} />
          ) : hasReportButton ? (
            <div>
              <Link css={styles.viewReportBtn} to={`${pathname}/report`}>
                {t('Button.View Report')}
              </Link>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

const styles = {
  actionContainer: css`
    align-items: center;
    background-color: #fff;
    bottom: 0;
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    height: 72px;
    position: fixed;
    width: 100%;

    & > div {
      display: flex;
      justify-content: flex-end;
      margin: 0 16px;
      width: 100%;

      & > button {
        width: 144px;

        @media (max-width: ${ViewportType.SMALL}px) {
          width: fill-available;
        }
      }

      & > a {
        @media (max-width: ${ViewportType.SMALL}px) {
          height: 40px;
          width: fill-available;
        }
      }
    }
  `,
  backNavigatorContainer: css`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;

    & > div {
      display: flex;
      flex-basis: 50%;
    }

    & > div:nth-of-type(2) {
      justify-content: flex-end;
    }
  `,
  container: css`
    margin: 16px;

    @media (max-width: ${ViewportType.SMALL}px) {
      margin: 0;
    }
  `,
  viewReportBtn: css`
    align-items: center;
    background-color: #fff;
    border: 1px solid #dee5ec;
    border-radius: 5px;
    color: #6e7c89;
    display: flex;
    font-size: 13px;
    font-weight: 600;
    height: 32px;
    justify-content: center;
    width: 144px;
  `,
};

export default Details;
