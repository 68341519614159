import React, { useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CircularProgress } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import AnalyticsContentWrapper, {
  ContentType,
} from '@src/components/organisms/AnalyticsShared/AnalyticsContentWrapper';
import { Period } from '@src/components/molecules/DayPicker/types';
import { useQueryHelper } from '@src/libs/hooks';
import * as StyledCards from '@src/components/organisms/AnalyticsShared/AccountCardsStyled';
import Overview from './AnalyticsOverview';
import { ChartColorTypes, defaultSelectableCharts } from './helpers';
import { OverviewStats } from './types';
import * as INFLUENCER_FB_OVERVIEW from './InfluencerFacebookPageAnalyticsOverview.graphql';
import {
  InfluencerFacebookPageAnalyticsOverview,
  InfluencerFacebookPageAnalyticsOverviewVariables,
} from './__generated__/InfluencerFacebookPageAnalyticsOverview';
import ChartSelector from './ChartSelector';

export interface OverviewProps {
  period: Period;
  socialMediaAccountId: number;
  influencerAccountId?: number;
}

const AnalyticsFacebookPageOverview = (props: OverviewProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const [chartSelection, setChartSelection] = useState<ChartColorTypes[]>(defaultSelectableCharts);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { socialMediaAccountId, period } = props;

  const { data: inflData, loading: inflLoading, error: inflError } = useQuery<
    InfluencerFacebookPageAnalyticsOverview,
    InfluencerFacebookPageAnalyticsOverviewVariables
  >(INFLUENCER_FB_OVERVIEW, {
    ...(!props.influencerAccountId
      ? { skip: true }
      : {
          variables: {
            socialAccountId: socialMediaAccountId,
            influencerId: props.influencerAccountId,
            startDate: period.startDate,
            endDate: period.endDate,
          },
        }),
    fetchPolicy: 'cache-first',
  });

  const overviewStats: OverviewStats | null = inflData?.dashboardOverview || null;

  if (inflError) {
    enqueueSnackbar(t(inflError?.message || 'Overview Data not found'), {
      variant: 'error',
    });

    return null;
  }

  return (
    <>
      <AnalyticsContentWrapper
        contentType={ContentType.Overview}
        hasHelp={false}
        addButton={<StyledAddButton onClick={e => setAnchorEl(e.currentTarget)}>+</StyledAddButton>}
      >
        {inflLoading ? (
          <LoaderWrapper>
            <CircularProgress
              size={64}
              thickness={2.4}
              css={css`
                margin: 240px auto;
                color: #e0e8ed;
              `}
            />
          </LoaderWrapper>
        ) : (
          <Overview overviewStats={overviewStats} allEngagements={chartSelection} {...props} />
        )}
      </AnalyticsContentWrapper>
      {anchorEl && (
        <ChartSelector
          onSelectionChange={e => setChartSelection(e)}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          currentSelection={chartSelection}
        />
      )}
    </>
  );
};

const StyledAddButton = styled(StyledCards.AddAccout)`
  width: 32px;
  height: 32px;
  flex: unset;
  margin-left: auto;
  margin-right: 16px;
  font-size: 24px;
  border: solid 1px #c5d0da;
  border-radius: 3px;
  align-items: baseline;
  background-color: #f6f8fa;
`;

const LoaderWrapper = styled.div`
  background-color: #fff;
  text-align: center;
  height: 554px;
`;

export default AnalyticsFacebookPageOverview;
