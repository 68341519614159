import React, { useEffect, useState } from 'react';
import InitialLoading from '../../molecules/InitialLoading';
import { getToken, removeToken } from '../../../libs/auth';
import { useQueryHelper } from '../../../libs/hooks';
import { useAuthSetup } from './useAuthSetup';

const AuthSetUp: React.FC = props => {
  const [loading, setLoading] = useState<boolean>(true);
  const { setUp } = useAuthSetup();
  const { t, enqueueSnackbar, history } = useQueryHelper();

  const executeSetUp = async () => {
    const token = getToken();
    if (!token) {
      setLoading(false);

      return;
    }

    try {
      await setUp(token);
    } catch (e) {
      console.error(e);
      enqueueSnackbar(t(e.message), { variant: 'error' });
      removeToken();
      history.push('/');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    executeSetUp();
  }, []);

  if (loading) {
    return <InitialLoading />;
  }

  return <>{props.children}</>;
};

export default AuthSetUp;
