import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { SignUpAndSignInBreakpoint } from '@src/libs/theme';

export const Title = styled.h1`
  font-size: 20px;
  color: #27313b;
  margin: 32px 0 16px;
  text-align: center;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    margin: 24px 0 16px;
  }
`;
export const Annotation = styled.p`
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #27313b;
`;
export const AnnotationRed = styled(Annotation)`
  margin-top: 16px;
  color: #ff2b52;
  font-weight: 600;
`;
export const AnnotationLink = styled(Link)`
  display: inline-flex;
  color: #3892e5;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`;
export const SignInHoverLink = styled.span`
  padding-left: 16px;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #3892e5;
`;
export const SignInBox = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0 8px 0;
  height: 48px;
  border-radius: 7px;
  background-color: #eef3f7;

  &:hover {
    background-color: #eef3f790;

    ${SignInHoverLink} {
      text-decoration: underline;
    }
  }
`;
export const SignInText = styled.span``;
export const AnnotationLinkHref = styled.a`
  margin-top: 16px;
  display: inline-flex;
  font-weight: 600;
  color: #3892e5;
  font-size: 12px;
  line-height: 18px;

  &:hover {
    text-decoration: underline;
  }
`;
