import styled from '@emotion/styled';
import React from 'react';
import CampaignList, { CampaignListProps } from '../../components/organisms/Campaign/CampaignList/CampaignList';
import { ViewportBreakpoint } from '../../libs/theme';

const Template = (props: CampaignListProps) => (
  <Container>
    <ResponsiveWrapper>
      <CampaignList {...props} />
    </ResponsiveWrapper>
  </Container>
);

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const ResponsiveWrapper = styled.div`
  width: 100%;

  @media (min-width: ${ViewportBreakpoint.DESKTOP}px) {
    width: 702px;
  }
`;

export default Template;
