import React, { useEffect } from 'react';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { getPageInfo, PageInfo } from '@src/libs/paging';
import PostsListTemplate from './PostsListTemplate';
import { IgInteractionListProps } from './types';
import { useInteractionPostsQuery } from './useInteractionPostsQuery';

const IgTaggedList = ({
  currentPage,
  sortData,
  filter,
  accountId,
  isTaggedPosts,
  setPageInfo,
}: IgInteractionListProps & { setPageInfo: React.Dispatch<React.SetStateAction<PageInfo>> }) => {
  const { posts, totalNumber, loading } = useInteractionPostsQuery({
    currentPage,
    sortData,
    filter,
    accountId,
    isTaggedPosts,
  });

  useEffect(() => {
    const pageInfo = getPageInfo(currentPage, totalNumber);
    setPageInfo(pageInfo);
  }, [totalNumber]);

  if (loading) {
    return <ListIndicator />;
  }

  return (
    <PostsListTemplate
      posts={posts || []}
      accountId={accountId}
      totalCount={totalNumber}
      sortData={sortData}
      isTaggedPosts={isTaggedPosts}
    />
  );
};

export default IgTaggedList;
