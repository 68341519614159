import { useQueryHelper } from '@src/libs/hooks';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { FormikHelpers } from 'formik';
import * as ADD_COMMENT from './AddComment.graphql';
import * as UPDATE_COMMENT from './UpdateComment.graphql';
import * as DELETE_COMMENT from './DeleteComment.graphql';
import { AddComment, AddCommentVariables } from './__generated__/AddComment';
import { UpdateComment, UpdateCommentVariables } from './__generated__/UpdateComment';
import { DeleteComment, DeleteCommentVariables } from './__generated__/DeleteComment';
import { FormValues } from './CommentForm';

export default (fanId: number) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const [activity, setActivity] = useState<{
    el: HTMLElement | null;
    id: number | null;
    showCommentInsideForm: boolean;
    showDeleteConfirmation: boolean;
  }>({
    el: null,
    id: null,
    showCommentInsideForm: false,
    showDeleteConfirmation: false,
  });

  const [addComment] = useMutation<AddComment, AddCommentVariables>(ADD_COMMENT, {
    refetchQueries: ['GetFanActivities'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInPosting'), { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  const [updateComment] = useMutation<UpdateComment, UpdateCommentVariables>(UPDATE_COMMENT, {
    refetchQueries: ['GetFanActivities'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  const [deleteComment] = useMutation<DeleteComment, DeleteCommentVariables>(DELETE_COMMENT, {
    refetchQueries: ['GetFanActivities'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  const handleClickMore = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, activityId: number) => {
    setActivity({ el: e.currentTarget, id: activityId, showCommentInsideForm: false, showDeleteConfirmation: false });
  };

  const handleClose = () => {
    setActivity({ el: null, id: null, showCommentInsideForm: false, showDeleteConfirmation: false });
  };

  const handleAddComment = async (payload: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
    setSubmitting(true);

    await addComment({
      variables: {
        input: {
          fanId,
          comment: payload.comment,
        },
      },
    });
    resetForm();

    setSubmitting(false);
  };

  const handleClickEdit = () => {
    setActivity(prevState => ({ ...prevState, showCommentInsideForm: true, el: null }));
  };

  const handleUpdateComment = async (comment: string) => {
    await updateComment({
      variables: {
        input: {
          activityId: activity.id as number,
          comment,
        },
      },
    });

    setActivity(prevState => ({ ...prevState, showCommentInsideForm: false, id: null }));
  };

  const handleClickDelete = () => {
    setActivity(prevState => ({ ...prevState, showDeleteConfirmation: true, el: null }));
  };

  const handleDeleteConfirm = async () => {
    await deleteComment({
      variables: {
        input: {
          id: activity.id as number,
        },
      },
    });

    setActivity(prevState => ({ ...prevState, el: null, id: null, showDeleteConfirmation: false }));
  };

  return {
    activity,
    handleClickMore,
    handleClose,
    handleClickEdit,
    handleClickDelete,
    handleAddComment,
    handleUpdateComment,
    handleDeleteConfirm,
  };
};
