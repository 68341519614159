import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { IframeAppRoute, PrivateRoute, IframePublicRoute } from './components/organisms/Route';
import AuthSetUp from './components/organisms/AuthSetUp';
import * as pages from './pages';
import ScrollToTop from './ScrollToTop';

const UuumRoutes = () => (
  <Router>
    <AuthSetUp>
      <ScrollToTop>
        <Switch>
          <IframePublicRoute path="/" exact={true} component={pages.SignUp_backup} />
          <IframePublicRoute path="/signup" exact={true} component={pages.SignUp} />
          <IframePublicRoute path="/signup/success" exact={true} component={pages.SignUpSuccess} />
          <Route path="/password_forgot" component={pages.PasswordForgot} />
          <Route path="/password_register/:token" component={pages.PasswordRegister} />
          <Route path="/password_reset/:token" component={pages.PasswordReset} />
          <IframePublicRoute path="/signup/profile" exact={true} component={pages.SignUpProfile} />
          <Route path="/redirect/signup-enabled/:provider" component={pages.RedirectSignUpEnabled_backup} />
          <Route path="/redirect/reconnect_ig" exact={true} component={pages.RedirectIgReconnect} />
          <Route path="/reconnect" exact={true} component={pages.IgReconnect} />
          <PrivateRoute path="/signup/connect" exact={true} component={pages.SignUpConnect} />
          <Route path="/redirect/twitter" component={pages.RedirectTwitter} />
          <Route path="/redirect/signup/:provider" component={pages.RedirectSignUp_backup} />
          <Route path="/redirect/signin/:provider" component={pages.RedirectSignIn_backup} />
          <Route path="/redirect/signup-connect/:provider" component={pages.RedirectSignUpConnect} />
          <Route path="/redirect/connect/:provider" component={pages.RedirectConnect} />
          <Route path="/redirect/join_campaign/:provider" component={pages.RedirectJoinCampaign} />
          <Route path="/redirect/analytics-reconnect/:provider" component={pages.RedirectAnalyticsReconnect} />
          {/* Settings */}
          <IframeAppRoute path="/profile:active_tab?" exact={true} component={pages.Profile} />
          <IframeAppRoute path="/profile/instagram_feed_post" exact={true} component={pages.PostFeed_backup} />
          <IframeAppRoute path="/profile/instagram_story_feed" exact={true} component={pages.StoryFeed_backup} />
          <IframeAppRoute path="/profile/feed_videos" exact={true} component={pages.YoutubeVideoList_backup} />
          <IframeAppRoute path="/profile/tag_ranking_list" exact={true} component={pages.TagRanking_backup} />
          <IframeAppRoute
            path="/profile:compare/youtube_compare_feed_videos"
            exact={true}
            component={pages.YoutubeCompareFeedVideos_backup}
          />
          <IframeAppRoute path="/profile/settings" exact={true} component={pages.ProfileSettings} />
          <IframeAppRoute path="/profile/settings/edit" exact={true} footerPadding={0} component={pages.EditProfile} />
          <IframeAppRoute path="/profile/settings/connect" exact={true} component={pages.ProfileConnect} />
          <IframeAppRoute path="/profile/settings/address" exact={true} component={pages.AddressInformation} />
          {/* Not Found inside the App */}
          <IframeAppRoute component={pages.NotFound} />
        </Switch>
      </ScrollToTop>
    </AuthSetUp>
  </Router>
);

export default UuumRoutes;
