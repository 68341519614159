import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import Label from '../../../atoms/Label';
import SearchForm from '../../../molecules/SearchForm';
import { DayPickerRange } from '../../../molecules/DayPicker';
import { FormValues } from './types';

interface BasePostListFilterProps {
  wrapWithContainer?: boolean;
  isMobile?: boolean;
  className?: string;
}

const BasePostListFilter = ({ wrapWithContainer = true, isMobile, className }: BasePostListFilterProps) => {
  const { t } = useTranslation();

  const { handleSubmit, handleBlur, handleChange, setFieldValue } = useFormikContext<FormValues>();

  const [keywordField] = useField<FormValues['keyword']>('keyword');
  const [periodField, periodMeta] = useField<FormValues['period']>('period');

  const handleBlurAndSubmit = (e: any) => {
    handleBlur(e);
    handleSubmit(e);
  };

  const handleChangePeriod = (value: any) => {
    setFieldValue('period', value);
  };

  const handleChangeAndSubmitPeriod = (e: any) => {
    handleChangePeriod(e);
    handleSubmit(e);
  };

  const mainContent = (
    <Wrapper css={[marginBottom0]}>
      <Keyword
        title={t('TextForm.Keyword')}
        placeholder={t('TextForm.Keyword')}
        value={keywordField.value}
        name="keyword"
        onBlur={handleBlurAndSubmit}
        onChange={handleChange}
        onEnterKeyPress={handleSubmit}
        css={[flex1]}
        inputStyle={inputStyle}
      />

      {!isMobile ? (
        <DayPickerRangeWrapper>
          <Label title={t(`Selector.Period`)} />
          <DayPickerRange
            period={periodField.value}
            handleChangePeriod={handleChangeAndSubmitPeriod}
            disabledRange={{ after: new Date() }}
            error={!!periodMeta.error}
          />
        </DayPickerRangeWrapper>
      ) : null}
    </Wrapper>
  );

  return (
    <form onSubmit={handleSubmit}>
      {wrapWithContainer ? (
        <Container css={[paddingBottom15]} className={className}>
          {mainContent}
        </Container>
      ) : (
        mainContent
      )}
    </form>
  );
};

const inputStyle = css`
  i {
    background-color: transparent;
    height: 30px;
  }

  input {
    border-radius: 0;
    line-height: 30px;
  }
`;
const paddingBottom15 = css`
  padding-bottom: 15px;
`;
const flex1 = css`
  flex: 1;
`;
const marginBottom0 = css`
  margin-bottom: 0;
`;
const Keyword = styled(SearchForm)`
  flex: 1;
`;
const DayPickerRangeWrapper = styled.div``;
const Container = styled.div`
  padding: 12px 17px 0;
  background-color: #fff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: 1px solid #dee5ec;
  border-bottom: none;
`;
const Wrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  flex-wrap: wrap;
  gap: 16px;
`;

export default BasePostListFilter;
