import { SliderComponents, StyledComponents } from '@src/components/molecules/SliderTable';
import { css } from '@emotion/react';
import React from 'react';
import { Link } from 'react-router-dom';
import lineIcon from '@src/assets/icon/line_chat.svg';
import shopifyIcon from '@src/assets/icon/shopify.svg';
import * as List from '@src/components/atoms/List';
import { getFormatDistanceToNow } from '@src/components/organisms/Notification/NotificationList/helper';
import { getOffset, getPageInfo } from '@src/libs/paging';
import { useQuery } from '@apollo/client';
import { LIMIT } from '@src/libs/constant';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { FanListSortableField, Order } from '@src/__generated__/globalTypes';
import TextCutter from '@src/components/atoms/TextCutter';
import * as FANS from './Fans.graphql';
import { GetFans, GetFansVariables, GetFans_getFans_fans_tags } from './__generated__/GetFans';
import { FanListFilterType } from './types';
import useFanCountries from './useFanCountries';
import { getFansVariables } from './helper';
import EmptyList from './EmptyList';
import { getTagsIdFromURLString } from './FanListFilter/helper';

const emptyUsernameKey = 'UnknownUsername';

interface FansListProps {
  currentPage: number;
  filter: FanListFilterType;
  sortOrder: Order;
  selectedFansId: Set<number>;
  isAllSelectedFromCurrentPage: boolean;
  onChangeFanSelection: (id: number, tags: GetFans_getFans_fans_tags[]) => void;
  setFansIdFromCurrentPage: React.Dispatch<React.SetStateAction<number[]>>;
  setTotalFanCount: React.Dispatch<React.SetStateAction<number>>;
  onCheckAll: () => void;
}

const FansList = (props: FansListProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const { fanCountries } = useFanCountries();
  const fanCountriesHash = fanCountries.reduce<{ [id: string]: string }>(
    (acc, curr) => ({ ...acc, [curr.id]: curr.name }),
    {}
  );

  const {
    currentPage,
    filter,
    sortOrder,
    selectedFansId,
    onChangeFanSelection,
    setFansIdFromCurrentPage,
    setTotalFanCount,
    isAllSelectedFromCurrentPage,
    onCheckAll,
  } = props;

  const { data, loading } = useQuery<GetFans, GetFansVariables>(FANS, {
    variables: {
      offset: getOffset(currentPage),
      limit: LIMIT,
      keyword: filter.keyword,
      orderBy: { field: FanListSortableField.CREATED, order: sortOrder },
      tagIds: getTagsIdFromURLString(filter.tags),
      ...getFansVariables(filter),
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ getFans, getTotalNumber }) => {
      setFansIdFromCurrentPage(getFans?.fans.map(item => item.id) || []);
      setTotalFanCount(getTotalNumber?.total || 0);
    },
    onError: err => {
      enqueueSnackbar(t(err.message || 'Cannot get fans data'), { variant: 'error' });
    },
  });

  if (loading) {
    return <ListIndicator css={styles.wrapperMargin} />;
  }

  const fans = data?.getFans?.fans || [];

  const isEmptyFans = !fans.length;

  const pageInfo = getPageInfo(currentPage, data?.getTotalNumber?.total || 0);

  return (
    <>
      <SliderComponents.Wrapper css={[styles.wrapperMargin, styles.padding0]}>
        {isMobileView ? (
          fans.map(item => (
            <div key={item.id} css={styles.mobileWrapper}>
              <Link to={`/fans/${item.id}`} css={styles.linkStyle}>
                {item.name || t(emptyUsernameKey)}
              </Link>
              <p css={styles.regularText}>{item.email}</p>
              <p css={styles.regularText}>{item.contactNumber}</p>
              <p css={styles.regularText}>{item.countryId ? fanCountriesHash[item.countryId] || '' : ''}</p>
            </div>
          ))
        ) : (
          <SliderComponents.Container>
            <SliderComponents.SliderSection>
              <SliderComponents.SliderTable>
                <thead>
                  <SliderComponents.HeaderRow>
                    <List.HeaderCheckboxColumn
                      css={styles.headerCheckboxColumn}
                      handleClick={onCheckAll}
                      checked={isAllSelectedFromCurrentPage}
                    />
                    <List.HeaderColumn title="Name" css={[styles.headerColumn, { minWidth: '120px' }]} />
                    <List.HeaderColumn title="Tag" css={styles.headerColumn} />
                    <List.HeaderColumn title="Mail Address" css={styles.headerColumn} />
                    <List.HeaderColumn title="Phone number" css={styles.headerColumn} />
                    <List.HeaderColumn title="Connect" css={styles.headerColumn} />
                    <List.HeaderColumn title="Location" css={styles.headerColumn} />
                    <List.HeaderColumn title="Created Date" css={styles.headerColumn} />
                  </SliderComponents.HeaderRow>
                </thead>
                {!isEmptyFans && (
                  <tbody>
                    {fans.map(item => (
                      <StyledComponents.StyledRowNew
                        key={item.id}
                        css={[styles.rowHeight, selectedFansId.has(item.id) ? styles.activeRow : undefined]}
                      >
                        <List.HeaderCheckboxColumn
                          css={[styles.paddingLeft16, styles.checkboxTopBorder]}
                          handleClick={() => onChangeFanSelection(item.id, item.tags)}
                          checked={selectedFansId.has(item.id)}
                        />
                        <List.TextLinkColumn
                          data={<TextCutter text={item.name || t(emptyUsernameKey)} />}
                          href={`/fans/${item.id}/details`}
                        />
                        <List.TagColumn tags={item.tags.map(el => el.tag)} />
                        <List.TextColumn data={item.email || ''} />
                        <List.TextColumn data={item.contactNumber || ''} />
                        <List.TextColumn
                          data={
                            <div css={{ display: 'flex' }}>
                              {item.lineAccount && (
                                <img alt="Line icon" height="24" src={lineIcon} width="21" css={{ marginRight: 8 }} />
                              )}
                              {item.shopifyAccount && (
                                <img alt="Shopify icon" height="24" src={shopifyIcon} width="21" />
                              )}
                            </div>
                          }
                        />
                        <List.TextColumn data={item.countryId ? fanCountriesHash[item.countryId] || '' : ''} />
                        <List.TextColumn data={getFormatDistanceToNow(item.created, true)} />
                      </StyledComponents.StyledRowNew>
                    ))}
                  </tbody>
                )}
              </SliderComponents.SliderTable>
            </SliderComponents.SliderSection>
          </SliderComponents.Container>
        )}
      </SliderComponents.Wrapper>
      {isEmptyFans && <EmptyList />}
      <SliderComponents.Pager
        currentPage={currentPage}
        totalPages={pageInfo.totalPages}
        totalCount={pageInfo.totalCount}
        first={pageInfo.firstIndex}
        last={pageInfo.lastIndex}
        css={styles.wrapperMargin}
      />
    </>
  );
};

const styles = {
  activeRow: css`
    & > th {
      background-color: #fffde7;
    }

    & > td {
      background-color: #fffde7 !important;
    }
  `,
  linkStyle: css`
    font-weight: 600;
    font-size: 14px;
    color: #3892e5;
    margin-bottom: 16px;
  `,
  regularText: css`
    font-size: 12px;
    color: #27313b;
    line-height: 100%;

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  `,
  wrapperMargin: css`
    margin: 0 24px 0;
  `,
  padding0: css`
    padding: 0;
  `,
  headerColumn: css`
    padding-left: 8px;
    background-color: #f6f8fa;
  `,
  headerCheckboxColumn: css`
    background-color: #f6f8fa;
    padding-left: 16px;
  `,
  paddingLeft16: css`
    padding-left: 16px;
  `,
  checkboxTopBorder: css`
    border-top: 1px solid #efefef;
  `,
  mobileWrapper: css`
    padding: 16px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #eef3f7;
    }
  `,
  rowHeight: css`
    height: 56px;
  `,
};

export default FansList;
