import React, { useState, useEffect } from 'react';
import { Global, css } from '@emotion/core';
import HighChart from '@src/components/molecules/HighChart';
import { MultiSelector } from '@src/components/molecules/Selector';
import DataOpacityWrapper from '@src/components/molecules/DataCard/DataOpacityWrapper';
import { FeatureStats } from '@src/components/organisms/PostDetail/FeatureStats';
import { SocialMediaAccountType } from '@src/components/organisms/ProfileDetail/types';
import { FeatureStatsData } from '@src/components/organisms/ProfileDetail/AnalyticsProfile/model';
import { useQueryHelper } from '@src/libs/hooks';
import * as Styled from '@src/components/organisms/AnalyticsShared/Overview/StyledComponents';
import { ViewportBreakpoint } from '@src/libs/theme';
import { useMediaQuery } from 'react-responsive';
import client from '@src/apollo';
import CheckBoxIcon from '@src/components/atoms/Icon/CheckBoxIcon';
import CarouselModal from '@src/components/molecules/CarouselModal/CarouselModal';
import StoryPostModal from '@src/components/organisms/AnalyticsInstagram/Dashboard/InstagramAnalyticsStoryPostDetail/template';
import FeedPostModal from '@src/components/organisms/AnalyticsInstagram/Dashboard/InstagramAnalyticsFeedPostDetail/template';
import { usePostDetailModal } from '@src/components/organisms/AnalyticsShared/BasePostList/usePostDetailModal';
import { InstagramAnalyticsStoryDetails_analyticsStoryDetails } from '../InstagramAnalyticsStoryPostDetail/__generated__/InstagramAnalyticsStoryDetails';
import { InstagramAnalyticsPostDetails } from '../InstagramAnalyticsFeedPostDetail/__generated__/InstagramAnalyticsPostDetails';
import { getOptions } from './getOptions';
import { ChartColorTypes, colors } from './helpers';
import { OverviewStats, PostsByPeriod } from './types';
import * as INFLUENCER_IG_POSTS_IN_DATE from './InfluencerAllInstagramPostsInDate.graphql';
import {
  InfluencerAllInstagramPostsInDate,
  InfluencerAllInstagramPostsInDateVariables,
  InfluencerAllInstagramPostsInDate_postInDate,
} from './__generated__/InfluencerAllInstagramPostsInDate';

interface AnalyticsOverviewProps {
  selectedSocialMedia: SocialMediaAccountType;
  overviewStats?: OverviewStats | null;
  allEngagements: ChartColorTypes[];
}

const Template = ({ overviewStats, allEngagements, selectedSocialMedia }: AnalyticsOverviewProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const [selectedCharts, setSelectedCharts] = useState<ChartColorTypes[]>(['Follower', 'Post']);
  const [dailyPostsData, setDailyPostsData] = useState<PostsByPeriod>([]);

  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev,
    setOpenPostDetail,
  } = usePostDetailModal(dailyPostsData, 'overviewPopup');

  const fetchDailyPosts = async (postDate: string) => {
    try {
      const { data } = await client.query<
        InfluencerAllInstagramPostsInDate,
        InfluencerAllInstagramPostsInDateVariables
      >({
        query: INFLUENCER_IG_POSTS_IN_DATE,
        variables: { socialAccountId: selectedSocialMedia.id, postDate },
      });

      const posts =
        data?.postInDate ||
        ({
          feedPosts: [],
          storyPosts: [],
          __typename: 'InstagramAnalyticsPostsInDate',
        } as InfluencerAllInstagramPostsInDate_postInDate);

      const combinedPostsData = [
        ...(posts?.feedPosts?.map(feedPost => ({ ...feedPost, type: 'feedPost' })) || []),
        ...(posts?.storyPosts?.map(storyPost => ({ ...storyPost, type: 'storyPost' })) || []),
      ] as PostsByPeriod;

      setDailyPostsData(combinedPostsData);
      setOpenPostDetail(combinedPostsData[0]?.id || null);
    } catch (error) {
      enqueueSnackbar(t('Can not get daily posts data, please try again later '), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (dailyPostsData.length && openPostDetail) {
      handleClickPost(openPostDetail);
    }
  }, [dailyPostsData.length, openPostDetail]);

  const { options, engagement } = overviewStats
    ? getOptions(selectedCharts, overviewStats, allEngagements, fetchDailyPosts)
    : { options: [] as Highcharts.Options, engagement: [] as FeatureStatsData[] };

  const noData = engagement.every(item => !item.rate);
  const currentOverviewPostForModal = dailyPostsData.find(dailyPost => dailyPost.id === openPostDetail);
  const isMedium = useMediaQuery({ minWidth: ViewportBreakpoint.MEDIUM });

  const toggleSelectedCharts = (isSelected: boolean, name: ChartColorTypes) => {
    if (isSelected) {
      setSelectedCharts(selectedCharts.filter(selectedItem => selectedItem !== name));
    } else {
      setSelectedCharts([...selectedCharts, name]);
    }
  };

  return (
    <>
      <DataOpacityWrapper dataNotAvailable={noData}>
        <Styled.EngagementGrid>
          {engagement.map(item => (
            <FeatureStats {...item} key={item.title} />
          ))}
        </Styled.EngagementGrid>
        <Styled.OverviewWrapper>
          <Styled.Chart>
            <Styled.LegendBar>
              {!isMedium ? (
                <MultiSelector
                  value={selectedCharts}
                  name={''}
                  isRoundInput={false}
                  options={allEngagements.map(v => ({
                    label: t(`Label.${v}`),
                    value: v,
                  }))}
                  onChange={e => setSelectedCharts(e as ChartColorTypes[])}
                  initialValues={['Follower']}
                  menuCss={styles.menuCss}
                  css={styles.selectorWrapper}
                />
              ) : (
                allEngagements.map(item => {
                  const isChecked = selectedCharts.includes(item);
                  const color = colors[item];

                  return (
                    <Styled.LegendTileColored key={item} onClick={() => toggleSelectedCharts(isChecked, item)}>
                      <CheckBoxIcon checked={isChecked} />
                      <Styled.Text>{t(item)}</Styled.Text>
                      <span style={{ background: color }} />
                    </Styled.LegendTileColored>
                  );
                })
              )}
            </Styled.LegendBar>
            <Global styles={styles.globalTooltip} />
            <HighChart options={options} />
          </Styled.Chart>
        </Styled.OverviewWrapper>
      </DataOpacityWrapper>
      <CarouselModal
        open={!!openPostDetail}
        title=""
        cancel="OK"
        onClose={handleClosePostDetail}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        siblingPostId={siblingPostId}
      >
        <>
          {currentOverviewPostForModal?.type === 'feedPost' && (
            <FeedPostModal
              data={currentOverviewPostForModal as InstagramAnalyticsPostDetails['analyticsPostDetails']}
              closeModal={() => handleClosePostDetail()}
            />
          )}
          {currentOverviewPostForModal?.type === 'storyPost' && (
            <StoryPostModal
              data={currentOverviewPostForModal as InstagramAnalyticsStoryDetails_analyticsStoryDetails}
              closeModal={() => handleClosePostDetail()}
            />
          )}
        </>
      </CarouselModal>
    </>
  );
};

const styles = {
  selectorWrapper: css`
    min-height: 30px;
    max-height: 30px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  `,
  menuCss: css`
    margin-top: 5px;
    box-shadow: 0 1px 2px 0 rgba(39, 49, 59, 0.1);
    border-radius: 3px !important;
    width: 100%;
  `,
  globalTooltip: css`
    .highcharts-tooltip path {
      fill: #27313b;
    }

    .highcharts-root {
      padding-left: 4px;
    }
  `,
};

export default Template;
