import { ApolloError } from '@apollo/client';
import { format } from 'date-fns';
import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import { DEFAULT_FNS_DATE_FORMAT } from '@src/libs/constant';
import { getMessagesFromApolloError, getMessagesFromFetchResult } from '@src/libs/error';
import { useQueryHelper } from '@src/libs/hooks';
import { useInfluencerCategories, useAllAvailableCountries } from '@src/libs/hooks';
import ProfileForm from './ProfileForm';
import { FormValues, ProfileFormProps } from './types';
import { ProfileFormSchema, useUpdateInfluencerProfile } from './utils';

export type { ProfileFormProps };

const ProfileFormComponent = (props: ProfileFormProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const updateInfluencerProfile = useUpdateInfluencerProfile();
  const { influencerCategories } = useInfluencerCategories();
  const { allTranslatedCountries } = useAllAvailableCountries();

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    setSubmitting(true);
    const { name, categories, regionId, gender, email, phoneNumber, birthday, introduce, notificationSetting } = values;
    const { userId } = props;

    // Update Account
    const variables = {
      input: {
        id: userId,
        name,
        email,
        gender,
        dateOfBirth: !!birthday ? format(new Date(birthday), DEFAULT_FNS_DATE_FORMAT) : null,
        categoryIds: categories.map(category => Number(category)),
        regionId: Number(regionId),
        phoneNumber,
        introduce,
        notificationSetting,
      },
    };

    await updateInfluencerProfile({ variables })
      .then(result => {
        if (result && result.data && result.data.updateInfluencerProfile && result.data.updateInfluencerProfile.ok) {
          enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
        } else {
          enqueueSnackbar(t('failedToUpdate'), { variant: 'error' });
          getMessagesFromFetchResult(result).forEach(error => {
            console.error(error);
            enqueueSnackbar(t(error), { variant: 'error' });
          });
        }

        return;
      })
      .catch((e: ApolloError) => {
        enqueueSnackbar(t('failedToUpdate'), { variant: 'error' });
        getMessagesFromApolloError(e).forEach(error => {
          console.error(error);
          enqueueSnackbar(t(error), { variant: 'error' });
        });
      });
    setSubmitting(false);

    return;
  };

  return (
    <Formik
      initialValues={{
        name: props.name || '',
        email: props.email || '',
        phoneNumber: props.phoneNumber || '',
        categories: props.categories || [],
        gender: props.gender,
        country: props.country || '',
        regionId: props.regionId || '',
        birthday: props.birthday || '',
        introduce: props.introduce || '',
        notificationSetting: props.notificationSetting,
      }}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={ProfileFormSchema}
    >
      <ProfileForm {...props} allCategories={influencerCategories} allCountries={allTranslatedCountries} />
    </Formik>
  );
};

export default ProfileFormComponent;
