import React from 'react';
import { AnalyticsTemplateProps } from '../AnalyticsShared/types';
import AnalyticsPosts from './AnalyticsPosts';
import AudienceChart from './AudienceChart';
import OverviewChart from './Overview';

const AccountAnalyticsFacebookPage = ({ selectedPeriod, selectedSocialMedia, userId }: AnalyticsTemplateProps) => (
  <>
    <OverviewChart period={selectedPeriod} socialMediaAccountId={selectedSocialMedia.id} influencerAccountId={userId} />
    <AudienceChart period={selectedPeriod} socialMediaAccountId={selectedSocialMedia.id} influencerAccountId={userId} />
    <AnalyticsPosts
      period={selectedPeriod}
      socialMediaAccountId={selectedSocialMedia.id}
      influencerAccountId={userId}
    />
  </>
);

export default AccountAnalyticsFacebookPage;
