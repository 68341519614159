import React, { useState } from 'react';
import { useQueryHelper } from '@src/libs/hooks';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import yup from '@src/libs/validation';
import { getOperationName } from '@apollo/client/utilities';
import * as GET_LINE_CHANNELS from '@src/libs/queries/GetLineChannels.graphql';
import * as CREATE_LINE_CHANNEL from './mutations/CreateLineChannel.graphql';
import { CreateLineChannel, CreateLineChannelVariables } from './mutations/__generated__/CreateLineChannel';
import { Step1, Step2, Step3 } from './LineConnectSteps';

export const enum ConnectionStepsTypes {
  IDLE = 'IDLE',
  STEP1 = 'STEP1',
  STEP2 = 'STEP2',
  STEP3 = 'STEP3',
}

export const useConnectLineMachine = () => {
  const [machineState, setMachineState] = useState(ConnectionStepsTypes.IDLE);
  const { t, enqueueSnackbar } = useQueryHelper();
  const [createLineChannel, { loading }] = useMutation<CreateLineChannel, CreateLineChannelVariables>(
    CREATE_LINE_CHANNEL,
    {
      refetchQueries: [getOperationName(GET_LINE_CHANNELS) || 'GetLineChannels'],
      onCompleted: () => {
        enqueueSnackbar(t('Successfully connected LINE channel'), { variant: 'success' });
      },
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
    }
  );

  const validationSchemaStep1 = yup.object().shape({
    lineChannelId: yup.string().required('requiredFieldMessage'),
    lineChannelSecret: yup.string().required('requiredFieldMessage'),
  });
  const validationSchemaStep3 = yup.object().shape({
    lineKid: yup.string().required('requiredFieldMessage'),
  });

  const formStep1 = useFormik({
    initialValues: {
      lineChannelId: '',
      lineChannelSecret: '',
    },
    validationSchema: validationSchemaStep1,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {
      machine.transition(machineState, 'next');
    },
  });
  const formStep2 = useFormik({
    initialValues: {
      publicKeyId: '',
    },
    onSubmit: () => {
      machine.transition(machineState, 'next');
    },
  });

  const formStep3 = useFormik({
    initialValues: {
      lineKid: '',
    },
    validationSchema: validationSchemaStep3,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async formValues => {
      await createLineChannel({
        variables: {
          input: {
            lineChannelId: formStep1.values.lineChannelId,
            lineChannelSecret: formStep1.values.lineChannelSecret,
            publicKeyId: Number(formStep2.values.publicKeyId),
            lineKid: formValues.lineKid,
          },
        },
      });
      machine.transition(machineState, 'next');
    },
  });

  const machineObject = {
    initial: ConnectionStepsTypes.IDLE,
    states: {
      [ConnectionStepsTypes.IDLE]: {
        actions: {
          onEnter() {
            return null;
          },
          onExit() {
            return null;
          },
        },
        transitions: {
          next: ConnectionStepsTypes.STEP1,
          prev: ConnectionStepsTypes.IDLE,
        },
        options: {
          nextButtonText: 'Next',
          formNode: null,
          formSubmit: () => null,
        },
      },
      [ConnectionStepsTypes.STEP1]: {
        actions: {
          onEnter() {
            return null;
          },
          onExit() {
            return null;
          },
        },
        transitions: {
          next: ConnectionStepsTypes.STEP2,
          prev: ConnectionStepsTypes.IDLE,
        },
        options: {
          nextButtonText: 'Next',
          formNode: <Step1 {...formStep1} />,
          formSubmit: formStep1.handleSubmit,
        },
      },
      [ConnectionStepsTypes.STEP2]: {
        actions: {
          onEnter() {
            return null;
          },
          onExit() {
            return null;
          },
        },
        transitions: {
          next: ConnectionStepsTypes.STEP3,
          prev: ConnectionStepsTypes.IDLE,
        },
        options: {
          nextButtonText: 'Next',
          formNode: <Step2 {...formStep2} />,
          formSubmit: formStep2.handleSubmit,
        },
      },
      [ConnectionStepsTypes.STEP3]: {
        actions: {
          onEnter() {
            return null;
          },
          onExit() {
            formStep1.resetForm();
            formStep2.resetForm();
            formStep3.resetForm();
          },
        },
        transitions: {
          next: ConnectionStepsTypes.IDLE,
          prev: ConnectionStepsTypes.IDLE,
        },
        options: {
          nextButtonText: 'Save',
          formNode: <Step3 {...formStep3} />,
          formSubmit: formStep3.handleSubmit,
        },
      },
    },
  };

  type MachineType = typeof machineObject;
  const createMachine = (machineDefinition: MachineType) => ({
    value: machineDefinition.initial,
    transition(currentState: keyof MachineType['states'], event: 'next' | 'prev') {
      const currentStateObject = machineDefinition.states[currentState];
      const destinationTransition = currentStateObject.transitions[event];

      if (!destinationTransition) {
        return;
      }

      const destinationStateObject = machineDefinition.states[destinationTransition];
      destinationStateObject.actions.onEnter();
      currentStateObject.actions.onExit();
      setMachineState(destinationTransition);

      machine.value = destinationTransition;

      return machine.value;
    },
  });
  const machine = createMachine(machineObject);

  return { machine, machineState, options: machineObject.states[machineState].options, loading };
};
