import styled from '@emotion/styled';
import * as React from 'react';
import { CampaignType } from '../../../../__generated__/globalTypes';
import { formatNumber } from '../../../../libs/format';
import CampaignCardPriceItem from './CampaignCardPriceItem';

export interface CampaignCardPriceProps {
  prices: Array<{
    value: number;
    unit: string;
  }>;
  currency: string;
  campaignType: CampaignType;
}

const CampaignCardPrice = (props: CampaignCardPriceProps) => {
  const { prices, currency, campaignType } = props;

  return (
    <Wrapper>
      <PriceWrapper campaignType={campaignType}>
        {prices.map(price => (
          <CampaignCardPriceItem
            key={price.unit}
            value={formatNumber(price.value)}
            currency={currency}
            unit={price.unit}
            campaignType={campaignType}
          />
        ))}
      </PriceWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
`;

const PriceWrapper = styled.div<{ campaignType: CampaignType }>`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.campaignType === CampaignType.ENGAGEMENT ? 'flex-start' : 'flex-end')};
`;

export default CampaignCardPrice;
