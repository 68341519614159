import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from '@src/components/atoms/Button';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import { Icon } from '@src/components/atoms/Icon';
import { HeaderColumn } from '@src/components/atoms/List';
import { EmptyTable } from '@src/components/molecules/Table';
import TextForm from '@src/components/molecules/TextForm';
import { useQueryHelper } from '@src/libs/hooks';
import { defaultInfluencerAvatar } from '@src/libs/image';
import { ViewportType } from '@src/libs/theme';
import { SliderComponents, StyledComponents } from '@src/components/molecules/SliderTable';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import CREATE_CREATOR_STAFF from './mutations/CreateCreatorStaff.graphql';
import REMOVE_CREATOR_STAFF from './mutations/RemoveCreatorStaff.graphql';
import { CreateCreatorStaff, CreateCreatorStaffVariables } from './mutations/__generated__/CreateCreatorStaff';
import { RemoveCreatorStaff, RemoveCreatorStaffVariables } from './mutations/__generated__/RemoveCreatorStaff';
import useGetCreatorStaffs from './useGetCreatorStaffs';

const Users = () => {
  const [dialogAdd, setDialogAdd] = useState<boolean>(false);
  const [dialogDelete, setDialogDelete] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [isSubmitting, setIsSubmmiting] = useState(false);
  const [name, setName] = useState<string>('');
  const [selectedStaffId, setSelectedStaffId] = useState<number | null>(null);
  const { enqueueSnackbar, t } = useQueryHelper();
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });
  const { data } = useGetCreatorStaffs();

  const [createCreatorStaff] = useMutation<CreateCreatorStaff, CreateCreatorStaffVariables>(CREATE_CREATOR_STAFF, {
    refetchQueries: ['GetCreatorStaffs'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInCreating'), { variant: 'success' });
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [removeCreatorStaff] = useMutation<RemoveCreatorStaff, RemoveCreatorStaffVariables>(REMOVE_CREATOR_STAFF, {
    refetchQueries: ['GetCreatorStaffs'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  function cancelDialogAdd() {
    setDialogAdd(false);
    setEmail('');
    setName('');
  }

  function onClickDelete(id: number) {
    setSelectedStaffId(id);
    setDialogDelete(true);
  }

  function onClickDeleteStaff() {
    if (selectedStaffId) {
      setIsSubmmiting(true);
      removeCreatorStaff({
        variables: {
          input: {
            creatorStaffId: selectedStaffId,
          },
        },
      })
        .then(() => setDialogDelete(false))
        .finally(() => setIsSubmmiting(false));
    }
  }

  // @ts-ignore: Not all code paths return a value
  function onClickSendInvite() {
    // eslint-disable-next-line no-useless-escape
    const isValidEmail = /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
    if (!isValidEmail) {
      setEmailError(true);

      return false;
    }

    setEmailError(false);
    setIsSubmmiting(true);
    createCreatorStaff({
      variables: {
        input: {
          name,
          email,
        },
      },
    })
      .then(() => cancelDialogAdd())
      .finally(() => setIsSubmmiting(false));
  }

  const avatar = data?.getCreatorStaffs?.avatar || '';
  const creatorName = data?.getCreatorStaffs?.name || '';
  const staffs = data?.getCreatorStaffs?.staffs || [];
  const selectedStaffName = staffs.find(staff => staff?.id === selectedStaffId)?.name || '';

  return (
    <div css={styles.container}>
      <Popup contentStyle={styles.dialog} open={dialogAdd} onClose={() => setDialogAdd(false)}>
        <>
          <DialogCloseButton>
            <Icon color="#c5d0da" icon="close" onClick={() => setDialogAdd(false)} />
          </DialogCloseButton>
          <DialogContent>
            <DialogTitle>{t('Add Staff')}</DialogTitle>
            <TextFormContainer>
              <StyledTextForm
                isRequired
                placeholder="Name"
                title="Name"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </TextFormContainer>
            <TextFormContainer>
              <StyledTextForm
                isRequired
                placeholder="Email"
                title="Email address"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              {emailError && <ErrorMessage message={t('invalidEmailMessage')} />}
            </TextFormContainer>
          </DialogContent>
          <DialogAction>
            <DialogCancelButton bgcolor="#fff" hoverbgcolor="#f6f8fa" title="Cancel" onClick={cancelDialogAdd} />
            <DialogSubmitButton
              bgcolor="#3892e5"
              disabled={!name || !email || isSubmitting}
              hoverbgcolor="#3f9dff"
              title="Send invite"
              onClick={onClickSendInvite}
            />
          </DialogAction>
        </>
      </Popup>

      <Popup contentStyle={styles.dialog} open={dialogDelete} onClose={() => setDialogDelete(false)}>
        <>
          <DialogCloseButton>
            <Icon color="#c5d0da" icon="close" onClick={() => setDialogDelete(false)} />
          </DialogCloseButton>
          <DialogContent>
            <DialogTitle>{t('Dialog.Delete Staff')}</DialogTitle>
            <div>{`${t('Delete staff')} ${selectedStaffName}?`}</div>
          </DialogContent>
          <DialogAction>
            <DialogCancelButton
              bgcolor="#fff"
              hoverbgcolor="#f6f8fa"
              title="Cancel"
              onClick={() => setDialogDelete(false)}
            />
            <DialogSubmitButton
              bgcolor="#3892e5"
              disabled={isSubmitting}
              hoverbgcolor="#3f9dff"
              title="Delete"
              onClick={onClickDeleteStaff}
            />
          </DialogAction>
        </>
      </Popup>

      {isDesktopView && <BackNavigator title="Users" to="/settings" />}

      <div css={styles.creatorContainer}>
        <div>{t('Creator')}</div>
        <div>
          <img alt="avatar" css={styles.avatar} height="64" src={defaultInfluencerAvatar(avatar)} width="64" />
          <span>{creatorName}</span>
        </div>
      </div>

      <div css={styles.addStaffContainer}>
        <Button bgcolor="#3892e5" hoverbgcolor="#18a0fb" title="Add Staff" onClick={() => setDialogAdd(true)} />
      </div>

      <div css={styles.tableContainer}>
        {staffs.length > 0 ? (
          !isDesktopView ? (
            staffs.map(staff => (
              <div css={styles.staffsContainer} key={staff!.id}>
                <Link to={`/settings/users/${staff!.id}`}>{staff!.name}</Link>
                <p>{staff?.email}</p>
              </div>
            ))
          ) : (
            <SliderComponents.SliderSection css={styles.sliderTableContainer}>
              <SliderComponents.SliderTable>
                <thead>
                  <SliderComponents.HeaderRow>
                    <StyledHeaderColumn title="Name" />
                    <StyledHeaderColumn title="Mail Address" />
                  </SliderComponents.HeaderRow>
                </thead>

                <tbody>
                  {staffs.map(staff => (
                    <StyledComponents.StyledRowNew css={styles.tableBodyRow} key={staff!.id}>
                      <td>
                        <Link to={`/settings/users/${staff!.id}`}>{staff!.name}</Link>
                      </td>
                      <td>
                        <div css={styles.emailContainer}>
                          <div>{staff!.email}</div>
                          <div>
                            <Button
                              bgcolor="#fff"
                              css={styles.deleteBtn}
                              hoverbgcolor="#f6f8fa"
                              title="Delete"
                              onClick={() => onClickDelete(staff!.id)}
                            />
                          </div>
                        </div>
                      </td>
                    </StyledComponents.StyledRowNew>
                  ))}
                </tbody>
              </SliderComponents.SliderTable>
            </SliderComponents.SliderSection>
          )
        ) : (
          <EmptyTable css={styles.emptyTable} />
        )}
      </div>
    </div>
  );
};

const DialogAction = styled.div`
  background-color: #f6f8fa;
  display: flex;
  flex-basis: 100%;
  justify-content: flex-end;
  margin-top: 8px;
  padding: 16px;
`;

const DialogCancelButton = styled(Button)`
  border: 1px solid #dee5ec;
  border-radius: 3px;
  color: #6e7c89;
  height: 32px;
  width: 90px;

  @media (max-width: ${ViewportType.SMALL}px) {
    border-radius: 5px;
  }
`;

const DialogCloseButton = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
`;

const DialogContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 32px 16px 16px 16px;
`;

const DialogSubmitButton = styled(Button)`
  height: 32px;
  margin-left: 8px;
  width: 115px;

  @media (max-width: ${ViewportType.SMALL}px) {
    border-radius: 5px;
  }
`;

const DialogTitle = styled.div`
  color: #27313b;
  display: grid;
  flex-basis: 100%;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 24px;
`;

const StyledHeaderColumn = styled(HeaderColumn)`
  color: #27313b;
  border-bottom: 1px solid #dee5ec;
  font-size: 12px;
  font-weight: 600;
`;

const StyledTextForm = styled(TextForm)`
  & > label {
    font-size: 14px;
  }

  & input {
    border-radius: 0;
    height: 32px;
  }
`;

const TextFormContainer = styled.div`
  display: grid;
  flex-basis: 100%;
  margin-bottom: 16px;
`;

const styles = {
  addStaffContainer: css`
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #dee5ec;
    display: flex;
    justify-content: flex-end;
    height: 40px;
    padding: 8px;

    & > button {
      height: 32px;
      width: 105px;

      @media (max-width: ${ViewportType.SMALL}px) {
        border-radius: 5px;
      }
    }
  `,
  avatar: css`
    border-radius: 50%;
  `,
  container: css`
    margin: 16px 24px;

    @media (max-width: ${ViewportType.SMALL}px) {
      margin: 16px;
    }
  `,
  creatorContainer: css`
    background-color: #fff;
    height: 98px;
    margin: 24px 0;
    padding: 24px 16px;

    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: 18px;
      font-weight: 600;
    }

    & > div:nth-of-type(2) {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      margin-top: 8px;

      & > span {
        color: #27313b;
        font-size: 14px;
        margin-left: 8px;
      }
    }
  `,
  deleteBtn: css`
    border: 1px solid #dee5ec;
    color: #6e7c89;
    height: 32px;
    width: 87px;
  `,
  dialog: {
    borderRadius: '5px',
    boxShadow: '0 0 12px rgba(0, 0, 0, 0.12), 0 12px 12px rgba(0, 0, 0, 0.24)',
    maxWidth: 480,
    padding: 0,
    width: '90%',
  },
  emailContainer: css`
    align-items: center;
    display: flex;

    /* stylelint-disable */
    & > div {
      display: grid;
      flex-basis: 50%;
    }
    /* stylelint-enable */

    & > div:nth-of-type(2) {
      justify-content: flex-end;
    }
  `,
  emptyTable: css`
    border: none;
    margin: 0;
  `,
  sliderTableContainer: css`
    width: 100%;
  `,
  staffsContainer: css`
    background-color: #fff;
    border-bottom: 1px solid #dee5ec;
    padding: 8px;

    & > a {
      color: #3892e5;
      font-size: 14px;
      margin-bottom: 8px;
    }

    & > p {
      color: #27313b;
      font-size: 14px;
    }
  `,
  tableBodyRow: css`
    & > td > a {
      color: #3892e5;
      font-size: 14px;
    }

    & > td:nth-of-type(1) {
      color: #3892e5;
      font-size: 14px;
    }

    &:hover {
      & > td {
        background-color: #fff;
      }
    }
  `,
  tableContainer: css`
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
  `,
};

export default Users;
