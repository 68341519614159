import { useField, useFormikContext } from 'formik';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SubmitButton } from '@src/components/atoms/Button';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import Grid from '@src/components/atoms/Grid';
import { useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import TextForm from '@src/components/molecules/TextForm';
import Switch from '@src/components/molecules/Switch';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { GetUserDetail_getUserDetail } from './queries/__generated__/GetUserDetail';

const UserDetail = () => {
  const { t } = useQueryHelper();
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });

  const { isSubmitting, handleSubmit, setFieldValue } = useFormikContext<GetUserDetail_getUserDetail>();
  const [name, nameMeta] = useField('name');
  const [email, emailMeta] = useField('email');
  const [crmNotificationSetting] = useField<boolean>('crmNotificationSetting');

  return (
    <div css={styles.container}>
      {isDesktopView && (
        <div css={styles.navigatorContainer}>
          <BackNavigator title="User Detail" to="/settings/users" />
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div css={styles.formContainer}>
          <Grid md={8} sm={10} xs={12}>
            <div css={styles.formBox}>
              <TextFormContainer>
                <StyledTextForm
                  error={!!nameMeta.error}
                  isRequired
                  placeholder="Display Name"
                  title="Display Name"
                  value={name.value}
                  onChange={e => setFieldValue('name', e.target.value)}
                />
                {nameMeta.error && <ErrorMessage message={t(nameMeta.error)} />}
              </TextFormContainer>

              <TextFormContainer>
                <StyledTextForm
                  error={!!emailMeta.error}
                  isRequired
                  placeholder="Email Address"
                  title="Email Address"
                  value={email.value}
                  onChange={e => setFieldValue('email', e.target.value)}
                />
                {emailMeta.error && <ErrorMessage message={t(emailMeta.error)} />}
              </TextFormContainer>

              <SwitchContainer>
                <Switch
                  isChecked={crmNotificationSetting.value}
                  handleChange={() => setFieldValue('crmNotificationSetting', !crmNotificationSetting.value)}
                />
                <SwitchLabel>{t(`MenuTitle.CRM`)}</SwitchLabel>
              </SwitchContainer>
            </div>

            <div css={styles.actionContainer}>
              <SubmitButton css={styles.submitBtn} disabled={isSubmitting} value="Save" />
            </div>
          </Grid>
        </div>
      </form>
    </div>
  );
};

const StyledTextForm = styled(TextForm)`
  & > label {
    font-size: 14px;
  }

  & input {
    border-radius: 0;
    height: 32px;
  }
`;

const SwitchContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 16px;
`;

const SwitchLabel = styled.span`
  color: #27313b;
  font-size: 14px;
  margin-left: 8px;
`;

const TextFormContainer = styled.div`
  margin-bottom: 16px;
`;

const styles = {
  actionContainer: css`
    background-color: #fff;
    border-top: 1px solid #eef3f7;
    display: flex;
    justify-content: flex-end;
    padding: 16px;
  `,
  container: css`
    margin: 16px 24px;

    @media (max-width: ${ViewportType.SMALL}px) {
      margin: 16px;
    }
  `,
  formBox: css`
    background-color: #fff;
    padding: 24px 24px 0 24px;
  `,
  formContainer: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  `,
  navigatorContainer: css`
    margin-bottom: 24px;
  `,
  submitBtn: css`
    border-radius: 0;
    height: 32px;
    line-height: 0;
    width: 77px;

    @media (max-width: ${ViewportType.SMALL}px) {
      border-radius: 5px;
      width: 100%;
    }
  `,
};

export default UserDetail;
