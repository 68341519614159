import * as React from 'react';
import { useQuery } from '@apollo/client';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { useQueryHelper } from '@src/libs/hooks';
import { BasePostFilterType } from '@src/pages/InstagramStoryOrFeedPosts/types';
import Template from './template';
import {
  InfluencerAllInstagramFeedPosts,
  InfluencerAllInstagramFeedPostsVariables,
} from './__generated__/InfluencerAllInstagramFeedPosts';
import * as INFLUENCER_ALL_INSTAGRAM_FEED_POSTS from './InfluencerAllInstagramFeedPosts.graphql';
import { feedPostVariables } from './utils';

interface PostFeedProps {
  accountId: number;
  currentPage: number;
  filter: BasePostFilterType;
  setFilter: (filter: BasePostFilterType) => void;
}

const PostFeed = (props: PostFeedProps) => {
  const { t, history, enqueueSnackbar, search } = useQueryHelper();

  const { data, loading, error } = useQuery<InfluencerAllInstagramFeedPosts, InfluencerAllInstagramFeedPostsVariables>(
    INFLUENCER_ALL_INSTAGRAM_FEED_POSTS,
    { variables: feedPostVariables(props) }
  );

  if (loading || !data) {
    return <ListIndicator />;
  }

  if (error) {
    history.push(`/profile${search}`);
    enqueueSnackbar(t(error?.message || ''), { variant: 'error' });
  }

  return (
    <Template total={data?.getTotalNumber?.totalNumber || 0} posts={data?.allInstagramFeedPosts || []} {...props} />
  );
};

export default PostFeed;
