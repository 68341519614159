import * as React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { Icon } from '@src/components/atoms/Icon';
import SignInEmailForm from '@src/components/organisms/SignInEmailForm';
import Header from '@src/components/organisms/TopPage/Header';
import TopPageContainer from '@src/components/organisms/TopPage/TopPageContainer';
import { SignUpAndSignInBreakpoint } from '@src/libs/theme';
import { usePageLayout } from '@src/libs/hooks';

const Template = () => {
  const { isMobileViewPublicRoutes } = usePageLayout();

  return (
    <div css={styles.container}>
      {isMobileViewPublicRoutes && (
        <div css={styles.headerContainer}>
          <Link css={styles.backArrowContainer} to="/">
            <Icon color="#3892e5" css={styles.backArrow} icon="arrow_back" />
          </Link>
          <Header />
        </div>
      )}
      <TopPageContainer
        hideFooter={isMobileViewPublicRoutes}
        hideHeader={isMobileViewPublicRoutes}
        isSignIn
        logoHref="/"
      >
        <div css={styles.form}>
          <SignInEmailForm />
        </div>
      </TopPageContainer>
    </div>
  );
};

const styles = {
  backArrow: css`
    font-size: 22px;

    &:hover {
      opacity: 0.7;
    }
  `,
  backArrowContainer: css`
    display: flex;
    left: 42px;
    position: absolute;
    top: 28px;
  `,
  container: css`
    height: 100%;

    & > div {
      @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
        background-color: #fff;
      }
    }
  `,
  form: css`
    display: flex;
    align-items: center;
    height: 100%;
  `,
  headerContainer: css`
    display: flex;
    flex-wrap: wrap;
    position: relative;

    & > header {
      display: flex;
      justify-content: center;

      & > div:nth-of-type(2) {
        display: none;
      }
    }
  `,
};

export default Template;
