import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { formatNumber } from '@src/libs/format';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { useTranslation } from 'react-i18next';
import { getDateLocal } from '@src/components/atoms/List/DateColumn';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { GetFanHeader, GetFanHeaderVariables } from './__generated__/GetFanHeader';
import * as GET_FAN_HEADER from './GetFanHeader.graphql';

interface FanDetailsHeaderProps {
  id: string;
}

const FanDetailsHeader = ({ id }: FanDetailsHeaderProps) => {
  const { i18n } = useTranslation();
  const { enqueueSnackbar, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();

  const { data, loading } = useQuery<GetFanHeader, GetFanHeaderVariables>(GET_FAN_HEADER, {
    variables: {
      input: {
        fanId: Number(id),
      },
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const headerInfo = data?.getFanHeader;

  const fullName = `${data?.getFanHeader?.firstName} ${data?.getFanHeader?.lastName}`;

  return (
    <>
      {!isMobileView && <BackNavigator to="/fans" title="Details of" interpolation={{ translationKey: fullName }} />}
      <div css={[styles.wrapper, isMobileView ? styles.mobileWrapper : undefined]}>
        {loading ? (
          <CircularProgress size={64} thickness={2.4} color="inherit" css={styles.progress} />
        ) : (
          <>
            <div css={styles.col}>
              <img css={styles.avatar} src={headerInfo?.avatar} />
              <div>
                <MainText css={styles.marginBottom6}>
                  {headerInfo?.firstName} {headerInfo?.lastName}
                </MainText>

                {headerInfo?.created && (
                  <SubText css={styles.clearMarginBottom}>
                    {`${t('Customer for')} ${
                      headerInfo.created
                        ? formatDistanceToNowStrict(new Date(headerInfo.created + 'Z'), {
                            locale: getDateLocal(i18n.language as AppLanguage),
                          })
                        : '-'
                    }`}
                  </SubText>
                )}
              </div>
            </div>
            <div css={[styles.col, isMobileView && styles.mobileCol]}>
              <InfoWrapper isMobile={isMobileView}>
                <SubText>{t('Last order')}</SubText>
                <MainText>
                  {headerInfo?.lastOrder
                    ? formatDistanceToNow(new Date(headerInfo.lastOrder + 'Z'), {
                        addSuffix: true,
                        locale: getDateLocal(i18n.language as AppLanguage),
                      })
                    : '-'}
                </MainText>
              </InfoWrapper>
              <InfoWrapper isMobile={isMobileView}>
                <SubText>{t('Order')}</SubText>
                <MainText>
                  {headerInfo?.totalOrders} {t('orders')}
                </MainText>
              </InfoWrapper>
              <InfoWrapper isMobile={isMobileView}>
                <SubText>{t('Total spent')}</SubText>
                <MainText>
                  {headerInfo?.currencyId} {formatNumber(headerInfo?.totalSpent || null)}
                </MainText>
              </InfoWrapper>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const styles = {
  mobileWrapper: css`
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    height: auto;
  `,
  clearMarginBottom: css`
    margin-bottom: 0;
  `,
  marginBottom6: css`
    margin-bottom: 6px;
  `,
  progress: css`
    margin: 0 auto;
  `,
  wrapper: css`
    height: 112px;
    display: flex;
    background-color: #fff;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    border-radius: 5px;
    margin: 24px 0;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
  `,
  col: css`
    display: flex;
    align-items: center;
  `,
  mobileCol: css`
    margin-top: 16px;
    width: 100%;
  `,
  avatar: css`
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin-right: 16px;
  `,
};
const MainText = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: #27313b;
`;
const SubText = styled.p`
  color: #6e7c89;
  font-size: 12px;
  margin-bottom: 4px;
`;
const InfoWrapper = styled.div<{ isMobile: boolean }>`
  padding: ${props => (props.isMobile ? '0' : '0 40px')};
  display: flex;
  flex: auto;
  flex-direction: column;
  align-items: center;

  &:not(:last-of-type) {
    border-right: 1px solid #eef3f7;
  }
`;

export default FanDetailsHeader;
