import React from 'react';
import { getThirtyDaysAgo, getToday } from '@src/libs/date';
import { useSort } from '@src/libs/filter';
import { usePaging } from '@src/libs/paging';
import { FacebookPageAnalyticsPostsSortOrder } from '@src/__generated__/globalTypes';
import { getFacebookPageFeedSortFieldType } from '@src/components/organisms/AnalyticsFacebookPage/AnalyticsPosts/FeedPosts/helpers';
import { useFilter } from '@src/components/organisms/AnalyticsShared/BasePostListFilter/useFilter';
import Template from './template';

const InfluencerFacebookPagePostList = () => {
  const currentPage = usePaging();
  const { filter, setFilter } = useFilter({ startDate: getThirtyDaysAgo(), endDate: getToday() });
  const sortData = useSort<FacebookPageAnalyticsPostsSortOrder>(getFacebookPageFeedSortFieldType);

  return <Template currentPage={currentPage} filter={filter} setFilter={setFilter} {...sortData} />;
};

export default InfluencerFacebookPagePostList;
