import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { mainBlue } from '../../../libs/pallete';

interface SubmitButtonProps extends SubmitProps {
  className?: string;
  value: string;
  submittingMessage?: string;
  isSubmitting?: boolean;
  onClick?: () => void | Promise<void>;
}

const SubmitButton = (props: SubmitButtonProps) => {
  const { t } = useTranslation();
  const { className, value, isSubmitting, onClick, color, bgcolor, hoverbgcolor } = props;
  const submittingMessage = props.submittingMessage || 'Submitting';
  const submitValue = isSubmitting ? submittingMessage : value;
  const translatedSubmitValue = t(`Button.${submitValue}`);
  const disabled = isSubmitting || props.disabled;

  return (
    <Submit
      className={className}
      type="submit"
      value={translatedSubmitValue}
      disabled={disabled}
      color={color}
      bgcolor={bgcolor}
      hoverbgcolor={hoverbgcolor}
      onClick={onClick}
    />
  );
};

interface SubmitProps {
  disabled?: boolean;
  color?: string;
  bgcolor?: string;
  hoverbgcolor?: string;
}

const Submit = styled.input<SubmitProps>`
  width: 100%;
  background-color: ${props => (props.disabled ? mainBlue.disable : props?.bgcolor || mainBlue.normal)};
  border-radius: 20px;
  color: ${props => props?.color || mainBlue.textColor};
  line-height: 40px;
  transition: background-color 0.3s ease-in-out;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  ${props => props.disabled && 'pointer-events: none'};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: center;

  &:hover {
    background-color: ${props => props.hoverbgcolor || mainBlue.hover};
  }
`;

export default SubmitButton;
