import { useApolloClient } from '@apollo/client';
import { useState, useEffect } from 'react';
import { CampaignSocialMediaType } from '@src/__generated__/globalTypes';
import * as MARKETPLACE_JOINABLE_ACCOUNTS from './MarketplaceJoinableAccounts.graphql';
import {
  MarketplaceJoinableAccounts,
  MarketplaceJoinableAccountsVariables,
  MarketplaceJoinableAccounts_marketplaceJoinableAccounts,
} from './__generated__/MarketplaceJoinableAccounts';
import { JoinableAccounts, SelectedJoinedAccount } from './types';
import { getFormattedAccounts } from './helpers';

interface JoinableAccountsProps {
  isDialogOpen: boolean;
  campaignId: number;
  campaignType: CampaignSocialMediaType;
}
export const useJoinableAccounts = ({ campaignId, campaignType }: JoinableAccountsProps) => {
  const client = useApolloClient();
  const [joinableAccounts, setJoinableAccounts] = useState<Array<Partial<JoinableAccounts>>>([]);
  const [selectedAccount, setSelectedAccount] = useState<SelectedJoinedAccount>({
    id: null,
    socialMedia: undefined,
  });
  const [isLoading, setLoading] = useState(false);
  const [fbAccountId, setFbAccountId] = useState<number | null>(null);

  const getJoinableAccountsList = async () => {
    // for some reasons using useQuery here causing marketplaceJoinableAccounts response being undefined in some cases
    // but using client.query and using useEffect solves the issue
    const { data, loading } = await client.query<MarketplaceJoinableAccounts, MarketplaceJoinableAccountsVariables>({
      query: MARKETPLACE_JOINABLE_ACCOUNTS,
      variables: { campaignId },
    });

    setLoading(loading);

    const marketplaceJoinableAccounts = (data?.marketplaceJoinableAccounts ||
      {}) as MarketplaceJoinableAccounts_marketplaceJoinableAccounts;

    setJoinableAccounts(getFormattedAccounts(marketplaceJoinableAccounts, campaignType));
    setFbAccountId(marketplaceJoinableAccounts.facebookAccount?.id || null);
  };

  useEffect(() => {
    getJoinableAccountsList();
  }, []);

  return {
    data: joinableAccounts as JoinableAccounts[],
    loading: isLoading,
    selectedAccount,
    setSelectedAccount,
    getJoinableAccountsList,
    fbAccountId,
  };
};
