import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Icon } from '@src/components/atoms/Icon';

interface MenuButtonProps {
  options: {
    icon?: string;
    title: string;
    onClick: () => void;
  }[];
}

const MenuButton = ({ options }: MenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { t } = useTranslation();

  function onClickMenu(event: SyntheticEvent<HTMLDivElement>) {
    setAnchorEl(event.currentTarget);
  }

  function onClickMenuItem(func: () => void) {
    setAnchorEl(null);
    func();
  }

  function onCloseMenu() {
    setAnchorEl(null);
  }

  return (
    <div>
      <div css={styles.menuButton} onClick={onClickMenu}>
        <Icon color="#6e7c89" icon="more_horiz" />
      </div>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        css={styles.menu}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        onClose={onCloseMenu}
      >
        {options.map((option, index) => {
          const { icon, title, onClick } = option;

          return (
            <MenuItem css={styles.menuItem} key={index} onClick={() => onClickMenuItem(onClick)}>
              {icon && <Icon color="#6e7c89" icon={icon} withText />}
              {t(`MenuTitle.${title}`)}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

const styles = {
  menuButton: css`
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    height: 32px;
    justify-content: center;
    margin-left: 8px;
    outline: 1px solid #dee5ec;
    width: 32px;

    & > i {
      margin: 0;
    }
  `,
  menu: css`
    & > div.MuiMenu-paper {
      box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
    }

    & ul.MuiMenu-list {
      padding: 0;
    }
  `,
  menuItem: css`
    background-color: #fff !important;
    filter: brightness(1);

    &:hover {
      filter: brightness(0.9);
    }
  `,
  menuItemIcon: css`
    margin-right: 8px;

    &:hover {
      opacity: 0.9;
    }
  `,
};

export default MenuButton;
