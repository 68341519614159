import React from 'react';
import { ListIndicator } from '../../../molecules/Indicator';
import { getAddPostPath } from '../utils';
import NavigationHeader from '../../Header/NavigationHeader';
import { PalleteButton } from '../../../atoms/Button';
import { mainBlue } from '../../../../libs/pallete';
import BottomOverflowSection from '../../../atoms/BottomOverflowSection';
import EngagementPostList from './EngagementPostList';
import { useEngagementPostQueries } from './useEngagementPostQueries';
import { EngagementPostListProps } from './types';

const EngagementPostListComponent = ({ engagementId }: EngagementPostListProps) => {
  const {
    loading,
    posts,
    closeDialog,
    deleteEngagementPost,
    dialog,
    isDeleting,
    hasUploadDraftPost,
    hasNotFoundError,
  } = useEngagementPostQueries(engagementId);

  if (loading) {
    return <ListIndicator />;
  }

  const addPostPath = getAddPostPath(engagementId);
  const hasUploadDraftPostButton = !hasNotFoundError && hasUploadDraftPost;

  return (
    <>
      <NavigationHeader>
        {hasUploadDraftPostButton && (
          <PalleteButton
            palleteColor={mainBlue}
            text="Upload Draft Post"
            href={addPostPath}
            height="32px"
            width="200px"
            fontSize="12px"
          />
        )}
      </NavigationHeader>
      <EngagementPostList
        posts={posts}
        closeDialog={closeDialog}
        deleteEngagementPost={deleteEngagementPost}
        engagementId={engagementId}
        dialog={dialog}
        isDeleting={isDeleting}
        hasNotFoundError={hasNotFoundError}
      />
      {hasUploadDraftPostButton && (
        <BottomOverflowSection>
          <PalleteButton
            href={addPostPath}
            text="Upload Draft Post"
            palleteColor={mainBlue}
            height="32px"
            fontSize="12px"
          />
        </BottomOverflowSection>
      )}
    </>
  );
};

export default EngagementPostListComponent;
