import range from 'lodash/range';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import questionMark from '@src/assets/icon/questionMark.svg';
import { Button } from '@src/components/atoms/Button';
import { Icon } from '@src/components/atoms/Icon';
import { tutorials, TutorialTypes } from './helpers';

interface TutorialToolProps {
  type: TutorialTypes;
}

const TutorialTool = ({ type }: TutorialToolProps) => {
  const [currStep, setCurrStep] = useState<number>(0);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const tutorialSteps = tutorials[type];

  useEffect(() => {
    setIsDialogOpen(false);
  }, []);

  useEffect(() => {
    setCurrStep(0);
  }, [isDialogOpen]);

  return (
    <>
      <Popup
        contentStyle={{
          animation: 'fadeIn linear 0.3s',
          borderRadius: 9,
          maxWidth: 343,
          width: '90%',
        }}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        {
          <div css={styles.dialog}>
            <div css={styles.dialogCloseBtn} onClick={() => setIsDialogOpen(false)}>
              <Icon icon="close" />
            </div>

            {tutorialSteps[currStep] && (
              <>
                <div css={styles.dialogContentContainer}>
                  <div css={styles.dialogContentTitle}>
                    <img alt="icon" height="24" src={tutorialSteps[currStep].icon} width="24" />
                    <div>{t(tutorialSteps[currStep].title)}</div>
                  </div>

                  <div css={styles.dialogContentImg}>
                    <img alt="image" src={tutorialSteps[currStep].image} />
                  </div>

                  <div css={styles.dialogContentDescription}>
                    <div>{t(tutorialSteps[currStep].descriptionTitle)}</div>
                    <div>{t(tutorialSteps[currStep].description)}</div>
                  </div>
                </div>

                <div css={styles.dotsContainer}>
                  {range(tutorialSteps.length).map(index => (
                    <Dot active={currStep === index} key={index} />
                  ))}
                </div>

                <div css={styles.dialogActionContainer}>
                  <StyledButton
                    bgcolor="#fff"
                    color="#6E7C89"
                    hoverbgcolor="#fff"
                    title={currStep === 0 ? 'Skip' : 'Prev'}
                    onClick={() => (currStep === 0 ? setIsDialogOpen(false) : setCurrStep(step => step - 1))}
                  />
                  <StyledButton
                    bgcolor="#3892e5"
                    color="#fff"
                    hoverbgcolor="#3892e5"
                    isPrimary
                    title={currStep === tutorialSteps.length - 1 ? 'Start' : 'Next'}
                    onClick={() =>
                      currStep === tutorialSteps.length - 1 ? setIsDialogOpen(false) : setCurrStep(step => step + 1)
                    }
                  />
                </div>
              </>
            )}
          </div>
        }
      </Popup>
      <img
        alt="icon"
        css={{ cursor: 'pointer' }}
        height="24"
        src={questionMark}
        width="24"
        onClick={() => setIsDialogOpen(true)}
      />
    </>
  );
};

const Dot = styled.div<{ active: boolean }>`
  background-color: ${({ active }) => (active ? '#3892e5' : '#afd3f5')};
  border-radius: 50%;
  height: 8px;
  margin-right: 3px;
  width: 8px;
`;

const StyledButton = styled(Button)<{ isPrimary?: boolean }>`
  border: 1px solid ${({ isPrimary }) => (isPrimary ? '#3892e5' : '#dee5ec')};
  border-radius: 5px;
  filter: brightness(1);
  font-size: 12px;
  font-weight: 600;
  height: 40px;
  width: 48%;

  &:hover {
    filter: brightness(0.9);
  }
`;

const styles = {
  dialog: css`
    height: 75vh;
    margin-bottom: 60px;
    max-height: 540px;
    padding: 8px 0;
    overflow: auto;
  `,
  dialogActionContainer: css`
    align-items: center;
    background-color: #fff;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    padding: 16px;
    position: absolute;
    width: calc(100% - 40px);

    & > button:nth-of-type(1) {
      margin-right: 4px;
    }

    & > button:nth-of-type(2) {
      margin-left: 4px;
    }
  `,
  dialogCloseBtn: css`
    align-items: center;
    background-color: #000;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: -10px;
    top: -15px;
    width: 40px;
    z-index: 1;

    & > i {
      margin: 0;
    }
  `,
  dialogContentContainer: css`
    min-height: 500px;
    padding: 8px;
  `,
  dialogContentDescription: css`
    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: 14px;
    }
  `,
  dialogContentImg: css`
    margin-bottom: 16px;
    margin-top: 40px;
    width: 100%;

    & > img {
      width: 100%;
    }
  `,
  dialogContentTitle: css`
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    height: 40px;
    padding: 16px 0 0 0;
    position: absolute;
    top: 0;
    width: calc(100% - 26px);

    & > img {
      margin: 0 8px;
    }

    /* stylelint-disable no-descending-specificity */
    & > div {
      color: #27313b;
      display: flex;
      flex: 1;
      font-size: 18px;
      font-weight: 700;
    }
  `,
  dotsContainer: css`
    background-color: #fff;
    border-bottom: 1px solid #dee5ec;
    bottom: 72px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 16px 0;
    position: absolute;
    width: calc(100% - 10px);
  `,
};

export { TutorialTypes };
export default TutorialTool;
