import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { css, Global } from '@emotion/core';
import CarouselModal from '@src/components/molecules/CarouselModal/CarouselModal';
import DataOpacityWrapper from '@src/components/molecules/DataCard/DataOpacityWrapper';
import HighChart from '@src/components/molecules/HighChart';
import { MultiSelector } from '@src/components/molecules/Selector';
import { useQueryHelper } from '@src/libs/hooks';
import { FeatureStats } from '@src/components/organisms/PostDetail/FeatureStats';
import { FeatureStatsData } from '@src/components/organisms/ProfileDetail/AnalyticsProfile/model';
import { SocialMediaAccountType } from '@src/components/organisms/ProfileDetail/types';
import { usePostDetailModal } from '@src/components/organisms/AnalyticsShared/BasePostList/usePostDetailModal';
import * as Styled from '@src/components/organisms/AnalyticsShared/Overview/StyledComponents';
import { useMediaQuery } from 'react-responsive';
import { ViewportBreakpoint } from '@src/libs/theme';
import CheckBoxIcon from '@src/components/atoms/Icon/CheckBoxIcon';
import AnalyticsPostDetail from '../AnalyticsPostDetail/PostDetail';
import * as INFLUENCER_YT_POSTS_IN_DATE from './InfluencerYoutubeAnalyticsPostsByDate.graphql';
import {
  InfluencerYoutubeAnalyticsPostsByDate,
  InfluencerYoutubeAnalyticsPostsByDateVariables,
  InfluencerYoutubeAnalyticsPostsByDate_postByDate_posts,
} from './__generated__/InfluencerYoutubeAnalyticsPostsByDate';
import { ChartColorTypes, defaultSelectableCharts, colors } from './helper';
import { OverviewStats } from './types';
import { getOptions } from './getOptions';

interface TemplateProps {
  selectedSocialMedia: SocialMediaAccountType;
  overviewStats?: OverviewStats | null;
}

const Template = ({ overviewStats, selectedSocialMedia }: TemplateProps) => {
  const client = useApolloClient();
  const { t, enqueueSnackbar } = useQueryHelper();
  const [selectedCharts, setSelectedCharts] = useState<ChartColorTypes[]>(['Subscribers', 'Post']);
  const [dailyPostsData, setDailyPostsData] = useState<InfluencerYoutubeAnalyticsPostsByDate_postByDate_posts[]>([]);
  const isMedium = useMediaQuery({ minWidth: ViewportBreakpoint.MEDIUM });

  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev,
    setOpenPostDetail,
  } = usePostDetailModal(dailyPostsData, 'overviewPopup');

  const fetchDailyPosts = async (date: string) => {
    try {
      const { data } = await client.query<
        InfluencerYoutubeAnalyticsPostsByDate,
        InfluencerYoutubeAnalyticsPostsByDateVariables
      >({
        query: INFLUENCER_YT_POSTS_IN_DATE,
        variables: { socialAccountId: selectedSocialMedia.id, date },
      });

      setDailyPostsData(data.postByDate.posts);
      setOpenPostDetail(data.postByDate.posts[0]?.id || null);
    } catch (error) {
      enqueueSnackbar(t('Can not get daily posts data, please try again later '), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (dailyPostsData.length && openPostDetail) {
      handleClickPost(openPostDetail);
    }
  }, [dailyPostsData.length, openPostDetail]);

  const { options, engagement } = overviewStats
    ? getOptions(selectedCharts, overviewStats, fetchDailyPosts)
    : { options: [] as Highcharts.Options, engagement: [] as FeatureStatsData[] };

  const noData = engagement.every(item => !item.rate) && false;
  const currentOverviewPostForModal = dailyPostsData.find(dailyPost => Number(dailyPost.id) === openPostDetail);

  const toggleSelectedCharts = (isSelected: boolean, name: ChartColorTypes) => {
    if (isSelected) {
      setSelectedCharts(selectedCharts.filter(selectedItem => selectedItem !== name));
    } else {
      setSelectedCharts([...selectedCharts, name]);
    }
  };

  return (
    <>
      <DataOpacityWrapper dataNotAvailable={noData}>
        <Styled.EngagementGrid>
          {engagement.map(item => (
            <FeatureStats {...item} key={item.title} />
          ))}
        </Styled.EngagementGrid>

        <Styled.OverviewWrapper>
          <Styled.Chart>
            <Styled.LegendBar>
              {!isMedium ? (
                <MultiSelector
                  value={selectedCharts}
                  name={''}
                  isRoundInput={false}
                  options={defaultSelectableCharts.map(v => ({
                    label: t(`Label.${v}`),
                    value: v,
                  }))}
                  onChange={e => setSelectedCharts(e as ChartColorTypes[])}
                  initialValues={['Follower']}
                  menuCss={styles.menuCss}
                  css={styles.selectorWrapper}
                />
              ) : (
                defaultSelectableCharts.map(item => {
                  const isChecked = selectedCharts.includes(item);
                  const color = colors[item];

                  return (
                    <Styled.LegendTileColored key={item} onClick={() => toggleSelectedCharts(isChecked, item)}>
                      <CheckBoxIcon checked={isChecked} />
                      <Styled.Text>{t(item)}</Styled.Text>
                      <span style={{ background: color }} />
                    </Styled.LegendTileColored>
                  );
                })
              )}
            </Styled.LegendBar>
            <Global styles={styles.globalTooltip} />
            <HighChart options={options} />
          </Styled.Chart>
        </Styled.OverviewWrapper>
      </DataOpacityWrapper>
      <CarouselModal
        open={!!openPostDetail}
        title=""
        cancel="OK"
        onClose={handleClosePostDetail}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        siblingPostId={siblingPostId}
      >
        <AnalyticsPostDetail
          video={currentOverviewPostForModal || null}
          accountName={selectedSocialMedia.name}
          avatarUrl={selectedSocialMedia.avatar}
        />
      </CarouselModal>
    </>
  );
};

const styles = {
  selectorWrapper: css`
    min-height: 30px;
    max-height: 30px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  `,
  menuCss: css`
    margin-top: 5px;
    box-shadow: 0 1px 2px 0 rgba(39, 49, 59, 0.1);
    border-radius: 3px !important;
    width: 100%;
  `,
  globalTooltip: css`
    .highcharts-tooltip path {
      fill: #27313b;
    }

    .highcharts-root {
      padding-left: 4px;
    }
  `,
};

export default Template;
