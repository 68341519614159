import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import anyCreatorBlack from '@src/assets/icon/anyCreatorBlack.svg';
import chooseBusinessAccount from '@src/assets/img/chooseBusinessAccount.png';
import chooseBusinessAccountJP from '@src/assets/img/chooseBusinessAccountJP.png';
import clickNextCompletion from '@src/assets/img/clickNextCompletion.png';
import clickNextCompletionJP from '@src/assets/img/clickNextCompletionJP.png';
import editProfile from '@src/assets/img/editProfile.png';
import editProfileJP from '@src/assets/img/editProfileJP.png';
import mobileAccount from '@src/assets/img/mobileAccount.png';
import mobileAccountJP from '@src/assets/img/mobileAccountJP.png';
import mobileChooseBusinessAccount from '@src/assets/img/mobileChooseBusinessAccount.png';
import mobileChooseBusinessAccountJP from '@src/assets/img/mobileChooseBusinessAccountJP.png';
import mobileSettings from '@src/assets/img/mobileSettings.png';
import mobileSettingsJP from '@src/assets/img/mobileSettingsJP.png';
import mobileSelectFBPages from '@src/assets/img/mobileSelectFBPages.png';
import mobileSelectFBPagesJP from '@src/assets/img/mobileSelectFBPagesJP.png';
import mobileSwitchProfessionalAccount from '@src/assets/img/mobileSwitchProfessionalAccount.png';
import mobileSwitchProfessionalAccountJP from '@src/assets/img/mobileSwitchProfessionalAccountJP.png';
import switchProfessionalAccount from '@src/assets/img/switchProfessionalAccount.png';
import switchProfessionalAccountJP from '@src/assets/img/switchProfessionalAccountJP.png';
import { Button } from '@src/components/atoms/Button';
import { Icon } from '@src/components/atoms/Icon';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { switchImage } from '@src/libs/SocialMedia';
import { SignUpAndSignInBreakpoint } from '@src/libs/theme';
import { SocialAccountType } from '@src/__generated__/globalTypes';

interface InstagramInstructionProps {
  backUrl: string;
  disabled?: boolean;
  failedConnect?: boolean;
  onClick: () => void;
}

const InstagramInstruction = ({ backUrl, disabled, failedConnect, onClick }: InstagramInstructionProps) => {
  const [currStep, setCurrStep] = useState<number>(0);
  const { history, t } = useQueryHelper();
  const { isMobileViewPublicRoutes } = usePageLayout();
  const isJPLanguage = i18n.language === 'ja';

  // TODO: will include ja version of images
  const registerAccountSteps = isMobileViewPublicRoutes
    ? [
        {
          image: isJPLanguage ? mobileSettingsJP : mobileSettings,
          title: 'Tutorial.Text.MobileRegisterYourAccountOne',
        },
        { image: isJPLanguage ? mobileAccountJP : mobileAccount, title: 'Tutorial.Text.MobileRegisterYourAccountTwo' },
        {
          image: isJPLanguage ? mobileSwitchProfessionalAccountJP : mobileSwitchProfessionalAccount,
          title: 'Tutorial.Text.MobileRegisterYourAccountThree',
        },
        {
          image: isJPLanguage ? mobileChooseBusinessAccountJP : mobileChooseBusinessAccount,
          title: 'Tutorial.Text.MobileRegisterYourAccountFour',
        },
        {
          image: isJPLanguage ? mobileSelectFBPagesJP : mobileSelectFBPages,
          title: 'Tutorial.Text.MobileRegisterYourAccountFive',
        },
      ]
    : [
        { image: isJPLanguage ? editProfileJP : editProfile, title: 'Tutorial.Text.RegisterYourAccountOne' },
        {
          image: isJPLanguage ? switchProfessionalAccountJP : switchProfessionalAccount,
          title: 'Tutorial.Text.RegisterYourAccountTwo',
        },
        {
          image: isJPLanguage ? chooseBusinessAccountJP : chooseBusinessAccount,
          title: 'Tutorial.Text.RegisterYourAccountThree',
        },
        {
          image: isJPLanguage ? clickNextCompletionJP : clickNextCompletion,
          title: 'Tutorial.Text.RegisterYourAccountFour',
        },
      ];

  useEffect(() => {
    setCurrStep(0);
  }, [isMobileViewPublicRoutes]);

  function onClickContact() {
    window.open(isJPLanguage ? 'https://any-creator.com/ja/contact/' : 'https://any-creator.com/contact/', '_blank');
  }

  return (
    <div css={styles.container}>
      <div css={styles.contentContainer}>
        <div>
          <div css={styles.backArrowContainer}>
            <Icon color="#3892e5" icon="arrow_back" onClick={() => history.push(backUrl)} />
            {isMobileViewPublicRoutes ? (
              <img alt="logo" src={anyCreatorBlack} width="165" />
            ) : (
              <div css={styles.contentTitle}>
                {failedConnect ? (
                  <>
                    <img alt="icon" height="18" src={switchImage(SocialAccountType.INSTAGRAM, true)} width="18" />
                    <span>{t(`Title.Instagram couldn't be found`)}</span>
                  </>
                ) : (
                  <span>{t('Title.Before you link Instagram')}</span>
                )}
              </div>
            )}
          </div>
          {isMobileViewPublicRoutes && (
            <div css={styles.contentTitle}>
              {failedConnect ? (
                <>
                  <img alt="icon" height="18" src={switchImage(SocialAccountType.INSTAGRAM, true)} width="18" />
                  <span>{t(`Title.Instagram couldn't be found`)}</span>
                </>
              ) : (
                <span>{t('Title.Before you link Instagram')}</span>
              )}
            </div>
          )}
          <div css={styles.contentDescription}>{t(`Annotation.Instagram couldn't be found`)}</div>
          <div css={styles.stepTitle}>{t('Tutorial.Heading.RegisterYourAccount')}</div>

          <div css={styles.stepsContainer}>
            {registerAccountSteps.map((step, index) => (
              <div css={styles.stepContainer} key={index}>
                <div>{index + 1}</div>
                <div>{t(step.title)}</div>
              </div>
            ))}
          </div>

          {isMobileViewPublicRoutes && (
            <ImgViewerContainer>
              <div>
                <div>
                  <div>
                    {currStep !== 0 && (
                      <ImgViewerButton css={styles.imageViewerBtnLeft} onClick={() => setCurrStep(step => step - 1)}>
                        <Icon color="#27313b" icon="chevron_left" />
                      </ImgViewerButton>
                    )}
                    <img alt="step" height="308" src={registerAccountSteps[currStep]?.image} width="231" />
                    {currStep < registerAccountSteps.length - 1 && (
                      <ImgViewerButton css={styles.imageViewerBtnRight} onClick={() => setCurrStep(step => step + 1)}>
                        <Icon color="#27313b" icon="chevron_right" />
                      </ImgViewerButton>
                    )}
                  </div>
                </div>
                <div>{`${currStep + 1}. ${t(registerAccountSteps[currStep]?.title)}`}</div>
              </div>
            </ImgViewerContainer>
          )}

          <StyledButton
            bgcolor="#3892e5"
            color="#fff"
            disabled={disabled}
            hoverbgcolor="#3892e5"
            title={failedConnect ? 'Try it Again' : 'Connect'}
            onClick={onClick}
          />
          <StyledButton bgcolor="#fff" color="#6e7c89" hoverbgcolor="#fff" title="Contact" onClick={onClickContact} />
        </div>
      </div>

      {!isMobileViewPublicRoutes && (
        <ImgViewerContainer>
          <div>
            <div>
              <div>
                {currStep !== 0 && (
                  <ImgViewerButton css={styles.imageViewerBtnLeft} onClick={() => setCurrStep(step => step - 1)}>
                    <Icon color="#27313b" icon="chevron_left" />
                  </ImgViewerButton>
                )}
                <img alt="step" height="195" src={registerAccountSteps[currStep]?.image} width="348" />
                {currStep < registerAccountSteps.length - 1 && (
                  <ImgViewerButton css={styles.imageViewerBtnRight} onClick={() => setCurrStep(step => step + 1)}>
                    <Icon color="#27313b" icon="chevron_right" />
                  </ImgViewerButton>
                )}
              </div>
            </div>
            <div>{`${currStep + 1}. ${t(registerAccountSteps[currStep]?.title)}`}</div>
          </div>
        </ImgViewerContainer>
      )}
    </div>
  );
};

const ImgViewerButton = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  top: 80px;
  width: 40px;

  & > i {
    font-size: 24px;
  }

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    top: 140px;
  }
`;

const ImgViewerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-basis: 50%;
  justify-content: center;
  margin-bottom: 24px;

  & > div {
    & > div:nth-of-type(1) {
      display: flex;
      flex-basis: 100%;
      justify-content: center;
      margin-bottom: 16px;

      & > div {
        position: relative;
      }
    }

    /* stylelint-disable no-descending-specificity */
    & > div:nth-of-type(2) {
      color: #27313b;
      display: flex;
      flex-basis: 100%;
      font-size: 14px;
      font-weight: 600;
      justify-content: center;

      @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
        justify-content: flex-start;
      }
    }
  }
`;

const StyledButton = styled(Button)`
  border: 1px solid #dee5ec;
  border-radius: 3px;
  filter: brightness(1);
  font-weight: 600;
  height: 32px;
  margin-bottom: 16px;
  transition: 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    height: 40px;

    &:hover {
      filter: brightness(1);
    }
  }
`;

const styles = {
  backArrowContainer: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 24px 0;
    min-height: 24px;
    position: relative;

    & > i {
      left: 0;
      position: absolute;
      top: 8px;
    }
  `,
  container: css`
    display: flex;
    flex-wrap: wrap;
    min-height: 520px;
    padding: 0 40px;

    @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
      padding: 0 24px;
    }
  `,
  contentContainer: css`
    align-items: center;
    display: flex;
    flex-basis: 50%;
    justify-content: center;

    @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
      flex-basis: 100%;
    }
  `,
  contentDescription: css`
    color: #27313b;
    font-size: 14px;
    margin-bottom: 24px;
  `,
  contentTitle: css`
    align-items: center;
    color: #27313b;
    display: flex;
    flex-wrap: wrap;
    font-size: 18px;
    font-weight: 600;
    justify-content: center;
    margin-bottom: 24px;

    & > img {
      margin-right: 8px;
    }
  `,
  imageViewerBtnLeft: css`
    left: -16px;
  `,
  imageViewerBtnRight: css`
    right: -16px;
  `,
  stepContainer: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;

    & > div:nth-of-type(1) {
      align-items: center;
      background-color: #000;
      border-radius: 50%;
      color: #fff;
      display: flex;
      height: 24px;
      justify-content: center;
      margin-right: 8px;
      width: 24px;
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: 14px;
      font-weight: 600;
      width: 90%;
    }
  `,
  stepsContainer: css`
    margin-bottom: 24px;
  `,
  stepTitle: css`
    color: #27313b;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
  `,
};

export default InstagramInstruction;
