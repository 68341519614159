import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { PalleteButton } from '../../components/atoms/Button';
import { mainWhite, mainBlue } from '../../libs/pallete';
import { DialogModal } from '../../components/molecules/DialogModal';
import { getSocialMediaUrlByCampaignType } from '../../libs/campaign';
import { CampaignSocialMediaType, SocialAccountType } from '../../__generated__/globalTypes';
import hashtagIcon from '../../assets/icon/hashtag.svg';
import timeSchedule from '../../assets/icon/time-schedule.svg';
import HashTags from '../../components/molecules/HashTags';
import { useCopy } from '../../libs/hooks/useCopy';
import { ListIndicator } from '../../components/molecules/Indicator';
import SocialAccount from '../../components/molecules/SocialAccount';
import userpic from '../../assets/icon/userpic.svg';
import { MarketplaceJoinedAccount, MarketplaceJoinedAccountVariables } from './__generated__/MarketplaceJoinedAccount';
import * as MARKETPLACE_JOINED_ACCOUNT from './MarketplaceJoinedAccount.graphql';

interface CopyHashtagsProps {
  socialMedia: CampaignSocialMediaType;
  isOpen: boolean;
  closeModal: () => void;
  hashtags?: string[];
  campaignId: number | null;
}
export const CopyHashtagModal = (props: CopyHashtagsProps) => {
  const { t } = useTranslation();
  const { socialMedia, isOpen, closeModal, hashtags, campaignId } = props;
  // Copy HashTags
  const { handleCopyHasHashtag, isCopying, isCopied } = useCopy();

  const { data, loading } = useQuery<MarketplaceJoinedAccount, MarketplaceJoinedAccountVariables>(
    MARKETPLACE_JOINED_ACCOUNT,
    { variables: { campaignId: Number(campaignId) }, fetchPolicy: 'cache-and-network', skip: !isOpen }
  );

  return (
    <DialogModal
      isOpen={isOpen}
      closeModal={closeModal}
      BackButton={<PalleteButton text="Close" palleteColor={mainWhite} onClick={closeModal} width="136px" />}
      NextButton={
        <PalleteButton
          text="Post Now"
          palleteColor={mainBlue}
          href={getSocialMediaUrlByCampaignType(socialMedia)}
          isDisabled={!isCopied}
          width="136px"
          targetBlank
          callback={closeModal}
        />
      }
    >
      <ContentWrapper>
        <MainTitle>{t('Precautions before posting')}</MainTitle>
        {loading ? (
          <ListIndicator border={false} />
        ) : (
          <>
            <StyledTitleWrapper>
              <Userpic src={userpic} />
              <Title>{t('Account you will post from')}</Title>
            </StyledTitleWrapper>
            <PostedAccountSection>
              <SocialAccount
                username={data?.marketplaceJoinedAccount?.username || ''}
                socialMedia={data?.marketplaceJoinedAccount?.socialAccountType as SocialAccountType}
                src={data?.marketplaceJoinedAccount?.avatar || ''}
                followers={data?.marketplaceJoinedAccount?.followersCount || null}
              />
            </PostedAccountSection>
          </>
        )}
        <TitleWrapper>
          <StyledIcon src={hashtagIcon} alt="hashtag" />
          <Title>{t('Hashtags are a "MUST ITEM"')}</Title>
        </TitleWrapper>

        <CopyHashTagsSection>
          <Paragrapgh>{t('HashTag1')}</Paragrapgh>
          <StyledHashTags hashTags={hashtags} />
          <PalleteButton
            text="Copy HashTags"
            palleteColor={mainWhite}
            height="32px"
            onClick={() => handleCopyHasHashtag(hashtags)}
            isDisabled={isCopying}
          />
        </CopyHashTagsSection>
        <TitleWrapper>
          <StyledIcon src={timeSchedule} />
          <Title>{t('Post reflection time')}</Title>
        </TitleWrapper>
        <Paragrapgh css={marginBottom19}>{t('HashTag4')}</Paragrapgh>
      </ContentWrapper>
    </DialogModal>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 8px 0;
  flex: 1;
  color: #27313b;
`;
const MainTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 8px;
  border-bottom: 1px dashed #dee5ec;
  flex: 1;
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 19px 0 11px;
`;
const StyledIcon = styled.img`
  margin-right: 12px;
`;
const Title = styled.h3`
  font-size: 14px;
  font-weight: bold;
`;
const Paragrapgh = styled.p`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  flex: 1;
`;
const StyledHashTags = styled(HashTags)`
  margin-top: 16px;
`;
const CopyHashTagsSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 24px;
  border-bottom: 1px dashed #dee5ec;
`;
const PostedAccountSection = styled.div`
  display: flex;
  padding: 12px 0;
  border-bottom: 1px dashed #dee5ec;
`;
const Userpic = styled.img`
  padding-right: 10px;
`;
const StyledTitleWrapper = styled(TitleWrapper)`
  margin: 12px 0 0;
`;

const marginBottom19 = css`
  margin-bottom: 19px;
`;
