import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CampaignType } from '../../../../__generated__/globalTypes';

interface CampaignCardPriceItemProps {
  value: string;
  currency: string;
  unit: string;
  campaignType: CampaignType;
}

const CampaignCardPriceItem = (props: CampaignCardPriceItemProps) => {
  const { t } = useTranslation();
  const { value, currency, unit, campaignType } = props;
  const isMarketplaceCampaign = campaignType === CampaignType.MARKETPLACE;

  return (
    <Wrapper>
      <Value>{value}</Value>
      <Unit>
        {t(currency)}&nbsp;{isMarketplaceCampaign && <>/&nbsp;{t(`RevenuePer.${unit}`)}</>}
      </Unit>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`;

const Value = styled.p`
  margin-right: 4px;
  font-weight: 600;
  color: #27313b;
  font-size: 13px;
`;

const Unit = styled.p`
  line-height: 1.18;
  font-size: 11px;
  color: #6e7c89;
`;

export default CampaignCardPriceItem;
