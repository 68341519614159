import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import noJobs from '../../../assets/icon/noJobs.svg';

const EmptySearchJobList = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ImageWrapper>
        <img src={noJobs} />
        <Count>0</Count>
      </ImageWrapper>
      <span>{t('Annotation.Empty Search Job Page')}</span>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const ImageWrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
`;

const Count = styled.i`
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  position: absolute;
  line-height: 0.75;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
`;

export default EmptySearchJobList;
