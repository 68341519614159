import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { bigIntFormatter } from '@src/libs/format';
import * as StyledComponents from '@src/components/organisms/AnalyticsYoutube/StyledComponents';
import { css } from '@emotion/react';
import { usePostDetailModal } from '@src/components/organisms/AnalyticsShared/BasePostList/usePostDetailModal';
import ImageSlider from '@src/components/molecules/Slider';
import DataOpacityWrapper from '@src/components/molecules/DataCard/DataOpacityWrapper';
import CarouselModal from '@src/components/molecules/CarouselModal/CarouselModal';
import InfluencerPostDetail from '@src/components/organisms/AnalyticsAccounts/DefaultProfile/InfluencerPostDetail';
import { InfluencerTikTokProfilePosts_influencerProfilePosts_feedPosts } from '../__generated__/InfluencerTikTokProfilePosts';

const slideWidth = 200;

interface LikeCommentPostsProps {
  posts?: InfluencerTikTokProfilePosts_influencerProfilePosts_feedPosts[] | null;
  header: ReactElement;
  className?: string;
  moreLink?: string;
  influencerAccountId: number;
}
const TikTokFeedPosts = (props: LikeCommentPostsProps) => {
  const { t } = useTranslation();
  const { posts, header, className, influencerAccountId } = props;

  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev,
  } = usePostDetailModal(posts || []);

  return (
    <>
      <StyledOpacityWrapper header={header} dataNotAvailable={!posts?.length}>
        <MainWrapper className={className}>
          {!!posts?.length && (
            <>
              <ImageSlider data={posts} slideWidth={slideWidth}>
                {posts.map(post => (
                  <StyledComponents.VideoContainerWrapper key={post.id} css={styles.videoContainerWrapper}>
                    <StyledComponents.VideoWrapper onClick={() => handleClickPost(post.id)} css={styles.videoWrapper}>
                      <StyledComponents.VideoThumbnail src={post.thumbNail} />
                    </StyledComponents.VideoWrapper>
                    <StyledComponents.EngagementInfo css={styles.engagementInfo}>
                      <StyledComponents.InfoWrapper>
                        <StyledComponents.Label css={styles.labelFont}>{t('Like')}</StyledComponents.Label>
                        <StyledComponents.Count title={`${post.likes}`}>
                          {bigIntFormatter(post.likes, 0, { k: true, m: true })}
                        </StyledComponents.Count>
                      </StyledComponents.InfoWrapper>
                      <StyledComponents.Delimeter />
                      <StyledComponents.InfoWrapper>
                        <StyledComponents.Label css={styles.labelFont}>{t('View')}</StyledComponents.Label>
                        <StyledComponents.Count title={`${post.views}`}>
                          {bigIntFormatter(post.views, 0, { k: true, m: true })}
                        </StyledComponents.Count>
                      </StyledComponents.InfoWrapper>
                      <StyledComponents.Delimeter />
                      <StyledComponents.InfoWrapper>
                        <StyledComponents.Label css={styles.labelFont}>{t('Comment')}</StyledComponents.Label>
                        <StyledComponents.Count title={`${post.comments}`}>
                          {bigIntFormatter(post.comments, 0, { k: true, m: true })}
                        </StyledComponents.Count>
                      </StyledComponents.InfoWrapper>
                      <StyledComponents.Delimeter />
                      <StyledComponents.InfoWrapper>
                        <StyledComponents.Label css={styles.labelFont}>{t('Share')}</StyledComponents.Label>
                        <StyledComponents.Count title={`${post.shares}`}>
                          {bigIntFormatter(post.shares, 0, { k: true, m: true })}
                        </StyledComponents.Count>
                      </StyledComponents.InfoWrapper>
                    </StyledComponents.EngagementInfo>
                  </StyledComponents.VideoContainerWrapper>
                ))}
              </ImageSlider>
            </>
          )}
        </MainWrapper>
      </StyledOpacityWrapper>
      <CarouselModal
        open={!!openPostDetail}
        title=""
        cancel="OK"
        onClose={handleClosePostDetail}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        siblingPostId={siblingPostId}
      >
        <InfluencerPostDetail influencerId={influencerAccountId} closeModal={handleClosePostDetail} />
      </CarouselModal>
    </>
  );
};

const MainWrapper = styled.div`
  padding-left: 16px;
  padding-bottom: 16px;
`;
const StyledOpacityWrapper = styled(DataOpacityWrapper)`
  min-height: 200px;
`;
const styles = {
  videoContainerWrapper: css`
    width: ${slideWidth}px !important;
    height: 140px;
  `,
  videoWrapper: css`
    cursor: pointer;
    height: 70%;
  `,
  engagementInfo: css`
    column-gap: 2px;
  `,
  labelFont: css`
    font-size: 10px;
  `,
};

export default TikTokFeedPosts;
