import styled from '@emotion/styled';
import * as React from 'react';

interface OperationButtonProps extends StyleProps {
  className?: string;
  type?: 'reset' | 'button' | 'submit';
  title: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const OperationButton = (props: OperationButtonProps) => {
  const { className, title, disabled, onClick } = props;
  const type = !!props.type ? props.type : 'button';

  return (
    <StyledButton type={type} className={className} disabled={disabled} onClick={onClick}>
      {title}
    </StyledButton>
  );
};

interface StyleProps {
  disabled?: boolean;
}

const StyledButton = styled.button<StyleProps>`
  box-sizing: border-box;
  width: 100%;
  background-color: #efefef;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  color: #9b9b9b;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  font-size: 12px;
  letter-spacing: 0.03em;
  line-height: 30px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s ease-out;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};

  &:hover {
    background-color: #dbdbdb;

    @media (max-width: 450px) {
      background-color: #efefef;
    }
  }
`;

export default OperationButton;
