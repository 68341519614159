import React, { ReactNode } from 'react';
import { formatIntNumber } from '@src/libs/format';
import { isVideoThumbnail } from '@src/libs/image';
import { useQueryHelper } from '@src/libs/hooks';
import InfluencerPostDetail from '@src/components/organisms/AnalyticsAccounts/DefaultProfile/InfluencerPostDetail';
import { usePostDetailModal } from '@src/components/organisms/AnalyticsShared/BasePostList/usePostDetailModal';
import CarouselModal from '@src/components/molecules/CarouselModal/CarouselModal';
import DataOpacityWrapper from '@src/components/molecules/DataCard/DataOpacityWrapper';
import {
  Count,
  EngagementInfo,
  InfoWrapper,
  Label,
  PostImage,
  PostImageWrapper,
  Sizer,
} from '@src/components/organisms/AnalyticsShared/PostDetail/SliderStyledComponents';
import ImageSlider from '@src/components/molecules/Slider';
import VideoPreview from '@src/components/atoms/VideoPreview';
import { CampaignType } from '@src/__generated__/globalTypes';
import InfluencerEngagementPostDetail from '@src/components/organisms/AnalyticsAccounts/DefaultProfile/InfluencerPostDetail/EngagementPostDetails';
import InfluencerMarketplacePostDetail from '@src/components/organisms/AnalyticsAccounts/DefaultProfile/InfluencerPostDetail/MarketplacePostDetails';
import { SponsoredPosts_sponsoredPosts } from './__generated__/SponsoredPosts';

interface TemplateProps {
  header?: ReactNode;
  sponsoredPosts: SponsoredPosts_sponsoredPosts['posts'];
  influencerId: number;
  className?: string;
}
const AnalyticsSponsoredPostTemplate = ({ header, sponsoredPosts, influencerId, className }: TemplateProps) => {
  const { t } = useQueryHelper();

  const {
    handleClickNext,
    handleClickPost,
    handleClickPrev,
    handleClosePostDetail,
    openPostDetail,
    siblingPostId,
  } = usePostDetailModal(sponsoredPosts);

  const selectedPost = sponsoredPosts.find(p => p.id === openPostDetail);

  return sponsoredPosts.length > 0 ? (
    <div css={{ borderBottom: '1px solid #E6ECF0' }} className={className}>
      <CarouselModal
        cancel="OK"
        open={!!openPostDetail}
        siblingPostId={siblingPostId}
        title=""
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        onClose={handleClosePostDetail}
      >
        {selectedPost?.campaignType === CampaignType.ENGAGEMENT ? (
          <InfluencerEngagementPostDetail closeModal={handleClosePostDetail} />
        ) : selectedPost?.campaignType === CampaignType.MARKETPLACE ? (
          <InfluencerMarketplacePostDetail closeModal={handleClosePostDetail} />
        ) : (
          <InfluencerPostDetail closeModal={handleClosePostDetail} influencerId={influencerId} />
        )}
      </CarouselModal>
      <DataOpacityWrapper header={header}>
        <div css={{ padding: '0 16px 16px 16px' }}>
          <ImageSlider data={sponsoredPosts}>
            {sponsoredPosts.map((post, index) => (
              <PostImageWrapper key={index} onClick={() => handleClickPost(post.id)}>
                <Sizer>
                  {isVideoThumbnail(post.thumbNail) ? (
                    <VideoPreview url={post.thumbNail} />
                  ) : (
                    <PostImage src={post.thumbNail} />
                  )}
                  <EngagementInfo>
                    <InfoWrapper>
                      <Label>{t('Like')}</Label>
                      <Count>{formatIntNumber(post.likes)}</Count>
                    </InfoWrapper>
                    <InfoWrapper>
                      <Label>{t('General.Comments')}</Label>
                      <Count>{formatIntNumber(post.comments)}</Count>
                    </InfoWrapper>
                  </EngagementInfo>
                </Sizer>
              </PostImageWrapper>
            ))}
          </ImageSlider>
        </div>
      </DataOpacityWrapper>
    </div>
  ) : null;
};

export default AnalyticsSponsoredPostTemplate;
