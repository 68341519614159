import React from 'react';
import { Redirect } from 'react-router';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import StoryDetail from '@src/components/organisms/StoryDetail';
import { StoryDetail as StoryDetailType } from '@src/components/organisms/StoryDetail/types';

interface TemplateProps {
  post?: StoryDetailType | null;
  accountType?: SocialAccountType;
}

const Template = (props: TemplateProps) => {
  const { post } = props;

  if (!post) {
    return <Redirect to="/404" />;
  }

  return <StoryDetail post={post} />;
};

export default Template;
