import React from 'react';
import { Global, css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import HighChart, { Highcharts } from '@src/components/molecules/HighChart';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { localizedWeekdays, hourIntervals } from '@src/libs/date';
import DataOpacityWrapper, { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import { FanActivityHistory } from '../../types';
import { minColor, maxColor } from './PostingHabitsChartConfig';

const FanActivity = ({
  fanActivity,
  className,
}: {
  fanActivity: FanActivityHistory[];
  showHeader?: boolean;
  className?: string;
}) => {
  const { t, i18n } = useTranslation();

  const chartData = !fanActivity.every(data => data.onlineCount === 0)
    ? fanActivity?.map(item => ({
        x: item.hour,
        y: item.weekday,
        value: item.onlineCount,
      }))
    : [];

  const options: Highcharts.Options = {
    chart: {
      type: 'heatmap',
      marginTop: 0,
      inverted: true,
      height: 350,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: hourIntervals.map(item => item.replace(/\s/g, '')),
      min: 0,
      gridLineColor: 'transparent',
      lineColor: '#FFFFFF',
      opposite: true,
      offset: 35,
      labels: {
        x: 0,
        align: 'right',
        style: {
          fontSize: '11px',
        },
      },
    },
    yAxis: {
      categories: localizedWeekdays(i18n.language as AppLanguage).map(item => item.slice(0, 3)),
      title: {
        text: '',
      },
      min: 0,
      gridLineColor: 'transparent',
      lineColor: '#FFFFFF',
      lineWidth: 2,
      labels: {
        autoRotation: false,
        style: {
          fontSize: '10px',
        },
      },
    },
    colorAxis: {
      min: 0,
      minColor,
      maxColor,
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        type: 'heatmap',
        pointPadding: 2,
        borderColor: 'transparent',
        name: 'Fan Activity',
        data: chartData,
        dataLabels: {
          enabled: false,
        },
      },
    ],
    tooltip: {
      padding: 0,
      useHTML: true,
      borderColor: '#27313b',
      headerFormat: '',
      borderWidth: 0,
      borderRadius: 3,
      pointFormat: `
        <div style="border-radius:3px;box-shadow: 0 3px 10px 0 rgba(39, 49, 59, 0.59);background-color:#27313b;padding:16px">
  
        <h1 style="font-size:13px;color: #ffffff;margin-bottom:8px">{point.time}  </h1>
   
        <div style="display:flex">
            <div style="border-left:1px solid #39444f;padding-left:4px">
              <p style="color:#c5d0da;font-size:11px;margin-bottom:2px">${t('Online Count')}</p>
              <p style="color:#ffffff;font-size:13px">{point.value}</p>
            </div>
          </div>`,
      footerFormat: '</div>',
    },
  };

  return (
    <DataOpacityWrapper
      header={<SectionHeader>{t('Fan Activity')}</SectionHeader>}
      dataNotAvailable={!fanActivity || fanActivity.every(data => data.onlineCount === 0)}
      className={className}
    >
      {/* Setting tooltip arrow color */}
      <Global
        styles={css`
          .highcharts-tooltip path {
            fill: #27313b;
          }
        `}
      />
      <HighChart options={options} />
    </DataOpacityWrapper>
  );
};

export default FanActivity;
