import React from 'react';
import { useMutation } from '@apollo/client';
import { Formik, FormikHelpers } from 'formik';
import { ApolloError } from '@apollo/client';
import { SignInCredentialSchema } from '../../../libs/validation';
import { UNEXPECTED_ERROR } from '../../../libs/error';
import { useQueryHelper } from '../../../libs/hooks';
import { removeToken, setToken } from '../../../libs/auth';
import { useAuthSetup } from '../AuthSetUp/useAuthSetup';
import BaseSignInForm from './SignInForm';
import { FormValues } from './types';
import { TokenAuth } from './__generated__/TokenAuth';
import * as TOKEN_AUTH from './TokenAuth.graphql';

const SignInEmailForm = () => {
  const [doMutation] = useMutation<TokenAuth>(TOKEN_AUTH);
  const { t, enqueueSnackbar, history } = useQueryHelper();
  const { setUp } = useAuthSetup();
  const handleSubmit = async (values: FormValues, { setSubmitting, setStatus }: FormikHelpers<FormValues>) => {
    const variables = {
      input: values,
    };
    setSubmitting(true);
    await doMutation({ variables })
      .then(async result => {
        if (!!result?.data?.tokenAuth) {
          const { token, refreshToken } = result.data.tokenAuth;
          setToken(token, refreshToken);
          try {
            await setUp(token);
            if (window.opener) {
              // TODO: consider for Iframe users
              window.opener.postMessage({ redirectPath: '/profile' }, '*');
              window.close();

              return;
            }
            history.push('/home');
          } catch (e) {
            removeToken();
            console.error(e);
            enqueueSnackbar(t(e.message), { variant: 'error' });
            if (window.opener) {
              window.opener.postMessage({ redirectPath: '/', errorMsg: e.message }, '*');
              window.close();

              return;
            }
            history.push('/');
          }
        } else {
          if (window.opener) {
            window.opener.postMessage({ redirectPath: '/', errorMsg: UNEXPECTED_ERROR }, '*');
            window.close();

            return;
          }
          setStatus(t(UNEXPECTED_ERROR));
        }
      })
      .catch((error: ApolloError) => {
        const graphQLerrors = error.graphQLErrors;
        if (graphQLerrors.length > 0 && ['NOT_FOUND', 'LOGIN_FAILED'].includes(graphQLerrors[0].message)) {
          setStatus(t('loginFailureMessage'));
        } else {
          setStatus(t(UNEXPECTED_ERROR));
        }
      });
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={SignInCredentialSchema}
    >
      <BaseSignInForm />
    </Formik>
  );
};

export default SignInEmailForm;
