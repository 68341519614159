import React from 'react';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import PostDetail from '@src/components/organisms/AnalyticsShared/PostDetail';
import { InstagramAnalyticsPostDetails } from './__generated__/InstagramAnalyticsPostDetails';

interface TemplateProps {
  closeModal: () => void;
  data?: InstagramAnalyticsPostDetails['analyticsPostDetails'];
  accountType?: SocialAccountType;
  hasViewColumn?: boolean;
}

const Template = (props: TemplateProps) => {
  const { data, closeModal, hasViewColumn } = props;
  const accountType = props.accountType || SocialAccountType.INSTAGRAM;

  if (!data) {
    closeModal();

    return null;
  }

  const {
    avatarUrl,
    instagramAccountName,
    followers,
    content,
    commentList,
    mediaUrls,
    postUrl,
    analysis,
    sentiment,
  } = data;

  return (
    <PostDetail
      avatarUrl={avatarUrl}
      accountType={accountType}
      accountName={instagramAccountName}
      followers={followers || 0}
      content={content}
      commentList={commentList || []}
      mediaUrls={mediaUrls}
      engagement={{
        postUrl,
        analysis,
        sentiment,
        hasViewColumn,
        storyData: null,
      }}
    />
  );
};

export default Template;
