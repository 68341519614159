import { useTranslation } from 'react-i18next';
import userpicIcon from '@src/assets/icon/userpic_stats.svg';
import pictureIcon from '@src/assets/icon/picture.svg';
import thunderIcon from '@src/assets/icon/thunder.svg';
import heartIcon from '@src/assets/icon/heart.svg';
import chatIcon from '@src/assets/icon/chat_green.svg';
import bookmarkIcon from '@src/assets/icon/bookmark.svg';
import impressionsIcon from '@src/assets/icon/impressions.svg';
import profileViewsIcon from '@src/assets/icon/profileViews.svg';
import websiteClicksIcon from '@src/assets/icon/websiteClicks.svg';
import { getGrowthArrow, getGrowthPrefix } from '@src/components/organisms/ProfileDetail/AnalyticsProfile/helpers';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { OverviewStats } from './types';
import {
  ChartColorTypes,
  getSeriesAndCategories,
  allSelectableCharts,
  getEngagementDataWithHistory,
  generateYAxisBasedOnDisplayedCharts,
} from './helpers';

export const getOptions = (
  displayedCharts: ChartColorTypes[],
  instagramDashboardOverview: OverviewStats,
  chosenSelectableCharts: ChartColorTypes[],
  fetchDailyPosts: (date: string) => void
): {
  options: Highcharts.Options;
  engagement: Array<{ icon: string; title: string; total: number; growth: number; rate: number }>;
} => {
  const { t, i18n } = useTranslation();
  const {
    followers,
    posts,
    reach,
    likes,
    comments,
    saved,
    impressions,
    profileViews,
    websiteClicks,
  } = instagramDashboardOverview;
  const { history: followersHistory, engagement: followersEngagement } = getEngagementDataWithHistory(followers);
  const { history: postsHistory, engagement: postsEngagement } = getEngagementDataWithHistory(posts);
  const { history: reachHistory, engagement: reachEngagement } = getEngagementDataWithHistory(reach);
  const { history: likesHistory, engagement: likesEngagement } = getEngagementDataWithHistory(likes);
  const { history: commentsHistory, engagement: commentsEngagement } = getEngagementDataWithHistory(comments);
  const { history: savedHistory, engagement: savedEngagement } = getEngagementDataWithHistory(saved);
  const { history: impressionsHistory, engagement: impressionsEngagement } = getEngagementDataWithHistory(impressions);
  const { history: profileViewsHistory, engagement: profileViewsEngagement } = getEngagementDataWithHistory(
    profileViews
  );
  const { history: websiteClicksHistory, engagement: websiteClicksEngagement } = getEngagementDataWithHistory(
    websiteClicks
  );

  // we are showing X-Axis dates based on displayed charts checkboxes
  const { series, categories, tooltipData } = getSeriesAndCategories({
    historyData: {
      followersHistory,
      postsHistory,
      reachHistory,
      likesHistory,
      commentsHistory,
      savedHistory,
      impressionsHistory,
      profileViewsHistory,
      websiteClicksHistory,
    },
    displayedCharts,
    fetchDailyPosts,
  });
  const yAxis = generateYAxisBasedOnDisplayedCharts<ChartColorTypes>(allSelectableCharts, false);

  return {
    engagement: [
      {
        icon: userpicIcon,
        title: 'Follower (Total)',
        ...followersEngagement,
      },

      { icon: pictureIcon, title: 'Post', ...postsEngagement },
      { icon: thunderIcon, title: 'Reach', ...reachEngagement },
      { icon: heartIcon, title: 'Like', ...likesEngagement },
      { icon: chatIcon, title: 'Comment', ...commentsEngagement },
      { icon: bookmarkIcon, title: 'Save', ...savedEngagement },
      ...(chosenSelectableCharts.includes('Impression')
        ? [{ icon: impressionsIcon, title: 'Impression', ...impressionsEngagement }]
        : []),
      ...(chosenSelectableCharts.includes('Profile views')
        ? [{ icon: profileViewsIcon, title: 'Profile views', ...profileViewsEngagement }]
        : []),
      ...(chosenSelectableCharts.includes('Website Click')
        ? [{ icon: websiteClicksIcon, title: 'Website Click', ...websiteClicksEngagement }]
        : []),
    ],
    options: {
      chart: {
        type: 'column',
        height: 250,
      },
      title: {
        text: '',
      },
      xAxis: {
        categories,
        crosshair: true,
        gridLineWidth: 1,
        type: 'category',
        labels: {
          formatter: function formatter() {
            return localizedDateFormatter(this.value, 'MMM do', i18n.language as AppLanguage);
          },
        },
      },
      yAxis,
      legend: {
        enabled: false,
      },
      tooltip: {
        padding: 10,
        useHTML: true,
        borderColor: '#27313b',
        borderWidth: 0,
        borderRadius: 3,
        headerFormat: '',
        outside: true,
        formatter: function formatter(): string {
          const followersIncrement = tooltipData[this.key].Follower.increment;
          const incrementArrow = getGrowthArrow(followersIncrement);
          const prefix = getGrowthPrefix(followersIncrement);

          const incrementSection = followersIncrement
            ? `<span style="color: ${
                followersIncrement > 0 ? '#40b87c' : '#ff2b52'
              };">${prefix} ${followersIncrement} ${incrementArrow}</span>`
            : '';

          const styleElement = (title: string, content: string): string => `
            <div style="box-sizing: border-box; flex: 33.33% 0 1; min-width: 100px; align-items: stretch; border-left: 1px solid #6e7c89; padding: 6px 2px;">
              <p style="color: #c5d0da; font-size: 11px;">${title}</p>
              <p style="font-size: 13px; color: #fff;">${content}</p>
            </div>
          `;

          const elements: { [key: string]: string } = {
            follower: styleElement(t('Follower'), `${tooltipData[this.key].Follower.value} ${incrementSection}`),
            post: styleElement(t('Post'), tooltipData[this.key].Post.value),
            reach: styleElement(t('Reach'), tooltipData[this.key].Reach.value),
            like: styleElement(t('Like'), tooltipData[this.key].Like.value),
            comment: styleElement(t('Comment'), tooltipData[this.key].Comment.value),
            save: styleElement(t('Save'), tooltipData[this.key].Save.value),
            impression: styleElement(t('Impression'), tooltipData[this.key].Impression.value),
            profileView: styleElement(t('Profile views'), tooltipData[this.key]['Profile views'].value),
            websiteClick: styleElement(t('Website Click'), tooltipData[this.key]['Website Click'].value),
          };

          return `
            <div style="border-radius:3px;border: 2px solid #27313b;margin:-2px;background-color:#27313b;font-size:12px;padding:13px 16px;">
              <h3 style="margin-bottom:8px; font-size: 13px; color: #fff;">${this.key}</h3>
              <div style="display:flex; flex-wrap: wrap; margin: -6px 2px; box-sizing: border-box; min-width: 300px">
                ${elements.follower}
                ${elements.post}
                ${elements.reach}
                ${elements.like}
                ${elements.comment}
                ${elements.save}
                ${elements.impression}
                ${elements.profileView}
                ${elements.websiteClick}
                </div>
              </div>
            </div>
          `;
        },
        pointFormat: '',
      },
      series,
    },
  };
};
