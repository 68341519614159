import { useQuery } from '@apollo/client';
import { LIMIT } from '@src/libs/constant';
import { getOffset, getPageInfo } from '@src/libs/paging';
import * as ALL_INSTAGRAM_TAGGED_POSTS from './AllInstagramTaggedPosts.graphql';
import * as ALL_INSTAGRAM_MENTIONED_POSTS from './AllInstagramMentionedPosts.graphql';
import { IgInteractionListProps } from './types';
import { AllInstagramTaggedPosts, AllInstagramTaggedPostsVariables } from './__generated__/AllInstagramTaggedPosts';
import {
  AllInstagramMentionedPosts,
  AllInstagramMentionedPostsVariables,
} from './__generated__/AllInstagramMentionedPosts';

export const useInteractionPostsQuery = ({
  accountId,
  currentPage,
  filter,
  sortData,
  isTaggedPosts,
}: IgInteractionListProps) => {
  const taggedPosts = useQuery<AllInstagramTaggedPosts, AllInstagramTaggedPostsVariables>(ALL_INSTAGRAM_TAGGED_POSTS, {
    variables: {
      socialAccountId: accountId,
      offset: getOffset(currentPage),
      limit: LIMIT,
      keyword: filter.keyword,
      orderBy: sortData.sort,
      startDate: filter.period.startDate,
      endDate: filter.period.endDate,
    },
    fetchPolicy: 'cache-and-network',
    skip: !isTaggedPosts,
  });
  const mentionedPosts = useQuery<AllInstagramMentionedPosts, AllInstagramMentionedPostsVariables>(
    ALL_INSTAGRAM_MENTIONED_POSTS,
    {
      variables: {
        socialAccountId: accountId,
        offset: getOffset(currentPage),
        limit: LIMIT,
        keyword: filter.keyword,
        orderBy: sortData.sort,
        startDate: filter.period.startDate,
        endDate: filter.period.endDate,
      },
      fetchPolicy: 'cache-and-network',
      skip: isTaggedPosts,
    }
  );

  const totalNumber =
    (isTaggedPosts
      ? taggedPosts.data?.getTotalNumber?.totalNumber
      : mentionedPosts.data?.getTotalNumber?.totalNumber) || 0;
  const pageInfo = getPageInfo(currentPage, totalNumber);

  return {
    posts:
      (isTaggedPosts
        ? taggedPosts.data?.influencerAllInstagramTaggedPosts
        : mentionedPosts.data?.influencerAllInstagramMentionedPosts) || [],
    pageInfo,
    totalNumber,
    loading: taggedPosts.loading || mentionedPosts.loading,
  };
};
