import React from 'react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { useQueryHelper } from '@src/libs/hooks';
import { AnalyticsTemplateProps } from '../AnalyticsShared/types';
import AudienceCard from './Dashboard/AudienceCard';
import PostCard from './Dashboard/PostCard';
import OverviewCard from './Dashboard/OverviewCard';
import YoutubeAnalyticsCompare from './Compare';

const AnalyticsYoutube = (props: AnalyticsTemplateProps) => {
  const { pathname } = useQueryHelper();

  const renderTabContent = () => {
    switch (pathname) {
      case '/analytics':
      case '/profile':
        return (
          <>
            <OverviewWrapper {...props} />
            <AudienceWrapper {...props} />
            <PostWrapper {...props} />
          </>
        );
      case '/analytics:compare':
      case '/profile:compare':
        return <YoutubeAnalyticsCompare id={props.selectedSocialMedia.id} period={props.selectedPeriod} />;

      default:
        return (
          <>
            <OverviewWrapper {...props} />
            <AudienceWrapper {...props} />
            <PostWrapper {...props} />
          </>
        );
    }
  };

  return renderTabContent();
};

const AudienceWrapper = styled(AudienceCard)`
  width: 100%;
  box-sizing: border-box;
  margin-top: 16px;
`;
const PostWrapper = styled(PostCard)`
  width: 100%;
  box-sizing: border-box;
  margin-top: 16px;
`;
const OverviewWrapper = styled(OverviewCard)`
  background-color: #fff;
  box-sizing: border-box;
  width: 100%;
`;
export const NavTab = styled(NavLink)`
  height: 45px;
  margin: 0 24px;
  line-height: 45px;
  cursor: pointer;
  border-bottom: 4px solid transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
  font-size: 14px;

  &:not(:first-of-type) {
    margin-left: 0;
  }

  &.selected {
    border-bottom-color: #3892e5;
    font-weight: 600;
    font-size: 14px;
    color: #27313b;
  }
`;
export const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  background: #fff;
  box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
  border-top: 1px solid #dee5ec;
`;

export default AnalyticsYoutube;
