import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTranslateCountry } from '@src/libs/hooks/useTranslateOptions';
import { useContainerQuery } from '@src/libs/hooks';
import { css } from '@emotion/react';
import { SocialAccountType, Genders, SocialAccountStatus } from '@src/__generated__/globalTypes';
import { formatGender, formatIntNumber, formatPercent } from '@src/libs/format';
import { switchText } from '@src/libs/SocialMedia';
import { ViewportType } from '@src/libs/theme';
import { LinkButton } from '@src/components/atoms/Button';
import More from '@src/components/molecules/More';
import SocialMediaLink from './SocialMediaLink';

const initialHeightOfOverflowContainer = 108;

export interface ProfileIntroProps {
  id: number;
  age: number | null;
  gender: Genders;
  country: string | null;
  region: string | null;
  categories: string[] | null;
  status: SocialAccountStatus;
  introduce: string | null;
  facebookFollowers: number | null;
  instagramFollowers: number | null;
  followersCount: number | null;
  twitterFollowers: number | null;
  tiktokFollowers: number | null;
  youtubeFollowers: number | null;
  avgEngagementRate: number | null;
  avgEngagementNumber: number | null;
  tags: string[];
  className?: string;
}

const ProfileIntro = (props: ProfileIntroProps) => {
  const { t } = useTranslation();
  const { tc } = useTranslateCountry();
  const { containerRef, isSmaller: hasGridLayout } = useContainerQuery({ hasWidthSmallerThan: 400 });

  const {
    age,
    gender,
    country,
    region,
    categories,
    introduce,
    facebookFollowers,
    instagramFollowers,
    twitterFollowers,
    tiktokFollowers,
    youtubeFollowers,
    followersCount,
    className,
    avgEngagementNumber,
    avgEngagementRate,
    tags,
  } = props;

  const countryRegion = country ? `${tc(country)} / ${region || '-'}` : null;
  const profileCategories = categories?.map(category => t(`Option.${category}`)).join(', ') || '-';

  return (
    <Container className={className}>
      <ProfileWrapper ref={containerRef}>
        <InfoBlock>
          <ProfileInfo>
            <BasicInfo>
              <InfoTitle>{t('BasicInfo.Age')}</InfoTitle>
              <InfoValue>{formatIntNumber(age)}</InfoValue>
            </BasicInfo>
            <BasicInfo>
              <InfoTitle>{t('BasicInfo.Gender')}</InfoTitle>
              <InfoValue>{t(formatGender(gender))}</InfoValue>
            </BasicInfo>
            {countryRegion && (
              <BasicInfo>
                <InfoTitle>{t('BasicInfo.Country / Region')}</InfoTitle>
                <InfoValue>{countryRegion}</InfoValue>
              </BasicInfo>
            )}
          </ProfileInfo>
          <BasicInfo>
            <InfoTitle>{t('BasicInfo.Categories')}</InfoTitle>
            <InfoValue>
              <More initialHeight={initialHeightOfOverflowContainer}>
                <p>{profileCategories}</p>
              </More>
            </InfoValue>
          </BasicInfo>
        </InfoBlock>

        {introduce && (
          <InfoBlock>
            <BlockTitle>{t('MenuTitle.Profile')}</BlockTitle>
            <InfoValue>
              <More initialHeight={initialHeightOfOverflowContainer}>{introduce}</More>
            </InfoValue>
          </InfoBlock>
        )}

        <InfoBlock>
          <SocialDataTitle>{t('Title.Social Data')}</SocialDataTitle>
          <CountWrapper>
            <TotalLabel>{t('Title.Total')}</TotalLabel>
            <Total>{formatIntNumber(followersCount)}</Total>
          </CountWrapper>

          <SocialLinkWrapper
            css={
              hasGridLayout
                ? css`
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 16px;
                  `
                : ''
            }
          >
            <SocialMediaLink
              mediaType={SocialAccountType.FACEBOOK}
              followersNumber={facebookFollowers}
              title={switchText(SocialAccountType.FACEBOOK)}
            />
            <SocialMediaLink
              mediaType={SocialAccountType.INSTAGRAM}
              followersNumber={instagramFollowers}
              title={switchText(SocialAccountType.INSTAGRAM)}
            />
            <SocialMediaLink
              mediaType={SocialAccountType.TWITTER}
              followersNumber={twitterFollowers}
              title={switchText(SocialAccountType.TWITTER)}
            />
            <SocialMediaLink
              mediaType={SocialAccountType.YOUTUBE}
              followersNumber={youtubeFollowers}
              title={switchText(SocialAccountType.YOUTUBE)}
            />
            <SocialMediaLink
              mediaType={SocialAccountType.TIKTOK}
              followersNumber={tiktokFollowers}
              title={switchText(SocialAccountType.TIKTOK)}
            />
          </SocialLinkWrapper>
        </InfoBlock>

        <InfoBlock>
          <SocialDataTitle>{t('Title.Engagement')}</SocialDataTitle>

          <EngagementWrapper>
            <EngagementContent>
              <EngagementNumber>{formatPercent(avgEngagementRate)}</EngagementNumber>
              <EngagementLabel>{t('HeaderColumn.Average Engagement Rate')}</EngagementLabel>
            </EngagementContent>
            <EngagementContent>
              <EngagementNumber>{formatIntNumber(avgEngagementNumber)}</EngagementNumber>
              <EngagementLabel>{t('HeaderColumn.Average Engagement Number')}</EngagementLabel>
            </EngagementContent>
          </EngagementWrapper>
        </InfoBlock>

        {tags.length > 0 && (
          <InfoBlock>
            <SocialDataTitle>{t('Title.Tags')}</SocialDataTitle>

            <More initialHeight={initialHeightOfOverflowContainer}>
              <HashtagWrapper>
                {tags.map(tag => (
                  <Hastag key={tag}>{tag}</Hastag>
                ))}
              </HashtagWrapper>
            </More>
          </InfoBlock>
        )}

        <ButtonWrapper>
          <EditButton href="/profile/settings/edit" title="Edit Profile" />
        </ButtonWrapper>
      </ProfileWrapper>
    </Container>
  );
};

const EngagementWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${ViewportType.SMALL}px) {
    flex-direction: row;
  }
`;

const EngagementNumber = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: #27313b;
  text-align: right;
  margin-bottom: 1px;

  @media (max-width: ${ViewportType.SMALL}px) {
    text-align: left;
    margin-bottom: 3px;
  }
`;

const EngagementLabel = styled.p`
  font-size: 13px;
  color: #6e7c89;
  text-align: right;

  @media (max-width: ${ViewportType.SMALL}px) {
    text-align: left;
  }
`;

const EngagementContent = styled.div`
  margin-bottom: 8px;

  @media (max-width: ${ViewportType.SMALL}px) {
    flex: 1;
    padding-right: 33px;

    &:last-of-type {
      position: relative;

      &::before {
        content: '';
        display: block;
        height: 54px;
        width: 1px;
        background-color: #eef3f7;
        position: absolute;
        left: -16px;
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 16px;
  justify-content: center;
`;

const CountWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
`;

const TotalLabel = styled.span`
  font-size: 13px;
  color: #6e7c89;
  margin-right: 13px;
`;

const Total = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: #27313b;
`;

const SocialDataTitle = styled.h6`
  font-size: 13px;
  font-weight: 600;
  color: #27313b;
  margin-bottom: 14px;
`;

const InfoBlock = styled.div`
  border-bottom: 1px solid #eef3f7;
  padding: 16px 0;
`;

const BlockTitle = styled.h6`
  font-size: 13px;
  font-weight: 600;
  color: #27313b;
  margin-bottom: 8px;
`;

const ProfileWrapper = styled.div`
  padding: 16px 24px 16px;
`;

const Container = styled.section`
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5);
  position: relative;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
`;

const SocialLinkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const BasicInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoValue = styled.span`
  color: #27313b;
  font-size: 13px;
  line-height: 1.38;
  word-break: break-all;
`;

const InfoTitle = styled.span`
  color: #27313b;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 8px;
`;

const ProfileInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  flex-wrap: wrap;
`;

const HashtagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
`;

const Hastag = styled.div`
  border-radius: 2px;
  background-color: #eef3f7;
  width: fit-content;
  padding: 2px;
  margin: 2px;
`;

const EditButton = styled(LinkButton)`
  width: auto;
  padding: 0 15px;
  background-color: #ff2b52;
  text-transform: uppercase;
  border-radius: 3px;
  line-height: 32px;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.5px;

  &:hover {
    background-color: #ff5575;
  }

  &:active {
    background-color: #cc2241;
  }
`;

export default ProfileIntro;
