import { useQueryHelper } from '@src/libs/hooks';
import { useMutation, useQuery } from '@apollo/client';
import { ApolloError } from '@apollo/client';
import * as ADDRESS_FOR_INFLUENCER from './AddressForInfluencer.graphql';
import * as UPDATE_ADDRESS from './UpdateAddress.graphql';
import { AddressForInfluencer } from './__generated__/AddressForInfluencer';
import { UpdateAddress, UpdateAddressVariables } from './__generated__/UpdateAddress';
import { FormValues } from './types';

export const useAddressInformation = () => {
  const { t, enqueueSnackbar } = useQueryHelper();

  const { data, loading } = useQuery<AddressForInfluencer>(ADDRESS_FOR_INFLUENCER, {
    fetchPolicy: 'no-cache',
    onError: (error: ApolloError) => {
      enqueueSnackbar(t(error.message || 'UnexpectedError'), { variant: 'error' });
    },
  });

  const addressInformation: FormValues = {
    fullName: data?.addressForInfluencer?.fullName || '',
    postalCode: data?.addressForInfluencer?.postalCode || '',
    province: data?.addressForInfluencer?.province || '',
    city: data?.addressForInfluencer?.city || '',
    address: data?.addressForInfluencer?.address || '',
    address2: data?.addressForInfluencer?.address2 || '',
  };

  const [updateAddress] = useMutation<UpdateAddress, UpdateAddressVariables>(UPDATE_ADDRESS);

  return {
    addressInformation,
    loading,
    updateAddress,
  };
};
