import { css } from '@emotion/react';

const customHeaderStyle = css`
  border-bottom: 1px solid #dee5ec;

  & > div {
    padding: 0;
    border-top-style: none;
    justify-content: flex-start;
  }
`;
const sentimentAnalysisColumn = css`
  flex: 1;
  border-top: 1px solid #dee5ec;

  & > section {
    padding-right: 16px;
    border-right: 1px solid #dee5ec;
  }
`;
const postHashtagColumn = css`
  flex: 1;
  border-top: 1px solid #dee5ec;
  padding-right: 0;

  & > div {
    padding-right: 16px;
  }
`;

const postHabitsColumn = css`
  flex: 1.2;
  border-right: none !important;
  border-top: 1px solid #dee5ec;
  padding-right: 0;

  & > div {
    height: 100%;
    padding-right: 16px;
  }
`;

const rightAlignedCol = css`
  td > div {
    justify-content: flex-end;
  }
`;

const hashtagTableCol = css`
  height: 42px;

  td:first-of-type {
    border-left: 1px solid #efefef;
  }

  td:last-of-type {
    border-right: 1px solid #efefef;
  }
`;
const tdPadding16 = css`
  td {
    padding: 16px 0 16px 16px;
  }
`;

const overflowText = css`
  div {
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const containerWrapper = css`
  & > div {
    border-bottom: none;
  }
`;

const accountStyledColumn = css`
  justify-content: flex-start;

  & > div {
    justify-content: flex-start;
    border-top: none;
  }
`;

export default {
  containerWrapper,
  customHeaderStyle,
  sentimentAnalysisColumn,
  postHashtagColumn,
  postHabitsColumn,
  accountStyledColumn,
  hashtagTableCol,
  tdPadding16,
  rightAlignedCol,
  overflowText,
};
