import * as React from 'react';
import { useQuery } from '@apollo/client';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { useQueryHelper } from '@src/libs/hooks';
import { BasePostFilterType } from '@src/pages/InstagramStoryOrFeedPosts/types';
import { useSort } from '@src/libs/filter';
import { InstagramStoryPostSortField } from '@src/__generated__/globalTypes';
import Template from './template';
import {
  InfluencerAllInstagramStoryPosts,
  InfluencerAllInstagramStoryPostsVariables,
} from './__generated__/InfluencerAllInstagramStoryPosts';
import * as INFLUENCER_ALL_INSTAGRAM_STORY_POSTS from './InfluencerAllInstagramStoryPosts.graphql';
import { storyPostVariables } from './utils';
import { getInstagramStoryPostSortFieldType } from './PostFeedList/helpers';

export interface StoryFeedProps {
  accountId: number;
  currentPage: number;
  filter: BasePostFilterType;
  setFilter: (filter: BasePostFilterType) => void;
}

const StoryFeed = (props: StoryFeedProps) => {
  const { t, history, enqueueSnackbar, search } = useQueryHelper();
  const sortData = useSort<InstagramStoryPostSortField>(getInstagramStoryPostSortFieldType);
  const { data, loading, error } = useQuery<
    InfluencerAllInstagramStoryPosts,
    InfluencerAllInstagramStoryPostsVariables
  >(INFLUENCER_ALL_INSTAGRAM_STORY_POSTS, { variables: storyPostVariables(props, sortData) });

  if (error) {
    history.push(`/analytics${search}`);
    enqueueSnackbar(t(error?.message || ''), { variant: 'error' });
  }

  if (loading || !data) {
    return <ListIndicator />;
  }

  return (
    <Template
      total={data?.getTotalNumber?.totalNumber || 0}
      posts={data?.allInstagramStoryPosts || []}
      currentPage={props.currentPage}
      filter={props.filter}
      setFilter={props.setFilter}
      accountId={props.accountId}
      sortData={sortData}
    />
  );
};

export default StoryFeed;
