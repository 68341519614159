import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import CarouselModal from '@src/components/molecules/CarouselModal/CarouselModal';
import { usePostDetailModal } from '@src/components/organisms/AnalyticsInstagram/Dashboard/PostCard/BasePostList/usePostDetailModal';
import { convertDurationToHhMmSs } from '@src/libs/date';
import { formatDate, formatIntNumber, formatPercent } from '@src/libs/format';
import { defaultEmptyImage } from '@src/libs/image';
import AnalyticsPostDetail from '../../AnalyticsPostDetail';

interface Video {
  id: number;
  postDate: any;
  views: any;
  likes: any;
  dislikes: any;
  comments: any;
  shares: any;
  positiveRate: number;
  negativeRate: number;
  videoDuration: number;
  videoTitle: string;
  videoId: string;
  videoThumbnail: string | null;
  averageViewDuration: any | null;
  averageViewPercentage: number | null;
}

interface FeedVideosListProps {
  videos: Video[];
  accountId: number;
  totalCount: number;
  avatarUrl?: string;
  accountName?: string;
}

const FeedVideosList = (props: FeedVideosListProps) => {
  const { t } = useTranslation();
  const { videos, accountId, totalCount, accountName, avatarUrl } = props;

  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev,
  } = usePostDetailModal(videos);

  return (
    <>
      <PostCount>{`${totalCount} ${t('Posts Found')}`}</PostCount>
      {videos.map(video => (
        <PostWrapper key={video.videoId}>
          <PostHeader onClick={() => handleClickPost(video.id)}>
            <PostImage src={defaultEmptyImage(video.videoThumbnail)} />
            <PostContent>{video.videoTitle}</PostContent>
          </PostHeader>
          <PostInfo>
            <Info>
              <Unit>{t('Views')}</Unit>
              <Value>{formatIntNumber(video.views)}</Value>
            </Info>
            <Info>
              <Unit>{t('Play Time')}</Unit>
              <Value>{convertDurationToHhMmSs(video.videoDuration)}</Value>
            </Info>
            <Info>
              <Unit>{t('Average View Duration')}</Unit>
              <Value>{convertDurationToHhMmSs(video.averageViewDuration)}</Value>
            </Info>
            <Info>
              <Unit>{t('Average View Duration Rate')}</Unit>
              <Value>{formatPercent(video.averageViewPercentage)}</Value>
            </Info>
            <Info>
              <Unit>{t('Like')}</Unit>
              <Value>{formatIntNumber(video.likes)}</Value>
            </Info>
            <Info>
              <Unit>{t('Dislike')}</Unit>
              <Value>{formatIntNumber(video.dislikes)}</Value>
            </Info>
            <Info>
              <Unit>{t('Comment')}</Unit>
              <Value>{formatIntNumber(video.comments)}</Value>
            </Info>
            <Info>
              <Unit>{t('Positive Rate')}</Unit>
              <Value>{formatPercent(video.positiveRate)}</Value>
            </Info>
            <Info>
              <Unit>{t('Negative Rate')}</Unit>
              <Value>{formatPercent(video.negativeRate)}</Value>
            </Info>
            <Info>
              <Unit>{t('Share')}</Unit>
              <Value>{formatIntNumber(video.shares)}</Value>
            </Info>
            <Info>
              <Unit>{t('Post Date')}</Unit>
              <Value>{formatDate(video.postDate)}</Value>
            </Info>
          </PostInfo>
        </PostWrapper>
      ))}
      <CarouselModal
        open={!!openPostDetail}
        title=""
        cancel="OK"
        onClose={handleClosePostDetail}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        siblingPostId={siblingPostId}
      >
        <AnalyticsPostDetail
          postId={Number(openPostDetail)}
          accountId={accountId}
          accountName={accountName}
          avatarUrl={avatarUrl}
        />
      </CarouselModal>
    </>
  );
};

const PostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;
  border-top: solid 1px #e6ecf0;
`;

const PostHeader = styled.div`
  width: 100%;
  height: 80px;
  display: inline-flex;
  cursor: pointer;
`;

const PostImage = styled.img`
  width: 80px;
  height: 100%;
`;

const PostContent = styled.div`
  flex: 1;
  margin-left: 12px;
  font-size: 13px;
  color: #3892e5;
  font-weight: 600;
  display: flex;
  word-break: break-all;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
  }
`;

const PostInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25%);
  margin-top: 16px;
`;

const Info = styled.div`
  padding: 4px 0;
`;

const Value = styled.p`
  font-size: 13px;
  color: #27313b;
`;
const Unit = styled.p`
  font-size: 10px;
  line-height: 1.3;
  letter-spacing: normal;
  color: #6e7c89;
`;

const PostCount = styled.div`
  padding: 8px 16px;
  border-top: solid 1px #e6ecf0;
`;

export default FeedVideosList;
