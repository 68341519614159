import styled from '@emotion/styled';
import React from 'react';
import { ChooseFanpageForm, ChooseInstagramAccount } from '../../SignUpConnectForm';
import { SelectedSocialAccount, SocialAccountInfo } from '../../SignUpConnectForm/types';
import { DialogModal } from '../../../molecules/DialogModal';
import { PalleteButton } from '../../../atoms/Button';
import { mainRed, mainWhite } from '../../../../libs/pallete';
import { useChooseConnectInstagramAccount } from '../../SignUpConnectForm/useChooseConnectInstagramAccount';
import { pseudoRightArrow } from './helpers';

interface ModalForChooseFanpage {
  facebook: SocialAccountInfo | null;
  fanPages: SelectedSocialAccount[];
  isOpening: boolean;
  step: number;
  selectedFanPageIds: string[];
  setSelectedFanPageIds: (ids: string[]) => void;
  handleNext: () => void;
  handleBack: () => void;
  close: () => void;
}

const ModalForChooseFanpage = (props: ModalForChooseFanpage) => {
  const {
    facebook,
    fanPages,
    isOpening,
    step,
    selectedFanPageIds,
    setSelectedFanPageIds,
    handleNext,
    handleBack,
    close,
  } = props;

  if (!facebook) {
    return null;
  }

  const {
    loading,
    connectingIG,
    igAccountsData,
    handleClickNext,
    selectedIds,
    toggleSelectedIds,
  } = useChooseConnectInstagramAccount({
    selectedFanPageIds,
    clickNext: close,
    clickPrev: handleBack,
    refetchQueries: ['CheckConnectedSocialAccountsForProfile', 'ConnectableFanPageAccountsProfile'],
  });

  const actionButtons = {
    ...(step === 1
      ? {
          NextButton: (
            <PalleteButton text="Next" palleteColor={mainRed} onClick={handleNext} textClassName={pseudoRightArrow} />
          ),
        }
      : {
          BackButton: <PalleteButton text="Previous" onClick={handleBack} palleteColor={mainWhite} />,
          NextButton: <PalleteButton text="Next" palleteColor={mainRed} onClick={handleClickNext} isDisabled={false} />,
        }),
  };

  return (
    <DialogModal isOpen={isOpening} closeModal={close} {...actionButtons}>
      <ModalContentWrapper>
        {step === 1 && (
          <ChooseFanpageForm
            fanPages={fanPages}
            facebook={facebook}
            selectedFanPageIds={selectedFanPageIds}
            setSelectedFanPageIds={setSelectedFanPageIds}
            // we show loader for whole page in EditProfile
            loading={false}
          />
        )}
        {step === 2 && (
          <ChooseInstagramAccount
            clickPrev={handleBack}
            loading={loading}
            connectingIG={connectingIG}
            igAccountsData={igAccountsData}
            clickNext={handleClickNext}
            selectedIds={selectedIds}
            toggleSelectedIds={toggleSelectedIds}
            hasActionButtons={false}
          />
        )}
      </ModalContentWrapper>
    </DialogModal>
  );
};

const ModalContentWrapper = styled.div`
  padding: 16px;
`;

export default ModalForChooseFanpage;
