import { format } from 'date-fns';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import downloadIcon from '@src/assets/icon/download.svg';
import linkIcon from '@src/assets/icon/link.svg';
import emptyImage from '@src/assets/img/emptyImage.jpg';
import { Button } from '@src/components/atoms/Button';
import Grid from '@src/components/atoms/Grid';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import Carousel from '@src/components/molecules/Carousel';
import RichEditor from '@src/components/molecules/RichEditor';
import { getDayfromDateString } from '@src/libs/date';
import { formatNumberWithCommas } from '@src/libs/format';
import { useQueryHelper } from '@src/libs/hooks';
import { useCopy } from '@src/libs/hooks/useCopy';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { ViewportType } from '@src/libs/theme';
import { switchImage, switchText } from '@src/libs/SocialMedia';
import {
  AnyXEngagementCampaignStatus,
  AnyXMarketplaceCampaignStatus,
  CampaignSocialMediaType,
  CampaignStatusForInfluencer,
  CampaignType,
  MarketplaceCampaignAppliedStatus,
  MarketplaceCampaignType,
} from '@src/__generated__/globalTypes';
import CampaignBudgetProgressBar from '../CampaignBudgetProgressBar';
import StatusStepper from '../StatusStepper';

interface CampaignDetailsProps {
  campaignDetails: {
    anyXStatus: AnyXEngagementCampaignStatus | AnyXMarketplaceCampaignStatus | null;
    appliedStatus?: MarketplaceCampaignAppliedStatus | null;
    campaignType?: MarketplaceCampaignType;
    clickUrl?: string | null;
    currency: string;
    endDate: any;
    hashtags: string[];
    isApplied?: boolean;
    isJoined: boolean;
    materials:
      | {
          id: number;
          url: string;
        }[]
      | null;
    materialUrl: string | null;
    maximumRevenuePerInfluencer?: number | null;
    remainingAmount?: number;
    requirement?: string;
    requirements?: string | null;
    revenuePerAction?: number | null;
    revenuePerClick?: number | null;
    revenuePerComment?: number | null;
    revenuePerFollower?: number | null;
    revenuePerLike?: number | null;
    revenuePerPost?: number | null;
    revenuePerShare?: number | null;
    revenuePerView?: number | null;
    rewardAmount?: number;
    serviceInformation: string;
    serviceUrl?: string | null;
    socialMedias: CampaignSocialMediaType[];
    startDate: any;
    status: CampaignStatusForInfluencer;
    title: string;
    thumbNail: string;
    trackingUrl: string | null;
    totalAmount?: number;
    type: CampaignType;
  };
}

const CampaignDetails = ({ campaignDetails }: CampaignDetailsProps) => {
  const {
    anyXStatus,
    appliedStatus,
    campaignType,
    clickUrl,
    currency,
    endDate,
    hashtags,
    isApplied,
    isJoined,
    materials,
    materialUrl,
    maximumRevenuePerInfluencer,
    remainingAmount,
    requirement,
    requirements,
    revenuePerAction,
    revenuePerClick,
    revenuePerComment,
    revenuePerFollower,
    revenuePerLike,
    revenuePerPost,
    revenuePerShare,
    revenuePerView,
    rewardAmount,
    serviceInformation,
    serviceUrl,
    socialMedias,
    startDate,
    status,
    title,
    thumbNail,
    trackingUrl,
    totalAmount,
    type,
  } = campaignDetails;
  const { i18n, t } = useQueryHelper();
  const { handleCopyHasHashtag, isCopying } = useCopy();
  const isMobileView = useMediaQuery({ query: `(max-width: ${ViewportType.SMALL}px)` }); // TODO: consider usePageLayout or change name?
  const isApplying = appliedStatus === MarketplaceCampaignAppliedStatus.APPLYING;
  const isCampaignFinishWithPosting =
    ((anyXStatus &&
      [AnyXEngagementCampaignStatus.INEFFECTIVE, AnyXMarketplaceCampaignStatus.INEFFECTIVE].includes(anyXStatus)) ||
      appliedStatus === MarketplaceCampaignAppliedStatus.REJECTED ||
      status === CampaignStatusForInfluencer.FINISHED) &&
    anyXStatus &&
    [AnyXEngagementCampaignStatus.POSTING, AnyXMarketplaceCampaignStatus.POSTING].includes(anyXStatus);
  const isRejected =
    appliedStatus === MarketplaceCampaignAppliedStatus.REJECTED ||
    anyXStatus === AnyXMarketplaceCampaignStatus.INEFFECTIVE;
  const isSearchCampaign = !isApplied && !isJoined; // For selection marketplace and normal marketplace
  const isUpcomingNormalCampaign =
    status === CampaignStatusForInfluencer.UPCOMING && campaignType === MarketplaceCampaignType.NORMAL;

  const startDay = getDayfromDateString(startDate);
  const startDayNumber = startDay.substring(0, 2);
  const startDayWord = startDay.substring(2, 3);
  const endDay = getDayfromDateString(endDate);
  const endDayNumber = endDay.substring(0, 2);
  const endDayWord = endDay.substring(2, 3);

  return (
    <div css={styles.infoContainer}>
      <div css={styles.imgSection}>
        <img alt="campaignImg" src={thumbNail} />
      </div>

      <div css={styles.infoSection}>
        <Grid md={8} xs={12}>
          {campaignType === MarketplaceCampaignType.SELECTION ? (
            isSearchCampaign || appliedStatus === MarketplaceCampaignAppliedStatus.APPLYING ? (
              <MessageIndicator>
                <span>{t('Heading.This is a Selection campaign')}</span>
                <br />
                <span>{t('Annotation.This campaign is only open to those who have applied and been accepted')}</span>
              </MessageIndicator>
            ) : isRejected ? (
              <MessageIndicator>
                <span>{t('Heading.Application Rejected')}</span>
                <br />
                <span>
                  {t(
                    'Annotation.We regret to announce that your application was not approved. We look forward to seeing you again soon!'
                  )}
                </span>
              </MessageIndicator>
            ) : null
          ) : isRejected ? (
            <MessageIndicator>
              <span>{t('Heading.Your post is rejected')}</span>
              <br />
              <span>{t(`Annotation.The post content didn't meet the campaign condition`)}</span>
            </MessageIndicator>
          ) : null}

          {!isUpcomingNormalCampaign && anyXStatus && !isApplying && !isCampaignFinishWithPosting && !isRejected && (
            <div css={styles.stepperContainer}>
              <div>
                <StatusStepper status={anyXStatus} type={type} />
              </div>
            </div>
          )}

          <div css={styles.title}>{title}</div>

          <RichEditor
            disable={true}
            height="auto"
            setDefaultStyle="padding: 0; margin-bottom: 16px;"
            setOptions={{ resizingBar: false }}
            showToolbar={false}
            value={serviceInformation}
          />

          {isMobileView && remainingAmount !== undefined && totalAmount !== undefined && (
            <div css={styles.progressBarContainer}>
              <CampaignBudgetProgressBar remainingAmount={remainingAmount} totalAmount={totalAmount} />
            </div>
          )}

          <div css={styles.rewardBudgetContainer}>
            <Grid sm={6} xs={12}>
              <div css={styles.rewardPeriodContainer}>
                <div>
                  <div>
                    {type === CampaignType.MARKETPLACE && (
                      <img alt="socialMediaIcon" height="15" src={switchImage(socialMedias[0])} width="15" />
                    )}
                    <span>{t('Reward amount')}</span>
                  </div>
                  {type === CampaignType.ENGAGEMENT ? (
                    rewardAmount && (
                      <div>
                        <RewardAmount large>{formatNumberWithCommas(rewardAmount)}</RewardAmount>
                        &nbsp;
                        <Currency large>{t(currency)}</Currency>
                      </div>
                    )
                  ) : (
                    <div style={{ display: 'grid', justifyContent: 'center' }}>
                      {revenuePerAction && (
                        <RewardAmountField>
                          <RewardAmount>{formatNumberWithCommas(revenuePerAction)}</RewardAmount>
                          &nbsp;
                          <Currency>{`${t(currency)} / ${t('RevenuePer.Action')}`}</Currency>
                        </RewardAmountField>
                      )}
                      {revenuePerClick && (
                        <RewardAmountField>
                          <RewardAmount>{formatNumberWithCommas(revenuePerClick)}</RewardAmount>
                          &nbsp;
                          <Currency>{`${t(currency)} / ${t('RevenuePer.Click')}`}</Currency>
                        </RewardAmountField>
                      )}
                      {revenuePerComment && (
                        <RewardAmountField>
                          <RewardAmount>{formatNumberWithCommas(revenuePerComment)}</RewardAmount>
                          &nbsp;
                          <Currency>{`${t(currency)} / ${t('RevenuePer.Comment')}`}</Currency>
                        </RewardAmountField>
                      )}
                      {revenuePerFollower && (
                        <RewardAmountField>
                          <RewardAmount>{formatNumberWithCommas(revenuePerFollower)}</RewardAmount>
                          &nbsp;
                          <Currency>{`${t(currency)} / ${t('RevenuePer.Follower')}`}</Currency>
                        </RewardAmountField>
                      )}
                      {revenuePerLike && (
                        <RewardAmountField>
                          <RewardAmount>{formatNumberWithCommas(revenuePerLike)}</RewardAmount>
                          &nbsp;
                          <Currency>{`${t(currency)} / ${t('RevenuePer.Like')}`}</Currency>
                        </RewardAmountField>
                      )}
                      {revenuePerPost && (
                        <RewardAmountField>
                          <RewardAmount>{formatNumberWithCommas(revenuePerPost)}</RewardAmount>
                          &nbsp;
                          <Currency>{`${t(currency)} / ${t('RevenuePer.Post')}`}</Currency>
                        </RewardAmountField>
                      )}
                      {revenuePerShare && (
                        <RewardAmountField>
                          <RewardAmount>{formatNumberWithCommas(revenuePerShare)}</RewardAmount>
                          &nbsp;
                          <Currency>{`${t(currency)} / ${t('RevenuePer.Share')}`}</Currency>
                        </RewardAmountField>
                      )}
                      {revenuePerView && (
                        <RewardAmountField>
                          <RewardAmount>{formatNumberWithCommas(revenuePerView)}</RewardAmount>
                          &nbsp;
                          <Currency>{`${t(currency)} / ${t('RevenuePer.View')}`}</Currency>
                        </RewardAmountField>
                      )}
                    </div>
                  )}
                </div>
                <div>
                  <div>{t('Campaign Period')}</div>
                  <div>
                    <MonthFont>
                      {localizedDateFormatter(new Date(startDate), 'MMM', i18n.language as AppLanguage)}
                    </MonthFont>
                    <DayFont>
                      {startDayNumber}
                      <DayWord>{startDayWord}</DayWord>
                    </DayFont>
                    <span css={styles.periodDash}>-</span>
                    <MonthFont>
                      {localizedDateFormatter(new Date(endDate), 'MMM', i18n.language as AppLanguage)}
                    </MonthFont>
                    <DayFont>
                      {endDayNumber}
                      <DayWord>{endDayWord}</DayWord>
                    </DayFont>
                  </div>
                </div>
              </div>
            </Grid>
            {!isMobileView && remainingAmount !== undefined && totalAmount !== undefined && (
              <Grid xs={6}>
                <CampaignBudgetProgressBar remainingAmount={remainingAmount} totalAmount={totalAmount} />
              </Grid>
            )}
          </div>

          <InfoHeaderTitle>{t('Label.Product information')}</InfoHeaderTitle>
          <InfoField marginBottom>
            <InfoTitle>{t('Label.Product / Service Information')}</InfoTitle>
            <InfoContent>
              <RichEditor
                disable={true}
                height="auto"
                setDefaultStyle="padding: 0;"
                setOptions={{ resizingBar: false }}
                showToolbar={false}
                value={serviceInformation}
              />
              {(clickUrl || serviceUrl) && (
                <StyledLink marginTop to={{ pathname: clickUrl || serviceUrl || '' }} target="_blank">
                  <span>{t('Button.More Detail')}</span>
                  <img alt="icon" height="15" src={linkIcon} width="15" />
                </StyledLink>
              )}
            </InfoContent>
          </InfoField>

          <InfoHeaderTitle>{t('Label.Request details')}</InfoHeaderTitle>
          <InfoField>
            <InfoTitle>{t('Label.Posting period')}</InfoTitle>
            <InfoContent>
              {`${format(new Date(startDate), 'yyyy.MM.dd')} -  ${format(new Date(endDate), 'yyyy.MM.dd')}`}
            </InfoContent>
          </InfoField>

          <InfoField>
            <InfoTitle>{t('Label.Social Media')}</InfoTitle>
            <InfoContent>
              <div css={styles.socialMediasContainer}>
                {socialMedias.map((socialMedia, index) => (
                  <div css={styles.socialMedia} key={index}>
                    <img height="18" src={switchImage(socialMedia)} width="18" />
                    <span>{switchText(socialMedia)}</span>
                  </div>
                ))}
              </div>
            </InfoContent>
          </InfoField>

          <InfoField>
            <InfoTitle>{t('Label.Post Requirements')}</InfoTitle>
            <InfoContent>
              <RichEditor
                disable={true}
                height="auto"
                setDefaultStyle="padding: 0;"
                setOptions={{ resizingBar: false }}
                showToolbar={false}
                value={requirement || requirements || ''}
              />
            </InfoContent>
          </InfoField>

          {!!trackingUrl && (
            <InfoField>
              <InfoTitle>{t('Label.Tracking URL')}</InfoTitle>
              <InfoContent>
                {t('Annotation.Please add this URL to your post')}
                <div css={styles.trackingUrl}>{trackingUrl}</div>
              </InfoContent>
            </InfoField>
          )}

          {hashtags.length > 0 && (
            <InfoField>
              <InfoTitle>{t('Label.Hash Tags')}</InfoTitle>
              <InfoContent>
                <div css={styles.hashtagsContainer}>
                  {hashtags.map((hastag, index) => (
                    <div css={styles.hashtag} key={index}>
                      {`#${hastag}`}
                    </div>
                  ))}
                </div>
                <Button
                  bgcolor="#fff"
                  css={styles.copyBtn}
                  disabled={isCopying}
                  hoverbgcolor="#fff"
                  title="Copy"
                  onClick={() => handleCopyHasHashtag(hashtags)}
                />
              </InfoContent>
            </InfoField>
          )}

          {!!maximumRevenuePerInfluencer && (
            <InfoField>
              <InfoTitle>{t('Label.Maximum Revenue')}</InfoTitle>
              <InfoContent>{`${formatNumberWithCommas(maximumRevenuePerInfluencer, 0)} ${t(currency)}`}</InfoContent>
            </InfoField>
          )}

          {!!materialUrl && (
            <InfoField>
              <InfoTitle>{t('Label.Download materials for posting')}</InfoTitle>
              <InfoContent>
                <StyledLink to={{ pathname: materialUrl || '' }} target="_blank">
                  <img alt="icon" height="15" src={downloadIcon} width="15" />
                  <span>{t('Button.Download')}</span>
                </StyledLink>
              </InfoContent>
            </InfoField>
          )}

          {!!(materials && materials.length > 0) && (
            <InfoField>
              <InfoTitle>{t('Label.Posting Samples')}</InfoTitle>
              <InfoContent>
                <div css={styles.carouselContainer}>
                  <Carousel height="230px" sources={materials.map(item => item.url) || [emptyImage]} width="300" />
                </div>
              </InfoContent>
            </InfoField>
          )}
        </Grid>
      </div>
    </div>
  );
};

const Currency = styled.span<{ large?: boolean }>`
  color: #6e7c89;
  font-size: ${({ large }) => (large ? 11 : 10)}px;
`;

const DayFont = styled.span`
  color: #27313b;
  font-size: 16px;
  font-weight: 600;
`;

const DayWord = styled.span`
  color: #27313b;
  font-size: 11px;
  font-weight: 600;
`;

const InfoContent = styled.div`
  font-size: 13px;
  padding: 16px;

  & .slick-arrow {
    border-radius: 50%;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    height: 32px !important;
    position: absolute;
    width: 32px !important;

    & > i {
      margin-top: 1px;
    }

    &.slick-prev {
      left: -15px !important;
    }

    &.slick-next {
      right: -15px !important;
    }
  }

  & .slick-dots {
    bottom: -30px !important;
  }

  & .slick-dots li button::before {
    color: #3892e5 !important;
    font-size: 11px !important;
  }
`;

const InfoField = styled.div<{ marginBottom?: boolean }>`
  border: 1px solid #eef3f7;
  ${({ marginBottom }) => (marginBottom ? 'margin-bottom: 16px;' : '')}
`;

const InfoHeaderTitle = styled.div`
  color: #27313b;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
`;

const MessageIndicator = styled.div`
  border-left: 5px solid #ffb63d;
  border-radius: 3px;
  box-shadow: 0 1px 5px rgba(39, 49, 59, 0.2);
  margin: 16px 0;
  padding: 16px;

  & > span:nth-of-type(1) {
    color: #27313b;
    font-size: 13px;
    font-weight: 600;
  }

  & > span:nth-of-type(2) {
    color: #6e7c89;
    font-size: 13px;
  }
`;

const MonthFont = styled.span`
  color: #27313b;
  font-size: 11px;
  font-weight: 600;
  margin-right: 5px;
  margin-bottom: -2px;
`;

const RewardAmount = styled.span<{ large?: boolean }>`
  color: #27313b;
  font-size: ${({ large }) => (large ? 16 : 14)}px;
  font-weight: 600;
`;

const RewardAmountField = styled.div`
  text-align: start;
`;

const StyledLink = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{ marginTop?: boolean }>`
  align-items: center;
  border: 1px solid #dee5ec;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  height: 32px;
  justify-content: center;
  width: 228px;
  ${({ marginTop }) => (marginTop ? 'margin-top: 16px;' : '')}

  & > img:nth-of-type(1) {
    margin-right: 8px;
  }

  /* stylelint-disable no-descending-specificity */
  & > span {
    color: #6e7c89;
    font-weight: 600;
    margin-right: 8px;
  }
`;

const InfoTitle = styled.div`
  background-color: #eef3f7;
  font-size: 13px;
  font-weight: 600;
  padding: 8px;
`;

const styles = {
  carouselContainer: css`
    max-width: 300px;
  `,
  copyBtn: css`
    border: 1px solid #dde5eb;
    border-radius: 5px;
    color: #6e7c89;
    font-weight: 600;
    height: 32px;
    width: 60px;
  `,
  hashtag: css`
    background-color: #d7e9fb;
    color: #3992e5;
    margin-bottom: 8px;
    margin-right: 8px;
    padding: 8px;
    width: fit-content;
  `,
  hashtagsContainer: css`
    display: flex;
    flex-wrap: wrap;
  `,
  imgSection: css`
    background-color: #000;
    display: flex;
    height: 338px;
    width: 100%;

    & > img {
      object-fit: contain;
      width: inherit;
    }
  `,
  infoContainer: css`
    display: flex;
    flex-wrap: wrap;
  `,
  infoSection: css`
    background-color: #fff;
    display: flex;
    justify-content: center;
    padding: 16px;
    width: 100%;

    @media (max-width: ${ViewportType.SMALL}px) {
      border-radius: 24px;
      margin-top: -16px;
    }
  `,
  periodDash: css`
    color: #27313b;
    font-weight: 600;
    margin: 0 5px;
  `,
  progressBarContainer: css`
    margin: 16px 0;
  `,
  rewardBudgetContainer: css`
    align-items: center;
    border-bottom: 1px solid #dee5ec;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    padding-bottom: 16px;
  `,
  rewardPeriodContainer: css`
    display: flex;
    flex-wrap: wrap;

    & > div {
      display: grid;
      flex-basis: 49%;
      text-align: center;

      & > div:nth-of-type(1) {
        align-items: flex-start;
        color: #6e7c89;
        display: flex;
        flex-wrap: wrap;
        font-size: 11px;
        font-weight: 600;
        justify-content: center;
        margin-bottom: 5px;

        & > img {
          margin-right: 8px;
        }
      }
    }

    & > div:nth-of-type(1) {
      border-right: 1px solid #dee5ec;
    }
  `,
  stepperContainer: css`
    border-bottom: 1px solid #eef3f7;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;

    & > div {
      display: flex;
      flex-basis: 60%;

      @media (max-width: ${ViewportType.SMALL}px) {
        flex-basis: 100%;
      }
    }
  `,
  socialMedia: css`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
    margin-right: 16px;

    & > img {
      margin-right: 8px;
    }
  `,
  socialMediasContainer: css`
    display: flex;
    flex-wrap: wrap;
  `,
  title: css`
    color: #27313b;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
  `,
  trackingUrl: css`
    background-color: #eef3f7;
    border-radius: 3px;
    margin-top: 16px;
    min-height: 80px;
    padding: 8px;
    word-break: break-all;
  `,
};

export default CampaignDetails;
