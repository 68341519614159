import React from 'react';
import { useLocation } from 'react-router';
import FeedPostList from '@src/components/organisms/PostFeedList';
import StoryFeedList from '@src/components/organisms/StoryFeedList';
import { BasePostFilterType } from './types';

interface TemplateProps {
  userId: number;
  accountId: number;
  currentPage: number;
  filter: BasePostFilterType;
  hasTalentAgencyQuery?: boolean;
  hasPartnerQuery?: boolean;
  setFilter: (filter: BasePostFilterType) => void;
  goBackUrl?: string;
}

const Template = (props: TemplateProps) => {
  const { pathname } = useLocation();

  const isInstagramFeed = pathname.includes('instagram_feed_post');
  const isInstagramStory = pathname.includes('instagram_story_feed');

  return isInstagramFeed ? <FeedPostList {...props} /> : isInstagramStory ? <StoryFeedList {...props} /> : null;
};

export default Template;
