import { Formik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import Address, { AddressInformation } from './Address';
import UPDATE_ADDRESS_INFORMATION from './mutations/UpdateAddressInformation.graphql';
import { UpdateAddressInformationVariables } from './mutations/__generated__/UpdateAddressInformation';
import CREATOR_ADDRESS_INFORMATION from './queries/CreatorAddressInformation.graphql';
import { CreatorAddressInformation } from './queries/__generated__/CreatorAddressInformation';

const Index = () => {
  const [addressInfo, setAddressInfo] = useState<AddressInformation>({
    address: '',
    address2: '',
    city: '',
    fullName: '',
    postalCode: '',
    province: '',
  });
  const { enqueueSnackbar, t } = useQueryHelper();

  const validationSchema = yup.object().shape({
    address: yup.string().required('requiredAddressMessage'),
    address2: yup.string().required('requiredApartmentMessage'),
    city: yup.string().required('requiredCityMessage'),
    fullName: yup.string().required('requiredFullNameMessage'),
    postalCode: yup.string().required('requiredPostalCodeMessage'),
    province: yup.string().required('requiredProvinceMessage'),
  });

  useQuery<CreatorAddressInformation>(CREATOR_ADDRESS_INFORMATION, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ creatorAddressInformation }) => {
      if (creatorAddressInformation) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { __typename, ...addressInformation } = creatorAddressInformation;
        setAddressInfo(addressInformation);
      }
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [updateAddressInformation] = useMutation<UpdateAddressInformationVariables>(UPDATE_ADDRESS_INFORMATION, {
    onCompleted: () => {
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  function onSubmit(values: AddressInformation) {
    updateAddressInformation({
      variables: {
        input: values,
      },
    });
  }

  return (
    <Formik
      enableReinitialize
      initialValues={addressInfo}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Address />
    </Formik>
  );
};

export default Index;
