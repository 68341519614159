import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import timeSchedule from '@src/assets/icon/timeSchedule.svg';
import { Button } from '@src/components/atoms/Button';
import { Icon } from '@src/components/atoms/Icon';
import { getSocialMediaUrlByCampaignType } from '@src/libs/campaign';
import { useCopy } from '@src/libs/hooks/useCopy';
import { ViewportType } from '@src/libs/theme';
import { CampaignSocialMediaType } from '@src/__generated__/globalTypes';

interface PostButtonProps {
  campaignDetails: {
    hashtags: string[];
    socialMedias: CampaignSocialMediaType[];
  };
  height?: number;
}

const PostButton = ({ campaignDetails, height = 32 }: PostButtonProps) => {
  const { hashtags, socialMedias } = campaignDetails;
  const [dialog, setDialog] = useState<boolean>(false);
  const { t } = useTranslation();
  const { handleCopyHasHashtag, isCopying } = useCopy();
  const pathname = getSocialMediaUrlByCampaignType(socialMedias[0]);

  function onClickPostNow() {
    window.open(pathname, '_blank');
    setDialog(false);
  }

  return (
    <div>
      <Popup
        contentStyle={{
          backgroundColor: '#fff',
          borderRadius: 5,
          maxWidth: 400,
          width: '90%',
        }}
        open={dialog}
        onClose={() => setDialog(false)}
      >
        <div>
          <div css={styles.closeBtn} onClick={() => setDialog(false)}>
            <Icon icon="close" />
          </div>

          <div css={styles.dialogContent}>
            <div css={styles.dialogTitle}>{t('Precautions before posting')}</div>
            <div css={styles.dialogNotes}>
              <div>
                <img alt="time" height="20" src={timeSchedule} width="20" />
              </div>
              <div>
                <div>{t('Post reflection time')}</div>
                <div>{t('After posting, it will take around 20 minutes for the report to take')}</div>
              </div>
            </div>
            <div css={styles.dialogSubtitle}>
              <span>#</span>
              <span>{t(`Hashtags are a "MUST ITEM"`)}</span>
            </div>
            <div css={styles.dialogDescription}>{t('precautionsPostingDescription')}</div>
            <div css={styles.hashtagsContainer}>
              {hashtags.map((hashtag, index) => (
                <div css={styles.hashtag} key={index}>
                  {`#${hashtag}`}
                </div>
              ))}
            </div>

            <div>
              <Button
                bgcolor="#fff"
                css={styles.copyBtn}
                disabled={isCopying}
                title="Copy Hashtags"
                hoverbgcolor="#fff"
                onClick={() => handleCopyHasHashtag(hashtags)}
              />
            </div>
          </div>

          <div css={styles.dialogAction}>
            <DialogButton title="Close" onClick={() => setDialog(false)} />
            <DialogButton isPrimary title="Post Now" onClick={onClickPostNow} />
          </div>
        </div>
      </Popup>

      <StyledButton
        bgcolor="#3892e5"
        hoverbgcolor="#55a3ec"
        height={height}
        title="Post Now"
        onClick={() => setDialog(true)}
      />
    </div>
  );
};

const DialogButton = styled(Button)<{ isPrimary?: boolean }>`
  color: ${({ isPrimary }) => (isPrimary ? '#fff' : '#6e7c89')};
  background-color: ${({ isPrimary }) => (isPrimary ? '#3892e5' : '#fff')};
  border: 1px solid ${({ isPrimary }) => (isPrimary ? '#fff' : '#dee5ec')};
  border-radius: 5px;
  filter: brightness(1);
  font-weight: 600;
  height: 32px;
  padding: 0 16px;
  width: 114px;

  &:hover {
    background-color: ${({ isPrimary }) => (isPrimary ? '#3892e5' : '#fff')};
    filter: brightness(0.9);
  }
`;

const StyledButton = styled(Button)<{ height: number }>`
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  height: ${({ height }) => height}px;
  padding: 0 24px;
  width: 100%;
`;

const styles = {
  closeBtn: css`
    align-items: center;
    background-color: #000;
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: -16px;
    top: -16px;
    width: 40px;
  `,
  copyBtn: css`
    border: 1px solid #dee5ec;
    border-radius: 5px;
    color: #6e7c89;
    filter: brightness(1);
    font-size: 12px;
    font-weight: 600;

    &:hover {
      filter: brightness(0.9);
    }
  `,
  dialogAction: css`
    display: flex;
    justify-content: flex-end;
    padding: 16px;

    & > button:nth-of-type(2) {
      margin-left: 8px;
    }

    @media (max-width: ${ViewportType.SMALL}px) {
      & > button {
        height: 40px;
        width: fill-available;
      }
    }
  `,
  dialogContent: css`
    border-bottom: 1px solid #dee5ec;
    padding: 16px;
  `,
  dialogDescription: css`
    color: #27313b;
    font-size: 13px;
    margin-bottom: 16px;
  `,
  dialogNotes: css`
    border-left: 5px solid #3892e5;
    border-radius: 3px;
    box-shadow: 0 1px 5px rgba(39, 49, 59, 0.2);
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    padding: 8px;

    & > div:nth-of-type(1) {
      display: flex;
      flex-basis: 10%;
      justify-content: center;
    }

    & > div:nth-of-type(2) {
      display: flex;
      flex-basis: 90%;
      flex-wrap: wrap;

      & > div:nth-of-type(1) {
        color: #27313b;
        display: flex;
        flex-basis: 100%;
        font-size: 13px;
        font-weight: 600;
        padding: 0 8px;
      }

      & > div:nth-of-type(2) {
        color: #6e7c89;
        display: flex;
        flex-basis: 100%;
        font-size: 13px;
        padding: 0 8px;
      }
    }
  `,
  dialogSubtitle: css`
    margin-bottom: 16px;

    & > span:nth-of-type(1) {
      color: #3892e5;
      font-size: 18px;
      font-weight: 700;
      margin-right: 8px;
    }

    & > span:nth-of-type(2) {
      color: #27313b;
      font-size: 14px;
      font-weight: 700;
    }
  `,
  dialogTitle: css`
    border-bottom: 1px solid #dee5ec;
    color: #27313b;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
    padding-bottom: 16px;
  `,
  hashtag: css`
    background-color: #d7e9fb;
    border-radius: 5px;
    color: #3892e5;
    font-size: 13px;
    margin-right: 8px;
    padding: 8px;
  `,
  hashtagsContainer: css`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
  `,
};

export default PostButton;
