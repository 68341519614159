import { startOfDay, isAfter } from 'date-fns';
import {
  CampaignPostStatusForInfluencer,
  CampaignStatusForInfluencer,
  CampaignType,
  CampaignSocialMediaType,
  SocialAccountType,
  MarketplaceCampaignAppliedStatus,
} from '../__generated__/globalTypes';

export const getCampaignTypeString = (campaignType: CampaignType) => {
  switch (campaignType) {
    case CampaignType.MARKETPLACE:
      return 'marketplace';
    case CampaignType.ENGAGEMENT:
      return 'engagement';
  }
};

interface CampaignBadgePayload {
  campaignType: CampaignType;
  isApplying: boolean | null | undefined;
}

export const getCampaignBadge = (campaignType: CampaignType, isApplying: boolean) => {
  if (campaignType === CampaignType.ENGAGEMENT) {
    return {
      bgColor: '#3892e5',
      text: 'Offer',
    };
  } else {
    return isApplying
      ? {
          bgColor: '#ffb63d',
          text: 'Applying',
        }
      : {
          bgColor: '#ff2b52',
          text: 'Joined',
        };
  }
};

export const getCampaignBadgeText = ({ campaignType, isApplying }: CampaignBadgePayload) => {
  switch (campaignType) {
    case CampaignType.MARKETPLACE:
      return isApplying ? 'Applying' : 'Joined';
    case CampaignType.ENGAGEMENT:
      return 'Offer';
  }
};

export const getCampaignBadgeBg = ({ campaignType, isApplying }: CampaignBadgePayload) => {
  if (campaignType === CampaignType.MARKETPLACE) {
    return isApplying ? '#ffb63d' : '#ff2b52';
  } else {
    return '#3892e5';
  }
};

export const getCampaignThumbnailText = ({
  status,
  hasReport,
  appliedStatus,
  selectionEndDate,
  isApplying,
  postStatus,
}: {
  status: CampaignStatusForInfluencer;
  hasReport?: boolean;
  appliedStatus?: MarketplaceCampaignAppliedStatus | null;
  selectionEndDate?: string;
  isApplying?: boolean; // Only be used for campaign card(Your Job page)
  postStatus?: CampaignPostStatusForInfluencer | null;
}) => {
  if (status === CampaignStatusForInfluencer.FINISHED) {
    return {
      mainText: 'Finished',
    };
  }

  if (hasReport) {
    return { mainText: 'Posted' };
  }

  const currentDateIsAfterSelectionEndDate =
    selectionEndDate && isAfter(startOfDay(new Date()), startOfDay(new Date(selectionEndDate)));

  if (
    appliedStatus === MarketplaceCampaignAppliedStatus.REJECTED ||
    postStatus === CampaignPostStatusForInfluencer.INEFFECTIVE
  ) {
    return {
      mainText: 'Finished',
      subText: 'We look forward to your next application',
    };
  }
  if (appliedStatus === MarketplaceCampaignAppliedStatus.APPROVED) {
    return {
      mainText: 'Joined',
    };
  }
  if (
    isApplying ||
    (appliedStatus === MarketplaceCampaignAppliedStatus.APPLYING &&
      selectionEndDate &&
      !currentDateIsAfterSelectionEndDate)
  ) {
    return {
      mainText: 'Reviewing',
      subText: 'We will be contacted if you are accepted',
    };
  }

  return null;
};

export enum CampaignPageType {
  SEARCH = 'search',
  JOB = 'job',
  JOB_FINISHED = 'finished',
}
export const getCampaignUrl = (page: CampaignPageType, campaignId: number, campaignType: CampaignType) =>
  // for finished campaigns url is under '/job' route
  `/${page === 'finished' ? 'job' : page}/${getCampaignTypeString(campaignType)}/${campaignId}`;

export enum CampaignStatus {
  UPCOMING = 'UPCOMING',
  JOIN_NOW = 'JOIN_NOW',
  POST_NOW = 'POST_NOW',
  ONGOING = 'ONGOING',
  SUSPENDED = 'SUSPENDED',
  FINISHED = 'FINISHED',
}

export const switchCampaignStatus = (
  status: CampaignStatusForInfluencer,
  isJoined: boolean,
  postStatus: CampaignPostStatusForInfluencer | null
) => {
  if (status === CampaignStatusForInfluencer.UPCOMING) {
    return CampaignStatus.UPCOMING;
  } else if (status === CampaignStatusForInfluencer.ONGOING && !isJoined) {
    return CampaignStatus.JOIN_NOW;
  } else if (status === CampaignStatusForInfluencer.ONGOING && !!isJoined && postStatus === null) {
    return CampaignStatus.POST_NOW;
  } else if (status === CampaignStatusForInfluencer.ONGOING) {
    return CampaignStatus.ONGOING;
  } else if (status === CampaignStatusForInfluencer.SUSPENDED) {
    return CampaignStatus.SUSPENDED;
  } else {
    return CampaignStatus.FINISHED;
  }
};

export const getCampaignStatusColor = (status: CampaignStatusForInfluencer) => {
  switch (status) {
    case CampaignStatusForInfluencer.ONGOING:
      return '#3892e5';
    case CampaignStatusForInfluencer.UPCOMING:
      return '#966acc';
    case CampaignStatusForInfluencer.FINISHED:
      return '#6e7c89';
    case CampaignStatusForInfluencer.SUSPENDED:
      return '#ffbe82';

    default:
      return '';
  }
};

export const getCampaignStatusText = (status: CampaignStatusForInfluencer) => {
  switch (status) {
    case CampaignStatusForInfluencer.ONGOING:
      return 'Ongoing';
    case CampaignStatusForInfluencer.UPCOMING:
      return 'Upcoming';
    case CampaignStatusForInfluencer.FINISHED:
      return 'Finished';
    case CampaignStatusForInfluencer.SUSPENDED:
      return 'Over Budget';

    default:
      return '';
  }
};

export const getSocialMediaUrlByCampaignType = (socialMedia: CampaignSocialMediaType) => {
  switch (socialMedia) {
    case CampaignSocialMediaType.INSTAGRAM:
    case CampaignSocialMediaType.INSTAGRAM_STORY:
      return 'https://www.instagram.com';

    case CampaignSocialMediaType.FACEBOOK:
      return 'https://www.facebook.com';

    case CampaignSocialMediaType.TWITTER:
      return 'https://www.twitter.com';

    case CampaignSocialMediaType.YOUTUBE:
      return 'https://www.youtube.com';

    case CampaignSocialMediaType.TIKTOK:
      return 'https://www.tiktok.com';

    default:
      return undefined;
  }
};

export const getAuthSocialAccountType = (socialMedia?: CampaignSocialMediaType): SocialAccountType | undefined => {
  switch (socialMedia) {
    case CampaignSocialMediaType.FACEBOOK:
      return SocialAccountType.FACEBOOK;
    case CampaignSocialMediaType.INSTAGRAM:
    case CampaignSocialMediaType.INSTAGRAM_STORY:
      return SocialAccountType.INSTAGRAM;
    case CampaignSocialMediaType.TWITTER:
      return SocialAccountType.TWITTER;
    case CampaignSocialMediaType.YOUTUBE:
      return SocialAccountType.YOUTUBE;
    case CampaignSocialMediaType.TIKTOK:
      return SocialAccountType.TIKTOK;

    default:
      return undefined;
  }
};
