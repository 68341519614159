import styled from '@emotion/styled';
import VideoPreview from '@src/components/atoms/VideoPreview';
import * as React from 'react';
import Image from '@src/components/atoms/Image';
import { isVideoThumbnail } from '@src/libs/image';

export interface PostProps {
  id: number;
  src: string | null;
  title: string | null;
}

interface ThumbnailProps {
  src: string | null;
  onClick: () => void;
}

const PostColumn = (props: PostProps & ThumbnailProps) => {
  const { src, title, onClick } = props;
  const isVideoThumb = isVideoThumbnail(src);

  return (
    <Wrapper>
      <ThumbnailWrapper onClick={onClick}>
        {isVideoThumb ? (
          <VideoPreview url={src} width="50" height="50" />
        ) : (
          <Thumbnail src={src} hasNoImgText={false} />
        )}
      </ThumbnailWrapper>
      <div>{!!title && <Title onClick={onClick}>{title}</Title>}</div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  height: 61px;
  align-items: center;
`;
const ThumbnailWrapper = styled.a`
  flex-shrink: 0;
  margin-right: 8px;
  border: 1px solid #f5f5f5;
  border-radius: 2px;
  background-color: black;

  ${Wrapper}:hover & {
    opacity: 0.7;
  }
`;
const Thumbnail = styled(Image)`
  width: 48px;
  height: 48px;
  object-fit: cover;
`;

const Title = styled.a`
  overflow: hidden;
  flex: 1;
  height: 31px;
  margin-top: 4px;
  font-size: 13px;
  line-height: 16px;
  word-break: break-word;
  padding-right: 8px;

  ${Wrapper}:hover & {
    opacity: 0.7;
  }
`;

export default PostColumn;
