import { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useConnect } from '../useConnect';
import { useQueryHelper } from '../../../../../libs/hooks';
import { AuthSocialAccountType } from '../../../../../__generated__/globalTypes';
import { ConnectType } from '../../../../../libs/auth';
import { UNEXPECTED_ERROR } from '../../../../../libs/error';
import * as CONNECTABLE_PAGES_AND_INSTAGRAM_ACCOUNTS from './ConnectableFacebookPagesAndInstagramAccountsForReconnect.graphql';
import { ConnectableFacebookPagesAndInstagramAccountsForProfileConnectForReconnect } from './__generated__/ConnectableFacebookPagesAndInstagramAccountsForProfileConnectForReconnect';
import * as CONNECT_IG_ACCOUNTS from './ConnectInstagramAccountsForReconnect.graphql';
import {
  ConnectInstagramAccountsForReconnect,
  ConnectInstagramAccountsForReconnectVariables,
} from './__generated__/ConnectInstagramAccountsForReconnect';

interface ReconnectInstagramBags {
  startReconnectInstagram: (instagramAccountId: string) => void;
  handleReconnectInstagram: (instagramAccountId?: string) => void;
  reconnecting: boolean;
}

interface UseReconnectInstagramOptions {
  onFinished: () => void;
}

export const useReconnectInstagram = ({ onFinished }: UseReconnectInstagramOptions): ReconnectInstagramBags => {
  const client = useApolloClient();
  const { t, enqueueSnackbar } = useQueryHelper();
  const [reconnecting, setReconnecting] = useState<boolean>(false);
  const { onClick: handleConnect } = useConnect(FE_REDIRECT_MAPPING.CONNECT);
  const [reconnectInstagramAccountMutation] = useMutation<
    ConnectInstagramAccountsForReconnect,
    ConnectInstagramAccountsForReconnectVariables
  >(CONNECT_IG_ACCOUNTS);

  /**
   * When Clicking "Reconnect" button
   */
  const startReconnectInstagram = async (instagramAccountId: string) => {
    // Set some properties to use after redirecting back from Facebook.
    localStorage.setItem('connectType', ConnectType.RECONNECT_IG_ACCOUNT);
    localStorage.setItem('reconnectIgAccountId', instagramAccountId);
    localStorage.setItem('shouldShowSuccessMessage', 'false');

    await handleConnect(AuthSocialAccountType.FACEBOOK)();
  };

  /**
   * Execute Reconnect.
   *
   * 1. Fetch Facebook pages and Instagram Accounts which the influencer has.
   * 2. Find target FB page and IG account info.
   * 3. Send a request to connect the FB page and IG account.
   *
   * TODO: Create a new Mutation API to reconnect IG account because we should not write such a business logic in Client-Side.
   */
  const handleReconnectInstagram = async (targetIGAccountId?: string) => {
    if (!targetIGAccountId) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });

      return;
    }

    setReconnecting(true);

    const handleFinished = () => {
      setReconnecting(false);
      onFinished();
    };

    // Fetch FB pages and IG accounts data
    const { data, errors } = await client.query<
      ConnectableFacebookPagesAndInstagramAccountsForProfileConnectForReconnect
    >({
      query: CONNECTABLE_PAGES_AND_INSTAGRAM_ACCOUNTS,
    });
    if (!!errors) {
      errors.forEach(e => {
        enqueueSnackbar(t(e.message) || t(UNEXPECTED_ERROR), { variant: 'error' });
      });
      handleFinished();

      return;
    }

    // Find the FB page and IG account of target account
    const targetFBPageAndIGAccount = data.connectableFacebookPagesAndInstagramAccounts?.accounts.find(
      account => account.igAccount?.id === targetIGAccountId
    );
    if (!targetFBPageAndIGAccount?.igAccount) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      handleFinished();

      return;
    }

    // User should set up his IG account at first.
    if (!targetFBPageAndIGAccount?.igAccount?.isAlreadyConnected) {
      enqueueSnackbar(t('YourInstagramIsNotLinkedToFacebookPage'), { variant: 'error' });
      handleFinished();

      return;
    }

    // Send a request to connect the FB page and IG account.
    const {
      page: { id: targetFBPageId },
    } = targetFBPageAndIGAccount;

    try {
      await reconnectInstagramAccountMutation({
        variables: { input: { igUserIds: [targetIGAccountId], fbPageIds: [targetFBPageId] } },
      });
      enqueueSnackbar(t('succeededInConnecting'), { variant: 'success' });
    } catch (e) {
      console.error(e);
      enqueueSnackbar(t('failedToConnect'), { variant: 'error' });
    }

    handleFinished();

    return;
  };

  return {
    startReconnectInstagram,
    handleReconnectInstagram,
    reconnecting,
  };
};
