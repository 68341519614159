import css from '@emotion/css';
import influencerAvatar from '../../../../assets/img/influencerAvatar.jpg';
import { SocialAccountInfo } from '../../SignUpConnectForm/types';
import { SocialAccountType } from '../../../../__generated__/globalTypes';
import { ConnectableFanPageAccountsProfile_connectableFacebookPages_pages } from './__generated__/ConnectableFanPageAccountsProfile';
import { CheckConnectedSocialAccountsForProfile_checkConnectedSocialAccountsV2 } from './__generated__/CheckConnectedSocialAccountsForProfile';

export const getAccountName = (socialAccount: SocialAccountInfo | null) => (socialAccount ? socialAccount.name : '-');
export const getAccountAvatar = (socialAccount: SocialAccountInfo | null) =>
  socialAccount ? socialAccount.avatar : influencerAvatar;
export const getAccountFollowers = (socialAccount: SocialAccountInfo | null) =>
  socialAccount ? socialAccount.followersCount : null;

export const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    borderRadius: '8px',
  };
};

export const defaultSocialAccounts: Omit<
  CheckConnectedSocialAccountsForProfile_checkConnectedSocialAccountsV2,
  '__typename'
> = {
  facebook: null,
  facebookPages: [],
  instagramAccounts: [],
  twitterAccounts: [],
  tiktokAccounts: [],
  youtubeAccounts: [],
};

export const reconnectIgWarningHeader = 'Instagram account is disconnected';
export const reconnectIgWarningBody =
  'Connect is required again due to the change in Instagram account specifications. Please register again from the reconnect button';

export const addFanPageCss = css`
  &::before {
    content: '+ ';
    height: 30px;
    line-height: 28px;
    margin-right: 3px;
    font-weight: 500;
  }
`;

export const pseudoRightArrow = css`
  &::after {
    content: '\u2192';
    position: absolute;
    right: 10px;
  }
`;

export const getAddAccountButtonText = (socialMedia: SocialAccountType) => {
  switch (socialMedia) {
    case SocialAccountType.YOUTUBE:
      return 'add channel';

    case SocialAccountType.TWITTER:
    case SocialAccountType.INSTAGRAM:
    case SocialAccountType.TIKTOK:
      return 'add account';

    default:
      return '';
  }
};

export const getAlreadyConnectedFanpagesIds = (
  pages?: ConnectableFanPageAccountsProfile_connectableFacebookPages_pages[]
) => pages?.filter(page => page.isAlreadyConnected).map(page => page.id) || [];
