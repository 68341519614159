import styled from '@emotion/styled';
import React from 'react';
import TextCutter from '@src/components/atoms/TextCutter';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { bigIntFormatter } from '@src/libs/format';
import { switchImage } from '@src/libs/SocialMedia';
import { ViewportType } from '@src/libs/theme';

const SocialMediaLink = ({
  mediaType,
  followersNumber,
  title,
}: {
  mediaType: SocialAccountType;
  followersNumber: number | null;
  title: string;
}) => (
  <LinkWrapper>
    <SocialMediaTitle>
      <Icon src={switchImage(mediaType)} alt="" />
      <Value>{title}</Value>
    </SocialMediaTitle>
    <Unit text={bigIntFormatter(followersNumber)} lines={1} />
  </LinkWrapper>
);

const SocialMediaTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;
const LinkWrapper = styled.a`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  margin-bottom: 24px;

  &:nth-last-of-type(-n + 2) {
    margin-bottom: 0;
  }

  @media (max-width: ${ViewportType.MEDIUM}px) {
    margin-bottom: 0;
  }

  @media (max-width: ${ViewportType.SMALL}px) {
    margin-right: 0;
  }
`;
const Unit = styled(TextCutter)`
  font-size: 14px;
  font-weight: 600;
  padding-left: 26px;

  @media (max-width: ${ViewportType.SMALL}px) {
    padding-left: 0;
  }
`;
const Value = styled.p`
  font-size: 13px;
  color: #6e7c89;

  @media (max-width: ${ViewportType.SMALL}px) {
    display: none;
  }
`;
const Icon = styled.img`
  width: 16px;
  margin-right: 10px;
`;

export default SocialMediaLink;
