import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ListIndicator } from '@src/components/molecules/Indicator';
import CampaignDetails from '@src/components/organisms/CastingMarketplace/YourJob/CampaignDetails';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import { AnyXEngagementCampaignStatus, CampaignStatusForInfluencer } from '@src/__generated__/globalTypes';
import CAMPAIGN_DETAIL from './queries/EngagementCampaignDetail.graphql';
import {
  EngagementCampaignDetail,
  EngagementCampaignDetailVariables,
} from './queries/__generated__/EngagementCampaignDetail';

const Details = () => {
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });
  const { enqueueSnackbar, history, params, t } = useQueryHelper();
  const engagementId = Number(params.id);

  const { data, loading } = useQuery<EngagementCampaignDetail, EngagementCampaignDetailVariables>(CAMPAIGN_DETAIL, {
    fetchPolicy: 'no-cache',
    variables: {
      pk: engagementId,
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  if (!loading && !data?.engagementForInfluencer) {
    history.push('/casting/your_job');
  }

  const campaignDetails = data?.engagementForInfluencer;
  const hasDraftPosts = campaignDetails?.hasDraftPosts;
  const hasReport = campaignDetails?.hasReport;
  const isCampaignFinish =
    campaignDetails?.anyXStatus === AnyXEngagementCampaignStatus.INEFFECTIVE ||
    campaignDetails?.status === CampaignStatusForInfluencer.FINISHED;
  const uploadDraftPost = campaignDetails?.status !== CampaignStatusForInfluencer.FINISHED && !hasDraftPosts;

  return (
    <>
      <div css={styles.container}>
        {isDesktopView && (
          <div css={styles.headerContainer}>
            <div>
              <BackNavigator
                title="Campaigns Detail"
                to={`/casting/your_job${
                  campaignDetails?.status === CampaignStatusForInfluencer.FINISHED ? '#finished' : ''
                }`}
              />
            </div>
            <div>
              {hasReport ? (
                <>
                  <ActionButton to={`/casting/your_job/${engagementId}/post`}>{t('Button.Post List')}</ActionButton>
                  <ActionButton to={`/casting/your_job/${engagementId}/report`}>{t('Button.View Report')}</ActionButton>
                </>
              ) : !isCampaignFinish && hasDraftPosts ? (
                <ActionButton to={`/casting/your_job/${engagementId}/post`}>{t('Button.Post List')}</ActionButton>
              ) : uploadDraftPost ? (
                <ActionButton isPrimary to={`/casting/your_job/${engagementId}/post/add`}>
                  {t('Button.Upload Draft Post')}
                </ActionButton>
              ) : null}
            </div>
          </div>
        )}

        {loading ? <ListIndicator /> : campaignDetails && <CampaignDetails campaignDetails={campaignDetails} />}
      </div>

      {!isDesktopView && (uploadDraftPost || hasDraftPosts || hasReport) && (
        <div css={styles.actionContainer}>
          <div>
            {hasReport ? (
              <>
                <ActionButton to={`/casting/your_job/${engagementId}/post`}>{t('Button.Post List')}</ActionButton>
                <ActionButton to={`/casting/your_job/${engagementId}/report`}>{t('Button.View Report')}</ActionButton>
              </>
            ) : hasDraftPosts ? (
              <ActionButton to={`/casting/your_job/${engagementId}/post`}>{t('Button.Post List')}</ActionButton>
            ) : uploadDraftPost ? (
              <ActionButton isPrimary to={`/casting/your_job/${engagementId}/post/add`}>
                {t('Button.Upload Draft Post')}
              </ActionButton>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

const ActionButton = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{ isPrimary?: boolean }>`
  align-items: center;
  background-color: ${({ isPrimary }) => (isPrimary ? '#3892e5' : '#fff')};
  ${({ isPrimary }) => (!isPrimary ? 'border: 1px solid #dee5ec' : '')};
  border-radius: 5px;
  color: ${({ isPrimary }) => (isPrimary ? '#fff' : '#6e7c89')};
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 32px;
  justify-content: center;
  width: 144px;
`;

const styles = {
  actionContainer: css`
    align-items: center;
    background-color: #fff;
    bottom: 0;
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    height: 72px;
    position: fixed;
    width: 100%;

    & > div {
      display: flex;
      justify-content: flex-end;
      margin: 0 16px;
      width: 100%;

      & > a {
        @media (max-width: ${ViewportType.SMALL}px) {
          height: 40px;
          width: fill-available;
        }
      }

      & > a:nth-of-type(2) {
        margin-left: 8px;
      }
    }
  `,
  container: css`
    margin: 16px;

    @media (max-width: ${ViewportType.SMALL}px) {
      margin: 0;
    }
  `,
  headerContainer: css`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;

    & > div:nth-of-type(1) {
      display: flex;
      flex-basis: 50%;
    }

    & > div:nth-of-type(2) {
      display: flex;
      flex-basis: 50%;
      justify-content: flex-end;

      & > a:nth-of-type(2) {
        margin-left: 8px;
      }
    }
  `,
};

export default Details;
