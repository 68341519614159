import React from 'react';
import { useQuery } from '@apollo/client';
import CarouselModal from '@src/components/molecules/CarouselModal/CarouselModal';
import DataOpacityWrapper, { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import ImageSlider from '@src/components/molecules/Slider';
import { usePostDetailModal } from '@src/components/organisms/AnalyticsInstagram/Dashboard/PostCard/BasePostList/usePostDetailModal';
import { convertDurationToHhMmSs } from '@src/libs/date';
import { formatIntNumber } from '@src/libs/format';
import { defaultEmptyImage } from '@src/libs/image';
import { useQueryHelper, useUuumInfluencerView } from '@src/libs/hooks';
import * as StyledComponents from '@src/components/organisms/AnalyticsYoutube/StyledComponents';
import { FeedVideos } from '@src/components/organisms/AnalyticsYoutube/types';
import AnalyticsPostDetail from '../../AnalyticsPostDetail';
import * as GET_AVATAR_AND_NAME from './InfluencerYoutubeAnalyticsChannelDetails.graphql';
import {
  InfluencerYoutubeAnalyticsChannelDetails,
  InfluencerYoutubeAnalyticsChannelDetailsVariables,
} from './__generated__/InfluencerYoutubeAnalyticsChannelDetails';

interface FeedVideosProps {
  className?: string;
  videos?: FeedVideos[] | null;
  socialMediaAccountId: number;
}

const FeedVideosList = (props: FeedVideosProps) => {
  const { className, videos, socialMediaAccountId } = props;
  const { search, t, enqueueSnackbar } = useQueryHelper();
  const { isUuumView } = useUuumInfluencerView();

  const { data } = useQuery<
    InfluencerYoutubeAnalyticsChannelDetails,
    InfluencerYoutubeAnalyticsChannelDetailsVariables
  >(GET_AVATAR_AND_NAME, {
    variables: { socialAccountId: socialMediaAccountId },
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev,
  } = usePostDetailModal(videos || []);

  const getParams = () => {
    const queryParams = new URLSearchParams(search);

    if (socialMediaAccountId) {
      queryParams.set('socialMediaAccountId', String(socialMediaAccountId));
    }

    return queryParams.toString();
  };

  return (
    <>
      <DataOpacityWrapper
        className={className}
        header={<SectionHeader>{t('Title.Videos')}</SectionHeader>}
        dataNotAvailable={!videos || videos.length === 0}
        css={{ minHeight: '180px' }}
      >
        <>
          {videos?.length && (
            <ImageSlider data={videos} css={{ paddingLeft: '16px' }}>
              {videos.map(video => (
                <StyledComponents.VideoContainerWrapper key={video.id}>
                  <StyledComponents.VideoWrapper>
                    <StyledComponents.VideoThumbnail
                      src={defaultEmptyImage(video.thumbNail)}
                      onClick={() => handleClickPost(video.id)}
                    />
                    <StyledComponents.VideoDuration>
                      {convertDurationToHhMmSs(video.videoDuration)}
                    </StyledComponents.VideoDuration>
                  </StyledComponents.VideoWrapper>
                  <StyledComponents.VideoTitle title={video.title}>{video.title}</StyledComponents.VideoTitle>
                  <StyledComponents.EngagementInfo>
                    <StyledComponents.InfoWrapper>
                      <StyledComponents.Label>{t('View')}</StyledComponents.Label>
                      <StyledComponents.Count>{formatIntNumber(video.views)}</StyledComponents.Count>
                    </StyledComponents.InfoWrapper>
                    <StyledComponents.InfoWrapper>
                      <StyledComponents.Label>{t('Like')}</StyledComponents.Label>
                      <StyledComponents.Count>{formatIntNumber(video.likes)}</StyledComponents.Count>
                    </StyledComponents.InfoWrapper>
                    <StyledComponents.InfoWrapper>
                      <StyledComponents.Label>{t('General.Comments')}</StyledComponents.Label>
                      <StyledComponents.Count>{formatIntNumber(video.comments)}</StyledComponents.Count>
                    </StyledComponents.InfoWrapper>
                  </StyledComponents.EngagementInfo>
                </StyledComponents.VideoContainerWrapper>
              ))}
            </ImageSlider>
          )}

          <StyledComponents.MoreText
            //  TODO: remove after full migration to AnyX
            to={isUuumView ? `/profile/feed_videos?${getParams()}` : `/analytics/feed_videos?${getParams()}`}
          />
        </>
      </DataOpacityWrapper>
      <CarouselModal
        open={!!openPostDetail}
        title=""
        cancel="OK"
        onClose={handleClosePostDetail}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        siblingPostId={siblingPostId}
      >
        <AnalyticsPostDetail
          postId={Number(openPostDetail)}
          accountId={socialMediaAccountId}
          accountName={data?.influencerYoutubeAnalyticsChannelDetails.channelName || ''}
          avatarUrl={data?.influencerYoutubeAnalyticsChannelDetails.channelAvatar || ''}
        />
      </CarouselModal>
    </>
  );
};

export default FeedVideosList;
