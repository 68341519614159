import React from 'react';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Grid from '@src/components/atoms/Grid';
import StatusIndicator from '@src/components/organisms/Emails/StatusIndicator';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import useGetEmailTemplate from '@src/pages/Emails/Edit/useGetEmailTemplate';
import Overview from './Overview';
import Recipient from './Recipient';

const Reports = () => {
  const { params, pathname, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const emailId = params.id;
  const { getEmailTemplate } = useGetEmailTemplate({ emailId: Number(emailId) });
  const tabs = [
    { title: 'Overview', to: `/emails/${emailId}/overview` },
    { title: 'Recipient', to: `/emails/${emailId}/recipient` },
  ];
  const isOverviewTab = pathname === `/emails/${emailId}/overview`;

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <div css={styles.navigatorContainer}>
          <BackNavigator title="Email Report" to="/emails" />
          {getEmailTemplate?.status && <StatusIndicator status={getEmailTemplate?.status} />}
        </div>
      )}

      <div css={styles.infoContainer}>
        <Grid lg={8} md={10} xs={12}>
          <div css={styles.tabsContainer}>
            {tabs.map((tab, index) => {
              const { title, to } = tab;
              const isActive = pathname.includes(to);

              return (
                <Tab isActive={isActive} key={index} to={to}>
                  {t(title)}
                </Tab>
              );
            })}
          </div>

          {isOverviewTab ? (
            <Overview emailId={emailId} />
          ) : isMobileView ? (
            <div css={styles.noAvailableFeatureContent}>
              <div>
                <div>{t('Annotation.This feature is only available on PCs')}</div>
                <Link to="/emails">{t('Anchor.Back')}</Link>
              </div>
            </div>
          ) : (
            <Recipient emailId={emailId} />
          )}
        </Grid>
      </div>
    </div>
  );
};

const Tab = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{ isActive: boolean }>`
  color: #27313b;
  font-size: 14px;
  font-weight: ${({ isActive }) => (isActive ? '600' : '400')};
  margin-right: 16px;
  padding: 0 8px 14px 8px;
  ${({ isActive }) => (isActive ? 'border-bottom: 4px solid #3892e5;' : '')}
`;

const styles = {
  container: css`
    padding: 16px 24px;

    @media (max-width: ${ViewportType.TABLET}px) {
      padding: 16px;
    }
  `,
  infoContainer: css`
    display: flex;
    justify-content: center;
  `,
  navigatorContainer: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;

    & > div:nth-of-type(1) {
      margin-right: 16px;
    }
  `,
  noAvailableFeatureContent: css`
    align-items: center;
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    display: flex;
    height: 88px;
    justify-content: center;
    text-align: center;

    /* stylelint-disable no-descending-specificity */
    & > div {
      & > div:nth-of-type(1) {
        color: #000;
        font-size: 14px;
        margin-bottom: 8px;
      }

      & > a {
        color: #3892e5;
        font-size: 14px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  `,
  tabsContainer: css`
    background-color: #fff;
    border-bottom: 1px solid #dee5ec;
    border-radius: 3px 3px 0 0;
    box-shadow: 0 2px 1px rgba(110, 124, 137, 0.05), 0 0 2px rgba(110, 124, 137, 0.25);
    display: flex;
    flex-wrap: wrap;
    padding: 16px 24px 0 24px;
  `,
};

export default Reports;
