import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';
import FeedVideosList from '@src/components/organisms/AnalyticsYoutube/Dashboard/PostCard/FeedVideos/FeedVideosList';
import { ListIndicator } from '@src/components/molecules/Indicator';
import Pager from '@src/components/molecules/Pager';
import { SliderComponents } from '@src/components/molecules/SliderTable';
import FeedVideosTable from '@src/components/organisms/AnalyticsYoutube/Dashboard/PostCard/FeedVideos/FeedVideosTable';
import PostFeedFilters from '@src/components/organisms/PostFeedList/PostFeedFilters';
import { getPageInfo } from '@src/components/organisms/AnalyticsYoutube/Dashboard/PostCard/FeedVideos/utils';
import { LIMIT } from '@src/libs/constant';
import { SortType } from '@src/libs/filter';
import { getOffset, getPageInfo as getPageInfoPaging } from '@src/libs/paging';
import BasePostFilter from '@src/components/organisms/AnalyticsShared/BasePostListFilter';
import BasePageCount from '@src/components/molecules/PageCount';
import { ViewportType } from '@src/libs/theme';
import { YoutubeAnalyticsPostsSortOrder, YoutubeAnalyticsPostsSortInput } from '@src/__generated__/globalTypes';
import { useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { BasePostFilterType } from '@src/components/organisms/AnalyticsShared/BasePostListFilter/types';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import * as INFLUENCER_YT_POSTS_VIDEOS_LIST from './InfluencerYoutubeAnalyticsPostsList.graphql';
import {
  InfluencerYoutubeAnalyticsPostsList,
  InfluencerYoutubeAnalyticsPostsListVariables,
} from './__generated__/InfluencerYoutubeAnalyticsPostsList';

interface TemplateProps {
  currentPage: number;
  socialMediaAccountId: string;
  filter: BasePostFilterType;
  setFilter: (filter: BasePostFilterType) => void;
  sort: SortType<YoutubeAnalyticsPostsSortOrder>;
  setSort: (sort: SortType<YoutubeAnalyticsPostsSortOrder>) => void;
}

const Template = (props: TemplateProps) => {
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });
  const { t, enqueueSnackbar, search } = useQueryHelper();

  const { filter, setFilter, sort, setSort, currentPage, socialMediaAccountId } = props;

  const { data, loading } = useQuery<InfluencerYoutubeAnalyticsPostsList, InfluencerYoutubeAnalyticsPostsListVariables>(
    INFLUENCER_YT_POSTS_VIDEOS_LIST,
    {
      variables: {
        socialAccountId: Number(socialMediaAccountId),
        keyword: filter.keyword,
        offset: getOffset(currentPage),
        limit: LIMIT,
        orderBy: sort as YoutubeAnalyticsPostsSortInput,
        ...filter.period,
      },
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
    }
  );

  if (loading) {
    return (
      <ListIndicator
        css={css`
          margin: 0;
        `}
      />
    );
  }

  const { totalCount, totalPages, firstIndex, lastIndex } = getPageInfo(currentPage, data);
  const pageInfo = getPageInfoPaging(currentPage, data?.getTotalNumber.totalNumber || 0);
  const avatarUrl = data?.influencerYoutubeAnalyticsChannelDetails.channelAvatar || '';
  const channelName = data?.influencerYoutubeAnalyticsChannelDetails.channelName || '';

  return (
    <>
      {isDesktopView ? (
        <Container>
          <div css={{ marginBottom: '16px' }}>
            <BackNavigator title="Video List" to={`/analytics${search}`} />
          </div>
          <BasePostFilter filter={filter} setFilter={setFilter} />
          <ButtonWrapper css={borderTop}>
            <PageCount total={totalCount} countLabel="Videos" />
          </ButtonWrapper>
          <SliderComponents.Wrapper css={[marginWrapper, padding0]}>
            <FeedVideosTable
              socialMediaAccountId={Number(socialMediaAccountId)}
              videos={data?.postList.posts || []}
              sort={sort}
              setSort={setSort}
              firstIndex={firstIndex}
              accountName={channelName}
              avatarUrl={avatarUrl}
            />
          </SliderComponents.Wrapper>
          <SliderComponents.Pager
            currentPage={currentPage}
            totalPages={totalPages}
            totalCount={totalCount}
            first={firstIndex}
            last={lastIndex}
          />
        </Container>
      ) : (
        <Wrapper>
          <Card>
            <PostFeedFilters filter={filter} setFilter={setFilter} />
            <FeedVideosList
              accountId={Number(socialMediaAccountId)}
              totalCount={pageInfo.totalCount}
              videos={data?.postList.posts || []}
              accountName={channelName}
              avatarUrl={avatarUrl}
            />
          </Card>
          <Pager
            currentPage={currentPage}
            totalPages={pageInfo.totalPages}
            totalCount={pageInfo.totalCount}
            first={pageInfo.firstIndex}
            last={pageInfo.lastIndex}
          />
        </Wrapper>
      )}
    </>
  );
};

const borderTop = css`
  border-top: 1px solid #dee5ec;
`;
const padding0 = css`
  padding: 0;
`;
const marginWrapper = css`
  margin: 0;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
`;

const Card = styled.div`
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5);
  background-color: #fff;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 16px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 35px;
  padding: 8px 15px;
  background-color: #fff;
  border: 1px solid #dee5ec;
  border-top: none;
  border-bottom: none;
`;

const PageCount = styled(BasePageCount)`
  display: flex;
  justify-content: flex-start;
  flex: 1;
  padding-right: 8px;
`;

export default Template;
