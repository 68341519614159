import styled from '@emotion/styled';
import * as React from 'react';

interface EmptyProps {
  text: string | null;
  lines?: number;
  className?: string;
  onClick?: () => void;
}
const TextCutter = ({ text, lines, className, onClick }: EmptyProps) => (
  <Wrapper className={className} lines={lines} title={text || ''} onClick={onClick}>
    {text}
  </Wrapper>
);

const Wrapper = styled.p<{ lines?: number }>`
  text-overflow: ellipsis;
  overflow: hidden;
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-line-clamp: ${props => props.lines || 1}; /* number of lines to show */
  -webkit-box-orient: vertical;
  /* stylelint-enable */
  word-break: break-all;
  height: fit-content;
`;

export default TextCutter;
