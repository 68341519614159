import styled from '@emotion/styled';
import React from 'react';
import { formatDate, formatIntNumber } from '../../../../libs/format';
import { useQueryHelper } from '../../../../libs/hooks';
import { getSocialMediaLabel } from '../../../../libs/utils';
import { PalleteButton } from '../../../atoms/Button';
import { mainRed, mainWhite } from '../../../../libs/pallete';
import { DialogModal, DialogTitle, DialogContent, WarningIcon } from '../../../molecules/DialogModal';
import { getIconByName } from '../../../../libs/getIconByName';
import SocialAccount from '../../../molecules/SocialAccount';
import { ListIndicator } from '../../../molecules/Indicator';
import { RadioIcon } from '../../../atoms/RadioButtonElement';
import { SocialAccountType } from '../../../../__generated__/globalTypes';
import { JoinButtonProps } from './types';

const JoinButton = (props: JoinButtonProps) => {
  const { t, history } = useQueryHelper();
  const {
    buttonText,
    campaignInfo,
    isOpeningDialogForJoin,
    isOpeningAlertForRegion,
    isOpeningAlertForConnectAccount,
    openDialogForJoin,
    closeAllDialog,
    closeAlertForRegion,
    closeAlertForConnectAccount,
    activeStep,
    handleJoin,
    closeAlertAndGoConnectProfile,
    isOpeningAlertForSignInAgain,
    handleSignInAgain,
    closeAlertForSignInAgain,
    isMobile,
    closeAlertAndGoEditProfile,
    dialogForSelectJoiningAccount,
    joinableAccountsData,
    dialogIgNonBusinessCantJoin,
  } = props;
  const { selectedAccount, setSelectedAccount } = joinableAccountsData;

  const { currency, start, end, maximumRevenuePerInfluencer, minimumPaymentAmount, socialMedia } = campaignInfo;

  const buttonProps = {
    text: buttonText || 'Join Now',
    onClick: dialogForSelectJoiningAccount.open,
    palleteColor: mainRed,
    height: '32px',
    width: isMobile ? '100%' : 'max-content',
  };
  const campaignSocialMediaLabel = getSocialMediaLabel(socialMedia);
  const hasJoinableAccounts = !!joinableAccountsData.data.length;

  const handleCloseChooseAccountWithRedirect = (shouldRedirect: boolean) => {
    if (shouldRedirect) {
      history.push('/settings/social_connect');
    }

    dialogForSelectJoiningAccount.close();
  };

  const dialogContents = [
    {
      title: t('TermsConditionsPayment'),
      text: (
        <List>
          <ListItem>{t('TheCampaignPeriodIs', { start: formatDate(start), end: formatDate(end) })}</ListItem>
          {maximumRevenuePerInfluencer && (
            <ListItem>
              {t('RevenueLimitGuideLine', { price: formatIntNumber(maximumRevenuePerInfluencer), currency })}
            </ListItem>
          )}
          <ListItem>{t('RevenueQuestionMask', { price: formatIntNumber(minimumPaymentAmount), currency })}</ListItem>
          <ListItem>{t('Terms1')}</ListItem>
          <ListItem>{t('Terms2')}</ListItem>
          <ListItem>{t('Terms3')}</ListItem>
          <ListItem>{t('Terms4')}</ListItem>
        </List>
      ),
      content: null,
      nextButton: <PalleteButton text="Join Now" palleteColor={mainRed} onClick={handleJoin} />,
      backButton: <PalleteButton palleteColor={mainWhite} onClick={closeAllDialog} text="Close" />,
    },
  ];

  return (
    <>
      <PalleteButton {...buttonProps} />

      {/* Dialogs */}
      <DialogModal
        isOpen={isOpeningAlertForRegion}
        closeModal={closeAlertForRegion}
        NextButton={<PalleteButton text="OK" palleteColor={mainRed} onClick={closeAlertAndGoEditProfile} />}
      >
        <TransparentWrapper>
          <DialogTitle>
            <WarningIcon src={getIconByName('warning')} alt="warning icon" />
            {t('Region was not specified')}
          </DialogTitle>
          <DialogContent>
            <ContentText>{t('Dialog.Fill Your Region')}</ContentText>
          </DialogContent>
        </TransparentWrapper>
      </DialogModal>

      {/* Redirect to Connect Page for IG Non-business accounts */}
      <DialogModal
        isOpen={dialogIgNonBusinessCantJoin.isOpening}
        closeModal={dialogIgNonBusinessCantJoin.close}
        BackButton={<PalleteButton text="Cancel" palleteColor={mainWhite} onClick={closeAllDialog} />}
        NextButton={<PalleteButton text="OK" palleteColor={mainRed} width="100%" href={'/settings/social_connect'} />}
      >
        <TransparentWrapper>
          <DialogTitle>
            <WarningIcon src={getIconByName('warning')} alt="warning icon" />
            {t('Reconnect is required')}
          </DialogTitle>
          <DialogContent>
            <ContentText>{t('Dialog.ReconnectAccountBeforeJoin')}</ContentText>
          </DialogContent>
        </TransparentWrapper>
      </DialogModal>

      {/* Connect Social Media to Join Campaign */}
      <DialogModal
        isOpen={isOpeningAlertForConnectAccount}
        closeModal={closeAlertForConnectAccount}
        NextButton={<PalleteButton text="OK" palleteColor={mainRed} onClick={closeAlertAndGoConnectProfile} />}
      >
        <TransparentWrapper>
          <DialogTitle>
            <WarningIcon src={getIconByName('warning')} alt="warning icon" />
            {t('Connect social account before join campaign')}
          </DialogTitle>
          <DialogContent>
            <ContentText>{t('Dialog.Connect Social Accounts', { media: campaignSocialMediaLabel })}</ContentText>
          </DialogContent>
        </TransparentWrapper>
      </DialogModal>

      {/* Redirect to Social_Network to refresh token */}
      <DialogModal
        isOpen={isOpeningAlertForSignInAgain}
        closeModal={closeAllDialog}
        BackButton={<PalleteButton text="Cancel" palleteColor={mainWhite} onClick={closeAlertForSignInAgain} />}
        NextButton={<PalleteButton text="OK" palleteColor={mainRed} onClick={handleSignInAgain} />}
      >
        <TransparentWrapper>
          <DialogTitle>
            <WarningIcon src={getIconByName('warning')} alt="warning icon" />
            {t('Required sign-in with social platform again')}
          </DialogTitle>
          <DialogContent>
            <ContentText>{t('Dialog.OauthJoinCampaign')}</ContentText>
          </DialogContent>
        </TransparentWrapper>
      </DialogModal>

      <DialogModal
        isOpen={isOpeningDialogForJoin}
        closeModal={closeAllDialog}
        BackButton={dialogContents[activeStep].backButton}
        NextButton={dialogContents[activeStep].nextButton}
      >
        <TransparentWrapper>
          <DialogTitle>{dialogContents[activeStep].title}</DialogTitle>
          <DialogContent>
            <ContentText>{dialogContents[activeStep].text}</ContentText>
            {dialogContents[activeStep].content}
          </DialogContent>
        </TransparentWrapper>
      </DialogModal>

      <DialogModal
        isOpen={dialogForSelectJoiningAccount.isOpening}
        closeModal={() => handleCloseChooseAccountWithRedirect(!hasJoinableAccounts)}
        BackButton={
          <PalleteButton
            text="Cancel"
            palleteColor={mainWhite}
            onClick={() => handleCloseChooseAccountWithRedirect(!hasJoinableAccounts)}
          />
        }
        NextButton={
          hasJoinableAccounts ? (
            <PalleteButton
              text="Next"
              palleteColor={mainRed}
              onClick={() => openDialogForJoin(joinableAccountsData.selectedAccount.needReconnect)}
              isDisabled={!joinableAccountsData.selectedAccount.id}
            />
          ) : undefined
        }
      >
        <StyledDialogTitle>{t('Choose an account to join')}</StyledDialogTitle>
        <DialogContent>
          <ContentText>{t('ChooseJoiningAccountExplained')}</ContentText>
          {joinableAccountsData.loading ? (
            <ListIndicator border={false} />
          ) : hasJoinableAccounts ? (
            joinableAccountsData.data.map(account => {
              const isChecked = selectedAccount.id === account.id;
              // IG nonBusiness accounts must be reconnected before join
              const needRedirectToSocialConnect =
                !account.isBusiness && account.socialMedia === SocialAccountType.INSTAGRAM;

              return (
                <JoinableAccountWrapper key={account.id} isChecked={isChecked}>
                  <RadioIcon
                    isChecked={isChecked}
                    onClick={() => {
                      setSelectedAccount({
                        id: account.id,
                        socialMedia: account.socialMedia,
                        needReconnect: needRedirectToSocialConnect,
                      });
                    }}
                  />
                  <StyledSocialAccount
                    socialMedia={account.socialMedia}
                    src={account.avatar}
                    username={account.username}
                    followers={account.followersCount}
                  />
                  {account.disabled && (
                    <DisabledCover>
                      <CoverText>{t(`This account doesn't match the condition`)}</CoverText>
                    </DisabledCover>
                  )}
                </JoinableAccountWrapper>
              );
            })
          ) : (
            <NoAccounts>
              {t(`Please connect ${campaignSocialMediaLabel} account before joining this campaign`)}
            </NoAccounts>
          )}
        </DialogContent>
      </DialogModal>
    </>
  );
};

const TransparentWrapper = styled.div`
  background-color: transparent;
`;

const List = styled.ul``;

const ListItem = styled.li`
  list-style-type: disc;
  font-size: 13px;
  color: #27313b;

  &:not(:first-of-type) {
    margin-top: 8px;
  }
`;

const ContentText = styled.div`
  white-space: pre-line;
  line-height: 1.5;
  color: #27313b;
  letter-spacing: 0.00938em;
`;

const JoinableAccountWrapper = styled.div<{ isChecked: boolean }>`
  position: relative;
  display: flex;
  place-items: center;
  padding: 10px;
  padding-left: 16px;
  border-radius: 5px;
  box-shadow: ${({ isChecked }) => `0 0 0 ${isChecked ? '3px #3892e5' : '1px #dee5ec'}`};
  margin-top: 24px;
  box-sizing: border-box;

  &:not(:first-of-type) {
    margin-top: 16px;
  }
`;
const StyledDialogTitle = styled(DialogTitle)`
  margin-left: 10px;
`;
const StyledSocialAccount = styled(SocialAccount)`
  margin-left: 8px;
`;
const DisabledCover = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  height: 70px;
  width: 100%;
  background: rgba(39, 49, 59, 0.6);
  border-radius: 5px;
`;
const CoverText = styled.p`
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  text-align: center;
`;
const NoAccounts = styled.div`
  padding: 24px;
  font-size: 14px;
`;

export default JoinButton;
