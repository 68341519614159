import {
  CampaignSocialMediaType,
  EngagementCampaignPostStatus,
  SocialAccountType,
} from '../../../__generated__/globalTypes';

export const isEditablePost = (postStatus: EngagementCampaignPostStatus) =>
  [EngagementCampaignPostStatus.REVIEWING, EngagementCampaignPostStatus.REJECT].includes(postStatus);

export const getAddPostPath = (engagementId: number) => `/job/engagement/${engagementId}/post/add`;
export const getEditPostPath = (engagementId: number, postId: number) =>
  `/job/engagement/${engagementId}/post/${postId}`;
export const getPostReportPath = (engagementId: number) => `/job/engagement/${engagementId}/report`;

export const convertSocialMedia = (socialMedia: CampaignSocialMediaType) =>
  (socialMedia === CampaignSocialMediaType.INSTAGRAM_STORY
    ? SocialAccountType.INSTAGRAM
    : socialMedia) as SocialAccountType;
