import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import userIcon from '@src/assets/icon/user.svg';
import { formatPercent } from '@src/libs/format';
import HighChart, { Highcharts } from '@src/components/molecules/HighChart';
import ProfileDetailsContentWrapper from '../ProfileDetailsContentWrapper/ProfileDetailsContentWrapper';

export interface AverageAgeAndGenderProps {
  className?: string;
  femaleRate: number | null;
  maleRate: number | null;
  ageGroups: string[];
  maleAges: number[];
  femaleAges: number[];
}

const AverageAgeAndGender = (props: AverageAgeAndGenderProps) => {
  const { t } = useTranslation();
  const { className, maleRate, femaleRate, ageGroups, maleAges, femaleAges } = props;

  const options: Highcharts.Options = {
    chart: {
      height: 270,
    },
    title: {
      text: '',
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: ageGroups,
      plotLines: [
        {
          color: '#dee5ec',
          width: 1,
          value: 6.5,
        },
      ],
    },
    // @ts-ignore
    yAxis: {
      gridLineWidth: 0,
      title: '',
      labels: {
        formatter: function formatter(): string {
          return `${this.value}%`;
        },
      },
    },
    series: [
      {
        type: 'bar',
        name: 'Male',
        color: '#707fc0',
        data: maleAges,
      },
      {
        type: 'bar',
        name: 'Female',
        color: '#ff8097',
        data: femaleAges,
      },
    ],
    tooltip: {
      padding: 0,
      formatter: function formatter(): string {
        return `
          <div style="display:flex;flex-direction:column;border-radius:3px;border: 2px solid #27313b;margin:-2px;background-color:#27313b;font-size:12px;padding:13px 16px;">
            <div style="margin-bottom:8px;">${this.x}</div>
            <div style="display:flex;align-items:center">
              <span style="display:inline-block;margin-right:8px;background-color:${this.point.color};height:12px;width:12px"></span>
              <span style="margin-right:16px">${this.series.name}:
              </span><span>${this.point.y}%</span>
            </div>
          </div>`;
      },
      pointFormat: '',
      borderColor: '#27313b',
      borderWidth: 0,
      backgroundColor: '#27313b',
      style: {
        color: '#ffffff',
      },
      useHTML: true,
      headerFormat: '',
    },
  };

  return (
    <ProfileDetailsContentWrapper
      icon={userIcon}
      className={className}
      title={t('Title.Average Age and Gender')}
      dataNotAvailable={(!maleAges || maleAges.length === 0) && (!femaleAges || femaleAges.length === 0)}
      isAvailableRate={Number(maleRate) > 0 || Number(femaleRate) > 0}
    >
      <LegendBar>
        <LegendText>
          <LegendBox color="#707fc0" />
          <UnitValue>
            <Value>{formatPercent(maleRate)}</Value>
            <Gender>{t('Option.Male')}</Gender>
          </UnitValue>
        </LegendText>
        <LegendText>
          <LegendBox color="#ff567c" />
          <UnitValue>
            <Value>{formatPercent(femaleRate)}</Value>
            <Gender>{t('Option.Female')}</Gender>
          </UnitValue>
        </LegendText>
      </LegendBar>

      <HighChart options={options} />
    </ProfileDetailsContentWrapper>
  );
};

const Gender = styled.span`
  font-size: 13px;
  color: #6e7c89;

  @media (max-width: 800px) {
    font-size: 16px;
    color: #3b4146;
  }
`;
const LegendBar = styled.div`
  display: flex;

  @media (max-width: 800px) {
    justify-content: center;
  }
`;
const LegendText = styled.div`
  display: flex;

  p {
    margin-right: 8px;
  }

  &:last-of-type {
    span {
      margin-right: 0;
    }
  }
`;
const LegendBox = styled.p<{ color: string }>`
  width: 20px;
  height: 20px;
  background-color: ${prop => prop.color};

  @media (max-width: 800px) {
    width: 11px;
    height: 11px;
    border-radius: 1px;
  }
`;
const UnitValue = styled.span`
  font-size: 20px;
  display: flex;
  flex-direction: column;
  margin-right: 18px;
  color: #27313b;

  @media (max-width: 800px) {
    margin-right: 60px;
    flex-direction: column-reverse;
  }
`;
const Value = styled.span`
  font-size: 18px;

  @media (max-width: 800px) {
    font-weight: 500;
  }
`;

export default AverageAgeAndGender;
