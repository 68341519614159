import { useMutation } from '@apollo/client';
import yup, {
  BirthdaySchema,
  CategoriesSchema,
  CountrySchema,
  GenderSchema,
  InfluencerEmailSchema,
  InfluencerPhoneNumberSchema,
  IntroduceSchema,
  NameSchema,
  RegionSchema,
} from '../../../../libs/validation';
import { UpdateInfluencerProfile, UpdateInfluencerProfileVariables } from './__generated__/UpdateInfluencerProfile';
import * as UPDATE_INFLUENCER_PROFILE from './UpdateInfluencerProfile.graphql';

export const ProfileFormSchema = yup.object().shape({
  name: NameSchema,
  categories: CategoriesSchema,
  gender: GenderSchema,
  country: CountrySchema,
  regionId: RegionSchema,
  email: InfluencerEmailSchema,
  phoneNumber: InfluencerPhoneNumberSchema,
  birthday: BirthdaySchema,
  introduce: IntroduceSchema,
});

export const useUpdateInfluencerProfile = () => {
  const [updateInfluencerProfile] = useMutation<UpdateInfluencerProfile, UpdateInfluencerProfileVariables>(
    UPDATE_INFLUENCER_PROFILE
  );

  return updateInfluencerProfile;
};
