import { css } from '@emotion/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface LabelProps {
  title: string;
}

const Label = ({ title }: LabelProps) => {
  const { t } = useTranslation();

  return <p css={labelStyle}>{t(`Label.${title}`)}</p>;
};

const labelStyle = css`
  font-size: 13px;
  font-weight: 600;
  line-height: 1.38;
  letter-spacing: normal;
  color: #27313b;
  margin-bottom: 8px;
`;

export default Label;
