import styled from '@emotion/styled';
import { JAPANESE_LANG } from '@src/libs/constant';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import TermsContentEN from '@src/components/organisms/TermsContent/TermsContentEN';
import TermsContentJP from '@src/components/organisms/TermsContent/TermsContentJP';
import TopPageFooter from '@src/components/organisms/TopPage/Footer';
import TopPageHeader from '@src/components/organisms/TopPage/Header';
import { usePageLayout, useUpdateDocumentTitle } from '@src/libs/hooks';
import { SignUpAndSignInBreakpoint } from '@src/libs/theme';

const Template = () => {
  const { isMobileViewPublicRoutes } = usePageLayout();
  const title = 'pageTitle.Profile';
  const {
    i18n: { language },
  } = useTranslation();
  useUpdateDocumentTitle({ title });

  return (
    <Wrapper>
      <Container>
        <Header href="/" />
        {language === JAPANESE_LANG ? <TermsContentJP /> : <TermsContentEN />}
        {!isMobileViewPublicRoutes && <TopPageFooter css={styles.footer} />}
      </Container>
    </Wrapper>
  );
};

const Header = styled(TopPageHeader)`
  padding-top: 16px;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    background-color: #fff;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 856px;
  align-items: center;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    width: 90%;
  }
`;

const styles = {
  footer: css`
    width: 100%;
  `,
};

export default Template;
