import { InstagramFeedPostSortField } from '@src/__generated__/globalTypes';
import { formatIntNumber, formatDate, formatPercent } from '@src/libs/format';
import { Post } from '../model';

export const getInstagramFeedPostSortFieldType = (field?: string | null) => {
  if (!field) {
    return null;
  }

  return InstagramFeedPostSortField[field as keyof typeof InstagramFeedPostSortField];
};

export const instagramFeedPostListColumns = [
  {
    key: 'postDate',
    title: 'Post Date',
    orderField: InstagramFeedPostSortField.POST_DATE,
    formatter: formatDate,
  },
  {
    key: 'engagement',
    title: 'Engagement',
    orderField: InstagramFeedPostSortField.ENGAGEMENT,
    formatter: formatIntNumber,
  },
  {
    key: 'engagementRate',
    title: 'Engagement Rate',
    orderField: InstagramFeedPostSortField.ENGAGEMENT_RATE,
    formatter: formatPercent,
  },
  {
    key: 'likes',
    title: 'Likes',
    orderField: InstagramFeedPostSortField.LIKE,
    formatter: formatIntNumber,
  },
  {
    key: 'comments',
    title: 'Comment',
    orderField: InstagramFeedPostSortField.COMMENT,
    formatter: formatIntNumber,
  },
  {
    key: 'saved',
    title: 'Save',
    orderField: InstagramFeedPostSortField.SAVED,
    formatter: formatIntNumber,
  },
  {
    key: 'reach',
    title: 'Reach',
    orderField: InstagramFeedPostSortField.REACH,
    formatter: formatIntNumber,
  },
  {
    key: 'impressions',
    title: 'Imps',
    orderField: InstagramFeedPostSortField.IMPRESSIONS,
    formatter: formatIntNumber,
  },
  {
    key: 'positiveRate',
    title: 'Positive Rate',
    orderField: InstagramFeedPostSortField.POSITIVE_RATE,
    formatter: formatPercent,
  },
  {
    key: 'negativeRate',
    title: 'Negative Rate',
    orderField: InstagramFeedPostSortField.NEGATIVE_RATE,
    formatter: formatPercent,
  },
] as { title: string; orderField: InstagramFeedPostSortField; key: keyof Post; formatter: Function }[];
