import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import AddressBookLightGray from '@src/assets/icon/addressBookLightGray.svg';
import AttachmentLightGray from '@src/assets/icon/attachmentLightGray.svg';
import ChannelConnect from '@src/assets/icon/channelConnect.svg';
import MoneyLightGray from '@src/assets/icon/moneyLightGray.svg';
import UserLightGray from '@src/assets/icon/userLightGray.svg';
import UsersLightGray from '@src/assets/icon/usersLightGray.svg';
import WalletGray from '@src/assets/icon/walletGray.svg';
import Grid from '@src/components/atoms/Grid';
import { Icon } from '@src/components/atoms/Icon';
import { useAuthData, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import { InfluencerType } from '@src/__generated__/globalTypes';
import SOCIAL_RATE_CARD from './SnsBudget/queries/GetSocialRateCardForInfluencer.graphql';
import { GetSocialRateCardForInfluencer } from './SnsBudget/queries/__generated__/GetSocialRateCardForInfluencer';

const Menu = () => {
  const { creatorType, userId } = useAuthData();
  const { enqueueSnackbar, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();

  const { data } = useQuery<GetSocialRateCardForInfluencer>(SOCIAL_RATE_CARD, {
    variables: {
      pk: userId,
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const menu = [
    {
      description: `View and edit account's information`,
      icon: UserLightGray,
      title: 'Account',
      to: '/settings/account',
    },
    {
      description: 'View and edit the address information to ship products',
      icon: AddressBookLightGray,
      title: 'Address',
      to: '/settings/address',
    },
    {
      description: 'Connect with your social account to use Analytics data and get sponsorship campaign',
      icon: AttachmentLightGray,
      title: 'Social Connect',
      to: '/settings/social_connect',
    },
    {
      description: 'View and edit the payment information to receive revenue of sponsorship jobs',
      icon: WalletGray,
      title: 'Payment Information',
      to: '/settings/payment_information',
    },
    {
      description: 'Edit your information and set notifications to receive',
      icon: UsersLightGray,
      title: 'Users',
      to: '/settings/users',
    },
    {
      description: 'Set your ideal post price per social media to get more sponsorship campaigns from clients',
      icon: MoneyLightGray,
      // we hide SNS budget for TA/PA influencer
      isHidden: !data?.getInfluencerRateCardForInfluencer,
      title: 'SNS budget',
      to: '/settings/sns_budget',
    },
    {
      description: 'Connect with your LINE account to use Chat and Shopify account to manage your fans',
      icon: ChannelConnect,
      // hide channel connect for non PRO creator type
      isHidden: creatorType !== InfluencerType.PRO,
      title: 'Channel Connect',
      to: '/settings/channel_connect',
    },
  ];

  return (
    <div css={styles.container}>
      {!isMobileView && <div css={styles.title}>{t(`MenuTitle.Settings`)}</div>}

      <div css={styles.menuContainer}>
        {menu
          .filter(item => !item.isHidden)
          .map(item => (
            <Grid key={item.title} md={3} sm={8} xs={12}>
              {isMobileView ? (
                <Link css={styles.mobileCard} to={item.to}>
                  <div>
                    <img alt="icon" height="24" src={item.icon} width="24" />
                  </div>
                  <div>
                    <CardTitle>{t(`MenuTitle.${item.title}`)}</CardTitle>
                    <CardDescription>{t(`Annotation.${item.description}`)}</CardDescription>
                  </div>
                  <div>
                    <Icon color="#6e7c89" icon="chevron_right" />
                  </div>
                </Link>
              ) : (
                <Link css={styles.desktopCard} to={item.to}>
                  <div>
                    <div>
                      <img alt="icon" height="40" src={item.icon} width="40" />
                    </div>
                    <CardTitle>{t(`MenuTitle.${item.title}`)}</CardTitle>
                  </div>
                  <div>
                    <CardDescription>{t(`Annotation.${item.description}`)}</CardDescription>
                  </div>
                </Link>
              )}
            </Grid>
          ))}
      </div>
    </div>
  );
};

const CardDescription = styled.div`
  color: #6e7c89;
  font-size: 12px;
`;

const CardTitle = styled.div`
  color: #27313b;
  font-size: 14px;
  font-weight: 600;
`;

const styles = {
  container: css`
    display: grid;
    justify-content: center;
    margin: 24px;

    @media (max-width: ${ViewportType.TABLET}px) {
      margin: 16px;
    }
  `,
  desktopCard: css`
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    display: flex;
    cursor: pointer;
    flex-wrap: wrap;
    margin: 8px;
    min-height: 131px;
    padding: 16px;

    /* stylelint-disable no-descending-specificity */
    & > div:nth-of-type(1) {
      align-items: center;
      display: flex;
      flex-basis: 100%;

      & > div:nth-of-type(1) {
        margin-right: 8px;
      }
    }
  `,
  menuContainer: css`
    display: flex;
    flex-wrap: wrap;
    max-width: 1080px;

    @media (max-width: ${ViewportType.TABLET}px) {
      justify-content: center;
    }
  `,
  mobileCard: css`
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    padding: 16px;

    & > div:nth-of-type(1) {
      align-items: flex-start;
      display: flex;
      flex-basis: 15%;
      justify-content: center;
    }

    & > div:nth-of-type(2) {
      display: grid;
      flex-basis: 75%;

      & > div:nth-of-type(1) {
        margin-bottom: 3px;
      }
    }

    & > div:nth-of-type(3) {
      align-items: center;
      display: flex;
      flex-basis: 10%;
      justify-content: center;
    }
  `,
  title: css`
    color: #27313b;
    font-size: 18px;
    font-weight: 600;
    margin: 8px;
  `,
};

export default Menu;
