import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { IntercomProvider } from 'react-use-intercom';
import { RecoilRoot } from 'recoil';
import client from './apollo';
import Notification from './components/organisms/Notification';
import { AdminStoreProvider } from './Context';
import './libs/i18n';
import { Maintenance } from './pages';
import Routes from './Routes';

// Maintenance Mode
declare const MAINTENANCE: boolean;

// Google Analytics Tracking Code
declare const GA_TRACKING_CODE: string;
// Google Tag Manager ID
declare const GTM_ID: string;

// Intercom ID depends on workspace
declare const INTERCOM_ID: string;

// tslint:disable-next-line:ordered-imports
import './assets/css/reset.css';

// Google Tag Manager
const tagManagerArgs = {
  gtmId: GTM_ID,
};

// Initialize Google Analytics(required first in the root component)
const initializeReactGA = (trackingCode: string) => {
  ReactGA.initialize(trackingCode);
  ReactGA.pageview(window.location.pathname + window.location.search);
};

const theme = createMuiTheme();

const App = () => {
  const isWithinIframe = window.location !== window.parent.location;

  useEffect(() => {
    if (!!GA_TRACKING_CODE && !isWithinIframe) {
      initializeReactGA(GA_TRACKING_CODE);
    }
    if (GTM_ID) {
      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  return (
    <ApolloProvider client={client}>
      <MuiThemeProvider theme={theme}>
        <AdminStoreProvider>
          <IntercomProvider appId={INTERCOM_ID}>
            <RecoilRoot>
              {MAINTENANCE ? <Maintenance /> : <Routes />}
              <Notification />
              <div id="portal-root" />
            </RecoilRoot>
          </IntercomProvider>
        </AdminStoreProvider>
      </MuiThemeProvider>
    </ApolloProvider>
  );
};

export default App;
