import * as React from 'react';
import ReportList from '../../components/organisms/Report/ReportList';
import { useUpdateDocumentTitle } from '../../libs/hooks';

const Template = () => {
  const title = 'pageTitle.Campaigns Report';
  const pageTitle = 'Campaigns Report';
  const href = 'post';
  useUpdateDocumentTitle({ title, pageTitle, href });

  return <ReportList isEngagement />;
};

export default Template;
