import React from 'react';
import styled from '@emotion/styled';
import css from '@emotion/css';
import { useTranslation } from 'react-i18next';
import { LinkWithIcon } from '@src/components/atoms/List';
import { MENUS } from '@src/libs/constant';
import arrowRight from '@src/assets/icon/redArrowRight.svg';
import { useUuumInfluencerView } from '@src/libs/hooks';

const settingsItems = [
  {
    id: 1,
    title: MENUS.ProfileSettings,
    href: '/profile/settings/edit',
  },
  {
    id: 2,
    title: MENUS.SocialAccountConnect,
    href: '/profile/settings/connect',
  },
  {
    id: 3,
    title: MENUS.PaymentInformation,
    href: '/profile/settings/payment',
  },
  {
    id: 4,
    title: MENUS.AddressInformation,
    href: '/profile/settings/address',
  },
];
const uuumSettings = [
  {
    id: 1,
    title: MENUS.ProfileSettings,
    href: '/profile/settings/edit',
  },
  {
    id: 2,
    title: MENUS.SocialAccountConnect,
    href: '/profile/settings/connect',
  },
  {
    id: 3,
    title: MENUS.AddressInformation,
    href: '/profile/settings/address',
  },
];

const Template = () => {
  const { t } = useTranslation();
  const { isUuumView } = useUuumInfluencerView();
  const settings = isUuumView ? uuumSettings : settingsItems;

  return (
    <Wrapper>
      <SettingsList>
        {settings.map(item => (
          <LinkWithIcon
            key={item.id}
            title={t(`MenuTitle.${item.title}`)}
            href={item.href}
            icon={<img src={arrowRight} alt="red_arrow_right" />}
            css={[listStyles]}
          />
        ))}
      </SettingsList>
    </Wrapper>
  );
};

export default Template;

const Wrapper = styled.section`
  width: 100%;
`;
const SettingsList = styled.div`
  background-color: #fff;
  box-shadow: 0 1px 0 0 #dee5ec, 0 3px 6px 1px #0000000c;
`;
const listStyles = css`
  padding: 16px 24px;
`;
