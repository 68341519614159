import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import ReactPlayer from 'react-player';
import Carousel from '@src/components/molecules/Carousel';
import VideoPreview from '@src/components/atoms/VideoPreview';
import { isVideoThumbnail } from '@src/libs/image';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { useQueryHelper } from '@src/libs/hooks';
import ImageSlider from '@src/components/molecules/Slider';
import * as SliderStyledComponents from './SliderStyledComponents';
import * as StyledComponents from './StyledComponents';
import InfluencerInfo from './InfluencerInfo';
import InstagramEngagmentInfo from './instagram/EngagementInfo';
import {
  FacebookPageEngagementInfoProps,
  InstagramEngagementInfoProps,
  YoutubeEngagementInfoProps,
  TwitterEngagementInfoProps,
} from './types';
import YoutubeEngagementInfo from './youtube/EngagementInfo';
import FacebookPageEngagementInfo from './facebookPage/EngagementInfo';
import TwitterEngagementInfo from './twitter/EngagementInfo';
import { useThumbnailDimensionsOnResize } from './useThumbnailDimensionsOnResize';

interface PostDetailProps {
  accountType?: SocialAccountType;
  engagement:
    | InstagramEngagementInfoProps
    | YoutubeEngagementInfoProps
    | FacebookPageEngagementInfoProps
    | TwitterEngagementInfoProps;
  avatarUrl: string | null;
  accountName: string | null;
  followers: number | null;
  content: string;
  commentList: { id: number | string; content: string; username: string | null }[];
  mediaUrls: string[] | null;
  postDate?: string;
  currSlideIdx?: number;
  setCurrentSlideIdx?: (idx: number) => void;
  postsPreviewData?: { id: number; thumbNail: string | null }[];
  hideFollowersCount?: boolean;
  videoUrl?: string;
  videoTags?: string[];
  videoTitle?: string;
  videoPostDate?: string;
}

const PostDetail = (props: PostDetailProps) => {
  const {
    avatarUrl,
    accountName,
    followers,
    content,
    commentList,
    mediaUrls,
    engagement,
    postDate,
    currSlideIdx = 0,
    setCurrentSlideIdx,
    postsPreviewData,
    hideFollowersCount,
    videoUrl,
    videoTags,
    videoTitle,
    videoPostDate,
  } = props;

  const accountType = props.accountType || SocialAccountType.INSTAGRAM;
  const { t } = useQueryHelper();
  const [width, height] = useThumbnailDimensionsOnResize(accountType);
  const [, setTagRecommend] = useState('');
  const hasComments = !!commentList.length;
  const hasPreview = !!(mediaUrls?.length || videoUrl || postsPreviewData?.length);

  const getEngagementTemplate = () => {
    switch (accountType) {
      case SocialAccountType.YOUTUBE:
        return <YoutubeEngagementInfo {...(engagement as YoutubeEngagementInfoProps)} />;
      case SocialAccountType.FACEBOOK_PAGE:
        return <FacebookPageEngagementInfo {...(engagement as FacebookPageEngagementInfoProps)} />;
      case SocialAccountType.TWITTER:
        return <TwitterEngagementInfo {...(engagement as TwitterEngagementInfoProps)} />;
      case SocialAccountType.INSTAGRAM:
      default:
        return <InstagramEngagmentInfo {...(engagement as InstagramEngagementInfoProps)} />;
    }
  };

  return (
    <StyledComponents.ContainerWrapper hasPreview={hasPreview}>
      <StyledComponents.Container>
        <StyledComponents.TopHeading>
          <InfluencerInfo
            avatar={avatarUrl}
            name={accountName}
            accountType={accountType}
            followersCount={followers}
            postDate={postDate}
            hideFollowersCount={hideFollowersCount}
          />
        </StyledComponents.TopHeading>

        <StyledComponents.MainContent>
          {hasPreview && (
            <StyledComponents.LeftContent css={{ width }}>
              {(postsPreviewData?.length || mediaUrls?.length) && (
                <Carousel
                  sources={
                    postsPreviewData?.length
                      ? (postsPreviewData.map(el => el.thumbNail) as string[])
                      : mediaUrls?.length
                      ? mediaUrls
                      : []
                  }
                  afterChangeHandler={idx => {
                    // eslint-disable-next-line no-unused-expressions
                    setCurrentSlideIdx?.(idx);
                  }}
                  currSlideIdx={currSlideIdx}
                  width={width}
                  height={height}
                />
              )}
              {videoUrl && (
                <>
                  <StyledComponents.ReactPlayerWrapper>
                    <ReactPlayer url={videoUrl} width={width} height={height} />
                  </StyledComponents.ReactPlayerWrapper>
                  <div
                    css={css`
                      overflow-y: auto;
                      max-height: 159px;
                      margin-top: 16px;
                    `}
                  >
                    {videoTags?.map((tag, i) => (
                      <StyledComponents.VideoTag key={`${tag}_${i}`} onClick={() => setTagRecommend(tag)}>
                        #{tag}
                      </StyledComponents.VideoTag>
                    ))}
                    <StyledComponents.VideoPostTitle>{videoTitle}</StyledComponents.VideoPostTitle>
                    <span
                      css={css`
                        margin-top: 8px;
                        font-size: 12px;
                      `}
                    >
                      {videoPostDate}
                    </span>
                  </div>
                </>
              )}
            </StyledComponents.LeftContent>
          )}

          <StyledComponents.RightContent css={{ width }}>
            {/* Engagement Info */}
            {getEngagementTemplate()}
            {/* Post Caption */}
            <StyledComponents.CaptionSection>
              <StyledComponents.Title>{t('Post’s Caption')}</StyledComponents.Title>
              <StyledComponents.PostCaptionWrapper>
                <StyledComponents.Caption>{content}</StyledComponents.Caption>
              </StyledComponents.PostCaptionWrapper>
            </StyledComponents.CaptionSection>
            {/* Post Comments */}
            {hasComments && (
              <StyledComponents.CommentsSection>
                <StyledComponents.Title>{t('General.Comments')}</StyledComponents.Title>
                <StyledComponents.CaptionFrame>
                  {commentList.map(item => (
                    <StyledComponents.CommentsWrapper key={item.id}>
                      <StyledComponents.TextWrapper>
                        <StyledComponents.CommentUsername text={item.username || t('no username')} />
                        <StyledComponents.CommentText>{item.content}</StyledComponents.CommentText>
                      </StyledComponents.TextWrapper>
                    </StyledComponents.CommentsWrapper>
                  ))}
                </StyledComponents.CaptionFrame>
              </StyledComponents.CommentsSection>
            )}
          </StyledComponents.RightContent>
        </StyledComponents.MainContent>
      </StyledComponents.Container>
      {postsPreviewData && postsPreviewData.length > 1 && (
        <SliderStyledComponents.PostsPreviewWrapper>
          <SliderStyledComponents.TotalPostsPreview>
            {/* count is starting from 1 -> currSlideIdx + 1 */}
            {`${t('General.Post')} ${currSlideIdx + 1} / ${postsPreviewData.length}`}
          </SliderStyledComponents.TotalPostsPreview>
          <ImageSlider
            data={postsPreviewData}
            css={css`
              padding-top: 8px;
            `}
            slideWidth={112}
            hasBgImage={false}
            sliderArrowCss={css`
              width: 24px;
              height: 48px;
              top: 30px;
            `}
            currentSlideIdx={currSlideIdx}
            sliderLength={postsPreviewData.length}
          >
            {postsPreviewData.map((item, idx) => {
              const isActive = currSlideIdx === idx;

              return (
                <SliderStyledComponents.PostImageWrapper key={item.id}>
                  <SliderStyledComponents.Sizer>
                    {isVideoThumbnail(item.thumbNail) ? (
                      <VideoPreviewWrapper isActive={isActive} onClick={() => setCurrentSlideIdx?.(idx)}>
                        <VideoPreview url={item.thumbNail} width="96" height="96" />
                      </VideoPreviewWrapper>
                    ) : (
                      <SliderStyledComponents.PostImage
                        src={item.thumbNail}
                        onClick={() => setCurrentSlideIdx?.(idx)}
                        css={css`
                          width: 96px;
                          height: 96px;
                          border: ${isActive ? '3px solid red' : ''};
                          box-sizing: border-box;
                        `}
                      />
                    )}
                  </SliderStyledComponents.Sizer>
                </SliderStyledComponents.PostImageWrapper>
              );
            })}
          </ImageSlider>
        </SliderStyledComponents.PostsPreviewWrapper>
      )}
      {/* Tags Recommend Dialog  */}
      {/* TODO: implement it if we will need */}
      {/* <Dialog
        visible={!!tagRecommend}
        onClose={() => setTagRecommend('')}
        css={css`
          padding: 0;
        `}
        maxWidth="lg"
      >
        <TagRecommend
          tagRecommend={tagRecommend}
          channelName={channelName || ''}
          closeModal={() => setTagRecommend('')}
          isInfluencerPage
        />
      </Dialog> */}
    </StyledComponents.ContainerWrapper>
  );
};

const VideoPreviewWrapper = styled.div<{ isActive: boolean }>`
  border: ${props => (props.isActive ? '3px solid red' : '')};
  cursor: pointer;
`;

export default PostDetail;
