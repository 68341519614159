import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { ApolloError } from '@apollo/client';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useQueryHelper } from '@src/libs/hooks';
import InitialLoading from '@src/components/molecules/InitialLoading';
import { getMessagesFromFetchResult, UNEXPECTED_ERROR } from '@src/libs/error';
import { ConnectType, getCallbackUrl, getConnectState } from '@src/libs/auth';
import { AnyXAuthSocialAccountType } from '@src/__generated__/globalTypes';
import { useAuthProviderResponse } from '../hooks';
import SOCIAL_AUTH_CONNECT from './AnyXSocialAuthConnect.graphql';
import { AnyXSocialAuthConnect, AnyXSocialAuthConnectVariables } from './__generated__/AnyXSocialAuthConnect';
import { getOptions } from './helpers';

const ConnectComponent = () => {
  const { provider, response } = useAuthProviderResponse('/settings/social_connect');
  const { t, enqueueSnackbar, history } = useQueryHelper();

  const [socialAuthConnect] = useMutation<AnyXSocialAuthConnect, AnyXSocialAuthConnectVariables>(SOCIAL_AUTH_CONNECT);

  const socialAuthConnectCall = async () => {
    const option = await getOptions();

    if (!provider) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      if (window.opener) {
        window.opener.postMessage({ redirectPath: '/settings/social_connect', errorMsg: UNEXPECTED_ERROR }, '*');
        window.close();

        return;
      }
      history.push('/settings/social_connect');

      return;
    }

    // Redirect with state for connecting FB pages and an IG account.
    let socialMediaProvider = provider;
    const connectState = await getConnectState();
    const isIgConnectType = connectState && connectState.connectType === ConnectType.CONNECT_IG_ACCOUNT;

    if (isIgConnectType) {
      socialMediaProvider = AnyXAuthSocialAccountType.INSTAGRAM;
    }

    // Connect
    const variables = {
      input: {
        provider: socialMediaProvider,
        response,
        callbackUrl: getCallbackUrl(provider, FE_REDIRECT_MAPPING.CONNECT),
      },
    };

    const { ok, errors } = await socialAuthConnect({ variables })
      .then(result => {
        if (result && result.data && result.data.anyXSocialAuthConnect && result.data.anyXSocialAuthConnect.ok) {
          return { ok: true, errors: [] };
        } else {
          return { ok: false, errors: getMessagesFromFetchResult(result) };
        }
      })
      .catch((e: ApolloError) => ({
        ok: false,
        errors: [e?.graphQLErrors[0].message],
      }));

    if (ok && errors.length === 0) {
      if (option.shouldShowSuccessMessage) {
        enqueueSnackbar('Success to connect.', { variant: 'success' });
      }

      if (window.opener) {
        window.opener.postMessage({ redirectPath: '/settings/social_connect', state: connectState }, '*');
        window.close();

        return;
      }
      history.push('/settings/social_connect', connectState);
    } else {
      errors.forEach(error => {
        console.error(error);
        enqueueSnackbar(t(error), { variant: 'error' });
        if (window.opener) {
          window.opener.postMessage({ redirectPath: '/settings/social_connect', errorMsg: error }, '*');
          window.close();

          return;
        }
      });
      if (window.opener) {
        window.opener.postMessage({ redirectPath: '/settings/social_connect' }, '*');
        window.close();

        return;
      }

      history.push(isIgConnectType ? '/settings/social_connect/instagram_unable_connect' : '/settings/social_connect');
    }

    return;
  };

  useEffect(() => {
    socialAuthConnectCall();
  }, []);

  return <InitialLoading />;
};

export default ConnectComponent;
