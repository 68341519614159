import React, { useCallback, useState, useEffect } from 'react';
import {
  getToday,
  getThirtyDaysAgo,
  getSevenDaysAgo,
  getFourteenDaysAgo,
  getSixtyDaysAgo,
  getNinetyDaysAgo,
} from '@src/libs/date';
import { Option } from '@src/libs/form';
import CalendarIcon from '@src/assets/icon/calendar.svg';
import { useTranslation } from 'react-i18next';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { css } from '@emotion/react';
import { TFunction } from 'i18next';
import { SingleSelector } from '../Selector';
import { Period } from '../DayPicker/types';

export enum AllowedDateRanges {
  LAST_ONE_WEEK = 'LAST_ONE_WEEK',
  LAST_TWO_WEEKS = 'LAST_TWO_WEEKS',
  LAST_ONE_MONTH = 'LAST_ONE_MONTH',
  LAST_TWO_MONTHS = 'LAST_TWO_MONTHS',
  LAST_THREE_MONTHS = 'LAST_THREE_MONTHS',
}

export const periodForDateRange: Record<AllowedDateRanges, Period> = {
  [AllowedDateRanges.LAST_ONE_WEEK]: { startDate: getSevenDaysAgo(), endDate: getToday() },
  [AllowedDateRanges.LAST_TWO_WEEKS]: { startDate: getFourteenDaysAgo(), endDate: getToday() },
  [AllowedDateRanges.LAST_ONE_MONTH]: { startDate: getThirtyDaysAgo(), endDate: getToday() },
  [AllowedDateRanges.LAST_TWO_MONTHS]: { startDate: getSixtyDaysAgo(), endDate: getToday() },
  [AllowedDateRanges.LAST_THREE_MONTHS]: { startDate: getNinetyDaysAgo(), endDate: getToday() },
};

const getRangeFromPeriod = ({ startDate, endDate }: Period) => {
  switch (JSON.stringify({ startDate, endDate })) {
    case JSON.stringify({ startDate: getSevenDaysAgo(), endDate: getToday() }):
      return AllowedDateRanges.LAST_ONE_WEEK;
    case JSON.stringify({ startDate: getFourteenDaysAgo(), endDate: getToday() }):
      return AllowedDateRanges.LAST_TWO_WEEKS;
    case JSON.stringify({ startDate: getThirtyDaysAgo(), endDate: getToday() }):
      return AllowedDateRanges.LAST_ONE_MONTH;
    case JSON.stringify({ startDate: getSixtyDaysAgo(), endDate: getToday() }):
      return AllowedDateRanges.LAST_TWO_MONTHS;
    case JSON.stringify({ startDate: getNinetyDaysAgo(), endDate: getToday() }):
      return AllowedDateRanges.LAST_THREE_MONTHS;

    default:
      return null;
  }
};

const Icon = <img src={CalendarIcon} style={{ marginRight: '7px' }} />;
export const getDateRangeOptions = (t: TFunction): Option[] => [
  {
    value: AllowedDateRanges.LAST_ONE_WEEK,
    label: t('Last one week'),
    icon: Icon,
  },
  {
    value: AllowedDateRanges.LAST_TWO_WEEKS,
    label: t('Last two weeks'),
    icon: Icon,
  },
  {
    value: AllowedDateRanges.LAST_ONE_MONTH,
    label: t('Last one month'),
    icon: Icon,
  },
  {
    value: AllowedDateRanges.LAST_TWO_MONTHS,
    label: t('Last two months'),
    icon: Icon,
  },
  {
    value: AllowedDateRanges.LAST_THREE_MONTHS,
    label: t('Last three months'),
    icon: Icon,
  },
];

interface MobileDaysRangeSelectorProps {
  onRangeChange: (period: Period) => void;
  initialPeriod: Period;
  className?: string;
}
const MobileDaysRangeSelector = ({ initialPeriod, onRangeChange, className }: MobileDaysRangeSelectorProps) => {
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState<AllowedDateRanges | string>('');

  const options = useCallback(() => getDateRangeOptions(t), []);

  const { startDate, endDate } = Object.keys(AllowedDateRanges).includes(value)
    ? periodForDateRange[value as AllowedDateRanges]
    : initialPeriod;

  useEffect(() => {
    const selectedRange = getRangeFromPeriod(initialPeriod);
    if (selectedRange) {
      setValue(selectedRange);
    }
  }, [initialPeriod.endDate, initialPeriod.startDate]);

  const handleChange = (selectedRange: string) => {
    onRangeChange(periodForDateRange[selectedRange as AllowedDateRanges]);
  };

  return (
    <div css={styles.wrapper} className={className}>
      <div>
        <SingleSelector
          name={'date ranges'}
          value={value}
          options={options()}
          onChange={handleChange}
          customLabel={t('Custom')}
          hideInput
          hideDeselectOption
          isRoundInput={false}
          css={styles.selectorCss}
        />
      </div>
      <p css={styles.selectedRange}>
        {value !== undefined
          ? `${localizedDateFormatter(startDate, 'MMM dd', i18n.language as AppLanguage)} - ${localizedDateFormatter(
              endDate,
              'MMM dd',
              i18n.language as AppLanguage
            )} `
          : '-'}
      </p>
    </div>
  );
};

const styles = {
  wrapper: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 400px;
  `,
  selectorCss: css`
    width: 170px;
  `,
  selectedRange: css`
    display: flex;
    margin-left: auto;
  `,
};
export default MobileDaysRangeSelector;
