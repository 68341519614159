import React from 'react';
import { useHistory } from 'react-router';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { CampaignStatus, getAuthSocialAccountType } from '../../../../libs/campaign';
import { useRedirectUrl } from '../../../../libs/hooks/useRedirectUrl';
import { useRedirectWithinIframe } from '../../../../libs/hooks';
import { SocialAccountType } from '../../../../__generated__/globalTypes';
import BaseJoinButton from './JoinButton';
import { CampaignInfo } from './types';
import { useDialog, useDialogStep } from './useDialog';
import { useJoinableAccounts } from './useJoinableAccounts';

interface JoinButtonProps {
  campaignInfo: CampaignInfo;
  status: CampaignStatus;
  regionId: number | null;
  hasConnectedAccount: boolean;
  isMobile: boolean;
  buttonText?: string;
}

const JoinButton = (props: JoinButtonProps) => {
  const { campaignInfo, status, regionId, hasConnectedAccount, isMobile, buttonText } = props;
  const history = useHistory();
  const { startRedirectProcess } = useRedirectWithinIframe();
  // SignIn again when user join the campaign.
  const { getRedirectUrl } = useRedirectUrl(FE_REDIRECT_MAPPING.JOIN_CAMPAIGN);
  // Dialog which has campaign information
  const dialogForJoin = useDialog();
  const dialogStep = useDialogStep();
  // Alerts
  const alertForRegion = useDialog();
  const alertForConnect = useDialog();
  const alertForSignInAgain = useDialog();
  const dialogForSelectJoiningAccount = useDialog();
  const dialogIgNonBusinessCantJoin = useDialog();

  const joinableAccountsData = useJoinableAccounts({
    isDialogOpen: dialogForSelectJoiningAccount.isOpening,
    campaignId: campaignInfo.id,
    campaignType: campaignInfo.socialMedia,
  });

  const openDialogForJoin = (needRedirectToConnect?: boolean) => {
    dialogForSelectJoiningAccount.close();
    if (needRedirectToConnect) {
      dialogIgNonBusinessCantJoin.open();
    } else {
      dialogForJoin.open();
    }
  };

  const closeAllDialogs = async () => {
    dialogForJoin.close();
    alertForRegion.close();
    alertForConnect.close();
    alertForSignInAgain.close();
    dialogIgNonBusinessCantJoin.close();
  };

  const closeAlertAndGoConnectProfile = () => {
    history.push('/settings/social_connect');
    closeAllDialogs();
  };

  const closeAlertAndGoEditProfile = () => {
    history.push('/settings/account');
    alertForRegion.close();
  };

  // Prepare to join the campaign
  const handleJoin = async () => {
    if (regionId === null) {
      // The user have not filled her region information.
      alertForRegion.open();

      return;
    }

    if (!hasConnectedAccount) {
      // The user must connect account with the campaign's social media.
      alertForConnect.open();

      return;
    }

    await closeAllDialogs();

    alertForSignInAgain.open();

    const {
      selectedAccount: { id, socialMedia },
      fbAccountId,
    } = joinableAccountsData;

    localStorage.setItem('joinCampaignId', String(campaignInfo.id));
    localStorage.setItem('joinAccountSocialMedia', String(socialMedia));
    localStorage.setItem('joinAccountId', String(id));
    localStorage.setItem('fbAccountId', fbAccountId ? String(fbAccountId) : '');
  };

  const handleSignInAgain = async () => {
    const authSocialAccount = getAuthSocialAccountType(campaignInfo.socialMedia);
    if (!authSocialAccount) {
      return;
    }
    const redirectUrl = await getRedirectUrl(authSocialAccount);
    alertForSignInAgain.close();

    if (!redirectUrl) {
      return;
    }

    if (authSocialAccount === SocialAccountType.TWITTER) {
      // Set RedirectType for Twitter OAuth
      localStorage.setItem('redirectType', FE_REDIRECT_MAPPING.JOIN_CAMPAIGN);
    }
    startRedirectProcess({ redirectUrl, redirectType: FE_REDIRECT_MAPPING.JOIN_CAMPAIGN });
  };

  return (
    <BaseJoinButton
      buttonText={buttonText}
      campaignInfo={campaignInfo}
      status={status}
      isOpeningDialogForJoin={dialogForJoin.isOpening}
      isOpeningAlertForRegion={alertForRegion.isOpening}
      isOpeningAlertForConnectAccount={alertForConnect.isOpening}
      isOpeningAlertForSignInAgain={alertForSignInAgain.isOpening}
      handleJoin={handleJoin}
      openDialogForJoin={openDialogForJoin}
      closeAllDialog={closeAllDialogs}
      closeAlertAndGoConnectProfile={closeAlertAndGoConnectProfile}
      closeAlertForRegion={alertForRegion.close}
      closeAlertForConnectAccount={alertForConnect.close}
      activeStep={dialogStep.activeStep}
      handleNext={dialogStep.handleNext}
      handleBack={dialogStep.handleBack}
      handleSignInAgain={handleSignInAgain}
      closeAlertForSignInAgain={alertForSignInAgain.close}
      isMobile={isMobile}
      closeAlertAndGoEditProfile={closeAlertAndGoEditProfile}
      dialogForSelectJoiningAccount={dialogForSelectJoiningAccount}
      joinableAccountsData={joinableAccountsData}
      dialogIgNonBusinessCantJoin={dialogIgNonBusinessCantJoin}
    />
  );
};

export default JoinButton;
