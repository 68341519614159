import styled from '@emotion/styled';
import { Button } from '@src/components/atoms/Button';
import { ViewportType } from '@src/libs/theme';
import TextForm from '@src/components/molecules/TextForm';
import myShopifyDomain from '@src/assets/img/myShopifyDomain.png';
import { css } from '@emotion/react';

export const StyledButton = styled(Button)<{
  isError?: boolean;
  isFillAvailable?: boolean;
  isPrimary?: boolean;
  width?: string;
  height?: string;
}>`
  border: 1px solid ${({ isError, isPrimary }) => (isError ? '#ff5f5f' : isPrimary ? '#3892e5' : '#dee5ec')};
  border-radius: 3px;
  color: ${({ isError, isPrimary }) => (isError || isPrimary ? '#fff' : '#6e7c89')};
  font-size: 12px;
  font-weight: 600;
  height: ${({ height }) => height || '32px'};
  width: ${({ width }) => width || '125px'};

  & > i {
    color: #3892e5;
  }

  @media (max-width: ${ViewportType.TABLET}px) {
    border-radius: 5px;
    height: 40px;
    ${({ isFillAvailable }) => (isFillAvailable ? 'width: fill-available' : '')};
  }
`;

export const DialogAction = styled.div`
  background-color: #f6f8fa;
  border-top: 1px solid #dee5ec;
  display: flex;
  justify-content: flex-end;
  padding: 16px;

  & > button:nth-of-type(2) {
    margin-left: 8px;
  }
`;

export const DialogContent = styled.div`
  padding: 32px;
`;

export const DialogHeader = styled.div`
  align-items: center;
  color: #27313b;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;

  & > img {
    margin-right: 8px;
  }
`;

export const DialogImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0;
`;

export const DialogSubHeader = styled.div`
  color: #27313b;
  font-size: 14px;
`;

export const StyledTextForm = styled(TextForm)`
  & > label {
    font-size: 14px;
  }

  & input {
    background-image: url(${myShopifyDomain});
    background-position: right 16px center;
    background-repeat: no-repeat;
    background-size: 104px 14px;
    border-radius: 2px;
    height: 32px;

    &:not(:placeholder-shown) {
      background-image: none;
    }

    &::placeholder {
      color: #c5d0da;
      font-size: 14px;
      font-weight: normal;
    }
  }
`;

export const styles = {
  channelContainer: css`
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    margin: 16px 0;
  `,
  channelContent: css`
    display: flex;
    flex-wrap: wrap;
    padding: 24px;

    & > div:nth-of-type(1) {
      align-items: center;
      display: flex;
      flex-basis: 50%;
      flex-wrap: wrap;

      & > img {
        margin-right: 8px;
      }

      & > span {
        color: #27313b;
        font-size: 14px;
        font-weight: 600;
      }
    }

    & > div:nth-of-type(2) {
      display: flex;
      flex-basis: 50%;
      justify-content: flex-end;
    }
  `,
  channelHeader: css`
    border-bottom: 1px solid #eef3f7;
    color: #27313b;
    font-size: 18px;
    font-weight: 600;
    padding: 24px;
  `,
  connectedIndicator: css`
    align-items: center;
    background-color: #fff;
    border-radius: 3px;
    color: #6e7c89;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    height: 32px;
    outline: 1px solid #dee5ec;
    padding: 0 8px;

    & > i {
      color: #3892e5;
      font-size: 12px;
      margin-right: 8px;
    }
  `,
  container: css`
    margin: 16px 24px;

    @media (max-width: ${ViewportType.TABLET}px) {
      margin: 16px;
    }
  `,
  dialogCloseBtn: css`
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 16px;
    top: 16px;

    & > i {
      margin: 0;
    }
  `,
  infoContainer: css`
    display: flex;
    justify-content: center;
  `,
  modalHeader: css`
    display: flex;
    align-items: center;
    padding: 16px;
    column-gap: 16px;
    border-bottom: 1px solid #eef3f7;
  `,
  modalTitle: css`
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #27313b;
  `,
  modalFooter: css`
    display: flex;
    background-color: #f6f8fa;
    padding: 16px;
    column-gap: 16px;
    justify-content: flex-end;
    border-radius: 0 0 5px 5px;
  `,
};
