import React from 'react';
import * as StyledComponents from '@src/components/organisms/AnalyticsShared/PostDetail/StyledComponents';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { useQueryHelper } from '@src/libs/hooks';
import { useMediaQuery } from 'react-responsive';
import { ViewportBreakpoint } from '@src/libs/theme';
import Carousel from '../../molecules/Carousel';
import InfluencerInfo from '../AnalyticsShared/PostDetail/InfluencerInfo';
import { StoryDetail } from './types';
import EngagementStats from './EngagementStats';

export interface TemplateProps {
  post?: StoryDetail | null;
}

const Template = (props: TemplateProps) => {
  const { post } = props;
  const { t } = useQueryHelper();
  const isMobile = useMediaQuery({ maxWidth: ViewportBreakpoint.MOBILE });

  const [width, height] = isMobile ? ['295px', '295px'] : ['400px', '400px'];
  const hasPreview = !!post?.postImageUrl?.length;

  return (
    <StyledComponents.ContainerWrapper hasPreview={hasPreview}>
      <StyledComponents.Container>
        <StyledComponents.TopHeading>
          <InfluencerInfo
            avatar={post?.avatarUrl || null}
            name={post?.accountName || null}
            accountType={SocialAccountType.INSTAGRAM}
            followersCount={post?.followers || null}
          />
        </StyledComponents.TopHeading>

        <StyledComponents.MainContent>
          {hasPreview && (
            <StyledComponents.LeftContent css={{ width }}>
              <Carousel sources={post?.postImageUrl ? [post?.postImageUrl] : []} width={width} height={height} />
            </StyledComponents.LeftContent>
          )}

          <StyledComponents.RightContent css={{ width }}>
            {/* Engagement Info */}
            <EngagementStats
              tapsForward={post?.tapsForward}
              tapsBack={post?.tapsBack}
              reach={post?.reach}
              impressions={post?.impressions}
              exits={post?.exits}
              comments={post?.comments}
              postUrl={post?.postUrl}
            />
            {/* Post Caption */}
            <StyledComponents.CaptionSection>
              <StyledComponents.Title>{t('Post’s Caption')}</StyledComponents.Title>
              <StyledComponents.PostCaptionWrapper>
                <StyledComponents.Caption>{post?.content}</StyledComponents.Caption>
              </StyledComponents.PostCaptionWrapper>
            </StyledComponents.CaptionSection>
          </StyledComponents.RightContent>
        </StyledComponents.MainContent>
      </StyledComponents.Container>
    </StyledComponents.ContainerWrapper>
  );
};

export default Template;
