import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import NotFound from '../Notfound';
import Template from './template';

interface UrlParams {
  id: string;
}

const EngagementPost = (props: RouteComponentProps<UrlParams>) => {
  // Extract Engagement's ID
  const {
    match: { params },
  } = props;
  const id = Number(params.id);
  if (!id) {
    return <NotFound />;
  }

  return <Template engagementId={id} />;
};

export default EngagementPost;
