import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SocialAccountType } from '../../../../../__generated__/globalTypes';
import chatIcon from '../../../../../assets/icon/chat.svg';
import { ViewportType } from '../../../../../libs/theme';
import ProfileDetailsContentWrapper from '../ProfileDetailsContentWrapper/ProfileDetailsContentWrapper';
import Post from './Post';

export interface PopularPost {
  id: number;
  socialMedia: SocialAccountType;
  thumbNail: string | null;
  likes: number;
  comments: number;
  shares: number | null;
  views: number | null;
  content: string | null;
}

export interface PopularPostsProps {
  className?: string;
  posts: PopularPost[];
}

const PopularPosts = (props: PopularPostsProps) => {
  const { className, posts } = props;
  const { t } = useTranslation();

  return (
    <ProfileDetailsContentWrapper
      icon={chatIcon}
      className={className}
      title={t('Title.Popular Posts')}
      dataNotAvailable={!posts || posts.length === 0}
    >
      <>
        <Wrapper>
          {posts.map(post => (
            <PostWrapper key={post.id} {...post} />
          ))}
        </Wrapper>
      </>
    </ProfileDetailsContentWrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &::after {
    content: '';
    display: block;
    width: 32%;
    height: 0;
  }
`;

const PostWrapper = styled(Post)`
  width: 48%;
  margin-bottom: 24px;
  border: 1px solid #dee5ec;
  box-sizing: border-box;

  @media (max-width: ${ViewportType.MEDIUM}px) {
    width: 100%;
  }
`;

export default PopularPosts;
