import { useQuery } from '@apollo/client';
import React from 'react';
import { useQueryHelper } from '@src/libs/hooks';
import { LIMIT_10 } from '@src/libs/paging/constant';
import { Period } from '@src/components/molecules/DayPicker/types';
import { ListIndicator } from '@src/components/molecules/Indicator';
import Template from './template';
import * as INFLUENCER_FB_POSTS_STATISTICS from './InfluencerFacebookPageAnalyticsPostsAnalytics.graphql';
import {
  InfluencerFacebookPageAnalyticsPostsAnalytics,
  InfluencerFacebookPageAnalyticsPostsAnalyticsVariables,
} from './__generated__/InfluencerFacebookPageAnalyticsPostsAnalytics';

interface AnalyticsPostsProps {
  socialMediaAccountId: number;
  period: Period;
  influencerAccountId?: number;
}
const FacebookPageAnalyticsPosts = (props: AnalyticsPostsProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { period, socialMediaAccountId, influencerAccountId } = props;

  const { data, loading, error } = useQuery<
    InfluencerFacebookPageAnalyticsPostsAnalytics,
    InfluencerFacebookPageAnalyticsPostsAnalyticsVariables
  >(INFLUENCER_FB_POSTS_STATISTICS, {
    ...(!influencerAccountId
      ? { skip: true }
      : {
          variables: {
            influencerId: influencerAccountId,
            socialAccountId: socialMediaAccountId,
            startDate: period.startDate,
            endDate: period.endDate,
            offset: 0,
            limit: LIMIT_10,
            keyword: '',
            orderBy: {
              field: null,
              order: null,
            },
          },
        }),
  });

  if (loading) {
    return <ListIndicator />;
  }

  if (error) {
    enqueueSnackbar(t(error?.message || 'Can not get analytics Posts for current account'), {
      variant: 'error',
    });

    return null;
  }
  const stats = data?.influencerFacebookPageAnalyticsPostsAnalytics || {};
  const posts = data?.influencerFacebookPageAnalyticsPosts.posts || [];
  const avatarUrl = data?.influencerProfileV2?.avatarUrl || '';
  const accountName = data?.influencerProfileV2?.accountName || '';

  return (
    <Template
      influencerAccountId={influencerAccountId}
      period={period}
      socialMediaAccountId={socialMediaAccountId}
      {...stats}
      posts={posts}
      avatarUrl={avatarUrl}
      accountName={accountName}
    />
  );
};

export default FacebookPageAnalyticsPosts;
