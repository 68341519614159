import { SocialAccountType } from '@src/__generated__/globalTypes';
import EmptyInstagram from '@src/assets/icon/emptySmAccounts/emptyInstagram.svg';
import EmptyFacebook from '@src/assets/icon/emptySmAccounts/emptyFacebook.svg';
import EmptyYoutube from '@src/assets/icon/emptySmAccounts/emptyYoutube.svg';
import EmptyTwitter from '@src/assets/icon/emptySmAccounts/emptyTwitter.svg';
import EmptyTiktok from '@src/assets/icon/emptySmAccounts/emptyTiktok.svg';
import { InfluencerProfileForAnalytics_influencerProfileV2 } from './__generated__/InfluencerProfileForAnalytics';

export const generateAvailableSmAccounts = (
  sm: SocialAccountType | null,
  profileData: InfluencerProfileForAnalytics_influencerProfileV2
) => {
  switch (sm) {
    case SocialAccountType.FACEBOOK:
    case SocialAccountType.FACEBOOK_PAGE:
      return [
        ...(profileData.facebookAccount
          ? [
              {
                id: profileData.facebookAccount.id,
                name: profileData.facebookAccount.name || 'Facebook',
                url: profileData.facebookAccount.url,
                socialAccountType: SocialAccountType.FACEBOOK,
                avatar: profileData.facebookAccount.avatar,
              },
            ]
          : []),
        ...(profileData.facebookPages || []).map(social => ({
          id: social.id,
          name: social.name || 'Facebook Page',
          url: social.url,
          socialAccountType: SocialAccountType.FACEBOOK_PAGE,
          analyticsEnabled: social.analyticsEnabled,
          avatar: social.avatar,
        })),
      ];

    case SocialAccountType.TWITTER:
      return [
        ...(profileData.twitterAccounts || []).map(social => ({
          id: social.id,
          name: social.name || 'Twitter',
          url: social.url,
          socialAccountType: SocialAccountType.TWITTER,
          analyticsEnabled: social.analyticsEnabled,
          avatar: social.avatar,
        })),
      ];

    case SocialAccountType.INSTAGRAM:
      return [
        ...(profileData.instagramAccounts || []).map(social => ({
          id: social.id,
          name: social.name || 'Instagram',
          url: social.url,
          socialAccountType: SocialAccountType.INSTAGRAM,
          analyticsEnabled: social.analyticsEnabled,
          avatar: social.avatar,
        })),
      ];

    case SocialAccountType.YOUTUBE:
      return [
        ...(profileData.youtubeAccounts || []).map(social => ({
          id: social.id,
          name: social.name || 'YouTube',
          url: social.url,
          socialAccountType: SocialAccountType.YOUTUBE,
          analyticsEnabled: social.analyticsEnabled,
          avatar: social.avatar,
        })),
      ];

    case SocialAccountType.TIKTOK:
      return [
        ...(profileData.tiktokAccounts || []).map(social => ({
          id: social.id,
          name: social.name || 'TikTok',
          url: social.url,
          socialAccountType: SocialAccountType.TIKTOK,
          avatar: social.avatar,
        })),
      ];

    default:
      return [];
  }
};

export const getEmptyImageBasedOnSm = (sm: SocialAccountType | null): string => {
  switch (sm) {
    case SocialAccountType.FACEBOOK:
    case SocialAccountType.FACEBOOK_PAGE:
      return EmptyFacebook;

    case SocialAccountType.INSTAGRAM:
      return EmptyInstagram;

    case SocialAccountType.YOUTUBE:
      return EmptyYoutube;

    case SocialAccountType.TWITTER:
      return EmptyTwitter;

    case SocialAccountType.TIKTOK:
      return EmptyTiktok;

    default:
      return '';
  }
};
