import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PalleteButton } from '@src/components/atoms/Button';
import { css } from '@emotion/react';

interface PostingHabitsColumnProps {
  className?: string;
  timeslots: string[];
  onClickAdd: () => void;
  isTable?: boolean;
}

const PostingHabitsColumn = (props: PostingHabitsColumnProps) => {
  const { className, timeslots, onClickAdd, isTable = true } = props;

  const { t } = useTranslation();
  const Wrapper = isTable ? TableElement : DivElement;

  return (
    <Wrapper className={className}>
      <FlexWrapper>
        <TableData>
          <TimeSlotsWrapper>
            <TimeSlot>
              <Label css={twoLineHeader}>{t('First time zone')}</Label>
              <DateTime css={twoLineHeader}>{timeslots[0]}</DateTime>
            </TimeSlot>
            <TimeSlot>
              <Label css={twoLineHeader}>{t('Second time zone')}</Label>
              <DateTime css={twoLineHeader}>{timeslots[1]}</DateTime>
            </TimeSlot>
            <TimeSlot>
              <Label css={twoLineHeader}>{t('Third time zone')}</Label>
              <DateTime css={twoLineHeader}>{timeslots[2]}</DateTime>
            </TimeSlot>
            <PalleteButton
              prefixIcon={<Icon className="material-icons">add</Icon>}
              onClick={onClickAdd}
              width="24px"
              height="24px"
              borderRadius="0"
            />
          </TimeSlotsWrapper>
        </TableData>
      </FlexWrapper>
    </Wrapper>
  );
};

const Icon = styled.i`
  width: 24px;
  font-size: 12px;
  font-weight: 600;
`;
const DivElement = styled.div`
  padding: 16px;

  &:not(:last-of-type) {
    border-right: 1px solid #dee5ec;
  }
`;
const TableElement = styled(DivElement.withComponent('td'))``;
const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const TableData = styled.div`
  display: inline-block;
  font-size: 13px;
  line-height: 16px;
  width: 100%;
`;
const Label = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #27313b;
  margin-bottom: 12px;
`;
const DateTime = styled.p`
  font-size: 12px;
  line-height: 15px;
  color: #27313b;
`;
const TimeSlot = styled.div`
  padding: 0 12px;

  &:not(:last-of-type) {
    border-right: 1px solid #e6ecf0;
  }
`;
const TimeSlotsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const twoLineHeader = css`
  white-space: pre-line;
  line-height: 1.4em;
  text-align: left;

  /* asian characters might be without whitespace we better keep them inline */
  word-break: keep-all;

  span {
    align-self: center;
  }
`;

export default PostingHabitsColumn;
