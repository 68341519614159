import * as React from 'react';
import { useParams } from 'react-router';
import ReportList from '../../components/organisms/Report/ReportList';
import { useUpdateDocumentTitle } from '../../libs/hooks';

const Template = () => {
  const { id } = useParams<{ id: string }>();

  const title = 'pageTitle.Campaigns Report';
  const pageTitle = 'Campaigns Report';
  const href = `/job/marketplace/${id}`;
  useUpdateDocumentTitle({ title, pageTitle, href });

  return <ReportList />;
};

export default Template;
