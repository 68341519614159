import React from 'react';
import { NotFound } from '../../../../pages';
import { SocialAccountType } from '../../../../__generated__/globalTypes';
import AnalyticsInstagram from '../../AnalyticsInstagram';
import { AnalyticsTemplateProps } from '../../AnalyticsShared/types';
import AnalyticsYoutube from '../../AnalyticsYoutube';

const Template = (props: AnalyticsTemplateProps) => {
  const { selectedSocialMedia } = props;

  if (selectedSocialMedia.socialAccountType === SocialAccountType.INSTAGRAM) {
    return <AnalyticsInstagram {...props} />;
  } else if (selectedSocialMedia.socialAccountType === SocialAccountType.YOUTUBE) {
    return <AnalyticsYoutube {...props} />;
  } else {
    return <NotFound />;
  }
};

export default Template;
