import styled from '@emotion/styled';
import React from 'react';
import { mainBlue } from '@src/libs/pallete';
import { PalleteButton } from '@src/components/atoms/Button';

interface ApplyProps {
  className?: string;
  handleClick: (e?: React.SyntheticEvent<HTMLButtonElement>) => void;
}

const Apply = (props: ApplyProps) => {
  const { className, handleClick } = props;

  return (
    <ApplyWrapper className={className}>
      <ApplyButton height="32px" width="auto" palleteColor={mainBlue} onClick={handleClick} text="Apply" />
    </ApplyWrapper>
  );
};

const ApplyWrapper = styled.div`
  border-top: 1px solid #e4e4e4;
  padding: 8px 0;
  border-radius: 0 0 20px 20px;
`;

const ApplyButton = styled(PalleteButton)`
  margin: 0 12px 0 auto;
  padding: 0 42px;
`;

export default Apply;
