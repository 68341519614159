import * as React from 'react';
import { Redirect } from 'react-router';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import PostDetail from './PostDetail';
import { InfluencerPostDetailsProps } from './types';

export interface TemplateProps {
  data?: {
    influencerPostDetail: InfluencerPostDetailsProps | null;
    influencerProfileV2: { id: number; name: string } | null;
  };
  closeModal: () => void;
  selectedSm: SocialAccountType;
}

const InfluencerListTemplate = (props: TemplateProps) => {
  const { data, selectedSm } = props;

  if (!data?.influencerPostDetail) {
    return <Redirect to="/404" />;
  }

  const {
    likes,
    shares,
    comments,
    caption,
    views,
    socialMedia,
    postUrl,
    avatar,
    followersCount,
    sentimentNegativeRatio,
    sentimentPositiveRatio,
    reactions,
    commentsContent,
  } = data.influencerPostDetail;

  const images = data.influencerPostDetail.thumbNail === null ? [] : [data.influencerPostDetail.thumbNail];

  const engagement = {
    likes,
    shares,
    comments,
    views,
    postUrl,
    socialMedia,
    sentimentNegativeRatio,
    sentimentPositiveRatio,
    reactions,
  };

  return (
    <PostDetail
      caption={caption}
      engagement={engagement}
      influencer={{ avatar, followersCount, accountType: selectedSm, name: data.influencerProfileV2?.name || '' }}
      images={images}
      commentsContent={commentsContent}
    />
  );
};

export default InfluencerListTemplate;
