import styled from '@emotion/styled';
import React from 'react';
import { CheckBoxForSelectAll } from '../../../atoms/CheckBox';
import { DisabledProps } from '../types';

interface SelectAllProps {
  className?: string;
  disabled?: boolean;
  isSelectedAll: boolean;
  isSelectedNoting: boolean;
  handleClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const SelectAll = (props: SelectAllProps) => {
  const { className, disabled, isSelectedAll, isSelectedNoting, handleClick } = props;

  return (
    <Wrapper className={className} disabled={disabled} onClick={handleClick}>
      <CheckBox
        label="Select all"
        name="Select all"
        disabled={disabled}
        isCheckedAll={isSelectedAll}
        isCheckedNothing={isSelectedNoting}
      />
    </Wrapper>
  );
};

const CheckBox = styled(CheckBoxForSelectAll)`
  border-bottom: 1px solid #e4e4e4;
`;

const Wrapper = styled.div<DisabledProps>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: white;
  border: 1px solid #179cd7;
  border-bottom: 0;
`;

export default SelectAll;
