import styled from '@emotion/styled';
import { Button, ClickAwayListener, DialogActions, Icon, Popover } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { format, isAfter, isBefore, isSameDay } from 'date-fns';
import React, { useEffect, useState } from 'react';
import DayPicker, { Modifier } from 'react-day-picker';
import classNames from 'react-day-picker/lib/src/classNames';
import 'react-day-picker/lib/style.css';
import { useTranslation } from 'react-i18next';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { localeUtils } from '@src/libs/date';
import { MenuControlIcon } from '../../atoms/MenuControlIcon/MenuControlIcon';
import { Navbar } from './Components';
import { datesSepatator, getDefaultDate, placeholderMulty } from './helpers';
import { Period, Range } from './types';
import { useHandlers } from './useDatepickerState';
interface DatePickerProps extends WithStyles {
  period: Period;
  handleChangePeriod: (period: Period) => void;
  initialPeriod?: Period;
  error?: boolean;
  disabled?: boolean;
  disabledRange?: Modifier | Modifier[];
  className?: string;
}
const ReactDatePicker = ({
  classes,
  className,
  period,
  handleChangePeriod,
  error,
  disabled,
  disabledRange,
}: DatePickerProps) => {
  // State
  const defaultInputState: Range = {
    from: getDefaultDate(period.startDate),
    to: getDefaultDate(period.endDate),
    hoverTo: getDefaultDate(period.endDate),
    hoverFrom: getDefaultDate(period.startDate),
  };
  const [state, setState] = useState<Range>(defaultInputState);
  const [datePicketInput, setDatePicketInput] = useState<string>(placeholderMulty);
  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);

  const { t, i18n } = useTranslation();
  // if form has default period values, than set picker input
  useEffect(() => {
    if (resultInput) {
      setDatePicketInput(resultInput);
    }
  }, []);

  // Handlers
  const { handleDayClick, handleDayMouseEnter } = useHandlers(state, defaultInputState, setState);
  const handleUpdate = () => {
    setDatePicketInput(resultInput);
    setAnchor(null);
    if (state.from && state.to) {
      const startDate = isBefore(state.from, state.to) ? state.from : state.to;
      const endDate = isAfter(state.to, state.from) ? state.to : state.from;
      handleChangePeriod({ startDate: format(startDate, 'yyyy-MM-dd'), endDate: format(endDate, 'yyyy-MM-dd') });
    }
  };

  const { from, hoverTo, hoverFrom } = state;

  const fromForInput = !!hoverFrom ? localizedDateFormatter(hoverFrom, 'PPP', i18n.language as AppLanguage) : '';
  const toForInput = !!hoverTo ? localizedDateFormatter(hoverTo, 'PPP', i18n.language as AppLanguage) : '';
  const showSingleDay = !!hoverTo && !!hoverFrom ? isSameDay(hoverTo, hoverFrom) : false;

  const resultInput =
    !showSingleDay && !!hoverTo && !!hoverFrom
      ? isBefore(hoverFrom, hoverTo)
        ? `${fromForInput}${datesSepatator}${toForInput}`
        : `${toForInput}${datesSepatator}${fromForInput}`
      : fromForInput;

  const modifiers = { start: hoverFrom || undefined, end: hoverTo || undefined };
  const isOpen = Boolean(anchor) && !disabled;
  const isPlaceholder = datePicketInput === placeholderMulty;

  return (
    <WidgetBlock className={className}>
      <StyledPickerInput
        onClick={({ target }) => setAnchor(target as HTMLDivElement)}
        disabled={!!disabled}
        error={!!error}
      >
        <Icon className="material-icons" fontSize="default">
          calendar_today
        </Icon>
        <DateInput isPlaceholder={isPlaceholder}>{datePicketInput}</DateInput>
        <MenuControlIcon />
      </StyledPickerInput>

      <Popover
        id="dayPicker"
        open={isOpen}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ClickAwayListener onClickAway={() => setAnchor(null)}>
          <div>
            <Wrapper className="RangeExample">
              <StyledDayPicker
                className="Range"
                numberOfMonths={2}
                selectedDays={[from || undefined, { from: hoverFrom as Date, to: hoverTo as Date }]}
                modifiers={modifiers}
                onDayClick={handleDayClick}
                locale={i18n.language}
                localeUtils={localeUtils}
                navbarElement={navbarProps => (
                  <Navbar {...navbarProps} locale={i18n.language} localeUtils={localeUtils} />
                )}
                classNames={{ ...classNames, ...classes }}
                onDayMouseEnter={handleDayMouseEnter}
                captionElement={() => null}
                initialMonth={from || new Date()}
                disabledDays={disabledRange || undefined}
              />
            </Wrapper>
            <ControlPanel>
              <DialogActions classes={{ root: classes.actions }}>
                <p>{resultInput}</p>
                <Button onClick={() => setAnchor(null)}>{t('Button.Cancel')}</Button>
                <Button type="submit" color="primary" classes={{ root: classes.button }} onClick={handleUpdate}>
                  {t('Button.Update')}
                </Button>
              </DialogActions>
            </ControlPanel>
          </div>
        </ClickAwayListener>
      </Popover>
    </WidgetBlock>
  );
};

const styles = createStyles({
  tooltip: {
    fontSize: '12px',
  },
  button: {
    color: 'rgb(23, 156, 215)',
  },
  actions: {
    alignItems: 'baseline',
  },
  months: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  today: {
    color: 'tomato',
  },
  selected: {
    backgroundColor: '#f0f8ff',
    color: '#179CD7',
    borderRadius: '0 !important',
  },
  day: {
    display: 'table-cell',
    padding: '0.5em',
    borderRadius: 0,
    verticalAlign: 'middle',
    textAlign: 'center',
    cursor: 'pointer',
    outline: 'none',

    '&.start, &.end': {
      backgroundColor: '#179CD7 !important',
      color: '#fff !important',
      borderRadius: '2px !important',
    },

    '&.outside': {
      backgroundColor: '#fff !important',
    },

    '&:hover': {
      backgroundColor: 'e1e2e3',
    },
  },
  disabled: {
    opacity: 0.3,
    backgroundColor: '#fff !important',
    color: '#000',
    cursor: 'unset',

    '&:hover': {
      backgroundColor: '#fff !important',
      color: '#000',
    },

    '&.start, &.end': {
      backgroundColor: '#fff !important',
      color: '#000 !important',
    },
  },
  inputWrapper: {
    width: '100%',
  },
});

const ControlPanel = styled.div``;

const WidgetBlock = styled.div`
  display: flex;
  flex-direction: column;
`;
const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const StyledDayPicker = styled(DayPicker)`
  border-bottom: 1px solid #e4e5e6;
  overflow: auto;

  > div {
    outline: none;
  }

  & * > div {
    outline: none;
  }

  & * > div > div {
    outline: none;
  }
`;
const StyledPickerInput = styled.div<{ disabled: boolean; error: boolean }>`
  align-self: center;
  width: 100%;
  min-width: 270px;
  height: 30px;
  background-color: #fff;
  position: relative;
  border: 1px solid #e0e8ed;
  border-radius: 2px;
  font-size: 13px;
  line-height: 30px;
  ${({ disabled }) => disabled && 'background-color: #f7f7f7; cursor: default;'}
  ${({ error }) => error && 'border-color: tomato;'}

  span,
  svg {
    position: absolute;
    left: 10px;
    top: 5px;
    color: #6e7c89;
  }

  p {
    padding-left: 40px;
  }
`;

const DateInput = styled.p<{ isPlaceholder: boolean }>`
  ${({ isPlaceholder }) => isPlaceholder && 'opacity: 0.2;'}
`;

export default withStyles(styles)(ReactDatePicker);
