import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface CheckBoxProps extends DisabledProps {
  className?: string;
  name?: string;
  value: boolean;
  label: string;
  interpolation?: Record<string, string>;
  onChange?: (e?: any) => void;
  checkedIcon?: string;
  uncheckedIcon?: string;
  handleClick?: (e: React.SyntheticEvent<HTMLDivElement>) => void;
}

const CheckBox = (props: CheckBoxProps) => {
  const {
    className,
    name,
    value: isChecked,
    disabled,
    onChange = () => null,
    label,
    interpolation,
    checkedIcon = 'check_box',
    uncheckedIcon = 'check_box_outline_blank',
    handleClick,
  } = props;
  const { t } = useTranslation();
  const icon = isChecked ? checkedIcon : uncheckedIcon;

  return (
    <Wrapper className={className} onClick={handleClick}>
      <HiddenCheckbox type="checkbox" name={name} checked={isChecked} onChange={onChange} />
      <Label disabled={disabled}>
        <Icon className="material-icons" checked={isChecked} disabled={disabled}>
          {icon}
        </Icon>
        <IconBg />
        {t(label, { ...interpolation })}
      </Label>
    </Wrapper>
  );
};

interface DisabledProps {
  disabled?: boolean;
}

interface IconProps extends DisabledProps {
  checked: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
`;

const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

const Label = styled.label<DisabledProps>`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.02em;
  margin-right: 24px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  user-select: none;
`;

const Icon = styled.i<IconProps>`
  margin-right: 6px;
  position: relative;
  z-index: 2;
  color: ${props => (props.checked ? '#179cd7' : '#d5d5d5')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  font-size: 20px;
  line-height: 20px;
`;

const IconBg = styled.div`
  width: 14px;
  height: 14px;
  position: absolute;
  margin: auto;
  left: 3px;
  z-index: 1;
  background-color: #fff;
`;

export default CheckBox;
