import React from 'react';
import { Redirect } from 'react-router';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { useQueryHelper, useAuthData } from '@src/libs/hooks';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { SocialMediaAccountType } from '@src/components/organisms/ProfileDetail/types';
import useInfluencerProfile from '@src/pages/Analytics/useInfluencerProfile';
import Template from './template';
import { generateAvailableSmAccounts } from './helper';

const Analytics = () => {
  const { userId } = useAuthData();
  const { search } = useQueryHelper();

  if (!userId) {
    return <Redirect to="/" />;
  }

  const { data, loading } = useInfluencerProfile();

  if (loading) {
    return <ListIndicator />;
  } else if (!data?.influencerProfileV2) {
    return <Redirect to="/" />;
  }

  const influencerProfileV2 = data.influencerProfileV2;
  const selectedSmTab = new URLSearchParams(search).get('sm') as SocialAccountType | null;

  const availableSocialMediaAccounts: SocialMediaAccountType[] = generateAvailableSmAccounts(
    selectedSmTab,
    influencerProfileV2
  );

  return <Template availableSocialMediaAccounts={availableSocialMediaAccounts} selectedSmTab={selectedSmTab} />;
};

export default Analytics;
