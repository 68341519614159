import { useField, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLazyQuery } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SubmitButton } from '@src/components/atoms/Button';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import Grid from '@src/components/atoms/Grid';
import { ListIndicator } from '@src/components/molecules/Indicator';
import SingleSelectField from '@src/components/molecules/SingleSelectField';
import TextForm from '@src/components/molecules/TextForm';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { getOptions } from '@src/libs/form';
import { useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import GET_BANK_BRANCHES from './queries/BankBranches.graphql';
import { BankBranches, BankBranchesVariables } from './queries/__generated__/BankBranches';

export interface Information {
  accountName: string;
  accountNumber: string;
  address: string;
  bankId: string;
  branchId: string;
  branchName: string;
  cityId: string;
  email: string;
  fullName: string;
  iban: string;
  postalCode: string;
  subject: string;
  swiftCode: string;
}

interface PaymentInformationProps {
  allBanks: {
    hasBranches: boolean;
    label: string;
    value: string;
  }[];
  allCities: {
    label: string;
    value: string;
  }[];
  isLoading: boolean;
  isMenaInfluencer: boolean;
  isJPInfluencer: boolean;
}

const PaymentInformation = ({
  allBanks,
  allCities,
  isLoading,
  isMenaInfluencer,
  isJPInfluencer,
}: PaymentInformationProps) => {
  const { enqueueSnackbar, t } = useQueryHelper();
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });

  const { isSubmitting, handleSubmit, setFieldValue } = useFormikContext<Information>();
  const [accountName, accountNameMeta] = useField('accountName');
  const [accountNumber, accountNumberMeta] = useField('accountNumber');
  const [address, addressMeta] = useField('address');
  const [bankId, bankIdMeta] = useField('bankId');
  const [branchId, branchIdMeta] = useField('branchId');
  const [branchName, branchNameMeta] = useField('branchName');
  const [cityId, cityIdMeta] = useField('cityId');
  const [email, emailMeta] = useField('email');
  const [fullName, fullNameMeta] = useField('fullName');
  const [iban, ibanMeta] = useField('iban');
  const [postalCode, postalCodeMeta] = useField('postalCode');
  const [subject, subjectMeta] = useField('subject');
  const [swiftCode, swiftCodeMeta] = useField('swiftCode');

  const subjectOptions = [
    { value: '普通', label: '普通' },
    { value: '当座', label: '当座' },
    { value: '貯蓄', label: '貯蓄' },
  ];

  const [getBankBranches, { data: dataBankBranches }] = useLazyQuery<BankBranches, BankBranchesVariables>(
    GET_BANK_BRANCHES,
    {
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  useEffect(() => {
    getBankBranches({
      variables: {
        bankId: Number(bankId.value),
      },
    });
  }, [bankId.value]);

  const allBranches = dataBankBranches?.allBankBranchesForInfluencer
    ? getOptions(dataBankBranches.allBankBranchesForInfluencer)
    : [];

  return (
    <div css={styles.container}>
      {isDesktopView && <BackNavigator to="/settings" title="Payment Information" />}

      <div css={styles.description}>
        {t('Annotation.View and edit the payment information to receive revenue of sponsorship jobs')}
      </div>

      <form onSubmit={handleSubmit}>
        <div css={styles.formContainer}>
          <Grid md={8} sm={10} xs={12}>
            {isLoading ? (
              <ListIndicator />
            ) : (
              <div css={styles.formInputSection}>
                {isJPInfluencer ? (
                  <>
                    <InputContainer>
                      <StyledTextForm
                        error={!!fullNameMeta.error}
                        isRequired
                        title="Full Name"
                        placeholder="Full Name"
                        value={fullName.value || ''}
                        onChange={e => setFieldValue('fullName', e.target.value)}
                      />
                      {fullNameMeta.error && <ErrorMessage message={t(fullNameMeta.error)} />}
                    </InputContainer>

                    <InputContainer>
                      <StyledSingleSelectField
                        error={!!bankIdMeta.error}
                        isRequired
                        name="bankId"
                        options={allBanks}
                        title="Bank Name"
                        value={bankId.value || ''}
                        setFieldValue={setFieldValue}
                      />
                      {bankIdMeta.error && <ErrorMessage message={t(bankIdMeta.error)} />}
                    </InputContainer>

                    <InputContainer>
                      <StyledSingleSelectField
                        error={!!branchIdMeta.error}
                        isRequired
                        name="branchId"
                        options={allBranches}
                        title="Branch"
                        value={branchId.value || ''}
                        setFieldValue={setFieldValue}
                      />
                      {branchIdMeta.error && <ErrorMessage message={t(branchIdMeta.error)} />}
                    </InputContainer>

                    <InputContainer>
                      <StyledSingleSelectField
                        error={!!subjectMeta.error}
                        isRequired
                        name="subject"
                        options={subjectOptions}
                        title="Subject"
                        value={subject.value || ''}
                        setFieldValue={setFieldValue}
                      />
                      {subjectMeta.error && <ErrorMessage message={t(subjectMeta.error)} />}
                    </InputContainer>

                    <InputContainer>
                      <StyledTextForm
                        error={!!accountNumberMeta.error}
                        isRequired
                        title="Account Number"
                        placeholder="Account Number"
                        type="number"
                        value={accountNumber.value || ''}
                        onChange={e => setFieldValue('accountNumber', e.target.value)}
                      />
                      {accountNumberMeta.error && <ErrorMessage message={t(accountNumberMeta.error)} />}
                    </InputContainer>

                    <InputContainer>
                      <StyledTextForm
                        error={!!accountNameMeta.error}
                        isRequired
                        title="Account Name"
                        placeholder="Account Name"
                        value={accountName.value || ''}
                        onChange={e => setFieldValue('accountName', e.target.value)}
                      />
                      {accountNameMeta.error && <ErrorMessage message={t(accountNameMeta.error)} />}
                    </InputContainer>
                  </>
                ) : isMenaInfluencer ? (
                  <>
                    <InputContainer>
                      <StyledTextForm
                        error={!!accountNameMeta.error}
                        isRequired
                        title="Account Holder Name"
                        placeholder="Account Holder Name"
                        value={accountName.value || ''}
                        onChange={e => setFieldValue('accountName', e.target.value)}
                      />
                      {accountNameMeta.error && <ErrorMessage message={t(accountNameMeta.error)} />}
                    </InputContainer>

                    <InputContainer>
                      <StyledTextForm
                        error={!!emailMeta.error}
                        isRequired
                        title="Payment Email Address"
                        placeholder="Payment Email Address"
                        value={email.value || ''}
                        onChange={e => setFieldValue('email', e.target.value)}
                      />
                      {emailMeta.error && <ErrorMessage message={t(emailMeta.error)} />}
                    </InputContainer>

                    <InputContainer>
                      <StyledTextForm
                        error={!!swiftCodeMeta.error}
                        isRequired
                        title="Swift Code"
                        placeholder="Swift Code"
                        value={swiftCode.value || ''}
                        onChange={e => setFieldValue('swiftCode', e.target.value)}
                      />
                      {swiftCodeMeta.error && <ErrorMessage message={t(swiftCodeMeta.error)} />}
                    </InputContainer>

                    <InputContainer>
                      <StyledSingleSelectField
                        error={!!bankIdMeta.error}
                        isRequired
                        name="bankId"
                        options={allBanks}
                        title="Bank Name"
                        value={bankId.value || ''}
                        setFieldValue={setFieldValue}
                      />
                      {bankIdMeta.error && <ErrorMessage message={t(bankIdMeta.error)} />}
                    </InputContainer>

                    <InputContainer>
                      <StyledTextForm
                        error={!!branchNameMeta.error}
                        isRequired
                        title="Branch Name"
                        placeholder="Branch Name"
                        value={branchName.value || ''}
                        onChange={e => setFieldValue('branchName', e.target.value)}
                      />
                      {branchNameMeta.error && <ErrorMessage message={t(branchNameMeta.error)} />}
                    </InputContainer>

                    <InputContainer>
                      <StyledTextForm
                        error={!!accountNumberMeta.error}
                        isRequired
                        title="Account Number"
                        placeholder="Account Number"
                        type="number"
                        value={accountNumber.value || ''}
                        onChange={e => setFieldValue('accountNumber', e.target.value)}
                      />
                      {accountNumberMeta.error && <ErrorMessage message={t(accountNumberMeta.error)} />}
                    </InputContainer>

                    <InputContainer>
                      <StyledTextForm
                        error={!!ibanMeta.error}
                        isRequired
                        title="IBAN"
                        placeholder="IBAN"
                        value={iban.value || ''}
                        onChange={e => setFieldValue('iban', e.target.value)}
                      />
                      {ibanMeta.error && <ErrorMessage message={t(ibanMeta.error)} />}
                    </InputContainer>
                  </>
                ) : (
                  <>
                    <InputContainer>
                      <StyledTextForm
                        error={!!accountNameMeta.error}
                        isRequired
                        title="Account Holder Name(Full Name)"
                        placeholder="Account Holder Name(Full Name)"
                        value={accountName.value || ''}
                        onChange={e => setFieldValue('accountName', e.target.value)}
                      />
                      {accountNameMeta.error && <ErrorMessage message={t(accountNameMeta.error)} />}
                    </InputContainer>

                    <InputContainer>
                      <StyledTextForm
                        error={!!emailMeta.error}
                        isRequired
                        title="Payment Email Address"
                        placeholder="Payment Email Address"
                        value={email.value || ''}
                        onChange={e => setFieldValue('email', e.target.value)}
                      />
                      {emailMeta.error && <ErrorMessage message={t(emailMeta.error)} />}
                    </InputContainer>

                    <InputContainer>
                      <StyledSingleSelectField
                        error={!!bankIdMeta.error}
                        isRequired
                        name="bankId"
                        options={allBanks}
                        title="Bank Name"
                        value={bankId.value || ''}
                        setFieldValue={setFieldValue}
                      />
                      {bankIdMeta.error && <ErrorMessage message={t(bankIdMeta.error)} />}
                    </InputContainer>

                    <InputContainer>
                      <StyledTextForm
                        error={!!accountNumberMeta.error}
                        isRequired
                        title="Account Number"
                        placeholder="Account Number"
                        type="number"
                        value={accountNumber.value || ''}
                        onChange={e => setFieldValue('accountNumber', e.target.value)}
                      />
                      {accountNumberMeta.error && <ErrorMessage message={t(accountNumberMeta.error)} />}
                    </InputContainer>

                    <InputContainer>
                      <StyledTextForm
                        error={!!addressMeta.error}
                        isRequired
                        title="Your Address"
                        placeholder="Your Address"
                        value={address.value || ''}
                        onChange={e => setFieldValue('address', e.target.value)}
                      />
                      {addressMeta.error && <ErrorMessage message={t(addressMeta.error)} />}
                    </InputContainer>

                    <InputContainer>
                      <StyledSingleSelectField
                        error={!!cityIdMeta.error}
                        isRequired
                        name="cityId"
                        options={allCities}
                        title="City"
                        value={cityId.value || ''}
                        setFieldValue={setFieldValue}
                      />
                      {cityIdMeta.error && <ErrorMessage message={t(cityIdMeta.error)} />}
                    </InputContainer>

                    <InputContainer>
                      <StyledTextForm
                        error={!!postalCodeMeta.error}
                        isRequired
                        title="Postal Code"
                        placeholder="Postal Code"
                        value={postalCode.value || ''}
                        onChange={e => setFieldValue('postalCode', e.target.value)}
                      />
                      {postalCodeMeta.error && <ErrorMessage message={t(postalCodeMeta.error)} />}
                    </InputContainer>
                  </>
                )}

                <div css={styles.formActionSection}>
                  <SubmitButton disabled={isSubmitting} value="Save" />
                </div>
              </div>
            )}
          </Grid>
        </div>
      </form>
    </div>
  );
};

const InputContainer = styled.div`
  margin-bottom: 24px;
`;

const StyledSingleSelectField = styled(SingleSelectField)`
  & > label {
    font-size: 14px;
  }

  & > div > div {
    border-radius: 2px;
    min-height: 32px;
  }

  & input {
    border-radius: 2px;
    min-height: 32px;
  }
`;

const StyledTextForm = styled(TextForm)`
  & > label {
    font-size: 14px;
  }

  & input {
    border-radius: 2px;
    height: 32px;
  }
`;

const styles = {
  container: css`
    margin: 16px 24px;

    @media (max-width: ${ViewportType.TABLET}px) {
      margin: 16px;
    }
  `,
  description: css`
    color: #27313b;
    font-size: 14px;
    margin-bottom: 24px;
    margin-left: 48px;

    @media (max-width: ${ViewportType.TABLET}px) {
      margin-left: 0;
    }
  `,
  formActionSection: css`
    background-color: #fff;
    border-top: 1px solid #eef3f7;
    display: flex;
    justify-content: flex-end;
    padding: 16px;

    /* stylelint-disable */
    & input {
      border-radius: 3px;
      width: 77px;

      @media (max-width: ${ViewportType.SMALL}px) {
        width: 100%;
      }
    }
    /* stylelint-enable */
  `,
  formContainer: css`
    display: flex;
    justify-content: center;
  `,
  formInputSection: css`
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    padding: 16px;
  `,
};

export default PaymentInformation;
