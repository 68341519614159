import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatPercent, bigIntFormatter, intlNumberFormat } from '@src/libs/format';
import * as Styled from '@src/components/organisms/AnalyticsShared/Overview/StyledComponents';
import { FeatureStatsData } from '../ProfileDetail/AnalyticsProfile/model';
import { getGrowthPrefix, getGrowthArrow } from '../AnalyticsShared/utils';

export const FeatureStats = (props: FeatureStatsData) => {
  const { t } = useTranslation();
  const growth = props.growth && typeof props.growth === 'string' ? +props.growth : props.growth;
  const rate = props.rate && typeof props.rate === 'string' ? +props.rate : props.rate;
  const total = props.total && typeof props.total === 'string' ? +props.total : props.total;

  const isPercentage = ['Engagement Rate'].includes(props.title);
  const isPositiveGrowth = growth === 0 ? undefined : growth > 0;
  const prefix = getGrowthPrefix(growth) || '';
  const arrow = getGrowthArrow(growth);
  const totalValue = isPercentage ? formatPercent(total) : bigIntFormatter(total, 0);
  const growthValue = isPercentage ? formatPercent(growth) : bigIntFormatter(growth, 0);
  const hoverTotal = `${intlNumberFormat(total)}${isPercentage ? '%' : ''}`;
  const hoverGrowth = intlNumberFormat(growth);

  return (
    <Styled.EngagementGridCell key={props.title}>
      <Styled.PartWithIcon>
        <Styled.EngagementTitle text={t(props.title)} lines={1} />
        <Styled.EngagementTileIcon src={props.icon} />
      </Styled.PartWithIcon>

      <Styled.EngagementNumbers>
        <Styled.TotalNumber title={hoverTotal}>{totalValue}</Styled.TotalNumber>
        <Styled.Growth
          isPositive={isPositiveGrowth}
          title={hoverGrowth}
        >{`${prefix}${growthValue}  ${arrow}  (${formatPercent(rate)})`}</Styled.Growth>
      </Styled.EngagementNumbers>
    </Styled.EngagementGridCell>
  );
};
