import styled from '@emotion/styled';
import React from 'react';
import { ViewportBreakpoint } from '../../../../libs/theme';
import { SocialAccountType } from '../../../../__generated__/globalTypes';
import { SocialMediaAccountType } from '../types';
import AverageAgeAndGender, { AverageAgeAndGenderProps } from './AverageAgeAndGender/AverageAgeAndGender';
import CountrywiseFollowersMap, {
  CountrywiseFollowersMapProps,
} from './CountrywiseFollowersMap/CountrywiseFollowersMap';
import FollowersGrowth, { FollowersGrowthData } from './FollowersGrowth/FollowersGrowth';
import PopularPosts, { PopularPostsProps } from './PopularPosts/PopularPosts';
import PostingHabits, { PostingHabitsProps } from './PostingHabits/PostingHabits';
import SentimentAnalysis from './SentimentAnalysis/SentimentAnalysis';

export interface TemplateProps extends CountrywiseFollowersMapProps, PopularPostsProps {
  positiveRatio: number | null;
  negativeRatio: number | null;
  neutralRatio: number | null;
  averageAgeAndGender: AverageAgeAndGenderProps;
  postingHabits: PostingHabitsProps;
  followersGrowth: FollowersGrowthData[];
  selectedSocialMedia: SocialMediaAccountType;
}

const Template = (props: TemplateProps) => {
  const {
    countryRates,
    averageAgeAndGender,
    selectedSocialMedia,
    posts,
    postingHabits,
    positiveRatio,
    neutralRatio,
    negativeRatio,
    followersGrowth,
  } = props;

  const {
    postingHabitsData,
    averageEngagement,
    engagementRate,
    averageComments,
    averageLikes,
    averageShares,
    averageViews,
    socialAccountType,
  } = postingHabits;

  const { maleRate, femaleRate, ageGroups, maleAges, femaleAges } = averageAgeAndGender;
  const isTwitterOrTiktokAccount =
    socialAccountType === SocialAccountType.TWITTER || socialAccountType === SocialAccountType.TIKTOK;

  return (
    <DefaultProfileWrapper>
      {!isTwitterOrTiktokAccount && (
        <Wrapper>
          <CountrywiseFollowersMapWrapper countryRates={countryRates} />
          <AverageAgeAndGenderWrapper
            maleRate={maleRate}
            femaleRate={femaleRate}
            ageGroups={ageGroups}
            maleAges={maleAges}
            femaleAges={femaleAges}
          />
          <SentimentAnalysisWrapper
            positiveRatio={positiveRatio}
            negativeRatio={negativeRatio}
            neutralRatio={neutralRatio}
          />
        </Wrapper>
      )}
      <PostingHabitsWrapper
        postingHabitsData={postingHabitsData}
        socialAccountType={socialAccountType}
        averageEngagement={averageEngagement}
        engagementRate={engagementRate}
        averageComments={averageComments}
        averageLikes={averageLikes}
        averageShares={averageShares}
        averageViews={averageViews}
      />
      <FollowersGrowthWrapper
        followersGrowth={followersGrowth}
        socialAccountType={selectedSocialMedia.socialAccountType}
      />
      <PopularPostsWrapper posts={posts} />
    </DefaultProfileWrapper>
  );
};

const PostingHabitsWrapper = styled(PostingHabits)`
  margin-top: 16px;
  padding-bottom: 0;
`;

const FollowersGrowthWrapper = styled(FollowersGrowth)`
  margin-top: 24px;
  background-color: #fff;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (min-width: ${ViewportBreakpoint.DESKTOP}px) {
    flex-wrap: nowrap;
  }
`;

const AverageAgeAndGenderWrapper = styled(AverageAgeAndGender)`
  height: 380px;
  background-color: #fff;
  box-sizing: border-box;
  width: 100%;
  margin-top: 16px;

  @media (min-width: ${ViewportBreakpoint.DESKTOP}px) {
    margin: 0 12px;
    flex: 1;
    min-width: 200px;
  }
`;

const CountrywiseFollowersMapWrapper = styled(CountrywiseFollowersMap)`
  height: 380px;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: ${ViewportBreakpoint.DESKTOP}px) {
    margin: 0 12px 0 0;
    flex: 1;
    min-width: 200px;
  }
`;

const SentimentAnalysisWrapper = styled(SentimentAnalysis)`
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  margin-top: 16px;
  height: auto;

  @media (min-width: ${ViewportBreakpoint.DESKTOP}px) {
    margin-top: 0;
    flex: 1;
    margin-left: 12px;
    min-width: 200px;
  }
`;

const PopularPostsWrapper = styled(PopularPosts)`
  margin-top: 16px;
  padding-bottom: 0;
  min-height: 350px;
`;
const DefaultProfileWrapper = styled.div``;

export default Template;
