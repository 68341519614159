import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { useQueryHelper } from '@src/libs/hooks';
import Template from './template';
import {
  InfluencerInstagramInteractionTaggedPostDetails,
  InfluencerInstagramInteractionTaggedPostDetailsVariables,
} from './__generated__/InfluencerInstagramInteractionTaggedPostDetails';
import * as INFLUENCER_IG_TAGGED_POST_DETAILS from './InfluencerInstagramInteractionTaggedPostDetails.graphql';

interface AnayticsFeedPostDetailProps {
  closeModal: () => void;
  hasViewColumn?: boolean;
  socialMediaAccountId?: number;
}

const InstargamTaggedPostDetail = (props: AnayticsFeedPostDetailProps) => {
  const { closeModal, socialMediaAccountId } = props;
  const { search, t, enqueueSnackbar } = useQueryHelper();

  const postId = Number(new URLSearchParams(search).get('popup'));

  const { data, loading } = useQuery<
    InfluencerInstagramInteractionTaggedPostDetails,
    InfluencerInstagramInteractionTaggedPostDetailsVariables
  >(INFLUENCER_IG_TAGGED_POST_DETAILS, {
    variables: {
      socialAccountId: Number(socialMediaAccountId),
      postId,
    },
    onError: err => {
      closeModal();
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    fetchPolicy: 'cache-first',
  });

  if (loading) {
    return <CircularProgress size={64} thickness={2.4} css={{ margin: 'auto', color: '#dee5ec' }} />;
  }

  return (
    <Template
      closeModal={closeModal}
      data={data?.influencerInstagramInteractionTaggedPostDetails}
      accountType={SocialAccountType.INSTAGRAM}
    />
  );
};

export default InstargamTaggedPostDetail;
