import React from 'react';
import styled from '@emotion/styled';
import DataOpacityWrapper from '@src/components/molecules/DataCard/DataOpacityWrapper';
import ImageSlider from '@src/components/molecules/Slider';
import { css } from '@emotion/react';
import { bigIntFormatter } from '@src/libs/format';
import { InfluencerYoutubeCompareTagRanking_influencerYoutubeCompareTagRanking_mainAccount_tagsRanking } from './__generated__/InfluencerYoutubeCompareTagRanking';

interface TagRankingSliderProps {
  className?: string;
  tags?: InfluencerYoutubeCompareTagRanking_influencerYoutubeCompareTagRanking_mainAccount_tagsRanking[];
}
export const TagRankingSlider = ({ className, tags }: TagRankingSliderProps) => (
  <Wrapper className={className}>
    <StyledOpacityContentWrapper
      dataNotAvailable={!tags?.length}
      css={[styles.opacityContentWrapper, ...[!tags?.length && styles.opacityContentWrapperZoom]]}
    >
      <ImageSlider data={tags || []}>
        {tags?.map((tagItem, i) => (
          <VideoTagWrapper key={i}>
            <TagElement>
              #{tagItem.tag}
              <TagsViewCount title={`${tagItem.views}`}>{bigIntFormatter(tagItem.views)}</TagsViewCount>
            </TagElement>
          </VideoTagWrapper>
        ))}
      </ImageSlider>
    </StyledOpacityContentWrapper>
  </Wrapper>
);

const styles = {
  opacityContentWrapper: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  `,
  opacityContentWrapperZoom: css`
    zoom: 0.6;
    height: 100px;
  `,
};
const Wrapper = styled.div`
  min-height: 80px;
  display: flex;
  place-items: center;
  border-top: 1px solid #dee5ec;
  padding-right: 0;
`;
const StyledOpacityContentWrapper = styled(DataOpacityWrapper)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: none;
`;
const VideoTagWrapper = styled.div`
  position: relative;
`;
const TagElement = styled.div`
  display: flex;
  align-items: center;
  width: max-content !important;
  height: 60px;
  position: relative;
  margin-left: 16px;
  font-size: 13px;
`;
const TagsViewCount = styled.div`
  display: flex;
  height: 32px;
  margin-left: 11px;
  padding: 0 8px;
  border-radius: 0 3px 3px 0;
  line-height: 32px;
  background-color: #6e7c89;
  color: #fff;
`;
