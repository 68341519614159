import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const Casting = () => {
  const history = useHistory();

  useEffect(() => {
    history.push('/casting/your_job');
  }, []);

  return null;
};

export default Casting;
