import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import downloadIcon from '@src/assets/icon/download.svg';
import { Button } from '@src/components/atoms/Button';
import * as List from '@src/components/atoms/List';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { SliderComponents, StyledComponents } from '@src/components/molecules/SliderTable';
import RecipientStatusIndicator from '@src/components/organisms/Emails/RecipientStatusIndicator';
import { LIMIT } from '@src/libs/constant';
import { useQueryHelper } from '@src/libs/hooks';
import { getOffset, getPageInfo, usePaging } from '@src/libs/paging';
import { FilterItems } from './useFilter';
import useRecipient from './useRecipient';
import GET_EMAIL_RECIPIENTS from './queries/GetEmailRecipients.graphql';
import { GetEmailRecipients, GetEmailRecipientsVariables } from './queries/__generated__/GetEmailRecipients';

interface RecipientListProps {
  emailId: string;
  filter: FilterItems;
}

const RecipientList = ({ emailId, filter }: RecipientListProps) => {
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const { enqueueSnackbar, t } = useQueryHelper();
  const currentPage = usePaging();
  const { exportEmailRecipientsCsv } = useRecipient();

  const { data, loading } = useQuery<GetEmailRecipients, GetEmailRecipientsVariables>(GET_EMAIL_RECIPIENTS, {
    variables: {
      emailId,
      keyword: filter.keyword,
      limit: LIMIT,
      offset: getOffset(currentPage),
      ...(filter.status && { status: filter.status }),
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const onClickAllChecked = (status: boolean) => {
    const items = [...checkedIds];
    recipientsList.forEach(recipient => {
      const index = items.findIndex(checkedId => checkedId === recipient.fanId);
      if (status && index >= 0) {
        items.splice(index, 1);
      } else if (!status && index < 0) {
        items.push(recipient.fanId);
      }
    });
    setCheckedIds(items);
  };

  const onClickCheck = (id: string) => {
    const items = [...checkedIds];
    const index = items.findIndex(item => item === id);
    if (index > -1) {
      items.splice(index, 1);
    } else {
      items.push(id);
    }
    setCheckedIds(items);
  };

  const onClickExport = () => {
    exportEmailRecipientsCsv(emailId, checkedIds);
  };

  const recipientsList = data?.getEmailRecipients?.recipients || [];
  const getCheckedItemStatus = () => {
    let isAllCheckedStatus = checkedIds.length > 0;
    let isItemSelectedStatus = false;

    recipientsList.every(recipient => {
      if (!checkedIds.includes(recipient.fanId)) {
        isAllCheckedStatus = false;
      } else {
        isItemSelectedStatus = true;
      }

      if (!isAllCheckedStatus && isItemSelectedStatus) {
        return false;
      }

      return true;
    });

    return {
      isAllChecked: isAllCheckedStatus,
      isItemSelected: isItemSelectedStatus,
    };
  };
  const { isAllChecked, isItemSelected } = getCheckedItemStatus();
  const totalCount = data?.getEmailRecipientsCount?.total || 0;
  const { firstIndex, lastIndex, totalPages } = getPageInfo(currentPage, totalCount, LIMIT);

  if (loading) {
    return <ListIndicator />;
  }

  return (
    <div>
      <SliderComponents.Wrapper css={styles.container}>
        <SliderComponents.Container>
          <SliderComponents.SliderSection>
            <SliderComponents.SliderTable>
              <Thead isItemSelected={isItemSelected}>
                <SliderComponents.HeaderRow>
                  <List.HeaderCheckboxColumn
                    checked={isAllChecked || isItemSelected}
                    css={{ padding: '0 12px' }}
                    indeterminate={!isAllChecked && isItemSelected}
                    handleClick={e => onClickAllChecked((e.target as HTMLInputElement).checked)}
                  />
                  {isItemSelected ? (
                    <th colSpan={3} css={{ height: 48 }}>
                      <div css={styles.actionContainer}>
                        <div>{t('Selected', { count: checkedIds.length })}</div>
                        <div>
                          <Button
                            bgcolor="#fff"
                            hoverbgcolor="#fff"
                            icon={<img alt="download" height="16" src={downloadIcon} width="16" />}
                            title="Export CSV"
                            onClick={onClickExport}
                          />
                        </div>
                      </div>
                    </th>
                  ) : (
                    <>
                      <List.HeaderColumn title="Name" />
                      <List.HeaderColumn title="Email" />
                      <List.HeaderColumn title="Status" />
                    </>
                  )}
                </SliderComponents.HeaderRow>
              </Thead>

              <tbody>
                {recipientsList.map(recipient => {
                  const { email, fanId, name, status } = recipient;
                  const isChecked = checkedIds.includes(fanId);

                  return (
                    <StyledComponents.StyledRowNew key={fanId}>
                      <List.HeaderCheckboxColumn
                        checked={isChecked}
                        css={styles.columnCheckbox}
                        handleClick={() => onClickCheck(fanId)}
                      />
                      <List.TextColumn data={name} />
                      <List.TextColumn data={email} />
                      <List.TextColumn data={status ? <RecipientStatusIndicator status={status} /> : ''} />
                    </StyledComponents.StyledRowNew>
                  );
                })}
              </tbody>
            </SliderComponents.SliderTable>
          </SliderComponents.SliderSection>
        </SliderComponents.Container>
      </SliderComponents.Wrapper>

      <SliderComponents.Pager
        currentPage={currentPage}
        first={firstIndex}
        last={lastIndex}
        totalCount={totalCount}
        totalPages={totalPages}
      />
    </div>
  );
};

const Thead = styled.thead<{ isItemSelected: boolean }>`
  background-color: ${({ isItemSelected }) => (isItemSelected ? '#27313b' : '#f6f8fa')};
  border-bottom: 1px solid #dfe7ec;
  border-top: 1px solid #dfe7ec;
`;

const styles = {
  actionContainer: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    & > div:nth-of-type(1) {
      color: #fff;
      display: flex;
      flex: 1;
      font-size: 14px;
      font-weight: 600;
    }

    & > div:nth-of-type(2) {
      & > button {
        color: #6e7c89;
        font-size: 12px;
        font-weight: 600;
        height: 32px;
        margin-right: 16px;
        width: 124px;

        & > img {
          margin-right: 8px;
        }
      }
    }
  `,
  columnCheckbox: css`
    border-top: 1px solid #efefef;
    padding: 0 12px;
  `,
  container: css`
    border: 1px solid #eef3f7;
    margin: 0;
    padding: 0;
  `,
};

export default RecipientList;
