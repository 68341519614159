import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { FormValues, BasePostFilterType } from './types';
import BasePostListFilter from './BasePostListFilter';

interface PostFilterComponentProps {
  filter: BasePostFilterType;
  setFilter: (filter: BasePostFilterType) => void;
  wrapWithContainer?: boolean;
  isMobile?: boolean;
  className?: string;
}

const BasePostFilterComponent = (props: PostFilterComponentProps) => {
  const {
    filter: { keyword, period },
    setFilter,
    wrapWithContainer,
    isMobile,
    className,
  } = props;

  const handleSubmit = (payload: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const { keyword: keywordPayload, period: periodPayload } = payload;

    setFilter({ keyword: keywordPayload, period: periodPayload });
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        keyword,
        period,
      }}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <BasePostListFilter wrapWithContainer={wrapWithContainer} isMobile={isMobile} className={className} />
    </Formik>
  );
};

export default BasePostFilterComponent;
