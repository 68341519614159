import React from 'react';
import { validateUrl } from '@src/libs/url';
import { InfluencerInfoProps } from '@src/components/organisms/AnalyticsShared/PostDetail/InfluencerInfo';
import * as StyledComponents from '@src/components/organisms/AnalyticsShared/PostDetail/StyledComponents';
import { useQueryHelper } from '@src/libs/hooks';
import Carousel from '@src/components/molecules/Carousel';
import InfluencerInfo from '@src/components/organisms/AnalyticsShared/PostDetail/InfluencerInfo';
import { useMediaQuery } from 'react-responsive';
import { ViewportBreakpoint } from '@src/libs/theme';
import { CommentsContentType } from '../types';
import PostDetailEngagement, { PostDetailEngagementProps } from './PostDetailEngagement';

export interface PostDetailProps {
  caption?: string;
  engagement: PostDetailEngagementProps;
  influencer: InfluencerInfoProps;
  images: string[];
  commentsContent?: CommentsContentType[] | null;
  setCurrentSlideIdx?: (idx: number) => void;
  currSlideIdx?: number;
}

const PostDetail = (props: PostDetailProps) => {
  const { t } = useQueryHelper();
  const { caption, engagement, influencer, images, commentsContent = [], setCurrentSlideIdx, currSlideIdx } = props;
  const isMobile = useMediaQuery({ maxWidth: ViewportBreakpoint.MOBILE });
  const isTablet = useMediaQuery({ maxWidth: ViewportBreakpoint.MEDIUM });

  const [width, height] = isMobile ? ['295px', '295px'] : ['400px', '400px'];
  const hasPreview = !!images.length && images.some(img => validateUrl(img));

  const hasComments = !!commentsContent?.length;

  return (
    <StyledComponents.ContainerWrapper hasPreview={hasPreview}>
      <StyledComponents.Container>
        <StyledComponents.TopHeading>
          <InfluencerInfo
            avatar={influencer.avatar}
            name={influencer.name}
            accountType={influencer.accountType}
            followersCount={influencer.followersCount}
            postDate={influencer.postDate}
            hideFollowersCount={influencer.hideFollowersCount}
          />
        </StyledComponents.TopHeading>

        <StyledComponents.MainContent>
          {hasPreview && (
            <StyledComponents.LeftContent css={{ width }}>
              <Carousel
                sources={images}
                afterChangeHandler={idx => {
                  // eslint-disable-next-line no-unused-expressions
                  setCurrentSlideIdx?.(idx);
                }}
                currSlideIdx={currSlideIdx}
                width={width}
                height={height}
              />
            </StyledComponents.LeftContent>
          )}
          {/* info block */}
          <StyledComponents.RightContent css={isTablet ? { width: 'auto' } : ''}>
            {/* Engagement Info */}
            <PostDetailEngagement {...engagement} />
            {/* Post Caption */}
            <StyledComponents.CaptionSection>
              <StyledComponents.Title>{t('Post’s Caption')}</StyledComponents.Title>
              <StyledComponents.PostCaptionWrapper>
                <StyledComponents.Caption>{caption}</StyledComponents.Caption>
              </StyledComponents.PostCaptionWrapper>
            </StyledComponents.CaptionSection>
            {/* Post Comments */}
            {hasComments && (
              <StyledComponents.CommentsSection>
                <StyledComponents.Title>{t('General.Comments')}</StyledComponents.Title>
                <StyledComponents.CaptionFrame>
                  {commentsContent?.map(item => (
                    <StyledComponents.CommentsWrapper key={item.id}>
                      <StyledComponents.TextWrapper>
                        <StyledComponents.CommentUsername text={item.username || t('no username')} />
                        <StyledComponents.CommentText>{item.content}</StyledComponents.CommentText>
                      </StyledComponents.TextWrapper>
                    </StyledComponents.CommentsWrapper>
                  ))}
                </StyledComponents.CaptionFrame>
              </StyledComponents.CommentsSection>
            )}
          </StyledComponents.RightContent>
        </StyledComponents.MainContent>
      </StyledComponents.Container>
    </StyledComponents.ContainerWrapper>
  );
};

export default PostDetail;
