import { formatIntNumber, bigIntFormatter, formatNumber } from '../../../../libs/format';

export interface SeriesHistoryType {
  count: number | null;
  date: any | null;
}

export const formatLineChartDateCount = (historyData: SeriesHistoryType[]) =>
  historyData.reduce<{
    count: number[];
    date: string[];
  }>(
    (acc, curr) => ({
      count: [...acc.count, Number(curr.count)],
      date: [...acc.date, curr.date],
    }),
    { count: [], date: [] }
  );

export const generateYAxisBasedOnDisplayedCharts = <T>(
  yAxisCharts: T[],
  hasYMin: boolean | undefined = true,
  enabledLabels: boolean | undefined = true
) =>
  yAxisCharts.map(
    (_, i): Highcharts.YAxisOptions => ({
      ...(hasYMin ? { min: 0 } : {}),
      title: {
        text: '',
      },
      labels: {
        enabled: i === 0 && enabledLabels,
        formatter: function formatter(): string {
          return bigIntFormatter(this.value);
        },
      },
      allowDecimals: false,
    })
  );

export const formatDateCount = (followersHistory: SeriesHistoryType[], historyData: SeriesHistoryType[]) =>
  followersHistory.reduce<{
    count: number[];
  }>(
    (acc, curr) => ({
      count: [...acc.count, Number(historyData.find(el => el.date === curr.date)?.count || 0)],
    }),
    { count: [] }
  );

interface EngagementWithHistoryType {
  total: number | null;
  growth: number | null;
  percentage?: number | null;
  history: Array<{ count: number | null; date: any | null }> | null;
}
export const getEngagementDataWithHistory = (eng: EngagementWithHistoryType) => ({
  engagement: {
    total: eng.total || 0,
    growth: eng.growth || 0,
    rate: eng.percentage || 0,
  },
  history: eng.history || [],
});
interface EngagementType {
  total: number | null;
  growth: number | null;
  rate: number | null;
}
export const getEngagementData = (eng: EngagementType) => ({
  total: eng.total || 0,
  growth: eng.growth || 0,
  rate: eng.rate || 0,
});

export const formatDate = (date: string) => new Date(date).toDateString().split(' ').splice(1, 2).join(' ');

export interface SeriesType {
  name: string;
  type: string;
  data: number[];
  color: string;
}
export const getSerieDayData = <T extends string>(index: number, series: SeriesType[]) =>
  series.reduce((acc, serie) => {
    const hasDecimals = serie.name === 'Engagement Rate';

    return {
      ...acc,
      [serie.name as T]: {
        // @ts-ignore not sure how to fix this
        value: hasDecimals ? formatNumber(serie.data[index] || null) : formatIntNumber(serie.data[index] || null),
        // currenlty we just show difference between prev and curr values as increment
        increment: serie.name === 'Follower' && index !== 0 ? serie.data[index] - serie.data[index - 1] : 0,
      },
    };
  }, {});

export interface EngagementDataType {
  icon: string;
  title: string;
  total: number;
  growth: number;
  rate: number;
}

export interface TooltipDataType<T extends string> {
  [key: string]: {
    [key in T]: {
      value: string;
      increment: number;
    };
  };
}

export const getBarDataBasedOnPointer = (
  chart: Highcharts.Chart,
  e: Highcharts.PointerEventObject,
  postsColors: string[]
) => {
  let selectedPoint: Highcharts.Point[] | undefined;
  // @ts-ignore
  const x = Math.round(e.xAxis[0].value);
  chart.series.forEach(seria => {
    if (seria.visible) {
      seria.points.forEach(point => {
        if (point.x === x && postsColors.includes(`${point.color}`)) {
          selectedPoint = [...(selectedPoint || []), point];
        }
      });
    }
  });

  return selectedPoint;
};

export const parseUniqId = (uniqId: string): number => +uniqId.split('_')[0];
