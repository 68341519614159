import { RefObject } from 'react';

export const datesSepatator = '\u00A0\u00A0\u2013\u00A0\u00A0';
export const placeholderMulty = `Start Date${datesSepatator}End Date`;
export const placeholderSingle = 'Jan 01, 2020';
export const DAYPICKER_INPUT_FORMAT = 'MMM dd, yyyy';

export const getDefaultDate = (date: string) => (date ? new Date(date) : null);

export const CloseOverlayAfterFocus = (inputRef: RefObject<any>) => {
  const activeElement = document.activeElement;
  const overlayContainer = document.getElementsByClassName('DayPickerInput-Overlay');

  if (overlayContainer && !overlayContainer[0].contains(activeElement)) {
    inputRef.current.hideAfterDayClick();
  }
};
