import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Popup from 'reactjs-popup';
import { PalleteButton } from '@src/components/atoms/Button';
import { mainBlue, PalleteColorType } from '@src/libs/pallete';
import Portal from '@src/components/atoms/Portal';

interface DialogProps {
  visible: boolean;
  onClose: () => void;
  execColor?: PalleteColorType;
  execText: string;
  execDisabled?: boolean;
  onExec: () => void;
  contentStyle?: CSSProperties;
  headerNode?: React.ReactNode;
}

const popupStyles: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  width: '90%',
  maxWidth: '648px',
  height: 'max-content',
  padding: '0',
  border: 'none',
  borderRadius: '3px',
};

const Dialog: React.FC<DialogProps> = props => {
  const {
    visible,
    execText,
    children,
    contentStyle,
    execColor,
    headerNode: HeaderNode,
    execDisabled,
    onExec,
    onClose,
  } = props;

  return (
    <Portal portalId="portal-root">
      <Popup
        modal
        lockScroll
        closeOnDocumentClick={true}
        open={visible}
        onClose={onClose}
        contentStyle={{ ...popupStyles, ...contentStyle }}
      >
        {close => (
          <>
            <Close onClick={close} />
            {HeaderNode}
            <MainWrapper>{children}</MainWrapper>
            <FooterWrapper>
              <StyledPalleteButton
                text="Cancel"
                height="32px"
                borderRadius="5px"
                onClick={onClose}
                css={marginRight16}
                skipUppercase={true}
              />
              <StyledPalleteButton
                text={execText}
                palleteColor={execColor || mainBlue}
                borderRadius="5px"
                height="32px"
                onClick={onExec}
                skipUppercase={true}
                isDisabled={execDisabled}
              />
            </FooterWrapper>
          </>
        )}
      </Popup>
    </Portal>
  );
};

const StyledPalleteButton = styled(PalleteButton)`
  padding: 0 24px;
  width: fit-content;
`;

const marginRight16 = css`
  margin-right: 16px;
`;
const MainWrapper = styled.div`
  padding: 24px;
`;
const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
  border-top: 1px solid #dee5ec;
`;
const Close = styled.a`
  position: absolute;
  right: -15px;
  top: -15px;
  display: flex;
  justify-content: center;
  width: 36px;
  height: 36px;
  font-size: 25px;
  line-height: 34px;
  font-weight: 100;
  background: #fff;
  border-radius: 50%;
  background-color: #000;
  cursor: pointer;
  z-index: 100;

  &:hover {
    background-color: #000;
  }

  &::before {
    content: '\\00d7';
    color: #fff;
    font-weight: bold;
  }
`;

export default Dialog;
