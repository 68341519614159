import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import warningRed from '@src/assets/icon/warningRed.svg';
import { DialogModal } from '@src/components/molecules/DialogModal';
import { PalleteButton } from '@src/components/atoms/Button';
import { mainRed } from '@src/libs/pallete';
import * as Styled from './Styled';

interface DeleteChannelWithModal {
  loading?: boolean;
  handleClickDelete: () => void;
}
export const useDeleteChannelWithModal = ({ loading, handleClickDelete }: DeleteChannelWithModal) => {
  const [isOpened, setIsOpened] = useState(false);
  const { t } = useTranslation();
  const { styles } = Styled;

  const DeleteModalWithState = useMemo(
    () => (
      <DialogModal
        isOpen={isOpened}
        closeModal={() => setIsOpened(false)}
        footerNode={
          <div css={styles.modalFooter}>
            <PalleteButton
              text="Cancel"
              width="90px"
              height="32px"
              borderRadius="3px"
              onClick={() => setIsOpened(false)}
            />
            <PalleteButton
              width="76px"
              borderRadius="3px"
              height="32px"
              text="Delete"
              palleteColor={mainRed}
              isDisabled={loading}
              loading={loading}
              onClick={handleClickDelete}
            />
          </div>
        }
      >
        <Styled.DialogContent>
          <Styled.DialogHeader>
            <img alt="warning" height="24" src={warningRed} width="24" />
            {t('Title.Delete Channel')}
          </Styled.DialogHeader>
          <Styled.DialogSubHeader>
            {t('Annotation.Are you sure you want to delete Channel', { name: 'LINE' })}
          </Styled.DialogSubHeader>
        </Styled.DialogContent>
      </DialogModal>
    ),
    [isOpened]
  );

  return { DeleteModalWithState, setIsOpened };
};
