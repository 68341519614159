import React, { useEffect, useState, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import sortIcon from '@src/assets/icon/sort.svg';
import { Button } from '@src/components/atoms/Button';
import SearchForm from '@src/components/molecules/SearchForm';
import SingleSelectField from '@src/components/molecules/SingleSelectField';
import { ViewportType } from '@src/libs/theme';
import { EmailListSortableField, EmailTemplateStatus, Order } from '@src/__generated__/globalTypes';
import { FilterItems } from './useFilter';

interface EmailListFilterProps {
  values: FilterItems;
  onChange: (values: FilterItems) => void;
}

const EmailListFilter = ({ values, onChange }: EmailListFilterProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [filter, setFilter] = useState<FilterItems>(values);
  const { t } = useTranslation();
  const sortOptions = [
    { field: EmailListSortableField.OPEN_RATE, label: 'Highest Open Rate', order: Order.DESC },
    { field: EmailListSortableField.OPEN_RATE, label: 'Lowest Open Rate', order: Order.ASC },
    { field: EmailListSortableField.CLICK_RATE, label: 'Highest Click Rate', order: Order.DESC },
    { field: EmailListSortableField.CLICK_RATE, label: 'Lowest Click Rate', order: Order.ASC },
  ];
  const statusOptions = [
    { label: t('Draft'), value: EmailTemplateStatus.DRAFT },
    { label: t('Published'), value: EmailTemplateStatus.PUBLISHED },
    { label: t('Schedule'), value: EmailTemplateStatus.SCHEDULED },
  ];

  useEffect(() => {
    setFilter(values);
  }, [values.keyword, values.orderByField, values.orderBySequence, values.status]);

  const onClickMenu = (event: SyntheticEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const onClickSortOrder = (orderByField: EmailListSortableField, orderBySequence: Order) => {
    onChange({ ...filter, orderByField, orderBySequence });
    setAnchorEl(null);
  };

  return (
    <div css={styles.container}>
      <div>
        <SearchForm
          css={styles.searchForm}
          placeholder={t('TextForm.Search by Email Subject')}
          value={filter.keyword}
          onBlur={() => onChange(filter)}
          onChange={e => setFilter({ ...filter, keyword: e.target.value })}
          onEnterKeyPress={() => onChange(filter)}
        />
      </div>
      <div>
        <SingleSelectField
          css={styles.singleSelectField}
          name=""
          options={statusOptions}
          placeholder="Status"
          value={filter.status}
          setFieldValue={(_attr, value) => onChange({ ...filter, status: value as EmailTemplateStatus })}
        />
      </div>
      <div>
        <Button
          color="#6e7C89"
          bgcolor="#fff"
          hoverbgcolor="#fff"
          icon={<img alt="sortIcon" height="24" src={sortIcon} width="24" />}
          title="Sort"
          onClick={onClickMenu}
        />
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          css={styles.menu}
          getContentAnchorEl={null}
          open={Boolean(anchorEl)}
          transformOrigin={{
            horizontal: 'center',
            vertical: 'top',
          }}
          onClose={onCloseMenu}
        >
          {sortOptions.map((option, index) => {
            const { field, label, order } = option;

            return (
              <MenuItem css={styles.menuItem} key={index} onClick={() => onClickSortOrder(field, order)}>
                {t(`MenuTitle.${label}`)}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    </div>
  );
};

const styles = {
  container: css`
    background-color: #fff;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    display: flex;
    flex-wrap: wrap;
    padding: 16px;

    & > div:nth-of-type(1) {
      width: 70%;

      @media (max-width: ${ViewportType.TABLET}px) {
        width: 100%;
        margin-bottom: 8px;
      }
    }

    & > div:nth-of-type(2) {
      width: 20%;

      & > div {
        margin-left: 8px;
        width: fill-available;
      }

      @media (max-width: ${ViewportType.TABLET}px) {
        width: 75%;

        & > div {
          margin-left: 0;
        }
      }
    }

    /* stylelint-disable no-descending-specificity */
    & > div:nth-of-type(3) {
      width: 10%;

      & > button {
        border: 1px solid #dee5ec;
        border-radius: 3px;
        height: 32px;
        margin-left: 8px;
        width: fill-available;

        & > p {
          color: #6e7c89;
          font-size: 12px;
          font-weight: 600;
          margin-left: 8px;
        }
      }

      @media (max-width: ${ViewportType.TABLET}px) {
        width: 25%;
      }
    }
  `,
  menu: css`
    & > div.MuiMenu-paper {
      box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
    }

    & ul.MuiMenu-list {
      padding: 0;
    }
  `,
  menuItem: css`
    background-color: #fff !important;
    color: #6e7c89 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    filter: brightness(1);

    &:hover {
      filter: brightness(0.9);
    }
  `,
  searchForm: css`
    & input {
      height: 32px;
    }
  `,
  singleSelectField: css`
    & > label {
      font-size: 14px;
    }

    & > div > div {
      border-radius: 2px;
      min-height: 30px;

      & > div > div {
        align-items: center;
        display: flex;
      }
    }

    & input {
      border-radius: 2px;
      min-height: 30px;
    }
  `,
};

export default EmailListFilter;
