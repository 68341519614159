import React from 'react';
import { Period } from '@src/components/molecules/DayPicker/types';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { useQueryHelper } from '@src/libs/hooks';
import { useQuery } from '@apollo/client';
import { SocialMediaAccountType } from '@src/components/organisms/ProfileDetail/types';
import AudienceCardTemplate from '@src/components/organisms/AnalyticsShared/AudienceCardTemplate';
import * as INFLUENCER_YT_AUDIENCE_ANALYTICS from './InfluencerYoutubeAnalyticsAudience.graphql';
import {
  InfluencerYoutubeAnalyticsAudience,
  InfluencerYoutubeAnalyticsAudienceVariables,
} from './__generated__/InfluencerYoutubeAnalyticsAudience';

interface AudienceCardProps {
  selectedSocialMedia: SocialMediaAccountType;
  selectedPeriod: Period;
  userId: number;
  className?: string;
}

const AudienceCard = ({ selectedPeriod, selectedSocialMedia }: AudienceCardProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();

  const { data, loading, error } = useQuery<
    InfluencerYoutubeAnalyticsAudience,
    InfluencerYoutubeAnalyticsAudienceVariables
  >(INFLUENCER_YT_AUDIENCE_ANALYTICS, {
    variables: {
      startDate: selectedPeriod.startDate,
      endDate: selectedPeriod.endDate,
      socialAccountId: selectedSocialMedia.id,
    },
  });

  if (loading) {
    return <ListIndicator />;
  }

  if (error) {
    enqueueSnackbar(t('Can not get YouTube followers data for current account'), { variant: 'error' });

    return null;
  }

  return <AudienceCardTemplate {...data?.analyticsAudience} />;
};

export default AudienceCard;
