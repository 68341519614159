import { History } from 'history';
import { ConnectableFanPageAccountsProfile_connectableFacebookPages_pages } from '../Profile/ProfileConnectForm/__generated__/ConnectableFanPageAccountsProfile';

export const getInitialFanpageIds = (
  fanPages: Array<ConnectableFanPageAccountsProfile_connectableFacebookPages_pages | null>
) =>
  fanPages.reduce((accumulator: string[], fanPage) => {
    if (fanPage?.isAlreadyConnected) {
      accumulator.push(fanPage.id);
    }

    return accumulator;
  }, []);

export const setUrlParams = (history: History<any>, search: string) => {
  const newSearch = new URLSearchParams(search).toString();
  history.push({ pathname: history.location.pathname, search: newSearch });
};
