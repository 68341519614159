import styled from '@emotion/styled';
import * as React from 'react';
import { SignUpAndSignInBreakpoint } from '../../../../libs/theme';

export type TopPageCoverProps = CoverProps;

const TopPageCover = (props: TopPageCoverProps) => {
  const { img } = props;

  return <Cover img={img} />;
};

interface CoverProps {
  img: any;
}

const Cover = styled.div<CoverProps>`
  width: 440px;
  background-image: url(${props => props.img});
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
  border-radius: 0 7px 7px 0;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    display: none;
  }
`;

export default TopPageCover;
