import React from 'react';
import { StepStyleDTO } from 'react-form-stepper/dist/components/Step/StepTypes';
import { useTranslation } from 'react-i18next';
import Stepper from '@src/components/atoms/Stepper';
import {
  AnyXEngagementCampaignStatus,
  AnyXMarketplaceCampaignStatus,
  CampaignType,
} from '@src/__generated__/globalTypes';

interface StatusStepperProps {
  status: AnyXEngagementCampaignStatus | AnyXMarketplaceCampaignStatus;
  styleConfig?: StepStyleDTO;
  type: CampaignType;
}

const StatusStepper = ({ status, styleConfig, type }: StatusStepperProps) => {
  const { t } = useTranslation();

  const activeColor = () => {
    if (type === CampaignType.ENGAGEMENT) {
      switch (status) {
        case AnyXEngagementCampaignStatus.INEFFECTIVE:
        case AnyXEngagementCampaignStatus.WARNING_EFFECTIVE:
        case AnyXEngagementCampaignStatus.WARNING_REVIEWING:
          return '#ff5f5f';
        default:
          return '#3892e5';
      }
    } else {
      switch (status) {
        case AnyXMarketplaceCampaignStatus.WARNING_EFFECTIVE:
        case AnyXMarketplaceCampaignStatus.INEFFECTIVE:
          return '#ff5f5f';
        default:
          return '#3892e5';
      }
    }
  };

  const activeStep = () => {
    if (type === CampaignType.ENGAGEMENT) {
      switch (status) {
        case AnyXEngagementCampaignStatus.DRAFTING:
          return 0;
        case AnyXEngagementCampaignStatus.REVIEWING:
        case AnyXEngagementCampaignStatus.WARNING_REVIEWING:
          return 1;
        case AnyXEngagementCampaignStatus.POSTING:
          return 2;
        default:
          return 3;
      }
    } else {
      switch (status) {
        case AnyXMarketplaceCampaignStatus.POSTING:
          return 0;
        case AnyXMarketplaceCampaignStatus.EFFECTIVE:
        case AnyXMarketplaceCampaignStatus.WARNING_EFFECTIVE:
          return 1;
        case AnyXMarketplaceCampaignStatus.REVIEWING:
          return 2;
        default:
          return 3;
      }
    }
  };

  const steps = () => {
    if (type === CampaignType.ENGAGEMENT) {
      switch (status) {
        case AnyXEngagementCampaignStatus.INEFFECTIVE:
          return [
            { label: t('Drafting') },
            { label: t('Reviewing') },
            { label: t('Posting') },
            { label: t('Ineffective') },
          ];
        case AnyXEngagementCampaignStatus.WARNING_EFFECTIVE:
          return [
            { label: t('Drafting') },
            { label: t('Reviewing') },
            { label: t('Posting') },
            { label: t('Warning') },
          ];
        case AnyXEngagementCampaignStatus.WARNING_REVIEWING:
          return [
            { label: t('Drafting') },
            { label: t('Warning') },
            { label: t('Posting') },
            { label: t('Effective') },
          ];
        default:
          return [
            { label: t('Drafting') },
            { label: t('Reviewing') },
            { label: t('Posting') },
            { label: t('Effective') },
          ];
      }
    } else {
      switch (status) {
        case AnyXMarketplaceCampaignStatus.INEFFECTIVE:
          return [
            { label: t('Posting') },
            { label: t('Effective') },
            { label: t('Reviewing') },
            { label: t('Ineffective') },
          ];
        case AnyXMarketplaceCampaignStatus.WARNING_EFFECTIVE:
          return [
            { label: t('Posting') },
            { label: t('Warning') },
            { label: t('Reviewing') },
            { label: t('Approved') },
          ];
        default:
          return [
            { label: t('Posting') },
            { label: t('Effective') },
            { label: t('Reviewing') },
            { label: t('Approved') },
          ];
      }
    }
  };

  return <Stepper activeColor={activeColor()} activeStep={activeStep()} steps={steps()} styleConfig={styleConfig} />;
};

export default StatusStepper;
