import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useQueryHelper } from '@src/libs/hooks';
import { CompareAccountType } from './types';

interface BaseHeaderTabProps {
  allCompareAccounts: CompareAccountType[];
  setAllCompareAccounts: React.Dispatch<React.SetStateAction<CompareAccountType[]>>;
  className?: string;
  isPostDetailTabs?: boolean;
  hasLabel?: boolean;
  tabTextAlign?: 'center' | 'left' | 'right';
}

const BaseHeaderTab = ({
  allCompareAccounts,
  setAllCompareAccounts,
  className,
  isPostDetailTabs,
  hasLabel = true,
  tabTextAlign = 'left',
}: BaseHeaderTabProps) => {
  const { search, history, t } = useQueryHelper();
  const searchParams = new URLSearchParams(search);

  const handleClick = (account: CompareAccountType) => {
    if (searchParams.has('p')) {
      searchParams.delete('p');
      history.replace({
        search: searchParams.toString(),
      });
    }

    setAllCompareAccounts(prevState =>
      prevState.map(acc => ({ ...acc, isSelected: acc.accountId === account.accountId }))
    );
  };

  return (
    <Wrapper className={className}>
      {allCompareAccounts.map(item => {
        const isSelected = item.isSelected;
        const extendedPlan = allCompareAccounts.length > 5;
        const shouldHideLabel = !hasLabel && extendedPlan;

        return (
          <AccountTab
            active={isSelected}
            onClick={() => handleClick(item)}
            key={`${item.accountId}`}
            isPostDetailTabs={isPostDetailTabs}
          >
            {item.avatarUrl && <Avatar src={item.avatarUrl} title={item.channelName} />}
            {!shouldHideLabel && (
              <AccountName
                active={isSelected}
                title={item.channelName}
                css={css`
                ${extendedPlan ? 'max-width: 65px;' : ''}
                text-align: ${tabTextAlign};
              `}
              >
                {t(item.channelName)}
              </AccountName>
            )}
            {isSelected && <BottomTransparentBar />}
          </AccountTab>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  margin: 24px 24px 0;
`;
const AccountTab = styled.div<{ active: boolean; isPostDetailTabs?: boolean; width?: string }>`
  display: flex;
  align-items: center;
  height: 56px;
  max-width: 160px;
  min-width: 30px;
  flex: 1;
  padding: 0 12px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5);
  cursor: pointer;
  position: relative;
  ${({ active }) => `border-top: 2px solid ${active ? '#3892E5' : 'transparent'}`};
  ${({ isPostDetailTabs, active }) =>
    isPostDetailTabs
      ? active
        ? 'box-shadow: none; &:not(:first-of-type) {border-left: 1px solid #e1e1e1;}'
        : 'box-shadow: 0px 0px 0px 0px #0000003d, 1px -2px 0px 0px #0000001e inset;'
      : ''};
`;
const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 4px;
  border: solid 1px #f6f6f6;
`;
const AccountName = styled.p<{ active: boolean }>`
  width: 100%;
  font-size: 13px;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${props => (props.active ? '#ff2b52' : '#27313b')};
  font-weight: ${props => (props.active ? 600 : 'normal')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const BottomTransparentBar = styled.div`
  left: 0;
  bottom: -2px;
  height: 2px;
  width: 100%;
  position: absolute;
  background-color: #fff;
`;

export default BaseHeaderTab;
