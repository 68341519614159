import { useHistory } from 'react-router';
import { useEffect } from 'react';
import { useDialog, useDialogStep } from '../../Campaign/JoinButton/useDialog';

export const useConnectDialog = (initial = false) => {
  const history = useHistory();
  const dialog = useDialog(initial);

  const close = () => {
    dialog.close();

    // clear history state from Connect component
    history.replace('/settings/social_connect', {});
  };

  return {
    ...dialog,
    close,
  };
};

export const useFanpageDialog = (initialStep: number) => {
  const history = useHistory();
  const dialogStep = useDialogStep(initialStep);

  useEffect(() => {
    dialogStep.setActiveStep(initialStep);
  }, [initialStep]);

  const close = () => {
    dialogStep.resetStep();

    // clear history state from Connect component
    if (initialStep === 1) {
      history.replace('/settings/social_connect', {});
    }
  };

  return {
    isOpening: !!dialogStep.activeStep,
    step: dialogStep.activeStep,
    handleNext: dialogStep.handleNext,
    handleBack: dialogStep.handleBack,
    close,
  };
};
