import React from 'react';
import { css } from '@emotion/react';
// import Announcements from '@src/components/organisms/Home/Announcements';
import NavigationCards from '@src/components/organisms/Home/NavigationCards';
import Notification from '@src/components/organisms/Home/Notification';
import Tips from '@src/components/organisms/Home/Tips';
import Welcome from '@src/components/organisms/Home/Welcome';
import { useUpdateDocumentTitle } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';

const Home = () => {
  useUpdateDocumentTitle({ title: 'MenuTitle.Home' });

  return (
    <div css={styles.container}>
      <div>
        <div css={styles.navigatorContentContainer}>
          <div>
            <Welcome />
            <NavigationCards />
            <Notification />
          </div>
        </div>

        <div css={styles.announcementsTipsContainer}>
          <div>
            {/* no need to show for the first release */}
            {/* <Announcements /> */}
            <Tips />
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  announcementsTipsContainer: css`
    display: flex;
    flex-basis: 30%;
    flex-wrap: wrap;

    @media (max-width: ${ViewportType.LARGE}px) {
      flex-basis: 100%;
    }

    & > div {
      width: 100%;
    }
  `,
  container: css`
    display: flex;
    justify-content: center;
    padding: 24px;

    & > div {
      display: flex;
      flex-wrap: wrap;
      max-width: 1080px;

      & > div:nth-of-type(1) > div {
        @media (min-width: ${ViewportType.LARGE}px) {
          margin-right: 12px;
        }
      }

      & > div:nth-of-type(2) > div {
        @media (min-width: ${ViewportType.LARGE}px) {
          margin-left: 12px;
        }
      }
    }
  `,
  navigatorContentContainer: css`
    display: flex;
    flex-basis: 70%;
    flex-wrap: wrap;
    height: fit-content;

    @media (max-width: ${ViewportType.LARGE}px) {
      flex-basis: 100%;
    }

    /* stylelint-disable no-descending-specificity */
    & > div {
      width: 100%;
    }
  `,
};

export default Home;
