import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Redirect } from 'react-router';
import { UNEXPECTED_ERROR } from '@src/libs/error';
import { getInitialMaterials } from '@src/libs/form';
import { EngagementPostSchema } from '@src/libs/validation';
import { useQueryHelper } from '@src/libs/hooks';
import { ListIndicator } from '@src/components/molecules/Indicator';
import {
  CreateEngagementPostForInfluencer,
  CreateEngagementPostForInfluencerVariables,
} from './__generated__/CreateEngagementPostForInfluencer';
import {
  UpdateEngagementPostForInfluencer,
  UpdateEngagementPostForInfluencerVariables,
} from './__generated__/UpdateEngagementPostForInfluencer';
import * as CREATE_ENGAGEMENT_POST from './CreateEngagementPostForInfluencer.graphql';
import * as ENGAGEMENT_POST_INFLUENCER from './EngagementPostDetailForInfluencer.graphql';
import EngagementPostForm from './EngagementPostForm';
import { requestCreateUpdateEngagementPostForInfluencer } from './requests';
import { EngagementPostFormProps, FormValues } from './types';
import * as UPDATE_ENGAGEMENT_POST from './UpdateEngagementPostForInfluencer.graphql';
import {
  EngagementPostForInfluencer,
  EngagementPostForInfluencerVariables,
} from './__generated__/EngagementPostForInfluencer';
import {
  EngagementInfluencerSocialMedia,
  EngagementInfluencerSocialMediaVariables,
} from './__generated__/EngagementInfluencerSocialMedia';
import * as ENGAGEMENT_POST_SOCIAL_MEDIA from './EngagementPostDetailSocialMedia.graphql';
import { getIgDefaultSocialMedia } from './utils';

const EngagementPostFormComponent = (props: EngagementPostFormProps) => {
  const { t, enqueueSnackbar, history } = useQueryHelper();
  const { id: engagementId, postId } = props;
  // Query
  const { data, loading: postLoading } = useQuery<EngagementPostForInfluencer, EngagementPostForInfluencerVariables>(
    ENGAGEMENT_POST_INFLUENCER,
    {
      skip: !postId,
      variables: { pk: Number(postId) },
      fetchPolicy: 'cache-and-network',
    }
  );
  // get SOCIAL_MEDIA_ACCOUNTS
  const { data: socialAccountsData, loading: socialLoading, error: socialAccountsError } = useQuery<
    EngagementInfluencerSocialMedia,
    EngagementInfluencerSocialMediaVariables
  >(ENGAGEMENT_POST_SOCIAL_MEDIA, {
    variables: { pk: Number(engagementId) },
  });
  // Mutation
  const [createEngagementPost] = useMutation<
    CreateEngagementPostForInfluencer,
    CreateEngagementPostForInfluencerVariables
  >(CREATE_ENGAGEMENT_POST);
  const [updateEngagementPost] = useMutation<
    UpdateEngagementPostForInfluencer,
    UpdateEngagementPostForInfluencerVariables
  >(UPDATE_ENGAGEMENT_POST);

  if (postLoading || socialLoading) {
    return <ListIndicator />;
  }

  if (socialAccountsError) {
    return <Redirect to="/404" />;
  }

  const engagementPost = data?.engagementPostForInfluencerV2 || null;
  const postStatus = engagementPost?.postStatus;
  const initialMaterials = engagementPost ? getInitialMaterials(engagementPost.materials) : [];

  if (!!postId && !engagementPost) {
    return <Redirect to="/404" />;
  }

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const { ok, error } = await requestCreateUpdateEngagementPostForInfluencer(
      props,
      values,
      createEngagementPost,
      updateEngagementPost
    );

    if (ok && !error) {
      enqueueSnackbar(t('succeededInSaving'), { variant: 'success' });
      history.push(`/job/engagement/${engagementId}/post`);
    } else {
      enqueueSnackbar(t('failedToSave'), { variant: 'error' });

      const errorMessage = error || UNEXPECTED_ERROR;
      console.error(errorMessage);
      enqueueSnackbar(t(errorMessage), { variant: 'error' });
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        planedPostDate: engagementPost?.planedPostDate || '',
        content: engagementPost?.content || '',
        materialsUrl: initialMaterials.map(item => item.url) || [],
        socialAccountId: String(engagementPost?.socialAccountId || ''),
        socialAccountName: engagementPost?.socialAccountName || '',
        socialAccountMedia: engagementPost?.socialAccountMedia || '',
        socialMedia: engagementPost?.socialMedia || '',
        igSocialMedia: getIgDefaultSocialMedia(engagementPost),
      }}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={EngagementPostSchema}
    >
      <EngagementPostForm
        postStatus={postStatus}
        initialMaterials={initialMaterials}
        id={engagementId}
        postId={postId}
        socialAccountsData={socialAccountsData}
      />
    </Formik>
  );
};

export default EngagementPostFormComponent;
