import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Highcharts } from '@src/components/molecules/HighChart';
import { getGrowthArrow, getGrowthPrefix } from '@src/components/organisms/AnalyticsShared/utils';
import {
  formatLineChartDateCount,
  generateYAxisBasedOnDisplayedCharts,
  SeriesHistoryType,
  formatDateCount,
  getSerieDayData,
  TooltipDataType,
  SeriesType,
  getBarDataBasedOnPointer,
  getEngagementData,
} from '@src/components/organisms/AnalyticsShared/Overview/helpers';
import { defaultInfluencerAvatar } from '@src/libs/image';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import { AccountInfo, AvatarAndNameType, EngagementAccountDataType, VisibleAccountsDataType } from './types';
import { InfluencerYoutubeCompareOverview_influencerYoutubeCompareOverview } from './__generated__/InfluencerYoutubeCompareOverview';

// For Compare page we are only select Followers
export const allYtCompareCharts: YtCompareChartSeriesTypes[] = [
  'Subscribers',
  'Post',
  'View',
  'Like',
  'Dislike',
  'Comment',
];

export type YtCompareChartSeriesTypes = 'Subscribers' | 'Post' | 'View' | 'Like' | 'Dislike' | 'Comment';

export const HIDDEN_KEY = '_EMPTY_';

export const MAIN_ACCOUNT_IDENTIFIER = 'main';

export const compareColors: Record<number, string> = {
  0: '#ff2b52',
  1: '#40b87c',
  2: '#966acc',
  3: '#3892e5',
  4: '#ffbe82',
  5: '#15cccf',
} as const;
const allPostsColors = Object.values(compareColors).map(color => color + '80');

export const getDailyDataForTooltip = (
  categories: string[],
  series: SeriesType[]
): TooltipDataType<YtCompareChartSeriesTypes> =>
  categories.reduce(
    (acc, day, index) => ({
      ...acc,
      [day]: getSerieDayData<YtCompareChartSeriesTypes>(index, series),
    }),
    {}
  );

interface SeriesAndCategoriesType {
  historyData: Record<string, SeriesHistoryType[]>;
  colorIndex: number;
  displayedCharts: YtCompareChartSeriesTypes[];
}
export const getSeriesAndCategories = ({
  historyData: { subscribersHistory, postsHistory, viewsHistory, likesHistory, commentsHistory, dislikesHistory },
  colorIndex,
  displayedCharts,
}: SeriesAndCategoriesType) => {
  const { count: followersCount, date: followersCategories } = formatLineChartDateCount(subscribersHistory);
  const { count: postsCount } = formatDateCount(subscribersHistory, postsHistory);
  const { count: likesCount } = formatDateCount(subscribersHistory, likesHistory);
  const { count: dislikesCount } = formatDateCount(subscribersHistory, dislikesHistory);
  const { count: viewsCount } = formatDateCount(subscribersHistory, viewsHistory);
  const { count: commentsCount } = formatDateCount(subscribersHistory, commentsHistory);

  const dateCategories = followersCategories;

  const currentColor = compareColors[colorIndex];

  const series = [
    {
      name: 'Subscribers',
      type: 'line',
      data: followersCount,
      color: currentColor,
      marker: {
        symbol: 'circle',
        lineWidth: 2,
        lineColor: undefined, // inherit from chart
        fillColor: '#fff',
      },
      visible: displayedCharts.includes('Subscribers'),
      zIndex: 1,
    },
    {
      name: 'Post',
      type: 'column',
      data: postsCount,
      color: currentColor + '80',
      yAxis: 1,
      visible: displayedCharts.includes('Post'),
    },
    {
      name: 'View',
      type: 'column',
      data: viewsCount,
      color: currentColor + '80',
      yAxis: 2,
      visible: displayedCharts.includes('View'),
    },
    {
      name: 'Like',
      type: 'column',
      data: likesCount,
      color: currentColor + '80',
      yAxis: 3,
      visible: displayedCharts.includes('Like'),
    },
    {
      name: 'Dislike',
      type: 'column',
      data: dislikesCount,
      color: currentColor + '80',
      yAxis: 4,
      visible: displayedCharts.includes('Dislike'),
    },
    {
      name: 'Comment',
      type: 'column',
      data: commentsCount,
      color: currentColor + '80',
      yAxis: 5,
      visible: displayedCharts.includes('Comment'),
    },
  ];
  const tooltipData = getDailyDataForTooltip(dateCategories, series);

  return {
    series: series as Highcharts.SeriesOptionsType[],
    categories: dateCategories,
    tooltipData,
  };
};

export const getOptions = (
  displayedCharts: YtCompareChartSeriesTypes[],
  accountsInfo: (AccountInfo | null)[],
  fetchDailyPosts: (date: string) => void,
  locale: string,
  isMobile: boolean
): {
  options: Highcharts.Options;
} => {
  const { t } = useTranslation();
  let options = {} as Highcharts.Options;
  let combinedTooltipData = [] as Record<string, TooltipDataType<YtCompareChartSeriesTypes>>[];
  const [chartState, setChartState] = useState<Highcharts.Chart | undefined>(undefined);
  accountsInfo.map((accountInfo, index) => {
    if (!accountInfo || Object.keys(accountInfo).length === 0) {
      return;
    }
    const {
      graph: {
        subscribers: subscribersHistory,
        posts: postsHistory,
        views: viewsHistory,
        likes: likesHistory,
        dislikes: dislikesHistory,
        comments: commentsHistory,
      },
    } = accountInfo;

    // we are showing X-Axis dates based on displayed charts checkboxes
    const { series, categories, tooltipData } = getSeriesAndCategories({
      historyData: {
        subscribersHistory,
        postsHistory,
        viewsHistory,
        likesHistory,
        commentsHistory,
        dislikesHistory,
      },
      colorIndex: index,
      displayedCharts,
    });
    // in order to match hovered tooltip data we have to use color[index]
    const tooltipDataColor = compareColors[index];
    combinedTooltipData = {
      ...combinedTooltipData,
      [tooltipDataColor]: { ...tooltipData, accountInfo },
    };
    const yAxis = generateYAxisBasedOnDisplayedCharts<YtCompareChartSeriesTypes>(allYtCompareCharts, false, !isMobile);

    options = {
      ...options,
      chart: {
        type: 'column',
        events: {
          // eslint-disable-next-line object-shorthand
          click: function (e) {
            const selectedPoint = getBarDataBasedOnPointer(this, e, allPostsColors);
            // if Y-axis value is other than 0, means that there are some posts on this day
            if (selectedPoint?.length && selectedPoint.some(point => point.y)) {
              fetchDailyPosts(`${selectedPoint[0].category}`);
            }
          },
          // eslint-disable-next-line object-shorthand
          load: function () {
            setChartState(this);
          },
        },
      },
      title: {
        text: '',
      },
      xAxis: {
        // @ts-ignore -> categories doesnt exist on xAxis
        categories: [...(options?.xAxis?.categories || []), ...categories],
        crosshair: true,
        type: 'category',
        labels: {
          formatter: function formatter() {
            return localizedDateFormatter(this.value, 'MMM do', locale as AppLanguage);
          },
        },
      },
      yAxis,
      legend: {
        enabled: false,
      },
      tooltip: {
        padding: 0,
        useHTML: true,
        borderColor: '#27313b',
        borderWidth: 0,
        borderRadius: 3,
        headerFormat: '',
        formatter: function formatter(): string {
          // some colors are coded as 'color + 80'
          const currentColor = this.color.toString().slice(0, 7);
          // @ts-ignore Highchart color has some specific type
          const currentTooltipData = combinedTooltipData[currentColor];
          const subscribersIncrement = currentTooltipData[this.key].Subscribers.increment;
          const incrementArrow = getGrowthArrow(subscribersIncrement);
          const prefix = getGrowthPrefix(subscribersIncrement);
          const {
            accountInfo: { avatar, name },
          } = currentTooltipData;

          const incrementSection = subscribersIncrement
            ? `<span style="color: ${
                subscribersIncrement > 0 ? '#40b87c' : '#ff2b52'
              };">${prefix} ${subscribersIncrement} ${incrementArrow}</span>`
            : '';

          return `
          <div style="display:flex;flex-direction:column;border-radius:3px;border: 2px solid #27313b;margin:-2px;background-color:#27313b;font-size:12px;padding:13px 16px;">
          <div style="display: flex; padding-bottom: 8px;"><img src=${defaultInfluencerAvatar(
            avatar
          )} style="width: 24px; height: 24px; object-fit: contain; border: 1px solid #27313b; border-radius: 50%;"/>
          <span style="align-self: center;padding-left: 8px;font-size: 13px;font-weight: 600;color: #fff;">${name}</span>
          </div>
            <h3 style="margin-bottom:8px; font-size: 13px; color: #fff;">${this.key}</h3>
            <div style="display:flex; flex-direction: column;">
              <div style="display: flex;">
                <div style="display: flex; width: max-content; padding-right: 16px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Subscribers')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${currentTooltipData[this.key].Subscribers.value}
                      ${incrementSection}
                    </p>
                  </div>
                </div>
  
                <div style="display: flex;  flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Post')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${currentTooltipData[this.key].Post.value}
                    </p>
                  </div>
                </div>

                <div style="display: flex; flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('View')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${currentTooltipData[this.key].View.value}
                    </p>
                  </div>
                </div>
              </div>
  
              <div style="display: flex; margin-top: 12px;">
                <div style="display: flex; flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Like')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${currentTooltipData[this.key].Like.value}
                    </p>
                  </div>
                </div>
                  
                <div style="display: flex;  flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Dislike')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${currentTooltipData[this.key].Dislike.value}
                    </p>
                  </div>
                </div>

                <div style="display: flex;  flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Comment')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${currentTooltipData[this.key].Comment.value}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>`;
        },
        pointFormat: '',
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
        series: {
          cursor: 'pointer',

          point: {
            events: {
              // eslint-disable-next-line object-shorthand
              click: function () {
                if (this.color && allPostsColors.includes(this.color as string)) {
                  fetchDailyPosts(`${this.category}`);
                }
              },
              // eslint-disable-next-line object-shorthand
              mouseOver: function () {
                const hasDailyPosts = Object.values(combinedTooltipData).some(
                  // @ts-ignore => value doesn't exist
                  data => data[this.category]?.Post.value !== '-'
                );
                if (hasDailyPosts) {
                  /* eslint-disable */
                  // @ts-ignore
                  chartState?.chartBackground?.htmlCss({ cursor: 'pointer' });
                }
              },
              mouseOut: () => {
                // @ts-ignore
                chartState?.chartBackground?.htmlCss({ cursor: 'auto' });
              },
            },
          },
        },
      },
      series: [...(options?.series || []), ...series],
    };
  });

  return { options };
};

export const getCompareEngagementData = (
  visibleAccountsData: VisibleAccountsDataType[],
  accountsAvatarAndName: AvatarAndNameType[]
) => {
  return visibleAccountsData.reduce<EngagementAccountDataType[]>((acc, curr) => {
    const { avatar, name } = accountsAvatarAndName.find(el => el.uniqId === curr.uniqId) || { avatar: '', name: '' };
    const {
      summary: {
        totalSubscribers,
        averageViews,
        totalPosts,
        averageLikes,
        averageComments,
        maximumLikes,
        maximumViews,
      },
    } = curr;
    const subscribersEngagement = getEngagementData(totalSubscribers);
    const postsEngagement = getEngagementData(totalPosts);
    const likesEngagement = getEngagementData(averageLikes);
    const viewsEngagement = getEngagementData(averageViews);
    const commentsEngagement = getEngagementData(averageComments);
    const maxLikesEngagement = getEngagementData(maximumLikes);
    const maxViewsEngagement = getEngagementData(maximumViews);
    return [
      ...acc,
      {
        avatar,
        name,
        subscribers: subscribersEngagement,
        posts: postsEngagement,
        avgLikes: likesEngagement,
        avgViews: viewsEngagement,
        avgComments: commentsEngagement,
        maxLikes: maxLikesEngagement,
        maxViews: maxViewsEngagement,
      },
    ];
  }, []);
};

export const composeComparableAccountData = (
  accountsAvatarAndName: AvatarAndNameType[],
  isMainAccountVisible: boolean,
  visibleAccountsIds: number[],
  compareOverviewData?: InfluencerYoutubeCompareOverview_influencerYoutubeCompareOverview | null
) => {
  const mainAccountData = compareOverviewData
    ? [{ ...compareOverviewData.mainAccount, uniqId: `${compareOverviewData.mainAccount.id}_main` }]
    : [];
  const comparableAccountsData = compareOverviewData?.compareAccounts
    ? compareOverviewData?.compareAccounts.map(acc => {
        // for each comparable account find uniqId from accountsAvatarAndName
        const uniqId = accountsAvatarAndName.find(el => el.elementId !== undefined && el.id === acc.id)?.uniqId || '';

        return { ...acc, uniqId };
      })
    : [];

  return {
    visibleAccountsData: [
      ...(isMainAccountVisible ? mainAccountData : []),
      ...comparableAccountsData.filter(acc => visibleAccountsIds.includes(acc.id)),
    ],
    allComparableAccountsData: [...mainAccountData, ...comparableAccountsData],
  };
};
