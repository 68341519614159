import i18n from 'i18next';
import React from 'react';
import signInCover from '@src/assets/img/signInCover.png';
import signInCoverJP from '@src/assets/img/signInCoverJP.png';
import signUpCover from '@src/assets/img/signUpCover.png';
import signUpCoverJP from '@src/assets/img/signUpCoverJP.png';

interface CoverProps {
  isSignIn?: boolean;
}

const Cover = ({ isSignIn }: CoverProps) => {
  const isJPLanguage = i18n.language === 'ja';

  return (
    <img
      alt="cover"
      height="472"
      src={isSignIn ? (isJPLanguage ? signInCoverJP : signInCover) : isJPLanguage ? signUpCoverJP : signUpCover}
      width="404"
    />
  );
};

export default Cover;
