import { css } from '@emotion/core';
import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SocialAccountType } from '../../../__generated__/globalTypes';
import { switchSocialConnectIcon, switchSocialButtonColor, AuthenticationType } from '../../../libs/SocialMedia';

interface SocialButtonProps {
  className?: string;
  title: string;
  iconSize?: string;
  onClick?: () => void;
  socialMedia?: SocialAccountType | AuthenticationType;
  disabled?: boolean;
  hasIcon?: boolean;
  comboButton?: boolean;
  hasArrow?: boolean;
  href?: string;
  light?: boolean;
}

const SocialButton = (props: SocialButtonProps) => {
  const { t } = useTranslation();
  const {
    className,
    socialMedia,
    iconSize,
    title,
    onClick,
    disabled,
    hasIcon = true,
    comboButton,
    href,
    hasArrow = false,
    light,
  } = props;
  const bgcolor = switchSocialButtonColor(socialMedia, light);
  const translatedTitle = t(`Button.${title}`);

  return (
    <Button
      className={className}
      onClick={onClick}
      socialMedia={socialMedia}
      bgcolor={bgcolor}
      disabled={disabled}
      hasIcon={hasIcon}
      light={light}
      comboButton={comboButton}
      href={href}
    >
      {hasIcon && (
        <Image
          src={switchSocialConnectIcon(socialMedia, light)}
          height={iconSize}
          width={iconSize}
          css={comboButton ? marginRight8 : null}
        />
      )}
      {comboButton && <Image src={switchSocialConnectIcon(SocialAccountType.INSTAGRAM)} css={noPaddingLeft} />}
      <Title>{translatedTitle}</Title>
      {hasArrow && <Icon className="material-icons">keyboard_backspace</Icon>}
    </Button>
  );
};

interface ButtonProps {
  socialMedia?: SocialAccountType | AuthenticationType;
  bgcolor?: string;
  disabled?: boolean;
  hasIcon?: boolean;
  comboButton?: boolean;
  light?: boolean;
}

const Button = styled.a<ButtonProps>`
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.bgcolor};
  background-image: ${props =>
    props.comboButton
      ? 'linear-gradient(100deg, #4460a0 26%, #ce3396 74%, #ff6641)'
      : props.socialMedia === SocialAccountType.INSTAGRAM
      ? props.light
        ? 'linear-gradient(116.92deg, #F9BB67 22.14%, #DA337E 90.57%)'
        : 'linear-gradient(72deg, #ae3bb0, #ff6641)'
      : 'none'};
  border-radius: 7px;
  color: #fff;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  text-align: center;
  line-height: 48px;
  transition: opacity 0.3s ease-in-out;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;

  &:hover {
    opacity: ${props => (props.disabled ? 0.5 : 0.8)};
  }
`;

const noPaddingLeft = css`
  padding-left: 0;
`;

const marginRight8 = css`
  margin-right: 8px;
`;

const Image = styled.img`
  left: 0;
  width: 24px;
  margin-right: 16px;
  padding-left: 20px;
  position: absolute;
`;

const Icon = styled.i`
  transform: rotate(180deg);
  position: absolute;
  right: 10px;
  font-size: 18px;
  font-weight: 600;
`;

const Title = styled.span``;

export default SocialButton;
