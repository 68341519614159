import { formatDate, formatIntNumber } from '@src/libs/format';
import { InstagramFeedPostSortField } from '@src/__generated__/globalTypes';
import { InteractionPostModel } from './types';

export const instagramInteractionPostListColumns = [
  {
    key: 'likes',
    title: 'Likes',
    orderField: InstagramFeedPostSortField.LIKE,
    formatter: formatIntNumber,
  },
  {
    key: 'comments',
    title: 'Comment',
    orderField: InstagramFeedPostSortField.COMMENT,
    formatter: formatIntNumber,
  },
  {
    key: 'postDate',
    title: 'Post Date',
    orderField: InstagramFeedPostSortField.POST_DATE,
    formatter: formatDate,
  },
] as { title: string; orderField: InstagramFeedPostSortField; key: keyof InteractionPostModel; formatter: Function }[];
