import styled from '@emotion/styled';
import * as React from 'react';
import { SocialAccountType } from '../../../../../__generated__/globalTypes';
import { defaultEmptyImage } from '../../../../../libs/image';
import { switchImage, switchText } from '../../../../../libs/SocialMedia';
import SocialEngagements from './SocialEngagements';

export interface ProfilePostProps extends EngagementProps {
  className?: string;
  thumbNail: string | null;
}

const ProfilePost = (props: ProfilePostProps) => {
  const { className, thumbNail, socialMedia, likes, comments, shares, views, content } = props;

  // TODO: change EngagementWrapper to Link
  return (
    <div className={className}>
      <Header>{switchHeader(socialMedia)}</Header>
      <ThumbnailWrapper>
        {!thumbNail && content ? (
          <TextThumbnail>
            <ContentWrapper>{content}</ContentWrapper>
          </TextThumbnail>
        ) : (
          <Thumbnail url={defaultEmptyImage(thumbNail)} />
        )}
      </ThumbnailWrapper>
      <EngagementWrapper>
        <SocialEngagements likes={likes} comments={comments} shares={shares} views={views} socialMedia={socialMedia} />
      </EngagementWrapper>
    </div>
  );
};

export interface EngagementProps {
  content: string | null;
  likes: number;
  comments: number;
  shares: number | null;
  views: number | null;
  socialMedia?: SocialAccountType;
}

const switchHeader = (socialMedia?: SocialAccountType) => {
  if (!socialMedia) {
    return;
  }

  const image = switchImage(socialMedia);
  const mediaTitle = switchText(socialMedia);

  return (
    <>
      <Icon src={image} alt="" />
      <SocialMediaLabel>{mediaTitle}</SocialMediaLabel>
    </>
  );
};

const Header = styled.div`
  margin: 8px;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    margin-bottom: 15px;
  }
`;

const SocialMediaLabel = styled.span`
  color: #27313b;
  font-size: 13px;
`;

const Icon = styled.img`
  width: 16px;
  display: inline-block;
  margin-right: 4px;
`;

const ThumbnailWrapper = styled.div`
  width: 100%;
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }

  @media (max-width: 600px) {
    &::before {
      padding-top: 273px;
    }
  }
`;

const Thumbnail = styled.div<{ url: string | null }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: ${({ url }) => (!!url ? `url(${url})` : 'none')};
  border-radius: 2px;
`;

const ContentWrapper = styled.span`
  display: block;
  padding: 16px;
`;

const TextThumbnail = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f6f8fa;
  border-radius: 2px;
  overflow: auto;
  font-size: 16px;
`;

const EngagementWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 8px 21px 12px 16px;
  justify-content: space-between;
`;

export default ProfilePost;
