import styled from '@emotion/styled';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../../libs/format';
import { capitalizeText } from '../../../libs/text';
import { PriceSchema } from '../../../libs/validation';
import { SocialMediaTypeForRateCard } from '../../../__generated__/globalTypes';
import { DialogModal } from '../DialogModal';
import PriceEditorForm from './priceEditor';
import { FormValues } from './type';

interface PriceCardProps {
  className?: string;
  price: number;
  currency: string;
  socialMedia: SocialMediaTypeForRateCard;
  onChange: (social: SocialMediaTypeForRateCard, price: number) => void;
}

const PriceCard = (props: PriceCardProps) => {
  const { t } = useTranslation();
  const { className, price, currency, socialMedia, onChange } = props;
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSubmit = async (payload: FormValues) => {
    const { price: newPrice } = payload;
    onChange(socialMedia, newPrice);
    setModalOpen(false);
  };

  return (
    <>
      <Wrapper className={className}>
        <LeftSide>
          <Price>
            {formatNumber(price, 2)}
            <Currency>{currency}</Currency>
          </Price>
          <Title>{t(`${capitalizeText(socialMedia.replace('INSTAGRAM_STORY', 'INSTAGRAM STORY'))} Post`)}</Title>
        </LeftSide>
        <RightSide>
          <EditButton
            onClick={() => {
              setModalOpen(true);
            }}
          >
            {!!price ? t('Edit') : `+ ${t('AddPostPrice').toUpperCase()}`}
          </EditButton>
        </RightSide>
      </Wrapper>
      <DialogModal
        isActionsBorderTop={false}
        closeOnDocumentClick={false}
        isOpen={isModalOpen}
        closeModal={handleCloseModal}
      >
        <Formik
          initialValues={{
            price,
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={PriceSchema}
          onSubmit={handleSubmit}
        >
          <PriceEditorForm {...props} />
        </Formik>
      </DialogModal>
    </>
  );
};

const Wrapper = styled.div`
  display: inline-flex;
  width: 100%;
  height: 64px;
  padding: 16px;
  background: #fff;
  border: 1px solid #dee5ec;
  box-sizing: border-box;
  border-radius: 5px;
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`;

const Price = styled.div`
  display: inline-flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #27313b;
`;

const Currency = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #6e7c89;
  margin-left: 4px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #6e7c89;
  float: right;
`;

const RightSide = styled.div`
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const EditButton = styled.div`
  height: 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #ff2b52;
  cursor: pointer;
`;

export default PriceCard;
