import styled from '@emotion/styled';
import React from 'react';
import ArrowDown from '@src/assets/icon/arrowDown.svg';

export const MenuControlIcon = ({ className }: { className?: string }) => (
  <ControllerButton className={className}>
    <img src={ArrowDown} />
  </ControllerButton>
);

const ControllerButton = styled.div`
  background-color: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  width: 35px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
